/**
 * @author patrick.bard
 */
var app = angular.module("nms.dynamicDevice");

app.controller("ChoiceBodyController", ["$rootScope", "$scope", "ContentManager", "DomainHandlerService", "$filter",
    "RestrictionService", "DataPathService", "NodeValues", "NodeStatusService", "WhenVerifierService",
    function($rootScope, $scope, contentManager, DomainHandlerService, $filter, RestrictionService, DataPathService, NodeValues,
    NodeStatusService, WhenVerifierService) {
        var unregisterFunctions = [];
        $scope.defaultValue = _.get($scope.choice, "sub-statements.default");
        $scope.emptyChoiceText = NodeValues.FIELD_WITHOUT_VALUE;
        $scope.caseVisibilities = {};
        $scope.cases = $scope.choice["cases-template"];
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.choice);
        var casesContainSomeWhenCondition = _.some($scope.cases, WhenVerifierService.hasWhenCondition);
        $scope.isNodeVisible = !(hasWhenCondition || ($scope.isTransient && casesContainSomeWhenCondition));
        var casesInformation = [];

        if (!$scope.pathKeys && $scope.getPathKeys) {
            $scope.pathKeys = $scope.getPathKeys();
        }

        var getCasePath = function(caseOption) {
            return _.get(caseOption, "sub-statements.path");
        };

        $scope.readOnly = $rootScope.readOnly;
        _.forOwn($scope.cases, function(caseOption) {
            var caseHasWhenCondition = WhenVerifierService.hasWhenCondition(caseOption);
            var casePath = getCasePath(caseOption);
            casesInformation.push({caseOption: caseOption, path: casePath, hasWhenCondition: caseHasWhenCondition});
            $scope.caseVisibilities[casePath] = !caseHasWhenCondition;
        });

        $scope.selectedCase = {id: ""};

        var loadData = function() {
            $scope.choice.case = _.get(DomainHandlerService.getDataNode(pathWithKeys), "case");
            $scope.hasCaseSelected = !$filter("isEmptyValue")($scope.choice.case);
            $scope.selectedCase.id = ($scope.hasCaseSelected) ? $scope.choice.case.id : "";
        };

        var refreshTree = function() {
            $rootScope.$broadcast("reloadTreeNodeOf=" + pathWithKeys);
            $rootScope.$broadcast("dataNodeChanged", {pathKeys: $scope.pathKeys, node: $scope.choice});
        };

        if (!$scope.isTransient) {
            var pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.choice);
            var casePathWithKeys = pathWithKeys + ".case";
            loadData();

            var unregister = $rootScope.$on("reloadContentNodeOf=" + pathWithKeys, function() {
                loadData();
            });
            unregisterFunctions.push(unregister);
        }

        $scope.goToCase = function(canonicalPath) {
            var node = $scope.cases[$scope.choice.case.id];

            var content: any = {
                node: node,
                path: node.paths.schemaJsonPath,
                canonicalPath: canonicalPath,
                pathKeys: $scope.pathKeys
            };
            contentManager.setContent(content);
        };

        $scope.confirmSelection = function(caseId) {
            if (caseId && (!$scope.choice.case || $scope.choice.case.id !== caseId)) {
                $scope.choice.case = Object.assign({}, $scope.cases[caseId]);
                var choiceCaseDataNode: any = {id: caseId};
                if ($scope.watchChanges !== false && !$scope.isTransient) {
                    DomainHandlerService.addOrSetDataNode(casePathWithKeys, choiceCaseDataNode);
                }
            }
            $scope.hasCaseSelected = true;
            refreshTree();
        };

        var deleteSelectedCase = function() {
            if($scope.watchChanges !== false && !$scope.isTransient) {
                if (_.isUndefined($scope.defaultValue)) {
                    DomainHandlerService.deleteDataNodeByJsonPath(pathWithKeys);
                } else {
                    var choiceCaseDataNode: any = {id: $scope.defaultValue};
                    DomainHandlerService.addOrSetDataNode(casePathWithKeys, choiceCaseDataNode);
                }
            }
            delete $scope.choice.case;
            $scope.selectedCase.id = $scope.defaultValue || "";
            $scope.hasCaseSelected = false;
            refreshTree();
        };

        $scope.editSelection = function() {
            $rootScope.showDialog({
                translateKey: "yang.choice.changesLost",
                params: [$scope.choice.name],
                paramsInsideMessage: true,
                isConfirm: true
            }).then(deleteSelectedCase);
        };

        $scope.emptySelection = function() {
            return $scope.selectedCase.id === "";
        };

        $scope.isDisabled = function(choice) {
            if ($scope.isSingleEditing) {
                return $scope.hasCaseSelected || $scope.readOnly;
            }

            return !choice.shouldEdit || $scope.hasCaseSelected || $scope.readOnly;
        };

        /**
         * Verifies if field {@param id} has any error.
         *
         * @param {string} id Field Id.
         * @return {boolean} Indicate whether or not the field has errors.
         */
        $scope.hasErrorsOnField = function(id) {
            $scope.hasErrors = RestrictionService.hasErrorsOnField(id, $scope.form);
            return $scope.hasErrors;
        };

        $scope.isCaseOptionVisible = function(caseOption) {
            return $scope.caseVisibilities[getCasePath(caseOption)];
        };

        var registerChoiceStatusListener = function() {
            var unregisterNodeStatusListener = NodeStatusService.register(
                $scope.choice.paths.schemaJsonPath,
                $scope.choice.paths.dataJsonPath,
                $scope.pathKeys,
                function(nodeStatus, refreshData) {
                    $scope.isNodeVisible = nodeStatus.isVisible;
                    if (refreshData) {
                        loadData();
                    }
                }
            );
            unregisterFunctions.push(unregisterNodeStatusListener);
        };

        var registerChoiceCasesStatusListener = function() {
            _.forOwn(casesInformation, function(caseInformation) {
                if (caseInformation.hasWhenCondition) {
                    var casePath = caseInformation.path;
                    var caseOption = caseInformation.caseOption;
                    var unregisterCaseNodeStatusListener = NodeStatusService.register(
                        caseOption.paths.schemaJsonPath,
                        caseOption.paths.dataJsonPath,
                        $scope.pathKeys,
                        function(nodeStatus, refreshData) {
                            var lastVisibility = $scope.caseVisibilities[casePath];
                            if (lastVisibility && !nodeStatus.isVisible && $scope.selectedCase.id === caseOption.id) {
                                deleteSelectedCase();
                            }

                            $scope.caseVisibilities[casePath] = nodeStatus.isVisible;
                        }
                    );
                    unregisterFunctions.push(unregisterCaseNodeStatusListener);
                }
            });
        };

        if (!$scope.isTransient) {
            if (hasWhenCondition) {
                registerChoiceStatusListener();
            }
            registerChoiceCasesStatusListener();
        }

        $scope.$on("$destroy", function() {
            unregisterFunctions.forEach(_.attempt);
        });
    }
]);
