/**
* @ngdoc service
* @description Executa Workers em um processo diferente do processo da aplicação, fazendo com que não haja travamentos na tela e
* que o processamento seja realizado paralelamente a aplicação.
* Este service abstrai a lógica de utilização de um worker. (Referência: https://developer.mozilla.org/en-US/docs/Web/API/Worker)
**/
var app = angular.module("nms");
app.service("WorkerService", ["$q",
    function($q) {
        const service: any = {};

        service.run = function(worker, data) {
            var deferred = $q.defer();

            worker.onmessage = function(event) {
                worker.terminate();
                deferred.resolve(event.data);
            };

            worker.postMessage(data);

            return deferred.promise;
        };

        return service;
    }
]);
