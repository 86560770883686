"use strict";

/**
* @ngdoc function
* @name nms.templates.controller:TemplateTestController
* @description
* # TemplateTestController
* Controller para a diretiva de teste de template.
*/
var app = angular.module("nms.templates");

app.controller("TemplateTestController", ["$rootScope", "$scope", "VariableValidatorService", "TemplateService",
    function($rootScope, $scope, VariableValidatorService, TemplateService) {
        $scope.commandTest = {showTest: false};

        $scope.variablesFormOwner = {
            getVariablesFn: function() {
                return $scope.createVariables();
            }
        };

        $scope.createVariables = function() {
            return angular.copy($scope.variablesAlreadyAdded);
        };

        $scope.validateCommandsFields = function(commands) {
            if (commands === "") {
                $rootScope.showDialog({
                    translateKey: "popups.alert.nullCommands"
                });
                return false;
            }

            return true;
        };

        $scope.validateTemplateForTest = function() {
            if ($scope.validateCommandsFields($scope.commands)) {
                var commandValidationRequest = {
                    command: $scope.commands,
                    templateLanguage: $scope.language
                };

                TemplateService.validateCommands(commandValidationRequest).then(function() {
                    $scope.commandTest.showTest = true;
                }).catch(function(error) {
                    $rootScope.showDialog({
                        message: error.html
                    });
                });
            }
        };

        $scope.showHideCommandsTest = function() {
            if (!$scope.hasExtractionParams || $scope.commandTest.showTest) {
                $scope.commandTest.showTest = false;
            } else {
                $scope.validateTemplateForTest();
            }
        };

        $scope.testTemplate = function(hasCommands, formOwner) {
            $scope.commandTest.result = null;
            $scope.commandTest.error = false;

            if (!$scope.validateCommandsFields($scope.commands)) {
                return;
            }

            try {
                var variables = formOwner.getVariablesValues();

                TemplateService.testTemplateCommand($scope.variable.name, $scope.commands, variables)
                    .then(function(response) {
                        $scope.commandTest.result = response.resultCommand ? response.resultCommand : response.errorMessage;
                        $scope.commandTest.error = (angular.isDefined(response.errorMessage) && response.errorMessage);
                    });
            } catch (error) {
                $rootScope.showDialog({
                    message: error
                });
            }
        };
    }
]);
