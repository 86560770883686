/**
* Implementação de Web worker para o conversão do yang do equipamento, em um modelo buscável e filtrável a ser utilizado na
* árvore em Info/Config.
**/
var app = angular.module("nms.dynamicDevice");
app.service("TreeFilterWorkerService", ["WorkerService",
    function(WorkerService) {
        const service: any = {};

        service.getWorkerSrc = function() {
            return "webworkers/tree-filter-webworker.js";
        };

        service.run = function(data) {
            var worker = new Worker(service.getWorkerSrc());
            return WorkerService.run(worker, data);
        };

        return service;
    }
]);
