"use strict";

var app = angular.module("nms");

app.directive("changePageSet", ["$timeout", function($timeout) {
    return function(scope, element) {
        element.bind("keypress", function(event) {
            // Get the input value
            var input = element.val();

            // if ENTER, then apply the filter
            if (input && parseInt(input) > 0 && event.which === 13) {
                scope.filters.pageSize = parseInt(input);
                scope.filters.pageNumber = 0;
                scope.pageDetails.currentPage = 1;

                scope.setPageFiltersSession();
                scope.loadPage();
            }
        });
    };
}]);
