var app = angular.module("nms.dynamicDevice");

app.directive("leaflistFormRestriction",
    function() {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                scope.$watch("leaflist", function() {
                    ngModel.$validate();
                }, true);
            }
        };
    }
);
