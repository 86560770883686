"use strict";

/**
* @ngdoc directive
* @name nms.directive:polling
* @description Diretiva para criar componente de controle de polling.
*/
var app = angular.module("nms");

app.directive("polling", function() {
    return {
        restrict: "E",
        templateUrl: "templates/components/ui/polling/polling.html",
        controller: "PollingController",
        scope: {
            device: "="
        }
    };
});
