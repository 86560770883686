"use strict";

/**
* @ngdoc directive
* @name nms.templates.directive:templateInstanceStatus
* @description
* # templateInstanceStatus
* Cria um componente visual com o status geral de uma aplicação de template, contendo os seguintes status:
* <ul>
*   <li>APPLYING</li>
*   <li>SUCCESS</li>
*   <li>PARTIAL</li>
*   <li>FAIL</li>
*   <li>NOT_ORDERED</li>
* </ul>
* Independente do modo de visualização este componente deve sempre levar como base os dados de templates por equipamento.
*/
angular.module("nms.templates")
.directive("templateInstanceStatus", [function() {
    return {
        templateUrl: "templates/features/template/components/ui/template-instance-status/template-instance-status.html",
        controller: "TemplateInstanceStatusCtrl",
        scope: {
            data: "=",
            templateType: "=",
            getSpecificTranslationKeys: "="
        }
    };
}]);

