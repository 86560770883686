"use strict";

import { TemplateInstanceService } from "@nms-ng1/features/template/template-application/template-instance-service";

/**
 * @ngdoc controller
 * @name nms.components.variables:VariablesController
 * @description
 * # VariablesController
 * Classe de controle para a diretiva de variáveis. Usado na aplicação de template
 * e aplicação de comandos por variável.
 */
var app = angular.module("nms");

// TODO [Agendamento CLI/TR069][US-3989] Ajustar referências de device -> equipment
app.controller("VariablesController", [
    "$rootScope",
    "$scope",
    "$translate",
    "TemplateInstanceVariableService",
    "VariableService",
    "VARIABLES",
    "APPLICATION_STATUS",
    "ngDialog",
    "TemplateInstanceService",
    "ActivationService",
    "PRESENTATION_MODE",
    "$filter",
    "cutStringFilter",
    "VariablesService",
    "DeviceDropdownModelService",
    "TemplateInstanceIdentifierService",
    "TemplateInstanceUtils",
    function (
        $rootScope,
        $scope,
        $translate,
        TemplateInstanceVariableService,
        VariableService,
        VARIABLES,
        APPLICATION_STATUS,
        ngDialog,
        TemplateInstanceService: TemplateInstanceService,
        ActivationService,
        PRESENTATION_MODE,
        $filter,
        cutStringFilter,
        VariablesService,
        DeviceDropdownModelService,
        TemplateInstanceIdentifierService,
        TemplateInstanceUtils
    ) {
        _.defaults($scope, { variablesModel: {} });
        VariablesService.init($scope.variablesModel);

        var isEdit = $scope.variablesModel.isEdit;

        $scope.lastCommandsValuesByDevice = $scope.variablesModel.lastCommandsValuesByDevice;
        $scope.lastCommandsByDevice = $scope.variablesModel.lastCommandsByDevice;
        $scope.variablesThatNeedToBeReloaded = $scope.variablesModel.variablesThatNeedToBeReloaded;
        $scope.globalVarsDetails = $scope.variablesModel.globalVarsDetails;
        $scope.equipmentLocalVarsDetails = $scope.variablesModel.equipmentLocalVarsDetails;
        $scope.readOnlyVariables = $scope.variablesModel.readOnlyVariables;
        $scope.variableToReloadByCommand = $scope.variablesModel.variableToReloadByCommand;
        $scope.modalData = $scope.variablesModel.modalData;
        $scope.removedEquipments = $scope.variablesModel.removedEquipments;
        $scope.originalValuesForVariablesBeforeEdition = $scope.variablesModel.originalValuesForVariablesBeforeEdition;
        $scope.variablesModel.initVariables = $scope.initVariables;

        $scope.commandsResults = [];
        $scope.oldDeviceTemplateAssociations = [];
        $scope.filters = {
            orderByVariables: { column: "key", direction: false },
            orderByDevice: { column: "equipmentName", direction: false }
        };

        $scope.presentationMode = { value: PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE };
        $scope.forms = [
            {
                url: "templates/features/template/template-instance/form/global-variables.html",
                name: "globalVarsForm"
            },
            {
                url: "templates/features/template/template-instance/form/local-variables.html",
                name: "localVarsForm"
            }
        ];

        $scope.variablesModel.validateVariables = function (form) {
            if ($scope.hasErrorInExtractionsCommands()) {
                $rootScope.showDialog({
                    translateKey: $scope.getTypeErrorInApplication()
                });
                return false;
            } else {
                if (VariablesService.isLoadingCommands()) {
                    $rootScope.showDialog({
                        translateKey: "templateinstanceform.error.applicationLoading",
                        type: "information"
                    });
                    return false;
                } else {
                    return TemplateInstanceVariableService.validateVariablesForm(
                        form,
                        { forms: $scope.forms },
                        $scope.equipmentLocalVarsDetails
                    );
                }
            }
        };

        $scope.hasErrorInExtractionsCommands = function () {
            var hasError = false;
            if ($scope.globalVarsDetails.vars.length > 0) {
                hasError = $scope.checkForCommandApplicationError($scope.globalVarsDetails.vars);
            }

            if (!hasError && $scope.equipmentLocalVarsDetails.equipmentLocalVars.length > 0) {
                _.forEach($scope.equipmentLocalVarsDetails.equipmentLocalVars, function (equipmentLocalVar) {
                    hasError = $scope.checkForCommandApplicationError(equipmentLocalVar.localVars);
                    return !hasError;
                });
            }

            return hasError;
        };

        $scope.getTypeErrorInApplication = function () {
            var globalVarsStatus = _.map($scope.globalVarsDetails.vars, "status");
            var localVars = _.flatten(_.map($scope.equipmentLocalVarsDetails.equipmentLocalVars, "localVars"));
            var localVarsStatus = _.map(localVars, "status");

            var allStatus = globalVarsStatus.concat(localVarsStatus);

            var firstError = _.find(allStatus, function (status) {
                return $scope.hasError(status);
            });

            return $scope.hasGenericError(firstError)
                ? "templateinstanceform.error.applicationWithParseErrors"
                : "templateinstanceform.error.applicationWithDeviceErrors";
        };

        /**
         * Verifica se a aplicação de template deve ser bloqueada por erros em
         * aplicações de comandos de variáveis.
         **/
        const checkPreConditionsToVerifyErrors = function (variable) {
            return checkVariableTypeAndChangeValueCondition(variable) && checkActionVariableCondition(variable);
        };

        /**
         * Verifica se a variável de includeAndBlockOptionMode é diferente de DO_NOT_GET e
         * verifica se não é uma edição ou se o valor da variável foi alterada.
         **/
        const checkVariableTypeAndChangeValueCondition = function (variable) {
            return (
                variable.variable.includeAndBlockOptionMode !== VARIABLES.includeAndBlockOptionsModes.DO_NOT_GET &&
                (!isEdit || VariablesService.isVariableValueChanged(variable))
            );
        };

        /**
         * Verifica se a variável é do tipo 'AÇÂO' e se o status é diferente de DEVICE_APPLICATION_ERROR.
         **/
        const checkActionVariableCondition = function (variable) {
            return variable.variable.type === VARIABLES.ACTION && variable.status !== "DEVICE_APPLICATION_ERROR";
        };

        $scope.checkForCommandApplicationError = function (variables) {
            return _.some(variables, function (variable) {
                var result = false;
                if (checkPreConditionsToVerifyErrors(variable)) {
                    result = $scope.hasError(variable.status);
                }

                return result;
            });
        };

        $scope.isTemplateInstanceApplied = function () {
            return !!$scope.templateInstance.status && $scope.templateInstance.status !== APPLICATION_STATUS.NOT_REQUESTED;
        };

        $scope.controlAutomaticSelection = function (variable) {
            variable.value[0] = _.find(variable.availableOptions, (option) => VariablesService.isOptionAvailable(option));
            $rootScope.$broadcast("validate");
        };

        $scope.hasError = function (status) {
            return VariablesService.hasError(status);
        };

        $scope.hasGenericError = function (status) {
            return VariablesService.hasGenericError(status);
        };

        $scope.hasApplicationError = function (status) {
            return VariablesService.hasApplicationError(status);
        };

        function hasDefaultViewEnableForReadOnlyVariables(variables) {
            var allVariablesAreReadOnly = _.every(variables, { type: VARIABLES.READ_ONLY });

            if (allVariablesAreReadOnly) {
                return !_.some(variables, { defaultView: true });
            }

            return false;
        }

        $scope.modalData.refresh = function (application) {
            if (application.variable.globalScope) {
                VariablesService.reloadVariableDeviceOptions(application);
            } else {
                var equipment = $scope.templateInstance.equipmentAssociations.find(
                    (equipment) =>
                        TemplateInstanceIdentifierService.resolveIdentifier(equipment.equipmentIdentifier) ===
                        application.devices[0].deviceId
                );

                VariablesService.reloadVariableDeviceOptions(application, equipment);
            }
        };

        $scope.hasReadOnlyVariable = function () {
            var variables = _.map($scope.variablesModel.variablesRestrictions, "var");

            return _.some(variables, { type: VARIABLES.READ_ONLY });
        };

        $scope.hideAllReadOnlyVariables = function (showReadOnlyVariable) {
            _.forEach($scope.globalVarsDetails.vars, function (globalVariable) {
                setVariableDefaultViewForReadOnly(globalVariable.variable, showReadOnlyVariable);
            });

            _.forEach($scope.equipmentLocalVarsDetails.equipmentLocalVars, function (equipament) {
                _.forEach(equipament.localVars, function (localVariable) {
                    setVariableDefaultViewForReadOnly(localVariable.variable, showReadOnlyVariable);
                });
            });
        };

        $scope.hideVariable = function (currentVariable) {
            setVariableDefaultViewForReadOnly(currentVariable.variable, false);
        };

        function setVariableDefaultViewForReadOnly(variable, showReadOnlyVariable) {
            if (variable.type === VARIABLES.READ_ONLY) {
                variable.defaultView = showReadOnlyVariable;
            }
        }

        $scope.showVariableErrors = function (variable) {
            VariablesService.showVariableErrors(variable);
        };

        $scope.variableHasRestrictionsOptions = function (variable, equipment) {
            return VariablesService.variableHasRestrictionsOptions(variable, equipment);
        };

        $scope.revertValue = function (variable, index) {
            variable.value.splice(index, 1, variable.variable.defaultValue);
            $rootScope.$broadcast("validate", {});
            $rootScope.$broadcast("variableValueHasChange");
        };

        function selectVariablePanel(variables, normalizeFunction) {
            if (variables === null) {
                return "loading";
            } else if (shouldShowNoVariables(normalizeFunction(variables))) {
                return "no-variables";
            } else {
                return "show-variables";
            }
        }

        function shouldShowNoVariables(variables) {
            return _.isEmpty(variables) || hasDefaultViewEnableForReadOnlyVariables(variables);
        }

        function normalizeLocalVariables(variables) {
            return _.chain(variables).map("localVars").flatten().map("variable").value();
        }

        function normalizeGlobalVariables(variables) {
            return _.map(variables, "variable");
        }

        $scope.selectLocalVariablePanel = function () {
            return selectVariablePanel($scope.equipmentLocalVarsDetails.equipmentLocalVars, normalizeLocalVariables);
        };

        $scope.selectGlobalVariablePanel = function () {
            return selectVariablePanel($scope.globalVarsDetails.vars, normalizeGlobalVariables);
        };

        $scope.getVariableType = function (variable) {
            return $scope.variableTypes[variable.type];
        };

        $scope.getVariableRestrictions = function (variable) {
            return TemplateInstanceVariableService.hasRestrictions(variable);
        };

        $scope.buildRegexTooltip = function (variable) {
            return VariableService.buildRegexTooltip(variable);
        };

        $scope.hasValue = function (field) {
            return field !== "";
        };

        $scope.defineVariableType = function (type) {
            return VariablesService.defineVariableType(type);
        };

        $scope.isListType = function (variableMap) {
            return VariablesService.isListType(variableMap);
        };

        $scope.isNotEditable = function (varDetails): boolean {
            return !varDetails.variable.editable && varDetails.appliedValue && isEdit;
        };

        $scope.isApplying = function () {
            return $scope.templateInstance.status === APPLICATION_STATUS.APPLYING;
        };

        $scope.removeValue = function (variableMap, $event, index) {
            $event.preventDefault();
            if (variableMap.variable.mandatory === true && variableMap.value.length === 1) {
                $rootScope.showDialog({
                    translateKey: "templateinstanceform.templatedevices.mandatoryVariable"
                });
            } else {
                variableMap.value.splice(index, 1);
                $scope.reloadDependableVariables();
            }
        };

        $scope.addValue = function (variableMap, $event, index) {
            $event.preventDefault();
            variableMap.value.splice(index + 1, 0, variableMap.variable.defaultValue);
            $scope.reloadDependableVariables();
        };

        $scope.buildVariableValue = function (values) {
            return _.isArray(values) ? values.join("<br>") : values;
        };

        $scope.getVarMinValue = function (variable) {
            var min = variable.minValue;

            if (variable.minValue !== null && angular.isDefined(variable.minValue)) {
                if (variable.step) {
                    while (min % variable.step !== 0) {
                        min++;
                    }
                } else if (variable.baseExponent) {
                    min = min < 1 ? 1 : min;

                    var logResult = Math.log(min) / Math.log(variable.baseExponent);
                    while (logResult !== Math.floor(logResult)) {
                        min++;
                        logResult = Math.log(min) / Math.log(variable.baseExponent);
                    }
                }
            } else if (variable.baseExponent) {
                min = 1;
            }

            return min;
        };

        $scope.getVarMaxValue = function (variable) {
            var max = variable.maxValue;

            if (variable.maxValue !== null && angular.isDefined(variable.maxValue)) {
                if (variable.step) {
                    while (max % variable.step !== 0) {
                        max--;
                    }
                } else if (variable.baseExponent) {
                    max = max < 1 ? 1 : max;

                    var logResult = Math.log(max) / Math.log(variable.baseExponent);
                    while (max > 1 && logResult !== Math.floor(logResult)) {
                        max--;
                        logResult = Math.log(max) / Math.log(variable.baseExponent);
                    }
                }
            }

            return max;
        };

        $scope.errorApplyingCommands = function (variable) {
            return variable.status && variable.status !== APPLICATION_STATUS.SUCCESS;
        };

        $scope.getVariableToolTip = function (varDetails): string {
            if ($scope.isNotEditable(varDetails)) {
                return $translate.instant("templateinstanceform.templatedevices.varNotEditable");
            } else if (varDetails.variable.editable && $scope.isApplying()) {
                return $translate.instant("templateinstanceform.templatedevices.varEditableWhenApplying");
            }
        };

        $scope.removeVariableFromReloadPendency = function (varName, equipmentId) {
            VariablesService.removeVariableFromReloadPendency(varName, equipmentId);
        };

        $scope.getAcceptedValues = function (variable, equipment) {
            return VariablesService.getAcceptedValues(variable, equipment);
        };

        $scope.openExtractionApplicationStatus = function (varDetail) {
            var devices = [];
            var variableRestrictions = _.filter($scope.variablesModel.variablesRestrictions, "var.name", varDetail.variable.name);
            var varRestrictions = _.find(variableRestrictions, function (variableRestriction) {
                return _.intersection(
                    _.map(variableRestriction.deviceAssociations, "deviceId"),
                    _.map(varDetail.devices, "deviceId")
                );
            });

            _.forEach($scope.variablesModel.equipmentLocalVarsDetails.equipmentLocalVars, function (equipment) {
                devices.push(equipment);
            });

            $scope.modalData.currentApplication = {
                name: varDetail.variable.name,
                application: varDetail,
                templatesWithRestrictionsByDevice: $scope.getTemplatesWithRestrictions(varRestrictions.deviceAssociations)
            };

            ngDialog
                .open({
                    template: "templates/features/template/template-instance/modals/application-status.html",
                    controller: "ApplicationStatusCtrl",
                    className: "big-modal",
                    resolve: {
                        modalData: function () {
                            return $scope.modalData;
                        },
                        devices: function () {
                            return devices;
                        }
                    }
                })
                .closePromise.then(function () {
                    VariablesService.sync($scope.templateInstance, $scope.templates, $scope.variablesModel);
                });
        };

        $scope.getTemplatesWithRestrictions = function (deviceAssociations) {
            var templatesWithRestrictionsByDevice = [];

            deviceAssociations.forEach(function (deviceAssociation) {
                templatesWithRestrictionsByDevice.push({
                    deviceId: deviceAssociation.deviceId,
                    templates: $scope.convertTemplateIdsIntoTemplateNames(deviceAssociation.templatesWithRestrictions)
                });
            });

            return templatesWithRestrictionsByDevice;
        };

        $scope.convertTemplateIdsIntoTemplateNames = function (templateIds) {
            var templateNames = [];
            templateIds.forEach(function (templateId) {
                var name = $scope.templates[templateId].templateName;
                templateNames.push(name);
            });

            return templateNames;
        };

        $scope.applyCommands = function (varName, selectedDeviceId) {
            var deviceTemplateAssociations = [];
            $scope.templateInstance.equipmentAssociations.forEach(function (equipmentAssociation) {
                var deviceId = equipmentAssociation.equipmentIdentifier.resourceId;
                var templateIds = [];

                equipmentAssociation.templateAssociations.forEach(function (templateAssociation) {
                    templateIds.push(templateAssociation.templateId);
                });

                if (!selectedDeviceId || selectedDeviceId.equipmentIdentifier.resourceId == deviceId) {
                    deviceTemplateAssociations.push({ deviceId: angular.copy(deviceId), templateIds: angular.copy(templateIds) });
                }
            });

            var associations: any = { deviceTemplateAssociationRequests: deviceTemplateAssociations, variableName: varName };

            TemplateInstanceService.getDeviceTemplatesAssociationsByVariable(associations).then(function (result) {
                var deviceVariables = [];
                result.forEach(function (deviceTemplate) {
                    deviceTemplate.templateIdentifiers.forEach(function (deviceIdentifier) {
                        var validationModel: any = {
                            deviceId: deviceTemplate.equipmentId,
                            templateId: deviceIdentifier.templateId,
                            varName: varName,
                            variables: $scope.getAllValidVariablesValues(deviceTemplate.equipmentId)
                        };

                        deviceVariables.push(validationModel);
                    });
                });

                var convertedResult = result.map((value) => {
                    return {
                        equipmentIdentifier: {
                            resourceId: value.equipmentId,
                            type: "DeviceIdentifier"
                        },
                        equipmentDetails: {
                            name: value.equipmentName,
                            model: value.equipmentModel
                        },
                        templateIdentifiers: value.templateIdentifiers
                    };
                });

                ActivationService.validateVariableCommands(deviceVariables).then(function () {
                    $scope.openVariableApply(varName, deviceVariables, convertedResult);
                });
            });
        };

        $scope.getAllValidVariablesValues = function (deviceId) {
            return _.union($scope.getGlobalValidVariablesValues(), $scope.getDeviceVariableValues(deviceId));
        };

        $scope.getDeviceVariableValues = function (deviceId) {
            var variableValues = [];
            var equipmentLocalVarsDetails = _.find(
                $scope.equipmentLocalVarsDetails.equipmentLocalVars,
                function (localVarsDetails) {
                    return localVarsDetails.equipmentId == deviceId;
                }
            );

            if (equipmentLocalVarsDetails) {
                variableValues = VariablesService.getVariableValues(equipmentLocalVarsDetails.localVars);
            }

            return variableValues;
        };

        $scope.getGlobalValidVariablesValues = function () {
            return VariablesService.getValidVariableValues($scope.globalVarsDetails.vars);
        };

        $scope.openVariableApply = function (varName, deviceVariableValues, deviceTemplatesAssociationsByVariable) {
            ngDialog.open({
                template: "templates/features/template/template-instance/modals/results-modal.html",
                controller: "CommandsResultsModalCtrl",
                className: "big-modal",
                resolve: {
                    presentationMode: function () {
                        return $scope.presentationMode;
                    },
                    varName: function () {
                        return varName;
                    },
                    deviceTemplateAssociations: function () {
                        return deviceTemplatesAssociationsByVariable;
                    },
                    devicesVariables: function () {
                        return deviceVariableValues;
                    },
                    commandsResults: function () {
                        return $scope.commandsResults;
                    },
                    oldDeviceTemplateAssociations: function () {
                        return $scope.oldDeviceTemplateAssociations;
                    },
                    templateType: function () {
                        return $scope.templateInstance.type;
                    }
                }
            });
        };

        $scope.reloadVariableDeviceOptions = function (variable, equipment) {
            VariablesService.reloadVariableDeviceOptions(variable, equipment);
        };

        $scope.getTooltipForRefreshButton = function(variable) {
            return variable.variable.enableAutomaticReload
                ? $translate.instant("tooltips.templateinstance.variables.autoreload")
                : $translate.instant("templateinstanceform.refreshVariableTooltip");
        }

        $scope.applyVariableAction = function (variable, equipment, action) {
            if (variable.variable.actions[action]) {
                $rootScope
                    .showDialog({
                        translateKey: "templateinstanceform.actionvariable.execution.confirm",
                        params: [action],
                        paramsInsideMessage: true,
                        isConfirm: true
                    })
                    .then(function () {
                        reloadActionVariable(action, variable, equipment);
                    });
            } else {
                reloadActionVariable(action, variable, equipment);
            }
        };

        function reloadActionVariable(action, variable, equipment) {
            variable.selectedAction = action;
            VariablesService.reloadVariableDeviceOptions(variable, equipment);
        }

        $scope.formatActionVariableResult = function (variable) {
            const resultLabel = `${variable.selectedAction}
                ${$translate.instant("templateinstanceform.actionvariable.executed")}`;

            if ($scope.hasVariableValue(variable)) {
                return `${resultLabel}:`;
            }

            return resultLabel;
        };

        $scope.isActionVariableGlobalScopeAssociatedMoreThanOneDevice = function (variable) {
            return variable.variable.type === VARIABLES.ACTION && variable.devices.length > 1;
        };

        $scope.hasVariableValue = function (variable) {
            return this.isActionVariableGlobalScopeAssociatedMoreThanOneDevice(variable)
                ? false
                : checkHasVariableValue(variable);
        };

        function checkHasVariableValue(variable) {
            const { value } = variable;
            return !_.isEmpty(value) && !_.isEqual(value, [""]) && !_.isEqual(value, [undefined]);
        }

        $scope.reloadDependableVariables = function () {
            VariablesService.reloadDependableVariables();
            $rootScope.$broadcast("variableValueHasChange");
        };

        $scope.getTemplateNames = function (templates) {
            var templateNames = [];

            templates.forEach(function (template) {
                templateNames.push(cutStringFilter(template.templateName, 30));
            });

            return templateNames;
        };

        $scope.createDeviceDropdownModel = function (device) {
            return DeviceDropdownModelService.createModelFromTemplateInstanceDevice(device);
        };

        $scope.getAvailableFeatures = function (device) {
            if (device.equipmentIdentifier.resourceId) {
                var deviceId = device.equipmentIdentifier.resourceId;
                return DeviceDropdownModelService.getAvailableFeatures(deviceId);
            }
        };

        $scope.getLocalVariables = function (device) {
            if ($scope.deviceSelected !== device) {
                $scope.deviceSelected = device;
                $scope.deviceSelectedInLocalVars = device;
            }
        };

        $scope.areThereLocalVars = function (device) {
            return !_.isEmpty(device.localVars);
        };

        $scope.orderByVariablesTable = function (typeVariable) {
            $scope.filters.orderByVariables.direction = !$scope.filters.orderByVariables.direction;

            if (typeVariable === "global") {
                $scope.templateInstance.globalVars = $filter("orderBy")(
                    $scope.templateInstance.globalVars,
                    $scope.filters.orderByVariables.column,
                    $scope.filters.orderByVariables.direction
                );
            } else if (typeVariable === "local") {
                $scope.deviceSelected.localVars = $filter("orderBy")(
                    $scope.deviceSelected.localVars,
                    $scope.filters.orderByVariables.column,
                    $scope.filters.orderByVariables.direction
                );
            }
        };

        $scope.orderByDeviceTable = function (column) {
            if ($scope.filters.orderByDevice.column !== column) {
                $scope.filters.orderByDevice.column = column;
                $scope.filters.orderByDevice.direction = false;
            } else {
                $scope.filters.orderByDevice.direction = !$scope.filters.orderByDevice.direction;
            }

            $scope.templateInstance.equipmentAssociations = $filter("orderBy")(
                $scope.templateInstance.equipmentAssociations,
                $scope.filters.orderByDevice.column,
                $scope.filters.orderByDevice.direction
            );
        };

        $scope.getEquipmentLabel = function () {
            return TemplateInstanceUtils.getEquipmentLabelKey($scope.templateInstance.type);
        };

        $scope.isCliTemplateApplication = function () {
            return TemplateInstanceUtils.isCliTemplateApplication($scope.templateInstance.type);
        };

        /**
         * Verifica se o equipmentIdenfier é igual ao "deviceSelected.equipmentIdentifier", dessa forma
         * consideramos tanto Devices quanto Cpes.
         */
        $scope.isTheSelectedEquipment = function ({ equipmentIdentifier }) {
            return $scope.deviceSelected && _.isEqual(equipmentIdentifier, $scope.deviceSelected.equipmentIdentifier);
        };

        $scope.isActionVariableWithEmptyValue = function (variable) {
            return (
                variable.variable.type === VARIABLES.ACTION && (variable.value[0] === undefined || _.isEmpty(variable.value[0]))
            );
        };

        $scope.$on("convertTemplateInstanceVariables", function () {
            VariablesService.convertTemplateInstanceVariables();
        });

        $scope.$on("validateVariables", function (event, data) {
            if ($scope.variablesModel.validateVariables(data.form)) {
                _.result(data, "success");
            } else {
                _.result(data, "fail");
            }
        });

        $scope.$on("$destroy", function () {
            VariablesService.convertTemplateInstanceVariables();
        });

        $scope.$watch(
            "readOnlyVariables",
            function (newValue) {
                if (!_.isEmpty(newValue)) {
                    $scope.reloadDependableVariables();
                }
            },
            true
        );

        VariablesService.sync($scope.templateInstance, $scope.templates, $scope.variablesModel);

        VariablesService.getVariablesFromServer($scope.variablesModel);

        $scope.$on("unsubscribeVariablesWebsocket", function () {
            VariablesService.unsubscribeWebsocket();
        });
    }
]);
