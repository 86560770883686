var app = angular.module("nms.cpes", []);

app.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("features/cpes");
}]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);
