import {
    TemplateApplicationResult,
    TemplateApplicationResultsFormatterService
} from './template-application-results/template-application-results-formatter.service';
import { Inject, Injectable } from "@angular/core";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { HandlebarsService } from "@nms-ng2/app/shared/services/template-language/handlebars.service";
import { IndividualConfig } from "ngx-toastr";
import {
    ANGULARJS_TRANSLATE,
    STATE,
    NMS_STATES,
    ANGULARJS_ROOTSCOPE,
    GPON_ONU_SERVICE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsToasterLink } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr-model";

/**
 * Tipos de notificação suportadas pelo Maestro.
 * É importante que todas as notificações sejam cadastradas aqui, mesmo que não tenham um layout de notificação padrão, desta
 * forma centralizamos a definição dos tipos.
 */
export enum NotificationType {
    NEW_CPE_REGISTERED = "NEW_CPE_REGISTERED",
    ONU_DISCOVERED = "ONU_DISCOVERED",
    TEMPLATE_APPLICATION_SUCCESSFUL = "TEMPLATE_APPLICATION_SUCCESSFUL",
    TEMPLATE_APPLICATION_FAILED = "TEMPLATE_APPLICATION_FAILED",
    SCHEDULER_ADDED = "SCHEDULER_ADDED",
    SCHEDULER_UPDATED = "SCHEDULER_UPDATED",
    SCHEDULER_DELETED = "SCHEDULER_DELETED",
    SCHEDULER_EXECUTION_ERROR = "SCHEDULER_EXECUTION_ERROR",
    SCHEDULER_WITH_NOT_ALLOWED_DEVICES = "SCHEDULER_WITH_NOT_ALLOWED_DEVICES"
}

type NewCpeRegisteredParams = {
    serialNumber: string;
};

type OnuDiscoveredParams = {
    deviceName: string;
    slotNo: string;
    portNo: number;
    onuSerialNumber: string;
};

export type TemplateApplicationParams = {
    equipmentName: string;
    equipmentHostname: string;
    isDevice: boolean;
    managementIp?: string;
    templateApplicationResults: TemplateApplicationResult[];
};

export type ScheduleJobWithoutPermissionParams = {
    author: string;
    jobName: string;
    titleType: string;
};

type NotificationParametersUnion =
    | NewCpeRegisteredParams
    | OnuDiscoveredParams
    | TemplateApplicationParams
    | ScheduleJobWithoutPermissionParams
    | NotificationConfigurations;

export type Notification = {
    notificationType: NotificationType;
    autoClose: boolean;
    enabled: boolean;
    details: string;
    parameters?: NotificationParametersUnion;
    delayBeforeCloseInSeconds: number;
};

type ToastrTypeUnion = "show" | "success" | "error" | "info" | "warning";

type NotificationConfigurations = {
    title: string;
    code?: number;
    details?: Array<string>;
    message?: string;
    toastrType: ToastrTypeUnion;
    getLinks?: (notification: Notification) => Array<NmsToasterLink>;
};

type NotificationConfig = {
    [key in NotificationType]: NotificationConfigurations;
};
/**
 * Responsável por mostrar o toastr na tela.
 */
@Injectable({
    providedIn: "root"
})
export class NmsNotificationService {
    /**
     * Define os detalhes de notificações específicas pelo tipo.
     * Notificações não adicionadas aqui e contidas no NotificationType, utilizarão o layout default e a implementação dos dados
     * de notificação deve seguir conforme a implementação java de DefaultNotificationMessageBuilder.
     */
    private readonly NOTIFICATION_CONFIG: Partial<NotificationConfig> = {
        NEW_CPE_REGISTERED: {
            title: "nms-notification.newCpeRegistered",
            toastrType: "info",
            getLinks: (notification: Notification): Array<NmsToasterLink> => {
                const { serialNumber } = notification.parameters as NewCpeRegisteredParams;

                return [
                    {
                        id: serialNumber,
                        title: this.translate.instant("cwmp.parameters.view.link"),
                        action: () =>
                            this.$state.go(this.nmsStates.cwmpParameters, {
                                serialNumber
                            })
                    }
                ];
            }
        },
        ONU_DISCOVERED: {
            title: "nms-notification.onuDiscovered",
            toastrType: "info",
            getLinks: (notification: Notification): Array<NmsToasterLink> => {
                const parameters = notification.parameters as OnuDiscoveredParams;

                return [
                    {
                        id: "discovered-onu",
                        title: this.translate.instant("nms-notification.onuDiscovered.view", parameters),
                        action: () => {
                            const filters = {
                                serialNumber: parameters.onuSerialNumber
                            };

                            this.$state.go(
                                this.gponOnuService.getGponOnuUISref(),
                                {
                                    name: parameters.deviceName,
                                    filters,
                                    loadTablePreferences: false
                                },
                                { inherit: false }
                            );
                        }
                    },
                    {
                        id: "discovered-onus-olt",
                        title: this.translate.instant("nms-notification.onuDiscovered.oltLink"),
                        action: () => {
                            const filters = {};
                            this.$state.go(
                                this.gponOnuService.getGponOnuUISref(),
                                {
                                    name: parameters.deviceName,
                                    filters,
                                    loadTablePreferences: false
                                },
                                { inherit: false }
                            );
                        }
                    }
                ];
            }
        },
        TEMPLATE_APPLICATION_FAILED: {
            title: "nms-notification.templateApplicationFailed",
            toastrType: "error",
            getLinks: (notification: Notification): Array<NmsToasterLink> => {
                return this.buildDetailsLinkForTemplateApplication(notification);
            }
        },
        TEMPLATE_APPLICATION_SUCCESSFUL: {
            title: "nms-notification.templateApplicationSuccessful",
            toastrType: "success",
            getLinks: (notification: Notification): Array<NmsToasterLink> => {
                return this.buildDetailsLinkForTemplateApplication(notification);
            }
        },
        SCHEDULER_ADDED: {
            title: "scheduler.toastr.title.scheduler_added",
            toastrType: "success",
            getLinks: (notification: Notification): Array<NmsToasterLink> => {
                return [this.buildNavigateToSchedulerListAction("scheduler-added")];
            }
        },
        SCHEDULER_UPDATED: {
            title: "scheduler.toastr.title.scheduler_updated",
            toastrType: "success",
            getLinks: (notification: Notification): Array<NmsToasterLink> => {
                return [this.buildNavigateToSchedulerListAction("scheduler-updated")];
            }
        },
        SCHEDULER_DELETED: {
            title: "scheduler.toastr.title.scheduler_deleted",
            toastrType: "success",
            getLinks: (notification: Notification): Array<NmsToasterLink> => {
                return [this.buildNavigateToSchedulerListAction("scheduler-deleted")];
            }
        },
        SCHEDULER_EXECUTION_ERROR: {
            title: "scheduler.toastr.title.scheduler_execution_error",
            toastrType: "error"
        }
    };

    constructor(
        private readonly handlebarsService: HandlebarsService,
        private readonly toastrService: NmsToastrService,
        private readonly templateApplicationResultsFormatterService: TemplateApplicationResultsFormatterService,
        @Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
        @Inject(STATE) private readonly $state: any,
        @Inject(NMS_STATES) private readonly nmsStates: any,
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope: any,
        @Inject(GPON_ONU_SERVICE) private readonly gponOnuService: any
    ) {}

    public async showNotification(notification: Notification): Promise<void> {
        if (notification.enabled) {
            const { notificationType } = notification;
            this.handleNotificationDetails(notification);
            const templateEngine = this.handlebarsService.getTemplateEngine();
            const template = await this.fetchTemplate(notificationType);
            const compiledMessage = templateEngine.compile(template)(notification);

            const { title, toastrType, getLinks } = Object.keys(this.NOTIFICATION_CONFIG).includes(notificationType)
                ? this.NOTIFICATION_CONFIG[notificationType]
                : this.getDataToDefaultNotification(notification);
            const notificationLinks = getLinks ? getLinks(notification) : this.handleNotificationLinks(notification);
            /*
             *   Define os valores tapToDismiss=false e closeButton=true.
             *   Isto é necessário para que o componente toastr ignore o clique no
             *   checkbox como trigger do evento de fechar o toastr.
             */
            const [tapToDismiss, closeButton] = [false, true];

            const individualConfiguration: Partial<IndividualConfig> = {
                disableTimeOut: !notification.autoClose,
                timeOut: notification.delayBeforeCloseInSeconds * 1000,
                enableHtml: true,
                onActivateTick: true,
                tapToDismiss,
                closeButton,
                //@ts-ignore
                links: [...notificationLinks],
                notificationType
            };

            this.toastrService[toastrType](
                compiledMessage,
                this.translate.instant(title),
                individualConfiguration
            ).onAction.subscribe((toastr): any => toastr.action());
        }
    }

    private getDataToDefaultNotification(notification: Notification): NotificationConfigurations {
        return notification.parameters as NotificationConfigurations;
    }

    /**
     * Busca pelo modelo handlebars específico de acordo com o tipo de notificação.
     * Caso não seja encontrado será utilizado o modelo default.hbs.
     * Important: O modelo default funciona corretamente se os dados de notificação seguirem o formato estipulado na classe java
     * DefaultNotificationMessageBuilder.
     */
    private async fetchTemplate(notificationType: NotificationType): Promise<string> {
        return fetch(`templates/nms-notification/${notificationType.toLocaleLowerCase()}.hbs`).then(async (response) => {
            if (response.status !== 404) {
                return response.text();
            }
            const defaultResponse = await fetch("templates/nms-notification/default.hbs");
            return defaultResponse.text();
        });
    }

    buildDetailsLinkForTemplateApplication(notification: Notification): Array<NmsToasterLink> {
        const notificationParams = notification.parameters as TemplateApplicationParams;
        const { templateApplicationResults } = notificationParams;
        if (templateApplicationResults) {
            return [
                {
                    id: `details-${notificationParams.equipmentName}`,
                    title: this.translate.instant("toastr.details.link"),
                    action: () => {
                        const title: string = this.translate.instant(
                            "nms-notification.templateApplication.details.modal.title"
                        );
                        const alignLeft = true;
                        const message = this.templateApplicationResultsFormatterService.format(notificationParams);
                        this.$rootScope.showDialog({ title, message, insertScrollOnDetailsMessage: true, alignLeft });
                    }
                }
            ];
        }

        return [];
    }

    private buildNavigateToSchedulerListAction(actionId: string) {
        return {
            id: actionId,
            title: this.translate.instant("scheduler.toastr.action.view.scheduler"),
            action: () => {
                this.$state.go(this.nmsStates.scheduler.list, {}, { inherit: false });
            }
        };
    }

    private handleNotificationLinks(notification: Notification) {
        if (notification.notificationType === "SCHEDULER_WITH_NOT_ALLOWED_DEVICES") {
            return [this.buildNavigateToSchedulerListAction(notification.parameters["titleType"])];
        }

        return [];
    }

    /**
     * Preenche a mensagem da notificação com a tradução, baseado na chave de tradução recebida colocando
     * detalhes adicionais na mensagem caso haja.
     */
    private handleNotificationDetails(notification: Notification): void {
        if (notification.notificationType === "SCHEDULER_WITH_NOT_ALLOWED_DEVICES") {
            const titleType = notification.parameters["titleType"];
            const jobName = notification.parameters["jobName"];
            const author = notification.parameters["author"];
            const actionDesc = this.translate.instant(`scheduler.toastr.action.${titleType}`);

            notification.parameters["desc"] = actionDesc.replace("{0}", jobName).replace("{1}", author);
            notification.parameters["userNoPermission"] = this.translate
                .instant("scheduler.toastr.action.user_without_devices_permissions")
                .replace("%s", author);
            notification.parameters["title"] = `scheduler.toastr.title.${titleType}`;
        } else if (notification.notificationType === NotificationType.SCHEDULER_EXECUTION_ERROR) {
            notification.parameters["message"] = this.translate
            .instant(`toastr.error.code.${notification.parameters["code"]}`)
            .replace("{0}", notification.parameters["details"][1]);
        }
    }
}
