"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.ManageGroupsActionsService
* @description - Service para gerenciamento das ações da tabela de grupos.
* Factory in the nms.management.
*/
var app = angular.module("nms.management");

app.service("ManageGroupsActionsService", ["$rootScope", "$translate", "ManageGroupModalService", "ManageUserModalService",
    function($rootScope, $translate, ManageGroupModalService, ManageUserModalService) {
        const service: any = {};

        service.openAddGroupModal = function(groups, callback) {
            ManageGroupModalService.openAddGroupModal(_.map(groups, "name"), callback);
        };

        service.openEditGroupModal = function(groups, editedGroup, callback) {
            var groupNamesInUseButEditedGroup = _.filter(groups, function(groupName) {
                return groupName !== editedGroup;
            });
            ManageGroupModalService.openEditGroupModal(groupNamesInUseButEditedGroup, editedGroup, callback);
        };

        /*
        * Valida se um grupo foi marcado na tabela.
        * Caso tenha sido selecionado mais de um grupo ou nenhum grupo tenha sido selecionado na tabela, a validação falhará
        * e será apresentado a modal de erro, caso contrário, a tela de edição será aberta.
        */
        service.verifyAndOpenEditGroupModal = function(groups, checkedGroups, callback) {
            if (_.isEmpty(checkedGroups)) {
                $rootScope.showDialog({
                    translateKey: "groupList.tableActions.no.group.selected"
                });
            } else if (checkedGroups.length > 1) {
                $rootScope.showDialog({
                    translateKey: "groupList.tableActions.edit.more.than.one.group.selected"
                });
            } else {
                service.openEditGroupModal(groups, checkedGroups[0].name, callback);
            }
        };

        service.verifyAndOpenRemoveGroupModal = function(groups, groupsForRemove, callback) {
            if (_.isEmpty(groupsForRemove)) {
                $rootScope.showDialog({translateKey: "groupList.tableActions.no.group.selected"});
            } else {
                ManageGroupModalService.openRemoveGroupModal(groups, groupsForRemove, callback);
            }
        };

        return service;
    }
]);
