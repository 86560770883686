"use strict";

angular.module("nms.templates")
.directive("focus", function($timeout) {
    return {
        restrict: "A",
        link: function(scope, elem) {
            $timeout(function() {
                if (elem[0].id == scope.focusElement) {
                    $(elem[0]).focus();
                }
            }, 0);
        }
    };
});
