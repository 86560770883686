/**
 * Serviço para gerenciar a seleção dos nodos na árvore.
 */
var app = angular.module("nms.dynamicDevice");

app.service("TreeSelectionManager", ["$rootScope", "DataPathService",
    function($rootScope, DataPathService) {
        var self = this;
        var listeners: any = {};
        var treeNodes: any = {};
        var selectedNodeHierarchy = [];

        /**
         * Visível para testes.
         * @private
         */
        this.getListeners = function() {
            return listeners;
        };

        /**
         * Visível para testes.
         * @private
         */
        this.getTreeNodes = function() {
            return treeNodes;
        };

        var getOrCreateNode = function(id, parentId) {
            var node = treeNodes[id];
            if (!node) {
                var parentNode = treeNodes[parentId];
                node = {
                    id: id,
                    parent: parentId,
                    expanded: false,
                    filtered: false,
                    selected: false,
                    filterDownwards: false,
                    children: {}
                };
                if (parentNode) {
                    node.filterDownwards = parentNode.filterDownwards;
                    parentNode.children[id] = node;
                }
                treeNodes[id] = node;
            }

            return node;
        };

        /**
         * Notifica os listeners com os dados do nodo:
         * {
         *     open: boolean,
         *     filtered: boolean
         * }
         */
        var notify = function(id) {
            if (listeners[id]) {
                listeners[id](treeNodes[id]);
            }
        };

        this.clear = function() {
            treeNodes = {};
            listeners = {};
        };

        this.clearNodes = function() {
            _.forOwn(treeNodes, function(node, id) {
                self.filterNode(id, node.parent, false, false);
                self.expandNode(id, node.parent, false);
            });
            treeNodes = {};
        };

        this.clearSelectedNode = function() {
            _.forOwn(treeNodes, function(node, id) {
                self.selectNode(id, node.parent, false, false);
            });
            selectedNodeHierarchy = [];
        };

        this.isExpanded = function(id) {
            return treeNodes[id] && treeNodes[id].expanded;
        };

        this.isFiltered = function(id) {
            return treeNodes[id] && treeNodes[id].filtered;
        };

        this.isFilterDownwards = function(id) {
            return treeNodes[id] && treeNodes[id].filterDownwards;
        };

        this.isSelected = function(id) {
            return treeNodes[id] && treeNodes[id].selected;
        };

        this.register = function(id, parentId, setFunction) {
            if (angular.isDefined(id)) {
                listeners[id] = setFunction;
                getOrCreateNode(id, parentId);
                notify(id);
            }
        };

        this.unregister = function(id) {
            if (angular.isDefined(id)) {
                delete listeners[id];

                if (treeNodes[id]) {
                    var parentId = treeNodes[id].parent;
                    delete treeNodes[id];

                    if (parentId && treeNodes[parentId]) {
                        delete treeNodes[parentId].children[id];
                    }
                }
            }
        };

        this.expandNode = function(id, parentId, expand) {
            getOrCreateNode(id, parentId).expanded = expand;
            notify(id);
        };

        this.expandSelectedNode = function() {
            var cloneSelectedNodeHierarchy = selectedNodeHierarchy.slice();
            self.clearSelectedNode();
            _.forEach(cloneSelectedNodeHierarchy, function(node) {
                self.selectNode(node.id, node.parent, node.selected, node.selectedUpwards);
                if (!node.selected) {
                    self.expandNode(node.id, node.parent, true);
                }

                // Caso o pai seja do tipo filterDownwards, propaga para todos os filhos.
                // Isso garante que todos os filhos sejam exibidos quando um nó selecionado esteja dentro de um nó filtrado.
                if (self.isFilterDownwards(node.id)) {
                    var children = _.filter(_.keysIn(listeners), function(id) {
                        return id !== node.id && _.contains(id, node.id);
                    });
                    _.forEach(children, function(id) {
                        self.filterNode(id, null, false, true);
                    });
                }
            });
        };

        this.getSelectedNodeHierarchy = function() {
            return selectedNodeHierarchy;
        };

        this.filterNode = function(id, parentId, filter, filterDownwards) {
            var node = getOrCreateNode(id, parentId);
            node.filtered = filter;
            node.filterDownwards = filterDownwards;
            notify(id);
        };

        this.selectNode = function(id, parentId, selected, selectedUpwards) {
            var node = getOrCreateNode(id, parentId);
            node.selected = selected;
            node.selectedUpwards = selectedUpwards;

            if (selected || selectedUpwards) {
                selectedNodeHierarchy.push(node);
            }

            notify(id);
        };

        /**
         * It inverts the selection value to mark the new clicked node as selected
         *
         * @param {string} id Path to node that will be changed
         */
        this.toggleExpanded = function(id, parentId) {
            var node = getOrCreateNode(id, parentId);

            self.expandNode(id, parentId, !node.expanded);
        };


        /**
         * Verify if there are any children registered.
         *
         * @param {String} parentPathWithKeys complete parent's path
         * @return {boolean} true if there are any children registered, false otherwise
         */
        var areAnyChildrenVisibleOnTree = function(parentPathWithKeys) {
            return !_.isEmpty(treeNodes[parentPathWithKeys].children);
        };

        /**
         * Verify if an expanded node has visible children.
         *
         * @param {object} node schemaNode
         * @param {String} pathKeys path keys
         * @return {boolean} true if there are any children registered, false otherwise
         */
        this.hasVisibleChildrenOnExpandedNode = function(node, pathKeys) {
            var currentPathWithKeys = DataPathService.getPathWithKeys(node.paths.dataJsonPath, pathKeys);

            if (self.isExpanded(currentPathWithKeys)) {
                return areAnyChildrenVisibleOnTree(currentPathWithKeys);
            }

            return true;
        };
    }
]);
