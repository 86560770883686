import { CpeService } from "./cpe.service";
import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Cpe } from "./cpe-model";
import { ANGULARJS_ROOTSCOPE, ANGULARJS_LOCATION } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { AutoUpdaterDataResult } from "@nms-ng2/app/shared/components/elements/nms-auto-updater/nms-auto-updater.component";
import { RefreshIntervalService } from "@nms-ng2/app/shared/services/refresh-interval/refresh-interval.service";
import { Observable } from "rxjs";
import { CpesTableComponent, CpeUrlFilterParameter } from "./table/cpes-table.component";

/**
 * Classe responsável por exibir a listagem de CPEs
 */
@Component({
  selector: "cpes",
  templateUrl: "./cpes.component.html",
  styleUrls: ["./cpes.component.scss"],
})
export class CpesComponent implements OnInit {
  autoUpdateInterval: number;
  cpesProvider;
  queryParameters: CpeUrlFilterParameter;

  @ViewChild(CpesTableComponent)
  cpesTable: CpesTableComponent;

  constructor(
    @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
    @Inject(ANGULARJS_LOCATION) private location: any,
    private cpeService: CpeService,
    private refreshIntervalService: RefreshIntervalService
  ) {
    this.refreshIntervalService.getRefreshInterval((interval) => {
      this.autoUpdateInterval = interval;
    });
    this.cpesProvider = this.retrieveCpes;
    this.queryParameters = this.location.search();
  }

  ngOnInit() {
    this.retrieveCpes = this.retrieveCpes.bind(this);
  }

  /**
   * Busca os dados de cpes.
   */
  retrieveCpes(): Observable<Array<Cpe>> {
    return this.cpeService.getAllCpes();
  }

  /**
   * Atualiza a tabela com base nos dados recebidos.
   */
  refresh(dataResult: AutoUpdaterDataResult): void {
    if (dataResult.error) {
      this.showServerErrorMessage();
    } else {
      this.cpesTable.updateCpes(dataResult.data);
    }
    this.cpesTable.tableBodyMessage = null;
  }

  private showServerErrorMessage() {
    this.$rootScope.showDialog({ translateKey: "http.error.serverError" });
  }
}
