"use strict";

/**
* @ngdoc directive
* @name components.ui.nmsWarning
* @description Diretiva para permitir apresentação warnings do sistema.
* Formato esperado pela variável "settings":
{
    show:                                               (boolean)   Indica se o componente de warning deverá ou não ser mostrado
                                                                    na tela.
    header:                                             (string)    Chave de tradução para a primeira mensagem a ser mostrada no
                                                                    componente de warning.
    warnings:                                           (array)     Lista de warnings contendo as regras para apresentação da
                                                                    warning na tela.
        show:                                           (boolean)   Indica se uma warning específica deve ser mostrada no
                                                                    componente.
        externalLink:   (Opcional)                      (object)    Contém informações para acesso a links externos caso
                                                                    necessário.
            url:                                        (string)    Link para acesso a recursos externos.
            translateKey:                               (string)    Chave para tradução do link.
        internalLink:         (Opcional)                (object)    Contém informações para acesso a uma feature específica do
                                                                    sistema análogo aos links contidos em ui-router usados na
                                                                    aplicação.
            url:                                        (string)    Link para acesso ao recurso.
            translateKey:                               (string)    Chave para tradução do link.
        generalMessage: (Opcional)                      (object)    Mensagem complementar para uma warning. Este mensagem não
                                                                    contém links para acesso aos recursos.
            location: (Opcional)                        (string)    Posição na qual a mensagem será apresentada podendo assumir os
                                                                    valores 'top' e 'bottom'. Caso este parâmetro não seja
                                                                    definido ou diferente de 'top' e 'bottom', será usado o valor
                                                                    'top' para a mensagem, ou seja, a mensagem será apresentada
                                                                    acima dos links, de externalLink e internalLink caso existam.
            translateKey:                               (string)    Chave para tradução da mensagem.
        needsAdministratorPermission: (Opcional)        (string)    Define se a mensagem será mostrada apenas para usuários com
                                                                    permissão administrator. Caso este atributo não exista, será
                                                                    levado em consideração que o usuário precisa ter a permissão
                                                                    de administrator para que a mensagem seja apresentada.

     TODO - A fim de evoluir o componente de warning, avaliar se a implementação contida em
     https://medium.com/opinionated-angularjs/angular-model-objects-with-javascript-classes-2e6a067c73bc traria benefícios
     significativos. Análise/implementação não feita devido a tempo excedido para a tarefa.
*/
var app = angular.module("nms");

app.directive("nmsWarning", [function() {
    return {
        restrict: "E",
        templateUrl: "templates/components/ui/nms-warning/nms-warning.html",
        controller: "NmsWarningController",
        scope: {
            settings: "="
        }
    };
}]);
