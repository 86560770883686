var app = angular.module("nms.templates");

/**
* @ngdoc directive
* @name nms.templates.directive:optionsExtractionTest
* @description Diretiva para testar extração de opções.
*/
app.directive("optionsExtractionTest", [
    function() {
        return {
            scope: {
                tabKey: "@",
                extractionParams: "=",
                hasExtractionParams: "="
            },
            restrict: "E",
            templateUrl: "templates/features/template/template/modals/variable/options-extraction-test.html",
            controller: "OptionsExtractionTestController"
        };
    }
]);
