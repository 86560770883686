import { AfterViewInit, Component, ContentChild, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ANGULARJS_ROOTSCOPE, ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { delay } from "rxjs/operators";
import { AcsGlobalCredentials } from "./acs-credentials-model";
import { AcsCredentialsService } from "./acs-credentials.service";
import { AcsServiceStatus } from "./acs-service-status";
import { AcsSpecificalCredentials } from "./acs-specific-credentials-model";
import { NmsInputNumberComponent } from "@nms-angular-toolkit/nms-input-number";

/**
 * Classe responsável por exibir as credenciais do ACS.
 */
@Component({
    selector: "acs-credentials",
    templateUrl: "./acs-credentials.component.html",
    styleUrls: ["./acs-credentials.component.css"]
})
export class AcsCredentialsComponent implements OnInit {
    @Input() isSpecificalCredentials: boolean;
    @Input() isMultipleSelection: boolean;
    @Input() cpesSelected: Array<any>;
    @ViewChild("globalCpeTimeoutComponent")
    globalCpeTimeoutComponent: NmsInputNumberComponent;
    @ViewChild("specificCpeTimeoutComponent")
    specificCpeTimeoutComponent: NmsInputNumberComponent;

    acsServiceUrl: string;
    acsServiceStatus: AcsServiceStatus;
    credentialsForm: FormGroup;
    acsCredentials: AcsGlobalCredentials;
    acsSpecificalCredentials: AcsSpecificalCredentials;
    submitted: boolean;
    optionSelected: any;
    isLanguageEnglish: boolean = false;
    hasAcsSpecificalCredentials: boolean = false;
    isAcsOnline: boolean = false;
    acsTimeoutLabel: string;
    readonly GLOBAL: string = "global";
    readonly SPECIFIC: string = "specific";
    readonly RANGE_1_180 = "1-180s";
    readonly RANGE_1_180_PATTERN = "^([1-9]|[1-9][0-9]|1[0-7][0-9]|180)$";
    cpeTimeout: number = 30;
    cpeConnectionRequestTimeout: number = 30;

    constructor(
        @Inject(ANGULARJS_TRANSLATE) private translate: any,
        private formBuilder: FormBuilder,
        private acsCredentialService: AcsCredentialsService,
        private toastr: NmsToastrService,
        @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
        private window: Window
    ) {
        this.acsServiceUrl = "";
        this.acsCredentials = new AcsGlobalCredentials();
        this.acsSpecificalCredentials = new AcsSpecificalCredentials();
        this.submitted = false;
        this.isSpecificalCredentials = false;
        this.isMultipleSelection = false;
    }

    ngOnInit(): void {
        this.optionSelected = this.GLOBAL;
        this.acsTimeoutLabel = this.translate.instant("credentials.configuration.timeout");
        this.createForm();
        this.getAcsSpecificalCredentials();
        this.getAcsCredentials();
        this.updateAcsServiceStatus();
    }

    createForm(): void {
        this.credentialsForm = this.formBuilder.group({
            acsUser: [null, [Validators.maxLength(256)]],
            acsPassword: [null, [Validators.maxLength(256)]],
            cpeConnectionRequestUser: [null, [Validators.maxLength(256)]],
            cpeConnectionRequestPassword: [null, [Validators.maxLength(256)]],
            cpeUsername: [null, [Validators.maxLength(256)]],
            cpePassword: [null, [Validators.maxLength(256)]]
        });
    }

    /**
     * Atualiza o status referente ao serviço do ACS.
     * É adicionado um delay para melhorar a experiência do usuário. Pois como a resposta é muito rápida,
     * sem o delay, o botão pareceria não fazer nada quando o status não era alterado.
     */
    updateAcsServiceStatus(): void {
        const fakeDelayInMs = 500;
        this.acsServiceStatus = AcsServiceStatus.REFRESHING;

        this.acsCredentialService
            .isAcsServiceOnline()
            .pipe(delay(fakeDelayInMs))
            .subscribe(
                (response) => {
                    this.acsServiceStatus = response ? AcsServiceStatus.ONLINE : AcsServiceStatus.OFFLINE;
                    this.isAcsOnline = this.acsServiceStatus == AcsServiceStatus.ONLINE;
                },
                (error) => {
                    this.acsServiceStatus = error.status == 401 ? AcsServiceStatus.UNAUTHORIZED : AcsServiceStatus.OFFLINE;
                    this.isAcsOnline = false;
                }
            );
    }

    getAcsCredentials(): void {
        this.acsCredentialService.getAcsConnectionSettings().subscribe((acsCredentials: AcsGlobalCredentials) => {
            this.acsCredentials = acsCredentials;
            this.updateAcsCredentials();
        });
    }

    getAcsSpecificalCredentials(): void {
        if (this.isSpecificalCredentials && !this.isMultipleSelection) {
            let { serialNumber } = this.cpesSelected[0];

            this.acsCredentialService
                .getCpeCredentialBySerialNumber(serialNumber)
                .subscribe((acsSpecificalCredentials: AcsSpecificalCredentials) => {
                    let { username = "", password = "", timeout = 30 } = acsSpecificalCredentials || {};
                    this.acsSpecificalCredentials = { username, password, timeout };
                    this.hasAcsSpecificalCredentials = !!acsSpecificalCredentials;

                    if (this.hasAcsSpecificalCredentials) {
                        this.optionSelected = this.SPECIFIC;
                    }

                    this.updateAcsSpecificalCredentials();
                    this.updateFieldsState();
                });
        }
    }

    updateAcsSpecificalCredentials(): void {
        const cpeCredential = {
            cpeUsername: this.acsSpecificalCredentials.username,
            cpePassword: this.acsSpecificalCredentials.password
        };
        this.cpeTimeout = this.acsSpecificalCredentials.timeout;

        this.credentialsForm.patchValue(cpeCredential);
    }

    updateAcsCredentials(): void {
        this.cpeConnectionRequestTimeout = this.acsCredentials.cpeConnectionRequestTimeout || 30;
        this.credentialsForm.patchValue(this.acsCredentials);
    }

    updateAcsSpecificalCpeCredentialsFromGlobal(): void {
        const cpeCredential = {
            cpeUsername: this.acsCredentials.cpeConnectionRequestUser,
            cpePassword: this.acsCredentials.cpeConnectionRequestPassword
        };

        this.cpeTimeout = this.acsCredentials.cpeConnectionRequestTimeout;

        this.credentialsForm.patchValue(cpeCredential);
        this.updateFieldsState();
    }

    updateFieldsState(): void {
        if (this.isSpecificalCredentials) {
            this.credentialsForm.get("acsUser").disable();
            this.credentialsForm.get("acsPassword").disable();

            if (this.isGlobalSelected()) {
                this.credentialsForm.get("cpeConnectionRequestUser").disable();
                this.credentialsForm.get("cpeConnectionRequestPassword").disable();
                this.credentialsForm.get("cpeUsername").disable();
                this.credentialsForm.get("cpePassword").disable();
            } else {
                this.credentialsForm.get("cpeConnectionRequestUser").enable();
                this.credentialsForm.get("cpeConnectionRequestPassword").enable();
                this.credentialsForm.get("cpeUsername").enable();
                this.credentialsForm.get("cpePassword").enable();
            }
        }
    }

    /**
     * Chama o serviço responsável por inserir os valores das credenciais na base de dados.
     * Verifica se o componente é a modal referente ao(s) CPE(s) selecionado(s), ou seja (specificalCredencials = true)
     * Caso seja verifica se a opção selecionada é de configurações globais
     * caso afirmativo: exclui todas as configurações específicas
     * caso tenha selecionado configurações específicas: salva as configurações na base de dados
     * Caso o componente não referente ao de configurações globais, ou seja (specificalCredencials = false)
     * salva os dados referentes as configurações globais
     */
    onSubmit(): void {
        this.submitted = true;
        if (this.isFormValid(this.credentialsForm) && this.validateConfiguration()) {
            if (this.isSpecificalCredentials) {
                this.updateModalCredentials();
            } else {
                this.saveAcsGlobalConfiguration();
            }
        }
    }

    saveAcsGlobalConfiguration(): void {
        this.acsCredentials = Object.assign(this.acsCredentials, this.credentialsForm.value);
        this.acsCredentials.cpeConnectionRequestTimeout = this.globalCpeTimeoutComponent.model;
        this.acsCredentialService.saveCredentials(this.acsCredentials);
    }

    /**
     * Ao alterar as credenciais de específicas para globais (mais precisamente a deleção das cred. específicas)
     * será tratada no backend para que a auditoria possa ser realizada corretamente principalmente
     * no caso de múltiplos registros.
     */
    updateModalCredentials(): void {
        let credentialsForm: AcsSpecificalCredentials = {
            username: this.credentialsForm.value.cpeUsername,
            password: this.credentialsForm.value.cpePassword,
            timeout: this.specificCpeTimeoutComponent.model
        };
        this.acsSpecificalCredentials = Object.assign(this.acsSpecificalCredentials, credentialsForm);
        this.acsCredentialService.updateCredentials(credentialsForm, this.cpesSelected, this.isGlobalSelected());
    }

    /**
     * Valida as configurações de credenciais de acordo com as seguintes condições:
     * - Pode ser inserido usuário e senha vazias
     * - Ao inserir um usuário é necessário informar a senha
     * - Ao inserir uma senha é necessário informar um usuário
     * - As validações acima correspondem tanto para a conexão CPE - ACS quanto para ACS - CPE.
     * - Caso uma das opções seja inválida uma mensagem será exibida.
     */
    validateConfiguration(): boolean {
        let isValid: boolean = false;
        const acsUser: string = this.credentialsForm.value.acsUser;
        const acsPassword: string = this.credentialsForm.value.acsPassword;
        const cpeConnectionRequestUser: string = this.credentialsForm.value.cpeConnectionRequestUser;
        const cpeConnectionRequestPassword: string = this.credentialsForm.value.cpeConnectionRequestPassword;
        const cpeUsername: string = this.credentialsForm.value.cpeUsername;
        const cpePassword: string = this.credentialsForm.value.cpePassword;
        const cpeTimeout: number = this.specificCpeTimeoutComponent.model;
        const cpeConnectionRequestTimeout: number = this.globalCpeTimeoutComponent.model;

        if (acsUser && !acsPassword) {
            this.toastr.error(this.translate.instant("credentials.acsUserOrPassword.empty"));
        } else if (!acsUser && acsPassword) {
            this.toastr.error(this.translate.instant("credentials.acsUserOrPassword.empty"));
        } else if (cpeConnectionRequestUser && !cpeConnectionRequestPassword) {
            this.toastr.error(this.translate.instant("credentials.cpeConnectionRequestUserOrPassword.empty"));
        } else if (!cpeConnectionRequestUser && cpeConnectionRequestPassword) {
            this.toastr.error(this.translate.instant("credentials.cpeConnectionRequestUserOrPassword.empty"));
        } else if (cpeUsername && !cpePassword) {
            this.toastr.error(this.translate.instant("credentials.cpeConnectionRequestUserOrPassword.empty"));
        } else if (cpePassword && !cpeUsername) {
            this.toastr.error(this.translate.instant("credentials.cpeConnectionRequestUserOrPassword.empty"));
        } else if (!this.isSpecificalCredentials && !cpeConnectionRequestTimeout) {
            this.toastr.error(this.translate.instant("credentials.cpeConnectionRequestTimeout.empty"));
        } else if (this.isSpecificalCredentials && !cpeTimeout) {
            this.toastr.error(this.translate.instant("credentials.cpeConnectionRequestTimeout.empty"));
        } else {
            isValid = true;
        }
        return isValid;
    }

    cancel(): void {
        // Parece estranho mas em alguns casos de callback, o this se 'perde' e fica com o valor
        // undefined, nesses casos inserimos o seu valor em uma variável local, e usamos a variável
        // local ao invés de this.
        const that = this;
        if (this.isSpecificalCredentials) {
            that.acsCredentialService.cancelAcsCredentialsConfig();
        } else {
            this.$rootScope
                .showDialog({
                    message: this.translate.instant("credentials.connection.cancel"),
                    isConfirm: true
                })
                .then(function () {
                    that.window.history.back();
                });
        }
    }

    /**
     * Maneira reduzida de pegar o valor de credentialsForm.control
     * para ser usado na validação do input e exibir uma mensagem de inconsistência logo
     * abaixo do campo.
     * Com isso podemos usar no html a seguinte maneira: form.cpeConnectionRequestUser.errors
     * Caso contrário teria de ser credentialsForm.controls.cpeConnectionRequestUser.errors
     * Portanto se houver algum erro de validação referente ao tamanho máximo do campo a borda ficará
     * em vermelho e uma mensagem logo abaixo será exibida.
     */
    get form(): any {
        return this.credentialsForm.controls;
    }

    isSpecificSelected(): boolean {
        return this.optionSelected == this.SPECIFIC;
    }

    isGlobalSelected(): boolean {
        return this.optionSelected == this.GLOBAL;
    }

    private isFormValid(form: FormGroup) {
        if (form.invalid) {
            return false;
        }

        if (this.globalCpeTimeoutComponent.inputModel.invalid || this.specificCpeTimeoutComponent.inputModel.invalid) {
            this.toastr.error(this.translate.instant("credentials.cpeConnectionRequestTimeout.invalidRange"));
            return false;
        }

        return true;
    }
}
