"use strict";

/**
 * @ngdoc controller
 * @name nms.components.autoUpdater:AutoUpdaterCountdownController
 * @description
 *
 * # AutoUpdaterCountdownController
 *
 * Responsável por controlar a contagem regressiva do componente de atualização automática.
 *
 * OBS: É usado $scope.$broadcast() porque se trata de repassar a informação para o timer, no escopo filho.
 *      Não confundir com os eventos em AutoUpdaterCounterController.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.controller("AutoUpdaterCountdownController", ["$scope", "AutoUpdaterService",
    function($scope, AutoUpdaterService) {
        var unregisterStopCountdown = $scope.$on("stopCountdown", function() {
            $scope.$broadcast("timer-reset");
            $scope.$broadcast("timer-stop");
        });

        var unregisterStartCountdown = $scope.$on("startCountdown", function() {
            $scope.$broadcast("timer-reset");
            $scope.$broadcast("timer-start");
        });

        var unregisterTimerStopped = $scope.$on("timer-stopped", function(event, data) {
            if (data.minutes === 0 && data.seconds === 0) {
                AutoUpdaterService.executeUpdateFunction()
            }
        });

        $scope.$on("$destroy", function() {
            unregisterStopCountdown();
            unregisterStartCountdown();
            unregisterTimerStopped();
        });
    }
]);
