/**
 * Restrict the minimum number of items allowed on a list or leaflist.
 *
 * @author icaro.damiani
 */
var app = angular.module("nms.dynamicDevice");

app.directive("minElementsRestriction", ["$filter",
    function($filter) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                ngModel.$validators.minElementsValidator = function(modelValue) {
                    if (angular.isDefined(_.get(scope, "leaf.shouldEdit")) && !scope.leaf.shouldEdit) {
                        return true;
                    }
                    var minElements = $filter("getMinElements")(scope.list);

                    if (angular.isDefined(modelValue) && angular.isDefined(minElements)) {
                        var modelLength = angular.isArray(modelValue) ? modelValue.length : modelValue;

                        return modelLength >= minElements;
                    }

                    return true;
                };
            }
        };
    }
]);
