import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Observable } from "rxjs";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";

/**
 * Classe responsável por realizar e efetuar requisições http com o servidor referentes às credenciais globais.
 */
@Injectable({
  providedIn: "root",
})
export class GlobalManagementProtocolsService {

  readonly baseUrl: string = "/device-management-web/global-credentials";

  constructor(private http: HttpClient,
              private toastr: NmsToastrService,
              @Inject(ANGULARJS_TRANSLATE) private translate) {}

  getManagementProtocols(): Observable<any> {
      return this.http.get(this.baseUrl);
  };

  saveGlobalCredentials(globalCredentials: any) {
      this.http.post(this.baseUrl, globalCredentials).subscribe(() => {
          this.toastr.success(this.translate.instant("global.managementProtocols.save.successfully"));
      }, responseError => {
          console.error(`Could not save global management protocols. Error: ${responseError}`);
          this.toastr.error(this.translate.instant("global.managementProtocols.save.error"));
      });
  }
}
