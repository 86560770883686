"use strict";

import {
    CpeFieldValues,
    DeviceFieldValues,
    EquipmentSelectionType
} from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { TemplateInstanceService } from "../template-instance-service";

/**
 * @ngdoc function
 * @name nms.templates.controller:AddtemplatemodalCtrl
 * @description
 * # AddtemplatemodalCtrl
 * Controller of the nms.templates
 */
var app = angular.module("nms.templates");

app.controller("AddTemplateModalCtrl", [
    "$scope",
    "$rootScope",
    "$translate",
    "TemplateService",
    "TemplateInstanceService",
    "TemplateInstanceValidatorService",
    "ValidatorService",
    "WorkflowService",
    "RuleOptionsService",
    "equipment",
    "templateInstanceResolvedInformation",
    "$filter",
    "ServerPaginationService",
    "PRESENTATION_MODE",
    "UserPreferencesService",
    "deviceModelRestrictionTypes",
    "templateType",
    "TemplateInstanceUtils",
    "EquipmentService",
    function (
        $scope,
        $rootScope,
        $translate,
        TemplateService,
        TemplateInstanceService: TemplateInstanceService,
        TemplateInstanceValidatorService,
        ValidatorService,
        WorkflowService,
        RuleOptionsService,
        equipment,
        templateInstanceResolvedInformation,
        $filter,
        ServerPaginationService,
        PRESENTATION_MODE,
        UserPreferencesService,
        deviceModelRestrictionTypes,
        templateType,
        TemplateInstanceUtils,
        EquipmentService
    ) {
        var prefix = "modalTemplate";
        var columnType = "type";

        $scope.templates = [];
        $scope.search = {};
        $scope.equipment = _.toArray(equipment);
        $scope.checkedTemplates = [];
        $scope.isEdit = templateInstanceResolvedInformation.isEdit;
        $scope.originalTemplateAssociations = templateInstanceResolvedInformation.originalTemplateAssociations;
        $scope.hasSearch = false;
        $scope.service = templateInstanceResolvedInformation.service;
        $scope.presentationMode = templateInstanceResolvedInformation.presentationMode;
        $scope.presentationModeDefault = PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;
        $scope.deviceModelRestrictionTypes = deviceModelRestrictionTypes;
        $scope.templateType = templateType;
        $scope.selectionEquipmentFilter = templateInstanceResolvedInformation.selectionEquipmentFilter;

        // Init data
        $scope.pageDetails = ServerPaginationService.getPageDetails();
        $scope.filters = ServerPaginationService.getFilters({
            column: "name",
            direction: "ASC",
            searchName: "",
            selectedEquipments: [],
            showOnlyAllowedTemplates: true,
            templateType: null
        });

        $scope.searchTemplate = function () {
            if ($scope.search.name) {
                $scope.search.name = $scope.search.name.replace(/[\.']/g, "");
            }
            $scope.filters.searchName = $scope.search.name;
            $scope.filters.pageNumber = 0; // Reset page number
            $scope.filters.searchColumn = $scope.templateType ? columnType : "";
            $scope.filters.searchTerm = $scope.templateType || "";
            $scope.hasSearch = true;
            $scope.setPageFiltersSession();
            $scope.loadPage();
        };

        $scope.orderByTable = function () {
            $scope.filters.direction = $scope.filters.direction === "ASC" ? "DESC" : "ASC";
            $scope.loadPage();
        };

        $scope.loadPage = function () {
            if (ValidatorService.isFilterValid($scope.filters)) {
                TemplateService.getPaginated($scope.filters).then($scope.successGetPaginated);
            }
        };

        $scope.getEquipmentNamesLabel = function () {
            var isMultipleEquipment = $scope.equipment.length > 1;
            var keys = {
                multiple: {
                    deviceKey: "modals.search.template.equipment.devices",
                    cpeKey: "modals.search.template.equipment.cpes"
                },
                singular: {
                    deviceKey: "modals.search.template.equipment.device",
                    cpeKey: "modals.search.template.equipment.cpe"
                }
            };

            return isMultipleEquipment
                ? TemplateInstanceUtils.getEquipmentLabelKeyByTemplateType($scope.templateType, keys.multiple)
                : TemplateInstanceUtils.getEquipmentLabelKeyByTemplateType($scope.templateType, keys.singular);
        };

        $scope.getEquipmentsNames = function () {
            var equipmentsDetails = _.map($scope.equipment, "equipmentDetails");
            return TemplateInstanceService.getJoinedProperties(equipmentsDetails, "name");
        };

        $scope.getEquipmentModels = function () {
            var equipmentsDetails = _.map($scope.equipment, "equipmentDetails");
            return TemplateInstanceService.getJoinedProperties(equipmentsDetails, "model");
        };

        $scope.getTemplateRules = function (template) {
            return [
                {
                    hasRuleOptions: template.restrictTemplate,
                    rulesMatchingMode: template.equipmentRestrictionFilter.rulesMatchingMode,
                    ruleOptions: template.equipmentRestrictionFilter.equipmentRuleOption
                }
            ];
        };

        $scope.formatRuleOptions = function (template, type: "MODEL" | "FIRMARE") {
            const rules = $scope.getTemplateRules(template);
            let equipmentField: DeviceFieldValues | CpeFieldValues;

            const isCli = TemplateInstanceUtils.isCliTemplateApplication(template.type);

            if (isCli) {
                equipmentField = type === "MODEL" ? DeviceFieldValues.MODEL : DeviceFieldValues.FIRMWARE;
            } else {
                equipmentField = type === "MODEL" ? CpeFieldValues.PRODUCT_CLASS : CpeFieldValues.SW_VERSION;
            }

            return RuleOptionsService.format(rules, deviceModelRestrictionTypes, equipmentField);
        };

        $scope.getFirmwares = function () {
            var equipmentsDetails = _.map($scope.equipment, "equipmentDetails");
            let equipmentVersion = TemplateInstanceUtils.isCliTemplateApplication($scope.templateType)
                ? "firmwareVersion"
                : "softwareVersion";
            return TemplateInstanceService.getJoinedProperties(equipmentsDetails, equipmentVersion);
        };

        $scope.selectAndUnselectTemplate = function (event, template, index) {
            $scope.prevSelectedIndex = angular.copy($scope.currentIndex);
            $scope.currentIndex = index;

            if (event.shiftKey && !_.isUndefined($scope.prevSelectedIndex)) {
                var index = $scope.currentIndex < $scope.prevSelectedIndex ? $scope.currentIndex : $scope.prevSelectedIndex;
                var finalIndex = $scope.currentIndex > $scope.prevSelectedIndex ? $scope.currentIndex : $scope.prevSelectedIndex;
                TemplateInstanceService.selectMultiple(index, finalIndex, $scope.checkedTemplates, $scope.templates);
            } else if (!event.shiftKey) {
                TemplateService.selectAndUnselectTemplate(template, $scope.checkedTemplates);
            }
        };

        $scope.showAlertErrorAddTemplate = function (resourceId, templatesNames) {
            $scope.fails = templatesNames; // set the $scope.fails to be used in removeInvalidTemplatesSelected later
            $rootScope
                .showDialog({
                    translateKey: resourceId,
                    params: templatesNames,
                    maxChars: 37
                })
                .closePromise.then($scope.removeInvalidTemplatesSelected);
        };

        $scope.addTemplateDbClick = function (template) {
            $scope.checkedTemplates = [template];
            $scope.validateSelectedTemplates();
        };

        $scope.validateSelectedTemplates = function () {
            if (!$scope.checkedTemplates || $scope.checkedTemplates.length == 0) {
                $rootScope.showDialog({
                    translateKey: "modals.error.template.noCheckedTemplates"
                });
                return;
            }

            if ($scope.selectionEquipmentFilter !== EquipmentSelectionType.SPECIFIC) {
                const templateNameField = "name";
                if (!TemplateInstanceValidatorService
                    .validateTemplatesWithLocalVars($scope.checkedTemplates, $scope.templateType, templateNameField)) {
                        return;
                }
            }

            var fails = [];

            if ($scope.isEdit) {
                fails = TemplateInstanceValidatorService.validateAddTemplatesInAssociations(
                    $scope.checkedTemplates, $scope.originalTemplateAssociations);

                if (fails.length > 0) {
                    const templatesNames = fails.map(template => template.name);
                    $scope.showAlertErrorAddTemplate("modals.error.template.changeTemplates", templatesNames);
                    return;
                }
            }

            const templateIds = TemplateInstanceService.getTemplateIdsFromDevices($scope.equipment);
            fails = TemplateInstanceValidatorService.validateEqualTemplates(templateIds, $scope.checkedTemplates,
                $scope.equipment);

            if (fails.length > 0) {
                const templatesNames = fails.map(template => template.name);
                $scope.showAlertErrorAddTemplate("modals.error.template.repeatedTemplates", templatesNames);
                return;
            }

            if ($scope.presentationMode.value !== PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE) {
                TemplateInstanceService.validateTemplateRestriction($scope.checkedTemplates, $scope.equipment)
                    .then(response => {
                        if (response.length > 0) {
                            $scope.showAlertErrorAddTemplate("modals.error.template.invalidTemplates", response);
                            return;
                        }

                        $scope.loadTemplatesSelected();
                    });
            } else {
                $scope.loadTemplatesSelected();
            }

        };

        $scope.loadTemplatesSelected = function() {
            var templatesSelected = [];
            $scope.checkedTemplates.forEach(function (template) {
                templatesSelected.push({
                    templateId: template.id,
                    templateName: template.name,
                    templateDescription: template.description,
                    restrictTemplate: template.restrictTemplate,
                    joinRangeRules: $filter("firmwareRangeRule")(
                        template.equipmentRestrictionFilter.equipmentRuleOption,
                    ),
                    equipmentRestrictionFilter: template.equipmentRestrictionFilter,
                    applicationNameManual: template.applicationNameManual,
                    applicationNameVarSuggestion: template.applicationNameVarSuggestion,
                    variables: template.variables
                });
            });

            $scope.confirm(templatesSelected);
        };

        $scope.removeInvalidTemplatesSelected = function () {
            TemplateService.removeInvalidTemplatesSelected($scope.fails, $scope.checkedTemplates);
        };

        $scope.setPageFiltersSession = function () {
            $scope.filters = UserPreferencesService.savePageFilterValues($scope.filters, prefix);
        };

        $scope.successGetPaginated = function (response) {
            $scope.templates = response.pageElements;
            $scope.pageDetails = ServerPaginationService.updatePageDetails(
                $scope.pageDetails,
                response.totalElements,
                response.pageNumber,
                response.pageSize
            );
            $scope.filters = ServerPaginationService.updateFilters($scope.filters, response.pageSize, response.pageNumber);

            restoreSelectedTemplates();
        };

        /*
         * Atualiza a lista de templates selecionados com o objeto recebido pela requisição.
         * Como a paginação é realizada no back-end a referência dos objetos da página não é a mesma de quando ele foi
         * selecionado, então é necessário atualizar a lista de selecionados com o novo objeto. Isso é necessário por que
         * o check da tabela trabalha por referência e não pelos dados armazenados no objeto.
         */
        var restoreSelectedTemplates = function () {
            _.forEach($scope.checkedTemplates, function (checkedTemplate, index) {
                var template = _.find($scope.templates, "id", checkedTemplate.id);
                if (template) {
                    $scope.checkedTemplates[index] = template;
                }
            });
        };

        $scope.init = function () {
            $scope.filters = UserPreferencesService.loadPageFilterValues($scope.filters, prefix);
            $scope.filters.searchName = $scope.filters.searchName ? $scope.filters.searchName : "";
            $scope.search.name = $scope.filters.searchName;
            $scope.filters.searchColumn = $scope.templateType ? columnType : "";
            $scope.filters.searchTerm = $scope.templateType || "";
            $scope.filters.pageNumber = 0; // Reset page number
            $scope.filters.selectedEquipments = $scope.equipment;
            $scope.hasSearch = true;
            $scope.loadPage();
        };

        $scope.init();
    }
]);
