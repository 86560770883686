import { Component, Inject, Input, OnInit } from "@angular/core";
import { ANGULARJS_ROOTSCOPE, ANGULARJS_TRANSLATE, USER_PREFERENCES_SERVICE }
    from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { CwmpParameterPathModel } from "./cwmp-path-parameter-model";
import { Subject } from "rxjs";
import { NmsSelectValue, NmsSelectModel } from "@nms-ng2/app/shared/components/elements/nms-select/nms-select.component";

/** paths pré-definidos que serão visualizados no componente de seleção */
const PATHS_DEFAULT = [
    { value: "<vazio> (topo da hierarquia)", isRemovable: false },
    { value: "InternetGatewayDevice.", isRemovable: false },
    { value: "InternetGatewayDevice.DeviceInfo.", isRemovable: true },
    { value: "InternetGatewayDevice.LANDevice.", isRemovable: true },
    { value: "InternetGatewayDevice.ManagementServer.", isRemovable: true },
    { value: "InternetGatewayDevice.Services.", isRemovable: true },
    { value: "InternetGatewayDevice.WANDevice.", isRemovable: true}
];

@Component({
    selector: "cwmp-path-parameters",
    templateUrl: "./cwmp-path-parameters.component.html",
    styleUrls: ["./cwmp-path-parameters.component.scss"]
})
export class CwmpPathParametersComponent implements OnInit {
    @Input() parametersPath: Array<CwmpParameterPathModel>;
    nmsSelectItemNotifier: Subject<NmsSelectValue>;
    nmsItemNotifier: Subject<Array<NmsSelectModel>>;
    parametersPathList: Array<NmsSelectModel>;
    pathsDefault: Array<NmsSelectModel>;
    private readonly ITEMS_KEY_IDENTIFIER: string = "parametersPathOptions";

    constructor(@Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
                @Inject(ANGULARJS_TRANSLATE) private translate: any,
                @Inject(USER_PREFERENCES_SERVICE) private userPreferenceService) {
        this.parametersPath = new Array<CwmpParameterPathModel>();
        this.nmsSelectItemNotifier = new Subject();
        this.nmsItemNotifier = new Subject();
        this.pathsDefault = PATHS_DEFAULT;
    }

    ngOnInit(): void {
        this.loadParameterPathList();
        this.nmsSelectItemNotifier.subscribe((selectValue: NmsSelectValue) => {
            this.parametersPath[selectValue.index].path = selectValue.value;
        });

        this.nmsItemNotifier.subscribe((itens: Array<NmsSelectModel>) => {
            this.parametersPathList = this.sortItems(itens);
            this.savePathsToLocalStorage();
        });
    }

    loadParameterPathList(): void {
        this.parametersPathList = this.loadPathsToLocalStorage();
        let pathToReplaceTranslateKey = this.parametersPathList.findIndex(item => item.value == "<vazio> (topo da hierarquia)");
        if (pathToReplaceTranslateKey != -1) {
            this.parametersPathList[pathToReplaceTranslateKey].value = this.translate.instant("cwmp.parametes.pathList.empty");
        }
    }

    sortItems(itens: Array<NmsSelectModel>): Array<NmsSelectModel> {
        return itens.sort((a, b) => a.value.localeCompare(b.value));
    }

    loadPathsToLocalStorage(): Array<NmsSelectModel> {
        var parametersPathList = this.userPreferenceService.loadPreferences({}, this.ITEMS_KEY_IDENTIFIER, ["items"]);
        return _.isEmpty(parametersPathList) ? PATHS_DEFAULT : parametersPathList.items;
    }

    savePathsToLocalStorage(): void {
        var parametersList = {
            items : this.parametersPathList
        };
        this.userPreferenceService.savePreferences(parametersList, this.ITEMS_KEY_IDENTIFIER, ["items"]);
    }

    public addPathParameter(index: number): void {
        this.parametersPath.splice(index + 1, 0, new CwmpParameterPathModel("InternetGatewayDevice.", false));
    }

    public removePathParameter(index: number): void {
        if (this.parametersPath.length > 1) {
            this.parametersPath.splice(index, 1);
        } else {
            this.showDialog(this.translate.instant("cwmp.parameters.paths.atLeastOnePathPresentError"));
        }
    }

    private showDialog = (message: string, type: string = "error") => {
        this.$rootScope.showDialog({ type, message });
    };
}
