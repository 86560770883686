"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.ManageGroupsService
* @description - Service para persistência e leitura de informações de grupos.
* Factory in the nms.management.
*/
var app = angular.module("nms.management");

app.service("ManageGroupsService", ["ManageGroupsRestService", "ManageGroupsModelConverter",
    function(ManageGroupsRestService, ManageGroupsModelConverter) {
        const service: any = {};

        /**
        * Adiciona o atributo numberUsers para que os elementos sejam buscáveis nos componentes de busca da tabela
        * (coluna e busca global) e realiza o sort dos usuários.
        */
        var processUsers = function(groupUser) {
            groupUser.users.sort();
            groupUser.numberUsers = _.get(groupUser, "users.length");
            return groupUser;
        };

        service.getAll = function() {
            return ManageGroupsRestService.getAll().then(function(groupUsers) {
                return _.map(groupUsers.plain(), processUsers);
            });
        };

        service.getGroupNames = function() {
            return ManageGroupsRestService.getGroupNames();
        };

        service.createGroup = function(formModel) {
            var groupModel = ManageGroupsModelConverter.convert(formModel);
            return ManageGroupsRestService.create(groupModel).then(function(group) {
                return {
                    name: group.name,
                    description: group.description,
                    users: [],
                    numberUsers: 0
                };
            });
        };

        service.getGroup = function(groupName) {
            return ManageGroupsRestService.getGroup(groupName).then(function(response) {
                return response.plain();
            });
        };

        service.editGroup = function(formModel) {
            var groupModel = ManageGroupsModelConverter.convert(formModel);

            return ManageGroupsRestService.edit(formModel.group.data.oldName, groupModel).then(function(response) {
                return response.plain();
            });
        };

        service.remove = function(groupsForRemove, newGroupName) {
            var groupNames = _.map(groupsForRemove, "name");
            return ManageGroupsRestService.remove(groupNames, newGroupName);
        };

        return service;
    }
]);
