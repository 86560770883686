"use strict";

/**
* @ngdoc service
* @name nms.ManagementDeviceRestService
* @description
* # ManagementDeviceRestService - Serviço REST para busca/remoção de devices.
*/
var app = angular.module("nms");

app.service("ManagementDeviceRestService", ["Restangular",
    function(Restangular) {
        const service: any = {};
        var devicesServicePath = "/device-service";
        var deviceManagementWebPath = "/device-management-web";

        service.getAllDevices = function() {
            return Restangular.all(devicesServicePath + "/all")
                .withHttpConfig({hideLoadingPanel: true})
                .customGET();
        };

        service.getDeviceByName = function(name, config, callback) {
            return Restangular.all(devicesServicePath + "/name?name=" + name)
                .withHttpConfig(config)
                .customGET()
                .then(callback);
        };

        service.verifyDevicesToRemove = function(deviceToRemove) {
            return Restangular
                .all(deviceManagementWebPath+ "/device/validate-removal?username=" + localStorage.getItem("username"))
                .customPOST(deviceToRemove);
        };

        service.removeDevice = function(deviceToRemove) {
            var params: any = {username: localStorage.getItem("username")};
            var operationType = "remove";
            var path = "devices";
            var contentType: any = {"Content-Type": "application/json"};
            return Restangular.all(deviceManagementWebPath)
                .customOperation(operationType, path, params, contentType, deviceToRemove);
        };

        service.findDeviceById = function(deviceId) {
            return Restangular.all(devicesServicePath).customGET(deviceId);
        };

        service.getDevicesByIds = function(ids) {
            return Restangular.all("/templateInstance/device/searchById?deviceIds=" + ids).customGET();
        };

        return service;
    }
]);

