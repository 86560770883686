import { Component, Host, Inject } from "@angular/core";
import { NmsWizardComponent, NmsWizardStep, NmsWizardStepComponent, NmsWizardStepValidationResponse } from "@nms-angular-toolkit/nms-wizard";
import { TemplateComponent } from "../../template.component";
import { TranslateService } from "@ngx-translate/core";
import { ANGULARJS_TRANSLATE, TEMPLATE_SERVICE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { SelectionDropdownOptionsTitle } from "@nms-ng2/app/shared/components/elements/selection-dropdown/selection-dropdown.component";

/**
 * Componente responsável por gerenciar o passo Comandos da criação/edição
 * de Template utilizando o componente de Step do NmsWizard.
 */
@Component({
  selector: "template-step-commands",
  templateUrl: "./template-step-commands.component.html"
})
export class TemplateStepCommandsComponent extends NmsWizardStepComponent {

    model: NmsWizardStep;
    context: any;
    controller: TemplateComponent;
    wizard: NmsWizardComponent;
    variablesChecked = { variables: [], selectAllCheckbox: false, indeterminate: false };
    variablesFormOwner: any;
    favoriteVariables: any;
    allVariablesNames: string[];
    deviceVariablesObject: any;
    cpeVariablesObject: any;
    deviceVariables: any[] = [];
    removeCommandsWatchedVarsTitles: SelectionDropdownOptionsTitle;

    constructor(@Inject(TEMPLATE_SERVICE) public templateService: any,
                @Inject(ANGULARJS_TRANSLATE) private oldTranslate: any,
                @Host() controller: TemplateComponent,
                @Host() wizard: NmsWizardComponent,
                private translate: TranslateService) {
        super();
        this.controller = controller;
        this.wizard = wizard;

        if (!this.controller.template.removeCommandsEnabled) {
            this.controller.template.removeCommandsEnabled = false
        }

        this.removeCommandsWatchedVarsTitles = {
            emptyItems: "templateAdvancedOptions.emptyVariables",
            checkedNone: "templateAdvancedOptions.checkedVariables.empty",
            checkedAll: "templateAdvancedOptions.checkedVariables.all",
            manyItemsSelected: "templateAdvancedOptions.checkedVariables.many"
        };

        this.variablesFormOwner = {
            getVariablesFn: () => {
                return this.controller.template.variables;
            },
            isEditDisabledFn: (variable) => {
                return false;
            }
        };

        this.favoriteVariables = this.getFavoriteVariablesToClone();
        this.deviceVariablesObject = this.controller.getParentScopeVariable("deviceVariables");
        this.controller.cliCommandOptionExtractionFilterTypes = this.controller
                .getParentScopeVariable("cliCommandOptionExtractionFilterTypes");

        this.controller.commandTest = {
            removeResult: null,
            removeError: false,
            applyResult: null,
            applyError: null,
            showTest: false
        };

        this.controller.applicationNameSuggestionModel.varSugestionCheck = this.isVarSugestionCheck();
        this.controller.sortableOptions = { disabled: this.isSortableDisabled(), handle: ".drag-handle" };
        this.controller.applicationNameSuggestionModel.selectedVariable = this.controller.template.applicationNameVarSuggestion
            ? this.getVariableByName(this.controller.template.applicationNameVarSuggestion)
            : this.getFirstGlobalVar();

        this.setTemplateVarsToVarsWatchedAdvancedOption();

        this.fillDeviceVariables();
    }

    getForm() {
        return this.controller.templateCommandsStepForm;
    }

    setContext(context: NmsWizardStep): void {
        this.context = context;
    }

    async loadContent(): Promise<void> {
        return await new Promise((f) => setTimeout(f, 1000));
    }

    allowPrevious(): NmsWizardStepValidationResponse {
        return NmsWizardStepValidationResponse.getDefaultValidationResponse();
    }

    allowNext(): NmsWizardStepValidationResponse {
        return this.validate();
    }

    isCheckApplyRemoveCommandsAndNoSelectedVariables(): boolean {
        return this.controller.template.applyRemoveCommandsOnEditVariables
            && this.controller.template.applyRemoveCommandsWatchedVariables.length === 0;
    }

    validateBeforeFinish() {
        return this.templateService.isAllowedTemplateConfig(this.controller.hasTemplateConfigPermission)
            && this.allowFinish().isValid;
    }

    allowFinish(): NmsWizardStepValidationResponse {
        return this.validate();
    }

    private validate(): NmsWizardStepValidationResponse {
        let messageString = "";

        if (this.isCheckApplyRemoveCommandsAndNoSelectedVariables()) {
            messageString = this.oldTranslate.instant("templateform.error.emptyVariablesToRemove")

            return new NmsWizardStepValidationResponse(false, messageString);
        }

        if (this.getForm().applyCommands.$invalid) {
            messageString = this.oldTranslate.instant("templateform.error.invalidFieldApplyCommands")

            return new NmsWizardStepValidationResponse(false, messageString);
        }

        if (this.controller.template.removeCommandsEnabled && this.getForm().removeCommands.$invalid) {
            messageString = this.oldTranslate.instant("templateform.error.invalidFieldRemovalCommands")

            return new NmsWizardStepValidationResponse(false, messageString);
        }

        return new NmsWizardStepValidationResponse(true);
    }

    finishAction() {
        const isValid = this.validateBeforeFinish();

        if (this.controller.template.removeCommands && !this.controller.template.removeCommandsEnabled) {
            this.controller.$rootScope
                .showDialog({
                    translateKey: "templateform.warning.notEmptyButDisabledRemoveCommands",
                    isConfirm: true
                })
                .then(() => {
                    if (isValid) {
                        this.controller.save();
                    }
                });
        } else {
            if (isValid) {
                this.controller.save();
            }
        }
    }

    fillDeviceVariables(): void {
        _.keys(this.deviceVariablesObject).forEach((deviceVariable) => {
            this.deviceVariables.push({ name: deviceVariable });
        });
    }

    isVarSugestionCheck() {
        if (this.controller.template.applicationNameVarSuggestion) {
            return true;
        }

        return false;
    }

    /**
     * As variáveis favoritas ao serem editadas não devem ser incluídas na lista de variáveis a serem clonadas
     * ou seja, não devem aparecer no dropdown
     */
    getFavoriteVariablesToClone() {
        const savedFavoriteVariables = this.controller.getParentScopeVariable("favoriteVariables");

        return savedFavoriteVariables.filter((favoriteAvailable) => {
            return !this.controller.template.variables.find((variableLocal) => {
                return variableLocal.name === favoriteAvailable.variableName
                    && favoriteAvailable.templateName === this.controller.template.name
            });
        });
    }

    selectedFavoriteVariable = (variable) => {
        this.templateService.getVariable(variable).then((response) => {
            this.openVariableModalToAddClone(response);
        });
    };

    openVariableModalToAddClone(selectedVariable, concatName?) {
        let cloneVariable = angular.copy(selectedVariable);

        if (concatName) {
            cloneVariable.name = cloneVariable.name.concat(concatName);
        }

        if (this.controller.template.type === this.controller.templateTypeProvider.TR_069.name) {
            cloneVariable.commands = "";
            cloneVariable.includeAndBlockOptionMode = this.controller.variables.includeAndBlockOptionsModes.DO_NOT_GET;
            cloneVariable.inclusionParams = null;
            cloneVariable.blockingParams = null;
            cloneVariable.enableAutomaticReload = false;
            cloneVariable.automaticReloadDelay = this.controller.variables.automaticReload.DEFAULT_DELAY;
        }

        const otherVariablesNames = _.map(this.controller.template.variables, "name");
        cloneVariable.favorite = false;
        this.editVariable(cloneVariable, selectedVariable, otherVariablesNames, this.addVariableToTable.bind(this));
    }

    /**
     * Abre a modal de edição de variável e atualiza a controller com o retorno da variável editada.
     *
     * @param variableToEdit Variável que será utilizada para inicializar os campos da modal de variáveis.
     * @param selectedVariable Variável selecionada na tabela de variáveis.
     * @param otherVariablesNames Lista com o nome de todas as outras variáveis da tabela de variáveis. Será utilizada na
     * validação de nome da variável na modal de variáveis.
     * @param successFunction Função de sucesso que será executada quando o usuário finalizar o modal de variáveis.
     * Essa função receberá 2 parâmetros, a variável retornada pela modal e a variável de origem que foi selecionada
     * para ser editada/clonada.
     */
    editVariable(variableToEdit, selectedVariable, otherVariablesNames, successFunction) {
        this.controller.ngDialog
            .openConfirm({
                template: "templates/features/template/template/modals/variable/variable-modal.html",
                controller: "VariableModalController",
                controllerAs: "VariableModalController",
                className: "big-modal",
                resolve: {
                    otherVariablesNames: () => {
                        return otherVariablesNames;
                    },
                    variableTypesConfig: () => {
                        return this.controller.variableTypes;
                    },
                    variableToEdit: () => {
                        return variableToEdit;
                    },
                    deviceVariables: () => {
                        return this.getEquipmentVariables();
                    },
                    cliCommandOptionExtractionFilterTypes: () => {
                        return this.controller.cliCommandOptionExtractionFilterTypes;
                    },
                    rulesMatchingModes: () => {
                        return this.controller.getParentScopeVariable("rulesMatchingModes");
                    },
                    templateUsed: () => {
                        return this.controller.template && this.controller.template.used;
                    },
                    isNmsTemplate: () => {
                        return this.controller.isNmsTemplate;
                    },
                    hasTemplateConfigPermission: () => {
                        return this.controller.hasTemplateConfigPermission;
                    },
                    variablesAlreadyAdded: () => {
                        return _.filter(this.controller.template.variables, (variable) => {
                            return variable.name !== variableToEdit.name;
                        });
                    },
                    isTR069Template: () => {
                        return this.controller.isTR069Template;
                    },
                    expressionsProvider: () => {
                        return this.getExpressionsProvider();
                    },
                    formatCommand: () => {
                        return this.getFormatCommand();
                    },
                    tooltipVariable: () => {
                        return this.getTooltipVariable();
                    },
                    language: () => {
                        return this.controller.template.language;
                    }
                }
            })
            .then((editedVariable) => {
                successFunction(editedVariable, selectedVariable);
            });
    }

    openAddVariable() {
        this.controller.ngDialog
            .openConfirm({
                template: "templates/features/template/template/modals/variable/variable-modal.html",
                controller: "VariableModalController",
                controllerAs: "VariableModalController",
                className: "big-modal",
                resolve: {
                    otherVariablesNames: () => {
                        return _.map(this.controller.template.variables, "name");
                    },
                    variableTypesConfig: () => {
                        return this.controller.variableTypes;
                    },
                    variableToEdit: () => {
                        return {};
                    },
                    deviceVariables: () => {
                        return this.getEquipmentVariables();
                    },
                    cliCommandOptionExtractionFilterTypes: () => {
                        return this.controller.cliCommandOptionExtractionFilterTypes;
                    },
                    rulesMatchingModes: () => {
                        return this.controller.getParentScopeVariable("rulesMatchingModes");
                    },
                    templateUsed: () => {
                        return false;
                    },
                    isNmsTemplate: () => {
                        return this.controller.isNmsTemplate;
                    },
                    hasTemplateConfigPermission: () => {
                        return this.controller.hasTemplateConfigPermission;
                    },
                    variablesAlreadyAdded: () => {
                        return this.controller.template.variables;
                    },
                    isTR069Template: () => {
                        return this.controller.isTR069Template;
                    },
                    expressionsProvider: () => {
                        return this.getExpressionsProvider();
                    },
                    formatCommand: () => {
                        return this.getFormatCommand();
                    },
                    tooltipVariable: () => {
                        return this.getTooltipVariable();
                    },
                    language: () => {
                        return this.controller.template.language;
                    }
                }
            })
            .then((newVariable, selectedVariable) => {
                this.addVariableToTable(newVariable, selectedVariable);
            });
    }

    /**
     * Adiciona um variável na tabela de variáveis.
     *
     * @param newVariable Variável que será adicionada no domínio da tabela de variáveis.
     * @param selectedVariable (opcional) Variável que foi selecionada como origem da variável que será adicionada.
     * É utilizada na clonagem de variável para saber em qual posição da tabela a variável será adicionada. Caso
     * esse parâmetro não seja passado, a variável sempre será adicionada no final.
     */
    addVariableToTable(newVariable, selectedVariable) {
        const copyNewVariable = angular.copy(newVariable);

        if (!selectedVariable) {
            this.controller.template.variables.push(copyNewVariable);
        } else {
            const selectedIndex = this.getVariableIndexToEdit(selectedVariable);
            const indexToAdd = selectedIndex + 1;
            this.controller.template.variables.splice(indexToAdd, 0, copyNewVariable);
        }

        if (!this.controller.applicationNameSuggestionModel.selectedVariable && copyNewVariable.globalScope) {
            this.controller.applicationNameSuggestionModel.selectedVariable = copyNewVariable;
        }

        this.refreshVariablesHeaderCheck();
        this.setTemplateVarsToVarsWatchedAdvancedOption();
    }

    openEditVariable(variableToEdit) {
        /** Transforma a lista de variáveis em um lista de nomes de variáveis sem o nome da variável editada. */
        const otherVariablesNames = _.reduce(
            this.controller.template.variables,
            (result, variable) => {
                if (variableToEdit.name !== variable.name) {
                    result.push(variable.name);
                }
                return result;
            },
            []
        );
        this.editVariable(variableToEdit, variableToEdit, otherVariablesNames, this.successEditVariable.bind(this));
    }

    removeVariables() {
        if (this.variablesChecked.variables.length === 0) {
            this.controller.$rootScope.showDialog({
                translateKey: "popups.alert.variablesNotSelected"
            });
            return;
        }

        const selectedVariables = _.cloneDeep(this.variablesChecked.variables);
        selectedVariables.forEach((variable) => {
            this.removeVariable(variable);
        });

        this.variablesChecked.variables = [];
        this.refreshVariablesHeaderCheck();
    }

    removeVariable(variable) {
        _.remove(this.controller.template.variables, (currentVariable) => {
            return currentVariable.name === variable.name;
        });
        this.controller.sortableOptions = { disabled: this.isSortableDisabled(), handle: ".drag-handle" };
        this.changeApplicationNameVarSuggestionCheckIfNecessary(variable);
        this.setTemplateVarsToVarsWatchedAdvancedOption();
    }

    openCloneVariable(selectedVariable) {
        this.openVariableModalToAddClone(selectedVariable, "_clone");
    }

    getOrderedCheckedVariables = () => {
        const variables = _.cloneDeep(this.controller.template.variables);
        const checkedVariables = _.cloneDeep(this.variablesChecked.variables);

        return _.filter(variables, (variablesItem) => {
            return _.any(checkedVariables, (checkedVariablesItem) => {
                return variablesItem.name === checkedVariablesItem.name;
            });
        });
    };


    changeApplicationNameVarSuggestionCheckIfNecessary(variable) {
        if (_.isEqual(this.controller.applicationNameSuggestionModel.selectedVariable, variable)) {
            this.controller.applicationNameSuggestionModel.varSugestionCheck = false;
            this.controller.applicationNameSuggestionModel.selectedVariable = this.getFirstGlobalVar();
        }
    }

    getVariableIndexToEdit(oldVariable) {
        return _.findIndex(this.controller.template.variables, (variable) => {
            return variable.name === oldVariable.name;
        });
    }

    /**
     * Atualiza os dados da controller com o que foi editado na variável.
     * Como a propriedade de favorito não pode ser alterada na modal de edição de variável,
     * ela é configurada com o valor original (anterior à edição).
     */
    successEditVariable(editedVariable, originalVariable) {
        const index = this.getVariableIndexToEdit(originalVariable);
        const updateSelectedVarSugestion = _.isEqual(originalVariable, this.controller.applicationNameSuggestionModel.selectedVariable);
        editedVariable.favorite = originalVariable.favorite;
        this.controller.template.variables[index] = editedVariable;
        this.processVariableApplicationNameVarSuggestion(editedVariable, updateSelectedVarSugestion);

        if (this.keepValueInEditedVariable(originalVariable, editedVariable)) {
            editedVariable.value = originalVariable.value;
        } else {
            editedVariable.value = [editedVariable.defaultValue];
        }
        if (this.controller.template.applyRemoveCommandsWatchedVariables.includes(originalVariable.name)) {
            const editWatchedVariables = _.clone(this.controller.template.applyRemoveCommandsWatchedVariables);
            editWatchedVariables[index] = editedVariable.name;
            this.controller.template.applyRemoveCommandsWatchedVariables = _.clone(editWatchedVariables);
        }
        this.setTemplateVarsToVarsWatchedAdvancedOption();
    }

    keepValueInEditedVariable(oldVar, newVar) {
        if (oldVar.type === newVar.type) {
            switch (newVar.type) {
                case "TEXT":
                case "TEXT_LIST":
                    return this.keepSameTextValues(oldVar, newVar);
                case "INTEGER":
                case "INTEGER_LIST":
                    return this.keepSameIntegerValues(oldVar, newVar);
                case "SELECTION":
                case "SELECTION_LIST":
                    return this.keepSameSelectionValues(oldVar, newVar);
                default:
                    return false;
            }
        }

        return false;
    }

    keepSameTextValues(oldVar, newVar) {
        return oldVar.rangeMin === newVar.rangeMin && oldVar.rangeMax === newVar.rangeMax && oldVar.regex === newVar.regex;
    }

    keepSameIntegerValues(oldVar, newVar) {
        return (
            oldVar.baseExpoent === newVar.baseExpoent &&
            oldVar.minValue === newVar.minValue &&
            oldVar.maxValue === newVar.maxValue &&
            oldVar.step === newVar.step
        );
    }

    keepSameSelectionValues(oldVar, newVar) {
        return _.isEqual(oldVar.options, newVar.options);
    }

    processVariableApplicationNameVarSuggestion(variable, updateSelectedVarSugestion) {
        if (updateSelectedVarSugestion) {
            if (variable.globalScope) {
                this.controller.applicationNameSuggestionModel.selectedVariable = variable;
            } else {
                this.controller.applicationNameSuggestionModel.varSugestionCheck = false;
                this.controller.applicationNameSuggestionModel.selectedVariable = this.getFirstGlobalVar();
            }
        } else if (!this.controller.applicationNameSuggestionModel.selectedVariable) {
            this.controller.applicationNameSuggestionModel.selectedVariable = this.getFirstGlobalVar();
        }
    }

    getFirstGlobalVar() {
        return _.chain(this.controller.template.variables)
            .filter((variable) => {
                return variable.globalScope;
            })
            .sortBy("name")
            .first()
            .value();
    }

    getVariableByName(varName) {
        return _.find(this.controller.template.variables, (variable) => {
            return variable.name === varName;
        });
    }

    refreshVariablesHeaderCheck() {
        this.variablesChecked.selectAllCheckbox =
            this.variablesChecked.variables.length > 0
            && this.variablesChecked.variables.length === this.controller.template.variables.length;

        this.variablesChecked.indeterminate =
            this.variablesChecked.variables.length > 0
            && this.variablesChecked.variables.length < this.controller.template.variables.length;

        this.controller.sortableOptions = { disabled: this.isSortableDisabled(), handle: ".drag-handle" };
    }

    checkUncheckAll() {
        this.variablesChecked.indeterminate = false;

        if (this.variablesChecked.selectAllCheckbox) {
            this.variablesChecked.variables = _.cloneDeep(this.controller.template.variables);
        } else {
            this.variablesChecked.variables = [];
        }
    }

    isSortableDisabled() {
        return this.controller.isNmsTemplate ||
               this.controller.template.used ||
               this.controller.template.variables.length <= 1;
    }

    isAllStillSelected() {
        setTimeout(() => {
            this.refreshVariablesHeaderCheck();
        }, 10);
    }

    getVariableScope(variable) {
        return this.controller.variableService.defineVariableScope(variable.globalScope);
    }

    getVariableTooltipKey(isFavorite) {
        if (isFavorite) {
            return "templateform.commands.tooltipOverFavoriteVariable";
        }
        return "templateform.commands.tooltipOverNotFavoriteVariable";
    }

    getVariableCommandsType(variable) {
        return this.controller.variableService.getVariableCommandsType(variable);
    }

    getVariableType(variable) {
        return this.controller.variableTypes[variable.type];
    }

    getVariableRestrictions(variable) {
        return this.controller.variableService.defineRestrictions(variable);
    }

    buildVariableRegexTooltip(variable) {
        return this.controller.variableService.buildRegexTooltip(variable);
    }

    clearRemoveCommands() {
        this.controller.clearRemoveCommands();
    }

    setTemplateVarsToVarsWatchedAdvancedOption() {
        this.allVariablesNames = _.flatten(_.map(this.controller.template.variables, "name"));
    }

    disableRemoveCommandsVarsAdvancedOption(): boolean {
        return !this.controller.template.removeCommandsEnabled ||
               this.controller.disableStatusForEditing(this.controller.template);
    }

    setRemoveCommands() {
        this.clearRemoveCommands();
        this.setRemoveCommandsVarsAdvancedOption();
    }

    setRemoveCommandsVarsAdvancedOption() {
        if (this.controller.template.removeCommandsEnabled === false) {
            this.controller.template.applyRemoveCommandsOnEditVariables = false;
        }
    }

    isDisabledRemovalCommands() {
        return this.controller.template.used
            || !this.controller.template.removeCommandsEnabled
            || !this.controller.hasTemplateConfigPermission;
    }

    getAdvancedOptionsTranslatedKey() {
        return this.controller.advancedOptions.showOptions
            ? "templateAdvancedOptions.hideOptions"
            : "templateAdvancedOptions.showOptions";
    }

    validateCommandsFields() {
        if (!this.controller.template.applyCommands) {
            this.controller.$rootScope.showDialog({
                translateKey: "popups.alert.nullApplyCommands"
            });

            return false;
        }

        if (this.controller.template.removeCommandsEnabled && !this.controller.template.removeCommands) {
            this.controller.$rootScope.showDialog({
                translateKey: "popups.alert.nullRemoveCommands"
            });

            return false;
        }

        return true;
    }

    showHideAdvancedOptions(event) {
        event.preventDefault();
        event.stopPropagation();

        this.controller.advancedOptions.showOptions = !this.controller.advancedOptions.showOptions;
    }

    showHideTemplateTest(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.controller.commandTest.showTest) {
            this.validateTemplateForTest();
        } else {
            this.controller.commandTest.showTest = false;
        }
    }

    defineVariableType(variable) {
        return this.controller.variableService.defineVariableType(variable.type);
    }

    /**
     * Atualiza a coluna "Valor" no acordion de testar template.
     */
    revertValue(variable) {
        variable.value = variable.defaultValue;
    }

    hasGlobalVars() {
        return _.some(this.controller.template.variables, "globalScope", true);
    }

    getEquipmentVariables() {
        return this.controller.getTemplateComponent().getEquipmentVariables(this.deviceVariables);
    }

    getExpressionsProvider() {
        return this.controller.getTemplateComponent().getExpressions();
    }

    getFormatCommand() {
        return this.controller.getTemplateComponent().getFormatCommand();
    }

    getTooltipVariable() {
        return this.controller.getTemplateComponent().getTooltipVariable();
    }

    expressionsAggregatorsProvider() {
        return this.controller.getTemplateComponent().getExpressionsAggregatorProvider();
    }

    setSelectedWatchedVars(selectedVariables: string[]) {
        this.controller.template.applyRemoveCommandsWatchedVariables = selectedVariables;
    }

    getShowInlineExpressions() {
        return this.controller.getTemplateComponent().getShowInlineExpressions();
    }

    validateTemplateForTest() {
        if (this.validateCommandsFields()) {
            const templateApi = _.cloneDeep(this.controller.template);
            this.controller.templateService.validateTemplateCommands(templateApi).then(() => {
                this.controller.commandTest.showTest = true;
            });
        }
    }

    testTemplate() {
        this.controller.commandTest.applyResult = null;
        this.controller.commandTest.applyError = false;
        this.controller.commandTest.removeResult = null;
        this.controller.commandTest.removeError = false;

        if (!this.validateCommandsFields()) {
            return;
        }

        try {
            const variables = this.variablesFormOwner.getVariablesValues();

            let postTest = function (response, object, resultVariableName, errorVariableName) {
                object[resultVariableName] = response.resultCommand
                                           ? response.resultCommand
                                           : response.errorMessage;
                object[errorVariableName] = angular.isDefined(response.errorMessage) && response.errorMessage;
            }

            this.templateService
                .testTemplateCommand(this.controller.template.name, this.controller.template.applyCommands, variables)
                .then((response) => {
                    postTest(response, this.controller.commandTest, "applyResult", "applyError")
                });

            if (this.controller.template.removeCommandsEnabled) {
                this.templateService
                    .testTemplateCommand(this.controller.template.name, this.controller.template.removeCommands, variables)
                    .then((response) => {
                        postTest(response, this.controller.commandTest, "removeResult", "removeError")
                    });
            }
        } catch (error) {
            this.controller.$rootScope.showDialog({
                message: error
            });
        }
    }

}
