import { Inject, Injectable } from "@angular/core";
import { TEMPLATE_TYPE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
* Classe utilitária para funções relacionadas a Templates.
**/
@Injectable({
    providedIn: "root"
})
export class TemplateUtils {
    constructor(@Inject(TEMPLATE_TYPE) private templateType) {}

    isCliTemplate(templateType: string): boolean {
        return this.templateType.CLI.name === templateType;
    }
}