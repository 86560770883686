"use strict";

angular.module("nms.templates")
.factory("TemplateRESTService", ["Restangular", "$http", function(Restangular, $http) {
    var basePath = "/template";

    return {
        getPaginated: function(filters) {
            var URI = [];

            URI.push(basePath + "?pageNumber=" + filters.pageNumber +
            "&pageSize=" + filters.pageSize +
            "&column=" + filters.column +
            "&direction=" + filters.direction);

            if (filters.searchTerm) {
                URI.push("&searchTerm=" + filters.searchTerm);
            }

            if (filters.searchColumn) {
                URI.push("&searchColumn=" + filters.searchColumn);
            }
            if (filters.searchName) {
                URI.push("&searchName=" + filters.searchName);
            }
            if (filters.keyword) {
                URI.push("&keyword=" + filters.keyword);
            }
            if (filters.showOnlyAllowedTemplates) {
                URI.push("&showOnlyAllowedTemplates=" + filters.showOnlyAllowedTemplates);
            }
            return Restangular.all(URI.join("")).customGET();
        },

        get: function(id) {
            return Restangular.one(basePath + "/" + id).get();
        },

        save: function(template) {
            return Restangular.one(basePath).customPOST(template);
        },

        updateFull: function(template) {
            return Restangular.one(basePath + "/full").customPUT(template);
        },

        updateBasic: function(template) {
            return Restangular.one(basePath + "/basic").customPUT(template);
        },

        delete: function(templateIds) {
            return Restangular.all(basePath).customOperation("remove", "", {}, {
                "Content-Type": "application/json; charset=UTF-8"
            }, templateIds);
        },

        getVarRestrictions: function(templateIds) {
            return Restangular.all(basePath + "/varsRestrictions").customPOST(templateIds);
        },

        /*
         * O parâmetro httpConfig é opcional e foi necessário aqui para permitir ignorar o interceptor global para erros
         * nas requisições REST. Isso foi necessário para exibir os erros de freemarker dentro do dialog
         * de 'View Commands' na modal de aplicação de template em um passo.
         */
        getEquipmentsCommands: function(requestCommand, httpConfig) {
            _.defaults(httpConfig, {bypassInterceptor: false});

            return Restangular.all(basePath + "/applyEquipmentsCommands")
                .withHttpConfig(httpConfig)
                .customPOST(requestCommand)
                .then(response => response.plain());
        },

        /**
         * Compila os comandos de aplicações sem a necessidade de utilizar equipamentos.
         */
        getCommands: function (templateCommandsCompileRequest) {
            return Restangular.all(basePath + `/applyCommands`)
                .customPOST(templateCommandsCompileRequest)
                .then((response) => response.plain());
        },

        getTotalKeywords: function(countOnlyAllowedTemplates) {
            return Restangular.all(basePath + "/totalKeywords/" + countOnlyAllowedTemplates).customGET();
        },

        exportSelectedTemplates: function(templateIds) {
            return Restangular.all(basePath + "/export").customPOST(templateIds);
        },

        exportAllTemplates: function() {
            return Restangular.all(basePath + "/export").customGET();
        },

        import: function(file) {
            var jsonFile = new FormData();
            jsonFile.append("file", file);
            return $http.post(basePath + "/import", jsonFile, {
                transformRequest: angular.identity,
                headers: {"Content-Type": undefined}
            });
        },

        validateVariableName: function(varName) {
            return Restangular.one(basePath + "/validate-variable-name").customPOST(varName);
        },

        validateTemplate: function(request) {
            return Restangular.one(basePath + "/testTemplate").customPOST(request);
        },

        validateTemplateCommands: function(template) {
            return Restangular.one(basePath + "/validateSyntaxTemplateCommands").customPOST(template);
        },

        validateCommands: function(commands) {
            return Restangular.one(basePath + "/validateSyntaxCommands").customPOST(commands);
        },

        getTemplatesByKeywords: function(word) {
            return Restangular.one(basePath + "/templatesByKeywords/" + word).customGET();
        },

        validateName: function(templateIdName) {
            return Restangular.one(basePath + "/validateTemplateName").customPOST(templateIdName);
        },

        getFavoriteVariables: function() {
            return Restangular.all(basePath + "/favoriteVariables").customGET();
        },

        getVariable: function(variableTemplateNames) {
            var URI = [];
            URI.push("templateName=" + variableTemplateNames.templateName);
            URI.push("varName=" + variableTemplateNames.variableName);

            var url = basePath + "/getVariable?" + URI.join("&");
            return Restangular.all(url).customGET();
        },

        filterRestrictedTemplates(templateIds: string[]) {
            return Restangular.all(`${basePath}/filterRestrictedTemplates`).customPOST(templateIds);
        }
    };
}]);
