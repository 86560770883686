"use strict";

var app = angular.module("nms.deviceDiscover");

app.factory("DeviceDiscoverService", ["$rootScope", "$websocket", "$window", "DeviceDiscoverRESTService", "$translate",
    "DEVICE_STATUS", "CUSTOM_DEVICE",
    function($rootScope, $websocket, $window, DeviceDiscoverRESTService, $translate, DEVICE_STATUS, CUSTOM_DEVICE) {
        const service: any = {};

        service.websocketIsOpen = false;
        service.clientId;

        service.checkUncheckAll = function(selected, devices) {
            if (selected) {
                return devices.slice();
            } else {
                return [];
            }
        };

        service.getSubTitle = function(devices) {
            if (_.isEmpty(devices)) {
                return $translate.instant("findAddDevice.modals.managementProtocols.subTitleSingleHost");
            } else if (devices.length > 1) {
                return $translate.instant("findAddDevice.modals.managementProtocols.modalSubTitleMultipleHosts");
            } else {
                return devices[0].hostname;
            }
        };

        service.find = function(hostsToFind, clientId) {
            DeviceDiscoverRESTService.find(hostsToFind, clientId);
        };

        service.refresh = function(devices, clientId) {
            DeviceDiscoverRESTService.refresh(devices, clientId);
        };

        service.verifyLocationManagerPermission = function(locationId) {
            return DeviceDiscoverRESTService.verifyLocationManagerPermission(locationId);
        };

        service.addDevices = function(devices, callbackOnResponse) {
            var deviceInsertionInstances = [];

            devices.forEach(function(device) {
                deviceInsertionInstances.push(convertDeviceToDeviceInsertionInstance(device));
            });

            DeviceDiscoverRESTService.addDevices(deviceInsertionInstances)
                .then(function(response) {
                    callbackOnResponse(devices, response);
                });
        };

        service.getAvailableNetworkElements = function(callbackOnResponse) {
            DeviceDiscoverRESTService.getAvailableNetworkElements().then(callbackOnResponse);
        };

        service.addDevicesAsCustom = function(devices, callbackOnResponse) {
            var deviceInsertionInstances = [];

            _.forEach(devices, function(device) {
                device.model.code = CUSTOM_DEVICE.modelCode;
                device.properties.name = device.name;
                deviceInsertionInstances.push(convertDeviceToDeviceInsertionInstance(device));
            });

            DeviceDiscoverRESTService.addDevices(deviceInsertionInstances)
                .then(function(response) {
                    callbackOnResponse(devices, response);
                });
        };

        service.findAndAddDevices = function(hosts, clientId) {
            DeviceDiscoverRESTService.findAndAddDevices(hosts, clientId);
        };

        service.getDevicesWithoutManagerPermission = function(devices) {
            return DeviceDiscoverRESTService.getDevicesWithoutManagerPermission(devices);
        };

        service.validatePageValue = function(currentPage, totalPages) {
            if (!currentPage || currentPage > totalPages || currentPage < 1) {
                currentPage = "";
            }

            return currentPage;
        };

        service.connect = function(callbackOnOpen, callbackOnMessage) {
            if (!service.websocketIsOpen) {
                service.clientId = Date.now();
                service.ws = $websocket("wss://" + $window.location.host + "/device-management-web/" + service.clientId);

                service.ws.onMessage(function(event) {
                    var contactedDevices = angular.fromJson(event.data);
                    callbackOnMessage(contactedDevices);
                });

                service.ws.onOpen(function() {
                    service.websocketIsOpen = true;
                    callbackOnOpen(service.clientId);
                });

                service.ws.onClose(function() {
                    service.websocketIsOpen = false;
                });
            } else {
                callbackOnOpen(service.clientId);
            }
        };

        service.disconnect = function() {
            if (service.websocketIsOpen) {
                service.ws.close();
                service.websocketIsOpen = false;
            }
        };

        function convertDeviceToDeviceInsertionInstance(device) {
            if (isACustomDevice(device) || device.status === DEVICE_STATUS.UNSUPPORTED) {
                device.model.code = CUSTOM_DEVICE.modelCode;
                device.model.name = CUSTOM_DEVICE.modelName;
                device.proxyModelCode = CUSTOM_DEVICE.proxyModelCode;
                device.model.vendor.code = CUSTOM_DEVICE.vendor;
            }

            var deviceInsertionInstance: any = {
                devNo: device.number.number,
                devLocalId: device.number.localId,
                devId: device.properties.name,
                hostname: device.hostname,
                hostnameToInsert: device.hostname,
                modelCode: device.model.code,
                modelName: device.model.name,
                proxyCode: device.proxyCode,
                proxyModelCode: device.proxyModelCode,
                vendor: device.model.vendor.code,
                serial: device.serialNumber,
                firmwareVersion: device.firmwareVersion,
                /*
                 * FIXME Nem sempre a versão de firmware do proxy é igual a versão de firmware do device.
                 * Principalmente quando o proxy possui remotos. Aqui foi mantido dessa forma pois o ContactedDevice
                 * ainda não possui suporte ao firmware version do proxy.
                 */
                agentFirmwareVersion: device.firmwareVersion,
                useDefaultCredentials: device.globalConfig,
                pcgaCredential: filterCredential(device, device.credentials.pcgaCredential),
                snmpCredential: filterCredential(device, device.credentials.snmpCredential),
                httpCredential: filterCredential(device, device.credentials.httpCredential),
                netconfCredential: filterCredential(device, device.credentials.netconfCredential),
                telnetCredential: filterCredential(device, device.credentials.telnetCredential),
                sshCredential: filterCredential(device, device.credentials.sshCredential),
                sftpCredential: filterCredential(device, device.credentials.sftpCredential),
                locationId: device.locationId,
                path: device.path,
                externalLocationBean: device.properties.externalLocation,
                internalLocationBean: device.properties.internalLocation,
                vendorProxy: device.model.vendor.code,
                isManageable: device.status === DEVICE_STATUS.REACHABLE,
                notes: device.properties.notes,
                project: device.properties.project,
                accessData: device.accessData
            };

            return deviceInsertionInstance;
        }

        function isACustomDevice(device) {
            var UNKNOWN_MODEL_CODE = -1;
            return (device.status === DEVICE_STATUS.UNREACHABLE || device.status === DEVICE_STATUS.NOT_ADDED)
                        && device.model.code === UNKNOWN_MODEL_CODE || device.model.code ===CUSTOM_DEVICE.modelCode;
        }

        function filterCredential(device, credentials) {
            if (!device.globalConfig) {
                return credentials;
            }
            return null;
        }

        return service;
    }
]);
