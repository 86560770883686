var app = angular.module("nms.manageDevices");

/**
* @ngdoc directive
* @name nms.manageDevice.directive:pathNodeChildren
* @description Diretiva que adiciona as pastas filhas dentro da pasta ROOT na modal de localidades
*/
app.directive("pathNodeChildren", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            scope: {
                node: "=",
                parentNode: "=",
                parentPath: "=",
                parentId: "="
            },
            templateUrl: "templates/features/manage-devices/path-tree/path-node-children.html",
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);

/**
* @ngdoc directive
* @name nms.manageDevice.directive:containerNodeChildren
* @description Diretiva que adiciona as pastas filhas dentro das pastas filhas de ROOT na modal de localidades
*/
app.directive("containerNodeChildren", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            templateUrl: "templates/features/manage-devices/path-tree/container-node-children.html",
            controller: "PathTreeController",
            scope: {
                container: "=",
                parentNode: "=",
                parentPath: "=",
                parentId: "="
            },
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);

