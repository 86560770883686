"use strict";

/**
 * @ngdoc function
 * @name nms.templates.controller:AddDeviceModalGenericCtrl
 * @description
 * Controller of the nms.deviceConfigurationBackup
 *
 * Controller genérica para inserção de equipamentos em features que sejam necessárias
 * e que haja necessidade de validações genéricas, como permissão de equipamento,
 * equipamentos duplicados e etc.
 *
 * Obs.:
 * - Não foi utilizada a controller AddDeviceModalCtrl, pois a mesma é muito acoplada
 * a aplicação de template.
 * - Foi criado em angularJs por ser mais rápido do que criar um componente no angular 9,
 * o que não estava previsto.
 */
var app = angular.module("nms.deviceConfigurationBackup");

app.controller("AddDeviceModalGenericCtrl", [
    "$scope",
    "DeviceService",
    "devicesAlreadyAdded",
    "PRESENTATION_MODE",
    "TEMPLATE_TYPE",
    "deviceModelRestrictionTypes",
    "DeviceDropdownModelService",
    "ManagementDeviceRestService",
    "monitoringSettings",
    "EquipmentService",
    function (
        $scope,
        DeviceService,
        devicesAlreadyAdded,
        PRESENTATION_MODE,
        TEMPLATE_TYPE,
        deviceModelRestrictionTypes,
        DeviceDropdownModelService,
        ManagementDeviceRestService,
        monitoringSettings,
        EquipmentService,
    ) {
        $scope.monitoringSettings = monitoringSettings;
        $scope.devices = [];
        $scope.disableRefreshButton = false;

        $scope.devicesListOwner = {
            addDeviceDbClickFn: function () {
                $scope.addDeviceDbClick();
            },
            selectAndUnselectDeviceFn: function (event, device, index, selection) {
                $scope.selectAndUnselectDevice(event, device, index, selection);
            },
            devicesProvider: () => {
                return ManagementDeviceRestService.getAllDevices().then(
                    function (response) {
                        return response;
                    }
                );
            }
        };

        $scope.retrieveDevices = function () {
            $scope.disableRefreshButton = true;
            $scope.updateDevices().then(() => ($scope.disableRefreshButton = false));
        };

        $scope.updateDevices = function () {
            return $scope.devicesListOwner.updateModel.updateFunction().toPromise();
        };

        $scope.presentationMode = TEMPLATE_TYPE.CLI.name;
        $scope.presentationModeDefault = PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;
        $scope.deviceModelRestrictionTypes = deviceModelRestrictionTypes;

        $scope.selectAndUnselectDevice = function (event, device, index, selection) {
            $scope.firstSelectedIndex = angular.copy($scope.currentIndex);
            $scope.currentIndex = index;

            if (event.shiftKey && !_.isUndefined($scope.firstSelectedIndex)) {
                const index = $scope.currentIndex < $scope.firstSelectedIndex ? $scope.currentIndex : $scope.firstSelectedIndex;
                const finalIndex =
                    $scope.currentIndex > $scope.firstSelectedIndex ? $scope.currentIndex : $scope.firstSelectedIndex;
                $scope.selectMultiple(index, finalIndex, selection.checked, $scope.devices);
            } else if (!event.shiftKey) {
                EquipmentService.selectAndUnselectEquipment(device, selection.checked);
            }
        };

        $scope.selectMultiple = function (initialIndex, finalIndex, checkedItems, items) {
            const selectedItems = [];

            for (let i = initialIndex; i <= finalIndex; i++) {
                selectedItems.push(items[i]);
            }

            const areAllItemsSelected = _.every(selectedItems, function (item) {
                return _.indexOf(checkedItems, item) != -1;
            });

            if (areAllItemsSelected) {
                _.forEach(selectedItems, function (item) {
                    const index = _.indexOf(checkedItems, item);
                    checkedItems.splice(index, 1);
                });
            } else {
                _.forEach(selectedItems, function (item) {
                    const isDeviceAlreadySelected = _.indexOf(checkedItems, item);
                    if (_.isEqual(isDeviceAlreadySelected, -1)) {
                        checkedItems.push(item);
                    }
                });
            }

            return checkedItems;
        };

        $scope.addDeviceDbClick = function () {
            $scope.validateAddDevices();
        };

        $scope.validateAddDevices = function () {
            const selection = $scope.devicesListOwner.getCheckedDevices();

            if ($scope.validateThereIsSelectedEquipments(selection)) {
                DeviceService.genericPermissionsValidateAddDevices(selection)
                    .then(selection => {
                        $scope.validateDuplicatedDevices(selection);
                    });
            }
        };

        $scope.validateDuplicatedDevices = function (selection) {
            if (EquipmentService.validateDuplicateSelectedEquipments(selection, devicesAlreadyAdded, TEMPLATE_TYPE.CLI.name)) {
                $scope.confirm(selection);
            }
        }

        $scope.validateThereIsSelectedEquipments = function (checkedEquipments) {
            return EquipmentService.validateThereIsSelectedEquipments(checkedEquipments, TEMPLATE_TYPE.CLI.name);
        }

        $scope.createDeviceDropdownModel = function (device) {
            return DeviceDropdownModelService.createModelFromDeviceSearchModal(device);
        };

        $scope.getAvailableFeatures = function (deviceId) {
            return DeviceDropdownModelService.getAvailableFeatures(deviceId);
        };

    }
]);
