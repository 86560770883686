import { TemplateInstanceUtils } from "@nms-ng2/app/modules/template/template-instance/template-instance.utils";
import { RuleOptionsService } from "@nms-ng2/app/shared/services/rule-options/rule-options.service";
import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  PRESENTATION_MODE,
  TEMPLATE_INSTANCE_SERVICE,
  ANGULARJS_TRANSLATE,
  CUT_STRING_FILTER,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { RuleTypesSet } from "../matching-rules/matching-rules.models";
import { CpeFieldValues, DeviceFieldValues } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";

/**
 * Componente para visualização dos dados dos templates selecionados,
 * e suas restrições na aplicação de template.
 */
@Component({
  selector: "template-info-header",
  templateUrl: "./template-info-header.component.html",
})
export class TemplateInfoHeaderComponent implements OnInit {
  @Input() templateType: string;
  @Input() templates = [];
  @Input() equipmentModelRestrictionTypes: RuleTypesSet;
  @Input() presentationMode;
  presentationModeDefault;
  modelRestrictionsFullText: string;
  modelRestrictionsCroppedText: string;
  firmwareRestrictionFullText: string;
  firmwareRestrictionsCroppedText: string;
  templatesNames: string;
  firmwareOrSoftwareVersionLabel: string = "";

  private readonly modelRestrictionsMaxTextSize = 33;
  private readonly templatesNamesMaxTextSize = 27;
  private readonly firmwareRestrictionsMaxTextSize = 37;

  private firmwareOrSoftwareKeys = {
    deviceKey: "modals.search.template.equipment.firmware",
    cpeKey: "modals.search.template.equipment.software",
  };

  constructor(
    @Inject(TEMPLATE_INSTANCE_SERVICE) private templateInstanceService: any,
    @Inject(PRESENTATION_MODE) private presentationModeConst,
    @Inject(ANGULARJS_TRANSLATE) private translate,
    @Inject(CUT_STRING_FILTER) private cutStringFilter,
    private ruleOptionsService: RuleOptionsService,
    private templateInstanceUtils: TemplateInstanceUtils
  ) {
    this.presentationModeDefault = presentationModeConst.EQUIPMENTS_BY_TEMPLATE;
  }

  ngOnInit() {
    const rules = this.getAllTemplatesRules();
    const isCli = this.templateType === "CLI";
    const modelEquipmentField = isCli ? DeviceFieldValues.MODEL : CpeFieldValues.PRODUCT_CLASS;
    const firmwareEquipmentField = isCli? DeviceFieldValues.FIRMWARE : CpeFieldValues.SW_VERSION;

    this.modelRestrictionsFullText = this.ruleOptionsService.format(
      rules,
      this.equipmentModelRestrictionTypes,
      modelEquipmentField
    );
    this.modelRestrictionsCroppedText = this.cutStringFilter(
      this.modelRestrictionsFullText,
      this.modelRestrictionsMaxTextSize
    );
    this.firmwareRestrictionFullText = this.ruleOptionsService.format(
      rules,
      this.equipmentModelRestrictionTypes,
      firmwareEquipmentField
    );
    this.firmwareRestrictionsCroppedText = this.cutStringFilter(
      this.firmwareRestrictionFullText,
      this.firmwareRestrictionsMaxTextSize
    );
    this.templatesNames = this.cutStringFilter(
      this.getTemplatesNames(),
      this.templatesNamesMaxTextSize
    );
    this.firmwareOrSoftwareVersionLabel =
      this.templateInstanceUtils.getEquipmentLabelKeyByTemplateType(
        this.templateType,
        this.firmwareOrSoftwareKeys
      );
  }

  getTemplatesNames() {
    return this.templateInstanceService.getJoinedProperties(
      this.templates,
      "templateName"
    );
  }

  getAllTemplatesRules() {
    return _.map(this.templates, (template) => {
      return {
        hasRuleOptions: template.restrictTemplate,
        rulesMatchingMode: template.equipmentRestrictionFilter.rulesMatchingMode,
        ruleOptions: template.equipmentRestrictionFilter.equipmentRuleOption,
      };
    });
  }
}
