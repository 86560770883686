import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import pythonTemplateExpressions from './python-template-expressions';

/**
 * Serviço responsável por retornar funcionalidades utilizada statement in python.
 */
@Injectable({
    providedIn: "root"
})
export class PythonExpressionService {

    private readonly INDENTATION_SPACE: string = "\n        ";
    private readonly formatCommandPython: any = {
        start: "",
        end: ""
    };

    constructor(@Inject(ANGULARJS_TRANSLATE) private translate: any) {}

    public loadExpressions() {
        let expressionsAggregator = [
            {
                nameLabel: this.translate.instant("templateform.python.expressions.methodsTR069"),
                expressions: this.createMethodsTR069(),
                name: "tr069method"
            },
            {
                nameLabel: this.translate.instant("templateform.python.expressions.methodsCpe"),
                expressions: this.createMethodsCPE(),
                name: "cpe"
            },
            {
                nameLabel: this.translate.instant("templateform.python.expressions.templateFunctions"),
                expressions: this.createTemplateFunctions(),
                name: "templateFunctions"
            },
            {
                nameLabel: this.translate.instant("templateform.python.expressions.statements"),
                expressions: this.createStatements(),
                name: "statements"
            },
        ];
        return expressionsAggregator;
    }

    public createStatements() {
        let expressions = [
            {name: this.translate.instant("templateform.buttons.insertComment"), fn: this.getCommentSample, tooltip: this.translate.instant("templateform.commands.tooltip.comment")},
            {name: "For (loop)", fn: this.getForSample, tooltip: this.translate.instant("templateform.commands.tooltip.for")},
            {name: "Function", fn: this.getFunctionSample, tooltip: this.translate.instant("templateform.commands.tooltip.function")},
            {name: "If", fn: this.getIfSample, tooltip: this.translate.instant("templateform.commands.tooltip.if")},
            {name: "If/else", fn: this.getIfElseSample, tooltip: this.translate.instant("templateform.commands.tooltip.ifElse")},
            {name: "Main function", fn: this.getMainFunctionSample, tooltip: this.translate.instant("templateform.commands.tooltip.main")},
            {name: "Print", fn: this.getPrintSample, tooltip: this.translate.instant("templateform.commands.tootlip.print")},
            {name: "While (loop)", fn: this.getWhileSample, tooltip: this.translate.instant("templateform.commands.tooltip.while")}
        ]
        return expressions;
    }

    public createMethodsTR069() {
        let methods = [
            {name: "addObject(String path)", fn: this.getAddObjectSample, tooltip: this.translate.instant("templateform.commands.tooltip.addObject") },
            {name: "deleteObject(String path)", fn: this.getDeleteObjectSample, tooltip: this.translate.instant("templateform.commands.tooltip.deleteObject")},
            {name: "download(String fileUrl, int fileType)", fn: this.getDownloadSample, tooltip: this.translate.instant("templateform.commands.tooltip.download")},
            {name: "downloadConfigFile(String fileUrl)", fn: this.getDownloadSample, tooltip: this.translate.instant("templateform.commands.tooltip.downloadConfigFile")},
            {name: "downloadFirmwareFile(String fileUrl)", fn: this.getDownloadSample, tooltip: this.translate.instant("templateform.commands.tooltip.downloadFirmwareFile")},
            {name: "getParameterNames(String path, boolean nextlevel)", fn: this.getParameterNamesSample, tooltip: this.translate.instant("templateform.commands.tooltip.getParameterNames")},
            {name: "getParameterValues(String path)", fn: this.getParameterValuesSample, tooltip: this.translate.instant("templateform.commands.tooltip.getParameterValues.singlePath")},
            {name: "getParameterValues(List<String> paths)", fn: this.getMultipleParameterValuesSample, tooltip: this.translate.instant("templateform.commands.tooltip.getParameterValues.multiplePath")},
            {name: "setParameterValues(String path, Object value)", fn: this.getSetParameterValuesSample, tooltip: this.translate.instant("templateform.commands.tooltip.setParameterValues.singlePath")},
            {name: "setParameterValues(Map<String, Object> parameterValues)", fn: this.getSetMultipleParameterValuesSample, tooltip: this.translate.instant("templateform.commands.tooltip.setParameterValues.multiplePath")},
            {name: "reboot()", fn: this.getRebootSample, tooltip: this.translate.instant("templateform.commands.tooltip.reboot")}
        ]
        return methods;
    }

    public createMethodsCPE() {
        let methods = [
            {
                name: "getConnectionRequestUrl()",
                fn: this.getConnectionRequestUrlSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getConnectionRequestUrl")
            },
            {
                name: "getHardwareVersion()",
                fn: this.getHardwareVersionSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getHardwareVersion")
            },
            {
                name: "getHostname()",
                fn: this.getHostnameSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getHostname")
            },
            {
                name: "getLastInform()",
                fn: this.getLastInformSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getLastInform")
            },
            {
                name: "getManufacturer()",
                fn: this.getManufacturerSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getManufacturer")
            },
            {
                name: "getProductModel()",
                fn: this.getProductModelSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getProductModel")
            },
            {
                name: "getProvisioningCode()",
                fn: this.getProvisioningCodeSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getProvisioningCode")
            },
            {
                name: "getSerialNumber()",
                fn: this.getSerialNumberSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getSerialNumber")
            },
            {
                name: "getSoftwareVersion()",
                fn: this.getSoftwareVersionSample,
                tooltip: this.translate.instant("templateform.commands.tooltip.getSoftwareVersion")
            }
        ];
        return methods;
    }

    public createTemplateFunctions() {
        let functions = [
            {name: "hasValue()", fn: this.getHasValueSample, tooltip: this.translate.instant("templateform.commands.tooltip.hasValue")},
            {name: "loadValue()", fn: this.getLoadValueSample, tooltip: this.translate.instant("templateform.commands.tooltip.loadValue")},
            {name: "saveValue()", fn: this.getSaveValueSample, tooltip: this.translate.instant("templateform.commands.tooltip.saveValue")},
            {name: "stop()", fn: this.getStopSample, tooltip: this.translate.instant("templateform.commands.tooltip.stop")}
        ];
        return functions;
    }

    public getVariableTooltip() : string {
        return this.translate.instant("templateform.variables.tooltips.python");
    }

    public getCommentSample = () : string => {
        let comment = this.translate.instant("templateform.variables.messages.commentSample");
        return pythonTemplateExpressions.createCommentStatement(comment);
    };

    public getForSample = (variables?) : string => {
        if (this.hasItems(variables)) {
            var { firstVarName, varNames } = this.extractExpressionParams(variables);
            var variable_item = `${firstVarName}_item`;
            if (variables.length > 1) {
                let printVars = varNames.map(name => `print(${name}[index])`).join(this.INDENTATION_SPACE);

                return pythonTemplateExpressions.createMultipleParameterizedForStatement(variable_item, firstVarName, printVars);
            } else {
                return pythonTemplateExpressions.createParameterizedForStatement(variable_item, firstVarName);
            }
        }

        return pythonTemplateExpressions.createForStatement();
    }

    public getFunctionSample = (variables?) : string => {
        let varsNames = this.hasItems(variables) ? variables.map(({ name }) => name) : [`'some message'`];
        let functionParams = varsNames.length === 0 ? "" : varsNames.join(", ");
        let varPrints = varsNames.map((varName) => `print(${varName})`).join(this.INDENTATION_SPACE);

        return pythonTemplateExpressions.createFunctionStatement(functionParams, varPrints);
    }

    public getIfSample = (variables?) : string  => {
        let {firstVarName, varNames} = this.extractExpressionParams(variables);
        let printVars = `print('some message')`;

        if (this.hasItems(variables) && variables.length > 1) {
            printVars = varNames
                .map(name => `print(${name})`)
                .join(this.INDENTATION_SPACE);
        }

        return pythonTemplateExpressions.createIfStatement(firstVarName, printVars);
    }

    public getIfElseSample = (variables?) : string => {
        let printVars = `print('some message')`;
        let someOtherMessage = "'some other message'";
        let {firstVarName, varNames} = this.extractExpressionParams(variables);

        if (this.hasItems(variables) && variables.length > 1) {
            printVars = varNames
                .map(name => `print(${name})`)
                .join(this.INDENTATION_SPACE);
        }

        return pythonTemplateExpressions.createIfElseStatement(firstVarName, printVars, someOtherMessage);
    }

    public getMainFunctionSample = () : string => {
        return pythonTemplateExpressions.createMainFunctionStatement();
    }

    public getPrintSample = () : string => {
        return pythonTemplateExpressions.createPrintStatement();
    }

    public getWhileSample = (variables?) : string => {
        let someVariable = "some_variable";
        let loadVar = `print(${someVariable})`;

        if (this.hasItems(variables)) {
            loadVar = variables.map(({ name }) => `print(${name})`).join(this.INDENTATION_SPACE);
        }

        return pythonTemplateExpressions.createWhileStatement(someVariable, loadVar);
    }

    public getAddObjectSample = () : string  => {
        return pythonTemplateExpressions.createAddObjectTr069Method();
    }

    public getDeleteObjectSample = () : string => {
        return pythonTemplateExpressions.createDeleteObjectTr069Method();
    }

    public getDownloadSample = () : string  => {
        return pythonTemplateExpressions.createDownloadTr069Method();
    }

    public getParameterNamesSample = () : string => {
        return pythonTemplateExpressions.createGetParameterNamesTr069Method();
    }

    public getParameterValuesSample = (): string  => {
        let parameters = `'InternetGatewayDevice.SomePath.SomeParameter'`;
        return pythonTemplateExpressions.createGetParameterValuesTr069Method(parameters);
    }

    public getMultipleParameterValuesSample = () : string  => {
        let parameters = `['InternetGatewayDevice.SomePath.SomeParameter', 'InternetGatewayDevice.SomePath.SomeOtherParameter1']`;
        return pythonTemplateExpressions.createGetParameterValuesTr069Method(parameters);
    }

    public getSetParameterValuesSample = (variables?) : string  => {
        var parameterValue = `'someValue'`;
        if (this.hasItems(variables)) {
            parameterValue = variables[0].name;

            if (variables.length > 1) {
                return this.getSetMultipleParameterValuesSample(variables);
            }
        }

        return pythonTemplateExpressions.createSetParameterValuesTr069Method(parameterValue);
    }

    public getSetMultipleParameterValuesSample = (variables?) : string  => {
        let params = [
            `'InternetGatewayDevice.SomePath.SomeParameter': 'someValue'`,
            `'InternetGatewayDevice.SomePath.SomeOtherParameter1': 'someOtherValue1'`
        ];

        if (this.hasItems(variables)) {
            let parameterValues = variables.map(variable => variable.name);

            if (parameterValues.length === 1) {
                parameterValues.push(`'someOtherValue1'`);
            }
            params = parameterValues.map((parameterValue, index) => {
                let path = `'InternetGatewayDevice.SomePath.SomeParameter'`
                if (index >= 1) {
                    path = `'InternetGatewayDevice.SomePath.SomeOtherParameter${index}'`
                }
                return `${path}: ${parameterValue}`;
            });
        }

        return pythonTemplateExpressions.createMultipleSetParameterValuesTr069Method(params.join(", "));
    }

    public getRebootSample = () : string  => {
        return pythonTemplateExpressions.createRebootTR069Method();
    }

    public getConnectionRequestUrlSample = () : string => {
        return pythonTemplateExpressions.createGetConnectionRequestUrlCpeMethod();
    }

    public getHardwareVersionSample = () : string  => {
        return pythonTemplateExpressions.createGetHardwareVersionCpeMethod();
    }

    public getHostnameSample = () : string  => {
        return pythonTemplateExpressions.createGetHostnameCpeMethod();
    }

    public getLastInformSample = () : string  => {
        return pythonTemplateExpressions.createGetLastInformCpeMethod();
  }

    public getManufacturerSample = () : string  => {
        return pythonTemplateExpressions.createGetManufacturerCpeMethod();
    }

    public getProductModelSample = () : string => {
        return pythonTemplateExpressions.createGetProductModelCpeMethod();
    }

    public getProvisioningCodeSample = () : string => {
        return pythonTemplateExpressions.createGetProvisioningCodeCpeMethod();
    }

    public getSerialNumberSample = () : string => {
        return pythonTemplateExpressions.createGetSerialNumberCpeMethod();
    }

    public getSoftwareVersionSample = () : string => {
        return pythonTemplateExpressions.createGetSoftwareVersionCpeMethod();
    }

    public getHasValueSample = () : string => {
        return pythonTemplateExpressions.createHasValueTemplateFunction();
    }

    public getLoadValueSample = () : string => {
        return pythonTemplateExpressions.createLoadValueTemplateFunction();
    }

    public getSaveValueSample = () : string => {
        return pythonTemplateExpressions.createSaveValueTemplateFunction();
    }

    public getStopSample = () : string => {
        return pythonTemplateExpressions.createStopTemplateFunction();
    }

    public getFormatCommandPython() {
        return this.formatCommandPython;
    }

    private hasItems = (array) : boolean => {
        return array && array.length !== 0;
    }

    /**
     * Extrai os parâmetros de uma expressão.
     * Retorna o nome da primeira variável separado e as demais em um array de nomes.
     */
    private extractExpressionParams = (variables: undefined | {name: string}[]) : {firstVarName: string, varNames: string[]} => {
        if (!this.hasItems(variables)) {
            return {
                firstVarName: "some_variable",
                varNames: []
            }
        }

        let [firstVarName, ...varNames] = variables.map(({ name }) => name);
        return {
            firstVarName,
            varNames
        }
    }

}