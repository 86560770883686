"use strict";

/**
 * Diretiva responsável por inserir o componente de contagem progressiva
 * que exibe o tempo de duração da atualização automática.
 */

var app = angular.module("nms");

app.directive("autoUpdaterCounter",
    function() {
        return {
            templateUrl: "templates/components/ui/auto-updater/auto-updater-counter.html",
            controller: "AutoUpdaterCounterController",
            scope: true
        };
    }
);