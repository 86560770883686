var app = angular.module("nms.dynamicDevice");

app.controller("ListEditAddModalController", ["$scope", "$rootScope", "$filter", "pathKeys", "keys", "parentPath", "list",
    "configurableChildren", "config", "$translate", "DomainHandlerService", "guiSettings", "changesValidator",
    "DataPathService", "YangStatements", "WhenVerifierService",
    function($scope, $rootScope, $filter, pathKeys, keys, parentPath, list, configurableChildren, config, $translate,
    DomainHandlerService, guiSettings, changesValidator, DataPathService, YangStatements, WhenVerifierService) {
        var acceptedStatements = ["leaf-lists", "choices", "leaves"];
        $scope.keys = keys;
        $scope.configurableChildren = configurableChildren;
        $scope.list = list;
        $scope.entry = angular.copy($scope.list.template);
        $scope.config = config;
        $rootScope.readOnly = false;
        $scope.listPath = DomainHandlerService.listPath($scope.parentPath, $scope.list.id);
        $scope.listEntriesPath = DomainHandlerService.listEntriesPath($scope.listPath);
        $scope.node = $scope.entry;
        $scope.parentPath = parentPath + $scope.listEntriesPath;
        $scope.guiSettings = guiSettings;
        $scope.pathKeys = pathKeys;
        $scope.isTransient = true;
        $scope.isSingleEditing = false;
        $scope.insideModal = true;

        $scope.confirmChangeValues = function() {
            if ($scope.guiSettings.changesRequired) {
                validateAndConfirmMultipleEditingChanges();
            } else {
                validateAndConfirmMultipleEditingOrAdd();
            }
        };

        function validateAndConfirmMultipleEditingChanges() {
            if (!hasChanges()) {
                $rootScope.showDialog({
                    translateKey: "yang.list.modal.noConfigChanged"
                });

                return false;
            }
            $scope.confirm(angular.copy($scope.entry));
        }

        function validateAndConfirmMultipleEditingOrAdd() {
            var validatedResult = changesValidator($scope.entry);
            if (!validatedResult) {
                $rootScope.showDialog({
                    translateKey: "yang.list.modal.fillRequiredFields"
                });

                return false;
            } else {
                $scope.confirm(validatedResult);
            }
        }

        function hasChanges() {
            return _.some($scope.entry, function(entry, key) {
                if (!_.isEmpty(entry) && _.includes(acceptedStatements, key)) {
                    return _.find(entry, "shouldEdit", true);
                }
            });
        }

        /**
        * Remove os nodos condicionados por WHEN ou DISPLAY_WHEN dos modelos apresentados na view.
        * A diretiva list-edit-modal, reaproveita as diretivas 'leaflists' e 'choices' para apresentação dos respectivos nodos.
        * Isso obriga a manter o modelo deste controller na mesma estrutura dos nodos reais apresentados na Info/Config.
        * Os nodos apresentados atualmente por esta diretiva são:
        * 1) leaves: atrelado ao modelo $scope.configurableChildren.leaves
        * 2) leaflists: atrelado ao modelo $scope.node['leaf-lists']. Tem que estar nesta estrutura para ser compatível com
        *                a diretiva 'leaflists'
        * 3) choices: atrelado ao modelo $scope.node.choices. Tem que estar nesta estrutura para ser compatível com
        *              a diretiva 'choices'
        *
        * TODO: Seria interessante avaliar as seguintes alterações nesta diretiva (e relacionados)
        * 1) Alterar a list-edit-add-modal.html para não mais utilizar as diretivas leaflists e choices, reaproveitando
        *    apenas as diretivas de representação individual: leaflist e choice.
        *    Isso daria mais flexibilidade a esta diretiva, pois seria possível aplicar uma estrutura própria neste escopo,
        *    e seria mais fácil implementar peculiaridades desta modal aqui.
        *
        * 2) Remover das diretivas leaf-body, leaflist e choice, parâmetros e elementos espefíficos que foram adicionados para o
        *    uso nesta modal. Por exemplo, o botão de toggle, para indicar se o valor deve ser alterado ou não. Este botão é uma
        *    peculiaridade desta modal, então talvez ele devesse estar em seu html, e não nas diretivas dos nodos, que tem o uso
        *    principal fora desta modal e acaba tendo que carregar um excesso de parâmetros e elementos que são escondidos na
        *    maioria das vezes.
        *
        * 3) Rever os atributos injetados na diretiva e adicionados no escopo. Aparentemente, tem muita coisa sobrando. Talves
        *    um pouco seja por causa do reuso das diretivas leaflists e choices.
        *
        * 4) Avaliar o custo benefício em manter esta diretiva genérica. Esta diretiva foi desenvolvida com o intuíto de ser
        *    pouco acoplada as regras de negócio da sua utilização nas listas. Por isso, muitas parametrizações, validações
        *    e peculiaridades dela são tratadas lá na list-body-controller, através da injeção de funções e parâmetros.
        *    Entretanto, me parece que atualmente ela é utilizada apenas em um ponto. Se não houver intenção de reaproveitar a
        *    diretiva em outro local com outro tipo de uso, eu acho que compensaria trazer as suas responsabilidades pra cá e
        *    aliviar um pouco a list-body-controller.
        */
        function removeWhenConditionedNodes() {
            var allLeafLists = $scope.node[YangStatements.LEAFLISTS];
            $scope.node[YangStatements.LEAFLISTS] = _.pick(allLeafLists, _.negate(WhenVerifierService.hasWhenCondition));
            var allChoices = $scope.node[YangStatements.CHOICES];
            $scope.node[YangStatements.CHOICES] = _.pick(allChoices, _.negate(WhenVerifierService.hasWhenCondition));

            $scope.configurableChildren = angular.copy(configurableChildren);
            $scope.configurableChildren.leaves = _.filter(configurableChildren.leaves, function(leafName) {
                var leafNode = $scope.entry.leaves[leafName];
                return !WhenVerifierService.hasWhenCondition(leafNode);
            });
        }

        function isNotHiddenNode(node) {
            return !$filter("isHidden")(node);
        }

        /**
        * Remove nós hidden das entradas de lista.
        */
        function removeHiddenNodes() {
            var allLeafLists = $scope.entry[YangStatements.LEAFLISTS];
            $scope.entry[YangStatements.LEAFLISTS] = _.pick(allLeafLists, isNotHiddenNode);
            var allChoices = $scope.entry[YangStatements.CHOICES];
            $scope.entry[YangStatements.CHOICES] = _.pick(allChoices, isNotHiddenNode);
            var allLeaves = $scope.entry[YangStatements.LEAVES];
            $scope.entry[YangStatements.LEAVES] = _.pick(allLeaves, isNotHiddenNode);
        }

        /**
        * Popula as leafs do template default ($scope.entry) adicionando o valor default quando existir
        * e a propriedade shouldEdit para definir quando um campo deve ser alterado ou não.
        */
        function fillDefaultTemplate() {
            _.forOwn($scope.entry, function(domain, key) {
                if (domain && !_.isEmpty(domain) && _.includes(acceptedStatements, key)) {
                    _.forOwn(domain, function(node, key) {
                        node.shouldEdit = false;
                        var nodeDefaultValue = node["sub-statements"].default;
                        if (nodeDefaultValue) {
                            node.value = nodeDefaultValue;
                        }
                    });
                }
            });
        }

        function initialize() {
            removeWhenConditionedNodes();
            fillDefaultTemplate();
            removeHiddenNodes();
        }

        initialize();
    }
]);
