var app = angular.module("nms");

/**
 * Service to control the session preferences.
 *
 * @author andre.pires
 */
app.service("SessionPreferencesService", [
    function() {
        var filters: any = {
            showStatus: true,
            showConfig: true
        };
        var accordionSettingsMap: any = {};

        this.getAccordionSettings = function(path) {
            if (!accordionSettingsMap[path]) {
                accordionSettingsMap[path] = {
                    openedStatus: true,
                    openedConfig: true
                };
            }

            return accordionSettingsMap[path];
        };

        this.getFilters = function() {
            return filters;
        };
    }
]);
