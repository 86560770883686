/**
* @ngdoc service
* @name nms.DeviceCommonService
* @description - Serviço com funções comuns sobre Device.
*/
var app = angular.module("nms");

app.service("DeviceCommonService", [function() {
    var service: any = {};

    /**
     * Retorna o IP/Hostname do device passado por parâmetro.
     * Para equipamentos remotos, esse campo será concatenado com a porta de acesso do proxy e dev no e dev local id.
     */
    service.getDeviceIpHostnameLabel = function(device) {
        var hostname = extractHostname(device);

        if (!service.isLocalDevice(device)) {
            var proxyAccessData = "";
            var remoteIdentification = " (" + extractDevNo(device) + "." + extractDevLocalId(device) + ")";

            if (hasAccessData(device)) {
                proxyAccessData = " (eth " + extractRemoteAccesSlot(device) + "/" + extractRemoteAccesPort(device) + ")";
            }

            let remoteSuffix = proxyAccessData + remoteIdentification;
            if (!hostname.endsWith(remoteSuffix)) {
                return hostname + remoteSuffix;
            }
        }

        return hostname;
    };

    service.isLocalDevice = function(device) {
        return (extractDevLocalId(device) === 1 && extractDevNo(device) === 1);
    };

    var extractHostname = function(device) {
        return device.hostname;
    };

    var extractDevNo = function(device) {
        if (hasDeviceNumber(device)) {
            return device.number.number;
        } else if (hasDevNo(device)) {
            return device.devNo;
        }
    };

    var extractDevLocalId = function(device) {
        if (hasDeviceNumber(device)) {
            return device.number.localId;
        } else if (hasDevNo(device)) {
            return device.devLocalId;
        }
    };

    var extractRemoteAccesSlot = function(device) {
        return device.accessData.slot;
    };

    var extractRemoteAccesPort = function(device) {
        return device.accessData.port;
    };

    var hasAccessData = function(device) {
        return device.hasOwnProperty("accessData") && device.accessData;
    };

    var hasDeviceNumber = function(device) {
        return device.hasOwnProperty("number") && device.number;
    };

    var hasDevNo = function(device) {
        return device.hasOwnProperty("devNo") && device.devNo;
    };

    return service;
}]);
