var app = angular.module("nms.dynamicDevice");

app.directive("leafBody", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            controller: "LeafController",
            scope: {
                config: "=",
                leaf: "=",
                form: "=",
                isKey: "=",
                pathKeys: "=?",
                getPathKeys: "&",
                isTransient: "=",
                isInsideListBody: "=",
                watchChanges: "=",
                isSingleEditing: "=",
                isLeafList: "=",
                isLeafListInput: "="
            },
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/leaf-body.html",
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);
