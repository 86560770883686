"use strict";

var app = angular.module("AuthenticationModule");

app.factory("AuthenticationRESTService", ["Restangular", "$window",
    function(Restangular, $window) {
        const service: any = {};
        var baseUrl = "/security";

        service.doLogin = function(credentials) {
            var token = $window.btoa(credentials.username + ":" + credentials.password);
            var header: any = {Authorization: "Basic " + token};
            var captcha = credentials.captcha;

            if (captcha) {
                header.Captcha = captcha;
            }

            return Restangular.one(baseUrl).customGET("login", {}, header);
        };

        service.doLogoff = function() {
            return Restangular.one(baseUrl + "/logout").customPOST();
        };

        service.getAuthorizations = function() {
            return Restangular.all(baseUrl + "/authorizations").customGET();
        };

        service.getNextCaptcha = function() {
            return Restangular.all(baseUrl + "/captcha").customGET();
        };

        service.getSessionData = function() {
            return Restangular.all(baseUrl + "/sessionData").customGET();
        };

        service.namServiceEnable = function() {
            return Restangular.all(baseUrl + "/namEnable").customGET();
        };

        service.isAuthenticated = function() {
            return Restangular.all(baseUrl + "/isAuthenticated").customGET();
        };

        service.getTemplateRestrictions = function() {
            return Restangular.all(baseUrl + "/template-permissions").customGET();
        };

        return service;
    }
]);
