"use strict";

/**
* @ngdoc service
* @name nms.templates.DeviceDiscoverValidatorService
* @description
* # DeviceDiscoverValidatorService
* Factory in the nms.Templates.
*/
angular.module("nms.templates")
.factory("DeviceDiscoverValidatorService", ["$rootScope", "WindowService", "DEVICE_STATUS",
    function($rootScope, WindowService, DEVICE_STATUS) {
        const service: any = {};

        service.validateSearchByIp = function(form, ip, check, name) {
            var translateKey;

            if (!ip) {
                translateKey = "findAddDevice.error.noIp";
            } else if (!_.isEmpty(form.$error)) {
                translateKey = "findAddDevice.error.invalidIp";
            } else if (!check && !name) {
                translateKey = "findAddDevice.error.noName";
            }

            if (translateKey) {
                $rootScope.showDialog({translateKey: translateKey});

                return false;
            }

            return true;
        };

        service.validateSearchByHostname = function(hostname, check, name) {
            var translateKey;

            if (!hostname) {
                translateKey = "findAddDevice.error.noHostname";
            } else if (!check && !name) {
                translateKey = "findAddDevice.error.noName";
            }

            if (translateKey) {
                $rootScope.showDialog({translateKey: translateKey});

                return false;
            }

            return true;
        };

        service.validateSearchBySubNetwork = function(form, subNetwork) {
            var value = subNetwork;
            var translateKey;

            if (!value) {
                translateKey = "findAddDevice.error.noIpMask";
            } else {
                var subNetMask = value.indexOf("/") > -1 ? value.substring(value.indexOf("/") + 1) : "";
                if (parseInt(subNetMask) < 24 || 30 < parseInt(subNetMask)) {
                    translateKey = "findAddDevice.error.invalidMask";
                } else if (!_.isEmpty(form.$error)) {
                    translateKey = "findAddDevice.error.invalidSubNetwork";
                }
            }

            if (translateKey) {
                $rootScope.showDialog({translateKey: translateKey});

                return false;
            }

            return true;
        };

        service.validateSearchByIpRange = function(form, initialIp, finalIp ) {
            var translateKey;

            if ((!finalIp) && (!initialIp)) {
                translateKey = "findAddDevice.error.noIpRange";
            } else if (_.isEmpty(initialIp)) {
                translateKey = "findAddDevice.error.noInitialIp";
            } else if (_.isEmpty(finalIp)) {
                translateKey = "findAddDevice.error.noFinalIp";
            } else if ((!_.isEmpty(form.$error)) && (finalIp >= 0 && finalIp <= 255)) {
                translateKey = "findAddDevice.error.invalidInitialIp";
            } else if (finalIp < 0 || finalIp > 255) {
                translateKey = "findAddDevice.error.invalidFinalIp";
            } else if (parseInt(initialIp.substring(initialIp.lastIndexOf(".") + 1, initialIp.lastIndexOf(".") + 4)) > parseInt(finalIp)) {
                translateKey = "findAddDevice.error.ipInitialBiggerThanFinal";
            }

            if (translateKey) {
                $rootScope.showDialog({translateKey: translateKey});

                return false;
            }

            return true;
        };

        service.validateIfDeviceCanBeAddedAsCustom = function(device) {
            return device.status === DEVICE_STATUS.UNREACHABLE || device.status === DEVICE_STATUS.UNSUPPORTED;
        };

        service.validateIfDeviceCanBeAdded = function(device) {
            return device.status === DEVICE_STATUS.REACHABLE || device.status === DEVICE_STATUS.UNREACHABLE
                    || device.status === DEVICE_STATUS.UNSUPPORTED || device.status === DEVICE_STATUS.NOT_ADDED
                    || device.status === DEVICE_STATUS.UNAUTHENTICATED;
        };

        service.validateRefreshingDevices = function(devices) {
            var translateKey;

            if (_.isEmpty(devices)) {
                translateKey = "findAddDevice.error.noSelectedDevices";
            } else {
                var isAllBlock = _.every(devices, function(device) {
                    return !service.validateIfDeviceCanBeAdded(device);
                });
                if (isAllBlock) {
                    translateKey = devices.length > 1 ? "findAddDevice.error.devicesCannotRefresh" : "findAddDevice.error.deviceCannotRefresh";
                }
            }

            if (translateKey) {
                $rootScope.showDialog({translateKey: translateKey});
                return false;
            }

            return true;
        };

        return service;
    }]);
