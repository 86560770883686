var app = angular.module("nms.dynamicDevice");

/**
 * Restringe um valor, um intervalo ou mais de um intervalo digitado pelo usuário.
 * Utilizada em campos do tipo text que permitem a definição de intervalos para a configuração.
 *
 * @author icaro.damiani, patrick.bard
 */
app.service("UserRangeRestrictionService", ["LeafService", "RestrictionService",
    function(LeafService, RestrictionService) {
        this.RESTRICTION_NAME = "user-range";

        this.validate = function(modelValue, viewValue, type) {
            var areAllRangesInCorrectFormat = new RegExp("^(\\d+)((?!([ \\d]*-){2})\\s*[-,]\\s*\\d+)*$");
            var userRanges = modelValue || viewValue;
            var yangRanges = LeafService.getRanges(type);

            if (!userRanges || userRanges === "") {
                return true;
            }

            if (!yangRanges) {
                return true;
            }

            if (!(areAllRangesInCorrectFormat.test(userRanges))) {
                return false;
            }

            var userValues = RestrictionService.buildArrayOfValues(userRanges);

            if (_.isEmpty(userValues)) {
                return false;
            }

            var isBetweenYangRange = function(value, yangRange) {
                var detachedYangRange = yangRange.split("..");
                var yangRangeStart = parseFloat(detachedYangRange[0]);
                var yangRangeEnd = parseFloat(detachedYangRange[1]);

                return (value >= yangRangeStart && value <= yangRangeEnd);
            };

            var shouldBeInAtLeastOneYangRange = function(value) {
                return _.some(yangRanges, function(yangRange) {
                    return isBetweenYangRange(parseFloat(value), yangRange);
                });
            };

            return _.every(userValues, shouldBeInAtLeastOneYangRange);
        };
    }
]);
