import { Injectable, Inject } from "@angular/core";
import { STATE, NMS_STATES, TEMPLATE_TYPE, MANAGEMENT_DEVICE_REST_SERVICE } from "../upgraded-provider/upgraded-providers";
import { PermissionsActionsService } from "./permissions-actions.service";
import { DevicesActionsService } from "./devices-actions.service";

/**
 * Serviço que engloba ações relacionadas a templates e suas aplicações, relacionadas a atalhos de dropdown.
 * Exemplos: abertura da página de aplicação de template com equipamentos selecionados.
 */
@Injectable({
    providedIn: "root"
})
export class TemplateActionsService {
    constructor(
        @Inject(STATE) private readonly state: any,
        @Inject(NMS_STATES) private readonly nmsStates: any,
        @Inject(TEMPLATE_TYPE) private readonly templateType: any,
        @Inject(MANAGEMENT_DEVICE_REST_SERVICE) private readonly managementDeviceRestService: any,
        private readonly permissionsActionsService: PermissionsActionsService,
        private readonly devicesActionsService: DevicesActionsService
    ) {}

    tryApplyTemplateByDeviceId = (deviceId) => {
        this.managementDeviceRestService.findDeviceById(deviceId).then((device) => {
            this.tryApplyTemplate([device], this.templateType.CLI, true);
        });
    };

    tryApplyTemplate(devices, templateType, showDetailedMessage) {
        const params = [];
        if (this.verifyRulesToOpenConfigModal(devices, templateType)) {
            const callback = (equipment) => {
                this.applyTemplate(equipment, templateType);
            };

            this.permissionsActionsService.verifyDevicesPermission(devices, showDetailedMessage, callback, params);
        }
    }

    applyTemplate(equipment, templateType) {
        if (!templateType) {
            throw new Error("It is not possible to apply a template without a templateType value.");
        }

        this.state.go(this.nmsStates.templateInstance.add, { equipment, templateType: templateType.name });
    }

    viewTemplateApplication(devices, names, templateType) {
        if (this.verifyRulesToOpenConfigModal(devices, templateType)) {
            this.state.go(this.nmsStates.templateInstance.list, { devicesNames: names });
        }
    }

    verifyRulesToOpenConfigModal(devices, templateType) {
        if (!templateType) {
            throw new Error("It is not possible to verify the rules to open the config modal without a templateType value.");
        }

        if (_.isEqual(templateType, this.templateType.CLI)) {
            return (
                this.permissionsActionsService.verifyUserPermissions() &&
                this.devicesActionsService.areThereSelectedDevices(devices) &&
                this.devicesActionsService.hasSupportForTemplateApplication(devices) &&
                this.permissionsActionsService.verifyMultivendorLicence(devices)
            );
        }

        return (
            this.permissionsActionsService.verifyUserPermissions() && this.devicesActionsService.areThereSelectedDevices(devices)
        );
    }
}
