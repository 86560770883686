"use strict";

/**
 * @ngdoc function
 * @name nms.deviceDiscover.controller:DeviceDiscoverController
 * @description
 * # DeviceDiscoverController
 * Controller of the nms.deviceDiscover
 */

var app = angular.module("nms.deviceDiscover");

app.controller("DeviceDiscoverController", [
    "$rootScope",
    "$scope",
    "$translate",
    "$timeout",
    "DeviceDiscoverService",
    "CONTACT_RANGE_TYPE",
    "NetworkService",
    "DeviceDiscoverValidatorService",
    "ngDialog",
    "DEVICE_STATUS",
    "LOCATION",
    "CUSTOM_DEVICE",
    "DevicesActionsService",
    "CredentialsRestService",
    "CredentialsService",
    "LocationService",
    "PropertiesService",
    "UserPreferencesService",
    "DeviceCommonService",
    "PropertiesModel",
    "TABLE_PROPERTIES",
    "AuthenticationService",
    "ConnectivityTestService",
    "EquipmentsModalsActionsService",
    function (
        $rootScope,
        $scope,
        $translate,
        $timeout,
        DeviceDiscoverService,
        CONTACT_RANGE_TYPE,
        NetworkService,
        DeviceDiscoverValidatorService,
        ngDialog,
        DEVICE_STATUS,
        LOCATION,
        CUSTOM_DEVICE,
        DevicesActionsService,
        CredentialsRestService,
        CredentialsService,
        LocationService,
        PropertiesService,
        UserPreferencesService,
        DeviceCommonService,
        PropertiesModel,
        TABLE_PROPERTIES,
        AuthenticationService,
        ConnectivityTestService,
        EquipmentsModalsActionsService
    ) {
        const DEVICE_NOT_DISCOVERED_CODE = -1;
        const globalConfig = EquipmentsModalsActionsService.getDefaultGlobalConfig();
        const filterOptions = [
            {status: `findAddDevice.status.${DEVICE_STATUS.FINDING}`},
            {status: `findAddDevice.status.${DEVICE_STATUS.REACHABLE}`},
            {status: `findAddDevice.status.${DEVICE_STATUS.UNREACHABLE}`},
            {status: `findAddDevice.status.${DEVICE_STATUS.UNAUTHENTICATED}`},
            {status: `findAddDevice.status.${DEVICE_STATUS.UNSUPPORTED}`},
            {status: `findAddDevice.status.${DEVICE_STATUS.ADDED}`},
            {status: `findAddDevice.status.${DEVICE_STATUS.NOT_ADDED}`}
        ];

        $scope.discoveryCredentialsReady = false;
        $scope.ENTER = 13;
        $scope.ASC = "asc";
        $scope.DESC = "desc";
        $scope.userPassword = $translate.instant("findAddDevice.globalConfig");
        $scope.location = $translate.instant("findAddDevice.locationPath");
        $scope.properties = $translate.instant("findAddDevice.noPropertiesConfig");
        $scope.subnetwork = $translate.instant("findAddDevice.subnetwork");
        $scope.contactRangeType = CONTACT_RANGE_TYPE;
        $scope.deviceStatus = DEVICE_STATUS;
        $scope.findDevicesBy = $scope.findDevicesBy ? $scope.findDevicesBy : $scope.contactRangeType.SINGLE_IP;
        $scope.concludedHosts = 0;
        $scope.availableHosts = [];
        $scope.devices = [];
        $scope.devicesAddedInOperation = 0;
        $scope.clientId;
        $scope.pageSize = TABLE_PROPERTIES.DEFAULT_PAGE_SIZE;
        $scope.licenceValidator = {
            hasError: false,
            translateObj: {}
        };
        $scope.loadUserPreferences = false;
        $scope.discoveryTitles = {
            checkedAll: "findAddDevice.filter.title.all",
            checkedNone: "findAddDevice.filter.title.none",
            selectedItems: "findAddDevice.filter.title.checked"
        }
        $scope.progressBarPercentage = 0;
        $scope.searchTermByIp;
        $scope.searchTermByHostname;
        $scope.searchTermBySubNet;
        $scope.searchTermByRangeInitialIp;
        $scope.searchTermByRangeFinalIp;
        $scope.findDeviceOptions;
        $scope.typeSearchBy = $scope.typeSearchBy ? $scope.typeSearchBy : $scope.contactRangeType.SINGLE_IP;
        $scope.useHostnameAsName = true;
        $scope.useIpAsName = true;
        $scope.name = {
            forIp: "",
            forHostname: ""
        };
        var filters: any = {
            direction: $scope.ASC,
            column: "none"
        };
        $scope.table = {
            elements: [],
            visibleElements: [],
            firstIndex: 0,
            lastIndex: 0,
            totalPages: 1,
            currentPage: 1,
            lastVisitedPage: 1,
            pageSize: TABLE_PROPERTIES.DEFAULT_PAGE_SIZE,
            totalElements: 0,
            checked: { selected: [], selectAllCheckbox: false },
            filters: filters
        };
        $scope.isAddingDevice = false;

        var modal: any = {
            userPass: "credential",
            location: "location",
            properties: "properties"
        };
        var windowIdentifier = "DeviceDiscoveryOptions";

        var availablePropertiesKeysForLocalStorage = function () {
            return [
                "pageSize",
                "filters",
                "selectedOptions",
                "filterTitle",
                "findDevicesBy",
                "searchTermByIp",
                "searchTermByHostname",
                "searchTermBySubNet",
                "searchTermByRangeInitialIp",
                "searchTermByRangeFinalIp",
                "useHostnameAsName",
                "useIpAsName",
                "name"
            ];
        };

        var availablePropertiesKeysForSessionStorage = function () {
            return ["credentialsProperties", "locationProperties", "configProperties"];
        };

        const isFilterAllChecked = function() {
            return $scope.items.length === $scope.itemsSelected.length;
        }

        $scope.checkUncheckAll = function (checked, elements, callback) {
            checked.selected = DeviceDiscoverService.checkUncheckAll(checked.selectAllCheckbox, elements);
            if (typeof callback === "function") {
                callback();
            }
        };

        $scope.isAllStillSelected = function (checked, elements, callback) {
            $timeout(function () {
                $scope.isAllStillSelectedCallback(checked, elements, callback);
            }, 10);
        };

        $scope.isAllStillSelectedCallback = function (checked, elements, callback) {
            checked.selectAllCheckbox = checked.selected.length === elements.length;
            if (typeof callback === "function") {
                callback();
            }
        };

        $scope.filterResults = function(filterOptionsSelected?) {
            if (filterOptionsSelected) {
                $scope.itemsSelected = filterOptionsSelected;
            }
            if (isFilterAllChecked()) {
                $scope.table.elements = $scope.devices;
            } else if ($scope.itemsSelected.length === 0) {
                $scope.table.elements = [];
            } else {
                $scope.table.elements = _.filter($scope.devices, function(element) {
                    return _.includes($scope.itemsSelected, `findAddDevice.status.${element.status}`);
                });
            }
            $scope.refreshPage($scope.table.pageSize, 1);
            $scope.calculateTableFooterInfo();
            updatePreferences();
            /**
             * FIXME - Ao usar o downgrade do componente com o angularJS, o componente não consegue detectar as mudanças
             * ocorridas, sendo necessário 'força-las' com o $apply ou $digest.
             * Solução retirada do link abaixo.
             * https://stackoverflow.com/questions/64252186/downgraded-angular-component-with-no-change-detection-in-angularjs
             * Ao migrar esse componente para o angular 8 o timeout com o $apply abaixo não será mais necessário
             */
            $timeout(function() {
                $scope.$apply();
            }, 100);
        };

        $scope.findDevices = function (isAddedAfterDiscovery) {
            $scope.clearTableData();
            $scope.prepareAvailableHosts();
            $rootScope.hideLoadingPanel = true;

            if (isAddedAfterDiscovery) {
                DeviceDiscoverService.findAndAddDevices($scope.getHostsToFind(), $scope.clientId);
            } else {
                DeviceDiscoverService.find($scope.getHostsToFind(), $scope.clientId);
            }

            $rootScope.hideLoadingPanel = false;
        };

        $scope.getHostsToFind = function () {
            var hostsToFind = [];
            $scope.availableHosts.forEach(function (availableHost) {
                hostsToFind.push({
                    name: $scope.getDeviceName(availableHost),
                    networkAddress: availableHost,
                    credentials: $scope.getCredentials(availableHost),
                    defaultCredential: globalConfig.globalConfig,
                    externalLocation: globalConfig.properties.externalLocation,
                    internalLocation: globalConfig.properties.internalLocation,
                    notes: globalConfig.properties.notes,
                    project: globalConfig.properties.project,
                    locationId: globalConfig.location.locationId,
                    path: globalConfig.location.path
                });
            });
            return hostsToFind;
        };

        $scope.tryDiscoveryAndAdd = function () {
            DeviceDiscoverService.getAvailableNetworkElements(function (response) {
                var availableNetworkElements = response.availableNetworkElements;
                var availableHosts = $scope.getAvailableHosts();
                if (availableNetworkElements === 0) {
                    $rootScope.showDialogSingularOrPlural({
                        translateObjs: [
                            {
                                pluralKey: "findAddDevice.error.devicesNotAddedMaxDevicesReached",
                                singularKey: "findAddDevice.error.deviceNotAddedMaxDevicesReached",
                                isPlural: availableHosts.length > 1,
                                insideMsgParams: [availableHosts.length]
                            }
                        ],
                        maxChars: 128
                    });
                } else if (availableHosts.length > availableNetworkElements) {
                    $rootScope
                        .showDialogSingularOrPlural({
                            translateObjs: [
                                {
                                    pluralKey: "findAddDevice.confirm.noLicenseAvailable",
                                    isPlural: true,
                                    insideMsgParams: [availableHosts.length, availableNetworkElements]
                                },
                                {
                                    pluralKey: "findAddDevice.confirm.noLicenseAvailable.confirm",
                                    isPlural: true
                                },
                                {
                                    pluralKey: "findAddDevice.confirm.noLicenseAvailable.cancel",
                                    isPlural: true
                                }
                            ],
                            maxChars: 128,
                            isConfirm: true
                        })
                        .then(function () {
                            $scope.findDevices(true);
                        });
                } else {
                    $scope.findDevices(true);
                }
            });
        };

        $scope.calculateTableFooterInfo = function () {
            $scope.table.totalElements = $scope.table.elements.length;
            $scope.calculateVisibleRange();
            var pages = Math.ceil($scope.table.elements.length / $scope.table.pageSize);
            $scope.table.totalPages = pages > 0 ? pages : 1;
        };

        $scope.getDeviceName = function (hostname) {
            if ($scope.findDevicesBy === $scope.contactRangeType.SINGLE_IP && !$scope.useIpAsName) {
                return $scope.name.forIp;
            } else if ($scope.findDevicesBy === $scope.contactRangeType.HOSTNAME && !$scope.useHostnameAsName) {
                return $scope.name.forHostname;
            }

            return hostname;
        };

        $scope.getAvailableHosts = function () {
            switch ($scope.findDevicesBy) {
                case $scope.contactRangeType.SINGLE_IP:
                    return new Array($scope.searchTermByIp);
                case $scope.contactRangeType.HOSTNAME:
                    return new Array($scope.searchTermByHostname);
                case $scope.contactRangeType.SUBNETWORK:
                    return NetworkService.getAvailableHostsByNetwork($scope.searchTermBySubNet);
                case $scope.contactRangeType.SUBSEQUENT:
                    return NetworkService.getAvailableSubsequentHosts(
                        $scope.searchTermByRangeInitialIp,
                        $scope.searchTermByRangeFinalIp
                    );
            }
        };

        $scope.validateSearchForm = function (form) {
            switch ($scope.findDevicesBy) {
                case $scope.contactRangeType.SINGLE_IP:
                    return DeviceDiscoverValidatorService.validateSearchByIp(
                        form,
                        $scope.searchTermByIp,
                        $scope.useIpAsName,
                        $scope.name.forIp
                    );
                case $scope.contactRangeType.HOSTNAME:
                    return DeviceDiscoverValidatorService.validateSearchByHostname(
                        $scope.searchTermByHostname,
                        $scope.useHostnameAsName,
                        $scope.name.forHostname
                    );
                case $scope.contactRangeType.SUBNETWORK:
                    return DeviceDiscoverValidatorService.validateSearchBySubNetwork(form, $scope.searchTermBySubNet);
                case $scope.contactRangeType.SUBSEQUENT:
                    return DeviceDiscoverValidatorService.validateSearchByIpRange(
                        form,
                        $scope.searchTermByRangeInitialIp,
                        $scope.searchTermByRangeFinalIp
                    );
            }
        };

        $scope.discoveryDevices = function (form, isAddedAfterDiscovery) {
            if ($scope.validateSearchForm(form)) {
                DeviceDiscoverService.connect(function (clientId) {
                    $scope.clientId = clientId;
                    if (isAddedAfterDiscovery) {
                        $scope.tryDiscoveryAndAdd();
                    } else {
                        $scope.findDevices(false);
                    }
                }, $scope.receiveDeviceResponse);
            }
        };

        $scope.clearTableData = function () {
            $scope.devices = [];
            $scope.table.elements = [];
            $scope.table.visibleElements = [];
            $scope.table.checked.selectAllCheckbox = false;
            $scope.table.checked.selected = [];
            $scope.table.currentPage = 1;
            $scope.availableHosts = $scope.getAvailableHosts();
            $scope.concludedHosts = 0;
            $scope.progressBarPercentage = 0;
            $scope.devicesAddedInOperation = 0;
        };

        $scope.receiveDeviceResponse = function (contactedDevices) {
            contactedDevices.forEach(function (contactedDevice) {
                if (DeviceCommonService.isLocalDevice(contactedDevice)) {
                    $scope.concludedHosts++;
                    $scope.progressBarPercentage = (100 * $scope.concludedHosts) / $scope.availableHosts.length;
                    $scope.updateDevice(
                        _.find($scope.table.visibleElements, { hostname: contactedDevice.hostname }),
                        contactedDevice
                    );
                    $scope.updateDevice(_.find($scope.devices, { hostname: contactedDevice.hostname }), contactedDevice);
                    $scope.updateTableElementsIfNeeded(contactedDevice);
                } else {
                    $scope.addRemoteDeviceToList(contactedDevice, $scope.devices);
                    $scope.updateDevice(contactedDevice, contactedDevice);
                    $scope.updateTableElementsIfNeeded(contactedDevice);
                    $scope.table.totalElements++;
                    $scope.refreshPage($scope.table.pageSize, $scope.table.currentPage);
                }
                $scope.table.elements = angular.copy($scope.devices);
                $scope.waitForFindAndAddIfNeeded(contactedDevice);
            });
            $scope.filterResults();
        };

        $scope.waitForFindAndAddIfNeeded = function (contactedDevice) {
            if (contactedDevice.status === $scope.deviceStatus.ADDED && contactedDevice.addedInOperation) {
                $scope.devicesAddedInOperation++;
            }
            if ($scope.concludedHosts == $scope.availableHosts.length && $scope.devicesAddedInOperation > 0) {
                var translateKey =
                    $scope.devicesAddedInOperation === 1 ? "toastr.deviceSuccessfullyAdded" : "toastr.devicesSuccessfullyAdded";
                $rootScope.toastInfo(translateKey, [$scope.devicesAddedInOperation]);
            }
        };

        $scope.updateTableElementsIfNeeded = function (contactedDevice) {
            if (!isFilterAllChecked()) {
                if (_.some($scope.table.elements, { name: contactedDevice.name })) {
                    if (_.includes($scope.itemsSelected, `findAddDevice.status.${contactedDevice.status}`)) {
                        $scope.updateDevice(_.find($scope.table.elements, { name: contactedDevice.name }), contactedDevice);
                    } else {
                        _.remove($scope.table.elements, function (element) {
                            return element.name == contactedDevice.name;
                        });
                    }
                } else if (_.includes($scope.itemsSelected, `findAddDevice.status.${contactedDevice.status}`)) {
                    if (!contactedDevice.name) {
                        contactedDevice.name = contactedDevice.hostname;
                    }
                    contactedDevice.properties = angular.copy(globalConfig.properties);
                    contactedDevice.path = contactedDevice.path || globalConfig.location.path;
                    contactedDevice.locationId = contactedDevice.locationId || globalConfig.location.locationId;
                    $scope.updateDevice(contactedDevice, contactedDevice);
                    $scope.table.elements.push(contactedDevice);
                }
                $scope.refreshPage($scope.table.pageSize, $scope.table.currentPage);
                $scope.calculateTableFooterInfo();
            } else if (DeviceCommonService.isLocalDevice(contactedDevice)) {
                $scope.updateDevice(_.find($scope.table.elements, { hostname: contactedDevice.hostname }), contactedDevice);
            }
        };

        $scope.updateDevice = function (device, contactedDevice) {
            if (device) {
                var isAddedDevice = contactedDevice.status === $scope.deviceStatus.ADDED;
                device.name = isAddedDevice ? contactedDevice.name : $scope.getDeviceName(contactedDevice.name);
                if (!DeviceCommonService.isLocalDevice(contactedDevice)) {
                    let remoteSuffix = " - " + device.number.number + "." + device.number.localId;
                    if (!isAddedDevice && !device.name.endsWith(remoteSuffix)) {
                        device.name += remoteSuffix;
                    }
                }
                device.formattedHostname = $scope.getDeviceIpHostnameLabel(contactedDevice);
                device.status = contactedDevice.status;
                if (contactedDevice.model) {
                    device.model = {
                        code: contactedDevice.model.code,
                        name: contactedDevice.model.code != -1 ? $scope.getModelName(contactedDevice) : "",
                        vendor: contactedDevice.model.vendor
                    };
                }
                device.serialNumber = contactedDevice.serialNumber;
                device.firmwareVersion = contactedDevice.firmwareVersion;
                if (_.isUndefined(device.globalConfig)) {
                    device.globalConfig = globalConfig.globalConfig;
                    device.credentials = device.globalConfig ? globalConfig.globalCredentials : globalConfig.specificCredentials;
                }
                device.number = contactedDevice.number;
                device.proxyCode = contactedDevice.proxyCode;
                device.proxyModelCode = contactedDevice.proxyModelCode;
                device.properties.name = device.name;
                device.path = isAddedDevice ? contactedDevice.path : globalConfig.location.path;
                device.locationId = isAddedDevice ? contactedDevice.locationId : globalConfig.location.locationId;
            }
        };

        $scope.getModelName = function (contactedDevice) {
            return contactedDevice.status == DEVICE_STATUS.UNREACHABLE && contactedDevice.model.code == CUSTOM_DEVICE.modelCode
                ? ""
                : contactedDevice.model.name;
        };

        $scope.addRemoteDeviceToList = function (contactedDevice, deviceList) {
            var parentIndex = _.findIndex(deviceList, { hostname: contactedDevice.hostname });
            if (parentIndex !== -1) {
                contactedDevice.credentials = deviceList[parentIndex].credentials;
                contactedDevice.globalConfig = deviceList[parentIndex].globalConfig;
                contactedDevice.properties = angular.copy(globalConfig.properties);
                contactedDevice.path = contactedDevice.path || globalConfig.location.path;
                contactedDevice.locationId = contactedDevice.locationId || globalConfig.location.locationId;
                deviceList.splice(parentIndex + 1, 0, contactedDevice);
                var index = _.findIndex($scope.table.checked.selected, {
                    hostname: contactedDevice.hostname,
                    serialNumber: contactedDevice.serialNumber
                });
                if (index !== -1) {
                    $scope.table.checked.selected[index] = contactedDevice;
                }
            }
        };

        $scope.editDeviceUserPassConfig = function (device) {
            if ($scope.verifyMgmtProtocolConfigUserPermission()) {
                if ($scope.canEditDevices([device], modal.userPass)) {
                    $scope.openDeviceCredentialsModal(device);
                }
            }
        };

        $scope.editDeviceLocationConfig = function (device) {
            if ($scope.canEditDevices([device], modal.location)) {
                $scope.openLocationModal([device]);
            }
        };

        var updateDeviceProperties = function (device, properties) {
            if (properties.name) {
                device.name = properties.name;
                device.properties.name = properties.name;
            }

            device.properties.notes = properties.notes;
            device.properties.project = properties.project;
            device.properties.externalLocation = {
                address: properties.address,
                city: properties.city,
                country: properties.country,
                state: properties.state
            };
            device.properties.internalLocation = {
                room: properties.room,
                shelf: properties.shelf,
                station: properties.station
            };
        };

        $scope.tryOpenEditUserPass = function () {
            if ($scope.areThereSelectedDevices() && $scope.verifyMgmtProtocolConfigUserPermission()) {
                if ($scope.canEditDevices($scope.table.checked.selected, modal.userPass)) {
                    $scope.openDevicesCredentialsModal($scope.table.checked.selected);
                }
            }
        };

        $scope.tryOpenEditLocation = function () {
            if ($scope.areThereSelectedDevices()) {
                if ($scope.canEditDevices($scope.table.checked.selected, modal.location)) {
                    $scope.openLocationModal($scope.table.checked.selected);
                }
            }
        };

        $scope.tryOpenPropertiesConfig = function () {
            if ($scope.areThereSelectedDevices()) {
                if ($scope.canEditDevices($scope.table.checked.selected, modal.properties)) {
                    $scope.openPropertiesModal($scope.table.checked.selected);
                }
            }
        };

        $scope.tryOpenConnectivityTest = function () {
            if ($scope.areThereSelectedDevices()) {
                // TODO [TA323424] Criar o objeto específico que será usado no serviço de teste de conectividade, evitando passar
                // dados desnecessários.
                var availableHosts = angular.copy($scope.table.checked.selected);

                _.forEach(availableHosts, function (availableHost) {
                    if (availableHost.model.code === DEVICE_NOT_DISCOVERED_CODE) {
                        availableHost.model = $translate.instant("connectivityTest.deviceModel.notDiscovered");
                    } else {
                        availableHost.modelCode = availableHost.model.code;
                        availableHost.model = availableHost.model.name;
                    }
                    availableHost.devNo = availableHost.number.number;
                    availableHost.devLocalId = availableHost.number.localId;
                });

                ConnectivityTestService.openConnectivityTestModal(availableHosts);
            }
        };

        const canAddDevices = function() {
            return !$scope.isAddingDevice
                && $scope.areThereSelectedDevices()
                && $scope.canAddSomeDevices($scope.table.checked.selected);
        }

        $scope.tryToAddDevices = function () {
            if (canAddDevices()) {
                $scope.addDevices($scope.table.checked.selected);
            }
        };

        $scope.canAddSomeDevices = function (devices) {
            var blockedDevices = $scope.getBlockedDevices(devices);

            if (blockedDevices.length === devices.length) {
                $scope.unselectNotEditableDevices(blockedDevices);

                var devicesNameMap = _.map(blockedDevices, function (device) {
                    return device.hostname + ": " + $translate.instant("findAddDevice.error.deviceCanNotBeAddedInCurrentState");
                });

                $rootScope.showDialogSingularOrPlural({
                    translateObjs: [
                        {
                            pluralKey: "findAddDevice.error.devicesCanNotBeAdded",
                            singularKey: "findAddDevice.error.deviceCanNotBeAdded",
                            isPlural: blockedDevices.length > 1,
                            insideMsgParams: [blockedDevices.length]
                        }
                    ],
                    listParams: devicesNameMap,
                    maxChars: 128
                });
                return false;
            }

            return true;
        };

        $scope.canEditDevices = function (devices, modalToOpen) {
            var blockedDevices = $scope.getBlockedDevices(devices);

            var devicesNameMap = _.map(_.map(blockedDevices, "hostname"), $scope.createLabel);

            if (blockedDevices) {
                $scope.unselectNotEditableDevices(blockedDevices);
            }

            if (!_.isEmpty(blockedDevices) && devicesNameMap.length === devices.length) {
                $scope.buildErrorMessageWhenNoDeviceCanBeEdited(devicesNameMap, modalToOpen);
                return false;
            } else if (!_.isEmpty(blockedDevices) && devicesNameMap.length !== devices.length) {
                $scope.buildErrorMessageWhenSomeDevicesCanNotBeEdited(devices, devicesNameMap, modalToOpen);
                return false;
            } else {
                return true;
            }
        };

        $scope.getBlockedDevices = function (devices) {
            var blockedDevices = _.filter(devices, function (device) {
                return (
                    device.status == $scope.deviceStatus.FINDING ||
                    device.status == $scope.deviceStatus.ADDED ||
                    device.status == $scope.deviceStatus.UPDATING
                );
            });

            return blockedDevices;
        };

        $scope.unselectNotEditableDevices = function (devicesNotEditable) {
            $scope.table.checked.selected = _.filter($scope.table.checked.selected, function (device) {
                return !_.include(_.map(devicesNotEditable, "name"), device.name);
            });

            $scope.isAllStillSelected($scope.table.checked, $scope.table.visibleElements);
        };

        $scope.buildErrorMessageWhenNoDeviceCanBeEdited = function (devicesName, modalToOpen) {
            var messageMap;
            var args = [];

            switch (modalToOpen) {
                case modal.userPass:
                case modal.properties:
                    args = devicesName;
                    messageMap = [
                        {
                            pluralKey: "findAddDevice.error.devicesCanNotBeEdited",
                            singularKey: "findAddDevice.error.deviceCanNotBeEdited",
                            isPlural: devicesName.length > 1,
                            insideMsgParams: [devicesName.length]
                        }
                    ];
                    break;
                case modal.location:
                    messageMap = [
                        {
                            pluralKey: "findAddDevice.error.devicesCanNotHaveLocationEdited",
                            singularKey: "findAddDevice.error.deviceCanNotHaveLocationEdited",
                            isPlural: devicesName.length > 1,
                            insideMsgParams: [devicesName.length]
                        }
                    ];
                    break;
            }

            $rootScope.showDialogSingularOrPlural({
                translateObjs: messageMap,
                listParams: args,
                maxChars: 128
            });
        };

        $scope.buildErrorMessageWhenSomeDevicesCanNotBeEdited = function (devices, devicesName, modalToOpen) {
            var messageMap;
            var args = [];

            switch (modalToOpen) {
                case modal.userPass:
                case modal.properties:
                    args = devicesName;
                    messageMap = [
                        {
                            pluralKey: "findAddDevice.error.devicesCanBeEdited",
                            singularKey: "findAddDevice.error.deviceCanBeEdited",
                            isPlural: devices.length > devicesName.length && devices.length - devicesName.length > 1,
                            insideMsgParams: [devices.length - devicesName.length]
                        },
                        {
                            pluralKey: "findAddDevice.error.othersDevicesCantBeEdited",
                            singularKey: "findAddDevice.error.otherDeviceCantBeEdited",
                            isPlural: devicesName.length > 1,
                            insideMsgParams: [devicesName.length]
                        }
                    ];
                    break;
                case modal.location:
                    messageMap = [
                        {
                            pluralKey: "findAddDevice.error.someDevicesCanNotHaveLocationEdited",
                            isPlural: true,
                            insideMsgParams: [devices.length, devices.length - devicesName.length]
                        }
                    ];
                    break;
            }

            $rootScope
                .showDialogSingularOrPlural({
                    translateObjs: messageMap,
                    listParams: args,
                    maxChars: 128,
                    isConfirm: true
                })
                .then(function () {
                    switch (modalToOpen) {
                        case modal.userPass:
                            $scope.openDevicesCredentialsModal($scope.table.checked.selected);
                            break;
                        case modal.location:
                            $scope.openLocationModal($scope.table.checked.selected);
                            break;
                        case modal.properties:
                            $scope.openPropertiesModal($scope.table.checked.selected);
                            break;
                    }
                });
        };

        $scope.createLabel = function (ip) {
            return ip + ": " + $translate.instant("findAddDevice.error.devicesCanNotBeEditedLabel");
        };

        $scope.openCredentialsModal = function () {
            if ($scope.verifyMgmtProtocolConfigUserPermission()) {
                var specificCredentials = globalConfig.globalConfig ? undefined : globalConfig.specificCredentials;
                var globalCredentials = globalConfig.globalCredentials;
                var options: any = {
                    specificCredentials: specificCredentials,
                    globalCredentials: globalCredentials,
                    guiSettings: {
                        subtitle: $translate.instant("modals.managementProtocols.modalSubTitleSingleHost")
                    },
                    finishCallback: function (credentialsConfig) {
                        setCredentialsToGlobalDiscovery(credentialsConfig);
                        $scope.$apply();
                    },
                    formData: CredentialsService.provideUpdatedCredentials(globalCredentials, specificCredentials)
                };
                CredentialsService.openGenericCredentialsModal(options);
            }
        };

        $scope.openDevicesCredentialsModal = function (devices) {
            if ($scope.verifyMgmtProtocolConfigUserPermission()) {
                if (devices.length === 1) {
                    $scope.openDeviceCredentialsModal(devices[0]);
                } else {
                    var specificCredentials = undefined;
                    var globalCredentials = globalConfig.globalCredentials;
                    var options: any = {
                        isMultipleSelection: true,
                        specificCredentials: specificCredentials,
                        globalCredentials: globalCredentials,
                        guiSettings: {
                            subtitle: $translate.instant("modals.managementProtocols.modalSubTitleSingleHost"),
                            tipMessage: $translate.instant("modals.managementProtocols.credentials.popoverDefaultConfig")
                        },
                        finishCallback: function (credentialsConfig) {
                            credentialsModalConfirmed(devices, credentialsConfig);
                        },
                        formData: CredentialsService.provideUpdatedCredentials(globalCredentials, specificCredentials)
                    };
                    CredentialsService.openGenericCredentialsModal(options);
                }
            }
        };

        $scope.openDeviceCredentialsModal = function (device) {
            if ($scope.verifyMgmtProtocolConfigUserPermission()) {
                var specificCredentials = device.globalConfig ? undefined : device.credentials;
                var globalCredentials = globalConfig.globalCredentials;
                var options: any = {
                    specificCredentials: specificCredentials,
                    globalCredentials: globalCredentials,
                    guiSettings: {
                        subtitle: device.hostname
                    },
                    finishCallback: function (credentialsConfig) {
                        credentialsModalConfirmed([device], credentialsConfig);
                    },
                    formData: CredentialsService.provideUpdatedCredentials(globalCredentials, specificCredentials)
                };
                CredentialsService.openGenericCredentialsModal(options);
            }
        };

        var setCredentialsToGlobalDiscovery = function (response) {
            globalConfig.globalConfig = response.globalConfig;
            globalConfig.specificCredentials = response.data;

            if (!globalConfig.globalConfig) {
                $scope.userPassword = $translate.instant("findAddDevice.specificConfig");
            } else {
                $scope.userPassword = $translate.instant("findAddDevice.globalConfig");
            }
        };

        var credentialsModalConfirmed = function (devices, response) {
            if (devices) {
                updateDevices(devices, response);

                var translateKey = devices.length > 1 ? "toastr.devicesSuccessfullyEdited" : "toastr.deviceSuccessfullyEdited";
                $rootScope.toastInfo(translateKey, [devices.length]);
            }

            if (_.some($scope.devices, { globalConfig: false })) {
                $scope.userPassword = $translate.instant("findAddDevice.specificConfig");
            } else {
                $scope.userPassword = $translate.instant("findAddDevice.globalConfig");
            }
        };

        var updateDevices = function (receivedDevices, response) {
            receivedDevices.forEach(function (receivedDevice) {
                receivedDevice.credentials = angular.copy(response.data);
                receivedDevice.globalConfig = response.globalConfig;
            });
            updateDevicesCredentialsHostname(receivedDevices);
        };

        var updateDevicesCredentialsHostname = function (devices) {
            _.forEach(devices, function (device) {
                CredentialsService.updateCredentialsHostname(device.credentials, device.hostname);
            });

            $scope.updateParentDevicesIfNeeded(devices);
            $scope.refreshDevices(devices);
        };

        var openSingleDevicePropertiesModal = function (device) {
            if ($scope.canEditDevices([device], modal.properties)) {
                var modalOptions: any = {
                    model: new PropertiesModel(device.properties, device.name),
                    finishCallback: function (properties) {
                        updateDeviceProperties(device, properties);
                        $rootScope.toastInfo("toastr.devicePropertiesSuccessfullyEdited");
                    },
                    validatorFunction: function (properties) {
                        return validateEmptyDeviceName(properties);
                    }
                };

                PropertiesService.openPropertiesModal(modalOptions);
            }
        };

        /**
         * Valida a propriedade de nome de gerência do equipamento existe.
         *
         * @param {object} objeto com as informações de propriedades.
         */
        var validateEmptyDeviceName = function (properties) {
            var deviceName = _.trim(_.get(properties, "name"));

            if (!_.isEmpty(deviceName)) {
                return true;
            }
            $rootScope.showDialog({ translateKey: "form.error.requiredFieldName" });

            return false;
        };

        var openMultipleDevicePropertiesModal = function (devices) {
            var modalOptions: any = {
                guiSettings: {
                    showNameField: false,
                    isChangeRequired: false,
                    tipMessage: $translate.instant("modals.properties.popoverDefaultProperties"),
                    subtitle: $translate.instant("modals.properties.modalSubTitleMultipleHosts")
                },
                finishCallback: function (properties) {
                    devices.forEach(function (device) {
                        updateDeviceProperties(device, properties);
                    });
                    $rootScope.toastInfo("toastr.devicesPropertiesSuccessfullyEdited", [devices.length]);
                }
            };

            PropertiesService.openPropertiesModal(modalOptions);
        };

        $scope.updateParentDevicesIfNeeded = function (devices) {
            var remotes = _.filter(devices, function (device) {
                return !DeviceCommonService.isLocalDevice(device);
            });

            remotes.forEach(function (remote) {
                var parentDevices = _.filter($scope.devices, { hostname: remote.hostname });
                parentDevices.forEach(function (parentDevice) {
                    parentDevice.globalConfig = remote.globalConfig;
                });
            });
        };

        $scope.getDeviceIpHostnameLabel = function (device) {
            return DeviceCommonService.getDeviceIpHostnameLabel(device);
        };

        $scope.addDevices = function (devices) {
            $scope.isAddingDevice = true;

            var noCustoms = _.filter(devices, function (device) {
                return !$scope.isCustomDevice(device);
            });

            $scope.validateLicences(noCustoms, devices, function () {
                if ($scope.licenceValidator.addOnlyCustom) {
                    devices = _.difference(devices, noCustoms);
                }
                if (noCustoms.length === devices.length) {
                    DeviceDiscoverService.addDevices(devices, $scope.onAddResponse);
                } else {
                    $rootScope
                        .showDialogSingularOrPlural({
                            translateObjs: [
                                {
                                    pluralKey: "findAddDevice.error.someDevicesWillBeAddAsCustom",
                                    singularKey: "findAddDevice.error.deviceWillBeAddAsCustom",
                                    isPlural: devices.length - noCustoms.length > 1
                                }
                            ],
                            maxChars: 128,
                            isConfirm: true
                        })
                        .then(function () {
                            DeviceDiscoverService.addDevices(devices, $scope.onAddResponse);
                        });
                }
            });
        };

        $scope.isCustomDevice = function (device) {
            return device.status === $scope.deviceStatus.UNREACHABLE || device.status === $scope.deviceStatus.UNSUPPORTED;
        };

        $scope.validateLicences = function (noCustoms, devices, callback) {
            DeviceDiscoverService.getAvailableNetworkElements(function (response) {
                var hasCustom = devices.length !== noCustoms.length;
                $scope.licenceValidator.hasError = false;
                var availableNetworkElements = response.availableNetworkElements;
                if (noCustoms.length > availableNetworkElements) {
                    $scope.licenceValidator.hasError = true;
                    $scope.licenceValidator.addOnlyCustom = hasCustom;
                    if (availableNetworkElements > 0) {
                        $scope.licenceValidator.translateObj = [
                            {
                                pluralKey: hasCustom
                                    ? "findAddDevice.error.deviceCustomNoLicenseAvailable"
                                    : "findAddDevice.error.deviceNoLicenseAvailable",
                                isPlural: true,
                                insideMsgParams: [noCustoms.length, availableNetworkElements]
                            }
                        ];
                    } else {
                        $scope.licenceValidator.translateObj = [
                            {
                                singularKey: hasCustom
                                    ? "findAddDevice.error.customDeviceNotAddedMaxDevicesReached"
                                    : "findAddDevice.error.deviceNotAddedMaxDevicesReached",
                                pluralKey: hasCustom
                                    ? "findAddDevice.error.customDevicesNotAddedMaxDevicesReached"
                                    : "findAddDevice.error.devicesNotAddedMaxDevicesReached",
                                isPlural: noCustoms.length > 1,
                                insideMsgParams: [noCustoms.length]
                            }
                        ];
                    }
                }
                callback();
            });
        };

        $scope.onAddResponse = function (devices, response) {
            var insertedDevices = response.insertedDevices;
            var devicesAlreadyAdded = response.devicesAlreadyAdded;
            var violationMessage = response.violationMessage;
            var deviceInsertionResponseMessage = response.deviceInsertionResponseMessage;

            var notInsertedDevices = _.filter(devices, function (device) {
                return !_.some(insertedDevices, "devId", device.name);
            });
            $scope.unselectNotEditableDevices(notInsertedDevices);

            _.forEach(insertedDevices, function (insertedDevice) {
                $scope.updateDeviceStatusToAdded(insertedDevice);
            });

            _.forEach(devicesAlreadyAdded, function (addedDevice) {
                $scope.updateDeviceStatusToAdded(addedDevice);
            });

            $scope.updateTableAfterInsertDevice(insertedDevices);
            $scope.updateTableAfterInsertDevice(devicesAlreadyAdded);
            $scope.showInsertResult(
                devices,
                insertedDevices,
                notInsertedDevices,
                violationMessage,
                deviceInsertionResponseMessage
            );
            $scope.isAddingDevice = false;
        };

        $scope.updateDeviceStatusToAdded = function (device) {
            $scope.setAddedDevice($scope.devices, device);
            $scope.setAddedDevice($scope.table.elements, device);
            $scope.setAddedDevice($scope.table.visibleElements, device);
        };

        $scope.showInsertResult = function (
            devices,
            insertedDevices,
            notInsertedDevices,
            violationMessage,
            deviceInsertionResponseMessage
        ) {
            if (_.isEmpty(violationMessage) && deviceInsertionResponseMessage.result && !$scope.licenceValidator.hasError) {
                var translateKey =
                    insertedDevices.length === 1 ? "toastr.deviceSuccessfullyAdded" : "toastr.devicesSuccessfullyAdded";
                $rootScope.toastInfo(translateKey, [insertedDevices.length]);
            } else {
                var translateObjs = [];
                if (insertedDevices.length > 0 && deviceInsertionResponseMessage.result) {
                    translateObjs.push({
                        singularKey: $scope.licenceValidator.addOnlyCustom
                            ? "toastr.deviceCustomSuccessfullyAdded"
                            : "toastr.deviceSuccessfullyAdded",
                        pluralKey: $scope.licenceValidator.addOnlyCustom
                            ? "toastr.devicesCustomSuccessfullyAdded"
                            : "toastr.devicesSuccessfullyAdded",
                        isPlural: insertedDevices.length > 1,
                        insideMsgParams: [insertedDevices.length]
                    });
                }
                if ($scope.licenceValidator.hasError) {
                    translateObjs = translateObjs.concat($scope.licenceValidator.translateObj);
                }
                if (!_.isEmpty(violationMessage)) {
                    if (notInsertedDevices.length === devices.length) {
                        translateObjs.push({
                            singularKey: "findAddDevice.error.deviceCanNotBeAdded",
                            pluralKey: "findAddDevice.error.devicesCanNotBeAdded",
                            isPlural: notInsertedDevices.length > 1,
                            insideMsgParams: [notInsertedDevices.length]
                        });
                    } else {
                        translateObjs.push({
                            singularKey: "findAddDevice.error.oneDeviceCanNotBeAdded",
                            pluralKey: "findAddDevice.error.someDevicesCanNotBeAdded",
                            isPlural: notInsertedDevices.length > 1,
                            insideMsgParams: [notInsertedDevices.length]
                        });
                    }
                    var listParams = violationMessage.split(/\n/g);
                }

                $rootScope.showDialogSingularOrPlural({
                    translateObjs: translateObjs,
                    listParams: listParams,
                    maxChars: 500
                });
            }
            $scope.unselectNotEditableDevices(notInsertedDevices);
        };

        $scope.updateTableAfterInsertDevice = function (insertedDevices) {
            _.forEach(insertedDevices, function (device) {
                var visibleDevice = _.find($scope.table.elements, { hostname: device.hostname });
                $scope.updateTableElementsIfNeeded(visibleDevice);
            });
        };

        $scope.createDeviceObjectForFilter = function (device) {
            return {
                hostname: device.hostname,
                number: {
                    localId: parseInt(device.devLocalId),
                    number: parseInt(device.devNo)
                }
            };
        };

        $scope.setAddedDevice = function (devices, insertedDevice) {
            var device = _.find(devices, $scope.createDeviceObjectForFilter(insertedDevice));
            if (device) {
                device.status = $scope.deviceStatus.ADDED;
                device.name = insertedDevice.devId;
                device.properties.name = insertedDevice.devId;
                if (insertedDevice.modelCode == CUSTOM_DEVICE.modelCode) {
                    device.model = {
                        name: "Custom"
                    };
                }
            }
        };

        $scope.addDevicesAsCustom = function (form) {
            if ($scope.validateSearchForm(form)) {
                $rootScope
                    .showDialog({
                        translateKey: "findAddDevice.confirm.addCustomDevices",
                        isConfirm: true
                    })
                    .then(function () {
                        $scope.clearTableData();
                        $scope.prepareAvailableHosts();
                        DeviceDiscoverService.addDevicesAsCustom($scope.devices, $scope.onAddCustomResponse);
                    });
            }
        };

        $scope.onAddCustomResponse = function (devices, response) {
            $scope.concludedHosts = $scope.availableHosts.length;
            $scope.progressBarPercentage = 100;
            var insertedDevices = response.insertedDevices;
            var devicesAlreadyAdded = response.devicesAlreadyAdded;
            var violationMessage = response.violationMessage;
            var deviceInsertionResponseMessage = response.deviceInsertionResponseMessage;

            var notInsertedDevices = _.filter(devices, function (device) {
                if (!_.some(insertedDevices, "devId", device.name)) {
                    $scope.updateDeviceCustomOnFail(device);
                    $scope.updateDeviceCustomOnFail(_.find($scope.table.elements, "hostname", device.hostname));
                    $scope.updateDeviceCustomOnFail(_.find($scope.table.visibleElements, "hostname", device.hostname));
                    return true;
                }
            });

            _.forEach(insertedDevices, function (insertedDevice) {
                $scope.updateDeviceStatusToAdded(insertedDevice);
            });

            _.forEach(devicesAlreadyAdded, function (addedDevice) {
                $scope.updateDeviceStatusToAdded(addedDevice);
            });

            $scope.updateTableAfterInsertDevice(insertedDevices);
            $scope.updateTableAfterInsertDevice(devicesAlreadyAdded);
            $scope.showInsertResult(
                devices,
                insertedDevices,
                notInsertedDevices,
                violationMessage,
                deviceInsertionResponseMessage
            );
        };

        $scope.updateDeviceCustomOnFail = function (device) {
            if (device && device.status !== $scope.deviceStatus.ADDED) {
                device.status = $scope.deviceStatus.NOT_ADDED;
                device.name = device.hostname;
                device.properties.name = device.name;
            }
        };

        $scope.prepareAvailableHosts = function () {
            $scope.availableHosts.forEach(function (availableHost) {
                $scope.devices.push({
                    hostname: availableHost,
                    formattedHostname: availableHost,
                    name: $scope.getDeviceName(availableHost),
                    properties: angular.copy(globalConfig.properties),
                    path: globalConfig.location.path,
                    locationId: globalConfig.location.locationId,
                    credentials: $scope.getCredentials(availableHost),
                    globalConfig: globalConfig.globalConfig,
                    status: $scope.deviceStatus.FINDING,
                    number: {
                        localId: 1,
                        number: 1
                    },
                    model: { code: -1, vendor: {}, name: "" },
                    serialNumber: "",
                    firmwareVersion: ""
                });
            });
            if (isFilterAllChecked() ||
                _.includes($scope.itemsSelected, `findAddDevice.status.${$scope.deviceStatus.FINDING}`)) {
                $scope.table.elements = angular.copy($scope.devices);
            }
            $scope.table.visibleElements = $scope.getSortedElements(_.slice($scope.table.elements, 0, $scope.table.pageSize));
            $scope.calculateTableFooterInfo();
        };

        $scope.getCredentials = function (hostname) {
            var credentials = globalConfig.globalConfig
                ? angular.copy(globalConfig.globalCredentials)
                : angular.copy(globalConfig.specificCredentials);

            CredentialsService.updateCredentialsHostname(credentials, hostname);

            return credentials;
        };

        $scope.getStatus = function (status) {
            switch (status) {
                case $scope.deviceStatus.ADDED:
                    return $translate.instant("findAddDevice.status.added");
                case $scope.deviceStatus.NOT_ADDED:
                    return $translate.instant("findAddDevice.status.notAdded");
                case $scope.deviceStatus.UNSUPPORTED:
                    return $translate.instant("findAddDevice.status.notSupported");
                case $scope.deviceStatus.REACHABLE:
                    return $translate.instant("findAddDevice.status.found");
                case $scope.deviceStatus.UNREACHABLE:
                    return $translate.instant("findAddDevice.status.notFound");
                case $scope.deviceStatus.UNAUTHENTICATED:
                    return $translate.instant("findAddDevice.status.unauthenticated");
                default:
                    return $translate.instant("findAddDevice.status.finding");
            }
        };

        $scope.refreshPage = function (pageSize, page) {
            $scope.table.pageSize = pageSize;
            if ($scope.table.elements.length > 0) {
                $scope.table.totalPages = Math.ceil($scope.table.elements.length / $scope.table.pageSize);
                $scope.changeToPage(page);
            } else {
                $scope.table.visibleElements = [];
            }
        };

        $scope.changeToPage = function (page) {
            $scope.clearSelectionIfNeeded(page);
            $scope.table.currentPage = page;
            if ($scope.table.currentPage < 1) {
                $scope.table.currentPage = 1;
            } else if ($scope.table.currentPage > $scope.table.totalPages) {
                $scope.table.currentPage = $scope.table.totalPages;
            }

            $scope.calculateVisibleRange();

            var start = ($scope.table.currentPage - 1) * parseInt($scope.table.pageSize);
            var end = start + parseInt($scope.table.pageSize);

            $scope.table.visibleElements = $scope.getSortedElements(_.slice($scope.table.elements, start, end));

            if ($scope.table.lastVisitedPage !== $scope.table.currentPage) {
                $scope.table.lastVisitedPage = $scope.table.currentPage;
                $scope.table.checked.selected = [];
            }

            updatePreferences();
        };

        $scope.clearSelectionIfNeeded = function (page) {
            if ($scope.table.currentPage !== page) {
                $scope.table.checked.selectAllCheckbox = false;
                $scope.table.checked.selected = DeviceDiscoverService.checkUncheckAll(
                    $scope.table.checked.selectAllCheckbox,
                    $scope.table.visibleElements
                );
            } else if ($scope.table.visibleElements.length !== $scope.table.checked.selected.length) {
                $scope.table.checked.selectAllCheckbox = false;
            }
        };

        $scope.getSortedElements = function (elements) {
            return _.sortByOrder(elements, [$scope.table.filters.column], [$scope.table.filters.direction]);
        };

        $scope.sortElementsBy = function (column) {
            if ($scope.table.filters.column !== column) {
                $scope.table.filters.column = column;
                $scope.table.filters.direction = $scope.ASC;
            } else {
                $scope.table.filters.direction = $scope.table.filters.direction === $scope.ASC ? $scope.DESC : $scope.ASC;
            }

            $scope.table.visibleElements = $scope.getSortedElements($scope.table.visibleElements);
        };

        $scope.calculateVisibleRange = function () {
            $scope.table.firstIndex =
                $scope.table.elements.length == 0 ? 0 : ($scope.table.currentPage - 1) * $scope.table.pageSize + 1;
            var lastIndex = $scope.table.currentPage * $scope.table.pageSize;
            $scope.table.lastIndex = lastIndex < $scope.table.elements.length ? lastIndex : $scope.table.totalElements;
        };

        $scope.validatePageValue = function () {
            $scope.table.currentPage = DeviceDiscoverService.validatePageValue(
                parseInt($scope.table.currentPage),
                $scope.table.totalPages
            );
        };

        $scope.validatePageSize = function () {
            if (!$scope.pageSize || $scope.pageSize == "-") {
                $scope.pageSize = null;
            }
        };

        const getSelectedFilters = function() {
            return _.map($scope.items, item => {
                return {
                    status: item,
                    isSelected: _.includes($scope.itemsSelected, item)
                }
            });
        };

        var updatePreferences = function () {
            var properties = {
                pageSize: $scope.table.pageSize,
                filters: $scope.table.filters,
                selectedOptions: getSelectedFilters(),
                findDevicesBy: $scope.findDevicesBy,
                searchTermByIp: $scope.searchTermByIp,
                searchTermByHostname: $scope.searchTermByHostname,
                searchTermBySubNet: $scope.searchTermBySubNet,
                searchTermByRangeInitialIp: $scope.searchTermByRangeInitialIp,
                searchTermByRangeFinalIp: $scope.searchTermByRangeFinalIp,
                useHostnameAsName: $scope.useHostnameAsName,
                useIpAsName: $scope.useIpAsName,
                name: $scope.name
            };

            UserPreferencesService.savePreferences(properties, windowIdentifier, availablePropertiesKeysForLocalStorage());
        };

        $scope.isDiscoveryInProgress = function () {
            return $scope.devices.length !== 0 && $scope.concludedHosts !== $scope.availableHosts.length ? true : false;
        };

        $scope.shouldDisableDiscoveryButtons = function () {
            var discoveryInProgress = $scope.isDiscoveryInProgress();

            return discoveryInProgress || !$scope.discoveryCredentialsReady;
        };

        $scope.areThereSelectedDevices = function () {
            return DevicesActionsService.areThereSelectedDevices($scope.table.checked.selected);
        };

        $scope.verifyMgmtProtocolConfigUserPermission = function () {
            if (!AuthenticationService.hasPermission("managementProtocolConfiguration")) {
                $rootScope.showDialog({
                    translateKey: "findAddDevice.error.noUserPermission"
                });
                return false;
            }

            return true;
        };

        $scope.openLocationModal = function (devices) {
            LocationService.openLocationModal(globalConfig, devices, function (response) {
                if (_.isEmpty(devices)) {
                    $scope.locationModalConfirmed(response);
                }
            });
        };

        $scope.locationModalConfirmed = function (response) {
            globalConfig.location = {
                path: response.path,
                locationId: response.locationId
            };

            $scope.location = _.isEqual(globalConfig.location.path, LOCATION.DEFAULT_PATH)
                ? $translate.instant("findAddDevice.locationPath")
                : globalConfig.location.path;
        };

        $scope.blockManageDeviceByStatus = function (device, disableWhenDeviceIsUnauthenticated = false) {
            if ($scope.isAddingDevice) {
                return true;
            }
            if (disableWhenDeviceIsUnauthenticated && device.status === DEVICE_STATUS.UNAUTHENTICATED) {
                return true;
            }
            return !DeviceDiscoverValidatorService.validateIfDeviceCanBeAdded(device);
        };

        $scope.refreshDevices = function (devices) {
            if (DeviceDiscoverValidatorService.validateRefreshingDevices(devices)) {
                var updatingDevices = [];
                var blockedDevices = [];
                devices.forEach(function (device) {
                    if (DeviceDiscoverValidatorService.validateIfDeviceCanBeAdded(device)) {
                        if (DeviceCommonService.isLocalDevice(device)) {
                            updatingDevices.push(device);
                        } else if (!_.some(updatingDevices, { hostname: device.hostname })) {
                            updatingDevices.push(device);
                        }
                        $scope.table.totalElements -= $scope.removeRemotesIfNeeded($scope.table.elements, device);
                        $scope.removeRemotesIfNeeded($scope.table.visibleElements, device);
                        $scope.removeRemotesIfNeeded($scope.devices, device);
                    } else {
                        blockedDevices.push(device);
                    }
                });
                $scope.sendRefresh(updatingDevices, blockedDevices);
            }
        };

        $scope.removeRemotesIfNeeded = function (deviceList, device) {
            var devices = _.filter(deviceList, { hostname: device.hostname });
            if (devices.length > 1) {
                _.remove(deviceList, function (device) {
                    if (_.some(devices, device)) {
                        if (DeviceCommonService.isLocalDevice(device)) {
                            device.status = $scope.deviceStatus.UPDATING;
                        } else {
                            return true;
                        }
                    }
                });
            }

            return devices ? devices.length - 1 : 0;
        };

        $scope.sendRefresh = function (updatingDevices, blockedDevices) {
            $scope.restartDiscoveryForDevices(updatingDevices);
            $rootScope.hideLoadingPanel = true;

            if (!$scope.clientId) {
                DeviceDiscoverService.connect(function (clientId) {
                    $scope.clientId = clientId;
                    DeviceDiscoverService.refresh(updatingDevices, $scope.clientId);
                }, $scope.receiveDeviceResponse);
            } else {
                DeviceDiscoverService.refresh(updatingDevices, $scope.clientId);
            }

            $rootScope.hideLoadingPanel = false;
            $scope.showResult(updatingDevices, blockedDevices);
        };

        $scope.showResult = function (updatingDevices, blockedDevices) {
            if (_.isEmpty(blockedDevices)) {
                var translateKey =
                    updatingDevices.length === 1
                        ? "findAddDevice.error.discoveryRestartForSelectedDevice"
                        : "findAddDevice.error.discoveryRestartForSelectedDevices";

                $rootScope.toastInfo(translateKey, [updatingDevices.length]);
            } else {
                var messageMap = [
                    {
                        pluralKey: "findAddDevice.error.discoveryRestartForSelectedDevices",
                        singularKey: "findAddDevice.error.discoveryRestartForSelectedDevice",
                        isPlural: updatingDevices.length > 1,
                        insideMsgParams: [updatingDevices.length]
                    },
                    {
                        pluralKey: "findAddDevice.error.discoveryNotRestartForDevices",
                        singularKey: "findAddDevice.error.discoveryNotRestartForDevice",
                        isPlural: blockedDevices.length > 1,
                        insideMsgParams: [blockedDevices.length]
                    }
                ];

                var hosts = _.map(blockedDevices, function (device) {
                    return $scope.getDeviceIpHostnameLabel(device);
                });

                $rootScope.showDialogSingularOrPlural({
                    translateObjs: messageMap,
                    listParams: hosts,
                    maxChars: 128
                });
            }
        };

        $scope.restartDiscoveryForDevices = function (devices) {
            devices.forEach(function (device) {
                device.status = $scope.deviceStatus.UPDATING;
            });
            $scope.concludedHosts -= devices.length;
            $scope.progressBarPercentage = (100 * $scope.concludedHosts) / $scope.availableHosts.length;
        };

        $scope.tryRemoveSelectedDevices = function () {
            if ($scope.areThereSelectedDevices()) {
                $scope.clearDevices($scope.table.checked.selected);
            }
        };

        $scope.clearDevice = function (device) {
            $scope.clearDevices([device]);
        };

        $scope.clearDevices = function (checkedDevices) {
            var devices = _.clone(checkedDevices);
            var deviceListsToRemove = [
                $scope.table.visibleElements,
                $scope.table.checked.selected,
                $scope.devices,
                $scope.table.elements
            ];

            _.forEach(devices, function (device) {
                _.forEach(deviceListsToRemove, function (devices) {
                    $scope.removeDevice(devices, device);
                });

                if (DeviceCommonService.isLocalDevice(device)) {
                    _.remove($scope.availableHosts, function (availableHost) {
                        return device.hostname == availableHost;
                    });

                    $scope.concludedHosts--;
                    $scope.progressBarPercentage = (100 * $scope.concludedHosts) / $scope.availableHosts.length || 0;
                }
            });

            $scope.table.checked.selectAllCheckbox = false;
            $scope.refreshPage($scope.table.pageSize, $scope.table.currentPage);
            $scope.calculateTableFooterInfo();

            var translateKey = devices.length > 1 ? "toastr.devicesSuccessfullyRemoved" : "toastr.deviceSuccessfullyRemoved";
            $rootScope.toastInfo(translateKey, [devices.length]);
        };

        $scope.removeDevice = function (devices, deviceToRemove) {
            _.remove(devices, function (device) {
                return deviceToRemove === device;
            });
        };

        $scope.openDiscoverPropertiesModal = function () {
            var modalOptions: any = {
                model: new PropertiesModel(globalConfig.properties),
                guiSettings: {
                    showNameField: false,
                    isChangeRequired: false,
                    subtitle: $translate.instant("modals.properties.modalSubTitleNoSelectedDevices")
                },
                finishCallback: function (properties) {
                    globalConfig.properties = {
                        notes: properties.notes,
                        project: properties.project,
                        externalLocation: {
                            address: properties.address,
                            city: properties.city,
                            country: properties.country,
                            state: properties.state
                        },
                        internalLocation: {
                            room: properties.room,
                            shelf: properties.shelf,
                            station: properties.station
                        }
                    };

                    $scope.properties = $scope.isThereProperties()
                        ? $translate.instant("findAddDevice.propertiesConfig")
                        : $translate.instant("findAddDevice.noPropertiesConfig");
                }
            };

            PropertiesService.openPropertiesModal(modalOptions);
        };

        $scope.editDevicePropertiesConfig = function (device) {
            openSingleDevicePropertiesModal(device);
        };

        $scope.openPropertiesModal = function (devices) {
            if (_.size(devices) === 1) {
                openSingleDevicePropertiesModal(devices[0]);
            } else {
                openMultipleDevicePropertiesModal(devices);
            }
        };

        $scope.isThereProperties = function () {
            return (
                !_.isEmpty(globalConfig.properties.notes) ||
                !_.isEmpty(globalConfig.properties.project) ||
                !_.isEmpty(globalConfig.properties.externalLocation.address) ||
                !_.isEmpty(globalConfig.properties.externalLocation.city) ||
                !_.isEmpty(globalConfig.properties.externalLocation.state) ||
                !_.isEmpty(globalConfig.properties.externalLocation.country) ||
                !_.isEmpty(globalConfig.properties.internalLocation.station) ||
                !_.isEmpty(globalConfig.properties.internalLocation.room) ||
                !_.isEmpty(globalConfig.properties.internalLocation.shelf)
            );
        };

        $scope.propertiesModalConfirmed = function (selectedDevices) {
            var translateKey;
            if (selectedDevices.length > 1) {
                translateKey = "toastr.devicesPropertiesSuccessfullyEdited";
            } else {
                translateKey = "toastr.devicePropertiesSuccessfullyEdited";
            }

            $rootScope.toastInfo(translateKey, [selectedDevices.length]);

            if ($scope.anyDeviceChangeProperties()) {
                $scope.properties = $translate.instant("findAddDevice.propertiesConfig");
            } else {
                $scope.properties = $translate.instant("findAddDevice.noPropertiesConfig");
            }
        };

        $scope.anyDeviceChangeProperties = function () {
            var changed = false;
            $scope.devices.forEach(function (device) {
                changed =
                    changed ||
                    device.properties.name !== device.name ||
                    !_.isEmpty(device.properties.notes) ||
                    !_.isEmpty(device.properties.project) ||
                    !_.isEmpty(device.properties.externalLocation.address) ||
                    !_.isEmpty(device.properties.externalLocation.city) ||
                    !_.isEmpty(device.properties.externalLocation.state) ||
                    !_.isEmpty(device.properties.externalLocation.country) ||
                    !_.isEmpty(device.properties.internalLocation.station) ||
                    !_.isEmpty(device.properties.internalLocation.room) ||
                    !_.isEmpty(device.properties.internalLocation.shelf);
            });

            return changed;
        };

        $scope.init = function () {
            CredentialsRestService.getDiscoveryGlobalCredentials().then(function (response) {
                try {
                    $scope.successGetCredentials(response.plain());
                    $scope.itemsSelected = angular.copy(filterOptions);

                    var options = UserPreferencesService.loadPreferences(
                        {},
                        windowIdentifier,
                        availablePropertiesKeysForLocalStorage()
                    );
                    options = UserPreferencesService.loadUserData(
                        options,
                        windowIdentifier,
                        availablePropertiesKeysForSessionStorage()
                    );
                    if (!_.isEmpty(options)) {
                        $scope.table.pageSize = options.pageSize;
                        $scope.pageSize = options.pageSize;
                        $scope.table.filters = options.filters || $scope.table.filters;
                        $scope.findDevicesBy = options.findDevicesBy;
                        $scope.searchTermByIp = options.searchTermByIp;
                        $scope.searchTermByHostname = options.searchTermByHostname;
                        $scope.searchTermBySubNet = options.searchTermBySubNet;
                        $scope.searchTermByRangeInitialIp = options.searchTermByRangeInitialIp;
                        $scope.searchTermByRangeFinalIp = options.searchTermByRangeFinalIp;
                        $scope.useHostnameAsName = options.useHostnameAsName;
                        $scope.useIpAsName = options.useIpAsName;
                        $scope.name = options.name;

                        if (options.locationProperties) {
                            $scope.location = options.locationProperties.locationConfig;
                            globalConfig.location = options.locationProperties.location;
                        }
                        if (options.configProperties) {
                            $scope.properties = options.configProperties.propertiesConfig;
                            globalConfig.properties = options.configProperties.properties;
                        }
                        if (options.credentialsProperties) {
                            globalConfig.specificCredentials = options.credentialsProperties.specificCredentials;
                            globalConfig.globalConfig = options.credentialsProperties.isGlobalCredentials;
                        }

                        if (globalConfig.globalConfig) {
                            $scope.userPassword = $translate.instant("findAddDevice.globalConfig");
                        } else {
                            $scope.userPassword = $translate.instant("findAddDevice.specificConfig");
                        }
                    }

                    $scope.items = filterOptions.map(element  => element.status);
                    $scope.itemsSelected =  getFiltersFromStorage(options).map(item => item.status);
                    $scope.loadUserPreferences = true;

                } finally {
                    $scope.discoveryCredentialsReady = true;
                }
            });
        };

        $scope.successGetCredentials = function (response) {
            CredentialsService.clearDefaultUserAndPasswordForSnmpV3(response);
            globalConfig.globalCredentials = angular.copy(response);
            globalConfig.specificCredentials = angular.copy(response);
        };

        function getFiltersFromStorage(options) {
            options.selectedOptions = options.selectedOptions || filterOptions;

            let areThereAnyOptionsUndefined = _.some(options.selectedOptions, (opt) => angular.isUndefined(opt.isSelected));

            if (areThereAnyOptionsUndefined) {
                if (!options.selectedOptions
                        .some(item => item.status === `findAddDevice.status.${DEVICE_STATUS.UNAUTHENTICATED}`)) {
                    options.selectedOptions.push({ status: `findAddDevice.status.${DEVICE_STATUS.UNAUTHENTICATED}`});
                }
                options.selectedOptions.forEach(item => { item.isSelected = true; });
            }

            var selectedOptions = _.filter(options.selectedOptions, function (item) {
                return item.isSelected;
            });

            return _.filter(filterOptions, function (option) {
                return _.find(selectedOptions, "status", option.status);
            });
        }

        $scope.$on("$destroy", function () {
            var locationProperties: any = {
                locationConfig: $scope.location,
                location: globalConfig.location
            };
            var configProperties: any = {
                propertiesConfig: $scope.properties,
                properties: globalConfig.properties
            };
            var credentialsProperties: any = {
                isGlobalCredentials: globalConfig.globalConfig,
                specificCredentials: globalConfig.specificCredentials
            };
            var userData = {
                configProperties: configProperties,
                credentialsProperties: credentialsProperties,
                locationProperties: locationProperties
            };

            updatePreferences();

            UserPreferencesService.saveUserData(userData, windowIdentifier, availablePropertiesKeysForSessionStorage());

            DeviceDiscoverService.disconnect();
        });

        $scope.init();
    }
]);
