"use strict";

var app = angular.module("nms.dynamicDevice");

app.factory("DataPathService", function() {
    const service: any = {};

    service.getPathWithKeys = function(dataJsonPath, pathKeys) {
        return _.template(dataJsonPath)({keys: pathKeys || []});
    };

    /**
     * Aceita tanto um nodo de schema quanto um nodo da NodeInfoCache
     */
    service.getNodePath = function(pathKeys, node) {
        var pathTemplate;

        if (_.get(node, "paths.dataJsonPath")) {
            pathTemplate = node.paths.dataJsonPath;
        } else if (_.get(node, "dataJsonPath")) {
            pathTemplate = node.dataJsonPath;
        }

        return service.getPathWithKeys(pathTemplate, pathKeys);
    };

    service.getParentNodePath = function(pathKeys, node) {
        if (node.parentPaths) {
            return service.getPathWithKeys(node.parentPaths.dataJsonPath, pathKeys);
        }
        return null;
    };

    var getEntryKeys = function(listNode, entryNode, index) {
        var keys = _.get(listNode, "sub-statements.key");
        var listPath = _.get(listNode, "sub-statements.path");
        var entryKeys: any = {
            path: listPath
        };

        if (keys) {
            keys.split(" ").forEach(function(key) {
                entryKeys[key] = entryNode.leaves[key].value;
            });
        } else {
            entryKeys.index = index;
        }

        return entryKeys;
    };

    service.getEntryPathKeys = function(listPathKeys, listNode, entryNode, index) {
        var entryKeys = getEntryKeys(listNode, entryNode, index);

        return (listPathKeys || []).concat(entryKeys);
    };

    service.getEntryPath = function(listPathKeys, listNode, entryNode, index) {
        var entryPathKeys = this.getEntryPathKeys(listPathKeys, listNode, entryNode, index);
        var entryPath = this.getNodePath(entryPathKeys, listNode.template);

        return entryPath;
    };

    service.getEntryPathByIndex = function(listPathKeys, listNode, index) {
        var entryPath = this.getNodePath(listPathKeys, listNode) + ".entries[" + index + "]";

        return entryPath;
    };

    return service;
});
