// TODO: receber um mapa de propriedades a serem incluídas no scope ao invés de propriedades definidas,
//       para tornar o componente mais flexível.
// TODO: permitir também passar o template a ser utilizado.
app.service("ContentManager", ["$rootScope", "DataPathService", "DomainHandlerService", "TreeSelectionManager",
    "$timeout", "TreeService", "YangStatements", "WhenVerifierService", "NodeInfoCache", "$filter",
    function($rootScope, DataPathService, DomainHandlerService, TreeSelectionManager, $timeout, TreeService,
        YangStatements, WhenVerifierService, NodeInfoCache, $filter) {
        var node;
        var path;
        var canonicalPath;
        var keys;
        var pathKeys = [];
        var self = this;

        var openTreeAtPathNodeRecursive = function(path, pathKeys, onExpand) {
            var schemaNode = DomainHandlerService.getSchemaNode(path);
            if (schemaNode && schemaNode.paths.schemaJsonPath !== "$") {
                openTreeAtPathNodeRecursive(schemaNode.parentPaths.schemaJsonPath, pathKeys, onExpand);
                var parentDataNodePath = DataPathService.getParentNodePath(pathKeys, schemaNode);
                var dataNodePath = DataPathService.getNodePath(pathKeys, schemaNode);
                TreeSelectionManager.expandNode(dataNodePath, parentDataNodePath, true);
                if (onExpand) {
                    onExpand(dataNodePath, parentDataNodePath);
                }
            }
        };

        /**
         * Expande todos os paths da raiz da árvore até um nó específico, para que cada um desses nós sejam visíveis na árvore.
         * A partir do schemaJsonPath, é pego o dataJsonPath e incluídos pathKeys para requisitar a expansão do nodo através do
         * TreeSelectionManager.
         *
         * Exemplo:
         * path: $.containers.config>interface.lists.gpon.template.lists.onu.template
         * pathKeys: [
         *     {"id": "1/1/1"},
         *     {"id": 2}
         * ]
         * Irá expandir os nodos na seguinte ordem, utilizando esses dataJsonPaths com pathKeys:
         *  - $.containers.config.containers.interface
         *  - $.containers.config.containers.interface.lists.gpon
         *  - $.containers.config.containers.interface.lists.gpon.entries[?(@.leaves['id'].value=='1/1/1')]
         *  - $.containers.config.containers.interface.lists.gpon.entries[?(@.leaves['id'].value=='1/1/1')].lists.onu
         *  - $.containers.config.containers.interface.lists.gpon.entries[?(@.leaves['id'].value=='1/1/1')].lists.onu
         *    .entries[?(@.leaves['id'].value=='2')]
         *
         * @param {string} path schemaJsonPath.
         * @param {Array} pathKeys pathKeys necessárias para chegar a esse nó.
         * @callback [onExpand] função chamada após expandir todo o ramo da árvore contido no path.
         */
        this.openTreeAtPathNode = function(path, pathKeys, onExpand) {
            if (!TreeService.existsInTree(path, pathKeys)) {
                return false;
            }
            openTreeAtPathNodeRecursive(path, pathKeys, onExpand);

            return true;
        };

        var selectNode = function(path, pathKeys, selected, selectedUpwards) {
            if (!_.isEmpty(path)) {
                var schemaNode = DomainHandlerService.getSchemaNode(path);
                if (schemaNode) {
                    selectNode(schemaNode.parentPaths.schemaJsonPath, pathKeys, false, true);
                    var pathWithKeys = DataPathService.getNodePath(pathKeys, schemaNode);
                    var parentPathWithKeys = DataPathService.getParentNodePath(pathKeys, schemaNode);
                    TreeSelectionManager.selectNode(pathWithKeys, parentPathWithKeys, selected, selectedUpwards);
                }
            }
        };

        this.setContent = function(content) {
            node = content.node;
            path = content.path;
            canonicalPath = content.canonicalPath;
            keys = content.keys;
            pathKeys = content.pathKeys;

            if (content.refreshTree) {
                self.openTreeAtPathNode(path, pathKeys);
            }

            TreeSelectionManager.clearSelectedNode();
            selectNode(path, pathKeys, true, false);

            $timeout(function() {
                $rootScope.$broadcast("reloadContent");
            });
        };

        this.getNode = function() {
            return node;
        };

        this.getContentPath = function() {
            return path;
        };

        this.getCurrentKeys = function() {
            return getCurrentKeysAsArray(keys);
        };

        this.getCanonicalPath = function() {
            return canonicalPath;
        };

        this.getPathKeys = function() {
            return pathKeys;
        };

        this.clear = function() {
            node = null;
            path = null;
            canonicalPath = null;
            keys = null;
            $rootScope.$broadcast("reloadContent");
        };

        this.hasVisibleContent = function() {
            if (node) {
                var yangTypes = _.values(YangStatements);
                return _.some(yangTypes, function(yangType) {
                    var children = node[yangType];
                    return _.some(children, function(child) {
                        if (!$filter("isHidden")(child)) {
                            if (!WhenVerifierService.hasWhenCondition(child)) {
                                return true;
                            } else {
                                var nodeInfo = NodeInfoCache.getNode(child["sub-statements"].path);
                                return WhenVerifierService.resolve(child, pathKeys, nodeInfo).visible;
                            }
                        }
                        return false;
                    });
                });
            }

            return false;
        };

        function getCurrentKeysAsArray(keys) {
            if (keys) {
                if (_.isArray(keys)) {
                    return keys;
                } else {
                    return keys.split(" ");
                }
            }

            return null;
        }
    }
]);
