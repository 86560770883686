import { ErrorResponse } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";
import { Subject } from "rxjs";

/**
 * Define os possíveis tipos de ordenação
 */
type OrderType = "asc" | "desc";

/** Configuração de ordenação inicial da tabela */
interface InitialSort {
    /**
     * Nome do campo a ser ordenado e ordenação
     * Ex.:
     * hostname: "asc"
     */
    [key: string]: OrderType
}

/** Configurações globais da tabela. */
export interface NmsTableConfig {
    /** Esconde o componente de customização de tabela. Default: false. */
    hideCustomizedTableComponents?: boolean;
    /** Id da tabela. */
    tableId: string;
    /**
     * Prefixo da chave de tradução para os nomes das colunas da tabela.
     *
     * Ex.: "modals.cwmp.connectivityTest.tablecolumn".
     */
    tableTranslatePrefix: string;
    /**
     * Configuração de ordenação inicial da tabela.
     *
     * Ex.: { name: "hostname" }
     */
    initialSort: InitialSort;
    /** Id da combobox de seleção de itens na tabela. */
    selectionId: string;
    /**
     * Chave de tradução para o componente progressbar geral.
     * A tradução deve respeitar o formato "{0} de {1} itens...".
     *
     * Default: "nms-table.commons.information.progressbar"
     */
    progressbarInformationTranslateKey?: string;
    /**
     * Variável de controle para indicar que existem dados na tabela diferente
     * dos dados originais inicialmente carregados.
     * Default: false.
     */
    dirty?: boolean;
    /**
     * Variável de controle para incluir filtros customizados ao iniciar a tabela.
     * Um exemplo é o filtro de info/config da tela de Parâmetros, que deve iniciar com o valor armazenado para toda a tela e não
     * para um cpe específico.
     */
    initCustomFilters?: object;
    /**
     * Define se os custom filters setados em initCustomFilters devem
     * sobrescrever qualquer outro filtro pre-existente na tabela.
     */
    overrideWithCustomFilters?: boolean;
    /**
     * Define o identificador único das colunas
     */
    rowId: string;
}

/**
 * Define os possíveis tipos de renderização dentro de uma coluna da tabela.
 */
export enum NmsTableColumnType {
    /** Renderiza o componente de seleção de itens na tabela. */
    SELECTOR = "selector",
    /** Apresenta os dados em forma textual. */
    DEFAULT = "default",
    /** Renderiza o componente de barra de progresso. */
    PROGRESS = "progress",
    /** Utilizado para testes de conectividade. */
    CONNECTIVITY_TEST = "connectivity_test",
    /** Renderiza a coluna com um componente de dropdown. */
    DROPDOWN_MENU = "dropdown_menu",
    /**
     * Renderiza os dados de uma mesma coluna de forma dinâmica, podendo
     * assumir diferentes tipos de componentes.
     * Para mais informações sobre os tipos de renderizações disponíveis @see NmsTableRowRenderType.
     */
    MULTI_TYPE = "multi_type",
    /** Renderiza a coluna com as possíveis ações. */
    ACTIONS = "actions",
    /** Renderiza a coluna em formato de link */
    LINK = "link",
    /** Renderiza a coluna em formato de status */
    STATUS = "status"
}

export interface NmsTableActionColumn {
    /** Utilizado para identificar a ação */
    id: string;
    /** Utilizado para determinar o ícone do botão da ação
     * Devido ao toggle o icon passou a ser opcional.
     */
    iconClass?: string;
    /**Ultilizado para desabilitar uma ação */
    isDisabled?: Function;
    /**Ultilizado para criar tooltip complexos */
    getTooltip?: Function;
    /**
     * Utilizado para definir o tooltip a ser exibido
     * quando o botão da ação recebe um hover.
     */
    tooltip?: string;
    /** Utilizado para definir se o toggle será exibido quando
     * o botão disabe/enable for selecionado.
     * Devido ao toggle o icon passou a ser opcional.
     * O valor default é o "icon".
     * */
    type?: "toggle" | "icon";
    /** Implementa a lógica executada pela ação */
    onAction: Function;
}

/** Interface que representa as colunas da tabela. */
export interface NmsTableColumn {
    /**
     * Utilizado quando o field não deve ser traduzido.
     * Na ausência desta configuração o componente tentará traduzir o nome da coluna.
     */
    title?: string;
    /** Nome do campo acessível do objeto. A partir deste valor as colunas
     * sabem de onde os dados devem ser obtidos para as linhas.
     */
    field: string;
    /** Tipo de dados a serem renderizados. Default: TEXT */
    type?: NmsTableColumnType;
    /** Largura inicial da coluna na tabela. */
    width?: string;
    /** Utilizado para exibir/esconder coluna na tabela. Default: true */
    show?: boolean;
    /**
     * Utilizado para habilitar/desabilitar customização da coluna, por exemplo
     * reordenação de coluna, esconder coluna, etc.
     *
     * Default: true
     */
    editable?: boolean;
    /**
     * Utilizado para configurar a ordenação na coluna.
     *
     * Formato: { nomeDoCampo: "direção" } -> { name: "asc" }.
     */
    sorting?: Object;
    /**
     * Configura o filtro na coluna, desabilita o filtro
     * se setado como false.
     *
     * Formato: { nomeDocampo: "tipoDoCampo" } -> { name: "text" }.
     */
    filter?: Object;
    /** Chave para tradução de tipicon na coluna. */
    tipiconTranslateKey?: string;
    /**
     * Utilizado para definir que ordenação da coluna
     * seja feita baseada em outra propriedade do objeto
     * e não na propriedade utilizada para visualização.
     *
     * Ex.: Uma coluna de Data onde a ordenação é baseada
     * não na String formatada para leitura, e sim em
     * uma propriedade que armazena o timestamp da data.
     *
     * columns = [{
     *  date: new Date()
     *  timestamp: new Date().getTime()
     * }]
     *
     * Nesse caso
     * sortable: "timestamp"
     */
    sortable?: string;
    /** Utilizado para configurar a coluna de ações. */
    actions?: Array<NmsTableActionColumn>;
    /** Utilizado para configurar uma ação ao clicar na coluna */
    onAction?: Function;
    getColumnText?: Function
}

/** Interface base para dados das tabelas. */
export interface NmsTableRow {
    /**
     * Possibilita a adição de classes para serem utilizadas em estilizações
     * nas renderizações de colunas de texto (default) da tabela.
     */
    className?: string
    /** Possibilita executar o evento do botão adicionado na tabela */
    action?(row: NmsTableRow): Function;
    /** Define se o elemento estará habilitado ou desabilitado de acordo com as condições
     * pré definidas.
     */
    isDisable?: boolean
}

/**
 * Representa as ações que estão relacionadas aos elementos da tabela.
 */
export interface NmsTableActions {
    /** Id da ação. Este campo facilitará a criação dos testes automáticos. */
    id: string,
    /** Chave de tradução da ação. */
    translateKey: string,
    /**
     * Define se a ação será executada sob todos os dados da lista ou apenas os dados selecionados.
     * Default: Utiliza a lista de seleção
     * */
    performActionWithAllData?: boolean,
    /**
     * Ação a ser executada no click do usuário.
     * @param selectRows Lista com as itens selecionados.
     */
    action(selectRows?: Array<Object>)
    /**
     * Define a visibilidade da ação no contexto
     */
    hide?: boolean,
    /**
     * Define chave de tradução para customizar a mensagem de erro de nenhum item selecionado.
     * default: "nms-table.commons.error.noSelectedItem".
     */
    customNoSelectedItemTranslationKey?: string;
    /**
     * Ignora a verificação se existem items selecionados.
     */
    ignoreSelectedItemsValidation?: boolean;
}

/** Modelo a ser usado nos protocolos de teste de conectividade. */
export interface Protocol {
    /** O status se baseia na constante CONNECTIVITY_TEST_STATUS. */
    status: string,
    /**
     * Os detalhes do resultado de teste de conectividade.
     * Quando em branco o link details não será renderizado na UI.
     */
    details?: string,
    /** Chave de tradução para label a ser apresentado no link que mostra os detalhes da resposta. */
    detailsLabelKey?: string
}

/**
 * As operações de atualização podem ser dos tipos:
 * - update (default): Atualiza um valor existente na tabela
 * - remove: Remove um valor existente na tabela
 * - upsert: Atualiza ou adiciona um novo item na tabela
 */
export enum RowUpdateType {
    update = "update",
    remove = "remove",
    upsert = "upsert",
}

/**
 * Modelo esperado para atualização de uma linha da tabela.
 *
 * Atributos:
 * identifier: nome do atributo no qual a comparação entre os objetos será feita.
 * data: novos dados a serem mostrados na tabela.
 */
export interface NmsTableRowAsync {
    /**
     * Define qual atributo será utilizado para comparação de igualdade entre
     * a resposta e as linhas da tabela.
     */
    identifier: string,
    /**
     * Objeto contendo o novo valor a ser atualizado na tabela.
     * Observação: Este modelo deve ser do mesmo tipo utilizado nas linhas.
     */
    data: NmsTableRow,
    /**
     * Define o tipo de operação que será realizada na linha da tabela.
     * Operação default: update
     */
    rowUpdateType?: RowUpdateType,
    /**
     * Define se deve manter a linha selecionada.
     */
    keepSelected?: boolean
}

/** Modelo de resposta para testes de conectividade. */
export interface ConnectivityTestResponse {
    /**
     * Identificador único na tabela.
     *
     * Este nome se dá devido ao modelo respondido pelo backend.
     */
    hostname: string,
    /** Identificador do protocolo que foi testado. */
    protocol: string,
    /** Resultado do teste de conetividade. */
    status: string,
    /** Detalhes do teste. Opcional. */
    details: string,
    /** Detalhes de erro de um teste de conectividade. Opcional. */
    errorResponse?: ErrorResponse
}

/** Configura o componente para receber os dados de resposta de forma assíncrona. */
export interface AsyncUpdater {
    /** Utilizado para atualizar um valor específico em uma linha da tabela. */
    singleRow?: {
        /** Recebe os dados que deverão ser atualizados na linha da tabela. */
        responseObservable: Subject<NmsTableRowAsync>,
        /** Define se e como os valores anteriores serão limpos antes de processar novas requisições. */
        cleanPreviousValues?(rows: Array<Object>): void,
        /** Ação assíncrona na qual será executada os pedido de atualizações. */
        runAsyncUpdater?(rows: Object): void
    },
    /** Utilizado para atualizar todos os dados da tabela, removendo os valores anteriores. */
    multipleRows?: {
        /**
         * Recebe os novos dados que serão mostrados na tabela.
         */
        responseObservable: Subject<Array<Object>>
    },
    searchAll?: {
        /**
         * Realiza a pesquisa geral nos itens da tabela.
         */
        responseObservable: Subject<string>
    },
    searchCustom?: {
        /**
         * Realiza uma pesquisa customizada nos itens da tabela.
         */
        responseObservable: Subject<Object>
    },
    clearFiltersCustom?: {
        /**
         * Realiza a sobrescrita dos filtros que estão sendo usados pelo que é recebido,
         * contrário apaga todos os filtros. A ordenação é setado para default.
         */
        responseObservable: Subject<Object>
    },
    /** Utilizado para possibilitar a requisição de atualização da tabela, caso os dados dos
     * modelos apresentados por ela forem atualizados externamente por referência.
     */
    reloadObservable?: Subject<void>
}

/** Modelo utilizado para atualizar os itens selecionados de forma manual. */
export interface NmsTableSelectionUpdater {
    /**
     * Atualiza os elementos selecionados.
     */
    update?:  Subject<Array<Object>>
}
/**
 * Modelo que define como um atalho deverá ser cadastrado.
 * Encapsula a estrutura necessária utilizada no componente dropdown-directive.js
 * do AngularJS.
 * */
interface DropdownShortcut {
    /** O label que será exibido para o atalho. */
    label: string,
    /** Define se o atalho possui linha separadora. Default: false */
    hasDivider?: boolean,
    /** define se o atalho deve ser exibido em negrito. Default: false  */
    isBold?: boolean,
    /** Define se o atalho deve ser exibido. */
    isVisible?(): boolean,
    /** Ação a ser realizada ao clicar no atalho. */
    action(item: Object): void
}

/** Modelo utilizado para colunas que renderizam o componente "dropdown". */
export interface DropdownMenu {
    /**
     * Campo no qual o elemento de dropdown será atrelado. Desta forma
     * possibilita que a tabela consiga trabalhar com múltiplos elementos de dropdown
     * para campos distintos.
     */
    field: string,
    /** Lista de ações para o menu de dropdown. */
    shortcuts: Array<DropdownShortcut>
    /** Usado para quando quiser adicionar um link no elemento com ação padrão. */
    defaultAction?(item: Object): void
}

/**
 * Define qual será o tipo de renderização para uma célula na coluna.
 */
export enum NmsTableRowRenderType {
    /** Renderiza o dado em forma de texto. Default. */
    TEXT = "text",
    // TODO [TA340908][TA340909][TA340909]- verificar os tipos de componentes apropriados com o PO.
    /** Renderiza um input do tipo 'text'. */
    INPUT_TEXT = "inputText",
    /** Renderiza um input do tipo 'number' que permite apenas números naturais. */
    INPUT_UNSIGNED_INT_NUMBER = "unsignedIntNumber",
    /** Renderiza um input do tipo 'number' que permite apenas números inteiros. */
    INPUT_INT_NUMBER = "intNumber",
    /** Renderiza um input do tipo 'checkbox' que permite apenas valores boolean. */
    TOGGLE = "toggle",
    /** Renderiza um botão */
    BUTTON = "button",
    /** Renderiza um input do tipo 'datetime' que permite apenas valores do tipo data. */
    /** TODO [TA340910] - Incluir um exemplo de data retornada de uma ONU para documentação. */
    INPUT_DATE_TIME = "dateTime"
}

/**
 * Define as açãos de reload da tabela.
 */
export interface RefreshActions {
    /** Busca os dados */
    retrieveFn: Function,
    /** Inicia o processo de atualização */
    startUpdatingFn: Function,
    /** Finaliza o processo de atualização */
    finishedUpdatingFn: Function
}