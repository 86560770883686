"use strict";

/**
* @ngdoc service
* @name nms.templates.VariableService
* @description
* # VariableService
* Factory in the nms.templates.
*/
angular.module("nms.templates")
.factory("VariableService", ["$rootScope", "$translate", "VARIABLES",
    function($rootScope, $translate, VARIABLES) {
        const service: any = {};

        service.defineVariableType = function(type) {
            return VARIABLES[type];
        };

        service.defineVariableScope = function(scope) {
            return scope ? $translate.instant("modals.addVariableModal.scopeGlobal")
                : $translate.instant("modals.addVariableModal.scopeLocal");
        };

        service.buildRegexTooltip = function(variable) {
            return $translate.instant("tooltips.variable.regex").replace("{0}", variable.regex);
        };

        service.defineRestrictions = function(variable) {
            var restrictions = [];

            if (variable.type === VARIABLES.TEXT || variable.type === VARIABLES.TEXT_LIST) {
                var rangeMessage;
                if ((variable.rangeMin !== 0 && variable.rangeMin !== null) && !variable.rangeMax) {
                    rangeMessage = $translate.instant("templateform.variables.restrictions.sizeMinimum") +
                                                        variable.rangeMin;
                } else if (variable.rangeMin !== 0 && variable.rangeMin !== null && variable.rangeMax !== null) {
                    rangeMessage = $translate.instant("templateform.variables.restrictions.sizeFrom") + " " +
                                                variable.rangeMin + " " +
                                                $translate.instant("templateform.variables.restrictions.sizeTo")
                                                + " " + variable.rangeMax;
                } else if (variable.rangeMax !== null) {
                    rangeMessage = $translate.instant("templateform.variables.restrictions.sizeTo")
                                                 + " " + variable.rangeMax;
                }

                if (rangeMessage) {
                    restrictions.push(rangeMessage + " " + $translate.instant("templateform.variables.restrictions.size.character"));
                }
            }

            if (variable.type === VARIABLES.INTEGER || variable.type === VARIABLES.INTEGER_LIST) {
                var rangeMessage;
                if (variable.minValue && !variable.maxValue) {
                    rangeMessage = $translate.instant("modals.addVariableModal.restrictions.min")
                                    + " " + variable.minValue;
                } else if (variable.minValue && variable.maxValue) {
                    rangeMessage = variable.minValue + "-" + variable.maxValue;
                } else if (variable.maxValue) {
                    rangeMessage =
                    $translate.instant("modals.addVariableModal.restrictions.max")
                     + " " + variable.maxValue;
                }

                if (rangeMessage) {
                    restrictions.push(rangeMessage);
                }

                if (variable.step) {
                    restrictions.push($translate.instant("modals.addVariableModal.restrictions.step")
                                   + " " + variable.step);
                }

                if (variable.baseExponent) {
                    restrictions.push($translate.instant("modals.addVariableModal.restrictions.baseExponent")
                                   + " " + variable.baseExponent);
                }
            }

            if (variable.type === VARIABLES.SELECTION || variable.type === VARIABLES.SELECTION_LIST) {
                restrictions = variable.options;
            } else if (variable.type == VARIABLES.ACTION) {
                restrictions = convertObjectActionsToArray(variable.actions);
            }

            if (restrictions.length > 6) {
                return (variable.type === VARIABLES.ACTION)
                        ? "(" + restrictions.length + $translate.instant("templateform.variables.restrictions.actions") + ")"
                        : "(" + restrictions.length + $translate.instant("templateform.variables.restrictions.options") + ")";
            }

            return restrictions.toString().replace("/[!\[\]\"]/g", "").replace(/,/g, ", ");
        };

        service.getVariableCommandsType = function(variable) {
        // Build info to variable CLI Commands Column
            var message = [];

            if (variable.includeAndBlockOptionMode === VARIABLES.includeAndBlockOptionsModes.INCLUDE) {
                message.push($translate.instant("templateform.commands.includeCommands"));
            } else if (variable.includeAndBlockOptionMode === VARIABLES.includeAndBlockOptionsModes.BLOCK) {
                message.push($translate.instant("templateform.commands.blockCommands"));
            } else if (variable.includeAndBlockOptionMode === VARIABLES.includeAndBlockOptionsModes.INCLUDE_AND_BLOCK) {
                message.push($translate.instant("templateform.commands.includeCommands"));
                message.push($translate.instant("templateform.commands.blockCommands"));
            }

            if (variable.commands) {
                message.push($translate.instant("templateform.commands.visualizationCommands"));
            }

            return message.join(", ");
        };

        let convertObjectActionsToArray = function(actions) {
            return Object.entries(actions).map(([name, alert]) => ((alert)? `<u>${name}</u>` : name));
        }

        return service;
    }]);
