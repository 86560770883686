"use strict";

/**
* @ngdoc directive
* @name nms.directive:segmentedControl
* @description Diretiva para componente segmented-control.
*
*   #prefixId            - Obrigatório  - String    - Prefixo para criação de Ids no html para evitar duplicidade quando a
*                                                     diretiva é usada mais de uma vez na página.
*   #options             - Obrigatório  - String[{key: "", value: ""}]  - Opções de seleção do segmented-control. Caso o elemento
*                                                     value do objeto, seja definido com outro "nome" ele deverá ser passado no
*                                                     atributo "value" da diretiva.
*   #model               - Obrigatório  - Object    - Modelo que mantem a opção selecionada deve ser do formato {value: "value"}.
*   #translateKeyPrefix  - Obrigatório  - String    - Prefixo das chaves de tradução utilizadas nas opções de seleção.
*   #changeListener      - Opcional     - Function  - Função a ser executada quando a opção selecionada é alterada.
*   #value               - Opcional     - String    - Nome do atributo que conterá o valor da lista de opções. Default: "value".
*/
var app = angular.module("nms");
app.directive("segmentedControl", ["$log", "$translate",
    function($log, $translate) {
        return {
            restrict: "E",
            scope: {
                prefixId: "@",
                options: "=",
                model: "=",
                changeListener: "=?",
                translateKeyPrefix: "@",
                value: "@?"
            },
            templateUrl: "templates/components/ui/segmented-control/segmented-control.html",
            link: {
                pre: function(scope, elem, attrs) {
                    if (!scope.changeListener) {
                        scope.changeListener = function() {};
                    }

                    if (!scope.value) {
                        scope.value = "value";
                    }

                    var MANDATORY_ATTRIBUTES = ["prefixId", "options", "model", "translateKeyPrefix"];

                    var mandatoryAttributesWithError = _.filter(MANDATORY_ATTRIBUTES, function(key) {
                        if (key === "options") {
                            return !_.isArray(scope[key]);
                        }
                        return !attrs[key];
                    });

                    if (!_.isEmpty(mandatoryAttributesWithError)) {
                        var errorMessage
                            = "There are errors in mandatory attributes. This may cause problems with the operation of the "
                             + "segmented-control component.\nPlease verify the following attributes: ";

                        $log.error(errorMessage + mandatoryAttributesWithError.toString(), _.pick(elem, "context").context);
                    }
                }
            }
        };
    }
]);
