"use strict";

/**
* @ngdoc service
* @description Service para fazer highlight de texto.
* Referência: https://markjs.io
*/
var app = angular.module("nms");

app.service("TextHighlighterService", ["$timeout",
    function($timeout) {
        var options: any = {separateWordSearch: false};

        this.highlight = function(baseElement, searchableElementSelector, text) {
            return $timeout(function() {
                baseElement.find(searchableElementSelector).unmark({
                    done: function() {
                        baseElement.find(searchableElementSelector).mark(text, options);
                    }
                });
            });
        };

        this.clear = function(baseElement, searchableElementSelector) {
            return $timeout(function() {
                baseElement.find(searchableElementSelector).unmark();
            });
        };
    }
]);
