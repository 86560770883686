import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnDestroy } from "@angular/core";
import { Subject, timer } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { ANGULARJS_TRANSLATE, FILTER } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/** Modelo que representa os dados da última execução. */
interface LastExecution {
    /** Data da início última execução. */
    initialDate: Date;
    /** Data de término última execução. */
    finalDate: Date;
}

export interface NotifierModel {
    /** Data de início da execução. */
    date: Date;
    /**
     * Mensagem de ínicio de execução.
     * Será necessário a inserção '{0}' para replace
     * dos segundos a serem exibidos.
    */
    translationKey?: string;
}

/** Componente para cálcular o tempo de execução de um processo. */
@Component({
  selector: "nms-timer",
  templateUrl: "./nms-timer.component.html",
  styleUrls: ["./nms-timer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NmsTimerComponent implements OnInit, OnDestroy {
    /**
     * Quando presente calcula o tempo do processo em andamento e inicia o contador
     * a partir do valor calculado.
     */
    @Input() initialDate?: Date;
    /** Notifica o início da execução de um processo. */
    @Input() startNotifier: Subject<NotifierModel>;
    /** Notifica a finalização da execução de um processo.
     * Quando disparado sem o parâmetro de data, indica que a informação de intervalo
     * da última execução representada na variável 'lastExecution' não deve ser atualizada.
     */
    @Input() stopNotifier: Subject<Date>;
    /**
     * Chave para tradução custom para mensagem de carregamento de dados em andamento.
     * default: nms-timer.commons.process.running
     */
    @Input() processRunningTranslateKey?: string;
    /**
     * Chave para tradução custom para mensagem de última execução de dados em andamento.
     * default: nms-timer.commons.process.lastExecution
     */
    @Input() lastExecutionTranslateKey?: string;
    /** Dados da última execução do processo. */
    @Input() lastExecution?: LastExecution;

    /** Variável de controle para finalização do contador. */
    hasProcessRunning: boolean;
    /** Duração do processamento a ser atualizado no componente. */
    duration: number;
    /** Mensagem de exibição do loading traduzida */
    translatedProcessRunningMessage: string;
    /** Mensagem default de carregamento de dados traduzida */
    defaultProcessRunningMessage: string;

    constructor(@Inject(ANGULARJS_TRANSLATE) private translate: any,
                @Inject(FILTER) private filter: any,
                private changeDetector: ChangeDetectorRef) {
        this.hasProcessRunning = false;
        this.processRunningTranslateKey = "nms-timer.commons.process.running";
        this.lastExecutionTranslateKey = "nms-timer.commons.process.lastExecution";
        this.startNotifier = new Subject();
        this.stopNotifier = new Subject();
        this.duration = 0;
    }

    ngOnInit() {
        this.defaultProcessRunningMessage = this.translate.instant(this.processRunningTranslateKey);
        this.translatedProcessRunningMessage = this.defaultProcessRunningMessage;

        this.startNotifier.subscribe((notifierModel: NotifierModel) => {
            this.initialDate = notifierModel.date;

            this.translatedProcessRunningMessage = !!notifierModel.translationKey
                ? this.translate.instant(notifierModel.translationKey)
                : this.defaultProcessRunningMessage;

            this.startCounter();
        });

        this.stopNotifier.subscribe((date: Date) => {
            this.hasProcessRunning = false;
            if (date) {
                this.lastExecution = {
                    finalDate: date,
                    initialDate: this.initialDate
                };
            }

            this.verifyChangeDetector();
        });

        if (this.initialDate) {
            this.startCounter();
        }
    }

    getProcessingMessage = (): string => this.translatedProcessRunningMessage.replace("{0}", this.duration.toString());

    getLastExecutionMessage = (): string => {
        if (this.lastExecution && !!this.lastExecution.initialDate && !!this.lastExecution.finalDate) {
            return this.translate.instant(this.lastExecutionTranslateKey)
                    .replace("{0}", this.getDurationInSeconds(this.lastExecution.initialDate , this.lastExecution.finalDate))
                    .replace("{1}", this.parseDateToStr(this.lastExecution.finalDate));
        }
        return "";
    }

    parseDateToStr = (date: Date): string => this.filter("dateFormat")(date);

    private getDurationInSeconds = (firstDate: Date, lastDate: Date): number =>
        Math.round((lastDate.getTime() - firstDate.getTime()) / 1000);

    /** Inicializa o contador. */
    private startCounter = () => {
        this.duration = this.getDurationInSeconds(this.initialDate, new Date());
        this.hasProcessRunning = true;

        timer(0, 1000)
            .pipe(takeWhile(() => this.hasProcessRunning))
            .subscribe(() => {
                this.duration++;
                this.verifyChangeDetector();
            });
    };

    /**
     * Verifique se o ChangeDetectorRef foi destruído
     * antes de executar o método detectChanges.
     */
    private verifyChangeDetector() {
        if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.changeDetector.detach();
    }
}
