var app = angular.module("nms.dynamicDevice");

/**
 * Controller da diretiva type-restriction, tem no escopo funções utilizadas por outras diretivas que exibem mensagens
 * de erro de restrições contidas nos types, como pattern-restriction e user-range-restriction.
 */
app.controller("TypeRestrictionController", ["RestrictionService", "LeafService", "$filter", "$scope",
    function(RestrictionService, LeafService, $filter, $scope) {
        var getValidatorName = function(restrictionName) {
            return RestrictionService.getLeafTypeValidatorName($scope.type, restrictionName);
        };

        $scope.isInvalidDecimalFormat = function(id) {
            return RestrictionService.isInvalidDecimalFormat(id, $scope.form);
        };

        /**
         * Verifies if value has a valid length.
         *
         * @param {string} id Name of a field.
         * @return {boolean} Returns TRUE if value has an invalid length.
         */
        $scope.isOutOfLength = function(id) {
            return RestrictionService.hasErrorOnField(id, getValidatorName("length"), $scope.form);
        };

        /**
         * Verifies if value matches the desired pattern (yang-pattern).
         *
         * @param {string} id Name of a field.
         * @return {boolean} Returns TRUE if value does not match the desired pattern (yang-pattern).
         */
        $scope.isOutOfPattern = function(id) {
            return RestrictionService.hasErrorOnField(id, getValidatorName("pattern"), $scope.form);
        };

        /**
         * Verifies if value matches the desired yang ranges.
         *
         * @param {string} id Name of a field.
         * @return {boolean} Returns TRUE if value does not match the desired ranges.
         */
        $scope.isOutOfYangRange = function(id) {
            return RestrictionService.hasErrorOnField(id, getValidatorName("yang-range"), $scope.form);
        };

        /**
         * Verifies if a value or a range is valid according to field restrictions.
         *
         * @param {string} id Name of a field.
         * @return {boolean} Returns TRUE if value or range is invalid.
         */
        $scope.isOutOfUserRange = function(id) {
            return RestrictionService.hasErrorOnField(id, getValidatorName("user-range"), $scope.form);
        };

        $scope.isInvalidDecimalIntegerDigits = function(id) {
            return RestrictionService.hasErrorOnField(id, getValidatorName("decimal-integer"), $scope.form);
        };

        $scope.isInvalidDecimalFractionDigits = function(id) {
            return RestrictionService.hasErrorOnField(id, getValidatorName("decimal-fraction"), $scope.form);
        };

        $scope.isInvalidNumber = function(id) {
            return RestrictionService.isInvalidNumber(id, $scope.form);
        };

        $scope.getShowPatternMessage = function(showPatterns) {
            return showPatterns ? "Hide Pattern..." : "Show Pattern...";
        };

        /**
         * Returns a text with all patterns of a given leaf.
         *
         * @return {string} text to display in View which patterns must be matched.
         */
        $scope.getPatternsAsString = function() {
            var patterns = LeafService.getPatterns($scope.type);
            if (patterns) {
                return _.map(patterns, function(regex) {
                    return "<code>" + regex + "</code>";
                }).join("");
            }
        };

        var getRangeAsString = function(type, range) {
            var result = "";

            if (range) {
                var rangeArray = $filter("wrapInArray")(range);
                var min;
                var max;

                _.each(rangeArray, function(element, index) {
                    min = $filter("number")(element.split("..")[0]);
                    max = $filter("number")(element.split("..")[1]);
                    result += (index >= 1) ? " or " : "";
                    result += (min === max) ? min : min + " to " + max;
                });

                result += (type.name === "string") ? " characters" : "";
            }
            return result;
        };

        /**
         * Returns a text with all acceptable ranges of a given numeric YANG type.
         *
         * @return {string} text to display in View which ranges are acceptable.
         */
        $scope.getRangesAsString = function() {
            return getRangeAsString($scope.type, LeafService.getRanges($scope.type));
        };

        /**
         * Returns a text with all acceptable lengths of a given YANG type.
         *
         * @return {string} text to display in View which lengths are acceptable.
         */
        $scope.getLengthsAsString = function() {
            return getRangeAsString($scope.type, LeafService.getLengths($scope.type));
        };

        /**
         * Returns the fraction-digits of a given YANG type.
         *
         * @return {number} Maximum number of fraction digits.
         */
        $scope.getMaxFractionDigits = function() {
            return LeafService.getMaxFractionDigits($scope.type);
        };

        $scope.getMaxIntegerDigits = function() {
            return LeafService.getMaxIntegerDigits($scope.type);
        };
    }
]);
