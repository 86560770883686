var app = angular.module("nms.dynamicDevice");

app.directive("restrictionLeaflist", ["RestrictionService",
    function(restrictionService) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaflist/restrictions/restriction.html",
            link: function(scope) {
                scope.hasDuplicatedElement = function(leaflist) {
                    return restrictionService.hasDuplicatedElement(leaflist.id, scope.form);
                };
            }
        };
    }
]);

app.directive("duplicatedElement",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaflist/restrictions/duplicated-element.html"
        };
    }
);
