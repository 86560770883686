import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

/**
 * Upgrade da diretiva tags-input, da biblioteca ng-tags-input.
 *
 * A documentação de cada propriedade foi adquirida diretamente
 * pela biblioteca, tradução via Google Translate.
 */
@Directive({
    selector: "nms-tags-input",
})
export class NmsTagsInput extends UpgradeComponent {

    @Input() model?: any;
    @Input() modelObject?: any;
    @Input() modelProperty?: any;

    /**
     * URL ou ID de um modelo personalizado para renderizar cada tag.
     */
    @Input() template: string;

    /**
     * Escopo a ser passado para templates customizados - tanto das diretivas tagsInput quanto autoComplete - como $scope.
     */
    @Input() templateScope: string;

    /**
     * Propriedade a ser renderizada como o rótulo da tag.
     */
    @Input() displayProperty: string;

    /**
     * Propriedade a ser usada como identificador exclusivo da tag.
     */
    @Input() keyProperty: string;

    /**
     * Tipo do elemento de entrada. Apenas 'text', 'email' e 'url' são valores suportados.
     */
    @Input() type: string;

    /**
     * Expressão angular atribuível para vinculação de dados ao texto do elemento.
     */
    @Input() text: string;

    /**
     * Ordem de tabulação do controle.
     */
    @Input() tabindex: number;

    /**
     * Texto placeholder para o controle.
     */
    @Input() placeholder: string;

    /**
     * Comprimento mínimo para uma nova tag.
     */
    @Input() minLength: number;

    /**
     * Comprimento máximo permitido para uma nova tag.
     */
    @Input() maxLength: number;

    /**
     * Define a chave de erro de validação minTags se o número de tags adicionadas for menor que minTags.
     */
    @Input() minTags: number;

    /**
     * Define a chave de erro de validação de maxTags se o número de tags adicionadas for maior que maxTags.
     */
    @Input() maxTags: number;

    /**
     * Define a chave de erro de validação leftoverText se houver algum texto restante
     * no elemento de entrada quando a diretiva perde o foco.
     */
    @Input() allowLeftoverText: boolean;

    /**
     * (Obsoleto) Caractere de símbolo para o botão remover tag.
     */
    @Input() removeTagSymbol: string;

    /**
     * Flag indicando que um novo tag será adicionado ao pressionar a tecla ENTER.
     */
    @Input() addOnEnter: boolean;

    /**
     * Flag indicando que um novo tag será adicionado ao pressionar a tecla ESPAÇO.
     */
    @Input() addOnSpace: boolean;

    /**
     * Flag indicando que um novo tag será adicionado ao pressionar a tecla COMMA.
     */
    @Input() addOnComma: boolean;

    /**
     * Flag indicando que um novo tag será adicionado quando o campo de entrada perder o foco.
     */
    @Input() addOnBlur: boolean;

    /**
     * Flag indicando que o texto colado no campo de entrada será dividido em tags.
     */
    @Input() addOnPaste: boolean;

    /**
     * Expressão regular usada para dividir o texto colado em tags.
     */
    @Input() pasteSplitPattern: string;

    /**
     * Flag indicando que os espaços serão substituídos por travessões.
     */
    @Input() replaceSpacesWithDashes: boolean;

    /**
     * Expressão regular que determina se uma nova tag é válida.
     */
    @Input() allowedTagsPattern: string;

    /**
     * Sinalizador indicando que a última tag será movida de volta para a nova caixa de entrada em vez de
     * ser removida quando a tecla backspace é pressionada e a caixa de entrada está vazia.
     */
    @Input() enableEditingLastTag: boolean;

    /**
     * Sinalizador indicando que apenas as tags vêm da lista de preenchimento automático será permitido.
     * Quando esse sinalizador é verdadeiro, os valores addOnEnter, addOnComma, addOnSpace e addOnBlur são ignorados.
     */
    @Input() addFromAutocompleteOnly: boolean;

    /**
     * Sinalizador indicando se a verificação ortográfica do navegador está habilitada para o campo de entrada ou não.
     */
    @Input() spellcheck: boolean;

    /**
     * Expressão a ser avaliada para cada tag existente para obter as classes CSS a serem utilizadas.
     * A expressão é fornecida com a tag atual como $tag, seu índice como $index e seu estado como $selected.
     * O resultado da avaliação deve ser um dos valores suportados pelo ngClass
     * diretiva (seja uma string, um array ou um objeto).
     *
     * Ver https://docs.angularjs.org/api/ng/directive/ngClass para mais informações.
     */
    @Input() tagClass: Function;

    /**
     * Expressão para avaliar que será invocada antes de adicionar uma nova tag. A nova
     * tag está disponível como $tag. Este método deve retornar um valor booleano ou uma promessa.
     * Se um valor falso ou uma promessa rejeitada for retornado, a tag não será adicionada.
     */
    @Input() onTagAdding: Function;

    /**
     * Expressão a ser avaliada ao adicionar uma nova tag. A nova tag está disponível como $tag.
     */
    @Input() onTagAdded: Function;

    /**
     * Expressão para avaliar quando um tag é inválido. A tag inválida está disponível como $tag.
     */
    @Input() onInvalidTag: Function;

    /**
     * Expressão para avaliar que será invocada antes de remover uma tag. A tag está disponível como $tag.
     * Este método deve retornar um valor booleano ou uma promessa. Se um valor falso ou um valor rejeitado
     * a promessa é retornada, a tag não será removida.
     */
    @Input() onTagRemoving: Function;

    /**
     * Expressão a ser avaliada ao remover uma tag existente. A tag removida está disponível como $tag.
     */
    @Input() onTagRemoved: Function;

    /**
     * Expressão para avaliar ao clicar em uma tag existente. A tag clicada está disponível como $tag.
     */
    @Input() onTagClicked: Function;


    @Input() isDisabled: boolean;
    @Input() id: string;
    @Input() specialCharactersMask: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super("tagsInputWrapper", elementRef, injector);
    }
}