"use strict";

/**
* @ngdoc controller
* @name nms.components.nmsWarning:NmsWarningController
* @description
* # NmsWarningController
* Classe de controle para a diretiva de nms-warning.
*/
var app = angular.module("nms");

app.controller("NmsWarningController", ["$rootScope", "$scope",
    function($rootScope, $scope) {
        $scope.filterWarningsByPermission = function() {
            var filteredWarnings = _.filter($scope.settings.warnings, function(warning) {
                if (_.isUndefined(warning.needsAdministratorPermission)) {
                    warning.needsAdministratorPermission = true;
                }

                return $rootScope.isAdmin === warning.needsAdministratorPermission;
            });

            return filteredWarnings;
        };
    }
]);
