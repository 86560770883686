var app = angular.module("nms.dynamicDevice");

app.controller("ContainerBodyController", ["$scope", "ContentManager", "DomainHandlerService", "NodeStatusService",
    "WhenVerifierService",
    function($scope, contentManager, domainHandlerService, nodeStatusService, whenVerifierService) {
        var hasWhenCondition = whenVerifierService.hasWhenCondition($scope.container);
        $scope.isNodeVisible = !hasWhenCondition;

        $scope.openContainer = function() {
            var content: any = {
                node: $scope.container,
                path: $scope.container.paths.schemaJsonPath,
                refreshTree: true,
                canonicalPath: domainHandlerService.getPath($scope.container),
                pathKeys: $scope.pathKeys
            };
            contentManager.setContent(content);
        };

        if (hasWhenCondition) {
            var unregisterNodeStatusListener = nodeStatusService.register(
                $scope.container.paths.schemaJsonPath,
                $scope.container.paths.dataJsonPath,
                $scope.pathKeys,
                function(nodeStatus, refreshData) {
                    $scope.isNodeVisible = nodeStatus.isVisible;
                }
            );

            $scope.$on("$destroy", unregisterNodeStatusListener);
        }
    }
]);
