"use strict";

/**
* @ngdoc function
* @name nms.templates.controller:OptionsExtractionTestController
* @description
* # OptionsExtractionTestController
* Controller para a diretiva de teste de extração de opções.
*/
var app = angular.module("nms.templates");

app.controller("OptionsExtractionTestController", ["$rootScope", "$scope", "$filter", "VariableValidatorService",
    "DeviceOptionsExtractionService",
    function($rootScope, $scope, $filter, VariableValidatorService, DeviceOptionsExtractionService) {
        $scope.optionExtractionTest = {showTest: false, input: "", output: ""};

        $scope.showHideOptionExtractionTest = function() {
            if (!$scope.hasExtractionParams || $scope.optionExtractionTest.showTest) {
                $scope.optionExtractionTest.showTest = false;
            } else {
                if (VariableValidatorService.isValidExtractOptions($scope.tabKey, $scope.extractionParams)) {
                    $scope.optionExtractionTest.showTest = true;
                }
            }
        };

        $scope.testOptionExtraction = function() {
            $scope.optionExtractionTest.output = "";
            $scope.optionExtractionTest.error = false;

            if (VariableValidatorService.isValidExtractOptions($scope.tabKey, $scope.extractionParams)) {
                if (_.isEmpty($scope.optionExtractionTest.input.trim())) {
                    $rootScope.showDialog({
                        translateKey: "addVariableModal.error.emptyInputExtractOptionsTest"
                    });
                } else {
                    DeviceOptionsExtractionService
                        .testExtraction($scope.optionExtractionTest.input.trim(), $scope.extractionParams)
                        .then(function(response) {
                            var options = response.extractedOptions;
                            if (_.isEmpty(options)) {
                                $scope.optionExtractionTest.output = response.rawOutput;
                                $scope.optionExtractionTest.error = angular.isDefined(response.rawOutput);
                            } else {
                                var sortedOptions = filterOptions(options);
                                var text = sortedOptions.join("\n");
                                $scope.optionExtractionTest.output = text;
                            }
                        });
                }
            }
        };

        var filterOptions = function(options) {
            var orderFilterMethod = $filter("orderByNumbersAndLetters");

            return orderFilterMethod(options, true);
        };
    }
]);
