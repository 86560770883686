"use strict";

/**
 * @deprecated - Utilizar o novo serviço - CpeDropdownService
 * @ngdoc service
 * @name nms.DropdownCpeService
 * @description
 * # DropdownCpeService - Serviço de criação de atalhos para CPEs.
 * @param {object} $translate - chama função para tradução de keys
 * @param {object} CpesActionsService - serviço que possui a implementação dos atalhos
 * @param {object} CpeShortcutsActionService - serviço que possui a implementação dos atalhos
 * @return {object} A lista de atalhos.
 */
angular.module("nms").factory("DropdownCpeService", [
    "$translate",
    "CpesActionsService",
    "CpeShortcutsActionService",
    function (
        $translate,
        CpesActionsService,
        CpeShortcutsActionService
    ) {
        const service: any = {};

        service.getTR069Shortcuts = (openDeviceCpeFn) => {
            const hasCpeLastInform = (cpe) => !_.isArray(cpe) && _.isEmpty(cpe.cwmpCpeLastInform);
            let shortcuts = [
                {
                    label: $translate.instant("title.deviceConfiguration"),
                    action: (cpe) => openDeviceCpeFn(cpe),
                    hasDivider: true,
                    isVisible: (cpe) => !_.isArray(cpe)
                },
                {
                    label: $translate.instant("gpon.cpes.tableActions.tr069.parameters"),
                    action: (cpes) => CpesActionsService.tryToOpenParametersModalForCpes(cpes),
                    isShortcutDisabled: hasCpeLastInform
                },
                {
                    label: $translate.instant("gpon.cpes.tableActions.tr069.applyTemplate"),
                    action: (cpes) => CpeShortcutsActionService.goToTemplateApplication([cpes]),
                    isShortcutDisabled: hasCpeLastInform
                },
                {
                    label: $translate.instant("gpon.cpes.tableActions.tr069.viewTemplateApplication"),
                    action: (cpes) => CpeShortcutsActionService.viewTemplateApplication([cpes]),
                    isShortcutDisabled: hasCpeLastInform
                },
                {
                    label: $translate.instant("gpon.cpes.tableActions.tr069.credentials"),
                    action: (cpes) => CpesActionsService.tryToCredentialsModalCpes(cpes)
                },
                {
                    label: $translate.instant("gpon.cpes.tableActions.tr069.testConnectivity"),
                    action: (cpes) => CpesActionsService.tryToOpenConnectivityTestModalForCpes(cpes),
                    isShortcutDisabled: hasCpeLastInform
                },
                {
                    label: $translate.instant("gpon.cpes.tableActions.tr069.download"),
                    action: (cpes) => CpesActionsService.tryToOpenDownloadRequestModal(cpes),
                    isShortcutDisabled: hasCpeLastInform
                },
                {
                    label: $translate.instant("gpon.cpes.tableActions.tr069.reboot"),
                    action: (cpes) => CpesActionsService.tryToRebootCpes(cpes),
                    isShortcutDisabled: hasCpeLastInform
                }
            ];

            return shortcuts;
        };

        return service;
    }
]);
