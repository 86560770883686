"use strict";

var app = angular.module("nms.templates");

app.controller("SearchController", ["$scope", "$timeout", "ConverterService", "$filter",
    "$translate", "UserPreferencesService", "TABLE_PROPERTIES",
    function($scope, $timeout, ConverterService, $filter, $translate, UserPreferencesService,
        TABLE_PROPERTIES) {
        $scope.previousSearch = {};

        $scope.orderSearchCriteriaByFieldName = function() {
            var searchArray = ConverterService.convertMapToArray($scope.searchCriteria);
            searchArray = $filter("orderBy")(searchArray, "value.fieldName");
            $scope.searchCriteria = ConverterService.convertArrayToMap(searchArray);
        };

        $scope.clearSearch = function(keyword) {
            delete $scope.filters.searchColumn;
            delete $scope.filters.searchValue;
            delete $scope.search.field;
            delete $scope.search.value;
            delete $scope.previousSearch.field;
            delete $scope.previousSearch.value;

        // If keyword is true, it means that keyword has been clicked, so, no need for another search
            if (!keyword) {
                $scope.searchByCriteria();
            }
        };

        $scope.validateSearchFields = function() {
            if ($scope.previousSearch.field !== $scope.search.field ||
            $scope.previousSearch.value !== $scope.search.value) {
                if (!$scope.search.value) {
                    $scope.clearSearch();
                } else {
                    $scope.search.value = $scope.search.value.trim();

                    $scope.searchByCriteria();
                }
            }
        };

    /* Search templates by criteria */
        $scope.searchByCriteria = function() {
            $scope.filters.searchColumn = $scope.search.field;
            if ($scope.search.value) {
                $scope.filters.searchValue = encodeURIComponent($scope.search.value);
            }

            // Reset pageNumber and pageSize
            $scope.filters.pageNumber = 0;
            if (!$scope.filters.pageSize) {
                $scope.filters.pageSize = TABLE_PROPERTIES.DEFAULT_PAGE_SIZE;
            }
            $scope.pageDetails.currentPage = 1;

            /* set to session storage */
            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);

            // Clear keywords filter
            $scope.clearKeywordsFilter();

            // Load page with criteria search
            $scope.loadPage();

            // change the search button
            $timeout(function() {
                $scope.hasSearch = !!$scope.filters.searchValue;
            }, 1);
        };

        $scope.clearKeywordsFilter = function() {
            delete $scope.filters.keyword;
            UserPreferencesService.removeValue($scope.filters.origin, "keyword");
        };

        this.loadTooltipsText = function() {
            $scope.searchToolTip = $translate.instant("tooltips.search.searchToolTip");
        };

        $scope.orderSearchCriteriaByFieldName();
        this.loadTooltipsText();
    }]);
