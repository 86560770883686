import difference from "lodash4/fp/difference";
import groupBy from "lodash4/fp/groupBy";
import isEqual from "lodash4/fp/isEqual";
import keyBy from "lodash4/fp/keyBy";
import map from "lodash4/fp/map";

/**
 * Realiza a comparação entre dois arrays.
 * @param first Array original.
 * @param second Array a ser comparado.
 * @param idField Field que será usado como identificador na comparação.
 */
const diff = (first = [], second = [], idField = "id") => {
    const firstIds = [];
    const secondIds = [];

    /** Criando as chaves para o primeiro array. */
    const getKey = (o) => {
        firstIds.push(o[idField]);
        return o[idField];
    };

    const firstIndex = keyBy(getKey)(first);

    /**
     * Fazendo o agrupamento dos dados em [added, same, updated]
     * @param o2 Objeto contigo no array (second)
     */
    const groupingFunction = (o2) => {
        secondIds.push(o2[idField]);

        const o1 = firstIndex[o2[idField]];

        if (!o1) {
            return "added";
        } else if (isEqual(o1, o2)) {
            return "same";
        }

        return "updated";
    };

    const result = groupBy(groupingFunction)(second);

    /** Obtendo os dados que foram removidos do array. */
    const removedIds = difference(firstIds)(secondIds);
    const removed = map(id => firstIndex[id])(removedIds);

    /** Preparando o objeto de resposta. */
    return { same: [], added: [], updated: [], ...result, removed };
};

export default diff;
