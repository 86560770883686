"use strict";

/**
* Diretiva que cria e controla o componente de paginação de tabela pelo front-end.
*
* #id {string} Obrigatório - utilizado para salvar e identificar os dados da paginação de uma tabela especifica no localStorage.
* #tableModel {object} Obrigatório - objeto contendo todos os dados da tabela.
* #hideSelectedRows {boolean} Opcional - Esconde/Exibe a informação de total de itens selecionado no canto inferior da tabela.
* #handlePaginationVisibility {boolean} Opcional - Esconde/exibe a paginação de acordo com a quantidade de itens na tabela.
* #usePattern {boolean} Opcional - Caso true, seta um pattern para validar o input de itens por página (intervalo de 5-100).
* #paginationLimit {boolean} Opcional - Caso true, seta o atributo pagination-limit-validation no input de itens por página
* para validar a quantidade máxima de itens (default 100).
* #loadTablePreferences {boolean} Opcional - Caso true, sempre irá atualizar as preferências da tabela independente da
* quantidade de linhas.
*/

var app = angular.module("nms");

app.directive("clientPagination", ["$compile", "$rootScope", function($compile, $rootScope) {
    return {
        templateUrl: "templates/components/ui/pagination/client/client-pagination.html",
        controller: "ClientPaginationController",
        scope: {
            id: "@",
            tableModel: "=",
            hideSelectedRows: "=?",
            handlePaginationVisibility: "=?",
            usePattern: "=?",
            paginationLimit: "=?",
            loadTablePreferences: "=?"

        },
        link: function(scope, element, attrs) {
            scope.hideSelectedRows = !!scope.hideSelectedRows;
            scope.handlePaginationVisibility = !!scope.handlePaginationVisibility;

            if (scope.usePattern) {
                var input = angular.element(element.find("#pageSize"));
                input.attr("ng-pattern", scope.rangePattern);
                input.attr("name", "customPageSize");
            }

            if (scope.paginationLimit) {
                angular.element(element.find("#pageSize")).attr("pagination-limit-validation", true);
            }

            var html = element.html();
            element.contents().remove();
            element.html(html);
            $compile(element.contents())(scope);
            $rootScope.$broadcast("updateTableHeight");
        }
    };
}]);
