/**
* @ngDoc directive
* @name tooltipTextBuilder
* @description Builder para texto dos tooltips (apenas tooltips menores que 510 caracteres serão exibidos).
*/

var app = angular.module("nms.dynamicDevice");

app.directive("tooltipTextBuilder", ["$timeout",
    function($timeout) {
        return {
            restrict: "A",
            link: function(scope, element, attrs) {
                $timeout(function() {
                    var fieldText;
                    if (attrs.customTooltipText) {
                        fieldText = attrs.customTooltipText;
                    } else {
                        fieldText = element[0].textContent;
                    }

                    if (fieldText.length <= 510) {
                        element.attr("title", fieldText);
                        element.tooltip();
                    }
                }, 5);
            }
        };
    }
]);
