"use strict";

var app = angular.module("nms.dynamicDevice");

app.service("XPathResolverService", ["$injector", "NodeInfoCache", "DataPathService",
    function($injector, NodeInfoCache, DataPathService) {
        var xpathOptions: any = {
            language: fontoxpath.evaluateXPath.XPATH_3_1_LANGUAGE
        };
        var DomainHandlerService;

        var replaceQuotationToMark = function(xpath, text, index) {
            return xpath.replace(text, "{quotation" + index + "}");
        };

        var replaceMarkToQuotation = function(xpath, text, index) {
            return xpath.replace("{quotation" + index + "}", text);
        };

        var replaceQuotationTexts = function(xpath, quotationTexts, replaceFunction) {
            _.each(quotationTexts, function(text, index) {
                xpath = replaceFunction(xpath, text, index);
            });

            return xpath;
        };

        var removeNamespaces = function(xpath) {
            var namespacesRegex = /[A-Za-z0-9_.-]+\:/g;
            var quotationTexts = xpath.match(/'[^']*'|(\+)/g);

            if (!_.isEmpty(quotationTexts)) {
                xpath = replaceQuotationTexts(xpath, quotationTexts, replaceQuotationToMark);
                xpath = xpath.replace(namespacesRegex, "");
                xpath = replaceQuotationTexts(xpath, quotationTexts, replaceMarkToQuotation);

                return xpath;
            } else {
                return xpath.replace(namespacesRegex, "");
            }
        };

        var includePathKeys = function(path, pathKeys) {
            if (!_.isEmpty(pathKeys)) {
                var pathWithPathKeys = "";
                var subPath = "";
                var pathFragments = _.compact(path.split("/"));
                _.forEach(pathFragments, function(fragment) {
                    subPath += "/" + fragment;

                    var foundKeys = _.find(pathKeys, function(keys) {
                        return keys.path === subPath;
                    });
                    if (foundKeys) {
                        var keyValues = _.compact(_.map(Object.keys(foundKeys),
                                function(key) {
                                    if (key !== "path") {
                                        return key + " = \"" + foundKeys[key] + "\"";
                                    }
                                }
                            )).join(" and ");
                        pathWithPathKeys += "/" + fragment + "[" + keyValues + "]";
                    } else {
                        pathWithPathKeys += "/" + fragment;
                    }
                });

                return pathWithPathKeys;
            }

            return path;
        };

        var replaceCurrentFunction = function(xpath, currentPath) {
            if (_.contains(xpath, "current()")) {
                return xpath.replace(/current\(\)/g, "/" + currentPath);
            }

            return xpath;
        };

        var removePredicates = function(xpath) {
            return xpath.replace(/\[.*?\]/, "");
        };

        var getNodeFromCache = function(path) {
            return NodeInfoCache.getNode(removePredicates(path));
        };

        var resolveDeref = function(pathToDeref) {
            var referencedNode = getNodeFromCache(pathToDeref);
            var referencedPath = _.get(referencedNode, "leafType.sub-statements.path.0");

            return removeNamespaces(referencedPath);
        };

        var getPathKeyForDeref = function(path, value) {
            // FIXME: Provavelmente deveria retornar apenas um objeto, para ser combinado
            //        em um array fora deste método. Se possível, seria ideal até tirar daqui
            //        pois a lógica é meio genérica.
            var lastSlashIndex = _.lastIndexOf(path, "/");
            var parentPath = path.slice(0, lastSlashIndex);
            var identifier = path.slice(lastSlashIndex + 1);

            var obj: any = {path: parentPath};

            obj[identifier] = value;

            return [obj];
        };

        // TODO [ACTIONS]: Aqui para suportar o deref de forma realmente recursiva, teria que repassar as chaves das listas
        //                 e adicionando no array de pathKeys. O problema é que da maneira que está estruturado o código fica
        //                 difícil de ter essa informação pois no momento que deveria adicionar o path e suas respectivas chaves,
        //                 o path pode ser um deref ainda não resolvido totalmente.
        var replaceDerefFunction = function(xpath, currentPath, pathKeys, iteration?) {
            // FIXME: Tentar achar um jeito melhor. Hoje existe uma dependencia circular que de certa forma faz sentido.
            //        Uma forma de fazer isso seria por este caminho: https://goo.gl/HEH5VB
            if (!DomainHandlerService) {
                DomainHandlerService= $injector.get("DomainHandlerService");
            }

            iteration = iteration || 0;

            var derefRegex = RegExp("deref\\((.*?)\\)");
            var match = xpath.match(derefRegex);

            if (match) {
                var derefParam = goBackwards(match[1], currentPath);
                var nodeToBeDeref = getNodeFromCache(derefParam);
                var dataPathWithKeyValues = DataPathService.getNodePath(pathKeys, nodeToBeDeref);
                var valueOfNodeToBeDeref = DomainHandlerService.getDataNode(dataPathWithKeyValues).value;
                var resolvedDeref = resolveDeref(derefParam);
                var pathKeysForDeref = getPathKeyForDeref(resolvedDeref, valueOfNodeToBeDeref);
                var replacedPath = replaceDerefFunction(resolvedDeref, currentPath, pathKeysForDeref, ++iteration);
                var resultXPath = goBackwards(xpath.replace(derefRegex, replacedPath));

                return resultXPath;
            }

            var found = _.find(pathKeys, function(item) {
                return xpath.startsWith(item.path);
            });

            if (found && iteration > 0) {
                xpath = includePathKeys(xpath, pathKeys);
            }

            return xpath;
        };

        var goBackInXpath = function(xpath, path?) {
            if (xpath.startsWith("..") && path) {
                xpath = path + "/" + xpath;
            }

            var xpathParts = xpath.split("/");
            var resultXPath = [];

            _.each(xpathParts, function(xpathPart) {
                if (xpathPart === "..") {
                    resultXPath.pop();
                } else {
                    resultXPath.push(xpathPart);
                }
            });

            return resultXPath.join("/");
        };

        var getPredicatesIndexRange = function(xpath) {
            var predicateMarkStack = [];
            var ranges = [];

            return _.reduce(xpath, function(predicateRanges, character, index) {
                if (character === "[") {
                    predicateMarkStack.push(index);
                }

                if (character === "]") {
                    var startIndex = predicateMarkStack.pop();
                    ranges.push({start: startIndex, end: index + 1});

                    if (predicateMarkStack.length === 0) {
                        predicateRanges.push(ranges);
                        ranges = [];
                    }
                }

                return predicateRanges;
            }, []);
        };

        var getPredicatesInfo = function(xpath) {
            var predicatesIndexRange = getPredicatesIndexRange(xpath);
            var index = 0;

            return _.map(predicatesIndexRange, function(predicateIndexes) {
                var predicatesInfo = _.reduce(predicateIndexes, function(predicates, range) {
                    var predicate = xpath.substring(range.start, range.end);
                    var processedPredicate = predicate;

                    _.each(predicates, function(previousPredicate) {
                        processedPredicate = processedPredicate.replace(previousPredicate.predicate, previousPredicate.key);
                    });

                    predicates.push({
                        key: "[predicate" + index++ + "]",
                        predicate: predicate,
                        processedPredicate: processedPredicate
                    });

                    return predicates;
                }, []);

                return predicatesInfo;
            });
        };

        var replacePredicateKeys = function(xpath, path, predicateValues) {
            var predicateKeyRegex = /\[predicate\d+\]/g;

            xpath = goBackInXpath(xpath, path);
            var matches = xpath.match(predicateKeyRegex);
            while (matches) {
                _.each(matches, function(match) {
                    xpath = xpath.replace(match, predicateValues[match]);
                });
                matches = xpath.match(predicateKeyRegex);
            }

            return xpath;
        };

        var getProcessedPredicate = function(predicateInfo, path?) {
            var predicate = predicateInfo.processedPredicate;
            var xpathPredicate = predicate.substring(predicate.indexOf("=") + 1).replace(/\]$/g, "").trim();
            var processedXpath = goBackInXpath(xpathPredicate, path);

            return predicate.replace(xpathPredicate, processedXpath);
        };

        var goBackwards = function(xpath, path?) {
            if (_.contains(xpath, "..")) {
                var predicatesInfo = getPredicatesInfo(xpath);

                if (predicatesInfo.length !== 0) {
                    var predicateValues = _.reduce(predicatesInfo, function(values, predicateInfo) {
                        _.each(predicateInfo, function(info, index) {
                            info.processedPredicate = getProcessedPredicate(info, path);
                            values[info.key] = info.processedPredicate;

                            if (index === predicateInfo.length - 1) {
                                xpath = xpath.replace(info.predicate, info.key);
                            }
                        });

                        return values;
                    }, {});

                    return replacePredicateKeys(xpath, path, predicateValues);
                }

                xpath = goBackInXpath(xpath, path);
                return xpath;
            }

            return xpath;
        };

        var processXPath = function(xpath, path, pathKeys) {
            pathKeys = pathKeys || [];
            var currentPath = includePathKeys(path, pathKeys);

            xpath = removeNamespaces(xpath);
            xpath = replaceCurrentFunction(xpath, currentPath);
            xpath = replaceDerefFunction(xpath, currentPath, pathKeys);
            xpath = goBackwards(xpath, currentPath);

            return "/" + xpath;
        };

        var resolveCurrentPath = function(currentPath, pathKeys) {
            pathKeys = pathKeys || [];
            var pathWithKeys = includePathKeys(currentPath, pathKeys);

            return "/root" + pathWithKeys;
        };

        var includeRootElement = function(xpathExpression, rootElementName) {
            return xpathExpression.replace(/(^|[\s(])\//g, "$1/" + rootElementName + "/");
        };

        var addNamespaceToCustomFunctions = function(xpathExpression) {
            return xpathExpression
                .replace(/derived-from-or-self\s*\(/g, "confd:derived-from-or-self(")
                .replace(/re-match\s*\(/g, "confd:re-match(");
        };

        var evaluateXPathToBoolean = function(rootDocument, xpathExpression, currentPath, pathKeys) {
            var isValid = false;
            var currentPathResolved = resolveCurrentPath(currentPath, pathKeys);
            var fixedExpression = includeRootElement(xpathExpression, "root");

            fixedExpression = removeNamespaces(fixedExpression);
            fixedExpression = replaceCurrentFunction(fixedExpression, currentPathResolved);
            fixedExpression = addNamespaceToCustomFunctions(fixedExpression);

            var contextNode = fontoxpath.evaluateXPathToFirstNode(currentPathResolved, rootDocument, null, null, xpathOptions);

            /* global fontoxpath */
            fontoxpath.registerCustomXPathFunction("confd:derived-from-or-self", ["node()", "xs:string"], "xs:boolean",
                function(dynamicContext, node, typeName) {
                    /* TODO Implementar quando necessáiro.
                       Essa função atualmente não é usada em sub-statements "when" ou "display-when"
                       No momento essa função não é utilizada a não ser em yangs de exemplo */
                    throw new Error(
                        "Function confd:derived-from-or-self is not implemented for 'when' and display 'when' statements");
                });
            /* global fontoxpath */
            fontoxpath.registerCustomXPathFunction("confd:re-match", ["xs:string", "xs:string"], "xs:boolean",
                function(dynamicContext, value, pattern) {
                    /* global XRegExp */
                    return new XRegExp(pattern).test(value);
                });

            if (contextNode) {
                isValid = fontoxpath.evaluateXPathToBoolean(fixedExpression, contextNode, null, null, xpathOptions);
            }

            return isValid;
        };

        var generateElementKeys = function(elementPath, pathKeys) {
            var keys = _.find(pathKeys, function(keys) {
                return keys.path === elementPath;
            });
            return _.chain(keys)
                .pairs()
                .filter(function(pair) {
                    return pair[0] !== "path";
                }).value();
        };

        var generateElement = function(doc, fullPathArray, pathKeys, firstPresentNodePath, child) {
            var elementName = _.last(fullPathArray);
            var elementPath = fullPathArray.join("/");
            if (elementPath === firstPresentNodePath) {
                return child;
            }

            var element = doc.createElement(elementName);
            generateElementKeys(elementPath, pathKeys)
                .forEach(function(keyValue) {
                    var keyElement = doc.createElement(keyValue[0]);
                    keyElement.appendChild(doc.createTextNode(keyValue[1]));
                    element.appendChild(keyElement);
                });
            if (child) {
                element.appendChild(child);
            }

            return generateElement(doc, _.slice(fullPathArray, 0, fullPathArray.length-1),
                pathKeys, firstPresentNodePath, element);
        };

        var createMissingElementsFromXPath = function(doc, xpath, pathKeys) {
            var xPathArr = xpath.split("/");
            var firstPresentNode;
            var firstPresentNodePath;
            var absentPathArr;
            for (var index = xPathArr.length; index >= 0; index--) {
                firstPresentNodePath = _.slice(xPathArr, 0, index);
                var currentXpath = resolveCurrentPath(firstPresentNodePath.join("/"), pathKeys);
                /* global fontoxpath */
                firstPresentNode = fontoxpath.evaluateXPathToFirstNode(currentXpath, doc, null, null, xpathOptions);
                if (firstPresentNode) {
                    absentPathArr = _.slice(xPathArr, index, xPathArr.length);
                    break;
                }
            }
            if (!_.isEmpty(absentPathArr)) {
                var missingNode = generateElement(doc, xPathArr, pathKeys, firstPresentNodePath.join("/"), null);
                firstPresentNode.appendChild(missingNode);
            }
        };

        return {
            removeNamespaces: removeNamespaces,
            goBackwards: goBackwards,
            processXPath: processXPath,
            evaluateXPathToBoolean: evaluateXPathToBoolean,
            createMissingElementsFromXPath: createMissingElementsFromXPath
        };
    }
]);
