import { Inject, Injectable } from "@angular/core";
import {
  ANGULARJS_TRANSLATE,
  CWMP_DOWNLOAD_FILE_TYPE,
  NMS_STATES,
  STATE,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsToasterLink } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr-model";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import {
  webSocket,
  WebSocketSubject,
  WebSocketSubjectConfig,
} from "rxjs/webSocket";
import {
  DownloadOperationResponse,
  DownloadRequestParameters,
} from "./download.service";

export interface TransferCompleteMessage {
  commandKey: string;
  startTime: Date;
  completeTime: Date;
  faultCode: string;
  faultString: string;
}

const WEB_SOCKET_TIMEOUT_CODE = 1001;

@Injectable({
  providedIn: "root",
})
export class TransferCompleteWebsocketService {
  readonly TRANSFER_COMPLETE_SUCCESS: string = "0";

  transferCompleteWebSocket: WebSocketSubject<TransferCompleteMessage>;
  webSocketUri: string;
  webSocketFactory: any;

  requestsInProgress: number;
  receivedTransferCompleteMessages: Array<TransferCompleteMessage>;
  receivedDownloadOperationResponses: Array<DownloadOperationResponse>;
  downloadRequestParameters: Array<DownloadRequestParameters>;

  constructor(private window: Window,
          private toastr: NmsToastrService,
          @Inject(ANGULARJS_TRANSLATE) private translate: any,
          @Inject(STATE) private $state: any,
          @Inject(NMS_STATES) private nmsStates: any,
          @Inject(CWMP_DOWNLOAD_FILE_TYPE) private downloadFileTypeConstants: any) {
      this.requestsInProgress = 0;
      this.receivedTransferCompleteMessages = new Array<TransferCompleteMessage>();
      this.receivedDownloadOperationResponses = new Array<DownloadOperationResponse>();
      this.downloadRequestParameters = new Array<DownloadRequestParameters>();

      this.webSocketFactory = webSocket;
      this.transferCompleteWebSocket = this.webSocketFactory();
      this.webSocketUri = `wss://${this.window.location.host}/acs-client/transferCompleteNotification`;
  }

  public initWebsocket = (downloadRequestParameters: Array<DownloadRequestParameters>) => {
      if (this.requestsInProgress === 0) {
          this.initializeWebsocket();
      }
      this.downloadRequestParameters = this.downloadRequestParameters.concat(downloadRequestParameters);
      this.requestsInProgress += downloadRequestParameters.length;
  }

  public setDownloadOperationResponse = (downloadOperationResponses: Array<DownloadOperationResponse>) => {
      this.filterDownloadOperationResponse(downloadOperationResponses);
      this.receivedDownloadOperationResponses = this.receivedDownloadOperationResponses.concat(downloadOperationResponses);
      this.processReceivedMessageToShow();
  }

  private filterDownloadOperationResponse = (downloadOperationResponses: Array<DownloadOperationResponse>) => {
      downloadOperationResponses.forEach((downloadOperationResponse, index, array) => {
          const { downloadStatus } = downloadOperationResponse;

          // Se status for nulo ou Complete remove da lista downloadOperationResponses.
          if (!downloadStatus || downloadStatus === "0") {
              array.splice(index, 1);
              this.requestsInProgress--;
          }
      });
  }

  /**
   * Se inscreve no websocket de transfer complete a fim de receber as mensagens
   * vindas do servidor.
   */
  private websocketSubscriber = async () => {
      this.transferCompleteWebSocket.subscribe((response: TransferCompleteMessage) => {
          this.receivedTransferCompleteMessages.push(response);
          this.processReceivedMessageToShow();
      });
  };

  private initializeWebsocket = () => {
      let closeFunction = (closeEvent: CloseEvent) => {
          /*
           * Verifica se o motivo do encerramento do websocket se refere a timeout,
           * neste caso, se registra novamente para seguir ouvindo as mensagens.
           */
          if (closeEvent.code === WEB_SOCKET_TIMEOUT_CODE) {
              this.websocketSubscriber();
          }
      };

      let config: WebSocketSubjectConfig<TransferCompleteMessage> = {
          url: this.webSocketUri,
          closeObserver: {
              next(closeEvent: CloseEvent) {
                  closeFunction(closeEvent);
              }
          }
      };

      this.transferCompleteWebSocket = this.webSocketFactory(config);
      this.websocketSubscriber();
  };

  private processReceivedMessageToShow = () => {
      this.receivedTransferCompleteMessages.forEach((transferComplete, index, array) => {
          const { commandKey } = transferComplete;

          let runningRequest = this.receivedDownloadOperationResponses.find(receivedDownload => receivedDownload.commandKey === commandKey);

          if (runningRequest) {
              this.showMessage(transferComplete, runningRequest);
              let parameterIndex = this.downloadRequestParameters.findIndex(parameter => parameter.serialNumber === runningRequest.serialNumber);
              array.splice(index, 1);
              this.receivedDownloadOperationResponses.splice(this.receivedDownloadOperationResponses.indexOf(runningRequest), 1);
              this.downloadRequestParameters.splice(parameterIndex, 1);
              this.requestsInProgress--;
          }
      });

      if (this.receivedDownloadOperationResponses.length === 0 && this.requestsInProgress === 0) {
          console.info("Close Transfer Complete WebSocket session.");
          this.transferCompleteWebSocket.unsubscribe();
      }
  }

  private showMessage = (transferComplete: TransferCompleteMessage, runningRequest: DownloadOperationResponse ) => {
      const { faultCode, faultString } = transferComplete;

      const downloadRequestParameters = this.downloadRequestParameters
          .find(downloadRequestParameter => downloadRequestParameter.serialNumber === runningRequest.serialNumber);

      faultCode === this.TRANSFER_COMPLETE_SUCCESS ? this.showSuccessMessage(downloadRequestParameters)
          : this.showErrorMessage(downloadRequestParameters, faultCode, faultString);
  }

  private showSuccessMessage = (downloadRequestParameters: DownloadRequestParameters) => {
      let successMessage = this.translate.instant("download.transfer.complete.success.message")
          .replace("{0}", downloadRequestParameters.serialNumber)
          .replace("{1}", downloadRequestParameters.fileUrl)
          .replace("{2}", this.getFileTypeDescription(downloadRequestParameters.fileType));

      this.toastr.success(successMessage, null, {
          enableHtml: true,
          // @ts-ignore
          links: this.getSuccessParameterLink(downloadRequestParameters.serialNumber) })
      .onAction.subscribe(toastr => toastr.action() );
  }

  private showErrorMessage = (downloadRequestParameters: DownloadRequestParameters, faultCode: string, faultString: string) => {
      let tostErrorMessage = this.translate.instant("download.transfer.complete.error.message")
          .replace("{0}", downloadRequestParameters.serialNumber)
          .replace("{1}", downloadRequestParameters.fileUrl)
          .replace("{2}", this.getFileTypeDescription(downloadRequestParameters.fileType))
          .replace("{3}", faultCode).replace("{4}", faultString);

      this.toastr.error(tostErrorMessage, null, {
          enableHtml: true
      });
  }

  private getFileTypeDescription = (type: number): string => {
      return _.values(this.downloadFileTypeConstants).find(fileType => fileType.type === type).description;
  }

  private getSuccessParameterLink = (serialNumber: string): Array<NmsToasterLink> => {
      let links: Array<NmsToasterLink> = [];

      links.push(
          {
              id: serialNumber,
              title: this.translate.instant("cwmp.parameters.view.link"),
              action: () => this.$state.go(this.nmsStates.cwmpParameters, { serialNumber })
          });

      return links;
  }
}

