import { Injectable } from "@angular/core";
import { NmsTableRow, NmsTableRowRenderType } from "@nms-ng1/components/ui/nms-table-old/nms-table-models";
import { CwmpOperationType } from "./cwmp-parameters.service";

/**
 * Tipos de valores de um parâmetro.
 */
export enum ParameterValueType {
    STRING = "xsd:string",
    INT = "xsd:int",
    UNSIGNED_INT = "xsd:unsignedInt",
    BOOLEAN = "xsd:boolean",
    DATE_TIME = "xsd:dateTime"
}

/** Dados a serem apresentados na tabela. */
export interface Parameters extends NmsTableRow {
    /** Path do parâmetro. */
    parameter: string,
    /** Valor do parâmetro. */
    value: any,
    /** Indica se o parâmetro é de leitura ou de escrita */
    writable: boolean,
    /** Indica o tipo do parâmetro em sua forma bruta */
    type: string,
    /**
     * Possibilita que parâmetros do tipo STATUS possam ser renderizados em itálico na tabela
     * e que parâmetros do tipo CONFIG utilizem negrito.
     * */
    className: string,
    /**
     * Define qual tipo de renderização uma célula na coluna assumirá.
     * TODO [TA340908][TA340909][TA340909] - Atualizar os tipos conforme os componentes forem definidos.
     * Tipos possíveis @see NmsTableRowRenderType: text, inputText, unsignedIntNumber, intNumber, toggle, button e datetime.
     */
    renderType: string,
    /**
     * Possibilita alterar o estilo da linha através do css informando o nome da classe
     */
    highlightRow?: string
}

const CONTAINER_NAME_PATTERN = /\.$/;
const FALSE_VALUE_PATTERN = /^(0|false)$/i;
const TRUE_VALUE_PATTERN = /^(1|true)$/i;

/**
 */
@Injectable({
    providedIn: "root"
})
export class CwmpParametersResolverService {

     /**
     * Resolve um @see Parameters.
     */
    public resolveParameterValues = (data: any, buttonAction: Function): Parameters => {
        const isDisable = false;
        const value = this.getConvertedValue(data.value, data.type);
        const className = this.getRenderClass(data.writable, data.parameter);
        const renderType = this.getRenderType(data.writable, data.parameter, data.type);
        return {
            ...data,
            isDisable,
            value,
            className,
            renderType,
            ...renderType == NmsTableRowRenderType.BUTTON ? { action: buttonAction } : {}
        }
    }

    /**
     * Converte os valores necessários para os tipos apropriados.
     */
    private getConvertedValue = (value: string, type: string): any => {
        if (type == ParameterValueType.INT || type == ParameterValueType.UNSIGNED_INT) {
            return parseInt(value);
        } else if (type == ParameterValueType.BOOLEAN
            && (FALSE_VALUE_PATTERN.test(value) || TRUE_VALUE_PATTERN.test(value))) {
            return TRUE_VALUE_PATTERN.test(value)
        }

        return value;
    }

    /**
     * Verifica se o parametro é de escrita e é do tipo container.
     * O botão 'Adicionar' será renderizado sempre que o nome do parâmetro terminar com "." e não for precedido por um número
     * O botão 'Remover' será renderizado sempre que o nome do parâmetro terminar com "." e for precedido por um número
     */
    private getRenderClass = (writable: boolean, parameter: string): string => {
        if (writable &&  this.isContainerType(parameter)) {
            var regex =  new RegExp(/(\d\.)$/g);
            var isRemoveButton = regex.test(parameter);
            return isRemoveButton ? CwmpOperationType.DELETE : CwmpOperationType.ADD;
        }
        return writable ? "config" : "status";
    }

    /**
     * Define o tipo de renderização para os parametros recebidos.
     * Caso o parametro seja de leitura, deve ser renderizado como @see NmsTableRowRenderType.TEXT.
     * Caso seja de escrita, deve ser renderizado como uma das opções para edição.
     */
    private getRenderType = (writable: boolean, parameter: string, type: string): string => {
        if (writable) {
            return this.getEditableRenderType(parameter, type);
        }

        return NmsTableRowRenderType.TEXT;
    };

    /**
     * Define um tipo de renderização editável para os parâmetros recebidos.
     * O tipo de renderização default é @see NmsTableRenderType.INPUT_TEXT.
     * @see NmsTableRenderType.BUTTON - Se o parâmetro for um container.
     * @see NmsTableRenderType.INPUT_TEXT - Se o parâmetro for do tipo @see ParameterValueType.STRING.
     * @see NmsTableRowRenderType.INPUT_NUMBER - Se o parâmetro for do tipo @see ParameterValueType.INT.
     * @see NmsTableRowRenderType.INPUT_UNSIGNED_NUMBER - Se o parâmetro for do tipo @see ParameterValueType.UNSIGNED_INT.
     * @see NmsTableRowRenderType.TOGGLE - Se o parâmetro for do tipo @see ParameterValueType.BOOLEAN.
     * @see NmsTableRenderType.INPUT_DATE_TIME - Se o parâmetro for do tipo @see ParameterValueType.DATE_TIME.
     */
    private getEditableRenderType = (parameter: string, type: string): string => {
        if (this.isContainerType(parameter)) {
            return NmsTableRowRenderType.BUTTON;
        }

        switch(type) {
            case ParameterValueType.STRING:
                return NmsTableRowRenderType.INPUT_TEXT;
            case ParameterValueType.INT:
                return NmsTableRowRenderType.INPUT_INT_NUMBER;
            case ParameterValueType.UNSIGNED_INT:
                return NmsTableRowRenderType.INPUT_UNSIGNED_INT_NUMBER;
            case ParameterValueType.BOOLEAN:
                return NmsTableRowRenderType.TOGGLE;
            case ParameterValueType.DATE_TIME:
                return NmsTableRowRenderType.INPUT_DATE_TIME;
            default:
                return NmsTableRowRenderType.INPUT_TEXT;
        }
    }

    /**
     * Um parâmetro é do tipo container quando termina com '.'.
     */
    private isContainerType = (parameter: string): boolean => {
        return CONTAINER_NAME_PATTERN.test(parameter);
    }
}
