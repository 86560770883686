"use strict";

/**
* @ngdoc service
* @name nms.components.ui.device:DeviceDropdownModelService
* @description - Service para gerenciamento de modelos para a diretiva device-dropdown. Disponibiliza funções para criação do
* modelo a partir dos diferentes modelos de objeto utilizado nas diversas features (aplicação de template, dynamic device, summary
* e GPN ONUs e Manage Device). Utiliza o conceito de cache para armazenar os dados já obtidos de cada equipamento a fim de reduzir
* a necessidade de consultas no back-end.
* # DeviceDropdownModelService
* Factory in the nms.
*/
var app = angular.module("nms");

// TODO - [US-314] Verificar se esse service será reutilizado para CPE, caso não for, remover o TemplateInstanceIdentifierService.
// Caso for ser reutilizado, renomear as ocorrências de device para equipment

app.factory("DeviceDropdownModelService", ["DeviceDropdownModel", "ManagementDeviceRestService", "TemplateInstanceIdentifierService",
    function(DeviceDropdownModel, ManagementDeviceRestService, TemplateInstanceIdentifierService) {
        /**
        * A utilização desta cache é necessária devido a ausência da informação de 'availableFeatures' que é utilizada na diretiva
        * para disponibilizar as features específicas e hoje é presente apenas no modelo de dados da Manage Device (front e
        * back-end). Se houver uma forma de incluir o atributo 'availableFeatures' no objeto de modelo de todas as features, o
        * modelo tratado por este service poderá ser sempre criado sem necessidade de nenhuma requisição REST ou cache.
        */
        var availableFeaturesCache: any = {};

        const service: any = {};

        service.createModelFromManagedDevice = function(managedDevice) {
            var model = new DeviceDropdownModel(managedDevice);
            availableFeaturesCache[managedDevice.id] = managedDevice.availableFeatures;
            return model;
        };

        service.createModelFromGponOnu = function(gponOnu) {
            var device: any = {
                id: gponOnu.deviceId,
                name: gponOnu.deviceName,
                hostname: gponOnu.deviceHostname,
                modelName: gponOnu.deviceModelName,
                fwVersion: gponOnu.deviceFwVersion,
                locationId: gponOnu.deviceLocationId,
                modelCode: gponOnu.deviceModelCode,
                vendorCode: gponOnu.deviceVendorCode
            };
            return new DeviceDropdownModel(device);
        };

        service.createModelFromDeviceSearchModal = function(device) {
            return new DeviceDropdownModel(device);
        };

        service.createModelFromTemplateInstanceDevice = function(templateInstanceDevice) {
            var device: any = {
                id: TemplateInstanceIdentifierService.resolveIdentifier(templateInstanceDevice.equipmentIdentifier),
                name: templateInstanceDevice.equipmentDetails.name,
                hostname: templateInstanceDevice.equipmentDetails.hostname,
                type: templateInstanceDevice.equipmentIdentifier.type
            };

            return new DeviceDropdownModel(device);
        };

        service.createModelFromTemplateApplicationResult = function(applicationResult) {
            var device: any = {
                id: TemplateInstanceIdentifierService.resolveIdentifier(applicationResult.equipmentIdentifier),
                name: applicationResult.equipmentDetails.name,
                hostname: applicationResult.equipmentDetails.hostname,
                type: applicationResult.equipmentIdentifier.type,
                model: applicationResult.equipmentDetails.model
            };

            return new DeviceDropdownModel(device);
        };

        service.createModelFromVariableExtractionResult = function(extraction) {
            var device: any = {
                id: TemplateInstanceIdentifierService.resolveIdentifier(extraction.equipmentIdentifier),
                name: extraction.equipmentDetails.name,
                hostname: extraction.equipmentDetails.hostname
            };

            return new DeviceDropdownModel(device);
        };

        service.getAvailableFeatures = function(deviceId) {
            var cachedAvailableFeatures = availableFeaturesCache[deviceId];
            if (cachedAvailableFeatures) {
                return cachedAvailableFeatures;
            }

            return ManagementDeviceRestService.findDeviceById(deviceId).then(function(device) {
                var availableFeatures = device.availableFeatures;
                availableFeaturesCache[deviceId] = availableFeatures;
                return availableFeatures;
            });
        };

        return service;
    }
]);
