"use strict";

/**
 * Diretiva responsável por inserir o componente de contagem regressiva que
 * exibe o tempo que falta para que a atualização seja feita novamente.
 */

var app = angular.module("nms");

app.directive("autoUpdaterCountdown",
    function() {
        return {
            templateUrl: "templates/components/ui/auto-updater/auto-updater-countdown.html",
            controller: "AutoUpdaterCountdownController",
            scope: true
        };
    }
);