/**
* @ngdoc directive
* @name nms.templates.directive:varsToApply
* @description Diretiva para criação do componente formulário de variáveis na aplicação de template.
*/
"use strict";

angular.module("nms.templates")
.directive("varsToApply", function() {
    return {
        restrict: "E",
        templateUrl: "templates/features/template/components/ui/template-instance-variables/template-instance-variables.html",
        link: function(scope, element, attrs) {
            scope.variable = _.get(scope, "globalVar", scope.localVar);
            scope.$watchCollection(attrs.variable, function(value) {
                scope.variable = value;
            });
        }
    };
});
