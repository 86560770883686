import { Component, Inject, Input, OnInit } from "@angular/core";
import { TooltipOptions } from "ng2-tooltip-directive";
import { Subject } from "rxjs";
import {
    AsyncUpdater,
    NmsTableActions,
    NmsTableColumn,
    NmsTableColumnType,
    NmsTableConfig,
    NmsTableRowAsync,
    RefreshActions,
    RowUpdateType,
    NmsTableSelectionUpdater
} from "@nms-ng1/components/ui/nms-table-old/nms-table-models";
import {
    ANGULARJS_ROOTSCOPE,
    ANGULARJS_TRANSLATE,
    USER_PREFERENCES_SERVICE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { SchedulerJobDetailToViewConverterService } from "@nms-ng2/app/modules/scheduler/scheduler-list/scheduler-table/scheduler-job-detail-to-view-converter.service";
import {
    SchedulerJobDetails,
    SchedulerJobDetailsView,
    SchedulerJob,
    JobExecutionStatus
}
from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { SchedulerListService } from "../scheduler-list.service";
import {
    SchedulerBroadcastFeedbackMessage,
    SchedulerJobWebsocketService,
    SchedulerNotificationType
} from "./scheduler-job-websocket.service";
import { SchedulerResolverService } from "../../scheduler-resolver.service";
import { SchedulerPermissionsService } from "../../scheduler-permissions.service";

/**
 * Classe utilizada no filtro de busca global
 */
export class SchedulerJobsListOptions {
    public static readonly FILTER_KEY: string = "list-scheduler-job-options";
    public static readonly PROPERTIES_KEYS: Array<string> = ["globalFilter"];

    public globalFilter: string;

    constructor(globalFilter: string) {
        this.globalFilter = globalFilter;
    }
}

/* configurações da tabela */
const TABLE_CONFIG: NmsTableConfig = {
    tableId: "scheduler-table",
    tableTranslatePrefix: "scheduler.table",
    initialSort: { nextExecutionTimeStamp: "asc" },
    selectionId: "scheduler-table-selector",
    rowId: "jobId"
};

@Component({
    selector: "scheduler-table",
    templateUrl: "./scheduler-table.component.html",
    styleUrls: ["./scheduler-table.component.scss"]
})
export class SchedulerTableComponent implements OnInit {
    @Input() schedulerProvider: Function;
    @Input() refreshActions?: RefreshActions;
    searchAllFilterText: string;
    tooltipOptions: TooltipOptions;
    columns: Array<NmsTableColumn>;
    config: NmsTableConfig;
    scheduledJobs: Array<SchedulerJobDetailsView>;
    tableActions: Array<NmsTableActions>;
    asyncUpdater: AsyncUpdater;
    tableBodyMessage: string;
    selectedScheduledJobs: Array<SchedulerJobDetailsView> = new Array<SchedulerJobDetailsView>();
    jobsByJobId: { [id: string]: SchedulerJob } = {};
    changeSelectedItems: NmsTableSelectionUpdater;
    readonly noJobSelectedKey = "scheduler.table.actions.no.schedule.selected";
    readonly DISABLE_SELECTION = this.$translate.instant("scheduler.table.actions.disable.oneSelected")
    readonly ENABLE_SELECTION = this.$translate.instant("scheduler.table.actions.enable.oneSelected");
    readonly EXECUTE_SELECTION = this.$translate.instant("scheduler.table.actions.execute.now");
    readonly EXECUTE_ALL_SELECTION = this.$translate.instant("scheduler.table.actions.execute.task.selected");

    constructor(
        @Inject(ANGULARJS_TRANSLATE) private readonly $translate: any,
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope,
        @Inject(USER_PREFERENCES_SERVICE) private readonly userPreferenceService: any,
        private readonly schedulerListService: SchedulerListService,
        private readonly schedulerJobDetailToViewConverterService: SchedulerJobDetailToViewConverterService,
        private readonly schedulerJobWebsocketService: SchedulerJobWebsocketService,
        private readonly schedulerResolverService: SchedulerResolverService,
        private readonly schedulerPermissionsService: SchedulerPermissionsService) {

        this.changeSelectedItems = {
            update: new Subject()
        };

        this.configTableColumns();
        this.config = Object.assign({}, TABLE_CONFIG);
        this.tooltipOptions = {
            "content-type": "html",
            placement: "bottom",
            offset: 15
        };

        this.scheduledJobs = new Array<SchedulerJobDetailsView>();

        this.asyncUpdater = {
            multipleRows: {
                responseObservable: new Subject()
            },
            searchAll: {
                responseObservable: new Subject()
            },
            singleRow: {
                responseObservable: new Subject()
            }
        };
    }

    ngOnInit() {
        const refreshActionsPlaceholder = {
            retrieveFn: () => { },
            startUpdatingFn: () => { },
            finishedUpdatingFn: () => { }
        };

        this.refreshActions = _.get(this, "refreshActions", refreshActionsPlaceholder);
        this.refreshActions.retrieveFn = () => this.loadSchedulerJobs();
        this.configTableActions();
        this.loadSchedulerJobs();
        this.schedulerJobWebsocketService.connect(this.onMessageReceive.bind(this));
    }

    searchAll(filterText: string): void {
        this.asyncUpdater.searchAll.responseObservable.next(filterText);
    }

    update(jobs: Array<SchedulerJobDetails>): void {
        const jobsDetails = [];

        jobs.forEach((job) => {
            jobsDetails.push(this.schedulerJobDetailToViewConverterService.convert(job));
            this.jobsByJobId[job.schedulerJob.id] = job.schedulerJob;
        });
        this.scheduledJobs = jobsDetails;
        this.asyncUpdater.multipleRows.responseObservable.next(this.scheduledJobs);
    }

    private onMessageReceive(message: SchedulerBroadcastFeedbackMessage) {
        this.jobsByJobId[message.schedulerJob.id] = message.schedulerJob;

        const rowUpdateType = this.selectRowUpdateType(message.notificationType);

        message.lastExecution = message.lastExecution !== null ? new Date(message.lastExecution) : null;
        message.nextExecution = message.nextExecution !== null ? new Date(message.nextExecution) : null;

        const rowToUpdate: NmsTableRowAsync = {
            identifier: "jobId",
            data: this.schedulerJobDetailToViewConverterService.convert(message),
            rowUpdateType,
            keepSelected: this.jobsByJobId[message.schedulerJob.id].status === JobExecutionStatus.REQUESTED
                || this.jobsByJobId[message.schedulerJob.id].status === JobExecutionStatus.EXECUTING
        };

        this.asyncUpdater.singleRow.responseObservable.next(rowToUpdate);
    }

    /**
     * Define o tipo de operação que será realizada na linha da tabela, de acordo com o tipo de notificação.
     */
    private selectRowUpdateType(notificationType: SchedulerNotificationType): RowUpdateType {
        switch (notificationType) {
            case SchedulerNotificationType.SCHEDULER_ADDED:
                return RowUpdateType.upsert;
            case SchedulerNotificationType.SCHEDULER_DELETED:
                return RowUpdateType.remove;
            default:
                return RowUpdateType.update;
        }
    }

    private configTableColumns(): void {
        this.columns = [
            {
                field: "jobName",
                width: "130",
                type: NmsTableColumnType.LINK,
                onAction: (schedulerJobDetailsView: SchedulerJobDetailsView) => {
                    if (this.userHasPermissionOnJob(schedulerJobDetailsView.jobId)) {
                        this.editScheduler(schedulerJobDetailsView.jobId);
                    } else {
                        this.showDialog("scheduler.table.user.without.permission");
                    }
                }
            },
            {
                field: "jobType",
                width: "130"
            },
            {
                field: "triggers",
                width: "130"
            },
            {
                field: "lastExecution",
                sortable: "lastExecutionTimeStamp",
                width: "130"
            },
            {
                field: "nextExecution",
                sortable: "nextExecutionTimeStamp",
                width: "130"
            },
            {
                field: "status",
                width: "130",
                type: NmsTableColumnType.STATUS,
                filter: { translatedStatus: "text" },
                getColumnText: ({ translatedStatus }: SchedulerJobDetailsView) => translatedStatus
            },
            {
                field: "modifiedBy",
                width: "130"
            },
            {
                type: NmsTableColumnType.ACTIONS,
                width: "130",
                editable: false,
                filter: false,
                field: "actions",
                actions: [
                    {
                        id: "enableDisable",
                        type: "toggle",
                        onAction: ({ jobId, enabled }: SchedulerJobDetailsView) => {
                            if (this.userHasPermissionOnJob(jobId)) {
                                const message = enabled ? this.DISABLE_SELECTION : this.ENABLE_SELECTION;
                                this.$rootScope.showDialog({ message, isConfirm: true })
                                    .then(() => this.enableDisableToggleJob(jobId, enabled));
                            }
                        },
                        isDisabled: (schedulerJobDetailsView: SchedulerJobDetailsView) =>
                            !this.userHasPermissionOnJob(schedulerJobDetailsView.jobId),
                        getTooltip: (schedulerJobDetailsView: SchedulerJobDetailsView) =>
                            this.getActionTooltip(schedulerJobDetailsView.jobId, "scheduler.table.enableDisableJob"),
                    },
                    {
                        id: "execute",
                        iconClass: "glyphicon glyphicon-play",
                        onAction: (schedulerJobDetailsView: SchedulerJobDetailsView) => {
                            if (this.userHasPermissionOnJob(schedulerJobDetailsView.jobId) &&
                                this.canExecuteSingleJob([schedulerJobDetailsView])) {
                                this.executeSchedulers([schedulerJobDetailsView]);
                            }
                        },
                        isDisabled: (schedulerJobDetailsView: SchedulerJobDetailsView) => {
                            return (!schedulerJobDetailsView.enabled)
                                || !this.userHasPermissionOnJob(schedulerJobDetailsView.jobId);
                        },
                        getTooltip: (schedulerJobDetailsView: SchedulerJobDetailsView) => {
                            const defaultTooltip = schedulerJobDetailsView.enabled
                                ? "scheduler.table.actions.execute" : "scheduler.table.actions.disableJob";
                            return this.getActionTooltip(schedulerJobDetailsView.jobId, defaultTooltip);
                        }
                    },
                    {
                        id: "edit",
                        iconClass: "glyphicon glyphicon-edit",
                        onAction: (schedulerJobDetailsView: SchedulerJobDetailsView) => {
                            if (this.userHasPermissionOnJob(schedulerJobDetailsView.jobId)) {
                                this.editScheduler(schedulerJobDetailsView.jobId);
                            }
                        },
                        isDisabled: (schedulerJobDetailsView: SchedulerJobDetailsView) =>
                            !this.userHasPermissionOnJob(schedulerJobDetailsView.jobId),
                        getTooltip: (schedulerJobDetailsView: SchedulerJobDetailsView) =>
                            this.getActionTooltip(schedulerJobDetailsView.jobId, "scheduler.table.actions.edit"),
                    },
                    {
                        id: "seeResults",
                        iconClass: "icon-result medium",
                        onAction: (schedulerJobDetailsView: SchedulerJobDetailsView) => {
                            if (this.userHasPermissionOnJob(schedulerJobDetailsView.jobId)) {
                                this.viewSchedulerResults(schedulerJobDetailsView.jobId);
                            }
                        },
                        isDisabled: (schedulerJobDetailsView: SchedulerJobDetailsView) =>
                            !this.userHasPermissionOnJob(schedulerJobDetailsView.jobId),
                        getTooltip: (schedulerJobDetailsView: SchedulerJobDetailsView) =>
                            this.getActionTooltip(schedulerJobDetailsView.jobId, "scheduler.table.actions.seeResults")
                    },
                    {
                        id: "delete",
                        iconClass: "glyphicon glyphicon-trash",
                        onAction: (schedulerJobDetailsView: SchedulerJobDetailsView) => {
                            if (this.userHasPermissionOnJob(schedulerJobDetailsView.jobId)) {
                                this.removeSchedulers([schedulerJobDetailsView]);
                            }
                        },
                        isDisabled: (schedulerJobDetailsView: SchedulerJobDetailsView) =>
                            !this.userHasPermissionOnJob(schedulerJobDetailsView.jobId),
                        getTooltip: (schedulerJobDetailsView: SchedulerJobDetailsView) =>
                            this.getActionTooltip(schedulerJobDetailsView.jobId, "scheduler.table.actions.delete")
                    }
                ]
            }
        ];
    }

    private editScheduler(jobId: string) {
        const scheduledJob = this.jobsByJobId[jobId];
        const jobType = scheduledJob.type;
        this.schedulerResolverService.resolve(jobType).editScheduler(scheduledJob);
    }

    private viewSchedulerResults(jobId: string) {
        const scheduledJob = this.jobsByJobId[jobId];
        const jobType = scheduledJob.type;
        this.schedulerResolverService.resolve(jobType).viewResults(scheduledJob);
    }

    private configTableActions(): void {
        this.tableActions = [
            {
                id: "execute",
                translateKey: "scheduler.table.actions.execute",
                customNoSelectedItemTranslationKey: this.noJobSelectedKey,
                action: (selectedScheduledJobs: Array<SchedulerJobDetailsView>) => {

                    if (this.validateSchedulerPermission(selectedScheduledJobs)) {
                        if (selectedScheduledJobs.length === 1 && this.canExecuteSingleJob(selectedScheduledJobs)) {
                            this.executeSchedulers(selectedScheduledJobs);
                        } else if (selectedScheduledJobs.length !== 1) {
                            this.handleMultipleExecution(selectedScheduledJobs);
                        }
                    }
                }
            },
            {
                id: "edit",
                translateKey: "scheduler.table.actions.edit",
                customNoSelectedItemTranslationKey: this.noJobSelectedKey,
                action: (selectedScheduledJobs: Array<SchedulerJobDetailsView>) => {
                    const errorKey = "scheduler.table.actions.edit.manySelected";
                    if (this.isOnlyOneJobSelected(selectedScheduledJobs, errorKey)) {
                        if (!this.userHasPermissionOnJob(selectedScheduledJobs[0].jobId)) {
                            this.showDialog("scheduler.table.user.without.permission");
                            this.changeSelectedItems.update.next([]);
                        } else {
                            this.editScheduler(selectedScheduledJobs[0].jobId);
                        }
                    }
                }
            },
            {
                id: "seeResults",
                translateKey: "scheduler.table.actions.seeResults",
                customNoSelectedItemTranslationKey: this.noJobSelectedKey,
                action: (selectedScheduledJobs: Array<SchedulerJobDetailsView>) => {
                    const errorKey = "scheduler.table.actions.seeResults.manySelected";
                    if (this.isOnlyOneJobSelected(selectedScheduledJobs, errorKey)) {
                        if (!this.userHasPermissionOnJob(selectedScheduledJobs[0].jobId)) {
                            this.showDialog("scheduler.table.user.without.permission");
                            this.changeSelectedItems.update.next([]);
                        } else {
                            this.viewSchedulerResults(selectedScheduledJobs[0].jobId);
                        }
                    }
                }
            },
            {
                id: "removeScheduler",
                translateKey: "scheduler.table.actions.delete",
                customNoSelectedItemTranslationKey: this.noJobSelectedKey,
                action: (selectedScheduledJobs: Array<SchedulerJobDetailsView>) => {
                    if (this.validateSchedulerPermission(selectedScheduledJobs)) {
                        this.removeSchedulers(selectedScheduledJobs);
                    }
                }
            },
            {
                id: "enableJob",
                translateKey: "scheduler.table.actions.enable",
                customNoSelectedItemTranslationKey: this.noJobSelectedKey,
                action: (selectedScheduledJobs: Array<SchedulerJobDetailsView>) => {
                    if (this.validateSchedulerPermission(selectedScheduledJobs)) {
                        const errorKey = selectedScheduledJobs.length == 1
                            ? "scheduler.table.actions.enable.oneSelected"
                            : "scheduler.table.actions.enable.manySelected";
                        this.confirmChangeJobState(selectedScheduledJobs, errorKey, false);
                    }
                }
            },
            {
                id: "disableJob",
                translateKey: "scheduler.table.actions.disable",
                customNoSelectedItemTranslationKey: this.noJobSelectedKey,
                action: (selectedScheduledJobs: Array<SchedulerJobDetailsView>) => {
                    if (this.validateSchedulerPermission(selectedScheduledJobs)) {
                        const errorKey = selectedScheduledJobs.length == 1
                            ? "scheduler.table.actions.disable.oneSelected"
                            : "scheduler.table.actions.disable.manySelected";
                        this.confirmChangeJobState(selectedScheduledJobs, errorKey, true);
                    }
                }
            }
        ];
    }

    private validateSchedulerPermission(selectedScheduledJobs: Array<SchedulerJobDetailsView>): boolean {
        const allowedSchedulerJobs = this.getAllowedSchedulerJobs(selectedScheduledJobs);
        const allowedJobIds = allowedSchedulerJobs.map(schedulerJob => schedulerJob.id);
        const hasNotAllowedSchedulerJob = selectedScheduledJobs.some(({jobId}) => !allowedJobIds.includes(jobId));

        if (hasNotAllowedSchedulerJob) {
            const key = this.hasSameJobType(selectedScheduledJobs)
                ? "scheduler.table.user.without.permission"
                : "scheduler.table.user.without.permission.tasks"

            this.showDialog(key);
            const allowedViewDetails = this.filterSchedulerJobDetailsView(selectedScheduledJobs, allowedSchedulerJobs);
            this.changeSelectedItems.update.next(allowedViewDetails);

            return false;
        }

        return true;
    }

    private hasSameJobType(selectedSchedulerJobs: Array<SchedulerJobDetailsView>): boolean {
        const firstJobType = selectedSchedulerJobs[0].jobType;

        return selectedSchedulerJobs.every(schedulerJob => schedulerJob.jobType === firstJobType);
    }

    private executeSchedulers(selectedScheduledJobs: Array<SchedulerJobDetailsView>) {
        const scheduledJobIds: Array<string> = selectedScheduledJobs.map((scheduler) => scheduler.jobId);
        const message = selectedScheduledJobs.length === 1 ? this.EXECUTE_SELECTION : this.EXECUTE_ALL_SELECTION;
        this.$rootScope.showDialog({ message, isConfirm: true }).then(() => {
            this.schedulerListService.executeJobsOf(scheduledJobIds).subscribe();
        })
    }

    private removeSchedulers(selectedScheduledJobs: Array<SchedulerJobDetailsView>) {
        const selectedSize = selectedScheduledJobs.length === 1 ? "oneSelected" : "multipleSelected";
        const message = this.$translate.instant(`scheduler.table.actions.schedule.remove.${selectedSize}`);
        const scheduledJobIds: Array<string> = selectedScheduledJobs.map((scheduler) => scheduler.jobId);
        this.$rootScope.showDialog({ message, isConfirm: true }).then(() => {
            this.schedulerListService.removeSchedulers(scheduledJobIds).subscribe(
                () => this.successRemoveSchedulers(scheduledJobIds),
                ({ error }) => this.failRemoveSchedulers(error.details, selectedScheduledJobs)
            );
        });
    }

    private enableDisableToggleJob(jobId: string, enableJob: boolean) {
        if (enableJob) {
            this.schedulerListService.disableJob(jobId).subscribe();
        } else {
            this.schedulerListService.enableJob(jobId).subscribe();
        }
    }

    private successRemoveSchedulers(scheduledJobIds: Array<string>) {
        this.loadSchedulerJobs();
    }

    private failRemoveSchedulers(ids: Array<string>, selectedScheduledJobs: Array<SchedulerJobDetailsView>) {
        const nameSchedulerJobs: Array<string> = selectedScheduledJobs
            .filter((scheduler) => ids.includes(scheduler.jobId))
            .map((scheduler) => scheduler.jobName);

        this.$rootScope.showDialog({
            translateKey: "scheduler.table.actions.error.remove.message",
            params: nameSchedulerJobs,
            maxChars: 128
        });
    }

    private isOnlyOneJobSelected(selectedScheduledJobs: Array<SchedulerJobDetailsView>, errorKey: string): boolean {
        if (selectedScheduledJobs.length > 1) {
            this.showDialog(errorKey);
            return false;
        }

        return true;
    }

    private isAnySelectedJobDisabled(selectedScheduledJobs: Array<SchedulerJobDetailsView>): boolean {
        const enableJobs = selectedScheduledJobs.filter((job)=> job.enabled)
        const errorDisable = selectedScheduledJobs.length === 1 ? "scheduler.table.actions.disable.execute.now"
            : "scheduler.table.actions.disable.task.select"
        if (selectedScheduledJobs.length > enableJobs.length ) {
            this.changeSelectedItems.update.next(enableJobs);
            this.showDialog(errorDisable);
            return true;
        }
        return false;
    }

    private confirmChangeJobState(selectedScheduledJobs: Array<SchedulerJobDetailsView>, errorKey: string, enabled: boolean) {
        this.$rootScope.showDialog({
            translateKey: errorKey,
            isConfirm: true,
        }).then(() => {
            selectedScheduledJobs.forEach(({ jobId }) => this.enableDisableToggleJob(jobId, enabled));
        });
    };

    private canExecuteSingleJob(selectedScheduledJobs: Array<SchedulerJobDetailsView>){
        return !this.isAnySelectedJobDisabled(selectedScheduledJobs) &&
            (!this.verifyIfAllJobsAreRunning(selectedScheduledJobs));
    };

    private verifyIfAllJobsAreRunning(selectedScheduledJobs: Array<SchedulerJobDetailsView>) {
        const isAllJobsRunning = selectedScheduledJobs.every((job)=> this.isJobRunning(job));
        if (isAllJobsRunning) {
            const message = selectedScheduledJobs.length === 1 ? "scheduler.table.actions.selected.executing"
                : "scheduler.table.actions.select.task.executing"
            this.showDialog(message);
            return true;
        }
        return false;
    };

    private readonly applyGlobalFilterUserPreferences = () => {
        const { globalFilter } = this.userPreferenceService.loadPreferences(
            {},
            SchedulerJobsListOptions.FILTER_KEY,
            SchedulerJobsListOptions.PROPERTIES_KEYS
        );
        this.searchAll(globalFilter);
        this.searchAllFilterText = globalFilter;
    };

    private readonly updateSchedulerGlobalFilter = () => {
        this.userPreferenceService.savePreferences(
            new SchedulerJobsListOptions(this.searchAllFilterText),
            SchedulerJobsListOptions.FILTER_KEY,
            SchedulerJobsListOptions.PROPERTIES_KEYS
        );
    };

    private successGetSchedulerJobs(response: Array<SchedulerJobDetails>): void {
        this.refreshActions.finishedUpdatingFn();
        this.update(response);
        this.tableBodyMessage = null;
        this.applyGlobalFilterUserPreferences();
    }

    private errorGetSchedulerJobs(): void {
        this.refreshActions.finishedUpdatingFn();
        this.showDialog("http.error.serverError");
        this.tableBodyMessage = null;
    }

    private showDialog(key: string): void {
        this.$rootScope.showDialog({ translateKey: key });
    }

    private handleMultipleExecution(selectedScheduledJobs: Array<SchedulerJobDetailsView>) {
        if (!this.isAnySelectedJobDisabled(selectedScheduledJobs)) {
            const jobsRunning = selectedScheduledJobs.filter((job) => this.isJobRunning(job));
            if (jobsRunning.length > 0 && (jobsRunning.length !== selectedScheduledJobs.length)) {
                this.$rootScope.showDialog({
                    translateKey: "scheduler.table.actions.select.task.not.execute",
                    isConfirm: true,
                }).then(() => {
                    const jobsToExecute = selectedScheduledJobs.filter(( job ) =>
                        !this.isJobRunning(job)).map((job) => job.jobId);
                    this.schedulerListService.executeJobsOf(jobsToExecute).subscribe();
                    this.selectedScheduledJobs = selectedScheduledJobs;
                });
            } else if (!this.verifyIfAllJobsAreRunning(selectedScheduledJobs)) {
                this.executeSchedulers(selectedScheduledJobs);
            }
        }
    }

    private isJobRunning(job: SchedulerJobDetailsView): boolean{
        return job.status === JobExecutionStatus.EXECUTING || job.status === JobExecutionStatus.REQUESTED
    }

    private loadSchedulerJobs(): void {
        this.refreshActions.startUpdatingFn();
        this.tableBodyMessage = "scheduler.table.retrieving.message";

        this.schedulerProvider().subscribe(
            (response: Array<SchedulerJobDetails>) => this.successGetSchedulerJobs(response),
            (error) => this.errorGetSchedulerJobs()
        );
    }

    private userHasPermissionOnJob(jobId: string): boolean {
        const scheduledJob = this.jobsByJobId[jobId];
        return this.schedulerPermissionsService.hasPermissionToScheduledJob(scheduledJob.type);
    }

    private getActionTooltip(jobId: string, defaultTooltip: string): string {
        return this.userHasPermissionOnJob(jobId) ? defaultTooltip : "scheduler.table.user.without.permission";
    }

    private getAllowedSchedulerJobs(schedulerJobsDetailsView: Array<SchedulerJobDetailsView>): Array<SchedulerJob> {
        const schedulerJobs = schedulerJobsDetailsView.map(({jobId}) => this.jobsByJobId[jobId]);
        return this.schedulerPermissionsService.getAllowedUserSchedulerJobs(schedulerJobs);
    }

    private filterSchedulerJobDetailsView(viewSchedulerJobsDetailsView: Array<SchedulerJobDetailsView>,
        schedulerJobs: Array<SchedulerJob>): Array<SchedulerJobDetailsView> {

        return viewSchedulerJobsDetailsView.filter((viewSchedulerJob) => {
            return schedulerJobs.some(job => job.id === viewSchedulerJob.jobId);
        });
    }

    ngOnDestroy(): void {
        this.updateSchedulerGlobalFilter();
        this.schedulerJobWebsocketService.disconnect();
    }
}
