"use strict";

/**
* @ngdoc controller
* @name nms.templates.components.variables:VariablesFormDirectiveCtrl
* @description
* # Controller para o componente de formulário de variáveis.
*/
angular.module("nms.templates")
.controller("VariablesFormDirectiveCtrl", ["$scope", "$rootScope", "VariableService", "$translate",
    function($scope, $rootScope, VariableService, $translate) {
        var owner = $scope.formOwner;
        $scope.isEditDisabled = owner.isEditDisabledFn;
        $scope.variables = owner.getVariablesFn();

        owner.getVariablesValues = function() {
            var errors = $scope.variablesForm.$error;
            var emptyMandatoryVariables = [];

            for (var errorType in errors) {
                var fieldNames = [];
                errors[errorType].forEach(function(error) {
                    fieldNames.push(error.$name);
                });

                if (fieldNames.length > 0) {
                    var message = $translate.instant(
                    errorType === "required" ?
                        "templateinstanceform.error.mandatoryVariables" :
                        "templateinstanceform.error.variablesInvalids") + " " + fieldNames.join(", ") + ".";

                    throw message;
                }
            }

            var values = [];
            $scope.variables.forEach(function(variable) {
                var validValues = angular.copy(variable.value);
                _.remove(validValues, function(v) {
                    return v == null;
                });

                if (validValues.length > 0) {
                    values.push({name: variable.name, type: variable.type, values: validValues});
                }
            });

            return values;
        };

        $scope.defineVariableType = function(variable) {
            return VariableService.defineVariableType(variable.type);
        };

        $scope.initVariableValue = function(variable) {
            if (!variable.value) {
                variable.value = [variable.defaultValue];
            } else if (variable.value.length === 1 && variable.value[0] === "") {
                variable.value.splice(0, 1, variable.defaultValue);
            }
        };

        $scope.buildRegexTooltip = function(variable) {
            return VariableService.buildRegexTooltip(variable);
        };

        $scope.getVariableRestrictions = function(variable) {
            return VariableService.defineRestrictions(variable);
        };

        $scope.hasValue = function(field) {
            return field !== "";
        };

        $scope.isListType = function(variable) {
            switch (variable.type) {
                case "TEXT_LIST":
                case "INTEGER_LIST":
                case "SELECTION_LIST":
                    return true;
                default:
                    return false;
            }
        };

        $scope.hasInclusionCommands = function (variable) {
            return variable.includeAndBlockOptionMode === "INCLUDE" || variable.includeAndBlockOptionMode === "INCLUDE_AND_BLOCK";
        };

        $scope.addValue = function(variable, $event, index) {
            $event.preventDefault();
            variable.value.splice(index+1, 0, variable.defaultValue);
        };

        $scope.removeValue = function(variable, $event, index) {
            $event.preventDefault();
            if (variable.mandatory === true && variable.value.length === 1) {
                $rootScope.showDialog({
                    translateKey: "templateinstanceform.templatedevices.mandatoryVariable"
                });
            } else {
                variable.value.splice(index, 1);
            }
        };

        $scope.getVarMinValue = function(variable) {
            var min = variable.minValue;

            if (variable.minValue !== null && angular.isDefined(variable.minValue)) {
                if (variable.step) {
                    while (min % variable.step !== 0) {
                        min++;
                    }
                } else if (variable.baseExponent) {
                    min = min < 1 ? 1 : min;

                    var logResult = Math.log(min)/ Math.log(variable.baseExponent);
                    while (logResult !== Math.floor(logResult)) {
                        min++;
                        logResult = Math.log(min)/ Math.log(variable.baseExponent);
                    }
                }
            } else if (variable.baseExponent) {
                min = 1;
            }

            return min;
        };

        $scope.getVarMaxValue = function(variable) {
            var max = variable.maxValue;

            if (variable.maxValue !== null && angular.isDefined(variable.maxValue)) {
                if (variable.step) {
                    while (max % variable.step !== 0) {
                        max--;
                    }
                } else if (variable.baseExponent) {
                    max = max < 1 ? 1 : max;

                    var logResult = Math.log(max)/ Math.log(variable.baseExponent);
                    while (max > 1 && logResult !== Math.floor(logResult)) {
                        max--;
                        logResult = Math.log(max)/ Math.log(variable.baseExponent);
                    }
                }
            }

            return max;
        };

        $scope.revertValue = function(variable, index) {
            variable.value.splice(index, 1, variable.defaultValue);
        };
    }]);
