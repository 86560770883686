import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnDestroy } from "@angular/core";
import { Toast, ToastrService, ToastPackage } from "ngx-toastr";
import { NmsToasterLink } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr-model";
import {
  NotificationPreferencesService
} from "@nms-ng2/app/shared/services/notification-preferences/notification-preferences.service";

/**
 * Componente de toaster customizado para poder adicionar links com eventos de onClick
 */
@Component({
  selector: "[nms-toastr-component]",
  styleUrls: ["./nms-toastr.component.scss"],
  templateUrl: "./nms-toastr.component.html",
  /* É obrigatório adicionar a linha abaixo, o componente ngx-toaster não tem a opção
   * de sobrescrevê-lo sendo necessário adicioná-lo.
   * Essa é a animação do componente padrão ngx-toastr
   */
  animations: [
    trigger("flyInOut", [
      state("inactive", style({ opacity: 0 })),
      state("active", style({ opacity: 1 })),
      state("removed", style({ opacity: 0 })),
      transition(
        "inactive => active",
        animate("{{ easeTime }}ms {{ easing }}")
      ),
      transition("active => removed", animate("{{ easeTime }}ms {{ easing }}")),
    ]),
  ],
  preserveWhitespaces: false,
})
export class NmsToastrComponent extends Toast implements OnDestroy {
  disableNotificationsOfType: boolean;
  checkboxDisableId: string;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private notificationPreferencesService: NotificationPreferencesService
  ) {
    super(toastrService, toastPackage);
    this.disableNotificationsOfType = false;
    this.checkboxDisableId = `disable-notification-${Date.now()}`;
  }

  /**
   * Evento na qual iremos sobrescrevê-lo ao clicar no link do toast.
   */
  action(link: NmsToasterLink) {
    event.stopPropagation();
    this.toastPackage.triggerAction(link);
    this.toastrService.clear(this.toastPackage.toastId);
    return false;
  }

  /**
   * Limita a área de fechamento do toastr.
   * O componente do checkbox fica fora do limite da área de clique do fechamento
   * do toastr, dessa forma, ao clicar no checkbox o toastr não será fechado automáticamente.
   *
   * Para que esse comportamento seja possível é necessário que as opções tapToDismiss=false
   * e closeButton=true.
   */
  close(): void {
    const { tapToDismiss, closeButton } = this.options;

    if (!tapToDismiss && closeButton) {
      this.remove();
    }
  }

  /**
   * Verifica se o usuário marcou a opção de desabilitar uma notificationType específica
   * e chama o serviço caso ele tenha marcado a checkbox.
   */
  private verifyAndDisableNotificationOfType() {
    //@ts-ignore
    const { notificationType } = this.options;

    if (this.disableNotificationsOfType && notificationType) {
      this.notificationPreferencesService
        .disableNotificationOfType(notificationType)
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.verifyAndDisableNotificationOfType();
  }
}
