"use strict";

/*
* @ngDoc          Service
* @name           nms.management.ManageGroupModalService
* @description    Service para a abertura da Modal para gerenciamento de grupos.
* @requires       [ngDialog, ManageGroupsService]
*/
var app = angular.module("nms.management");
app.service("ManageGroupModalService", ["ngDialog", "$rootScope", "ManageGroupsService", "RESTRICTION_RULE_MATCHING_TYPES",
    "RESTRICTION_RULE_MATCHING_MODES",
    function(ngDialog, $rootScope, ManageGroupsService, RESTRICTION_RULE_MATCHING_TYPES, RESTRICTION_RULE_MATCHING_MODES) {
        const service: any = {};

        /**
        *   Abre a dialog de confirmação antes da remoção de grupos, quando os grupos a serem removidos não têm nenhum usuário
        *   atrelado.
        **/
        var confirmAndRemoveGroupsWithoutUsers = function(groupsForRemove, callback) {
            var translateKey = groupsForRemove.length > 1
                ? "groupList.tableActions.remove.groups.confirm"
                : "groupList.tableActions.remove.group.confirm";

            $rootScope.showDialog({
                translateKey: translateKey,
                paramsInsideMessage: true,
                params: [groupsForRemove.length],
                isConfirm: true
            }).then(function() {
                ManageGroupsService.remove(groupsForRemove).then(callback);
            });
        };

        /**
        *   Abre a modal de confirmação de remoção de usuário, apresentando também as possíveis opções para casos em que o grupo
        *   já tenha usuário atrelado. Possibilitando que esses usuários possam passar a fazer parte de outro grupo, ou que as
        *   permissões anteriormente contidas nos grupos, sejam mantidas, porém transformando-as em permissões individuais.
        */
        var confirmAndRemoveGroupsWithUsers = function(allGroups, groupsForRemove, callback) {
            ngDialog.openConfirm({
                template: "templates/features/management/groups/modal/remove-manage-group-modal.html",
                controller: "RemoveGroupModalController",
                className: "medium-modal-remove remove-group-dialog",
                resolve: {
                    groups: function() {
                        return allGroups;
                    },
                    groupsForRemove: function() {
                        return groupsForRemove;
                    }
                }
            }).then(function(newGroupName) {
                ManageGroupsService.remove(groupsForRemove, newGroupName).then(callback);
            });
        };

        var openModal = function(resolve, callback) {
            ngDialog.openConfirm({
                template: "templates/features/management/groups/modal/manage-group-modal.html",
                controller: "ManageGroupModalController",
                className: "big-modal",
                resolve: resolve
            }).then(callback);
        };

        service.openAddGroupModal = function(groupNamesInUse, callback) {
            var resolve: any = {
                groupNamesAlreadyInUse: function() {
                    return groupNamesInUse;
                },
                groupData: function() {
                    return null;
                },
                rulesMatchingModes: function() {
                    return RESTRICTION_RULE_MATCHING_MODES;
                },
                ruleRestrictionTypes: function() {
                    return RESTRICTION_RULE_MATCHING_TYPES;
                }
            };

            openModal(resolve, callback);
        };

        service.openEditGroupModal = function(groupNamesInUse, group, callback) {
            var resolve: any = {
                groupNamesAlreadyInUse: function() {
                    return groupNamesInUse;
                },
                groupData: function() {
                    return ManageGroupsService.getGroup(group);
                },
                rulesMatchingModes: function() {
                    return RESTRICTION_RULE_MATCHING_MODES;
                },
                ruleRestrictionTypes: function() {
                    return RESTRICTION_RULE_MATCHING_TYPES;
                }
            };

            openModal(resolve, callback);
        };

        service.openRemoveGroupModal = function(allGroups, groupsForRemove, callback) {
            var groupsInUse = _.filter(groupsForRemove, function(group) {
                return group.users.length > 0;
            });

            if (_.isEmpty(groupsInUse)) {
                confirmAndRemoveGroupsWithoutUsers(groupsForRemove, callback);
            } else {
                confirmAndRemoveGroupsWithUsers(allGroups, groupsForRemove, callback);
            }
        };

        return service;
    }
]);
