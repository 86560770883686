"use strict";

/**
* @ngdoc directive
* @name nms.directive:devicePageTitle
* @description Diretiva que cria o identificador nas páginas de device.
*
*  Recebe dois eventos diferentes que podem ser utilizados para atualizar as informações do header.
*
*    'updateDeviceHeaderByManagedDevice':
*    Recebe um objeto do tipo ManagedDevice e o utiliza para atualizar o header
*
*    'updateDeviceHeaderByDeviceId':
*    Recebe o resourceId do equipamento e dispara uma requisição para obter os dados atualizados
*    e então atualiza o header.
*
*  Envia o broadcast 'updateDeviceHeaderFinished' que indica que o header foi atualizado.
*
*/
angular.module("nms")
.directive("devicePageTitle", ["$rootScope", "DeviceStatusService", "DEVICE_PROPERTIES",
    function($rootScope, DeviceStatusService, DEVICE_PROPERTIES) {
        return {
            restrict: "E",
            transclude: true,
            templateUrl: "templates/components/ui/page-title/device-page-title.html",
            link: function(scope, elem, attr, ctrl, $transclude) {
                $transclude(function(clone) {
                    if (clone.length) {
                        scope.hasExternalContent = true;
                    }
                });

                // TODO: Futuramente alterar para ser usado modelCode ao invés de modelName.
                scope.isMemorySupportedByDeviceModel = function(device) {
                    return (!(_.startsWith(device.modelName, "DM23")) && !(_.startsWith(device.modelName, "FOX605")));
                };

                var updateHeader = function(managedDevice) {
                    scope.device.name = managedDevice.identifiedDevice.name.name;
                    scope.device.modelName = managedDevice.identifiedDevice.deviceCode.modelName;
                    scope.device.status = managedDevice.status.deviceState;
                    scope.device.monitoringStatus = managedDevice.monitoringStatus;
                    scope.device.cpuUsage = managedDevice.deviceProperties.properties[DEVICE_PROPERTIES.DEVICE_CPU_USAGE];
                    scope.device.memoryUsage = managedDevice.deviceProperties.properties[DEVICE_PROPERTIES.DEVICE_MEMORY_USAGE];
                    scope.device.cpuLink = managedDevice.deviceProperties.properties[DEVICE_PROPERTIES.DEVICE_CPU_LINK];
                    scope.device.memoryLink = managedDevice.deviceProperties.properties[DEVICE_PROPERTIES.DEVICE_MEMORY_LINK];
                    $rootScope.$broadcast("updateDeviceHeaderFinished");
                };

                var unregisterByManagedDevice = $rootScope.$on("updateDeviceHeaderByManagedDevice",
                    function(event, managedDevice) {
                        updateHeader(managedDevice);
                    }
                );

                var unregisterByDeviceId = $rootScope.$on("updateDeviceHeaderByDeviceId", function(event, deviceId) {
                    DeviceStatusService.getStatus(deviceId).then(function(managedDevice) {
                        updateHeader(managedDevice);
                    });
                });

                scope.$on("$destroy", function() {
                    unregisterByDeviceId();
                    unregisterByManagedDevice();
                });
            }
        };
    }
]);
