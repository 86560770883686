import { Injectable } from "@angular/core";
import { MatchingRules, RuleCriteria, RuleType } from "./matching-rules.models";

/**
 * Serviço utilitário para componente matching-rules.
 */
@Injectable({
    providedIn: "root"
})
export class MatchingRulesUtilsService {

    constructor() { }

    initMatchingRules(): MatchingRules {
        return {
            criteria: RuleCriteria.ALL,
            rules: [{
                ruleType: RuleType.CONTAINS,
                values: [""]
            }]
        };
    }
}
