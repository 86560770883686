"use strict";

/**
* @ngdoc directive
* @name nms.directive:ngEnter
* @description Diretiva para acionar função ao pressionar ENTER em um input. Caso a execução dependa de algum ng-disabled
* adicionar no input o atributo block-ng-enter. Se o atributo não existir ou for false a função NgEnter será executada, caso
* contrário não.
*/
angular.module("nms")
.directive("ngEnter", function() {
    return function(scope, element, attrs) {
        var ENTER_KEY = 13;
        element.on("keypress", function(event) {
            var blockFunction = (attrs.blockNgEnter === "true");
            if (event.which === ENTER_KEY && !blockFunction) {
                scope.$apply(function() {
                    scope.$eval(attrs.ngEnter);
                });
                event.preventDefault();
            }
        });

        scope.$on("$destroy", function() {
            element.off();
        });
    };
});
