"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.ManageUsersService
* @description - Service para persistência e leitura de informações de usuários.
* Factory in the nms.management.
*/
var app = angular.module("nms.management");

app.service("ManageUsersService", ["ManageUsersRestService", "$translate", "ManageUsersModelConverter",
    "EditUserRequestConverter",
    function(ManageUsersRestService, $translate, ManageUsersModelConverter, EditUserRequestConverter) {
        const service: any = {};

        var setUserGroup = function(user) {
            user.groupName = user.groupName || $translate.instant("management.users.permissions.individual");

            return user;
        };

        service.getAll = function() {
            return ManageUsersRestService.getAll().then(function(response) {
                return _.map(response.plain(), setUserGroup);
            });
        };

        service.createUser = function(formModel) {
            var userModel = ManageUsersModelConverter.convert(formModel);

            return ManageUsersRestService.create(userModel).then(function(response) {
                return setUserGroup(response.plain().user);
            });
        };

        service.getUser = function(username) {
            return ManageUsersRestService.getUser(username).then(function(response) {
                return response.plain();
            });
        };

        service.editUser = function(formModel) {
            var userModel = ManageUsersModelConverter.convert(formModel);

            return ManageUsersRestService.edit(userModel).then(function(response) {
                return setUserGroup(response.plain().user);
            });
        };

        service.removeUsers = function(usernames, callback) {
            return ManageUsersRestService.remove(usernames).then(function() {
                callback(usernames);
            });
        };

        service.editBasic = function(data) {
            var editUserRequest = EditUserRequestConverter.convert(data);
            return ManageUsersRestService.editBasic(editUserRequest);
        };

        return service;
    }
]);
