"use strict";

var app = angular.module("LicenseModule");

app.factory("LicenseRESTService", ["Restangular",
    function(Restangular) {
        var basePath = "/";
        const service: any = {};

        service.requestLicense = function() {
            return Restangular.all(basePath + "license").customGET();
        };

        return service;
    }
]);
