import { Subscription } from "rxjs";
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from "rxjs/webSocket";

/**
 * Serviço base para implementações de WebSocket.
 * @param T tipo de mensagem retornada pelo WebSocket.
 */
export abstract class WebsocketService<T> {
    private readonly WEB_SOCKET_TIMEOUT_CODE = 1001;

    private readonly url: string;
    private readonly config: WebSocketSubjectConfig<T>;
    private onMessageReceiveFn: (message: T) => void;
    private websocketSubject: WebSocketSubject<T>;
    private subscription: Subscription;

    constructor(private readonly window: Window, private readonly webSocketUrl: string) {
        const urlSuffix = webSocketUrl.startsWith("/") ? webSocketUrl.substring(1) : webSocketUrl;
        this.url = `wss://${this.window.location.host}/${urlSuffix}`;

        this.config = {
            url: this.url,
            closeObserver: { next: this.onDisconnect.bind(this) }
        };
    }

    /**
     * Se connecta ao WebSocket.
     * @param onMessageReceiveFn callback que será chamado no onMessage
     */
    connect(onMessageReceiveFn: (message: T) => void): void {
        this.onMessageReceiveFn = onMessageReceiveFn;
        this.subscription = this.getSubject().subscribe(this.onMessageReceiveFn);
    }

    /**
     * Desconecta o WebSocket.
     */
    disconnect() {
        this.subscription.unsubscribe();
    }

    getSubject() {
        if (!this.websocketSubject) {
            this.websocketSubject = webSocket(this.config);
        }

        return this.websocketSubject;
    }

    /*
    * Verifica se o motivo do encerramento do websocket se refere a timeout,
    * neste caso, se registra novamente para seguir ouvindo as mensagens.
    */
    private onDisconnect({ code }: CloseEvent): void {
        if (code === this.WEB_SOCKET_TIMEOUT_CODE) {
            this.connect(this.onMessageReceiveFn);
        }
    }
}