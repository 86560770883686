import { NmsWizardStep } from "@nms-angular-toolkit/nms-wizard";
import { JobType } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { Trigger } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";

/**
 * Representa os disparadores do Agendamento de Backup de equipamentos.
 */
export class BackupDevicesScheduler extends NmsWizardStep {
    id?: string;
    jobType: JobType;
    triggers: Array<Trigger>;
}