"use strict";

/**
* @ngdoc controller
* @name nms.management.ManageUsersController
* @description Gerencia os usuários da aplicação.
*/
var app = angular.module("nms.management");

app.controller("ManageUsersController", ["$scope", "$rootScope", "$translate", "NgTableParams", "TABLE_PROPERTIES",
    "TableFilterService", "UserPreferencesService", "ManageUsersService", "ManageUsersActionsService",
    "ManageGroupsActionsService", "ManageGroupsService",
    function($scope, $rootScope, $translate, NgTableParams, TABLE_PROPERTIES, TableFilterService, UserPreferencesService,
        ManageUsersService, ManageUsersActionsService, ManageGroupsActionsService, ManageGroupsService) {
        var availableFilterPropertiesKeys = ["globalFilter"];

        $scope.individualPermissionsLabel = $translate.instant("management.users.permissions.individual");
        $scope.searchTooltip = $translate.instant("tooltips.search.searchToolTip");
        $scope.globalFilter
            = UserPreferencesService.loadPreferences({}, "ManageUserGlobalFilter", availableFilterPropertiesKeys).globalFilter;

        $scope.columns = [
            {field: "username"},
            {field: "administrator", filter: null, width: "125"},
            {field: "groupName"},
            {field: "name"},
            {field: "phone"},
            {field: "email"}
        ];

        $scope.tableParams = new NgTableParams(
            {
                page: 1,
                count: TABLE_PROPERTIES.DEFAULT_PAGE_SIZE,
                sorting: {username: "asc"}
            },
            {
                dataset: [],
                filterOptions: {filterFn: TableFilterService.getFilter($scope.columns)}
            }
        );

        $scope.selection = {
            id: "users-list",
            checked: [],
            table: $scope.tableParams
        };

        $scope.tableModel = {
            options: {
                translatePrefix: "management.users.tableColumn"
            },
            columns: $scope.columns,
            selection: $scope.selection,
            tableParams: $scope.tableParams
        };

        $scope.applyGlobalFilter = function() {
            angular.extend($scope.tableParams.filter(), {$: $scope.globalFilter});
        };

        $scope.openAddUserModal = function() {
            var callback = function(user) {
                $scope.tableParams.settings().dataset.push(user);
                $scope.tableParams.reload();
            };

            ManageUsersActionsService.openAddUserModal($scope.tableParams.data, callback);
        };

        /*
        * Abre a modal para edição de usuário.
        * O Parâmetro username é opcional. Ele é usado quando o método é chamado a partir de um click no nome de usuário da
        * tabela. Quando disparado através do link remover na parte superior da tabela, a lógica levará em conta os elementos
        * contidos em $scope.selection.checked.
        */
        $scope.openEditUserModal = function(username) {
            var callback = function(user) {
                var editedUserIndex = _.findIndex($scope.tableParams.settings().dataset, function(userInTable) {
                    return userInTable.username === user.username;
                });

                if (editedUserIndex !== -1) {
                    $scope.tableParams.settings().dataset[editedUserIndex] = user;
                    $scope.tableParams.reload();
                }
            };

            if (username) {
                ManageUsersActionsService.openEditUserModal(username, callback);
            } else {
                ManageUsersActionsService.verifyAndOpenEditUserModal($scope.selection.checked, callback);
            }
        };

        $scope.tryRemoveUsers = function() {
            var callback = function(usernames) {
                $rootScope.toastInfo("userList.tableActions.remove.users.success");
                _.remove($scope.tableParams.settings().dataset, function(userInTable) {
                    return _.contains(usernames, userInTable.username);
                });
                $scope.tableParams.reload();
            };

            ManageUsersActionsService.tryRemoveUsers($scope.selection.checked, callback);
        };

        $scope.openEditGroupModal = function(groupName) {
            var callback = function(group) {
                init();
            };
            ManageGroupsActionsService.openEditGroupModal($scope.groups, groupName, callback);
        };

        $scope.$on("$destroy", function() {
            var globalFilter: any = {globalFilter: $scope.globalFilter};
            UserPreferencesService.savePreferences(globalFilter, "ManageUserGlobalFilter", availableFilterPropertiesKeys);
        });

        var init = function() {
            ManageUsersService.getAll().then(function(users) {
                $scope.tableParams.settings({dataset: users});
            });
            ManageGroupsService.getGroupNames().then(function(groupNames) {
                $scope.groups = groupNames;
            });
        };

        init();
    }
]);
