"use strict";

/**
 * @ngdoc service
 * @name nms.management.ManageGroupsRestService
 * @description Realiza chamadas REST ao serviço de gerência de grupos.
 */
var app = angular.module("nms.management");

app.factory("ManageGroupsRestService", [
    "Restangular",
    function (Restangular) {
        var baseURL = "/groups/";
        const service: any = {};

        service.getAll = function () {
            return Restangular.all(baseURL).customGET();
        };

        service.getGroupNames = function () {
            return Restangular.all(baseURL + "names").customGET();
        };

        service.create = function (group) {
            return Restangular.one(baseURL).customPOST(group);
        };

        service.getGroup = function (groupName) {
            return Restangular.all(baseURL).customGET("name", { name: groupName });
        };

        service.edit = function (currentGroupName, group) {
            return Restangular.all(baseURL).customPUT(group, "", { name: currentGroupName });
        };

        service.remove = function (groupNames, newGroupName) {
            return Restangular.one(baseURL).customDELETE("", { groupNames: groupNames, newGroupName: newGroupName });
        };

        return service;
    }
]);
