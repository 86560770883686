import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import {
    NmsTableColumn,
    NmsTableConfig,
    AsyncUpdater,
    NmsTableColumnType
} from "@nms-ng1/components/ui/nms-table-old/nms-table-models";
import {
    STATE,
    FILTER,
    ANGULARJS_TRANSLATE,
    CONNECTIVITY_TEST_STATUS,
    ANGULARJS_ROOTSCOPE,
    ANGULARJS_SCOPE,
    USER_PREFERENCES_SERVICE,
    BROADCAST_EVENTS,
    NMS_STATES,
    GPON_ONU_SERVICE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { ModalFactory } from "@nms-ng2/app/shared/services/modal/modal.factory";
import { PageTitleItem } from "@nms-ng2/app/shared/components/elements/nms-page-title/nms-page-title.component";
import { NmsDropdownShortcut } from "@nms-ng2/app/shared/directives/nms-dropdown.upgraded.directive";
import { CwmpParameterPathModel } from "@nms-ng2/app/modules/device/cwmp-parameters/cwmp-path-parameters/cwmp-path-parameter-model";
import { CwmpParametersService, CwmpOperationType } from "./cwmp-parameters.service";
import { CwmpParametersResolverService, Parameters } from "./cwmp-parameters-resolver.service";
import { CpeEntity } from "./cwmp-parameters-model";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { NotifierModel } from "@nms-ng2/app/shared/components/elements/nms-timer/nms-timer.component";
import diffArrays from "app/shared/utils/array-diff/array-diff";
import { NmsInfoConfigFilterModel } from "@nms-ng2/app/modules/device/info-config/nms-info-config-filter/nms-info-config-filter.component";
import { CpeShortcutsActionService } from "../cpes/cpe-shortcuts-action.service";
import {
    CwmpConnectivityTestModalComponent,
    CwmpConnectivityTestModel
} from "@nms-ng2/app/shared/components/modals/cwmp-connectivity-test/cwmp-connectivity-test-modal.component";
import { CpesActionsService } from "@nms-ng2/app/shared/services/actions/cpes-actions.service";
import {
    AcsCredentialsModalComponent
} from "@nms-ng2/app/shared/components/modals/acs-credentials-modal/acs-credentials-modal.component";
import { PermissionsActionsService } from "@nms-ng2/app/shared/services/actions/permissions-actions.service";

const PARAMETERS_TABLE_COLUMNS = [
    { field: "parameter", width: "75%", sorting: { parameter: "asc" } },
    { field: "value", type: NmsTableColumnType.MULTI_TYPE }
];

const PARAMETERS_TABLE_CONFIG: NmsTableConfig = {
    hideCustomizedTableComponents: true,
    initialSort: { parameter: "asc" },
    selectionId: "cwmp-parameters-selector",
    tableId: "cwmp-parameters-table",
    tableTranslatePrefix: "cwmp.parameters.tablecolumn",
    rowId: "parameter"
};

@Component({
    selector: "cwmp-parameters",
    templateUrl: "./cwmp-parameters.component.html",
    styleUrls: ["./cwmp-parameters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CwmpParametersComponent implements OnInit, OnDestroy {
    private readonly RUNNING_TRANSLATED_STATUS: string = this.translate.instant("connectivityTest.protocolstatus.RUNNING");
    private readonly PAGE_IDENTIFIER: string = "parametersPageForSerialNumber=";
    private readonly INFO_CONFIG_FILTER_ID: string = "parametersInfoConfigFilter";

    asyncUpdater: AsyncUpdater;
    columns: Array<NmsTableColumn>;
    config: NmsTableConfig;
    rows: Array<Parameters>;
    originalValues: Array<any>;
    parametersPath: Array<CwmpParameterPathModel>;
    cpeEntity: CpeEntity;
    pageTitleItems: Array<PageTitleItem>;
    shortcuts: Array<NmsDropdownShortcut>;
    dropdownTitle: string;
    unsubscribe: Function;
    nmsTimerStartNotifier: Subject<NotifierModel>;
    nmsTimerStopNotifier: Subject<Date>;
    lastExecution: Object;
    currentProcessStartTime: Date;
    infoConfigFilters: NmsInfoConfigFilterModel;

    constructor(
        @Inject(STATE) private state: any,
        @Inject(FILTER) private filter: any,
        @Inject(ANGULARJS_TRANSLATE) private translate: any,
        @Inject(ModalFactory) private readonly modalFactory: ModalFactory,
        @Inject(CONNECTIVITY_TEST_STATUS) private connectivityStatus: any,
        @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
        @Inject(ANGULARJS_SCOPE) private $scope: any,
        @Inject(USER_PREFERENCES_SERVICE) private userPreferenceService,
        @Inject(NMS_STATES) private nmsStates: any,
        @Inject(BROADCAST_EVENTS) private broadcastEvents: any,
        @Inject(GPON_ONU_SERVICE) private readonly gponOnuService: any,
        private service: CwmpParametersService,
        private toastr: NmsToastrService,
        private changeDetector: ChangeDetectorRef,
        private parameterResolverService: CwmpParametersResolverService,
        private readonly cpeShortcutsActionService: CpeShortcutsActionService,
        private readonly cpesActionsService: CpesActionsService,
        private readonly permissionsActionsService: PermissionsActionsService
    ) {
        this.cpeEntity = this.state.params.cpeEntity;
        this.columns = PARAMETERS_TABLE_COLUMNS;
        this.infoConfigFilters = this.loadInfoConfigFilters();
        this.config = {
            ...PARAMETERS_TABLE_CONFIG,
            tableId: `cpe-${this.state.params.serialNumber} - ${PARAMETERS_TABLE_CONFIG.tableId}`,
            initCustomFilters: this.getWritableFilter(this.infoConfigFilters)
        };

        this.asyncUpdater = {
            multipleRows: {
                responseObservable: new Subject()
            },
            searchCustom: {
                responseObservable: new Subject()
            },
            clearFiltersCustom: {
                responseObservable: new Subject()
            },
            reloadObservable: new Subject()
        };

        this.pageTitleItems = [];
        this.shortcuts = [];
        this.dropdownTitle = "";

        this.nmsTimerStartNotifier = new Subject();
        this.nmsTimerStopNotifier = new Subject();

        this.confirmStateChanged();
    }

    ngOnInit() {
        this.unsubscribe = this.service.subscribe(
            this.cpeEntity.serialNumber,
            this.successParametersCallback,
            this.errorCallback
        );
        this.currentProcessStartTime = this.service.getRequestStartTime(this.cpeEntity.serialNumber);
        this.lastExecution = this.getLatestSuccessRequest(this.cpeEntity.serialNumber);
        this.updatePageTitleItems(
            this.cpeEntity.serialNumber,
            this.cpeEntity.productModel,
            this.cpeEntity.hostname,
            this.cpeEntity.lastInform
        );
        this.rows = this.retrieveLatestParameters();
        this.parametersPath = this.service.getLatestParametersPath(this.cpeEntity.serialNumber);

        this.dropdownTitle = this.translate.instant("gpon.cpes.tableActions.tr069.parameters");
        this.shortcuts = [
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.applyTemplate"),
                action: this.tryApplyTemplate
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.viewTemplateApplication"),
                action: this.tryViewTemplateApplications
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.testConnectivity"),
                action: this.openTestConnectivityModal
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.credentials"),
                action: this.openAcsCredentialsModal
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.download"),
                action: this.openDownloadModal
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.reboot"),
                action: this.rebootCpes
            }
        ];
    }

    getWritableFilter(filters: NmsInfoConfigFilterModel): Object {
        let writableFilterValue = filters.config == filters.info ? undefined : filters.config;

        return { writable: writableFilterValue };
    }

    filterInfoConfigParameters(filters: NmsInfoConfigFilterModel): void {
        this.asyncUpdater.searchCustom.responseObservable.next(this.getWritableFilter(filters));
        this.saveInfoConfigFilters(filters);
    }

    requestData(): void {
        if (this.config.dirty) {
            this.showEditionDiscardConfirmationMessage(() => {
                this.config.dirty = false;
                this.updateParameters();
                this.requestParametersValues();
            });
        } else {
            this.requestParametersValues();
        }
    }

    /* Verifica as seguintes validações:
     * - Se o nms-select estiver vazio exibe a mensagem de toast
     * - Se houver paths duplicados exibe a mensagem de toast
     */
    isValidPaths(): boolean {
        let isValidPaths = true;
        if (this.hasEmptyPath()) {
            isValidPaths = false;
            this.toastr.error(this.translate.instant("cwmp.parameters.paths.select.empty"));
        } else if (this.hasPathDuplicated()) {
            isValidPaths = false;
            this.toastr.error(this.translate.instant("cwmp.parameters.paths.duplicated"));
        }
        return isValidPaths;
    }

    hasEmptyPath(): boolean {
        return this.parametersPath.some((element) => element.path == "" || element.path == null);
    }
    /**
     * Verifica se o array parametersPath possui paths idênticos.
     */
    hasPathDuplicated(): boolean {
        var paths = _.reduce(
            this.parametersPath,
            function (result, element) {
                result.push(element.path);
                return result;
            },
            []
        );
        return new Set(paths).size !== this.parametersPath.length;
    }

    apply(): void {
        if (this.config.dirty) {
            this.setDisableTable(true);
            this.nmsTimerStartNotifier.next({ date: new Date(), translationKey: "cwmp.parametes.nms-timer.apply.running" });
            const { hostname, serialNumber } = this.cpeEntity;

            const rows = this.rows.filter(this.isConfigurableParameter).map(this.extractParameterBasicData);
            const { updated } = diffArrays(this.originalValues, rows, "parameter");
            const parametersValues = updated.map((row) => {
                const { parameter, value, type } = row;
                return {
                    name: parameter,
                    value: String(value),
                    type: type
                };
            });
            this.service.setParameters(hostname, serialNumber, parametersValues);
        } else {
            this.$rootScope.showDialog({
                type: "alert",
                translateKey: "cwmp.parameters.apply.noConfigurationChanged"
            });
        }
    }

    cancel(): void {
        if (this.config.dirty) {
            this.showEditionDiscardConfirmationMessage(() => {
                this.saveExitPageValue(this.cpeEntity.serialNumber, true);
                this.redirectToGponOnusOrPreviousPage();
            });
        } else {
            this.redirectToGponOnusOrPreviousPage();
        }
    }

    /**
     * Caso o usuário abra uma nova aba via browser e acesse diretamente a página de parâmetros e clicar na opção cancelar
     * O sistema irá redirecioná-lo a página de GPON-ONUS(all devices).
     * Caso contrário retorna a página anterior.
     */
    redirectToGponOnusOrPreviousPage(): void {
        const previousStateName = this.state.previous.route.name;

        if (previousStateName === "") {
            const params = { hostname: "", device: null, filters: null };
            this.state.go(this.gponOnuService.getGponOnuUISref(), params, { inherit: false });
        } else {
            this.state.go(previousStateName, this.state.previous.routeParams, { inherit: false });
        }
    }

    hasRunningRequest(): boolean {
        return this.cpeEntity && this.service.hasRunningRequest(this.cpeEntity.serialNumber);
    }

    hasAddObjectRequest(): boolean {
        return this.cpeEntity && this.service.hasAddObjectRequest(this.cpeEntity.serialNumber);
    }

    confirmStateChanged() {
        let context = this;

        this.$scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (!context.loadExitPageValue(context.cpeEntity.serialNumber)) {
                if (context.config.dirty) {
                    event.preventDefault();
                    context.showEditionDiscardConfirmationMessage(() => {
                        context.saveExitPageValue(context.cpeEntity.serialNumber, true);
                        context.state.go(toState, toParams);
                    });
                }
            }
            context.saveExitPageValue(context.cpeEntity.serialNumber, false);
        });
    }

    saveExitPageValue(serialNumber: string, value: boolean): void {
        this.userPreferenceService.saveExitValue(this.PAGE_IDENTIFIER + serialNumber, value.toString());
    }

    loadExitPageValue(serialNumber: string): boolean {
        return this.userPreferenceService.loadExitValue(this.PAGE_IDENTIFIER + serialNumber) === "true";
    }

    private saveInfoConfigFilters(filters: NmsInfoConfigFilterModel): void {
        let filtersToSave = {
            infoConfigFilter: filters
        };

        this.userPreferenceService.savePreferences(filtersToSave, this.INFO_CONFIG_FILTER_ID, ["infoConfigFilter"]);
    }

    private loadInfoConfigFilters(): NmsInfoConfigFilterModel {
        let filtersSaved = this.userPreferenceService.loadPreferences({}, this.INFO_CONFIG_FILTER_ID, ["infoConfigFilter"]);

        return _.get(filtersSaved, "infoConfigFilter", { info: true, config: true });
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    private requestParametersValues() {
        if (this.isValidPaths()) {
            const parametersPath = this.replaceEmptyPath();
            this.nmsTimerStartNotifier.next({ date: new Date() });
            this.service.getParameters(this.cpeEntity.hostname, this.cpeEntity.serialNumber, parametersPath);
            this.setDisableTable(true);
        }
    }

    /** Substitui a mensagem do path <empty>( vazio topo da hierarquia) pelo valor = "" */
    replaceEmptyPath(): any {
        return this.parametersPath.map(({ includeNextLevel, path }) => ({
            path: path == this.translate.instant("cwmp.parametes.pathList.empty") ? "" : path,
            includeNextLevel: includeNextLevel
        }));
    }

    private showEditionDiscardConfirmationMessage(callback: Function) {
        this.$rootScope
            .showDialog({
                message: this.translate.instant("cwmp.parameters.edition.discard.confirmation.message"),
                isConfirm: true
            })
            .then(callback);
    }

    /**
     * Callback de sucesso para operações GET, SET, ADD e DELETE.
     * @param type Tipo de operação.
     * @param requestResponse Resposta de retono da chamado do serviço.
     * @param cwmpResponse Resposta de retorno da adição de objeto.
     */
    private successParametersCallback = (type: CwmpOperationType, cwmpResponse: any) => {
        console.info(`The operation ${type} was executed successfully`);
        this.nmsTimerStopNotifier.next(new Date());
        this.updatePageTitleItems(
            cwmpResponse.serialNumber,
            cwmpResponse.productModel,
            cwmpResponse.hostname,
            cwmpResponse.lastInform
        );
        const parameters = this.updateParameters();
        this.changeDetector.markForCheck();

        if (CwmpOperationType.ADD === type) {
            const pathAdded = `${cwmpResponse.addedPath}${cwmpResponse.instanceNumber}.`;
            this.processFilterAndGoToTableIndex(parameters, pathAdded);
            this.processObjectToApplyFocus(parameters, pathAdded);
        }
    };

    /**
     * Callback de erro para operações GET, SET, ADD e DELETE.
     */
    private errorCallback = (error) => {
        console.error("There was an error in send request to get parameters", error);
        this.nmsTimerStopNotifier.next();
        this.setDisableTable(false);
        this.changeDetector.markForCheck();
    };

    private processFilterAndGoToTableIndex(parameters: Array<Parameters>, path: string) {
        if (!this.infoConfigFilters.info) {
            this.asyncUpdater.clearFiltersCustom.responseObservable.next(this.getWritableFilter(this.infoConfigFilters));
        } else {
            this.asyncUpdater.clearFiltersCustom.responseObservable.next();
        }

        this.$rootScope.$broadcast(
            this.broadcastEvents.TABLE_PAGINATION.CHANGE_PAGE_TO_ROW_POSITION,
            this.getIndexByPathAndInfoFilters(parameters, path) + 1
        );
    }

    private processObjectToApplyFocus(parameters: Array<Parameters>, path: string) {
        const indexPathAdded = this.getIndexByPath(parameters, path);
        parameters[indexPathAdded].highlightRow = "parameter-added";
        this.changeDetector.markForCheck();
        this.$rootScope.$broadcast("updateLocationScroll", `div-${parameters[indexPathAdded].parameter}`);
    }

    private getIndexByPathAndInfoFilters(parameters: Array<Parameters>, path: string) {
        if (!this.infoConfigFilters.info) {
            return parameters
                .filter((parameter) => parameter.writable === true)
                .findIndex((parameter) => parameter.parameter === path);
        }
        return this.getIndexByPath(parameters, path);
    }

    private getIndexByPath = (parameters: Array<Parameters>, path: string) => {
        return parameters.findIndex((parameter) => parameter.parameter === path);
    };

    /**
     * Busca os últimos dados de parâmetros salvos no localStorage e resolve os parâmetros.
     */
    private retrieveLatestParameters = (): Array<Parameters> => {
        let parametersStorage: any = this.service.getLatestParameters(this.cpeEntity.serialNumber);
        let parameters = parametersStorage.map((data) =>
            this.parameterResolverService.resolveParameterValues(data, this.addOrRemoveParameters)
        );
        this.originalValues = parameters.filter(this.isConfigurableParameter).map(this.extractParameterBasicData);

        return parameters;
    };

    private updateParameters = (): Array<Parameters> => {
        this.parametersPath = this.service.getLatestParametersPath(this.cpeEntity.serialNumber);
        const parameters: Array<Parameters> = this.retrieveLatestParameters();
        this.asyncUpdater.multipleRows.responseObservable.next(parameters);

        return parameters;
    };

    private tryApplyTemplate = (cpe: CpeEntity) => {
        this.cpeShortcutsActionService.goToTemplateApplication(new Array(cpe));
    };

    private tryViewTemplateApplications = (cpe: CpeEntity) => {
        this.cpeShortcutsActionService.viewTemplateApplication(new Array(cpe));
    };

    private openTestConnectivityModal = (cpe: CpeEntity) => {
        const { serialNumber, productModel, hostname } = cpe;
        const cwmps = [
            {
                serialNumber,
                equipmentId: productModel,
                cwmpCpeIp: hostname,
                percentage: 0,
                icmpTranslated: this.RUNNING_TRANSLATED_STATUS,
                tr069Translated: this.RUNNING_TRANSLATED_STATUS,
                icmp: {
                    status: this.connectivityStatus.RUNNING
                },
                tr069: {
                    status: this.connectivityStatus.RUNNING
                }
            }
        ];

        this.modalFactory.openAsyncModal<CwmpConnectivityTestModel, null>(CwmpConnectivityTestModalComponent, { cwmps });
    };

    private openAcsCredentialsModal = (cpe: CpeEntity) => {
        if (this.permissionsActionsService.isUserAdministrator()) {
            const cpesSelected = [{ serialNumber: cpe.serialNumber }];
            this.modalFactory.openAsyncModal(AcsCredentialsModalComponent, { cpesSelected });
        }
    };

    private readonly openDownloadModal = (cpe: CpeEntity) => {
        this.cpesActionsService.openDownloadRequestModal(cpe);
    };

    private readonly rebootCpes = (cpe: CpeEntity) => {
        this.cpesActionsService.rebootCpes(cpe);
    };

    private updatePageTitleItems = (serialNumber: string, producModel: string, hostname: string, lastInform: Date) => {
        this.pageTitleItems = [
            { titleKey: "cwmp.parameters.page.title.serial.number", content: serialNumber, id: "cpe-serial-number" },
            { titleKey: "cwmp.parameters.page.title.producModel", content: producModel, id: "cpe-producModel" },
            { titleKey: "cwmp.parameters.page.title.hostname", content: hostname, id: "cpe-ip" },
            {
                titleKey: "cwmp.parameters.page.title.last.inform",
                content: this.filter("dateFormat")(lastInform),
                id: "cpe-last-inform"
            }
        ];
    };

    /**
     * Converte os dados de última execução obtidas no localStorage no formato
     * esperado pelo componente nms-timer.
     */
    private getLatestSuccessRequest = (serialNumber: string) => {
        const { lastRequestSuccessInitialDate, lastRequestSuccessFinalDate } = this.service.getLatestDateRequest(serialNumber);

        return lastRequestSuccessInitialDate && lastRequestSuccessFinalDate
            ? {
                  initialDate: new Date(lastRequestSuccessInitialDate),
                  finalDate: new Date(lastRequestSuccessFinalDate)
              }
            : undefined;
    };

    /**
     * Ação realizada ao clicar no botão Adicionar ou Remover da tabela de parâmetros
     */
    addOrRemoveParameters = (parameters): any => {
        if (parameters) {
            var action = parameters.className;
            var parameter = parameters.parameter;

            if (this.config.dirty) {
                this.showEditionDiscardConfirmationMessage(() => {
                    this.config.dirty = false;
                    this.updateParameters();
                    this.executeAddOrRemoveParameters(action, parameter);
                });
            } else {
                this.executeAddOrRemoveParameters(action, parameter);
            }
        } else {
            throw new Error("Erro ao executar a ação do botão");
        }
    };

    executeAddOrRemoveParameters = (action: string, parameter: string): void => {
        this.setDisableTable(true);
        switch (action) {
            case CwmpOperationType.ADD:
                this.nmsTimerStartNotifier.next({ date: new Date(), translationKey: "cwmp.parametes.nms-timer.add.running" });
                this.service.addObjectPath(this.cpeEntity.hostname, this.cpeEntity.serialNumber, parameter);
                break;
            case CwmpOperationType.DELETE:
                this.nmsTimerStartNotifier.next({ date: new Date(), translationKey: "cwmp.parametes.nms-timer.remove.running" });
                this.service.removeObjectPath(this.cpeEntity.hostname, this.cpeEntity.serialNumber, parameter);
                break;
        }
        this.changeDetector.markForCheck();
    };

    private setDisableTable = (isDisable: boolean): void => {
        this.rows.forEach((row) => (row.isDisable = isDisable));
        this.asyncUpdater.reloadObservable.next();
    };

    /**
     * Extrai os dados básicos de uma parâmetro.
     * @returns Object com os valores de "parameter", "value" e "type" presentes no modelo @see Parameters.
     */
    private extractParameterBasicData = (parameter: Parameters) => {
        return _.pick(parameter, "parameter", "value", "type");
    };

    /**
     * Verifica se o parâmetro é de escrita.
     */
    private isConfigurableParameter = (parameter) => {
        return parameter.writable;
    };
}
