import { Component, OnInit, Inject, OnDestroy, Input } from "@angular/core";
import { TooltipOptions } from "ng2-tooltip-directive";
import {
    NmsTableColumn,
    NmsTableConfig,
    NmsTableColumnType,
    DropdownMenu,
    AsyncUpdater,
    NmsTableActions
} from "@nms-ng1/components/ui/nms-table-old/nms-table-models";
import { Cpe, CpeRemovalResponse } from "../cpe-model";
import { ModalFactory } from "@nms-ng2/app/shared/services/modal/modal.factory";
import {
    ANGULARJS_TRANSLATE,
    ANGULARJS_ROOTSCOPE,
    STATE,
    NMS_STATES,
    FILTER,
    USER_PREFERENCES_SERVICE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { CpeService } from "../cpe.service";
import { CpeShortcutsActionService } from "../cpe-shortcuts-action.service";
import { Subject } from "rxjs";
import { CpesActionsService } from "@nms-ng2/app/shared/services/actions/cpes-actions.service";
import { CpeFieldValues } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";

/**
 * Classe utilizada no filtro de busca global
 */
export class CpesListOptions {
    public static readonly FILTER_KEY: string = "list-cpe-options";
    public static readonly PROPERTIES_KEYS: Array<string> = ["globalFilter"];

    public globalFilter: string;

    constructor(globalFilter: string) {
        this.globalFilter = globalFilter;
    }
}

/**
 * Modelo para os parâmetros passados pela URL para tabela de CPEs
 */
export interface CpeUrlFilterParameter {
    operatorType?: "AND" | "OR";
    serialNumber?: string;
    manufacturer?: string;
    productModel?: string;
    hostname?: string;
    lastInform?: string;
    hardwareVersion?: string;
    softwareVersion?: string;
    provisioningCode?: string;
    connectionRequestUrl?: string;
    [key: string]: string
}

export type CpeEquipmentFieldToTableField = {
    [key in CpeFieldValues]: string
}

const CPE_FIELD_TO_TABLE_FIELD: CpeEquipmentFieldToTableField = {
    SERIAL_NUMBER:     "serialNumber",
    MANUFACTURER:      "manufacturer",
    PRODUCT_CLASS:     "productModel",
    MANAGEMENT_IP:     "hostname",
    LAST_INFORM:       "lastInform",
    HW_VERSION:        "hardwareVersion",
    SW_VERSION:        "softwareVersion",
    PROVISIONING_CODE: "provisioningCode"
}

/* colunas existentes para CPE */
const CPES_TABLE_COLUMNS = [
    {
        field: "serialNumber",
        width: "160",
        type: NmsTableColumnType.DROPDOWN_MENU
    },
    { field: "manufacturer", width: "130" },
    { field: "productModel", width: "130" },
    { field: "hostname", width: "130" },
    { field: "lastInform", width: "160", sortable: "lastInformToSort" },
    { field: "hardwareVersion", width: "130", validateVersionFormat: true },
    { field: "softwareVersion", width: "130", validateVersionFormat: true },
    { field: "provisioningCode", width: "130" },
    { field: "connectionRequestUrl" }
];

/* configurações da tabela */
const CPES_TABLE_CONFIG: NmsTableConfig = {
    hideCustomizedTableComponents: false,
    tableId: "cpe-table",
    initialSort: { serialNumber: "asc" },
    tableTranslatePrefix: "cpe.tablecolumn",
    selectionId: "cpe-table-selector",
    rowId: "serialNumber"
};
@Component({
    selector: "cpes-table",
    templateUrl: "./cpes-table.component.html",
    styleUrls: ["./cpes-table.component.scss"]
})
export class CpesTableComponent implements OnInit, OnDestroy {
    @Input() showTitle: boolean;
    @Input() singleClick?: Function;
    @Input() doubleClick?: Function;
    @Input() hiddenActions?: Array<string> = new Array<string>();
    @Input() openLinksInNewTab?: boolean = false;
    @Input() refreshActions?: any;
    @Input() selectedItems?: any;
    @Input() cpesProvider: any;
    @Input() queryParameters: CpeUrlFilterParameter;

    columns: Array<NmsTableColumn>;
    config: NmsTableConfig;
    cpes: Array<Cpe>;
    dropdownMenus: Array<DropdownMenu>;
    tableActions: Array<NmsTableActions>;
    asyncUpdater: AsyncUpdater;
    tableBodyMessage: string;
    searchAllFilterText: string;
    tooltipOptions: TooltipOptions;
    selectedCpes: Array<Cpe> = new Array<Cpe>();

    constructor(
        @Inject(ANGULARJS_TRANSLATE) private translate: any,
        @Inject(STATE) private $state: any,
        @Inject(NMS_STATES) private nmsStates: any,
        @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
        @Inject(USER_PREFERENCES_SERVICE) private userPreferenceService: any,
        @Inject(FILTER) private filter: any,
        private toastr: NmsToastrService,
        private modalFactory: ModalFactory,
        private cpeService: CpeService,
        private readonly cpeShortcutsActionService: CpeShortcutsActionService,
        private readonly cpesActionsService: CpesActionsService
    ) {
        this.columns = CPES_TABLE_COLUMNS.slice()
        this.config = Object.assign({}, CPES_TABLE_CONFIG)
        this.tooltipOptions = {
            "content-type": "html",
            placement: "bottom",
            offset: 15
        };

        this.cpes = new Array<Cpe>();
        this.asyncUpdater = {
            multipleRows: {
                responseObservable: new Subject()
            },
            searchAll: {
                responseObservable: new Subject()
            }
        };
        this.configDropDownItems();
    }

    ngOnInit() {
        const refreshActionsPlaceholder = {
            retrieveFn: () => {},
            startUpdatingFn: () => {},
            finishedUpdatingFn: () => {}
        };

        this.refreshActions = _.get(this, "refreshActions", refreshActionsPlaceholder);
        this.refreshActions.retrieveFn = () => this.loadCpes();

        this.initializeQueryParameters();
        this.configTableActions();
        this.loadCpes();
    }

    /**
     * Define os filtros da tabela de acordo com os parametros recebidos.
     *
     * Caso os parâmetros estejam seguindo o modelo usado no matching rules,
     * converte as chaves dos campos para os nomes utilizados na tabela
     */
    private initializeQueryParameters() {
        if (this.queryParameters && Object.keys(this.queryParameters).length > 0) {
            Object.keys(CPE_FIELD_TO_TABLE_FIELD).forEach((key) => {
                if (this.queryParameters[key]) {
                    this.queryParameters[CPE_FIELD_TO_TABLE_FIELD[key]] = this.queryParameters[key];
                    delete this.queryParameters[key]
                }
            })

            this.config.initCustomFilters = this.queryParameters;
            this.config.overrideWithCustomFilters = true;
        }
    }

    /**
     * Realiza o carregamento dos CPEs na tabela.
     */
    private loadCpes() {
        this.refreshActions.startUpdatingFn();
        this.tableBodyMessage = "cpe.tableAction.retrieving.message";

        this.cpesProvider().subscribe(
            (response: Array<Cpe>) => this.successGetCpes(response),
            (error) => this.errorGetCpes()
        );
    }

    private successGetCpes(response: Array<Cpe>) {
        this.refreshActions.finishedUpdatingFn();
        this.updateCpes(response);
        this.tableBodyMessage = null;
        this.applyGlobalFilterUserPreferences();
    }

    private errorGetCpes() {
        this.refreshActions.finishedUpdatingFn();
        this.showServerErrorMessage();
        this.tableBodyMessage = null;
    }

    updateCpes(cpes: Array<Cpe>): void {
        this.cpes = this.resolverCpeDateValue(cpes);
        this.asyncUpdater.multipleRows.responseObservable.next(this.cpes);
    }

    private showServerErrorMessage() {
        this.$rootScope.showDialog({ translateKey: "http.error.serverError" });
    }

    private updateCpeGlobalFilter = () => {
        this.userPreferenceService.savePreferences(
            new CpesListOptions(this.searchAllFilterText),
            CpesListOptions.FILTER_KEY,
            CpesListOptions.PROPERTIES_KEYS
        );
    };

    private applyGlobalFilterUserPreferences = () => {
        const { globalFilter } = this.userPreferenceService.loadPreferences(
            {},
            CpesListOptions.FILTER_KEY,
            CpesListOptions.PROPERTIES_KEYS
        );
        this.searchAll(globalFilter);
        this.searchAllFilterText = globalFilter;
    };

    searchAll(filterText: string) {
        this.asyncUpdater.searchAll.responseObservable.next(filterText);
    }

    /**
     * Configura os itens dos dropdowns a serem visualizados ao selecionar o serialNumber
     * na listagem dos cpes
     */
    private configDropDownItems() {
        this.dropdownMenus = [
            {
                field: "serialNumber",
                defaultAction: (cpe: Cpe) => {
                    const serialNumber = cpe.serialNumber;
                    this.$state.go(this.nmsStates.cwmpParameters, { serialNumber });
                },
                shortcuts: [
                    {
                        label: this.translate.instant("cpe.tableAction.parameters"),
                        action: (cpe: Cpe) =>
                            this.modalFactory.zone.run(async () => {
                                this.cpeShortcutsActionService.checkValidateParametersLink([cpe]);
                            })
                    },
                    {
                        label: this.translate.instant("cpe.tableAction.applyTemplate"),
                        action: (cpe: Cpe) => {
                            this.cpeShortcutsActionService.goToTemplateApplication([cpe]);
                        }
                    },
                    {
                        label: this.translate.instant("cpe.tableAction.viewTemplateApplication"),
                        action: (cpe: Cpe) => {
                            this.cpeShortcutsActionService.viewTemplateApplication([cpe]);
                        }
                    },
                    {
                        label: this.translate.instant("cpe.tableAction.credentials"),
                        action: (cpe: Cpe) => {
                            this.cpeShortcutsActionService.openCredentialsModalCpes([cpe]);
                        }
                    },
                    {
                        label: this.translate.instant("cpe.tableAction.testconectivity"),
                        action: (cpe: Cpe) => {
                            this.cpeShortcutsActionService.openConnectivityTestModalForCpes([cpe]);
                        }
                    },
                    {
                        label: this.translate.instant("gpon.cpes.tableActions.tr069.download"),
                        action: (cpe: Cpe) => {
                            this.cpesActionsService.openDownloadRequestModal([cpe]);
                        }
                    },
                    {
                        label: this.translate.instant("gpon.cpes.tableActions.tr069.reboot"),
                        hasDivider: true,
                        action: (cpe: Cpe) => {
                            this.cpesActionsService.rebootCpes(cpe);
                        }
                    },
                    {
                        label: this.translate.instant("cpe.tableAction.remove"),
                        action: (cpe: Cpe) => {
                            this.showRemoveCpesConfirmModal([cpe]);
                        }
                    }
                ]
            }
        ];
    }

    /**
     * Configura as ações a serem visualizadas ao selecionar um ou múltiplos cpes
     */
    private configTableActions() {
        this.tableActions = [
            {
                id: "parametersLink",
                translateKey: "cpe.tableAction.parameters",
                action: (selectedCpes: Array<Cpe>) => {
                    this.cpeShortcutsActionService.checkValidateParametersLink(selectedCpes, false);
                }
            },
            {
                id: "applyLink",
                translateKey: "cpe.tableAction.applyTemplate",
                action: (selectedCpes: Array<Cpe>) => {
                    this.cpeShortcutsActionService.goToTemplateApplication(selectedCpes);
                }
            },
            {
                id: "viewTemplateApplicationLink",
                translateKey: "cpe.tableAction.viewTemplateApplication",
                action: (selectedCpes: Array<Cpe>) => {
                    this.cpeShortcutsActionService.viewTemplateApplication(selectedCpes);
                }
            },
            {
                id: "credentialsLink",
                translateKey: "cpe.tableAction.credentials",
                action: (selectedCpes: Array<Cpe>) => {
                    this.cpeShortcutsActionService.openCredentialsModalCpes(selectedCpes);
                }
            },
            {
                id: "testConnectivityLink",
                translateKey: "cpe.tableAction.testconectivity",
                action: (selectedCpes: Array<Cpe>) => {
                    this.cpeShortcutsActionService.openConnectivityTestModalForCpes(selectedCpes);
                }
            },
            {
                id: "downloadLink",
                translateKey: "gpon.cpes.tableActions.tr069.download",
                action: (selectedCpes: Array<Cpe>) => {
                    this.cpesActionsService.openDownloadRequestModal(selectedCpes);
                }
            },
            {
                id: "rebootLink",
                translateKey: "gpon.cpes.tableActions.tr069.reboot",
                action: (selectedCpes: Array<Cpe>) => {
                    this.cpesActionsService.rebootCpes(selectedCpes);
                }
            },
            {
                id: "remove",
                translateKey: "cpe.tableAction.remove",
                action: (selectedCpes: Array<Cpe>) => {
                    this.showRemoveCpesConfirmModal(selectedCpes);
                },
                hide: this.hiddenActions.includes("remove")
            }
        ];
    }

    showRemoveCpesConfirmModal = (selectedsCpes: Array<Cpe>): void => {
        this.$rootScope.showDialog({
                message: this.processRemoveCpeMessage(selectedsCpes),
                isConfirm: true
        }).then(() => {
            this.cpeService.verifyCpesToRemove(selectedsCpes).subscribe((cpeRemovalResults: Array<CpeRemovalResponse>): void => {
                this.showCpesValidateRemovalResults(cpeRemovalResults, selectedsCpes);
            });
        });
    };

    showCpesValidateRemovalResults(cpeRemovalResults: Array<CpeRemovalResponse>, selectedCpes: Array<Cpe>) {
        const keyViolation = this.translate.instant("cpe.tableActions.templateAssociationMessage");
        const cpesRemoved = cpeRemovalResults.filter((cpe) => cpe.isAbleToRemove && cpe.violationMessage === "" );
        const cpesNotRemoved = cpeRemovalResults.filter((cpe) => !cpe.isAbleToRemove);
        const cpesWithTemplateInstances= cpeRemovalResults.filter((cpe)=> {
            return cpe.isAbleToRemove && cpe.violationMessage.includes(keyViolation);
        });
        if (cpesWithTemplateInstances.length > 0) {
            this.showRemoveTemplateInstanceConfirmationMessage(cpesWithTemplateInstances, selectedCpes);
        }
        this.handleCpesToRemove(cpesRemoved, cpesNotRemoved, cpeRemovalResults, selectedCpes);
    }

    showRemoveTemplateInstanceConfirmationMessage(cpesWithTemplateInstances: Array<CpeRemovalResponse>, selectedCpes: Array<Cpe>) {
        const serialNumbers = cpesWithTemplateInstances.map((cpe)=> cpe.cpeSerialNumberToRemove);
        this.$rootScope.showDialogSingularOrPlural({
            translateObjs: this.getCannotRemoveCpesTranslateObjects(serialNumbers),
            listParams: serialNumbers,
            maxChars: 128,
            isConfirm: true
        }).then(() => {
            const cpesToRemove = selectedCpes.filter((cpe) => serialNumbers.includes(cpe.serialNumber));
            this.removeCpesAndShowNotification(cpesToRemove);
        });
        return;
    }

    handleCpesToRemove(cpesRemoved: Array<CpeRemovalResponse>, cpesNotRemoved: Array<CpeRemovalResponse>,
        cpeRemovalResuls: Array<CpeRemovalResponse>, selectedCpes: Array<Cpe>): void {
        if (cpesRemoved.length === cpeRemovalResuls.length) {
            this.removeCpesAndShowNotification(this.convertToCpe(cpesRemoved, selectedCpes));
        } else {
            if (cpesRemoved.length > 0) {
                this.removeCpesAndShowNotification(this.convertToCpe(cpesRemoved, selectedCpes));
            }
            if (cpesNotRemoved.length > 0) {
                this.$rootScope.showDialogSingularOrPlural({
                    translateObjs: this.processTranslateObjects(cpesNotRemoved, cpesRemoved, cpeRemovalResuls),
                    listParams: this.processCpesNotRemoved(cpesNotRemoved, cpeRemovalResuls),
                    maxChars: 128
                });
            }
        }
    }

    private processCpesNotRemoved = (notRemoved: Array<CpeRemovalResponse>, cpeRemovalResults: Array<CpeRemovalResponse>) => {
        if (this.cpesNotRemovedIsEqualCpeValidationResuls(notRemoved, cpeRemovalResults)) {
            return notRemoved.map(cpe => cpe.violationMessage);
        }
        return this.processCpesRemovedAndCpesNotRemoved(notRemoved);
    };

    private cpesNotRemovedIsEqualCpeValidationResuls = (notRemoved: Array<CpeRemovalResponse>,
        cpeRemovalValidationResults: Array<CpeRemovalResponse>) => {
        return notRemoved.length === cpeRemovalValidationResults.length;
    };

    private processCpesRemovedAndCpesNotRemoved = (notRemoved: any) => {
        const errorSingleKey = "findAddCpe.error.oneCpeCouldNotBeRemoved";
        const errorMultipleKey = "findAddCpe.error.someCpesCouldNotBeRemoved"
        const translateKey = notRemoved.length === 1 ? errorSingleKey : errorMultipleKey;
        const violationMessages = notRemoved.map(cpe => cpe.violationMessage);
        const cpesNotRemoved = [this.translate.instant(translateKey).replace("{0}", notRemoved.length), ...violationMessages];
        return cpesNotRemoved;
    };

    private processTranslateObjects = (notRemoved: Array<CpeRemovalResponse>, removed: Array<CpeRemovalResponse>, cpeRemovalValidationResults: Array<CpeRemovalResponse>) => {
        if (this.cpesNotRemovedIsEqualCpeValidationResuls(notRemoved, cpeRemovalValidationResults)) {
            return [
                {
                    pluralKey: "findAddCpe.error.cpesCanNotBeRemoved",
                    singularKey: "findAddCpe.error.cpeCanNotBeRemoved",
                    isPlural: notRemoved.length > 1,
                    insideMsgParams: [notRemoved.length]
                }
            ];
        }
        return [
            {
                pluralKey: "cpe.tableAction.confirm.multiple.success.remove.message",
                singularKey: "cpe.tableAction.confirm.success.remove.message",
                isPlural: removed.length > 1,
                insideMsgParams: [removed.length]
            }
        ];
    };

    private removeCpesAndShowNotification(selectedsCpes: Array<Cpe>): void {
        this.removeCpes(selectedsCpes);
        this.toastr.success(this.getSucessRemoveMessage(selectedsCpes));
    }

    private removeCpes = (selectedsCpes: Array<Cpe>) => {
        this.cpeService.removeCpes(selectedsCpes).subscribe(
            () => {
                this.loadCpes();
            },
            (error) => {
                console.error(error);
                this.toastr.error(this.translate.instant("cpe.tableAction.confirm.error.remove.message"));
            }
        );
    };

    private getCannotRemoveCpesTranslateObjects(cpesWithTemplateInstances: Array<string>) {
        return [
            {
                pluralKey: "cpe.tableActions.cannotRemoveCpes.templateAssociationMessage",
                singularKey: "cpe.tableActions.cannotRemoveCpe.templateAssociationMessage",
                isPlural: cpesWithTemplateInstances.length > 1,
                insideMsgParams: [cpesWithTemplateInstances.length]
            }
        ];
    }

    private getSucessRemoveMessage = (selectedsCpes: Array<Cpe>): string => {
        return selectedsCpes.length > 1
            ? this.translate
                  .instant("cpe.tableAction.confirm.multiple.success.remove.message")
                  .replace("{0}", selectedsCpes.length)
            : this.translate.instant("cpe.tableAction.confirm.success.remove.message");
    };

    private processRemoveCpeMessage = (selectedsCpes: Array<Cpe>): string => {
        let messageKey =
            selectedsCpes.length > 1 ? "cpe.tableAction.confirm.multiple.message" : "cpe.tableAction.confirm.message";

        return this.translate.instant(messageKey);
    };

    private resolverCpeDateValue(cpeArray: Array<Cpe>): Array<Cpe> {
        let cpes = cpeArray.map((cpe) => ({
            ...cpe,
            lastInform: this.filter("dateFormat")(cpe.lastInform),
            lastInformToSort: cpe.lastInform
        }));
        return cpes;
    }

    private convertToCpe(arrayRemovalResponse: Array<CpeRemovalResponse>, selectedCpes: Array<Cpe>): Array<Cpe> {
        const serialNumbers = arrayRemovalResponse.map((cpe)=> cpe.cpeSerialNumberToRemove);
        return selectedCpes.filter((cpe) => serialNumbers.includes(cpe.serialNumber));
    }

    ngOnDestroy(): void {
        this.updateCpeGlobalFilter();
    }
}
