"use strict";

/**
* @ngdoc service
* @name nms.PropertiesService
* @description - Service para gerenciamento de propriedades de equipamentos. Tem como principal responsabilidade, facilitar
* interação com a modal 'properties-edit-modal' desempenhando as seguintes funções:
*   - Gerencia a abertura da modal;
*   - Busca e atualização das propriedades do equipamento no Back-End para possibilitar a apresentação da modal com dados atuais;
*   - Criação do modelo de dados utilizado pela modal, a partir do domínio do equipamento;
*   - Implementação dos tratamentos específicos, para a edição de propriedades de um equipamento ou de múltiplos equipamentos;
*   - Conversão do modelo de dados da modal, para o modelo de dados utilizado pela API Rest de atualização de propriedades;
* Factory in the nms.
*/
var app = angular.module("nms");

app.factory("PropertiesService", [
    "$rootScope", "PropertiesRestService", "PropertiesModel", "PropertiesUpdateModel", "ngDialog", "$q", "$translate",
    "BROADCAST_EVENTS",
    function($rootScope, PropertiesRestService, PropertiesModel, PropertiesUpdateModel, ngDialog, $q, $translate,
        BROADCAST_EVENTS) {
        var basicPropertiesFields = ["notes", "project", "address", "city", "state", "country", "station", "room", "shelf"];
        var allPropertiesFields = _.union(["name"], basicPropertiesFields);
        const service: any = {};

        /**
        * Providencia modelo para a modal properties-edit-modal com dados do equipamento atualizados através de requisição REST.
        *
        * @param {object} device Equipamento do qual se deseja as propriedades atualizadas.
        * @return {Promisse} A promisse que será disparada quando se for obtida as propriedades atualizadas do equipamento.
        */
        var provideUpdatedDeviceProperties = function(device) {
            var deferred = $q.defer();
            PropertiesRestService.getProperties(device.id).then(function(deviceProperties) {
                deferred.resolve(new PropertiesModel(deviceProperties, deviceProperties.devId));
            });

            return deferred.promise;
        };

        /**
        * Verifica se houve alteração no nome do equipamento, e caso positivo envia evento via broadcast para notificar
        * interessados na alteração.
        */
        var checkDeviceNameChange = function(device, previousName) {
            if (previousName !== device.name) {
                $rootScope.$broadcast(BROADCAST_EVENTS.DEVICE_NAME_CHANGED, {
                    device: device,
                    previousName: previousName
                });
            }
        };

        /**
        * Atualiza as propriedades do equipamento no back-end através de requisição REST.
        * Caso a atualização seja feita com sucesso no back-end, atualiza as propriedades no objeto do equipamento para que as
        * alterações já sejam consideradas no front-end.
        *
        * @param {object} device Equipamento que terá as propriedades atualizadas.
        * @param {object} properties Propriedades que serão atualizadas no equipamento.
        */
        var saveDeviceProperties = function(device, properties) {
            var updateModel = new PropertiesUpdateModel(device, properties, true);
            PropertiesRestService.updateProperties(updateModel).then(function(response) {
                if (response.propertiesUpdated) {
                    var previousName = device.name;
                    device.name = properties.name;
                    updateDeviceProperties(device, properties);
                    checkDeviceNameChange(device, previousName);
                    $rootScope.toastInfo("toastr.devicePropertiesSuccessfullyEdited");
                } else {
                    $rootScope.showDialog({message: response.violationMessage});
                }
            });
        };

        /**
        * Atualiza as propriedades no objeto do equipamento
        *
        * @param {object} device objeto que terá as propriedades atualizadas.
        * @param {object} properties Propriedades que serão atualizadas no objeto de equipamento.
        */
        var updateDeviceProperties = function(device, properties) {
            device.properties = {
                notes: properties.notes,
                project: properties.project,
                internalLocation: {
                    station: properties.station,
                    room: properties.room,
                    shelf: properties.shelf
                },
                externalLocation: {
                    address: properties.address,
                    city: properties.city,
                    state: properties.state,
                    country: properties.country
                }
            };
        };

        /**
        * Retorna a lista de equipamentos que tiverem alguma alteração nas propriedades (com exceção do nome).
        *
        * @param {array} devices lista de equipamentos.
        * @param {object} properties objeto com as propriedades para comparação com as propriedades de cada equipamento.
        */
        var getPropertiesChangedDevices = function(devices, properties) {
            return _.filter(devices, function(device) {
                var deviceProperties = new PropertiesModel(device.properties);
                return !arePropertiesEqual(deviceProperties, properties, true);
            });
        };

        /**
        * Atualiza as propriedades de uma lista de equipamentos no back-end através de requisições REST.
        * Nos casos em que a atualização no back-end seja feita com sucesso, as propriedades no objeto do equipamento também são
        * atualizadas para que as alterações já sejam consideradas no front-end.
        * Os casos de falha de atualização são armazenados e apresentados ao usuário no final do processo.
        *
        * TODO: Neste ponto, cabe uma melhoria, com a criação de um serviço REST que receba a lista de propriedades ao invés de
        * iterar sobre a lista e chamar o mesmo serviço várias vezes.
        *
        * @param {Array} devices Equipamentos que terão as propriedades atualizadas.
        * @param {object} properties Propriedades que serão atualizadas nos equipamentos.
        */
        var updateMultipleDeviceProperties = function(devices, properties) {
            var concludeDevices = 0;
            var result = true;
            var violationMessage = "";
            var changedDevices = getPropertiesChangedDevices(devices, properties);
            _.forEach(changedDevices, function(device) {
                var updateModel = new PropertiesUpdateModel(device, properties, false);
                PropertiesRestService.updateProperties(updateModel).then(function(response) {
                    concludeDevices++;
                    result = result && response.propertiesUpdated;
                    violationMessage = "<br>" + response.violationMessage;

                    if (response.propertiesUpdated) {
                        updateDeviceProperties(device, properties);
                    }

                    if (concludeDevices === changedDevices.length) {
                        if (result) {
                            $rootScope.toastInfo("toastr.devicesPropertiesSuccessfullyEdited", [changedDevices.length]);
                        } else {
                            $rootScope.showDialog({message: violationMessage});
                        }
                    }
                });
            });
        };

        /**
        * Cria um objeto com valores válidos para propriedades que estejam entre a lista de atributos esperados.
        *
        * @param {object} properties objeto com as informações de propriedades.
        * @param {array} fields lista com os nomes dos atributos desejados para composição do objeto de resposta.
        */
        var extractValidValues = function(properties, fields) {
            var extracted = _.pick(properties, fields);
            extracted = _.pick(extracted, _.negate(_.isEmpty));

            return extracted;
        };

        /**
        * Verifica se dois objetos de propriedades são iguais.
        *
        * @param {object} properties1 um dos objetos para comparação.
        * @param {object} properties2 outro objeto para comparação.
        * @param {boolean} ignoreName indica se o campo de 'nome' da propriedade será ignorado na comparação.
        */
        var arePropertiesEqual = function(properties1, properties2, ignoreName?) {
            var fieldsToCompare = ignoreName ? basicPropertiesFields : allPropertiesFields;
            var values1 = extractValidValues(properties1, fieldsToCompare);
            var values2 = extractValidValues(properties2, fieldsToCompare);
            var isEqual = _.isEqual(values1, values2);

            return isEqual;
        };

        /**
        * Valida a atualização de propriedades de um equipamento. Exibe uma mensagem caso não exista nenhuma alteração.
        *
        * @param {object} updatedProperties objeto com as atualizações nas propriedades.
        * @param {object} initialProperties objeto com as informações originais de propriedades.
        */
        var validateDevicePropertiesUpdate = function(updatedProperties, initialProperties) {
            if (arePropertiesEqual(initialProperties, updatedProperties)) {
                $rootScope.showDialog({message: $translate.instant("modals.properties.noChangesInConfigs")});
                return false;
            }

            return true;
        };

        /**
        * Valida a atualização de propriedades para múltiplos equipamentos. Exibe mensagem caso não exista nenhuma alteração de
        * propriedades entre os equipamentos da lista.
        *
        * @param {array} devices equipamentos candidatos a atualização de propriedades.
        * @param {object} properties objeto com as informações originais de propriedades.
        */
        var validateMultipleDevicePropertiesUpdate = function(devices, properties) {
            var anyDevicePropertiesChanged = _.any(devices, function(device) {
                var deviceProperties = new PropertiesModel(device.properties);
                return !arePropertiesEqual(deviceProperties, properties, true);
            });

            if (!anyDevicePropertiesChanged) {
                $rootScope.showDialog({message: $translate.instant("modals.properties.noChangesInConfigs")});
                return false;
            }

            return true;
        };

        /**
        * Abre a modal de propriedades para consulta ou edição de propriedades de um Equipamento. As informações de propriedades
        * do equipamento são buscadas no back-end antes da abertura da modal para certificar que os dados apresentados são atuais.
        * Quando o usuário confirma as informações da modal, as propriedades são atualizadas no back-end.
        *
        * @param {object} device Equipamento para o qual se pretente abrir a modal de propriedades para consulta ou edição.
        */
        service.openDevicePropertiesModal = function(device) {
            var options: any = {
                model: provideUpdatedDeviceProperties(device),
                finishCallback: function(updatedProperties) {
                    saveDeviceProperties(device, updatedProperties);
                },
                validatorFunction: validateDevicePropertiesUpdate
            };

            service.openPropertiesModal(options);
        };

        /**
        * Abre a modal de propriedades para edição de múltiplos equipamentos.
        * A modal é apresentada sem informações e com um tooltip que explica o funcionamento desta edição.
        * Quando o usuário confirma as informações da modal, as propriedades (exceto nome, que não é disponível neste caso), são
        * atualizadas para todos equipamentos no back-end.
        *
        * @param {Array} devices Lista dos equipamentos para edição de propriedades.
        */
        service.openMultipleDevicePropertiesModal = function(devices) {
            var options: any = {
                guiSettings: {
                    showNameField: false,
                    isChangeRequired: false,
                    tipMessage: $translate.instant("modals.properties.popoverDefaultProperties"),
                    subtitle: $translate.instant("modals.properties.modalSubTitleMultipleHosts")
                },
                finishCallback: function(updatedProperties) {
                    updateMultipleDeviceProperties(devices, updatedProperties);
                },
                validatorFunction: function(properties) {
                    return validateMultipleDevicePropertiesUpdate(devices, properties);
                }
            };

            service.openPropertiesModal(options);
        };

        /**
        * Abre a modal de propriedades 'properties-edit-modal.html'.
        *
        * @param {object} options parametrizações da modal, contendo opcionalmente os dados para preenchimento do formulário,
        * parâmetros para customizações visuais e callback de confirmação.
        */
        service.openPropertiesModal = function(options) {
            ngDialog.openConfirm({
                template: "templates/components/ui/modals/properties-edit-modal.html",
                controller: "PropertiesEditModalController",
                className: "medium-modal",
                resolve: {
                    model: function() {
                        return _.get(options, "model", new PropertiesModel());
                    },
                    guiSettings: function() {
                        var guiSettings = _.get(options, "guiSettings", {});
                        guiSettings = _.defaults(guiSettings, {
                            showNameField: true,
                            isChangeRequired: true,
                            tipMessage: null,
                            subtitle: null
                        });

                        return guiSettings;
                    },
                    validatorFunction: function() {
                        return options.validatorFunction;
                    }
                }
            }).then(function(confirmed) {
                if (options.finishCallback) {
                    options.finishCallback(confirmed);
                }
            });
        };

        return service;
    }
]);
