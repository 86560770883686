"use strict";

/**
 * Filter that verify if a given input is an Array.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.filter("isArray",
    function() {
        return function(input) {
            return angular.isArray(input);
        };
    }
);
