"use strict";

import { TemplateInstanceService } from "@nms-ng1/features/template/template-application/template-instance-service";
import {
    CommandsRequest,
    TemplateInstanceCommandsRequest,
    TemplateInstanceEquipmentsCommandsRequest
} from "@nms-ng2/app/modules/template/template-instance/template-instance-models";


var app = angular.module("nms.templates");

app.controller("CommandsViewerController", [
    "$scope",
    "TemplateInstanceService",
    "BROADCAST_EVENTS",
    function ($scope, TemplateInstanceService: TemplateInstanceService, BROADCAST_EVENTS) {
        _.defaults($scope, { showErrorOnConsole: false });
        _.defaults($scope, { showCommandTypeTitle: true });

        const successGetCommands = function (commands) {
            $scope.commands = commands;
        };

        /**
         * Por padrão os comandos de aplicação serão separados por deviceName.
         * Caso seja necessário realizar algum processamento diferente do padrão, deverá ser passado para a diretiva o atributo
         * getCommandTitle contendo a referência da função que executará o processamento.
         */
        $scope.getCommandTitleDefault = function (command) {
            return command.deviceName;
        };

        $scope.getCommands = function () {
            const httpConfig: any = { bypassInterceptor: $scope.showErrorOnConsole };

            const commandRequest: CommandsRequest = $scope.buildTemplateInstanceCommandRequest();

            if (Object.keys(commandRequest).includes("equipmentTemplateIdentifiers")) {
                TemplateInstanceService
                    .getEquipmentsCommands(commandRequest as TemplateInstanceEquipmentsCommandsRequest, httpConfig)
                    .then(successGetCommands);
            } else {
                TemplateInstanceService
                    .getCommands(commandRequest as TemplateInstanceCommandsRequest)
                    .then(successGetCommands);
            }
        };

        $scope.init = function () {
            $scope.commands = [];
        };

        const unregisterDevicePropertiesChangeListener = $scope.$on(BROADCAST_EVENTS.DEVICE_NAME_CHANGED, function (event) {
            $scope.getCommands();
        });

        const unregisterGetCommandsWatcher = $scope.$on("getCommands", function () {
            $scope.getCommands();
        });

        $scope.init();

        $scope.$on("$destroy", () => {
            unregisterDevicePropertiesChangeListener();
            unregisterGetCommandsWatcher();
        });
    }
]);
