"use strict";

/**
* @ngdoc directive
* @name nms.directive:stepper
* @description Diretiva para criar um input númerico com steppers
*/
var app = angular.module("nms");
app.directive("stepper", ["$compile", "$timeout", "$interval", "StepperService",
    function($compile, $timeout, $interval, StepperService) {
        return {
            restrict: "C",
            require: "ngModel",
            link: function(scope, element, attrs, ngModelCtrl) {
                var container = angular.element("<div class='stepper-input-container'></div>");
                var steppers = angular.element("<div class='steppers'></div>");
                var stepperUp = angular.element("<button class='glyphicon glyphicon-triangle-top' tabindex='-1'/>");
                var stepperDown = angular.element("<button class='glyphicon glyphicon-triangle-bottom' tabindex='-1'/>");

                var min = StepperService.parse(element.attr("min"))(scope) || Number.MIN_SAFE_INTEGER;
                var max = StepperService.parse(element.attr("max"))(scope) || Number.MAX_SAFE_INTEGER;
                var power = StepperService.parse(element.attr("power"))(scope) || null;
                var step = StepperService.parse(element.attr("step"))(scope) || 1;

                var operation: any = {
                    UP: {
                        type: "UP",
                        step: step,
                        power: power,
                        validate: function(value) {
                            return value <= max;
                        },
                        getValidValue: function(value) {
                            return value < min ? min : value;
                        }
                    },
                    DOWN: {
                        type: "DOWN",
                        step: step * -1,
                        power: power,
                        validate: function(value) {
                            return value >= min;
                        },
                        getValidValue: function(value) {
                            return value > max ? max : value;
                        }
                    }
                };

                var isInputInFocus = false;
                var isMouseIn = false;
                var clicked = false;
                var holdMinimumInterval;
                var processInterval;

                var createInnerHtml = function() {
                    element.attr("type", "text");
                    element.attr("autocomplete", "off");

                    element.wrap(container);
                    steppers.append(stepperUp);
                    steppers.append(stepperDown);
                    element.after(steppers);
                };

                var createEvents = function() {
                    var startProcess = function(operation) {
                        StepperService.process(operation, element);
                        holdMinimumInterval = $interval(function() {
                            $interval.cancel(holdMinimumInterval);
                            processInterval = $interval(function() {
                                StepperService.process(operation, element);
                            }, 50);
                        }, 200);
                    };

                    var endProcess = function() {
                        scope.$apply(function() {
                            ngModelCtrl.$setViewValue(element.val());
                            ngModelCtrl.$validate();
                        });
                        $interval.cancel(holdMinimumInterval);
                        $interval.cancel(processInterval);
                        holdMinimumInterval = null;
                        processInterval = null;
                        element.focus();
                    };

                    stepperUp.mousedown(function(event) {
                        clicked = true;
                        startProcess(operation.UP);
                    });

                    stepperDown.mousedown(function(event) {
                        clicked = true;
                        startProcess(operation.DOWN);
                    });

                    stepperUp.mouseup(endProcess);
                    stepperDown.mouseup(endProcess);
                    stepperUp.mouseleave(endProcess);
                    stepperDown.mouseleave(endProcess);

                    element.focus(function() {
                        isInputInFocus = true;
                        steppers.css("visibility", "visible");
                    });

                    element.blur(function() {
                        if (!isMouseIn) {
                            steppers.css("visibility", "hidden");
                        }
                        isInputInFocus = false;
                    });

                    var mouseenter = function(event) {
                        if (!element.attr("disabled")) {
                            isMouseIn = true;
                            steppers.css("visibility", "visible");
                        }
                    };

                    var mouseleave = function(event) {
                        if (!isInputInFocus && !clicked) {
                            steppers.css("visibility", "hidden");
                        }
                        isMouseIn = false;
                        clicked = false;
                    };

                    element.parent().mouseenter(mouseenter);
                    element.parent().mouseleave(mouseleave);

                    scope.$watch(attrs["ngDisabled"], function(newValue, oldValue) {
                        if (newValue) {
                            element.parent().attr("disabled", "disabled");
                        } else {
                            element.parent().removeAttr("disabled");
                        }
                    });

                    ngModelCtrl.$validators.number = StepperService.getValidateNumberFn();
                    ngModelCtrl.$validators.numberMin = StepperService.getValidateMinFn(min);
                    ngModelCtrl.$validators.numberMax = StepperService.getValidateMaxFn(max);

                    var observer = StepperService.getMutationObserver(element);
                    observer.observe(element[0], {attributes: true});
                };

                createInnerHtml();
                createEvents();
                $compile(container)(scope);

                scope.$on("$destroy", function() {
                    element.off();
                });
            }
        };
    }
]);
