/**
 * Diretivas para breadcrumb na dynamic devices.
 */
var app = angular.module("nms.dynamicDevice");

app.directive("breadcrumb", [
    function() {
        return {
            restrict: "E",
            replace: true,
            templateUrl: "templates/features/dynamic-device/breadcrumb.html",
            controller: "BreadcrumbController",
            scope: {
                node: "="
            }
        };
    }
]);
