import { Injectable, NgZone, Inject, Type } from "@angular/core";
import { SimpleModalComponent, SimpleModalService } from "ngx-simple-modal";
import { take } from "rxjs/operators";
import { ANGULARJS_ROOTSCOPE, AUTHENTICATION_SERVICE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { ComponentLoaderService } from "../component-loader/component-loader.service";

export interface SimpleModalOptions {
    closeOnEscape: boolean;
    closeOnClickOutside: boolean;
    bodyClass: string;
    wrapperDefaultClasses: string;
    wrapperClass: string;
    animationDuration: number;
    autoFocus: boolean;
}
export type SimpleModalOptionsOverrides = Partial<SimpleModalOptions>;

/**
 * Service responsible to show modals of the system.
 * New Angular modals should be managed here.
 */
@Injectable()
export class ModalFactory {
    constructor(
        public simpleModalService: SimpleModalService,
        public zone: NgZone,
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope: any,
        @Inject(AUTHENTICATION_SERVICE) private readonly authenticationService: any,
        private readonly componentLoaderService: ComponentLoaderService
    ) {}

    /**
     * Criação de métodos genéricos para visualização da modal.
     * Apenas algumas classes estão usando os novos métodos abaixo.
     * Na US[7286] - será feito um refactory para que todas modais do projeto utilizem
     * esses métodos.
     **/
    openSimpleModal<T, T1>(
        modalComponent: Type<SimpleModalComponent<T, T1>>,
        data?: T,
        options?: SimpleModalOptionsOverrides
    ): Promise<T1> {
        modalComponent = this.componentLoaderService.resolveComponent(modalComponent);

        return this.simpleModalService.addModal(modalComponent, data, options).pipe(take(1)).toPromise();
    }

    openAsyncModal<T, T1>(modalComponent: Type<SimpleModalComponent<T, T1>>, data?: any, options?: any): void {
        this.zone.run(async () => {
            const callback = await this.openSimpleModal(modalComponent, data, options, );
            if (data && data.hasOwnProperty("successCallback") && callback) {
                const { successCallback } = data;
                return successCallback(callback);
            }
        });
    }
}