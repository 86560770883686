import {
    Component, Input, Output, EventEmitter
} from "@angular/core";

export interface NmsInfoConfigFilterModel {
    info: boolean;
    config: boolean;
}

/** Componente para realizar filtragem de dados. */
@Component({
  selector: "nms-info-config-filter",
  templateUrl: "./nms-info-config-filter.component.html",
  styleUrls: ["./nms-info-config-filter.component.scss"]
})
export class NmsInfoConfigFilterComponent {

    @Input()
    filters: NmsInfoConfigFilterModel;

    @Input()
    disable?: boolean = false;

    @Output()
    onFilterTriggered = new EventEmitter<NmsInfoConfigFilterModel>();

    toggleFilters = () => {
        this.filters.info = !this.filters.info;
        this.filters.config = !this.filters.config;
    };

    onFilterChanged = () => {
        this.onFilterTriggered.emit(this.filters);
    }

}
