var app = angular.module("nms.deviceDiscover", []);

app.constant("CONTACT_RANGE_TYPE", {
    SINGLE_IP: "single",
    SUBSEQUENT: "subsequent",
    SUBNETWORK: "subnetwork",
    HOSTNAME: "hostname"
});

app.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("features/device-discover");
}]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);
