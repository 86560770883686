// TODO -  Avaliar evolução desta tabela para tornar os campos mais flexíveis, incluindo validações, etc...
"use strict";
import { NmsTableColumnType, NmsTableRowAsync, RowUpdateType } from "./nms-table-models";

var app = angular.module("nms");

/**
 * Controller utilizada na diretiva nms-table.
 */
app.controller("NmsTableController", ["$rootScope", "$scope", "TABLE_PROPERTIES", "NgTableParams", "$translate",
    "ngDialog", "TableFilterService",
    function($rootScope, $scope, TABLE_PROPERTIES, NgTableParams, $translate, ngDialog, TableFilterService) {
        const changedRows: any[] = [];

        /**
         * Validador padrão do componente.
         * Esta validação inclui a verificação de itens selecionados.
         * Em caso de seleção vazia, uma modal de erro é apresentada.
         * */
        const isValid = (rows, noSelectedItemTranslationKey = "nms-table.commons.error.noSelectedItem"): boolean => {
            if (rows.length === 0) {
                $rootScope.showDialog({
                    translateKey: noSelectedItemTranslationKey
                });

                return false;
            }

            return true;
        };

        /** Se a página estiver um componente de barra de progressos global, os cálculos serão recalculados. */
        const updateProgressBar = () => {
            if ($scope.hasProgressColumn) {
                var concluded = $scope.rows.filter(row => row.percentage === 100);
                $scope.progressBarMessage = $scope.progressbarInformationMessage
                                            .replace("{0}", concluded.length)
                                            .replace("{1}", $scope.rows.length);
                $scope.percentage = (concluded.length / $scope.rows.length) * 100;
            }
        };

        // TODO - Por momento esse modal está tratando somente o resultado do Inform.
        // Neste ponto terá que ser alterado para a modal ficar genérica para se adequar a outros tipos de resultado.
        // Transferir esse dialog para um service, assim desacoplando da tabela.
        const showInformationDialog = (message: string) => {
            ngDialog.open({
                template: "templates/components/ui/popups/scrollable-modal.html",
                className: "ngdialog-theme-default scrollable-modal",
                controller: ["$scope", "title", "message", function($scope, title, message) {
                    $scope.title = title;
                    $scope.message = message;
                }],
                resolve: {
                    title: () => $translate.instant("modals.cwmp.connectivityTest.inform.details.dialog.title"),
                    message: () => message,
                }
            });
        }

        const showDialog = (message: string, type: string = "error") => {
            $rootScope.showDialog({ type, message, insertScrollOnDetailsMessage: true });
        };

        /**
         * Atualiza todos os dados da tabela.
         */
        $scope.updateRows = (rows: Array<Object>): void => {
            $scope.rows.splice(0, $scope.rows.length);
            rows.forEach(row => $scope.rows.push(row));

            changedRows.splice(0, changedRows.length);
            $scope.config.dirty = false;

            updateProgressBar();
            $scope.tableParams.settings().dataset = $scope.rows;
            $scope.tableParams.count($scope.tableParams.count());
            $scope.tableParams.reload();
        };

        $scope.getTooltip = (action, row) => {
            return action.tooltip ? $translate.instant(action.tooltip) : $translate.instant(action.getTooltip(row));
        }

        $scope.updateRow = (nmsTableRow: NmsTableRowAsync) => {
            const { identifier, data, rowUpdateType = RowUpdateType.update } = nmsTableRow;
            const rowIndex = $scope.tableParams.settings().dataset.findIndex(row => row[identifier] === data[identifier]);
            const rowWasFound = rowIndex !== -1;

            if ((rowUpdateType === RowUpdateType.update || rowUpdateType === RowUpdateType.upsert) && rowWasFound) {
                $scope.tableParams.settings().dataset[rowIndex] = data;
                updateProgressBar();
                $scope.tableParams.reload();
            } else if (rowUpdateType === RowUpdateType.upsert) {
                $scope.tableParams.settings().dataset.push(data);
                updateProgressBar();
                $scope.tableParams.reload();
            } else if (rowUpdateType === RowUpdateType.remove && rowWasFound) {
                $scope.tableParams.settings().dataset.splice(rowIndex, 1);
                updateProgressBar();
                $scope.tableParams.reload();
            }

            if (nmsTableRow.keepSelected){
                $scope.selection.checked.push(data);
            }
        };

        /**
         * Traduz o label do link que abre a modal com detalhes.
         * Default: nms-table.commons.status.details.label.default = "Details".
         * */
        $scope.getDetailsLabel = (translationKey: string = "nms-table.commons.status.details.label.default") => {
            return $translate.instant(translationKey);
        };

        /**
         * Adiciona o usuário logado como prefixo, a fim de manter os dados salvos
         * no localStorage atrelados ao usuário.
         */
        $scope.buildTableId = () => `${$rootScope.loggedUser} - ${$scope.config.tableId}`;

        /**
         * Cria o id de paginação de acordo com o id da tabela
         */
        $scope.buildPaginationId = () => `${$scope.config.tableId}-pagination`;

        /** Mostra a dialog de informação. */
        $scope.showInformationDialog = (message) => showInformationDialog(message);

        /** Mostra a dialog de erro. */
        $scope.showErrorDialog = (message) => showDialog(message);

        /**
         * Limpa as informações das linhas selecionadas, caso registrado e atualiza o progressBar.
         */
        $scope.cleanPreviousValuesIfNeeded = (rows) => {
            if ($scope.asyncUpdater && $scope.asyncUpdater.singleRow
                    && $scope.asyncUpdater.singleRow.cleanPreviousValues) {
                $scope.asyncUpdater.singleRow.cleanPreviousValues(rows);
                updateProgressBar();
            }
        };

        /** Atualiza os dados selecionados da tabela e chama a execução do updater caso esteja cadastrado. */
        $scope.reloadRows = (rows) => {
            $scope.cleanPreviousValuesIfNeeded(rows);
            $scope.asyncUpdater.singleRow.runAsyncUpdater(rows);
        };

        $scope.reloadRow = (row) => $scope.reloadRows([row]);

        /**
         * Realiza a validação dos dados selecionados.
         * Quando o resultado é válido a ação é executada, quando inválido a Dialog
         * de erro é apresentada não prosseguindo com a execução da ação.
         */
        $scope.callAction = (tableAction) => {
            // Define se a ação será executada para toda a lista ou apenas para as linhas selecionadas.
            let rows = tableAction.performActionWithAllData ? $scope.rows : $scope.selection.checked;

            if ((!tableAction.ignoreSelectedItemsValidation) && isValid(rows, tableAction.customNoSelectedItemTranslationKey)) {
                $scope.cleanPreviousValuesIfNeeded(rows);
                tableAction.action(rows);
                $scope.tableParams.reload();
            } else if (tableAction.ignoreSelectedItemsValidation) {
                tableAction.action(rows);
            }
        };

        /** Busca pela definição de menus de dropdown de acordo com o nome do field. */
        $scope.getDropdownMenu = (field) => $scope.dropdownMenus.find(dropdownMenu => dropdownMenu.field === field);

        /**
         * Realiza a chamada da função em elementos do tipo button.
         * Uma exceção será lançada para lembrar de implementar o action em elementos do tipo button
         */
        $scope.callActionButton = (row) => {
            if (!!row.action) {
                row.action(row);
            } else {
                throw ("There is no action for this button");
            }
        };

        $scope.init = () => {
            const { initialSort, tableTranslatePrefix, selectionId, showSelection,
                 initCustomFilters, overrideWithCustomFilters, actions, rowId } = $scope.config;

            $scope.tableParams = new NgTableParams({
                    page: 1,
                    count: TABLE_PROPERTIES.DEFAULT_PAGE_SIZE,
                    sorting: initialSort,
                    initCustomFilters: initCustomFilters,
                    overrideWithCustomFilters
                },
                {
                    dataset: $scope.rows,
                    filterOptions: {filterFn: TableFilterService.getFilter($scope.columns)}
                }
            );

            $scope.rowId = rowId;

            $scope.selection = {
                id: selectionId,
                checked: [],
                table: $scope.tableParams
            };

            $scope.tableModel = {
                options: {
                    translatePrefix: tableTranslatePrefix,
                    selection: showSelection,
                    actions
                },
                columns: $scope.columns,
                selection: $scope.selection,
                tableParams: $scope.tableParams
            };

            $scope.hasProgressColumn = $scope.columns.find(column => column.type === NmsTableColumnType.PROGRESS);

            /**
             * Caso exista alguma coluna com barras de progresso, cria a variável
             * percentage no escopo a fim de mostrar a barra de progresso "global"
             * para apresentar o andamento da execução dos itens selecionados na tabela.
             */
            if ($scope.hasProgressColumn) {
                $scope.progressbarInformationMessage = $translate.instant($scope.config.progressbarInformationTranslateKey);
                $scope.percentage = 0;
                updateProgressBar();
            }

            /**
             * Caso exista configuração de atualização assíncrona para itens da tabela, se registra para
             * receber os dados.
             */
            if ($scope.asyncUpdater) {
                if ($scope.asyncUpdater.singleRow) {
                    $scope.asyncUpdater.singleRow.responseObservable.subscribe($scope.updateRow);
                }

                if ($scope.asyncUpdater.multipleRows) {
                    $scope.asyncUpdater.multipleRows.responseObservable.subscribe($scope.updateRows);
                }

                if ($scope.asyncUpdater.reloadObservable) {
                    $scope.asyncUpdater.reloadObservable.subscribe(() => {
                        $scope.tableParams.reload();
                    });
                }

                if($scope.asyncUpdater.searchAll) {
                    $scope.asyncUpdater.searchAll.responseObservable.subscribe($scope.searchGlobalFilter);
                }

                if($scope.asyncUpdater.searchCustom) {
                    $scope.asyncUpdater.searchCustom.responseObservable.subscribe($scope.customFilter);
                }

                if($scope.asyncUpdater.clearFiltersCustom) {
                    $scope.asyncUpdater.clearFiltersCustom.responseObservable.subscribe($scope.clearFiltersCustom);
                }
            }

            if($scope.selectionUpdater) {
                $scope.selectionUpdater.update.subscribe(itens => $scope.selection.checked = itens)
            }

            /**
             * Verifica se o valor do input é diferente do valor original, caso positivo
             * adiciona os dados originais no array changedRows.
             * @param originalValue Valor original inserido no momento do load da tabela.
             * @param currentValue Valor utilizado no ngModel e que pode ser alterado pelo usuário.
             */
            $scope.checkChange = (originalValue, currentValue) => {
                let changedRowIndex = changedRows.findIndex(row => row === originalValue);

                if (String(originalValue.value) === String(currentValue.value)) {
                    changedRows.splice(changedRowIndex, 1);
                } else if (changedRowIndex < 0) {
                    changedRows.push(originalValue);
                }

                $scope.config.dirty = changedRows.length > 0;
            }

            /** Faz uma cópia do valor utilizado na linha da tabela a fim de manter o valor original para comparação */
            $scope.copyRow = (row) => angular.copy(row);

            if ($scope.selectedItems) {
                $scope.selectedItems.get = () => $scope.selection.checked;
            }
        }

        /**
         * Realiza a pesquisa em todos os campos da tabela
         */
        $scope.searchGlobalFilter = (filter: Object) => {
            angular.extend($scope.tableParams.filter(), {$: filter});
            $scope.tableParams.reload();
        };

        /**
         * Realiza pesquisa customizada na tabela
         */
        $scope.customFilter = (filter: object) => {
            angular.extend($scope.tableParams.filter(), filter);
            $scope.tableParams.reload();
        };

        /**
         * Realiza a sobrescrita caso seja passado o filtro, caso
         * contrário apaga todos os filtros. A ordenação é setado
         * para default.
         */
        $scope.clearFiltersCustom = (filter?: object) => {
            if (filter) {
                $scope.tableParams.filter(filter);
            } else {
                $scope.tableParams.filter({});
            }
            $scope.tableParams.sorting($scope.config.initialSort);
            $scope.tableParams.reload();
        };

        $scope.setSelectedItem = function(entry) {
            $scope.selection.checked = [entry];
        };
    }
]);