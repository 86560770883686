import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import {
    ANGULARJS_ROOTSCOPE,
    ANGULARJS_TRANSLATE,
    SNMP_DATA
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Subject } from "rxjs";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { ManagementProtocolModel } from "./management-protocols-model";
import { ManagementProtocolNotifier } from "./management-protocols-notifier.model";

declare let _: any;

/**
 * Componente responsável pelo formulário de credenciais.
 */
@Component({
    selector: "management-protocols",
    templateUrl: "./management-protocols.component.html",
    styleUrls: ["./management-protocols.component.css"]
})
export class ManagementProtocolsComponent implements OnDestroy {
    readonly RANGE_CLI_PORT = "22, 1024-65535";
    readonly RANGE_CLI_PORT_PATTERN =
        "^(22)$|^(102[4-9]|10[3-9][0-9]|1[1-9][0-9]{2}|[2-9][0-9]{3}|[1-5][0-9]{4}|" +
        "6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$";
    readonly RANGE_1_180 = "1-180s";
    readonly RANGE_1_180_PATTERN = "^([1-9]|[1-9][0-9]|1[0-7][0-9]|180)$";
    readonly RANGE_1_300 = "1-300s";
    readonly RANGE_1_300_PATTERN = "^([1-9]|[1-9][0-9]|[1-2][0-9][0-9]|300)$";
    readonly RANGE_1_5 = "1-5";
    readonly RANGE_1_5_PATTERN = "^[1-5]$";
    readonly GLOBAL: string = "global";
    readonly SPECIFIC: string = "specific";

    /** Representa o formulário com os dados das configurações globais e específicas(caso exista)*/
    @Input() formData: any;
    /** Objeto com os valores de todas as credenciais: (globais, específicas e credenciais antes da atualização)*/
    @Input() credentials: ManagementProtocolModel;
    /** Verifica se mais de uma credencial foi selecionada */
    @Input() isMultipleSelection: any;
    /** Representa o tipo da credencial a ser atualizada (global ou específica) */
    @Input() credentialsType: string;
    /** Verifica se a configuração global estará com os campos desabilitados */
    @Input() disableGlobal: boolean;
    /** Notifica o componente para executar a ação de salvar os dados */
    @Input() credentialsSaveNotifier: Subject<ManagementProtocolNotifier>;
    /** Notifica o componente para executar a ação de fechar a modal (ou página) */
    @Input() credentialsCloseNotifier: Subject<void>;

    passwordsVisibility: any;
    snmpVersion: any;
    snmpV3PassLength: any;
    authType: any;
    cypher: any;

    @ViewChild("form", { static: true })
    public credentialsForm: NgForm;

    constructor(
        @Inject(SNMP_DATA) private snmpDataConstants: any,
        @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
        @Inject(ANGULARJS_TRANSLATE) private $translate: any,
        private toastr: NmsToastrService
    ) {
        this.snmpVersion = snmpDataConstants.SNMP_VERSION;
        this.snmpV3PassLength = snmpDataConstants.V3_PASS_LENGTH;
        this.authType = snmpDataConstants.AUTH_TYPE;
        this.cypher = snmpDataConstants.CYPHER;
        this.credentialsSaveNotifier = new Subject();
    }

    /**
     * Verifica se ocorreu alterações nas credenciais
     * Caso negativo, a função realiza uma segunda validação para verificar se são idênticas
     * @param oldCredentials credenciais antes da atualização
     * @param config informa o tipo se é global ou específica
     * @param newCredentials credenciais alteradas
     */

    areThereChangesInCredentials(oldCredentials, credentialType, newCredentials) {
        let hasChanges = oldCredentials.userPassConfig !== credentialType || this.isMultipleSelection;

        if (!hasChanges) {
            hasChanges = !this.isCredentialsEqual(oldCredentials.credentials, newCredentials);
        }

        return hasChanges;
    }

    /**
     * Verifica se as credenciais são iguais
     * @param oldCredentials credenciais antigas
     * @param newCredentials credenciais alteradas
     */

    isCredentialsEqual(oldCredentials, newCredentials) {
        return _.isEqual(oldCredentials, newCredentials);
    }

    /**
     * Efetua a validação do formulário exibindo uma mensagem caso esteja inválido ou
     * exibindo uma mensagem informando que não houve alteração nas credenciais.
     * Caso esteja válido, notifica o componente para salvar as credenciais de acordo com o tipo
     * específco.
     */
    validateCredentials() {
        if (!this.credentialsForm.valid) {
            this.toastr.error(this.$translate.instant("toastr.error.invalidFields"));
            return false;
        }
        let currentProtocolsConfigurations = this.formData[this.credentialsType];
        if (
            this.areThereChangesInCredentials(
                this.credentials.oldCredentials,
                this.credentialsType,
                currentProtocolsConfigurations
            )
        ) {
            let credentials: ManagementProtocolNotifier = this.convertCredentialsToSave(currentProtocolsConfigurations);
            this.credentialsSaveNotifier.next(credentials);
        } else {
            this.$rootScope.showDialog({
                message: this.$translate.instant("modals.managementProtocols.credentials.noChangesInConfigs")
            });
        }
    }

    /**
     * Converte os dados do formulário para o modelo de dados a ser salvo no banco de dados.
     * @param currentProtocolsConfigurations: credenciais com os valores atualizados
     */
    convertCredentialsToSave(currentProtocolsConfigurations): ManagementProtocolNotifier {
        let specificCredentials = _.cloneDeep(this.credentials.specificCredentials);
        let globalCredentials = _.cloneDeep(this.credentials.globalCredentials);

        if (this.disableGlobal) {
            this.setProtocolManagement(specificCredentials, currentProtocolsConfigurations);
        } else {
            this.setProtocolManagement(globalCredentials, currentProtocolsConfigurations);
        }

        let credentials: ManagementProtocolNotifier = {
            globalCredentials: globalCredentials,
            specificCredentials: specificCredentials
        };

        return credentials;
    }

    /**
     * Seta as configurações da aba de credenciais e de timeout
     * @param credential
     * @param currentCredentials
     */
    setProtocolManagement(credential, currentCredentials) {
        this.setCredentials(credential, currentCredentials);
        this.setTimeout(credential, currentCredentials);
    }

    /**
     * Notifica o componente para fechar a modal ou página correspondente.
     */
    closeCredentials() {
        this.credentialsCloseNotifier.next();
    }

    private setCredentials(credential: any, currentCredentials: any) {
        credential.telnetCredential.username = currentCredentials.userCliOverTelnet;
        credential.telnetCredential.password = currentCredentials.passCliOverTelnet;
        credential.netconfCredential.username = currentCredentials.userNetConfOverSsh;
        credential.netconfCredential.password = currentCredentials.passNetConfOverSsh;
        credential.sftpCredential.password = currentCredentials.passSFTP;
        credential.httpCredential.username = currentCredentials.userHTTP;
        credential.httpCredential.password = currentCredentials.passHTTP;
        credential.pcgaCredential.pcgaUser = currentCredentials.userNMS;
        credential.pcgaCredential.pcgaPassword = currentCredentials.passNMS;
        credential.sshCredential.username = currentCredentials.userCliOverSsh;
        credential.sshCredential.password = currentCredentials.passCliOverSsh;
        credential.sshCredential.port = currentCredentials.portCliOverSsh;
        credential.snmpCredential.username = currentCredentials.userSNMPv3;
        credential.snmpCredential.userPassword = currentCredentials.passSNMPv3;
        credential.snmpCredential.passPhrase = currentCredentials.privacyPass;
        credential.snmpCredential.readCommunity =
            currentCredentials.version === this.snmpVersion.V1
                ? currentCredentials.readCommunitySnmpV1
                : currentCredentials.readCommunitySnmpV2C;
        credential.snmpCredential.writeCommunity =
            currentCredentials.version === this.snmpVersion.V1
                ? currentCredentials.writeCommunitySnmpV1
                : currentCredentials.writeCommunitySnmpV2C;
        credential.snmpCredential.version = currentCredentials.version;
        credential.snmpCredential.authType = currentCredentials.authType;
        credential.snmpCredential.cypher = currentCredentials.privacy ? currentCredentials.cypher : this.cypher.NONE;
        credential.snmpCredential.privacy = currentCredentials.privacy;
    }

    private setTimeout(credential: any, currentCredentials: any) {
        credential.netconfCredential.timeout = currentCredentials.timeoutNetConfOverSsh;
        credential.sshCredential.timeout = currentCredentials.timeoutCliOverSsh;
        credential.sshCredential.retries = currentCredentials.retriesCliOverSsh;
        credential.telnetCredential.timeout = currentCredentials.timeoutCliOverTelnet;
        credential.telnetCredential.retries = currentCredentials.retriesCliOverTelnet;
        credential.httpCredential.timeout = currentCredentials.timeoutHTTP;
        credential.httpCredential.retries = currentCredentials.retriesHTTP;
        credential.pcgaCredential.timeout = currentCredentials.timeoutNMS;
        credential.pcgaCredential.retries = currentCredentials.retriesNMS;
        credential.sftpCredential.timeout = currentCredentials.timeoutSFTP;
        credential.snmpCredential.timeout = currentCredentials.timeoutSnmp;
        credential.snmpCredential.retries = currentCredentials.retriesSnmp;
    }

    ngOnDestroy() {
        this.credentialsSaveNotifier.unsubscribe();
        this.credentialsCloseNotifier.unsubscribe();
    }
}
