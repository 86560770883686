"use strict";

var app = angular.module("AuthenticationModule");

app.factory("AuthenticationService", ["$rootScope", "$state", "AuthenticationRESTService", "LicenseService", "$window", "$http",
    "AUTH_EVENTS", "NMS_STATES", "FeatureAccessControl", "UserPreferencesService", "AUTHENTICATION_STATUS", "WindowService",
    function($rootScope, $state, authenticationRESTService, licenseService, $window, $http, AUTH_EVENTS, NMS_STATES,
        featureAccessControl, UserPreferencesService, AUTHENTICATION_STATUS, WindowService) {
        const service: any = {};
        var userSessionData = null;
        const modulesThatNeedMultiplePermissions = ["discoverAndAddDevices"];
        var accessStates = [NMS_STATES.login, NMS_STATES.namCredentials];

        function handleAuthenticatedUser(sessionData) {
            const alreadyAuthenticated = userSessionData !== null;

            if (alreadyAuthenticated) {
                return true;
            }

            userSessionData = sessionData;

            if (service.userHasAtLeastOnePermission()) {
                localStorage.setItem("username", sessionData.username);
                localStorage.setItem("isAuthenticated", "true");
                UserPreferencesService.setLoggedUser(sessionData.username);

                $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
                $rootScope.$broadcast(AUTH_EVENTS.startWebSocket);

                if (userSessionData.messages.length > 0) {
                    $rootScope.showDialog({
                        type: 'alert',
                        message: userSessionData.messages[0]
                    });
                }

                return true;
            } else {
                $rootScope.loginError = "login.error.noPermissionAtAll";
                $rootScope.doLogoff();

                return handleNotAuthenticatedUser();
            }
        }

        function handleNotAuthenticatedUser() {
            service.clearLoggedInformation();

            return false;
        }

        service.clearLoggedInformation = function() {
            userSessionData = null;
            localStorage.removeItem("username");
            localStorage.removeItem("isAuthenticated");
            $rootScope.credentials = {username: "", password: ""};
        };

        service.isAccessState = function(state) {
            return _.includes(accessStates, state.name);
        };

        service.login = function(credentials) {
            var loginSuccess = function() {
                service.checkUserAuthentication().then(function(authenticated) {
                    if (authenticated) {
                        $rootScope.loginError = null;
                        if ($state.params.redirectTo) {
                            $state.go($state.params.redirectTo, $state.params.redirectParams);
                        } else {
                            var firstAllowedFeature = service.getFirstAllowedFeature();
                            $state.go(firstAllowedFeature.state);
                        }
                    }
                });
            };

            var loginError = function(response) {
                $rootScope.$broadcast(AUTH_EVENTS.loginFailed, response);
            };

            authenticationRESTService.doLogin(credentials).then(loginSuccess, loginError);
        };

        service.checkUserAuthentication = function() {
            return service.getSessionData().then(function(sessionData) {
                if (sessionData.authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED) {
                    return handleAuthenticatedUser(sessionData);
                } else {
                    return handleNotAuthenticatedUser();
                }
            });
        };

        service.isAuthenticated = function() {
            if (service.getUserSessionData()) {
                return authenticationRESTService.isAuthenticated();
            }

            return service.checkUserAuthentication();
        };

        service.logoff = function() {
            sessionStorage.clear();
            return authenticationRESTService.doLogoff();
        };

        service.getCredentials = function() {
            var username = localStorage.getItem("username");

            return authenticationRESTService.getAuthorizations(username);
        };

        service.getUserSessionData = function() {
            return userSessionData;
        };

        service.hasPermission = function(module) {
            var hasPermission = false;
            var permissions = _.get(userSessionData, "permissions");

            if (permissions) {
                if (Array.isArray(module)) {
                    var useMultiplePermissions = _.some(module, function(mod) {
                        return _.includes(modulesThatNeedMultiplePermissions, mod);
                    });

                    if (useMultiplePermissions) {
                        hasPermission = _.every(module, function(permission) {
                            return _.includes(permissions, permission);
                        });
                    } else {
                        _.some(module, function(rule) {
                            if (_.contains(permissions, rule)) {
                                hasPermission = true;
                            }
                        });
                    }
                } else {
                    hasPermission = _.contains(permissions, module);
                }

                return hasPermission;
            } else {
                return false;
            }
        };

        service.getTemplateRestrictions = function() {
            return authenticationRESTService.getTemplateRestrictions().then(function(templatePermissionModel) {
                if (templatePermissionModel) {
                    return templatePermissionModel.plain();
                }
            });
        };

        service.getAllowedFeatures = function() {
            var filterFeatures = function(features, attribute, predicate) {
                return _.pick(features, function(element) {
                    return predicate(element[attribute]);
                });
            };

            var allFeatures = featureAccessControl.getAllFeatures();
            var featuresAllowedByPermissions = filterFeatures(allFeatures, "requiredPermission", service.hasPermission);
            var featuresAllowedByLicense = filterFeatures(allFeatures, "requiredLicense", licenseService.hasLicense);

            return _.intersection(_.keys(featuresAllowedByPermissions), _.keys(featuresAllowedByLicense));
        };

        service.getFirstAllowedFeature = function() {
            var firstAllowedFeature = _.first(service.getAllowedFeatures());

            return featureAccessControl.getFeature(firstAllowedFeature);
        };

        service.userHasAtLeastOnePermission = function() {
            return (service.getAllowedFeatures().length > 0);
        };

        service.getNextCaptcha = function() {
            return authenticationRESTService.getNextCaptcha();
        };

        service.getSessionData = function() {
            return authenticationRESTService.getSessionData();
        };

        service.namServiceEnable = function() {
            return authenticationRESTService.namServiceEnable();
        };

        /**
        * Verifica se usuário possui permissão para uma rota específica.
        * Caso não haja regra de acesso (objeto routeAccessRules) para a rota especificada (objeto route),
        * considera-se que a rota não possui restrições de permissão específicas, retornando verdadeiro.
        *
        * @param {string} nome da rota
        * @param {object} objeto com as definições de permissão específicas para as rotas de uma feature
        * @return {boolean} true se o usuário tiver permissão, false caso contrário
        */
        service.hasRoutePermission = function(route, routesAccessRules) {
            if (routesAccessRules && routesAccessRules[route]) {
                return service.hasPermission(routesAccessRules[route]);
            }

            return true;
        };

        return service;
    }
]);
