var app = angular.module("nms.management", ["timer"]);

app.constant("TEST_CONNECTION_STATES",
    {
        NOT_STARTED: {},
        STARTED: {
            iconClass: "fa fa-spinner fa-spin fa-pulse loading-icon pull-left",
            messageClass: "pull-left loading-status-label",
            messageKey: "monitoring.configuration.test.testing"
        },
        CONNECTION_OK: {
            iconClass: "glyphicon glyphicon-ok-sign success-icon pull-left",
            messageClass: "pull-left success-status-label",
            messageKey: "monitoring.configuration.test.success"
        },
        CONNECTION_FAIL: {
            iconClass: "glyphicon glyphicon-ok-sign fail-icon pull-left",
            messageClass: "pull-left fail-status-label",
            messageKey: "monitoring.configuration.test.fail"
        },
        CONNECTION_FAIL_DUE_INVALID_CREDENTIALS: {
            iconClass: "glyphicon glyphicon-ok-sign fail-icon pull-left",
            messageClass: "pull-left fail-status-label",
            messageKey: "monitoring.configuration.test.failDueInvalidCredentials"
        }
    }
);

app.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("features/management/monitoring");
    $translatePartialLoader.addPart("features/management/users");
    $translatePartialLoader.addPart("features/management/groups");
    $translatePartialLoader.addPart("features/management/permissions");
    $translatePartialLoader.addPart("features/management/protocols");
}]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);

// TODO: Considerar mover esta responsabilidade para o authentication-module#$stateChangeStart onde nós resolvemos todas as
// dependências que precisam estar no rootScope.
app.run(["$rootScope", "NMS_FEATURES", "AuthenticationService", "AUTH_EVENTS",
    function($rootScope, NMS_FEATURES, AuthenticationService, AUTH_EVENTS) {
        var unregisterLoginSuccess= $rootScope.$on(AUTH_EVENTS.loginSuccess, function() {
            var unregisterIsAdmin = $rootScope.$watch(
                function() {
                    return AuthenticationService.hasPermission(NMS_FEATURES.administratorPrivs.feature);
                },
                function(newValue) {
                    $rootScope.isAdmin = newValue;
                }
            );

            $rootScope.$on("$destroy", function() {
                unregisterIsAdmin();
            });
        });

        $rootScope.$on("$destroy", function() {
            unregisterLoginSuccess();
        });
    }
]);
