/**
 * Serviço para gerenciar a seleção dos nodos na árvore.
 */
var app = angular.module("nms.dynamicDevice");

app.service("TreeService", ["DomainHandlerService", "NodeInfoCache", "YangStatements",
    function(DomainHandlerService, NodeInfoCache, YangStatements) {
        var isChoice = function(schemaNode) {
            var parentXmlPath = DomainHandlerService.getPath(schemaNode);

            return parentXmlPath && NodeInfoCache.getNode(parentXmlPath).yangType === YangStatements.CHOICES;
        };

        var isSelectedCase = function(caseSchemaNode, pathKeys) {
            var caseDataNode = DomainHandlerService.getDataNodeForSchemaNode(caseSchemaNode, pathKeys);

            return caseDataNode && caseDataNode.id === caseSchemaNode.id;
        };

        var existsInTreeRecursive = function(schemaNode, pathKeys) {
            if (!schemaNode) {
                return false;
            } else if (schemaNode.paths.schemaJsonPath === "$" || schemaNode.parentPaths.schemaJsonPath === "$") {
                return true;
            } else {
                var parentSchemaNode = DomainHandlerService.getSchemaNode(schemaNode.parentPaths.schemaJsonPath);
                var parentExists = existsInTreeRecursive(parentSchemaNode, pathKeys);
                if (!parentExists) {
                    return false;
                }
                if (isChoice(parentSchemaNode)) {
                    return isSelectedCase(schemaNode, pathKeys);
                }
                return true;
            }
        };

        this.existsInTree = function(path, pathKeys) {
            var schemaNode = DomainHandlerService.getSchemaNode(path);

            return existsInTreeRecursive(schemaNode, pathKeys);
        };
    }
]);
