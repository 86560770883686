/**
 * Restrict a leaf text based on one or more regular expressions (yang-pattern).
 *
 * @author patrick.bard
 */
var app = angular.module("nms.dynamicDevice");

/**
 * Verifies if value is valid to all patterns defined to a given leaf.
 */
app.directive("patternRestriction", ["PatternRestrictionService", "RestrictionService",
    function(PatternRestrictionService, RestrictionService) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                var validatorName = RestrictionService.getLeafTypeValidatorName(scope.leaf.type,
                    PatternRestrictionService.RESTRICTION_NAME);
                ngModel.$validators[validatorName] = function(modelValue, viewValue) {
                    return PatternRestrictionService.validate(modelValue, viewValue, scope.leaf.type);
                };
            }
        };
    }
]);
