/**
* @ngDoc directive
* @name alarm
* @description Transforms an alarm severity leaf into an alarm element and adds a class using the
*   severity plus the prefix _FAULT .
*/
var app = angular.module("nms.dynamicDevice");

app.directive("alarm", ["$compile", "LabelColorService",
    function($compile, LabelColorService) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/custom/alarm.html",
            compile: function compile() {
                return {
                    pre: function preLink(scope, iElement) {
                        var severityClass = scope.dataNode.value + "_FAULT";
                        var div = iElement.find("div");

                        div.addClass(severityClass);

                        var backgroundColor = div.css("backgroundColor");
                        var labelColorClass = LabelColorService.getBestColorContrastClassForForeground(backgroundColor);

                        div.addClass(labelColorClass);
                    }
                };
            }
        };
    }
]);
