"use strict";

var app = angular.module("nms.templates");

app.controller("TemplateInstanceStatusCtrl", ["$scope", "$translate", "APPLICATION_STATUS",
    function($scope, $translate, APPLICATION_STATUS) {
        $scope.status = [APPLICATION_STATUS.APPLYING, APPLICATION_STATUS.FULLY_APPLIED, APPLICATION_STATUS.PARTIALLY_APPLIED,
            APPLICATION_STATUS.NOT_APPLIED, APPLICATION_STATUS.NOT_ORDERED];
        $scope.statusApplication = $scope.data ? $scope.data.getResume() : [];
        $scope.equipmentsCount = $scope.data ? $scope.data.getEquipmentsCount() : 0;
        $scope.showStatus = $scope.data ? $scope.data.showStatus() : false;
        $scope.translationKeys = $scope.getSpecificTranslationKeys();

        $scope.getEquipmentsCountLabel = function() {
            var count = $translate.instant($scope.translationKeys.equipmentCount) + $scope.equipmentsCount;

            return $scope.showStatus ? $translate.instant($scope.translationKeys.statusByEquipmentLabel)
                        + " (" + count + ")" : count;
        };

        $scope.getStatusLabel = function(status) {
            var key;

            switch (status) {
                case APPLICATION_STATUS.APPLYING:
                    key = "templateinstanceform.viewapply.templateStatus.applying";
                    break;
                case APPLICATION_STATUS.FULLY_APPLIED:
                    key = "templateinstanceform.viewapply.templateStatus.success";
                    break;
                case APPLICATION_STATUS.PARTIALLY_APPLIED:
                    key = "templateinstanceform.viewapply.templateStatus.partial";
                    break;
                case APPLICATION_STATUS.NOT_APPLIED:
                    key = "templateinstanceform.viewapply.templateStatus.fail";
                    break;
                default:
                    key = "templateinstanceform.viewapply.templateStatus.notRequested";
                    break;
            }

            return $translate.instant(key);
        };

        $scope.getStatusOccurences = function(status) {
            return $scope.statusApplication[status] ? $scope.statusApplication[status]: 0;
        };

        $scope.getGeneralStatusLabel = function() {
            return $scope.getStatusLabel($scope.getGeneralStatusClass());
        };

        $scope.getGeneralStatusClass = function() {
            if ($scope.statusApplication[APPLICATION_STATUS.APPLYING]) {
                return APPLICATION_STATUS.APPLYING;
            } else if ($scope.statusApplication[APPLICATION_STATUS.PARTIALLY_APPLIED]
                || ($scope.statusApplication[APPLICATION_STATUS.FULLY_APPLIED]
                    && $scope.statusApplication[APPLICATION_STATUS.NOT_APPLIED])) {
                return APPLICATION_STATUS.PARTIALLY_APPLIED;
            } else if ($scope.statusApplication[APPLICATION_STATUS.FULLY_APPLIED]) {
                return APPLICATION_STATUS.FULLY_APPLIED;
            } else if ($scope.statusApplication[APPLICATION_STATUS.NOT_APPLIED]) {
                return APPLICATION_STATUS.NOT_APPLIED;
            }

            return APPLICATION_STATUS.NOT_ORDERED;
        };
    }
]);
