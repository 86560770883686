"use strict";

/**
 * Modelo para os parâmetros passados pela URL para tabela de Device
 */
export interface DeviceUrlFilterParameter {
    operatorType?: "AND" | "OR";
    name?: string;
    exhibitionName?: string;
    hostname?: string;
    formattedHostname?: string;
    model?: string;
    serialNumber?: string;
    fwVersion?: string;
    path?: string;
    status?: string;
    translatedStatus?: string;
    lastUpdate?: string;
    cpuUsage?: string;
    memoryUsageAbsolute?: string;
    [key: string]: string
}

var app = angular.module("nms.manageDevices");

app.controller("ManageDevicesController", ["$rootScope", "$scope", "$location", "monitoringSettings", "RefreshIntervalService",
    function($rootScope, $scope, $location, monitoringSettings, RefreshIntervalService) {
        $scope.monitoringSettings = monitoringSettings;
        $scope.queryParameters = $location.search() as DeviceUrlFilterParameter;

        $scope.devicesListOwner = {
            autoUpdate: true
        };

        RefreshIntervalService.getRefreshInterval((interval: number) => $scope.autoUpdateInterval = interval);

        var unregisterLoadUpdaterWatch = $rootScope.$watch("loadUpdater", function(newValue, oldValue) {
            if (newValue === true) {
                $scope.showUpdater = true;
            }
        });

        $scope.$on("$destroy", function() {
            unregisterLoadUpdaterWatch();
        });
    }
]);
