"use strict";

/**
* @ngdoc Controller
* @name nms.management.ManageGroupModalController
* @description Realiza gerência de um grupo.
*/
var app = angular.module("nms.management");

app.controller("ManageGroupModalController", ["$rootScope", "$scope", "$translate", "groupNamesAlreadyInUse",
    "FormValidatorService", "ManageGroupsService", "groupData", "PermissionsValidatorService", "rulesMatchingModes",
    "ruleRestrictionTypes",
    function($rootScope, $scope, $translate, groupNamesAlreadyInUse, FormValidatorService, ManageGroupsService, groupData,
        PermissionsValidatorService, rulesMatchingModes, ruleRestrictionTypes) {
        var isEdit = !_.isNull(groupData);
        $scope.groupNamesAlreadyInUse = groupNamesAlreadyInUse;

        /*
         * Armazena as informações para posterior validação e conversão para o modelo de comunicação com o back-end.
         */
        $scope.form = {
            group: {
                idPreffix: "group-modal-basic",
                title: $translate.instant("manage.group.basic"),
                fieldsTranslatePreffix: "manage.group.form.basic.",
                data: {}
            },
            features: {
                idPreffix: "group-modal-features",
                title: $translate.instant("manage.permissions.features"),
                data: {}
            },
            templates: {
                idPreffix: "group-modal-templates",
                title: $translate.instant("manage.permissions.templates"),
                data: {}
            },
            devices: {
                idPreffix: "group-modal-devices",
                title: $translate.instant("manage.permissions.devices"),
                data: {}
            },
            locations: {
                idPreffix: "group-modal-locations",
                title: $translate.instant("manage.permissions.locations"),
                data: {}
            },
            securitySettings: {
                data: {}
            }
        };

        /*
         * Modelos para comunicação com as diretivas de tabs.
         */
        $scope.groupOwner = {
            model: $scope.form.group.data
        };
        $scope.featuresOwner = {
            model: $scope.form.features.data
        };
        $scope.templatesOwner = {
            model: $scope.form.templates.data,
            rulesMatchingModes: rulesMatchingModes,
            ruleRestrictionTypes: ruleRestrictionTypes
        };
        $scope.devicesOwner = {
            model: $scope.form.devices.data
        };
        $scope.locationsOwner = {
            model: $scope.form.locations.data
        };

        /*
         * Tabelas renderizadas dentro das tabs não ativas precisam disparar o evento "tableColumnsHasChange" para recalcular o
         * redimensionamento de colunas quando ativadas.
         * Para tanto, basta incluir esta função no evento de "select" do "uib-tab" no html.
         */
        $scope.fireTableChanges = function() {
            $rootScope.$broadcast("tableColumnsHasChange", true);
        };

        /**
        * Método que verifica se o checkbox para restringir a aplicação de templates esta desmarcado, porém a lista de regras
        * esta preenchida.
        * @return {boolean} false se a condição for verdadeira, true caso contrário.
        */
        var checkValidateRestrictions = function(form) {
            if (PermissionsValidatorService.isCheckDisabledAndRestrictionsNotEmpty($scope.form.templates.data)) {
                showDataLossConfirmationModal();
                return false;
            }
            return true;
        };

        /*
         * Salva o grupo invocando o serviço de edição ou inserção do mesmo. Se a operação finalizar com sucesso, fecha a modal
         * e exibe mensagem de sucesso para o grupo.
         */
        var saveGroup = function() {
            var saveCallback = function(group) {
                $scope.confirm(group);
                $rootScope.toastInfo("manage.group.modal.finish.success", [group.name]);
            };

            if (isEdit) {
                ManageGroupsService.editGroup($scope.form).then(saveCallback);
            } else {
                ManageGroupsService.createGroup($scope.form).then(saveCallback);
            }
        };

        $scope.validateAndSaveGroup = function() {
            var isValid = checkValidateRestrictions($scope.form)
                && FormValidatorService.validate($scope.manageGroupForm, $scope.form)
                && PermissionsValidatorService.validate($scope.form);

            if (isValid) {
                saveGroup();
            }
        };


        /**
         Exibe modal de confirmação para informar que a lista de restrições será perdida
        */
        var showDataLossConfirmationModal = function() {
            $rootScope.showDialog({
                translateKey: "manage.permissions.templates.disable.check.restrictions",
                isConfirm: true
            }).then(function() {
                saveGroup();
            });
        };

        /**
         * Prepara os dados para edição, incluindo os dados de grupo recebidos nos modelos de comunicação de cada uma das
         * diretivas de tabs.
         */
        var prepareDataForEdition = function(groupData) {
            _.defaults($scope.groupOwner.model, {
                name: groupData.name,
                oldName: groupData.name,
                description: groupData.description
            });
            $scope.featuresOwner.model.editData = groupData.permissionsModel.personalPermissionModels;
            $scope.devicesOwner.model.editData = groupData.permissionsModel.devicePermissionModels;
            $scope.locationsOwner.model.editData = groupData.permissionsModel.locationPermissionModels;
            $scope.templatesOwner.model.editData = groupData.permissionsModel.templatePermissionModel;
            // Apenas mantem os dados de userSecuritySettings para enviar para edição, mas não permite que o usuário faça
            // alterações.
            $scope.form.securitySettings.data = groupData.userSecuritySettings;
        };

        var init = function() {
            if (isEdit) {
                $scope.modalTitle = $translate.instant("manage.group.modal.edit.title");
                prepareDataForEdition(groupData);
            } else {
                $scope.modalTitle = $translate.instant("manage.group.modal.add.title");
            }
        };

        init();
    }
]);
