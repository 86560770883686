import { Injectable } from "@angular/core";
import { FreemarkerExpressionService } from "../services/freemarker-expression.service";
import { PythonExpressionService } from "../services/python-expression.service";
import { TemplateTR069Component } from "./template-tr069.component";
import { TemplateCliComponent } from "./template-cli.component";
import { TemplateInterface, TemplateType } from "./template.interface";

/**
 * Classe responsável por selecionar a implementação correta de TemplateInstance
 * a partir do tipo do template.
 */
@Injectable({
  providedIn: "root"
})
export class TemplateComponentResolverService {
  private templateCpeComponent: TemplateTR069Component;
  private templateDeviceComponent: TemplateCliComponent;

  constructor(private pythonExpressionsService: PythonExpressionService,
    private freemarkerExpressionsService: FreemarkerExpressionService) {
    this.templateCpeComponent = new TemplateTR069Component(pythonExpressionsService);
    this.templateDeviceComponent = new TemplateCliComponent(freemarkerExpressionsService);
  }

  /**
   * Escolhe a implementação correta de TemplateInterface a partir do tipo de template.
   * @param templateType tipo do template a ser utilizado. Pode assumir os valores "CLI" ou "TR069".
   * @returns Implementação escolhida a partir do templateType.
   */
  resolve(templateType: { value: TemplateType }): TemplateInterface {
    if (templateType.value === "TR_069") {
      return this.templateCpeComponent;
    }

    return this.templateDeviceComponent;
  }
}
