import { HttpParameterCodec } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Encoder utilizado nos parâmetros das requisições na qual deseja-se manter o uso dos caracteres especiais.
 * Esses são os caracteres nos quais é necessário utilizar o encode abaixo  + @ : $ , ; ? \'
 * Ou seja na url da requisição ao inserir alguma informação com caracter especial, por default, o sistema
 * preenche-o com 'espaço em branco', para que isso não aconteça, esse encoder é necessário.
 */
@Injectable()
export class NmsHttpParamEncoder implements HttpParameterCodec {

    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}