"use strict";

/**
* @ngdoc service
* @name nms.templates.ReadOnlyVariableValidatorService
* @description
* # ReadOnlyVariableValidatorService
* Service in the nms.templates.
*/
var app = angular.module("nms.templates");
app.service("ReadOnlyVariableValidatorService", ["$rootScope", "ValidatorService", "VARIABLES",
    function($rootScope, ValidatorService, VARIABLES) {
        var self = this;

        self.validateReadOnlyVariable = function(checkedVariable) {
            if (_.isEqual(checkedVariable.includeAndBlockOptionMode, VARIABLES.includeAndBlockOptionsModes.INCLUDE_AND_BLOCK)) {
                $rootScope.showDialog({
                    translateKey: "addVariableModal.error.invalidSelectedBlockingOptionForReadOnly"
                });
                return false;
            }

            if (!_.isEqual(checkedVariable.includeAndBlockOptionMode, VARIABLES.includeAndBlockOptionsModes.INCLUDE)) {
                $rootScope.showDialog({
                    translateKey: "addVariableModal.error.invalidSelectedIncludingAndBlockingOptionForReadOnly"
                });
                return false;
            }

            return true;
        };
    }
]);
