import { Injectable, Inject } from "@angular/core";
import cronstrue from "cronstrue";
import { CronTriggerData } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { TriggersService } from "@nms-ng2/app/modules/scheduler/triggers/triggers.service";

/**
 * Classe utilitária para funções relacionadas ao disparador de Cron.
 */
@Injectable({
    providedIn: "root"
})
export class CronTriggerService {

    /**
     * Constantes necessárias para traduzir a expressão cron em linguagem natural, devido ao uso da lib cronstrue.
     */
    private readonly LOCALE_PT_BR_ICU = "pt_BR";
    private readonly LOCALE_EN = "en";

    constructor(
        @Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
        private readonly triggersService: TriggersService
    ) {}

    translateCronExpression(triggerData: CronTriggerData): string {
        const locale = this.triggersService.isPtBr(this.translate.use())
            ? this.LOCALE_PT_BR_ICU
            : this.LOCALE_EN;
        return cronstrue.toString(triggerData.cronExpression.toString(), {
            use24HourTimeFormat: true,
            locale
        });
    }

    getDateTimeDescription(dateTime: Date): string {
        return this.triggersService.formatDateDescription(dateTime);
    }

}
