"use strict";

/**
 * Filter that convert a given array in a string.
 */

var app = angular.module("nms");

app.filter("arrayToString",
    function() {
        return function(input, shouldBreakLine) {
            if (angular.isDefined(input) && input !== null) {
                if (shouldBreakLine) {
                    return input.join("<br>");
                }
                return input.join(", ");
            }
        };
    }
);
