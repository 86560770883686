"use strict";

/**
 * Directive to calculate status bar.
 */
var app = angular.module("nms");

app.directive("statusBar", function() {
    return {
        restrict: "E",
        replace: true,
        scope: {
            value: "=",
            lowValueLimit: "=",
            highValueBase: "=",
            url: "="
        },
        templateUrl: "templates/components/ui/status-bar/status-bar.html",
        link: function(scope) {
            var value = parseInt(scope.value);
            var calculateBarType = function() {
                if (value < scope.lowValueLimit) {
                    scope.type = "success";
                } else if (value >= scope.lowValueLimit && value < scope.highValueBase) {
                    scope.type = "warning";
                } else {
                    scope.type = "danger";
                }
            };

            scope.$watch("value", calculateBarType);
        }
    };
});
