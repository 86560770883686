"use strict";

/**
* @ngdoc directive
* @name nms.directive:onlyNumbers
* @description Diretiva para permitir apenas números em inputs
*/
// TODO - Efetuar rename de forma que não fique vinculada a números e assim torná-la genérica.
angular.module("nms")
.directive("onlyNumbers", ["OnlyNumbersService", "$timeout", function(OnlyNumbersService, $timeout) {
    return {
        restrict: "A",
        link: function(scope, elem, attrs) {
            $timeout(function() {
                $(elem).on("keypress", function(event) {
                    if (!OnlyNumbersService.validate(elem, event, attrs)) {
                        event.preventDefault();
                    }
                });
            });

            scope.$on("$destroy", function() {
                elem.off();
            });
        }
    };
}]);

