/**
 * @author patrick.bard
 */
var app = angular.module("nms.dynamicDevice");

app.controller("ListBodyController", ["$rootScope", "$scope", "ContentManager", "DomainHandlerService", "$timeout", "ListService",
    "$translate", "SessionPreferencesService", "NgTableParams", "$filter", "BROADCAST_EVENTS", "TABLE_PROPERTIES",
    "YangStatements", "TableFilterService", "TABLE_TEMPLATE_URL", "ngDialog", "DataPathService", "TableCellService",
    "LeafService", "NodeStatusService", "WhenVerifierService",
    function($rootScope, $scope, ContentManager, DomainHandlerService, $timeout, ListService, $translate,
            sessionPreferencesService, NgTableParams, $filter, BROADCAST_EVENTS, TABLE_PROPERTIES, YangStatements,
            tableFilterService, TABLE_TEMPLATE_URL, ngDialog, DataPathService, TableCellService,
            LeafService, NodeStatusService, WhenVerifierService) {
        var unregisterFunctions = [];
        $scope.filters = sessionPreferencesService.getFilters();
        $scope.listPath = DomainHandlerService.listPath($scope.parentPath, $scope.list.id);
        $scope.keys = ListService.getKeyNames($scope.list);
        $scope.emptyKeys = $scope.keys.length === 0;
        $scope.nonConfigurableChildren = ListService.getNonConfigurableChildren($scope.list);
        $scope.configurableChildren = ListService.getConfigurableChildren($scope.list);
        $scope.readOnly = $rootScope.readOnly;
        $scope.showingNewEntry = false;
        $scope.listEntriesPath = DomainHandlerService.listEntriesPath($scope.listPath);
        var pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.list);
        loadData();
        $scope.selectAllCheckboxId = "SelectAllFor" + $scope.list.id;
        $scope.templateHeaderUrl = TABLE_TEMPLATE_URL.INFO_CONFIG.TABLE_HEADER;
        $scope.parentPath = $scope.list.parentPaths.schemaJsonPath;
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.list);
        $scope.isNodeVisible = !hasWhenCondition;

        var acceptedStatementsForMultipleEdit = [YangStatements.LEAFLISTS, YangStatements.CHOICES, YangStatements.LEAVES];

        var newEntryValidators = [];
        var REPLACE_COMMA_REGEX = "/\,/g,' '";
        var isDatasetOutOfDate = true;
        var timer;
        $scope.fieldSearchStatement = {
            textField: "search-text-field-{1}"
        };

        this.registerNewEntryValidator = function(validator) {
            newEntryValidators.push(validator);
        };

        this.getNewEntry = function() {
            return $scope.entry;
        };

        var reloadData = function() {
            loadData();
            $scope.reloadTable();
        };

        var unregister = $rootScope.$on("reloadContentNodeOf=" + pathWithKeys, function() {
            reloadData();
        });
        unregisterFunctions.push(unregister);

        if (hasWhenCondition) {
            var unregisterNodeStatusListener = NodeStatusService.register(
                $scope.list.paths.schemaJsonPath,
                $scope.list.paths.dataJsonPath,
                $scope.pathKeys,
                function(nodeStatus, refreshData) {
                    if (refreshData) {
                        reloadData();
                    }
                    $scope.isNodeVisible = nodeStatus.isVisible;
                });

            unregisterFunctions.push(unregisterNodeStatusListener);
        }

        function loadData() {
            $scope.entries = _.get(DomainHandlerService.getDataNode(pathWithKeys), "entries", []);
        }

        var refreshTree = function() {
            $rootScope.$broadcast("reloadTreeNodeOf=" + pathWithKeys);
            $rootScope.$broadcast("dataNodeChanged", {pathKeys: $scope.pathKeys, node: $scope.list});
        };

        var confirmRemoveEntries = function(rowsToRemove, translateKey, params?) {
            _.each(rowsToRemove, function(row) {
                row.toBeRemoved = true;
            });

            $rootScope.showDialog({
                translateKey: translateKey,
                params: params,
                paramsInsideMessage: true,
                isConfirm: true
            }).then(function() {
                if ($scope.entries.length === 1) {
                    DomainHandlerService.deleteDataNodeByJsonPath(pathWithKeys);
                } else {
                    var listEntriesToRemove = _.map(rowsToRemove, "entry");
                    _.each(listEntriesToRemove, function(item) {
                        var listEntryJsonPath = DataPathService.getEntryPath($scope.pathKeys, $scope.list, item);
                        DomainHandlerService.deleteDataNodeByJsonPath(listEntryJsonPath);
                    });
                }

                loadData();
                $rootScope.$broadcast("CONTENT_MODIFIED", true);
                $scope.selection.checked = [];
                $scope.reloadTable();
                refreshTree();
                $rootScope.$broadcast("listDataNodeChanged");
            }).catch(function() {
                _.each(rowsToRemove, function(row) {
                    row.toBeRemoved = false;
                });
            });
        };

        $scope.getKeyValuesAsString = function(entry) {
            return ListService.getKeyValuesAsString(entry.leaves, $scope.keys);
        };

        $scope.getFieldSearchStatementForNodeId = function(nodeId) {
            return $scope.fieldSearchStatement.textField.replace("{1}", nodeId);
        };

        var isValidEntry = function(entry) {
            // TODO: [US53667][DM4610][CONFIG][SETH] Validar conteudo dos campos conforme as restrições
            var keysAndLeaves = _($scope.keys).concat($scope.configurableChildren.leaves);

            return _.every(newEntryValidators, function(validator) {
                return validator($scope.list, keysAndLeaves.value(), $scope.entries, entry);
            });
        };

        var includeEntry = function(entry) {
            var newEntry: any = {
                leaves: _.chain($scope.keys)
                    .map(function(key) {
                        return angular.copy(entry.leaves[key]);
                    })
                    .reduce(function(keyLeaves, keyLeaf) {
                        keyLeaves[keyLeaf.id] = _.pick(keyLeaf, ["id", "value"]);
                        return keyLeaves;
                    }, {})
                    .value()
            };
            var newEntryJsonPath = DataPathService.getEntryPathByIndex(
                $scope.pathKeys, $scope.list, $scope.entries.length);
            DomainHandlerService.addOrSetDataNode(newEntryJsonPath, newEntry);

            var newEntryPathKeys = DataPathService.getEntryPathKeys($scope.pathKeys, $scope.list, newEntry);
            _.forOwn(_.get(entry, "leaves", {}), function(leaf) {
                if (leaf.value !== null && angular.isDefined(leaf.value)) {
                    var nodePath = DataPathService.getNodePath(newEntryPathKeys, leaf);
                    DomainHandlerService.addOrSetDataNode(nodePath + ".value", leaf.value);
                }
            });
            _.forOwn(_.get(entry, "leaf-lists", {}), function(leaflist) {
                var nodePath = DataPathService.getNodePath(newEntryPathKeys, leaflist);
                if (leaflist.values !== null && angular.isDefined(leaflist.values)) {
                    DomainHandlerService.addOrSetDataNode(nodePath + ".values", leaflist.values);
                }
            });
            _.forOwn(_.get(entry, "choices", {}), function(choice) {
                if (_.get(choice.case, "id")) {
                    var casePathWithKeys = DataPathService.getNodePath(newEntryPathKeys, choice) + ".case";
                    DomainHandlerService.addOrSetDataNode(casePathWithKeys, {id: choice.case.id});
                }
            });

            loadData();
            refreshTree();
        };

        $scope.addAndEditEntry = function(entry) {
            if (isValidEntry(entry)) {
                includeEntry(entry);
                var index = $scope.entries.length - 1;
                var listPath = $filter("getPath")($scope.list);
                $scope.editEntry(entry, listPath, index);
            }
        };

        $scope.addEntry = function(entry) {
            if (isValidEntry(entry)) {
                includeEntry(entry);

                $scope.showingNewEntry = false;
                $scope.reloadTable();
                $scope.$broadcast(BROADCAST_EVENTS.TABLE_PAGINATION.CHANGE_PAGE_TO,
                    Math.max(Math.ceil($scope.tableParams.total() / $scope.tableParams.count()), 1));
                $rootScope.$broadcast("listDataNodeChanged");
                $rootScope.$broadcast("CONTENT_MODIFIED", true);
            }
        };

        $scope.discardChanges = function() {
            $scope.entry = {};
            $scope.showingNewEntry = false;
        };

        var fillValuesWithDefaultWhenNull = function(leaves) {
            _.forEach(leaves, function(leaf) {
                var baseType = DomainHandlerService.getBaseType(leaf.type);
                if (leaf && leaf["sub-statements"] && leaf["sub-statements"].default
                    && leaf.value == null && baseType.name !== "leafref") {
                    leaf.value = leaf["sub-statements"].default;
                }
            });
        };

        // FIXME: A princípio foi criada uma solução paliativa para que os campos com a propriedade
        // isConfiguration = false não sejam enviados ao equipamento
        // No bugzilla http://bugzilla.datacom/show_bug.cgi?id=169364, será mapeado uma solução para definir
        // melhor esse comportamento.
        // Esse problema estava ocorrendo para 'leaves' que possuiam status com valor default
        // (Ex: ao criar uma vlan em dot1q)
        // ou leaf-list com status e array vazio (Ex: Ao criar um router/pbr).
        const isConfiguration = (entry: any): string => {
            return entry["sub-statements"]["configuration"];
        };

        $scope.createNewEntry = function() {
            $scope.entry = angular.copy($scope.list.template);
            $scope.entry.leaves = _.omit($scope.entry.leaves, (leaf) => {
                return $filter("isHidden")(leaf) || isConfiguration(leaf) === "false";
            });
            $scope.entry["leaf-lists"] = _.omit($scope.entry["leaf-lists"], (leafList) => {
                return isConfiguration(leafList) === "false";
            });

            fillValuesWithDefaultWhenNull($scope.entry.leaves);
            $scope.showingNewEntry = true;
            $rootScope.$broadcast("CONTENT_MODIFIED", true);
        };

        $scope.entryPathKeysFunc = function(entry, index) {
            return _.curry(DataPathService.getEntryPathKeys)($scope.pathKeys, $scope.list, entry, index);
        };

        $scope.editEntry = function(entry, canonicalPath, entryIndex) {
            var keys = $scope.list["sub-statements"].key;
            var content: any = {
                node: $scope.list.template,
                path: $scope.list.template.paths.schemaJsonPath,
                canonicalPath: canonicalPath,
                keys: keys,
                pathKeys: $scope.entryPathKeysFunc(entry, entryIndex),
                refreshTree: true
            };
            ContentManager.setContent(content);
        };

        $scope.setContent = function(node, canonicalPath, entry, entryIndex) {
            var content: any = {
                node: node,
                path: node.paths.schemaJsonPath,
                refreshTree: true,
                canonicalPath: canonicalPath,
                pathKeys: $scope.entryPathKeysFunc(entry, entryIndex)
            };
            ContentManager.setContent(content);
        };

        $scope.editEntries = function() {
            if (isSingleEditing()) {
                singleEdit();
            } else {
                isMultipleEditingAllowed(multipleEdit);
            }
        };

        $scope.removeSelectedEntries = function() {
            confirmRemoveEntries(_.get($scope, "selection.checked"), "yang.list.entriesLost");
        };

        $scope.removeEntry = function(row, keys) {
            var params = [];
            var keysValues = _.map(keys, function(key) {
                return row.entry.leaves[key].value;
            }).join(" ");

            params.push(keysValues);
            confirmRemoveEntries([row], "yang.list.changesLost", params);
        };

        $scope.disableRemove = function() {
            return _.get($scope, "selection.checked.length") === 0;
        };

        $scope.maxTableColumns = ListService.maxTableColumns($scope.list, $scope.filters, $scope.config);

        var isContainerNodeVisible = function(node) {
            return $filter("hasNonContainerChildrenToShow")(node) && $filter("showNode")(node, null, true);
        };

        var isNotHidden = function(node) {
            return !$filter("isHidden")(node);
        };

        var isKey = function(options) {
            return _.get(options, "isKey", false);
        };

        $scope.isContainerVisible = function(containerId) {
            var node = _.get($scope.list, "template." + YangStatements.CONTAINERS + "." + containerId);
            return isContainerNodeVisible(node);
        };

        $scope.initTable = function() {
            $scope.columns = buildColumns();
            $scope.dataset = buildDataset();

            $scope.tableSettings = {
                dataset: $scope.dataset,
                filterOptions: {filterFn: tableFilterService.getFilter($scope.columns)}
            };

            $scope.tableParams = new NgTableParams(
                {
                    page: 1,
                    count: TABLE_PROPERTIES.DEFAULT_PAGE_SIZE
                },
                $scope.tableSettings
            );

            $scope.selection = {
                checked: [],
                table: $scope.tableParams
            };

            $scope.tableModel = {
                options: {
                    sortable: false
                },
                columns: $scope.columns,
                selection: $scope.selection,
                tableParams: $scope.tableParams
            };
        };

        function buildColumns() {
            var columns = [];

            var insertColumn = function(nodeName, nodeId, yangStatement, configuration, options) {
                var node = _.get($scope.list, "template." + yangStatement + "." + nodeId);
                var isValidToInsert = (_.get(options, "predicate")) ? options.predicate(node) : true;

                if (isValidToInsert) {
                    columns.push({
                        field: nodeId,
                        title: nodeName,
                        headerTitle: $filter("getInformation")(node),
                        yangStatement: yangStatement,
                        filter: createColumnFieldSearch(nodeId),
                        class: createColumnClass(isKey(options), configuration, node),
                        show: true
                    });
                }
            };

            var createColumnFieldSearch = function(nodeId) {
                var filter: any = {};

                var columnTextFieldSearch = $scope.getFieldSearchStatementForNodeId(nodeId);
                filter[columnTextFieldSearch] = "text";

                return filter;
            };

            var insertSelectorAndActionsColumns = function() {
                if ($scope.config) {
                    columns.unshift({
                        field: "selector",
                        title: "",
                        class: "listbody-table-check-column text-center col-check-size",
                        headerTemplateURL: TABLE_TEMPLATE_URL.INFO_CONFIG.SELECTION_CHECKBOX_LIST_HEADER,
                        editable: false,
                        show: true
                    });
                }

                if ($scope.config || $scope.emptyKeys) {
                    columns.push({
                        field: "table-actions",
                        title: $translate.instant("table.actions"),
                        class: "listbody-table-action action list-header-padding",
                        editable: false,
                        show: true
                    });
                }
            };

            var createColumnClass = function(isKey, configuration, node) {
                var columnClass = [];

                if (isKey) {
                    if (($filter("isMandatory")(node)) && $scope.config) {
                        columnClass.push("required");
                    }

                    if ($filter("isInformation")(node)) {
                        columnClass.push("nonConfigurable-label");
                    }

                    if ($filter("isConfiguration")(node)) {
                        columnClass.push("configurable-label");
                    }
                } else {
                    configuration ? columnClass.push("configurable-label") : columnClass.push("nonConfigurable-label");
                }
//                 [CHECKME][TR-069] - Expected 2 arguments, but got 1.
//                 return columnClass.toString().replace(REPLACE_COMMA_REGEX);
                return columnClass.toString().replace(REPLACE_COMMA_REGEX, '');
            };

            var insertNodesAsColumns = function(nodeChildren, yangStatement, configuration, options?) {
                var nodeIds = isKey(options) ? nodeChildren : _.get(nodeChildren, yangStatement);

                nodeIds.forEach(function(nodeId) {
                    insertColumn($scope.list.template[yangStatement][nodeId].name, nodeId, yangStatement, configuration, options);
                });
            };

            // Insert keys
            insertNodesAsColumns($scope.keys, YangStatements.LEAVES, true, {isKey: true});

            if ($scope.filters.showConfig) {
                // Insert configurable nodes
                insertNodesAsColumns($scope.configurableChildren, YangStatements.LEAVES, true, {predicate: isNotHidden});
                insertNodesAsColumns($scope.configurableChildren, YangStatements.LEAFLISTS, true);
                insertNodesAsColumns($scope.configurableChildren, YangStatements.LISTS, true);
                insertNodesAsColumns($scope.configurableChildren, YangStatements.CHOICES, true);
                insertNodesAsColumns($scope.configurableChildren, YangStatements.CONTAINERS, true,
                    {predicate: isContainerNodeVisible});
            }

            if ($scope.filters.showStatus) {
                // Insert non configurable nodes
                insertNodesAsColumns($scope.nonConfigurableChildren, YangStatements.LEAVES, false);
                insertNodesAsColumns($scope.nonConfigurableChildren, YangStatements.LEAFLISTS, false);
                insertNodesAsColumns($scope.nonConfigurableChildren, YangStatements.LISTS, false);
                insertNodesAsColumns($scope.nonConfigurableChildren, YangStatements.CHOICES, false);
                insertNodesAsColumns($scope.nonConfigurableChildren, YangStatements.CONTAINERS, false,
                    {predicate: isContainerNodeVisible});
            }

            // Insert selector and action column
            insertSelectorAndActionsColumns();

            return columns;
        }

        function buildDataset() {
            var dataset = [];

            var createRow = function(listTemplate, entry, newRow, yangStatement, field, options) {
                var schemaNode = _.get(listTemplate, yangStatement)[field];
                var dataNode = _.get(entry, [yangStatement, field]);
                var isValidToInsert = (_.get(options, "predicate")) ? options.predicate(schemaNode, dataNode) : true;

                if (isValidToInsert) {
                    newRow[schemaNode.id] = {
                        schemaNode: schemaNode,
                        dataNode: dataNode,
                        yangStatement: yangStatement,
                        isKey: isKey(options)
                    };
                    createDatasetFieldSearch(newRow, schemaNode, dataNode, yangStatement);
                }
            };

            var createDatasetFieldSearch = function(newRow, schemaNode, dataNode, yangStatement) {
                var searchTextField = $scope.getFieldSearchStatementForNodeId(schemaNode.id);

                switch (yangStatement) {
                    case YangStatements.LEAVES:
                        newRow[searchTextField] = _.get(dataNode, "value", "");
                        break;
                    case YangStatements.LEAFLISTS:
                        newRow[searchTextField] = _.get(dataNode, "values") || [];
                        break;
                    case YangStatements.LISTS:
                        newRow[searchTextField] = TableCellService.getEntriesByLeafKeys(schemaNode, dataNode) || [];
                        break;
                    case YangStatements.CHOICES:
                        newRow[searchTextField] = _.get(dataNode, "case.id", "");
                        break;
                    case YangStatements.CONTAINERS:
                        newRow[searchTextField] = _.get(schemaNode, "name", "");
                        break;
                }
            };

            var insertRows = function(nodeChildren, yangStatement, listTemplate, entry, newRow, options?) {
                var nodes = isKey(options) ? nodeChildren : _.get(nodeChildren, yangStatement);

                nodes.forEach(function(field) {
                    createRow(listTemplate, entry, newRow, yangStatement, field, options);
                });
            };

            $scope.entries.forEach(function(entry, index) {
                var newRow: any = {};
                var listTemplate = angular.copy($scope.list.template);
                // Insert keys
                insertRows($scope.keys, YangStatements.LEAVES, listTemplate, entry, newRow, {isKey: true});

                if ($scope.filters.showConfig) {
                    // Insert configurable nodes
                    insertRows($scope.configurableChildren, YangStatements.LEAVES, listTemplate, entry, newRow,
                        {predicate: isNotHidden});
                    insertRows($scope.configurableChildren, YangStatements.LEAFLISTS, listTemplate, entry, newRow);
                    insertRows($scope.configurableChildren, YangStatements.LISTS, listTemplate, entry, newRow);
                    insertRows($scope.configurableChildren, YangStatements.CHOICES, listTemplate, entry, newRow);
                    insertRows($scope.configurableChildren, YangStatements.CONTAINERS, listTemplate, entry, newRow,
                        {predicate: isContainerNodeVisible});
                }

                if ($scope.filters.showStatus) {
                    // Insert non configurable nodes
                    insertRows($scope.nonConfigurableChildren, YangStatements.LEAVES, listTemplate, entry, newRow );
                    insertRows($scope.nonConfigurableChildren, YangStatements.LEAFLISTS, listTemplate, entry, newRow);
                    insertRows($scope.nonConfigurableChildren, YangStatements.LISTS, listTemplate, entry, newRow);
                    insertRows($scope.nonConfigurableChildren, YangStatements.CHOICES, listTemplate, entry, newRow);
                    insertRows($scope.nonConfigurableChildren, YangStatements.CONTAINERS, listTemplate, entry, newRow,
                        {predicate: isContainerNodeVisible});
                }
                newRow.dataEntryIndex = index;
                newRow.entry = entry;
                newRow.nodePath = DataPathService.getEntryPath($scope.pathKeys, $scope.list, entry, index);

                dataset.push(newRow);
            });

            return dataset;
        }

        $scope.reloadTable = function() {
            $scope.tableParams.settings({dataset: buildDataset()});
            $scope.tableParams.reload();
            isDatasetOutOfDate = false;
        };

        $scope.shouldReloadTable = function() {
            if (isDatasetOutOfDate) {
                $scope.reloadTable();
            }
        };

        function isSingleEditing() {
            return $scope.selection.checked.length === 1;
        }

        function singleEdit() {
            var entry = $scope.selection.checked[0].entry;
            var index = $scope.selection.checked[0].dataEntryIndex;
            var listPath = $filter("getPath")($scope.list);
            $scope.editEntry(entry, listPath, index);
        }

        function isMultipleEditingAllowed(callBack) {
            if ($scope.selection.checked.length === 0) {
                isAllKeysInteger(callBack);
            } else {
                areThereMultipleEditingConfigurableFields(callBack);
            }
        }

        function isAllKeysInteger(callBack) {
            var keysTypes = _.map(_.pick($scope.list.template.leaves, $scope.keys), function(key) {
                return DomainHandlerService.getBaseType(key.type).name;
            });
            if (_.some(keysTypes, _.negate(ListService.isIntegerKey))) {
                var messageKey = "yang.list.modal.errorOnOpen";
                showErrorMessage(messageKey);
            } else {
                callBack();
            }
        }

        function areThereMultipleEditingConfigurableFields(callBack) {
            var isEmpty = _.every($scope.selection.checked, function(selectedRow) {
                var isLeavesOnlyKeys = _.isEqual(_.keys(selectedRow.entry.leaves), $scope.keys);
                var isOtherStatementsEmpty = _.isEmpty(selectedRow.entry["leaf-lists"])
                    && _.isEmpty(selectedRow.entry.choices);

                return isLeavesOnlyKeys && isOtherStatementsEmpty;
            });
            if (isEmpty) {
                var messageKey = "yang.list.modal.noConfigurableItems";
                showErrorMessage(messageKey);
            } else {
                callBack();
            }
        }

        function showErrorMessage(key) {
            $rootScope.showDialog({
                translateKey: key
            });
        }

        function multipleEdit() {
            ngDialog.openConfirm({
                template: "templates/features/dynamic-device/domain/yang/list/list-edit-add-modal.html",
                className: "list-edit-add-modal",
                controller: "ListEditAddModalController",
                closeByNavigation: true,
                resolve: {
                    pathKeys: function() {
                        return $scope.pathKeys;
                    },
                    keys: function() {
                        return $scope.keys;
                    },
                    parentPath: function() {
                        return $scope.parentPath;
                    },
                    list: function() {
                        return $scope.list;
                    },
                    configurableChildren: function() {
                        return $scope.configurableChildren;
                    },
                    config: function() {
                        return $scope.config;
                    },
                    guiSettings: function() {
                        return {
                            modalTitle: getModalTitle(),
                            editModalFieldsInfo: getEditModalFieldsInfo(),
                            shouldShowKeys: shouldShowKeys(),
                            changesRequired: $scope.selection.checked.length > 0
                        };
                    },
                    changesValidator: function() {
                        return function(entry) {
                            var entriesList = ListService.processEditOrAddMultipleItems(entry, $scope.keys);

                            var areAllEntriesEditing = _.every(entriesList, function(newEntry) {
                                return ListService.hasDuplicateEntry($scope.list, $scope.entries, newEntry);
                            });

                            if (areAllEntriesEditing || !areRequiredFieldsDisabled(entry)) {
                                return entriesList;
                            } else {
                                return false;
                            }
                        };
                    }
                }
            }).then(updateTableInfo);
        }

        function areRequiredFieldsDisabled(newEntry) {
            return _.some(newEntry, function(entry) {
                if (!_.isEmpty(entry)) {
                    return _.find(entry, {shouldEdit: false, "sub-statements": {mandatory: "true"}});
                }
            });
        }

        function updateTableInfo(editedEntries) {
            if ($scope.selection.checked.length > 0) {
                processMultipleEdit(editedEntries);
            } else {
                processMultipleEditOrAdd(editedEntries);
            }
        }

        function getModalTitle() {
            if ($scope.selection.checked.length === 0) {
                return $translate.instant("yang.list.modal.add.multipleItemsAddOrEdit");
            }

            return $translate.instant("yang.list.modal.add.multipleEdit");
        }

        function getEditModalFieldsInfo() {
            if ($scope.selection.checked.length > 0) {
                var message = $translate.instant("yang.list.modal.edit.editFieldsInfo");
                return message.replace("{0}", $scope.selection.checked.length);
            }

            return "";
        }

        function shouldShowKeys() {
            return $scope.selection.checked.length === 0;
        }

        function processMultipleEdit(entry) {
            $scope.selection.checked.forEach(function(selectedRow) {
                var keysToInsert = _.mapValues(_.pick(selectedRow, $scope.keys), "dataNode.value");
                var originalEntry = getOriginalEntry(keysToInsert);
                var newEntry = angular.copy(entry);

                if (originalEntry) {
                    updateTable(newEntry, originalEntry);
                }
            });
        }

        function processMultipleEditOrAdd(entries) {
            entries.forEach(function(newEntry) {
                var keysToInsert = _.mapValues(_.pick(newEntry.leaves, $scope.keys), "value");
                var originalEntry = getOriginalEntry(keysToInsert);

                if (originalEntry) {
                    updateTable(newEntry, originalEntry);
                } else {
                    prepareEntryToAdd(newEntry);
                }
            });
        }

        function getOriginalEntry(keysToInsert) {
            return _.find($scope.tableParams.data, function(tableData, index) {
                var originalKeys = _.mapValues(_.pick(tableData.entry.leaves, $scope.keys), "value");

                return _.isEqual(originalKeys, keysToInsert);
            });
        }

        function updateTable(newEntry, entry) {
            var entryPathKeys = DataPathService.getEntryPathKeys($scope.pathKeys, $scope.list, entry.entry);
            _.forOwn(newEntry, function(domain, key) {
                if (domain && !_.isEmpty(domain) && _.includes(acceptedStatementsForMultipleEdit, key)) {
                    _.forOwn(domain, function(node) {
                        if (node) {
                            if (node.shouldEdit) {
                                var nodePathWithKeys = DataPathService.getNodePath(entryPathKeys, node);
                                if (key === YangStatements.LEAVES) {
                                    LeafService.setPersistentLeafValue(node, nodePathWithKeys, node.value);
                                } else if (key === YangStatements.LEAFLISTS && node.values) {
                                    DomainHandlerService.addOrSetDataNode(nodePathWithKeys + ".values", node.values);
                                } else if (key === YangStatements.CHOICES) {
                                    DomainHandlerService.addOrSetDataNode(nodePathWithKeys + ".value", node.value);
                                    DomainHandlerService.addOrSetDataNode(nodePathWithKeys + ".case", node.case);
                                }
                            }
                            delete node.shouldEdit;
                        }
                    });
                }
            });
            $rootScope.$broadcast("CONTENT_MODIFIED", true);
            $scope.reloadTable();
        }

        function prepareEntryToAdd(newEntry) {
            _.forOwn(newEntry, function(domain, key1) {
                if (domain && !_.isEmpty(domain) && !_.includes(["paths", "parentPaths"], key1)
                    && _.contains(_.map(YangStatements), key1)) {
                    _.forOwn(domain, function(leaf, key2) {
                        if (!leaf.shouldEdit && _.contains(_.map(YangStatements), key2)) {
                            var defaultValue = newEntry[key1][key2]["sub-statements"].default;
                            if (defaultValue) {
                                newEntry[key1][key2].value = defaultValue;
                            }
                        }
                    });
                }
            });
            $scope.addEntry(newEntry);
        }

        $scope.$watch("entries", function(newValue, oldValue) {
            $timeout.cancel(timer);
            timer = $timeout(function() {
                isDatasetOutOfDate = newValue !== oldValue;
            }, 200);
        }, true);

        $scope.$on("$destroy", function() {
            unregisterFunctions.forEach(_.attempt);
        });
    }
]);
