"use strict";

/**
* @ngdoc service
* @name nms.templates.RestDeviceOptionsExtractionService
* @description
* # Serviço para consumo do serviço REST para extração de opções.
* Factory in the nms.templates.
*/
var app = angular.module("nms.templates");

app.factory("RestDeviceOptionsExtractionService", ["Restangular",
    function(Restangular) {
        const service: any = {};
        var basePath = "/device";

        service.testOptionsExtraction = function(request) {
            return Restangular.one(basePath + "/testOptionsExtraction").customPOST(request);
        };

        service.extractDeviceOptions = function(request) {
            return Restangular.one(basePath + "/extractDeviceOptions").customPOST(request);
        };

        service.extractSpecificVariableOptions = function(request) {
            return Restangular.one(basePath + "/extractDeviceOptionsBySpecificVariable").customPOST(request);
        };

        service.getDeviceOptionsTimeout = function() {
            return Restangular.one(basePath + "/deviceOptionsTimeout").customGET();
        };

        return service;
    }
]);
