import "app/ng2/main";
import "app/ng1/nms-module";
import "app/ng1/nms-constants";
import "app/ng1/nms-routes";
import "app/ng1/nms-downgraded";
import "app/ng1/features/navbar-controller";
import "app/ng1/features/audit/audit-module";
import "app/ng1/features/template/template-module";
import "app/ng1/features/device-summary/device-summary-module";
import "app/ng1/features/device-discover/device-discover-module";
import "app/ng1/features/dynamic-device/dynamic-device-module";
import "app/ng1/features/manage-devices/manage-devices-module";
import "app/ng1/features/management/management-module";
import "app/ng1/features/gpon-onus/gpon-onus-module";
import "app/ng1/features/cwmp-parameters/cwmp-parameters-module";
import "app/ng1/features/cpes/cpes-module";
import "app/ng1/features/scheduler/scheduler-module";
import "@nms-ng1/features/device-configuration-backup/device-configuration-backup-module";
import "@nms-ng1/features/device-configuration-backup/modals/add-device-modal-generic-controller";
import "app/ng1/components/utils/array/is-array-filter";
import "app/ng1/components/utils/array/to-array-filter";
import "app/ng1/components/utils/array/wrap-in-array-filter";
import "app/ng1/components/utils/array/array-to-string-filter";
import "app/ng1/components/utils/json/clear-empty-properties-filter";
import "app/ng1/components/utils/json/json-path-service";
import "app/ng1/components/utils/string/cut-string-filter";
import "app/ng1/components/utils/string/escape-tags-except-line-and-thematic-break-filter";
import "app/ng1/components/utils/string/remove-html-line-break-filter";
import "app/ng1/components/utils/string/string-formatter-filter";
import "app/ng1/components/utils/file-name-generator-filter";
import "app/ng1/components/utils/firmware-range-rule-filter";
import "app/ng1/components/utils/order-by-numbers-and-letters-filter";
import "app/ng1/components/utils/alphanum-filter";
import "app/ng1/components/utils/date-format-filter";
import "app/ng1/components/utils/numeric-range-formatter-filter";
import "app/ng1/components/utils/replace-line-break-filter";
import "app/ng1/components/utils/clean-line-break-filter";
import "app/ng1/components/utils/replace-tab-filter";
import "app/ng1/components/utils/window-service";
import "app/ng1/components/utils/identifier-filters";
import "app/ng1/components/utils/label-color-service";
import "app/ng1/components/utils/session-preferences-service";
import "app/ng1/components/utils/bytes-and-packets-formatter-filter";
import "app/ng1/components/utils/unit-converter-service";
import "app/ng1/components/utils/filter-processors-service";
import "app/ng1/components/utils/table-filter-service";
import "app/ng1/components/utils/user-preferences-service";
import "app/ng1/components/utils/zabbix-url-formatter-filter";
import "app/ng1/components/utils/zabbix-monitoring-service";
import "app/ng1/components/utils/worker-service";
import "app/ng1/components/utils/validators/form-validator-service";
import "app/ng1/components/utils/validators/fields-validator-service";
import "app/ng1/components/utils/validators/allowed-value-validator-directive";
import "app/ng1/components/utils/validators/confirm-password-validator-directive";
import "app/ng1/components/utils/ng-include-wrapper.directive"
import "app/ng1/components/ui/auto-updater/device-polling/device-polling-status-controller";
import "app/ng1/components/ui/auto-updater/device-polling/device-polling-status-directive";
import "app/ng1/components/ui/auto-updater/device-polling/device-polling-rest-service";
import "app/ng1/components/ui/auto-updater/device-polling/device-polling-service";
import "app/ng1/components/ui/auto-updater/auto-updater-controller";
import "app/ng1/components/ui/auto-updater/auto-updater-service";
import "app/ng1/components/ui/auto-updater/auto-updater-counter-controller";
import "app/ng1/components/ui/auto-updater/auto-updater-countdown-controller";
import "app/ng1/components/ui/auto-updater/auto-updater-counter-directive";
import "app/ng1/components/ui/auto-updater/auto-updater-countdown-directive";
import "app/ng1/components/ui/auto-updater/auto-updater-directive";
import "app/ng1/components/ui/auto-updater/auto-updater-model";
import "app/ng1/components/ui/status/status-directive";
import "app/ng1/components/ui/dropdown/dropdown-device-service";
import "app/ng1/components/ui/dropdown/dropdown-cpe-service";
import "app/ng1/components/ui/dropdown/dropdown-directive";
import "app/ng1/components/ui/nms-dropdown/nms-dropdown-directive";
import "app/ng1/components/ui/input/password-field-directive";
import "app/ng1/components/ui/input/autofocus-first-key-leaf-directive";
import "app/ng1/components/ui/input/stepper-directive";
import "app/ng1/components/ui/input/stepper-service";
import "app/ng1/components/ui/loading-pane/loading-pane-service";
import "app/ng1/components/ui/page-title/page-title-directive";
import "app/ng1/components/ui/page-title/page-title-wrapper-directive";
import "app/ng1/components/ui/page-title/device-page-title-directive";
import "app/ng1/components/ui/pagination/pagination-limit-validation-directive";
import "app/ng1/components/ui/pagination/client/client-pagination-controller";
import "app/ng1/components/ui/pagination/client/client-pagination-directive";
import "app/ng1/components/ui/pagination/server/server-pagination-directive";
import "app/ng1/components/ui/pagination/server/go-to-page-click-directive";
import "app/ng1/components/ui/pagination/server/change-page-set-directive";
import "app/ng1/components/ui/pagination/server/change-page-directive";
import "app/ng1/components/ui/pagination/server/server-pagination-service";
import "app/ng1/components/ui/selection-checkbox/selection-checkbox-controller";
import "app/ng1/components/ui/selection-checkbox/selection-checkbox-directive";
import "app/ng1/components/ui/table/table-resizable-directive";
import "app/ng1/components/ui/table/update-columns-width";
import "app/ng1/components/ui/table/vertical-scrollable-outer-table";
import "app/ng1/components/ui/delay-mask-directive";
import "app/ng1/components/ui/hide-on-focus-directive";
import "app/ng1/components/ui/input-formatter-directive";
import "app/ng1/components/ui/ng-enter-directive";
import "app/ng1/components/ui/onlynumbers-service";
import "app/ng1/components/ui/onlynumbers-directive";
import "app/ng1/components/ui/slide-toggle-directive";
import "app/ng1/components/ui/tags-input-wrapper-directive";
import "app/ng1/components/ui/status-bar/status-bar-directive";
import "app/ng1/components/ui/table/customize-table-columns-controller";
import "app/ng1/components/ui/table/customize-table-columns-directive";
import "app/ng1/components/ui/nms-warning/nms-warning-controller";
import "app/ng1/components/ui/nms-warning/nms-warning-directive";
import "app/ng1/components/ui/reorder-handle/reorder-handle-directive";
import "app/ng1/components/ui/ignore-dirty-directive";
import "app/ng1/components/ui/height-calculator-directive";
import "app/ng1/components/ui/input/text-highlighter-service";
import "app/ng1/components/ui/input/text-finder-directive";
import "app/ng1/components/ui/input/text-finder-controller";
import "app/ng1/components/ui/polling/polling-service";
import "app/ng1/components/ui/polling/polling-controller";
import "app/ng1/components/ui/polling/polling-directive";
import "app/ng1/components/ui/segmented-control/segmented-control-directive";
import "app/ng1/components/ui/variable/variable-dropdown-directive";
import "app/ng1/components/ui/template-commands-step-directive";
import "app/ng1/components/ui/template-commands-step-wrapper-directive";
import "app/ng1/components/authentication/authentication-module";
import "app/ng1/components/authentication/authentication-service";
import "app/ng1/components/authentication/authentication-rest";
import "app/ng1/components/authentication/sso-service";
import "app/ng1/components/authentication/feature-access-control";
import "app/ng1/components/license/license-module";
import "app/ng1/components/license/license-rest";
import "app/ng1/components/license/license-service";
import "app/ng1/components/exception-handler/http-error-handler";
import "app/ng1/components/ui/modals/properties/properties-edit-modal-controller";
import "app/ng1/components/ui/modals/properties/properties-service";
import "app/ng1/components/ui/modals/properties/properties-rest-service";
import "app/ng1/components/ui/modals/properties/properties-model";
import "app/ng1/components/ui/modals/properties/properties-update-model";
import "app/ng1/components/ui/modals/credentials/credentials-service";
import "app/ng1/components/ui/modals/credentials/credentials-rest-service";
import "app/ng1/components/ui/modals/connectivity-test/connectivity-test-controller";
import "app/ng1/components/ui/modals/connectivity-test/connectivity-test-service";
import "app/ng1/components/ui/device/dropdown/device-dropdown-model";
import "app/ng1/components/ui/device/dropdown/device-dropdown-directive";
import "app/ng1/components/ui/device/dropdown/device-dropdown-model-service";
import "app/ng1/components/ui/device/dropdown/device-dropdown-shortcuts-service";
import "app/ng1/components/ui/device/table/devices-table-directive";
import "app/ng1/components/ui/device/table/devices-table-service";
import "app/ng1/components/ui/device/table/devices-table-controller";
import "app/ng1/components/ui/device/services/device-augment-service";
import "app/ng1/components/ui/device/services/device-common-service";
import "app/ng1/components/ui/device/services/device-permission-service";
import "app/ng1/components/ui/device/services/management-device-rest-service";
import "@nms-ng1/components/ui/nms-table-old/nms-table-controller"
import "@nms-ng1/components/ui/nms-table-old/nms-table-directive"
import "app/ng1/features/audit/audit-list-controller";
import "app/ng1/features/audit/audit-modal-controller";
import "app/ng1/features/audit/audit-rest-service";
import "app/ng1/features/audit/audit-service";
import "app/ng1/features/template/components/utils/device-options-extraction/device-options-extraction-service";
import "app/ng1/features/template/components/utils/device-options-extraction/rest-device-options-extraction-service";
import "app/ng1/features/template/components/utils/converter-service";
import "app/ng1/features/template/components/utils/validator-service";
import "app/ng1/features/template/components/utils/web-socket-manager-service";
import "app/ng1/features/template/components/utils/workflow-service";
import "app/ng1/features/template/components/ui/search/complex-search-controller";
import "app/ng1/features/template/components/ui/search/complex-search-directive";
import "app/ng1/features/template/components/ui/search/keyword-controller";
import "app/ng1/features/template/components/ui/search/keyword-directive";
import "app/ng1/features/template/components/ui/search/search-controller";
import "app/ng1/features/template/components/ui/search/search-directive";
import "app/ng1/features/template/components/ui/search/type-controller";
import "app/ng1/features/template/components/ui/search/type-directive";
import "app/ng1/features/template/components/ui/focus/focus-directive";
import "app/ng1/features/template/components/ui/accordion/template-instance-accordion-controller";
import "app/ng1/features/template/components/ui/accordion/template-instance-accordion-directive";
import "app/ng1/features/template/components/ui/accordion/template-instance-accordion-device-changes-service";
import "app/ng1/features/template/components/ui/code-block/code-block-directive";
import "app/ng1/features/template/components/ui/prevent-scroll-directive";
import "app/ng1/features/template/components/ui/template-directives";
import "app/ng1/features/template/components/ui/scroll-options-directive";
import "app/ng1/features/template/components/ui/variable-step-directive";
import "app/ng1/features/template/components/ui/variable-power-directive";
import "app/ng1/features/template/components/ui/variables-validate-block-directive";
import "app/ng1/features/template/components/ui/variables/variables-form-controller";
import "app/ng1/features/template/components/ui/variables/variables-form-directive";
import "app/ng1/features/template/components/ui/variables/template-instance-variables-form-directive";
import "app/ng1/features/template/components/ui/variables/variables-controller";
import "app/ng1/features/template/components/ui/variables/variables-directive";
import "app/ng1/features/template/components/ui/upload/file-model-directive";
import "app/ng1/features/template/components/ui/template-instance-status/template-instance-status-controller";
import "app/ng1/features/template/components/ui/template-instance-status/template-instance-status-directive";
import "app/ng1/features/template/components/ui/commands-viewer/commands-viewer-controller";
import "app/ng1/features/template/components/ui/commands-viewer/commands-viewer-directive";
import "app/ng1/features/template/components/ui/templates-by-keywords/templates-by-keywords-directive";
import "app/ng1/features/template/template/variable/integer-variable-validator-service";
import "app/ng1/features/template/template/variable/text-variable-validator-service";
import "app/ng1/features/template/template/variable/selection-variable-validator-service";
import "app/ng1/features/template/template/variable/readonly-variable-validator-service";
import "app/ng1/features/template/template/variable/variable-validator-service";
import "app/ng1/features/template/template/variable/variable-service";
import "app/ng1/features/template/template/modals/template-test-controller";
import "app/ng1/features/template/template/modals/template-test-directive";
import "app/ng1/features/template/template/modals/options-extraction-test-controller";
import "app/ng1/features/template/template/modals/options-extraction-test-directive";
import "app/ng1/features/template/template/modals/variable-params-options-directive";
import "app/ng1/features/template/template/modals/variable-visualization-directive";
import "app/ng1/features/template/template/modals/variable-modal-controller";
import "app/ng1/features/template/template/template-list-controller";
import "app/ng1/features/template/template/template-rest-service";
import "app/ng1/features/template/template/template-service";
import "app/ng1/features/template/template/rest-config-service";
import "app/ng1/features/template/template-application/device/device-service";
import "app/ng1/features/template/template-application/activation-service/activation-service-rest";
import "app/ng1/features/template/template-application/activation-service/activation-service";
import "app/ng1/features/template/template-application/modals/add-device-modal-controller";
import "app/ng1/features/template/template-application/modals/add-cpe-modal-controller";
import "app/ng1/features/template/template-application/modals/add-template-modal-controller";
import "app/ng1/features/template/template-application/modals/progressbar-calculate-service";
import "app/ng1/features/template/template-application/modals/results-modal-controller";
import "app/ng1/features/template/template-application/template-instance-list-controller";
import "app/ng1/features/template/template-application/template-instance-rest-service";
import "app/ng1/features/template/template-application/template-instance-service";
import "app/ng1/features/template/template-application/template-instance-variable-service";
import "app/ng1/features/template/template-application/template-instance-validator-service";
import "app/ng1/features/template/template-application/modals/commands-results-modal-controller";
import "app/ng1/features/template/template-application/modals/application-status-controller";
import "app/ng1/features/template/template-application/one-step/template-application-one-step-service";
import "app/ng1/features/template/template-application/one-step/template-application-one-step-controller";
import "app/ng1/features/device-discover/device-discover-controller";
import "app/ng1/features/device-discover/device-discover-rest-service";
import "app/ng1/features/device-discover/device-discover-service";
import "app/ng1/features/device-discover/device-discover-validator-service";
import "app/ng1/features/device-discover/network-service";
import "app/ng1/features/device-summary/device-summary-resolver";
import "app/ng1/features/device-summary/device-summary-controller";
import "app/ng1/features/device-summary/status/device-status-rest-service";
import "app/ng1/features/device-summary/status/device-status-service";
import "app/ng1/features/dynamic-device/content-header";
import "app/ng1/features/dynamic-device/content-panel/configurable-content-directive";
import "app/ng1/features/dynamic-device/content-panel/content-manager-service";
import "app/ng1/features/dynamic-device/content-panel/width-calculator-directive";
import "app/ng1/features/dynamic-device/sidebar/tree/tree-directives";
import "app/ng1/features/dynamic-device/sidebar/tree/tree-filter-worker-service";
import "app/ng1/features/dynamic-device/sidebar/tree/tree-filter-service";
import "app/ng1/features/dynamic-device/sidebar/tree/tree-controllers";
import "app/ng1/features/dynamic-device/sidebar/tree/tree-node-children-directive";
import "app/ng1/features/dynamic-device/sidebar/tree/tree-selection-manager";
import "app/ng1/features/dynamic-device/sidebar/tree/tree-service";
import "app/ng1/features/dynamic-device/sidebar/save-coordinates-directive";
import "app/ng1/features/dynamic-device/domain/nes/nes-data-cache-service";
import "app/ng1/features/dynamic-device/domain/nes/nes-edit-config-service";
import "app/ng1/features/dynamic-device/domain/nes/nes-http-error-message-provider";
import "app/ng1/features/dynamic-device/domain/nes/nes-rest-service";
import "app/ng1/features/dynamic-device/domain/nes/nes-error-handler-service";
import "app/ng1/features/dynamic-device/domain/nes/node-info-cache";
import "app/ng1/features/dynamic-device/domain/nes/node-info-cache-manager";
import "app/ng1/features/gpon-onus/gpon-onus-controller";
import "app/ng1/features/gpon-onus/gpon-onus-service";
import "app/ng1/features/gpon-onus/gpon-onus-rest-service";
import "app/ng1/features/gpon-onus/gpon-onus-resolver";
import "app/ng1/features/gpon-onus/gpon-onus-device-information-resolver";
import "app/ng1/features/gpon-onus/gpon-onus-constants";
import "app/ng1/features/dynamic-device/domain/xpath-resolver-service";
import "app/ng1/features/dynamic-device/domain/domain-handler-service";
import "app/ng1/features/dynamic-device/domain/restriction-service";
import "app/ng1/features/dynamic-device/domain/validator-constants";
import "app/ng1/features/dynamic-device/domain/yang/node-filters";
import "app/ng1/features/dynamic-device/domain/yang/list/list-service";
import "app/ng1/features/dynamic-device/domain/yang/substatement-filters";
import "app/ng1/features/dynamic-device/domain/yang/when-verifier-service";
import "app/ng1/features/dynamic-device/domain/yang/node-status-service";
import "app/ng1/features/dynamic-device/domain/yang/choice/choice-body-controller";
import "app/ng1/features/dynamic-device/domain/yang/choice/choice-directives";
import "app/ng1/features/dynamic-device/domain/yang/choice/restrictions/choice-restriction-directives";
import "app/ng1/features/dynamic-device/domain/yang/container/containers-directive";
import "app/ng1/features/dynamic-device/domain/yang/container/container-body-directive";
import "app/ng1/features/dynamic-device/domain/yang/container/container-body-controller";
import "app/ng1/features/dynamic-device/domain/yang/leaf/leaf-service";
import "app/ng1/features/dynamic-device/domain/yang/leaf/leaf-controller";
import "app/ng1/features/dynamic-device/domain/yang/leaf/leaves-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/leaf-header-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/leaf-body-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-string-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-integer-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-unsigned-integer-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-decimal-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-numeric-binding-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-multiple-values-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-union-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-boolean-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-empty-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leafref-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/custom/leaf-alarm-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/custom/input-default-value-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/leaf-restriction-directives";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/type-restriction-controller";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/decimal-integer-restriction-service";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/decimal-fraction-restriction-service";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/decimal-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/pattern-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/pattern-restriction-service";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/user-range-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/user-range-restriction-service";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/yang-range-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/yang-range-restriction-service";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/length-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/length-restriction-service";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/union-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaf/restrictions/union-restriction-service";
import "app/ng1/features/dynamic-device/domain/yang/leaf/types/leaf-type-constants";
import "app/ng1/features/dynamic-device/domain/yang/list/list-body-controller";
import "app/ng1/features/dynamic-device/domain/yang/list/list-table-cell-controller";
import "app/ng1/features/dynamic-device/domain/yang/list/list-directives";
import "app/ng1/features/dynamic-device/domain/yang/list/list-edit-add-modal-controller";
import "app/ng1/features/dynamic-device/domain/yang/list/restrictions/list-restriction-directives";
import "app/ng1/features/dynamic-device/domain/yang/list/restrictions/duplicate-key-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/list/restrictions/max-elements-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/list/restrictions/min-elements-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaflist/leaflist-controller";
import "app/ng1/features/dynamic-device/domain/yang/leaflist/leaflists-controller";
import "app/ng1/features/dynamic-device/domain/yang/leaflist/leaflist-table-cell-controller";
import "app/ng1/features/dynamic-device/domain/yang/leaflist/leaflist-modal-controller";
import "app/ng1/features/dynamic-device/domain/yang/leaflist/leaflist-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaflist/restrictions/leaflist-restriction-directives";
import "app/ng1/features/dynamic-device/domain/yang/leaflist/restrictions/leaflist-form-restriction-directive";
import "app/ng1/features/dynamic-device/domain/yang/leaflist/restrictions/duplicated-element-restriction-directive";
import "app/ng1/features/dynamic-device/dynamic-device-controller";
import "app/ng1/features/dynamic-device/data-path-service";
import "app/ng1/features/dynamic-device/device-directives";
import "app/ng1/features/dynamic-device/relink-event-directive";
import "app/ng1/features/dynamic-device/table-cell-service";
import "app/ng1/features/dynamic-device/dynamic-device-directives";
import "app/ng1/features/dynamic-device/tooltip-text-builder-directive";
import "app/ng1/features/dynamic-device/breadcrumb-controller";
import "app/ng1/features/dynamic-device/breadcrumb-directive";
import "app/ng1/features/system-configuration/system-configuration-rest-service";
import "app/ng1/features/system-configuration/system-configuration-service";
import "app/ng1/features/manage-devices/locations/location-service";
import "app/ng1/features/manage-devices/locations/location-rest-service";
import "app/ng1/features/manage-devices/modals/device-location-controller";
import "app/ng1/features/manage-devices/simple-path-tree/path-tree-controller";
import "app/ng1/features/manage-devices/simple-path-tree/path-tree-directive";
import "app/ng1/features/manage-devices/manage-devices-controller";
import "app/ng1/features/management/monitoring/monitoring-configuration-controller";
import "app/ng1/features/management/monitoring/monitoring-rest-service";
import "app/ng1/features/management/monitoring/monitoring-service";
import "app/ng1/features/management/permissions/converter/manage-permissions-model-converter";
import "app/ng1/features/management/permissions/validator/manage-permissions-validator-service";
import "app/ng1/features/management/permissions/devices/manage-permissions-devices-rest-service";
import "app/ng1/features/management/permissions/devices/manage-permissions-devices-service";
import "app/ng1/features/management/permissions/devices/manage-permissions-devices-controller";
import "app/ng1/features/management/permissions/devices/manage-permissions-devices-directive";
import "app/ng1/features/management/permissions/features/manage-permissions-features-rest-service";
import "app/ng1/features/management/permissions/features/manage-permissions-features-service";
import "app/ng1/features/management/permissions/features/manage-permissions-features-controller";
import "app/ng1/features/management/permissions/features/manage-permissions-features-directive";
import "app/ng1/features/management/permissions/locations/manage-permissions-locations-rest-service";
import "app/ng1/features/management/permissions/locations/manage-permissions-locations-service";
import "app/ng1/features/management/permissions/locations/manage-permissions-locations-controller";
import "app/ng1/features/management/permissions/locations/manage-permissions-locations-directive";
import "app/ng1/features/management/permissions/templates/manage-permissions-templates-controller";
import "app/ng1/features/management/permissions/templates/manage-permissions-templates-directive";
import "app/ng1/features/management/users/account/manage-user-account-controller";
import "app/ng1/features/management/users/account/manage-user-account-directive";
import "app/ng1/features/management/users/modal/manage-user-modal-service";
import "app/ng1/features/management/users/modal/manage-user-modal-controller";
import "app/ng1/features/management/users/manage-users-rest-service";
import "app/ng1/features/management/users/manage-users-actions-service";
import "app/ng1/features/management/users/manage-users-service";
import "app/ng1/features/management/users/manage-users-controller";
import "app/ng1/features/management/users/manage-users-model-converter";
import "app/ng1/features/management/users/edit-user-request-converter";
import "app/ng1/features/management/groups/basic/manage-group-basic-directive";
import "app/ng1/features/management/groups/modal/manage-group-modal-service";
import "app/ng1/features/management/groups/modal/manage-group-modal-controller";
import "app/ng1/features/management/groups/modal/remove-group-modal-controller";
import "app/ng1/features/management/groups/manage-groups-rest-service";
import "app/ng1/features/management/groups/manage-groups-actions-service";
import "app/ng1/features/management/groups/manage-groups-service";
import "app/ng1/features/management/groups/manage-groups-controller";
import "app/ng1/features/management/groups/manage-groups-model-converter";