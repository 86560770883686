/**
 * Directive to handle statuses.
 *
 * @author Rafael Senna
 */
var app = angular.module("nms");

app.directive("status", ["$compile", "$translate", "STATUS_KEYS", "LabelColorService",
    function($compile, $translate, STATUS_KEYS, LabelColorService) {
        return {
            restrict: "E",
            replace: true,
            template: "<div class='status-component'></div>",
            scope: {
                status: "="
            },
            compile: function compile() {
                return {
                    pre: function preLink(scope, iElement) {
                        scope.$watch("status", function(newValue, oldValue) {
                            if (!_.isEqual(newValue, oldValue)) {
                                iElement.removeClass(oldValue);
                            }
                            iElement.addClass(newValue);
                        });
                    },
                    post: function postLink(scope, iElement) {
                        scope.$watch("status", function() {
                            if (!_.isNull(scope.status)) {
                                var statusKey = STATUS_KEYS[scope.status] || scope.status;
                                var translatedStatusText = $translate.instant(statusKey);

                                iElement.html(translatedStatusText);
                                iElement.attr("title", translatedStatusText);
                                var backgroundColor = iElement.css("backgroundColor");
                                var labelColorClass = LabelColorService.getBestColorContrastClassForForeground(backgroundColor);

                                iElement.addClass(labelColorClass);
                                $compile(iElement.contents())(scope);
                            }
                        });
                    }
                };
            }
        };
    }
]);
