"use strict";

import { TABLE_FILTER_OPERATOR_MAPPING } from "@nms-ng2/app/shared/models/operators.models";

var app = angular.module("nms");

/**
* Serviço que auxilia a filtragem de itens de uma tabela.
* Possui as seguintes funcionalidades para comparação de strings (todos os casos são case-insensitive):
*
* - Busca por conteúdo exato colocando o texto entre aspas.
* - Busca por conteúdo exato quando o texto começa com "=".
* - Busca por negação de conteúdo quando o texto começa com "!".
* - Faz busca comparativa em campos que possuam pelo menos um número, quando o texto inicia com
* um dos seguintes operadores: "<", "<=", ">", ">=".
* - Quando nenhum desses operadores é utilizado, a busca é feita com substring.
*/
app.service("TableFilterService", [
    "$translate",
    "FilterProcessorsService",
    "$rootScope",
    function ($translate, FilterProcessorsService, $rootScope) {
        const service: any = {};
        const hasBothOperatorsRegex = /((?=(^.+ (AND|E) .+$))(?=(^.+ (OR|OU) .+$)))/i;
        const doesNotStartWithOperatorRegex = /^((?!^\s?(AND|OR|E|OU)\s).)*$/i;
        const doesNotEndWithOperatorRegex = /^((?!\s(AND|OR|E|OU)\s?$).)*$/i;
        const hasLogicOperatorRegex = /^(.+ (AND|E) .+)$|^(.+ (OR|OU) .+)$/i;
        const operatorType: any = { AND: "AND", OR: "OR" };
        service.tooltipText = $translate.instant("tooltips.search.searchToolTip");

        service.getProcessedFilters = function (filters) {
            const processedFilters: any = {};
            const processors = FilterProcessorsService.getProcessors();
            _.forEach(filters, function (value, field) {
                _.forEach(processors, function (processor) {
                    let processedFilter = processor({ field: field, value: value });
                    processedFilters[processedFilter.field] = processedFilter.value;
                });
            });

            return processedFilters;
        };

        var calculate: any = {
            OR: function (a, b) {
                return a || b;
            },
            AND: function (a, b) {
                return a && b;
            }
        };

        service.buildOperator = function (globalFilter, processedFilters) {
            if (globalFilter) {
                return !_.startsWith(globalFilter, "!") ? operatorType.OR : operatorType.AND;
            }
            return service.getOperatorTypeInTable(processedFilters);
        }

        service.getOperatorTypeInTable = function (processedFilters) {
            return processedFilters.operatorType || operatorType.AND;
        }

        service.getFilter = function (columns) {
            return function (rows, rawFilters) {
                if (isRawFiltersValuesInvalid(columns, rawFilters)) {
                    $rootScope.showDialog({
                        translateKey: "devicelist.tablecolumn.invalidFiltersWithOperators",
                    });
                } else {
                    const filters = service.getProcessedFilters(rawFilters);
                    const globalFilter = FilterProcessorsService.globalFilterProcessor(filters);

                    if (service.hasFilters(filters, globalFilter)) {
                        return service.filterRows(rows, columns, filters, globalFilter);
                    }

                    return rows;
                }
            };
        };

        service.filterRows = function (rows, columns, filters, globalFilter) {
            const fieldsForGlobalFilter = FilterProcessorsService.globalFilterFieldsProcessor(globalFilter, columns);
            const operatorGlobalFilter = service.buildOperator(globalFilter, filters);
            const operatorInsideTable = service.getOperatorTypeInTable(filters);

            return rows.filter(function (row) {
                let isVisibleRow = service.isAndOperator(operatorGlobalFilter);

                _.forEach(fieldsForGlobalFilter, function (field) {
                    const rowValue = row[field];
                    const rowValueResult = service.getRowValueResult(false, globalFilter, rowValue);
                    isVisibleRow = calculate[operatorGlobalFilter](isVisibleRow, rowValueResult);
                });

                _.forEach(filters, function (filterValue, field) {
                    if (angular.isDefined(row[field])) {
                        const rowValue = row[field];
                        const isLogicFilter = service.isLogicFilter(filterValue);
                        const rowValueResult = service.getRowValueResult(isLogicFilter, filterValue, rowValue);
                        isVisibleRow = calculate[operatorInsideTable](isVisibleRow, rowValueResult);
                    }
                });

                return isVisibleRow;
            });
        };

        /**
         * Verifica se algum dos filtros da tabela, incluindo o global, foi preenchido.
         */
        service.hasFilters = function (filters, globalFilter) {
            return JSON.stringify(_.omit(filters, "operatorType")) !== "{}" || globalFilter !== null;
        };

        service.getRowValueResult = function (isLogicFilter, filterValue, rowValue) {
            let rowValueResult = null;
            if (isLogicFilter) {
                const operatorLogic = service.getOperator(filterValue);
                rowValueResult = service.isAndOperator(operatorLogic);
                const parts = filterValue.split(service.getSplitRegex(operatorLogic));

                for (const part of parts) {
                    rowValueResult = calculate[operatorLogic](rowValueResult, service.comparator(rowValue, part));
                }
            } else {
                rowValueResult = service.comparator(rowValue, filterValue);
            }
            return rowValueResult;
        };

        service.isLogicFilter = function (filterValue) {
            let isLogicFilter = false;
            const hasBothOperators = hasBothOperatorsRegex.test(filterValue);

            if (!hasBothOperators) {
                const doesNotStartWithOperator = doesNotStartWithOperatorRegex.test(filterValue);
                const doesNotEndWithOperator = doesNotEndWithOperatorRegex.test(filterValue);

                if (doesNotStartWithOperator && doesNotEndWithOperator) {
                    isLogicFilter = hasLogicOperatorRegex.test(filterValue);
                }
            }

            return isLogicFilter;
        };

        service.getOperator = function (filterValue) {
            return filterValue.includes(" AND ") || filterValue.includes(" E ") ? operatorType.AND : operatorType.OR;
        };

        service.getSplitRegex = function (operator) {
            return operatorType.AND === operator ? / AND | E /gi : / OR | OU /gi;
        };

        service.isAndOperator = function (operatorLogic) {
            return operatorLogic === operatorType.AND;
        };

        service.comparator = function (columnValue, filterValue) {
            filterValue = String(filterValue).toLowerCase();

            for (var index = 0; index < operators.length; index++) {
                var operator = operators[index];
                var cleanedFilter = operator.clean(filterValue);

                if (operator.match(filterValue)) {
                    if (_.isArray(columnValue)) {
                        if (columnValue.length > 0) {
                             return _.some(columnValue, function (value) {
                                return operator.compare(cleanedFilter, String(value).toLowerCase());
                            });
                        }

                        return operator.compare(cleanedFilter, "");
                    } else {
                        return operator.compare(cleanedFilter, String(columnValue).toLowerCase());
                    }
                }
            }

            return _.includes(String(columnValue).toLowerCase(), filterValue);
        };

        service.filterTableData = function (params, globalFilter, customTableFilterCallback, fullData) {
            var filters = params.filter();
            filters["$"] = globalFilter;
            var processedFilters = service.getProcessedFilters(filters);

            var tableData = angular.copy(fullData);
            var filteredInfo = customTableFilterCallback(tableData, processedFilters);

            return filteredInfo;
        };

        /**
         * Operadores conhecidos para comparar duas strings.
         * Cada operador precisa definir 3 funções:
         *
         * - match: Verifica se a string de busca possui os elementos mínimos para que esse operador seja utilizado.
         * - clean: Limpa a string de busca, removendo todos os elementos que são específicos do operador. Mantém apenas o texto.
         * - compare: Compara a string de busca com um determinado campo para determinar se o campo deve ser filtrado ou não.
         */
        var operators = [
            {
                match: function (filterValue) {
                    return _.startsWith(filterValue, '"') && _.endsWith(filterValue, '"') && filterValue.length > 2;
                },
                clean: function (filterValue) {
                    return filterValue.substring(1, filterValue.length - 1);
                },
                compare: function (filterValue, columnValue) {
                    return columnValue === filterValue;
                }
            },
            {
                match: function (filterValue) {
                    return _.startsWith(filterValue, TABLE_FILTER_OPERATOR_MAPPING.DOES_NOT_CONTAIN) && filterValue.length > 2;
                },
                clean: function (filterValue) {
                    return filterValue.substring(2);
                },
                compare: function (filterValue, columnValue) {
                    return !columnValue.includes(filterValue);
                }
            },
            {
                match: function (filterValue) {
                    return _.startsWith(filterValue, TABLE_FILTER_OPERATOR_MAPPING.NOT_EQUALS) && filterValue.length > 1;
                },
                clean: function (filterValue) {
                    return filterValue.substring(1);
                },
                compare: function (filterValue, columnValue) {
                    return columnValue !== filterValue;
                }
            },
            {
                match: function (filterValue) {
                    return _.startsWith(filterValue, TABLE_FILTER_OPERATOR_MAPPING.STARTS_WITH) && filterValue.length > 1;
                },
                clean: function (filterValue) {
                    return filterValue.substring(1);
                },
                compare: function (filterValue, columnValue) {
                    return columnValue.endsWith(filterValue);
                }
            },
            {
                match: function (filterValue) {
                    return _.endsWith(filterValue, TABLE_FILTER_OPERATOR_MAPPING.ENDS_WITH) && filterValue.length > 1;
                },
                clean: function (filterValue) {
                    return filterValue.slice(0, -1);
                },
                compare: function (filterValue, columnValue) {
                    return columnValue.startsWith(filterValue);
                }
            },
            {
                match: function (filterValue) {
                    return _.startsWith(filterValue, TABLE_FILTER_OPERATOR_MAPPING.EQUALS) && filterValue.length > 1;
                },
                clean: function (filterValue) {
                    return filterValue.substring(1);
                },
                compare: function (filterValue, columnValue) {
                    return columnValue === filterValue;
                }
            },
            {
                match: numericMatch(TABLE_FILTER_OPERATOR_MAPPING.LESS_THAN_OR_EQUAL),
                clean: numericClean(TABLE_FILTER_OPERATOR_MAPPING.LESS_THAN_OR_EQUAL),
                compare: numericCompare(function (a, b) {
                    return a <= b;
                })
            },
            {
                match: numericMatch(TABLE_FILTER_OPERATOR_MAPPING.LESS_THAN),
                clean: numericClean(TABLE_FILTER_OPERATOR_MAPPING.LESS_THAN),
                compare: numericCompare(function (a, b) {
                    return a < b;
                })
            },
            {
                match: numericMatch(TABLE_FILTER_OPERATOR_MAPPING.GREATER_THAN_OR_EQUAL),
                clean: numericClean(TABLE_FILTER_OPERATOR_MAPPING.GREATER_THAN_OR_EQUAL),
                compare: numericCompare(function (a, b) {
                    return a >= b;
                })
            },
            {
                match: numericMatch(TABLE_FILTER_OPERATOR_MAPPING.GREATER_THAN),
                clean: numericClean(TABLE_FILTER_OPERATOR_MAPPING.GREATER_THAN),
                compare: numericCompare(function (a, b) {
                    return a > b;
                })
            }
        ];

        /**
         * Remove da string de busca o operador especificado. Considera que a string começa com esse operador,
         * pois o isso é garantido pelo match.
         */
        function numericClean(operator) {
            return function (filterValue) {
                return filterValue.substring(operator.length);
            };
        }

        /**
         * Retorna true sempre que a string iniciar com um determinado operador e seja seguida por um número.
         * Esse número pode ser decimal, separado por ponto.
         */
        function numericMatch(operator) {
            return function (filterValue) {
                if (_.startsWith(filterValue, operator)) {
                    var cleanFunction = numericClean(operator);
                    var filterWithoutOperator = cleanFunction(filterValue);

                    return filterWithoutOperator.match(/^-?\d+(\.\d{1,})?/);
                }

                return false;
            };
        }

        /**
         * Extrai o primeiro número (com ou sem decimal) encontrado na string e compara esse número com a string de busca
         * utilizando a função de comparação recebida por parâmetro.
         */
        function numericCompare(operatorCompare) {
            return function (filterValue, columnValue) {
                var match = columnValue.match(/-?\d+(\.\d{1,})?/);

                if (match) {
                    var matchValue = match[0];

                    return operatorCompare(Number(matchValue), Number(filterValue));
                }

                return false;
            };
        }

        /**
         * Verifica se o valor do filtro passado pertence a uma coluna que precisa ter o formato da versão validada,
         * caso precise, o valor recebido do filtro é validado.
         */
        function isRawFiltersValuesInvalid(columns, rawFilters) {
            const fieldsToVerifyIfInvalid = _.filter(columns, (column) => column.validateVersionFormat).map(field => field.field);

            const hasMoreThanOnePoint = function(filterValue) {
                return filterValue
                    && (filterValue.startsWith(">") || filterValue.startsWith("<"))
                    && (filterValue.match(/\./g) || []).length > 1;
            }

            return fieldsToVerifyIfInvalid.some(field => {
                if (service.isLogicFilter(rawFilters[field])) {
                    const operatorLogic = service.getOperator(rawFilters[field])
                    const parts = rawFilters[field].split(service.getSplitRegex(operatorLogic));

                    return parts.some(part => hasMoreThanOnePoint(part));
                }

                return hasMoreThanOnePoint(rawFilters[field])
            });
        }

        return service;
    }
]);