/**
 * Service that submits configuration changes to NES.
 *
 * @author bruno.vollino
 */
var app = angular.module("nms.dynamicDevice");

app.service("NesEditConfigService", ["$rootScope", "$websocket", "$window", "NesDataCacheService",
    "AuthenticationService", "$log", "NesErrorHandlerService", "Restangular",
    function($rootScope, $websocket, $window, NesDataCacheService, authenticationService, $log,
    nesErrorHandlerService, restangular) {
        this.editConfig = function(
                patch, onActivationCallback, onCacheUpdateCallback, onSuccessCallback, onFinishCallback) {
            $log.log("Trying to create websocket...");
            var deviceId = patch["target-resource-identifier"];

            var ws = $websocket("wss://" + $window.location.host + "/edit-config");

            ws.onOpen(function() {
                $log.log("Sending config patch...");
                ws.send(patch);
            });

            ws.onClose(function() {
                $log.log("Server Closed");
                onFinishCallback();
            });

            ws.onError(function() {
                $rootScope.showDialog({
                    translateKey: "error.websocket",
                    ngDialogOptions: {
                        appendClassName: "non-centered-dialog-message pre-formatted-text"
                    }
                });
            });

            ws.onMessage(function(event) {
                var eventData = angular.fromJson(event.data);
                var status = eventData.statusCode;

                if (status === "ACTIVATED") {
                    $log.log("Requesting Device...");
                    onActivationCallback();
                } else if (status === "FINISHED") {
                    var onCacheUpdateAndActivationCallback = function() {
                        onCacheUpdateCallback();
                        onFinishCallback();
                    };
                    NesDataCacheService.reloadData(deviceId, onCacheUpdateAndActivationCallback);
                    onSuccessCallback();
                } else if (status === "ERROR") {
                    onFinishCallback();
                    nesErrorHandlerService.handleError(eventData.error, deviceId, onCacheUpdateCallback);
                }
            });
        };
    }
]);
