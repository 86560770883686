"use strict";

import { IdentifierType } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";

var app = angular.module("nms.templates");

/**
 * Controller usada na modal de visualização de resultados de comandos de visualização em variáveis
 */
app.controller("CommandsResultsModalCtrl", [
    "$rootScope",
    "$scope",
    "$translate",
    "ActivationService",
    "varName",
    "deviceTemplateAssociations",
    "devicesVariables",
    "presentationMode",
    "commandsResults",
    "oldDeviceTemplateAssociations",
    "templateType",
    "ProgressBarService",
    "APPLICATION_STATUS",
    "PRESENTATION_MODE",
    "TemplateInstanceUtils",
    "EquipmentService",
    function (
        $rootScope,
        $scope,
        $translate,
        ActivationService,
        varName,
        deviceTemplateAssociations,
        devicesVariables,
        presentationMode,
        commandsResults,
        oldDeviceTemplateAssociations,
        templateType,
        ProgressBarService,
        APPLICATION_STATUS,
        PRESENTATION_MODE,
        TemplateInstanceUtils,
        EquipmentService
    ) {
        $rootScope.hideLoadingPanel = true;
        $scope.presentationMode = angular.copy(presentationMode);
        $scope.accordionData = [];
        $rootScope.deviceResults = [];
        $scope.selectedData = { parentId: null, childId: null };
        $scope.resultForVariable;
        $scope.oldDeviceTemplateAssociations = oldDeviceTemplateAssociations;
        $scope.stacked = {};
        $scope.addMarginInProgressBar = true;

        $scope.viewTypeResults = $translate.instant("templateinstanceform.viewapply.viewresults");
        $scope.showExecutionColumnsAtAccordion = false;

        function getEquipmentAssociationRequestDevice(equipmentIdentifier) {
            return $scope.results.equipmentAssociationResults.find((equipmentAssociation) =>
                TemplateInstanceUtils.resolveAccordionIdentifier(equipmentAssociation.equipmentIdentifier)
                    === TemplateInstanceUtils.resolveAccordionIdentifier(equipmentIdentifier)
            );
        }

        function getEquipmentAssociationRequestTemplate(currentDevice, templateIdentifier) {
            const templateId = TemplateInstanceUtils.resolveAccordionIdentifier(templateIdentifier);
            return currentDevice.templateIdentifiers.find((templateIdentifier) =>
                templateIdentifier.templateId === templateId
            );
        }

        $scope.getResultForEquipment = function (templateInstanceId, equipmentAssociationRequests) {
            $rootScope.equipmentResults = equipmentAssociationRequests.map((equipmentAssociationRequest) => {
                const currentDevice = getEquipmentAssociationRequestDevice(equipmentAssociationRequest.equipmentIdentifier);

                if (currentDevice) {
                    const currentTemplate = currentDevice
                        ? getEquipmentAssociationRequestTemplate(currentDevice, equipmentAssociationRequest.templateId)
                        : undefined;

                    return {
                        equipmentModel: currentDevice.equipmentDetails.model,
                        equipmentName: currentDevice.equipmentDetails.name,
                        templateName: currentTemplate.templateName,
                        applicationResult: currentTemplate.applicationResult
                    }
                }
            }).filter(association => association);
        };

        $scope.createCommandResultLabel = function (command) {
            if (command.applicationResult != "") {
                if ($scope.isDefaultVisualizationMode()) {
                    var equipmentLabel = TemplateInstanceUtils.getEquipmentLabelKey(templateType);
                    return (
                        $translate.instant(equipmentLabel) +
                        ": " +
                        command.equipmentName +
                        ", " +
                        $translate.instant("templateinstanceform.model") +
                        ": " +
                        command.equipmentModel
                    );
                } else {
                    return $translate.instant("templateinstanceform.template") + ": " + command.templateName;
                }
            }
        };

        $scope.isDefaultVisualizationMode = function () {
            return !$scope.presentationMode || $scope.presentationMode.value === PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;
        };

        $scope.createCommandTemplate = function (deviceId, varName, templateId) {
            var vars = _.find(devicesVariables, function (deviceVariable) {
                return deviceVariable.deviceId == deviceId;
            });
            return { deviceId: deviceId, varName: varName, templateId: templateId, variables: vars.variables };
        };

        $scope.proccessResult = function (variableCommandResponse) {
            if ($scope.results) {
                // Elementos de escopo
                var currentDevice = _.find($scope.results.equipmentAssociationResults, function (equipmentAssociation) {
                    return equipmentAssociation.equipmentIdentifier.resourceId == variableCommandResponse.deviceId;
                });

                var currentTemplate = _.find(currentDevice.templateIdentifiers, function (templateIdentifier) {
                    return templateIdentifier.templateId == variableCommandResponse.templateId;
                });

                var parentId = $scope.isDefaultVisualizationMode()
                    ? variableCommandResponse.templateId
                    : variableCommandResponse.deviceId;
                var childId = $scope.isDefaultVisualizationMode()
                    ? variableCommandResponse.deviceId
                    : variableCommandResponse.templateId;
                var oldStatus = angular.copy(currentTemplate.status);

                currentTemplate.status = variableCommandResponse.status;
                currentTemplate.applicationResult = variableCommandResponse.result;

                $scope.reloadAccordionData(parentId, childId, oldStatus, variableCommandResponse.status);
                var vars = _.find(devicesVariables, function (deviceVariable) {
                    return deviceVariable.deviceId == currentDevice.equipmentIdentifier.resourceId;
                });

                variableCommandResponse.vars = angular.copy(vars.variables);
                $scope.resultForVariable.variableCommandResponses.push(variableCommandResponse);

                ProgressBarService.calculateProgressBar(currentDevice);
            }
        };

        $scope.reloadAccordionData = function (parentId, childId, oldStatus, newStatus) {
            // Elementos visuais
            const parent = $scope.accordionData.find((accordionData) =>
                TemplateInstanceUtils.resolveAccordionIdentifier(accordionData.id) === parentId
            );

            if (parent) {
                const child = parent.children.find((childElement) =>
                    TemplateInstanceUtils.resolveAccordionIdentifier(childElement.id) === childId
                );

                child.status = newStatus;

                $scope.updateResults(oldStatus, parent, child);
            }
        };

        $scope.updateResults = function (oldStatus, parent, child) {
            const selectedDataChildId = $scope.selectedData.childId
                ? TemplateInstanceUtils.resolveAccordionIdentifier($scope.selectedData.childId)
                : undefined;
            const selectedDataParentId = TemplateInstanceUtils.resolveAccordionIdentifier($scope.selectedData.parentId);
            const accordionDataId = TemplateInstanceUtils.resolveAccordionIdentifier(parent.id)
            const childId =
                child && selectedDataChildId ? TemplateInstanceUtils.resolveAccordionIdentifier(child.id) : undefined;
            parent.status[oldStatus] = parent.status[oldStatus] ? parent.status[oldStatus] - 1 : 0;
            parent.status[child.status] = parent.status[child.status] ? parent.status[child.status] + 1 : 1;

            if (selectedDataParentId === accordionDataId && (!selectedDataChildId || selectedDataChildId == childId)) {
                $scope.showDetails(accordionDataId, childId);
            }
        };

        $scope.showDetails = function (parentId, childId) {
            let equipmentAssociationRequests = [];
            let equipmentId = childId;
            let templateId = parentId;

            if (!$scope.isDefaultVisualizationMode()) {
                equipmentId = parentId;
                templateId = childId;
            }

            if ($scope.isDefaultVisualizationMode()) {
                if (equipmentId) {
                    equipmentAssociationRequests.push({
                        equipmentIdentifier: { resourceId: equipmentId, type: IdentifierType.DEVICE_IDENTIFIER },
                        templateId: { id: templateId, type: IdentifierType.TEMPLATE_IDENTIFIER }
                    });
                } else {
                    $scope.results.equipmentAssociationResults.forEach((equipmentAssociation) => {
                        const templateAssociations = equipmentAssociation.templateResults || equipmentAssociation.templateIdentifiers;
                        const template = templateAssociations.find((templateAssociation) =>
                            templateAssociation.templateId == templateId
                        );

                        if (template) {
                            equipmentAssociationRequests.push({
                                equipmentIdentifier: equipmentAssociation.equipmentIdentifier,
                                templateId: { id: template.templateId, type: IdentifierType.TEMPLATE_IDENTIFIER }
                            });
                        }
                    });
                }
            } else {
                const selectedDevice = $scope.results.equipmentAssociationResults.find((equipmentAssociation) =>
                    TemplateInstanceUtils.resolveAccordionIdentifier(equipmentAssociation.equipmentIdentifier) == equipmentId
                );

                if (templateId) {
                    equipmentAssociationRequests.push({
                        equipmentIdentifier: selectedDevice.equipmentIdentifier,
                        templateId: { id: templateId, type: IdentifierType.TEMPLATE_IDENTIFIER }
                    });
                } else {
                    selectedDevice.templateIdentifiers.forEach(function (templateAssociation) {
                        equipmentAssociationRequests.push({
                            equipmentIdentifier: selectedDevice.equipmentIdentifier,
                            templateId: { id: templateAssociation.templateId, type: IdentifierType.TEMPLATE_IDENTIFIER }
                        });
                    });
                }
            }

            $scope.getResultForEquipment($scope.results.templateInstanceId, equipmentAssociationRequests);
        };

        $scope.reload = function () {
            ProgressBarService.clearProgressBarData();
            $scope.results.equipmentAssociationResults.forEach(function (equipmentAssociationResult) {
                equipmentAssociationResult.templateIdentifiers.forEach(function (template) {
                    var indexToRemove = _.findIndex($scope.resultForVariable.variableCommandResponses, {
                        deviceId: equipmentAssociationResult.equipmentIdentifier.resourceId,
                        templateId: template.templateId
                    });

                    if (indexToRemove > -1) {
                        $scope.resultForVariable.variableCommandResponses.splice(indexToRemove, 1);
                    }

                    indexToRemove = _.findIndex($rootScope.deviceResults, {
                        equipmentModel: equipmentAssociationResult.equipmentDetails.model,
                        equipmentName: equipmentAssociationResult.equipmentDetails.name,
                        templateName: template.templateName,
                        applicationResult: template.applicationResult
                    });

                    if (indexToRemove > -1) {
                        $rootScope.deviceResults.splice(indexToRemove, 1);
                    }

                    var parentId = $scope.isDefaultVisualizationMode()
                        ? template.templateId
                        : equipmentAssociationResult.equipmentIdentifier.resourceId;
                    var childId = $scope.isDefaultVisualizationMode()
                        ? equipmentAssociationResult.equipmentIdentifier.resourceId
                        : template.templateId;
                    var oldStatus = angular.copy(template.status);

                    template.status = APPLICATION_STATUS.APPLYING;
                    template.applicationResult = "";

                    $scope.reloadAccordionData(parentId, childId, oldStatus, template.status);
                    $scope.applyVariable(equipmentAssociationResult, template);
                });
            });
        };

        $scope.applyVariable = function (equipmentAssociation, template) {
            var equipmentId = equipmentAssociation.equipmentIdentifier.resourceId;
            var templateId = template.templateId;
            var vars = _.find(devicesVariables, function (deviceVariable) {
                return deviceVariable.deviceId == equipmentId;
            });

            var variableResponse = _.find($scope.resultForVariable.variableCommandResponses, function (variableCommandResponse) {
                return variableCommandResponse.deviceId == equipmentId && variableCommandResponse.templateId == templateId;
            });

            if (variableResponse && _.isEqual(variableResponse.vars, vars.variables)) {
                template.status = variableResponse.status;
                template.applicationResult = variableResponse.result;
            } else {
                if (variableResponse) {
                    var index = _.indexOf($scope.resultForVariable.variableCommandResponses, variableResponse);
                    $scope.resultForVariable.variableCommandResponses.splice(index, 1);
                }

                template.status = APPLICATION_STATUS.APPLYING;
                template.applicationResult = "";

                ActivationService.applyVariableCommands(
                    $scope.createCommandTemplate(
                        equipmentAssociation.equipmentIdentifier.resourceId,
                        varName,
                        template.templateId
                    )
                ).then($scope.proccessResult);
            }
        };

        $scope.getFirstParentId = function (deviceTemplateAssociations) {
            return $scope.isDefaultVisualizationMode()
                ? deviceTemplateAssociations[0].templateIdentifiers[0].templateId
                : deviceTemplateAssociations[0].equipmentIdentifier.resourceId;
        };

        $scope.init = function () {
            $scope.clearInvalidResults();

            $scope.resultForVariable = _.find(commandsResults, function (commandResult) {
                return commandResult.varName == varName;
            });

            if (!$scope.resultForVariable) {
                $scope.resultForVariable = { varName: varName, variableCommandResponses: [] };
                commandsResults.push($scope.resultForVariable);
            }

            deviceTemplateAssociations.forEach(function (equipmentAssociation) {
                equipmentAssociation.templateIdentifiers.forEach(function (template) {
                    $scope.applyVariable(equipmentAssociation, template);
                });
            });

            $scope.results = { equipmentAssociationResults: deviceTemplateAssociations };

            if (!$scope.selectedData) {
                var parentId = $scope.getFirstParentId(deviceTemplateAssociations);
                $scope.selectedData.parentId = parentId;
            }

            $scope.stacked = ProgressBarService.getProgressBarDataSource($scope.results.equipmentAssociationResults);

            $scope.results.equipmentAssociationResults.forEach(function (equipmentAssociationResult) {
                ProgressBarService.calculateProgressBar(equipmentAssociationResult);
            });
        };

        $scope.clearInvalidResults = function () {
            var variableAssociations = _.find(commandsResults, function (commandResult) {
                return commandResult.varName == varName;
            });

            if ($scope.oldDeviceTemplateAssociations[varName]) {
                if ($scope.oldDeviceTemplateAssociations[varName].length != deviceTemplateAssociations.length) {
                    deviceTemplateAssociations.forEach(function (deviceTemplateAssociation) {
                        $scope.clearResult(variableAssociations, deviceTemplateAssociation.equipmentIdentifier.resourceId);
                    });
                } else {
                    $scope.oldDeviceTemplateAssociations[varName].forEach(function (association) {
                        var currentAssociation = _.find(deviceTemplateAssociations, function (deviceTemplateAssociation) {
                            return association.equipmentId == deviceTemplateAssociation.equipmentIdentifier.resourceId;
                        });

                        if (
                            currentAssociation &&
                            association.templateIds.length != currentAssociation.templateIdentifiers.length
                        ) {
                            $scope.clearResult(variableAssociations, association.equipmentId);
                        }
                    });
                }
            }

            $scope.createAssociations();
        };

        $scope.clearResult = function (variableAssociations, deviceId) {
            var index = _.findIndex(variableAssociations.variableCommandResponses, { deviceId: deviceId });

            if (index > -1) {
                variableAssociations.variableCommandResponses.splice(index, 1);
            }
        };

        $scope.createAssociations = function () {
            var associations = [];

            deviceTemplateAssociations.forEach(function (equipmentAssociation) {
                var result: any = { equipmentId: equipmentAssociation.equipmentIdentifier.resourceId, templateIds: [] };
                equipmentAssociation.templateIdentifiers.forEach(function (template) {
                    result.templateIds.push(template.templateId);
                });

                associations.push(result);
            });

            $scope.oldDeviceTemplateAssociations[varName] = associations;
        };

        $scope.getTitle = function () {
            return (
                $translate.instant("templateinstancelisting.results") +
                " - " +
                $translate.instant("modals.results.variable") +
                varName
            );
        };

        $scope.getSpecificTranslationKeys = function () {
            return EquipmentService.getSpecificTranslationKeys(templateType);
        };

        $scope.isApplyingCommand = function () {
            var applying = 0;

            $scope.accordionData.forEach(function (accordionParent) {
                var applyingElem = _.find(accordionParent.children, function (child) {
                    return child.status == APPLICATION_STATUS.APPLYING;
                });

                if (applyingElem) {
                    applying += applyingElem.lenght;
                }
            });

            return applying != 0;
        };

        $scope.getProgressBarTitle = function () {
            return ProgressBarService.getProgressBarTitle(templateType);
        };

        $scope.accordion = {
            getAccordionData: function () {
                return $scope.accordionData;
            },
            getSelectedData: function () {
                return $scope.selectedData;
            },
            getPresentationMode: function () {
                return $scope.presentationMode;
            },
            getResults: function () {
                return $scope.results;
            },
            getResultForEquipment: $scope.getResultForEquipment,
            isShowReloadButton: function () {
                return true;
            },
            reload: $scope.reload,
            isApplyingCommand: $scope.isApplyingCommand
        };

        $scope.init();
    }
]);
