import { ErrorDescriptionDetails, ErrorResponse, NmsError } from "./error-response-handler.model";

/**
 * Serviço para lidar com mensagens de erros recebidas através de um @see ErrorResponse.
 */
export abstract class AbstractErrorResponseHandlerService {

    private readonly DEFAULT_DETAILS_SEPARATOR: string = "<br>"

    public errorEnumeration;

    constructor(private enumeration: any = NmsError) {
        this.errorEnumeration = enumeration;
    }

    /**
     * Gera um @see ErrorDescriptionDetails, com a descrição do erro ocorrido e seus detalhes, se existirem.
     *
     * @param errorResponse    @see ErrorResponse
     * @param status           código de status da resposta.
     * @param detailsSeparator Utilizado para concatenar detalhes referentes as mensagens.
     *                         Se omitido, o valor de @see DEFAULT_DETAILS_SEPARATOR será utilizado.
     *
     * @returns @see ErrorDescriptionDetails
     */
    public abstract buildErrorDescriptionDetails(errorResponse: ErrorResponse,
                                                 detailsSeparator?: string,
                                                 status?: number): ErrorDescriptionDetails;

    /**
     * Gera os detalhes de erro de acordo com o tipo de erro recebido.
     *
     * @param errorCode código do erro
     * @param details Array com detalhes de erro.
     * @param detailsSeparator String utilizada como separador entre os detalhes.
     *
     * @returns Array com os detalhes de erro processados.
     */
    public abstract buildErrorDetails(errorCode: number, details: Array<string>, detailsSeparator?: string): string;


    public getDefaultSeparator(): string {
        return this.DEFAULT_DETAILS_SEPARATOR;
    }

    /**
     * Caso exista um valor no primeiro elemento dentro de detalhes,
     * retorna ele em maiúsculo.
     */
    public getErrorKeyFromDetails(details: Array<string>): string {
        let error = "";

        if (details && details.length > 0) {
            let key: string = details[0];
            error = key.toUpperCase();
        }

        return error;
    }

    /**
     * Realiza a procura do erro ocorrido no enum, caso encontre,
     * retorna a chave para buscar a mensagem de erro ocorrida no arquivo de tradução
     */
     public getErrorKeyFromEnum(errorCode: number): any {
        return Object
                .keys(this.errorEnumeration)
                .filter(index => this.errorEnumeration[index] === errorCode);
    }
}
