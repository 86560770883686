var app = angular.module("nms.audit", []);

app.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("features/audit");
}]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);
