const schedulerModule  = angular.module("nms.scheduler", []);

schedulerModule.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("features/scheduler");
}]);

schedulerModule.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);
