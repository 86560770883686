"use strict";

/**
* @ngDoc directive
* @name nms.allowedValueValidator
* @description  Verifica se o valor presente em ngModal não está contido na lista notAllowedValues.
*               Esta diretiva adiciona um $validator em um input chamado "allowedValueValidator".
*               Isto possibilitará que o elemento fique com a borda vermelha em caso de valores inválidos.
* @scope: {
*   notAllowedValues:  [array] -   Lista com dados inválidos para adição.
* }
*/
var app = angular.module("nms");
app.directive("allowedValueValidator", [
    function() {
        return {
            require: "ngModel",
            restrict: "A",
            scope: {
                notAllowedValues: "="
            },
            link: {
                post: function(scope, elm, attrs, ctrl) {
                    ctrl.$validators.allowedValue = function(modelValue, viewValue) {
                        return !_.any(scope.notAllowedValues, function(notAllowedValue) {
                            if (!_.isUndefined(notAllowedValue) && !_.isUndefined(modelValue)) {
                                return notAllowedValue.toLowerCase() === modelValue.toLowerCase();
                            }
                            return true;
                        });
                    };
                }
            }
        };
    }
]);
