"use strict";

/**
 * Filter to order two values by numbers and letters.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.filter("orderByNumbersAndLetters", ["$filter", function($filter) {
    return function(items, caseInsensitive) {
        return items.sort( function(value1, value2) {
            if (!isNaN(value1)) {
                value1 = parseInt(value1);
            }

            if (!isNaN(value2)) {
                value2 = parseInt(value2);
            }

            var type1 = typeof value1;
            var type2 = typeof value2;

            if (type1 > type2) {
                return -1;
            } else if (type1 < type2) {
                return 1;
            } else if (_.isString(value1) && _.isString(value2)) {
                return $filter("alphanum")(value1, value2, caseInsensitive);
            } else if (_.isNumber(value1) && value1 !== value2) {
                return value1 > value2 ? 1 : -1;
            } else {
                return 0;
            }
        });
    };
}]);

