"use strict";

/**
* @ngdoc service
* @name nms.deviceSummary.DeviceStatusService
* @description
* # LocationService - Serviço que chama o REST para busca de status de equipamento.
* Factory in the nms.deviceSummary.
*/
var app = angular.module("nms.deviceSummary");

app.factory("DeviceStatusService", ["DeviceStatusRESTService", function(DeviceStatusRESTService) {
    const service: any = {};

    service.getStatus = function(resourceId) {
        return DeviceStatusRESTService.getStatus(resourceId);
    };

    return service;
}]);
