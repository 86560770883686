"use strict";

/**
* @ngdoc directive
* @name nms.directive:templatesByKeywords
* @description Diretiva para criar listagem de templates por keywords.
* @restrict E
* @scope
* @param {String} words palavras que devem estar contidas nas keywords.
* @param {Function} paramsToApply Responsável por retornar os parametros do template que será aplicado.
* @param {Function} handleKeywords Responsável realizar a interseção das keywords. Estas regras são definidas pela controller.
*
* @example
* Os elementos utilizados no <ng-transclude> devem ser do tipo <li>, podendo ser mais de 1.
*/
angular.module("nms.templates")
.directive("templatesByKeywords", ["$rootScope", "DropdownDeviceService", "TemplateService", "$timeout",
    function($rootScope, DropdownDeviceService, TemplateService, $timeout) {
        return {
            restrict: "E",
            templateUrl: "templates/components/ui/templates-by-keywords/templates-by-keywords.html",
            scope: {
                words: "=",
                paramsToApply: "=",
                handleKeywords: "=?"
            },
            transclude: true,
            link: function(scope) {
                scope.keywordsTemplates = [];

                /**
                * Remove templates com obrigatoriedade de preenchimento manual de nome em aplicações de template.
                */
                var removeApplicationNameManualTemplates = function(keywordsTemplates) {
                    var filteredKeywordsTemplates = [];
                    keywordsTemplates.forEach(function(keywordTemplates) {
                        var filteredTemplates = _.filter(keywordTemplates.templatesIdentifiers, "applicationNameManual", false);
                        if (!_.isEmpty(filteredTemplates)) {
                            keywordTemplates.templatesIdentifiers = filteredTemplates;
                            filteredKeywordsTemplates.push(keywordTemplates);
                        }
                    });

                    return filteredKeywordsTemplates;
                };

                let successGetKeywordsTemplates = function(keywordsTemplatesResponses: any[]) {
                    const keywordsTemplates = [];

                    let templates = keywordsTemplatesResponses.flatMap(keywordsTemplatesResponse =>
                        removeApplicationNameManualTemplates(keywordsTemplatesResponse.plain()));

                    if (scope.handleKeywords) {
                        templates = scope.handleKeywords(templates);
                    }

                    templates.forEach(function(keywordTemplates) {
                        var shortcuts =
                            DropdownDeviceService.getShortcutToKeywordsTemplates(keywordTemplates, scope.paramsToApply);

                        keywordsTemplates.push({
                            keyword: keywordTemplates.keyword,
                            templateShortcuts: shortcuts
                        });
                    });

                    scope.keywordsTemplates = keywordsTemplates;

                    // Atualiza a altura da tabela após o bind dos templates na tela.
                    $timeout(function() {
                        $rootScope.$broadcast("updateTableHeight");
                    });
                };

                const promises = scope.words.map(word => TemplateService.getTemplatesByKeywords(word));
                Promise.all(promises).then(successGetKeywordsTemplates);
            }
        };
    }
]);