/**
 * Verifies integrity of list keys.
 *
 * @author patrick.bard
 */
var app = angular.module("nms.dynamicDevice");

app.factory("DuplicateKeyRestrictionLink", ["ListService", function(listService) {
    return function(scope, element, attrs, controllers) {
        var formController = controllers[0];
        var listBodyController = controllers[1];

        var setIsUniqueError = function(isUnique) {
            formController.$setValidity("unique", isUnique);
        };

        setIsUniqueError(true);

        var isValidNewEntry = function(listSchema, keys, entries, newEntry) {
            var hasDuplicate = listService.hasDuplicateEntry(listSchema, entries, newEntry);
            setIsUniqueError(!hasDuplicate);

            return !hasDuplicate;
        };

        listBodyController.registerNewEntryValidator(isValidNewEntry);

        scope.$watch(listBodyController.getNewEntry, function(newEntry, oldEntry) {
            if (newEntry !== oldEntry) {
                setIsUniqueError(true);
            }
        }, true);
    };
}]);

app.directive("duplicateKeyRestriction", ["DuplicateKeyRestrictionLink",
    function(duplicateKeyRestrictionLink) {
        return {
            restrict: "A",
            require: ["^form", "^^listbody"],
            link: duplicateKeyRestrictionLink
        };
    }
]);
