"use strict";

/**
* @ngdoc directive
* @description Diretiva de busca com a possibilidade do uso de highlight para ocorrências encontradas.
*              Para dar foco ao elemento de busca, pode-se usar a hotkey "ctrl+shift+f".
* Referência: https://markjs.io
* scope:
* @param {String} [identifier] <Opcional> Identificador para armazenar os dados na sessionStorage. Caso o identifier não seja
                                definido, os dados não serão salvos/resgatados de sessionStorage.
* @param {Object} [config]
* @param {String} [config.id]   Define o Id único do componente, possibilitando utilizar mais de uma instância de
                                text-finder-directive na mesma tela.
* @callback [config.filterContent] função executada ao fim de cada busca.
* @param {string} [config.searchableElementSelector = "*"] seletor jquery dos elementos onde o texto deve ser buscado.
* @param {boolean} [enabled = true] habilita/desabilita a caixa de busca.
* @param {string[]} [config.performSearchOnEvents] lista de eventos que devem disparar uma execução da busca.
* @callback [config.clearSearch] função executada após o texto na caixa de busca ser apagado.
* @param {boolean} [config.baseElementSelector = "body"] seletor jquery para o elemento base da busca.
*                                                        Só serão buscados os valores dentro desse elemento base.
* @param {number} [config.minTermSize = 1] número mínimo de caracteres digitados para iniciar a execução de uma busca.
*                                          A busca só será executada quando o texto for maior ou igual esse número, ou vazio.
* @param {number} [config.delay = 1000] intervalo em milisegundos até a busca ser iniciada, após o usuário parar de digitar.
* @param {string} [config.placeholder = ""] placeholder para o text input de busca.
* @param {Object} [config.hotkey]   <Opcional> Usado para definir uma ação de hotkey para o componente.
* @param {String} [config.hotkey.combo] Keys utilizadas para disparar o evento de atalho. Ex: "P", "shift+p". Obs.: O Componente
                                        de hotkeys utilizado não responderam as chamadas das hotkeys quando a combo está definida
                                        em uppercase. Ex.: "Shift+P"
* @param {String} [config.hotkey.description]   Descreve a ação da hotkey.
* @param {Function} [config.hotkey.callback]    Função a ser chamada na execução da hotkey.
*/
var app = angular.module("nms");

app.directive("textFinder", ["hotkeys",
    function(hotkeys) {
        return {
            restrict: "E",
            scope: {
                config: "=?",
                identifier: "@"
            },
            controller: "TextFinderController",
            template: "<input class='form-control' ng-model='config.searchTerm' ng-change='onChange()' id='{{config.id}}'"
                        + " ng-readonly='!config.enabled' placeholder='{{config.placeholder}}'/>"
                        + " <span class='glyphicon glyphicon-info-sign' tooltip-placement='bottom' "
                        + " uib-tooltip-html='tooltip'></span>",
            compile: function compile() {
                return {
                    pre: function preLink(scope) {
                        if (scope.config.hotkey) {
                            /*
                            * O Componente de hotkeys não aceita a combo de comandos utilizando uppercase.
                            * Portanto, o combo deve ser mostrado para o usuário com as primeiras letras em uppercase.
                            */
                            var upperCaseFirstLetter = function(value) {
                                return value.replace(/^\w/, function(letter) {
                                    return letter.toUpperCase();
                                });
                            };

                            var combo = scope.config.hotkey.combo;
                            var formattedHotkey = _.map(combo.split("+"), upperCaseFirstLetter).join("+");

                            scope.tooltip = scope.config.placeholder + (scope.config.placeholder ? "<br/>" : "")
                                            + formattedHotkey;
                            scope.config.placeholder += (scope.config.placeholder ? "    " : "") + formattedHotkey;
                            hotkeys.bindTo(scope).add(scope.config.hotkey);
                        }
                    }
                };
            }
        };
    }
]);
