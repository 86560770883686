"use strict";

/*
* @ngDoc          Service
* @name           nms.management.PermissionsValidatorService
* @description    Validador para permissões de usuários e grupos.
* @requires       [$rootScope, NMS_FEATURES]
*/
var app = angular.module("nms.management");
app.service("PermissionsValidatorService", [
    "$rootScope",
    "NMS_FEATURES",
    function ($rootScope, NMS_FEATURES) {
        const service: any = {};

        const findFeature = function (features, searchFeature) {
            return _.find(features, function (feature) {
                return feature.name === searchFeature;
            });
        };

        const showErrorMessage = function (messageKey) {
            $rootScope.showDialog({
                translateKey: messageKey
            });
        };

        /**
         * Valida as permissões de features.
         *
         * Regras de validação:
         *     - A permissão 'templateConfig' só pode ser habilitada se a permissão 'template' também estiver habilitada.
         */
        const validateFeatures = function (featuresData) {
            var selectedFeatures = featuresData.selection.checked;

            if (
                findFeature(selectedFeatures, NMS_FEATURES.templateConfig.feature) &&
                !findFeature(selectedFeatures, NMS_FEATURES.template.feature)
            ) {
                showErrorMessage("manage.permissions.features.templateConfig.validate");

                return false;
            }

            return true;
        };

        const hasFeature = function (features, featureName) {
            return _.some(features, { name: featureName });
        };

        /**
         * Verifica se nenhuma regra de restrição está vazia.
         */
        const hasTemplateRuleTypeEmpty = function (templatesData) {
            return _.some(templatesData.restrictions.rules, function (rule) {
                return _.isEmpty(rule.values) || _.includes(rule.values, "", null, undefined);
            });
        };

        /**
         * Verifica se contêm a feature 'templateConfig'.
         */
        const hasTemplateConfigFeature = function (featuresData) {
            return hasFeature(featuresData, NMS_FEATURES.templateConfig.feature);
        };

        /**
         * Verifica se não contêm a feature 'templateInstance'.
         */
        const notContainsTemplateInstanceFeature = function (featuresData) {
            return !hasFeature(featuresData, NMS_FEATURES.templateInstance.feature);
        };

        /**
         * Valida as permissões de templates.
         *
         * Regras de validação:
         *     - Não são permitidas restrições vazias.
         *     - Não é permitido configurar restrições de template se a feature 'templateConfig' estiver habilitada.
         *     - Não é permitido configurar restrições de template se a feature 'templateInstance' estiver desabilitada.
         */
        const validateTemplateRestrictions = function (templatesData, featuresData) {
            if (templatesData.restrictions.useRules) {
                if (hasTemplateRuleTypeEmpty(templatesData)) {
                    showErrorMessage("manage.permissions.templates.empty.restrictions");
                    return false;
                } else if (hasTemplateConfigFeature(featuresData.selection.checked)) {
                    showErrorMessage("manage.permissions.templates.templateConfig.permission.validate");
                    return false;
                } else if (notContainsTemplateInstanceFeature(featuresData.selection.checked)) {
                    showErrorMessage("manage.permissions.templates.restrictions.without.permisison");
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };

        /*
         * @ngDoc  method
         * @name   nms.management.PermissionsValidatorService#validate
         * @param  {form}  Objeto que contem os dados de permissões.
         * @return {boolean} True se as permissões são válidas, false caso contrário.
         */
        service.validate = function (form) {
            return validateFeatures(form.features.data) && validateTemplateRestrictions(form.templates.data, form.features.data);
        };

        /*
         * Verifica se o checkbox de restrição de templates está desmarcado, porém contém critérios de restrição associados
         * @return {boolean} True se a condição for verdadeira, false caso contrário.
         */
        service.isCheckDisabledAndRestrictionsNotEmpty = function (templatesData) {
            return (
                templatesData &&
                templatesData.restrictions &&
                !templatesData.restrictions.useRules &&
                templatesData.restrictions.rules &&
                !hasTemplateRuleTypeEmpty(templatesData)
            );
        };

        return service;
    }
]);
