import { Injectable } from "@angular/core";
import { InformTriggerData } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models"

/**
 * Classe responsável por realizar a validação do agendamento por evento
 */
@Injectable({
    providedIn: "root"
})
export class InformTriggerValidator {
    constructor() {}

    validate(informTriggerData: InformTriggerData): void {
        if (_.isEmpty(informTriggerData.eventCodes)) {
            throw new Error("scheduler.validation.error.invalidInformEvent");
        }
    }
}
