"use strict";

/**
 * Filter that removes a html line break tag (<br/>) on a given input.
 */

var app = angular.module("nms");

app.filter("removeLineBreak",
    function() {
        return function(input) {
            return _.isString(input) ? input.replace(/\<br\s*[\/]?>/g, "") : input;
        };
    }
);
