import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { ClipboardService } from "ngx-clipboard";
import { AcsServiceStatus } from "../acs-service-status";

/**
 * Componente responsável por exibir a URL no formato URL: urlValue  + ícone representando o status + ícone traduzido
 * Exemplo: URLS http://www.datacom.com.br ícone Offline.
 */
@Component({
  selector: "acs-url-status",
  templateUrl: "./acs-url-status.component.html",
  styleUrls: ["./acs-url-status.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcsUrlStatusComponent implements OnChanges, OnInit {
  @Input() url: string;
  @Input() status: AcsServiceStatus;
  @Input() showLabelUrl: boolean;
  showTipIcon: boolean;

  readonly LOCALHOST: Array<string> = ["localhost", "127.0.0.1"];

  constructor(
    @Inject(ANGULARJS_TRANSLATE) private translate: any,
    private clipboardService: ClipboardService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.url = "";
    this.status = AcsServiceStatus.REFRESHING;
    this.showLabelUrl = true;
    this.showTipIcon = false;
  }

  ngOnInit(): void {
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.url && this.isAcsRunningOnLocalhost(changes.url.currentValue)) {
      this.url = this.translate.instant("credentials.url.unavailable");
      this.showTipIcon = true;
    }
  }

  copyUrl(): void {
    this.clipboardService.copyFromContent(this.url);
  }

  getAcsStatusClass(): string {
    switch (this.status) {
      case AcsServiceStatus.OFFLINE:
        return "icon-offline";
      case AcsServiceStatus.UNAUTHORIZED:
        return "icon-unauthorized";
      case AcsServiceStatus.REFRESHING:
        return "fa fa-spinner fa-spin fa-pulse";
      case AcsServiceStatus.ONLINE:
        return "icon-online";
    }
  }

  getAcsStatusLabelKey(): string {
    switch (this.status) {
      case AcsServiceStatus.OFFLINE:
        return "credentials.configuration.offline";
      case AcsServiceStatus.UNAUTHORIZED:
        return "credentials.configuration.unauthorized";
      case AcsServiceStatus.REFRESHING:
        return "credentials.configuration.refreshing";
      case AcsServiceStatus.ONLINE:
        return "credentials.configuration.online";
    }
  }

  private isAcsRunningOnLocalhost = (value: string): boolean => {
    return value && this.LOCALHOST.some((host) => value.includes(host));
  };
}
