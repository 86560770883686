"use strict";

/**
* @ngdoc service
* @name nms.ServerPaginationService
* @description
* # ServerPaginationService
* Service in the nms.
*/
var app = angular.module("nms");

app.factory("ServerPaginationService", ["TABLE_PROPERTIES",
    function(TABLE_PROPERTIES) {
        const service: any = {};

        var defaultPageDetails: any = {
            currentPage: 1,
            totalPages: 1,
            viewFrom: 0,
            viewTo: 0,
            totalElements: 0
        };

        var defaultFilters: any = {
            pageNumber: 0,
            pageSize: TABLE_PROPERTIES.DEFAULT_PAGE_SIZE,
            direction: "DESC"
        };

        service.getPageDetails = function(options) {
            return _.merge(angular.copy(defaultPageDetails), options);
        };

        service.getFilters = function(options) {
            return _.merge(angular.copy(defaultFilters), options);
        };

        service.updatePageDetails = function(pageDetails, totalElements, pageNumber, pageSize) {
            pageDetails.totalElements = totalElements;
            pageDetails.totalPages = Math.ceil(totalElements / pageSize);
            pageDetails.viewFrom = pageNumber * pageSize + 1;
            pageDetails.viewTo = Math.min(pageDetails.viewFrom - 1 + pageSize, totalElements);
            pageDetails.currentPage = pageNumber + 1;

            return pageDetails;
        };

        service.updateFilters = function(filters, pageSize, pageNumber) {
            filters.pageSize = pageSize;
            filters.pageNumber = pageNumber;

            return filters;
        };

        return service;
    }
]);
