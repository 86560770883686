"use strict";

/**
* @ngdoc directive
* @name nms.directive:hideOnFocus
* @description Diretiva para esconder um elemento quando o input estiver em foco.
*/
angular.module("nms")
.directive("hideOnFocus", function($timeout) {
    return {
        restrict: "A",
        scope: {
            id: "@hideOnFocus"
        },
        link: function(scope, elem, attrs) {
            $timeout(function() {
                $(elem).on("focus", function(event) {
                    angular.element(scope.id)[0].hidden = true;
                });
            }, 0);
        }
    };
});
