"use strict";

var app = angular.module("AuthenticationModule", [
    "ngRoute",
    "ui.router",
    "restangular",
    "ngDialog",
    "toastr"
]);

app.constant("AUTH_EVENTS", {
    loginSuccess: "authLoginSuccess",
    loginFailed: "authLoginFailed",
    logoutSuccess: "authLogoutSuccess",
    sessionTimeout: "authSessionTimeout",
    notAuthenticated: "authNotAuthenticated",
    notAuthorized: "authNotAuthorized",
    startWebSocket: "startWebSocket"
});

app.config(["RestangularProvider", function(RestangularProvider) {
    RestangularProvider.setBaseUrl("/");
}]);

app.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("components/authentication");
}]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);

app.run(["$rootScope", "$state", "AuthenticationService", "AUTH_EVENTS", "NMS_STATES", "WindowService",
    "$window", "$cookies",
    function($rootScope, $state, authenticationService, AUTH_EVENTS, NMS_STATES, WindowService, $window, $cookies) {
        $rootScope.credentials = {username: "", password: ""};
        $rootScope.showCaptcha = false;

        $rootScope.getNextCaptcha = function() {
            authenticationService.getNextCaptcha().then(function(response) {
                if (response) {
                    $rootScope.captchaImage = response;
                    $rootScope.showCaptcha = true;
                    $rootScope.credentials.captcha = "";
                } else {
                    $rootScope.showCaptcha = false;
                }
            });
        };

        $rootScope.redirectTo = function(state) {
            $state.go(state);
        };

        $rootScope.prepareToNewLogin = function(isRedirect) {
            authenticationService.clearLoggedInformation();
            if (isRedirect) {
                $state.go(NMS_STATES.login, {redirectTo: $state.current.name, redirectParams: $state.params});
            } else {
                $state.go(NMS_STATES.login);
            }
            $rootScope.$broadcast(AUTH_EVENTS.logoutSuccess);
        };

        $rootScope.isAuthenticated = function() {
            return localStorage.getItem("isAuthenticated") === "true";
        };

        $rootScope.doLogin = function(credentials) {
            if (!credentials.username) {
                $rootScope.loginError = "authentication.alert.requiredUsername";
            } else if (!credentials.password) {
                $rootScope.loginError = "authentication.alert.requiredPassword";
            } else if ($rootScope.showCaptcha && !credentials.captcha) {
                $rootScope.loginError = "authentication.alert.requiredCaptcha";
            } else {
                authenticationService.login(credentials);
            }
        };

        $rootScope.doLogoff = function() {
            authenticationService.logoff().then(function() {
                $rootScope.prepareToNewLogin(false);
            });
        };

        $rootScope.hasPermission = function(module) {
            return authenticationService.hasPermission(module);
        };

        $rootScope.getCsrfCookie = function() {
            return $cookies.get("XSRF-TOKEN");
        };
    }
]);

app.run(["$rootScope", "$state", "AuthenticationService", "SsoService", "LicenseService", "$location", "AUTH_EVENTS",
    "FeatureAccessControl", "WindowService", "NMS_STATES", "NMS_ERRORS",
    function($rootScope, $state, authenticationService, ssoService, licenseService, $location, AUTH_EVENTS, featureAccessControl,
             windowService, NMS_STATES, NMS_ERRORS) {
        var unregisterAuthLoginFailed = $rootScope.$on(AUTH_EVENTS.loginFailed, function(event, response) {
            if (response.data.code == NMS_ERRORS.INVALID_CAPTCHA) {
                $rootScope.loginError = "authentication.alert.invalidCaptcha";
            }
            $rootScope.getNextCaptcha();
        });

        /*
         * Devido a inconsistência entre os dados de autenticação salvos no localStorage (que não são perdidos ao fechar o
         * navegador sem deslogar) e os dados reais (vindos do servidor) optou-se pela utilização da API REST para determinar
         * se um usuário estava realmente autenticado. Porém, devido as requisições REST serem assíncronas era possível navegar
         * entre as páginas mesmo sem autenticação caso o serviço demorasse para responder, e de forma tardia o sistema era
         * redirecionado para página indicativa do erro ou alertas eram exibidos para o usuário.
         * Para evitar este comportamento inadequado foi implementado um RESOLVE responsável por esperar a resposta do servidor
         * antes de executar qualquer navegação entre as páginas e, caso houver necessidade, fazer os devidos redirecionamentos
         * ou apresentar as mensagens de erro adequadas.
         */
        var unregisterStateChanged = $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState) {
            // TODO: Avaliar trazer todas as dependências básicas da aplicação para este ponto, como por exemplo as informações de
            // licença, que hoje são requisitadas no nms-routes, como depência do estado 'NMS_STATES.root'. A vantagem de
            // tratar estas dependências aqui, é que temos a oportunidade de validar e requisitar novamente as informações caso
            // necessário, antes de qualquer navegação do sistema. Por exemplo, com relação a licença, ela fica armazenada na
            // Local Storage, e como sua requisição é feita apenas na inicialização do 'NMS_STATES.root', caso seja feita a
            // limpeza do Local Storage, precisamos requisitá-la novamente. Se esta requisição fosse feita aqui, este controle
            // seria mais fácil e centralizado. Para contornar essa questão da licença de forma mais rápida, foi adicionado um
            // '$watch' em license-service.js, que requisita a licença automaticamente caso seu valor seja removido. Entendo que
            // esta não é a melhor solução e que o fluxo deve ser melhorado num momento oportuno.
            toState.resolve.authentication = ["$q", function($q) {
                var defer = $q.defer();
                var queryParams = $location.$$url.split("?");

                if (queryParams[1]) {
                    $location.$$url = queryParams[0];
                    $rootScope.queryParams = queryParams[1];
                }

                ssoService.isNamEnabled().then(function(namEnabled) {
                    authenticationService.isAuthenticated().then(function(isAuthenticated) {
                        if (isAuthenticated) {
                            var firstAllowedFeature = authenticationService.getFirstAllowedFeature();
                            if (authenticationService.isAccessState(toState)) {
                                $state.go(firstAllowedFeature.state);
                                defer.reject();
                            } else if (toState.name === NMS_STATES.forbidden || toState.name === NMS_STATES.unavailableAcsServer) {
                                if (!toParams.redirect) {
                                    $state.go(firstAllowedFeature.state);
                                    defer.reject();
                                } else {
                                    defer.resolve();
                                    return;
                                }
                            } else {
                                var requirements = featureAccessControl.getRequirementsFor(toState.data.feature);
                                var hasPermission = !requirements.requiredPermission || authenticationService.hasPermission(requirements.requiredPermission);
                                var hasLicense = licenseService.hasLicense(requirements.requiredLicense);
                                var hasRoutePermission = authenticationService
                                    .hasRoutePermission(toState.data.route, requirements.routesAccessRules);
                                var notAuthorized = !hasPermission || !hasLicense || !hasRoutePermission;

                                if (notAuthorized) {
                                    defer.reject();
                                    var messageKey = !hasLicense ? "login.error.licenseNotEnabled" : "login.error.noPermission";

                                    if ($state.current.url === $location.url() && !($state.params.redirectTo)) {
                                        $rootScope.showDialog({
                                            translateKey: messageKey
                                        });
                                    } else {
                                        $rootScope.forbiddenError = messageKey;
                                        $state.go(NMS_STATES.forbidden, {redirect: true});
                                    }
                                } else {
                                    defer.resolve();
                                    return;
                                }
                            }
                        } else {
                            handleNotAuthenticatedStateChangeAttempt(event, toState, toParams, namEnabled, defer);
                        }
                    });
                });

                return defer.promise;
            }];
        });

        var unregisterStateChangedSuccess = $rootScope.$on("$stateChangeSuccess", function(event, toState, toParams, fromState) {
            $rootScope.previousState = fromState.name;
        });

        var handleNotAuthenticatedStateChangeAttempt = function(event, toState, toParams, namEnabled, defer) {
            var expectedAccessState = namEnabled ? NMS_STATES.namCredentials : NMS_STATES.login;

            if (expectedAccessState !== toState.name) {
                defer.reject();

                authenticationService.checkUserAuthentication().then(function(authenticated) {
                    if (authenticated) {
                        $state.go(toState.name, toParams);
                    } else {
                        if (namEnabled) {
                            $state.go(NMS_STATES.namCredentials);
                        } else {
                            $state.go(NMS_STATES.login, {redirectTo: toState.name, redirectParams: toParams});
                        }
                    }
                });
            } else {
                defer.resolve();
            }
        };

        var unregisterUsernameWatcher = $rootScope.$watch(
            function() {
                return localStorage.getItem("username");
            }, function(newValue, oldValue) {
            if (oldValue !== null && newValue !== null && newValue !== oldValue) {
                $rootScope.toastError("authentication.toast.userChanged");
            }
        });

        var unregisterIsAuthenticatedWatcher = $rootScope.$watch(
            function() {
                return localStorage.getItem("isAuthenticated");
            }, function(newValue, oldValue) {
            if (newValue !== oldValue && newValue !== "true" && !_.isEqual(authenticationService.getUserSessionData(), null)) {
                $rootScope.toastError("authentication.toast.userLoggedOut");
                $rootScope.$broadcast(AUTH_EVENTS.logoutSuccess);
                $state.go(NMS_STATES.login);
            }
        });

        $rootScope.$on("$destroy", function() {
            unregisterAuthLoginFailed();
            unregisterStateChanged();
            unregisterStateChangedSuccess();
            unregisterUsernameWatcher();
            unregisterIsAuthenticatedWatcher();
        });
    }
]);

