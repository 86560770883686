"use strict";

/**
 * String manipulation filters.
 *
 * @author patrick.bard
 */

// TODO: This should not be a filter.

var app = angular.module("nms");

app.filter("fileNameGenerator",
    function() {
        return function(fileNamePrefix, extension) {
            var date = new Date();
            var df = moment(date).format("YYYYMMDDHHmmss");

            return fileNamePrefix + df + extension;
        };
    }
);
