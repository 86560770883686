"use strict";

/**
 * @ngdoc service
 * @name nms.components.modals.propertiesEditModal:PropertiesUpdateModel
 * @description
 *
 * # PropertiesUpdateModel
 *
 * Modelo de dados utilizados pela API REST para atualização de propriedades de equipamentos.
 *
 * @author andre.pires
 */

var app = angular.module("nms");

app.service("PropertiesUpdateModel",
    function() {
        function PropertiesUpdateModel(device, properties, updateName?) {
            // Device identifiers
            this.hostname = device.hostname;
            this.resourceId = device.id;
            this.proxyCode = device.proxyCode;
            this.devNo = device.devNo;
            this.devLocalId = device.devLocalId;
            this.modelCode = device.modelCode;
            this.vendorCode = device.vendorCode;
            this.serialNumber = device.serialNumber;
            this.manageable = device.manageable;

            // Device properties
            this.notes = properties.notes;
            this.project = properties.project;
            this.devId = updateName ? properties.name : device.name;
            this.externalLocation = {
                address: properties.address,
                city: properties.city,
                state: properties.state,
                country: properties.country
            };
            this.internalLocation = {
                station: properties.station,
                room: properties.room,
                shelf: properties.shelf
            };
        }

        return PropertiesUpdateModel;
    }
);
