import { AfterViewInit, Component, Inject, ViewChild, OnDestroy, OnInit } from "@angular/core";
import { DcbRestoreRequest, DeviceBackupConfigurationView } from "./device-backup.models";
import { DeviceBackupService } from "./device-backup.service";
import { Observable, Subscription } from "rxjs";
import {
    AutoUpdaterDataResult, NmsAutoUpdaterComponent
} from "@nms-ng2/app/shared/components/elements/nms-auto-updater/nms-auto-updater.component";
import { DeviceBackupTableComponent } from "./device-backup-table/device-backup-table.component";
import { RefreshIntervalService } from "@nms-ng2/app/shared/services/refresh-interval/refresh-interval.service";
import {
    ANGULARJS_ROOTSCOPE, ANGULARJS_TRANSLATE, LOADING_PANE_SERVICE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { DeviceBackupErrorResponseHandlerService } from "./device-backup-error-response-handler.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DcbError } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";

/**
 * Tela de backup de equipamentos.
 */
@Component({
    selector: "device-backup",
    templateUrl: "./device-backup.component.html"
})
export class DeviceBackupComponent implements OnInit, AfterViewInit, OnDestroy {
    autoUpdateInterval: number;
    backupsProvider;
    restoreEventEmmiterSubscription: Subscription;
    restoreInProcessMessage: string;

    @ViewChild(DeviceBackupTableComponent)
    devicesBackupTable: DeviceBackupTableComponent;

    @ViewChild(NmsAutoUpdaterComponent)
    mmsAutoUpdaterComponent: NmsAutoUpdaterComponent;

    constructor(private errorResponseHandlerService : DeviceBackupErrorResponseHandlerService,
        public readonly devicesBackupService: DeviceBackupService,
        private readonly refreshIntervalService: RefreshIntervalService,
        @Inject(ANGULARJS_TRANSLATE) private readonly translate,
        private nmsDialogService: NmsDialogService,
        @Inject(ANGULARJS_ROOTSCOPE) private $rootscope: any,
        @Inject(LOADING_PANE_SERVICE) public readonly loadindPaneService,
        private toastr: NmsToastrService) {
        this.refreshIntervalService.getRefreshInterval((interval) => {
            this.autoUpdateInterval = interval;
        });

        this.restoreInProcessMessage = "backup.devices.table.restore.in.process";
        this.backupsProvider = this.retrieveDevicesBackup.bind(this);
    }

    ngOnInit(): void {
        this.subscribeToRestoreEvent();
    }

    ngAfterViewInit(): void {
        this.openRetentionModalIfNeeded();
    }

    ngOnDestroy(): void {
        this.restoreEventEmmiterSubscription.unsubscribe();
    }

    subscribeToRestoreEvent() {
        this.restoreEventEmmiterSubscription = this.devicesBackupService.restoreEventEmitter.subscribe(
            (dcbRestoreRequest: DcbRestoreRequest) => {
                this.blockPage(this.restoreInProcessMessage);
                this.restoreBackup(dcbRestoreRequest);
            });
    }

    /**
     * Atualiza os dados da tabela pelo nms-auto-updater.
     * @param dataResult - Resultado da atualização
     */
    refresh(dataResult: AutoUpdaterDataResult): void {
        if (dataResult.error) {
            this.showServerErrorMessage();
        } else {
            this.devicesBackupTable.update(dataResult.data);
        }
        this.devicesBackupTable.tableBodyMessage = null;
    }

    /**
     * Busca os dados de backups de Equipamentos.
     */
    retrieveDevicesBackup(): Observable<Array<DeviceBackupConfigurationView>> {
        return this.devicesBackupService.getAll();
    }

    private showServerErrorMessage() {
        this.nmsDialogService.openDialog(
            {
                description: this.translate.instant("http.error.serverError"),
                confirmButton: "OK"
            },
            { maxWidth: "800px" }
        );
    }

    public restoreBackup(dcbRestoreRequest: DcbRestoreRequest) {
        this.devicesBackupService.restoreBackup(dcbRestoreRequest).subscribe(() => {
            let msg = this.translate.instant("backup.devices.table.restore.process.success");
            this.toastr.success(msg);
            this.releasePage();
        }, (errorResponse: HttpErrorResponse) => {
            this.handleRestoreError(errorResponse, dcbRestoreRequest);
        });
    }

    private handleRestoreError(errorResponse: HttpErrorResponse, dcbRestoreRequest: DcbRestoreRequest) {
        const errorMessage = this.devicesBackupTable.getErrorMessage(errorResponse);
        const errorMessageConfirm = this.translate.instant("backup.devices.table.actions.ignore.firmware");
        if (errorResponse.error.code === DcbError.FIRMWARE_VERSION_DCB_ERROR) {
            this.showConfirmDialog(`${errorMessage} ${errorMessageConfirm}`)
                .then(() => {
                    this.blockPage(this.restoreInProcessMessage);
                    dcbRestoreRequest.ignoreFirmwareVersion = true;
                    this.restoreBackup(dcbRestoreRequest)
                });
        } else {
            const msg = this.translate.instant("backup.devices.table.restore.process.fail");
            this.toastr.error(msg);
            this.showDialog("backup.devices.table.restore.process.fail", errorMessage);
        }

        this.releasePage();
    }

    private showConfirmDialog(message: string): Promise<any> {
        return this.$rootscope.showDialog({ message: message, isConfirm: true });
    }

    private showDialog(key: string, parameters?: Array<string>): void {
        let msg = this.translate.instant(key);

        if (parameters != undefined) {
            msg += "<br>" + parameters.join("<br>");
        }
        this.nmsDialogService.openDialog({ description: msg, confirmButton: "OK", bindHtml: true }, { maxWidth: "800px" });
    }

    /**
     * Deixa a página esmaecida e sem ação, com um loading e uma mensagem
     * informando que o usuário deve aguardar o final do processo.
     */
    private blockPage(blockingMessage: String) {
        this.loadindPaneService.showLoadingPane(blockingMessage)
        this.mmsAutoUpdaterComponent.pause();
    }

    /**
     * Remove o loading e libera a página para que o usuário possa interagir.
     */
    private releasePage() {
        this.mmsAutoUpdaterComponent.continue();
        this.loadindPaneService.closeLoadingPane();
    }

    /**
     * Verifa se na URL existe o parâmetro 'showRetention'
     * caso exista, abre a modal de configuração de retenção
     * automaticamente.
     * Pode ser utilizado por aplicações externas, como o
     * DmView Desktop.
     */
    private openRetentionModalIfNeeded() {
        if (this.$rootscope.queryParams.indexOf("showRetention") != -1) {
            this.devicesBackupTable.handleBackupRetention();
        }
    }

}
