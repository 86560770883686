import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Inject, Injectable } from "@angular/core";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { Observable } from "rxjs";
import {
    ANGULARJS_TRANSLATE, NMS_ERRORS,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { AcsGlobalCredentials } from "./acs-credentials-model";
import { AcsSpecificalCredentials } from "./acs-specific-credentials-model";
import { CpeCredentialsForSerialNumbers } from "./cpe-credentials-for-serial-numbers-model";

/**
 * Classe responsável por realizar as requisições http com o servidor.
 */
@Injectable({
    providedIn: "root",
})
export class AcsCredentialsService {
    private readonly IS_CANCEL: boolean = true;

    acsServiceOnline: boolean;
    acsBaseUrl: string;
    cpeBaseUrl: string;
    cancelModalEmmiter = new EventEmitter<boolean>();

    constructor(private http: HttpClient,
      private toastr: NmsToastrService,
      @Inject(ANGULARJS_TRANSLATE) private translate,
      @Inject(NMS_ERRORS) private nmsErrors) {
        this.acsServiceOnline = false;
        this.acsBaseUrl = "/acs/settings/";
        this.cpeBaseUrl = "/acs-client/cpe-credentials/";
    }

    public getAcsConnectionSettings(): Observable<AcsGlobalCredentials> {
        return this.http.get<AcsGlobalCredentials>(this.acsBaseUrl);
    }

    public saveCredentials(acsCredentials: AcsGlobalCredentials): void {
      this.http.post(this.acsBaseUrl, acsCredentials).subscribe(
        () => {
          this.toastr.success(this.translate.instant("credentials.save.successfully"));
        },
        (responseError) => {
            // Por enquanto esta sendo tratado assim, mas posteriormente verificar para usar a classe
            // http-handler referente ao tratamento de exceções das requisições.
            console.error(`Could not save ACS global credentials. Error: ${responseError}`);
            this.verifyAndShowErrorToastr(responseError, acsCredentials);
        }
      );
    }

    public getCpeCredentialBySerialNumber(serialNumber: string) {
        return this.http.get<AcsSpecificalCredentials>(this.cpeBaseUrl + serialNumber);
    }

    public updateCredentials(acsSpecificCredentials: AcsSpecificalCredentials, cpes: Array<any>,
      isGlobalCredentials: boolean): void {
        const serialNumbers = this.extractSerialNumbers(cpes);
        let cpeCredentialsForSerialNumbers = new CpeCredentialsForSerialNumbers(serialNumbers,acsSpecificCredentials);

        let params = new HttpParams();
        params = params.append("isGlobal", String(isGlobalCredentials));

        this.http.post(this.cpeBaseUrl, cpeCredentialsForSerialNumbers, { params: params })
          .subscribe(
            (response: Array<any>) => {
              this.showSuccessMessage(response.length);
              this.cancelAcsCredentialsConfig();
            },
            (responseError) => {
              console.error(`Could not update CPE credentials. Error: ${JSON.stringify(responseError)}`);
              this.verifyAndShowErrorToastr(responseError);
            }
          );
    }

    public isAcsServiceOnline(): Observable<boolean> {
        return this.http.get<boolean>(`${this.acsBaseUrl}service-online/`);
    }

    public cancelAcsCredentialsConfig(): void {
        this.cancelModalEmmiter.emit(this.IS_CANCEL);
    }

    private showSuccessMessage(cpesChanged: number): void {
        let messageCpe, messageSuccess;
        if (cpesChanged == 0) {
            messageSuccess = this.translate.instant("credentials.modal.noChanges");
        } else {
          messageCpe = cpesChanged == 1
              ? this.translate.instant("credentials.modal.CPE")
              : this.translate.instant("credentials.modal.CPEs");
          messageSuccess = `${this.translate.instant("credentials.modal.saveSpecific")} ${cpesChanged} ${messageCpe}`;
        }
        this.toastr.success(messageSuccess);
    }

    private extractSerialNumbers(cpes: Array<any>): Array<string> {
        return cpes.map((cpe) => cpe.serialNumber);
    }

    /**
     * Verifica o código de erro da exceção para mostrar corretamente o erro correspondente traduzido no toast.
    */
    private verifyAndShowErrorToastr = (responseError: any, requestData?:any): void => {
      const { error } = responseError;

      if (!error) {
          this.toastr.error(this.translate.instant("credentials.save.error"));
          return;
      }

      switch(error.code) {
          case this.nmsErrors.ACS_DUPLICATED_USER:
            this.toastr.error(this.replaceUsernameMessage(requestData));
            break;
          default:
            this.toastr.error(this.translate.instant("credentials.save.error"));
      };
    }

    private replaceUsernameMessage(requestData): string {
        const rowErrorMessage = this.translate.instant("credentials.duplicated.user.error");
        return rowErrorMessage.replace("{0}", requestData.acsUser);
    }
}
