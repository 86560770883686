/**
 * Common Tree Node Controllers.
 *
 * @authors bruno.vollino
 */
var app = angular.module("nms.dynamicDevice");

var registerTreeNode = function(TreeSelectionManager, scope, pathWithKeys, parentPathWithKeys) {
    TreeSelectionManager.register(pathWithKeys, parentPathWithKeys, function(node) {
        scope.expanded = node.expanded;
        scope.filtered = node.filtered;
        scope.selected = node.selected;
        scope.selectedUpwards = node.selectedUpwards;
        scope.filterDownwards = node.filterDownwards;
    });
};

var registerNodeStatusListener = function(NodeStatusService, TreeSelectionManager, rootScope, scope, schemaNode, pathWithKeys,
    parentPathWithKeys) {
    return NodeStatusService.register(
        schemaNode.paths.schemaJsonPath,
        schemaNode.paths.dataJsonPath,
        scope.pathKeys,
        function(nodeStatus, refreshData) {
            scope.isNodeVisible = nodeStatus.isVisible;
            if (nodeStatus.isVisible) {
                registerTreeNode(TreeSelectionManager, scope, pathWithKeys, parentPathWithKeys);
            } else {
                TreeSelectionManager.unregister(pathWithKeys);
            }

            var parentStillHasVisibleChildren = TreeSelectionManager
                .hasVisibleChildrenOnExpandedNode(scope.parentNode, scope.pathKeys);
            rootScope.$broadcast("reloadTreeNodeOf=" + parentPathWithKeys, parentStillHasVisibleChildren);
        });
};

app.controller("ContainerTreeNodeController", ["$rootScope", "$scope", "ContentManager", "TreeSelectionManager",
    "SessionPreferencesService", "DataPathService", "WhenVerifierService", "NodeStatusService",
    function($rootScope, $scope, ContentManager, TreeSelectionManager, sessionPreferencesService, DataPathService,
        WhenVerifierService, NodeStatusService) {
        var unregisterFunctions = [];
        var pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.container);
        var parentPathWithKeys = DataPathService.getParentNodePath($scope.pathKeys, $scope.container);
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.container);

        $scope.filters = sessionPreferencesService.getFilters();
        $scope.hasVisibleChildren = true;
        $scope.isNodeVisible = !hasWhenCondition;

        registerTreeNode(TreeSelectionManager, $scope, pathWithKeys, parentPathWithKeys);
        if (hasWhenCondition) {
            var unregisterNodeStatusListener = registerNodeStatusListener(NodeStatusService, TreeSelectionManager, $rootScope,
                $scope, $scope.container, pathWithKeys, parentPathWithKeys);
            unregisterFunctions.push(unregisterNodeStatusListener);
        }

        $scope.setContent = function(node, canonicalPath) {
            var content: any = {
                node: node,
                path: node.paths.schemaJsonPath,
                pathKeys: $scope.pathKeys,
                canonicalPath: canonicalPath
            };
            ContentManager.setContent(content);
        };

        $scope.toggleSelect = function() {
            TreeSelectionManager.toggleExpanded(pathWithKeys, parentPathWithKeys);
        };

        var unregisterReloadTreeNodeOf = $rootScope.$on("reloadTreeNodeOf=" + pathWithKeys, function(event, hasVisibleChildren) {
            $scope.hasVisibleChildren = hasVisibleChildren;
        });
        unregisterFunctions.push(unregisterReloadTreeNodeOf);

        $scope.$on("$destroy", function() {
            TreeSelectionManager.unregister(pathWithKeys);
            unregisterFunctions.forEach(_.attempt);
        });
    }
]);

app.controller("ListTreeNodeController", ["$rootScope", "$scope", "ListService", "ContentManager", "$timeout",
    "TreeSelectionManager", "$filter", "NodeInfoCache", "SessionPreferencesService", "DomainHandlerService", "DataPathService",
    "WhenVerifierService", "NodeStatusService",
    function($rootScope, $scope, ListService, ContentManager, $timeout, TreeSelectionManager, $filter, NodeInfoCache,
        sessionPreferencesService, DomainHandlerService, DataPathService, WhenVerifierService, NodeStatusService) {
        $scope.filters = sessionPreferencesService.getFilters();
        $scope.pathKeys = $scope.pathKeys || [];

        var unregisterFunctions = [];
        var pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.list);
        var parentPathWithKeys = DataPathService.getParentNodePath($scope.pathKeys, $scope.list);
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.list);

        $scope.isNodeVisible = !hasWhenCondition;

        registerTreeNode(TreeSelectionManager, $scope, pathWithKeys, parentPathWithKeys);
        if (hasWhenCondition) {
            var unregisterNodeStatusListener = registerNodeStatusListener(NodeStatusService, TreeSelectionManager, $rootScope,
                $scope, $scope.list, pathWithKeys, parentPathWithKeys);

            unregisterFunctions.push(unregisterNodeStatusListener);
        }

        if ($scope.list) {
            ListService.updateKeysAsMandatory($scope.list);
        }

        var unregisterReloadTreeNode = $rootScope.$on("reloadTreeNode", function() {
            $scope.loadEntries();
        });
        unregisterFunctions.push(unregisterReloadTreeNode);

        var unregisterReloadTreeNodeOf = $rootScope.$on("reloadTreeNodeOf=" + pathWithKeys, function() {
            $scope.loadEntries();
        });
        unregisterFunctions.push(unregisterReloadTreeNodeOf);

        $scope.setListAnchored = function(parentNode, canonicalPath, id) {
            setContentWithParent(parentNode, canonicalPath);
            $scope.anchorId = id;
            $timeout(function() {
                anchorAtList();
            });
        };

        $scope.toggleSelect = function() {
            TreeSelectionManager.toggleExpanded(pathWithKeys, parentPathWithKeys);
        };

        $scope.loadEntries = function() {
            $scope.entries = _.get(DomainHandlerService.getDataNode(pathWithKeys), "entries", []);
        };

        var setContentWithParent = function(parentNode, canonicalPath) {
            var cachedNode = NodeInfoCache.getNode(canonicalPath);
            var parentCanonicalPath = cachedNode.parentPath;
            var pathKeys = $scope.pathKeys;
            var path = parentNode.paths.schemaJsonPath;
            var currentPath = ContentManager.getContentPath();
            var currentPathKeys = ContentManager.getPathKeys();

            if (currentPath !== parentNode.paths.schemaJsonPath || !_.isEqual(currentPathKeys, pathKeys)) {
                var parentCachedNode = NodeInfoCache.getNode(parentCanonicalPath);
                var parentKeys = null;

                if (parentCachedNode && parentCachedNode["sub-statements"]) {
                    parentKeys = parentCachedNode["sub-statements"].key;
                }

                var content: any = {
                    node: parentNode,
                    path: path,
                    canonicalPath: parentCanonicalPath,
                    keys: parentKeys,
                    pathKeys: pathKeys
                };
                ContentManager.setContent(content);
                openAccordionIfNeeded(parentCachedNode, path);
            }
        };

        var openAccordionIfNeeded = function(parentNode, accordionPath) {
            var accordionSettings = sessionPreferencesService.getAccordionSettings(accordionPath);
            if (!accordionSettings.openedStatus && $filter("isInformation")(parentNode)) {
                accordionSettings.openedStatus = true;
            }
            if (!accordionSettings.openedConfig && $filter("isConfiguration")(parentNode)) {
                accordionSettings.openedConfig = true;
            }
        };

        /**
         * Function that perform an anchor animation in case a list was selected in the tree.
         *
         * The animation is based on the distance between the list and the top minus the distance
         * between #content and the top (including a margin, set to be 20 pixels).
         */
        var anchorAtList = function() {
            var anchorId = "#" + $scope.anchorId.replace(/>/g, "\\$&");
            var content = angular.element("#content");
            var element = angular.element(anchorId);

            if (angular.isDefined($scope.anchorId) && angular.isDefined(element.offset())) {
                var contentOffset = content.offset().top;
                var contentScrollTop = content.scrollTop();
                var elementOffset = element.offset().top;

                var offset = elementOffset - contentOffset + contentScrollTop - 10;
                content.scrollTop(offset);

                $scope.anchorId = undefined;
            }
        };

        $scope.$on("$destroy", function() {
            TreeSelectionManager.unregister(pathWithKeys);
            unregisterFunctions.forEach(_.attempt);
        });
    }
]);

app.controller("ListEntryTreeNodeController", ["$rootScope", "$scope", "ListService", "ContentManager", "TreeSelectionManager",
    "SessionPreferencesService", "DataPathService",
    function($rootScope, $scope, ListService, ContentManager, TreeSelectionManager, sessionPreferencesService,
        DataPathService) {
        $scope.filters = sessionPreferencesService.getFilters();
        $scope.pathKeys = getPathKeys();
        $scope.hasVisibleChildren = true;

        var pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.entryTemplate);
        var parentPathWithKeys = DataPathService.getParentNodePath($scope.pathKeys, $scope.entryTemplate);

        function getPathKeys() {
            return DataPathService.getEntryPathKeys($scope.pathKeys, $scope.parentNode, $scope.entry, $scope.entryIndex);
        }

        registerTreeNode(TreeSelectionManager, $scope, pathWithKeys, parentPathWithKeys);

        var unregisterReloadTreeNodeOf = $rootScope.$on("reloadTreeNodeOf=" + pathWithKeys, function(event, hasVisibleChildren) {
            $scope.hasVisibleChildren = hasVisibleChildren;
        });

        $scope.setContent = function(node, canonicalPath) {
            var keys = $scope.list["sub-statements"].key;

            var content: any = {
                node: node,
                path: node.paths.schemaJsonPath,
                canonicalPath: canonicalPath,
                pathKeys: $scope.pathKeys,
                keys: keys
            };
            ContentManager.setContent(content);
        };

        $scope.toggleSelect = function() {
            TreeSelectionManager.toggleExpanded(pathWithKeys, parentPathWithKeys);
        };

        $scope.getEntryLabel = function(entryDataNode, entryIndex) {
            var key = $scope.list["sub-statements"].key;
            if (key) {
                var keyValues = ListService.getListKeyValues(entryDataNode.leaves, key.split(/\s+/));
                return _.pluck(keyValues, "value").join(" ");
            }

            return String(entryIndex + 1);
        };

        $scope.$on("$destroy", function() {
            TreeSelectionManager.unregister(pathWithKeys);
            unregisterReloadTreeNodeOf();
        });
    }
]);

app.controller("ChoiceTreeNodeController", ["$rootScope", "$scope", "TreeSelectionManager", "SessionPreferencesService",
    "DataPathService", "DomainHandlerService", "WhenVerifierService", "NodeStatusService",
    function($rootScope, $scope, TreeSelectionManager, sessionPreferencesService, DataPathService, DomainHandlerService,
        WhenVerifierService, NodeStatusService) {
        var unregisterFunctions = [];
        var pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.choice);
        var parentPathWithKeys = DataPathService.getParentNodePath($scope.pathKeys, $scope.choice);
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.choice);

        $scope.filters = sessionPreferencesService.getFilters();
        $scope.isNodeVisible = !hasWhenCondition;

        var unregisterReloadTreeNode = $rootScope.$on("reloadTreeNode", function() {
            $scope.init();
        });
        unregisterFunctions.push(unregisterReloadTreeNode);

        var unregisterReloadTreeNodeOf = $rootScope.$on("reloadTreeNodeOf=" + pathWithKeys, function() {
            $scope.init();
        });
        unregisterFunctions.push(unregisterReloadTreeNodeOf);

        var initCase = function() {
            var caseNode = DomainHandlerService.getDataNode(pathWithKeys + ".case");

            if (caseNode) {
                var caseSchema = Object.assign({}, $scope.choice["cases-template"][caseNode.id]);
                $scope.choiceCase = _.merge({}, caseSchema, caseNode);
            } else {
                $scope.choiceCase = null;
            }
        };

        registerTreeNode(TreeSelectionManager, $scope, pathWithKeys, parentPathWithKeys);
        if (hasWhenCondition) {
            var unregisterNodeStatusListener = registerNodeStatusListener(NodeStatusService, TreeSelectionManager, $rootScope,
                $scope, $scope.choice, pathWithKeys, parentPathWithKeys);

            unregisterFunctions.push(unregisterNodeStatusListener);
        }

        $scope.toggleSelect = function() {
            TreeSelectionManager.toggleExpanded(pathWithKeys, parentPathWithKeys);
        };

        $scope.init = function() {
            $scope.nodeData = $scope.choice;
            initCase();
        };

        $scope.$on("$destroy", function() {
            TreeSelectionManager.unregister(pathWithKeys);
            unregisterFunctions.forEach(_.attempt);
        });
    }
]);

app.controller("CaseTreeNodeController", ["$rootScope", "$scope", "ContentManager", "TreeSelectionManager",
    "SessionPreferencesService", "DataPathService", "WhenVerifierService", "NodeStatusService",
    function($rootScope, $scope, ContentManager, TreeSelectionManager, sessionPreferencesService, DataPathService,
        WhenVerifierService, NodeStatusService) {
        var unregisterFunctions = [];
        var pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.case);
        var parentPathWithKeys = DataPathService.getParentNodePath($scope.pathKeys, $scope.case);
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.case);

        $scope.filters = sessionPreferencesService.getFilters();
        $scope.hasVisibleChildren = true;
        $scope.isNodeVisible = !hasWhenCondition;

        registerTreeNode(TreeSelectionManager, $scope, pathWithKeys, parentPathWithKeys);
        if (hasWhenCondition) {
            var unregisterNodeStatusListener = registerNodeStatusListener(NodeStatusService, TreeSelectionManager, $rootScope,
                $scope, $scope.case, pathWithKeys, parentPathWithKeys);

            unregisterFunctions.push(unregisterNodeStatusListener);
        }

        var unregisterReloadTreeNodeOf = $rootScope.$on("reloadTreeNodeOf=" + pathWithKeys, function(event, hasVisibleChildren) {
            $scope.hasVisibleChildren = hasVisibleChildren;
        });
        unregisterFunctions.push(unregisterReloadTreeNodeOf);

        $scope.setContent = function(node, canonicalPath) {
            var content: any = {
                node: node,
                path: node.paths.schemaJsonPath,
                pathKeys: $scope.pathKeys,
                canonicalPath: canonicalPath
            };
            ContentManager.setContent(content);
        };

        $scope.toggleSelect = function() {
            TreeSelectionManager.toggleExpanded(pathWithKeys, parentPathWithKeys);
        };

        $scope.$on("$destroy", function() {
            TreeSelectionManager.unregister(pathWithKeys);
            unregisterFunctions.forEach(_.attempt);
        });
    }
]);

app.controller("RootTreeNodeController", ["$scope", "ContentManager", "SessionPreferencesService", "TreeSelectionManager",
    function($scope, ContentManager, sessionPreferencesService, TreeSelectionManager) {
        $scope.filters = sessionPreferencesService.getFilters();

        TreeSelectionManager.register("$", null, function(node) {
            $scope.selected = node.selected;
        });

        $scope.setContent = function(node, canonicalPath) {
            var content: any = {
                node: node,
                path: "$",
                pathKeys: $scope.pathKeys,
                canonicalPath: canonicalPath
            };
            ContentManager.setContent(content);
        };
    }
]);
