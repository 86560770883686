"use strict";

/**
 * @ngdoc controller
 * @name nms.ConnectivityTestController
 * @description
 * # Controller responsável por solicitar testes de conectividade, bem como preparar os dados para serem apresentados na ui.
 */
var app = angular.module("nms");

app.controller("ConnectivityTestController", ["$rootScope", "$scope", "$translate", "NgTableParams", "CONNECTIVITY_TEST_STATUS",
    "TABLE_PROPERTIES", "availableHosts", "DeviceDiscoverRESTService", "$window", "$websocket", "websocketClientId",
    "WEBSOCKET_STATES", "protocolByDeviceModelMap", "TableFilterService",
    function($rootScope, $scope, $translate, NgTableParams, CONNECTIVITY_TEST_STATUS, TABLE_PROPERTIES, availableHosts,
        DeviceDiscoverRESTService, $window, $websocket, websocketClientId, WEBSOCKET_STATES, protocolByDeviceModelMap,
        TableFilterService) {
        var websocket;
        var WEB_SOCKET_AUTHENTICATION_FAILED_CODE = 1006;
        var OF_TRANSLATION_MESSAGE = $translate.instant("connectivityTest.of");
        var CONCLUDED_HOSTS_TRANSLATION_MESSAGE = $translate.instant("connectivityTest.completedHosts");
        var NOT_PROTOCOLS_COLUMNS = ["selector", "hostname", "model", "completedProtocols"];
        var STATUS_LABELS = _.mapValues(CONNECTIVITY_TEST_STATUS, function(status) {
            return $translate.instant("connectivityTest.protocolstatus." + status);
        });

        $scope.progressBarMessage = "";
        $scope.progressBarPercentage = 0;

        $scope.columns = [
            {field: "hostname", width: "110", editable: false, show: true},
            {field: "model", width: "240", show: true},
            {field: "completedProtocols", width: "150", show: true},
            {field: "ping", filter: {pingTranslated: "text"}, show: true},
            {field: "snmp", filter: {snmpTranslated: "text"}, show: true},
            {field: "nmsNetconf", filter: {nmsNetconfTranslated: "text"}, show: true},
            {field: "userNetconf", filter: {userNetconfTranslated: "text"}, show: false},
            {field: "nmsSsh", filter: {nmsSshTranslated: "text"}, show: true},
            {field: "userSsh", filter: {userSshTranslated: "text"}, show: false},
            {field: "nmsTelnet", filter: {nmsTelnetTranslated: "text"}, show: true},
            {field: "userTelnet", filter: {userTelnetTranslated: "text"}, show: false},
            {field: "http", filter: {httpTranslated: "text"}, show: true},
            {field: "https", filter: {httpsTranslated: "text"}, show: true},
            {field: "nms", filter: {nmsTranslated: "text"}, show: false}
        ];

        $scope.tableParams = new NgTableParams({
            page: 1,
            count: TABLE_PROPERTIES.DEFAULT_PAGE_SIZE,
            sorting: {name: "asc"}
        }, {
            dataset: availableHosts,
            filterOptions: {filterFn: TableFilterService.getFilter($scope.columns)}
        });

        $scope.selection = {
            id: "connectivity-test-modal-selector",
            checked: [],
            table: $scope.tableParams
        };

        $scope.tableModel = {
            options: {
                translatePrefix: "connectivityTest.tablecolumn"
            },
            columns: $scope.columns,
            selection: $scope.selection,
            tableParams: $scope.tableParams
        };

        /*
         * Filtra os campos de protocolos que estão visíveis na tabela. Estes protocolos serão utilizados no cálculo de status
         * e serão os protocolos que terão os testes de conectividade disparados.
         */
        var filterVisibleProtocolsOnTable = function() {
            return _.filter($scope.columns, function(column) {
                return !_.contains(NOT_PROTOCOLS_COLUMNS, column.field) && column.show;
            });
        };

        var getConcludedHosts = function(hosts) {
            return _.filter(hosts, {percentage: 100});
        };

        var updateProgressBar = function() {
            var concludedHosts = getConcludedHosts(availableHosts);

            var params: any = {
                concludedHostsLength: concludedHosts.length,
                of: OF_TRANSLATION_MESSAGE,
                availableHostsLength: availableHosts.length,
                concludedHosts: CONCLUDED_HOSTS_TRANSLATION_MESSAGE
            };

            $scope.progressBarPercentage = (concludedHosts.length / availableHosts.length) * 100;
            $scope.progressBarMessage
                = _.template("${concludedHostsLength} ${of} ${availableHostsLength} ${concludedHosts}")(params);
        };

        /*
         * Atualiza um host com o feedback de seu teste de conectividade.
         *
         */
        var updateHostProtocol = function(testConnectivityFeedback) {
            var host = _.filter(availableHosts, {hostname: testConnectivityFeedback.hostname})[0];
            updateProtocolStatus(host, testConnectivityFeedback.protocol, testConnectivityFeedback.status);
            updateHostPercentage(host);
            updateProgressBar();
            $scope.tableParams.reload();
        };

        /*
         * Atualiza o status de um protocolo de um host, com seu valor e sua tradução.
         */
        var updateProtocolStatus = function(host, protocol, status) {
            host[protocol] = status;
            host[protocol + "Translated"] = getStatusLabel(host[protocol]);
        };

        /*
         * Atualiza a porcentagem de protocolos completos para um host.
         */
        var updateHostPercentage = function(host) {
            var visibleProtocolColumns = filterVisibleProtocolsOnTable();
            var visibleProtocolsStatus = _.pick(host, _.map(visibleProtocolColumns, "field"));
            var completedProtocols = _.sum(visibleProtocolsStatus, function(status) {
                return status !== CONNECTIVITY_TEST_STATUS.RUNNING;
            });
            host.percentage = (completedProtocols / Object.keys(visibleProtocolsStatus).length) * 100;
        };

        /**
         * Verifica a existência de falha na validação.
         * Caso exista um erro um dialog é apresentado ao usuário e é retornado o valor false, fazendo com que a lógica não
         * prossiga com a execução, caso contrário é retornado true executando o fluxo esperado.
         * Parâmetros:
         *   hasError            -   Boleano indicando a existência de erro na validação.
         *   errorTranslateKey   -   Chave da mensagem de erro a ser traduzida e usada na modal.
         */
        var verify = function(hasError, errorTranslateKey) {
            if (hasError) {
                $rootScope.showDialog({
                    translateKey: errorTranslateKey,
                    type: "alert"
                });
                return false;
            }

            return true;
        };

        var areThereSelectedHosts = function() {
            return verify(_.isEmpty($scope.selection.checked), "connectivityTest.error.noSelectedDevices");
        };

        var areThereHostsWithTestsCompleted = function(hosts, errorTranslateKey) {
            var hasError = _.isEmpty(getConcludedHosts(hosts));

            return verify(hasError, errorTranslateKey);
        };

        var willThereBeAnyItemsInTheTable = function() {
            var remainingHosts = availableHosts.length - $scope.selection.checked.length;
            return verify(remainingHosts === 0, "connectivityTest.error.cantRemoveAllHosts");
        };

        /**
         *   Filtra os protocolos que estão visíveis na tabela e seta os respectivos status para RUNNING.
         *   Apenas estes protocolos serão disparados nos testes.
         */
        var getVisibleProtocolsForRunning = function() {
            const visibleProtocols = filterVisibleProtocolsOnTable();

            return _.reduce(visibleProtocols, function(protocols, protocol) {
                protocols[protocol.field] = CONNECTIVITY_TEST_STATUS.RUNNING;
                return protocols;
            }, {});
        };

        /*
         * Inicializa a porcentagem dos testes executados no host em 0 e seta os protocolos que devem estar com o status RUNNING.
         */
        var resetHostProtocols = function(host, protocolsForRunning) {
            host.percentage = 0;
            _.forOwn(protocolsForRunning, function(protocolForRunningStatus, protocolForRunning) {
                updateProtocolStatus(host, protocolForRunning, protocolForRunningStatus);
            });
        };

        /*
         * Reconecta o websocket caso o mesmo se encontre fechado.
         */
        var reconnectWebSocketIfNeeded = function() {
            if (websocket.socket.readyState === WEBSOCKET_STATES.CLOSED) {
                websocket.reconnect();
            }
        };

        /*
         * Retorna os protocolos suportados pelo equipamento e atualiza o objeto host para que os protocolos não suportados não
         * fiquem com o status running.
         * Protocolos não suportados não têm label definido. Serão demonstrados com status vazio.
         */
        var getSupportedProtocolsForRunningTests = function(host, visibleProtocolsStatus, visibleProtocols) {
            var protocolsForRunningTests = visibleProtocols;
            var hostSupportedProtocols = protocolByDeviceModelMap[host.modelCode];
            var protocolsForRunningTestsStatus = visibleProtocolsStatus;

            if (hostSupportedProtocols) {
                protocolsForRunningTestsStatus = _.pick(visibleProtocolsStatus, hostSupportedProtocols);
                protocolsForRunningTests = _.intersection(visibleProtocols, hostSupportedProtocols);
            }
            resetHostProtocols(host, protocolsForRunningTestsStatus);
            return protocolsForRunningTests;
        };

        /*
         * Inicia testes de conectividade para os procolos visíveis de cada hostname.
         */
        var performTests = function(hosts) {
            reconnectWebSocketIfNeeded();

            var visibleProtocolsStatus = getVisibleProtocolsForRunning();
            var visibleProtocols = _.keys(visibleProtocolsStatus);
            var hostsToTest = _.map(hosts, function(host) {
                var hostToTest = _.pick(host, ["hostname", "credentials"]);

                hostToTest["protocols"] = getSupportedProtocolsForRunningTests(host, visibleProtocolsStatus, visibleProtocols);
                return hostToTest;
            });

            updateProgressBar();
            DeviceDiscoverRESTService.testHostsConnectivity(websocketClientId, hostsToTest);
        };

        /*
         * Seta o status dos protocolos com testes em andamento para "DEFAULT_ERROR" quando a conexão com o websocket é encerrada
         * por timeout.
         */
        var setErrorOnRunningProtocols = function() {
            var protocols = _.map(filterVisibleProtocolsOnTable(), "field");

            _.forEach(availableHosts, function(host) {
                _.forEach(protocols, function(protocol) {
                    if (host[protocol] === CONNECTIVITY_TEST_STATUS.RUNNING) {
                        var feedback: any = {
                            hostname: host.hostname,
                            protocol: protocol,
                            status: CONNECTIVITY_TEST_STATUS.DEFAULT_ERROR
                        };
                        updateHostProtocol(feedback);
                    }
                });
            });
        };

        $scope.getVisibleColumnsLength = function() {
            var visibleColumns = _.filter($scope.columns, function(column) {
                return column.show !== false;
            });

            return _.size(visibleColumns);
        };

        /**
         * Atualiza os dados de acordo com os itens selecionados na tabela. Caso nenhum item esteja selecionado ou nenhum dos
         * hosts tenham completado 100% dos testes uma modal de erro será apresentada.
         */
        $scope.update = function() {
            if (areThereSelectedHosts() && areThereHostsWithTestsCompleted(
                $scope.selection.checked, "connectivityTest.error.testsRunningForAllSelectedHosts")) {
                performTests(getConcludedHosts($scope.selection.checked));
            }
        };

        /**
         * Atualiza um host específico. Este update é usado no Dropdown do equipamento.
         */
        $scope.updateHost = function(host) {
            performTests([host]);
        };

        /*
         * Verifica a existência de hosts que tenham completado seus testes de conectividade em todos os hosts presentes na modal,
         * e reinicializa os testes para esses equipamentos. Caso todos os equipamentos estejam com o teste em andamento, uma
         * modal de erro é apresentada.
         */
        $scope.updateAll = function() {
            if (areThereHostsWithTestsCompleted(availableHosts, "connectivityTest.error.testsRunningForAllHosts")) {
                performTests(getConcludedHosts(availableHosts));
            }
        };

        $scope.remove = function() {
            if (areThereSelectedHosts() && willThereBeAnyItemsInTheTable()) {
                _.forEach($scope.selection.checked, function(host) {
                    _.remove(availableHosts, host);
                });

                updateProgressBar();
                $scope.tableParams.reload();
            }
        };

        $scope.$on("$destroy", function() {
            if (websocket.socket.readyState !== WEBSOCKET_STATES.CLOSED) {
                websocket.close();
            }
        });

        var getStatusLabel = function(status) {
            return STATUS_LABELS[status];
        };

        var init = function() {
            websocket = $websocket("wss://" + $window.location.host + "/test-connectivity/" + websocketClientId);

            websocket.onMessage(function(event) {
                updateHostProtocol(angular.fromJson(event.data));
            });

            websocket.onOpen(function() {
                performTests(availableHosts);
            });

            websocket.onClose(function(event) {
                /**
                 * Quando ocorre algum erro "anormal" na conexão do webscoket, o erro com o código 1006
                 * é retornado. Esse erro indica que houve problemas tais como autenticação, autorização
                 * incorreta ou uso de protocolo incorreto
                 */
                if (event && event.code == WEB_SOCKET_AUTHENTICATION_FAILED_CODE) {
                    $rootScope.prepareToNewLogin(true);
                } else {
                    setErrorOnRunningProtocols();
                }
            });
        };

        init();
    }
]);
