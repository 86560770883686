/**
 * Representa uma resposta de erro
 */
export interface ErrorResponse {
    /** Código do erro recebido */
    code: number;
    /** Descrição do erro recebido */
    description: string
    /** Detalhes do erro recebido */
    details: Array<string>;
}

/**
 * Representa a resposta de erro com suas informações tratadas para apresentação em tela
 */
export interface ErrorDescriptionDetails {
    /** Descrição do erro internacionalizada */
    description: string;
    /** Detalhes do erro internacionalizado */
    details: string;
}


/**
 * Mapeamento genérico entre enums e valores.
 *
 * Pode ser usado tanto para mapear uma enum de texto para um código de erro,
 * ou de um código de erro para um texto.
 */
export type ErrorCodeMap<T extends string | number> = {
    [key in T]: string | number;
}

/**
 * Enum para os códigos de status HTTP.
 *
 * Utilizando o padrão TitleCase ao invés de deixar tudo maiúsculo separado
 * por subtraço, porque essa é a forma que o Angular usa, facilitando assim
 * compatibilidade quando um upgrade for feito.
 *
 * Quando atualizar o Angular para uma versão superior a 12,
 * remover essa definição e utilizar a disponibilizada pelo Angular.
 *
 * @See https://angular.io/api/common/http/HttpStatusCode
 */
export enum HttpStatusCode {
    Continue                      = 100,
    SwitchingProtocols            = 101,
    Processing                    = 102,
    EarlyHints                    = 103,
    Ok                            = 200,
    Created                       = 201,
    Accepted                      = 202,
    NonAuthoritativeInformation   = 203,
    NoContent                     = 204,
    ResetContent                  = 205,
    PartialContent                = 206,
    MultiStatus                   = 207,
    AlreadyReported               = 208,
    ImUsed                        = 226,
    MultipleChoices               = 300,
    MovedPermanently              = 301,
    Found                         = 302,
    SeeOther                      = 303,
    NotModified                   = 304,
    UseProxy                      = 305,
    Unused                        = 306,
    TemporaryRedirect             = 307,
    PermanentRedirect             = 308,
    BadRequest                    = 400,
    Unauthorized                  = 401,
    PaymentRequired               = 402,
    Forbidden                     = 403,
    NotFound                      = 404,
    MethodNotAllowed              = 405,
    NotAcceptable                 = 406,
    ProxyAuthenticationRequired   = 407,
    RequestTimeout                = 408,
    Conflict                      = 409,
    Gone                          = 410,
    LengthRequired                = 411,
    PreconditionFailed            = 412,
    PayloadTooLarge               = 413,
    UriTooLong                    = 414,
    UnsupportedMediaType          = 415,
    RangeNotSatisfiable           = 416,
    ExpectationFailed             = 417,
    ImATeapot                     = 418,
    MisdirectedRequest            = 421,
    UnprocessableEntity           = 422,
    Locked                        = 423,
    FailedDependency              = 424,
    TooEarly                      = 425,
    UpgradeRequired               = 426,
    PreconditionRequired          = 428,
    TooManyRequests               = 429,
    RequestHeaderFieldsTooLarge   = 431,
    UnavailableForLegalReasons    = 451,
    InternalServerError           = 500,
    NotImplemented                = 501,
    BadGateway                    = 502,
    ServiceUnavailable            = 503,
    GatewayTimeout                = 504,
    HttpVersionNotSupported       = 505,
    VariantAlsoNegotiates         = 506,
    InsufficientStorage           = 507,
    LoopDetected                  = 508,
    NotExtended                   = 510,
    NetworkAuthenticationRequired = 511
}

/**
 * Abaixo segue o cadastro de todos os códigos de erro dos serviços do nms-services.
 * Os códigos devem ser únicos, e devem ser agrupados por serviço ou lib.
 *
 * Cada serviço possui a sua própria enum e ao final é criado uma consolidação de todos
 * os códigos de erro no objeto @see NmsError
 *
 * Deve se manter alinhada com a classe br.ind.datacom.nms.commons.error.ErrorCodes
 */

export enum NesError {
    UNKNOWN_ERROR                   =  1,
    DEVICE_ACCESS_ERROR             =  2,
    DEVICE_COMMUNICATION_ERROR      =  3,
    CONFIGURATION_CHANGED_ERROR     =  4,
    NETWORK_ELEMENT_NOT_IDENTIFIED  =  5,
    DEVICE_LOCKED                   =  6,
    DEVICE_AUTHENTICATION_ERROR     =  7,
    UNEXPECTED_DEVICE_BEHAVIOR      =  8,
    MONGO_ACCESS_ERROR              =  9,
    INVENTORY_UNAVAILABLE_ERROR     = 10,
    DISCOVERY_ERROR                 = 11,
    DEVICE_CONFIGURATION_ERROR      = 12,
    NETWORK_ELEMENT_NOT_FOUND_IN_DB = 13,
    TIMEOUT_ERROR                   = 14,
    PARSER_ERROR                    = 15,
    UNKNOW_DEVICE_ERROR             = 16,
    FIRMWARE_VERSION_ERROR          = 17,
    ACCESS_DENIED_ERROR             = 18
};

export enum DeviceAccessError {
    GENERIC_DEVICE_ERROR = 1,
    UNREACHABLE_DEVICE   = 2
}

export enum DatabaseError {
    DATABASE_ERROR       = 101,
    DEVICE_ALREADY_ADDED = 102
}

export enum DataCollectorError {
    TOO_MANY_RESULTS_FOUND = 201
}

export enum AcsServerError {
    ACS_CONNECTION_REQUEST_ERROR                = 300,
    ACS_BAD_CONDITIONAL_COMMAND_ERROR           = 301,
    ACS_COULD_NOT_ACCESS_SERVER_ERROR           = 302,
    ACS_CONNECTION_REQUEST_AUTHENTICATION_ERROR = 303,
    ACS_CPE_MESSAGE_TIMEOUT                     = 304,
    ACS_UNEXPECTED_ERROR                        = 305,
    UNEXPECTED_CPE_MESSAGE                      = 306
}

export enum NmsSecurityError {
    INVALID_CAPTCHA = 350
}

export enum NotificationServiceError {
    INVALID_NOTIFICATION_PREFERENCES_ERROR = 400
}

export enum SchedulerError {
    JOB_NOT_FOUND_ERROR                              = 450,
    SCHEDULER_UNEXPECTED_ERROR                       = 451,
    INVALID_CRON_EXPRESSION_ERROR                    = 452,
    SCHEDULER_REMOVAL_ERROR                          = 453,
    JOB_EXECUTION_ERROR                              = 454,
    USER_WITHOUT_SCHEDULER_PERMISSION_ERROR          = 455,
    USER_WITHOUT_JOB_TYPE_SCHEDULER_PERMISSION_ERROR = 456,
    USER_WITHOUT_CREATE_SCHEDULER_PERMISSION_ERROR   = 457,
    INVALID_BACKUP_DEVICE_MODEL_SCHEDULER_ERROR      = 458
}

export enum NmsTemplateError {
    DEVICE_WITHOUT_MANAGE_PERMISSION_ERROR            = 500,
    AUTO_SELECTION_VARIABLE_VALUE_NOT_AVAILABLE_ERROR = 501,
    AUDIT_SETTINGS_VALIDATION_ERROR                   = 502
}

export enum DcbError {
    UNKNOWN_DCB_ERROR                 = 550,
    UNSUPPORTED_DCB_FILES_ERROR       = 551,
    DEVICE_COMMUNICATION_DCB_ERROR    = 552,
    CONFIG_CHANGED_DCB_ERROR          = 553,
    DEVICE_NOT_IDENTIFIED_DCB_ERROR   = 554,
    DEVICE_LOCKED_DCB_ERROR           = 555,
    DEVICE_AUTHENTICATION_DCB_ERROR   = 556,
    DATABASE_ACCESS_DCB_ERROR         = 557,
    SERVICE_UNAVAILABLE_DCB_ERROR     = 558,
    DEVICE_CONFIGURATION_DCB_ERROR    = 559,
    FIRST_POLLING_FAILED_DCB_ERROR    = 560,
    FIRMWARE_VERSION_DCB_ERROR        = 562,
    ACCESS_DENIED_DCB_ERROR           = 563,
    DEVICE_CONFIG_PARSER_DCB_ERROR    = 564,
    CORRUPTED_FILE_DCB_ERROR          = 565,
    PROTECTED_DCB_ERROR               = 566,
    DCB_NOT_FOUND                     = 567,
    EXPORT_CONFIG_PARSER_DCB_ERROR    = 568,
    POLLER_COMMUNICATION_DCB_ERROR    = 569,
    DCB_RETENTION_CONFIGURATION_ERROR = 570,
    DCB_MODEL_OPERATION_ERROR         = 571,
};

export enum ApiNorthboundError {
    BAD_REQUEST_ERROR     = 600,
    DATA_NOT_FOUND_ERROR  = 601,
    DUPLICATED_DATA_ERROR = 602,
    VALIDATION_ERROR      = 603,
    FORBIDDEN_ERROR       = 604,
    INTERNAL_SERVER_ERROR = 605,
    VARIABLE_VALUES_ERROR = 606
};


/**
 * Cadastro de todos os códigos de erro dos serviços do nms-services.
 *
 * Erros consolidados fazendo uma união entre todas as enums individuais.
 *
 * Os códigos devem ser únicos, e devem ser agrupados por serviço ou lib.
 * Deve se manter alinhada com a classe br.ind.datacom.nms.commons.error.ErrorCodes
 *
 * Aqui são definidos os valores, de modo que seja possível usar,
 * por exemplo, NmsError.UNKNOWN_ERROR em um case de um switch.
 */
export const NmsError = {
    ...DeviceAccessError,
    ...DatabaseError,
    ...DataCollectorError,
    ...AcsServerError,
    ...NmsSecurityError,
    ...NotificationServiceError,
    ...SchedulerError,
    ...NmsTemplateError,
    ...DcbError,
    ...ApiNorthboundError
};

/**
 * Definição de NmsError como tipo.
 * Deve ter o mesmo nome da constante NmsError acima.
 *
 * Aqui é definido o tipo, de modo que seja possível usar,
 * por exemplo, NmsError como tipo de variável/retorno.
 *
 * (e.g. function example(error: NmsError) {}, function example(): NmsError {})
 */
export type NmsError = keyof typeof NmsError;