var app = angular.module("nms.dynamicDevice");

app.directive("leaves", ["DomainHandlerService", "$filter", "WhenVerifierService", "NodeStatusService",
    function(DomainHandlerService, $filter, WhenVerifierService, NodeStatusService) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/leaves.html",
            link: function(scope, element) {
                scope.showHeader = function(leaf, config) {
                    if (config && leaf && leaf.type) {
                        var baseType = DomainHandlerService.getBaseType(leaf.type);

                        if (baseType.name === "empty") {
                            return false;
                        }

                        if (baseType.name === "boolean" && _.has(leaf["sub-statements"], "default")) {
                            return false;
                        }
                    }

                    return true;
                };

                scope.isKey = function(leaf) {
                    return _.contains(scope.keys, leaf.id);
                };

                /**
                * Ordenadores de leaves para parametrização do filtro orderBy.
                * Garante que leaves de keys sejam posicionadas no início, leaves de configuração sejam ordenadas alfabeticamente
                * por ID e leaves de status tenham a ordenação do Yang preservada.
                * Se houverem leaves de config e status misturadas vai ordenar primeiro as de status, mas essa situação não
                * ocorre atualmente pois os sorters não são usados em lista misturada.
                */
                scope.leavesSorters = [
                    function(leaf) {
                        return !scope.isKey(leaf);
                    },
                    function(leaf) {
                        return $filter("configurationFilter")(leaf, false) ? 0 : leaf.id;
                    }
                ];

                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
]);
