import { DropdownShortcut } from "@nms-angular-toolkit/nms-dropdown";

/**
 * Enum que representam os campos para a tabela GPON Onus.
 *
 * Os valores da enum seguem o formato esperado para o campo da tabela, camelCase,
 * diferente das chaves da enum que usam TitleCase.
 */
export enum GponOnusField {
    DeviceName            = "deviceName",
    PortViewName          = "portViewName",
    OnuId                 = "onuId",
    Status                = "status",
    RxPower               = "rxPower",
    TxPower               = "txPower",
    Alarms                = "alarms",
    AlarmsTimes           = "alarmsTimes",
    SerialNumber          = "serialNumber",
    Password              = "password",
    VendorId              = "vendorId",
    EquipmentId           = "equipmentId",
    OnuName               = "onuName",
    OperationalState      = "operationalState",
    PrimaryStatus         = "primaryStatus",
    Distance              = "distance",
    Ipv4Address           = "ipv4Address",
    CwmpCpeIp             = "cwmpCpeIp",
    CwmpCpeLastInform     = "cwmpCpeLastInform",
    Tr069AcsProfile       = "tr069acsProfile",
    Ipv4DefaultGateway    = "ipv4DefaultGateway",
    Ipv4Mode              = "ipv4Mode",
    Ipv4Vlan              = "ipv4Vlan",
    RgProfile             = "rgProfile",
    Snmp                  = "snmp",
    UpstreamFec           = "upstreamFec",
    Uptime                = "uptime",
    LastSeenOnline        = "lastSeenOnline",
    Version               = "version",
    AutoProvisioned       = "autoProvisioned",
    LineProfile           = "lineProfile",
    ServiceProfile        = "serviceProfile",
    ServiceVlans          = "serviceVlans",
    FixedBandwidth        = "fixedBandwidth",
    AssuredFixedBandwidth = "assuredFixedBandwidth",
    ActiveFwFormatted     = "activeFwFormatted",
    StandbyFwFormatted    = "standbyFwFormatted",
    SoftwareDownloadState = "softwareDownloadState",
    LastUpdated           = "lastUpdated",
    Eth1Link              = "eth1Link",
    Eth1InRate            = "eth1InRate",
    Eth1OutRate           = "eth1OutRate",
    Eth2Link              = "eth2Link",
    Eth2InRate            = "eth2InRate",
    Eth2OutRate           = "eth2OutRate",
    Eth3Link              = "eth3Link",
    Eth3InRate            = "eth3InRate",
    Eth3OutRate           = "eth3OutRate",
    Eth4Link              = "eth4Link",
    Eth4InRate            = "eth4InRate",
    Eth4OutRate           = "eth4OutRate"
}

/**
* Modelo utilizado para preencher os dropdown de acordo com as features disponiveis e de acordo
* com a gpon.
*/
export interface DeviceDropdownShortcut {
    deviceId: string;
    shortcuts: Array<DropdownShortcut>;
}

/**
 * Modelo que representa o mapeamento entre strings e suas respectivas funções.
 */
export interface FilterTypeMapping {
    [key: string]: Function
}

/**
 * Modelo que representa o mapeamento entre @see GponOnusField e um valor desejado (@see T).
 */
export type FieldMapping<T> = {
    [key in GponOnusField]?: T
}