"use strict";

/**
* @ngdoc Controller
* @name nms.management.ManagePermissionsDevicesController
* @description Realiza gerência das permissões de devices.
*/
var app = angular.module("nms.management");

app.controller("ManagePermissionsDevicesController", ["$scope", "$rootScope", "NgTableParams", "TableFilterService",
    "ManagePermissionsDevicesService", "DEVICE_PERMISSION",
    function($scope, $rootScope, NgTableParams, TableFilterService, ManagePermissionsDevicesService, DEVICE_PERMISSION) {
        var defaultDevicesProperties: any = {
            devices: []
        };
        _.defaults($scope.owner.model, defaultDevicesProperties);

        $scope.permissionOptions = [DEVICE_PERMISSION.MANAGE, DEVICE_PERMISSION.VIEW, DEVICE_PERMISSION.NOTHING];

        /**
        * Representa a opção selecionada no componente que permite que uma permissão seja aplicada a devices selecionados
        * previamente (componente acima da tabela de permissão de devices).
        */
        $scope.generalPermission = {
            value: DEVICE_PERMISSION.MANAGE.databaseValue
        };

        /**
         * Retorna função que atualiza os valores de permissão traduzido.
         * Invocada pela diretiva @see nms.directive:segmentedControl ao trocar valor do componente segmented-control.
         *
         * @param {Object} modelo de device.
         * @returns {Function} função que realiza a atualização.
         */
        $scope.updatePermissionValue = function(device) {
            return function() {
                device.translatedPermission = ManagePermissionsDevicesService.translatePermission(device.permission.value);
            };
        };

        $scope.columns = [
            {field: "modelName"},
            {field: "permission", sortable: "translatedPermission", filter: {translatedPermission: "text"}}
        ];

        $scope.tableParams = new NgTableParams(
            {
                sorting: {modelName: "asc"}
            },
            {
                counts: [],
                dataset: [],
                filterOptions: {filterFn: TableFilterService.getFilter($scope.columns)}
            }
        );

        $scope.selection = {
            id: $scope.id,
            checked: [],
            table: $scope.tableParams
        };

        $scope.tableModel = {
            options: {
                translatePrefix: "manage.permissions.devices.tableColumn"
            },
            columns: $scope.columns,
            selection: $scope.selection,
            tableParams: $scope.tableParams
        };

        $scope.applyPermissionToSelectedDevices = function() {
            if (_.isEmpty($scope.selection.checked)) {
                $rootScope.showDialog({
                    translateKey: "manage.permissions.devices.no.device.selected"
                });
            } else {
                var permissionTranslate = ManagePermissionsDevicesService.translatePermission($scope.generalPermission.value);
                _.forEach($scope.selection.checked, function(device) {
                    device.permission.value = $scope.generalPermission.value;
                    device.translatedPermission = permissionTranslate;
                });
            }
        };

        /**
         * Desabilita paginação.
         *
         * Nas tabelas que utilizam ngTable é necessário setar o count com a quantidade de elementos presentes no dataset para
         * esconder a paginação.
         */
        var disableTablePagination = function(elements) {
            $scope.tableParams.count(elements.length);
        };

        var init = function() {
            ManagePermissionsDevicesService.getDevicePermissions($scope.owner.model.editData).then(function(devices) {
                $scope.tableParams.settings({dataset: devices});
                disableTablePagination(devices);
                $scope.owner.model.devices = devices;
                delete $scope.owner.model.editData;
            });
        };

        init();
    }
]);
