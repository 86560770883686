/**
 * Directive used to build a container node.
 */
var app = angular.module("nms.dynamicDevice");

app.directive("containerBody", ["ContentManager",
    function(contentManager) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/container/container-body.html",
            controller: "ContainerBodyController",
            scope: {
                container: "=",
                pathKeys: "="
            }
        };
    }
]);
