"use strict";

/**
* @ngdoc service
* @name nms.management.ManagePermissionsFeaturesTabRestService
* @description Realiza chamadas REST ao serviço de gerência de permissões de features.
*/
var app = angular.module("nms.management");

app.factory("ManagePermissionsFeaturesRestService", ["Restangular",
    function(Restangular) {
        var baseURL = "/permissions";
        const service: any = {};

        service.getAll = function() {
            return Restangular.all(baseURL + "/personal").customGET();
        };

        return service;
    }
]);
