import { TemplateResultStatus } from "@nms-ng2/app/shared/models/application-results.models";

"use strict";

/**
* @ngdoc service
* @name nms.templates.ProgressBarService
* @description
* # ProgressBarService
* Service in the nms.templates.
*/
var app = angular.module("nms.templates");

app.factory("ProgressBarService", ["$translate", "APPLICATION_STATUS", "TemplateInstanceUtils",
    function($translate, APPLICATION_STATUS, TemplateInstanceUtils ) {
        let progressBarValues: any = {
            danger: 0,
            warning: 0,
            success: 0,
            applyingByAnotherUser: 0,
            notRequested: 0,
            notExecuted: 0,
            applying: 0
        };
        const initialProgressBarValues: any = Object.freeze(Object.assign({}, progressBarValues));
        const stacked = [
            {value: "0", type: TemplateResultStatus.SUCCESS},
            {value: "0", type: TemplateResultStatus.WARNING},
            {value: "0", type: TemplateResultStatus.DANGER},
            {value: "0", type: TemplateResultStatus.APPLYING_BY_ANOTHER_USER},
            {value: "0", type: TemplateResultStatus.NOT_REQUESTED},
            {value: "0", type: TemplateResultStatus.NOT_EXECUTED},
            {value: "0", type: TemplateResultStatus.APPLYING},
        ];

        const service: any = {};
        let totalOfEquipments = 0;

        service.calculateProgressBar = function(equipmentAssociation) {
            const results = equipmentAssociation.templateResults
                ? equipmentAssociation.templateResults
                : equipmentAssociation.templateIdentifiers;
            const elements = _.map(results, "status");

            if (!_.includes(elements, APPLICATION_STATUS.NOT_STARTED) && !_.includes(elements, APPLICATION_STATUS.APPLYING)) {
                const barType = service.getProgressBarType(elements);
                const option = _.find(stacked, {type: barType});
                progressBarValues[barType]++;
                option.value = ((progressBarValues[barType] / totalOfEquipments) * 100).toFixed(2);
            }
        };

        service.updateProgressBar = function(equipmentAssociationResults) {
            progressBarValues = Object.assign({}, initialProgressBarValues);

            equipmentAssociationResults.forEach((equipmentAssociation) => {
                const barType = service.getProgressBarType(_.map(equipmentAssociation.templateResults, "status"));
                progressBarValues[barType]++;
            })

            stacked.forEach(stack => {
                stack.value = ((progressBarValues[stack.type] / totalOfEquipments) * 100).toFixed(2);
            })
        }

        service.getProgressBarDataSource = function(equipmentAssociationResults) {
            totalOfEquipments = equipmentAssociationResults.length;
            service.clearProgressBarData();

            return stacked;
        };

        service.clearProgressBarData = function() {
            progressBarValues = Object.assign({}, initialProgressBarValues);

            stacked.forEach(function(stack) {
                stack.value = "0";
            });
        };

        service.getProgressBarTitle = function (templateType) {
            let count = 0;
            let details = "";
            const progressBarStatusOrder = [
                TemplateResultStatus.SUCCESS,
                TemplateResultStatus.WARNING,
                TemplateResultStatus.DANGER,
                TemplateResultStatus.APPLYING_BY_ANOTHER_USER,
                TemplateResultStatus.NOT_REQUESTED,
                TemplateResultStatus.NOT_EXECUTED,
                TemplateResultStatus.APPLYING
            ];

            progressBarStatusOrder.forEach(function (status) {
                const occurrences = progressBarValues[status];
                count += service.getTotalEquipmentsFinalized(status, occurrences);

                if (occurrences > 0) {
                    if (details !== "") {
                        details += ", ";
                    }

                    details += service.getLabelForStatus(status, occurrences);
                }
            });

            const translationKey = TemplateInstanceUtils.isCliTemplateApplication(templateType)
                ? $translate.instant("modals.results.devicescompleted")
                : $translate.instant("modals.results.cpescompleted");

            return `${count} ${$translate.instant("modals.results.sizeFrom")} ${totalOfEquipments} ${translationKey}${details}`;
        };

        service.getLabelForStatus = function (status, occurrences) {
            if (status === TemplateResultStatus.SUCCESS) {
                return `${$translate.instant("templateinstanceform.viewapply.templateStatus.success")} (${occurrences})`;
            } else if (status === TemplateResultStatus.WARNING) {
                return `${$translate.instant("templateinstanceform.viewapply.templateStatus.partial")} (${occurrences})`;
            } else if (status === TemplateResultStatus.NOT_REQUESTED) {
                return `${$translate.instant("templateinstanceform.viewapply.templateStatus.notRequested")} (${occurrences})`;
            } else if (status === TemplateResultStatus.NOT_EXECUTED) {
                return `${$translate.instant("templateinstanceform.viewapply.templateStatus.notExecuted")} (${occurrences})`;
            } else if (status === TemplateResultStatus.APPLYING_BY_ANOTHER_USER) {
                const applyingByAnotherUserKey = $translate.instant(
                    "templateinstanceform.viewapply.templateStatus.applyingByAnotherUser"
                );
                return `${applyingByAnotherUserKey} (${occurrences})`;
            } else if (status === TemplateResultStatus.APPLYING) {
                return "";
            }

            return `${$translate.instant("templateinstanceform.viewapply.templateStatus.fail")} (${occurrences})`;
        };

        service.getTotalEquipmentsFinalized = function (status, occurrences) {
            switch (status) {
                case TemplateResultStatus.NOT_EXECUTED:
                case TemplateResultStatus.APPLYING:
                    return 0;
                default:
                    return occurrences;
            }
        };

        service.getProgressBarType = function(elements) {
            if (_.includes(elements, APPLICATION_STATUS.APPLYING)) {
                return TemplateResultStatus.APPLYING;
            } else if (_.includes(elements, APPLICATION_STATUS.APPLYING_BY_ANOTHER_USER)
                    && _.includes(elements, APPLICATION_STATUS.NOT_STARTED)) {
                return TemplateResultStatus.APPLYING_BY_ANOTHER_USER;
            } else if (_.includes(elements, APPLICATION_STATUS.NOT_STARTED)) {
                return TemplateResultStatus.APPLYING;
            } else if (_.includes(elements, APPLICATION_STATUS.SUCCESS)
                    && _.includes(elements, APPLICATION_STATUS.FAIL)) {
                return TemplateResultStatus.WARNING;
            } else if (_.includes(elements, APPLICATION_STATUS.SUCCESS)
                    && _.includes(elements, APPLICATION_STATUS.NOT_EXECUTED)) {
                return TemplateResultStatus.WARNING;
            } else if (_.includes(elements, APPLICATION_STATUS.FAIL)) {
                return TemplateResultStatus.DANGER;
            } else if (_.includes(elements, APPLICATION_STATUS.SUCCESS)) {
                return TemplateResultStatus.SUCCESS;
            } else if (_.includes(elements, APPLICATION_STATUS.NOT_REQUESTED)) {
                return TemplateResultStatus.NOT_REQUESTED;
            } else if (_.includes(elements, APPLICATION_STATUS.APPLYING_BY_ANOTHER_USER)) {
                return TemplateResultStatus.APPLYING_BY_ANOTHER_USER;
            } else if (_.includes(elements, APPLICATION_STATUS.NOT_EXECUTED)) {
                return TemplateResultStatus.NOT_EXECUTED;
            }

            return TemplateResultStatus.APPLYING;
        };

        return service;
    }
]);
