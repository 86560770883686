/**
 * Classe responsável por realizar o downgrade dos componentes do angular 8 para o angularjs.
 */
import { downgradeComponent, downgradeInjectable } from "@angular/upgrade/static";
import { KeepAliveService } from "@nms-ng2/app/shared/services/security/keep-alive.service";
import { ModalFactory } from "@nms-ng2/app/shared/services/modal/modal.factory";
import { AcsCredentialsComponent } from "@nms-ng2/app/modules/settings/acs-credentials/acs-credentials.component";
import { CpesComponent } from "@nms-ng2/app/modules/device/cpes/cpes.component";
import { DownloadComponent } from "@nms-ng2/app/modules/device/cpes/download/download.component";
import { RebootService } from "@nms-ng2/app/modules/device/cpes/reboot/reboot.service";
import { CwmpParametersComponent } from "@nms-ng2/app/modules/device/cwmp-parameters/cwmp-parameters.component";
import { CwmpParametersService } from "@nms-ng2/app/modules/device/cwmp-parameters/cwmp-parameters.service";
import { TemplateInstanceComponent } from "@nms-ng2/app/modules/template/template-instance/template-instance.component";
import { NmsAutoUpdaterComponent } from "@nms-ng2/app/shared/components/elements/nms-auto-updater/nms-auto-updater.component";
import { RefreshIntervalService } from "@nms-ng2/app/shared/services/refresh-interval/refresh-interval.service";
import { GlobalManagementProtocolsComponent } from "@nms-ng2/app/modules/settings/global-management-protocols/global-management-protocols.component";
import { CodeBlockService } from "@nms-ng2/app/modules/template/services/code-block.service";
import { TemplateInstanceIdentifierService } from "@nms-ng2/app/modules/template/template-instance/template-instance-identifier.service";
import { VariablesService } from "../ng2/app/modules/template/services/variables.service";
import { TemplateComponent } from "@nms-ng2/app/modules/template/template/template.component";
import { TemplateInfoHeaderComponent } from "@nms-ng2/app/shared/components/elements/template-info-header/template-info-header.component";
import { CpesTableComponent } from "@nms-ng2/app/modules/device/cpes/table/cpes-table.component";
import { TemplateInstanceUtils } from "@nms-ng2/app/modules/template/template-instance/template-instance.utils";
import { EquipmentService } from "@nms-ng2/app/modules/template/services/equipment.service";
import { RuleOptionsService } from "@nms-ng2/app/shared/services/rule-options/rule-options.service";
import { TemplateValidatorTypeService } from "@nms-ng2/app/modules/template/services/template-validator-type.service";
import { CpeShortcutsActionService } from "@nms-ng2/app/modules/device/cpes/cpe-shortcuts-action.service";
import { NmsNotificationWebSocketService } from "@nms-ng2/app/shared/services/nms-notification/nms-notification-web-socket.service";
import { ActionVariableValidatorService } from "@nms-ng2/app/modules/template/services/variable/action-variable-validator.service";
import { UserPreferencesComponent } from "@nms-ng2/app/modules/user/user-preferences/user-preferences.component";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { MatchingRulesComponent } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.component";
import { PermissionsActionsService } from "@nms-ng2/app/shared/services/actions/permissions-actions.service";
import { CpesActionsService } from "@nms-ng2/app/shared/services/actions/cpes-actions.service";
import { DevicesActionsService } from "@nms-ng2/app/shared/services/actions/devices-actions.service";
import { TemplateActionsService } from "@nms-ng2/app/shared/services/actions/template-actions.service";
import { EquipmentsModalsActionsService } from "@nms-ng2/app/shared/services/actions/equipments-modals-actions.service";
import { SelectionDropdownComponent } from "@nms-ng2/app/shared/components/elements/selection-dropdown/selection-dropdown.component";
import { NmsCounterComponent } from "@nms-ng2/app/shared/components/elements/nms-counter/nms-counter.component";
import { SchedulerComponent } from "@nms-ng2/app/modules/scheduler/scheduler.component";
import { SchedulerListComponent } from "@nms-ng2/app/modules/scheduler/scheduler-list/scheduler-list.component";
import { GponOnusComponent } from "@nms-ng2/app/modules/device/gpon-onus/gpon-onus.component";
import { DeviceBackupComponent } from "@nms-ng2/app/modules/device/device-backup/device-backup.component";

var app = angular.module("nms.downgraded", []);

app.factory("ModalFactory", downgradeInjectable(ModalFactory));
app.directive("acsCredentials", downgradeComponent({ component: AcsCredentialsComponent }));
app.directive("cwmpParameters", downgradeComponent({ component: CwmpParametersComponent }));
app.directive("cpes", downgradeComponent({ component: CpesComponent }));
app.directive(
    "scheduler",
    downgradeComponent({
        component: SchedulerComponent,
        inputs: ["showInformTrigger", "schedulerJob", "hasSchedulerPermission"]
    })
);
app.directive(
    "cpesTable",
    downgradeComponent({
        component: CpesTableComponent,
        inputs: [
            "showTitle",
            "doubleClick",
            "singleClick",
            "hiddenActions",
            "openLinksInNewTab",
            "refreshActions",
            "selectedItems",
            "cpesProvider"
        ]
    })
);
app.directive(
    "templateInfoHeader",
    downgradeComponent({
        component: TemplateInfoHeaderComponent,
        inputs: ["templateType", "templates", "equipmentModelRestrictionTypes", "presentationMode"]
    })
);
app.directive("download", downgradeComponent({ component: DownloadComponent }));
app.directive("globalManagementProtocols", downgradeComponent({ component: GlobalManagementProtocolsComponent }));
app.directive("nmsTemplateInstance", downgradeComponent({ component: TemplateInstanceComponent }));
app.directive("nmsTemplate", downgradeComponent({ component: TemplateComponent }));
app.factory("CwmpParametersService", downgradeInjectable(CwmpParametersService));
app.factory("RebootService", downgradeInjectable(RebootService));
app.factory("KeepAliveService", downgradeInjectable(KeepAliveService));
app.directive(
    "nmsAutoUpdater",
    downgradeComponent({
        component: NmsAutoUpdaterComponent,
        inputs: ["dataRetrieveProvider", "intervalTime"],
        outputs: ["onDataReceived"]
    })
);
app.directive(
    "nmsCounter",
    downgradeComponent({
        component: NmsCounterComponent,
        inputs: ["startNotifier", "stopNotifier", "isCountdown", "secondsTranslationKey", "minutesTranslationKey", "timeUnit"],
        outputs: ["onCountEnded"]
    })
);
app.factory("RefreshIntervalService", downgradeInjectable(RefreshIntervalService));
app.factory("TemplateInstanceIdentifierService", downgradeInjectable(TemplateInstanceIdentifierService));
app.factory("TemplateInstanceUtils", downgradeInjectable(TemplateInstanceUtils));
app.factory("CodeBlockService", downgradeInjectable(CodeBlockService));
app.factory("VariablesService", downgradeInjectable(VariablesService));
app.factory("EquipmentService", downgradeInjectable(EquipmentService));
app.factory("RuleOptionsService", downgradeInjectable(RuleOptionsService));
app.factory("TemplateValidatorTypeService", downgradeInjectable(TemplateValidatorTypeService));
app.factory("CpeShortcutsActionService", downgradeInjectable(CpeShortcutsActionService));
app.factory("NmsNotificationWebSocketService", downgradeInjectable(NmsNotificationWebSocketService));
app.factory("ActionVariableValidatorService", downgradeInjectable(ActionVariableValidatorService));
app.factory("NmsToastrService", downgradeInjectable(NmsToastrService));
app.directive("userPreferences", downgradeComponent({ component: UserPreferencesComponent }));
app.directive(
    "matchingRules",
    downgradeComponent({
        component: MatchingRulesComponent,
        inputs: [
            "id",
            "ruleCriterias",
            "ruleTypes",
            "ruleFields",
            "translationKeys",
            "isBlocked",
            "filterRuleTypes",
            "matchingRules",
            "showUseMatchingRulesCheck"
        ]
    })
);
app.factory("PermissionsActionsService", downgradeInjectable(PermissionsActionsService));
app.factory("CpesActionsService", downgradeInjectable(CpesActionsService));
app.factory("DevicesActionsService", downgradeInjectable(DevicesActionsService));
app.factory("TemplateActionsService", downgradeInjectable(TemplateActionsService));
app.factory("EquipmentsModalsActionsService", downgradeInjectable(EquipmentsModalsActionsService));

app.directive(
    "selectionDropdown",
    downgradeComponent({
        component: SelectionDropdownComponent,
        inputs: [
            "selectionOptions",
            "maxSizeSelectedItens",
            "showItemValueSelected",
            "items",
            "itemsCheckeds",
            "titles",
            "disabled",
            "useTranslateKeyValue",
            "showIndeterminatedState"
        ],
        outputs: ["onCheckboxTrigger"]
    })
);

app.directive("schedulerList", downgradeComponent({ component: SchedulerListComponent }));
app.directive("gponOnus", downgradeComponent({ component: GponOnusComponent }));
app.directive("deviceBackup", downgradeComponent({ component: DeviceBackupComponent }));
