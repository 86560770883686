var app = angular.module("nms.dynamicDevice");

app.service("NesRestService", ["$resource", "$http", "Restangular",
    function($resource, $http, Restangular) {
        this.getConfigurationData = function(resourceId, success, error) {
            Restangular
                .one("/device/" + resourceId)
                .withHttpConfig({bypassInterceptor: true})
                .customGET()
                .then(success, error);
        };

        this.getSchema = function(resourceId, success, error) {
            Restangular
                .one("/device/" + resourceId + "/schema")
                .withHttpConfig({bypassInterceptor: true})
                .customGET()
                .then(success, error);
        };

        this.createPatchOfOperations = function(originalContainer, currentContainer) {
            var resource = $resource("/patch/create");
            var data: any = {
                base: originalContainer,
                modified: currentContainer
            };

            return resource.save(data).$promise;
        };
    }
]);
