"use strict";

/*
* @ngDoc          Service
* @name           nms.management.ManageUserModalService
* @description    Service para a abertura da Modal para gerenciamento de usuários.
* @requires       [ngDialog, ManageUsersService, SystemConfigurationService]
*/
var app = angular.module("nms.management");
app.service("ManageUserModalService", ["$rootScope", "ngDialog", "ManageUsersService", "SystemConfigurationService",
    "RESTRICTION_RULE_MATCHING_TYPES", "RESTRICTION_RULE_MATCHING_MODES",
    function($rootScope, ngDialog, ManageUsersService, SystemConfigurationService, RESTRICTION_RULE_MATCHING_TYPES,
        RESTRICTION_RULE_MATCHING_MODES) {
        const service: any = {};

        var openModal = function(resolve, callback) {
            ngDialog.openConfirm({
                template: "templates/features/management/users/modal/manage-user-modal.html",
                controller: "ManageUserModalController",
                className: "big-modal",
                resolve: resolve
            }).then(function(user) {
                callback(user);
            });
        };

        service.openAddUserModal = function(usernamesInUse, callback) {
            var resolve: any = {
                usernamesAlreadyInUse: function() {
                    return usernamesInUse;
                },
                securitySystemConfig: function() {
                    return SystemConfigurationService.getSecuritySystemConfig();
                },
                userData: function() {
                    return null;
                },
                selfEditing: function() {
                    return false;
                },
                ldapMode: function() {
                    return SystemConfigurationService.getLdapMode();
                },
                rulesMatchingModes: function() {
                    return RESTRICTION_RULE_MATCHING_MODES;
                },
                ruleRestrictionTypes: function() {
                    return RESTRICTION_RULE_MATCHING_TYPES;
                }
            };

            openModal(resolve, callback);
        };

        service.openEditUserModal = function(username, callback, isSelfEditing) {
            var resolve: any = {
                usernamesAlreadyInUse: function() {
                    return [];
                },
                securitySystemConfig: function() {
                    return SystemConfigurationService.getSecuritySystemConfig();
                },
                userData: function() {
                    return ManageUsersService.getUser(username);
                },
                selfEditing: function() {
                    return isSelfEditing || false;
                },
                ldapMode: function() {
                    return SystemConfigurationService.getLdapMode();
                },
                rulesMatchingModes: function() {
                    return RESTRICTION_RULE_MATCHING_MODES;
                },
                ruleRestrictionTypes: function() {
                    return RESTRICTION_RULE_MATCHING_TYPES;
                }
            };

            openModal(resolve, callback);
        };

        service.editLoggedUser = function(callback) {
            service.openEditUserModal($rootScope.loggedUser, callback, true);
        };

        return service;
    }
]);
