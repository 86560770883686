var app = angular.module("nms.dynamicDevice");

app.directive("treeNodeChildren", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            scope: {
                node: "=",
                parentNode: "=",
                form: "=",
                pathKeys: "=",
                childData: "=",
                childType: "@"
            },
            templateUrl: "templates/features/dynamic-device/sidebar/tree/tree-node-children.html",
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);
