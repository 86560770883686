export const DEVICES_TABLE_SERVICE = "DevicesTableService";
export const SNMP_DATA = "SNMP_DATA";
export const ANGULARJS_SCOPE = "$scope";
export const ANGULARJS_ROOTSCOPE = "$rootScope";
export const ANGULARJS_TRANSLATE = "$translate";
export const ANGULARJS_LOCATION = "$location";
export const CONNECTIVITY_TEST_STATUS = "CONNECTIVITY_TEST_STATUS";
export const STATE = "$state";
export const FILTER = "$filter";
export const USER_PREFERENCES_SERVICE = "UserPreferencesService";
export const NMS_ERRORS = "NMS_ERRORS";
export const NMS_STATES = "NMS_STATES";
export const NMS_FEATURES = "NMS_FEATURES";
export const CUSTOM_DEVICE = "CUSTOM_DEVICE";
export const LOCATION = "LOCATION";
export const SYSTEM_KEYWORD = "SYSTEM_KEYWORD";
export const BROADCAST_EVENTS = "BROADCAST_EVENTS";
export const CWMP_DOWNLOAD_FILE_TYPE = "CWMP_DOWNLOAD_FILE_TYPE";
export const LICENSE_SERVICE = "LicenseService";
export const NMS_LICENSES = "NMS_LICENSES";
export const APPLICATION_BRAND_ICONS = "APPLICATION_BRAND_ICONS";
export const AUTHENTICATION_SERVICE = "AuthenticationService";
export const WORKFLOW_SERVICE = "WorkflowService";
export const TEMPLATE_SERVICE = "TemplateService";
export const NG_DIALOG = "ngDialog";
export const VARIABLE_SERVICE = "VariableService";
export const TEMPLATE = "template";
export const ACTIONS_LIST = "ACTIONS_LIST";
export const APPLICATION_STATUS = "APPLICATION_STATUS";
export const APPLICATION_COMMANDS = "APPLICATION_COMMANDS";
export const ACTIVATION_SERVICE = "ActivationService";
export const CONFIG_REST_SERVICE = "ConfigRESTService";
export const CONVERTER_SERVICE = "ConverterService";
export const DEVICE_DROPDOWN_MODEL_SERVICE = "DeviceDropdownModelService";
export const DEVICE_DROPDOWN_SHORTCUTS_SERVICE = "DeviceDropdownShortcutsService";
export const DEVICE_SERVICE = "DeviceService";
export const MONITORING_SERVICE = "MonitoringService";
export const PRESENTATION_MODE = "PRESENTATION_MODE";
export const TEMPLATE_INSTANCE_ACCORDION_DEVICE_CHANGES_SERVICE = "TemplateInstanceAccordionDeviceChangesService";
export const TEMPLATE_INSTANCE_SERVICE = "TemplateInstanceService";
export const TEMPLATE_INSTANCE_VALIDATOR_SERVICE = "TemplateInstanceValidatorService";
export const VARIABLES = "VARIABLES";
export const CUT_STRING_FILTER = "cutStringFilter";
export const TEMPLATE_INSTANCE = "templateInstance";
export const VARIABLE_TYPES = "variableTypes";
export const TEMPLATE_TYPE = "TEMPLATE_TYPE";
export const TEMPLATE_LANGUAGE = "TEMPLATE_LANGUAGE";
export const ANGULARJS_TIMEOUT = "$timeout";
export const WEBSOCKET = "$websocket";
export const WINDOW = "$window";
export const OPTIONS_EXTRACTION_STATUS = "OPTIONS_EXTRACTION_STATUS";
export const TEMPLATE_INSTANCE_VARIABLE_SERVICE = "TemplateInstanceVariableService";
export const DEVICE_OPTIONS_EXTRACTION_SERVICE = "DeviceOptionsExtractionService";
export const DEVICE_DISCOVER_SERVICE = "DeviceDiscoverService";
export const MANAGEMENT_DEVICE_REST_SERVICE = "ManagementDeviceRestService";
export const DEVICE_PERMISSION_SERVICE = "DevicePermissionService";
export const CREDENTIALS_SERVICE = "CredentialsService";
export const PROPERTIES_SERVICE = "PropertiesService";
export const CONNECTIVITY_TEST_SERVICE = "ConnectivityTestService";
export const GPON_ONU_SERVICE = "GponOnusService";
export const GPON_ONUS_STATUS_CONFIG = "GPON_ONUS_STATUS_CONFIG";
export const LABEL_COLOR_SERVICE = "LabelColorService";
export const LOADING_PANE_SERVICE = "LoadingPaneService";
