"use strict";
import { CpeField, EquipmentRuleOption } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { from } from "rxjs";
import { TemplateInstanceRESTService } from "../template-instance-rest-service";

/**
 * @ngdoc function
 * @name nms.templates.controller:AddCpeModalController
 * @description
 * Responsável pela adição de CPEs na aplicação de template
 */
var app = angular.module("nms.templates");

// TODO - [US-3675] - Migrar modal para o Angular 8
app.controller("AddCpeModalController", [
    "$scope",
    "cpesAlreadyAdded",
    "templateInstanceResolvedInformation",
    "PRESENTATION_MODE",
    "$translate",
    "equipmentModelRestrictionTypes",
    "TEMPLATE_TYPE",
    "EquipmentService",
    "TemplateInstanceRESTService",
    function (
        $scope,
        cpesAlreadyAdded,
        templateInstanceResolvedInformation,
        PRESENTATION_MODE,
        $translate,
        equipmentModelRestrictionTypes,
        TEMPLATE_TYPE,
        EquipmentService,
        TemplateInstanceRESTService: TemplateInstanceRESTService
    ) {
        $scope.cpes = [];
        $scope.hiddenActions = ["remove"];
        $scope.templateType = templateInstanceResolvedInformation.templateType;
        $scope.templates = _.get(templateInstanceResolvedInformation, "templateAlreadyAdded", null);
        $scope.disableRefreshButton = false;
        $scope.presentationMode = templateInstanceResolvedInformation.presentationMode;
        $scope.presentationModeDefault = PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;
        $scope.cpeModelRestrictionTypes = equipmentModelRestrictionTypes;
        $scope.refreshActions = {
            startUpdatingFn: function () {
                $scope.disableRefreshButton = true;
            },
            finishedUpdatingFn: function () {
                $scope.disableRefreshButton = false;
            }
        };
        $scope.selectedItems = {};

        var restrictionRules = _.compact(
            _.map($scope.templates, function (template) {
                if (template.restrictTemplate) {
                    return template.equipmentRestrictionFilter;
                }
            })
        );

        $scope.cpesProvider = () => {
            const equipmentsFilters = {
                globalRules: restrictionRules,
                rulesByTemplate: {}
            };

            return from(TemplateInstanceRESTService.getCpesByRestrictions(equipmentsFilters).then(
                function (response) {
                    return _.values(response.equipmentDetails);
                }
            ));
        };

        this.loadTootipTexts = function () {
            $scope.theSearchUsesOnlyTheSelectedField = $translate.instant(
                "tooltips.addEquipmentmodal.theSearchUsesOnlyTheSelectedField"
            );
        };
        this.loadTootipTexts();

        $scope.selectAndUnselectCpe = function (cpe) {
            const selectedCpes = $scope.selectedItems.get();
            EquipmentService.selectAndUnselectEquipment(cpe, selectedCpes);
        };

        $scope.validateAndAddCpes = function () {
            const selectedCpes = $scope.selectedItems.get();
            if ($scope.isValidCpesToAdd(selectedCpes)) {
                $scope.confirm(selectedCpes);
            }
        };

        $scope.isValidCpesToAdd = function (selectedCpes) {
            return EquipmentService.validateAddEquipments(
                selectedCpes,
                cpesAlreadyAdded,
                $scope.presentationMode.value,
                templateInstanceResolvedInformation.shouldAddDevicesToAllTemplates,
                $scope.templates,
                TEMPLATE_TYPE.TR_069.name
            );
        };
    }
]);
