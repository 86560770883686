import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_ROOTSCOPE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { CronTriggerValidator } from "@nms-ng2/app/modules/scheduler/triggers/time-trigger/cron-trigger/cron-trigger-validator";
import { InformTriggerValidator } from "@nms-ng2/app/modules/scheduler/triggers/inform-trigger/inform-trigger-validator";
import { TimeTriggerValidator } from "@nms-ng2/app/modules/scheduler/triggers/time-trigger/time-trigger-validator";
import { TriggersService } from "@nms-ng2/app/modules/scheduler/triggers/triggers.service";
import {
    Trigger,
    TriggerType,
    TimeTriggerData,
    InformTriggerData,
    CronTriggerData
} from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";

/**
 * Classe responsável por reunir a validação dos agendamentos
 */
@Injectable({
    providedIn: "root"
})
export class TriggersValidatorService {
    constructor(
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope,
        private readonly triggersService: TriggersService,
        private readonly cronValidator: CronTriggerValidator,
        private readonly timeValidator: TimeTriggerValidator,
        private readonly informValidator: InformTriggerValidator
    ) {}

    validate(allTriggers: Array<Trigger>): boolean {
        let isValid = true;

        try {
            allTriggers
                .filter(trigger => trigger.triggerEnabled)
                .forEach(trigger => this.validateTrigger(trigger));
         } catch (error) {
            this.$rootScope.showDialog({
                translateKey: error.message
            });
            isValid = false;
         }

        return isValid;
    }

    private validateTrigger(trigger: Trigger): void {
        if (this.triggersService.isTimeTrigger(trigger.triggerType)) {
            this.timeValidator.validate(trigger.triggerType, trigger.triggerData as TimeTriggerData);

            if (trigger.triggerType === TriggerType.CRON) {
                this.cronValidator.validate(trigger.triggerData as CronTriggerData);
            }
        } else if (trigger.triggerType === TriggerType.TR069_EVENT) {
            this.informValidator.validate(trigger.triggerData as InformTriggerData);
        }
    }
}
