"use strict";

/**
* @ngdoc function
* @name nms.templates.controller:WebsocketManagerCtrl
* @description
* # WebsocketManagerCtrl
* Controller of the nms.templates
*/
var app = angular.module("nms.templates");

app.factory("WebSocketManagerService", ["$rootScope", "$websocket", "$timeout", "$translate", "$injector", "BROADCAST_EVENTS",
    "APPLICATION_STATUS",
    function($rootScope, $websocket, $timeout, $translate, $injector, BROADCAST_EVENTS, APPLICATION_STATUS) {
        var service: any = {
            websocket: null
        };

        const isTemplateApplicationFinishedNotification = function(message) {
            return angular.isUndefined(message.equipmentApplicationStatus);
        };

        const hasTemplateApplicationFailed = function(message) {
            return message.status === "NOT_APPLIED";
        };

        const formatTemplateApplicationStatusMessage = function(message) {
            const notificationData = {
                templateInstanceName: message.templateInstanceName,
                status: message.status
            };

            return `${notificationData.templateInstanceName}<br />${$rootScope.templateInstanceStatus[notificationData.status]}`;
        };

        const displayMessage = function(message) {
            const nmsToastrService = $injector.get("NmsToastrService");

            const toastProperties = {enableHtml: true};
            if (message.isError) {
                nmsToastrService.error(message.message, null, toastProperties);
            } else if (hasTemplateApplicationFailed(message)) {
                nmsToastrService.error(formatTemplateApplicationStatusMessage(message), null, toastProperties);
            } else {
                nmsToastrService.info(formatTemplateApplicationStatusMessage(message), null, toastProperties);
            }
        };

        service.connect = function (contextPath, userId) {
            service.websocket = $websocket("wss://" + contextPath + "templateInstance/notification/" + userId);

            service.websocket.onMessage(function (event) {
                const message = JSON.parse(event.data);

                if (message.templateId) {
                    $timeout(function () {
                        $rootScope.templateResultsChanged = event.data;
                    }, 100);
                } else {
                    if (message.equipmentDetails && message.equipmentDetails.name) {
                        $rootScope.resultsChanged = event.data;
                    }

                    if (isTemplateApplicationFinishedNotification(message)) {
                        displayMessage(message);
                    }
                }
                service.broadcastTemplateStatus(message);
            });

            service.websocket.onError(function() {
                $translate.onReady(function() {
                    $rootScope.showDialog({
                        translateKey: "error.websocket",
                        ngDialogOptions: {
                            appendClassName: "non-centered-dialog-message pre-formatted-text"
                        }
                    });
                });
            });
        };

        service.disconnect = function() {
            service.websocket.close();
        };

        service.broadcastTemplateStatus = function(message) {
            if (message.templateApplicationStatus ===  APPLICATION_STATUS.APPLYING
                    || isTemplateApplicationFinishedNotification(message)) {
                $rootScope.$broadcast(BROADCAST_EVENTS.UPDATE_TEMPLATE_APPLICATION_STATUS, message);
            }
        }

        return service;
    }
]);
