"use strict";

/**
 * Filtro que limpa quebra de linha (\r).
 */
var app = angular.module("nms");

app.filter("cleanLineBreak",
    function() {
        return function(input) {
            return input.replace(/\r|\\r/g, "");
        };
    }
);
