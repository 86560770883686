"use strict";

/**
 * Filter responsible to substring a given string at a given point, after that an ellipsis is added.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.filter("cutString",
    function() {
        return function(input, maxChars) {
            if (input && input.split("").length > maxChars) {
                return input.substring(0, maxChars) + "...";
            }

            return input;
        };
    }
);
