"use strict";

/**
* @ngdoc controller
* @name nms.components.autoUpdater:DevicePollingStatusController
* @description
* # DevicePollingStatusController
* Classe de controle para a diretiva de status de polling.
*/
var app = angular.module("nms");

app.controller("DevicePollingStatusController", ["$rootScope", "$scope", "$translate", "POLLING_STATES",
    "DevicePollingService", "AutoUpdaterService", "$log", "ManagementDeviceRestService", "$timeout", "$interval",
    function($rootScope, $scope, $translate, POLLING_STATES, DevicePollingService, AutoUpdaterService, $log,
        ManagementDeviceRestService, $timeout, $interval) {
        var requestPolling = function() {
            DevicePollingService.requestPolling($scope.deviceId);
        };
        var errorDialog = null;
        var checkPollingTask;
        var tooLongPollingTask;
        var pollingTimeoutInMinutes;

        AutoUpdaterService.getAutoUpdaterModel().setUpdateFunction(requestPolling);

        var createActions = function() {
    var actions: any = {};
           actions[POLLING_STATES.STARTED] = function() {
                $log.debug("Polling has started");
                AutoUpdaterService.startUpdating();
            };
            actions[POLLING_STATES.FINISHED] = function(feedback) {
                $log.debug("Polling has finished successfully");
                AutoUpdaterService.finishedUpdating();
                AutoUpdaterService.setLastSuccessfulPollingDuration(feedback.payload.lastSuccessfulPollingDuration);
                // TODO: Transferir lógica de chamar callback ao terminar no update para o serviç de update
                // Como o callback pode ter parâmetros, não sei como isso poderia ser feito atualmente
                AutoUpdaterService.getAutoUpdaterModel().updateFinishedCallback($scope.deviceId);
            };
            actions[POLLING_STATES.ERROR] = function(feedback) {
                $log.debug("Error on polling");
                AutoUpdaterService.finishedUpdating();

                _.result(errorDialog, "close");
                errorDialog = $rootScope.showDialogSingularOrPlural({
                    translateObjs: [{
                        singularKey: "device.polling.status.pollingConcludedWithFail",
                        isPlural: false
                    },
                    {
                        singularKey: "device.polling.status.pollingError",
                        isPlural: false,
                        insideMsgParams: [feedback.processDetails]
                    },
                    {
                        singularKey: "device.polling.status.notUpdatedData",
                        isPlural: false
                    }]
                });
            };

            return actions;
        };

        var pollingTimedOut = function() {
            AutoUpdaterService.finishedUpdating();
            $rootScope.showDialog({
                translateKey: "device.polling.status.timeout",
                params: [pollingTimeoutInMinutes],
                paramsInsideMessage: true
            });
        };

        var init = function() {
            DevicePollingService.getPollingTimeoutInMinutes().then(function(response) {
                pollingTimeoutInMinutes = response;
                DevicePollingService.checkFirstPollingStatus($scope.deviceId, function() {
                    if (AutoUpdaterService.getLastSuccessfulPollingDuration() === null) {
                        ManagementDeviceRestService.findDeviceById($scope.deviceId).then(function(deviceInfo) {
                            AutoUpdaterService.setLastSuccessfulPollingDuration(deviceInfo.lastSuccessfulPollingDuration);

                            var actions = createActions();
                            DevicePollingService.connectAndRequestPolling(actions, $scope.deviceId);
                            $scope.startController();
                        });
                    }
                });
            });
        };

        init();

        // Esse método executa o finishedUpdating pois o evento é acionado quando um polling é requisitado, porém o websocket não
        // recebeu nenhuma resposta se deve iniciar o polling, por isso o update precisa ser finalizado.
        var unregisterPollingRequested = $scope.$on("pollingRequested", function() {
            // TODO: Abstrair lógica de start/finishedUpdating para que fique totalmente contida no serviço de auto-updater
            AutoUpdaterService.finishedUpdating();
        });

        /**
         * Sempre que iniciar uma nova contagem progressiva, ou seja um novo polling, monitora o tempo
         * do polling para garantir que o contador não fique contando infinitamente.
         *
         * Esse monitoramento é feito de duas formas:
         *
         * 1) A cada 30 segundos (tempo fixo), pergunta ao serviço se o polling ainda está rodando.
         * Caso não esteja, a atualização da tela é reiniciada.
         *
         * 2) Ao final de 5 minutos (valor configurável via nms.conf) caso o polling ainda esteja rodando,
         * é exibida uma mensagem de erro para o usuário, e a atualização da tela é reiniciada. Se o valor configurado
         * for zero, a feature é desabilitada.
         */
        var unregisterStartPolling = $scope.$on("startCounter", function() {
            checkPollingTask = $interval(function() {
                DevicePollingService.checkPollingStatus($scope.deviceId);
            }, 30 * 1000);

            if (pollingTimeoutInMinutes > 0) {
                tooLongPollingTask = $timeout(pollingTimedOut, pollingTimeoutInMinutes * 60 * 1000);
            }
        });

        /**
        * Realiza o cancelamento do $timeout e $interval que faz o monitoramento de tempo de duração do polling.
        */
        var cancelMonitoringPolling = function() {
            $timeout.cancel(tooLongPollingTask);
            $interval.cancel(checkPollingTask);
        };

        /**
         * Ao encerrar o contador, cancela as tarefas que monitoram o tempo do polling.
         */
        var unregisterStopPolling = $scope.$on("stopCounter", function() {
            cancelMonitoringPolling();
            tooLongPollingTask = null;
            checkPollingTask = null;
        });

        $scope.$on("$destroy", function() {
            cancelMonitoringPolling();
            unregisterPollingRequested();
            unregisterStartPolling();
            unregisterStopPolling();

            AutoUpdaterService.reset();
            DevicePollingService.disconnect();
        });
    }
]);
