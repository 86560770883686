/**
 * Restrict the maximum number of items allowed on a list or leaflist.
 *
 * @author icaro.damiani
 */
var app = angular.module("nms.dynamicDevice");

app.directive("maxElementsRestriction", ["$filter",
    function($filter) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                ngModel.$validators.maxElementsValidator = function(modelValue) {
                    var maxElements = $filter("getMaxElements")(scope.list);

                    if (angular.isDefined(modelValue) && maxElements) {
                        var modelLength = angular.isArray(modelValue) ? modelValue.length : modelValue;

                        return modelLength <= maxElements;
                    }

                    return true;
                };
            }
        };
    }
]);
