import { Injectable } from "@angular/core";

/**
 * Serviço responsável por verificar se os templates selecionados possuem o mesmo tipo
 * Primeiramente filtra os templates pelo identificador 'type' e em seguida adiciona-os em um Set.
 * O Set não permite inserir valores idênticos, sendo assim, caso o tamanho do array for igual a 1
 * significa que todos os templates são do mesmo tipo, caso contrário possuem tipos diferentes.
 * @param templates selecionados
 * @returns true => para indicar que todos são do mesmo tipo
 * ou false => para indicar que possuem tipos diferentes
 */
 @Injectable({
    providedIn: "root"
})
export class TemplateValidatorTypeService {

    public areTemplatesTheSameType(checkedTemplates): boolean {
        var templateSelecteds = _.map(checkedTemplates, item => item.type);
        var templatesSelectedsSet = [...new Set(templateSelecteds)];
        return templatesSelectedsSet.length === 1;
    }
}