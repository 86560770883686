"use strict";

/**
 * @ngdoc service
 * @name nms.components.ui.device:DeviceDropdownModel
 * @description Modelo de dados utilizados pela diretiva device-dropdown.
 * # DeviceDropdownModel
 * Factory in the nms.
 */
var app = angular.module("nms");

app.service("DeviceDropdownModel",
    function() {
        function DeviceDropdownModel(device) {
            this.id = device.id;
            this.name = device.name;
            this.type = device.type;
            this.serialNumber = device.name;
            this.hostname = device.hostname;
            this.productModel = device.model;
        }

        return DeviceDropdownModel;
    }
);
