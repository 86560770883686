import { Component, Input } from "@angular/core";
import { NmsDropdownShortcut } from "@nms-ng2/app/shared/directives/nms-dropdown.upgraded.directive";

/** Formato a ser utilizado para cada item na lista de informações. */
export interface PageTitleItem {
  /** Chave de tradução do label. */
  titleKey: string;
  /** Conteúdo a ser apresentado. */
  content: string;
  /** Id do item. */
  id: string;
}

/**
 * Componente responsável por renderizar o cabeçalho de uma página.
 * O componente é composto por um menu dropdown e uma lista de informações dinâmicas.
 */
@Component({
  selector: "nms-page-title",
  templateUrl: "./nms-page-title.component.html",
  styleUrls: ["./nms-page-title.component.scss"],
})
export class NmsPageTitleComponent {
  @Input() titleItems: Array<PageTitleItem>;
  @Input() shortcuts: Array<NmsDropdownShortcut>;
  @Input() parameters: Object;
  @Input() dropdownTitle?: string;

  constructor() {
    this.titleItems = [];
    this.shortcuts = [];
  }
}
