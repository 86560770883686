const createIfStatement = (firstVarName, printVars) =>
`
    if ${firstVarName} == 'some_value':
        ${printVars}
`;

const createIfElseStatement = (firstVarName, printVars, someOtherMessage) =>
`
    if ${firstVarName} == 'some_value':
        ${printVars}
    else:
        print(${someOtherMessage})
`;

const createMultipleParameterizedForStatement = (variableItem, firstVarName, printVars) =>
`
    for index, ${variableItem} in enumerate(${firstVarName}):
        print(${variableItem})
        ${printVars}
`;

const createParameterizedForStatement = (variableItem, firstVarName) =>
`
    for ${variableItem} in ${firstVarName}:
        print(${variableItem})
`;

const createForStatement = () =>
`
    some_list = ['1','2','3','4']
    for some_list_item in some_list:
        print(some_list_item)
`;

const createMainFunctionStatement = () =>
`
    def run(cpe):
        # your code here
`;

const createFunctionStatement = (functionParams, printVars) =>
`
    def some_function(${functionParams}):
        ${printVars}
`;

const createWhileStatement = (someVariable, loadVar) =>
`
    ${someVariable} = 0
    while ${someVariable} < 1:
        ${loadVar}
        ${someVariable} += 1
`;

const createCommentStatement = (comment) =>
`
    # ${comment}
`;

const createPrintStatement = () =>
`
    print('Some message')
`;

const createAddObjectTr069Method = () =>
`
    cpe.addObject('InternetGatewayDevice.SomePath.SomeObject.')
`;

const createDeleteObjectTr069Method = () =>
`
    cpe.deleteObject('InternetGatewayDevice.SomePath.SomeObject.1')
`;

const createDownloadTr069Method = () =>
`
    cpe.download('http://file_server/file', 1)
`;

const createGetParameterNamesTr069Method = () =>
`
    cpe.getParameterNames('InternetGatewayDevice.SomePath.', True)
`;

const createGetParameterValuesTr069Method = (parameters) =>
`
    cpe.getParameterValues(${parameters})
`;

const createSetParameterValuesTr069Method = (varName) =>
`
    cpe.setParameterValues('InternetGatewayDevice.SomePath.SomeParameter', ${varName})
`;

const createMultipleSetParameterValuesTr069Method = (paramValues) =>
`
    cpe.setParameterValues({${paramValues}})
`;

const createRebootTR069Method = () =>
`
    cpe.reboot()
`;

const createGetConnectionRequestUrlCpeMethod = () =>
`
    cpe.getConnectionRequestUrl()
`;

const createGetHardwareVersionCpeMethod = () =>
`
    cpe.getHardwareVersion()
`;

const createGetHostnameCpeMethod = () =>
`
    cpe.getHostname()
`;

const createGetLastInformCpeMethod = () =>
`
    cpe.getLastInform()
`;

const createGetManufacturerCpeMethod = () =>
`
    cpe.getManufacturer()
`;

const createGetProductModelCpeMethod = () =>
`
    cpe.getProductModel()
`;

const createGetSerialNumberCpeMethod = () =>
`
    cpe.getSerialNumber()
`;

const createGetProvisioningCodeCpeMethod = () =>
    `
    cpe.getProvisioningCode()
`;

const createGetSoftwareVersionCpeMethod = () =>
    `
    cpe.getSoftwareVersion()
`;

const createHasValueTemplateFunction = () =>
    `
    if hasValue('someKey'):
        loadValue('someKey')
    else:
        print('some message')
`;

const createLoadValueTemplateFunction = () =>
    `
    loadValue('someKey')
`;

const createSaveValueTemplateFunction = () =>
    `
    saveValue('someKey', 'someValue')
`;

const createStopTemplateFunction = () =>
    `
    stop('Some error message.')
`;

export default {
    createIfStatement,
    createIfElseStatement,
    createMultipleParameterizedForStatement,
    createParameterizedForStatement,
    createForStatement,
    createMainFunctionStatement,
    createFunctionStatement,
    createWhileStatement,
    createCommentStatement,
    createPrintStatement,
    createAddObjectTr069Method,
    createDeleteObjectTr069Method,
    createDownloadTr069Method,
    createGetParameterNamesTr069Method,
    createGetParameterValuesTr069Method,
    createSetParameterValuesTr069Method,
    createMultipleSetParameterValuesTr069Method,
    createRebootTR069Method,
    createGetConnectionRequestUrlCpeMethod,
    createGetHardwareVersionCpeMethod,
    createGetHostnameCpeMethod,
    createGetLastInformCpeMethod,
    createGetManufacturerCpeMethod,
    createGetProductModelCpeMethod,
    createGetSerialNumberCpeMethod,
    createGetSoftwareVersionCpeMethod,
    createHasValueTemplateFunction,
    createLoadValueTemplateFunction,
    createSaveValueTemplateFunction,
    createStopTemplateFunction,
    createGetProvisioningCodeCpeMethod
};
