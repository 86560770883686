import { Inject, Injectable } from "@angular/core";
import { ModalFactory } from "@nms-ng2/app/shared/services/modal/modal.factory";
import { CreateBackupModalComponent } from "../device/device-backup/create-backup-modal/create-backup-modal.component";
import { SchedulerInterface } from "./scheduler.interface";
import { JobExecutionStatus, SchedulerJob } from "./scheduler.models";import {
    DeviceResultBackupModalComponent
} from "../device/device-backup/device-result-backup-modal/device-result-backup-modal.component";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsDialogService } from "@nms-angular-toolkit/nms-dialog";

/**
 * Implementação de SchedulerInterface para agendamentos do tipo backup de equipamentos.
 */
@Injectable({
    providedIn: "root"
})
export class SchedulerDeviceBackupService implements SchedulerInterface {

    /**
     * Mapa contendo os status que evitam a abertura da modal com as chaves de tradução a serem apresentadas.
     */
    private readonly messageKeyByStatus: Partial<{ [status in JobExecutionStatus]: string }>;

    constructor(private readonly modalFactory: ModalFactory,
        @Inject(ANGULARJS_TRANSLATE) private readonly $translate,
        private readonly nmsDialogService: NmsDialogService) {
        this.messageKeyByStatus = {
            [JobExecutionStatus.EXECUTING]: this.$translate.instant("scheduler.backup.validation.error.jobRunning"),
            [JobExecutionStatus.NOT_REQUESTED]: this.$translate.instant("scheduler.backup.validation.error.jobNotExecuted")
        };
    }

    editScheduler(schedulerJob: SchedulerJob) {
        this.modalFactory.openAsyncModal(CreateBackupModalComponent, { schedulerJob: schedulerJob });
    }

    viewResults(schedulerJob: SchedulerJob) {
        if (this.isStatusInvalidForOpenModal(schedulerJob.status)) {
            this.nmsDialogService.openDialog({
                description: this.messageKeyByStatus[schedulerJob.status],
                confirmButton: "OK"
            });
        } else {
            this.modalFactory.openAsyncModal(DeviceResultBackupModalComponent, { schedulerJobId: schedulerJob.id });
        }
    }

    private isStatusInvalidForOpenModal(status: JobExecutionStatus): boolean {
        return Object.keys(this.messageKeyByStatus).includes(status);
    }
}
