"use strict";

var app = angular.module("nms");

app.directive("changePage", ["$timeout", "UserPreferencesService", "TABLE_PROPERTIES",
    function($timeout, UserPreferencesService, TABLE_PROPERTIES) {
        return function(scope, element) {
            element.bind("keypress", function(event) {
                // Get the input value
                var input = element.val();

                if (!scope.filters.pageSize) {
                    var pageSizeInStorage = UserPreferencesService.loadPreferences({}, scope.filters.origin, ["pageSize"]);
                    scope.filters.pageSize = pageSizeInStorage.pageSize || TABLE_PROPERTIES.DEFAULT_PAGE_SIZE;
                }

                // if ENTER, then apply the filter
                if (input && parseInt(input) > 0 && event.which === 13) {
                    scope.filters.pageNumber = parseInt(input)-1;

                    scope.setPageFiltersSession();
                    scope.loadPage();
                }
            });
        };
    }
]);
