import { Component, Input, Inject } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { NotificationType } from "@nms-ng2/app/shared/services/nms-notification/nms-notification.service";
import { ANGULARJS_ROOTSCOPE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

export interface NotificationPreferences {
  notificationType: NotificationType,
  autoClose: boolean,
  enabled: boolean,
  delayBeforeCloseInSeconds: number,
  admin: boolean
}

/**
 * Classe responsável por exibir uma notificação de usuário.
 */
@Component({
    selector: "notification-preferences",
    templateUrl: "./notification-preferences.component.html",
    styleUrls: ["./notification-preferences.component.scss"],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class NotificationPreferencesComponent {

    @Input()
    userNotification: NotificationPreferences;

    isAdminUser = false;

    constructor(@Inject(ANGULARJS_ROOTSCOPE) private readonly rootScope: any) {
        this.isAdminUser = rootScope.isAdmin;
    }
}
