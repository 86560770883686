"use strict";

/**
* @ngdoc function
* @name nms.templates.controller:SearchKeywordController
* @description
* # SearchKeywordController
* Controller of the nms.templates
*/
var app = angular.module("nms.templates");

app.controller("SearchKeywordController", ["$scope", "$attrs", "UserPreferencesService", "TABLE_PROPERTIES",
    function($scope, $attrs, UserPreferencesService, TABLE_PROPERTIES) {
        if ($attrs.sidebarId) {
            $scope.sidebarId = $attrs.sidebarId;
        }

        $scope.filterByKeyword = function(keyword) {
            if (keyword) {
                $scope.filters.pageNumber = 0;
                $scope.pageDetails.currentPage = $scope.filters.pageNumber + 1;
                $scope.filters.keyword = keyword.keyword;
            } else {
                $scope.filters.keyword = null;
            }

            // I can't test this IF because jasmine fill it with an Spy,
            // then it always is fullfield in test
            if ($scope.clearSearch) {
                $scope.clearSearch(true);
            }

            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);

            if (!$scope.pageDetails.currentPage) {
                $scope.pageDetails.currentPage = 0;
            }

            if (!$scope.filters.pageSize) {
                $scope.filters.pageSize = TABLE_PROPERTIES.DEFAULT_PAGE_SIZE;
            }

            $scope.loadPage();
        };
    }
]);
