var app = angular.module("nms");

app.directive("ngIncludeWrapper", function() {
    return {
        restrict: "E",
        scope: {
            templateUrl: "=",
            controller: "=",
            formName: "="
        },
        template: `<div ng-if='formName != null'>
                       <form class="form-horizontal" name="{{formName}}" role="form" novalidate>
                           <div ng-include='templateUrl'></div>
                       </form>
                   </div>
                   <div ng-if='formName == null'>
                       <div ng-include='templateUrl'></div>
                   </div>`
    };
});
