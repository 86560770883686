import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { CpesActionsService } from "@nms-ng2/app/shared/services/actions/cpes-actions.service";
import { CpeShortcutsActionService } from "./cpe-shortcuts-action.service";

/**
 * Serviço responsável por retornar os shortcuts relacionados ao CPE
 * Foi necessária a criação desse serviço, pois a chamada da funcionalidade openDeviceCpeFn é um pouco diferente
 * na nova tela de GPON-ONUS.
 */
@Injectable({
    providedIn: "root"
})
export class CpeDropdownService {

    constructor(@Inject(ANGULARJS_TRANSLATE) public readonly translate,
        private cpesActionsService: CpesActionsService,
        private cpesShortcutsActionservice: CpeShortcutsActionService) {}

    getTR069Shortcuts = (openDeviceCpeFn) => {
        const hasCpeLastInform = (cpe) => !_.isArray(cpe) && _.isEmpty(cpe.cwmpCpeLastInform);
        return [
            {
                label: this.translate.instant("title.deviceConfiguration"),
                action: (cpe) => openDeviceCpeFn.call(null, cpe),
                hasDivider: true,
                isVisible: (cpe) => !_.isArray(cpe),
                disabledShortcutTooltip: this.translate.instant("gpon.onus.onuNotRegistered")
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.parameters"),
                action: (cpes) => this.cpesActionsService.tryToOpenParametersModalForCpes(cpes),
                isShortcutDisabled: hasCpeLastInform,
                disabledShortcutTooltip: this.translate.instant("gpon.onus.onuNotRegistered")
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.applyTemplate"),
                action: (cpes) => this.cpesShortcutsActionservice.goToTemplateApplication([cpes]),
                isShortcutDisabled: hasCpeLastInform,
                disabledShortcutTooltip: this.translate.instant("gpon.onus.onuNotRegistered")
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.viewTemplateApplication"),
                action: (cpes) => this.cpesShortcutsActionservice.viewTemplateApplication([cpes]),
                isShortcutDisabled: hasCpeLastInform,
                disabledShortcutTooltip: this.translate.instant("gpon.onus.onuNotRegistered")
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.credentials"),
                action: (cpes) => this.cpesActionsService.tryToCredentialsModalCpes(cpes),
                disabledShortcutTooltip: this.translate.instant("gpon.onus.onuNotRegistered")
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.testConnectivity"),
                action: (cpes) => this.cpesActionsService.tryToOpenConnectivityTestModalForCpes(cpes),
                isShortcutDisabled: hasCpeLastInform,
                disabledShortcutTooltip: this.translate.instant("gpon.onus.onuNotRegistered")
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.download"),
                action: (cpes) => this.cpesActionsService.tryToOpenDownloadRequestModal(cpes),
                isShortcutDisabled: hasCpeLastInform,
                disabledShortcutTooltip: this.translate.instant("gpon.onus.onuNotRegistered")
            },
            {
                label: this.translate.instant("gpon.cpes.tableActions.tr069.reboot"),
                action: (cpes) => this.cpesActionsService.tryToRebootCpes(cpes),
                isShortcutDisabled: hasCpeLastInform,
                disabledShortcutTooltip: this.translate.instant("gpon.onus.onuNotRegistered")
            }
        ];
    }
}