"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.ManageUsersActionsService
* @description - Service para gerenciamento das ações da tabela de usuários.
* Factory in the nms.management.
*/
var app = angular.module("nms.management");

app.service("ManageUsersActionsService", ["$rootScope", "$translate", "ManageUserModalService", "ManageUsersService",
    "SystemConfigurationService", "SYSTEM_USER", "LDAP_MODE",
    function($rootScope, $translate, ManageUserModalService, ManageUsersService, SystemConfigurationService, SYSTEM_USER,
        LDAP_MODE) {
        const service: any = {};

        var getRemoveInvalidMessage = function(usersToRemove) {
            if (_.isEmpty(usersToRemove)) {
                return "userList.tableActions.no.user.selected";
            } else if (_.includes(usersToRemove, SYSTEM_USER.DATACOM) && _.includes(usersToRemove, SYSTEM_USER.ADMINISTRATOR)) {
                return "userList.tableActions.remove.system.users.selected";
            } else if (_.includes(usersToRemove, SYSTEM_USER.ADMINISTRATOR)) {
                return "userList.tableActions.remove.administrator.user.selected";
            } else if (_.includes(usersToRemove, SYSTEM_USER.DATACOM)) {
                return "userList.tableActions.remove.datacom.user.selected";
            } else if (_.includes(usersToRemove, $rootScope.loggedUser.toUpperCase())) {
                return "userList.tableActions.remove.own.user.selected";
            }
        };

        service.openAddUserModal = function(users, callback) {
            ManageUserModalService.openAddUserModal(_.map(users, "username"), callback);
        };

        service.openEditUserModal = function(username, callback) {
            ManageUserModalService.openEditUserModal(username, callback);
        };

        /*
        * Valida se um usuário foi marcado na tabela.
        * Caso tenha sido selecionado mais de um usuário ou nenhum usuário tenha sido selecionado na tabela, a validação falhará
        * e será apresentado a modal de erro, caso contrário, a tela de edição será aberta.
        */
        service.verifyAndOpenEditUserModal = function(checkedUsers, callback) {
            if (_.isEmpty(checkedUsers)) {
                $rootScope.showDialog({
                    translateKey: "userList.tableActions.no.user.selected"
                });
            } else if (checkedUsers.length > 1) {
                $rootScope.showDialog({
                    translateKey: "userList.tableActions.edit.more.than.one.user.selected"
                });
            } else {
                service.openEditUserModal(checkedUsers[0].username, callback);
            }
        };

        service.editLoggedUser = function(callback) {
            var ldapCallback = function(ldapMode) {
                if (ldapMode === LDAP_MODE.AUTHORIZATION) {
                    $rootScope.showDialog({
                        translateKey: "manage.user.ldap.authorization.edit.logged.user"
                    });
                } else {
                    ManageUserModalService.editLoggedUser(callback);
                }
            };
            SystemConfigurationService.getLdapMode(ldapCallback);
        };

        service.tryRemoveUsers = function(checkedUsers, callback) {
            var usersToRemove = _.map(checkedUsers, function(user) {
                return user.username;
            });
            var invalidMessage = getRemoveInvalidMessage(usersToRemove);

            if (invalidMessage) {
                $rootScope.showDialog({
                    translateKey: invalidMessage
                });
            } else {
                var confirmMessage = usersToRemove.length === 1
                    ? "userList.tableActions.remove.user.remove.confirm"
                    : "userList.tableActions.remove.users.remove.confirm";

                $rootScope.showDialog({
                    translateKey: confirmMessage,
                    params: [usersToRemove.length],
                    paramsInsideMessage: true,
                    isConfirm: true
                }).then(function() {
                    ManageUsersService.removeUsers(usersToRemove, callback);
                });
            }
        };

        return service;
    }
]);
