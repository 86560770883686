/**
 * Directive responsible to save Dynamic Device resizer width on Session Storage.
 *
 * @author patrick.bard
 */
var app = angular.module("nms.dynamicDevice");

app.directive("saveResizerPosition", ["UserPreferencesService",
    function(UserPreferencesService) {
        return {
            restrict: "A",
            link: function(scope, element) {
                // TODO: Hoje o código apenas salva o valor no session storage e não o reutiliza.
                // Foi definido que seria mais util para o usuario manter esse comportamento.
                // Mas acho util manter essa diretiva aqui até que entre a ideia de um projeto de opções do usuário
                // onde isso poderia ser melhor utilizado.
                var id = angular.element(element).attr("id");

                scope.$on("verticalResizerChange", function(event, obj) {
                    if (obj.id === id) {
                        UserPreferencesService.savePreferences(_.pick(obj, "width"), obj.id, ["width"]);
                    }
                });
            }
        };
    }
]);
