"use strict";

/**
* @ngdoc directive
* @name nms.templates.directive:type
* @description
* # TypeDirective
*/
angular.module("nms.templates")
.directive("typeDirective", function() {
    return {
        templateUrl: "templates/features/template/components/ui/search/type.html",
        controller: "SearchTypeController"
    };
});
