"use strict";

/**
 * @ngdoc service
 * @name nms.components.modals.propertiesEditModal:PropertiesModel
 * @description
 *
 * # PropertiesModel
 *
 * Modelo de dados utilizados pela modal de propriedades.
 *
 * @author andre.pires
 */

var app = angular.module("nms");

app.service("PropertiesModel",
    function() {
        function PropertiesModel(properties, name) {
            if (name) {
                this.name = name;
            }

            this.notes = _.get(properties, "notes", "");
            this.project = _.get(properties, "project", "");
            this.address = _.get(properties, "externalLocation.address", "");
            this.city = _.get(properties, "externalLocation.city", "");
            this.state = _.get(properties, "externalLocation.state", "");
            this.country = _.get(properties, "externalLocation.country", "");
            this.station = _.get(properties, "internalLocation.station", "");
            this.room = _.get(properties, "internalLocation.room", "");
            this.shelf = _.get(properties, "internalLocation.shelf", "");
        }

        return PropertiesModel;
    }
);
