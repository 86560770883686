import { Injectable } from '@angular/core';
import { TemplateInstanceIdentifierService } from './template-instance-identifier.service';
import { EquipmentIdentifier, TemplateIdentifier } from "./template-instance-models";
import { TemplateUtils } from '../template/template.utils';

type EquipmentLabelKeys = {
    deviceKey: string;
    cpeKey: string;
}

/**
 * Serviço responsável por identificar os detalhes das interfaces
 * referentes à Equipamento e Template baseado no tipo.
 */

@Injectable({
    providedIn: "root"
})
export class TemplateInstanceUtils {
    constructor(private readonly templateInstanceIdentifierService: TemplateInstanceIdentifierService,
        private readonly templateUtils: TemplateUtils) {}

    resolveAccordionIdentifier(identifier: EquipmentIdentifier | TemplateIdentifier){
        if (identifier.type === "TemplateIdentifier") {
            return (identifier as TemplateIdentifier).id;
        }

        return this.templateInstanceIdentifierService.resolveIdentifier(identifier);
    }

    isCliTemplateApplication(templateType: string): boolean {
        return this.templateUtils.isCliTemplate(templateType);
    }

    getEquipmentLabelKey(templateType: string): string {
        const keys: EquipmentLabelKeys = {
            deviceKey: "templateinstanceform.device",
            cpeKey: "templateinstanceform.cpe"
        }

        return this.getEquipmentLabelKeyByTemplateType(templateType, keys);
    }

    getEquipmentLabelKeyByTemplateType(type: string, possibleKeys: EquipmentLabelKeys): string {
        return this.isCliTemplateApplication(type) ? possibleKeys.deviceKey : possibleKeys.cpeKey
    }

    getKeyByTemplateType(templateType: string): string {
        return this.isCliTemplateApplication(templateType) ? "device" : "cpe";
    }
}