var app = angular.module("nms.dynamicDevice");

/**
 * Diretiva para exibir o valor default como placeholder para os tipos exibidos como input.
 *
 * - Decimal
 * - Integer
 * - Unsigned Integer
 * - String
 */
app.directive("inputDefaultValue", [
    function() {
        return {
            restrict: "C",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                var defaultValue = _.get(scope.leaf, "sub-statements.default");

                ngModel.$parsers.push(function(value) {
                    if (!value && defaultValue) {
                        attrs.$set("placeholder", "Default: " + defaultValue);
                        return defaultValue;
                    }

                    return value;
                });

                ngModel.$formatters.push(function(value) {
                    var valueAsString = "" + value;

                    if (valueAsString === defaultValue && defaultValue !== "") {
                        attrs.$set("placeholder", "Default: " + defaultValue);
                        return "";
                    }

                    return value;
                });
            }
        };
    }
]);
