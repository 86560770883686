var app = angular.module("nms.dynamicDevice");

app.directive("boolean", ["DomainHandlerService",
    function(domainHandlerService) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/boolean.html",
            link: {
                pre: function($scope) {
                    var leaf = $scope.leaf;
                    $scope.hasDefaultValue = leaf["sub-statements"] && _.has(leaf["sub-statements"], "default");
                }
            }
        };
    }
]);
