"use strict";
var app = angular.module("nms");

/** Opções de configuração default da tabela. */
const TABLE_DEFAULT_CONFIG: any = {
    hideCustomizedTableComponents: false,
    progressbarInformationTranslateKey: "nms-table.commons.information.progressbar",
    dirty: false
};

/** Atributos defaults e requeridos nas colunas. */
const DEFAULT_COLUMNS_OPTIONS = {
    show: true,
    editable: true,
    hidden: false
};

/**
 * Cria tabela padronizada na aplicação.
 * As opções das variáveis definidas pelo scopo estão descritas no arquivo
 * nms-table-models.ts
 *
 * Esta diretiva adiciona as seguintes features da tabela:
 * - Redimensionamento de colunas.
 * - Seleção de itens da tabela.
 * - Salvar resize das colunas no localStorage por usuário.
 * - Mostrar ou esconder colunas.
 * - Reordenação de colunas.
 * - Barra de progresso para elementos com atualização assíncrona, por exemplo,
 * teste de conectividade.
 * */
app.directive("nmsTableOld", ["$translate",
    function($translate) {
        return {
            restrict: "E",
            controller: "NmsTableController",
            template: `<ng-include src="'templates/components/ui/nms-table/nms-table.html'"></ng-include>`,
            scope: {
                columns: "=",
                rows: "=",
                tableActions: "=",
                config: "=",
                asyncUpdater: "=?",
                dropdownMenus: "=?",
                bodyMessage: "=?",
                singleClick: "&?",
                doubleClick: "&?",
                selectedItems: "=?",
                selectionUpdater: "=?",
                showLoading: "=?"
            },
            link: {
                pre: (scope) => {
                    scope.config.hideCustomizedTableComponents = scope.config.hideCustomizedTableComponents
                        || TABLE_DEFAULT_CONFIG.hideCustomizedTableComponents;
                    scope.config.progressbarInformationTranslateKey = scope.config.progressbarInformationTranslateKey
                        || TABLE_DEFAULT_CONFIG.progressbarInformationTranslateKey;
                    scope.config.dirty = scope.config.dirty || TABLE_DEFAULT_CONFIG.dirty;

                    // Caso existam ações para a tabela a coluna de seleção será mostrada.
                    scope.config.showSelection = !!scope.tableActions;

                    let { tableTranslatePrefix } = scope.config;

                    /**
                     * Adiciona valores default na coluna caso ausente.
                     * Os valores default a serem adicionados estão cadastradas em DEFAULT_COLUMNS_OPTIONS
                     * além do valor de filtro
                     */
                    let addRequiredAttributesOnColumns = (column) => {
                        let title = _.isUndefined(tableTranslatePrefix)
                                    ? column.field
                                    : $translate.instant(`${tableTranslatePrefix}.${column.field}`);

                        return {
                            ...DEFAULT_COLUMNS_OPTIONS,
                            title,
                            filter: {[column.field]: "text"},
                            ...column,
                        };
                    }

                    scope.columns = scope.columns.map(addRequiredAttributesOnColumns);

                    /**
                     * TODO: Verificar a possibilidade de validar os dados de entrada a serem utilizados
                     * na renderização das tabelas de acordo com o tipo, por exemplo:
                     * - Se renderização for do tipo dropdown, checar se existe um atributo de ação.
                     *
                     * Esta validação é importante para evolução do componente para ajudar que os tipos de
                     * renderizações de coluna escolhido recebe os dados necessários, evitando possíveis bugs.
                     *
                     * A ideia seria avaliar a possibilidade de validar aqui, lançar uma exceção no browser e se possível
                     * não deixar renderizar a tabela, para que o desenvolvedor consiga facilmente ver que a utilização do
                     * componente está incorreta em tempo de desenvolvimento.
                     *
                     * Como ainda não temos um caminho claro e não foi estimado para está tarefa mapeamos este TODO para
                     * análise e priorização do PO.
                     *
                     * Possível ideia de resolução do problema:
                     * https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
                     */
                },
                post: (scope) => {
                    scope.init();
                }
            }
        };
    }
]);
