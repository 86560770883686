import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Inject, Injectable } from "@angular/core";
import { CpeFieldValues, DeviceFieldValues } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";

/**
 * Serviço responsável por formatar o texto das restrições (Modelo/Firmware) do equipamento por template.
 */
@Injectable({
    providedIn: "root"
})
export class RuleOptionsService {
    constructor(@Inject(ANGULARJS_TRANSLATE) private translate) { }

    format(rules: any[], restrictionTypes: any, equipmentFieldValue: DeviceFieldValues | CpeFieldValues): string {
        return _.chain(rules)
            .map((rule) => {
                if (rule.hasRuleOptions) {
                    const operationType = rule.rulesMatchingMode === "ALL"
                        ? this.translate.instant("general.and")
                        : this.translate.instant("general.or");

                    return _.chain(rule.ruleOptions)
                        .filter(ruleOption => ruleOption.equipmentField.value === equipmentFieldValue)
                        .map((ruleOption) => {
                            const ruleType = restrictionTypes[ruleOption.ruleType].type;
                            const ruleTypeName = restrictionTypes[ruleOption.ruleType].translate;
                            const ruleValues = ruleOption.values;

                            return ruleType === "BOOLEAN" ? ruleTypeName : ruleTypeName + " " + ruleValues;
                        })
                        .join(" " + operationType + " ")
                        .value();
                }

                return null;
            })
            .filter((templateRules) => {
                return !_.isEmpty(templateRules);
            })
            .join(", ")
            .value();
    }
}
