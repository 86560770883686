"use strict";

/**
* @ngdoc service
* @name nms.templates.VariableService
* @description
* # VariableService
* Factory in the nms.templates.
*/
angular.module("nms.templates")
.factory("TextVariableValidatorService", ["$rootScope", "ValidatorService",
    function($rootScope, ValidatorService) {
        const service: any = {};

        service.validateTextVariable = function(checkedVariable) {
        // Field Size validation
            if (angular.isUndefined(checkedVariable.rangeMax)) {
                ValidatorService.showAlertBasicTab("addVariableModal.error.invalidVariableMaximumSizeZero");
                return false;
            } else if (checkedVariable.rangeMax > 256) {
                ValidatorService.showAlertBasicTab("addVariableModal.error.invalidVariableMaximumSize256");
                return false;
            } else if ((checkedVariable.rangeMax === null && checkedVariable.rangeMin > 256)) {
                ValidatorService.showAlertBasicTab("addVariableModal.error.invalidVariableMinimumSize256");
                return false;
            } else if ((checkedVariable.rangeMax !== null && angular.isDefined(checkedVariable.rangeMin))
                        && (checkedVariable.rangeMin > checkedVariable.rangeMax)) {
                ValidatorService.showAlertBasicTab("addVariableModal.error.invalidVariableSize");
                return false;
            } else {
                if (checkedVariable.defaultValue) {
                    if (checkedVariable.defaultValue.length < checkedVariable.rangeMin
                        || (checkedVariable.rangeMax && checkedVariable.defaultValue.length > checkedVariable.rangeMax)) {
                        ValidatorService.showAlertBasicTab("addVariableModal.error.invalidDefaultValueSize");
                        return false;
                    } else if (checkedVariable.defaultValue.length > 256) {
                        ValidatorService.showAlertBasicTab("addVariableModal.error.invalidDefaultValueSizeGreaterThan256");
                        return false;
                    }
                }
            }

        // Field Regular Expression validation
            if (checkedVariable.regex) {
                try {
                    new RegExp(checkedVariable.regex);
                } catch (e) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.invalidRegex");
                    return false;
                }
            }

        // Field Regular Expression validation for Default Value
            if (checkedVariable.regex && checkedVariable.defaultValue) {
                var regex = new RegExp(checkedVariable.regex);
                var validForRegex = checkedVariable.defaultValue;
                validForRegex = validForRegex.replace(regex, "");

                if (validForRegex.length > 0) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.invalidDefaultValueForRegex");
                    return false;
                }
            }

            return true;
        };

        return service;
    }]);
