"use strict";

/**
* @ngdoc directive
* @name nms.directive:paginationLimitValidation
* @description Diretiva para validação dos limites de paginação, tanto de tamanho da página como de número de página.
*
* #model           - Obrigatório - Modelo que representa o tamanho da página ou o número da página.
*                                  Deve ser o mesmo definido no ng-model do input.
* #maxValue        - Opcional - Valor máximo que pode ser utilizado tanto no tamanho da página como no número da página.
*                               caso não seja definido, o default utilizado é 100.
*/
var app = angular.module("nms");
app.directive("paginationLimitValidation", ["$timeout", function($timeout) {
    return {
        restrict: "A",
        scope: {
            model: "=",
            maxValue: "="
        },
        link: function(scope, element) {
            var ENTER_KEY = 13;

            var validate = function(desiredValue, isPaste, keyCode?) {
                if (isPaste || keyCode !== ENTER_KEY) {
                    var maxValue = scope.maxValue != null ? scope.maxValue : 100;

                    if (!desiredValue.match("[0-9]") || maxValue === 0) {
                        event.preventDefault();
                        return false;
                    }

                    $timeout(function() {
                        var invalidRegex = /^0*|[\.']/g;
                        var valueToValidate = isPaste ? desiredValue : scope.model;
                        scope.model = valueToValidate.replace(invalidRegex, "");
                        var pageSize = parseInt(scope.model);

                        if (pageSize > maxValue) {
                            scope.model = scope.model.toString().substring(0, scope.model.length - 1);
                        }
                    }, 0);
                }
            };

            element.bind("keypress", function(event) {
                var keyCode = event.which || event.keyCode;
                var pressedValue = String.fromCharCode(keyCode);

                validate(pressedValue, false, keyCode);
            });

            element.bind("paste", function(event) {
                var pastedValue = event.originalEvent.clipboardData.getData("text/plain");

                validate(pastedValue, true);
            });
        }
    };
}]);
