import { Injectable, Inject } from "@angular/core";
import {
    ANGULARJS_TRANSLATE,
    ANGULARJS_ROOTSCOPE,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { NmsToasterLink } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr-model";
import {
    CpeErrorResponseHandlerService,
} from "@nms-ng2/app/modules/device/cpe-error-response-handler.service";
import { DownloadOperationResponse } from "./download.service";
import { NmsToastrLinkService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr-link.service";
import { ErrorDescriptionDetails } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";

/**
 * Classe responsável por controlar as requisições de download.
 */
@Injectable({
    providedIn: "root",
})
export class DownloadNotificationService {
    constructor(private toastr: NmsToastrService,
                private errorResponseHandlerService: CpeErrorResponseHandlerService,
                private nmsToastrLinkService: NmsToastrLinkService,
                @Inject(ANGULARJS_TRANSLATE) private translate: any,
                @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any) {
        this.showSuccessDownloadsNotifications = this.showSuccessDownloadsNotifications.bind(this);
        this.showErrorDownloadsNotifications = this.showErrorDownloadsNotifications.bind(this);
    }

    public showDownloadRequestNotification(serialNumbersLength: number): void {
        let toastInfoMessage: string = serialNumbersLength > 1
                                     ? "download.request.multiples.cpes"
                                     : "download.request.one.cpe";
        this.toastr.info(this.translate.instant(toastInfoMessage));
    }

    public showDownloadResponsesNotification(downloadResponses: DownloadOperationResponse[]): void {
        let responseStatusGroups = _.groupBy(downloadResponses, "downloadStatus");
        this.showDownloadNotifications(
            responseStatusGroups.COMPLETED,
            "download.request.multiples.cpes.completed.downloads",
            "download.request.one.cpe.completed.downloads",
            this.showSuccessDownloadsNotifications
        );

        this.showDownloadNotifications(
            responseStatusGroups.PENDING,
            "download.request.multiples.cpes.pending.downloads",
            "download.request.one.cpe.pending.downloads",
            this.showSuccessDownloadsNotifications
        );

        this.showDownloadNotifications(
            responseStatusGroups["null"],
            "download.request.multiples.cpes.failed.downloads",
            "download.request.one.cpe.failed.downloads",
            this.showErrorDownloadsNotifications
        );
    }

    private showDownloadNotifications(responses: DownloadOperationResponse[],
                                      multipleCpesMessageKey: string,
                                      singleCpeMessageKey: string,
                                      showNotification: Function): void {
        if (responses && responses.length > 0) {
            showNotification(responses, multipleCpesMessageKey, singleCpeMessageKey);
        }
    }

    private showSuccessDownloadsNotifications(downloadResponses: DownloadOperationResponse[],
                                              multipleCpesMessageKey: string,
                                              singleCpeMessageKey: string): void {
        if (downloadResponses.length > 1) {
            let message: string = this.translate
                .instant(multipleCpesMessageKey)
                .replace("{0}", downloadResponses.length);

            this.nmsToastrLinkService.showToastSuccess(message, this.createSuccessDetailsLink(downloadResponses));
        } else {
            let message: string = this.translate
                .instant(singleCpeMessageKey)
                .replace("{0}", downloadResponses[0].serialNumber);

            this.nmsToastrLinkService.showToastSuccess(message, []);
        }
    }

    private showErrorDownloadsNotifications(downloadResponses: DownloadOperationResponse[],
                                            multipleCpesMessageKey: string,
                                            singleCpeMessageKey: string): void {
        let message: string;
        const errorMessages: {
            serialNumber: string;
            errorMessage: ErrorDescriptionDetails;
        }[] = downloadResponses.map((response) => {
            let separator = downloadResponses.length > 1 ? "<br>&emsp;&emsp;" : "<br>";
            return {
                serialNumber: response.serialNumber,
                errorMessage:
                    this.errorResponseHandlerService.buildErrorDescriptionDetails(
                        response.errorResponse,
                        separator
                    ),
            };
        });

        if (errorMessages.length > 1) {
            message = this.translate
                .instant(multipleCpesMessageKey)
                .replace("{0}", downloadResponses.length);
        } else {
            let mainMessage = this.translate
                .instant(singleCpeMessageKey)
                .replace("{0}", downloadResponses[0].serialNumber);
            let failTypeMask = this.translate.instant("cwmp.parameters.request.failType");
            let failTypeMessage = failTypeMask.replace("{0}", errorMessages[0].errorMessage.description);

            message = `${mainMessage}<br>${failTypeMessage}`;
        }

        this.nmsToastrLinkService.showToastError(message, this.createErrorDetailsLink(errorMessages));
    }

    private createSuccessDetailsLink(downloadResponses: DownloadOperationResponse[]): NmsToasterLink[] {
        let message: string = this.translate.instant("download.request.multiples.cpes.serial.numbers");

        message += this.concatDetailsMessage(downloadResponses.map((response) => response.serialNumber));

        let linkUniqueId = downloadResponses
            .map((response) => response.serialNumber)
            .join("-");

        return [this.createToastLink(linkUniqueId, message, "information", false)];
    }

    private createErrorDetailsLink(
        errorMessages: {
            serialNumber: string;
            errorMessage: ErrorDescriptionDetails;
        }[]
    ): NmsToasterLink[] {
        let message: string;
        let alignLeft: boolean = false;
        if (errorMessages.length > 1) {
            alignLeft = true;
            message = errorMessages
                .map((message) => {
                    let errorDetails: string = `&emsp;${message.serialNumber}: ${message.errorMessage.description}`;

                    if (message.errorMessage.details) {
                        errorDetails += `<br>&emsp;&emsp;${message.errorMessage.details}`;
                    }

                    return errorDetails;
                })
                .join("<br><br>");
        } else {
            message = errorMessages[0].errorMessage.details;
        }

        let linkUniqueId = errorMessages
            .map((response) => response.serialNumber)
            .join("-");

        return [this.createToastLink(linkUniqueId, message, "error", alignLeft)];
    }

    private createToastLink(linkId: string,
                            message: string,
                            type: string,
                            alignLeft: boolean): NmsToasterLink {
        return {
            id: linkId,
            title: this.translate.instant("toastr.details.link"),
            action: () =>
                this.$rootScope.showDialog({
                    message,
                    insertScrollOnDetailsMessage: true,
                    type,
                    alignLeft
                })
        };
    }

    private concatDetailsMessage(downloadMessages: string[]): string {
        const lastMessage = downloadMessages.pop();

        return `${downloadMessages.join(", ")} ${this.translate.instant("general.and")} ${lastMessage}.`;
    }
}
