"use strict";

var app = angular.module("nms");

app.directive("pageTitleWrapper", ["$state", "$translate",
    function($state, $translate) {
        return {
            restrict: "E",
            scope: {
                subtitle: "=",
                device: "="
            },
            template: "<page-title></page-title>"
        };
    }
]);