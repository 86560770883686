var app = angular.module("nms.dynamicDevice");

app.directive("restrictionList", ["RestrictionService",
    function(restrictionService) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/list/restrictions/restriction.html",
            link: function(scope) {
                scope.hasMoreElementsThanMax = function(list) {
                    return restrictionService.hasMoreElementsThanMax(list.id, scope.form);
                };

                scope.hasFewerElementsThanMin = function(list) {
                    return restrictionService.hasFewerElementsThanMin(list.id, scope.form);
                };
            }
        };
    }
]);

app.directive("maxElements",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/list/restrictions/max-elements.html"
        };
    }
);

app.directive("minElements",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/list/restrictions/min-elements.html"
        };
    }
);

app.directive("duplicateKey",
    function() {
        return {
            restrict: "E",
            scope: {
                form: "=",
                keyValues: "&"
            },
            templateUrl: "templates/features/dynamic-device/domain/yang/list/restrictions/duplicate-key.html"
        };
    }
);
