var app = angular.module("nms.dynamicDevice");

/**
 * Diretiva para exibir leaf do tipo union.
 */
app.directive("union", ["LeafService", "$translate",
    function(LeafService, $translate) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/union.html",
            link: {
                pre: function preLink(scope, element, attrs) {
                    var possibleTypes = LeafService.getUnionPossibleTypes(scope.leaf.type);
                    var allowCreation = _.some(possibleTypes, function(type) {
                        return !LeafService.isDiscreteType(type);
                    });
                    scope.hasDiscreteType = _.some(possibleTypes, LeafService.isDiscreteType);
                    var isRequired = scope.isRequired(scope.leaf, scope.isKey);
                    scope.unionPossibleValues = LeafService.getPossibleValues(scope.leaf, scope.dataNode.value, isRequired);
                    if (angular.isUndefined(scope.leaf.possibleValues)) {
                        scope.leaf.possibleValues = scope.unionPossibleValues.slice();
                    }
                    scope.required = isRequired;
                    var unionSelectize = null;

                    var clearIfNullOrEmpty = function(value) {
                        if ((value === "" || value === null) && unionSelectize) {
                            unionSelectize.clear();
                        }
                    };

                    scope.unionSelectionConfig = {
                        create: allowCreation,
                        valueField: "value",
                        labelField: "value",
                        searchField: "value",
                        allowEmptyOption: false,
                        maxItems: 1,
                        placeholder: allowCreation
                                        ? $translate.instant("yang.leaf.types.union.selectOrInput")
                                        : $translate.instant("yang.leaf.types.union.select"),
                        onInitialize: function(selectize) {
                            unionSelectize = selectize;
                            if (scope.dataNode.value === null && selectize.options) {
                                if (scope.defaultValue) {
                                    scope.dataNode.value = scope.defaultValue;
                                }
                            }
                        },
                        createFilter: function(value) {
                            return allowCreation && !_.includes(scope.leaf.possibleValues, value);
                        },
                        onChange: clearIfNullOrEmpty
                    };

                    if (scope.isInsideListBody) {
                        scope.unionSelectionConfig.dropdownParent = "body";
                    }

                    scope.$watch("dataNode.value", function(newValue) {
                        clearIfNullOrEmpty(newValue);
                    });
                },
                post: function postLink(scope) {
                    var setOptions = function(possibleValues) {
                        scope.options = _.map(possibleValues, function(possibleValue) {
                            return {value: possibleValue};
                        });
                    };
                    setOptions(scope.leaf.possibleValues);
                    scope.$watch("leaf.possibleValues", function(newValues) {
                        if (angular.isDefined(newValues)) {
                            setOptions(newValues);
                        }
                    });
                }
            }
        };
    }
]);
