"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.ManagePermissionsFeaturesTabService
* @description - Service para leitura das permissões de features.
* Factory in the nms.management.
*/
var app = angular.module("nms.management");

app.service("ManagePermissionsFeaturesService", ["ManagePermissionsFeaturesRestService", "$translate",
    function(ManagePermissionsFeaturesRestService, $translate) {
        const service: any = {};
        var featureTranslatePreffix = "manage.permissions.features.name.";
        var moduleTranslatePreffix = "manage.permissions.features.modules.";

        /*
        * Chaves de features que não são configuráveis, pois não são do tipo enable/disable.
        */
        var notConfigurableFeatures = ["userTimeoutDelay", "loginAccessInitialTime", "loginAccessFinalTime"];

        /*
        * Recupera todas as permissões de features possíveis, filtrando as não configuráveis e incluindo atributos esperados por
        * @see nms.management.ManagePermissionsFeaturesTabController.
        *
        * @param {object} resposta da requisição pelas permissões de features
        *                 @see nms.management.ManagePermissionsFeaturesTabRestService#getAll.
        * @return {object[]} permissões de features processadas.
        */
        var getFeaturesPermissionsData = function(response) {
            return _.chain(response.plain())
                .filter(function(feature) {
                    return !_.includes(notConfigurableFeatures, feature.name);
                })
                .map(function(feature) {
                    feature.nameLabel = $translate.instant(featureTranslatePreffix + feature.name);
                    feature.modulesLabel = _.chain(feature.modules)
                        .map(function(module) {
                            return $translate.instant(moduleTranslatePreffix + module);
                        })
                        .join(", ")
                        .value();
                    return feature;
                })
                .value();
        };

        /*
        * Recupera todas as permissões de features habilitadas para inclusão no modelo de seleção.
        * Caso não existam permissões de features prévias, o padrão é possuir todas as features selecionadas.
        *
        * @param {object[]} Dados de permissões de features já processados.
        * @param {object[]} Permissões de features previamente definidos.
        * @return {object[]} Permissões de features habilitadas.
        */
        var getFeaturesSelected = function(featuresData, featurePermissions) {
            if (!_.isEmpty(featurePermissions)) {
                return _.filter(featuresData, function(feature) {
                    return _.find(featurePermissions, function(featurePermission) {
                        return featurePermission.instance === feature.name && featurePermission.permission;
                    });
                });
            }

            return featuresData;
        };

        /*
        * Processa a resposta de permissões de features, recuperando todas as features possíveis e aquelas que estão selecionadas.
        *
        * @param {object[]} permissões de features previamente definidas.
        * @return {object} objeto com as informações de permissões, no formato: {data: [], selected: []}.
        */
        service.getFeaturePermissions = function(featurePermissions) {
            return ManagePermissionsFeaturesRestService.getAll().then(function(response) {
                var features: any = {};
                features.data = getFeaturesPermissionsData(response);
                features.selected = getFeaturesSelected(features.data, featurePermissions);

                return features;
            });
        };

        return service;
    }
]);
