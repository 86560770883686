import { MatchingRules } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { TranslationMap } from "@nms-ng2/app/shared/models/translation.models";

var app = angular.module("nms.templates");

/**
 * @ngdoc directive
 * @name nms.templates.directive:variableParamsOptions
 * @description Diretiva para comandos de inclusão/bloqueio de opções.
 */
app.directive("variableParamsOptions", [
    function () {
        return {
            restrict: "E",
            scope: {
                id: "@",
                hasParams: "=",
                tabKey: "@",
                params: "=ngModel",
                isNmsOrUsedTemplate: "=",
                getTooltipForNmsTemplates: "=",
                cliCommandOptionExtractionFilterTypes: "=",
                rulesMatchingModes: "=",
                variable: "=",
                variablesAlreadyAdded: "=",
                language: "=",
                extractionTypeTooltip: "@",
                deviceVariables: "=",
                expressionsProvider: "&",
                formatCommand: "&",
                tooltipVariable: "&"
            },
            templateUrl: "templates/features/template/template/modals/variable/variable-params-options-panel.html",
            link(scope) {
                scope.tooltipKeys = {
                    isBlocked: scope.getTooltipForNmsTemplates(),
                    matchingRules: "modals.addVariableModal.extractOptionsFilterResults",
                    criteriaBelow: "modals.addVariableModal.criteriaBelow",
                    criteriaBelowTip: "tooltips.addVariableModal.criteriaBelowTip",
                    atLeastOneRule: "popups.alert.atLeastOneOptionToExtraction"
                } as TranslationMap;

                scope.matchingRules = {
                    criteria: scope.params.rulesMatchingMode,
                    rules: scope.params.ruleOptions,
                    useRules: scope.params.extractOptionsFilterResults
                } as MatchingRules;

                scope.$watch(
                    "matchingRules",
                    function () {
                        scope.params.rulesMatchingMode = scope.matchingRules.criteria;
                        scope.params.ruleOptions = scope.matchingRules.rules;
                        scope.params.extractOptionsFilterResults = scope.matchingRules.useRules;
                    },
                    true
                );
            }
        };
    }
]);
