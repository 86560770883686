var app = angular.module("nms.dynamicDevice");

/**
 * Decimal Restriction.
 * Validates the number of integer digits of a decimal value.
 */
app.service("DecimalIntegerRestrictionService", ["LeafService",
    function(LeafService) {
        this.RESTRICTION_NAME = "decimal-integer";

        /**
         * Returns a regex pattern to validate integer part of the input.
         *
         * @param {object} type A YANG type.
         * @return {RegExp} The regular expression to validate the integer part.
         */
        var getPattern = function(type) {
            var max = LeafService.getMaxIntegerDigits(type);

            var firstNonZeroChar = 1;

            var integersMax = "([0-9]{0," + (max - firstNonZeroChar) + "})";
            if (max === 0) {
                integersMax = "";
            }

            return new RegExp("^([-]?(0{1}|([1-9])" + integersMax + "))(\\..*)?$");
        };

        this.validate = function(modelValue, viewValue, type) {
            var value = modelValue || viewValue;

            if (!value || value === "") {
                return true;
            }

            return getPattern(type).test(value);
        };
    }
]);
