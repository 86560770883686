/**
 * Handler for errors occurred when accessing NES using $http.
 *
 * @author bruno.vollino
 */
var app = angular.module("nms.dynamicDevice");

app.service("NesHttpMessageProvider", function() {
    this.errorMessageFor = function(response) {
        switch (response.status) {
            case 404:
                return "The resource requested to service NES is unavailable. Please check if the service is running.";
            case 471:
                return "Error processing request because the first polling was not completed.";
            case 503:
                return "The service NES is unavailable. Please check the service NES.";
            case 500:
                return "An error has occurred in the service NES.";
            default:
                return "An unexpected error has occurred in a request to the service NES. Please contact support.";
        }
    };
});
