import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

/**
 * Serviço para acesso às API de segurança.
 */
@Injectable({
    providedIn: "root"
})
export class SecurityService {

    static readonly SECURITY_ROOT_PATH = "/security";
    static readonly KEEP_ALIVE_PATH = "/keep-alive";

    constructor(private http: HttpClient) { }

    public postKeepAlive(): Observable<void> {
        return this.http.post<void>(SecurityService.SECURITY_ROOT_PATH + SecurityService.KEEP_ALIVE_PATH, "");
    }
}
