/**
 * Controller para a diretiva de breadcrumb.
 */
var app = angular.module("nms.dynamicDevice");

app.controller("BreadcrumbController", ["$rootScope", "$scope", "$filter", "$timeout", "ContentManager",
    "DomainHandlerService", "jsonPathService", "SessionPreferencesService",
    function($rootScope, $scope, $filter, $timeout, ContentManager, DomainHandlerService, jsonPathService,
        sessionPreferencesService) {
        $scope.nodeHierarchy = [];
        var anchorId;

        var openAccordionIfNeeded = function(parentNode, accordionPath) {
            var accordionSettings = sessionPreferencesService.getAccordionSettings(accordionPath);
            if (!accordionSettings.openedStatus && $filter("isInformation")(parentNode)) {
                accordionSettings.openedStatus = true;
            }
            if (!accordionSettings.openedConfig && $filter("isConfiguration")(parentNode)) {
                accordionSettings.openedConfig = true;
            }
        };

        var anchoredList = function() {
            var content = angular.element("#content");
            var element = angular.element(anchorId);
            var animateTime = 500;

            if (angular.isDefined(anchorId) && angular.isDefined(element.offset())) {
                content.animate({
                    scrollTop: element.offset().top - (content.offset().top + 20)
                }, animateTime);

                anchorId = undefined;
            }
        };

        $scope.goToNodeLink = function(nodeLink) {
            var node = DomainHandlerService.getSchemaNode(nodeLink.schemaJsonPath);
            var schemaNode = $filter("getSchemaNode")(node);

            var content: any = {
                node: node,
                path: node.paths.schemaJsonPath,
                canonicalPath: $filter("getPath")(schemaNode) || "/",
                pathKeys: nodeLink.pathKeys,
                keys: schemaNode["sub-statements"].key
            };

            ContentManager.clear();
            ContentManager.setContent(content);

            if (nodeLink.isAnchoredList) {
                anchorId = "#" + nodeLink.name;
                openAccordionIfNeeded(schemaNode, schemaNode.paths.dataJsonPath);
                $timeout(anchoredList);
            }
        };

        var init = function() {
            var currentPathKeys = Object.assign({}, ContentManager.getPathKeys());
            var pathNames = _.map(currentPathKeys, function(pathKey) {
                var keys = _.omit(pathKey, "path");
                return _.values(keys).join(" ");
            });
            var path = DomainHandlerService.getPath($filter("getSchemaNode")($scope.node));


            // Nó atual
            $scope.nodeHierarchy.push({
                schemaJsonPath: $scope.node.paths.schemaJsonPath,
                name: $scope.node.name || pathNames.pop(),
                isConfiguration: $filter("hasConfigurableChildren")(path),
                isInformation: $filter("hasNonConfigurableChildren")(path)
            });

            var parentPath = $scope.node.parentPaths.schemaJsonPath;
            while (parentPath != null) {
                var parentNode = Object.assign({}, DomainHandlerService.getSchemaNode(parentPath));
                var nodeToRedirect = parentNode;
                var isAnchoredList = false;

                /**
                  * Nós do tipo List e Choice precisam ser tratados de forma diferente.
                  * -> Caso o nó seja do tipo Lista é necessario redirecionar para o nó superior que contem a lista.
                  * -> Caso o nó seja do tipo Choice o comportamento da árvore é mantido, impossibilitando o usuário de
                  * navegar diretamente para a choice.
                  */
                var parts = jsonPathService.toPathArray(parentPath);
                var isChoice = parts[parts.length - 2] === "choices" ? true : false;
                if (parts[parts.length - 2] === "lists") {
                    nodeToRedirect = DomainHandlerService.getSchemaNode(parentNode.parentPaths.schemaJsonPath);
                    isAnchoredList = true;
                }

                var nodePath = DomainHandlerService.getFirstExistingPath(nodeToRedirect);
                var nodeToRedirectPathKeys = _.filter(currentPathKeys, function(pathKeys) {
                    return _.contains(nodePath, pathKeys.path);
                });

                $scope.nodeHierarchy.push({
                    schemaJsonPath: nodeToRedirect.paths.schemaJsonPath,
                    pathKeys: nodeToRedirectPathKeys,
                    name: parentNode.name || pathNames.pop(),
                    isAnchoredList: isAnchoredList,
                    isChoice: isChoice
                });

                parentPath = parentNode.parentPaths.schemaJsonPath;
            }

            $scope.nodeHierarchy.reverse();
        };

        $scope.$watch("node", function(newValue) {
            if (newValue) {
                init();
            }
        });
    }
]);
