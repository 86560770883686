import { NmsWizardStep } from "@nms-angular-toolkit/nms-wizard";
import { JobExecutionStatus } from "@nms-ng2/app/modules/scheduler/scheduler.models";

/**
 * Representa os dados gerais do Agendamento de Backup de equipamentos.
 */
export class BackupDevicesGeneral extends NmsWizardStep {
    name: string;
    description: string;
    enabled: boolean;
    exportBackups: boolean;
    status: JobExecutionStatus
}