var app = angular.module("nms.dynamicDevice");

app.constant("Validator",
    {
        DECIMAL_FRACTION_VALIDATOR: "decimalFractionValidator",
        DECIMAL_INTEGER_VALIDATOR: "decimalIntegerValidator",
        LENGTH_VALIDATOR: "lengthValidator",
        PATTERN_VALIDATOR: "patternValidator",
        RANGE_VALIDATOR: "rangeValidator",
        MAX_ELEMENTS_VALIDATOR: "maxElementsValidator",
        MIN_ELEMENTS_VALIDATOR: "minElementsValidator",
        DUPLICATED_ELEMENT_VALIDATOR: "duplicatedElementRestriction"
    }
);
