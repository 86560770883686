"use strict";

/**
* @ngdoc directive
* @name nms.directive:reorderHandle
* @description Diretiva para lidar com reordenação de listas e tabelas.
*
*   #hideElement - Opcional - Booleano - indica se a reordenação está habilitada para um elemento.
*                                        Caso não esteja definido, o elemento sempre estará
*                                        disponível para reordenação.
*
*/
var app = angular.module("nms");
app.directive("reorderHandle", function() {
    return {
        restrict: "E",
        template: "<span class=\"glyphicon glyphicon-option-vertical drag-handle\" ng-class=\"{'notSortable':hideElement}\">"
                  + "</span>",
        scope: {
            hideElement: "=?"
        }
    };
});
