var app = angular.module("nms.dynamicDevice");

app.directive("choices",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/choice/choices.html",
            link: function(scope, element) {
                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);

app.directive("choiceBody",
    function() {
        return {
            restrict: "E",
            controller: "ChoiceBodyController",
            scope: {
                choice: "=",
                config: "=",
                isInsideListBody: "=",
                pathKeys: "=?",
                watchChanges: "=",
                form: "=",
                getPathKeys: "&",
                isTransient: "=",
                isSingleEditing: "=",
                insideModal: "="
            },
            templateUrl: "templates/features/dynamic-device/domain/yang/choice/choice-body.html",
            link: function(scope, element) {
                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);
