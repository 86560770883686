/**
 * Directive used to build the container nodes.
 *
 * @author andre.pires
 */
var app = angular.module("nms.dynamicDevice");

app.directive("containers", ["ContentManager",
    function(contentManager) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/container/containers.html"
        };
    }
]);
