import { RuleType } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { PythonExpressionService } from "../services/python-expression.service";
import {
    TemplateInterface,
    TemplateOptions,
    ExternalOptions,
    TemplateType,
    Expression,
    CommandFormat,
    TemplateConfirmationConfig
} from "./template.interface";

/**
 * Implementação de TemplateInterface para templates do tipo TR069.
 */
export class TemplateTR069Component implements TemplateInterface {

    constructor(private pythonExpressionService: PythonExpressionService) { }

    getExpressions(): Expression[] | null {
        return null;
    }

    getExpressionsAggregatorProvider() {
        return this.pythonExpressionService.loadExpressions();
    }

    /**
     * @inheritdoc
     */
    getFormatCommand(): CommandFormat | null {
        return this.pythonExpressionService.getFormatCommandPython();
    }

    /**
     * @inheritdoc
     */
    getTooltipVariable(): string {
        return this.pythonExpressionService.getVariableTooltip();
    }

    /**
     * @inheritdoc
     * Templates TR-069 não aceitam as seguintes características:
     * - equipmentModelRestrictionTypes: ["IS_DMOS", "NOT_DMOS"] @see RuleType
     * - variableTypes: ["READ_ONLY", "ACTION"]
     */
    createTemplateOptions(externalOptions: ExternalOptions): TemplateOptions {
        let equipmentModelRestrictionTypes = Object.assign({}, externalOptions.deviceModelRestrictionTypes);
        delete equipmentModelRestrictionTypes[RuleType.IS_DMOS];
        delete equipmentModelRestrictionTypes[RuleType.NOT_DMOS];

        let variableTypes = Object.assign({}, externalOptions.variableTypes);
        delete variableTypes["READ_ONLY"];
        delete variableTypes["ACTION"];

        const templateOptions: TemplateOptions = {
            equipmentModelRestrictionTypes,
            variableTypes,
            isTR069Template: true,
            matchingRulesKey: "templateForm.basic.cpe.model.restriction",
            templateLanguage: "PYTHON"
        };

        return templateOptions;
    }

    /**
     * @inheritdoc
     *
     * Templates do tipo TR-069 ainda não implementam esta feature.
     */
    getEquipmentVariables(variables: any) {
        return [];
    }

    /**
     * O comando é inicializado incluindo a função "run(cpe)" caso ela não exista.
     * Uma linha em branco é adicionada apenas quando há comandos pré-existentes sem a definição da função run.
     * Caso isRemovalEnabled seja false (ou seja a check "Comandos Remoção" esteja desmarcada) e o comando
     * inserido for igual ao da sugestão, esse comando será limpo para que a sugestão não fique aparecendo
     * quando a check estiver desmarcada assim como acontece quando a tela é aberta pela primeira vez.
     */
    getCommands(commands = "", isRemovalEnabled: boolean): string {
        let tr069Commands = commands || "";
        if (isRemovalEnabled) {
            const commandsIncludeRun = _.some(tr069Commands.split("\n"), (line) => line.startsWith("def run(cpe):"));

            if (!commandsIncludeRun) {
                if (!(_.isEmpty(tr069Commands) || _.isEmpty(tr069Commands.trim()))) {
                    tr069Commands += "\n";
                }
                tr069Commands += "def run(cpe):\n    # your code here";
            }
        } else if (commands === "def run(cpe):\n    # your code here") {
            tr069Commands = "";
        }

        return tr069Commands;
    }

    /**
     * @inheritdoc
     */
    createChangeTypeConfirmationConfig(
        loadOptionsByTemplate: (templateType: { value: TemplateType }) => void,
        getConfirmationOptionsByTemplate: (templateType: TemplateType) => {
            confirmationModalFn: () => boolean,
            postConfirmationFn?: () => void
        },
        cancelationCallbackFn: (templateType: { value: TemplateType }) => void,
    ): TemplateConfirmationConfig {
        const { confirmationModalFn, postConfirmationFn } = getConfirmationOptionsByTemplate("TR_069");
        const confirmationCallbackFn = () => {
            if (postConfirmationFn) {
                postConfirmationFn();
            }

            loadOptionsByTemplate({ value: "TR_069" });
        };

        return {
            confirmationCallbackFn,
            cancelationCallbackFn: () => cancelationCallbackFn({ value: "CLI" }),
            confirmationModalFn,
            confirmationTranslationKey: "popups.confirm.changeTemplateTypeToTr069"
        };
    }

    /**
     * @inheritdoc
     */
    getShowInlineExpressions(): boolean {
        return false;
    }

    /**
     * @inheritdoc
     */
    isTemplateOfType(templateType: TemplateType): boolean {
        return templateType === "TR_069";
    }
}