import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { DeviceBackupSettings } from "./retention-device-backup-modal.component";
import { Observable } from "rxjs";

/**
 * Serviço responsável por realizar as requisições referentes as configurações de retenção de backup de equipamento.
 */
@Injectable({
  providedIn: "root"
})
export class DeviceBackupSettingsService {
    deviceBackupSettingsUrl: string = "/dcb/settings";
    closeModalEmmiter = new EventEmitter<boolean>();

    constructor(private http: HttpClient) {}

    public getDeviceBackupRetentionSettings(): Observable<DeviceBackupSettings> {
      return this.http.get<DeviceBackupSettings>(`${this.deviceBackupSettingsUrl}/retention`);
    }

    public saveDeviceBackupRetentionSettings(deviceBackupSettings: DeviceBackupSettings): Observable<DeviceBackupSettings> {
        return this.http.put<DeviceBackupSettings>(`${this.deviceBackupSettingsUrl}/retention`, deviceBackupSettings);
    }

}
