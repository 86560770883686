/**
 * Formata o Objeto JSON para facilitar a leitura dos dados.
 *
 * Referência: https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
 *
 * @param json      O valor a ser convertido para uma string JSON.
 * @param useHtmlBr Indica se a resposta do JSON será utilizada em componentes HTML, neste caso
 *                  as quebras de linhas serão alteradas pela tag <br/>.
 * @param space     Um Number que é usado para inserir espaço em branco na saída da string JSON
 *                  para propósito de legibilidade. O Number, indica o número de caracteres espaço
 *                  para usar como espaço em branco; este número é limitado em 10 se for maior que isso.
 *                  Valores menores que 1 indicam que nenhum espaço deve ser usado.
 * @param replacer  Uma função que altera o comportamento do processo de transformação em string,
 *                  ou um array de objetos String e Number que servem como uma lista branca para selecionar
 *                  as propriedades do objeto valor a ser incluído na string JSON.
 *                  Se este valor for nulo ou não fornecido, todas as propriedades do objeto são incluídas na
 *                  string JSON resultante.
 */
const prettify = (json: Object, useHtmlBr: boolean = false, space: string | number = 4, replacer: any = undefined): string => {
    let formattedString = JSON.stringify(json, replacer, space);
    return useHtmlBr ? formattedString.replace(/ /g, "&nbsp;").replace(/\n/g, "<br/>") : formattedString;
}

/**
 * Formata uma String de um objeto JSON adicionando 4 espaços entre os níveis.
 * @param string    O valor do objeto JSON em String.
 * @param useHtmlBr Indica se a resposta do JSON será utilizada em componentes HTML, neste caso
 *                  as quebras de linhas serão alteradas pela tag <br/>. Default: false.
 * @param space     Um Número que é usado para inserir espaço em branco na saída da string JSON
 *                  para propósito de legibilidade. Default: 4.
 */
const prettifyJSONString = (string: string, useHtmlBr?: boolean, space?: number):string => {
     return string ? prettify(JSON.parse(string), useHtmlBr, space) : string;
}

/**
 * Formata um objeto JSON adicionando 4 espaços entre os níveis.
 * @param json      Objeto a ser formatado
 * @param useHtmlBr Indica se a resposta do JSON será utilizada em componentes HTML, neste caso
 *                  as quebras de linhas serão alteradas pela tag <br/>. Default: false.
 * @param space     Um Número que é usado para inserir espaço em branco na saída da string JSON
 *                  para propósito de legibilidade. Default: 4.
 */
const prettifyJSON = (json: Object, useHtmlBr?: boolean, space?: number): string => {
    return prettify(json, useHtmlBr, space);
}

export { prettifyJSON, prettifyJSONString }

