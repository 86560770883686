/**
 * Service that provide a cache containing information about yang nodes.
 *
 * Structure example:
 *
 *     "/a/b/c": {
 *         parentPath: "/a/b",
 *         yangType: "containers",
 *         isConfiguration: true,
 *         "sub-statements": {...}
 *         configurableChildren: {
 *             containers: [],
 *             lists: [],
 *             choices: [],
 *             "leaf-lists": [],
 *             leaves: []
 *         },
 *         nonConfigurableChildren: {
 *             containers: [],
 *             lists: [],
 *             choices: [],
 *             "leaf-lists": [],
 *             leaves: []
 *         },
 *         hasNonContainerChildren: true
 *     },
 *
 * @author guilherme.otta
 */
var app = angular.module("nms.dynamicDevice");

app.service("NodeInfoCache", [
    function() {
        var cachedNodes: any = {};

        this.put = function(path, value) {
            cachedNodes[path] = value;
        };

        this.get = function() {
            return cachedNodes;
        };

        this.getNode = function(path) {
            return cachedNodes[path];
        };

        this.clear = function() {
            cachedNodes = {};
        };
    }
]);
