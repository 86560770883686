var app = angular.module("nms.dynamicDevice");

app.directive("restrictionChoice", ["RestrictionService",
    function(restrictionService) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/choice/restrictions/restriction.html",
            scope: {
                form: "=",
                choice: "=",
                choiceFieldName: "@",
                caseSelectedFieldName: "@"
            }
        };
    }
]);

/**
 * Restrict the required choices.
 */
app.directive("requiredChoice",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/choice/restrictions/required.html",
            link: function(scope) {
                scope.caseSelectedError = scope.form[scope.caseSelectedFieldName].$error.required;
            }
        };
    }
);

