"use strict";

/**
* @ngdoc service
* @name nms.templates.workflow
* @description
* # workflow
* Service in the nms.templates.
*/
var app = angular.module("nms.templates");

app.factory("WorkflowService", [
    function() {
        const service: any = {};

        service.changeTab = function(nextTab, currentTab, tabs, form) {
            var indexOfCurrentTab = tabs.indexOf(currentTab);
            var indexOfTab = tabs.indexOf(nextTab);

            if (indexOfCurrentTab > indexOfTab) {
                currentTab =
                    !!currentTab.validatePreviousFormMethod && !currentTab.validatePreviousFormMethod(form)
                        ? currentTab
                        : nextTab;
            } else if (indexOfCurrentTab === indexOfTab-1 && currentTab.validateFormMethod(form)) {
                currentTab = nextTab;
            }
            return currentTab;
        };

        service.checkUncheckAll = function(selected, arrayToVerify) {
            if (selected) {
                return arrayToVerify.slice();
            } else {
                return [];
            }
        };

        service.getNamesFromIds = function(arrayIds, arrayObjects) {
            var arrayNames = [];
            arrayIds.forEach(function(id) {
                arrayObjects.forEach(function(object) {
                    if (id === object.id) {
                        arrayNames.push(object.name);
                    }
                });
            });
            return arrayNames;
        };

        service.getElementsFromIds = function(arrayIds, arrayObjects) {
            var arrayNames = [];

            arrayIds.forEach(function(id) {
                arrayObjects.forEach(function(object, index) {
                    if (id === object.id) {
                        arrayNames.push(arrayObjects[index]);
                        arrayObjects.splice(index, 1);
                    }
                });
            });

            return arrayNames;
        };

        service.getNames = function(objectArray) {
            var arrayNames = [];
            if (Array.isArray(objectArray)) {
                objectArray.forEach(function(element) {
                    arrayNames.push(element.name);
                });
                return arrayNames;
            }
            return null;
        };

        service.getIdInObjectArray = function(objectArray) {
            var arrayIds = [];
            if (Array.isArray(objectArray)) {
                objectArray.forEach(function(element) {
                    arrayIds.push(element.id);
                });
                return arrayIds;
            }
            return null;
        };

        service.getFailureDeletions = function(response) {
            var fails = [];
            response.forEach(function(responseItem) {
                if (!responseItem.success) {
                    fails.push(responseItem.id);
                }
            });

            return fails;
        };

        service.selectOrUnselect = function(array, object) {
            var index = array.indexOf(object);
            if (index === -1) {
                array.push(object);
            } else {
                array.splice(index, 1);
            }
        };

        service.cutStringAccordingColumnWidth = function(columnWidth) {
            var letterWidth = 8;
            return Math.floor(columnWidth/letterWidth);
        };

        return service;
    }
]);
