"use strict";

/**
 * @author patrick.bard
 */

var app = angular.module("AuthenticationModule");

/**
* Constante que enumera as features do sistema e suas rotas.
* Exemplo: A feature "template" possui a rota principal "/template" e as rotas da feature incluem "template/add", "template/clone"
* e "template/edit".
*
* Estrutura:
* Os atributos "featureName" e "routesFromFeature" devem ser seguidos ao criar regras de acesso as rotas do sistema em
* FeatureAccessControl.service.accessRules.
* featureName: {
*     feature: nome da feature (igual a featureName),
*     routesFromFeature: {
*         routeFromFeatureName: {
*             feature: nome da rota da feature (routeFromFeatureName)
*         }
*
*     }
* }
*
* FIXME: A constante NMS_FEATURES está sendo usada tanto para listar as features do sistema como para listar permissões.
* Isolar esses dois conceitos.
* Lista de features: templateInstance, template, audit, dynamicDeviceConfig, discoverAndAddDevices, summary, gponOnus,
*                    monitoringConfiguration, manageUsers, manageGroups, tr069Cpes e managementProtocolConfiguration.
* Lista de permissões: templateInstance, template, templateConfig, dynamicDeviceConfig, audit, discoverAndAddDevices,
*                      addRemoveDevices, gponOnus, administratorPrivs, infoConfigConfig, tr069Cpes, managementProtocolConfiguration.
*/
app.constant("NMS_FEATURES", {
    templateInstance: {
        feature: "templateInstance",
        routesFromFeature: {
            templateInstanceAdd: {
                feature: "templateInstanceAdd"
            },
            templateInstanceClone: {
                feature: "templateInstanceClone"
            },
            templateInstanceEdit: {
                feature: "templateInstanceEdit"
            }
        }
    },
    template: {
        feature: "template",
        routesFromFeature: {
            templateAdd: {
                feature: "templateAdd"
            },
            templateClone: {
                feature: "templateClone"
            },
            templateEdit: {
                feature: "templateEdit"
            }
        }
    },
    templateConfig: {
        feature: "templateConfig"
    },
    dynamicDeviceConfig: {
        feature: "dynamicDeviceConfig"
    },
    audit: {
        feature: "audit"
    },
    discoverAndAddDevices: {
        feature: "discoverAndAddDevices"
    },
    addRemoveDevices: {
        feature: "addRemoveDevices"
    },
    summary: {
        feature: "summary"
    },
    gponOnus: {
        feature: "gponOnus"
    },
    monitoringConfiguration: {
        feature: "monitoringConfiguration"
    },
    manageUsers: {
        feature: "manageUsers"
    },
    manageGroups: {
        feature: "manageGroups"
    },
    administratorPrivs: {
        feature: "administratorPrivs"
    },
    infoConfigConfig: {
        feature: "infoConfigConfig"
    },
    manageAcsCredentials: {
        feature: "manageAcsCredentials"
    },
    cwmpParameters: {
        feature: "cwmpParameters"
    },
    tr069Cpes: {
        feature: "tr069Cpes"
    },
    managementProtocolConfiguration: {
        feature: "managementProtocolConfiguration"
    },
    userPreferences: {
        feature: "userPreferences"
    },
    scheduler: {
        feature: "scheduler"
    },
    configureBackup: {
        feature: "configureBackup"
    }
});

app.service("FeatureAccessControl", ["NMS_LICENSES", "NMS_FEATURES", "NMS_STATES",
    function(NMS_LICENSES, NMS_FEATURES, NMS_STATES) {
        var service: any = {
            accessRules: {
                dynamicDeviceConfig: {
                    requiredPermission: NMS_FEATURES.dynamicDeviceConfig.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.manageDevices,
                    order: 1
                },
                templateInstance: {
                    requiredPermission: NMS_FEATURES.templateInstance.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.templateInstance.list,
                    order: 2
                },
                template: {
                    requiredPermission: NMS_FEATURES.template.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.template.list,
                    order: 3,
                    routesAccessRules: {
                        templateAdd: NMS_FEATURES.templateConfig.feature
                    }
                },
                audit: {
                    requiredPermission: NMS_FEATURES.audit.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.audit.list,
                    order: 4
                },
                discoverAndAddDevices: {
                    requiredPermission: [NMS_FEATURES.discoverAndAddDevices.feature, NMS_FEATURES.addRemoveDevices.feature],
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.deviceDiscover,
                    order: 5
                },
                summary: {
                    requiredPermission: NMS_FEATURES.dynamicDeviceConfig.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.manageDevices,
                    order: 6
                },
                gponOnus: {
                    requiredPermission: NMS_FEATURES.gponOnus.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.gponOnus,
                    order: 7
                },
                monitoringConfiguration: {
                    requiredPermission: NMS_FEATURES.administratorPrivs.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.management.monitoring,
                    order: 8
                },
                manageUsers: {
                    requiredPermission: NMS_FEATURES.administratorPrivs.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.management.users,
                    order: 9
                },
                manageGroups: {
                    requiredPermission: NMS_FEATURES.administratorPrivs.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.management.groups,
                    order: 10
                },
                manageAcsCredentials: {
                    requiredPermission: NMS_FEATURES.administratorPrivs.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.management.acsCredentials,
                    order: 11
                },
                cwmpParameters: {
                    requiredPermission: NMS_FEATURES.tr069Cpes.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.cwmpParameters,
                    order: 12
                },
                tr069Cpes: {
                    requiredPermission: NMS_FEATURES.tr069Cpes.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.cpes,
                    order: 13
                },
                managementProtocolConfiguration: {
                    requiredPermission: NMS_FEATURES.managementProtocolConfiguration.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.management.managementProtocols,
                    order: 14
                },
                userPreferences: {
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.userManagement.preferences,
                    order: 15
                },
                scheduler: {
                    requiredPermission: NMS_FEATURES.scheduler.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.scheduler.list,
                    order: 16
                },
                configureBackup: {
                    requiredPermission: NMS_FEATURES.configureBackup.feature,
                    requiredLicense: NMS_LICENSES.notRequired.name,
                    state: NMS_STATES.deviceConfigurationBackup,
                    order: 17
                }
            }
        };

        service.getAllFeatures = function() {
            return service.accessRules;
        };

        service.getFeature = function(featureName) {
            return service.accessRules[featureName];
        };

        service.getRequirementsFor = function(featureName) {
            return _.pick(service.accessRules[featureName], ["requiredPermission", "requiredLicense", "routesAccessRules"]);
        };

        service.getRequiredPermissionsFor = function(featureName) {
            return service.accessRules[featureName].requiredPermission;
        };

        service.getRequiredLicenseFor = function(featureName) {
            return service.accessRules[featureName].requiredLicense;
        };

        service.getOrderFor = function(featureName) {
            return service.accessRules[featureName].order;
        };

        return service;
    }
]);
