import { Component, Inject, OnInit } from "@angular/core";
import { SimpleModalComponent } from "ngx-simple-modal";
import { DcbCompatibleDevices, DcbRestoreRequest, RestoreOperationType } from "../device-backup.models";
import { ANGULARJS_ROOTSCOPE, ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { DeviceBackupService } from "../device-backup.service";

export interface RestoreDeviceBackupModelComponentData {
    cfgId: number;
    deviceHostname: string;
}

/**
 * Modal de restauração de backup de equipamento.
 */
@Component({
    selector: "restore-device-backup-modal",
    templateUrl: "./restore-device-backup-modal.component.html",
    styleUrls: ["./restore-device-backup-modal.component.scss"]
})
export class RestoreDeviceBackupModalComponent extends SimpleModalComponent<RestoreDeviceBackupModelComponentData, any>
    implements OnInit, RestoreDeviceBackupModelComponentData {
    cfgId: number;
    deviceHostname: string;
    restoreOperationType = RestoreOperationType;

    isLoading = false;
    restaurationMode: RestoreOperationType;
    equipments: Array<DcbCompatibleDevices> = [];
    selectedEquipment: DcbCompatibleDevices;

    constructor(private readonly deviceBackupService: DeviceBackupService,
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope,
        @Inject(ANGULARJS_TRANSLATE) private readonly $translate: any) {
        super();
        this.restaurationMode = RestoreOperationType.REPLACE;
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.loadData();
    }

    isEquipmentSelected(equipment: DcbCompatibleDevices) {
        return this.selectedEquipment && this.selectedEquipment.resourceId === equipment.resourceId;
    }

    updateSelection(item: DcbCompatibleDevices) {
        this.selectedEquipment = item;
    }

    activate() {
        if (this.restaurationMode === RestoreOperationType.MERGE) {
            const message = this.$translate.instant("backup.devices.modal.restore.merge");
            this.$rootScope.showDialog({ message, isConfirm: true }).then(() => {
                this.restoreBackup();
            });
        } else {
            this.restoreBackup();
        }

    }

    private restoreBackup() {
        const {resourceId} = this.selectedEquipment;
        const dcbRestoreRequest: DcbRestoreRequest = {
            backupId: this.cfgId,
            resourceId: resourceId,
            restoreOperationType: this.restaurationMode,
            ignoreFirmwareVersion: false
        }
        this.deviceBackupService.restoreEventEmitter.emit(dcbRestoreRequest)
        this.close();
    }

    private loadData() {
        this.deviceBackupService.getDcbCompatibleDevices(this.cfgId)
            .subscribe((response: Array<DcbCompatibleDevices>) => {
                this.equipments = response;
                this.selectedEquipment = this.equipments.find(equipment => equipment.hostname === this.deviceHostname);
                const backupEquipmentIndex = this.equipments.indexOf(this.selectedEquipment);

                if (backupEquipmentIndex === -1) {
                    this.selectedEquipment = this.equipments[0];
                } else if (backupEquipmentIndex > 0) {
                    this.changeBackupDeviceToFirstPosition(backupEquipmentIndex);
                }
                this.isLoading = false;
            }, () => this.isLoading = false);
    }

    /**
     * O equipamento do respectivo backup deve sempre ser o primeiro da lista.
     * @param backupEquipmentIndex - índice atual que o equipamento do backup se encontra.
     */
    private changeBackupDeviceToFirstPosition(backupEquipmentIndex: number) {
        this.equipments.splice(backupEquipmentIndex, 1);
        this.equipments.unshift(this.selectedEquipment);
    }

}
