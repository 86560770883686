import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Cpe, CpeRemovalResponse } from "./cpe-model";
import { CpeCount } from "./cpe-count-model";

/**
 * Classe responsável por realizar as requisições referentes aos CPEs
 */
@Injectable({
    providedIn: "root"
})
export class CpeService {

    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = "/acs-client/cpe";
    }

    public getAllCpes(): Observable<Array<Cpe>> {
        return this.http.get<Array<Cpe>>(`${this.baseUrl}/all`);
    }

    public getCpesBySerialNumbers(serialNumbers): Observable<Array<Cpe>> {
        return this.http.get<Array<Cpe>>(`${this.baseUrl}/by-serial-numbers?serialNumbers=${serialNumbers}`);
    }

    public getCpeBySerialNumber(serialNumber): Observable<Cpe> {
        return this.http.get<Cpe>(`${this.baseUrl}/by-serial-number/${serialNumber}`);
    }

    public getCpeCount(): Observable<CpeCount> {
        return this.http.get<CpeCount>(`${this.baseUrl}/count`);
    }

    public removeCpes(cpesToRemove: Array<Cpe>): Observable<any> {
        let serialNumbers: Array<string> = cpesToRemove.map(cpe => cpe.serialNumber);

        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: serialNumbers
        };

        return this.http.delete(`${this.baseUrl}/remove`, httpOptions);
    }

    public verifyCpesToRemove(serialNumberCpes: Array<Cpe>): Observable<Array<CpeRemovalResponse>> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        const serialNumbers: Array<string> = serialNumberCpes.map(cpe => cpe.serialNumber);

        return this.http.post<Array<CpeRemovalResponse>>(`${this.baseUrl}/validate-removal`, serialNumbers, httpOptions);
    }
}
