import { Injectable } from "@angular/core";
import { isValidCron } from "cron-validator";
import { CronTriggerData } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";

/**
 * Classe responsável por realizar a validação do agendamento por expressão cron
 */
@Injectable({
    providedIn: "root"
})
export class CronTriggerValidator {
    private readonly WHITESPACE: string = " ";
    private readonly TWO_OR_MORE_WHITESPACE_REGEX: RegExp = / {2,}/g;
    private readonly DAY_OF_MONTH_POSITION: number = 2;
    private readonly DAY_OF_WEEK_POSITION: number = 4;
    private readonly ASTERISK: string = "*";

    validate(cronTriggerData: CronTriggerData): void {
        if (!this.validateCronExpression(cronTriggerData.cronExpression)) {
            throw new Error("scheduler.validation.error.invalidCronExpression");
        }

        cronTriggerData.cronExpression =
            cronTriggerData.cronExpression.replace(this.TWO_OR_MORE_WHITESPACE_REGEX, this.WHITESPACE);
        const expressionSplitted = cronTriggerData.cronExpression.split(this.WHITESPACE);

        if (expressionSplitted[this.DAY_OF_MONTH_POSITION] !== this.ASTERISK
            && expressionSplitted[this.DAY_OF_WEEK_POSITION] !== this.ASTERISK) {
            throw new Error("scheduler.validation.error.invalidDayMonthAndDayWeek");
        }
    }

    validateInputCronExpression(cronExpression: string): Map<string, boolean> | null {
        return this.validateCronExpression(cronExpression) ? null : new Map().set("cronError", true);
    }

    validateCronExpression(expression: string): boolean {
        return isValidCron(expression.toString());
    }
}
