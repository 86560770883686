import { EquipmentService } from "@nms-ng2/app/modules/template/services/equipment.service";
import { TemplateInstanceCpeComponent } from "./template-instance-cpe.component";
import { TemplateInstanceUtils } from "./template-instance.utils";
import { Component, OnInit, DoCheck, Injector, Input, KeyValueDiffer, KeyValueDiffers, OnDestroy, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    ACTIONS_LIST,
    ACTIVATION_SERVICE,
    ANGULARJS_LOCATION,
    ANGULARJS_ROOTSCOPE,
    ANGULARJS_SCOPE,
    ANGULARJS_TRANSLATE,
    APPLICATION_STATUS,
    BROADCAST_EVENTS,
    CONFIG_REST_SERVICE,
    CONVERTER_SERVICE,
    CUT_STRING_FILTER,
    FILTER,
    NG_DIALOG,
    NMS_STATES,
    PRESENTATION_MODE,
    STATE,
    TEMPLATE_INSTANCE_ACCORDION_DEVICE_CHANGES_SERVICE,
    TEMPLATE_INSTANCE_SERVICE,
    TEMPLATE_INSTANCE_VALIDATOR_SERVICE,
    TEMPLATE_SERVICE,
    TEMPLATE_TYPE,
    VARIABLES,
    WORKFLOW_SERVICE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { TemplateInstanceIdentifierService } from "./template-instance-identifier.service";
import { TemplateInstanceEquipmentInterface } from "./template-instance-equipment.interface";
import { TemplateInstanceDeviceComponent } from "./template-instance-device.component";
import {
    AccordionParentData,
    EquipmentAssociation,
    EquipmentDetails,
    EquipmentIdentifier,
    TemplateIdentifier,
    TemplateInstance,
    EquipmentTemplateChangedStatus,
    Keyword,
    SimpleEquipmentAssociations,
    EquipmentSelectionType,
    EquipmentSelectionFilter,
    TemplateInstanceOperation,
    EquipmentTemplateIdentifier,
    TemplateInstanceEquipmentsCommandsRequest,
    EquipmentFilterResponse,
    EquipmentSearchFilter,
    AccordionChildrenData,
    TemplateInstanceCommandsRequest,
    ScheduledTemplateApplication,
    TemplateInstanceTabs,
    EquipmentPermissionResult
} from "./template-instance-models";
import { VariablesService } from "../services/variables.service";
import { CpeService } from "../../device/cpes/cpe.service";
import { TemplateInstanceService } from "@nms-ng1/features/template/template-application/template-instance-service";
import {
    MatchingRules,
    MatchingRulesTranslationKeys,
    RuleCriteria,
    RuleCriteriasSet,
    RuleFieldsSet,
    RuleType,
    RuleTypesSet,
    Rule
} from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { HttpParams } from "@angular/common/http";
import { TABLE_FILTER_OPERATOR_MAPPING } from "@nms-ng2/app/shared/models/operators.models";
import { NmsHttpParamEncoder } from "@nms-ng2/app/core/encoder/nms-http-param-encoder";

import { EnableSchedulerJob, JobType, SchedulerJob } from "../../scheduler/scheduler.models";
import { TriggersValidatorService } from "../../scheduler/triggers/triggers-validator.service";
import { SchedulerService } from '../../scheduler/scheduler.service';
import { TriggerType } from "../../scheduler/triggers/triggers.models";
@Component({
    selector: "template-instance",
    templateUrl: "./template-instance.component.html",
    styleUrls: ["./template-instance.component.css"],
    providers: [HttpParams]
})

/**
 * Componente que gerencia a tela de aplicação de templates.
 * Este componente contém a lógica genérica da aplicação de templates.
 *
 * Ele delega as operação específicas que diferentes tipos de templates podem ter (CLI e TR-069 por exemplo)
 * para classes que implementam a interface TemplateInstanceEquipmentInterface.
 * Ao inicializar o componente, ele verfica que tipo de aplicação é e decide qual implementação da interface utilizar.
 *
 * Este componente disponibiliza os mesmo métodos da interface apenas para torná-los disponíveis para uso no HTML.
 */
export class TemplateInstanceComponent implements OnInit, OnDestroy, DoCheck {
    private readonly currentTabDiffer: KeyValueDiffer<string, any>;
    private readonly UPDATE_BLOCK_MSG: string = "templateinstanceform.templatedevices.updateBlockedMessage";
    private readonly UPDATE_REMOVE_TEMPLATE_BLOCK_MSG: string =
        "templateinstanceform.templatedevices.updateRemoveTemplateBlockedMessage";
    activeTab?: TemplateInstanceTabs;
    disableNavigation: boolean;
    disableNavitagionMessage: {[key: string]: string};
    defaultDisableNavigationMessage: string;
    onFinishOrCancelRedirectRoute: string;
    isClone: boolean;
    isEdit: boolean;
    lastSuggestedName: any;
    lastSelectedTemplateIds: any;
    equipmentSelected: any;
    templateSelected: any;
    commands: any;
    templateInstance: TemplateInstance;
    schedulerJob: SchedulerJob;
    oldSchedulerJob: SchedulerJob;
    enableSchedulerDataTimeJob: EnableSchedulerJob;
    enableSchedulerTr069EventJob: EnableSchedulerJob;
    originalTemplateInstance: TemplateInstance;
    equipmentSelectionTypeEnum: any;
    originalTemplateAssociations: string[];
    originalEquipmentAssociations: SimpleEquipmentAssociations[];
    selectAllEquipmentAssociationsCheckbox: any;
    isNotApplying: boolean;
    filters: any;
    equipmentSelectedInLocalVars: any;
    equipmentSelectedInViewApply: any;
    oneAtATime: boolean;
    presentationModeDefault: any;
    presentationMode: any;
    equipmentSelectionTypeTranslationKeys: any;
    data: Array<AccordionParentData>;
    dataExpanded: boolean;
    equipments: any;
    type: string;
    service: any;
    templates: any; // TODO: OBJECT or ARRAY
    sortableOptions: any;
    restrictions: Array<any>;
    variableTypes: any;
    form: any;
    shouldGetVariables: any;
    selectedData: any;
    accordionData: Array<AccordionParentData>;
    removedEquipmentsByTemplates: Array<any>;
    needHelper: any;
    lastCommandsValuesByDevice: any;
    lastCommandsByDevice: any;
    variablesModel: any;
    tabs: any;
    @Input() currentTab: any;
    watchCurrentTab: any;
    accordionOwner: any;
    statusApplication: any;
    selectTemplateTooltip: any;
    resultTemplateInstanceId: any;
    indexOfDeviceToBeDeleted: any;
    areThereLocalVars: any;
    templateForm: any;
    templateInstanceForm: any;
    unregisterDevicePropertiesChangeListener: any;
    isCliTemplateApplication: boolean;

    $rootScope: any;
    $scope: any;
    $location: any;
    $filter: any;
    $state: any;
    ngDialog: any;
    $translate: any;
    TemplateInstanceService: TemplateInstanceService;
    WorkflowService: any;
    ConfigRESTService: any;
    TemplateService: any;
    TemplateInstanceValidatorService: any;
    ActivationService: any;
    cutStringFilter: any;
    ACTIONS_LIST: any;
    ConverterService: any;
    NMS_STATES: any;
    VARIABLES: any;
    PRESENTATION_MODE: any;
    APPLICATION_STATUS: any;
    VariablesService: VariablesService;
    TemplateInstanceAccordionDeviceChangesService: any;
    BROADCAST_EVENTS: any;
    route: ActivatedRoute;
    differs: KeyValueDiffers;
    templateInstanceIdentifierService: TemplateInstanceIdentifierService;
    cpeService: CpeService;
    schedulerService: SchedulerService;
    templateInstanceUtils: TemplateInstanceUtils;
    templateInstanceEquipment: TemplateInstanceEquipmentInterface;
    TEMPLATE_TYPE: any;
    equipmentService: EquipmentService;
    translationKeys: any;
    needsReloadAutoSelectionVariables: boolean;
    addEquipmentTitle: string;
    removeAccordionTitle: string;
    removeItemAccordionTitle: string;
    httpParams: any;

    rulesId: string;
    ruleCriterias: RuleCriteriasSet;
    ruleTypes: RuleTypesSet;
    availableFields: RuleFieldsSet;
    ruleTranslation: MatchingRulesTranslationKeys;
    matchingRules: MatchingRules;
    ruleTypesFilter: any;

    hasSchedulerPermission: boolean;
    hasEquipmentsWithoutPermission: boolean = false;
    showExecutionColumnsAtAccordion = false;

    triggersValidatorService: TriggersValidatorService;

    constructor(private readonly injector: Injector) {
        this.$rootScope = this.injector.get(ANGULARJS_ROOTSCOPE);
        this.$scope = this.injector.get(ANGULARJS_SCOPE);
        this.$location = this.injector.get(ANGULARJS_LOCATION);
        this.$filter = this.injector.get(FILTER);
        this.$state = this.injector.get(STATE);
        this.ngDialog = this.injector.get(NG_DIALOG);
        this.$translate = this.injector.get(ANGULARJS_TRANSLATE);
        this.TemplateInstanceService = this.injector.get(TEMPLATE_INSTANCE_SERVICE);
        this.WorkflowService = this.injector.get(WORKFLOW_SERVICE);
        this.ConfigRESTService = this.injector.get(CONFIG_REST_SERVICE);
        this.TemplateService = this.injector.get(TEMPLATE_SERVICE);
        this.TemplateInstanceValidatorService = this.injector.get(TEMPLATE_INSTANCE_VALIDATOR_SERVICE);
        this.ActivationService = this.injector.get(ACTIVATION_SERVICE);
        this.cutStringFilter = this.injector.get(CUT_STRING_FILTER);
        this.ACTIONS_LIST = this.injector.get(ACTIONS_LIST);
        this.ConverterService = this.injector.get(CONVERTER_SERVICE);
        this.NMS_STATES = this.injector.get(NMS_STATES);
        this.VARIABLES = this.injector.get(VARIABLES);
        this.PRESENTATION_MODE = this.injector.get(PRESENTATION_MODE);
        this.APPLICATION_STATUS = this.injector.get(APPLICATION_STATUS);
        this.VariablesService = this.injector.get(VariablesService);
        this.TemplateInstanceAccordionDeviceChangesService = this.injector.get(
            TEMPLATE_INSTANCE_ACCORDION_DEVICE_CHANGES_SERVICE
        );
        this.BROADCAST_EVENTS = this.injector.get(BROADCAST_EVENTS);
        this.TEMPLATE_TYPE = this.injector.get(TEMPLATE_TYPE);
        this.route = this.injector.get(ActivatedRoute);
        this.differs = this.injector.get(KeyValueDiffers);
        this.templateInstanceIdentifierService = this.injector.get(TemplateInstanceIdentifierService);
        this.cpeService = this.injector.get(CpeService);
        this.templateInstanceUtils = this.injector.get(TemplateInstanceUtils);
        this.schedulerService = this.injector.get(SchedulerService);
        this.equipmentService = this.injector.get(EquipmentService);
        this.httpParams = this.injector.get(HttpParams);
        this.triggersValidatorService = this.injector.get(TriggersValidatorService);

        this.isClone = _.isEqual(this.$state.current.name, this.NMS_STATES.templateInstance.clone);
        this.isEdit = _.isEqual(this.$state.current.name, this.NMS_STATES.templateInstance.edit);
        this.lastSuggestedName = undefined;

        this.lastSelectedTemplateIds = null;
        this.equipmentSelected = null;
        this.templateSelected = null;
        this.commands = null;
        this.selectAllEquipmentAssociationsCheckbox = { checked: false };
        this.presentationModeDefault = this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;

        const scheduledTemplateApplication: ScheduledTemplateApplication =
            this.$scope.$parent.$resolve.scheduledTemplateApplication;
        this.schedulerJob = this.schedulerService.initSchedulerJob(scheduledTemplateApplication.schedulerJob,
            JobType.TEMPLATE_APPLICATION);
        this.oldSchedulerJob = _.cloneDeep(this.schedulerJob);
        this.hasSchedulerPermission = this.$scope.$parent.$resolve.hasSchedulerConfigPermission;

        this.needsReloadAutoSelectionVariables = false;
        const initialTemplateInstance: TemplateInstance = {
            equipmentAssociations: [],
            globalVars: [],
            id: null,
            applyCommandsOnRemovedEquipments: false,
            presentationMode: this.presentationModeDefault,
            status: null,
            dirty: false,
            name: "",
            type: this.$state.params.templateType || this.TEMPLATE_TYPE.CLI.name,
            equipmentSelectionType: EquipmentSelectionType.SPECIFIC
        };
        this.equipmentSelectionTypeEnum = EquipmentSelectionType;
        this.templateInstance = scheduledTemplateApplication.templateInstance || initialTemplateInstance;
        this.isCliTemplateApplication = this.templateInstanceUtils.isCliTemplateApplication(this.templateInstance.type);
        this.originalTemplateInstance = _.cloneDeep(this.templateInstance);
        this.originalTemplateAssociations = this.originalTemplateInstance
            ? this.extractTemplateAssociationsIds(this.originalTemplateInstance)
            : [];
        this.originalEquipmentAssociations = this.originalTemplateInstance
            ? this.extractEquipmentAssociations(this.originalTemplateInstance)
            : [];

        this.isNotApplying = this.templateInstance.status !== this.APPLICATION_STATUS.APPLYING;

        this.filters = {
            orderByVariables: { column: "key", direction: false },
            orderByDevice: { column: "equipmentName", direction: false }
        };

        this.equipmentSelectedInLocalVars = null;
        this.equipmentSelectedInViewApply = null;
        this.oneAtATime = false;

        this.presentationMode = {
            value: _.get(this, "templateInstance.presentationMode", this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE)
        };

        this.data = [];
        this.equipments = {};
        this.templates = [];
        this.sortableOptions = {
            disabled: !this.isNotApplying,
            handle: ".drag-handle"
        };
        this.restrictions = [];
        this.form = null;
        this.shouldGetVariables = this.isEdit;
        this.selectedData = { parentId: null, childId: null };
        this.accordionData = [];
        this.$scope.accordionData = this.accordionData;
        this.removedEquipmentsByTemplates = [];
        this.needHelper = !this.isEdit;
        this.lastCommandsValuesByDevice = {};
        this.lastCommandsByDevice = {};
        this.variablesModel = {
            isEdit: this.isEdit,
            isClone: this.isClone,
            equipmentTemplateChangedStatus: EquipmentTemplateChangedStatus.CREATING,
            lastCommandsValuesByDevice: this.lastCommandsValuesByDevice,
            lastCommandsByDevice: this.lastCommandsByDevice,
            variablesThatNeedToBeReloaded: [],
            removedEquipments: this.removedEquipmentsByTemplates
        };

        this.templateInstanceEquipment = this.createTemplateInstanceEquipment();
        this.translationKeys = this.getSpecificTranslationKeys();

        if (this.$state.params.activeTab) {
            this.activeTab = this.$state.params.activeTab;
        }

        this.disableNavigation = this.$state.params.disableNavigation || false;
        this.disableNavitagionMessage = {
            "scheduler": this.$translate.instant("templateInstanceform.editApplication.scheduler.edition.mode.tooltip")
        };
        this.defaultDisableNavigationMessage = this.$translate.instant("templateInstanceform.editApplication.tooltip.disableNavigation");
        this.onFinishOrCancelRedirectRoute = this.$state.params.onFinishOrCancelRedirectRoute;
        this.initTabs();
        this.currentTabDiffer = this.differs.find(this.currentTab).create();
    }

    async ngOnInit() {
        this.initTemplateInstance();
        this.initMatchingRulesDirective();
        this.templateInstanceEquipment.applyEquipmentIfNecessary();
        this.needsReloadAutoSelectionVariables = this.checkIfNeedsReloadAutoSelectionVariables();
    }

    private initMatchingRulesDirective() {
        this.rulesId = "equipmentSelectionRulesId";
        this.ruleCriterias = this.$scope.$parent.$resolve.rulesMatchingModes;
        this.ruleTypes = this.$scope.$parent.$resolve.deviceModelRestrictionTypes;
        this.ruleTypesFilter = this.templateInstanceEquipment.createRuleTypesFilter(this.ruleTypes);
        this.availableFields = this.initAvailableFields();
        this.ruleTranslation = this.initRuleTranslation();
        this.matchingRules = this.initMatchingRules();
    }

    private initMatchingRules(): MatchingRules {
        const defaultEquipmentSelectionFilter = {
            rulesMatchingMode: RuleCriteria.ALL,
            equipmentRuleOption: [{ ruleType: RuleType.CONTAINS, values: [""] }]
        };

        const equipmentSelectionFilter: EquipmentSelectionFilter = this.templateInstance.equipmentSelectionFilter
            || defaultEquipmentSelectionFilter;

        const ruleOptions: Rule[] = equipmentSelectionFilter.equipmentRuleOption.map((ruleOption) => {
            return {
                ruleType: ruleOption.ruleType,
                values: ruleOption.values,
                field: ruleOption.equipmentField ? ruleOption.equipmentField.value : undefined
            } as Rule;
        });

        return {
            criteria: equipmentSelectionFilter.rulesMatchingMode,
            rules: ruleOptions
        };
    }

    private initRuleTranslation(): MatchingRulesTranslationKeys {
        return {
            isBlocked: "templateform.notEditableFieldForNmsTemplate",
            matchingRules: this.translationKeys.equipmentSelectionFilterLabel,
            criteriaBelow: "templateForm.basic.device.model.restriction.criteria.below",
            atLeastOneRule: "popups.alert.atLeastOneRestriction"
        };
    }

    private initAvailableFields(): RuleFieldsSet {
        const availableFields = {};

        Object.keys(this.$scope.$parent.$resolve.rulesAvailableFields).forEach((key, index) => {
            availableFields[key] = {
                translate: this.$scope.$parent.$resolve.rulesAvailableFields[key]
            };

            if (index === 0) {
                availableFields[key].initial = true;
            }
        });

        return availableFields;
    }

    /**
     * Quando for uma edição de aplicação de template que nunca tenha sido aplicadada (status NOT_REQUESTED),
     * e houverem variáveis com seleção automática em algum dos templates da aplicação, será necessário executar
     * o reload das variáveis caso existam templates selecionados para serem aplicados antes de salvar o mesmo.
     *
     * Esse reload deverá ser feito manualmente pelo usuário (navegando para o passo de variáveis), e não
     * diretamente através desse método.
     */
    private checkIfNeedsReloadAutoSelectionVariables = () => {
        if (this.isEdit && this.templateInstance.status === this.APPLICATION_STATUS.NOT_REQUESTED) {
            let isAutoSelection = (variable) => _.has(variable, "autoSelection") && variable.autoSelection;

            return (
                this.templateInstance.globalVars.some((variable) => isAutoSelection(variable)) ||
                _.flatten(this.templateInstance.equipmentAssociations.map((equipment) => equipment.localVars)).some((variable) =>
                    isAutoSelection(variable)
                )
            );
        }

        return false;
    };

    async fillEquipment(
        equipmentIdentifierParam: string,
        equipmentRetrieverCallback: (equipmentIdentifier: string) => void,
        getEquipmentIdentifier: (equipmentAssociation: EquipmentAssociation) => string | number
    ): Promise<void> {
        if (this.$rootScope.queryParams && this.$rootScope.queryParams.indexOf(equipmentIdentifierParam) !== -1) {
            const equipmentIdentifier = this.$rootScope.queryParams.substring(this.$rootScope.queryParams.indexOf("=") + 1);
            this.presentationMode.value = this.PRESENTATION_MODE.TEMPLATES_BY_EQUIPMENT;
            equipmentRetrieverCallback(equipmentIdentifier);
            delete this.$rootScope.queryParams;
        }

        if (this.isShowEquipmentsByTemplate()) {
            this.initDataForEquipmentByTemplatePresentationMode(getEquipmentIdentifier);
        } else {
            this.initDataForTemplateByEquipmentPresentationMode(getEquipmentIdentifier);
        }

        this.templateInstance.presentationMode = this.presentationMode.value;
        await this.VariablesService.init(this.variablesModel);
        this.VariablesService.sync(this.templateInstance, this.templates, this.variablesModel);
        await this.VariablesService.extractOriginalValuesForVariablesBeforeEdition();

        setTimeout(() => {
            this.$rootScope.$broadcast("showDetailsFromApplication");
        });
    }

    /**
     * Na criação/edição esse método é chamado para preencher o this.data com os equipamentos,
     * caso o filtro selecionado for diferente de específico, o 'children' não deverá ser preenchido
     * com os equipamentos.
     */
    private initDataForEquipmentByTemplatePresentationMode(
        getEquipmentIdentifier: (equipmentAssociation: EquipmentAssociation) => string | number
    ): void {
        this.templateInstance.equipmentAssociations.forEach((equipmentAssociation) => {
            equipmentAssociation.apply = false;
            equipmentAssociation.templateAssociations.forEach((templateAssociation) => {
                templateAssociation.apply = false;
                const equipmentIdentifier = getEquipmentIdentifier(equipmentAssociation);

                let currentTemplate = _.find(
                    this.data,
                    (data) => this.templateInstanceUtils.resolveAccordionIdentifier(data.id) === templateAssociation.templateId
                );

                if (!currentTemplate) {
                    this.templates[templateAssociation.templateId] = angular.copy(templateAssociation);
                    let equipment = angular.copy(equipmentAssociation);
                    equipment.templateAssociations = [];
                    this.equipments[equipmentIdentifier] = equipment;

                    const templateIdentifier: TemplateIdentifier = {
                        id: templateAssociation.templateId,
                        type: "TemplateIdentifier"
                    };

                    currentTemplate = {
                        id: templateIdentifier,
                        label: this.createTemplateLabel(templateAssociation.templateName),
                        description: templateAssociation.templateDescription,
                        children: this.getEquipmentChildren(equipmentAssociation),
                        expand: true
                    };
                    this.data.push(currentTemplate);
                } else {
                    let currentEquipment = _.find(currentTemplate.children, (child) => _.isEqual(child.id, equipmentIdentifier));

                    if (!currentEquipment) {
                        let equipment = angular.copy(equipmentAssociation);
                        equipment.templateAssociations = [];
                        this.equipments[equipmentIdentifier] = equipment;
                        if (this.isSpecificEquipmentSelectionType()) {
                            currentTemplate.children.push({
                                id: equipmentAssociation.equipmentIdentifier,
                                label: this.createEquipmentLabel(equipmentAssociation.equipmentDetails)
                            });
                        }
                    }
                }
            });
        });
    }

    private getEquipmentChildren(equipmentAssociation) {
        return !this.isSpecificEquipmentSelectionType()
            ? []
            : [
                  {
                      id: equipmentAssociation.equipmentIdentifier,
                      label: this.createEquipmentLabel(equipmentAssociation.equipmentDetails)
                  }
              ];
    }

    private initDataForTemplateByEquipmentPresentationMode(
        getEquipmentIdentifier: (equipmentAssociation: EquipmentAssociation) => string | number
    ): void {
        this.templateInstance.equipmentAssociations.forEach((equipmentAssociation) => {
            equipmentAssociation.apply = false;

            const equipmentIdentifier = getEquipmentIdentifier(equipmentAssociation);

            let equipment: any = {
                id: equipmentAssociation.equipmentIdentifier,
                label: this.createEquipmentLabel(equipmentAssociation.equipmentDetails),
                children: [],
                expand: true
            };

            this.equipments[equipmentIdentifier] = equipmentAssociation;

            equipmentAssociation.templateAssociations.forEach((templateAssociation) => {
                const templateIdentifier: TemplateIdentifier = {
                    id: templateAssociation.templateId,
                    type: "TemplateIdentifier"
                };
                templateAssociation.apply = false;
                equipment.children.push({
                    id: templateIdentifier,
                    label: this.createTemplateLabel(templateAssociation.templateName),
                    description: templateAssociation.templateDescription
                });
                this.templates[templateAssociation.templateId] = templateAssociation;
            });
            this.data.push(equipment);
        });
    }

    createTemplateInstanceEquipment(): TemplateInstanceEquipmentInterface {
        // TODO[US-3988]: Ao resolver o acoplamento, verificar melhor se é possível utilizar a injeção do Angular
        this.separateDistinctRoutes();

        if (this.templateInstance.type === this.TEMPLATE_TYPE.TR_069.name) {
            return new TemplateInstanceCpeComponent(this, this.injector);
        }

        return new TemplateInstanceDeviceComponent(this, this.injector);
    }

    separateDistinctRoutes() {
        if (this.$rootScope.queryParams && this.$rootScope.queryParams.indexOf("id") != -1) {
            this.templateInstance.type = this.TEMPLATE_TYPE.CLI.name;
        } else if (this.$rootScope.queryParams && this.$rootScope.queryParams.indexOf("serialNumber") != -1) {
            this.templateInstance.type = this.TEMPLATE_TYPE.TR_069.name;
        }
    }

    async ngDoCheck() {
        const changes = this.currentTabDiffer.diff(this.currentTab);

        this.watchCurrentTab = async (currentTab) => {
            this.equipmentSelected = null;
            this.filters = {
                // Reseting orderBy filter
                orderByVariables: { column: "key", direction: false },
                orderByDevice: { column: "equipmentName", direction: false }
            };
            this.templateInstance.equipmentAssociations = this.$filter("orderBy")(
                this.templateInstance.equipmentAssociations,
                this.filters.orderByDevice.column,
                this.filters.orderByDevice.direction
            );

            if (this.currentTab.id === "variables") {
                if (!this.equipmentSelected && this.templateInstance.equipmentAssociations) {
                    this.equipmentSelected = _.find(this.templateInstance.equipmentAssociations, this.areThereLocalVars);
                }
                await this.VariablesService.startAutoReloadVariables();
            } else if (this.currentTab.id === "viewApply") {
                if (this.shouldGetVariables) {
                    const varRestrictions = await this.getVarRestrictions();
                    await this.storeVariablesRestrictions(varRestrictions);
                    await this.VariablesService.createVariablesThatNeedToBeReloaded(varRestrictions);
                    this.shouldGetVariables = false;
                } else {
                    if (!this.equipmentSelectedInViewApply && this.templateInstance.equipmentAssociations) {
                        this.equipmentSelectedInViewApply = this.templateInstance.equipmentAssociations[0];
                    }
                }
            }
        };

        if (changes) {
            this.watchCurrentTab(this.currentTab);
        }
    }

    loadTooltipCheckApply(): string {
        let template;

        if (this.templateInstance.equipmentSelectionType === EquipmentSelectionType.SPECIFIC) {
            if (this.templateInstance.type === this.TEMPLATE_TYPE.CLI.name) {
                template = this.$translate.instant("templateinstanceform.templatedevices").toLocaleLowerCase();
                return this.$translate.instant("tooltips.templateinstance.viewapply.applyNow").replace("{0}", template);
            }

            template = this.$translate.instant("templateinstanceform.templatecpes");
            template = this.firstLetterToLowerCase(template);
            return this.$translate.instant("tooltips.templateinstance.viewapply.applyNow.tr069").replace("{0}", template);
        }

        template = this.$translate.instant("templateform.templates");
        template = this.firstLetterToLowerCase(template);
        return this.$translate.instant("tooltips.templateinstance.viewapply.applyNow").replace("{0}", template);
    }

    translatePopoverStepEquipment(key: string): string {
        const equipmentInnerKey = this.templateInstanceUtils.getKeyByTemplateType(this.templateInstance.type);
        const equipmentKey = `templateinstanceform.templateEquipments.${equipmentInnerKey}`;
        const equipmentType = this.$translate.instant(equipmentKey);

        return this.$translate.instant(key).replace("{0}", equipmentType);
    }

    private firstLetterToLowerCase(value: string): string {
        return value[0].toLocaleLowerCase() + value.slice(1);
    }

    initTemplateInstance() {
        this.variableTypes = this.$scope.$parent.$resolve.variableTypes;

        this.loadTootipTexts();
        this.loadTitleInAccordion();
        this.accordionOwner = {
            getAccordionData: () => this.accordionData,
            getSelectedData: () => this.selectedData,
            getTemplateInstance: () => this.templateInstance,
            getPresentationMode: () => this.presentationMode,
            processDataModel: this.processDataModel.bind(this),
            getData: () => this.data,
            isApplying: this.isApplying.bind(this),
            getVariablesThatNeedToBeReloaded: () => this.variablesModel.variablesThatNeedToBeReloaded
        };

        const statusApplicationEquipments = this.isEdit ? this.originalTemplateInstance : this.templateInstance;
        this.statusApplication = {
            getResume: () => {
                let statusResume = [];

                statusApplicationEquipments.equipmentAssociations.forEach((equipmentAssociation) => {
                    this.statusCalculation(statusResume, equipmentAssociation.templateAssociations);
                });

                return statusResume;
            },
            getEquipmentsCount: () => statusApplicationEquipments.equipmentAssociations.length,
            showStatus: () => statusApplicationEquipments.id != null
        };

        this.unselectAllApplications();
        this.applyTemplateIfNecessary();

        this.unregisterDevicePropertiesChangeListener = this.$scope.$on(
            this.BROADCAST_EVENTS.DEVICE_NAME_CHANGED,
            (event, payload) => {
                let updatedDevice = payload.device;
                this.TemplateInstanceAccordionDeviceChangesService.updateAccordionDeviceModels(
                    updatedDevice,
                    this.data,
                    this.presentationMode.value
                );
                this.TemplateInstanceAccordionDeviceChangesService.updateAccordionDeviceModels(
                    updatedDevice,
                    this.accordionData,
                    this.presentationMode.value
                );
                this.TemplateInstanceAccordionDeviceChangesService.updateTemplateInstanceDeviceModels(
                    updatedDevice,
                    this.templateInstance
                );
                this.TemplateInstanceAccordionDeviceChangesService.updateVariableModel(updatedDevice, this.variablesModel);
                this.VariablesService.sync(this.templateInstance, this.templates, this.variablesModel);

                let equipment = this.equipments[updatedDevice.id];
                if (equipment) {
                    equipment.equipmentName = updatedDevice.name;
                }
            }
        );

        if (this.templateInstance.status == this.APPLICATION_STATUS.APPLYING) {
            this.setTemplateAssociationsStatusWithApplying();
        }

        if (this.isEdit) {
            this.templateInstance.applyCommandsOnRemovedEquipments = true;
        }
    }

    initTabs(): void {
        this.tabs = [
            {
                id: "equipments",
                translateId: this.templateInstanceUtils.isCliTemplateApplication(this.templateInstance.type)
                    ? "templateinstanceform.templatedevices"
                    : "templateinstanceform.templatecpes",
                templateUrl: "app/ng2/app/modules/template/template-instance/tabs/equipments.html",
                validateFormMethod: (form, tab) => true,
                title: this.templateInstanceUtils.isCliTemplateApplication(this.templateInstance.type)
                    ? "templateinstanceform.templatedevices"
                    : "templateinstanceform.templatecpes"
            },
            {
                id: "variables",
                translateId: "templateinstanceform.variables",
                templateUrl: "app/ng2/app/modules/template/template-instance/tabs/variables.html",
                validateFormMethod: (form, tab) => true,
                title: "templateinstanceform.variables"
            },
            {
                id: "scheduler",
                translateId: "scheduler",
                templateUrl: "app/ng2/app/modules/template/template-instance/tabs/scheduler.html",
                validateFormMethod: (form, tab) => this.validateSchedulerTab(),
                validatePreviousFormMethod: (form, tab) => this.validateSchedulerTab(),
                title: "scheduler"
            },
            {
                id: "viewApply",
                translateId: "templateinstanceform.viewapply",
                templateUrl: "app/ng2/app/modules/template/template-instance/tabs/view-apply.html",
                validateFormMethod: (form, tab) => true,
                title: "templateinstanceform.viewapply"
            },
            {
                id: "general",
                translateId: "templateinstanceform.general",
                templateUrl: "app/ng2/app/modules/template/template-instance/tabs/general.html",
                validateFormMethod: (form, tab) => true,
                title: "templateinstanceform.general"
            }
        ];

        if (this.isEdit && this.activeTab) {
            this.currentTab = this.tabs.find((tab) => tab.id === this.activeTab);
        } else if (this.isEdit && this.isSpecificEquipmentSelectionType()) {
            this.currentTab = this.tabs.find((tab) => tab.id === "viewApply");
        } else {
            this.currentTab = this.tabs.find((tab) => tab.id === "equipments");
        }
    }

    private validateSchedulerTab(): boolean {
        return this.shouldValidateSchedulerJob() ? this.triggersValidatorService.validate(this.schedulerJob.triggers) : true;
    }

    private shouldValidateSchedulerJob(): boolean {
        return this.schedulerService.hasSchedulerJob(this.schedulerJob) && this.shouldValidateSchedulerJobDates()
            && !_.isEqual(this.oldSchedulerJob, this.schedulerJob);
    }

    isApplicationNameRequired(): any {
        let usedTemplates = _.flatten(_.map(this.templateInstance.equipmentAssociations, "templateAssociations"));
        return _.any(usedTemplates, "applicationNameManual");
    }

    showDetails(equipmentIdentifier, templateIds): any {
        const equipmentId = equipmentIdentifier
            ? this.templateInstanceIdentifierService.resolveIdentifier(equipmentIdentifier)
            : undefined;

        let selectedDevice = this.templateInstance.equipmentAssociations.find(
            (device: EquipmentAssociation) =>
                this.templateInstanceIdentifierService.resolveIdentifier(device.equipmentIdentifier) === equipmentId
        );

        if (selectedDevice || templateIds) {
            if (this.isTemplateInstanceVariablesValid()) {
                this.equipmentSelectedInViewApply = selectedDevice;
                this.lastSelectedTemplateIds = templateIds;

                // Timeout necessário devido a ordem de disponibilidade dos escopos durante carregamento, porém nesse ponto
                // os serviços já responderam, então não teremos problemas em relação ao tempo de resposta das API's.
                // https://stackoverflow.com/questions/15676072/angularjs-broadcast-not-working-on-first-controller-load?answertab=active#tab-top
                setTimeout(() => {
                    this.$rootScope.$broadcast("getCommands");
                }, 500);
            }
        }
    }

    isSpecificEquipmentSelectionType(): boolean {
        return this.templateInstance.equipmentSelectionType === EquipmentSelectionType.SPECIFIC;
    }

    isFilterEquipmentSelectionType(): boolean {
        return this.templateInstance.equipmentSelectionType === EquipmentSelectionType.FILTER;
    }

    isTemplateInstanceVariablesValid(): any {
        let valid = this.templateInstance.globalVars instanceof Array;

        this.templateInstance.equipmentAssociations.forEach(function (equipmentAssociation) {
            valid = valid && equipmentAssociation.localVars instanceof Array;
        });

        return valid;
    }

    private loadTootipTexts() {
        this.selectTemplateTooltip = this.$translate.instant("tooltips.templateinstanceform.equipments.selectTemplate");
    }

    unselectAllApplications(): any {
        angular.forEach(
            this.templateInstance.equipmentAssociations,
            (equipmentAssociation) => (equipmentAssociation.apply = false)
        );
    }

    isApplying(viewController?): any {
        let controller = viewController ? viewController : this;

        return controller.templateInstance.status
            ? controller.templateInstance.status === controller.APPLICATION_STATUS.APPLYING
            : null;
    }

    findElementDataByKey(data, key, value): any {
        return data && this.data.length > 0 ? _.find(data, (elem) => elem[key] === value) : undefined;
    }

    validateSchedulerAndSave(templateInstanceForm): void {
        const callback = () => {
            if (this.validateSchedulerTab()) {
                this.validateAndTryApplyEquipment(templateInstanceForm);
            }
        };
        if (this.schedulerService.hasSchedulerJob(this.schedulerJob)) {
            this.verifyEquipmentsPermissionsBeforeSchedule(callback)
        } else {
            callback.call(this);
        }
    }

    validateAndTryApply(form): any {
        this.TemplateInstanceValidatorService.validateTemplatesPermissionByTemplateInstances([this.templateInstance.id])
            .then(() => {
                this.tryApply(form);
            })
            .catch(() => {
                this.$rootScope.showDialog({
                    translateKey: "templateinstanceform.userHasNoPermission",
                    type: "warning"
                });
            });
    }

    tryApply(form) {
        if (
            this.currentTab.id === "general" &&
            !this.TemplateInstanceValidatorService.validateGeneralForm(form, this.templateInstance)
        ) {
            return;
        }

        if (!this.templateInstance.dirty) {
            if (this.TemplateInstanceValidatorService.areThereTemplatesToApply(this.templateInstance.equipmentAssociations)) {
                if (this.needsReloadAutoSelectionVariables) {
                    this.$rootScope.showDialog({
                        translateKey: "tooltips.templateinstance.viewapply.automaticselection"
                    });
                    return;
                }
                this.apply();
            } else {
                if (this.isEqualTemplateApplication()) {
                    return this.$rootScope.showDialog({
                        translateKey: "tooltips.templateinstance.viewapply.noChanges",
                        type: "alert"
                    });
                } else {
                    const applyTemplate = () => {
                        const schedulerJob = this.schedulerService.prepareToSave(this.schedulerJob);
                        const scheduledTemplateApplication = {
                            templateInstance: this.templateInstance,
                            schedulerJob
                        } as ScheduledTemplateApplication;

                        this.TemplateInstanceService.apply(scheduledTemplateApplication).then(() => {
                            this.$rootScope.toastInfo("toastr.templateInstanceSavedSuccessfully");
                            this.navigateToFinishOrCancelRoute();
                        });
                    };
                    this.showNoEquipmentToApplyConfirmDialog(applyTemplate);
                }
            }
        } else {
            this.$rootScope.showDialog({
                translateKey: "modals.search.templateInstance.templateInstanceCannotBeEdited"
            });
        }
    }

    isEqualTemplateApplication(): boolean {
        const currentEquipmentAssociations = this.extractEquipmentAssociations(this.templateInstance);
        return (
            this.isEdit &&
            this.originalTemplateInstance.equipmentSelectionType === this.templateInstance.equipmentSelectionType &&
            this.isEqualEquipmentSelectionFilter(
                this.originalTemplateInstance.equipmentSelectionFilter,
                this.templateInstance.equipmentSelectionFilter
            ) &&
            this.isEqualEquipmentAssociations(currentEquipmentAssociations, this.originalEquipmentAssociations) &&
            this.isEqualGlobalAndLocalVars() &&
            this.isEqualGeneralSettingsInTemplateApplication(this.originalTemplateInstance, this.templateInstance) &&
            _.isEqual(this.oldSchedulerJob, this.schedulerJob)
        );
    }

    /**
     * Verifica se deverá ou não ser validada as datas iniciais e finais.
     * Todos os triggers ativos e diferentes do tipo ON_DEMAND terão as datas validadas.
     */
    private shouldValidateSchedulerJobDates(): boolean {
        return this.schedulerJob.triggers.filter(({ triggerEnabled }) => triggerEnabled)
            .every(({triggerType}) =>  triggerType !== TriggerType.ON_DEMAND);
    }

    private isEqualEquipmentSelectionFilter(
        originalEquipmentSelectionFiler: EquipmentSelectionFilter,
        currentEquipmentSelectionFilter: EquipmentSelectionFilter
    ): boolean {
        return _.isEqual(originalEquipmentSelectionFiler, currentEquipmentSelectionFilter);
    }

    private isEqualEquipmentAssociations(
        currentEquipments: SimpleEquipmentAssociations[],
        originalEquipments: SimpleEquipmentAssociations[]
    ): boolean {
        return _.isEqual(currentEquipments, originalEquipments);
    }

    private isEqualGlobalAndLocalVars(): boolean {
        return (
            _.isEqual(
                this.getLocalVarValues(this.originalTemplateInstance.equipmentAssociations),
                this.getLocalVarValues(this.templateInstance.equipmentAssociations)
            ) &&
            _.isEqual(
                this.getGlobalVarValues(this.originalTemplateInstance.globalVars),
                this.getGlobalVarValues(this.templateInstance.globalVars)
            )
        );
    }

    private getGlobalVarValues(values: []): string[] {
        const globalVarValues = _.flatten(_.map(values, "values"));
        return this.ConverterService.convertArrayToString(globalVarValues);
    }

    private getLocalVarValues(values: EquipmentAssociation[]): string[] {
        const localVars = _.flatten(_.map(values, "localVars"));
        const localVarsValues = _.flatten(_.map(localVars, "values"));
        return this.ConverterService.convertArrayToString(localVarsValues);
    }

    private isEqualGeneralSettingsInTemplateApplication(
        originalTemplateInstance: TemplateInstance,
        newTemplateInstance: TemplateInstance
    ): boolean {
        const originalKeywords: Array<Keyword> = originalTemplateInstance.keywords || [];
        const newKeywords: Array<Keyword> = newTemplateInstance.keywords
            ? this.checkForkeywordsInGeneralSettings(newTemplateInstance.keywords)
            : [];

        return (
            _.isEqual(originalTemplateInstance.name, newTemplateInstance.name) &&
            _.isEqual(originalTemplateInstance.description, newTemplateInstance.description) &&
            _.isEqual(originalKeywords, newKeywords)
        );
    }

    /**
     * Ao avançar para a página 'General' o sistema faz um tratamento no array de keywords
     * e insere uma propriedade chamada 'text'.
     * Para fazer a comparação será necessário fazer a verificação dessa nova propriedade,
     * caso exista, elmininá-la e comparar somente os valores do array.
     */
    checkForkeywordsInGeneralSettings(keywordsArray: Array<Keyword>) {
        if (_.some(keywordsArray, "text")) {
            return _.map(keywordsArray, (el) => {
                return el.text;
            });
        }

        return keywordsArray;
    }

    apply(): void {
        const onClose = () => {
            this.$rootScope.$broadcast(this.ACTIONS_LIST.reload);
        };
        const schedulerJob = this.schedulerService.prepareToSave(this.schedulerJob);
        const scheduledTemplateApplication = { templateInstance: this.templateInstance, schedulerJob };
        this.TemplateInstanceService.applyAndOpenResultModal(
            scheduledTemplateApplication,
            onClose,
            this.navigateToFinishOrCancelRoute.bind(this)
        );
    }

    navigateToFinishOrCancelRoute(): void {
        this.$state.go(this.onFinishOrCancelRedirectRoute);
    }


    addTemplatesToAllEquipments(): any {
        this.needHelper = false;
        if (this.canUpdate()) {
            if (_.isEmpty(this.data)) {
                this.$rootScope.showDialog({
                    translateKey: "templateinstanceform.error.noSelectedEquipments"
                });
            } else {
                const curriedSuccessCallback = _.curryRight(this.successAddTemplate.bind(this))(true);
                this.addNewTemplate(null, curriedSuccessCallback, true);
            }
        }
    }

    showTemplateSelect(device): any {
        this.equipmentSelected = device;
    }

    createAddModalToNewTemplate(equipment, callbackFunction?, shouldAddTemplatesToAllEquipment?): any {
        let callback = callbackFunction ? callbackFunction : this.successAddTemplate.bind(this);

        // Create modal for add new template for one device
        this.ngDialog
            .openConfirm({
                template: "templates/features/template/template-instance/modals/add-template-modal.html",
                controller: "AddTemplateModalCtrl",
                className: "big-modal",
                closeByNavigation: true,
                resolve: {
                    equipment: () => {
                        let selectedEquipment: any = { templateAssociations: [] };
                        if (this.presentationMode.value === this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE) {
                            this.data.forEach((template) => {
                                const templateId = (template.id as TemplateIdentifier).id;
                                let currentTemplate = this.templates[templateId];
                                selectedEquipment.templateAssociations.push(currentTemplate);
                            });
                        } else {
                            if (shouldAddTemplatesToAllEquipment) {
                                return _.map(this.data, (equipment) => {
                                    const equipmentId = this.templateInstanceIdentifierService.resolveIdentifier(equipment.id);
                                    let currentEquipment = this.equipments[equipmentId];
                                    currentEquipment.templateAssociations = [];
                                    equipment.children.forEach((templateAssociation) => {
                                        const templateId = (templateAssociation.id as TemplateIdentifier).id;
                                        const currentTemplate = this.templates[templateId];
                                        currentEquipment.templateAssociations.push(currentTemplate);
                                    });
                                    return currentEquipment;
                                });
                            } else {
                                const equipmentId = this.templateInstanceIdentifierService.resolveIdentifier(equipment.id);
                                selectedEquipment = angular.copy(this.equipments[equipmentId]);
                                selectedEquipment.templateAssociations = [];
                                equipment.children.forEach((templateAssociation) => {
                                    const templateId = (templateAssociation.id as TemplateIdentifier).id;
                                    let currentTemplate = this.templates[templateId];
                                    selectedEquipment.templateAssociations.push(currentTemplate);
                                });
                            }
                        }

                        return [selectedEquipment];
                    },
                    templateInstanceResolvedInformation: () => {
                        let info: any = {
                            service: this.service, // TODO: CHECKOUT THIS VARIABLE
                            presentationMode: this.presentationMode,
                            shouldAddTemplatesToAllEquipment,
                            originalTemplateAssociations: this.originalEquipmentAssociations,
                            isEdit: this.isEdit,
                            selectionEquipmentFilter: this.templateInstance.equipmentSelectionType
                        };

                        return info;
                    },
                    deviceModelRestrictionTypes: () => {
                        return this.ConfigRESTService.deviceModelRestrictionTypes();
                    },
                    templateType: () => {
                        return this.templateInstance.type;
                    }
                }
            })
            .then(callback);
    }

    addNewTemplate(equipment, callbackFunction?, shouldAddTemplatesToAllEquipment?): any {
        if (this.hasConditionToDisableTemplate()) {
            this.$rootScope.showDialog({ translateKey: this.UPDATE_BLOCK_MSG });
        } else {
            if (equipment) {
                const equipmentId = this.templateInstanceIdentifierService.resolveIdentifier(equipment.id);
                this.equipmentSelected = angular.copy(this.equipments[equipmentId]);
                this.createAddModalToNewTemplate(equipment);
            } else if (angular.isDefined(shouldAddTemplatesToAllEquipment)) {
                this.createAddModalToNewTemplate(equipment, callbackFunction, shouldAddTemplatesToAllEquipment);
            } else {
                this.createAddModalToNewTemplate(equipment);
            }

            this.variablesModel.equipmentTemplateChangedStatus = EquipmentTemplateChangedStatus.NEEDS_CUSTOM_UPDATE;
        }
    }

    successAddTemplate(templates, shouldAddTemplatesToAllEquipment?): any {
        let addTemplates = (templates, equipment?) => {
            templates.forEach((template) => {
                let arrayToAdd = _.get(equipment, "children", this.data);
                let existingTemplateIds = _.map(arrayToAdd, "id.id");

                if (!existingTemplateIds.includes(template.templateId)) {
                    const templateIdentifier: TemplateIdentifier = {
                        id: template.templateId,
                        type: "TemplateIdentifier"
                    };
                    let newTemplate: any = {
                        id: templateIdentifier,
                        label: this.createTemplateLabel(template.templateName),
                        description: template.templateDescription
                    };

                    if (!equipment) {
                        newTemplate.children = [];
                        newTemplate.expand = true;
                    }

                    template.apply = false;
                    this.templates[template.templateId] = template;
                    arrayToAdd.push(newTemplate);
                }
            });

            if (equipment) {
                equipment.expand = true;
            }
        };

        if (this.presentationMode.value === this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE) {
            addTemplates(templates);
        } else {
            if (shouldAddTemplatesToAllEquipment) {
                this.data.forEach((device) => addTemplates(templates, device));
            } else {
                let currentDevice = _.find(this.data, (data) => {
                    return _.isEqual(data.id, this.equipmentSelected.equipmentIdentifier);
                });
                addTemplates(templates, currentDevice);
            }
        }
    }

    removeChild(parent: AccordionParentData, child: AccordionChildrenData): any {
        if (this.isEdit && this.isShowEquipmentsByTemplate()) {
            const message = this.$translate.instant("templateinstanceform.equipments.removedEquipments.confirm");

            this.$rootScope.showDialog({ message, isConfirm: true }).then(() => {
                this.removeEquipmentFromAllTemplates(child);
            });
        } else {
            this.removeSingleChild(parent, child);
        }
    }

    private removeSingleChild(parent, child): any {
        let selectedParent = this.findElementDataByKey(this.data, "id", parent.id);
        const index = selectedParent.children.indexOf(child);
        selectedParent.children.splice(index, 1);
        if (this.presentationMode.value === this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE) {
            this.updateRemovedEquipment([parent.id], child);
        } else {
            this.variablesModel.equipmentTemplateChangedStatus = EquipmentTemplateChangedStatus.NEEDS_CUSTOM_UPDATE;
        }
        this.clearAccordionData();
    }

    /**
     * Recebe um equipamento qualquer, remove todas as suas ocorrências nos
     * parents do data e atualiza a lista de equipamentos removidos.
     * Só deve ser chamado na visualização device by template.
     */
    private removeEquipmentFromAllTemplates(equipment: AccordionChildrenData) {
        const removedEquipmentParents: Array<TemplateIdentifier> = [];
        this.data.forEach((templateAccordion: AccordionParentData) => {
            const index = templateAccordion.children.findIndex((child: AccordionChildrenData) => {
                return _.isEqual(child.id, equipment.id);
            });
            if (index >= 0) {
                removedEquipmentParents.push(templateAccordion.id as TemplateIdentifier);
                templateAccordion.children.splice(index, 1);
            }
        });

        this.updateRemovedEquipment(removedEquipmentParents, equipment);
        this.clearAccordionData();
    }

    /**
     * Atualiza a lista de templates que tiveram equipamentos removidos.
     * @param removedEquipmentsParents lista de templates que sofreram alterações
     * @param equipment equipamentos removidos da lista de templates
     */
    private updateRemovedEquipment(removedEquipmentsParents: Array<TemplateIdentifier>, equipment: AccordionChildrenData) {
        const equipmentIdentifier = this.templateInstanceUtils.resolveAccordionIdentifier(equipment.id);
        delete this.lastCommandsValuesByDevice[equipmentIdentifier];

        if (this.variablesModel.equipmentTemplateChangedStatus === EquipmentTemplateChangedStatus.NO_CHANGE) {
            this.variablesModel.equipmentTemplateChangedStatus = EquipmentTemplateChangedStatus.ONLY_DEVICES_REMOVED;
        }

        removedEquipmentsParents.forEach((templateId: TemplateIdentifier) => {
            this.addRemovedEquipment(templateId.id, equipmentIdentifier);
        });
    }

    /**
     * Apaga os dados de selectedData nos modos de seleção de equipamentos diferentes de SPECIFIC.
     * Desta forma, ao reordenar os templates no passo 1, o passo 3 refletirá a exibição correta do template em 'View Commands'.
     */
    private clearSelectedData(): void {
        if (!this.isSpecificEquipmentSelectionType()) {
            this.selectedData.parentId = null;
            this.selectedData.childId = null;
        }
    }

    private isNotAllowedToChangeTab(targetTabId: string) {
        return targetTabId !== this.currentTab.id && this.disableNavigation;
    }

    getDisableNavigationMessageForCurrentTab() {
        return _.get(this.disableNavitagionMessage, this.currentTab.id, this.defaultDisableNavigationMessage);
    }

    changeTab(tab, form): any {
        if (this.isNotAllowedToChangeTab(tab.id)) {
            this.$rootScope.showDialog({ message: this.getDisableNavigationMessageForCurrentTab() });
            return;
        }

        if (this.currentTab.id === "equipments") {
            this.refreshAccordionEquipments();
            const oldEquipmentsAssociations: EquipmentAssociation[] = _.cloneDeep(this.templateInstance.equipmentAssociations);
            this.templateInstance.equipmentAssociations = [];

            if (this.isShowEquipmentsByTemplate()) {
                this.clearSelectedData();

                this.data.forEach((template) => {
                    const templateId = this.templateInstanceUtils.resolveAccordionIdentifier(template.id);
                    const currentTemplate = angular.copy(this.templates[templateId]);

                    template.children.forEach((device) => {
                        const deviceId = this.templateInstanceIdentifierService.resolveIdentifier(
                            device.id as EquipmentIdentifier
                        );
                        let currentEquipmentAssociation: EquipmentAssociation = _.find(
                            this.templateInstance.equipmentAssociations,
                            (tempInstanceEquipAssoc: EquipmentAssociation) =>
                                _.isEqual(tempInstanceEquipAssoc.equipmentIdentifier, device.id)
                        );

                        if (!currentEquipmentAssociation) {
                            const currentDevice = angular.copy(this.equipments[deviceId]);
                            currentDevice.templateAssociations = [];
                            currentEquipmentAssociation = currentDevice;
                            const oldEquipment = _.find(oldEquipmentsAssociations, (oldEquipment) =>
                                _.isEqual(oldEquipment.equipmentIdentifier, currentDevice.equipmentIdentifier)
                            );
                            currentDevice.localVars = oldEquipment ? oldEquipment.localVars : [];
                            this.templateInstance.equipmentAssociations.push(currentDevice);
                        }

                        const existingTemplate = this.findElementDataByKey(
                            currentEquipmentAssociation.templateAssociations,
                            "templateId",
                            currentTemplate.templateId
                        );

                        if (!existingTemplate) {
                            const oldEquipment = oldEquipmentsAssociations.find((oldEquipmentsAssociation) =>
                                _.isEqual(oldEquipmentsAssociation.equipmentIdentifier, device.id)
                            );

                            currentTemplate.status = this.APPLICATION_STATUS.NOT_REQUESTED;
                            if (oldEquipment) {
                                const oldTemplate = this.findElementDataByKey(
                                    oldEquipment.templateAssociations,
                                    "templateId",
                                    currentTemplate.templateId
                                );
                                currentTemplate.status = oldTemplate ? oldTemplate.status : this.APPLICATION_STATUS.NOT_REQUESTED;
                            }

                            currentEquipmentAssociation.templateAssociations.push(_.clone(currentTemplate));
                        }
                    });
                });
            } else {
                this.data.forEach((device) => {
                    const deviceId = this.templateInstanceIdentifierService.resolveIdentifier(device.id);
                    const currentDevice: EquipmentAssociation = angular.copy(this.equipments[deviceId]);
                    currentDevice.templateAssociations = [];

                    const oldDeviceAssociation: EquipmentAssociation = _.find(
                        oldEquipmentsAssociations,
                        (oldEquipmentsAssociation: EquipmentAssociation) =>
                            _.isEqual(oldEquipmentsAssociation.equipmentIdentifier, currentDevice.equipmentIdentifier)
                    );

                    device.children.forEach((template) => {
                        const templateId = this.templateInstanceUtils.resolveAccordionIdentifier(template.id);
                        const currentTemplate = angular.copy(this.templates[templateId]);

                        if (oldDeviceAssociation) {
                            currentDevice.localVars = oldDeviceAssociation.localVars;
                        }

                        const oldTemplate = oldDeviceAssociation
                            ? this.findElementDataByKey(
                                  oldDeviceAssociation.templateAssociations,
                                  "templateId",
                                  currentTemplate.templateId
                              )
                            : undefined;

                        currentTemplate.status = oldTemplate ? oldTemplate.status : this.APPLICATION_STATUS.NOT_REQUESTED;

                        currentDevice.templateAssociations.push(_.clone(currentTemplate));
                    });

                    this.templateInstance.equipmentAssociations.push(currentDevice);
                });
            }
            this.accordionData.splice(0, this.accordionData.length);
            this.setSelectedRows();
        }
        if (this.needToValidateDevicesTab(tab)) {
            this.addFiltersToTemplateInstance();
            const templateIds = this.TemplateInstanceService.extractTemplateIds(this.templateInstance);
            this.TemplateInstanceValidatorService.validateTemplatesPermissionByTemplates(templateIds).then(
                async () => {
                    if (this.isValidEquipmentsAndTemplatesSelection()) {
                        this.loadEquipmentAssociationsForFilters()
                            .then(async () => {
                                if (
                                    this.TemplateInstanceValidatorService.validateEquipmentAssociationsForFilters(
                                        this.templateInstance.equipmentAssociations,
                                        this.isFilterEquipmentSelectionType()
                                    )
                                ) {
                                    const varRestrictions = await this.getVarRestrictions();
                                    await this.storeVariablesRestrictions(varRestrictions);

                                    if (this.hasValidTemplateSelectionForEditing()) {
                                        this.proceedChangeTab(tab, this.form);
                                    } else {
                                        this.$rootScope.showDialog({
                                            translateKey: "templateinstanceform.templatedevices.blockAddTemplate"
                                        });
                                    }
                                }
                            })
                            .catch(() => {});
                    }
                },
                () => {
                    this.$rootScope.showDialog({ translateKey: "templateinstanceform.userHasNoPermission" });
                }
            );
        } else if (this.currentTab.id === "variables" && this.needValidateTemplateWithVariables(tab)) {
            if (this.variablesModel.validateVariables(form)) {
                this.$rootScope.$broadcast("convertTemplateInstanceVariables");
                this.validateTemplatesWithVariables(tab, form);
            }
        } else if (tab.id === "general") {
            this.processApplicationNameSuggestion();
            this.proceedChangeTab(tab, form);
        } else if (this.isEdit && tab.id === "variables" &&
            (this.currentTab.id === "viewApply" || this.currentTab.id === "scheduler")) {
            this.needsReloadAutoSelectionVariables = false;
            this.proceedChangeTab(tab, form);
            this.VariablesService.reloadVariablesIfNeeded();
        } else if (this.currentTab.id === "scheduler" && this.schedulerService.hasSchedulerJob(this.schedulerJob)
            && tab.id === "viewApply") {
                const callback = () => this.proceedChangeTab(tab, form);
                this.verifyEquipmentsPermissionsBeforeSchedule(callback)
        } else {
            this.proceedChangeTab(tab, form);
        }
    }

    isValidEquipmentsAndTemplatesSelection() {
        return (
            this.TemplateInstanceValidatorService.validateEquipmentsSelection(this.templateInstance, this.ruleTypes) &&
            this.TemplateInstanceValidatorService.validateTemplatesSelection(
                this.data, this.templateInstance, this.getTemplatesInAccordion()
            )
        );
    }

    /**
     * Remove os equipamentos que haviam sido adicionados no filtro 'Specific', caso tenha selecionado
     * 'Filter by parameters' ou 'All'
     */
    refreshAccordionEquipments() {
        if (!this.isSpecificEquipmentSelectionType() && this.isShowEquipmentsByTemplate()) {
            this.removeAllEquipmentsFromAccordion();
        }
    }

    /**
     * Verifica se a seleção de templates atual é válida em uma edição.
     * Em caso de criação, retorna sempre true.
     * Em caso de edição, retorna true se os templates não foram alterados e false caso contrário.
     */
    private hasValidTemplateSelectionForEditing(): boolean {
        return !this.isEdit || (this.isEdit && this.isEqualTemplateAssociations());
    }

    showNoEquipmentToApplyConfirmDialog(callback, tab?, form?) {
        if (this.currentTab.id === "viewApply" || this.currentTab.id === "general") {
            let key = this.templateInstanceUtils.isCliTemplateApplication(this.templateInstance.type)
                ? "templateinstanceform.viewapply.noDevicesToApply"
                : "templateinstanceform.viewapply.noCPEsToApply";

            if (this.templateInstance.applyCommandsOnRemovedEquipments === true) {
                key = "templateinstanceform.viewapply.applyCommandsOnRemovedEquipments";
            }

            const message = this.$translate.instant(key);

            this.$rootScope
                .showDialog({
                    message,
                    isConfirm: true
                })
                .then(() => callback(tab, form));
        } else {
            callback(tab, form);
        }
    }

    navigateToMatchingEquipments() {
        const openNewWindow = function (url) {
            window.open(url, "_blank");
        };

        return openNewWindow(this.templateInstanceEquipment.buildMatchingEquipmentsFilterUrl());
    }

    buildUrlParametersFromMatchingRules(): HttpParams {
        let parameters = {};

        switch (this.matchingRules.criteria) {
            case RuleCriteria.ALL:
                parameters["operatorType"] = "AND";
                break;
            case RuleCriteria.ONE_OF_THE:
                parameters["operatorType"] = "OR";
                break;
        }

        parameters = this.matchingRules.rules.reduce(function (map, rule: Rule) {
            if (rule.ruleType === RuleType.BETWEEN) {
                const gteOperator = TABLE_FILTER_OPERATOR_MAPPING.GREATER_THAN_OR_EQUAL;
                const lteOperator = TABLE_FILTER_OPERATOR_MAPPING.LESS_THAN_OR_EQUAL;
                map[rule.field] = `${gteOperator}${rule.values[0]} AND ${lteOperator}${rule.values[1]}`;
            } else if (rule.ruleType === RuleType.NOT_BETWEEN) {
                const ltOperator = TABLE_FILTER_OPERATOR_MAPPING.LESS_THAN;
                const gtOperator = TABLE_FILTER_OPERATOR_MAPPING.GREATER_THAN;
                map[rule.field] = `${ltOperator}${rule.values[0]} OR ${gtOperator}${rule.values[1]}`;
            } else if (rule.ruleType === RuleType.STARTS_WITH) {
                if (map[rule.field]) {
                    map[rule.field] +=
                    ` ${parameters["operatorType"]} ${rule.values[0]}${TABLE_FILTER_OPERATOR_MAPPING[rule.ruleType]}`;
                } else {
                    map[rule.field] = `${rule.values[0]}${TABLE_FILTER_OPERATOR_MAPPING[rule.ruleType]}`;
                }
            } else if (rule.ruleType === RuleType.IS_DMOS) {
                map["dmOs"] = true;
            } else if (rule.ruleType === RuleType.NOT_DMOS) {
                map["dmOs"] = false;
            } else if (map[rule.field]) {
                if (rule.ruleType === RuleType.ENDS_WITH) {
                    map[rule.field] +=
                    ` ${parameters["operatorType"]} ${TABLE_FILTER_OPERATOR_MAPPING[rule.ruleType]}${rule.values[0]}`;
                } else {
                    map[rule.field] += ` ${parameters["operatorType"]} ${rule.values[0]}`;
                }
            } else {
                map[rule.field] = `${TABLE_FILTER_OPERATOR_MAPPING[rule.ruleType]}${rule.values[0]}`;
            }

            return map;
        }, parameters);

        return new HttpParams({ fromObject: parameters, encoder: new NmsHttpParamEncoder() });
    }

    /**
     * Se o usuário utilizar alguma combinação dos critérios descrita abaixo, uma mensagem será exibida informando
     * que as restrições com valores com mais de um ponto entre os números não são suportadas para visualização.
     *
     * Restrições: Firmware, HW Version ou SW Version.
     * Tipo:  "É maior que", "É menor que", "É maior ou igual a" ou "É menor ou igual a".
     * Valor: For digitado um valor com mais de 1 ponto entre os números, ex: 3.0.1.
     */
    openViewEquipmentsByRules() {
        const fields = ["FIRMWARE", "HW_VERSION", "SW_VERSION"];
        const types = ["GREATER_THAN", "GREATER_THAN_OR_EQUAL", "LESS_THAN", "LESS_THAN_OR_EQUAL"];

        const hasFoundRule = this.matchingRules.rules.some(
            (rule) =>
                fields.find((field) => field === rule.field) &&
                types.find((type) => type === rule.ruleType) &&
                (rule.values[0].match(/\./g) || []).length > 1
        );

        if (hasFoundRule) {
            this.$rootScope
                .showDialog({ translateKey: "template.application.confirm.templates.restricted" })
                .closePromise.then(() => this.navigateToMatchingEquipments());
        } else {
            this.navigateToMatchingEquipments();
        }
    }

    /**
     * Devido a diferença entre os modelos utilizados no componente matching-rules e na aplicação de templates,
     * foi necessário realizar a adição das regras configuradas no componente matching-rules ao objeto templateInstance
     * ao alterar para o próximo step da aplicação de templates, alinhando as informações dos 2 modelos.
     */
    private addFiltersToTemplateInstance() {
        if (this.templateInstance.equipmentSelectionType !== EquipmentSelectionType.SPECIFIC) {
            this.templateInstance.equipmentSelectionFilter = {
                equipmentRuleOption: [],
                rulesMatchingMode: this.matchingRules.criteria
            };

            this.matchingRules.rules.forEach((rule) => {
                this.templateInstance.equipmentSelectionFilter.equipmentRuleOption.push({
                    ruleType: rule.ruleType,
                    values: rule.values,
                    equipmentField: this.templateInstanceEquipment.createEquipmentField(rule.field)
                });
            });
        }
    }

    /**
     * Na aplicação de template se houver uma clonagem e não houver nenhuma variável favorita definida,
     * o nome sugerido deverá vir com o nome do template original + sufixo_clone.
     * Caso o usuário altere o nome do template após visualizá-lo pela primeira vez na aba 'Geral' e fizer uso do back/next
     * após a configuração inicial, o nome alterado (com o novo valor) deverá permanecer. Porém caso altere esse valor na aba
     * 'Variáveis' o sistema deverá aplicar esse novo valor na aba 'Geral'. Essa opção aplica-se tanto na aplicação de um novo
     * template quanto na clonagem ou edição de um template que ja foi aplicado.
     **/
    private processApplicationNameSuggestion() {
        if (this.isEditTemplate()) {
            this.lastSuggestedName = this.getNameSuggestion();
        }

        let currentSuggestedName = this.getNameSuggestion();
        if (this.shouldSetSuggestedName(currentSuggestedName)) {
            this.lastSuggestedName = currentSuggestedName;
            this.templateInstance.name = this.lastSuggestedName;
        }
    }

    /**
     * Se for uma edição, setar em 'lastSuggestedName' o valor da variável sugerida
     * para que no método 'shouldSetSuggestedName' o sistema retorne false e mantenha o valor editado pelo usuário
     **/
    private isEditTemplate() {
        return this.isEdit && angular.isUndefined(this.lastSuggestedName);
    }

    /**
     * Deverá setar o nome do template caso: usuário edite-o com o valor desejado
     * Caso retorne na aba variáveis e altere outro valor dentre as opções sugeridas
     */
    private shouldSetSuggestedName(currentSuggestedName) {
        let hasNewSuggestedName = !_.isEmpty(currentSuggestedName);
        let suggestedNameChanged = angular.isDefined(currentSuggestedName) && this.lastSuggestedName !== currentSuggestedName;
        return hasNewSuggestedName && suggestedNameChanged;
    }

    /**
     * O nome sugerido da aplicação de template é de acordo com o valor da variável configurada na opção: "sugerir nome
     * configurado na variável X".
     * No caso em que houver mais de um template com esta configuração, deve ser considerada como
     * variável X a variável do primeiro template, de acordo com a ordenação de templates conforme seleção do usuário.
     * Quando a variável X for do tipo lista, deve ser utilizado o valor configurado no primeiro item da lista como nome
     * sugerido.
     * Quando a variável X for opcional e estiver com valor em branco, o nome da aplicação deverá vir em branco.
     **/
    private getNameSuggestion() {
        let varToSuggestName = _.chain(this.templateInstance.equipmentAssociations)
            .map("templateAssociations")
            .flatten()
            .filter("applicationNameVarSuggestion")
            .map("applicationNameVarSuggestion")
            .first()
            .value();
        if (varToSuggestName) {
            let varModel = _.find(this.variablesModel.globalVarsDetails.vars, {
                variable: { name: varToSuggestName }
            });
            if (!_.isEmpty(varModel.value)) {
                return varModel.value[0];
            }
        }

        return undefined;
    }

    /**
     * Solicita ao serviço a lista de equipamentos que satisfazem os filtros configurados pelo usuário
     * e seta as EquipmentAssociations desses equipamentos no TemplateInstance.
     */
    private async loadEquipmentAssociationsForFilters(): Promise<void> {
        if (this.templateInstance.equipmentSelectionType !== EquipmentSelectionType.SPECIFIC) {
            const equipmentSearchFilter = this.createEquipmentSearchFilter();

            return this.TemplateInstanceService.getEquipmentsByRestrictions(
                this.templateInstance.type,
                equipmentSearchFilter
            ).then((response: EquipmentFilterResponse) => {
                this.templateInstance.equipmentAssociations.splice(0, this.templateInstance.equipmentAssociations.length);
                const allEquipments = Object.values(response.equipmentDetails);

                return this.templateInstanceEquipment.validateEquipmentsForFilters(allEquipments)
                    .then((filtersResult: EquipmentPermissionResult) => {
                        this.hasEquipmentsWithoutPermission = filtersResult.hasEquipmentWithoutPermission;
                        this.setEquipmentAssociations(filtersResult.equipments, response.templatesByEquipments);
                    });
            });
        }

        return Promise.resolve();
    }

    private setEquipmentAssociations(equipments, templatesByEquipments) {
        _.forEach(equipments, (equipment, equipmentId: string): void => {
            const allowedTemplates = templatesByEquipments[equipmentId];
            const currentEquipmentAssociation = this.createEquipmentAssociation(equipment, allowedTemplates);

            this.templateInstance.equipmentAssociations.push(currentEquipmentAssociation);
            this.variablesModel.equipmentTemplateChangedStatus = EquipmentTemplateChangedStatus.NEEDS_CUSTOM_UPDATE;
        });
    }

    private createEquipmentAssociation(equipmentInfo: any, allowedTemplates: Array<string>): EquipmentAssociation {
        const equipmentIdentifier = this.templateInstanceEquipment.createEquipmentIdentifier(equipmentInfo);
        const templateAssociations = this.resolveTemplateAssociations(equipmentIdentifier, allowedTemplates);

        return {
            equipmentIdentifier,
            equipmentDetails: this.templateInstanceEquipment.createEquipmentDetails(equipmentInfo),
            templateAssociations,
            localVars: [],
            apply: true
        };
    }

    /**
     * Em uma edição os dados de templateAssociations serão extraídos utilizando o this.originalTemplateInstance, garantindo que
     * seja mantido o status anterior da aplicação.
     * Quando houver um novo equipamento adicionado pelo filtro, será buscado em this.templates ignorando o status de aplicação
     * previamente existente.
     */
    private resolveTemplateAssociations(equipmentIdentifier: EquipmentIdentifier, allowedTemplates: Array<string>) {
        const equipmentAssociation = this.originalTemplateInstance.equipmentAssociations.find(equipmentAssociation => {
            return _.isEqual(equipmentAssociation.equipmentIdentifier, equipmentIdentifier);
        });

        const filteredData = this.data
            .filter(({ id: templateIdentifier }) => allowedTemplates.includes((templateIdentifier as TemplateIdentifier).id));

        if (equipmentAssociation) {
            const templatesByTemplateId = equipmentAssociation.templateAssociations
                .reduce((accumulator, templateAssociation) => {
                    accumulator[templateAssociation.templateId] = _.clone(templateAssociation);
                    accumulator[templateAssociation.templateId].apply = false;
                    return accumulator;
                }, {});

            return filteredData
                .map(({ id: templateIdentifier }) => templatesByTemplateId[(templateIdentifier as TemplateIdentifier).id]);
        }

        return filteredData
            .map(({ id: templateIdentifier }) => {
                const { status, ...template } = _.clone(this.templates[(templateIdentifier as TemplateIdentifier).id]);
                return template;
            });
    }

    private createEquipmentSearchFilter(): EquipmentSearchFilter {
        const equipmentSelectionFilter = this.createEquipmentSelectionFilter();
        const rulesByTemplate = this.createRulesByTemplate();

        return { globalRules: [equipmentSelectionFilter], rulesByTemplate };
    }

    private createEquipmentSelectionFilter(): EquipmentSelectionFilter {
        if (this.templateInstance.equipmentSelectionType === EquipmentSelectionType.ALL) {
            return this.createAllEquipmentRules();
        }
        return this.createFilterEquipmentRules();
    }

    private createAllEquipmentRules(): EquipmentSelectionFilter {
        return {
            rulesMatchingMode: RuleCriteria.ALL,
            equipmentRuleOption: []
        };
    }

    private createFilterEquipmentRules(): EquipmentSelectionFilter {
        const equipmentRuleOption = this.matchingRules.rules.map((rule) => {
            return {
                ruleType: rule.ruleType,
                values: rule.values,
                equipmentField: this.templateInstanceEquipment.createEquipmentField(rule.field)
            };
        });

        return {
            rulesMatchingMode: this.matchingRules.criteria,
            equipmentRuleOption
        };
    }

    private createRulesByTemplate(): { [templateId: string]: EquipmentSelectionFilter } {
        const rulesByTemplate: { [templateId: string]: EquipmentSelectionFilter } = {};

        _.forEach(this.data, (dataItem: AccordionParentData) => {
            const templateId = (dataItem.id as TemplateIdentifier).id;
            const template = this.templates[templateId];

            rulesByTemplate[template.templateId] = template.equipmentRestrictionFilter;
        });

        return rulesByTemplate;
    }

    private async getVarRestrictions(): Promise<any> {
        const associationsChangeStatus = this.variablesModel.equipmentTemplateChangedStatus;
        const reloadIsNotNecessary =
            associationsChangeStatus === EquipmentTemplateChangedStatus.ONLY_DEVICES_REMOVED ||
            associationsChangeStatus === EquipmentTemplateChangedStatus.NO_CHANGE;

        if (reloadIsNotNecessary) {
            return this.variablesModel.variablesRestrictions;
        } else {
            return this.TemplateInstanceService.getVarRestrictions(this.templateInstance.equipmentAssociations, this.templates);
        }
    }

    private storeVariablesRestrictions(vars): Promise<void> {
        return new Promise(async (resolve, reject) => {
            this.variablesModel.newVariablesRestrictions = vars;

            if (this.isEdit) {
                this.VariablesService.getVariablesFromServer(this.variablesModel);
            }

            resolve();
        });
    }

    needValidateTemplateWithVariables(tab): any {
        return this.currentTab.id === "variables" && this.tabs.indexOf(this.currentTab) < this.tabs.indexOf(tab);
    }

    validateTemplatesWithVariables(tab, form): any {
        this.TemplateInstanceValidatorService.validateVariables(this.templateInstance, () => {
            this.proceedChangeTab(tab, form);
        });
    }

    needToValidateDevicesTab(tab): boolean {
        return this.currentTab.id === "equipments" && this.tabs.indexOf(this.currentTab) < this.tabs.indexOf(tab);
    }

    setSelectedRows(): any {
        let findInLocalVarsFn = (equipmentAssociation) => {
            return this.findOldDeviceSelected(equipmentAssociation, this.equipmentSelectedInLocalVars);
        };
        let findInViewApplyFn = (equipmentAssociation) => {
            return this.findOldDeviceSelected(equipmentAssociation, this.equipmentSelectedInViewApply);
        };

        let oldequipmentSelectedInLocalVars = _.find(this.templateInstance.equipmentAssociations, findInLocalVarsFn);
        let oldDeviceSelectedInViewApply = _.find(this.templateInstance.equipmentAssociations, findInViewApplyFn);

        this.equipmentSelectedInLocalVars = oldequipmentSelectedInLocalVars;
        this.equipmentSelectedInViewApply = oldDeviceSelectedInViewApply;
    }

    private findOldDeviceSelected(equipmentAssociation, equipmentSelected) {
        return (
            this.equipmentSelected &&
            equipmentAssociation.equipmentId ===
                this.templateInstanceIdentifierService.resolveIdentifier(this.equipmentSelected.equipmentIdentifier)
        );
    }

    confirmCancel(): any {
        this.$rootScope
            .showDialog({
                translateKey: "popups.confirm.cancelConfirmation",
                isConfirm: true
            })
            .then(() => this.navigateToFinishOrCancelRoute());
    }

    getTemplateNames(templates): any {
        let templateNames = [];

        this.templates.forEach((template) => templateNames.push(this.cutStringFilter(template.templateName, 30)));

        return templateNames;
    }

    private readonly buildTemplateInstanceCommandRequestForSpecific = (): TemplateInstanceEquipmentsCommandsRequest => {
        let lastSelectedTemplateIds = null;
        let equipmentTemplateIdentifiers: EquipmentTemplateIdentifier[] = [];
        let equipmentAssociation: EquipmentAssociation[] = [];

        if (!this.equipmentSelectedInViewApply) {
            equipmentAssociation = this.templateInstance.equipmentAssociations;
        } else {
            equipmentAssociation.push(this.equipmentSelectedInViewApply);
        }

        if (this.lastSelectedTemplateIds && this.lastSelectedTemplateIds.length > 0) {
            lastSelectedTemplateIds = _.map(this.lastSelectedTemplateIds, "id");
        }

        let templateIdentifiers = lastSelectedTemplateIds
            ? lastSelectedTemplateIds
            : this.TemplateInstanceService.getTemplateIds(this.equipmentSelectedInViewApply.templateAssociations);

        this.equipmentSelected = this.equipmentSelectedInViewApply;

        equipmentAssociation.forEach((equipment) => {
            templateIdentifiers.forEach((templateIdentifier) => {
                if (_.find(equipment.templateAssociations, "templateId", templateIdentifier)) {
                    equipmentTemplateIdentifiers.push({
                        equipmentIdentifier: equipment.equipmentIdentifier,
                        templateId: templateIdentifier
                    });
                }
            });
        });

        return {
            templateInstance: this.templateInstance,
            equipmentTemplateIdentifiers,
            templateInstanceOperation: this.isEdit ? TemplateInstanceOperation.EDIT : TemplateInstanceOperation.CREATE
        };
    };

    private readonly buildTemplateInstanceCommandRequestForFilters = () => {
        const { id: templateId } = this.lastSelectedTemplateIds[0];

        const previouslyApplied = this.templateInstance.equipmentAssociations
            .flatMap((equipmentAssociation) => equipmentAssociation.templateAssociations)
            .some(
                (templateAssociation) => templateAssociation.templateId === templateId && templateAssociation.previouslyApplied
            );

        return {
            templateId,
            previouslyApplied,
            newGlobalVars: this.templateInstance.globalVars,
            oldGlobalVars: this.originalTemplateInstance.globalVars,
            templateInstanceOperation: this.isEdit ? TemplateInstanceOperation.EDIT : TemplateInstanceOperation.CREATE
        };
    };

    buildTemplateInstanceCommandRequest = (): TemplateInstanceEquipmentsCommandsRequest | TemplateInstanceCommandsRequest => {
        if (this.isSpecificEquipmentSelectionType()) {
            return this.buildTemplateInstanceCommandRequestForSpecific();
        }

        return this.buildTemplateInstanceCommandRequestForFilters();
    };

    errorRetrieveData(): any {
        this.currentTab = this.tabs[1];
    }

    setTemplateAssociationsStatusWithApplying(): any {
        this.templateInstance.equipmentAssociations.forEach((equipmentAssociation) => {
            equipmentAssociation.templateAssociations.forEach((templateAssociation) => {
                if (templateAssociation.apply) {
                    templateAssociation.status = this.APPLICATION_STATUS.APPLYING;
                }
            });
        });
    }

    createEquipmentLabel({ name, model }: EquipmentDetails): any {
        const equipmentLabel = this.templateInstanceUtils.getEquipmentLabelKey(this.templateInstance.type);
        return (
            this.$translate.instant(equipmentLabel) +
            ": " +
            name +
            ", " +
            this.$translate.instant("templateinstanceform.model") +
            ": " +
            model
        );
    }

    createTemplateLabel(templateName): any {
        return this.$translate.instant("templateinstanceform.template") + ": " + templateName;
    }

    expandAll(expand): any {
        this.data.forEach((data) => (data.expand = expand));
    }

    reloadAccordionData(): void {
        let results = [];
        this.data.forEach((parent) => {
            parent.children.forEach((child) => {
                let newParent = _.find(results, (result) => {
                    const resultId = this.templateInstanceUtils.resolveAccordionIdentifier(result.id);
                    const templateId = this.templateInstanceUtils.resolveAccordionIdentifier(child.id);

                    return resultId === templateId;
                });

                if (!newParent) {
                    newParent = {
                        id: child.id,
                        label: child.label,
                        description: child.description,
                        children: [
                            {
                                id: parent.id,
                                label: parent.label
                            }
                        ],
                        expand: true
                    };
                    results.push(newParent);
                } else {
                    newParent.children.push({ id: parent.id, label: parent.label });
                }
            });
        });

        this.data.splice(0, this.data.length);
        results.forEach((data) => this.data.push(data));
    }

    changeVisualizationMode(lastDataType): any {
        if (this.selectedData && this.selectedData.childId) {
            let parentId = this.selectedData.childId;
            let childId = this.selectedData.parentId;

            this.selectedData.parentId = parentId;
            this.selectedData.childId = childId;
        } else {
            this.selectedData.parentId = null;
            this.selectedData.childId = null;
        }

        this.loadTitleInAccordion();
        this.processDataModel(lastDataType);
    }

    processDataModel(lastDataType): any {
        if (this.data.some((parentData) => parentData.children.length === 0)) {
            this.$rootScope.message = this.$filter("translate")(
                this.presentationMode.value !== this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
                    ? "templateinstanceform.templatedevices.templateWithoutDevice"
                    : "templateinstanceform.templatedevices.deviceWithoutTemplate"
            );

            this.ngDialog
                .openConfirm({
                    template: "templates/components/ui/popups/confirm.html",
                    scope: this.$rootScope
                })
                .then(
                    () => {
                        this.reloadAccordionData();
                    },
                    () => {
                        this.presentationMode.value = lastDataType;
                    }
                );
        } else {
            this.reloadAccordionData();
        }
        this.templateInstance.presentationMode = this.presentationMode.value;
    }

    addChildren(parent, addEquipmentCallback): any {
        this.needHelper = false;

        if (this.presentationMode.value === this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE) {
            const parentId = this.templateInstanceUtils.resolveAccordionIdentifier(parent.id);
            this.templateSelected = angular.copy(this.templates[parentId]);
            addEquipmentCallback();
        } else {
            this.addNewTemplate(parent);
        }
    }

    removeAccordionGroup(index): any {
        this.$rootScope.message = this.$filter("translate")(
            this.presentationMode.value !== this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
                ? "templateinstanceform.templatedevices.deviceRemove"
                : "templateinstanceform.templatedevices.templateRemove"
        );

        let data = this.data[index];

        if (data.children.length > 0) {
            this.ngDialog
                .openConfirm({
                    template: "templates/components/ui/popups/confirm.html",
                    scope: this.$rootScope
                })
                .then(() => {
                    if (this.presentationMode.value === this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE) {
                        const equipments = _.map(data.children, "id");
                        equipments.forEach((equipment) => delete this.lastCommandsValuesByDevice[equipment]);
                        this.variablesModel.equipmentTemplateChangedStatus = EquipmentTemplateChangedStatus.NEEDS_CUSTOM_UPDATE;
                    } else {
                        const equipmentId = this.templateInstanceUtils.resolveAccordionIdentifier(data.id);
                        delete this.lastCommandsValuesByDevice[equipmentId];
                        if (this.variablesModel.equipmentTemplateChangedStatus === EquipmentTemplateChangedStatus.NO_CHANGE) {
                            this.variablesModel.equipmentTemplateChangedStatus =
                                EquipmentTemplateChangedStatus.ONLY_DEVICES_REMOVED;
                        }

                        data.children
                            .map((child) => this.templateInstanceUtils.resolveAccordionIdentifier(child.id))
                            .forEach((templateId) => {
                                this.addRemovedEquipment(templateId, equipmentId);
                            });
                    }

                    this.data.splice(index, 1);
                    this.clearAccordionData();
                });
        } else {
            this.variablesModel.equipmentTemplateChangedStatus = EquipmentTemplateChangedStatus.NEEDS_CUSTOM_UPDATE;
            this.data.splice(index, 1);
            this.clearAccordionData();
        }
    }

    private addRemovedEquipment(templateId: string, equipmentId: number) {
        const template = _.find(this.removedEquipmentsByTemplates, { templateId });
        if (!template) {
            this.removedEquipmentsByTemplates.push({
                templateId,
                deviceIds: [equipmentId]
            });
        } else {
            template.deviceIds.push(equipmentId);
        }
    }

    applyTemplateIfNecessary(): any {
        if (this.$state.params.templates) {
            let templatesSelected = [];

            this.$state.params.templates.forEach((template) => {
                templatesSelected.push({
                    templateId: template.id,
                    templateName: template.name,
                    templateDescription: template.description,
                    equipmentRestrictionFilter: template.equipmentRestrictionFilter,
                    joinRangeRules: this.$filter("firmwareRangeRule")(
                        template.equipmentRestrictionFilter.equipmentRuleOption
                    ),
                    restrictTemplate: template.restrictTemplate,
                    applicationNameManual: template.applicationNameManual,
                    applicationNameVarSuggestion: template.applicationNameVarSuggestion,
                    variables: template.variables
                });
            });

            this.successAddTemplate(templatesSelected);
        }
    }

    initVariableValue(variable): any {
        if (variable.value.length === 1 && variable.value[0] === "") {
            variable.value.splice(0, 1, variable.variable.defaultValue);
        }
    }

    defineVariableType(type): any {
        return VARIABLES[type];
    }

    clearAccordionData(): any {
        this.equipmentSelectedInViewApply = null;
        this.equipmentSelectedInLocalVars = null;
        this.accordionData.splice(0, this.accordionData.length);
        this.selectedData.parentId = null;
        this.selectedData.childId = null;
    }

    /**
     * Traduz o cabeçalho de separação dos comandos de cada equipamento no modo de seleção específica, caso contrário, retorna
     * vazio, omitindo o cabeçalho.
     */
    getCommandTitle = (command) => {
        if (this.isSpecificEquipmentSelectionType()) {
            if (this.presentationMode.value === this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE) {
                return this.createEquipmentLabel(command.equipmentDetails);
            } else {
                return this.createTemplateLabel(command.templateName);
            }
        }

        return "";
    };

    async proceedChangeTab(tab, form) {
        this.currentTab = await this.WorkflowService.changeTab(tab, this.currentTab, this.tabs, form);

        // FIXME [US-3651] - Buscar alternativas para não utilizar o digest aqui.
        // Ao navegar pelo menu lateral e retornar para o passo anterior, o conteúdo da aba não atualiza sozinho,
        // é necessário 'triggar' os watchers do conteúdo (o que ainda está em AngularJs) para ele propagar o bind,
        // por exemplo, clicando em um dos inputs ou checkbox.
        // Para ir para o próximo passo, não ocorre porque o evento vem do AngularJS e já triga esses watchers (inclusive com um digest).
        // Foram feitos alguns testes utilizando ChangeDetectorRef, ApplicationRef.tick() e zone.run().
        this.$rootScope.$digest();
    }

    statusCalculation(statusResume, associations): any {
        let associationsStatus = _.map(associations, "status");
        let key = this.APPLICATION_STATUS.NOT_ORDERED;

        if (_.includes(associationsStatus, this.APPLICATION_STATUS.APPLYING)) {
            key = this.APPLICATION_STATUS.APPLYING;
        } else if (
            _.includes(associationsStatus, this.APPLICATION_STATUS.FAIL) &&
            _.includes(associationsStatus, this.APPLICATION_STATUS.SUCCESS)
        ) {
            key = this.APPLICATION_STATUS.PARTIALLY_APPLIED;
        } else if (_.includes(associationsStatus, this.APPLICATION_STATUS.FAIL)) {
            key = this.APPLICATION_STATUS.NOT_APPLIED;
        } else if (_.includes(associationsStatus, this.APPLICATION_STATUS.SUCCESS)) {
            key = this.APPLICATION_STATUS.FULLY_APPLIED;
        }

        statusResume[key] = statusResume[key] ? statusResume[key] + 1 : 1;
    }

    viewCommandsLabelKey() {
        const keys = this.getSpecificTranslationKeys();

        return keys.viewCommandsLabel;
    }

    closePopOver(): any {
        this.needHelper = false;
    }

    successAddEquipments(checkedDevices, shouldAddTemplatesToAllEquipment?) {
        this.templateInstanceEquipment.successAddEquipments(checkedDevices, shouldAddTemplatesToAllEquipment);
    }

    addEquipmentsToAllTemplates() {
        this.needHelper = false;
        if (this.canUpdate()) {
            if (_.isEmpty(this.data)) {
                this.$rootScope.showDialog({
                    translateKey: "templateinstanceform.error.noSelectedTemplates"
                });
            } else {
                const templates = this.data.map((template) => {
                    const templateId = this.templateInstanceUtils.resolveAccordionIdentifier(template.id);
                    return _.cloneDeep(this.templates[templateId]);
                });

                // TODO TK-42177 criar validação para exibir mensagem de incompatibilidade entre FWs/Models de equipamento
                // template1 firmware > 5 e template2 firmware < 2

                const curriedSuccessCallback = _.curryRight(this.successAddEquipments.bind(this))(true);
                this.addNewEquipment(curriedSuccessCallback, true);
            }
        }
    }

    addNewEquipment(callbackFunction?, shouldAddEquipmentsToAllTemplates?) {
        this.templateInstanceEquipment.addNewEquipment(callbackFunction, shouldAddEquipmentsToAllTemplates);
    }

    addEquipmentChildren(parent) {
        this.addChildren(parent, this.templateInstanceEquipment.addNewEquipment.bind(this.templateInstanceEquipment));
    }

    validateAndTryApplyEquipment(form) {
        if (this.variablesModel.variablesThatNeedToBeReloaded.length > 0) {
            this.$rootScope.showDialog({
                translateKey: "templateinstanceform.error.variablesInvalidsFinish"
            });
            return;
        }

        this.validateAndTryApply(form);
    }

    getSpecificTranslationKeys = () => {
        return this.templateInstanceEquipment.getSpecificTranslationKeys();
    };

    loadTitleInAccordion() {
        this.addEquipmentTitle = this.getTitleToAddEquipment();
        this.removeAccordionTitle = this.getTitleToRemoveAccordion();
        this.removeItemAccordionTitle = this.getTitleToRemoveItemAccordion();
    }

    canUpdate(): boolean {
        if (!this.isNotApplying) {
            this.$rootScope.showDialog({ translateKey: this.UPDATE_BLOCK_MSG });
            return false;
        }
        return true;
    }

    isShowEquipmentsByTemplate() {
        return this.presentationMode.value === this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;
    }

    isShowTemplateByEquipment() {
        return this.presentationMode.value === this.PRESENTATION_MODE.TEMPLATES_BY_EQUIPMENT;
    }

    /**
     * Em uma edição desabilita o botão de remoção do child do accordion,
     * no modo visualização template by device.
     */
    isTemplateRemovalDisabled() {
        return !this.isNotApplying || (this.isEdit && this.isShowTemplateByEquipment());
    }

    /**
     * Em uma edição desabilita o botão de remoção do parent do accordion,
     * no modo de visualização device by template.
     */
    hasConditionToDisableTemplate() {
        return this.isShowEquipmentsByTemplate() && this.isEdit;
    }

    getEquipmentSelectionTypeMessage(): string {
        const { equipmentSelectionType } = this.templateInstance;
        const translatedMessage = this.templateInstanceEquipment.getEquipmentSelectionTypeTranslationKey(equipmentSelectionType);

        if (this.isFilterEquipmentSelectionType()) {
            const { criteria, rules } = this.matchingRules;
            const criteriaText = this.$translate.instant(criteria === RuleCriteria.ALL ? "general.and" : "general.or");
            const linkingWord = this.$translate.instant("general.and");

            const rulesText = _.chain(rules)
                .map(
                    (({ field, ruleType, values }: Rule) => {
                        const { translate: translatedField } = this.availableFields[field];
                        const { translate: translatedType } = this.ruleTypes[ruleType];
                        const groupValues = values.join(` ${linkingWord} `);
                        return `${translatedField} ${translatedType.toLocaleLowerCase()} ${groupValues}`;
                    }).bind(this)
                )
                .join(` ${criteriaText} `)
                .value();
            return this.$translate.instant(translatedMessage).replace("{0}", rulesText);
        }

        return this.$translate.instant(translatedMessage);
    }

    selectionTypeChanged() {
        if (this.validateLocalVariables()) {
            this.performSelectionTypeChange();
        } else {
            this.setSpecifiFilter();
        }
    }

    /**
     * Se estiver marcado a opção 'Visualizar Templates Por Equipamento' e for trocado o tipo de seleção para uma
     * opção diferente de 'Specific', será alterado para o modo de visualização 'Equipamentos por Template'.
     * Caso existam equipamentos selecionados, uma mensagem será exibida informando que os equipamentos serão perdidos
     * e no accordion serão exibidos apenas os templates.
     */
    private performSelectionTypeChange() {
        if (this.isShowTemplateByEquipment()) {
            if (this.hasEquipments()) {
                this.$rootScope
                    .showDialog({
                        translateKey: "templateinstanceform.templatedevices.filter.clear.devices.confirm",
                        isConfirm: true
                    })
                    .then(
                        () => {
                            this.changeVisualizationModeToDefault();
                            this.removeAllEquipmentsFromAccordion();
                        },
                        () => {
                            this.templateInstance.equipmentSelectionType = this.equipmentSelectionTypeEnum.SPECIFIC;
                        }
                    );
            } else {
                this.changeVisualizationModeToDefault();
            }
        }
    }

    private changeVisualizationModeToDefault(): void {
        this.presentationMode.value = this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;
        this.changeVisualizationMode(this.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE);
    }

    /**
     * Caso o usuário adicione template com variáveis locais no Filtro 'Specific', e altere para um dos
     * demais filtros, o sistema exibirá uma mensagem que esta definida no validator-service
     * e retornará com a opção 'Specific' marcada.
     */
    private validateLocalVariables(): boolean {
        if (
            !this.TemplateInstanceValidatorService.validateTemplatesWithLocalVars(
                this.getTemplatesInAccordion(),
                this.templateInstance.type,
                "templateName"
            )
        ) {
            return false;
        }

        return true;
    }

    /**
     * Como o sistema exibe uma modal de alerta antes e somente depois ele 'seta' o radioButton do filtro 'Specific'
     * novamente, o Timeout foi necessário para efetuar essa mudança.
     */
    private setSpecifiFilter() {
        setTimeout(() => {
            this.templateInstance.equipmentSelectionType = this.equipmentSelectionTypeEnum.SPECIFIC;
        }, 10);
    }

    private getTemplatesInAccordion(): any {
        if (this.isShowEquipmentsByTemplate()) {
            return this.getTemplatesInEquipmentsByTemplates();
        }
        return this.getTemplatesInTemplatesByEquipment();
    }

    private getTemplatesInEquipmentsByTemplates(): any {
        return this.data.map((template) => {
            const templateId = this.templateInstanceUtils.resolveAccordionIdentifier(template.id);
            return _.cloneDeep(this.templates[templateId]);
        });
    }

    private getTemplatesInTemplatesByEquipment(): any {
        let templates = [];
        this.data.forEach((parent) => {
            parent.children.forEach((child) => {
                const templateFound = _.find(templates, (templateId) => {
                    const templateIdAccordion = this.templateInstanceUtils.resolveAccordionIdentifier(child.id);
                    return templateId === templateIdAccordion;
                });

                if (!templateFound) {
                    const templateId = (child.id as TemplateIdentifier).id;
                    templates.push(templateId);
                }
            });
        });
        return templates.map((templateId) => {
            return _.cloneDeep(this.templates[templateId]);
        });
    }

    private hasEquipments(): boolean {
        const equipments = _.toArray(this.equipments);
        return equipments.length > 0;
    }

    private removeAllEquipmentsFromAccordion() {
        this.data.forEach((element) => (element.children = []));
        this.equipments = {};
    }

    private getTitleToAddEquipment(): string {
        return !this.isNotApplying
            ? this.UPDATE_BLOCK_MSG
            : this.isShowEquipmentsByTemplate()
            ? "templateinstanceform.templatedevices.button.addDevice"
            : "templateinstanceform.templatedevices.button.addTemplate";
    }

    private getTitleToRemoveAccordion(): string {
        return this.hasConditionToDisableTemplate() || !this.isNotApplying
            ? this.UPDATE_REMOVE_TEMPLATE_BLOCK_MSG
            : this.isShowEquipmentsByTemplate()
            ? "templateinstanceform.templatedevices.button.removeTemplate"
            : "templateinstanceform.templatedevices.removeDevice";
    }

    /**
     * Exibe mensagem ao passar o mouse sobre os botões informando que o template não pode ser removido,
     * em uma edição de aplicação de templates no modo template by device.
     * Se for uma edição e estiver no modo device by templates, ao colocar o cursor do mouse em cima dos
     * botões será apresentado o nome do botão.
     * Se for uma criação de aplicação de templates, mostra os nomes do botões de acordo com o modo
     * de visualização.
     */
    private getTitleToRemoveItemAccordion(): string {
        return this.isTemplateRemovalDisabled()
            ? this.UPDATE_REMOVE_TEMPLATE_BLOCK_MSG
            : this.isShowEquipmentsByTemplate()
            ? "templateinstanceform.templatedevices.removeDevice"
            : "templateinstanceform.templatedevices.button.removeTemplate";
    }

    private extractTemplateAssociationsIds(templateInstance: TemplateInstance): string[] {
        return _.uniq(
            templateInstance.equipmentAssociations
                .flatMap((e) => {
                    return e.templateAssociations;
                })
                .map((t) => {
                    return t.templateId;
                })
        ).sort();
    }

    private extractEquipmentAssociations(templateInstance: TemplateInstance): SimpleEquipmentAssociations[] {
        return _.uniq(
            templateInstance.equipmentAssociations.map((equipment) => {
                const id = this.templateInstanceIdentifierService.resolveIdentifier(equipment.equipmentIdentifier);
                return {
                    id,
                    templates: equipment.templateAssociations.map((t) => {
                        return t.templateId;
                    })
                };
            })
        );
    }

    private isEqualTemplateAssociations(): boolean {
        const currentTemplateAssociations = this.extractTemplateAssociationsIds(this.templateInstance);

        return _.isEqual(this.originalTemplateAssociations, currentTemplateAssociations);
    }

    private verifyEquipmentsPermissionsBeforeSchedule(callbackFn: Function) {
        if (this.hasEquipmentsWithoutPermission) {
            this.$rootScope.showDialog({
                translateKey: "templateinstanceform.confirmToScheduleEquipmentsWithoutUserPermission",
                isConfirm: true
            }).then(() => callbackFn.call(this));
        } else {
            callbackFn.call(this)
        }
    }

    ngOnDestroy() {
        if (this.unregisterDevicePropertiesChangeListener) {
            this.unregisterDevicePropertiesChangeListener();
        }

        this.VariablesService.unsubscribeWebsocket();
    }
}
