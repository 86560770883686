import { ComponentFactoryResolver, Injectable, Type } from "@angular/core";

interface ComponentLoaderModel {
    name: string;
}
/**
* Service responsável por retornar o componente de acordo com o nome da classe.
* Em classes referentes ao angularJS como não é possível realizar a tipagem,
* esse service foi necessário para fazer essa transformação.
**/
@Injectable({
    providedIn: "root"
})
export class ComponentLoaderService {

    private readonly classNames:string = "_factories";

    constructor(public componentFactoryResolver: ComponentFactoryResolver){}

    public resolveComponent(component: any): Type<any> {
        return typeof(component) === "string" ? this.getComponentFromClassName(component) : component;
    }

    private getComponentFromClassName(className: any): Type<any> {
        const factories = Array.from(this.componentFactoryResolver[this.classNames].keys());
        return factories.find((value: ComponentLoaderModel) => value.name === className) as Type<any>;
    }
}
