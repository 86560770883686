"use strict";

/**
* @ngdoc service
* @name nms.templates.service
* @description
* # validator
* Service in the nms.templates.
*/
angular.module("nms.templates")
.factory("ValidatorService", ["$rootScope", "$translate", function($rootScope, $translate) {
    const service: any = {};

    service.isFilterValid = function(filter) {
        if (filter.pageNumber === "" || filter.pageNumber < 0 || filter.pageSize === "" || filter.pageSize < 1) {
            $rootScope.showDialog({
                translateKey: "popups.alert.pageNumberOrSizeInvalid"
            });
            return false;
        }
        return true;
    };

    service.showAlertBasicTab = function(message) {
        var params = [$translate.instant("addVariableModal.tab.title.basicConfig"), $translate.instant(message)];
        service.showAlertWithPrefix(params);
    };

    service.showAlertOnTab = function(tabKey, messageKey) {
        var params = [$translate.instant(tabKey), $translate.instant(messageKey)];
        service.showAlertWithPrefix(params);
    };

    service.showAlertWithPrefix = function(params) {
        $rootScope.showDialog({
            translateKey: "template.tabbedTitlePattern",
            params: params,
            paramsInsideMessage: true
        });
    };

    return service;
}]);
