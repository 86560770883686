import { Inject, Injectable } from "@angular/core";

import { VARIABLES, ANGULARJS_ROOTSCOPE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

export interface Action {
    name: string,
    alert: boolean
}

export interface ActionVariable {
    actionOptions: Action[],
    includeAndBlockOptionMode: any
}

export interface ActionVariableToSave {
    actions: {
        [key: string]: boolean;
    },
    includeAndBlockOptionMode: any
}

@Injectable({
    providedIn: "root"
})
export class ActionVariableValidatorService {

    private readonly maxActionNameLength = 20;
    private readonly maxActionAmount: number;

    constructor(
        @Inject(VARIABLES) private variables: any,
        @Inject(ANGULARJS_ROOTSCOPE) private rootScope: any) {

        this.maxActionAmount = this.variables.maxActionsList;
    }

    validateActionToInclude(action: Action, variable: ActionVariable): boolean {
        try {
            this.validateEmptyAction(action);
            this.validateMaxActionAmount(variable);
            this.validateRepetedAction(action, variable);
            this.validateMaxActionNameLength(action);
        } catch(error) {
            return false;
        }

        return true;
    }

    validateActionVariable(variable: ActionVariableToSave): boolean {
        try {
            this.validateIncludeAndBlockMode(variable);
            this.validateMaxActionAmountToSave(variable);
            this.validateActionListEmpty(variable);
        } catch(error) {
            return false;
        }

        return true;
    }

    private validateMaxActionNameLength({ name }: Action): void {
        if (name.length > this.maxActionNameLength) {
            this.rootScope.showDialog({
                translateKey: "addVariableModal.error.actionNameLengthExceeded"
            });

            throw new Error("Action is not configured properly.");
        }
    }

    private validateEmptyAction(action: Action): void {
        if (!action || action.name === "") {
            this.rootScope.showDialog({
                translateKey: "addVariableModal.error.fieldOptionIsEmpty"
            });

            throw new Error("Action is not configured properly.");
        }
    }

    private validateMaxActionAmount(variable: ActionVariable): void {
        if (variable.actionOptions.length >= this.maxActionAmount) {
            this.throwMaxActionAmountError();
        }
    }

    private validateRepetedAction(action: Action, variable: ActionVariable): void {
        if (variable.actionOptions.some(actionOption => actionOption.name === action.name)) {
            this.rootScope.showDialog({
                translateKey: "addVariableModal.error.repeatedAction"
            });

            throw new Error("Action is already configured.");
        }
    }

    private validateIncludeAndBlockMode(variable: ActionVariable | ActionVariableToSave): void {
        if (!_.isEqual(variable.includeAndBlockOptionMode, this.variables.includeAndBlockOptionsModes.INCLUDE)) {
            this.rootScope.showDialog({
                translateKey: "addVariableModal.error.invalidSelectedIncludingAndBlockingOptionForAction"
            });

            throw new Error("Variable must have only Include option mode.");
        }
    }

    private validateActionListEmpty(variable: ActionVariableToSave): void {
        if (!variable.actions || Object.keys(variable.actions).length === 0) {
            this.rootScope.showDialog({
                translateKey: "addVariableModal.error.actionListEmpty",
                params: [this.maxActionAmount],
                paramsInsideMessage: true
            });

            throw new Error("Action list is empty.");
        }
    }

    private validateMaxActionAmountToSave(variable: ActionVariableToSave): void {
        if (Object.keys(variable.actions).length > this.maxActionAmount) {
            this.throwMaxActionAmountError();
        }
    }

    private throwMaxActionAmountError() {
        this.rootScope.showDialog({
            translateKey: "addVariableModal.error.actionListIsFull",
            params: [this.maxActionAmount],
            paramsInsideMessage: true
        });

        throw new Error("Action list has the maximum number of items configured.");
    }

}