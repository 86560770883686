"use strict";

/**
* @ngdoc directive
* @name nms.directive:selectionCheckbox
* @description
* Diretiva para criar o componente de checkbox na primeira coluna das tabelas.
* Features:
* - Elemento para marcar/desmarcar todos os itens da tabela;
* - Representação visual de estado indeterminado (quando existem de 1 a n-1 elementos marcados na tabela).
* - Mantém elementos marcados após reload de página.
*
* Parâmetros:
* #id                   - Obrigatório   - Objeto usado para salvar os dados de usuário na sessionStorage.
* #value                - Obrigatório   - Valor selecionado na lista de objetos. Este valor é usado no componente externo
                                          check-list-model.
* #rowId                - Obrigatório   - Identificador do dado em uma linha da tabela. Usado para criar o Id do input
                                          (checkbox{{index}}) e para renderizar o componente visual.
                                        - da caixa de marcação do elemento.
* #selection            - Obrigatório   - Contém os dados dos elementos checkados, funções de marcar e desmarcar todos.
                                          Neste parametro o único elemento obrigatório no objeto é o selection.checked que contém
                                          os dados checados pelo usuário.
* #comparableFields     - Opcional      - Compara os dados checados antes de um reload na página, para que após o reload, caso o
                                          elemento exista ele deve ser checado novamente.
                                          Esta lista de elementos é usada para evitar casos em que dados dinâmicos não atrapalhem
                                          a comparação do elemento. Caso este array não seja definido, a comparação será feita
                                          levando em consideração todos os atributos.
*/
var app = angular.module("nms");
app.directive("selectionCheckbox", ["$compile", "$log", function($compile, $log) {
    return {
        restrict: "C",
        templateUrl: "templates/components/ui/selection-checkbox/selection-checkbox.html",
        controller: "SelectionCheckboxController",
        scope: {
            value: "=",
            rowId: "@",
            selection: "=",
            comparableFields: "=?"
        },
        link: function(scope, element, attrs) {
            var MANDATORY_ATTRIBUTES = ["value", "rowId", "selection"];

            /*
            * Atributos de seleção:
            *
            * #selectAllChebox        - Indica que todos os dados estão selecionados quando verdadeiro, e que todos os dados estão
            *                           desmarcados quando falso.
            * #indeterminate          - Indica se a seleção está em estado indeterminado, ou seja, se nem todos os dados estão
            *                           marcados ou desmarcados.
            * #id                     - Id da linha da tabela.
            * #ignoreHiddenElements   - Define que devem ser considerados somente os elementos visíveis na tabela durante a
            *                           seleção quando verdadeiro, e que devem ser considerados todos os elementos presentes na
            *                           tabela durante a seleção quando falso.
            */
            _.defaults(scope.selection, {
                selectAllCheckbox: false,
                indeterminate: false,
                ignoreHiddenElements: true
            });

            var mandatoryAttributesNotDeclared = _.filter(MANDATORY_ATTRIBUTES, function(key) {
                return angular.isUndefined(attrs[key]);
            });

            if (!_.isEmpty(mandatoryAttributesNotDeclared)) {
                var warningMessage
                    = "Mandatory attributes are not declared. This may cause problems with the operation of the "
                     + "selection-checkbox component.\nYou should declare the attributes: ";

                $log.warn(warningMessage + mandatoryAttributesNotDeclared.toString(), _.pick(element, "context").context);
            }
        }
    };
}]);
