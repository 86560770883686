var app = angular.module("nms.dynamicDevice");

app.service("DomainHandlerService", ["$rootScope", "NesDataCacheService", "jsonPath",
    "NodeInfoCache", "YangStatements", "YangConventions", "jsonPathService", "x2js", "DataPathService", "XPathResolverService",
    function($rootScope, NesDataCacheService, JsonPath, NodeInfoCache, YangStatements, YangConventions, jsonPathService,
            x2js, DataPathService, XPathResolverService) {
        var self = this;
        var keysIndexAndNamesPattern = /\$\{keys(\[[0-9]+\]\['.+?'\])\}/g;
        var keysIndexPattern = /\[([0-9]+)\]/g;
        var keysNamesPattern = /\[[0-9]+\]\['(.+?)'\]/g;

        var replaceAt = function replaceAt(string, index, replacement) {
            return string.substring(0, index) + replacement + string.substring(index + 1);
        };

        var convertPath = function(path) {
            var replace = path.replace(/\//g, ".");
            return replaceAt(replace, 0, "$.");
        };

        var getNodeByPath = function(path, container) {
            var convertedPath = convertPath(path);
            return JsonPath(container, convertedPath);
        };

        this.getOriginalDataNode = function(jsonPath) {
            if (jsonPath) {
                return jsonPathService({
                    json: NesDataCacheService.getOriginalData().value,
                    path: jsonPath,
                    wrap: false
                });
            }

            return null;
        };

        this.getDataNode = function(jsonPath) {
            if (jsonPath) {
                var dataNode = jsonPathService({
                    json: NesDataCacheService.getCurrentData().value,
                    path: jsonPath,
                    wrap: false
                });

                return dataNode;
            }

            return null;
        };

        this.getDataNodeForSchemaNode = function(schemaNode, pathKeys) {
            var dataNodePath = DataPathService.getNodePath(pathKeys, schemaNode);

            return self.getDataNode(dataNodePath);
        };

        this.getSchemaNode = function(jsonPath) {
            if (jsonPath) {
                return jsonPathService({
                    json: NesDataCacheService.getSchema(),
                    path: jsonPath,
                    wrap: false
                });
            }

            return null;
        };

        this.getParentSchemaPath = function(schemaNode) {
            var parentSchemaPath = _.get(schemaNode, "parentPaths.schemaJsonPath");

            if (parentSchemaPath && parentSchemaPath.match(/(lists.[^.\s]+.template)$/g)) {
                parentSchemaPath = parentSchemaPath.replace(/.template$/g, "");
            }

            return parentSchemaPath;
        };

        this.getParentSchemaNode = function(jsonPath) {
            var schemaNode = this.getSchemaNode(jsonPath);

            return (schemaNode) ? this.getSchemaNode(this.getParentSchemaPath(schemaNode)) : null;
        };

        this.getParentNodeInfoByPath = function(jsonPath) {
            return this.getNodeInfoBySchemaNode(this.getParentSchemaNode(jsonPath));
        };

        this.getParentNodeInfoBySchemaNode = function(schemaNode) {
            return (schemaNode) ? this.getNodeInfoByPath(this.getParentSchemaPath(schemaNode)) : null;
        };

        this.getNodeInfoByPath = function(jsonPath) {
            return this.getNodeInfoBySchemaNode(this.getSchemaNode(jsonPath));
        };

        this.getNodeInfoBySchemaNode = function(schemaNode) {
            return (schemaNode) ? NodeInfoCache.getNode(this.getPath(schemaNode)) : null;
        };

        this.getDataValue = function(jsonPath) {
            var node = self.getDataNode(jsonPath);

            return _.get(node, "value");
        };

        this.getNodeByPath = function(path, container, isCanonicalPath?) {
//             [CHECKME][TR-069] - Third parameter not used apperently
//             return getNodeByPath(path, container, isCanonicalPath);
            return getNodeByPath(path, container);
        };

        this.getNodeId = function(canonicalPath) {
            return canonicalPath.replace(/^.*\/([^\/]+)$/, "$1");
        };

        this.searchCanonicalPath = function(path, parent) {
            var parentPath = path;
            var node = getNodeByPath(path, parent)[0];
            while(node) {
                var canonicalPath = this.getPath(node);
                if (canonicalPath) {
                    return canonicalPath;
                }

                var paths = parentPath.split("/");
                paths.pop();
                parentPath = paths.join("/");
                node = getNodeByPath(parentPath, parent)[0];
            }

            return null;
        };

        this.listPath = function(parentPath, listId) {
            var path = correctPathIfNecessary(parentPath);
            return path + "/lists/" + listId;
        };

        this.listEntriesPath = function(listPath) {
            return listPath + "/entries/";
        };

        this.choicePath = function(parentPath, choiceId) {
            var path = correctPathIfNecessary(parentPath);
            return path + "/choices/" + choiceId;
        };

        this.casePath = function(choicePath) {
            return choicePath + "/case";
        };

        this.getBaseType = function(type) {
            if (type && type["base-type"] != null) {
                return this.getBaseType(type["base-type"]);
            }

            return type;
        };

        this.deleteDataNodeByJsonPath = function(jsonPath) {
            var data = NesDataCacheService.getCurrentData();
            var result = jsonPathService({
                json: data.value,
                path: jsonPath,
                wrap: false,
                resultType: "all"
            });
            if (result && result.value) {
                var node = result.value;

                if (Array.isArray(result.parent)) {
                    _.remove(result.parent, function(elem) {
                        return elem === node;
                    });
                } else {
                    delete result.parent[node.id];
                }
                return true;
            }
            return false;
        };

        var nodePathArrayToString = function(nodePathArray) {
            var path = "";
            for (var index = 0; index < nodePathArray.length; index++) {
                var fragment = nodePathArray[index];
                if (index > 0 && nodePathArray[index-1] === "entries") {
                    path += "[" + fragment + "]";
                } else if (index === 0) {
                    path += fragment;
                } else {
                    path += "." + fragment;
                }
            }

            return path;
        };

        /**
         * Cria um nodo filho em um nodo de dados pai, utilizado para criar os nodos intermediários de um path para possibilitar
         * a inserção de um novo nodo na árvore.
         *
         * @param {Object} rootNode o nodo raiz dos dados, localizado no JSON path '$'.
         * @param {Object} parentNode o pai do nodo a ser criado.
         * @param {string[]} childPath um array com os fragmentos do JSON path completo do nodo a ser criado.
         * @param {boolean} isIdentifiable indica se o nodo filho é identificável (container, choice, leaflist, list, leaf) ou
         *   é um wrapper (containers, lists, leaves, choices).
         * @return {Object} o nodo criado.
         */
        var createDataNode = function(rootNode, parentNode, childPath, isIdentifiable) {
            var childName = _.last(childPath);
            var childNode = jsonPathService({
                json: rootNode,
                path: nodePathArrayToString(childPath),
                wrap: false
            });

            /* Por alguma razão está fazendo wrap em uma lista quando uma lista é retornada, por exemplo,
            (ex.: $.containers.config.containers.profile.containers.gpon.lists.onu-profile.entries) está retornando uma lista
            cujo elemento 0 é a lista entries.*/
            if (angular.isArray(childNode) && childNode.length === 1 && angular.isArray(childNode[0])) {
                childNode = childNode[0];
            }

            if (!childNode) {
                if (!isIdentifiable && childName === "entries") {
                    parentNode[childName] = [];
                } else {
                    parentNode[childName] = {};
                    if (isIdentifiable) {
                        parentNode[childName].id = childName;
                    }
                }
                childNode = parentNode[childName];
            }
            return childNode;
        };

        /**
         * Para um dado JSON path e nodo a ser inserido, cria, na árvore de dados, todos os nodos intermediários entre o primeiro
         * elemento existente do path e o nodo a ser inserido.
         *
         * É chamado recursivamente para a criação dos nodos intermediários, e, no final da recursão, insere o nodeToInsert
         * na árvode de dados.
         *
         * @param {Object} rootNode o nodo raiz dos dados, localizado no JSON path '$'.
         * @param {Object} parentNode o pai do próximo nodo a ser inserido na árvore de dados.
         * @param {Object} nodeToInsert o nodo a ser inserido no final do path.
         * @param {boolean} isIdentifiable indica se o próximo nodo a ser criado é identificável
         *  (container, choice, leaflist, list, leaf) ou é um wrapper (containers, lists, leaves, choices).
         * @param {string[]} path um array com os fragmentos do JSON path completo do próximo nodo a ser criado.
         * @param {string[]} pathTail um array com os fragmentos restantes do JSON path até o ponto onde o
         *  nodeToInsert deve ser incluído.
         * @return {Object} o nodo criado.
         */
        var createDataNodeHierarchy = function(rootNode, parentNode, nodeToInsert, isIdentifiable, path, pathTail, replace) {
            var lastPathFragment = _.last(path);
            var isEntries = !isIdentifiable && lastPathFragment === "entries";
            var isEntryIndex = !isIdentifiable && path.length > 1 && path[path.length-2] === "entries";
            var isCase = !isIdentifiable && path.length > 2 && path[path.length-3] === "choices";

            if (pathTail.length > 0) {
                var childNode = createDataNode(rootNode, parentNode, path, isIdentifiable);
                var childrenAreIdentifiable = !isIdentifiable;
                if (isCase || isEntries || isEntryIndex) {
                    childrenAreIdentifiable = false;
                }
                return createDataNodeHierarchy(rootNode, childNode, nodeToInsert, childrenAreIdentifiable,
                    path.concat(_.head(pathTail)), _.tail(pathTail), replace);
            }
            if (angular.isUndefined(parentNode[lastPathFragment]) || replace) {
                parentNode[lastPathFragment] = nodeToInsert;
            }

            return nodeToInsert;
        };

        /**
          * A lib JSONPath-plus possui um problema na cache dos paths onde em alguns casos o primeiro elemento "$"
          * é removido indevidamente, e por isso não é retornado pelo método toPathArray, pra esses casos foi adicionado
          * o devido tratamento para garantir que o pathArray contenha todos os elementos presentes no nodePath.
          */
        var getPathArray = function(nodePath) {
            var pathArray = jsonPathService.toPathArray(nodePath);
            if (pathArray.length > 1 && pathArray[0] !== "$") {
                pathArray.unshift("$");
            }

            return pathArray;
        };

        this.createIdentifiableDataNode = function(rootDataNode, nodePath, nodeToInsert, replace) {
            var nodePathArray = getPathArray(nodePath);

            return createDataNodeHierarchy(rootDataNode.value, rootDataNode.value, nodeToInsert, true,
                [_.head(nodePathArray)], _.tail(nodePathArray), replace);
        };

        /**
         * Insere um nodo na árvore de dados, em um path informado. Todos os nodos do path entre a raiz da árvore
         * e o nodo a ser incluído serão criados.
         *
         * Por exemplo, dado a árvore de dados original
         *
         * originalConfiguration = {
         *     containers: { ... }
         * }
         *
         * e os seguintes parâmetros
         *
         * nodeToInsert = { id: "1/1/1" }
         * nodePath = $.lists.interfaces.entries[0]
         *
         * ao final da execução a árvore de dados será:
         *
         * originalConfiguration = {
         *     containers: { ... },
         *     lists: {
         *         interfaces: {
         *             id: "interfaces",
         *             entries: [
         *                 { id: "1/1/1" }
         *             ]
         *         }
         *     }
         * }
         *
         * IMPORTANTE: Apesar de previsto no RFC 6020, o caractere '.' no identificador de um elemento yang irá quebrar a lógica,
         * pois ele é o separador dos elementos no json path. Se estiver sendo usado, terá que ser revisto aqui e no backend.
         *
         * @param {string} nodePath o JSON path onde o nodo será inserido.
         * @param {Object} nodeToInsert o nodo de configuração a ser inserido.
         * @return {Object} o nodo criado.
         */
        this.addOrSetDataNode = function(nodePath, nodeToInsert) {
            var data = NesDataCacheService.getCurrentData();

            return self.createIdentifiableDataNode(data, nodePath, nodeToInsert, true);
        };

        var restoreNode = function(nodePath) {
            var originalDataNode = angular.copy(self.getOriginalDataNode(nodePath));

            if (angular.isDefined(originalDataNode)) {
                self.addOrSetDataNode(nodePath, originalDataNode);
            } else {
                self.deleteDataNodeByJsonPath(nodePath);
            }

            $rootScope.$broadcast("reloadContentNodeOf=" + nodePath);
        };

        this.restoreDataNode = function(paths, pathKeys) {
            var pathWithKeys = DataPathService.getNodePath(pathKeys, {paths: paths});
            restoreNode(pathWithKeys);
        };

        /**
         * Traverse each set of nodes inside a given node
         *
         * Example:
         *
         *     "aaa": {
         *         "leaves": {...},
         *         "containers": {...},
         *         "lists": {...},
         *         "choices": {...},
         *         "leaf-lists": {...},
         *         "id": "aaa",
         *     }
         *
         * On the example above, "aaa" is the given node, and "leaves", "containers", "lists", "choices" and "leaf-lists"
         * are its "nodeSet", and each one can contain multiple nodes.
         *
         *
         *  @param {object} node A node with all children categorized by yang statements
         *  @param {function} processNodeFunction Function that will be executed for each node inside each nodeSet of a given node
         */
        this.traverseNode = function(node, processNodeFunction) {
            _.each(node, function(nodeSet, yangType) {
                if (_.contains(_.map(YangStatements), yangType)) {
                    if (Object.keys(nodeSet).length) {
                        return self.traverseNodeSet(nodeSet, yangType, processNodeFunction);
                    }
                }
            });
        };

        /**
         * Traverse each node on a given set and request to go through each one
         *
         * Example:
         *
         *     "containers": {
         *         "aaa": {...},
         *         "config": {...}
         *     }
         *
         * On the example above, "containers" is the given nodeSet, so "aaa" and "config"
         * are its nodes, and each one has its nodeSets.
         *
         *  @param {object} nodeSet A set of all nodes of a @param yangType
         *  @param {string} yangType Name of the current node set type (i.e. yang statement)
         *  @param {function} processNodeFunction Function that will be executed for each node inside the given nodeSet
         */
        this.traverseNodeSet = function(nodeSet, yangType, processNodeFunction) {
            _.each(nodeSet, function(childNode) {
                processNodeFunction(childNode, yangType);

                switch (yangType) {
                    case YangStatements.CONTAINERS:
                        self.traverseNode(childNode, processNodeFunction);
                        break;
                    case YangStatements.LISTS:
                        self.traverseNode(childNode.template, processNodeFunction);
                        break;
                    case YangStatements.CHOICES:
                        _.each(childNode["cases-template"], function(caseNode) {
                            processNodeFunction(caseNode, YangStatements.CONTAINERS);
                            self.traverseNode(caseNode, processNodeFunction);
                        });
                        break;

                }
            });
        };

        this.getParentPath = function(path) {
            var isInsideRoot = new RegExp(/^(\/)[^\/]+$/);
            var replaceLastSegment = /^(.*)\/[^\/]+$/;

            return (isInsideRoot.test(path)) ? "/" : path.replace(replaceLastSegment, "$1");
        };

        this.getFirstExistingPath = function(node) {
            var path = this.getPath(node);

            if (!path && _.has(node, "parentPaths.schemaJsonPath")) {
                var parentNode = this.getSchemaNode(node.parentPaths.schemaJsonPath);
                return this.getFirstExistingPath(parentNode);
            }

            return path;
        };

        this.getPath = function(node) {
            return _.get(node, "sub-statements.path");
        };

        this.getDirectChildrenByConfigurationType = function(node, configuration) {
            var cachedNode = NodeInfoCache.getNode(this.getPath(node));

            if (configuration) {
                return angular.copy(cachedNode.configurableChildren);
            }

            return angular.copy(cachedNode.nonConfigurableChildren);
        };

        this.getDirectChildrenNamesByConfigurationType = function(node, configuration, statementsToOmit) {
            var cachedNode = angular.copy(NodeInfoCache.getNode(this.getPath(node)));

            if (configuration) {
                var configurableChildren = _.omit(cachedNode.configurableChildren, statementsToOmit);
                return _.flatten(_.values(configurableChildren));
            }

            var nonConfigurableChildren = _.omit(cachedNode.nonConfigurableChildren, statementsToOmit);
            return _.flatten(_.values(nonConfigurableChildren));
        };

        function correctPathIfNecessary(path) {
            return _.isEqual(path, "/") || angular.isUndefined(path) ? "" : path;
        }

        /**
         * Given a leaf path that is inside a list (at any level), get the values for each entry of that list.
         */
        this.getAllValuesInsideAList = function(referrerPath, referencedXpath, pathKeys) {
            var container: any = {
                xml: self.containerForXpath(NesDataCacheService.getCurrentData().value)
            };

            var xmlStr = x2js.json2xml_str(container);
            var path = XPathResolverService.processXPath(referencedXpath, referrerPath, pathKeys);

            var doc = new DOMParser().parseFromString(xmlStr, "text/xml");
            var possibleValues = angular.element(doc).xpath(path);

            return _.map(possibleValues, function(value) {
                return value.textContent;
            });
        };

        this.containerForXpathRec = function(node, parent) {
            if (node) {
                _.forOwn(node.containers, function(container, id) {
                    parent[id] = self.containerForXpath(container);
                });

                _.forOwn(node.lists, function(list, id) {
                    if (!_.isEmpty(list.entries)) {
                        parent[id] = _.map(list.entries, self.containerForXpath);
                    }
                });

                _.forOwn(node["leaf-lists"], function(leaflist, id) {
                    if (!_.isEmpty(leaflist.values)) {
                        parent[id] = leaflist.values;
                    }
                });

                _.forOwn(node.choices, function(choice, id) {
                    self.containerForXpathRec(choice.case, parent);
                });

                _.forOwn(node.leaves, function(leaf, id) {
                    if (angular.isDefined(leaf.value)) {
                        parent[id] = leaf.value;
                    }
                });
            }
        };

        this.containerForXpath = function(node) {
            var root: any = {};
            self.containerForXpathRec(node, root);
            return root;
        };

        /*
        * Retorna os pathKeys necessários no formato e ordenação esperada pelo nodo especificado.
        * Esta função foi criada para possibilitar a compatibilidade entre equipamentos de firmwares diferentes, que definam
        * pathKeys para uma mesma entidade de formas distintas.
        * O primeiro caso encontrato, que motivou a criação deste tratamento foi o nodo de configuração de interface GPON, que
        * nos DM4610 de FW 3.X identifica o nodo com uma leaf 'id' enquanto a partir do FW 4.X passa a identificar com as três
        * leaves 'chassis-id', 'port-id' e 'slot-id'. No final das contas, os dois casos possuem as mesmas informações, porém em
        * formatos diferentes.
        * Para auxilizar em casos similares a este, pode-se utilizar esta função, passando como parâmetro o path para o qual se
        * deseja navegar e as variações de pathKeys conhecidas. Com base nisso, esta função identificará quais são os pathKeys
        * necessários de acordo com a estrutura do equipamento carregado.
        *
        * Esta função pode não funcionar corretamente se dentre os pathKeys existirem objetos com a mesma estrutura em posições
        * diferentes. Um caso conhecido é no atalho de ONU, onde os FWs antigos podem referenciar um pathKey com atributo único de
        * 'id' no nível de identificação da porta, e outro no nível de identificação da ONU. Neste caso, esta função não é capaz
        * de distinguir qual é o objeto de pathKeys esperado em cada nível (já que ambos possuem a mesma estrutura). Caso esta
        * alternativa de 'overload' de pathKeys for efetivada, esta função poderia receber os pathKeys num formato mais detalhado,
        * onde seria especificado as variações conhecidas para cada nível do path.
        *
        * FIXME - O ideal, seria que os atalhos para a Info/Config fossem definidos em mais alto nível (sem os detalhes
        * estruturais como paths, pathKeys, etc). Mesmo para nodos que não dependem de pathKeys, esta abordagem está muito
        * amarrada. Por exemplo, o atalho para os alarmes do equipamento na InfoConfig, indica o path exato onde deve existir a
        * lista de alarmes. Se algum equipamento definir este caminho de outra forma, os links atuais não serão compatíveis. Por
        * isso, eu acredito que os parâmetros passados para a Info/Config (necessários nos atalhos) deveriam ser em alto nível,
        * onde seria indicado o tipo da entidade que se prentende abrir (ONU, Alarme, Porta, Slot) e seus indentificadores, com
        * isso ficaria sob a responsabilidade da controller da Info/Config localizar o nodo referente aos parâmetros, independente
        * da estrutura onde ele esteja incluído.
        * Outra alternativa, seria que todos os locais que disponibilizam links para itens específicos da Info/Config
        * 'conhecessem' os detalhes de cada equipamento, para que pudessem prover os dados necessários de acordo com a estrutura
        * de cada um.
        */
        this.resolveRequiredPathKeys = function(schemaJsonPath, pathKeys) {
            var node = this.getSchemaNode(schemaJsonPath);
            var dataJsonPath = node.paths.dataJsonPath;

            var indexedFields = getIndexedFields(dataJsonPath);
            var resolvedPathKeys = [];
            indexedFields.forEach(function(fieldNames) {
                var foundKey = _.find(pathKeys, function(key) {
                    var hasAllRequiredFields = _.every(fieldNames, function(fieldName) {
                        return _.has(key, fieldName);
                    });

                    return hasAllRequiredFields;
                });

                resolvedPathKeys.push(foundKey);
            });

            return resolvedPathKeys;
        };

        var getIndexedFields = function(dataJsonPath) {
            var indexedFields = [];
            var fieldsByIndex = extractPatternGroups(dataJsonPath, keysIndexAndNamesPattern);
            fieldsByIndex.forEach(function(indexedField) {
                var index = extractPatternGroups(indexedField, keysIndexPattern)[0];
                var fieldName = extractPatternGroups(indexedField, keysNamesPattern)[0];
                var indexNumber = Number(index);

                var fields = indexedFields[indexNumber];
                if (!fields) {
                    fields = [];
                    indexedFields[indexNumber] = fields;
                }
                fields.push(fieldName);
            });

            return indexedFields;
        };

        var extractPatternGroups = function(input, pattern) {
            var groups = [];
            var match = pattern.exec(input);
            while (match) {
                groups.push(match[1]);
                match = pattern.exec(input);
            }

            return groups;
        };
    }
]);
