"use strict";

var specialKeyCodeExceptions = [8, 9, 13, 35, 36, 37, 38, 39, 40, 46];

var isSpecialKeyCode = function(keyCode) {
    var isIgnorable = false;

    specialKeyCodeExceptions.every(function(key) {
        if (keyCode === key) {
            isIgnorable = true;
            return false;
        } else {
            return true;
        }
    });
    return isIgnorable;
};

angular.module("nms.templates")
.directive("replaceLineBreak", function() {
    return function(scope, element, attrs) {
        scope.$watch("deviceSelected", function() {
            element.html(attrs.replaceLineBreak.replace(/\n/g, "<br>"));
        });
    };
})
.directive("specialCharactersMask", function($timeout) {
    return function(scope, element) {
        element.bind("keypress", function(event) {
            var keyCode = event.which || event.keyCode,
                character = String.fromCharCode(keyCode);

            // This way we can wait for the event to be fulfilled then check the result value
            $timeout(function() {
                var regexMask = /^[-_]|[\.']/g;
                // Get input value when changing name or tags-input value when changing key words
                if (element[0].tagName === "INPUT") {
                    element.val(element.val().replace(regexMask, ""));
                } else {
                    $(element[0]).find("input").val($(element[0]).find("input").val().replace(regexMask, ""));
                }
            }, 0);

            if (character.match("[A-Za-z0-9_-]")) {
                return true;
            }

            if (isSpecialKeyCode(keyCode) && !event.shiftKey) {
                return true;
            }

            event.preventDefault();
            return false;
        });

        scope.$on("$destroy", function() {
            element.off();
        });
    };
})
.directive("hostnameMask", function($timeout) {
    return function(scope, element) {
        element.bind("keypress", function(event) {
            var keyCode = event.which || event.keyCode,
                character = String.fromCharCode(keyCode);

            // This way we can wait for the event to be fulfilled then check the result value
            $timeout(function() {
                var regexMask = /'/g;
                element.val(element.val().replace(regexMask, ""));
            }, 0);

            if (character.match("[A-Za-z0-9_-]")) {
                return true;
            }

            if (isSpecialKeyCode(keyCode) && !event.shiftKey) {
                return true;
            }

            event.preventDefault();
            return false;
        });
    };
})
.directive("firmwareMask", function($timeout) {
    return function(scope, element) {
        element.bind("keypress", function(event) {
            var keyCode = event.which || event.keyCode,
                character = String.fromCharCode(keyCode);

            // This way we can wait for the event to be fulfilled then check the result value
            $timeout(function() {
                var regexMask = /'/g;
                element.val(element.val().replace(regexMask, ""));
            }, 0);

            if (isSpecialKeyCode(keyCode) && !event.shiftKey) {
                return true;
            }

            if (!character.match("[0-9\.]")) {
                event.preventDefault();
                return false;
            }
        });

        scope.$on("$destroy", function() {
            element.off();
        });
    };
})
.directive("variableMask", function($timeout) {
    return function(scope, element) {
        element.bind("keypress", function(event) {
            var keyCode = event.which || event.keyCode,
                character = String.fromCharCode(keyCode);

            // This way we can wait for the event to be fulfilled then check the result value
            $timeout(function() {
                var regexMask = /'/g;
                element.val(element.val().replace(regexMask, ""));
            }, 0);

            if (character.match("[\\w-./]")) {
                return true;
            }

            if (isSpecialKeyCode(keyCode) && !event.shiftKey) {
                return true;
            }

            event.preventDefault();
            return false;
        });
    };
})
.directive("aDisabled", function() {
    return {
        compile: function() {
            // return a link function
            return function(scope, iElement, iAttrs) {
                if (!scope.hasPermission(iAttrs.aDisabled)) {
                    iElement.toggleClass("disabled");
                    // Disable href on click
                    iElement.on("click", function(e) {
                        e.preventDefault();
                    });
                }
            };
        }
    };
})
.directive("acronymMask", function($timeout) {
    return function(scope, element, attrs) {
        element.bind("keypress", function(event) {
            var keyCode = event.which || event.keyCode,
                character = String.fromCharCode(keyCode);

            // This way we can wait for the event to be fulfilled then check the result value
            $timeout(function() {
                var regexMask = /'/g;
                element.val(element.val().replace(regexMask, ""));
            }, 0);

            if (character.match("[A-Za-z]")) {
                return true;
            }

            if (isSpecialKeyCode(keyCode) && !event.shiftKey) {
                return true;
            }

            event.preventDefault();
            return false;
        });
    };
})
.directive("uppercased", function() {
    return {
        require: "ngModel",
        link: function(scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function(input) {
                return input ? input.toUpperCase() : "";
            });
            element.css("text-transform", "uppercase");
        }
    };
});
