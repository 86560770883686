var app = angular.module("nms.dynamicDevice");

/**
 * Verifica as restrições de todos os tipos de uma union.
 */
app.service("UnionRestrictionService", ["LeafService", "RestrictionService", "PatternRestrictionService",
    "LengthRestrictionService", "YangRangeRestrictionService", "DecimalIntegerRestrictionService",
    "DecimalFractionRestrictionService",
    function(LeafService, RestrictionService, PatternRestrictionService,
        LengthRestrictionService, YangRangeRestrictionService, DecimalIntegerRestrictionService,
        DecimalFractionRestrictionService) {
        var validateFunction = function(validatorsInError, modelValue, viewValue, type, service) {
            var isValid = service.validate(modelValue, viewValue, type);
            if (!isValid) {
                var validatorName = RestrictionService.getLeafTypeValidatorName(type, service.RESTRICTION_NAME);
                validatorsInError.push(validatorName);
            }
            return isValid;
        };

        this.getErrors = function(modelValue, viewValue, possibleTypes, possibleValues) {
            var value = modelValue || viewValue;

            var validatorsInError = [];
            var validate = _.curry(validateFunction)(validatorsInError, modelValue, viewValue);

            if (value !== null && angular.isDefined(value) && !_.includes(possibleValues, value)) {
                var isValid = _.chain(possibleTypes)
                    .filter(function(type) {
                        return !LeafService.isDiscreteType(type);
                    })
                    .some(function(type) {
                        var baseTypeName = LeafService.getBaseType(type).name;
                        var validators = [];
                        switch (baseTypeName) {
                            case "int8":
                            case "int16":
                            case "int32":
                            case "int64":
                            case "uint8":
                            case "uint16":
                            case "uint32":
                            case "uint64":
                                return validate(type, YangRangeRestrictionService);
                            case "decimal":
                                validators = [
                                    YangRangeRestrictionService,
                                    DecimalIntegerRestrictionService,
                                    DecimalFractionRestrictionService];
                                return _.every(_.map(validators, function(validator) {
                                    return validate(type, validator);
                                }));
                            case "string":
                                validators = [
                                    PatternRestrictionService,
                                    LengthRestrictionService];
                                return _.every(_.map(validators, function(validator) {
                                    return validate(type, validator);
                                }));
                            default:
                                var errorMessage = _.template("Did not expect type '{{type}}' to be a member of a type 'union'."
                                    + " All primitive types with non-discrete values should be mapped.")({type: baseTypeName});
                                throw new Error(errorMessage);
                        }
                    }).value();

                if (isValid) {
                    validatorsInError = [];
                } else if (!isValid && _.isEmpty(validatorsInError)) {
                    throw new Error("Value should be one of the possible discrete values for the union.");
                }
            }

            return validatorsInError;
        };
    }
]);
