"use strict";

/**
 * Filter that convert a given input to an Array, using Lodash's _.toArray().
 *
 * This filter is intended to be used inside the view, on javascript use _.toArray() directly.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.filter("toArray",
    function() {
        return function(input) {
            return _.toArray(input);
        };
    }
);
