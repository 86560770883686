"use strict";

var app = angular.module("nms");

/**
* @ngdoc controller
* @name nms.
* @description Controller para a diretiva text-finder.
*/
app.controller("TextFinderController", ["$scope", "$timeout", "TextHighlighterService", "UserPreferencesService",
    function($scope, $timeout, TextHighlighterService, UserPreferencesService) {
        var propertiesKeys = ["searchTerm"];
        var getOrSetDefault = function(obj, key, defaultValue) {
            if (angular.isUndefined(obj[key])) {
                obj[key] = defaultValue;
            }
            return obj[key];
        };

        var savedProperties: any = {searchTerm: ""};

        if ($scope.identifier) {
            savedProperties = UserPreferencesService.loadUserData(savedProperties, $scope.identifier, propertiesKeys);
        }

        var config = getOrSetDefault($scope, "config", {});
        getOrSetDefault(config, "id", "text-finder-input");
        getOrSetDefault(config, "searchTerm", savedProperties.searchTerm);
        getOrSetDefault(config, "enabled", true);
        getOrSetDefault(config, "minTermSize", 1);
        getOrSetDefault(config, "searchableElementSelector", "*");
        getOrSetDefault(config, "filterContent", angular.noop);
        getOrSetDefault(config, "baseElementSelector", "body");
        getOrSetDefault(config, "clearSearch", angular.noop);
        getOrSetDefault(config, "delay", 1000);
        getOrSetDefault(config, "placeholder", "");

        var timer;
        var listenerUnregisters = [];

        var enableSearch = function() {
            config.enabled = true;
        };

        /*
         * event: Info do evento que realizou a operação de busca.
         *        retorna: event.name=userInteraction para operação de busca realizada pelo usuário
         *        retorna: O event recebido pelo handler de eventos performSearchOnEvents para busca realizada por eventos
         *
         * data: Dados adicionais do evento.
         *       retorna: Null para o evento userInteraction
         *       retorna: Os dados recebidos pelo handler de eventos performSearchOnEvents
         */
        var performSearch = function(event, data?) {
            if (!config.searchTerm) {
                config.enabled = false;
                config.clearSearch(event, data);
                TextHighlighterService.clear(angular.element(config.baseElementSelector), config.searchableElementSelector)
                    .then(enableSearch);
            } else if (config.searchTerm.length >= config.minTermSize) {
                config.enabled = false;
                config.filterContent(event, data);
                TextHighlighterService
                    .highlight(angular.element(config.baseElementSelector), config.searchableElementSelector, config.searchTerm)
                    .then(enableSearch);
            }
        };

        $scope.onChange = function() {
            $timeout.cancel(timer);
            timer = $timeout(function() {
                performSearch({name: "userInteraction"});
            }, config.delay);
        };

        _.each(config.performSearchOnEvents, function(eventName) {
            listenerUnregisters.push($scope.$on(eventName, performSearch));
        });

        var unregisterIndexUpdateListener = $scope.$on("updatingTreeSearchIndex", function(event, isUpdating) {
            config.enabled = !isUpdating;
        });

        $scope.$on("$destroy", function() {
            _.each(listenerUnregisters, function(unregister) {
                unregister();
            });
            unregisterIndexUpdateListener();

            if ($scope.identifier) {
                UserPreferencesService.saveUserData(_.pick(config, propertiesKeys), $scope.identifier, propertiesKeys);
            }
        });
    }
]);
