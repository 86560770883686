"use strict";

/**
* @ngdoc controller
* @name nms.templates.components.upload:fileModel
* @description
* # fileModel
* Classe de controle para upload de arquivo.
*/
angular.module("nms.templates")
.directive("fileModel", ["$parse", function($parse) {
    return {
        restrict: "A",
        link: function(scope, element, attrs) {
            var model = $parse(attrs.fileModel);
            var modelSetter = model.assign;

            element.bind("change", function() {
                scope.$apply(function() {
                    modelSetter(scope, element[0].files[0]);
                });
            });
        }
    };
}]);
