import {
    EquipmentDetails,
    EquipmentIdentifier,
    TemplateApplicationStatus
} from "@nms-ng2/app/modules/template/template-instance/template-instance-models";

/**
 * Representa o resultado da aplicação de um template instance.
 */
export class TemplateInstanceResult {
    templateInstanceId: string;
    presentationMode: string;
    equipmentAssociationResults: EquipmentAssociationResult;
}

/**
 * Representa o resultado de uma associação de equipamento de um template instance.
 */
export interface EquipmentAssociationResult {
    equipmentIdentifier: EquipmentIdentifier;
    equipmentDetails: EquipmentDetails;
    templateResults: Array<TemplateResult>;
    apply: boolean;
}

/**
 * Representa o resultado da aplicação de um template associado a um equipamento.
 */
export interface TemplateResult{
    templateId: string;
    templateName: string;
    templateDescription: string;
    status: TemplateApplicationStatus;
}

/**
 * Representa o resultado da aplicação/remoção de um template associado a um equipamento.
 */
export interface TemplateAssociationResult{
    equipmentDetails: EquipmentDetails;
    templateName: string;
    removalResult: string;
    applicationResult
    status: TemplateApplicationStatus;
    executedAt: Date;
    executedBy: string;
    statusInfo: string;
}

/**
 * Representa os status da barra de Status da modal de visualização de resultados
 */
export enum TemplateResultStatus {
    SUCCESS = "success",
    WARNING = "warning",
    DANGER = "danger",
    APPLYING_BY_ANOTHER_USER = "applyingByAnotherUser",
    NOT_REQUESTED = "notRequested",
    NOT_EXECUTED = "notExecuted",
    APPLYING = "applying"
}