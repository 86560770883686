/**
 * Restriction for duplicated items in a leaflist.
 *
 * @author icaro.damiani
 */
var app = angular.module("nms.dynamicDevice");

app.directive("duplicatedElementRestriction", ["RestrictionService", "ListService", "DomainHandlerService",
    function(RestrictionService, ListService, DomainHandlerService) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                ngModel.$validators.duplicatedElementValidator = function(modelValue) {
                    var isRangeAlreadyIncluded;
                    var baseTypeName = DomainHandlerService.getBaseType(scope.leaflist.type).name;
                    var isIntegerType = ListService.isIntegerKey(baseTypeName);

                    if (modelValue && isIntegerType) {
                        var values = RestrictionService.buildArrayOfValues(modelValue);

                        if (!_.isEmpty(values)) {
                            isRangeAlreadyIncluded = _.every(values, function(value) {
                                return _.contains(scope.dataNode.values, value.toString());
                            });
                        }
                    }

                    return !_.contains(scope.dataNode.values, modelValue) && !isRangeAlreadyIncluded;
                };
            }
        };
    }
]);
