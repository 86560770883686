"use strict";

/**
 * Filter which HTML tags to escape, replacing chevrons with HTML entities.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.filter("escapeTagsExceptLineAndThematicBreak",
    function() {
        return function(input) {
            return input.replace(/<(?!br|hr|b|\/b)/g, "&lt;").replace(/(?<!br|hr|b|\/b)>/g, "&gt;");
        };
    }
);
