"use strict";

/**
* @ngdoc directive
* @name nms.directive:autofocusFirstKeyLeaf
* @description Diretiva para dar autofoco na primeira leaf key na criação de um item em tabelas da info/config.
**/
var app = angular.module("nms");
app.directive("autofocusFirstKeyLeaf", ["$timeout",
    function($timeout) {
        return {
            restrict: "A",
            link: function(scope, element, attrs) {
                $timeout(function() {
                    element.find("key-leaves:first input").focus();
                });
            }
        };
    }
]);
