"use strict";

/**
* @ngdoc Controller
* @name nms.management.ManageUserAccountTabController
* @description Realiza gerência de conta de usuário.
*/
var app = angular.module("nms.management");

app.controller("ManageUserAccountController", ["$scope", "ManageGroupsService", "SYSTEM_USER", "ManageGroupsActionsService",
    "LDAP_MODE",
    function($scope, ManageGroupsService, SYSTEM_USER, ManageGroupsActionsService, LDAP_MODE) {
        $scope.isEdit = !_.isEmpty($scope.owner.model);
        $scope.defaultUsers = [SYSTEM_USER.DATACOM, SYSTEM_USER.ADMINISTRATOR];
        var defaultAccountProperties: any = {
            user: "",
            administrator: false,
            permission: "individual",
            group: null,
            password: "",
            confirmPassword: "",
            name: "",
            phone: "",
            email: ""
        };
        _.defaults($scope.owner.model, defaultAccountProperties);

        /**
        * Limpa os campos de password e confirmPassword quando a check 'changePassword' é alterada.
        * Utilizado apenas em modo de edição.
        */
        $scope.resetNewPasswordsFields = function() {
            $scope.owner.model.password = "";
            $scope.owner.model.confirmPassword = "";
        };

        $scope.openEditGroupModal = function(groupName) {
            var callback = function(editedGroup) {
                $scope.groups = _.remove($scope.groups, function(group) {
                    return group === groupName;
                });
                $scope.groups.push(editedGroup.name);
                $scope.groups.sort();
                $scope.owner.model.group = editedGroup.name;
            };
            ManageGroupsActionsService.openEditGroupModal($scope.groups, groupName, callback);
        };

        $scope.isLdapAuthorization = function() {
            return $scope.ldapMode === LDAP_MODE.AUTHORIZATION;
        };

        $scope.isLdapAuthentication = function() {
            return $scope.ldapMode === LDAP_MODE.AUTHENTICATION;
        };

        var init = function() {
            if (!$scope.isSelfEditing) {
                ManageGroupsService.getGroupNames().then(function(groupNames) {
                    $scope.groups = groupNames;

                    if ($scope.owner.model.permission !== "group" && !_.isEmpty(groupNames)) {
                        $scope.owner.model.group = groupNames[0];
                    }
                });
            }
        };

        init();
    }
]);
