import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { SimpleModalComponent } from "ngx-simple-modal";
import { SchedulerListService } from "@nms-ng2/app/modules/scheduler/scheduler-list/scheduler-list.service";
import { NmsWizardComponent, NmsWizardStep } from "@nms-angular-toolkit/nms-wizard";
import { BackupDevicesFilter } from "./tabs/backup-devices-tab/backup-devices-tab.model";
import { BackupDevicesScheduler } from "./tabs/backup-scheduler-tab/backup-scheduler-tab.model";
import { BackupDevicesGeneral } from "./tabs/backup-general-tab/backup-general-tab.model";
import { JobType, SchedulerJob } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { SchedulerService } from "@nms-ng2/app/modules/scheduler/scheduler.service";
import { DeviceBackupUtilsService } from "../device-backup-utils.service";
import { BackupJobModal } from "../device-backup.models";

/**
 * Modal para criação de backups de Equipamentos.
 */
@Component({
    selector: "create-backup-modal",
    templateUrl: "./create-backup-modal.component.html",
    styleUrls: ["./create-backup-modal.component.scss"]
})
export class CreateBackupModalComponent extends SimpleModalComponent<BackupJobModal, any> implements AfterViewInit, BackupJobModal {
    schedulerJob: SchedulerJob
    isEdit: boolean;
    @ViewChild(NmsWizardComponent)
    nmsWizard: NmsWizardComponent;

    constructor(private schedulerListService: SchedulerListService,
        private schedulerService: SchedulerService,
        private deviceBackupUtils: DeviceBackupUtilsService,
        private changeDetectorRef: ChangeDetectorRef) {
        super();
        this.schedulerListService.closeModalEmmiter.subscribe(() => this.close());
    }

    ngAfterViewInit(): void {
        if (this.schedulerJob) {
            this.isEdit = true;
            this.fillTabsData();
        }
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    fillTabsData(): void {
        const wizardItems = this.nmsWizard.getWizardAllItems();
        const schedulerJob = this.schedulerService.initSchedulerJob(this.schedulerJob, JobType.BACKUP);

        wizardItems.forEach(tab => {
            let model: NmsWizardStep;
            if (tab.bodyComponent.getStepData() instanceof BackupDevicesFilter) {
                model = this.deviceBackupUtils.buildDevicesFilter(schedulerJob);
            } else if (tab.bodyComponent.getStepData() instanceof BackupDevicesScheduler) {
                model = this.deviceBackupUtils.buildDeviceBackupTriggers(schedulerJob);
            } else if (tab.bodyComponent.getStepData() instanceof BackupDevicesGeneral) {
                model = this.deviceBackupUtils.buildDeviceBackupGeneralInfo(schedulerJob);
            }
            tab.bodyComponent.setContext(model);
        });
    }

    cancel(): void {
        this.close();
    }
}
