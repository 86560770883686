"use strict";

/**
* @ngDoc service
* @name TableCellService
* @description: A partir de uma lista de elementos cria o label apartir das seguintes regras:
*   1 - Se lista de entries for igual a 0: "0 ${label}" - 0 items / 0 values, etc.
*   2 - Se todos os elementos forem números, deverá ser processado os ranges e mostrado seguinte o padrão: 1-5, 7, 15-20 ...
*   3 - Se houver algum texto na lista "entries" e está lista seja menor que MAX_ELEMENTS o resultado será: onu1, onu2, 3
*   4 - Havendo um número de elementos maior que MAX_ELEMENTS será retornado: onu1, 2, 3, onu4, 8, 6, +X ${label}
*
*   Parâmetros:
*   entries: [],                Lista de elementos a serem processados para criação do valor da célula.
*   translationKeys: {
*       useEmptyLabel: {Boolean},   Define se deverá ou não mostrar uma mensagem caso as entradas sejam vazias. Default: true
*       singleElement: {String},    Chave para tradução de um elemento a ser usado no campo ${label}.
*       multiElements: {String}     Chave para tradução de múltiplos elementos a ser usado no campo ${label}.
*    }
*/
var app = angular.module("nms.dynamicDevice");

app.service("TableCellService", ["$filter", "$translate",
    function($filter, $translate) {
        var MAX_ELEMENTS = 6;
        const service: any = {};

        service.getCellValuesStr = function(entries, translationKeys) {
            if (entries.length === 0) {
                if (_.isUndefined(translationKeys.useEmptyLabel) || translationKeys.useEmptyLabel) {
                    return "";
                }

                return $translate.instant(translationKeys.multiElements).replace("{0}", entries.length);
            } else if (!_.some(entries, isNaN)) {
                return $filter("numericRangeFormatter")(entries);
            } else if (entries.length <= MAX_ELEMENTS) {
                return entries.join(", ");
            } else {
                var entriesToShow = entries.slice(0, MAX_ELEMENTS);
                var remainingElementsLength = entries.length - entriesToShow.length;
                var label = remainingElementsLength === 1 ? translationKeys.singleElement : translationKeys.multiElements;

                var data: any = {
                    entries: entriesToShow.join(", "),
                    label: $translate.instant(label).replace("{0}", remainingElementsLength)
                };

                return _.template("${entries}, +${label}")(data);
            }
        };

        service.getEntriesByLeafKeys = function(schemaNode, dataNode) {
            var keysSubstatements = _.get(schemaNode, "sub-statements.key");
            var keys = keysSubstatements ? keysSubstatements.split(" ") : [];

            return _.map(_.get(dataNode, "entries"), function(entry) {
                return _.map(keys, function(key) {
                    return _.get(entry.leaves, key).value;
                }).join("/");
            });
        };

        return service;
    }
]);
