import { Component, Inject, OnInit } from "@angular/core";
import { SimpleModalComponent } from "ngx-simple-modal";
import { ANGULARJS_TRANSLATE,
    CWMP_DOWNLOAD_FILE_TYPE,
    ANGULARJS_ROOTSCOPE,
    USER_PREFERENCES_SERVICE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Cpe } from "../cpe-model";
import { DownloadService } from "./download.service";
import { Subject } from "rxjs";
import { NmsSelectModel, NmsSelectValue } from "@nms-ng2/app/shared/components/elements/nms-select/nms-select.component";

export interface DownloadModel {
  cpesSelected: any;
}

@Component({
    selector: "download",
    templateUrl: "./download.component.html",
    styleUrls: ["./download.component.scss"]
})
export class DownloadComponent extends SimpleModalComponent<DownloadModel, any> implements DownloadModel, OnInit {
    cpesSelected: Cpe[];
    itemsUrl:  Array<NmsSelectModel>
    items: any[] = [];
    typeDownloadSelected: any;
    url: string;
    titleCpesSelected: string;
    nmsSelectItemNotifier: Subject<NmsSelectValue>;
    nmsItemNotifier: Subject<Array<NmsSelectModel>>;
    private readonly ITEMS_KEY_IDENTIFIER: string = "urlsDownloadFiles";

    constructor(@Inject(ANGULARJS_TRANSLATE) private translate: any,
                @Inject(CWMP_DOWNLOAD_FILE_TYPE) private downloadFileTypeConstants: any,
                @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
                @Inject(USER_PREFERENCES_SERVICE) private userPreferenceService,
                private downloadService: DownloadService) {
        super();
        this.items = _.values(this.downloadFileTypeConstants);
        this.typeDownloadSelected = this.items[0].type;
        this.nmsSelectItemNotifier = new Subject();
        this.nmsItemNotifier = new Subject();
    }

    ngOnInit() {
        this.itemsUrl = this.loadUrlsFromLocalStorage();
        this.titleCpesSelected = this.cpesSelected.length == 1
          ? `${this.cpesSelected.length} ${this.translate.instant("download.cpe.selected")}`
          : `${this.cpesSelected.length} ${this.translate.instant("download.cpes.selected")}`;

        this.nmsSelectItemNotifier.subscribe((selectValue: NmsSelectValue) => {
            this.url = selectValue.value;
        });

        this.nmsItemNotifier.subscribe((itens: Array<NmsSelectModel>) => {
            this.itemsUrl = itens;
            this.saveUrlsToLocalStorage();
        });

    }

    sendDownloadRequest() {
        if (!this.url) {
            this.$rootScope.showDialog({
                translateKey: "download.url.empty"
            });
        } else {
            this.downloadService
                .sendDownloads(this.url, this.typeDownloadSelected, this.cpesSelected.map(cpe => cpe.serialNumber));
            this.close();
        }
    }

    saveUrlsToLocalStorage(): void {
        var urlsToDownload = {
            items : this.itemsUrl
        };
        this.userPreferenceService.savePreferences(urlsToDownload, this.ITEMS_KEY_IDENTIFIER, ["items"]);
    }

    loadUrlsFromLocalStorage(): Array<NmsSelectModel> {
        var itemsUrl = this.userPreferenceService.loadPreferences({}, this.ITEMS_KEY_IDENTIFIER, ["items"]);
        return _.isEmpty(itemsUrl) ? [{ value: "", isRemovable: false }] : itemsUrl.items;
    }
}
