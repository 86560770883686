"use strict";
/**
 * Wrapper para a diretiva dropdown.
 *
 * Componentes feitos em AngularJS que utilizam o load do template através de "templateURL", geram um problema
 * ao carregar esses templates em um upgrade de componente.
 * Para solucionar o problemas existem duas alternativas:
 * - Alterar a diretiva para carregar o template no formato template: `<ng-include src="path"></ng-include>`
 * - Criar um wrapper para encapsular a diretiva chamando a diretiva alvo explicitamente como está sendo utilizado aqui.
 *
 * A escolha dessa segunda abordagem se dá devido a um problema na combinação do uso do ng-include e diretivas que usam
 * transclude. Neste caso o upgrade falha dizendo que não existe um componente pai que possibilite o uso de transclude,
 * tendo em vista que o transclude deixo de existir no Angular 2+.
 */
angular.module("nms")
    .directive("nmsDropdownOld", () => {
        return {
            restrict: "E",
            transclude: true,
            template: `<dropdown shortcuts="shortcuts"
                                parameters="parameters"
                                dropdown-title="dropdownTitle"
                                use-title-as-plain-text="useTitleAsPlainText"
                                disabled-shortcut-tooltip="disabledShortcutTooltip">
                            <ng-transclude></ng-transclude>
                        </dropdown>`,
            scope: {
                shortcuts: "=",
                parameters: "=",
                dropdownTitle: "=",
                useTitleAsPlainText: "=?",
                disabledShortcutTooltip: "@?"
            }
        }
})