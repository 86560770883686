/**
 * Decimal Restriction.
 * Validates the number of integer and fraction digits of a decimal value.
 *
 * @author icaro.damiani
 */
var app = angular.module("nms.dynamicDevice");

app.directive("decimalRestriction", ["RestrictionService", "DecimalFractionRestrictionService",
    "DecimalIntegerRestrictionService",
    function(RestrictionService, DecimalFractionRestrictionService,
        DecimalIntegerRestrictionService) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                // Validates the fraction part of decimal type.
                var decimalFractionValidatorName = RestrictionService.getLeafTypeValidatorName(scope.leaf.type,
                    DecimalFractionRestrictionService.RESTRICTION_NAME);
                ngModel.$validators[decimalFractionValidatorName] = function(modelValue, viewValue) {
                    return DecimalFractionRestrictionService.validate(modelValue, viewValue, scope.leaf.type);
                };

                // Validates the integer part of decimal type.
                var decimalIntegerValidatorName = RestrictionService.getLeafTypeValidatorName(scope.leaf.type,
                    DecimalIntegerRestrictionService.RESTRICTION_NAME);
                ngModel.$validators[decimalIntegerValidatorName] = function(modelValue, viewValue) {
                    return DecimalIntegerRestrictionService.validate(modelValue, viewValue, scope.leaf.type);
                };
            }
        };
    }
]);
