import { Component } from "@angular/core";
import { NmsWizardStep, NmsWizardStepComponent, NmsWizardStepValidationResponse } from "@nms-angular-toolkit/nms-wizard";
import { SchedulerListService } from "@nms-ng2/app/modules/scheduler/scheduler-list/scheduler-list.service";
import { BackupDevicesGeneral } from "./backup-general-tab.model";
import { CreateBackupService } from "../../create-backup.service";

/**
 * Aba de configurações gerais do backup.
 */
@Component({
    selector: "backup-general-tab",
    templateUrl: "./backup-general-tab.component.html",
    styleUrls: ["./backup-general-tab.component.scss"]
})
export class BackupGeneralTabComponent extends NmsWizardStepComponent {
    model: BackupDevicesGeneral = new BackupDevicesGeneral();

    constructor(private schedulerListService: SchedulerListService,
        private createBackupService: CreateBackupService) {
        super();
        this.model.enabled = true;
    }

    setContext(context: BackupDevicesGeneral): void {
        this.model = Object.assign(this.model, context);
    }

    loadContent(): Promise<void> {
        return Promise.resolve();
    }

    allowPrevious(): NmsWizardStepValidationResponse {
        return NmsWizardStepValidationResponse.getDefaultValidationResponse();
    }

    allowNext(): NmsWizardStepValidationResponse {
        return NmsWizardStepValidationResponse.getDefaultValidationResponse();
    }

    finishAction(tabs: Array<NmsWizardStep>): void {
        const schedulerJob = this.createBackupService.buildDeviceBackupScheduler(tabs);
        this.schedulerListService.save(schedulerJob);
    }

}