import { Inject, Injectable } from "@angular/core";
import { AUTHENTICATION_SERVICE, NMS_FEATURES } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { JobType, SchedulerJob } from "./scheduler.models";

/**
 * Serviço responsável por verificar os agendamentos os quais o usuário possui permissão,
 * baseado no tipo @see JobType.
 */
@Injectable({
    providedIn: "root"
})
export class SchedulerPermissionsService {

    constructor(
        @Inject(AUTHENTICATION_SERVICE) public authenticationService,
        @Inject(NMS_FEATURES) public nmsFeatures) { }

    /**
     * Busca os agendamentos que o usuário possui permissão baseado no tipo.
     * @param schedulerJobs - Lista de agendamentos
     * @returns Listas de agendamentos que o usuário possui permissão.
     */
    getAllowedUserSchedulerJobs(schedulerJobs: Array<SchedulerJob>): Array<SchedulerJob> {
        return schedulerJobs.filter((schedulerJob: SchedulerJob) => this.hasPermissionToScheduledJob(schedulerJob.type));
    }

    /**
     * Verifica se o usuário tem permissão para um determinado agendamento baseado no tipo.
     * @param jobType - Tipo de agendamento
     * @returns true/false
     */
    hasPermissionToScheduledJob(jobType: JobType): boolean {
        switch (jobType) {
            case JobType.TEMPLATE_APPLICATION:
                return this.hasTemplateApplicationPermission();
            case JobType.BACKUP:
                return this.hasDcbPermission();
            default:
                return true;
        }
    }

    private hasTemplateApplicationPermission(): boolean {
        return this.authenticationService.hasPermission(this.nmsFeatures.templateInstance.feature);
    }

    private hasDcbPermission(): boolean {
        return this.authenticationService.hasPermission(this.nmsFeatures.configureBackup.feature);
    }
}
