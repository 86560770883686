var app = angular.module("nms.manageDevices");

/**
 * @ngdoc function
 * @name nms.manageDevice.controller:DeviceLocationController
 * @description
 * # DeviceLocationController
 * Controller of the nms.manageDevice
 */

app.controller("DeviceLocationController", ["$rootScope", "$scope", "selectedDevices", "DeviceDiscoverService", "$translate", "LOCATION",
    "globalLocation",
    function($rootScope, $scope, selectedDevices, DeviceDiscoverService, $translate, LOCATION, globalLocation) {
        $scope.selectedDevices = selectedDevices;
        $scope.globalLocation = globalLocation;
        $rootScope.initialLocationId = globalLocation.locationId;

        if (!_.isEmpty(selectedDevices) && (selectedDevices.length === 1)) {
            $rootScope.initialLocationId = $scope.selectedDevices[0].locationId;
        }

        /**
        * Quando a location inicial for a default, o nó selecionado passa a ser vazio.
        * Isso permite que a árvore de caminhos identifique o nó root como selecionado
        * {@see nms.manageDevices.PathTreeController#isRootSelected}.
        */
        if ($rootScope.initialLocationId === LOCATION.DEFAULT_LOCATION_ID) {
            $rootScope.selectedNode = {};
        }

        $scope.getSubTitle = function() {
            var useDeviceName = !_.isEmpty($scope.selectedDevices) && $scope.selectedDevices.length === 1;
            return useDeviceName ? $scope.selectedDevices[0].name : DeviceDiscoverService.getSubTitle($scope.selectedDevices);
        };

        $scope.removePopover = function() {
            $scope.closePopover = true;
        };

        $scope.saveNewLocation = function() {
            if (_.isEmpty($scope.selectedDevices)) {
                $scope.globalLocation.locationId = $rootScope.selectedNode.id || LOCATION.DEFAULT_LOCATION_ID;
                $scope.globalLocation.path = $rootScope.selectedNode.path || LOCATION.DEFAULT_PATH;
                $scope.confirm($scope.globalLocation);
            } else {
                _.forEach($scope.selectedDevices, function(device) {
                    device.locationId = $rootScope.selectedNode.id || LOCATION.DEFAULT_LOCATION_ID;
                    device.path = $rootScope.selectedNode.path || LOCATION.DEFAULT_PATH;
                });

                $scope.confirm($scope.selectedDevices);

                var translateKey = $scope.selectedDevices.length > 1 ?
                                    "toastr.devicesLocationSuccessfullyEdited" :
                                    "toastr.deviceLocationSuccessfullyEdited";

                $rootScope.toastInfo(translateKey, [$scope.selectedDevices.length]);
            }
        };

        $scope.trySaveNewLocation = function() {
            var locationId = $rootScope.selectedNode && $rootScope.selectedNode.id ? $rootScope.selectedNode.id : LOCATION.DEFAULT_LOCATION_ID;
            DeviceDiscoverService.verifyLocationManagerPermission(locationId).then(function(hasPermission) {
                if (hasPermission) {
                    $scope.saveNewLocation();
                } else {
                    $rootScope.showDialog({
                        translateKey: "findAddDevice.error.noUserPermission"
                    });
                }
            });
        };
    }
]);
