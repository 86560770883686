import { Injectable } from "@angular/core";
import { NmsWizardStep } from "@nms-angular-toolkit/nms-wizard";
import { BackupDevicesFilter } from "./tabs/backup-devices-tab/backup-devices-tab.model";
import { BackupJobData, JobExecutionStatus, JobType, SchedulerJob } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { BackupDevicesScheduler } from "./tabs/backup-scheduler-tab/backup-scheduler-tab.model";
import { BackupDevicesGeneral } from "./tabs/backup-general-tab/backup-general-tab.model";
import { SchedulerService } from "@nms-ng2/app/modules/scheduler/scheduler.service";

/**
 * Serviço utilitário para criação/edição de Backups de Equipamento.
 */
@Injectable({
    providedIn: "root"
})
export class CreateBackupService {

    constructor(private schedulerService: SchedulerService) { }

    buildDeviceBackupScheduler(steps: Array<NmsWizardStep>): SchedulerJob {
        const schedulerJob: SchedulerJob = this.schedulerService.initSchedulerJob(null, JobType.BACKUP);
        const backupJobData: BackupJobData = schedulerJob.jobData as BackupJobData;

        steps.forEach((stepData: NmsWizardStep) => {
            if (stepData instanceof BackupDevicesFilter) {
                backupJobData.devicesFilter = stepData.devicesFilter;
            } else if (stepData instanceof BackupDevicesScheduler) {
                schedulerJob.type = stepData.jobType;
                schedulerJob.triggers = stepData.triggers;
                schedulerJob.id = stepData.id;
            } else if (stepData instanceof BackupDevicesGeneral) {
                schedulerJob.name = stepData.name;
                schedulerJob.enabled = stepData.enabled;
                backupJobData.description = stepData.description;
                backupJobData.exportBackups = stepData.exportBackups;
                schedulerJob.status = stepData.status || JobExecutionStatus.NOT_REQUESTED;
            }
        });

        return schedulerJob;
    }
}
