"use strict";

angular.module("nms.templates")
.factory("ActivationServiceREST", ["Restangular", function(Restangular) {
    var basePath = "/activationService";

    return {
        applyVariableCommands: function(commandTemplate) {
            return Restangular.one(basePath + "/applyVariableCommands").customPOST(commandTemplate);
        },

        validateVariableCommands: function(commandTemplate) {
            return Restangular.one(basePath + "/validateVariableCommands").customPOST(commandTemplate);
        }
    };
}]);
