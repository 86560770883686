var app = angular.module("nms.dynamicDevice");

app.directive("lists",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/list/lists.html",
            link: function(scope, element) {
                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);

app.directive("keyLeaves",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/list/key-leaves.html",
            scope: {
                leaf: "=",
                form: "=",
                pathKeys: "=",
                isInsideListBody: "=",
                isSingleEditing: "=",
                isKey: "="
            },
            link: function(scope, element) {
                var isKey = function(leaf) {
                    return _.contains(scope.keys, leaf.id);
                };

                scope.sortLeaves = function(leaf) {
                    return (isKey(leaf)) ? 0 : leaf.id;
                };

                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);

app.directive("keyLeafBody",
    function() {
        return {
            restrict: "E",
            controller: "LeafController",
            scope: {
                leaf: "=",
                form: "=",
                watchChanges: "=",
                config: "=",
                isInsideListBody: "=",
                isSingleEditing: "=",
                pathKeys: "=?",
                getPathKeys: "&",
                isTransient: "=",
                isKey: "="
            },
            templateUrl: "templates/features/dynamic-device/domain/yang/list/key-leaf-body.html",
            link: function(scope, element) {
                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);

app.directive("listbody",
    function() {
        return {
            restrict: "E",
            scope: {
                list: "=",
                form: "=",
                pathKeys: "=",
                config: "=",
                deviceId: "="
            },
            controller: "ListBodyController",
            templateUrl: "templates/features/dynamic-device/domain/yang/list/list-body.html",
            link: function(scope, element) {
                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);

app.directive("listTableCell",
    function() {
        return {
            restrict: "E",
            controller: "ListTableCellController",
            scope: {
                rowModel: "=",
                rowModelIndex: "=",
                listSchemaNode: "=",
                listDataNode: "=",
                editEntry: "=",
                fieldSearchStatement: "=",
                pathKeys: "=?",
                getPathKeys: "&"
            },
            templateUrl: "templates/features/dynamic-device/domain/yang/list/list-table-cell.html",
            link: function(scope, element) {
                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);
