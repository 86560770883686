"use strict";

/**
* @ngdoc service
* @name nms.management.ManageUsersRestService
* @description Realiza chamadas REST ao serviço de gerência de usuários.
*/
var app = angular.module("nms.management");

app.factory("ManageUsersRestService", ["Restangular",
    function(Restangular) {
        var baseURL = "/users/";
        const service: any = {};

        service.getAll = function() {
            return Restangular.all(baseURL).customGET();
        };

        service.create = function(userModel) {
            return Restangular.one(baseURL).customPOST(userModel);
        };

        service.getUser = function(username) {
            return Restangular.all(baseURL + username).customGET();
        };

        service.edit = function(userModel) {
            return Restangular.one(baseURL + "edit").customPOST(userModel);
        };

        service.remove = function(usernames) {
            var contentType: any = {"content-type": "application/json; charset=UTF-8"};
            return Restangular.all(baseURL).customOperation("remove", "", {}, contentType, usernames);
        };

        service.editBasic = function(editUserRequest) {
            return Restangular.one(baseURL + "edit-basic").withHttpConfig({bypassInterceptor: true}).customPOST(editUserRequest);
        };

        return service;
    }
]);
