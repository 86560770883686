"use strict";

/**
* @ngdoc service
* @name nms.templates.VariableService
* @description
* # VariableService
* Factory in the nms.templates.
*/
angular.module("nms.templates")
.factory("IntegerVariableValidatorService", ["$rootScope", "ValidatorService",
    function($rootScope, ValidatorService) {
        const service: any = {};

        service.validateIntegerVariable = function(checkedVariable, hasInterval, hasStep, hasBaseExponent) {
        // Field Interval Validation
            checkedVariable = service.parseNumbersIfVariableIsValid(checkedVariable);
            if (hasInterval) {
                if ((checkedVariable.minValue === null || angular.isUndefined(checkedVariable.minValue))
                    && (checkedVariable.maxValue === null || angular.isUndefined(checkedVariable.maxValue))) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.emptyCheckedRestrictions");
                    return false;
                } else if (service.checkVariableInvalidInterval(checkedVariable)) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.invalidInterval");
                    return false;
                }
            }

        // Field Step validation and Field step validation for Interval
            if (hasStep) {
                if (checkedVariable.step === null) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.emptyCheckedRestrictions");
                    return false;
                } else if (angular.isUndefined(checkedVariable.step)) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.invalidStep");
                    return false;
                } else if (checkedVariable.maxValue !== null) {
                    valid = false;
                    for (var value = checkedVariable.minValue; value <= checkedVariable.maxValue; value++) {
                        if (value % checkedVariable.step === 0) {
                            valid = true;
                            break;
                        }
                    }
                    if (!valid) {
                        ValidatorService.showAlertBasicTab("addVariableModal.error.invalidStepForInterval");
                        return false;
                    }
                }
            }

        // Field Power validation for Interval
            if (hasBaseExponent) {
                if (checkedVariable.baseExponent === null) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.emptyCheckedRestrictions");
                    return false;
                } else if (angular.isUndefined(checkedVariable.baseExponent)) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.invalidBaseExponent");
                    return false;
                } else if (checkedVariable.maxValue !== null) {
                    var valid = false;

                    if (!(checkedVariable.minValue < 0 && checkedVariable.maxValue < 0)) {
                        var min = checkedVariable.minValue;

                        if (checkedVariable.minValue <= 0) {
                            min = 1;
                        }

                        for (var value = min; value <= checkedVariable.maxValue; value++) {
                            var logResult = Math.log(value)/ Math.log(checkedVariable.baseExponent);
                            if (logResult == Math.floor(logResult)) {
                                valid = true;
                                break;
                            }
                        }
                    }

                    if (!valid) {
                        ValidatorService.showAlertBasicTab("addVariableModal.error.invalidPowerOfForInterval");
                        return false;
                    }
                }
            }

        // Field Default Value Validation for Integer Type
            if (checkedVariable.defaultValue) {
                var valid = true;

            // For Interval
                if (checkedVariable.minValue && checkedVariable.defaultValue < checkedVariable.minValue) {
                    valid = false;
                } else if (checkedVariable.maxValue && checkedVariable.defaultValue > checkedVariable.maxValue) {
                    valid = false;
                }

            // For Step
                if (checkedVariable.step !== null) {
                    valid = valid && checkedVariable.defaultValue % checkedVariable.step === 0;
                }

            // For power
                if (checkedVariable.baseExponent !== null) {
                    if (checkedVariable.defaultValue <= 0) {
                        valid = false;
                    } else {
                        var logResult = Math.log(checkedVariable.defaultValue) / Math.log(checkedVariable.baseExponent);
                        valid = valid && logResult == Math.floor(logResult);
                    }
                }

                if (!valid) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.invalidIntDefaultValueForRestricitions");
                    return false;
                }
            }

            return true;
        };

        service.checkVariableInvalidInterval = function(checkedVariable) {
            return (checkedVariable.maxValue && checkedVariable.minValue)
                && (checkedVariable.minValue > checkedVariable.maxValue);
        };

        /**
        * Para evitar resultados inconsistentes que estavam ocorrendo em várias partes referentes ao intervalo de restrições, o
        * sistema verifica uma única vez se as variáveis possuem valores. Caso possuam já realiza o parse.
        **/
        service.parseNumbersIfVariableIsValid = function(checkedVariable) {
            if (checkedVariable.minValue) {
                checkedVariable.minValue = parseInt(checkedVariable.minValue);
            }
            if (checkedVariable.maxValue) {
                checkedVariable.maxValue = parseInt(checkedVariable.maxValue);
            }
            if (checkedVariable.defaultValue) {
                checkedVariable.defaultValue = parseInt(checkedVariable.defaultValue);
            }
            if (checkedVariable.step) {
                checkedVariable.step = parseInt(checkedVariable.step);
            }
            if (checkedVariable.baseExponent) {
                checkedVariable.baseExponent = parseInt(checkedVariable.baseExponent);
            }
            return checkedVariable;
        };

        return service;
    }]);
