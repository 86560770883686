"use strict";

/**
 * @ngdoc service
 * @name nms.ConnectivityTestService
 * @description
 * # Serviço responsável por abrir a modal de Teste de conectividade.
 */

var app = angular.module("nms");

app.factory("ConnectivityTestService", ["$rootScope", "ngDialog", "DEVICE_STATUS", "DeviceDiscoverRESTService",
    function($rootScope, ngDialog, DEVICE_STATUS, DeviceDiscoverRESTService) {
        const service: any = {};

        /**
         * Remove todos os equipamentos remotos da lista recebida.
         */
        function removeRemotes(hosts) {
            return _.filter(hosts, function(host) {
                return host.devNo === 1 && host.devLocalId === 1;
            });
        }

        /**
         * Valida a lista de equipamentos recebida da seguinte forma:
         * - Se a lista estiver vazia e havia equipamentos remotos, exibe mensagem informando que
         *   é necessário selecionar equipamentos locais. Retorna false nesse caso.
         * - Se a lista contiver equipamentos e havia equipamentos remotos, exibe mensagem informando
         *   que os remotos que foram selecionados serão ignorados. Retorna true nesse caso.
         * - Se não havia equipamentos remotos, não exibe mensagem e retorna true.
         */
        function validateRemotes(hosts, hasRemoteDevices) {
            if (hasRemoteDevices) {
                if (_.isEmpty(hosts)) {
                    $rootScope.showDialog({translateKey: "connectivityTest.error.mustSelectLocalDevice"});

                    return false;
                } else {
                    $rootScope.showDialog({
                        translateKey: "connectivityTest.error.remoteDevicesWereRemoved",
                        type: "alert"
                    });
                }
            }

            return true;
        }

        /**
         * Valida a quantidade de equipamentos selecionados. Atualmente só é possível abrir
         * o teste de conectividade para 20 equipamentos simultaneamente. Em caso de tamanho
         * inválido, exibe mensagem de erro e retorna false.
         */
        function validateSelectionSize(hosts) {
            const maxSelection = 20;
            const selectionSize = hosts.length;

            if (maxSelection < selectionSize) {
                var options: any = {
                    translateKey: "connectivityTest.error.maxSelectionExceeded",
                    params: [selectionSize, maxSelection],
                    type: "alert",
                    paramsInsideMessage: true
                };
                $rootScope.showDialog(options);
                return false;
            }

            return true;
        }

        /**
         * Realiza validaçao dos equipamentos selecionados, exibe mensagem caso necessário, e decide se deve
         * abrir a modal com os dados dos hosts a serem testados.
         *
         * Parâmetros:
         *   availableHosts [{
         *       hostname            - mandatório     -  Hostname apresentado na tabela
         *       model               - mandatório     -  Modelo do equipamento a ser apresentado na tabela
         *       id                  - opcional       -  Id do equipamento. Quando presente a modal mostra o menu de dropdown do
         *                                               equipamento.
         *       name                - opcional       -  Para equipamentos monitorados, usado em links de dropdown
         *       credentials         - opcional       -  Credenciais dos protocolos a serem testados.
         *       availableFeatures   - opcional       -  Lista de features suportadas pelo equipamento. Essas features serão
         *                                               utilizadas na composição do dropdown.
         *       modelCode           - opcional       -  Código de modelo de equipamento usado para saber quais protocolos o
         *                                               equipamento responde.
         *   }]
         */
        service.openConnectivityTestModal = function(availableHosts) {
            var hostsToTest = removeRemotes(angular.copy(availableHosts));
            var hasRemoteDevices = hostsToTest.length !== availableHosts.length;

            if (validateSelectionSize(hostsToTest) && validateRemotes(hostsToTest, hasRemoteDevices)) {
                ngDialog.open({
                    template: "templates/components/ui/modals/connectivity-test-modal.html",
                    controller: "ConnectivityTestController",
                    className: "extra-large-modal",
                    closeByNavigation: true,
                    resolve: {
                        availableHosts: function() {
                            return _.map(hostsToTest, function(host) {
                                host.percentage = 0;

                                // Hosts adicionados devem ter suas credenciais removidas caso existam,
                                // para que o serviço de teste de conectividade utilize as credenciais salvas no banco de dados.
                                if (host.status === DEVICE_STATUS.ADDED) {
                                    delete host.credentials;
                                }
                                return host;
                            });
                        },
                        protocolByDeviceModelMap: function() {
                            var hostDeviceModels = _.chain(hostsToTest)
                                .filter(function(host) {
                                    return _.has(host, "modelCode");
                                }).map(function(host) {
                                    return host.modelCode;
                                }).uniq().value();

                            return DeviceDiscoverRESTService.getDevicesSupportedProtocols(hostDeviceModels)
                                .then(function(response) {
                                    return response.plain();
                                });
                        },
                        websocketClientId: function() {
                            return Date.now();
                        }
                    }
                });
            }
        };

        return service;
    }
]);
