"use strict";

import { EquipmentSelectionType } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { TemplateInstanceService } from "../template-instance-service";

/**
 * @ngdoc service
 * @name nms.templates.TemplateApplicationOneStepService
 * @description - Service que abre a modal de aplicação de templates de um único passo.
 * # TemplateApplicationOneStepService
 * Factory in the nms.templates.
 */
var app = angular.module("nms.templates");

app.factory("TemplateApplicationOneStepService", [
    "ngDialog",
    "PRESENTATION_MODE",
    "ConfigRESTService",
    "TemplateInstanceService",
    "TEMPLATE_TYPE",
    "TemplateActionsService",
    "PermissionsActionsService",
    function (
        ngDialog,
        PRESENTATION_MODE,
        ConfigRESTService,
        TemplateInstanceService: TemplateInstanceService,
        TEMPLATE_TYPE,
        TemplateActionsService,
        PermissionsActionsService
    ) {
        const service: any = {};

        /**
         * Cria o objeto templateInstance, necessário para funcionamento da modal.
         *
         * @param device device onde o template será aplicado
         * @param templateIdentifier informações básicas do template
         *
         * @example
         * Campos necessários:
         *   device = {
         *       id: 21,
         *       name: "172.31.203.65",
         *       hostname: "172.31.203.65 - 1.1",
         *       fwVersion: "2.0",
         *       modelName: "DM4610 8GPON+8GX+4GT+2XS",
         *       locationId: 0,
         *       modelCode: 51001,
         *       vendorCode: 1
         *   }
         *
         *   templateIdenfifier = {
         *       templateId: "templateId",
         *       templateName: "templateName",
         *       templateDescription: "templateDescription"
         *   }
         */
        var createTemplateInstance = function (device, templateIdentifier) {
            const equipmentIdentifier = {
                resourceId: device.id,
                type: "DeviceIdentifier"
            };

            const equipmentDetails = {
                name: device.name,
                model: device.model,
                firmwareVersion: device.fwVersion,
                hostname: device.hostname,
                modelCode: device.modelCode,
                vendorCode: device.vendorCode,
                locationId: device.locationId,
                type: "DeviceDetails"
            };

            return {
                equipmentAssociations: [
                    {
                        equipmentIdentifier: equipmentIdentifier,
                        equipmentDetails: equipmentDetails,
                        templateAssociations: [
                            {
                                templateId: templateIdentifier.templateId,
                                templateName: templateIdentifier.templateName,
                                templateDescription: templateIdentifier.templateDescription,
                                apply: true
                            }
                        ],
                        localVars: [],
                        apply: false
                    }
                ],
                globalVars: [],
                presentationMode: PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE,
                keywords: [],
                type: TEMPLATE_TYPE.CLI.name,
                equipmentSelectionType: EquipmentSelectionType.SPECIFIC
            };
        };

        var openTemplateApplicationOneStepModal = function (templateIdentifier, variables, devices) {
            ngDialog.openConfirm({
                template: "templates/features/template/template-instance/one-step/template-application-one-step-modal.html",
                controller: "TemplateApplicationOneStepController",
                className: "big-modal",
                resolve: {
                    resolvedInformation: function () {
                        var templates: any = {};
                        templates[templateIdentifier.templateId] = {
                            templateName: templateIdentifier.templateName,
                            templateDescription: templateIdentifier.templateDescription
                        };

                        return {
                            templateInstance: createTemplateInstance(devices[0], templateIdentifier),
                            templates: templates,
                            device: devices[0],
                            variables: variables
                        };
                    },
                    variableTypes: function () {
                        return ConfigRESTService.templateVariableTypes();
                    }
                }
            });
        };

        service.tryToOpenTemplateApplicationModal = function (device, variables, templateIdentifier, templateType) {
            TemplateInstanceService.validateDeviceForTemplate(device.id, templateIdentifier.templateId).then(
                function () {
                    var devices = [device];
                    if (TemplateActionsService.verifyRulesToOpenConfigModal(devices, templateType)) {
                        var params = [templateIdentifier, variables];
                        PermissionsActionsService.verifyDevicesPermission(
                            devices,
                            false,
                            openTemplateApplicationOneStepModal,
                            params
                        );
                    }
                }
            );
        };

        return service;
    }
]);
