/**
 * Service with List utility functions.
 *
 * @author icaro.damiani
 */
var app = angular.module("nms.dynamicDevice");

app.service("ListService", ["$filter", "DomainHandlerService", "YangStatements", "YangConventions", "LeafType",
    "RestrictionService",
    function($filter, DomainHandlerService, YangStatements, YangConventions, LeafType, RestrictionService) {
        this.updateKeysAsMandatory = function(list) {
            if (list.template && list.template.leaves) {
                if (list.keys) {
                    var leaves = _.where(list.template.leaves, function(leaf) {
                        return _.contains($filter("wrapInArray")(list.keys), leaf.id);
                    });

                    _.each(leaves, function(leaf) {
                        if (leaf && leaf["sub-statements"]) {
                            leaf["sub-statements"].mandatory = "true";
                        }
                    });
                }
            }
        };

        this.getKeyNames = function(list) {
            var keyProperty = list["sub-statements"].key;
            return keyProperty ? keyProperty.split(/\s+/) : [];
        };

        this.hasDuplicateEntry = function(listSchema, entries, newEntry) {
            var keys = this.getKeyNames(listSchema);

            var hasDuplicate = _.some(entries, function(entry) {
                return _.every(keys, function(key) {
                    return String(entry.leaves[key].value) === String(newEntry.leaves[key].value);
                });
            });

            return hasDuplicate;
        };

        this.getListKeyValues = function(leaves, keyNames) {
            var keyLeaves = _.pick(leaves, keyNames);
            return _.mapValues(keyLeaves, function(key) {
                return _.pick(key, "value");
            });
        };

        this.getKeyValuesAsString = function(leaves, keyNames) {
            var keyValues = this.getListKeyValues(leaves, keyNames);

            return _.pluck(keyValues, "value").join(" ");
        };

        this.getNonConfigurableChildren = function(list) {
            return this.getNonKeyChildren(list, false);
        };

        this.getConfigurableChildren = function(list) {
            return this.getNonKeyChildren(list, true);
        };

        this.getNonKeyChildren = function(list, configuration) {
            var listPath = DomainHandlerService.getPath(list);
            var children = DomainHandlerService.getDirectChildrenByConfigurationType(list, configuration);
            var keys = this.getKeyNames(list);

            children.leaves = _.filter(children.leaves, function(leafName) {
                return !_.includes(keys, leafName);
            });

            _.each(children, function(nodeSet, yangType) {
                if (shouldSort(yangType, configuration)) {
                    children[yangType] = $filter("orderBy")(nodeSet);
                }

                if (!configuration && !_.includes(YangConventions.LEAF_NODES, yangType)) {
                    children[yangType] = _.filter(children[yangType], function(nodeName) {
                        return !$filter("hasConfigurableChildren")(listPath + "/" + nodeName);
                    });
                }
            });

            return children;
        };

        this.shouldHaveActionsColumn = function(list, configuration) {
            var keys = this.getKeyNames(list);
            var emptyKeys = (keys.length === 0);

            return (configuration || emptyKeys);
        };

        this.countConfigurableAndNonConfigurableColumns = function(list, filters) {
            var allFilteredChildren = [];

            if (filters.showConfig) {
                var configurableNames = DomainHandlerService.getDirectChildrenNamesByConfigurationType(list, true);
                allFilteredChildren.push(configurableNames);
            }

            if (filters.showStatus) {
                var nonConfigurableNames = DomainHandlerService.getDirectChildrenNamesByConfigurationType(list, false);
                allFilteredChildren.push(nonConfigurableNames);
            }

            return _.uniq(_.flatten(allFilteredChildren)).length;
        };

        this.maxTableColumns = function(list, filters, configuration) {
            var total = 0;

            total += (configuration ? 1 : 0);
            total += this.countConfigurableAndNonConfigurableColumns(list, filters);
            total += (this.shouldHaveActionsColumn(list, configuration) ? 1 : 0);

            return total;
        };

        this.isIntegerKey = function(keyType) {
            return _.includes(_.union(LeafType.INTEGER, LeafType.UNSIGNED_INTEGER), keyType);
        };

        /**
        * Processa os dados para inserção ou edição dos itens da lista.
        *
        * keys {array} Lista das keys de um leave. Ex: ["key1", "key2"]
        * inputtedKeys {array} Mapa de keys a ser inserido/editado.
        *
        * entry {object} Representa o template (do yang) que define os itens da lista
        *         Ex.: {leaves:{}, containers: {}, lists:{}, leaflists:{}, choices:{}}
        *
        * formattedKeysObj {object} Representa as keys e valores a serem inseridos de maneira encadeada possibilitando inserir
        * todas as combinações possíveis.
        *    Ex: {
        *            key: "a",
        *            value: [1,2],
        *            nested: {
        *                key: "b",
        *                value: [3,4],
        *                nested: {
        *                    key: "c",
        *                    value: [5,6]
        *                }
        *            }
        *         }
        *    Irá inserir:
        *    key | a | b | c |
        *        | 1 | 3 | 5 |
        *        | 1 | 4 | 5 |
        *        | 1 | 3 | 6 |
        *        | 1 | 4 | 6 |
        *        | 2 | 3 | 5 |
        *        | 2 | 4 | 5 |
        *        | 2 | 3 | 6 |
        *        | 2 | 4 | 6 |
        */
        this.processEditOrAddMultipleItems = function(entry, keys) {
            var inputtedKeys = _.map(_.pick(entry.leaves, keys));
            var genericObject: any = {};
            var initialIndex = 0;
            var newEditedEntries = [];

            var formattedKeysObj = buildKeysObj(inputtedKeys, genericObject, initialIndex);

            buildNewEntry(formattedKeysObj, keys[0], entry, newEditedEntries);

            return newEditedEntries;
        };

        /**
        * Verifica se os elementos de um nodo devem ser ordenados ou não.
        * Leaves de status (config=false) não devem ser ordenados para que seja mantida a ordem original do Yang.
        *
        * @param {YangStatements} yangType tipo do atributo.
        * @param {boolean} isConfiguration indica se o processamento é referente a inclusão de campos de config ou status.
        * @return {object} true se a ordenação for necessária ou false caso contrário.
        */
        function shouldSort(yangType, isConfiguration) {
            return isConfiguration || YangStatements.LEAVES !== yangType;
        }

        /**
        * Cria as novas entradas e insere na lista newEditedEntries;
        *
        * @param {object} formattedKeysObj Keys formatadas de maneira encadeada.
        * @param {string} key Key da lista keys
        * @param {object} newEntry Template da list que receberá os dados da nova entrada.
        */
        function buildNewEntry(formattedKeysObj, key, newEntry?, newEditedEntries?) {
            if (formattedKeysObj.key === key) {
                var values = RestrictionService.buildArrayOfValues(formattedKeysObj.value);
                values.forEach(function(value) {
                    var newEntryCopy = angular.copy(newEntry);
                    var leaf = _.find(newEntryCopy.leaves, "id", key);
                    leaf.value = value;
                    if (!_.isEmpty(formattedKeysObj.nested)) {
                        buildNewEntry(formattedKeysObj.nested, formattedKeysObj.nested.key, newEntryCopy, newEditedEntries);
                    } else {
                        newEditedEntries.push(newEntryCopy);
                    }
                });
            }
        }


        /**
        * Monta um objeto de keys de forma encadeada
        *
        * @param {array} keys Keys com os valores inputtados na modal.
        * @param {object} newObj Objeto que receberá os dados das keys.
        * @param {int} index Índice da key no array 'keys'.
        * @return {object} Retorna o objeto que representa as keys encadeadas.
        */
        function buildKeysObj(keys, newObj, index?) {
            var key = keys[index];
            if (key) {
                index = index + 1;
                if (newObj.nested) {
                    newObj.nested = {
                        value: key.value,
                        key: key.id,
                        nested: {}
                    };
                    buildKeysObj(keys, newObj.nested, index);
                } else {
                    newObj = {
                        value: key.value,
                        key: key.id,
                        nested: {}
                    };
                    buildKeysObj(keys, newObj, index);
                }
            }

            return newObj;
        }
    }
]);
