import {
    AcsServerError,
    ApiNorthboundError,
    DatabaseError,
    DataCollectorError,
    DcbError,
    DeviceAccessError,
    ErrorCodeMap,
    NesError,
    NmsSecurityError,
    NmsTemplateError,
    NotificationServiceError,
    SchedulerError
} from "./error-response-handler.model";

export const NES_ERROR_NAME: ErrorCodeMap<NesError> = {
    [NesError.UNKNOWN_ERROR]                   : "UNKNOWN_ERROR",
    [NesError.DEVICE_ACCESS_ERROR]             : "DEVICE_ACCESS_ERROR",
    [NesError.DEVICE_COMMUNICATION_ERROR]      : "DEVICE_COMMUNICATION_ERROR",
    [NesError.CONFIGURATION_CHANGED_ERROR]     : "CONFIGURATION_CHANGED_ERROR",
    [NesError.NETWORK_ELEMENT_NOT_IDENTIFIED]  : "NETWORK_ELEMENT_NOT_IDENTIFIED",
    [NesError.DEVICE_LOCKED]                   : "DEVICE_LOCKED",
    [NesError.DEVICE_AUTHENTICATION_ERROR]     : "DEVICE_AUTHENTICATION_ERROR",
    [NesError.UNEXPECTED_DEVICE_BEHAVIOR]      : "UNEXPECTED_DEVICE_BEHAVIOR",
    [NesError.MONGO_ACCESS_ERROR]              : "MONGO_ACCESS_ERROR",
    [NesError.INVENTORY_UNAVAILABLE_ERROR]     : "INVENTORY_UNAVAILABLE_ERROR",
    [NesError.DISCOVERY_ERROR]                 : "DISCOVERY_ERROR",
    [NesError.DEVICE_CONFIGURATION_ERROR]      : "DEVICE_CONFIGURATION_ERROR",
    [NesError.NETWORK_ELEMENT_NOT_FOUND_IN_DB] : "NETWORK_ELEMENT_NOT_FOUND_IN_DB",
    [NesError.TIMEOUT_ERROR]                   : "TIMEOUT_ERROR",
    [NesError.PARSER_ERROR]                    : "PARSER_ERROR",
    [NesError.UNKNOW_DEVICE_ERROR]             : "UNKNOW_DEVICE_ERROR",
    [NesError.FIRMWARE_VERSION_ERROR]          : "FIRMWARE_VERSION_ERROR",
    [NesError.ACCESS_DENIED_ERROR]             : "ACCESS_DENIED_ERROR"
};

export const DEVICE_ACCESS_ERROR_NAME: ErrorCodeMap<DeviceAccessError> = {
    [DeviceAccessError.GENERIC_DEVICE_ERROR] : "GENERIC_DEVICE_ERROR",
    [DeviceAccessError.UNREACHABLE_DEVICE]   : "UNREACHABLE_DEVICE"
}

export const DATABASE_ERROR_NAME: ErrorCodeMap<DatabaseError> = {
    [DatabaseError.DATABASE_ERROR]       : "DATABASE_ERROR",
    [DatabaseError.DEVICE_ALREADY_ADDED] : "DEVICE_ALREADY_ADDED"
}

export const DATA_COLLECTOR_ERROR_NAME: ErrorCodeMap<DataCollectorError> = {
    [DataCollectorError.TOO_MANY_RESULTS_FOUND] : "TOO_MANY_RESULTS_FOUND"
}

export const ACS_SERVER_ERROR_NAME: ErrorCodeMap<AcsServerError> = {
    [AcsServerError.ACS_CONNECTION_REQUEST_ERROR]                : "ACS_CONNECTION_REQUEST_ERROR",
    [AcsServerError.ACS_BAD_CONDITIONAL_COMMAND_ERROR]           : "ACS_BAD_CONDITIONAL_COMMAND_ERROR",
    [AcsServerError.ACS_COULD_NOT_ACCESS_SERVER_ERROR]           : "ACS_COULD_NOT_ACCESS_SERVER_ERROR",
    [AcsServerError.ACS_CONNECTION_REQUEST_AUTHENTICATION_ERROR] : "ACS_CONNECTION_REQUEST_AUTHENTICATION_ERROR",
    [AcsServerError.ACS_CPE_MESSAGE_TIMEOUT]                     : "ACS_CPE_MESSAGE_TIMEOUT",
    [AcsServerError.ACS_UNEXPECTED_ERROR]                        : "ACS_UNEXPECTED_ERROR",
    [AcsServerError.UNEXPECTED_CPE_MESSAGE]                      : "UNEXPECTED_CPE_MESSAGE"
}

export const NMS_SECURITY_ERROR_NAME: ErrorCodeMap<NmsSecurityError> = {
    [NmsSecurityError.INVALID_CAPTCHA] : "INVALID_CAPTCHA"
}

export const NOTIFICATION_SERVICE_ERROR_NAME: ErrorCodeMap<NotificationServiceError> = {
    [NotificationServiceError.INVALID_NOTIFICATION_PREFERENCES_ERROR] : "INVALID_NOTIFICATION_PREFERENCES_ERROR"
}

export const SCHEDULER_ERROR_NAME: ErrorCodeMap<SchedulerError> = {
    [SchedulerError.JOB_NOT_FOUND_ERROR]                              : "JOB_NOT_FOUND_ERROR",
    [SchedulerError.SCHEDULER_UNEXPECTED_ERROR]                       : "SCHEDULER_UNEXPECTED_ERROR",
    [SchedulerError.INVALID_CRON_EXPRESSION_ERROR]                    : "INVALID_CRON_EXPRESSION_ERROR",
    [SchedulerError.SCHEDULER_REMOVAL_ERROR]                          : "SCHEDULER_REMOVAL_ERROR",
    [SchedulerError.JOB_EXECUTION_ERROR]                              : "JOB_EXECUTION_ERROR",
    [SchedulerError.USER_WITHOUT_SCHEDULER_PERMISSION_ERROR]          : "USER_WITHOUT_SCHEDULER_PERMISSION_ERROR",
    [SchedulerError.USER_WITHOUT_JOB_TYPE_SCHEDULER_PERMISSION_ERROR] : "USER_WITHOUT_JOB_TYPE_SCHEDULER_PERMISSION_ERROR",
    [SchedulerError.USER_WITHOUT_CREATE_SCHEDULER_PERMISSION_ERROR]   : "USER_WITHOUT_CREATE_SCHEDULER_PERMISSION_ERROR",
    [SchedulerError.INVALID_BACKUP_DEVICE_MODEL_SCHEDULER_ERROR]      : "INVALID_BACKUP_DEVICE_MODEL_SCHEDULER_ERROR"
}

export const NMS_TEMPLATE_ERROR_NAME: ErrorCodeMap<NmsTemplateError> = {
    [NmsTemplateError.DEVICE_WITHOUT_MANAGE_PERMISSION_ERROR]            : "DEVICE_WITHOUT_MANAGE_PERMISSION_ERROR",
    [NmsTemplateError.AUTO_SELECTION_VARIABLE_VALUE_NOT_AVAILABLE_ERROR] : "AUTO_SELECTION_VARIABLE_VALUE_NOT_AVAILABLE_ERROR",
    [NmsTemplateError.AUDIT_SETTINGS_VALIDATION_ERROR]                   : "AUDIT_SETTINGS_VALIDATION_ERROR"
}

export const DCB_ERROR_NAME: ErrorCodeMap<DcbError> = {
    [DcbError.UNKNOWN_DCB_ERROR]                 : "UNKNOWN_DCB_ERROR",
    [DcbError.UNSUPPORTED_DCB_FILES_ERROR]       : "UNSUPPORTED_DCB_FILES_ERROR",
    [DcbError.DEVICE_COMMUNICATION_DCB_ERROR]    : "DEVICE_COMMUNICATION_DCB_ERROR",
    [DcbError.CONFIG_CHANGED_DCB_ERROR]          : "CONFIG_CHANGED_DCB_ERROR",
    [DcbError.DEVICE_NOT_IDENTIFIED_DCB_ERROR]   : "DEVICE_NOT_IDENTIFIED_DCB_ERROR",
    [DcbError.DEVICE_LOCKED_DCB_ERROR]           : "DEVICE_LOCKED_DCB_ERROR",
    [DcbError.DEVICE_AUTHENTICATION_DCB_ERROR]   : "DEVICE_AUTHENTICATION_DCB_ERROR",
    [DcbError.DATABASE_ACCESS_DCB_ERROR]         : "DATABASE_ACCESS_DCB_ERROR",
    [DcbError.SERVICE_UNAVAILABLE_DCB_ERROR]     : "SERVICE_UNAVAILABLE_DCB_ERROR",
    [DcbError.DEVICE_CONFIGURATION_DCB_ERROR]    : "DEVICE_CONFIGURATION_DCB_ERROR",
    [DcbError.FIRST_POLLING_FAILED_DCB_ERROR]    : "FIRST_POLLING_FAILED_DCB_ERROR",
    [DcbError.FIRMWARE_VERSION_DCB_ERROR]        : "FIRMWARE_VERSION_DCB_ERROR",
    [DcbError.ACCESS_DENIED_DCB_ERROR]           : "ACCESS_DENIED_DCB_ERROR",
    [DcbError.DEVICE_CONFIG_PARSER_DCB_ERROR]    : "DEVICE_CONFIG_PARSER_DCB_ERROR",
    [DcbError.CORRUPTED_FILE_DCB_ERROR]          : "CORRUPTED_FILE_DCB_ERROR",
    [DcbError.PROTECTED_DCB_ERROR]               : "PROTECTED_DCB_ERROR",
    [DcbError.DCB_NOT_FOUND]                     : "DCB_NOT_FOUND",
    [DcbError.EXPORT_CONFIG_PARSER_DCB_ERROR]    : "EXPORT_CONFIG_PARSER_DCB_ERROR",
    [DcbError.POLLER_COMMUNICATION_DCB_ERROR]    : "POLLER_COMMUNICATION_DCB_ERROR",
    [DcbError.DCB_RETENTION_CONFIGURATION_ERROR] : "DCB_RETENTION_CONFIGURATION_ERROR",
    [DcbError.DCB_MODEL_OPERATION_ERROR]         : "DCB_MODEL_OPERATION_ERROR",
};

export const API_NORTHBOUND_ERROR_NAME: ErrorCodeMap<ApiNorthboundError> = {
    [ApiNorthboundError.BAD_REQUEST_ERROR]     : "BAD_REQUEST_ERROR",
    [ApiNorthboundError.DATA_NOT_FOUND_ERROR]  : "DATA_NOT_FOUND_ERROR",
    [ApiNorthboundError.DUPLICATED_DATA_ERROR] : "DUPLICATED_DATA_ERROR",
    [ApiNorthboundError.VALIDATION_ERROR]      : "VALIDATION_ERROR",
    [ApiNorthboundError.FORBIDDEN_ERROR]       : "FORBIDDEN_ERROR",
    [ApiNorthboundError.INTERNAL_SERVER_ERROR] : "INTERNAL_SERVER_ERROR",
    [ApiNorthboundError.VARIABLE_VALUES_ERROR] : "VARIABLE_VALUES_ERROR"
};