import { Injectable } from "@angular/core";

import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";
import {
    AbstractErrorResponseHandlerService
} from "@nms-ng2/app/core/services/error-response-handler/abstract-error-response-handler";
import {
    DcbError,
    ErrorDescriptionDetails,
    ErrorResponse,
} from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";
import { SCHEDULER_ERROR_NAME } from "@nms-ng2/app/core/services/error-response-handler/error-names.constant";

/**
 * Serviço para lidar com mensagens de erros de Scheduler recebidas através de um @see ErrorResponse.
 */
@Injectable({
    providedIn: "root"
})
export class SchedulerErrorResponseHandlerService extends AbstractErrorResponseHandlerService {

    private readonly LOCALE_PREFIX: string = "scheduler.response.error.";

    constructor(private readonly translationHelper: TranslationHelperService) {
        super(DcbError);
    }

    public buildErrorDescriptionDetails(errorResponse: ErrorResponse,
                                        detailsSeparator: string = "<br>",
                                        status?: number): ErrorDescriptionDetails {
        let { code, details } = errorResponse;
        let erroName = SCHEDULER_ERROR_NAME[code];

        if (!erroName) {
            throw new Error(`Unknown Scheduler error code ${code}`);
        }

        return {
            description: this.translationHelper.translateByPrefix(this.LOCALE_PREFIX, erroName),
            details: this.buildErrorDetails(code, details)
        };
    }

    public buildErrorDetails(code: number, details: Array<string>): string {
        let detailsMessage = "";

        if (details && details.length > 0) {
            detailsMessage = details.join(this.getDefaultSeparator());
        }

        return detailsMessage;
    }
}
