"use strict";
import { NmsTableColumnType } from "../nms-table-old/nms-table-models";

/**
* @ngdoc controller
* @name nms.components.ui.table:CustomizeTableColumnsController
* @description
* # CustomizeTableColumnsController
* Classe de controle para a diretiva de customização de colunas.
*/
var app = angular.module("nms");

app.controller("CustomizeTableColumnsController", ["$rootScope", "$scope", "$translate", "UserPreferencesService",
    function($rootScope, $scope, $translate, UserPreferencesService) {
        $scope.tooltip = $translate.instant("tooltips.customizeColumns");
        $scope.selection = {
            selectAllCheckbox: false,
            indeterminate: false
        };

        var updateUserPreferences = function() {
            UserPreferencesService.savePreferences($scope.tableModel, $scope.id, ["columns"]);
        };

        $scope.sortableOptions = {
            scroll: true,
            draggable: ".draggable",
            handle: ".drag-handle",
            onUpdate: function() {
                $rootScope.$broadcast("tableColumnsHasChange", false);
                updateUserPreferences();
            }
        };

        var init = function() {
            loadDefaultValues();
            loadStoredValues();
            isAllStillSelected();
        };

        var loadDefaultValues = function() {
            var defaultOptions: any = {
                show: true,
                editable: true,
                hidden: false,
                selection: true,
                sortable: true,
                filter: true,
                type: NmsTableColumnType.DEFAULT
            };

            _.defaults($scope.tableModel, {options: {}});
            var options = $scope.tableModel.options;
            _.defaults(options, defaultOptions);

            var getTitle = function(field) {
                if (!_.isUndefined(options.translatePrefix)) {
                    return $translate.instant(options.translatePrefix + "." + field);
                }
                return field;
            };

            _.forEach($scope.tableModel.columns, function(column) {
                var defaultProperties: any = {
                    title: getTitle(column.field),
                    show: options.show,
                    editable: options.editable,
                    hidden: options.hidden
                };
                _.defaults(column, defaultProperties);
                if (options.sortable && _.isUndefined(column.sortable)) {
                    column.sortable = column.field;
                }
                if (column.tipiconTranslateKey) {
                    column.headerTemplateURL = "templates/components/ui/table/custom-table-column-header-tipicon.html";
                }
                if (options.filter && _.isUndefined(column.filter)) {
                    column.filter = {};
                    column.filter[column.field] = "text";
                }
            });

            if (options.selection) {
                $scope.tableModel.columns.unshift({
                    field: "selector",
                    title: "",
                    class: "col-selection-size",
                    headerTemplateURL: "templates/components/ui/selection-checkbox/selection-checkbox-header.html",
                    show: true,
                    editable: false,
                    hidden: true,
                    type: NmsTableColumnType.SELECTOR,
                    filter: { operatorType: "/templates/components/ui/table/operator-type-filter.html" }
                });
            }

            if (options.actions) {
                $scope.tableModel.columns.push({
                    field: "table-actions",
                    title: $translate.instant("customizeColumns.columns.actions"),
                    show: true,
                    editable: false,
                    hidden: false
                });
            }

            if ($scope.tableModel.columns.some(column => column.field === "selector")) {
                angular.extend($scope.tableModel.tableParams.filter(), {operatorType: "AND"});
            }
        };

        var loadStoredValues = function() {
            var storedColumns = UserPreferencesService.loadPreferences({}, $scope.id, ["columns"]);

            if (storedColumns.columns && isSameColumns(storedColumns.columns, $scope.tableModel.columns)) {
                loadStoredColumns(storedColumns.columns);
            }
            $rootScope.$broadcast("tableColumnsHasChange", false);
        };

        /**
        * Verifica se as preferências das colunas persistidas são compatíveis com as atuais definições de colunas.
        */
        var isSameColumns = function(storedColumns, tableColumns) {
            if (storedColumns.length !== tableColumns.length) {
                return false;
            }

            var hasAllItens = _.all(storedColumns, function(storedColumn) {
                var columnToSearch = _.pick(storedColumn, "field", "editable", "filter", "sortable");
                var hasItem = _.some(tableColumns, columnToSearch);

                return hasItem;
            });

            return hasAllItens;
        };

        /*
         * É necessário substituir cada coluna na tabela pela coluna salva na storage,
         * esse comportamento é necessário porque sobrescrever toda a lista altera a referência utilizada pela diretiva
         * ng-table evitando que a mesma possa atualizar suas colunas de forma correta.
         * Colunas que não estão marcadas como editaveis utilizam sempre os valores default.
         */
        var loadStoredColumns = function(storedColumns) {
            for (var i = 0; i < storedColumns.length; i++) {
                if ($scope.tableModel.columns[i].editable) {
                    $scope.tableModel.columns[i] = storedColumns[i];
                }
            }
        };

        var isAllStillSelected = function() {
            if (_.some($scope.tableModel.columns, "show", false)) {
                if (_.every($scope.tableModel.columns, "show", false)) {
                    $scope.selection.selectAllCheckbox = false;
                    $scope.selection.indeterminate = false;
                } else {
                    $scope.selection.indeterminate = true;
                }
            } else {
                $scope.selection.selectAllCheckbox = true;
                $scope.selection.indeterminate = false;
            }
        };

        $scope.checkUncheckAll = function() {
            $scope.selection.indeterminate = false;
            _.forEach($scope.tableModel.columns, function(column) {
                if (column.editable) {
                    column.show = $scope.selection.selectAllCheckbox;
                }
            });
            updateUserPreferences();
            $rootScope.$broadcast("tableColumnsHasChange", true);
        };

        $scope.columnChanged = function(column) {
            var tableParams = $scope.tableModel.tableParams ? $scope.tableModel.tableParams.parameters() : null;
            if (tableParams) {
                tableParams.filter = _.omit(tableParams.filter, column.field);
            }
            isAllStillSelected();
            updateUserPreferences();
            $rootScope.$broadcast("tableColumnsHasChange", true);
        };

        init();
    }
]);
