"use strict";

/**
* @ngdoc directive
* @name nms.directive:ignoreDirty
* @description Diretiva para fazer com que inputs não sejam marcados como dirty dentro de formulários.
*/
var app = angular.module("nms");

app.directive("ignoreDirty", function() {
    return {
        restrict: "A",
        require: "ngModel",
        link: function(scope, elm, attrs, ngModelCtrl) {
            ngModelCtrl.$setDirty = angular.noop;
        }
    };
});
