import { Injectable, Inject } from "@angular/core";
import {
    ANGULARJS_ROOTSCOPE,
    ANGULARJS_TRANSLATE,
    STATE,
    NMS_STATES,
    CONNECTIVITY_TEST_STATUS
} from "../upgraded-provider/upgraded-providers";
import { ModalFactory } from "../modal/modal.factory";
import { RebootService } from "@nms-ng2/app/modules/device/cpes/reboot/reboot.service";
import { PermissionsActionsService } from "./permissions-actions.service";
import {
    CwmpConnectivityTestModalComponent
} from "../../components/modals/cwmp-connectivity-test/cwmp-connectivity-test-modal.component";
import { DownloadComponent } from "@nms-ng2/app/modules/device/cpes/download/download.component";
import { AcsCredentialsModalComponent } from "../../components/modals/acs-credentials-modal/acs-credentials-modal.component";

/**
 * Serviço que engloba ações relacionadas a CPEs, relacionadas a atalhos de dropdown.
 * Exemplos: abertura de modais, da página de parâmetros e ações de download e reboot.
 */
@Injectable({
    providedIn: "root"
})
export class CpesActionsService {
    constructor(
        @Inject(ANGULARJS_ROOTSCOPE) private readonly rootScope: any,
        @Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
        @Inject(ModalFactory) private readonly modalFactory: ModalFactory,
        @Inject(STATE) private readonly state: any,
        @Inject(NMS_STATES) private readonly nmsStates: any,
        @Inject(CONNECTIVITY_TEST_STATUS) private readonly connectivityStatus: any,
        private readonly rebootService: RebootService,
        private readonly permissionsActionsService: PermissionsActionsService
    ) {}

    /**
     * O parâmetro "param" pode assumir o valor de um objeto quando usado por um elemento da lista de onus ou uma lista de
     * objetos quando acessado via dropdown padrão no qual se leva em consideração a lista de seleção da tabela.
     */
    async tryToOpenConnectivityTestModalForCpes(param) {
        const cpes = _.isArray(param) ? param : [param];
        const unregisteredCpes = cpes.filter((device) => _.isEmpty(device.cwmpCpeLastInform));

        if (this.areThereSelectedCpes(cpes) && (await this.validateCwmpCpeLastInformField(cpes, unregisteredCpes))) {
            _.remove(cpes, (cpe) => unregisteredCpes.includes(cpe));
            this.rootScope.$digest();
            const translateStatus = this.translate.instant("connectivityTest.protocolstatus.RUNNING");
            const cwmps = cpes.map((cpe) => ({
                serialNumber: cpe.serialNumber,
                equipmentId: cpe.equipmentId,
                cwmpCpeIp: cpe.cwmpCpeIp,
                percentage: 0,
                icmpTranslated: translateStatus,
                tr069Translated: translateStatus,
                icmp: {
                    status: this.connectivityStatus.RUNNING
                },
                tr069: {
                    status: this.connectivityStatus.RUNNING
                }
            }));
            this.modalFactory.openAsyncModal(CwmpConnectivityTestModalComponent, { cwmps });
        }
    }

    /**
     * O parâmetro "param" pode assumir o valor de um objeto quando usado por um elemento da lista de onus ou uma lista de
     * objetos quando acessado via dropdown padrão no qual se leva em consideração a lista de seleção da tabela.
     */
    tryToCredentialsModalCpes(param) {
        const cpes = _.isArray(param) ? param : [param];
        if (this.permissionsActionsService.isUserAdministrator() && this.areThereSelectedCpes(cpes)) {
            const cpesSelected = cpes.map((cpe) => ({
                serialNumber: cpe.serialNumber
            }));
            this.modalFactory.openAsyncModal(AcsCredentialsModalComponent, { cpesSelected });
        }
    }

    /**
     * O parâmetro "param" pode assumir o valor de um objeto quando usado por um elemento da lista de cpes ou uma lista de
     * objetos quando acessado via dropdown padrão no qual se leva em consideração a lista de seleção da tabela.
     */
    tryToOpenParametersModalForCpes(param) {
        const cpes = _.isArray(param) ? param : [param];
        if (this.canOpenParametersModal(cpes)) {
            const { serialNumber } = cpes[0];
            this.goToParameters(serialNumber);
        }
    }

    async tryToOpenDownloadRequestModal(selected) {
        const cpes = _.isArray(selected) ? selected : [selected];
        const unregisteredCpes = cpes.filter((device) => _.isEmpty(device.cwmpCpeLastInform));

        if (await this.validateCwmpCpeLastInformField(cpes, unregisteredCpes)) {
            this.openDownloadRequestModal(_.difference(cpes, unregisteredCpes));
        }
    }

    openDownloadRequestModal(selected) {
        const cpes = _.isArray(selected) ? selected : [selected];
        if (this.areThereSelectedCpes(cpes)) {
            const cpesSelected = cpes.map((cpe) => ({
                serialNumber: cpe.serialNumber
            }));
            this.modalFactory.openAsyncModal(DownloadComponent, {cpesSelected: Object.values(cpesSelected)});
        }
    }

    async tryToRebootCpes(selected) {
        const cpes = _.isArray(selected) ? selected : [selected];
        const unregisteredCpes = cpes.filter((device) => _.isEmpty(device.cwmpCpeLastInform));

        if (await this.validateCwmpCpeLastInformField(cpes, unregisteredCpes)) {
            this.rebootCpes(_.difference(cpes, unregisteredCpes));
        }
    }

    rebootCpes(selected) {
        const cpes = _.isArray(selected) ? selected : [selected];

        if (this.areThereSelectedCpes(cpes)) {
            let translateKey = "reboot.one.cpe.confirm.message";

            if (cpes.length > 1) {
                translateKey = "reboot.multiples.cpes.confirm.message";
            }

            this.rootScope
                .showDialog({
                    translateKey,
                    isConfirm: true
                })
                .then(() => {
                    const serialNumbers = cpes.map((cpe) => cpe.serialNumber);
                    this.rebootService.sendRebootRequest(serialNumbers);
                });
        }
    }

    private canOpenParametersModal(cpes): boolean {
        return this.areThereSelectedCpes(cpes) && this.validadeSelectedCpeToUseInParametersModal(cpes);
    }

    private areThereSelectedCpes(checked) {
        if (_.isEmpty(checked)) {
            this.rootScope.showDialog({
                translateKey: "gpon.onus.tableActions.noSelectOnuError"
            });
            return false;
        }
        return true;
    }

    private async validateCwmpCpeLastInformField(onus, unregisteredOnus) {
        let isValid = true;
        if (onus.length === unregisteredOnus.length) {
            this.rootScope.showDialog({ translateKey: "gpon.onus.tableActions.noOnuRegisteredError" });
            isValid = false;
        } else if (!_.isEmpty(unregisteredOnus)) {
            try {
                await this.rootScope.showDialog({
                    translateKey: "gpon.onus.tableActions.removeNoRegisteredOnusConfirmMessage",
                    params: [unregisteredOnus.length, onus.length],
                    paramsInsideMessage: true,
                    isConfirm: true
                });
            } catch (err) {
                isValid = false;
            }
        }
        return isValid;
    }

    private validadeSelectedCpeToUseInParametersModal(cpes) {
        let isValid = true;
        if (cpes.length > 1) {
            this.rootScope.showDialog({ translateKey: "gpon.onus.tableActions.multiOnusSelectedError" });
            isValid = false;
        } else if (_.isEmpty(cpes[0].cwmpCpeLastInform)) {
            this.rootScope.showDialog({ translateKey: "gpon.onus.tableActions.selectedOnuIsNotRegisteredError" });
            isValid = false;
        }

        return isValid;
    }

    private goToParameters(serialNumber) {
        this.state.go(this.nmsStates.cwmpParameters, { serialNumber });
    }
}
