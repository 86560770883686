var app = angular.module("nms.dynamicDevice");

app.controller("LeafListController", ["$rootScope", "$scope", "$filter", "RestrictionService", "Validator", "ListService",
    "DomainHandlerService", "DataPathService", "LeafService", "NodeStatusService", "WhenVerifierService",
    function($rootScope, $scope, $filter, RestrictionService, Validator, ListService, DomainHandlerService, DataPathService,
        LeafService, NodeStatusService, WhenVerifierService) {
        var controller = this;
        var unregisterFunctions = [];
        $scope.readOnly = $rootScope.readOnly;
        var maxScrollessItens = 10;
        var uiScrollPosition;
        var isMultipleValuesLeafList;
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.leaflist);
        $scope.isNodeVisible = !hasWhenCondition;

        var reloadDataNode = function() {
            var dataNode = DomainHandlerService.getDataNode($scope.pathWithKeys);
            if (dataNode) {
                $scope.dataNode = dataNode;
            } else {
                $scope.dataNode = {
                    id: $scope.leaflist.id,
                    values: []
                };
            }
        };

        $scope.initLeafList = function() {
            if (!$scope.pathKeys && $scope.getPathKeys) {
                $scope.pathKeys = $scope.getPathKeys();
            }

            if ($scope.isTransient) {
                $scope.dataNode = $scope.leaflist;
            } else {
                $scope.pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.leaflist);
                reloadDataNode();

                if ($scope.config) {
                    var unregister = $rootScope.$on("reloadContentNodeOf=" + $scope.pathWithKeys, function() {
                        reloadDataNode();
                    });

                    unregisterFunctions.push(unregister);
                }
            }

            var leaflist = $scope.leaflist;

            isMultipleValuesLeafList = checkMultipleValuesLeafList(leaflist);
            $scope.parentPath = leaflist.parentPaths.schemaJsonPath;
            $scope.list = leaflist;
            $scope.leaf = {
                id: leaflist.id,
                name: leaflist.name,
                "sub-statements": leaflist["sub-statements"],
                parentPaths: {
                    schemaJsonPath: $scope.parentPath
                },
                type: leaflist.type,
                shouldEdit: leaflist.shouldEdit,
                referencedLeaf: leaflist.referencedLeaf,
                leafrefXPath: leaflist.leafrefXPath,
                paths: leaflist.paths
            };

            if (!$scope.dataNode.values) {
                $scope.dataNode.values = [];
            } else if (!_.isArray($scope.dataNode.values)) {
                $scope.dataNode.values = $filter("wrapInArray")($scope.dataNode.values);
            }

            setPossibleValues();
        };

        /**
        * Verify if the specified leaf-list is a multiple value leaf-list.
        *
        * Example: enumeration.
        *
        * @param {object} leaflist Leaf-list to check.
        * @return {boolean}.
        */
        function checkMultipleValuesLeafList(leaflist) {
            var multipleValuesTypes = ["enumeration", "bits"];

            return _.contains(multipleValuesTypes, leaflist.type.name);
        }

        /**
        * Create a array with available values to being added to leaflist.
        *
        * @return {string[]} array with possible values.
        */
        function setPossibleValues() {
            $scope.leaf.possibleValues = _.difference(
                LeafService.getPossibleValues($scope.leaflist),
                $scope.dataNode.values);
        }

        $scope.canAddNewValues = function() {
            if (isMultipleValuesLeafList) {
                return ($scope.leaf.possibleValues.length !== 0);
            }

            return !RestrictionService.hasErrorsOnField($scope.leaflist.id, $scope.form);
        };

        var spliceDataNodeValues = function(index, length) {
            $scope.dataNode.values.splice(index, length);
            if (_.isEmpty($scope.dataNode.values)) {
                $scope.dataNode.values = [];
            }
        };

        /**
         * A diretiva min-elements-restriction não estava sendo notificada da mudança no ngModel quando feito o splice do último
         * elemento do array "dataNode.values", o que o deixava vazio.
         *
         * Atribuir o array vazio quando o array fica empty resolve o problema, mas não sei qual a razão do splice não funcionar.
         * */
        $scope.removeValue = function(index) {
            storeUiScrollPosition();
            spliceDataNodeValues(index, 1);
            $rootScope.$broadcast("CONTENT_MODIFIED", true);
            $rootScope.$broadcast("leaflistDataNodeChanged");
            setPossibleValues();
        };

        $scope.newValue = function(valueToInsert) {
            var exclude = [Validator.MAX_ELEMENTS_VALIDATOR, Validator.MIN_ELEMENTS_VALIDATOR];

            if (!RestrictionService.hasErrorsOnField($scope.leaflist.id, $scope.form, exclude) && valueToInsert) {
                var acceptValueRanges = ListService.isIntegerKey(DomainHandlerService.getBaseType($scope.leaflist.type).name);

                if (!$scope.dataNode.values) {
                    $scope.dataNode.values = [];
                }

                if (acceptValueRanges) {
                    insertLeafListValues($scope.dataNode, valueToInsert);
                } else {
                    $scope.dataNode.values.push(valueToInsert.slice());
                    $scope.dataNode.values = $filter("orderByNumbersAndLetters")(_.uniq($scope.dataNode.values));
                }

                $scope.leaf.value = "";
                $rootScope.$broadcast("CONTENT_MODIFIED", true);
                $rootScope.$broadcast("leaflistDataNodeChanged");
            }

            setPossibleValues();
        };

        $scope.removeValues = function() {
            $rootScope.showDialog({
                translateKey: "yang.leaf.leaflist.modal.remove.message",
                isConfirm: true
            }).then(function() {
                uiScrollPosition = undefined;
                spliceDataNodeValues(0, $scope.dataNode.values.length);
                $rootScope.$broadcast("CONTENT_MODIFIED", true);
                $rootScope.$broadcast("leaflistDataNodeChanged");
                setPossibleValues();
            });
        };

        $scope.buildCustomTooltipText = function(value) {
            if (angular.isArray(value)) {
                return value.join(", ");
            }

            return value;
        };

        $scope.leaflistDataSource = {
            get: function(index, count, success) {
                var arrayIndex = index - 1;
                var sliceIndex = arrayIndex < 0 ? 0 : arrayIndex;
                var sliceCount = arrayIndex + count;
                var items = $scope.dataNode.values.slice(sliceIndex, sliceCount);

                success(items);
            }
        };

        $scope.changeLeaflistEditing = function() {
            $scope.leaflist.shouldEdit = $scope.leaf.shouldEdit;
        };

        $scope.showUiScroll = function() {
            return _.get($scope.dataNode, "values.length", 0) > maxScrollessItens;
        };


        if ($scope.config) {
            $scope.$watch("dataNode.values.length", function(newValue, oldValue) {
                if (!$scope.isTransient && $scope.watchChanges !== false) {
                    if ($scope.dataNode.values.length === 0) {
                        DomainHandlerService.deleteDataNodeByJsonPath($scope.pathWithKeys);
                    } else {
                        DomainHandlerService.addOrSetDataNode($scope.pathWithKeys + ".values", $scope.dataNode.values);
                    }
                }
                setPossibleValues();
                reloadScroll();
            });
        }

        function storeUiScrollPosition() {
            var topVisible = _.get(controller, "scrollAdapter.topVisible");
            uiScrollPosition = topVisible ? $scope.dataNode.values.indexOf(topVisible) + 1 : undefined;
        }

        function insertLeafListValues(dataNode, rangesToInsert) {
            var valuesToInsert = RestrictionService.buildArrayOfValues(rangesToInsert);
            var dataNodeValueCopy = Object.assign({}, dataNode.values);
            var values = _.uniq(_.union(dataNodeValueCopy, valuesToInsert));

            dataNode.values = $filter("orderByNumbersAndLetters")(values);
        }

        function reloadScroll() {
            if ($scope.showUiScroll() && angular.isFunction(_.get(controller, "scrollAdapter.reload"))) {
                if (uiScrollPosition) {
                    controller.scrollAdapter.reload(uiScrollPosition);
                } else {
                    controller.scrollAdapter.reload();
                }
            }
        }

        if (hasWhenCondition && !$scope.insideModal) {
            var unregisterNodeStatusListener = NodeStatusService.register(
                $scope.leaflist.paths.schemaJsonPath,
                $scope.leaflist.paths.dataJsonPath,
                $scope.pathKeys,
                function(nodeStatus, refreshData) {
                    $scope.isNodeVisible = nodeStatus.isVisible;
                    if (refreshData) {
                        reloadDataNode();
                    }
                });

            unregisterFunctions.push(unregisterNodeStatusListener);
        } else {
            $scope.isNodeVisible = true;
        }

        $scope.$on("$destroy", function() {
            unregisterFunctions.forEach(_.attempt);
        });

        $scope.initLeafList();
    }
]);
