/**
* @ngdoc directive
* @name nms.templates.directive:variablePower
* @description Diretiva para realizar o calculo das potências e validar seus valores em inputs.
*/
app.directive("variablePower", function() {
    return {
        require: "ngModel",
        priority: 100,
        link: function(scope, elem, attr, ngModel) {
            var power = attr.variablePower;

            if (!_.isEmpty(power)) {
                ngModel.$validators.validPower = function(value) {
                    var logResult = Math.log(value)/ Math.log(power);
                    return _.isEmpty(value) || logResult === Math.floor(logResult);
                };
            }
        }
    };
});
