"use strict";

/**
*   @ngDoc          directive
*   @name           nms.confirmPasswordValidator
*   @description    Verifica se os campos senha e confirmação de senha são iguais.
*/
var app = angular.module("nms");
app.directive("confirmPasswordValidator",
    function() {
        return {
            require: "ngModel",
            restrict: "A",
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.confirmPassword = function(modelValue, viewValue) {
                    if (scope.useConfirmation && !_.isUndefined(scope.confirmModel) && !_.isNull(scope.confirmModel)) {
                        scope.isValid = modelValue === scope.confirmModel;
                    } else {
                        scope.isValid = true;
                    }
                    return scope.isValid;
                };

                scope.$watch("confirmModel", function() {
                    ctrl.$validate();
                });
            }
        };
    }
);
