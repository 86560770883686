"use strict";

/**
* @ngdoc service
* @name nms.templates.VariableService
* @description
* # VariableService
* Factory in the nms.templates.
*/
var app = angular.module("nms.templates");
app.service("VariableValidatorService", ["$rootScope", "TemplateRESTService", "TextVariableValidatorService",
    "IntegerVariableValidatorService", "SelectionVariableValidatorService", "VARIABLES", "ValidatorService",
    "ReadOnlyVariableValidatorService", "ActionVariableValidatorService",
    function($rootScope, TemplateRESTService, TextVariableValidatorService, IntegerVariableValidatorService,
    SelectionVariableValidatorService, VARIABLES, ValidatorService, ReadOnlyVariableValidatorService,
    ActionVariableValidatorService) {
        const service: any = {};

        /**
         * Padrão de nome de variável válido para versões do Freemarker < 2.3.22.
         * http://freemarker.org/docs/dgui_template_exp.html#dgui_template_exp_var_toplevel
         */
        var variableNameRegex = XRegExp("^(\\p{L}|[_$@])(\\p{L}|\\p{N}|[_$@])*$");

        var hasIncludeEnabled = function(includeAndBlockOptionMode) {
            return includeAndBlockOptionMode === VARIABLES.includeAndBlockOptionsModes.INCLUDE
                || includeAndBlockOptionMode === VARIABLES.includeAndBlockOptionsModes.INCLUDE_AND_BLOCK;
        };

        var isSelectionType = function(type) {
            return type === "SELECTION" || type === "SELECTION_LIST";
        };

        service.validateVariable = function (variablesNamesAlreadyAdded, checkedVariable,
            hasVisualizationCommandsCli, hasInterval, hasStep, hasBaseExponent) {
            // Commons Fields Validation
            if (!checkedVariable.name) {
                ValidatorService.showAlertBasicTab("addVariableModal.error.emptyVariableName");
                return false;
            }

            if (variablesNamesAlreadyAdded) {
                const isRepeatedName = _.some(variablesNamesAlreadyAdded, function (existentVariableName) {
                    return existentVariableName === checkedVariable.name;
                });
                if (isRepeatedName) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.repeatedVariableName");
                    return false;
                }
            }

            if (!variableNameRegex.test(checkedVariable.name)) {
                ValidatorService.showAlertBasicTab("addVariableModal.error.invalidVariableName");
                return false;
            }

            if (checkedVariable.enableAutomaticReload) {
                if (checkedVariable.type === VARIABLES.ACTION) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.automaticReloadInvalidToActionVariable");
                    return false;
                }
                if (!service.validateAutomaticReloadDelayValue(checkedVariable.automaticReloadDelay)) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.automaticReloadDelayValueInvalid");
                    return false;
                }
                if (!checkedVariable.inclusionParams && !checkedVariable.blockingParams) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.needIncludeOrBlockCommandsToAutomaticReloadVar");
                    return false;
                }
            }

            if (!service.validateExtractOptions(checkedVariable)) {
                return false;
            }

            if (!service.validateFreemarkerTemplateForm("addVariableModal.tab.title.commandsVisualization",
                    "addVariableModal.error.enabledAndEmptyCliCommands", hasVisualizationCommandsCli, checkedVariable.commands)) {
                return false;
            }

            if (hasIncludeEnabled(checkedVariable.includeAndBlockOptionMode) && !isSelectionType(checkedVariable.type)
            && checkedVariable.type !== "READ_ONLY" && checkedVariable.type !== "ACTION") {
                const messageKey =
                    checkedVariable.type === "TEXT" || checkedVariable.type === "TEXT_LIST"
                        ? "addVariableModal.error.includeInvalidTextOrTextListType"
                        : "addVariableModal.error.includeInvalidIntOrIntListType";

                $rootScope.showDialog({
                    translateKey: messageKey
                });

                return false;
            }

            // Specific Variable Fields Validation
            switch (checkedVariable.type) {
                case "TEXT":
                case "TEXT_LIST":
                    return TextVariableValidatorService.validateTextVariable(checkedVariable);
                case "INTEGER":
                case "INTEGER_LIST":
                    return IntegerVariableValidatorService.validateIntegerVariable(checkedVariable, hasInterval,
                        hasStep, hasBaseExponent);
                case "SELECTION":
                case "SELECTION_LIST":
                    return SelectionVariableValidatorService.validateSelectionVariable(checkedVariable);
                case "READ_ONLY":
                    return ReadOnlyVariableValidatorService.validateReadOnlyVariable(checkedVariable);
                case "ACTION":
                    return ActionVariableValidatorService.validateActionVariable(checkedVariable);
            }
        };

        /**
         * https://lodash.com/docs/4.17.15#inRange
         * O valor máximo não é incluso no range.
         */
        service.validateAutomaticReloadDelayValue = function (delay) {
            const MAX_DELAY_RANGE = VARIABLES.automaticReload.MAX_DELAY + 1;
            return _.inRange(delay, VARIABLES.automaticReload.MIN_DELAY, MAX_DELAY_RANGE);
        };

        service.validateExtractOptions = function (checkedVariable) {
            var result = true;
            var tabKey;
            const msgEnabledAndEmptyCliCommands = "addVariableModal.error.enabledAndEmptyCliCommands";

            switch (checkedVariable.includeAndBlockOptionMode) {
                case VARIABLES.includeAndBlockOptionsModes.INCLUDE:
                    tabKey = "addVariableModal.tab.title.inclusionOptions";
                    result = service.isValidExtractOptions(tabKey, checkedVariable.inclusionParams,
                      msgEnabledAndEmptyCliCommands);
                    break;
                case VARIABLES.includeAndBlockOptionsModes.BLOCK:
                    tabKey = "addVariableModal.tab.title.blockingOptions";
                    result = service.isValidExtractOptions(tabKey, checkedVariable.blockingParams,
                      msgEnabledAndEmptyCliCommands);
                    break;
                case VARIABLES.includeAndBlockOptionsModes.INCLUDE_AND_BLOCK:
                    tabKey = "addVariableModal.tab.title.inclusionOptions";
                    result = service.isValidExtractOptions(tabKey, checkedVariable.inclusionParams,
                      msgEnabledAndEmptyCliCommands);
                    if (result) {
                        tabKey = "addVariableModal.tab.title.blockingOptions";
                        result = service.isValidExtractOptions(tabKey, checkedVariable.blockingParams,
                            msgEnabledAndEmptyCliCommands);
                    }
                    break;
                default:
                    return true;
            }

            return result;
        };

        service.isValidExtractOptions = function(tabKey, extractionParams, commandsMessageKey) {
            if (_.isEmpty(extractionParams.commands.trim())) {
                var customMessageKey = commandsMessageKey ? commandsMessageKey : "popups.alert.nullCommands";
                ValidatorService.showAlertOnTab(tabKey, customMessageKey);
                return false;
            }

            if (extractionParams.extractResultsFromCliResults && _.isEmpty(extractionParams.regexToExtractOptions.trim())) {
                ValidatorService.showAlertOnTab(tabKey, "addVariableModal.error.emptyRegexValue");
                return false;
            }

            var hasInvalidOption = _.some(extractionParams.ruleOptions, function(options) {
                return _.any(options.values, function(value) {
                    return _.isEmpty(value.trim());
                });
            });

            if (extractionParams.extractOptionsFilterResults && (!extractionParams.ruleOptions || hasInvalidOption)) {
                ValidatorService.showAlertOnTab(tabKey, "addVariableModal.error.emptyFilterValue");
                return false;
            }

            if (!service.validateFreemarkerTemplateForm(tabKey, "addVariableModal.error.transformationLogicCommands",
                extractionParams.hasTransformationLogic, extractionParams.transformationLogicCommands)) {
                return false;
            }

            if (extractionParams.extractResultsFromCliResults && extractionParams.useSpecificOccurrences) {
                var values = extractionParams.specificOccurrences.split(",");
                var hasInvalid = _.some(values, function(value) {
                    return service.isInvalidExtractOccurrence(value);
                });

                if (hasInvalid) {
                    ValidatorService.showAlertOnTab(tabKey, "addVariableModal.error.invalidSpecificOccurrencesValue");
                    return false;
                }
            }

            return true;
        };

        service.isInvalidExtractOccurrence = function(occurrence) {
            if (occurrence.indexOf("-") !== -1) {
                return service.isInvalidRange(occurrence);
            }

            return service.isInvalidOccurrenceNumber(occurrence);
        };

        service.isInvalidRange = function(range) {
            var values = range.split("-");

            if (values.length != 2) {
                return true;
            }

            if (service.isInvalidOccurrenceNumber(values[0]) || service.isInvalidOccurrenceNumber(values[1])) {
                return true;
            }

            if (parseInt(values[0]) >= parseInt(values[1])) {
                return true;
            }

            return false;
        };

        service.isInvalidOccurrenceNumber = function(number) {
            var number: any = parseInt(number);

            if (isNaN(number)) {
                return true;
            }

            if (number === 0) {
                return true;
            }

            return false;
        };

        /**
         * Faz a validação estática de preenchimento da diretiva code-block e o checkbox de habilitação.
         */
        service.validateFreemarkerTemplateForm = function(tabKey, emptyMessageKey, codeBlockEnabled, freemarkerTemplate) {
            if (codeBlockEnabled && (!freemarkerTemplate || _.isEmpty(freemarkerTemplate.trim()))) {
                ValidatorService.showAlertOnTab(tabKey, emptyMessageKey);
                return false;
            }

            return true;
        };

        service.isValidOptionsToAdd = function(option, variable) {
            return SelectionVariableValidatorService.validateOption(option, variable);
        };

        service.isValidActionsToAdd = function(option, variable) {
            return ActionVariableValidatorService.validateActionToInclude(option, variable);
        };

        service.isValidRangeToAdd = function(selectionRangeMin, selectionRangeMax) {
            return SelectionVariableValidatorService.validateRange(selectionRangeMin, selectionRangeMax);
        };

        service.validateVariableName = function(variableName) {
            return TemplateRESTService.validateVariableName(variableName);
        };

        return service;
    }
]);
