"use strict";

/**
 * Filter responsible for formatting dates.
 *
 * First it gets the three chars month translated,
 * and then it gets the country mask and replace month three chars according to the language
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.filter("dateFormat", ["$filter", "$translate",
    function($filter, $translate) {
        return function(date) {
            var month = $translate.instant("month." + $filter("date")(date, "MM"));
            var fullDate = $filter("date")(date, $translate.instant("date.format"));

            return fullDate.replace(/[A-z]{3}/g, month);
        };
    }
]);
