"use strict";

/**
* @ngdoc service
* @description Realiza filtros na árvore de info/config buscando pela ocorrência a partir de um termo de busca e atualiza o modelo
* de dados usados para a busca dos elementos em caso de atualizações em lists e choices.
**/
var app = angular.module("nms.dynamicDevice");
app.service("TreeFilterService", ["$rootScope", "ContentManager", "NodeInfoCache",
    "NesDataCacheService", "TreeFilterWorkerService", "TreeSelectionManager", "DomainHandlerService", "DataPathService",
    function($rootScope, ContentManager, NodeInfoCache, NesDataCacheService, TreeFilterWorkerService, TreeSelectionManager,
        DomainHandlerService, DataPathService) {
        var self = this;
        var MIN_TERM_SIZE = 3;
        var pathsByNameMap;
        var nodeNames;
        var currentSearchTerm;
        var lastSearchTreeNodes: any = {};

        var openChildrenNodesFromLastSearch = function(dataNodePath) {
            var oldChildrenTreeNodes = _.filter(_.keysIn(lastSearchTreeNodes), function(id) {
                return id !== dataNodePath && _.contains(id, dataNodePath);
            });

            _.forEach(oldChildrenTreeNodes, function(id) {
                var oldTreeNode = lastSearchTreeNodes[id];

                TreeSelectionManager.expandNode(id, oldTreeNode.parent, oldTreeNode.expanded);
                TreeSelectionManager.selectNode(id, oldTreeNode.parent, oldTreeNode.selected, oldTreeNode.selectedUpwards);
                TreeSelectionManager.filterNode(id, oldTreeNode.parent, oldTreeNode.filtered, oldTreeNode.filterDownwards);
            });
        };

        var openTreeAtPathNodeFromLastSearch = function(schemaNode, pathKeys, filterDownwards) {
            if (schemaNode && schemaNode.paths.schemaJsonPath !== "$") {
                var dataNodePath = DataPathService.getNodePath(pathKeys, schemaNode);
                var parentDataNodePath = DataPathService.getParentNodePath(pathKeys, schemaNode);
                var oldTreeNode = lastSearchTreeNodes[dataNodePath];

                TreeSelectionManager.expandNode(dataNodePath, parentDataNodePath, oldTreeNode.expanded);

                if (TreeSelectionManager.isExpanded(dataNodePath) && filterDownwards) {
                    openChildrenNodesFromLastSearch(dataNodePath);
                }

                TreeSelectionManager.selectNode(dataNodePath, parentDataNodePath, oldTreeNode.selected,
                    oldTreeNode.selectedUpwards);
                TreeSelectionManager.filterNode(dataNodePath, parentDataNodePath, true, filterDownwards);
                schemaNode = DomainHandlerService.getSchemaNode(schemaNode.parentPaths.schemaJsonPath);

                openTreeAtPathNodeFromLastSearch(schemaNode, pathKeys, false);
            }
        };

        var openNode = function(node, isPolling) {
            var schemaNode = DomainHandlerService.getSchemaNode(node.schemaJsonPath);
            var dataNodePath = DataPathService.getNodePath(node.pathKeys, schemaNode);
            var parentDataNodePath = DataPathService.getParentNodePath(node.pathKeys, schemaNode);
            var oldTreeNode = lastSearchTreeNodes[dataNodePath];

            if (isPolling && oldTreeNode && oldTreeNode.filtered) {
                openTreeAtPathNodeFromLastSearch(schemaNode, node.pathKeys, true);
            } else {
                var opened = ContentManager.openTreeAtPathNode(schemaNode.parentPaths.schemaJsonPath, node.pathKeys,
                    function(dataPathWithKeys, parentDataPathWithKeys) {
                        TreeSelectionManager.filterNode(dataPathWithKeys, parentDataPathWithKeys, true, false);
                    });
                if (opened) {
                    TreeSelectionManager.filterNode(dataNodePath, parentDataNodePath, true, true);
                }
            }
        };

        var runTreeFilterWorker = function(data, updateFunction) {
            $rootScope.$broadcast("updatingTreeSearchIndex", true);
            return TreeFilterWorkerService.run(data)
                .then(updateFunction)
                .then(function() {
                    $rootScope.$broadcast("updatingTreeSearchIndex", false);
                });
        };

        this.init = function() {
            var workerParams: any = {
                currentData: NesDataCacheService.getCurrentData(),
                nodeInfoCache: NodeInfoCache.get(),
                operation: "create"
            };
            return runTreeFilterWorker(workerParams, function(result) {
                pathsByNameMap = result.pathsByNameMap;
                nodeNames = result.nodeNames;
            });
        };

        this.getCurrentSearchTerm = function() {
            return currentSearchTerm;
        };

        this.filter = function(searchTerm, isPolling) {
            currentSearchTerm = searchTerm;
            if (searchTerm && searchTerm.length >= MIN_TERM_SIZE) {
                self.resetSearch();
                var regex = new RegExp(_.escapeRegExp(searchTerm), "i");

                var matches = _.filter(nodeNames, function(name) {
                    return regex.test(name);
                });

                if (isPolling) {
                    TreeSelectionManager.clearSelectedNode();
                }

                var filteredNodes = _.pick(pathsByNameMap, matches);
                _.flatten(_.values(filteredNodes)).forEach(function(node) {
                    openNode(node, isPolling);
                });
            }

            if (!isPolling) {
                TreeSelectionManager.expandSelectedNode();
            }
        };

        this.resetSearch = function() {
            lastSearchTreeNodes = Object.assign({}, TreeSelectionManager.getTreeNodes());
            TreeSelectionManager.clearNodes();
        };

        this.clearSearch = function() {
            currentSearchTerm = null;
            TreeSelectionManager.clearNodes();
            TreeSelectionManager.expandSelectedNode();
        };

        var unregisterDataNodeChanged = $rootScope.$on("dataNodeChanged", function(event, attrs) {
            var data: any = {
                currentData: NesDataCacheService.getCurrentData(),
                nodeInfoCache: NodeInfoCache.get(),
                operation: "update",
                node: attrs.node,
                pathsByNameMap: pathsByNameMap
            };

            var updateFunction = function(newPathsByNameMap) {
                pathsByNameMap = newPathsByNameMap;
                nodeNames = _.keys(pathsByNameMap);
            };

            runTreeFilterWorker(data, updateFunction);
        });

        $rootScope.$on("$destroy", function() {
            unregisterDataNodeChanged();
        });
    }
]);
