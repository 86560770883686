import { RuleType } from "../components/elements/matching-rules/matching-rules.models";

export type OperatorMap = {
    [key in RuleType]?: string
}

export const TABLE_FILTER_OPERATOR_MAPPING: OperatorMap = {
    [RuleType.CONTAINS]:              "",
    [RuleType.DOES_NOT_CONTAIN]:      "!!",
    [RuleType.EQUALS]:                "=",
    [RuleType.NOT_EQUALS]:            "!",
    [RuleType.STARTS_WITH]:           "%",
    [RuleType.ENDS_WITH]:             "%",
    [RuleType.LESS_THAN]:             "<",
    [RuleType.LESS_THAN_OR_EQUAL]:    "<=",
    [RuleType.GREATER_THAN]:          ">",
    [RuleType.GREATER_THAN_OR_EQUAL]: ">=",
}
