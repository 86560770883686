var app = angular.module("nms.dynamicDevice");

app.directive("deviceContent", function () {
    return {
        templateUrl: "templates/features/dynamic-device/device-content.html"
    };
});

app.directive("deviceAccessDenied", function () {
    return {
        templateUrl: "templates/features/dynamic-device/device-access-denied.html"
    };
});

app.directive("deviceSummary", [
    "$compile",
    "NMS_FEATURES",
    "PermissionsActionsService",
    function ($compile, NMS_FEATURES, PermissionsActionsService) {
        return {
            restrict: "A",
            replace: true,
            scope: {
                infoBind: "=",
                device: "=deviceSummary"
            },
            compile: function compile() {
                return {
                    post: function postLink(scope, iElement) {
                        var device = angular.fromJson(scope.device);
                        var dataToBind = !_.isNull(scope.infoBind) ? scope.infoBind : "";
                        if (PermissionsActionsService.hasFeature(device, NMS_FEATURES.summary.feature)) {
                            iElement.append("<a>" + dataToBind + "</a>");
                        } else {
                            iElement.append("<span>" + dataToBind + "</span>");
                            iElement.addClass("cursor-default");
                        }
                        $compile(iElement.contents())(scope);
                    }
                };
            }
        };
    }
]);
