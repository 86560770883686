"use strict";

/**
* @ngdoc controller
* @name nms.components.selection-checkbox:SelectionCheckboxController
* @description
* # SelectionCheckboxController
* Classe de controle para a diretiva selection checkbox.
*/
var app = angular.module("nms");

app.controller("SelectionCheckboxController", ["$rootScope", "$scope", "$timeout",
    function($rootScope, $scope, $timeout) {
        var updateCheckedItens = function() {
            var tableData = getTableData();
            if (!_.isEmpty($scope.comparableFields) && _.isArray($scope.comparableFields)) {
                $scope.selection.checked = _.filter(tableData, function(item) {
                    return _.find($scope.selection.checked, _.pick(item, $scope.comparableFields));
                });
            } else {
                $scope.selection.checked = _.filter(tableData, function(item) {
                    return _.find($scope.selection.checked, item);
                });
            }
        };

        /*
        * Define os dados utilizados para as operações de seleção.
        * Quando o atributo 'ignoreHiddenElements' é verdadeiro, somente os dados visíveis são utilizados, do contrário, o dataset
        * inteiro é considerado.
        */
        var getTableData = function() {
            return $scope.selection.ignoreHiddenElements
                ? $scope.selection.table.data
                : $scope.selection.table.settings().dataset;
        };

        var isAllStillSelectedCallback = function() {
            updateCheckedItens();
            var tableData = getTableData();
            $scope.selection.selectAllCheckbox = $scope.selection.checked.length === tableData.length
                && !_.isEmpty($scope.selection.checked);

            if ($scope.selection.checked.length === 0 || $scope.selection.selectAllCheckbox) {
                $scope.selection.indeterminate = false;
            } else {
                $scope.selection.indeterminate = true;
            }
        };

        var visibleElements = function() {
            return $scope.selection.table.data;
        };

        /*
        * Seleciona elementos.
        * Caso o atributo ignoreHiddenElements esteja habilitado, somente os elementos visíveis são considerados, caso o contrário
        * o dataset completo da tabela é utilizado.
        */
        var selectItens = function() {
            $scope.selection.checked = $scope.selection.ignoreHiddenElements
                ? visibleElements().slice()
                : _.union($scope.selection.checked, $scope.selection.table.data);
        };

        /*
        * Desseleciona elementos.
        * Caso o atributo ignoreHiddenElements esteja habilitado, a seleção passa a ser vazia, caso o contrário
        * é feita a diferença entre os elementos selecionados e os elementos visíveis na tabela.
        */
        var unselectItens = function() {
            $scope.selection.checked = $scope.selection.ignoreHiddenElements
                ? []
                : _.difference($scope.selection.checked, $scope.selection.table.data);
        };

        $scope.selection.checkUncheckAll = function() {
            $scope.selection.indeterminate = false;
            if ($scope.selection.selectAllCheckbox) {
                selectItens();
            } else {
                unselectItens();
            }
        };

        $scope.isAllStillSelected = function() {
            $timeout(function() {
                isAllStillSelectedCallback();
            }, 10);
        };

        $scope.$watch(visibleElements, function() {
            isAllStillSelectedCallback();
        });
    }
]);
