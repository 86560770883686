/**
 * Directives used to build the tree.
 *
 * @authors Marcelo Pancinha
 */
var app = angular.module("nms.dynamicDevice");

app.directive("treeNode", ["DomainHandlerService", "$filter", "$timeout", "TreeFilterService",
    "TextHighlighterService",
    function(DomainHandlerService, $filter, $timeout, TreeFilterService, TextHighlighterService) {
        return {
            restrict: "A",
            link: function(scope, element) {
                var nodeLabel = angular.element(element[0]).find("label");
                var path = DomainHandlerService.getPath(scope.nodeData);

                element.on("click", function() {
                    var oldSelected = angular.element(".selected");

                    if (oldSelected != null && oldSelected.length > 0) {
                        oldSelected.removeClass("selected");
                    }

                    angular.element(this).addClass("selected");
                });

                var setLabelClass = function() {
                    if (scope.filters.showStatus && $filter("hasNonConfigurableChildren")(path)) {
                        nodeLabel.addClass("nonConfigurable-node");
                        if (!scope.filters.showConfig) {
                            nodeLabel.removeClass("configurable-node");
                        }
                    }

                    if (scope.filters.showConfig && $filter("hasConfigurableChildren")(path)) {
                        nodeLabel.addClass("configurable-node");
                        if (!scope.filters.showStatus) {
                            nodeLabel.removeClass("nonConfigurable-node");
                        }
                    }
                };

                $timeout(setLabelClass, 10);
                scope.$watchCollection("filters", setLabelClass);

                var searchTerm = TreeFilterService.getCurrentSearchTerm();
                if (searchTerm) {
                    TextHighlighterService.highlight(angular.element(element), ".searchable-element", searchTerm);
                }
            }
        };
    }
]);

app.directive("containerTreeNode", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/sidebar/tree/container-tree-node.html",
            controller: "ContainerTreeNodeController",
            scope: {
                container: "=",
                parentNode: "=",
                pathKeys: "="
            },
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);

app.directive("listTreeNode", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/sidebar/tree/list-tree-node.html",
            controller: "ListTreeNodeController",
            scope: {
                list: "=",
                parentNode: "=",
                pathKeys: "="
            },
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);

app.directive("listEntryTreeNode", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/sidebar/tree/list-entry-tree-node.html",
            controller: "ListEntryTreeNodeController",
            scope: {
                entry: "=",
                entryIndex: "=",
                entryTemplate: "=",
                parentNode: "=",
                pathKeys: "="
            },
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);

app.directive("choiceTreeNode", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/sidebar/tree/choice-tree-node.html",
            controller: "ChoiceTreeNodeController",
            scope: {
                choice: "=",
                parentNode: "=",
                pathKeys: "="
            },
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);

app.directive("caseTreeNode", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/sidebar/tree/case-tree-node.html",
            controller: "CaseTreeNodeController",
            scope: {
                case: "=",
                parentNode: "=",
                pathKeys: "="
            },
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);
