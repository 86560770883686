"use strict";

/**
 * @ngdoc controller
 * @name nms.components.autoUpdater:AutoUpdaterService
 * @description
 *
 * # AutoUpdaterService
 *
 * Serviço responsável por disponibilizar os dados de modelo e de configuração do componente de atualização automática.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.service("AutoUpdaterService", ["$rootScope", "$log", "AutoUpdaterModel", "RefreshIntervalService", "$timeout",
    function($rootScope, $log, AutoUpdaterModel, RefreshIntervalService, $timeout) {
        const service: any = {};
        var updateProperties: any = {};

        service.getAutoUpdaterModel = function() {
            return updateProperties.autoUpdaterModel;
        };

        service.setAutoUpdaterModel = function(autoUpdaterModel) {
            updateProperties.autoUpdaterModel = new AutoUpdaterModel(autoUpdaterModel);
        };

        service.getUpdateInterval = function() {
            return updateProperties.updateInterval;
        };

        service.setUpdateInterval = function(updateInterval) {
            updateProperties.updateInterval = updateInterval;
        };

        service.getIsUpdating = function() {
            return updateProperties.isUpdating;
        };

        service.setIsUpdating = function(isUpdating) {
            updateProperties.isUpdating = isUpdating;
        };

        service.getLastSuccessfulPollingDuration = function() {
            return updateProperties.lastSuccessfulPollingDuration;
        };

        service.setLastSuccessfulPollingDuration = function(lastSuccessfulPollingDuration) {
            updateProperties.lastSuccessfulPollingDuration = lastSuccessfulPollingDuration;
        };

        service.executeUpdateFunction = function() {
            updateProperties.autoUpdaterModel.updateFunction()
        };

        service.startUpdating = function() {
            $timeout(function() {
                $log.log("Started updating at", new Date());
                service.setIsUpdating(true);
                $rootScope.$broadcast("stopCountdown");
                $rootScope.$broadcast("startCounter");
            });
        };

        service.stopUpdating = function() {
            $timeout(function() {
                service.setIsUpdating(false);
                $rootScope.$broadcast("stopCountdown");
                $rootScope.$broadcast("stopCounter");
            });
        };

        service.finishedUpdating = function() {
            $timeout(function() {
                $log.log("Finished updating at", new Date());
                service.setIsUpdating(false);
                $rootScope.$broadcast("stopCounter");
                $rootScope.$broadcast("startCountdown");
            });
        };

        service.reset = function() {
            updateProperties = {
                autoUpdaterModel: null,
                updateInterval: 300,
                isUpdating: false,
                lastSuccessfulPollingDuration: null
            };

            RefreshIntervalService.getRefreshInterval(function(interval) {
                service.setUpdateInterval(interval);
            });
        };

        service.reset();

        return service;
    }
]);
