"use strict";

var app = angular.module("nms");
app.service("SystemConfigurationService", ["SystemConfigurationRestService",
    function(SystemConfigurationRestService) {
        const service: any = {};

        service.getSecuritySystemConfig = function() {
            return SystemConfigurationRestService.getSecuritySystemConfig().then(function(securitySystemConfig) {
                return securitySystemConfig.plain();
            });
        };

        service.getLdapMode = function(callback) {
            return SystemConfigurationRestService.getLdapMode().then(callback);
        };

        return service;
    }
]);
