"use strict";

/**
* @ngdoc function
* @name nms.audit.controller:AuditModalCtrl
* @description
* Controller of the nms.templates
*/
angular.module("nms.audit")
.controller("AuditModalCtrl", ["$scope", "$rootScope", "$translate", "auditOperation", function($scope, $rootScope, $translate, auditOperation) {
    $scope.auditOperation = auditOperation;
}]);
