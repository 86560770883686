import { AcsSpecificalCredentials } from "./acs-specific-credentials-model";

/**
 * Modelo que representa as credenciais alteradas para os números de série.
 */
export class CpeCredentialsForSerialNumbers {

    serialNumbers: Array<any>;
    cpeCredentials: AcsSpecificalCredentials;

    constructor(serialNumbers: Array<any>, acsSpecificalCredentials: AcsSpecificalCredentials) {
        this.serialNumbers = serialNumbers;
        this.cpeCredentials = acsSpecificalCredentials;
    }
}
