"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.MonitoringRestService
* @description
* # MonitoringRestService - Serviço REST para monitoramento
* Factory in the nms.management.
*/
var app = angular.module("nms.management");

app.factory("MonitoringRestService", ["Restangular",
    function(Restangular) {
        var basePath = "/settings";
        const service: any = {};

        service.getMonitoringSettings = function() {
            return Restangular.one(basePath + "/monitoring").customGET();
        };

        service.updateMonitoringSettings = function(monitoringConnectionSettings) {
            return Restangular.one(basePath + "/monitoring").customPOST(monitoringConnectionSettings);
        };

        service.testConnection = function(monitoringConnectionSettings) {
            return Restangular.one("/monitor/device/api-info").customPOST(monitoringConnectionSettings);
        };

        return service;
    }
]);
