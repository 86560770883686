var app = angular.module("nms.dynamicDevice");

app.directive("decimal",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/numbers/decimal.html"
        };
    }
);
