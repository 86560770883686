"use strict";

/**
* @ngdoc Service
* @name nms.management.ManagePermissionsLocationsService
* @description Service para gerência de permissões de localidade.
*/
var app = angular.module("nms.management");

app.service("ManagePermissionsLocationsService", ["ManagePermissionsLocationsRestService", "DEVICE_PERMISSION",
    function(ManagePermissionsLocationsRestService, DEVICE_PERMISSION) {
        const service: any = {};

        /**
        *  Adicionar o atributo permission recursivamente, quando o mesmo não existe no objeto.
        **/
        var addDefaultPermission = function(location, locationPermissions) {
            var foundPermission = _.find(locationPermissions, function(locationPermission) {
                return locationPermission.graphicId === location.locationBean.locationId;
            });
            var permissionValue = foundPermission ? foundPermission.permission : DEVICE_PERMISSION.MANAGE.databaseValue;

            _.defaults(location, {permission: {value: permissionValue}});
            _.forEach(location.children, function(child) {
                addDefaultPermission(child, locationPermissions);
            });
        };

        /**
         * Processa a resposta da requisição de localidades para adequação ao modelo esperado pelo
         * @see nms.management.ManagePermissionsLocationsController, incluindo a opção default de permissão para todas as
         * localidades que não tiverem permissões pré definidas.
         *
         * @param {object[]} permissões de localidades previamente definidas.
         * @returns {Object[]} permissões de localidades processadas.
         */
        service.getLocationPermissions = function(locationPermissions) {
            return ManagePermissionsLocationsRestService.getAll().then(function(response) {
                var locations = response.plain();
                addDefaultPermission(locations, locationPermissions);
                return new Array(locations);
            });
        };

        return service;
    }
]);
