"use strict";

import { EquipmentInfo } from "@nms-ng2/app/modules/template/services/equipment.service";
import { EquipmentAssociation } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";

/**
* @ngdoc service
* @name nms.templates.DeviceService
* @description
* # DeviceService
* Factory in the nms.templates.
*/

// TODO: Renomear para algo mais coerente, com relação à aplicação de template.

angular.module("nms.templates")
.factory("DeviceService", ["$rootScope", "LicenseService", "ManagementDeviceRestService",
    "NMS_LICENSES", "EquipmentService", "DeviceDiscoverService",
    function( $rootScope, LicenseService, ManagementDeviceRestService,
        NMS_LICENSES, EquipmentService, DeviceDiscoverService) {
        const service: any = {};

        service.validateAddDevices = async function(checkedEquipments: Array<EquipmentInfo>,
                equipmentsAlreadyAdded: Array<EquipmentAssociation>, presentationMode: string,
                shouldAddEquipmentsToAllTemplates: boolean, templateAlreadyAdded,  templateType: string): Promise<object> {

            return service.getDevicesWithoutManagerPermission(checkedEquipments).then(function (devices) {
                if (service.validateDevicesWithoutPermission(
                           checkedEquipments, devices, "addDeviceModal.error.userWithoutPermission") &&
                        EquipmentService.validateAddEquipments(checkedEquipments, equipmentsAlreadyAdded, presentationMode,
                            shouldAddEquipmentsToAllTemplates, templateAlreadyAdded, templateType) &&
                        service.validateDevicesCustom(checkedEquipments, "addDeviceModal.error.customDevices")) {
                    return checkedEquipments;
                }

                return Promise.reject(checkedEquipments);
            });
        }

        service.genericPermissionsValidateAddDevices = async function (checkedEquipments: Array<EquipmentInfo>): Promise<object> {
            return service.getDevicesWithoutManagerPermission(checkedEquipments).then(function (devices) {
                if (service.validateDevicesWithoutPermission(
                    checkedEquipments, devices, "addDeviceModal.error.userWithoutPermission")) {
                    return checkedEquipments;
                }

                return Promise.reject(checkedEquipments);
            });
        }

        service.getDevicesWithoutManagerPermission = function(checkedEquipments): Promise<object> {
            const devicesForValidate = checkedEquipments.map(checkedDevice => {
                return {
                    name: checkedDevice.name,
                    devModelCode: checkedDevice.modelCode,
                    vendorCode: checkedDevice.vendorCode,
                    locationId: checkedDevice.locationId
                };
            });

            return DeviceDiscoverService.getDevicesWithoutManagerPermission(devicesForValidate);
        }

        service.validateDevicesCustom = function(checkedDevices, messageKey: string): boolean {
            const fails = [];

            checkedDevices.forEach(function(checkedDevice) {
                if (!LicenseService.hasLicense(NMS_LICENSES.deviceCustom.name) && checkedDevice.model == "Custom") {
                    fails.push(checkedDevice);
                }
            });

            if (fails.length > 0) {
                EquipmentService.handleInvalidEquipments(checkedDevices, fails, messageKey);
                return false;
            }

            return true;
        };

        service.validateDevicesWithoutPermission = function(selection, devices, messageKey): boolean {
            const invalidDevices = _.map(devices.plain(), "name");
            const fails = [];

            _.forEach(selection, function (checkedDevice) {
                if (_.includes(invalidDevices, checkedDevice.name)) {
                    fails.push(checkedDevice);
                }
            });

            if (fails.length > 0) {
                EquipmentService.handleInvalidEquipments(selection, fails, messageKey);
                return false;
            }

            return true;
        }

        service.updateSelectedDevice = function() {
            $rootScope.results.equipmentAssociationResults.forEach(function(equipment) {
                if (equipment.equipmentId === $rootScope.selectedAssociationDevice.equipmentId) {
                    $rootScope.selectedAssociationDevice = equipment;
                }
            });
        };

        service.getDevicesByIds = function(ids) {
            return ManagementDeviceRestService.getDevicesByIds(ids);
        };

        return service;
    }]);
