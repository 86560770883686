"use strict";

import {
    ScheduledTemplateApplication,
    TemplateInstanceTabs
} from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { TemplateInstanceService } from "./features/template/template-application/template-instance-service";

/**
 * @name Device
 * App Routes
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

/**
 * Decorator para o $state.
 * Acrescenta ao $state o valor do estado anterior da aplicação.
 */
app.decorator("$state", [
    "$delegate",
    "$rootScope",
    ($delegate, $rootScope) => {
        function decorated$State() {
            let $state = $delegate;
            $state.previous = undefined;

            /**
             * Os parâmetro repassados aqui são referentes aos parâmetros das rotas do angularjs,
             * não tem relação com parâmetros de URL.
             */
            $rootScope.$on("$stateChangeSuccess", (ev, to, toParams, from, fromParams) => {
                $state.previous = { route: from, routeParams: fromParams };
            });

            return $state;
        }

        return decorated$State();
    }
]);

app.config([
    "$stateProvider",
    "$urlRouterProvider",
    "NMS_STATES",
    "NMS_FEATURES",
    "SYSTEM_KEYWORD",
    function ($stateProvider, $urlRouterProvider, NMS_STATES, NMS_FEATURES, SYSTEM_KEYWORD) {
        var $delegate = $stateProvider.state;
        $stateProvider.state = function (name, definition) {
            if (!definition.resolve) {
                definition.resolve = {};
            }

            var args = [name, definition];
            return $delegate.apply(this, args);
        };

        var resolveDevice = [
            "$stateParams",
            "ManagementDeviceRestService",
            "DeviceAugmentService",
            function ($stateParams, ManagementDeviceRestService, DeviceAugmentService) {
                if (!$stateParams.device || $stateParams.device.name !== $stateParams.name) {
                    var httpConfig: any = { redirectToOnError: NMS_STATES.manageDevices };
                    return ManagementDeviceRestService.getDeviceByName($stateParams.name, httpConfig, function (device) {
                        $stateParams.device = DeviceAugmentService.resolveDevice(device);
                    });
                }
                return true;
            }
        ];

        var getMonitoringSettings = [
            "$rootScope",
            "MonitoringService",
            function ($rootScope, MonitoringService) {
                return MonitoringService.getMonitoringSettings().then(function (response) {
                    return response;
                });
            }
        ];

        const hasSchedulerConfigPermission = [
            "AuthenticationService",
            "NMS_FEATURES",
            function (AuthenticationService, NMS_FEATURES) {
                return AuthenticationService.isAuthenticated().then(function () {
                    return AuthenticationService.hasPermission(NMS_FEATURES.scheduler.feature);
                });
            }
        ];

        /**
         * Lista os fields disponíveis para restrições de template de acordo com o tipo de aplicação (CLI/TR-069).
         * - CLI:{ MODEL: { initial: true }, FIRMWARE: { initial: false } }
         * - TR_069: { PRODUCT_CLASS: { initial: true }, SW_VERSION: { initial:  false }}
         */
        const resolveAvailableFields = ["ConfigRESTService", "TEMPLATE_TYPE",
            async function (ConfigRESTService, TEMPLATE_TYPE) {
                const cliAvailableFields = await ConfigRESTService.getRuleAvailableTemplateFields(TEMPLATE_TYPE.CLI.name);
                const tr069AvailableFields = await ConfigRESTService.getRuleAvailableTemplateFields(TEMPLATE_TYPE.TR_069.name);

                const convertModels = (availableFields, defaultField) => {
                    return Object.keys(availableFields).reduce((accumulator, field) => {
                        const availableField = {
                            [field]: {
                                translate: `templateform.basic.${availableFields[field].replace(" ", ".").toLowerCase()}`,
                                initial: field === defaultField
                            }
                        };

                        return { ...accumulator, ...availableField };
                    }, {});
                };

                return {
                    [TEMPLATE_TYPE.CLI.name]: convertModels(cliAvailableFields, "MODEL"),
                    [TEMPLATE_TYPE.TR_069.name]: convertModels(tr069AvailableFields, "PRODUCT_CLASS")
                };
            }
        ];

        var options: any = {};

        $stateProvider
            // ROOT
            .state(NMS_STATES.root, {
                abstract: true,
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        template: '<header ui-view="header" role="menubar"></header>'
                    },
                    main: {
                        template: '<div class="container container-fluid" role="main" ui-view="main"></div>'
                    }
                },
                data: {
                    css: [
                        "styles/components/page-title.css",
                        "styles/components/accordion.css",
                        "styles/components/table-resize.css",
                        "styles/components/notification.css",
                        "styles/components/vertical-scrollable-table.css",
                        "styles/components/ng-table-custom.css",
                        "styles/components/polling.css",
                        "styles/components/connectivity-test.css",
                        "https://fonts.googleapis.com/css?family=Open+Sans:400,600",
                        "styles/features/dynamic-device.css",
                        "styles/features/templates/templates.css",
                        "styles/features/templates/template-application-one-step.css",
                        "styles/features/management/permissions.css",
                        "styles/app.css"
                    ]
                },
                resolve: {
                    license: [
                        "LicenseService",
                        function (licenseService) {
                            licenseService.requestLicense();
                        }
                    ]
                }
            })
            // LOGIN
            .state(NMS_STATES.login, {
                url: "/login",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav-blank.html"
                    },
                    main: {
                        templateUrl: "templates/components/authentication/login.html"
                    }
                },
                data: {
                    css: ["styles/components/authentication/login.css"]
                },
                params: {
                    redirectTo: undefined,
                    redirectParams: undefined
                }
            })
            // FORBIDDEN
            .state(NMS_STATES.forbidden, {
                url: "/forbidden",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/components/license/forbidden.html"
                    }
                },
                params: {
                    redirect: false
                },
                data: {
                    pageTitle: "error.forbidden"
                }
            })
            // NAM
            .state(NMS_STATES.namCredentials, {
                url: "/nam-credentials",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav-blank.html"
                    },
                    main: {
                        templateUrl: "templates/components/authentication/nam-credentials.html"
                    }
                },
                params: {
                    redirect: true
                },
                data: {
                    pageTitle: "authentication.nam.credentials"
                }
            })
            // AUDIT
            .state(NMS_STATES.audit.list, {
                url: "/audit",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/audit/audit-list.html",
                        controller: "AuditListCtrl"
                    }
                },
                data: {
                    pageTitle: "title.audit",
                    feature: NMS_FEATURES.audit.feature,
                    css: ["styles/features/audit.css"]
                },
                resolve: {
                    types: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.auditType();
                        }
                    ],
                    operation: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.auditOperation();
                        }
                    ],
                    result: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.auditResult();
                        }
                    ],
                    names: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.auditName();
                        }
                    ],
                    searchCriteria: [
                        "ConfigRESTService",
                        "MODULE",
                        function (ConfigRESTService, MODULE) {
                            return ConfigRESTService.searchCriteria(MODULE.audit);
                        }
                    ],
                    equipmentStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            // FIXME Remover de TODOS os resolvers de equipmentStatus e templateInstanceStatus, o carregamento dessas
                            // informações passaram a ser feitas na inicialização do módulo templates.
                            if (!$rootScope.status) {
                                ConfigRESTService.equipmentStatus().then(function (response) {
                                    $rootScope.status = response;
                                    return $rootScope.status;
                                });
                            } else {
                                return $rootScope.status;
                            }
                        }
                    ],
                    templateInstanceStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.templateInstanceStatus) {
                                ConfigRESTService.templateInstanceStatus().then(function (response) {
                                    $rootScope.templateInstanceStatus = response;
                                    return $rootScope.templateInstanceStatus;
                                });
                            } else {
                                return $rootScope.templateInstanceStatus;
                            }
                        }
                    ]
                }
            })
            // TEMPLATE
            .state(NMS_STATES.template.list, {
                url: "/template",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/template/template/template-list.html",
                        controller: "TemplateListCtrl"
                    }
                },
                data: {
                    pageTitle: "title.template",
                    feature: NMS_FEATURES.template.feature
                },
                resolve: {
                    status: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateStatus();
                        }
                    ],
                    searchCriteria: [
                        "ConfigRESTService",
                        "MODULE",
                        function (ConfigRESTService, MODULE) {
                            return ConfigRESTService.searchCriteria(MODULE.template);
                        }
                    ],
                    equipmentStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.status) {
                                ConfigRESTService.equipmentStatus().then(function (response) {
                                    $rootScope.status = response;
                                    return $rootScope.status;
                                });
                            } else {
                                return $rootScope.status;
                            }
                        }
                    ],
                    templateInstanceStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.templateInstanceStatus) {
                                ConfigRESTService.templateInstanceStatus().then(function (response) {
                                    $rootScope.templateInstanceStatus = response;
                                    return $rootScope.templateInstanceStatus;
                                });
                            } else {
                                return $rootScope.templateInstanceStatus;
                            }
                        }
                    ],
                    hasTemplateConfigPermission: [
                        "AuthenticationService",
                        "NMS_FEATURES",
                        function (AuthenticationService, NMS_FEATURES) {
                            return AuthenticationService.isAuthenticated().then(function () {
                                return AuthenticationService.hasPermission(NMS_FEATURES.templateConfig.feature);
                            });
                        }
                    ],
                    hasTemplateInstancePermission: [
                        "AuthenticationService",
                        "NMS_FEATURES",
                        function (AuthenticationService, NMS_FEATURES) {
                            return AuthenticationService.isAuthenticated().then(function () {
                                return AuthenticationService.hasPermission(NMS_FEATURES.templateInstance.feature);
                            });
                        }
                    ]
                }
            })
            .state(NMS_STATES.template.add, {
                url: "/add",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    "main@": {
                        template: "<nms-template><nms-template>"
                    }
                },
                data: {
                    pageTitle: "title.addTemplate",
                    feature: NMS_FEATURES.template.feature,
                    route: NMS_FEATURES.template.routesFromFeature.templateAdd.feature
                },
                resolve: {
                    status: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateStatus();
                        }
                    ],
                    variableTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateVariableTypes();
                        }
                    ],
                    deviceVariables: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateDeviceVariables();
                        }
                    ],
                    cliCommandOptionExtractionFilterTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.cliCommandOptionExtractionFilterTypes();
                        }
                    ],
                    rulesMatchingModes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.rulesMatchingModes();
                        }
                    ],
                    deviceModelRestrictionTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.deviceModelRestrictionTypes();
                        }
                    ],
                    template: function () {
                        return null;
                    },
                    equipmentStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.status) {
                                ConfigRESTService.equipmentStatus().then(function (response) {
                                    $rootScope.status = response;
                                    return $rootScope.status;
                                });
                            } else {
                                return $rootScope.status;
                            }
                        }
                    ],
                    templateInstanceStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.templateInstanceStatus) {
                                ConfigRESTService.templateInstanceStatus().then(function (response) {
                                    $rootScope.templateInstanceStatus = response;
                                    return $rootScope.templateInstanceStatus;
                                });
                            } else {
                                return $rootScope.templateInstanceStatus;
                            }
                        }
                    ],
                    favoriteVariables: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.favoriteVariables();
                        }
                    ],
                    availableFields: resolveAvailableFields
                }
            })
            .state(NMS_STATES.template.clone, {
                url: "/clone",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    "main@": {
                        template: "<nms-template><nms-template>"
                    }
                },
                data: {
                    pageTitle: "title.addTemplate",
                    feature: NMS_FEATURES.template.feature,
                    route: NMS_FEATURES.template.routesFromFeature.templateClone.feature
                },
                params: {
                    templateId: null
                },
                resolve: {
                    status: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateStatus();
                        }
                    ],
                    variableTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateVariableTypes();
                        }
                    ],
                    deviceVariables: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateDeviceVariables();
                        }
                    ],
                    cliCommandOptionExtractionFilterTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.cliCommandOptionExtractionFilterTypes();
                        }
                    ],
                    rulesMatchingModes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.rulesMatchingModes();
                        }
                    ],
                    deviceModelRestrictionTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.deviceModelRestrictionTypes();
                        }
                    ],
                    template: [
                        "TemplateService",
                        "$stateParams",
                        function (TemplateService, $stateParams) {
                            return TemplateService.clone($stateParams.templateId);
                        }
                    ],
                    equipmentStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.status) {
                                ConfigRESTService.equipmentStatus().then(function (response) {
                                    $rootScope.status = response;
                                    return $rootScope.status;
                                });
                            } else {
                                return $rootScope.status;
                            }
                        }
                    ],
                    templateInstanceStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.templateInstanceStatus) {
                                ConfigRESTService.templateInstanceStatus().then(function (response) {
                                    $rootScope.templateInstanceStatus = response;
                                    return $rootScope.templateInstanceStatus;
                                });
                            } else {
                                return $rootScope.templateInstanceStatus;
                            }
                        }
                    ],
                    favoriteVariables: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.favoriteVariables();
                        }
                    ],
                    availableFields: resolveAvailableFields
                }
            })
            .state(NMS_STATES.template.edit, {
                url: "/edit",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    "main@": {
                        template: "<nms-template><nms-template>"
                    }
                },
                data: {
                    pageTitle: "title.editTemplate",
                    feature: NMS_FEATURES.template.feature,
                    route: NMS_FEATURES.template.routesFromFeature.templateEdit.feature
                },
                params: {
                    template: null
                },
                resolve: {
                    status: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateStatus();
                        }
                    ],
                    variableTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateVariableTypes();
                        }
                    ],
                    deviceVariables: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateDeviceVariables();
                        }
                    ],
                    cliCommandOptionExtractionFilterTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.cliCommandOptionExtractionFilterTypes();
                        }
                    ],
                    rulesMatchingModes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.rulesMatchingModes();
                        }
                    ],
                    deviceModelRestrictionTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.deviceModelRestrictionTypes();
                        }
                    ],
                    template: [
                        "TemplateRESTService",
                        "$stateParams",
                        function (TemplateRESTService, $stateParams) {
                            return TemplateRESTService.get($stateParams.template.id);
                        }
                    ],
                    equipmentStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.status) {
                                ConfigRESTService.equipmentStatus().then(function (response) {
                                    $rootScope.status = response;
                                    return $rootScope.status;
                                });
                            } else {
                                return $rootScope.status;
                            }
                        }
                    ],
                    templateInstanceStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.templateInstanceStatus) {
                                ConfigRESTService.templateInstanceStatus().then(function (response) {
                                    $rootScope.templateInstanceStatus = response;
                                    return $rootScope.templateInstanceStatus;
                                });
                            } else {
                                return $rootScope.templateInstanceStatus;
                            }
                        }
                    ],
                    hasTemplateConfigPermission: [
                        "AuthenticationService",
                        "NMS_FEATURES",
                        function (AuthenticationService, NMS_FEATURES) {
                            return AuthenticationService.isAuthenticated().then(function () {
                                return AuthenticationService.hasPermission(NMS_FEATURES.templateConfig.feature);
                            });
                        }
                    ],
                    favoriteVariables: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.favoriteVariables();
                        }
                    ],
                    availableFields: resolveAvailableFields
                }
            })
            // TEMPLATE INSTANCE
            .state(NMS_STATES.templateInstance.list, {
                url: "/templateinstance",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/template/template-instance/template-instance-list.html",
                        controller: "TemplateInstanceListCtrl"
                    }
                },
                data: {
                    pageTitle: "title.templateInstance",
                    feature: NMS_FEATURES.templateInstance.feature
                },
                params: {
                    templateNames: null,
                    devicesNames: null,
                    cpesSerialNumbers: null
                },
                resolve: {
                    status: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateInstanceStatus();
                        }
                    ],
                    searchCriteria: [
                        "ConfigRESTService",
                        "MODULE",
                        function (ConfigRESTService, MODULE) {
                            return ConfigRESTService.searchCriteria(MODULE.templateInstance);
                        }
                    ],
                    equipmentStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.status) {
                                ConfigRESTService.equipmentStatus().then(function (response) {
                                    $rootScope.status = response;
                                    return $rootScope.status;
                                });
                            } else {
                                return $rootScope.status;
                            }
                        }
                    ],
                    templateInstanceStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.templateInstanceStatus) {
                                ConfigRESTService.templateInstanceStatus().then(function (response) {
                                    $rootScope.templateInstanceStatus = response;
                                    return $rootScope.templateInstanceStatus;
                                });
                            } else {
                                return $rootScope.templateInstanceStatus;
                            }
                        }
                    ]
                }
            })
            .state(NMS_STATES.templateInstance.add, {
                url: "/add/:templateType",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    "main@": {
                        template: "<nms-template-instance></nms-template-instance>"
                    }
                },
                data: {
                    pageTitle: "title.addTemplateInstance",
                    feature: NMS_FEATURES.templateInstance.feature,
                    route: NMS_FEATURES.templateInstance.routesFromFeature.templateInstanceAdd.feature,
                    css: ["styles/features/templates/template-instance-application-status.css"]
                },
                params: {
                    templates: null,
                    equipment: null,
                    templateType: null,
                    onFinishOrCancelRedirectRoute: NMS_STATES.templateInstance.list
                },
                resolve: {
                    scheduledTemplateApplication: function (): ScheduledTemplateApplication {
                        return { templateInstance: null, schedulerJob: null };
                    },
                    rulesMatchingModes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.rulesMatchingModes();
                        }
                    ],
                    deviceModelRestrictionTypes: [
                        "$stateParams",
                        "ConfigRESTService",
                        function ($stateParams, ConfigRESTService) {
                            const templateType = $stateParams.templateType.toUpperCase();
                            return ConfigRESTService.getRuleRestrictionTypes(templateType);
                        }
                    ],
                    rulesAvailableFields: [
                        "$stateParams",
                        "ConfigRESTService",
                        function ($stateParams, ConfigRESTService) {
                            const templateType = $stateParams.templateType.toUpperCase();
                            return ConfigRESTService.getRuleAvailableFields(templateType);
                        }
                    ],
                    variableTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateVariableTypes();
                        }
                    ],
                    equipmentStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.status) {
                                ConfigRESTService.equipmentStatus().then(function (response) {
                                    $rootScope.status = response;
                                    return $rootScope.status;
                                });
                            } else {
                                return $rootScope.status;
                            }
                        }
                    ],
                    templateInstanceStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.templateInstanceStatus) {
                                ConfigRESTService.templateInstanceStatus().then(function (response) {
                                    $rootScope.templateInstanceStatus = response;
                                    return $rootScope.templateInstanceStatus;
                                });
                            } else {
                                return $rootScope.templateInstanceStatus;
                            }
                        }
                    ],
                    hasSchedulerConfigPermission
                }
            })
            .state(NMS_STATES.templateInstance.clone, {
                url: "/clone",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    "main@": {
                        template: "<nms-template-instance></nms-template-instance>"
                    }
                },
                data: {
                    pageTitle: "title.addTemplateInstance",
                    feature: NMS_FEATURES.templateInstance.feature,
                    route: NMS_FEATURES.templateInstance.routesFromFeature.templateInstanceClone.feature,
                    css: ["styles/features/templates/template-instance-application-status.css"]
                },
                params: {
                    templateInstance: null,
                    onFinishOrCancelRedirectRoute: NMS_STATES.templateInstance.list
                },
                resolve: {
                    scheduledTemplateApplication: [
                        "TemplateInstanceService",
                        "$stateParams",
                        "$q",
                        function (TemplateInstanceService: TemplateInstanceService, $stateParams, $q) {
                            var deferred = $q.defer();
                            TemplateInstanceService.getScheduledTemplateApplicationAndConvertVars(
                                $stateParams.templateInstance.id
                            ).then(function success(scheduledTemplateApplication: ScheduledTemplateApplication) {
                                const data = scheduledTemplateApplication.templateInstance;
                                delete data.id;
                                delete data.status;
                                delete scheduledTemplateApplication.schedulerJob;
                                data.name = data.name.concat("_clone");
                                data.equipmentAssociations.forEach(function (equipmentAssociation) {
                                    delete equipmentAssociation.status;
                                    equipmentAssociation.templateAssociations.forEach(function (templateAssociation) {
                                        delete templateAssociation.status;
                                        delete templateAssociation.previouslyApplied;
                                    });
                                });
                                deferred.resolve(scheduledTemplateApplication);
                            });

                            return deferred.promise;
                        }
                    ],
                    rulesMatchingModes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.rulesMatchingModes();
                        }
                    ],
                    deviceModelRestrictionTypes: [
                        "$stateParams",
                        "ConfigRESTService",
                        function ($stateParams, ConfigRESTService) {
                            const templateType = $stateParams.templateInstance.type.toUpperCase();
                            return ConfigRESTService.getRuleRestrictionTypes(templateType);
                        }
                    ],
                    rulesAvailableFields: [
                        "$stateParams",
                        "ConfigRESTService",
                        function ($stateParams, ConfigRESTService) {
                            const templateType = $stateParams.templateInstance.type.toUpperCase();
                            return ConfigRESTService.getRuleAvailableFields(templateType);
                        }
                    ],
                    variableTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateVariableTypes();
                        }
                    ],
                    equipmentStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.status) {
                                ConfigRESTService.equipmentStatus().then(function (response) {
                                    $rootScope.status = response;
                                    return $rootScope.status;
                                });
                            } else {
                                return $rootScope.status;
                            }
                        }
                    ],
                    templateInstanceStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.templateInstanceStatus) {
                                ConfigRESTService.templateInstanceStatus().then(function (response) {
                                    $rootScope.templateInstanceStatus = response;
                                    return $rootScope.templateInstanceStatus;
                                });
                            } else {
                                return $rootScope.templateInstanceStatus;
                            }
                        }
                    ],
                    hasSchedulerConfigPermission
                }
            })
            .state(NMS_STATES.templateInstance.edit, {
                url: "/edit",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    "main@": {
                        template: "<nms-template-instance></nms-template-instance>"
                    }
                },
                data: {
                    pageTitle: "title.editTemplateInstance",
                    feature: NMS_FEATURES.templateInstance.feature,
                    route: NMS_FEATURES.templateInstance.routesFromFeature.templateInstanceEdit.feature,
                    css: ["styles/features/templates/template-instance-application-status.css"]
                },
                params: {
                    templateInstance: null,
                    activeTab: null,
                    disableNavigation: false,
                    onFinishOrCancelRedirectRoute: NMS_STATES.templateInstance.list
                },
                resolve: {
                    scheduledTemplateApplication: [
                        "TemplateInstanceService",
                        "$stateParams",
                        function (TemplateInstanceService: TemplateInstanceService, $stateParams) {
                            const templateInstanceId = $stateParams.templateInstance.id;
                            return TemplateInstanceService.getScheduledTemplateApplicationAndConvertVars(templateInstanceId);
                        }
                    ],
                    rulesMatchingModes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.rulesMatchingModes();
                        }
                    ],
                    deviceModelRestrictionTypes: [
                        "$stateParams",
                        "ConfigRESTService",
                        function ($stateParams, ConfigRESTService) {
                            const templateType = $stateParams.templateInstance.type.toUpperCase();
                            return ConfigRESTService.getRuleRestrictionTypes(templateType);
                        }
                    ],
                    rulesAvailableFields: [
                        "$stateParams",
                        "ConfigRESTService",
                        function ($stateParams, ConfigRESTService) {
                            const templateType = $stateParams.templateInstance.type.toUpperCase();
                            return ConfigRESTService.getRuleAvailableFields(templateType);
                        }
                    ],
                    variableTypes: [
                        "ConfigRESTService",
                        function (ConfigRESTService) {
                            return ConfigRESTService.templateVariableTypes();
                        }
                    ],
                    equipmentStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.status) {
                                ConfigRESTService.equipmentStatus().then(function (response) {
                                    $rootScope.status = response;
                                    return $rootScope.status;
                                });
                            } else {
                                return $rootScope.status;
                            }
                        }
                    ],
                    templateInstanceStatus: [
                        "$rootScope",
                        "ConfigRESTService",
                        function ($rootScope, ConfigRESTService) {
                            if (!$rootScope.templateInstanceStatus) {
                                ConfigRESTService.templateInstanceStatus().then(function (response) {
                                    $rootScope.templateInstanceStatus = response;
                                    return $rootScope.templateInstanceStatus;
                                });
                            } else {
                                return $rootScope.templateInstanceStatus;
                            }
                        }
                    ],
                    hasSchedulerConfigPermission
                }
            })
            // DEVICE SUMMARY
            .state(NMS_STATES.deviceSummary, {
                url: "/device/summary/:name",
                params: {
                    device: null,
                    globalConfig: null
                },
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/device-summary/device-summary.html",
                        controller: "DeviceSummaryController"
                    }
                },
                data: {
                    pageTitle: "title.deviceSummary",
                    feature: NMS_FEATURES.summary.feature,
                    css: ["styles/features/device-summary.css"]
                },
                resolve: {
                    device: resolveDevice,
                    monitoringSettings: getMonitoringSettings
                }
            })
            // DISCOVER/ADD DEVICES
            .state(NMS_STATES.deviceDiscover, {
                url: "/device/discover",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/device-discover/device-discover.html",
                        controller: "DeviceDiscoverController"
                    }
                },
                data: {
                    pageTitle: "title.findAddDevices",
                    feature: NMS_FEATURES.discoverAndAddDevices.feature,
                    css: [
                        "styles/features/device-discover.css"
                        // "styles/features/dynamic-device.css"
                    ]
                },
                resolve: {
                    formData: function () {
                        return options.formData;
                    },
                    globalCredentials: function () {
                        return options.globalCredentials;
                    },
                    specificCredentials: function () {
                        return options.specificCredentials;
                    },
                    guiSettings: function () {
                        return options.guiSettings;
                    },
                    isMultipleSelection: function () {
                        return options.isMultipleSelection;
                    }
                }
            })
            //BACKUP DEVICES
            .state(NMS_STATES.deviceConfigurationBackup, {
                url: "/device/backup",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        template: "<device-backup></device-backup>"
                    },
                    params: {
                        redirect: true
                    }
                },
                data: {
                    pageTitle: "title.backupDevices",
                    feature: NMS_FEATURES.configureBackup.feature
                }
            })
            // GPON_ONUS
            .state(NMS_STATES.gponOnus, {
                url: "/device/gponOnus/:name",
                params: {
                    device: null,
                    globalConfig: null,
                    filters: null,
                    loadTablePreferences: true
                },
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/gpon-onus/gpon-onus.html",
                        controller: "GponOnusController"
                    }
                },
                data: {
                    pageTitle: "title.gponOnus",
                    feature: NMS_FEATURES.gponOnus.feature,
                    css: ["styles/features/gpon-onus.css"]
                },
                resolve: {
                    device: [
                        "$stateParams",
                        "ManagementDeviceRestService",
                        "DeviceAugmentService",
                        function ($stateParams, ManagementDeviceRestService, DeviceAugmentService) {
                            if ($stateParams.name && (!$stateParams.device || $stateParams.device.name !== $stateParams.name)) {
                                var httpConfig: any = { redirectToOnError: NMS_STATES.manageDevices };
                                return ManagementDeviceRestService.getDeviceByName(
                                    $stateParams.name,
                                    httpConfig,
                                    function (device) {
                                        $stateParams.device = DeviceAugmentService.resolveDevice(device);
                                    }
                                );
                            } else if (_.isEmpty($stateParams.name) && !_.isEmpty($stateParams.device)) {
                                delete $stateParams.device;
                            }

                            return true;
                        }
                    ],
                    monitoringSettings: getMonitoringSettings
                }
            })
            // NEW GPON ONUS
            .state(NMS_STATES.newGponOnus, {
                url: "/device/newGponOnus/:name",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        template: "<gpon-onus></gpon-onus>"
                    }
                },
                data: {
                    pageTitle: "title.gponOnus",
                    feature: NMS_FEATURES.gponOnus.feature
                },
            })
            //TR-069 CPEs
            .state(NMS_STATES.cpes, {
                url: "/device/cpes",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        template: "<cpes></cpes>"
                    },
                    params: {
                        redirect: true
                    }
                },
                data: {
                    pageTitle: "title.cpes",
                    feature: NMS_FEATURES.tr069Cpes.feature
                }
            })
            // MANAGE DEVICE
            .state(NMS_STATES.manageDevices, {
                url: "/device/manage",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/manage-devices/manage-devices.html",
                        controller: "ManageDevicesController"
                    }
                },
                data: {
                    pageTitle: "title.devices",
                    feature: NMS_FEATURES.dynamicDeviceConfig.feature,
                    css: ["styles/features/manage-devices.css"]
                },
                resolve: {
                    monitoringSettings: getMonitoringSettings
                }
            })
            // DYNAMIC DEVICE
            .state(NMS_STATES.dynamicDevice, {
                url: "/device/:name?{path}",
                params: {
                    fullPath: null,
                    pathKeys: null,
                    displayName: null,
                    path: {
                        value: null
                    }
                },
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/dynamic-device/dynamic-device.html",
                        controller: "DynamicDeviceController"
                    }
                },
                data: {
                    pageTitle: "title.deviceConfiguration",
                    feature: NMS_FEATURES.dynamicDeviceConfig.feature,
                    css: [
                        // "styles/features/dynamic-device.css",
                        "external/custom/resizable/resizable.css"
                    ]
                },
                resolve: {
                    device: resolveDevice,
                    monitoringSettings: getMonitoringSettings,
                    hasConfigPermission: [
                        "AuthenticationService",
                        "NMS_FEATURES",
                        function (AuthenticationService, NMS_FEATURES) {
                            return AuthenticationService.isAuthenticated().then(function () {
                                return AuthenticationService.hasPermission(NMS_FEATURES.infoConfigConfig.feature);
                            });
                        }
                    ]
                }
            })
            .state(NMS_STATES.management.monitoring, {
                url: "/monitoring-configuration",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/management/monitoring/monitoring-configuration.html",
                        controller: "MonitoringConfigurationController"
                    }
                },
                data: {
                    pageTitle: "zabbix.configuration.title",
                    feature: NMS_FEATURES.monitoringConfiguration.feature,
                    css: ["/styles/features/management/monitoring.css"]
                }
            })
            .state(NMS_STATES.management.users, {
                url: "/manage-users",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/management/users/users-configuration.html",
                        controller: "ManageUsersController"
                    }
                },
                data: {
                    pageTitle: "title.management.users",
                    feature: NMS_FEATURES.manageUsers.feature,
                    css: ["/styles/features/management/users.css"]
                }
            })
            .state(NMS_STATES.management.groups, {
                url: "/manage-groups",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/features/management/groups/groups-configuration.html",
                        controller: "ManageGroupsController"
                    }
                },
                data: {
                    pageTitle: "title.management.groups",
                    feature: NMS_FEATURES.manageGroups.feature,
                    css: ["/styles/features/management/groups.css", "/styles/features/management/users.css"]
                }
            })
            .state(NMS_STATES.management.acsCredentials, {
                url: "/acs-credentials",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        template: "<acs-credentials></acs-credentials>"
                    },
                    params: {
                        redirect: true
                    }
                },
                data: {
                    pageTitle: "title.management.acsCredentials",
                    feature: NMS_FEATURES.manageAcsCredentials.feature
                }
            })
            .state(NMS_STATES.cwmpParameters, {
                url: "/parameters/:serialNumber",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        template: "<cwmp-parameters></cwmp-parameters>"
                    },
                    params: {
                        hostname: null
                    }
                },
                data: {
                    pageTitle: "title.parameters",
                    feature: NMS_FEATURES.cwmpParameters.feature
                },
                resolve: {
                    cpe: [
                        "$state",
                        "$stateParams",
                        "Restangular",
                        "NMS_ERRORS",
                        function ($state, $stateParams, Restangular, NMS_ERRORS) {
                            return Restangular.one(`/acs-client/cpe/by-serial-number/${$stateParams.serialNumber}`)
                                .withHttpConfig({ bypassInterceptor: true })
                                .get()
                                .then(
                                    (cpeEntity) => {
                                        return ($stateParams.cpeEntity = cpeEntity.plain());
                                    },
                                    (errorResponse) => {
                                        if (
                                            errorResponse.data &&
                                            errorResponse.data.code === NMS_ERRORS.ACS_COULD_NOT_ACCESS_SERVER_ERROR
                                        ) {
                                            $state.go(NMS_STATES.unavailableAcsServer, { redirect: true });
                                        } else {
                                            return Promise.reject(errorResponse);
                                        }
                                    }
                                );
                        }
                    ]
                }
            })
            .state(NMS_STATES.unavailableAcsServer, {
                url: "/unavailable-acs-server",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        templateUrl: "templates/components/ui/unavailable-acs-server.html"
                    }
                },
                params: {
                    redirect: false
                },
                data: {
                    pageTitle: "unavailable.acs.server.title"
                }
            })
            .state(NMS_STATES.management.managementProtocols, {
                url: "/management-protocols",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        template: "<global-management-protocols></global-management-protocols>"
                    }
                },
                data: {
                    pageTitle: "title.managementProtocols",
                    feature: NMS_FEATURES.managementProtocolConfiguration.feature
                }
            })
            .state(NMS_STATES.userManagement.preferences, {
                url: "/preferences",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        template: "<user-preferences></user-preferences>"
                    }
                },
                data: {
                    pageTitle: "title.user.preferences",
                    feature: NMS_FEATURES.userPreferences.feature
                }
            })
            // Scheduler
            .state(NMS_STATES.scheduler.list, {
                url: "/scheduler",
                views: {
                    loading: {
                        templateUrl: "templates/components/ui/loading-template.html"
                    },
                    header: {
                        templateUrl: "templates/components/ui/header/nav.html"
                    },
                    main: {
                        template: "<scheduler-list></scheduler-list>"
                    },
                    params: {
                        redirect: true
                    }
                },
                data: {
                    pageTitle: "scheduler.title",
                    feature: NMS_FEATURES.scheduler.feature
                }
            });

        $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get("$state");
            var authenticationService = $injector.get("AuthenticationService");
            $state.go(NMS_STATES.login);

            authenticationService.isAuthenticated().then(function (isAuthenticated) {
                if (isAuthenticated) {
                    var firstAllowedFeature = authenticationService.getFirstAllowedFeature();
                    $state.go(firstAllowedFeature.state);
                } else {
                    var ssoService = $injector.get("SsoService");
                    ssoService.isNamEnabled().then(function (namEnabled) {
                        $state.go(namEnabled ? NMS_STATES.namCredentials : NMS_STATES.login);
                    });
                }
            });
        });
    }
]);
