"use strict";

var app = angular.module("nms");

/**
 * @ngdoc directive.
 * @name nms.templates.directive:tableResizable.
 * @description Diretiva que habilita redimensionar a largura das colunas da tabela.
 */
app.directive("tableResizable", ["$timeout", "$rootScope",
    function($timeout, $rootScope) {
        return {
            restrict: "C",
            link: function(scope, element, attrs) {
                // TODO: Encontrar um meio melhor de generalizar o uso desta diretiva
                var isYangList = (_.get(scope.$parent, "list.template")) ? true : false;
                var isOverflowTable = _.includes(element[0].className, "table-overflow");
                var minWidth = attrs.minColumnWidth || 75;


                var execute = function(flush) {
                    $timeout(function() {
                        var ngTableDynamic = attrs.ngTableDynamic;
                        // Tabela usa a diretiva ng-table e é do tipo dynamic
                        if (ngTableDynamic) {
                            var parts = ngTableDynamic.split(/\s+with\s+/);
                            var columns = _.get(scope.$parent, parts[1]);
                            columns = _.filter(columns, {show: true});
                        }

                        element.colResizable({disable: true});

                        element.find("thead th.header").each(function(index, headerColumn) {
                            var width = (columns && columns.length > 0) ? columns[index].width : null;
                            if (width) {
                                angular.element(headerColumn).width(width);
                            } else {
                                if(angular.element(headerColumn).width() < minWidth) {
                                    angular.element(headerColumn).width(minWidth);
                                }
                            }
                        });

                        element.colResizable({
                            liveDrag: true,
                            draggingClass: "dragging",
                            postbackSafe: true,
                            partialRefresh: true,
                            headerOnly: true,
                            flush: flush,
                            resizeMode: (isYangList || isOverflowTable) ? "overflow" : "fit",
                            useLocalStorage: true,
                            onDrag: function() {
                                $rootScope.$broadcast("tdResize", {});
                            }
                        });
                        $rootScope.$broadcast("tdResize", {});
                    });
                };

                var unregister = $rootScope.$on("tableColumnsHasChange", function(event, flush) {
                    execute(flush);
                });

                scope.$on("$destroy", function() {
                    unregister();
                });

                execute(false);
            }
        };
    }
]);
