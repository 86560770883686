import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EquipmentInfo } from "@nms-ng2/app/modules/template/services/equipment.service";
import { Observable } from "rxjs";

/**
 * Classe responsável por realizar as requisições disponibilizadas pelo serviço de inventory
 */
@Injectable({
    providedIn: "root"
})
export class InventoryService {

    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = "/inventory";
    }

    public getDevicesCount(): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/devices/licence-added-devices`)
    }

    public getEquipmentInfoListByIds(resourceIds: Array<number>): Observable<Array<EquipmentInfo>> {
        return this.http.post<Array<EquipmentInfo>>(`${this.baseUrl}/equipment-info/resourceIds`, resourceIds);
    }
}
