/**
* @ngDoc service
* @name LabelColorService
* @description Calcule the best color contrast for foreground elements using background color.
*/
var app = angular.module("nms");

app.service("LabelColorService", [
    function() {
        var blackClass = "text-black";
        var whiteClass = "text-white";

        /**
         * Calcula a distância do background com cores claras e escuras.
         */
        this.calculateYiq = function(backgroundColor) {
            var isHexColor = /^#([a-fA-F\d]{3}){1,2}$/.test(backgroundColor);
            backgroundColor = isHexColor ? this.hexToRgb(backgroundColor) : backgroundColor;

            var isTransparentBackground = _.contains(backgroundColor, "rgba");

            if (isTransparentBackground) {
                return 128;
            }

            var rgbColors = backgroundColor.match(/\d+/g);
            var red = rgbColors[0];
            var green = rgbColors[1];
            var blue = rgbColors[2];

            return ((red * 299) + (green * 587) + (blue * 114)) / 1000;
        }

        /**
         * Retorna a melhor cor a ser usada no texto de acordo com o YIQ calculado de acordo com o background.
         * Os valores podem ser:
         * - #000: preto (YIQ >= 128)
         * - #fff: branco (YIQ < 128)
         */
        this.getBestColorContrastForForeground = function(backgroundColor) {
            return this.calculateYiq(backgroundColor) >= 128 ? "#000" : "#fff";
        };

        /**
         * Retorna a melhor classe a ser usada no texto de acordo com o background.
         * Os valores podem ser:
         * - text-black (YIQ >= 128)
         * - text-white (YIQ < 128)
         */
        this.getBestColorContrastClassForForeground = function(backgroundColor) {
            return this.calculateYiq(backgroundColor) >= 128 ? blackClass : whiteClass;
        };

        this.hexToRgb = function(colorHex) {
            var rgbColors = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorHex);
            rgbColors = _.without(rgbColors, colorHex);

            var formmattedColors = _.reduce(rgbColors, function(colors, color) {
                return ((colors === "") ? colors : colors + ",") + parseInt(color, 16);
            });

            return "rgb(" + formmattedColors + ")";
        };
    }
]);
