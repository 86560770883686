/**
 * Classe que representa o modelo das credenciais globais do ACS.
 * Esse modelo contém os campos necessários para registrar os dados na base de dados.
 */
export class AcsGlobalCredentials {
    acsUri: string;
    acsPrefsBean: string;
    acsUser: string;
    acsPassword: string;
    cpeConnectionRequestUser: string;
    cpeConnectionRequestPassword: string;
    cpeConnectionRequestTimeout: number;
}