var app = angular.module("nms.dynamicDevice");

app.directive("leafHeader", ["RecursionHelper",
    function(RecursionHelper) {
        return {
            restrict: "E",
            scope: {
                leaf: "=",
                isKey: "=",
                config: "=",
                isSingleEditing: "=?",
                pathKeys: "="
            },
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/leaf-header.html",
            compile: function(element) {
                return RecursionHelper.compile(element);
            }
        };
    }
]);
