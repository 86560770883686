import { Pipe, PipeTransform } from '@angular/core';
import { TranslatedText, TranslationKey } from '../models/translation.models';
import { TranslationHelperService } from '../services/util/translation-helper.service';

/**
 * A pipe which its purpose is to be of similar use to $translate
 * reusing it internally to maintain the same behaviour.
 */
@Pipe({name: 'translate'})
export class TranslatePipe implements PipeTransform {

    constructor(private readonly translationHelper: TranslationHelperService) {}

    transform(value: TranslationKey): TranslatedText {
        return this.translationHelper.translate(value);
    }
}