"use strict";

/**
* @ngdoc service
* @name nms.ManagePermissionsModelConverter
* @description - Conversor do modelo de dados de permissões.
*/
var app = angular.module("nms.management");

app.service("ManagePermissionsModelConverter", [
    function() {
        const service: any = {};

        /**
        *   Percorre recursivamente a lista de permissões de localidade, convertendo os elementos para o modelo
        *   {graphicId: 0, permission: 1}.
        */
        var fillLocationPermission = function(locations, location) {
            locations.push({
                graphicId: location.locationBean.locationId,
                permission: parseInt(location.permission.value)
            });

            _.forEach(location.children, function(child) {
                fillLocationPermission(locations, child);
            });
        };

        /**
        * Percorre a lista de regras de permissão selecionadas na aba Templates e converte para o modelo esperado pelo
        * serviço: [{field: "name", type: "CONTAINS", value: "RUNNING CONFIG"}].
        */
        const extractTemplatePermissionsRules = function (rules) {
            return _.map(rules, function (rule) {
                return {
                    field: rule.field,
                    type: rule.ruleType,
                    value: rule.values[0]
                };
            });
        };

        /**
         * Converte o modelo de dados de permissões usado na tela para o modelo de permissões usado pelos serviços.
         * @param {Object} Objeto com os dados de permissões
         * @return {Object} Objeto com os dados de permissões no formato esperado pelo serviço.
         * Estrutura:
         * {
         *   permissionsModel: {
         *       "devicePermissionModels": [
         *           {
         *               "modelCode": 0,
         *               "permission": 0,
         *               "vendorCode": 0
         *           }
         *       ],
         *       "locationPermissionModels": [
         *           {
         *               "graphicId": 0,
         *               "permission": 0
         *           }
         *     ],
         *     "personalPermissionModels": [
         *           {
         *               "instance": "string",
         *               "permission": true
         *           }
         *     ]
         *   },
         *   "userSecuritySettings": {
         *       "loginAccessInitialTime": "string",
         *       "loginAccessFinalTime": "string",
         *       "userTimeoutDelay": 0
         *   }
         * }
         *
         */
        service.convertPermissions = function (permissionsForm) {
            const devicePermissionModels = _.map(permissionsForm.devices.data.devices, function (device) {
                return {
                    modelCode: device.modelCode,
                    permission: device.permission.value,
                    vendorCode: device.vendorCode
                };
            });

            const personalPermissionModels = _.map(permissionsForm.features.data.available.data, function (feature) {
                return {
                    instance: feature.name,
                    permission: _.includes(permissionsForm.features.data.selection.checked, feature)
                };
            });

            var locationPermissionModels = [];
            _.forEach(permissionsForm.locations.data.dataTree, function (location) {
                fillLocationPermission(locationPermissionModels, location);
            });

            var templatePermissionModel = null;
            if (permissionsForm.templates.data.restrictions.useRules) {
                const rules = permissionsForm.templates.data.restrictions.rules;
                templatePermissionModel = {
                    criteria: permissionsForm.templates.data.restrictions.criteria,
                    rules: extractTemplatePermissionsRules(rules)
                };
            }

            return {
                permissionsModel: {
                    devicePermissionModels: devicePermissionModels,
                    personalPermissionModels: personalPermissionModels,
                    locationPermissionModels: locationPermissionModels,
                    templatePermissionModel: templatePermissionModel
                },
                userSecuritySettings: permissionsForm.securitySettings.data
            };
        };

        return service;
    }
]);
