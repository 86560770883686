app.directive("relinkEvent", ["$rootScope",
    function($rootScope) {
        return {
            transclude: "element",
            restrict: "A",
            link: function(scope, element, attr, ctrl, transclude) {
                var previousContent = null;

                var triggerRelink = function() {
                    var contentStyle;
                    if (previousContent) {
                        contentStyle = previousContent.attr("style");
                        previousContent.remove();
                        previousContent = null;
                    }
                    transclude(function(clone) {
                        element.parent().append(clone);
                        previousContent = clone;
                        clone.attr("style", contentStyle);
                    });
                };

                triggerRelink();

                var unregisterRelinkEvent = $rootScope.$on(attr.relinkEvent, triggerRelink);

                $rootScope.$on("$destroy", function() {
                    unregisterRelinkEvent();
                });
            }
        };
    }
]);
