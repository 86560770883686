"use strict";

var app = angular.module("nms.templates");

app.controller("ComplexSearchController", ["$scope", "$timeout", "ConverterService", "$filter", "$translate",
    "UserPreferencesService", "TABLE_PROPERTIES",
    function($scope, $timeout, ConverterService, $filter, $translate, UserPreferencesService, TABLE_PROPERTIES) {
        // variables declared here in order to be used at Unit test, since it cannot be changed
        $scope.datePicker.date = {
            startDate: moment().startOf("day"),
            endDate: moment().endOf("day")
        };
        // Força a tradução do calendário do date-range-picker de acordo com o idioma selecionado
        moment.locale($translate.preferredLanguage());

        $scope.orderSearchCriteriaByFieldName = function() {
            var searchArray = ConverterService.convertMapToArray($scope.searchCriteria);
            searchArray = $filter("orderBy")(searchArray, "value.fieldName");
            $scope.searchCriteria = ConverterService.convertArrayToMap(searchArray);
        };

        $scope.clearSearch = function(type) {
            delete $scope.filters.searchColumn;
            delete $scope.filters.searchTerm;
            delete $scope.filters.searchTermFinal;
            delete $scope.search.field;
            delete $scope.search.value;
            delete $scope.previousSearch.field;
            delete $scope.previousSearch.value;
            delete $scope.dateFinalPreviousSearch;
            $scope.filters.currentType = "TEXT";

            // If keyword is true, it means that keyword has been clicked, so, no need for another search
            if (!type) {
                $scope.searchByCriteria();
            }
        };

        $scope.validateSearchFields = function() {
            if ($scope.filters.currentType === "DATE_RANGE") {
                if ($scope.previousSearch.field !== $scope.search.field ||
                $scope.previousSearch.value !== $scope.search.value ||
                $scope.dateFinalPreviousSearch !== $scope.searchTermFinal) {
                    $scope.searchByCriteria();
                }
            } else {
                if ($scope.previousSearch.field !== $scope.search.field ||
                $scope.previousSearch.value !== $scope.search.value) {
                    if (!$scope.search.value && $scope.filters.currentType === "TEXT") {
                        $scope.clearSearch();
                    } else {
                        if ($scope.search.value && $scope.filters.currentType === "TEXT") {
                            $scope.search.value = $scope.search.value.trim();
                        }
                        $scope.searchByCriteria();
                    }
                }
            }
        };

    /* Search templates by criteria */
        $scope.searchByCriteria = function() {
            $scope.filters.searchColumn = $scope.search.field;

            delete $scope.filters.searchTermFinal;
            delete $scope.filters.searchTerm;

            if ($scope.filters.currentType === "DATE_RANGE") {
                $scope.filters.searchTerm = $scope.datePicker.date.startDate.toDate().getTime();
                $scope.filters.searchTermFinal = + $scope.datePicker.date.endDate.toDate().getTime();
            } else if ($scope.search.value) {
                $scope.filters.searchTerm = encodeURIComponent($scope.search.value);
            }

            // Reset pageNumber
            $scope.filters.pageNumber = 0;
            if (!$scope.filters.pageSize) {
                $scope.filters.pageSize = TABLE_PROPERTIES.DEFAULT_PAGE_SIZE;
            }
            $scope.pageDetails.currentPage = $scope.filters.pageNumber + 1;
            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);

            // Load page with search criteria
            $scope.loadPage();

            $timeout(function() {
                $scope.hasSearch = !!$scope.filters.searchTerm;
            }, 1);
        };

        this.loadTooltipsText = function() {
            $scope.searchToolTip = $translate.instant("tooltips.search.searchToolTip");
        };

        $scope.orderSearchCriteriaByFieldName();
        this.loadTooltipsText();
    }
]);
