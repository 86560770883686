import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_ROOTSCOPE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
 * Encapsula as chamadas de ações de dialog para o Angular.
 */
@Injectable({
    providedIn: "root"
})
export class DialogService {
    constructor(@Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any) { }

    /**
     * Abre dialog para confirmação da ação do usuário.
     * @param needsShowConfirmationModal Função que verifica se a modal de confirmação deverá ou não ser mostrada.
     *                                   Caso esta função retorne false, a modal não será apresentada e o confirmCallbackFn
     *                                   será chamado.
     * @param translateKey Chave de tradução da mensagem a ser apresentada na confirmação da ação.
     * @param confirmCallbackFn Função de callback ao ser executada através do botão confirmar da dialog de confirmação.
     * @param cancelCallbackFn Função de callback que será executada através do botão cancelar da dialog de confirmação.
     */
    confirm(needsShowConfirmationModal: () => boolean, translateKey: string,
        confirmCallbackFn: () => void, cancelCallbackFn?: () => void) {
        if (needsShowConfirmationModal()) {
            this.$rootScope
                .showDialog({
                    translateKey: translateKey,
                    isConfirm: true
                })
                .then(confirmCallbackFn, () => { if (cancelCallbackFn) cancelCallbackFn(); })
        } else {
            confirmCallbackFn();
        }
    }
}