import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SimpleModalComponent } from "ngx-simple-modal";
import { CpeService } from "../device/cpes/cpe.service";
import { InventoryService } from "@nms-ng2/app/shared/services/inventory/inventory.service";
import {
  ANGULARJS_TRANSLATE,
  NMS_LICENSES,
  APPLICATION_BRAND_ICONS,
  LICENSE_SERVICE,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

declare let angular: any;
declare let _: any;

export interface LicenseModalModel {
  title: string;
  iconUrl: string;
  version: string;
  licenseType: string;
  endDate: number;
  deviceCount: any;
  maxDevices: any;
  cpeCountDatacom: number;
  cpeCountMultivendor: number;
  maxCpesDatacom: number;
  maxCpesMultivendor: number;
  brandCode: number;
  productName: string;
  possibleFeatures: string[];
  enabledFeatures: string[];
  installationType: string;
}

const versionTypeNames = {
  ENTERPRISE_LITE: "Enterprise Lite",
  ENTERPRISE_FULL: "Enterprise"
};

@Component({
  selector: "license-modal",
  templateUrl: "./license-modal.component.html",
  styleUrls: ["./license-modal.component.scss"],
})
export class LicenseModalComponent
  extends SimpleModalComponent<LicenseModalModel, any>
  implements LicenseModalModel, OnInit
{
  title: string;
  iconUrl: string;
  version: string;
  licenseType: string;
  endDate: number;
  deviceCount: any;
  maxDevices: any;
  cpeCountDatacom: number;
  cpeCountMultivendor: number;
  maxCpesDatacom: number;
  maxCpesMultivendor: number;
  brandCode: number;
  productName: string;
  possibleFeatures: string[];
  enabledFeatures: string[];
  installationType: string;
  versionDescription: string;

  constructor(
    private http: HttpClient,
    private cpeService: CpeService,
    private inventoryService: InventoryService,
    @Inject(ANGULARJS_TRANSLATE) private translate: any,
    @Inject(NMS_LICENSES) private LICENSES: any,
    @Inject(APPLICATION_BRAND_ICONS) private ApplicationIcons: any,
    @Inject(LICENSE_SERVICE) private LicenseService: any
  ) {
    super();
    let license = LicenseService.getLicense();
    this.title = translate.instant("modals.license.title");
    this.iconUrl = ApplicationIcons[license.product.brandCode];
    this.version = license.product.swVersion;
    this.licenseType = license.type;
    this.endDate = license.endDate;
    this.maxDevices = license.nes;
    this.maxCpesDatacom = license.maxCpesDatacom;
    this.maxCpesMultivendor = license.maxCpesMultivendor;
    this.brandCode = license.product.brandCode;
    this.productName = license.product.productName;
    this.possibleFeatures = _.map(_.omit(LICENSES, "notRequired"));
    this.enabledFeatures = license.features;
    this.installationType = license.product.installationType;
    this.versionDescription = this.getVersionDescription();
  }

  ngOnInit() {
    this.cpeService.getCpeCount().subscribe((data) => {
      this.cpeCountDatacom = data.cpesDatacom;
      this.cpeCountMultivendor = data.cpesMultivendor;
    });

    this.inventoryService.getDevicesCount().subscribe((data) => {
      this.deviceCount = data;
    });
  }

  getVersionDescription() {
    if (this.installationType == "LOCAL_ACCESS") {
      return "Local Access";
    }

    return versionTypeNames[this.licenseType];
  }

  getExpirationText() {
    let locale = this.translate.preferredLanguage().replace("_", "-");
    let dateOptions = { year: "numeric", month: "long", day: "numeric" };
    let expirationDate = new Date(this.endDate).toLocaleDateString(
      locale,
      dateOptions
    );

    return this.translate
      .instant("modals.license.expiresOn")
      .replace("{0}", expirationDate);
  }

  getDeviceUsageText() {
    return this.translate
      .instant("modals.license.deviceUsage")
      .replace("{0}", this.deviceCount)
      .replace("{1}", this.maxDevices);
  }

  getCpeDatacomUsageText() {
    return this.translate
      .instant("modals.license.cpeDatacomUsage")
      .replace("{0}", this.cpeCountDatacom)
      .replace("{1}", this.maxCpesDatacom);
  }

  getCpeMultivendorUsageText() {
    return this.translate
      .instant("modals.license.cpeMultivendorUsage")
      .replace("{0}", this.cpeCountMultivendor)
      .replace("{1}", this.maxCpesMultivendor);
  }

  getDeviceUsage() {
    return (this.deviceCount / this.maxDevices) * 100;
  }

  getCpeDatacomUsage() {
    return (this.cpeCountDatacom / this.maxCpesDatacom) * 100;
  }

  getCpeMultivendorUsage() {
    return (this.cpeCountMultivendor / this.maxCpesMultivendor) * 100;
  }
}
