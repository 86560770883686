/**
 * Controller para diretiva leafListsController.
 *
 * @author joao.otavio
 */
var app = angular.module("nms.dynamicDevice");

app.controller("LeafListsController", ["$scope", "ngDialog",
    function($scope, ngDialog) {
        $scope.openModalLeaflist = function(leaflist) {
            ngDialog.openConfirm({
                template: "/templates/features/dynamic-device/domain/yang/leaflist/leaflist-modal.html",
                controller: "LeafListModalController",
                className: "small-modal",
                closeByDocument: false,
                closeByNavigation: false,

                resolve: {
                    leafListTemplate: function() {
                        return leaflist;
                    },

                    config: function() {
                        return $scope.config;
                    },

                    labelModal: function() {
                        return leaflist.id;
                    },

                    parentPath: function() {
                        return null;
                    },

                    modalCloseCallback: function() {
                        return function() {};
                    }
                }
            });
        };
    }
]);
