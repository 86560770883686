"use strict";

/**
* @ngdoc function
* @name nms.templates.controller:SearchTypeController
* @description
* # SearchTypeController
* Controller of the nms.templates
*/
var app = angular.module("nms.templates");

app.controller("SearchTypeController", ["$scope", "UserPreferencesService", "TABLE_PROPERTIES",
    function($scope, UserPreferencesService, TABLE_PROPERTIES) {
        $scope.filterByType = function(type) {
            if (type) {
                $scope.filters.pageNumber = 0;
                $scope.pageDetails.currentPage = $scope.filters.pageNumber + 1;
                $scope.filters.searchTerm = $scope.search.value = type.type;
                $scope.filters.searchColumn = $scope.search.field = "type";
                $scope.filters.currentType = "OPTION";
                delete $scope.filters.searchTermFinal;
            } else {
                $scope.clearSearch(true);
            }

            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);

            if (!$scope.pageDetails.currentPage) {
                $scope.pageDetails.currentPage = 0;
            }

            if (!$scope.filters.pageSize) {
                $scope.filters.pageSize = TABLE_PROPERTIES.DEFAULT_PAGE_SIZE;
            }

            $scope.loadPage();
        };
    }
]);
