"use strict";

/*
* @ngDoc          Service
* @name           nms.FieldsValidatorService
* @description    Validador para inputs contidos em um formulário.
*                 Faz o uso do atributo $error para extrair os erros. Desta forma, validadores padrão do html (maxlength,
*                 minlength, pattern, etc) serão identificados por padrão. Validadores custom, adicionados em field.@validators
*                 também serão verificados por exemplo: allowed-value-validator-directive.js).
*/
var app = angular.module("nms");
app.service("FieldsValidatorService",
    function() {
        const service: any = {};

        var getUniqueErrors = function(errorList) {
            return _.reduce(errorList, function(result, error) {
                var foundIndex = _.findIndex(result, function(element) {
                    return element.formName === error.formName && element.type === error.type && element.field === error.field;
                });
                if (foundIndex < 0) {
                    result.push(error);
                }
                return result;
            }, []);
        };

        var findErrors = function(errorList, form) {
            _.each(form.$error, function(error, errorType) {
                if (_.isArray(error)) {
                    _.each(error, function(formError) {
                        findErrors(errorList, formError);
                    });
                } else {
                    errorList.push({
                        type: errorType,
                        formName: _.get(form, "$$parentForm.$name"),
                        field: form.$name
                    });
                }
            });
        };

        /*
         * @ngDoc               method
         * @name                nms.FieldsValidatorService#extractFormErrors
         * @description         Extrai todos os erros presentes em um formulário.
         * @param   {form}      Contém todos os atributos de um formulário.
         * @returns {Object[]}  Retorna os erros encontrados, caso contrário undefined.
         */
        service.extractFormErrors = function(form) {
            var errorList = [];
            if (form && form.$invalid) {
                findErrors(errorList, form);

                return getUniqueErrors(errorList);
            }

            return undefined;
        };

        return service;
    }
);
