"use strict";

var app = angular.module("nms");

/**
 * Serviço para manter as operações realizadas sobre preferências do usuário no LocalStorage e na SessionStorage.
 * Grava, recupera e remove objetos no LocalStorage e na SessionStorage.
 *
 * Considera que as informações salvas ou recuperadas devem apresentar a identificação do usuário logado no momento.
*/
app.factory("UserPreferencesService", ["$window", "$rootScope",
    function($window, $rootScope) {
        const service: any = {};

        var createIdentifier = function(windowIdentifier, propertyName?) {
            var identifiers = [$rootScope.loggedUser, windowIdentifier, propertyName];

            return _.compact(identifiers).join(" - ");
        };

        /**
         * Método para armazenar os nomes das propriedades dos objetos "filter" que devem ser armazenadas e restauradas.
         *
         * @return {array} Uma lista com os nomes das propriedades a serem utilizadas no processo de armazenamento e restauração.
         */
        var availableFiltersKeys = function() {
            return ["pageSize", "pageNumber", "column", "direction", "searchValue", "searchTerm", "searchTermFinal", "typeSearch",
                "currentType", "searchColumn", "keyword", "searchName", "showOnlyAllowedTemplates"];
        };

        var _load = function(identifier, storage) {
            return storage.getItem(identifier);
        };

        var _save = function(identifier, object, storage) {
            storage.setItem(identifier, object);
        };

        var _remove = function(identifier, storage) {
            storage.removeItem(identifier);
        };

        /**
         * Salva o objeto passado como parâmetro no objeto storage também passado como parâmetro.
         * O objeto será salvo como uma string formatada como um JSON.
         * Caso alguma key não esteja presente nas propriedades a serem salvas, qualquer valor relativo a esta key presente na
         * storage será removido.
         *
         * @param {object} properties Objeto com as propriedades que deve ser salvas.
         * @param {string} windowIdentifier Identificador da tela utilizando o objeto a ser salvo.
         * @param {array} keys Array com os nomes das propriedades que devem ser salvos.
         * @param {object} storage Objeto que irá realizar as operações sobre as propriedades.
         */
        var _saveOrRemoveProperties = function(properties, windowIdentifier, keys, storage) {
            keys.forEach(function(key) {
                var identifier = createIdentifier(windowIdentifier, key);

                if (properties[key] != null) {
                    _save(identifier, angular.toJson(properties[key]), storage);
                } else {
                    _remove(identifier, storage);
                }
            });

            return properties;
        };

        /**
         * Busca propriedades salvas na storage conforme o array de keys passado como parâmetro,
         * retornando um objeto contendo os valores encontrados.
         *
         * @param {object} properties Objeto com as propriedades que deve ser carregadas.
         * @param {string} windowIdentifier Identificador da tela utilizando o objeto a ser carregado.
         * @param {array} keys Array com os nomes das propriedades que devem ser carregados.
         * @param {object} storage Objeto que irá realizar as operações sobre as propriedades.
         */
        var _loadStoredProperties = function(properties, windowIdentifier, keys, storage) {
            keys.forEach(function(key) {
                var identifier = createIdentifier(windowIdentifier, key);

                var storedValue = _load(identifier, storage);

                if (storedValue) {
                    properties[key] = angular.fromJson(storedValue);
                }
            });

            return properties;
        };

        /**
         * Salva o objeto passado como parâmetro ngTableParams na LocalStorage.
         * O objeto será salvo como uma string formatada como um JSON.
         *
         * @param {string} windowIdentifier Identificador da tela utilizando o objeto a ser salvo.
         * @param {object} ngTableParams Objeto com as informações que devem ser salvas. Deste objeto, somente o retorno da função
         * parameters() será serializado e salvo.
        */
        service.saveTablePreferences = function(windowIdentifier, ngTableParams) {
            var identifier = createIdentifier(windowIdentifier);
            var parameters: any = {tableParameters: ngTableParams.parameters()};

            _save(identifier, angular.toJson(parameters), localStorage);
        };

        /**
         * Carrega o objeto com as propriedades salvas no LocalStorage.
         * Caso existam propriedades salvas para o identificador usado como parâmetro, será retornado um objeto representando
         * o mesmo, caso contrário, retornará null.
         *
         * @param {string} windowIdentifier Identificador da tela utilizando o objeto a ser salvo.
         */
        service.loadTablePreferences = function(windowIdentifier) {
            var identifier = createIdentifier(windowIdentifier);
            var parameters = _load(identifier, localStorage);

            return parameters ? angular.fromJson(parameters) : null;
        };

        /**
         * Salva propriedades, conforme as keys passadas, no LocalStorage.
         * Caso alguma key não esteja presente nas propriedades a serem salvas, qualquer valor relativo a esta key presente na
         * LocalStorage será removida.
         *
         * @param {object} properties Objeto com as propriedades que deve ser salvas.
         * @param {string} Identificador da tela utilizando o objeto a ser salvo.
         * @param {array} Array com os nomes das propriedades que devem ser salvos.
         */
        service.savePreferences = function(properties, windowIdentifier, keys) {
            return _saveOrRemoveProperties(properties, windowIdentifier, keys, localStorage);
        };

        /**
         * Busca propriedades salvas na LocalStorage conforme o array de keys passado como parâmetro,
         * retornando um objeto contendo os valores encontrados.
         *
         * @param {object} properties Objeto com as propriedades que deve ser carregadas.
         * @param {string} windowIdentifier Identificador da tela utilizando o objeto a ser carregado.
         * @param {array} keys Array com os nomes das propriedades que devem ser carregados.
         */
        service.loadPreferences = function(properties, windowIdentifier, keys) {
            return _loadStoredProperties(properties, windowIdentifier, keys, localStorage);
        };

        service.removeValue = function(prefix, key) {
            var identifier = createIdentifier(prefix, key);

            _remove(identifier, localStorage);
        };

        service.getValue = function(prefix, key) {
            var identifier = createIdentifier(prefix, key);

            return _load(identifier, localStorage);
        };

        service.savePageFilterValues = function(properties, prefix) {
            return service.savePreferences(properties, prefix, availableFiltersKeys());
        };

        service.loadPageFilterValues = function(properties, prefix) {
            return service.loadPreferences(properties, prefix, availableFiltersKeys());
        };

        service.saveExitValue = function(windowIdentifier, value) {
            var identifier = createIdentifier(windowIdentifier, "exit");

            _save(identifier, value, sessionStorage);
        };

        service.loadExitValue = function(windowIdentifier) {
            var identifier = createIdentifier(windowIdentifier, "exit");

            return _load(identifier, sessionStorage);
        };

        /**
         * Salva dados do usuário na SessionStorage.
         * Os dados serão salvos com usando o usuário logado e windowIdentifier, juntamente com as keys passadas,
         * para montar a chave.
         * Caso não exista uma propriedade para uma das keys passadas, qualquer valor relacionado a esta chave na SessionStorage
         * será removido.
         *
         * @param {object} properties Objeto com as propriedades que deve ser salvas.
         * @param {string} windowIdentifier Identificador da tela utilizando o objeto a ser salvo.
         * @param {array} keys Array com os nomes das propriedades que devem ser salvos.
         */
        service.saveUserData = function(properties, windowIdentifier, keys) {
            return _saveOrRemoveProperties(properties, windowIdentifier, keys, sessionStorage);
        };

        /**
         * Busca propriedades na SessionStorage.
         * Busca as proriedades levando em conta o usuário logado.
         *
         * @param {object} properties Objeto com as propriedades que deve ser carregadas.
         * @param {string} windowIdentifier Identificador da tela utilizando o objeto a ser carregado.
         * @param {array} keys Array com os nomes das propriedades que devem ser carregados.
         */
        service.loadUserData = function(properties, windowIdentifier, keys) {
            return _loadStoredProperties(properties, windowIdentifier, keys, sessionStorage);
        };

        service.setLoggedUser = function(username) {
            $rootScope.loggedUser = username;
        };

        return service;
    }
]);
