import { Injectable } from "@angular/core";
import { WebsocketService } from "@nms-ng2/app/shared/services/websocket/websocket.service";
import { SchedulerJobDetails } from "../../scheduler.models";

export enum SchedulerNotificationType {
    SCHEDULER_UPDATED = "SCHEDULER_UPDATED",
    SCHEDULER_ADDED = "SCHEDULER_ADDED",
    SCHEDULER_DELETED = "SCHEDULER_DELETED"
}
export interface SchedulerBroadcastFeedbackMessage extends SchedulerJobDetails {
    notificationType: SchedulerNotificationType
}

/**
 * Implementação de Atualização via WebSocket para o agendamento.
 */
@Injectable({
    providedIn: "root"
})
export class SchedulerJobWebsocketService extends WebsocketService<SchedulerBroadcastFeedbackMessage> {
    constructor(window: Window) {
        super(window, "service/broadcast/scheduler/notification");
    }
}