"use strict";

/**
* @ngdoc directive
* @name nms.templates.directive:SearchDirective
* @description
* # SearchDirective
*/
angular.module("nms.templates")
.directive("searchDirective", function() {
    return {
        restrict: "E",
        templateUrl: "templates/features/template/components/ui/search/search.html",
        controller: "SearchController"
    };
});
