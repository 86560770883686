import { Component, Input, OnInit } from "@angular/core";
import {
    SelectionDropdownOptionsTitle
} from "@nms-ng2/app/shared/components/elements/selection-dropdown/selection-dropdown.component";
import {
    Trigger,
    Tr069Event,
    InformTriggerData,
    EVENTS_MAP
} from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";

/**
 * Classe responsável por exibir o formulário do agendamento por eventos TR-069
 */
@Component({
    selector: "inform-trigger",
    templateUrl: "./inform-trigger.component.html",
    styleUrls: ["./inform-trigger.component.scss"]
})
export class InformTriggerComponent implements OnInit {
    @Input("informTrigger")
    trigger: Trigger;
    @Input()
    hasSchedulerPermission: boolean;

    public readonly events = Object.values(EVENTS_MAP);
    public readonly eventsSelectTitles: SelectionDropdownOptionsTitle = {
        checkedNone: "scheduler.checkedEvents.empty",
        checkedAll: "scheduler.checkedEvents.all",
        manyItemsSelected: "scheduler.checkedEvents.many"
    };
    public selectedEvents: Array<string> = [];

    ngOnInit(): void {
        this.initSelectedEvents();
    }

    private initSelectedEvents(): void {
        const informTriggerData = this.trigger.triggerData as InformTriggerData;
        this.selectedEvents = informTriggerData.eventCodes.map((eventCode) => EVENTS_MAP[eventCode]);
    }

    /**
     * Converte os eventos selecionados para o formato esperado no InformTriggerData. Essa conversão é necessária
     * para permitir a comunicação com os serviços do backend.
     * Ex: "0 BOOTSTRAP" => "BOOTSTRAP".
     */
    public addSelectedEvents(events: Array<string>): void {
        const informTriggerData = this.trigger.triggerData as InformTriggerData;
        const eventCodes = events.map((eventCode) => this.findTr069Event(eventCode));

        informTriggerData.eventCodes = eventCodes;
    }

    private findTr069Event(eventValue: string): Tr069Event {
        return Object.keys(EVENTS_MAP).find((key) => EVENTS_MAP[key] === eventValue) as Tr069Event;
    }
}
