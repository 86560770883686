"use strict";

var app = angular.module("nms");

/**
* Serviço que auxilia o processamento de filtros de uma tabela.
*/
app.service("FilterProcessorsService", ["UnitConverterService",
    function(UnitConverterService) {
        const service: any = {};
        var VIEW = 0;
        var VALUE = 1;

        /**
         * Converte o valor digitado pelo usuário para um valor absoluto preservando o operador.
         * Exemplo: >1k para >1000
         */
        var getAbsoluteValue = function(operator, value) {
            return operator + UnitConverterService.getAbsoluteValueByDataUnit(value);
        };

        var splitValue = function(value, regex) {
            var regExp = new RegExp(regex, "g");
            return _.without(value.split(regExp), "");
        };

        /**
         * Retorna o campo que será utilizado para a comparação.
         * Para valores numéricos que são representados utilizando grandezas (k, m, g, etc) na tabela
         * é possível definir 2 campos para realizar o filtro.
         * Isso foi necessário devido a possibilidade do filtro se realizado
         * no valor apresentado na tabela assim como no valor absoluto do campo.
         *
         * Para utilizar essa função o campo filtro precisa ser definido no seguinte formato:
         * filter: {"[view, value]": "text/number/selection"}
         *
         * Chave do Mapa: O valor 'view' é a propriedade exiba na tela e o valor 'value' é a propriedade com o valor real.
         * Valor: Tipo de filtro definido pela diretiva ng-table.
         * Ver: http://ng-table.com/#/filtering/demo-filtering-basic
         *
         * Obs: Essa funcionalidade só está disponível utilizando o
         * customFilter para a diretiva ng-table 'TableFilterService:dynamicComparator'.
         */
        var getFilterField = function(filter, filterType) {
            var fields = filter.field.replace(/[\[\]]/g, "").split(",");
            if (fields.length > 1) {
                return fields[filterType].trim();
            }

            return fields[0];
        };

        const getOperatorLogic = function(operator, value) {
            return operator === "AND" ? value.split(/ AND | E /gi) : value.split(/ OR | OU /gi);
        }

        const processPartsOfDoubleQuoteFilters = function(parts) {
            const value = parts.replace(new RegExp("\"", "g"), "");
            return `\"${getAbsoluteValue("", value)}\"`;
        }

        const processPartsOfOperatorsFilters = function(parts) {
            const value = splitValue(parts, "^([>,<,=,!]*)(.*\\S)$");
            return getAbsoluteValue(value[0], value[1]);
        }

        const processPartsOfFilter = function(parts) {
            if (parts.match(/(^((>=)|(<=)|[<>=!])(\w+))/g)) {
                return processPartsOfOperatorsFilters(parts);
            }

            if (_.startsWith(parts, "\"")) {
                return processPartsOfDoubleQuoteFilters(parts);
            }

            return parts;
        }

        /**
         * Divide a string em partes para poder processá-la corretamente, depois une-a novamente
         * de acordo com o operador lógico
         */
        const processFilters = function(value) {
            const operator = value.includes(" AND ") || value.includes(" E ") ? "AND" : "OR";
            const partsOfFilterValue = getOperatorLogic(operator, value);

            const filters = [];
            for (const parts of partsOfFilterValue) {
                filters.push(processPartsOfFilter(parts));
            }

            return filters.join(` ${operator} `);
        }

        /**
         * Processa os filtros que utilizam operadores >,<,=,!
         */
        const processNumericFilters = function(hasLogicOperator, filterValue) {
            if (hasLogicOperator) {
                return processFilters(filterValue);
            }
            const parts = splitValue(filterValue, "^([>,<,=,!]*)(.*\\S)$");
            return getAbsoluteValue(parts[0], parts[1]);
        }

        /**
         * Processa os filtros que utilizam aspas duplas ("")
         */
        const processStringFilters = function(hasLogicOperator, filterValue) {
            if (hasLogicOperator) {
                return processFilters(filterValue);
            }
            const value = filterValue.replace(new RegExp("\"", "g"), "");
            return `\"${getAbsoluteValue("", value)}\"`;
        }

        /**
         * Processa o filtro digitado pelo usuário para converter possíveis grandezas.
         * Apenas buscas numéricas, ou seja, buscas que utilizem os operadores >,<,=,!,""
         * são eletivas a conversão de grandezas.
         */
        const dataUnitsProcessor = function(filter) {
            let filterType = VIEW;
            const operatorRegex = /^(.+ (AND|E) .+)$|^(.+ (OR|OU) .+)$/i;
            const hasLogicOperator = operatorRegex.test(filter.value);

            if (_.isString(filter.value)) {
                if (filter.value.match(/(^((>=)|(<=)|[<>=!])(\w+))/g)) {
                    filter.value = processNumericFilters(hasLogicOperator, filter.value);
                    filterType = VALUE;
                }

                if (_.startsWith(filter.value, "\"") && _.endsWith(filter.value, "\"") && filter.value.length > 2) {
                    filter.value = processStringFilters(hasLogicOperator, filter.value);
                    filterType = VALUE;
                }
                filter.field = getFilterField(filter, filterType);
            }

            return filter;
        };

        service.globalFilterFieldsProcessor = function(globalFilter, columns) {
            var fields = [];

            if (globalFilter) {
                _.forEach(columns, function(column) {
                    if (_.has(column, "filter") && !_.isNull(column.filter) && column.filter !== false) {
                        var filterType = 0;
                        var filter: any = {
                            field: _.keys(column.filter)[0]
                        };
                        if (globalFilter.match(/(^[>,<,=,!]|[>=,<=])(.+\S)/g) || globalFilter.match(/^\s*"+(.+)"\s*$/g)) {
                            filterType = 1;
                        }
                        var field = getFilterField(filter, filterType);
                        fields.push(field);
                    }
                });
            }

            return fields;
        };

        service.globalFilterProcessor = function(filters) {
            // O caractere '$' é utilizado como wildcard para o filtro global.
            if (_.includes(_.keys(filters), "$")) {
                var globalFilter = angular.copy(filters["$"]);
                delete filters["$"];
                return globalFilter;
            }

            return null;
        };

        service.getProcessors = function() {
            return [dataUnitsProcessor];
        };

        return service;
    }
]);
