import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { SimpleModalComponent } from "ngx-simple-modal";
import { DeviceBackupSettingsService } from "./device-backup-settings.service";

/* Modelo a ser utilizado na modal de retenção de backup de equipamento */
export class DeviceBackupSettings {
    /* Habilita/desabilita retenção de backup de equipamento */
    enablePurge: boolean;
    /* Período de retenção de registros.*/
    purgeOlder: number;
}

/**
 * Modal de configuração de retenção de backup de equipamentos.
 */
@Component({
    selector: "app-retention-device-backup-modal",
    templateUrl: "./retention-device-backup-modal.component.html",
    styleUrls: ["./retention-device-backup-modal.component.scss"]
})
export class RetentionDeviceBackupModalComponent extends SimpleModalComponent<any, any> implements OnInit {

    currentDeviceBackupRetention: DeviceBackupSettings;
    deviceBackupRetentionForm: FormGroup;
    isDisabledBackup: boolean = false;
    maxRetentionInDays: number = 365;
    minRetentionInDays: number = 1;
    retentionPeriodTooltip: string;

    constructor(private readonly formBuilder: FormBuilder,
                private readonly toastr: NmsToastrService,
                private readonly deviceBackupSettingsService: DeviceBackupSettingsService,
                @Inject(ANGULARJS_TRANSLATE) private $translate: any) {
        super();
        this.createDeviceBackupRetentionForm();
        this.retentionPeriodTooltip = $translate.instant("backup.devices.retention.period.tooltip")
            .replace("{0}", this.minRetentionInDays)
            .replace("{1}", this.maxRetentionInDays);
    }

    ngOnInit(): void {
        this.getDeviceBackupSettingsRetention();
    }

    /**
     * Cria um form com os dados e os validadores da modal de retenção.
     * purgeOlder: Insere o validator com o intervalo permitido 1 - 365.
     * enablePurge: Caso não tenha nenhum valor pré-configurado no banco, vem desabilitado por default.
     */
    createDeviceBackupRetentionForm(): void {
        this.deviceBackupRetentionForm = this.formBuilder.group({
            enablePurge: [this.isDisabledBackup],
            purgeOlder: [this.maxRetentionInDays,
                [Validators.min(this.minRetentionInDays), Validators.max(this.maxRetentionInDays), Validators.required]
            ]
        });
    }

    save() {
        const newDeviceBackupRetention = {
            purgeOlder: this.deviceBackupRetentionForm.value.purgeOlder,
            enablePurge: this.deviceBackupRetentionForm.value.enablePurge
        };

        if (this.isDeviceBackupRetentionSettingsNotChanged(newDeviceBackupRetention)) {
            this.toastr.error(this.$translate.instant("backup.devices.retention.noChangesConfig"));
            return;
        }

        this.deviceBackupSettingsService.saveDeviceBackupRetentionSettings(newDeviceBackupRetention)
            .subscribe(() => {
                this.toastr.success(this.$translate.instant("backup.devices.retention.saved"));
                this.close();
            }, (responseError) => {
                this.toastr.error(this.$translate.instant("backup.devices.retention.error"));
                this.close();
            });

    }

    /**
     * Busca os dados do banco de dados e chama o método para atualizar o form.
     */
    getDeviceBackupSettingsRetention(): void {
        this.deviceBackupSettingsService.getDeviceBackupRetentionSettings()
            .subscribe((deviceBackupSettingsRetention: DeviceBackupSettings) => {
                this.updateDeviceBackupSettingsRetention(deviceBackupSettingsRetention);
            });
    }

    /**
     * Atualiza o form com os dados da configuração retornados do banco de dados.
     */
    private updateDeviceBackupSettingsRetention(deviceBackupSettingsRetention): void {
        this.currentDeviceBackupRetention = deviceBackupSettingsRetention;
        this.deviceBackupRetentionForm.patchValue(deviceBackupSettingsRetention);
    }

    /**
     * Verifica se houve alteração nas credenciais de backup
     * newDeviceBackupRetention: dados que serão atualizados nas configurações de backup
     * currentDeviceBackupRetention: dados atualizados das configurações de backup
     * @returns boolean
     */
    private isDeviceBackupRetentionSettingsNotChanged(newDeviceBackupRetention: DeviceBackupSettings): boolean {
        return _.isEqual(this.currentDeviceBackupRetention, newDeviceBackupRetention);
    }
}
