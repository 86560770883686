import { Injector } from "@angular/core";
import { RuleType, RuleTypesSet } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { DEVICE_DROPDOWN_MODEL_SERVICE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Cpe } from "../../device/cpes/cpe-model";
import { EquipmentInfo, EquipmentService } from "../services/equipment.service";
import { TemplateInstanceEquipmentInterface } from "./template-instance-equipment.interface";
import {
    AccordionParentData,
    CpeDetails,
    CpeIdentifier,
    EquipmentAssociation,
    EquipmentDetails,
    TemplateIdentifier,
    EquipmentTemplateChangedStatus,
    CpeField,
    CpeFieldValues,
    EquipmentSelectionType,
    EquipmentPermissionResult
} from "./template-instance-models";
import { TemplateInstanceComponent } from "./template-instance.component";

/**
 * Classe que colabora no componente TemplateInstanceComponent contendo a implementação
 * específica de CPE TR-069 de acordo com a interface TemplateInstanceEquipmentInterface.
 */
export class TemplateInstanceCpeComponent implements TemplateInstanceEquipmentInterface {
    selectionTypeTranslationKey: string;
    injector: Injector;
    templateInstanceComponent: TemplateInstanceComponent;
    deviceDropdownModelService: any;
    equipmentService: EquipmentService;

    constructor(templateInstanceComponent: TemplateInstanceComponent, injector: Injector) {
        this.injector = injector;
        this.templateInstanceComponent = templateInstanceComponent;
        this.deviceDropdownModelService = this.injector.get(DEVICE_DROPDOWN_MODEL_SERVICE);
        this.selectionTypeTranslationKey = "templateinstanceform.equipmentselectiontype.selectioncpes";
        this.equipmentService = this.injector.get(EquipmentService);
        this.fillEquipment();
    }

    private readonly getCpeSerialNumber = (equipmentAssociation: EquipmentAssociation) =>
        (equipmentAssociation.equipmentIdentifier as CpeIdentifier).serialNumber;

    async ngOnInit() {}

    private getCpesBySerialNumbers = (serialNumbers: string) => {
        this.templateInstanceComponent.cpeService
            .getCpesBySerialNumbers(serialNumbers)
            .subscribe(this.successAddEquipments.bind(this));
    };

    async fillEquipment() {
        await this.templateInstanceComponent.fillEquipment("serialNumber", this.getCpesBySerialNumbers, this.getCpeSerialNumber);
    }

    successAddEquipments(checkedCpes, shouldAddTemplatesToAllCpes?) {
        checkedCpes.forEach((checkedCpe) => {
            const currentEquipmentAssociation = this.createEquipmentAssociation(checkedCpe);

            if (
                this.templateInstanceComponent.presentationMode.value ===
                this.templateInstanceComponent.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
            ) {
                if (shouldAddTemplatesToAllCpes) {
                    this.templateInstanceComponent.data.forEach((template) => {
                        const cpesIds = _.map(template.children, "id.serialNumber");

                        if (!cpesIds.includes(checkedCpe.serialNumber)) {
                            this.associateTemplateToCpe(template, checkedCpe, currentEquipmentAssociation);
                        }
                    });
                } else {
                    const selectedTemplateId = _.get(this.templateInstanceComponent, "templateSelected.templateId");
                    const selectedTemplate = _.find(this.templateInstanceComponent.data, (data: AccordionParentData) => {
                        const templateId = this.templateInstanceComponent.templateInstanceUtils.resolveAccordionIdentifier(
                            data.id
                        );
                        return templateId === selectedTemplateId;
                    });

                    this.associateTemplateToCpe(selectedTemplate, checkedCpe, currentEquipmentAssociation);
                }
            } else {
                this.templateInstanceComponent.data.push({
                    id: currentEquipmentAssociation.equipmentIdentifier,
                    label: this.templateInstanceComponent.createEquipmentLabel(currentEquipmentAssociation.equipmentDetails),
                    children: [],
                    expand: false
                });

                this.templateInstanceComponent.templateInstance.equipmentAssociations.push(currentEquipmentAssociation);
            }

            this.templateInstanceComponent.equipments[checkedCpe.serialNumber] = angular.copy(currentEquipmentAssociation);
        });

        this.templateInstanceComponent.variablesModel.equipmentTemplateChangedStatus =
            EquipmentTemplateChangedStatus.NEEDS_CUSTOM_UPDATE;
    }

    addNewEquipment(callbackFunction?, shouldAddEquipmentsToAllTemplates?) {
        if (this.templateInstanceComponent.canUpdate()) {
            const callback = callbackFunction ? callbackFunction : this.successAddEquipments.bind(this);
            this.templateInstanceComponent.ngDialog
                .openConfirm({
                    template: "templates/features/template/template-instance/modals/add-cpe-modal.html",
                    controller: "AddCpeModalController",
                    className: "extra-large-modal",
                    closeByNavigation: true,
                    resolve: {
                        cpesAlreadyAdded: () => {
                            let equipmentsAlreadyAssociated: EquipmentAssociation[];

                            if (
                                this.templateInstanceComponent.presentationMode.value ===
                                this.templateInstanceComponent.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
                            ) {
                                equipmentsAlreadyAssociated = this.getCpesAssociatedToTemplates();
                            } else {
                                equipmentsAlreadyAssociated = this.getCpesAlreadyAddedToTemplateInstance();
                            }

                            return equipmentsAlreadyAssociated;
                        },
                        templateInstanceResolvedInformation: () => {
                            return {
                                templateType: this.templateInstanceComponent.templateInstance.type,
                                templateAlreadyAdded: this.getTemplatesAlreadyAdded(shouldAddEquipmentsToAllTemplates),
                                presentationMode: this.templateInstanceComponent.presentationMode,
                                shouldaddEquipmentsToAllTemplates: shouldAddEquipmentsToAllTemplates
                            };
                        },
                        equipmentModelRestrictionTypes: () =>
                            this.templateInstanceComponent.ConfigRESTService.cpeModelRestrictionTypes()
                    }
                })
                .then(callback);
        }
    }

    applyEquipmentIfNecessary(): void {
        if (this.templateInstanceComponent.$state.params.equipment) {
            this.templateInstanceComponent.presentationMode.value =
                this.templateInstanceComponent.PRESENTATION_MODE.TEMPLATES_BY_EQUIPMENT;
            this.successAddEquipments(this.templateInstanceComponent.$state.params.equipment);
        }
    }

    getSpecificTranslationKeys() {
        return this.templateInstanceComponent.equipmentService.getSpecificTranslationKeys(
            this.templateInstanceComponent.TEMPLATE_TYPE.TR_069.name
        );
    }

    createDeviceDropdownModel(equipmentIdentifier: CpeIdentifier) {
        let { serialNumber } = equipmentIdentifier;
        let cpe = this.templateInstanceComponent.equipments[serialNumber];

        return this.deviceDropdownModelService.createModelFromTemplateInstanceDevice(cpe);
    }

    getAvailableFeatures(equipmentIdentifier: CpeIdentifier): any {
        // TODO - [US-314] - Fazer a lógica para dropdown de CPE
        return [];
    }

    private createEquipmentAssociation(checkedCpe): EquipmentAssociation {
        return {
            equipmentIdentifier: this.createEquipmentIdentifier(checkedCpe),
            equipmentDetails: this.createEquipmentDetails(checkedCpe),
            templateAssociations: [],
            localVars: [],
            apply: false
        };
    }

    private associateTemplateToCpe(template, checkedCpe, equipmentAssociation: EquipmentAssociation): void {
        const cpeLabel = this.templateInstanceComponent.createEquipmentLabel({
            name: checkedCpe.serialNumber,
            model: checkedCpe.productModel
        } as EquipmentDetails);

        const cpeIdentifier = {
            serialNumber: checkedCpe.serialNumber,
            type: "CpeIdentifier"
        };

        template.children.push({
            id: cpeIdentifier,
            label: cpeLabel
        });

        const equipmentAssociations = _.get(this.templateInstanceComponent, "templateInstance.equipmentAssociations");
        const existingCpe = equipmentAssociations.find(
            (equipmentAssociation: EquipmentAssociation) =>
                (equipmentAssociation.equipmentIdentifier as CpeIdentifier).serialNumber === checkedCpe.serialNumber
        );
        const templateToAssociate = this.templateInstanceComponent.templateSelected
            ? this.templateInstanceComponent.templateSelected
            : template;

        if (existingCpe) {
            existingCpe.templateAssociations.push(templateToAssociate);
        } else if (equipmentAssociation) {
            equipmentAssociation.templateAssociations.push(templateToAssociate);
        }
    }

    private getTemplatesAlreadyAdded(shouldAddEquipmentsToAllTemplates) {
        let templatesAlreadyAdded = [];

        if (shouldAddEquipmentsToAllTemplates) {
            this.templateInstanceComponent.data.forEach((template) => {
                const templateId = (template.id as TemplateIdentifier).id;
                templatesAlreadyAdded.push(angular.copy(this.templateInstanceComponent.templates[templateId]));
            });
        } else if (
            this.templateInstanceComponent.presentationMode.value ===
            this.templateInstanceComponent.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
        ) {
            templatesAlreadyAdded.push(angular.copy(this.templateInstanceComponent.templateSelected));
        }

        return templatesAlreadyAdded;
    }

    private getCpesAssociatedToTemplates(): EquipmentAssociation[] {
        let equipmentsAlreadyAssociated: EquipmentAssociation[] = new Array<EquipmentAssociation>();
        this.templateInstanceComponent.data.forEach((template) => {
            template.children.forEach((cpe) => {
                const cpeId = (cpe.id as CpeIdentifier).serialNumber;
                const templateId = (template.id as TemplateIdentifier).id;
                let selectedCpe: EquipmentAssociation = _.find(
                    equipmentsAlreadyAssociated,
                    (equipmentAssociation: EquipmentAssociation) => {
                        return (
                            (equipmentAssociation.equipmentIdentifier as CpeIdentifier).serialNumber ===
                            (cpe.id as CpeIdentifier).serialNumber
                        );
                    }
                );

                if (!selectedCpe) {
                    selectedCpe = angular.copy(this.templateInstanceComponent.equipments[cpeId]);
                    selectedCpe.templateAssociations = [];
                    equipmentsAlreadyAssociated.push(selectedCpe);
                }
                selectedCpe.templateAssociations.push(this.templateInstanceComponent.templates[templateId]);
            });
        });

        return equipmentsAlreadyAssociated;
    }

    private getCpesAlreadyAddedToTemplateInstance(): EquipmentAssociation[] {
        let equipmentsAlreadyAssociated: EquipmentAssociation[] = new Array<EquipmentAssociation>();
        this.templateInstanceComponent.data.forEach((cpe) => {
            const cpeId = (cpe.id as CpeIdentifier).serialNumber;
            equipmentsAlreadyAssociated.push(angular.copy(this.templateInstanceComponent.equipments[cpeId]));
        });

        return equipmentsAlreadyAssociated;
    }

    createRuleTypesFilter(ruleTypes: RuleTypesSet) {

        const mapOmitField = {
            SERIAL_NUMBER: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN,
                RuleType.GREATER_THAN, RuleType.GREATER_THAN_OR_EQUAL, RuleType.LESS_THAN, RuleType.LESS_THAN_OR_EQUAL
            ],
            MANUFACTURER: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN,
                RuleType.GREATER_THAN, RuleType.GREATER_THAN_OR_EQUAL, RuleType.LESS_THAN, RuleType.LESS_THAN_OR_EQUAL
            ],
            PRODUCT_CLASS: [
                RuleType.GREATER_THAN_OR_EQUAL, RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN,
                RuleType.AFTER,RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN
            ],
            MANAGEMENT_IP: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN,
                RuleType.GREATER_THAN, RuleType.GREATER_THAN_OR_EQUAL, RuleType.LESS_THAN, RuleType.LESS_THAN_OR_EQUAL
            ],
            HW_VERSION: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE
            ],
            SW_VERSION: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE
            ],
            PROVISIONING_CODE: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN,
                RuleType.GREATER_THAN, RuleType.GREATER_THAN_OR_EQUAL, RuleType.LESS_THAN, RuleType.LESS_THAN_OR_EQUAL
            ],
        };

        return function(field) {
            if (Object.keys(mapOmitField).includes(field)) {
                return _.omit(ruleTypes, mapOmitField[field]);
            }

            return ruleTypes;
        }
    }

    createEquipmentField(value: string): CpeField {
        return { value: CpeFieldValues[value], type: "cpe" };
    }

    createEquipmentIdentifier(equipment: any): CpeIdentifier {
        return {
            serialNumber: equipment.serialNumber,
            type: "CpeIdentifier"
        };
    }

    createEquipmentDetails(equipment: any): CpeDetails {
        return {
            name: equipment.serialNumber,
            model: equipment.productModel,
            hostname: equipment.hostname,
            softwareVersion: equipment.softwareVersion,
            connectionRequestUrl: equipment.connectionRequestUrl,
            type: "CpeDetails"
        };
    }

    getEquipmentSelectionTypeTranslationKey(equipmentSelectionType: EquipmentSelectionType): string {
        return `cpe.template.application.viewApply.selection.type.${equipmentSelectionType}`;
    }

    validateEquipmentsForFilters(checkedEquipments: Array<Cpe & EquipmentInfo>): Promise<EquipmentPermissionResult> {
        return Promise.resolve({
            hasEquipmentWithoutPermission: false,
            equipments: _.indexBy(checkedEquipments, "serialNumber")
        } as EquipmentPermissionResult);
    }

    buildMatchingEquipmentsFilterUrl() {
        let httpParams = this.templateInstanceComponent.buildUrlParametersFromMatchingRules();

        return `#/device/cpes?${httpParams.toString()}`;
    }
}
