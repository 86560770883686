var app = angular.module("nms.dynamicDevice");

/**
 * Decimal Restriction.
 * Validates the number of fraction digits of a decimal value.
 */
app.service("DecimalFractionRestrictionService", ["LeafService",
    function(LeafService) {
        this.RESTRICTION_NAME = "decimal-fraction";

        /**
         * Returns a regex pattern based on leaf attribute "fraction-digits" to validate fraction part of the input.
         *
         * @return {RegExp} The regular expression to validate the fraction part.
         */
        var getPattern = function(type) {
            var fraction = LeafService.getMaxFractionDigits(type);

            if (fraction === 0) {
                return new RegExp("^[-]?\\d*$");
            }
            return new RegExp("^[-]?\\d*([.]{1}\\d{1," + fraction + "})?$");
        };

        this.validate = function(modelValue, viewValue, type) {
            var value = modelValue || viewValue;

            if (!value || value === "") {
                return true;
            }

            return getPattern(type).test(value);
        };
    }
]);
