import { FreemarkerExpressionService } from "../services/freemarker-expression.service";
import {
    CommandFormat,
    Expression,
    ExternalOptions,
    TemplateConfirmationConfig,
    TemplateInterface,
    TemplateOptions,
    TemplateType
} from "./template.interface";

/**
 * Implementação de TemplateInterface para templates do tipo CLI.
 */
export class TemplateCliComponent implements TemplateInterface {

    constructor(private freemarkerExpressionService: FreemarkerExpressionService) { }

    /**
     * @inheritdoc
     * Expressões disponíveis:
     * - If
     * - If/else
     * - Switch
     * - List (loop)
     * - Assign
     * - Comment
     * @returns Lista de componentes customizados para templates CLI.
     */
    getExpressions(): Expression[] | null {
        return this.freemarkerExpressionService.createExpressions();
    }

    /**
     * @inheritdoc
     * Templates CLI não agregam expressões customizadas no code-block.
     */
    getExpressionsAggregatorProvider() {
        return [];
    }

    /**
     * @inheritdoc
     * As variáveis de templates para "devices" seguem o formato "${VAR_NAME}".
     *
     * @returns object \{ start: "${", end: "} " }
     */
    getFormatCommand(): CommandFormat | null {
        return this.freemarkerExpressionService.getFormatCommandFreemarker();
    }

    /**
     * @inheritdoc
     */
    getTooltipVariable(): string {
        return this.freemarkerExpressionService.getVariableTooltip();
    }

    /**
     * @inheritdoc
     */
    createTemplateOptions(externalOptions: ExternalOptions): TemplateOptions {
        let {
            variableTypes,
            deviceModelRestrictionTypes: equipmentModelRestrictionTypes
        } = externalOptions;

        const templateOptions: TemplateOptions = {
            equipmentModelRestrictionTypes,
            variableTypes,
            isTR069Template: false,
            matchingRulesKey: "templateForm.basic.device.model.restriction",
            templateLanguage: "FREEMARKER"
        };

        return templateOptions;
    }

    /**
     *  @inheritdoc
     */
     getEquipmentVariables(variables: any) {
        return variables;
    }

    /**
     * Método responsável por inicializar a string de um comando a ser utilizado no template.
     * Caso o parametro de entrada seja null/undefined, ele será redefinido para string vazia.
     * Caso haja comandos já definidos na string, retorna a string como ela veio.
     */
    getCommands(commands = "", isRemovalEnabled: boolean): string {
        return commands || "";
    }

    /**
     * @inheritdoc
     */
    createChangeTypeConfirmationConfig(
        loadOptionsByTemplate: (templateType: { value: TemplateType }) => void,
        getConfirmationModalByTemplateType: (templateType: TemplateType) => {
            confirmationModalFn: () => boolean,
            postConfirmationFn?: () => void
        },
        cancelationCallbackFn: (templateType: { value: TemplateType }) => void,
    ): TemplateConfirmationConfig {
        const { confirmationModalFn, postConfirmationFn } = getConfirmationModalByTemplateType("CLI");
        const confirmationCallbackFn = () => {
            if (postConfirmationFn) {
                postConfirmationFn();
            }

            loadOptionsByTemplate({ value: "CLI" });
        };

        return {
            confirmationCallbackFn,
            cancelationCallbackFn: () => cancelationCallbackFn({ value: "TR_069" }),
            confirmationModalFn,
            confirmationTranslationKey: "popups.confirm.changeTemplateTypeToCli"
        };
    }

    /**
     * @inheritdoc
     */
    getShowInlineExpressions(): boolean {
        return true;
    }

    /**
     * @inheritdoc
     */
    isTemplateOfType(templateType: TemplateType): boolean {
        return templateType === "CLI";
    }
}