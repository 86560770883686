"use strict";

/**
* @ngdoc controller
* @name nms.components.pagination:ClientPaginationController
* @description
* # ClientPaginationController
* Classe de controle para a diretiva de paginação realizada no front-end.
*/
var app = angular.module("nms");

app.controller("ClientPaginationController", ["$rootScope", "$scope", "WindowService", "UserPreferencesService",
    "BROADCAST_EVENTS", "TABLE_PROPERTIES", "$translate",
    function($rootScope, $scope, WindowService, UserPreferencesService, BROADCAST_EVENTS, TABLE_PROPERTIES, $translate) {
        var identifier = $scope.id;

        $scope.tableParams = $scope.tableModel.tableParams;
        $scope.selection = $scope.tableModel.selection;
        $scope.loadTablePreferences = _.get($scope, "loadTablePreferences", true);

        $scope.numberOfPages = 1;
        $scope.firstItemIndex = 0;
        $scope.lastItemIndex = 0;
        var RANGE_PAGINATION_REGEX = "^([{1}-9]|[1-8][0-9]|9[0-9]|{2})$";

        var init = function() {
            loadOptions();
            $scope.viewPageNumber = $scope.tableParams.page();
            $scope.viewPageSize = $scope.tableParams.count();
            var acceptableRangePattern = RANGE_PAGINATION_REGEX.replace("{1}", TABLE_PROPERTIES.MIN_ROWS_FOR_PAGINATION)
                .replace("{2}", TABLE_PROPERTIES.MAX_ROWS_FOR_PAGINATION);
            $scope.errorRangeMessage = $translate.instant("pagination.error.range.message")
                .replace("{1}", TABLE_PROPERTIES.MIN_ROWS_FOR_PAGINATION)
                .replace("{2}", TABLE_PROPERTIES.MAX_ROWS_FOR_PAGINATION);

            $scope.rangePattern = new RegExp(acceptableRangePattern);
        };

        var pageSize = function(pageSize?) {
            if (pageSize) {
                $scope.tableParams.count(pageSize);
                updateShownIndexes();
                updateNumberOfPages();
            }

            return $scope.tableParams.count();
        };

        var page = function(page?) {
            if (page) {
                $scope.tableParams.page(page);
                $scope.viewPageNumber = page;
                updateShownIndexes();
            }

            return $scope.tableParams.page();
        };

        var updateShownIndexes = function() {
            if ($scope.total() === 0) {
                $scope.firstItemIndex = 0;
                $scope.lastItemIndex = 0;
            } else {
                $scope.firstItemIndex = ((page() - 1) * pageSize()) + 1;
                $scope.lastItemIndex = Math.min($scope.firstItemIndex - 1 + pageSize(), $scope.total());
            }
        };

        var updateNumberOfPages = function() {
            if (pageSize() === null || pageSize() === 0) {
                $scope.numberOfPages = 1;
            } else {
                $scope.numberOfPages = Math.max(Math.ceil($scope.total() / pageSize()), 1);
            }
            updateShownIndexes();
        };

        var visibleElements = function() {
            return $scope.tableParams.data;
        };

        /*
         * Corrige incoerência na página corrente do usuário.
         *
         * No caso de o usuário retornar para a janela e a página que ele estava anteriormente não for a primeira é
         * necessário forçar uma navegação para a página obtida na sessionStorage. Pois o ng-table automaticamente
         * vai para a primeira página quando o dataset é passado via settings().
         *
         * Também corrige a pagina atual quando o há uma mudança na quantidade de dados visíveis e a página atual
         * não é mais válida. O ng-table corrige automaticamente, mas precisamos fazer essa verificação também
         * para corrigir a variável viewPageNumber, que existe para permitir a mudança de página apenas quando
         * o usuário apertar a tecla Enter.
         */
        var fixPaginationIfNeeded = function() {
            if (parseInt($scope.viewPageNumber) > $scope.numberOfPages) {
                page(1);
            } else {
                page($scope.viewPageNumber);
            }
        };

        $scope.changePageSize = function() {
            pageSize($scope.viewPageSize);
        };

        $scope.changePageNumber = function() {
            if ($scope.validPageValue()) {
                page($scope.viewPageNumber);
            }
        };

        $scope.previousPage = function() {
            if (page() > 1) {
                page(page()-1);
            }
        };

        $scope.nextPage = function() {
            if (page() < $scope.numberOfPages) {
                page(page()+1);
            }
        };

        $scope.firstPage = function() {
            page(1);
        };

        $scope.lastPage = function() {
            page($scope.numberOfPages);
        };

        $scope.validPageValue = function() {
            var currentPage = $scope.viewPageNumber;

            if (!currentPage || currentPage > $scope.numberOfPages || currentPage < 1) {
                return false;
            }

            return true;
        };

        $scope.total = function() {
            return $scope.tableParams.total();
        };

        $scope.showPagination = function() {
            var settings = $scope.tableParams.settings();

            return settings.dataset.length > TABLE_PROPERTIES.MIN_ROWS_FOR_PAGINATION;
        };

        var unregisterChangePageTo = $scope.$on(BROADCAST_EVENTS.TABLE_PAGINATION.CHANGE_PAGE_TO, function(event, pageNumber) {
            page(pageNumber);
        });

        var unregisterGoToRowPosition = $scope.$on(BROADCAST_EVENTS.TABLE_PAGINATION.CHANGE_PAGE_TO_ROW_POSITION, function(event, positionRow) {
            page(Math.ceil(positionRow / $scope.tableParams.count()));
        });

        $scope.$watch(visibleElements, function() {
            updateNumberOfPages();
            fixPaginationIfNeeded();
            saveOptions();
        });

        $scope.$on("$destroy", function() {
            unregisterChangePageTo();
            unregisterGoToRowPosition();
            saveOptions();
        });

        var saveOptions = function() {
            if (!$scope.tableModel.doNotPersist) {
                UserPreferencesService.saveTablePreferences(identifier, $scope.tableParams);
            }
        };

        /**
        * Remove os filtros setados nos parâmetros da ngTable, com as chaves contidas no atributo ignoredColumns.
        * Este caso deve ser usado quando as colunas da tabela são diferentes devido a lógica de negócio, como é o caso
        * da janela de Gpon Onu onde coluna deviceName deve ser considerada apenas para o contexto de múltiplos equipamentos.
        */
        var removeIgnoredColums = function(filter, ignoredColumns) {
            return _.isUndefined(ignoredColumns) ? filter : _.omit(filter, ignoredColumns);
        };

        var loadOptions = function() {
            var persistedOptions = UserPreferencesService.loadTablePreferences(identifier);

            if (persistedOptions && $scope.loadTablePreferences) {
                var tableParameters = persistedOptions.tableParameters || $scope.tableParams.parameters();

                processTableParams(tableParameters);
                $scope.tableParams.parameters(tableParameters);
            }
        };

        var processTableParams = function(tableParams) {
            var lastPageNumber = Math.max(Math.ceil($scope.tableParams.total() / tableParams.count), 1);
            var ignoredColumns = $scope.tableParams.parameters().ignoredColumns;
            var initCustomFilters = $scope.tableParams.parameters().initCustomFilters;

            if (tableParams.page > lastPageNumber) {
                tableParams.page = lastPageNumber;
            }

            tableParams.filter = removeIgnoredColums(tableParams.filter, ignoredColumns);

            if (initCustomFilters) {
                if ($scope.tableParams.parameters().overrideWithCustomFilters) {
                    tableParams.filter = initCustomFilters;
                } else {
                    angular.extend(tableParams.filter, initCustomFilters);
                }
            }
        };

        init();
    }
]);
