var app = angular.module("nms.dynamicDevice");

app.constant("LeafType",
    {
        DECIMAL: {
            maxDigits: 19
        },
        INTEGER: ["int8", "int16", "int32", "int64"],
        UNSIGNED_INTEGER: ["uint8", "uint16", "uint32", "uint64"]
    }
);
