"use strict";

/**
* @ngdoc service
* @name nms.templates.DeviceOptionsExtractionService
* @description
* # Serviço para extração de opções.
* Factory in the nms.templates.
*/
var app = angular.module("nms.templates");

app.factory("DeviceOptionsExtractionService", ["RestDeviceOptionsExtractionService", "$q",
    function(RestDeviceOptionsExtractionService, $q) {
        const service: any = {};
        var deviceOptionsTimeoutInMinutes;

        service.testExtraction = function(input, extractionParams) {
            var request: any = {
                input: input,
                ruleOptions: extractionParams.extractOptionsFilterResults ? extractionParams.ruleOptions : [],
                rulesMatchingMode: extractionParams.rulesMatchingMode,
                regexToExtractOptions: extractionParams.extractResultsFromCliResults
                    ? extractionParams.regexToExtractOptions : "",
                specificOccurrences: extractionParams.useSpecificOccurrences ? extractionParams.specificOccurrences : "",
                transformationLogicCommands: extractionParams.hasTransformationLogic
                    ? extractionParams.transformationLogicCommands : ""
            };

            return RestDeviceOptionsExtractionService.testOptionsExtraction(request);
        };

        service.extractDeviceOptions = function(deviceId, templateId, allVariablesValues) {
            var request: any = {
                deviceId: deviceId,
                templateId: templateId,
                commandTemplateVariables: allVariablesValues
            };

            return resolveTimeoutAndCall(RestDeviceOptionsExtractionService.extractDeviceOptions, request);
        };

        service.extractSpecificVariableOptions = function(deviceId, templateId, variableName, selectedAction, allVariablesValues) {
            var request: any = {
                deviceId: deviceId,
                templateId: templateId,
                variableName: variableName,
                selectedAction: selectedAction,
                commandTemplateVariables: allVariablesValues
            };

            return resolveTimeoutAndCall(RestDeviceOptionsExtractionService.extractSpecificVariableOptions, request);
        };

        service.getDeviceOptionsTimeoutInMinutes = function() {
            return deviceOptionsTimeoutInMinutes;
        };

        service.getDeviceOptionsTimeoutInMilliseconds = function() {
            return deviceOptionsTimeoutInMinutes * 60 * 1000;
        };

        var resolveTimeoutAndCall = function(callbackFunction, parameters) {
            var deferred = $q.defer();
            var promise = deferred.promise;

            deferred.resolve(deviceOptionsTimeoutInMinutes || RestDeviceOptionsExtractionService.getDeviceOptionsTimeout());

            return promise.then(function(response) {
                deviceOptionsTimeoutInMinutes = response;

                return callbackFunction(parameters);
            });
        };

        return service;
    }
]);
