"use strict";

/**
* @ngdoc directive
* @name nms.directive:deviceDropdown
* @description Diretiva para criar componente para representação de equipamentos com menu dropdown padrão.
*   @param {string} label é opcional e quando não passado como parâmetro na diretiva, será utilizada a informação de device.name.
*/
angular.module("nms")
.directive("deviceDropdown", ["DeviceDropdownShortcutsService", "BROADCAST_EVENTS",
    function(DeviceDropdownShortcutsService, BROADCAST_EVENTS) {
        return {
            restrict: "E",
            transclude: true,
            templateUrl: "templates/components/ui/device/dropdown/device-dropdown.html",
            scope: {
                device: "=?",
                deviceProvider: "&?",
                additionalShortcuts: "=?",
                availableFeatures: "=?",
                availableFeaturesProvider: "&?",
                label: "=?"
            },
            link: function(scope, element, attrs) {
                var contentElement = angular.element(element[0].querySelector(".device-dropdown-content"))[0];
                scope.hasTranscludeContent = _.size(contentElement.children) > 0;

                function addDivider(shortcuts) {
                    if (_.size(shortcuts) > 0) {
                        shortcuts[shortcuts.length - 1].hasDivider = true;
                    }
                }

                function addFeaturesShortcuts(availableFeatures, openNewTab) {
                    var currentShortcuts = scope.shortcuts;
                    scope.shortcuts = DeviceDropdownShortcutsService.getFeaturesShortcuts(
                        availableFeatures, scope.model.name, openNewTab);
                    addDivider(scope.shortcuts);
                    scope.shortcuts = scope.shortcuts.concat(currentShortcuts);
                }

                function isPromise(object) {
                    return _.isFunction(object.then);
                }

                function initialize(device) {
                    var openNewTab = "true" === attrs["openLinksInNewTab"];
                    scope.model = device;
                    scope.label = scope.label || device.name;

                    scope.shortcuts = DeviceDropdownShortcutsService.getShortcutsByEquipmentType(device, openNewTab);

                    if (scope.additionalShortcuts) {
                        scope.shortcuts = scope.shortcuts.concat(scope.additionalShortcuts);
                    }

                    if (scope.availableFeatures) {
                        addFeaturesShortcuts(scope.availableFeatures, openNewTab);
                    } else if (scope.availableFeaturesProvider) {
                        var availableFeatures = scope.availableFeaturesProvider();
                        if (isPromise(availableFeatures)) {
                            availableFeatures.then(function(availableFeatures) {
                                addFeaturesShortcuts(availableFeatures, openNewTab);
                            });
                        } else {
                            addFeaturesShortcuts(availableFeatures, openNewTab);
                        }
                    }
                }

                if (scope.device) {
                    initialize(scope.device);
                } else if (_.isFunction(scope.deviceProvider)) {
                    const provided = scope.deviceProvider();
                    initialize(provided);
                }

                var unregisterDevicePropertiesChangeListener = scope.$on(BROADCAST_EVENTS.DEVICE_NAME_CHANGED,
                    function(event, payload) {
                        if (scope.model.id === payload.device.id) {
                            scope.model.name = payload.device.name;
                        }
                    }
                );

                scope.$on("$destroy", unregisterDevicePropertiesChangeListener);
            }
        };
    }
]);
