var app = angular.module("nms.dynamicDevice");

app.controller("LeafController", ["$rootScope", "$scope", "$filter", "RestrictionService", "DomainHandlerService", "$translate",
    "DataPathService", "LeafService", "NodeValues", "NodeStatusService", "WhenVerifierService",
    function($rootScope, $scope, $filter, RestrictionService, DomainHandlerService, $translate,
        DataPathService, LeafService, NodeValues, NodeStatusService, WhenVerifierService) {
        var unregisterFunctions = [];
        if (!$scope.pathKeys && $scope.getPathKeys) {
            $scope.pathKeys = $scope.getPathKeys();
        }
        $scope.readOnly = $rootScope.readOnly;
        $scope.acceptableCharacters = "^[0-9 \\-,]*$";
        $scope.parentPath = $scope.leaf.parentPaths.schemaJsonPath;
        $scope.defaultValue = LeafService.getDefaultValue($scope.leaf);
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.leaf);
        $scope.isNodeVisible = !hasWhenCondition;
        var extractNumbersPattern = new RegExp(/\d+/g);

        var reloadDataNode = function() {
            var dataNode = DomainHandlerService.getDataNode($scope.pathWithKeys);
            if (dataNode) {
                $scope.dataNode = dataNode;
            } else {
                $scope.dataNode = {
                    id: $scope.leaf.id,
                    value: null
                };
            }
        };

        if ($scope.isTransient) {
            $scope.dataNode = $scope.leaf;
        } else {
            $scope.pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.leaf);
            reloadDataNode();

            if ($scope.config) {
                var unregister = $rootScope.$on("reloadContentNodeOf=" + $scope.pathWithKeys, function() {
                    reloadDataNode();
                });
                unregisterFunctions.push(unregister);

                $scope.$watch("dataNode.value", function(newValue) {
                    LeafService.setPersistentLeafValue($scope.leaf, $scope.pathWithKeys, newValue);
                });
            }
        }

        /**
         * Verifies if field {@param id} has any error.
         *
         * @param {string} id Field Id.
         * @return {boolean} Indicate whether or not the field has errors.
         */
        $scope.hasErrorsOnField = function(id) {
            $scope.hasErrors = RestrictionService.hasErrorsOnField(id, $scope.form);
            return $scope.hasErrors;
        };

        $scope.getBaseType = function(leafType) {
            return LeafService.getBaseType(leafType);
        };

        $scope.getLeafrefPossibleValues = function(referrerNode) {
            var referrerNodePath = DomainHandlerService.getPath(referrerNode);
            var xpath = $scope.getBaseType(referrerNode.type)["sub-statements"].path[0];
            var leafrefValues = _.uniq(DomainHandlerService.getAllValuesInsideAList(referrerNodePath, xpath, $scope.pathKeys));

            var possibleValues = _.map($filter("orderByNumbersAndLetters")(leafrefValues), function(value) {
                return {value: value};
            });

            if (!$scope.isRequired(referrerNode, $scope.isKey) && !$scope.defaultValue) {
                possibleValues.unshift({value: ""});
            }

            return possibleValues;
        };

        $scope.getMin = function(leaf) {
            return LeafService.getMin(leaf.type);
        };

        $scope.getMax = function(leaf) {
            return LeafService.getMax(leaf.type);
        };

        $scope.getRangeHint = function(leaf) {
            var ranges = LeafService.getRanges(leaf.type)[0].match(extractNumbersPattern);
            var templateParameters: any = {};
            var templateString = "";
            var rangeHint = "";

            if (ranges) {
                var isLargeRange = (ranges[1] - ranges[0]) > 2;

                if (isLargeRange) {
                    var firstParam = ranges[0];
                    var secondParam = parseInt(ranges[0]) + 2;
                    var thirdParam = secondParam + 1;

                    templateString = "${firstParam},${secondParam}-${thirdParam}";
                    templateParameters = {
                        firstParam: firstParam,
                        secondParam: secondParam,
                        thirdParam: thirdParam
                    };
                } else {
                    templateString = "${firstParam}-${secondParam}";
                    templateParameters = {
                        firstParam: ranges[0],
                        secondParam: ranges[1]
                    };
                }

                rangeHint = _.template(templateString)(templateParameters);
            }

            return rangeHint;
        };

        /**
         * Returns a set of possible values if a given leaf has any.
         *
         * @param {object} leaf A leaf node.
         * @return {Array} all possible values for a leaf.
         */
        $scope.getPossibleValues = function(leaf) {
            return leaf ? leaf.possibleValues || LeafService.getPossibleValues(leaf) : null;
        };

        /**
        * Verifies if leaf is an alarm severity node.
        *
        * @return {boolean} indicate if the leaf is an alarm severity node.
        */
        $scope.isAlarmSeverityLeaf = function(leafFieldId) {
            var alarmSeverityPattern = /(^\$\.containers\.alarm\.lists\.alarms.*severity_field$)/g;
            return alarmSeverityPattern.test(leafFieldId);
        };

         /**
         * Returns a text with the leaf value if it exists or an empty label when field is empty.
         * In case the leaf is inside a list, returns leaf value even if it's empty.
         *
         * @param {string} value leaf value.
         * @param {boolean} isEmptyValue determine if value is empty.
         * @param {boolean} isInsideListBody determine if leaf is inside a list.
         * @return {string} text to display in View.
         */
        $scope.getText = function(value, isEmptyValue, isInsideListBody) {
            return !isInsideListBody && isEmptyValue ? NodeValues.FIELD_WITHOUT_VALUE : value;
        };

        /**
         * Returns the text for leafs of type empty.
         * When the value is null the leaf is considered not present and the value "" represents a present leaf.
         *
         * @param {string} value leaf value.
         * @return {string} text to display in View.
         */
        $scope.getTextForEmptyLeaf = function(value) {
            return _.isNull(value) ? NodeValues.NOT_PRESENT_FIELD : NodeValues.PRESENT_FIELD;
        };

        $scope.isRequired = function(leaf, isKey) {
            if ($scope.isSingleEditing) {
                return isKey || $filter("isMandatory")(leaf);
            }

            return leaf.shouldEdit && $filter("isMandatory")(leaf) || isKey;
        };

        $scope.getInvalidPatternMessage = function(leaf, type, hasMultipleRestrictions) {
            return RestrictionService.getRestrictionNodeInformation(leaf, type, hasMultipleRestrictions);
        };

        $scope.isDisabled = function(leaf) {
            if ($scope.isSingleEditing) {
                return ($scope.isKey && !$scope.isInsideListBody) || $scope.readOnly;
            }

            return !leaf.shouldEdit || $scope.readOnly;
        };

        /**
         * A diretiva de leaf é reaproveitada dentro da diretiva de leaflist, de forma que
         * quando uma leaflist chega neste controller, o valor do isTransient é sempre true
         * e deve-se considerar que, se esta leaf foi criada como filha de leaflist, é porque
         * a leaflist foi aprovada na validação do WHEN, sendo necessário registrar a leaf também.
         */
        if (hasWhenCondition && (!$scope.isTransient || $scope.isLeafList)) {
            var unregisterNodeStatusListener = NodeStatusService.register(
                $scope.leaf.paths.schemaJsonPath,
                $scope.leaf.paths.dataJsonPath,
                $scope.pathKeys,
                function(nodeStatus, refreshData) {
                    $scope.isNodeVisible = nodeStatus.isVisible;
                    if (refreshData) {
                        reloadDataNode();
                    }
                }
            );
            unregisterFunctions.push(unregisterNodeStatusListener);
        }

        $scope.$on("$destroy", function() {
            unregisterFunctions.forEach(_.attempt);
        });
    }
]);
