import { TemplateInstanceIdentifierService } from "../template-instance/template-instance-identifier.service";
import {
    ANGULARJS_TRANSLATE,
    ANGULARJS_ROOTSCOPE,
    FILTER,
    TEMPLATE_INSTANCE_SERVICE,
    TEMPLATE_INSTANCE_VARIABLE_SERVICE,
    VARIABLES,
    DEVICE_OPTIONS_EXTRACTION_SERVICE,
    APPLICATION_STATUS,
    OPTIONS_EXTRACTION_STATUS,
    WINDOW,
    WEBSOCKET,
    ANGULARJS_TIMEOUT
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Inject, Injectable } from "@angular/core";
import { EquipmentAssociation, EquipmentTemplateChangedStatus } from "../template-instance/template-instance-models";
import { Subject } from 'rxjs';

/**
 * Serviço responsável por validação e tratamento de regras de negócios referentes as variáveis.
 */
@Injectable({
    providedIn: "root"
})
export class VariablesService {
    private readonly VAR_NAME_FIELD: string = "variable.name";
    private readonly selectionVariableValueSupplier;
    private websocket;

    public templateInstance;
    public variablesModel;
    public templates;
    public readOnlyVariables;
    public globalVarsDetails;
    public equipmentLocalVarsDetails;
    public lastCommandsValuesByDevice;
    public lastCommandsByDevice;
    public isEdit;
    public isClone;
    public variableToReloadByCommand;
    public modalData;
    public equipmentTemplateChangedStatus: EquipmentTemplateChangedStatus;
    public removedEquipments;
    public variablesThatNeedToBeReloaded;
    public originalValuesForVariablesBeforeEdition;
    public templateApplicationResponseAssociation;
    public variableResponseTimeoutPromises = [];
    public VARIABLES_EXTRACTION_ERRORS = [];
    public suffixes;

    constructor(
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope,
        @Inject(TEMPLATE_INSTANCE_SERVICE) private readonly TemplateInstanceService,
        @Inject(TEMPLATE_INSTANCE_VARIABLE_SERVICE) private readonly TemplateInstanceVariableService,
        @Inject(VARIABLES) private readonly variablesConst,
        @Inject(DEVICE_OPTIONS_EXTRACTION_SERVICE) private readonly DeviceOptionsExtractionService,
        @Inject(APPLICATION_STATUS) private readonly applicationStatus,
        @Inject(OPTIONS_EXTRACTION_STATUS) private readonly optionsExtractionStatus,
        @Inject(FILTER) public $filter,
        @Inject(ANGULARJS_TRANSLATE) private readonly $translate,
        @Inject(WINDOW) private readonly $window,
        @Inject(WEBSOCKET) private readonly $websocket,
        @Inject(ANGULARJS_TIMEOUT) private readonly $timeout,
        private readonly templateInstanceIdentifier: TemplateInstanceIdentifierService
    ) {
        this.VARIABLES_EXTRACTION_ERRORS = [
            this.optionsExtractionStatus.PARSE_COMMAND_ERROR,
            this.optionsExtractionStatus.TRANSFORMATION_LOGIC_ERROR
        ];

        /* FIXME: A lógica que adiciona os sufixos foi toda elaborada em cima de manipulação de string,
         * essa não é a melhor forma de implementar essa funcionalidade e deve ser resolvido no bugzilla:
         * http://bugzilla.datacom/show_bug.cgi?id=97845
         */
        const notAvailableForInclusion = $translate.instant("templateinstanceform.templatedevices.notAvailableForInclusion");
        const blocked = $translate.instant("templateinstanceform.templatedevices.blockValue");
        this.suffixes = {
            notAvailableForInclusion,
            blocked
        };

        this.selectionVariableValueSupplier = new Proxy(
            new Map<string, { get(value: any): string }>([
                ["DEFAULT", { get: (value) => String(value) }],
                ["NUMERO_SERIE_ONU_DESCOBERTA", { get: ({ serialNumber }) => String(serialNumber) }]
            ]),
            {
                get: (target, key) => {
                    const id = String(key);
                    return target.has(id) ? target.get(id) : target.get("DEFAULT");
                }
            }
        );
    }

    public init(model) {
        _.defaults(model, { isEdit: false });
        _.defaults(model, { isClone: false });
        _.defaults(model, { lastCommandsByDevice: {} });
        _.defaults(model, { equipmentTemplateChangedStatus: EquipmentTemplateChangedStatus.CREATING });
        _.defaults(model, { lastCommandsValuesByDevice: {} });
        _.defaults(model, { variablesThatNeedToBeReloaded: [] });
        _.defaults(model, { variableToReloadByCommand: {} });
        _.defaults(model, { globalVarsDetails: { expand: true, vars: null } });
        _.defaults(model, { equipmentLocalVarsDetails: { expand: true, equipmentLocalVars: null } });
        _.defaults(model, { removedEquipments: [] });
        _.defaults(model, { modalData: {} });
        _.defaults(model, { readOnlyVariables: [] });
        _.defaults(model, { originalValuesForVariablesBeforeEdition: [] });
        _.defaults(model, { templateApplicationResponseAssociation: [] });
        _.defaults(model, { subscription: null });
        _.defaults(model, { newVariablesRestrictions: null });

        return model;
    }

    public getVariablesFromServer(model) {
        if (!model.subscription) {
            this.connect(model, this.updateVariables.bind(this));
        } else {
            this.updateVariables();
        }
    }

    public async updateVariables() {
        if (this.variablesModel.newVariablesRestrictions === null) {
            const varRestrictions = await this.TemplateInstanceService.getVarRestrictions(
                this.templateInstance.equipmentAssociations,
                this.templates
            );
            this.updateVariablesRestrictions(varRestrictions);
        } else {
            await this.updateVariablesRestrictions(this.variablesModel.newVariablesRestrictions);
        }
        // FIXME [US-3651] - Buscar alternativas para não utilizar o digest aqui.
        // Teoricamente não deveria ser necessário chamar o $digest manualmente.
        this.$rootScope.$digest();
    }

    connect(model, afterConnectCallback) {
        this.websocket = this.$websocket(
            "wss://" + this.$window.location.host + "/device-extraction/" + this.$rootScope.loggedUser
        );

        this.websocket.onMessage((event) => {
            var variablesFromDevice = angular.fromJson(event.data);
            // TODO - [US-3962] Verificar o uso do model.subscription pois o método não tem retorno.
            model.subscription = this.updateVariablesAfterApplying(variablesFromDevice);
        });

        this.websocket.onOpen(() => {
            afterConnectCallback();
        });
    }

    private getVariablesWaitingResponse() {
        return _.flatten(_.map(this.templateApplicationResponseAssociation, "variablesIdentifiers"));
    }

    private variableResponseTimedOut(identifiers) {
        const waitingResponseVariables = this.getVariablesWaitingResponse();

        _.forEach(identifiers, (identifier) => {
            const waitingVariable = _.find(waitingResponseVariables, { id: identifier.id });

            if (waitingVariable) {
                const variable = this.findVariable(waitingVariable.variableName);

                variable.status = this.optionsExtractionStatus.SERVICE_TIMEOUT_ERROR;

                _.forEach(this.templateApplicationResponseAssociation, (association) => {
                    this.removeApplicationAssociation(association, "id", identifier.id);
                });
            }
        });
    }

    private findVariable(variableName) {
        const globalVariable = _.find(this.globalVarsDetails.vars, (variable) => {
            return variable.variable.name === variableName;
        });

        const localVariable = _.chain(this.equipmentLocalVarsDetails.equipmentLocalVars)
            .map("localVars")
            .flatten()
            .find((variable) => {
                return variable.variable.name === variableName;
            })
            .value();

        return globalVariable || localVariable;
    }

    /**
     * Busca corretamente as variaveis com reload, com base nos nomes das variáveis utilizadas nos comandos.
     *
     * Utiliza uma regex com a notação '/^(\S+)\[(\S+)\]$/' para descobrir se é preciso ajustar o nome da variável no comando,
     * ou se é possível utilizar diretamente o paramêtro.
     *
     * Essa regex identifica se o valor de varInsideCommand encaixa-se no padrão:
     * - São duas sequências de caracteres não vazios separados por conchetes ('[]');
     * - Os conchetes demarcam a segunda sequência de caracteres;
     * - O valor começará com a primeira sequencia de caracteres e encerrará após o conchete ao final da segunda sequência.
     *
     * Essa regex serve para identificar quando um comando usa uma notação complexa para acessar propriedades de uma variável.
     * Exemplo:
     * <#if VAR_1 == 1> ${item[property]} </#if>
     *
     * Se a regex conseguir identificar que está tentando acessar a propriedade de uma variável, acessa essa propriedade.
     * Caso contrário, acessa a variável diretamente.
     * Se não for possível recuperar algum valor para a variável, retorna o valor undefined.
     * Algumas variáveis poderão ser definidas nos comandos de inclusão e/ou bloqueio, podendo assim
     * ter espaços em branco como por exemplo: RESULTS[RESULT?index + 1]
     * Em casos como estes o sistema deverá acessar o valor da variável de outra forma
     * através do parentAttribute e childAttribute
     *
     */
    public getVariableToReloadByCommand(variableToReloadByCommand, varInsideCommand) {
        let variableToReload;
        const regex = new RegExp(/^(\S+)\[(.+)\]$/);
        const varInsideCommandGrouped = regex.exec(varInsideCommand);

        if (_.isNull(varInsideCommandGrouped)) {
            variableToReload = variableToReloadByCommand[varInsideCommand];
        } else {
            const parentAttribute = varInsideCommandGrouped[1];
            const childAttribute = varInsideCommandGrouped[2];

            variableToReload = _.get(variableToReloadByCommand, [parentAttribute, childAttribute]);
        }

        return variableToReload;
    }

    public sync(instance, templatesParam, model) {
        this.variablesModel = model;
        this.templateInstance = instance;
        this.templates = templatesParam;

        this.readOnlyVariables = this.variablesModel.readOnlyVariables;
        this.globalVarsDetails = this.variablesModel.globalVarsDetails;
        this.equipmentLocalVarsDetails = this.variablesModel.equipmentLocalVarsDetails;
        this.lastCommandsValuesByDevice = this.variablesModel.lastCommandsValuesByDevice;
        this.lastCommandsByDevice = this.variablesModel.lastCommandsByDevice;
        this.isEdit = this.variablesModel.isEdit;
        this.isClone = this.variablesModel.isClone;
        this.variableToReloadByCommand = this.variablesModel.variableToReloadByCommand;
        this.modalData = this.variablesModel.modalData;
        this.equipmentTemplateChangedStatus = this.variablesModel.equipmentTemplateChangedStatus;
        this.removedEquipments = this.variablesModel.removedEquipments;
        this.variablesThatNeedToBeReloaded = this.variablesModel.variablesThatNeedToBeReloaded;
        this.originalValuesForVariablesBeforeEdition = this.variablesModel.originalValuesForVariablesBeforeEdition;
        this.templateApplicationResponseAssociation = this.variablesModel.templateApplicationResponseAssociation;
    }

    defineVariableType(type) {
        return this.variablesConst[type];
    }

    variableHasRestrictionsOptions(variable, equipment?) {
        if (!variable.globalScope) {
            if (equipment) {
                const equipmentIdentifier = equipment.equipmentIdentifier;
                const variableRestrictions = _.filter(this.variablesModel.variablesRestrictions, "var.name", variable.name);
                const deviceAssociation = _.find(variableRestrictions[0].deviceAssociations, {
                    deviceId: this.templateInstanceIdentifier.resolveIdentifier(equipmentIdentifier)
                });
                const equipmentAssociation = this.templateInstance.equipmentAssociations.find((association) =>
                    _.isEqual(association.equipmentIdentifier, equipmentIdentifier)
                );

                const templateIds = _.map(equipmentAssociation.templateAssociations, "templateId");

                return _.some(deviceAssociation.templatesWithRestrictions, (templateWithRestrictions) => {
                    return _.includes(templateIds, templateWithRestrictions);
                });
            }
        }
        return this.variablesConst.includeAndBlockOptionsModes.DO_NOT_GET !== variable.includeAndBlockOptionMode;
    }

    findVariableDetails(variable, deviceId: number) {
        if (variable.globalScope) {
            return this.findOmitDefaultView(this.globalVarsDetails.vars, variable);
        }

        const selectedEquipment = this.variablesModel.equipmentLocalVarsDetails.equipmentLocalVars.find(
            (equipment) => this.templateInstanceIdentifier.resolveIdentifier(equipment.equipmentIdentifier) == deviceId
        );
        return this.findOmitDefaultView(selectedEquipment.localVars, variable);
    }

    /*
     * TODO [#103771] Foi necessário ignorar o campo defaultView na busca pois esse campo está sendo
     * alterado apenas nas variáveis globalVarsDetails.vars e equipmentLocalVarsDetails.equipmentLocalVars
     * e não está sendo alterado na variável variablesRestrictions. O método que altera o defaultView é
     * o setVariableDefaultViewForReadOnly na variables-controller. Verificar se está correto não alterar
     * as variáveis do variablesRestrictions. Talvez tenha algum problema com as referências que pode
     * trazer outros efeitos colaterais.
     */
    private findOmitDefaultView(variables, variableToFind) {
        return _.find(variables, "variable", _.omit(variableToFind, "defaultView"));
    }

    public getVariableValues(varDetails) {
        return _.map(varDetails, (varDetail) => {
            return {
                name: varDetail.variable.name,
                type: varDetail.variable.type,
                values: angular.copy(varDetail.value)
            };
        });
    }

    public getGlobalVariablesValues() {
        return this.getVariableValues(this.globalVarsDetails.vars);
    }

    public addValidVariableValues(varValuesArray, variableValue) {
        if (variableValue.value) {
            const validValues = angular.copy(variableValue.value);
            _.remove(validValues, (v) => {
                return (
                    v == null ||
                    (_.isEqual(this.defineVariableType(variableValue.variable.type), this.variablesConst.INTEGER) &&
                        (v.length === 0 || isNaN(v)))
                );
            });

            if (validValues.length > 0) {
                varValuesArray.push({
                    name: variableValue.variable.name,
                    type: variableValue.variable.type,
                    values: validValues
                });
            }
        }
    }

    public getValidVariableValues(varDetails) {
        const variableValues = [];

        varDetails.forEach((varDetail) => {
            this.addValidVariableValues(variableValues, varDetail);
        });

        return variableValues;
    }

    public getDeviceVariablesValues(deviceId) {
        if (!_.isEmpty(this.equipmentLocalVarsDetails.equipmentLocalVars)) {
            const deviceLocalVarsDetails = _.find(
                this.equipmentLocalVarsDetails.equipmentLocalVars,
                (localVarsDetails: EquipmentAssociation) => {
                    return this.templateInstanceIdentifier.resolveIdentifier(localVarsDetails.equipmentIdentifier) == deviceId;
                }
            );

            if (deviceLocalVarsDetails) {
                return this.getValidVariableValues(deviceLocalVarsDetails.localVars);
            }
        }
    }

    public getAllVariablesValues(deviceId) {
        return _.union(this.getGlobalVariablesValues(), this.getDeviceVariablesValues(deviceId));
    }

    public includeVariableInsideCommands(variables, varInsideCommand, varDetail) {
        const variableToReloadByCommandExtracted = this.getVariableToReloadByCommand(
            this.variableToReloadByCommand,
            varInsideCommand
        );

        if (!_.includes(variables, varInsideCommand)) {
            variables.push(varInsideCommand);
            const varsDetails = [varDetail];
            _.set(this.variableToReloadByCommand, varInsideCommand, varsDetails);
        } else if (!_.includes(variableToReloadByCommandExtracted, varDetail)) {
            variableToReloadByCommandExtracted.push(varDetail);
        }
    }

    private getVariablesCommands(variable) {
        switch (variable.includeAndBlockOptionMode) {
            case "INCLUDE":
                return this.getExtractionParamsCommands(variable.inclusionParams);
            case "BLOCK":
                return this.getExtractionParamsCommands(variable.blockingParams);
            case "INCLUDE_AND_BLOCK":
                return _.union(
                    this.getExtractionParamsCommands(variable.inclusionParams),
                    this.getExtractionParamsCommands(variable.blockingParams)
                );
            default:
                return [];
        }
    }

    private getExtractionParamsCommands(extractionParamsCommands) {
        return _.chain([extractionParamsCommands.commands, extractionParamsCommands.transformationLogicCommands])
            .filter(_.negate(_.isEmpty))
            .value();
    }

    public getVariablesInsideCommands(varDetails, deviceId) {
        const variables = [];
        const intDeviceId = parseInt(deviceId);
        const variablesNames = _.map(varDetails, this.VAR_NAME_FIELD);

        varDetails.forEach((varDetail) => {
            const extractionCommands = this.getVariablesCommands(varDetail.variable);
            _.forEach(extractionCommands, (extractionCommand) => {
                this.processSimpleVarInsideCommand(intDeviceId, extractionCommand, varDetail, variables);
                this.processFreemarkerCommandVariable(intDeviceId, extractionCommand, varDetail, variablesNames, variables);
            });
        });

        return variables;
    }

    public getAllCommandsValues(deviceId) {
        const deviceLocalVarsDetails = _.find(
            this.equipmentLocalVarsDetails.equipmentLocalVars,
            (localVarsDetails: EquipmentAssociation) => {
                return this.templateInstanceIdentifier.resolveIdentifier(localVarsDetails.equipmentIdentifier) == deviceId;
            }
        );

        const localVariables = _.get(deviceLocalVarsDetails, "localVars", []);

        return this.getVariablesInsideCommands(_.union(this.globalVarsDetails.vars, localVariables), deviceId);
    }

    private fillVariableValues(variables, varDetail, variableValues) {
        if (_.includes(variables, varDetail.variable.name)) {
            variableValues.push({
                name: varDetail.variable.name,
                type: varDetail.variable.type,
                value: angular.copy(varDetail.value)
            });
        }
    }

    public getVariableDetailsWithVariablesValueDependency(variables, deviceId) {
        const variableValues = [];

        const deviceLocalVarsDetails = _.find(
            this.equipmentLocalVarsDetails.equipmentLocalVars,
            (localVarsDetails: EquipmentAssociation) => {
                return this.templateInstanceIdentifier.resolveIdentifier(localVarsDetails.equipmentIdentifier) == deviceId;
            }
        );

        if (deviceLocalVarsDetails) {
            _.forEach(deviceLocalVarsDetails.localVars, (varDetail) => {
                this.fillVariableValues(variables, varDetail, variableValues);
            });
        }

        _.forEach(this.globalVarsDetails.vars, (varDetail) => {
            this.fillVariableValues(variables, varDetail, variableValues);
        });

        return variableValues;
    }

    public isListType(variableMap) {
        switch (variableMap.variable.type) {
            case "TEXT_LIST":
            case "INTEGER_LIST":
            case "SELECTION_LIST":
                return true;
            default:
                return false;
        }
    }

    public getVariableSuffixes() {
        return this.suffixes;
    }

    public clearSuffixes(varDetails) {
        for (let i = 0; i < varDetails.value.length; i++) {
            if (varDetails.value[i]) {
                const str = varDetails.value[i].toString();
                varDetails.value[i] = str.replace(this.suffixes.blocked, "");
            }
        }

        for (let j = 0; j < varDetails.availableOptions.length; j++) {
            varDetails.availableOptions[j] = varDetails.availableOptions[j].replace(this.suffixes.blocked, "");
        }
    }

    public getAcceptedValues(variable, equipment) {
        let currentVar;

        if (this.isEdit) {
            if (variable.globalScope) {
                const isAllTemplateAssociationsSuccessfullyApplied = _.some(
                    this.templateInstance.equipmentAssociations,
                    (equipmentAssociation) => {
                        const templateAssociationsStatus = _.map(equipmentAssociation.templateAssociations, "status");
                        return !_.includes(templateAssociationsStatus, this.applicationStatus.FAIL);
                    }
                );

                if (isAllTemplateAssociationsSuccessfullyApplied) {
                    currentVar = _.find(this.globalVarsDetails.vars, { variable });
                }
            } else {
                const originalVariablesValuesForDevice = this.originalValuesForVariablesBeforeEdition.find((originalValue) =>
                    _.isEqual(originalValue.equipmentIdentifier, equipment.equipmentIdentifier)
                );

                const templateStatusMap = _.map(equipment.templateAssociations, "status");

                if (originalVariablesValuesForDevice && !_.includes(templateStatusMap, this.applicationStatus.FAIL)) {
                    currentVar = _.find(equipment.localVars, this.VAR_NAME_FIELD, variable.name);
                }
            }
        }

        return currentVar ? currentVar.value : [];
    }

    public setDisabledSelectOptions(varDetails, options) {
        const variableDetails = angular.copy(varDetails);

        if (variableDetails.availableOptions) {
            for (let i = 0; i < variableDetails.availableOptions.length; i++) {
                options.forEach((option) => {
                    if (this.mustBlockVariableOption(varDetails, option, i)) {
                        varDetails.availableOptions[i] += this.suffixes.blocked;
                    }
                });

                if (this.isEdit || this.isClone || varDetails.reload) {
                    variableDetails.value.forEach((value) => {
                        if (varDetails.availableOptions[i] == value + this.suffixes.blocked) {
                            varDetails.value[0] = varDetails.availableOptions[i];
                        }
                    });
                } else if (varDetails.variable.mandatory) {
                    const blockedDefaultValue = varDetails.variable.defaultValue + this.suffixes.blocked;
                    if (varDetails.variable.defaultValue && !_.includes(varDetails.availableOptions, blockedDefaultValue)) {
                        varDetails.value[0] = varDetails.variable.defaultValue;
                    } else {
                        for (const options of varDetails.availableOptions) {
                            if (!options.endsWith(this.suffixes.blocked)) {
                                varDetails.value[0] = options;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Verifica algumas condições para que a variável seja bloqueada
     */
    mustBlockVariableOption(varDetails, option, index) {
        return (
            this.blockValueIfVariableWasApplied(varDetails, option, index) ||
            this.blockValueIfLocalVariableAndValueNotApplied(varDetails, option, index) ||
            this.blockValueIfGlobalVariableAndNotAppliedValue(varDetails, option, index)
        );
    }

    /**
     * Verifica as seguintes condições para bloqueio da variável:
     * 1 - Verifica se a variável foi aplicada no template
     * 2 - Verifica se o valor a ser bloqueado não está incluso nos valores já aplicados
     * 3 - Verifica se a opção disponível é igual a opção a ser bloqueada.
     */
    blockValueIfVariableWasApplied(varDetails, option, index): boolean {
        return (
            varDetails.appliedValue &&
            !_.includes(varDetails.appliedValue, option) &&
            varDetails.availableOptions[index] === option
        );
    }

    /**
     * Verifica as seguintes condições para bloqueio da variável:
     * 1 - Verifica se a variável é local
     * 2 - Verifica se o valor a ser bloqueado não está incluso nos valores já aplicados
     * 3 - Verifica se a opção disponível é igual a opção a ser bloqueada.
     */
    blockValueIfLocalVariableAndValueNotApplied(varDetails, option, index): boolean {
        return (
            !varDetails.variable.globalScope &&
            !_.includes(varDetails.appliedValue, option) &&
            varDetails.availableOptions[index] === option
        );
    }

    /**
     * Verifica as seguintes condições para bloqueio da variável:
     * 1 - Verifica se a opção disponível não está inclusa nos valores já aplicados
     * 3 - Verifica se a opção disponível é igual a opção a ser bloqueada.
     */
    blockValueIfGlobalVariableAndNotAppliedValue(varDetails, option, index): boolean {
        return (
            !_.includes(varDetails.appliedValue, varDetails.availableOptions[index]) &&
            varDetails.availableOptions[index] === option
        );
    }

    public blockOptions(varDetails, options) {
        let optionsToBlock;
        if (this.isEdit) {
            optionsToBlock = _.difference(options, _.map(varDetails.appliedValue, String));
        } else {
            optionsToBlock = options;
        }
        varDetails.extractedOptionsToBlock = optionsToBlock;
        this.setDisabledSelectOptions(varDetails, optionsToBlock);
    }

    public filterOptions(variables) {
        return this.$filter("orderByNumbersAndLetters")(variables, true);
    }

    public isUnavailableForInclusion(varDetails, varValue) {
        const areAllValuesAccepted = [];
        varDetails.devices.forEach((device) => {
            const equipment = _.find(
                this.templateInstance.equipmentAssociations,
                (assoc: EquipmentAssociation) =>
                    this.templateInstanceIdentifier.resolveIdentifier(assoc.equipmentIdentifier) === device.deviceId
            );
            const acceptedValues = this.getAcceptedValues(varDetails.variable, equipment);
            areAllValuesAccepted.push(_.includes(acceptedValues, varValue));
        });

        return _.isEmpty(areAllValuesAccepted) || _.includes(areAllValuesAccepted, false);
    }

    public blockIncludeOptionsIfNeeded(filteredOptions, varDetails) {
        for (let i = 0; i < varDetails.value.length; i++) {
            if (!_.isEmpty(varDetails.value[i])) {
                const currentValue = angular.copy(varDetails.value[i]);
                varDetails.value[i] = varDetails.value[i].replace(this.suffixes.notAvailableForInclusion, "");
                if (!_.includes(filteredOptions, varDetails.value[i])) {
                    filteredOptions.push(varDetails.value[i]);
                    this.filterOptions(filteredOptions);
                    const value = this.isUnavailableForInclusion(varDetails, varDetails.value[i])
                        ? varDetails.value[i] + this.suffixes.notAvailableForInclusion
                        : varDetails.value[i];
                    filteredOptions[filteredOptions.indexOf(varDetails.value[i])] = value;
                    varDetails.value[i] = value;
                } else if (_.includes(currentValue, this.suffixes.notAvailableForInclusion)) {
                    filteredOptions = _.remove(filteredOptions, (option) => {
                        return option !== currentValue;
                    });
                }
            }
        }

        return filteredOptions;
    }

    public includeOptions(varDetails, options) {
        varDetails.extractedOptions = options;
        let filteredOptions = [];

        if (this.isReadOnly(varDetails) || this.isAction(varDetails)) {
            filteredOptions = this.filterOptions(varDetails.extractedOptions);
            filteredOptions = _.isEmpty(filteredOptions) ? [""] : filteredOptions;
        } else {
            filteredOptions = this.filterOptions(
                _.union(varDetails.variable.options, varDetails.extractedOptions, varDetails.appliedValue)
            );

            if (this.isEdit || varDetails.reload) {
                filteredOptions = this.blockIncludeOptionsIfNeeded(filteredOptions, varDetails);
            }
        }

        varDetails.availableOptions = filteredOptions;

        if (this.isAction(varDetails)) {
            varDetails.value[0] = varDetails.availableOptions.join("\n");
        }

        if (!this.isEdit && _.isEmpty(varDetails.value[0]) && varDetails.variable.mandatory) {
            varDetails.value[0] = varDetails.variable.defaultValue || varDetails.availableOptions[0];
        }

        this.$rootScope.$broadcast("varsToApply", { data: varDetails });
    }

    public checkForCommandValuesChanges() {
        var devices = this.getUsedDevices(this.lastCommandsValuesByDevice);

        return _.some(devices, (deviceId) => {
            var allVariablesInsideCommands = this.getAllCommandsValues(deviceId);
            var allCommandsValues = this.getVariableDetailsWithVariablesValueDependency(allVariablesInsideCommands, deviceId);
            return !angular.equals(this.lastCommandsByDevice[deviceId], allCommandsValues);
        });
    }

    public getUsedDevices(lastCommandsValuesByDevice) {
        var devices = _.filter(_.keys(lastCommandsValuesByDevice), (deviceId) => {
            return _.find(
                this.equipmentLocalVarsDetails.equipmentLocalVars,
                (equipmentLocalVar) =>
                    this.templateInstanceIdentifier.resolveIdentifier(equipmentLocalVar.equipmentIdentifier) == deviceId
            );
        });

        return devices;
    }

    public findVariableRestriction(variableName) {
        var deviceVariableRestriction = _.find(this.variablesModel.variablesRestrictions, (variableRestriction) => {
            return variableRestriction.var.name === variableName;
        });

        return deviceVariableRestriction;
    }

    public removeEquipmentFromReloadPendencyList(equipmentPendency) {
        if (_.isEmpty(equipmentPendency.globalVars) && _.isEmpty(equipmentPendency.localVars)) {
            _.remove(this.variablesThatNeedToBeReloaded, { equipmentId: equipmentPendency.equipmentId });
        }
    }

    public removeVariableFromReloadPendency(varName, equipmentId) {
        var equipment = _.find(this.variablesThatNeedToBeReloaded, { equipmentId: equipmentId });

        if (equipment) {
            _.remove(equipment.localVars, { variable: { name: varName } });
            _.remove(equipment.globalVars, { variable: { name: varName } });
            this.removeEquipmentFromReloadPendencyList(equipment);
        }
    }

    public reloadVariablesIfNeeded() {
        if (this.variablesThatNeedToBeReloaded) {
            var varsToReload = angular.copy(this.variablesThatNeedToBeReloaded);
            varsToReload.forEach((vars) => {
                vars.globalVars.forEach((variable) => {
                    this.reloadVariableDeviceOptions(variable, vars.equipmentId, null);
                });
                vars.localVars.forEach((variable) => {
                    this.reloadVariableDeviceOptions(variable, vars.equipmentId, null);
                });
            });
        }
    }

    public reloadVariableDeviceOptions(variable, equipment, previousExtractionStatus) {
        const equipmentId: number = _.get(equipment, "equipmentIdentifier.resourceId", equipment);
        const varDetails = this.findVariableDetails(variable.variable, equipmentId);
        varDetails.devicesList = _.map(varDetails.devices, "deviceId");
        varDetails.devices = [];

        if (previousExtractionStatus === this.optionsExtractionStatus.SUCCESS) {
            varDetails.reload = true;
        }

        const deviceVariableRestriction = this.findVariableRestriction(variable.variable.name);
        const variableRestriction = _.find(
            deviceVariableRestriction.deviceAssociations,
            (deviceAssociation) => deviceAssociation.deviceId == equipmentId
        );
        const deviceAssociations =
            equipmentId && variableRestriction ? [variableRestriction] : deviceVariableRestriction.deviceAssociations;

        deviceAssociations.forEach((deviceAssociation) => {
            deviceAssociation.extractedOptions = {
                result: null,
                inclusionOptions: { rawOutput: "", result: "", options: null },
                blockingOptions: { rawOutput: "", result: "", options: null }
            };
            this.startSpecificVariableOptionsExtraction(
                deviceAssociation,
                deviceAssociation.templatesWithRestrictions[0],
                variable
            );
            this.removeVariableFromReloadPendency(variable.variable.name, equipmentId);
        });
    }

    public reloadVariablesChanged() {
        var vars = [];
        var devices = this.getUsedDevices(this.lastCommandsByDevice);

        devices.forEach((device) => {
            var allVariablesInsideCommands = this.getAllCommandsValues(device);
            var variables = _.filter(this.lastCommandsByDevice[device], (value) => {
                var varsWithDependency = this.getVariableDetailsWithVariablesValueDependency(allVariablesInsideCommands, device);
                return !_.find(varsWithDependency, (varsWithDependency) => {
                    return (
                        varsWithDependency.name === value.name &&
                        varsWithDependency.type === value.type &&
                        _.isEqual(varsWithDependency.value, value.value)
                    );
                });
            });
            vars.push({ details: variables, deviceId: device });
        });

        vars.forEach((editedVariable) => {
            _.forEach(this.equipmentLocalVarsDetails.equipmentLocalVars, (equipment) => {
                equipment.localVars.forEach((localVariable) => {
                    editedVariable.details.forEach((varDetail) => {
                        var varsToReloadByCommand = _.mapValues(this.variableToReloadByCommand[varDetail.name], "variable");
                        var varToReload = _.find(varsToReloadByCommand, { name: localVariable.variable.name });
                        var sameDevice = _.isEqual(equipment.equipmentIdentifier.resourceId, parseInt(editedVariable.deviceId));
                        if (varToReload && this.variableHasRestrictionsOptions(localVariable.variable) && sameDevice) {
                            this.reloadVariableDeviceOptions(localVariable, editedVariable.deviceId, localVariable.status);
                        }
                    });
                });
            });

            _.forEach(this.globalVarsDetails.vars, (globalVariable) => {
                editedVariable.details.forEach((varDetail) => {
                    var varsToReloadByCommand = _.mapValues(this.variableToReloadByCommand[varDetail.name], "variable");
                    var varToReload = _.find(varsToReloadByCommand, { name: globalVariable.variable.name });
                    var sameDevice = _.includes(_.map(globalVariable.devices, "deviceId"), parseInt(editedVariable.deviceId));
                    if (varToReload && this.variableHasRestrictionsOptions(globalVariable.variable) && sameDevice) {
                        this.reloadVariableDeviceOptions(globalVariable, undefined, globalVariable.status);
                    }
                });
            });
        });
    }

    public reloadDependableVariables() {
        var needsReloading = this.checkForCommandValuesChanges();
        if (needsReloading) {
            this.reloadVariablesChanged();
        }
    }

    public addValues(variableDetails, inclusionExtractedOptions, blockingExtractedOptions) {
        if (this.isEdit || this.isClone || variableDetails.reload) {
            this.clearSuffixes(variableDetails);
        }

        if (inclusionExtractedOptions || !_.isEmpty(inclusionExtractedOptions)) {
            this.includeOptions(variableDetails, inclusionExtractedOptions);
        }

        if (blockingExtractedOptions || !_.isEmpty(blockingExtractedOptions)) {
            this.blockOptions(variableDetails, blockingExtractedOptions);
            if (variableDetails.autoSelection) {
                variableDetails.value[0] = _.find(variableDetails.availableOptions, (option) => this.isOptionAvailable(option));
            }
        }

        this.fillVariablesWithPreDefinedValues(variableDetails);

        this.reloadDependableVariables();
    }

    /**
     * Responsável por preencher os valores das variáveis que contenham valores
     * pré-definidos, sem buscar o próximo disponível, como é o caso da aplicação
     * de template em um passo na tela de GPON ONUs com as variáveis PORTA_PON,
     * ONU_ID e NUMERO_SERIE_ONU_DESCOBERTA. Ex.: GponOnusController#getParamsForAction
     *
     * @param variableDetails - Detalhes da variável
     */
    private fillVariablesWithPreDefinedValues(variableDetails) {
        if (this.variablesModel.initVariables) {
            var initVariable = _.find(this.variablesModel.initVariables, "name", variableDetails.variable.name);

            if (initVariable && initVariable.fillVariableWithMultipleValues) {
                this.setVariableMultipleInitialValues(variableDetails, initVariable);
            } else {
                var value = this.getVariableInitialValue(variableDetails, initVariable);
                variableDetails.value.splice(0, 1, value);
            }
        }
    }

    /**
     * Busca o valor para a variável levando em consideração valores pré-definidos e
     * se está bloqueado ou não.
     *
     * @param variableDetails - Detalhes da variável
     * @param initVariable - Variável com valor pré-definido
     *
     * @returns - Valor da variável
     */
    public getVariableInitialValue(variableDetails, initVariable) {
        var value = initVariable && initVariable.value !== undefined ? initVariable.value : variableDetails.value[0];

        if (value !== "") {
            var valueWithSuffix = value + this.suffixes.blocked;
            value = _.includes(variableDetails.availableOptions, valueWithSuffix) ? valueWithSuffix : String(value);
        }
        return value;
    }

    public setVariableMultipleInitialValues(variableDetails, initVariable) {
        let availableOption = variableDetails.value[0];
        const { availableOptions, variable } = variableDetails;
        const variableValueSupplier = this.selectionVariableValueSupplier[variable.name];

        const hasInitialValue = initVariable && initVariable.value;
        const { value: values } = hasInitialValue ? initVariable : variableDetails;

        if (variable.type.includes("LIST")) {
            values.forEach((variableValue, index) => {
                const value = variableValueSupplier.get(variableValue);
                variableDetails.value[index] = this.getBlockedOrFirstAvailableOption(availableOptions, value);
            });
        } else {
            const value = variableValueSupplier.get(values[0]);
            variableDetails.value[0] = this.getAvailableOption(availableOptions, availableOption, value);
        }
    }

    private getAvailableOption(availableOptions, firstAvailableOption, value) {
        const valueWithSuffix = value + this.suffixes.blocked;
        return _.includes(availableOptions, valueWithSuffix) || _.isEmpty(value) ? firstAvailableOption : value;
    }

    /**
     * Retorna a primeria opção disponível no array availableOptions, não necessáriamente a próxima opção.
     * Por exemplo, o array availableOptions possui: ["0", "1 blocked", "3", "4", "5"] e o value é 2. Nesse caso, o primeiro
     * valor disponível será utilizado, que é o "0" e não "3".
     */
    private getBlockedOrFirstAvailableOption(availableOptions, value) {
        const valueWithSuffix = value + this.suffixes.blocked;
        if (_.includes(availableOptions, valueWithSuffix)) {
            return valueWithSuffix;
        }

        if (_.includes(availableOptions, value)) {
            return value;
        }

        return availableOptions.find(option => this.isOptionAvailable(option));
    }

    private mergeBlockingOptions(variableRestriction) {
        var mergedOptions = [];

        variableRestriction.deviceAssociations.forEach((deviceAssociation) => {
            mergedOptions = _.union(mergedOptions, deviceAssociation.extractedOptions.blockingOptions.extractedOptions);
        });

        variableRestriction.extractedOptions = angular.copy(mergedOptions);

        return mergedOptions;
    }

    private mergeInclusionOptions(variableRestriction) {
        var inclusionOptions = _.map(variableRestriction.deviceAssociations, "extractedOptions.inclusionOptions");

        var options = angular.copy(_.map(inclusionOptions, "extractedOptions"));

        return _.reduce(options, (accumulator, value) => {
            // As variáveis 'accumulator' e 'value' quando null/undefined representam uma extração que não ocorreu
            // e devem ser ignoradas no merge.
            if (value) {
                if (accumulator) {
                    return _.intersection(accumulator, value);
                }
                return value;
            }
            return accumulator;
        });
    }

    public mergeOptions(variable, variableDetails) {
        var variableRestriction = _.find(this.variablesModel.variablesRestrictions, "var.name", variable.name);
        var includeMergedOptions = [];
        var blockMergedOptions = [];

        if (variable.includeAndBlockOptionMode === this.variablesConst.includeAndBlockOptionsModes.INCLUDE_AND_BLOCK) {
            includeMergedOptions = this.mergeInclusionOptions(variableRestriction);
            blockMergedOptions = this.mergeBlockingOptions(variableRestriction);
        } else if (variable.includeAndBlockOptionMode === this.variablesConst.includeAndBlockOptionsModes.INCLUDE) {
            includeMergedOptions = this.mergeInclusionOptions(variableRestriction);
        } else if (variable.includeAndBlockOptionMode === this.variablesConst.includeAndBlockOptionsModes.BLOCK) {
            blockMergedOptions = this.mergeBlockingOptions(variableRestriction);
        }

        this.addValues(variableDetails, includeMergedOptions, blockMergedOptions);
    }

    public cleanAvailableOptions(variableDetails) {
        if (
            _.isEqual(variableDetails.variable.type, this.variablesConst.SELECTION) ||
            _.isEqual(variableDetails.variable.type, this.variablesConst.SELECTION_LIST)
        ) {
            variableDetails.availableOptions.splice(0, variableDetails.availableOptions.length);
            variableDetails.availableOptions = angular.copy(variableDetails.variable.options);
        }

        if (this.isReadOnly(variableDetails)) {
            variableDetails.availableOptions.splice(0, variableDetails.availableOptions.length);
            variableDetails.availableOptions = [""];
        }
    }

    public getVarValueWhenDeviceErrorAplication(value, suffix, type) {
        if (_.isEmpty(value)) {
            return "";
        }

        return _.endsWith(value, suffix) || type === this.variablesConst.READ_ONLY ? value : value + suffix;
    }

    public isVariableValueChanged(varDetails) {
        return !_.isUndefined(varDetails.appliedValue) && !_.isEqual(varDetails.appliedValue, varDetails.value);
    }

    public getCommandsApplicationStatus(variables) {
        return _.some(variables, (variable) => {
            if (!this.isEdit || this.isVariableValueChanged(variable) || !this.isAllEquipmentsAppliedWithSuccess()) {
                return variable.status === this.optionsExtractionStatus.LOADING;
            }

            return false;
        });
    }

    public isLoadingCommands() {
        var invalid = false;

        if (!_.isEmpty(this.globalVarsDetails.vars)) {
            invalid = this.getCommandsApplicationStatus(this.globalVarsDetails.vars);
        }

        if (!invalid && !_.isEmpty(this.equipmentLocalVarsDetails.equipmentLocalVars)) {
            this.equipmentLocalVarsDetails.equipmentLocalVars.forEach((equipmentLocalVar) => {
                invalid = this.getCommandsApplicationStatus(equipmentLocalVar.localVars);
            });
        }

        return invalid;
    }

    convertTemplateInstanceVariables() {
        this.templateInstance.globalVars = this.TemplateInstanceVariableService.convertTemplateInstanceVars(
            angular.copy(this.globalVarsDetails.vars)
        );

        this.templateInstance.equipmentAssociations.forEach((equipmentAssociation: EquipmentAssociation) => {
            var equipmentLocalVars = _.find(
                this.equipmentLocalVarsDetails.equipmentLocalVars,
                (equipmentLocalVar: EquipmentAssociation) => {
                    return _.isEqual(equipmentAssociation.equipmentIdentifier, equipmentLocalVar.equipmentIdentifier);
                }
            );

            if (equipmentLocalVars) {
                equipmentAssociation.localVars = this.TemplateInstanceVariableService.convertTemplateInstanceVars(
                    equipmentLocalVars.localVars
                );
            }
        });
    }

    private updateVariablesDetailsToApply(deviceId, variable) {
        var allVariablesValues = this.getAllVariablesValues(deviceId);
        var variableDetails = this.findVariableDetails(variable, deviceId);
        var deviceInfo = this.templateInstance.equipmentAssociations.find(
            (assoc) => assoc.equipmentIdentifier.resourceId == deviceId
        );

        var deviceApplication: any = {
            deviceId: deviceId,
            deviceName: deviceInfo.equipmentDetails.name,
            deviceModel: deviceInfo.equipmentDetails.model,
            status: this.applicationStatus.APPLYING,
            extractedOptions: [],
            rawOutput: ""
        };

        if (!_.find(variableDetails.devices, { deviceId: deviceId })) {
            variableDetails.devices.push(deviceApplication);
        }

        variableDetails.devicesList = _.map(variableDetails.devices, "deviceId");

        if (this.isEdit || this.isClone) {
            variableDetails.availableOptions = this.isReadOnly(variableDetails)
                ? variableDetails.availableOptions
                : _.union(variableDetails.availableOptions, _.uniq(variableDetails.value));
        }

        var oldStatus = variableDetails.status ? variableDetails.status : "NONE";
        variableDetails.oldStatusList.push(angular.copy(oldStatus));
        this.startVarLoading(variableDetails);

        var path = deviceId;
        var allVariablesInsideCommands = this.getAllCommandsValues(deviceId);
        var allCommandsValues = this.getVariableDetailsWithVariablesValueDependency(allVariablesInsideCommands, deviceId);

        _.set(this.lastCommandsValuesByDevice, path, allVariablesValues);
        _.set(this.lastCommandsByDevice, path, allCommandsValues);
    }

    private startVarLoading(variableDetails): void {
        variableDetails.status = this.optionsExtractionStatus.LOADING;
        if (variableDetails.variable.enableAutomaticReload) {
            variableDetails.autoReloadCounterNotifier.stop.next();
        }
    }

    extractDeviceOptions() {
        var deviceAssociationsMap = _.map(_.map(this.variablesModel.variablesRestrictions, "deviceAssociations"), (obj) => {
            return angular.toJson(obj);
        });
        var devicesAssociation = _.flatten(
            _.map(_.uniq(deviceAssociationsMap), (obj) => {
                return angular.fromJson(obj);
            })
        );

        devicesAssociation.forEach((deviceAssociation) => {
            var templateId = _.first(deviceAssociation.templatesWithRestrictions);
            this.startDeviceOptionsExtraction(deviceAssociation, templateId);
        });
    }

    private hasExtractionError(result) {
        return _.contains(this.VARIABLES_EXTRACTION_ERRORS, result);
    }

    private chooseExtractionErrorMessageKey(options, commandsErrorKey, transformationLogicErrorKey) {
        return options.result === this.optionsExtractionStatus.PARSE_COMMAND_ERROR
            ? commandsErrorKey
            : transformationLogicErrorKey;
    }

    showVariableErrors(variable) {
        var status = variable.status;
        var messageKey;
        var params = [];

        params.push(variable.variable.name);

        if (status === this.optionsExtractionStatus.SERVICE_TIMEOUT_ERROR) {
            messageKey = "templateinstanceform.error.timeoutWaitingServerResponse";
            params = [this.DeviceOptionsExtractionService.getDeviceOptionsTimeoutInMinutes()];
        } else if (status === this.optionsExtractionStatus.UNKNOWN_ERROR) {
            messageKey = "templateinstanceform.applicationstatus.unknownError";
        } else {
            var rawOutput;
            var extractedOptions = variable.extractedOptions;

            if (extractedOptions.inclusionOptions && this.hasExtractionError(extractedOptions.inclusionOptions.result)) {
                messageKey = this.chooseExtractionErrorMessageKey(
                    extractedOptions.inclusionOptions,
                    "templateinstanceform.error.variablesWithIncludeCommands",
                    "templateinstanceform.error.variablesWithIncludeCommandsLogicTransformation"
                );
                rawOutput = extractedOptions.inclusionOptions.rawOutput;
            } else if (extractedOptions.blockingOptions && this.hasExtractionError(extractedOptions.blockingOptions.result)) {
                messageKey = this.chooseExtractionErrorMessageKey(
                    extractedOptions.blockingOptions,
                    "templateinstanceform.error.variablesWithBlockCommands",
                    "templateinstanceform.error.variablesWithBlockCommandsLogicTransformation"
                );
                rawOutput = extractedOptions.blockingOptions.rawOutput;
            }

            var messageDetails = "<br>" + this.$filter("replaceLineBreak")(rawOutput);
            params = [variable.variable.name, messageDetails];
        }

        this.$rootScope.showDialog({
            translateKey: messageKey,
            params: params,
            paramsInsideMessage: true
        });
    }

    updateOtherVariablesWithSameCommands(options, variable, deviceAssociation) {
        var sameCommandVariables = _.reject(options, { variableName: variable.name });

        if (options.length > 0) {
            _.filter(sameCommandVariables, (variable) => {
                var sameCommandVariable = _.find(this.variablesModel.variablesRestrictions, "var", {
                    name: variable.variableName
                });
                this.updateVariablesDetailsToApply(deviceAssociation.deviceId, sameCommandVariable.var);
            });
        }
    }

    removeApplicationAssociation(applicationAssociation, identifier, value) {
        _.remove(applicationAssociation.variablesIdentifiers, identifier, value);
        _.remove(this.variablesModel.templateApplicationResponseAssociation, (application) => {
            return _.isEmpty(application.variablesIdentifiers);
        });
    }

    startSpecificVariableOptionsExtraction(deviceAssociation, templateId, variable) {
        var deviceId = deviceAssociation.deviceId;
        var allVariablesValues = this.getAllVariablesValues(deviceId);

        /*
         * Remove uma associação previa que possa existir antes de disparar um reload.
         * Devido à natureza assíncrona da operação de extração, isso é necessario  para evitar
         * que dados antigos sejam utilizados erroneamente.
         */
        var application = _.find(this.variablesModel.templateApplicationResponseAssociation, "deviceId", deviceId);
        if (application) {
            this.removeApplicationAssociation(application, "variableName", variable.variable.name);
        }

        this.DeviceOptionsExtractionService.extractSpecificVariableOptions(
            deviceId,
            templateId,
            variable.variable.name,
            variable.selectedAction,
            allVariablesValues
        ).then((response) => {
            var variableIdentifiers = response.plain();
            var timeoutInMiliseconds = this.DeviceOptionsExtractionService.getDeviceOptionsTimeoutInMilliseconds();

            if (variableIdentifiers.length !== 0
                && variableIdentifiers.some((id) => id.variableName === variable.variable.name)) {

                this.updateVariablesDetailsToApply(deviceAssociation.deviceId, variable.variable);
                this.templateApplicationResponseAssociation.push({
                    variablesIdentifiers: variableIdentifiers,
                    templateId: templateId,
                    deviceId: deviceId
                });
                var timeoutPromise = this.$timeout(
                    this.variableResponseTimedOut.bind(this),
                    timeoutInMiliseconds,
                    true,
                    angular.copy(variableIdentifiers)
                );
                this.variableResponseTimeoutPromises.push(timeoutPromise);
                this.updateOtherVariablesWithSameCommands(variableIdentifiers, variable.variable, deviceAssociation);
            }
        });
    }

    startDeviceOptionsExtraction(deviceAssociation, templateId) {
        if (templateId) {
            this.$rootScope.hideLoadingPanel = true;
            var deviceId = deviceAssociation.deviceId;
            var allVariablesValues = this.isEdit ? this.getAllVariablesValues(deviceId) : [];

            this.DeviceOptionsExtractionService.extractDeviceOptions(deviceId, templateId, allVariablesValues).then(
                (response) => {
                    var variableIdentifiers = response.plain();
                    var timeoutInMiliseconds = this.DeviceOptionsExtractionService.getDeviceOptionsTimeoutInMilliseconds();

                    if (variableIdentifiers.length != 0) {
                        this.variablesModel.variablesRestrictions.forEach((variableRestriction) => {
                            var variable = variableRestriction.var;
                            if (
                                this.variableHasRestrictionsOptions(variable) &&
                                variableIdentifiers.some((id) => id.variableName === variable.name)
                            ) {
                                var deviceAssociations = variableRestriction.deviceAssociations;
                                deviceAssociations.forEach((deviceAssociation) => {
                                    this.updateVariablesDetailsToApply(deviceAssociation.deviceId, variable);
                                });
                            }
                        });

                        this.templateApplicationResponseAssociation.push({
                            variablesIdentifiers: variableIdentifiers,
                            templateId: templateId,
                            deviceId: deviceId
                        });
                        var timeoutPromise = this.$timeout(
                            this.variableResponseTimedOut.bind(this),
                            timeoutInMiliseconds,
                            true,
                            angular.copy(variableIdentifiers)
                        );
                        this.variableResponseTimeoutPromises.push(timeoutPromise);
                    }
                }
            );

            this.$rootScope.hideLoadingPanel = false;
        }
    }

    initVarDetails(varDetails) {
        this.initVariableValue(varDetails);
        if (_.isEmpty(varDetails.availableOptions)) {
            if (_.isEqual(this.defineVariableType(varDetails.variable.type), this.variablesConst.SELECTION)) {
                varDetails.availableOptions = angular.copy(varDetails.variable.options);
            } else if (this.isReadOnly(varDetails)) {
                varDetails.availableOptions = [""];
                this.readOnlyVariables.push({ name: varDetails.variable.name, value: varDetails.value });
            } else {
                varDetails.availableOptions = [];
            }
        }
        if (!varDetails.devices) {
            varDetails.devices = [];
        }
        if (!varDetails.oldStatusList) {
            varDetails.oldStatusList = [];
        }
        varDetails.autoSelection =
            _.isUndefined(varDetails.autoSelection) || _.isNull(varDetails.autoSelection)
                ? varDetails.variable.allowAutoSelection
                : varDetails.autoSelection;

        if (varDetails.variable.enableAutomaticReload) {
            varDetails.autoReloadCounterNotifier = {
                start: new Subject<void>(),
                stop: new Subject<void>()
            };
        }
    }

    initVariableValue(variable) {
        if (variable.value.length === 1 && variable.value[0] === "" && !this.isEdit) {
            var value = variable.variable.defaultValue;
            if (
                this.variablesModel.initVariables &&
                variable.variable.includeAndBlockOptionMode === this.variablesConst.includeAndBlockOptionsModes.DO_NOT_GET
            ) {
                var initVariable = _.find(this.variablesModel.initVariables, "name", variable.variable.name);
                value = initVariable && initVariable.value ? initVariable.value : value;
            }
            variable.value.splice(0, 1, value);
        } else if (this.isEdit && this.isAllEquipmentsAppliedWithSuccess()) {
            variable.appliedValue = angular.copy(variable.value);
        }
    }

    updateGlobalVars(variables) {
        return new Promise((resolve) => {
            var inter = [];
            var globalVars = _.map(
                _.filter(variables, (variable) => {
                    return variable.var.globalScope;
                }),
                "var"
            );
            var oldVars = angular.copy(this.globalVarsDetails);

            var newVars = this.TemplateInstanceVariableService.updateGlobalVars(
                globalVars,
                this.globalVarsDetails,
                this.templateInstance.globalVars
            );

            _.forEach(oldVars.vars, (oldVar) => {
                _.forEach(newVars, (newVar) => {
                    if (_.isEqual(oldVar.variable, newVar.variable)) {
                        inter.push(oldVar);
                    } else {
                        var varDetails = _.find(this.globalVarsDetails.vars, { variable: oldVar.variable });
                        varDetails.value = [];
                        varDetails.availableOptions = [];
                        varDetails.extractedOptions = [];
                        newVar.value = [""];
                    }
                });
            });

            this.globalVarsDetails.vars = _.uniq(_.union(inter, newVars), this.VAR_NAME_FIELD);

            this.globalVarsDetails.vars.forEach((varDetails) => {
                this.initVarDetails(varDetails);
            });

            resolve(this.globalVarsDetails);
        });
    }

    createEquipmentVariables(variables) {
        var result = [];
        var localVars = _.filter(variables, (variable) => {
            return !variable.var.globalScope;
        });

        var templateAssociations = _.flattenDeep(_.map(this.templateInstance.equipmentAssociations, "templateAssociations"));
        var templateIds = _.uniq(_.map(templateAssociations, "templateId"));

        templateIds.forEach((templateId) => {
            var vars = [];

            localVars.forEach((variable) => {
                var variableTemplateIds = _.flatten(_.map(variable.deviceAssociations, "templateIds"));

                if (_.includes(variableTemplateIds, templateId)) {
                    vars.push(angular.copy(variable.var));
                }
            });

            result.push({ localVars: vars, templateId: templateId });
        });

        return result;
    }

    updateLocalVars(variables) {
        return new Promise((resolve) => {
            var templateVariables = this.createEquipmentVariables(variables);
            var oldVars = angular.copy(this.equipmentLocalVarsDetails.equipmentLocalVars);

            this.equipmentLocalVarsDetails.equipmentLocalVars = angular.copy(this.templateInstance.equipmentAssociations);
            this.equipmentLocalVarsDetails.equipmentLocalVars.forEach((equipment: EquipmentAssociation) => {
                var inter;
                var newLocalVars = this.TemplateInstanceVariableService.updateLocalVars(templateVariables, equipment);

                _.forEach(oldVars, (oldEquipmentsAssociation: EquipmentAssociation) => {
                    if (_.isEqual(equipment.equipmentIdentifier, oldEquipmentsAssociation.equipmentIdentifier)) {
                        inter = _.filter(oldEquipmentsAssociation.localVars, (oldVar) => {
                            var isEqualVar = _.find(newLocalVars, "variable", _.omit(oldVar.variable, "defaultView"));
                            if (isEqualVar) {
                                return oldVar;
                            } else {
                                oldVar.value = [];
                                oldVar.availableOptions = [];
                                oldVar.extractedOptions = [];
                                const newVar = _.find(newLocalVars, this.VAR_NAME_FIELD, oldVar.variable.name);
                                if (newVar) {
                                    newVar.value = [""];
                                }
                            }
                        });
                    }
                });

                equipment.localVars = _.uniq(_.union(inter, newLocalVars), this.VAR_NAME_FIELD);

                equipment.localVars.forEach((varDetails) => {
                    if (!this.variableHasRestrictionsOptions(varDetails.variable, equipment)) {
                        varDetails.availableOptions = varDetails.variable.options;
                        varDetails.extractedOptions = [];
                    }
                    this.initVarDetails(varDetails);
                });
            });

            resolve(this.equipmentLocalVarsDetails.equipmentLocalVars);
        });
    }

    createVarsRestrictionsMap(varsRestrictions) {
        var variableDeviceAssociations = [];

        varsRestrictions.forEach((restriction) => {
            restriction.deviceAssociations.forEach((deviceAssociation) => {
                var variable = _.find(variableDeviceAssociations, { name: restriction.var.name });
                if (variable) {
                    variable.deviceAssociations.push({
                        deviceId: deviceAssociation.deviceId,
                        templateIds: deviceAssociation.templateIds
                    });
                } else {
                    variableDeviceAssociations.push({
                        name: restriction.var.name,
                        deviceAssociations: [
                            {
                                deviceId: deviceAssociation.deviceId,
                                templateIds: deviceAssociation.templateIds
                            }
                        ]
                    });
                }
            });
        });

        return variableDeviceAssociations;
    }

    getDiff(map1, map2) {
        return _.filter(map1, (map) => {
            return !_.find(map2, map);
        });
    }

    getRemovedEquipmentsByVariables(variablesRestrictionsMap) {
        var result = [];
        variablesRestrictionsMap.forEach((variableRestriction) => {
            this.removedEquipments.forEach((removedEquipment) => {
                var templateIds = _.flatten(_.map(variableRestriction.deviceAssociations, "templateIds"));
                if (_.includes(templateIds, removedEquipment.templateId)) {
                    result.push({ name: variableRestriction.name, deviceIds: removedEquipment.deviceIds });
                }
            });
        });

        return result;
    }

    reloadVariables(diff) {
        var globalVarsName = _.map(
            _.filter(diff, (variable) => {
                return variable.var.globalScope;
            }),
            "var.name"
        );
        var equipmentLocalVars = [];
        var localVarsMap = _.map(diff, _.partialRight(_.pick, ["var", "deviceAssociations"]));

        if (localVarsMap.length > 0) {
            var varToApply;
            localVarsMap.forEach((localVar) => {
                localVar.deviceAssociations.forEach((device) => {
                    var equipment = _.filter(
                        this.equipmentLocalVarsDetails.equipmentLocalVars,
                        (equipmentLocalVar: EquipmentAssociation) => {
                            return (
                                this.templateInstanceIdentifier.resolveIdentifier(equipmentLocalVar.equipmentIdentifier) ==
                                device.deviceId
                            );
                        }
                    );

                    if (!_.isEmpty(equipment)) {
                        equipment = equipment[0];
                        varToApply = _.filter(equipment.localVars, (variable) => {
                            return (
                                !this.isAction(variable) &&
                                _.isEqual(variable.variable.name, localVar.var.name) &&
                                _.intersection(_.map(equipment.templateAssociations, "templateId"), _.map(device.templateIds))
                            );
                        });

                        if (!_.isEmpty(varToApply)) {
                            equipmentLocalVars.push(equipment);
                        }
                    }
                });
            });

            equipmentLocalVars.forEach((equipment) => {
                equipment.localVars.forEach((localVar) => {
                    if (this.variableHasRestrictionsOptions(localVar.variable) && !this.isAction(localVar)) {
                        this.reloadVariableDeviceOptions(localVar, equipment, null);
                    }
                });
            });
        }

        if (globalVarsName.length > 0) {
            var globalVars = _.uniq(
                _.filter(this.globalVarsDetails.vars, (globalVar) => {
                    return !this.isAction(globalVar) && _.includes(globalVarsName, globalVar.variable.name);
                })
            );

            globalVars.forEach((globalVar) => {
                if (this.variableHasRestrictionsOptions(globalVar.variable)) {
                    this.reloadVariableDeviceOptions(globalVar, null, null);
                }
            });
        }
    }

    async updateVariablesRestrictions(variables) {
        var varsToApply = [];
        var oldVariablesRestrictions = angular.copy(this.variablesModel.variablesRestrictions);
        var equipmentLocalVars = this.equipmentLocalVarsDetails.equipmentLocalVars;
        this.variablesModel.variablesRestrictions = variables;

        await this.updateGlobalVars(this.variablesModel.variablesRestrictions);
        await this.updateLocalVars(this.variablesModel.variablesRestrictions);

        this.$rootScope.$broadcast("variableValueHasChange");

        if (!oldVariablesRestrictions) {
            this.extractDeviceOptions();
        } else {
            var newMap = this.createVarsRestrictionsMap(this.variablesModel.variablesRestrictions);
            var oldMap = this.createVarsRestrictionsMap(oldVariablesRestrictions);

            var diffOldToNew = this.getDiff(oldMap, newMap);
            var diffNewToOld = this.getDiff(newMap, oldMap);

            if (this.equipmentTemplateChangedStatus === EquipmentTemplateChangedStatus.ONLY_DEVICES_REMOVED) {
                var removedEquipmentsByVariables = this.getRemovedEquipmentsByVariables(newMap);

                _.forEach(this.globalVarsDetails.vars, (varDetails) => {
                    var devicesToIgnore = _.find(removedEquipmentsByVariables, { name: varDetails.variable.name });

                    if (devicesToIgnore) {
                        var options = [];
                        var inclusionOptions = [];
                        var blockingOptions = [];

                        varDetails.devices.forEach((device) => {
                            if (!_.includes(devicesToIgnore.deviceIds, device.deviceId)) {
                                var deviceInclusionOptions = device.extractedOptions.inclusionOptions;
                                var deviceBlockingOptions = device.extractedOptions.blockingOptions;

                                if (
                                    varDetails.variable.includeAndBlockOptionMode ===
                                    this.variablesConst.includeAndBlockOptionsModes.INCLUDE_AND_BLOCK
                                ) {
                                    options.push(deviceInclusionOptions.extractedOptions);
                                    blockingOptions = _.union(blockingOptions, deviceBlockingOptions.extractedOptions);
                                } else if (
                                    varDetails.variable.includeAndBlockOptionMode ===
                                    this.variablesConst.includeAndBlockOptionsModes.INCLUDE
                                ) {
                                    options.push(deviceInclusionOptions.extractedOptions);
                                } else {
                                    blockingOptions = _.union(blockingOptions, deviceBlockingOptions.extractedOptions);
                                }
                            }
                        });

                        _.remove(varDetails.devices, (device) => {
                            return _.includes(devicesToIgnore.deviceIds, device.deviceId);
                        });

                        this.variablesModel.variablesRestrictions.forEach((varRestriction) => {
                            _.remove(varRestriction.deviceAssociations, (device) => {
                                return _.includes(devicesToIgnore.deviceIds, device.deviceId);
                            });
                        });

                        if (
                            varDetails.variable.includeAndBlockOptionMode ===
                            this.variablesConst.includeAndBlockOptionsModes.INCLUDE_AND_BLOCK
                        ) {
                            this.clearSuffixes(varDetails);
                            inclusionOptions = _.reduce(options, (accumulator, value) => {
                                return _.intersection(accumulator, value);
                            });
                        } else if (
                            varDetails.variable.includeAndBlockOptionMode ===
                            this.variablesConst.includeAndBlockOptionsModes.INCLUDE
                        ) {
                            inclusionOptions = _.reduce(options, (accumulator, value) => {
                                return _.intersection(accumulator, value);
                            });
                        } else {
                            this.clearSuffixes(varDetails);
                        }

                        this.addValues(varDetails, inclusionOptions, blockingOptions);
                    }
                });

                this.$rootScope.$broadcast("validate");

                _.remove(equipmentLocalVars, (equipment) => {
                    return equipment.localVars.length === 0;
                });

                if (!_.isEmpty(equipmentLocalVars)) {
                    _.remove(this.templateInstance.equipmentAssociations, (equipmentAssociation: EquipmentAssociation) => {
                        return !_.some(equipmentLocalVars, (equipmentLocalVar: EquipmentAssociation) => {
                            return _.isEqual(equipmentLocalVar.equipmentIdentifier, equipmentAssociation.equipmentIdentifier);
                        });
                    });
                }
            } else if (this.equipmentTemplateChangedStatus === EquipmentTemplateChangedStatus.NEEDS_CUSTOM_UPDATE) {
                var addRemoveVarDeviceDiff;
                if (diffNewToOld.length > 0) {
                    addRemoveVarDeviceDiff = diffNewToOld;
                } else if (diffNewToOld.length === 0) {
                    addRemoveVarDeviceDiff = diffOldToNew;
                }

                _.forEach(addRemoveVarDeviceDiff, (diff) => {
                    _.forEach(this.variablesModel.variablesRestrictions, (restriction) => {
                        if (restriction.var.name === diff.name) {
                            varsToApply.push({
                                var: restriction.var,
                                deviceAssociations: diff.deviceAssociations
                            });
                        }
                    });
                });

                this.reloadVariables(varsToApply);
            }
        }

        this.convertTemplateInstanceVariables();
        this.variablesModel.equipmentTemplateChangedStatus = EquipmentTemplateChangedStatus.NO_CHANGE;
        this.removedEquipments = [];
    }

    hasError(status) {
        return this.hasApplicationError(status) || this.hasGenericError(status);
    }

    hasGenericError(status) {
        return (
            status === this.optionsExtractionStatus.PARSE_COMMAND_ERROR ||
            status === this.optionsExtractionStatus.SERVICE_TIMEOUT_ERROR ||
            status === this.optionsExtractionStatus.UNKNOWN_ERROR ||
            status === this.optionsExtractionStatus.TRANSFORMATION_LOGIC_ERROR
        );
    }

    hasApplicationError(status) {
        return status === this.optionsExtractionStatus.DEVICE_APPLICATION_ERROR;
    }

    unsubscribeWebsocket() {
        if (this.variablesModel && this.variablesModel.subscription) {
            this.variablesModel.subscription.unsubscribe();
            this.variablesModel.subscription = null;
        }

        if (this.websocket) {
            this.websocket.close();
        }
    }

    /**
     * Verifica se uma variável é do tipo READ ONLY.
     * Converte o type para que seja possível comparar usando o valor da constante VARIABLES.
     */
    isReadOnly(variableDetails) {
        return _.isEqual(this.defineVariableType(variableDetails.variable.type), this.variablesConst.READ_ONLY);
    }

    /**
     * Verifica se uma variável é do tipo ACTION.
     * Converte o type para que seja possível comparar usando o valor da constante VARIABLES.
     */
    isAction(variableDetails) {
        return _.isEqual(this.defineVariableType(variableDetails.variable.type), this.variablesConst.ACTION);
    }

    private cancelOptionExtractionTimeoutIfAllResponsesWereReceived() {
        var waitingResponseVariables = this.getVariablesWaitingResponse();
        if (_.isEmpty(waitingResponseVariables) && this.variableResponseTimeoutPromises) {
            while (!_.isEmpty(this.variableResponseTimeoutPromises)) {
                this.$timeout.cancel(this.variableResponseTimeoutPromises.pop());
            }
        }
    }

    private updateVariablesAfterApplying(response) {
        var shouldPublishVariableEvent = false;
        var data = response;
        var associations = _.filter(this.variablesModel.templateApplicationResponseAssociation, (template) => {
            return _.find(template.variablesIdentifiers, "id", data.id);
        });

        _.forEach(associations, (association) => {
            var variableIdentifier = _.find(association.variablesIdentifiers, "id", data.id);
            if (variableIdentifier) {
                var variableToUpdate = _.find(
                    this.variablesModel.variablesRestrictions,
                    "var.name",
                    variableIdentifier.variableName
                );

                // Remove a associação quando a resposta é recebida.
                this.removeApplicationAssociation(association, "id", variableIdentifier.id);
                this.cancelOptionExtractionTimeoutIfAllResponsesWereReceived();

                var deviceAssociation = _.find(variableToUpdate.deviceAssociations, "deviceId", association.deviceId);

                if (variableToUpdate && deviceAssociation) {
                    var variable = variableToUpdate.var;

                    deviceAssociation.extractedOptions = {
                        result: this.getExtractionResult(variable, data.inclusionOptions, data.blockingOptions),
                        inclusionOptions: data.inclusionOptions,
                        blockingOptions: data.blockingOptions
                    };
                    this.updateVariableDetailsData(variable, association, deviceAssociation);
                    shouldPublishVariableEvent = true;
                }
            }
        });

        if (shouldPublishVariableEvent) {
            this.$rootScope.$broadcast("variableValueHasChange");
            this.$rootScope.$broadcast("validate");
        }
    }

    private getExtractionResult(variable, inclusionOptions, blockingOptions) {
        switch (variable.includeAndBlockOptionMode) {
            case this.variablesConst.includeAndBlockOptionsModes.INCLUDE_AND_BLOCK:
                if (inclusionOptions.result !== this.applicationStatus.SUCCESS) {
                    return inclusionOptions.result;
                }

                if (blockingOptions.result !== this.applicationStatus.SUCCESS) {
                    return blockingOptions.result;
                }

                return this.applicationStatus.SUCCESS;
            case this.variablesConst.includeAndBlockOptionsModes.INCLUDE:
                return inclusionOptions.result;
            case this.variablesConst.includeAndBlockOptionsModes.BLOCK:
                return blockingOptions.result;
        }
    }

    isAllEquipmentsAppliedWithSuccess() {
        var result = true;

        this.templateInstance.equipmentAssociations.forEach((equipmentAssociation) => {
            result = result && _.every(equipmentAssociation.templateAssociations, "status", this.applicationStatus.SUCCESS);
        });

        return result;
    }

    private updateVariableDetailsData(variable, association, deviceAssociation) {
        var variableDetails = this.findVariableDetails(variable, association.deviceId);
        var variableDetailsCopy = angular.copy(variableDetails);
        var variableAssociation = _.find(variableDetails.devices, "deviceId", association.deviceId);

        var inclusionOptions = deviceAssociation.extractedOptions.inclusionOptions;
        var blockingOptions = deviceAssociation.extractedOptions.blockingOptions;

        variableAssociation.status = deviceAssociation.extractedOptions.result;
        variableAssociation.extractedOptions = {
            inclusionOptions: inclusionOptions,
            blockingOptions: blockingOptions
        };

        var isApplying = _.some(variableDetails.devices, { status: this.applicationStatus.APPLYING });
        if (!isApplying) {
            var allDevicesConcludedWithSuccess = _.every(variableDetails.devices, "status", this.applicationStatus.SUCCESS);
            if (allDevicesConcludedWithSuccess) {
                variableDetails.status = this.applicationStatus.SUCCESS;

                if (variable.globalScope) {
                    this.mergeOptions(variable, variableDetails);
                } else {
                    var inclusionExtractedOptions = _.isEmpty(inclusionOptions)
                        ? []
                        : _.flatten(inclusionOptions.extractedOptions);
                    var blockingExtractedOptions = _.isEmpty(blockingOptions) ? [] : _.flatten(blockingOptions.extractedOptions);
                    this.addValues(variableDetails, inclusionExtractedOptions, blockingExtractedOptions);
                }
            } else {
                this.cleanAvailableOptions(variableDetails);
                var deviceError = _.find(variableDetails.devices, (device) => {
                    return device.status !== this.applicationStatus.SUCCESS;
                });
                variableDetails.status = deviceError.status;
                variableDetails.extractedOptions = {
                    inclusionOptions: deviceError.extractedOptions.inclusionOptions,
                    blockingOptions: deviceError.extractedOptions.blockingOptions
                };
            }

            if (variableDetails.variable.enableAutomaticReload) {
                variableDetails.autoReloadCounterNotifier.start.next({countStart: variableDetails.variable.automaticReloadDelay});
            }
        }

        if (this.modalData.updateApplicationStatus) {
            this.modalData.updateApplicationStatus(association.deviceId, variable.name, deviceAssociation.extractedOptions);
        }

        if (this.hasApplicationError(variableDetails.status)) {
            if (
                this.isEdit &&
                _.isEqual(variableDetails.value, variableDetails.appliedValue) &&
                this.isAllEquipmentsAppliedWithSuccess()
            ) {
                variableDetails.availableOptions = variableDetails.value;
            } else {
                var suffix = this.suffixes.notAvailableForInclusion;
                for (var i = 0; i < variableDetailsCopy.value.length; i++) {
                    variableDetails.availableOptions[i] = variableDetailsCopy.value[i];
                    var value = this.getVarValueWhenDeviceErrorAplication(
                        variableDetails.availableOptions[i],
                        suffix,
                        variableDetails.variable.type
                    );
                    var index = variableDetails.availableOptions.indexOf(variableDetailsCopy.value[i]);
                    variableDetails.availableOptions[index] = value;
                    variableDetails.value[i] = value;
                }
            }
        }

        if (!this.isLoadingCommands()) {
            this.convertTemplateInstanceVariables();
        }
    }

    /**
     * Avalia se a opção está bloqueada ou indisponível para seleção
    */
    isOptionAvailable(option) {
        return (!_.includes(option, this.suffixes.notAvailableForInclusion) && !_.includes(option, this.suffixes.blocked));
    }

    removeOptionSuffix(optionValue) {
        return optionValue.replace(this.suffixes.blocked, "").replace(this.suffixes.notAvailableForInclusion, "");
    }

    async createVariablesThatNeedToBeReloaded(varRestrictions) {
        if (this.isEdit && this.hasTemplateApplicationsWithStatusFailOrNotRequested()) {
            this.prepareGlobalVariablesThatNeedReload(varRestrictions);
            this.prepareLocalVariablesThatNeedReload(varRestrictions);
        }
    }

    async startAutoReloadVariables() {
        _.forEach(this.globalVarsDetails.vars, (globalVar) => {
            if (globalVar.variable.enableAutomaticReload) {
                this.reloadVariableDeviceOptions(globalVar, null, globalVar.status);
            }
        })

        _.forEach(this.equipmentLocalVarsDetails.equipmentLocalVars, (equipment) => {
            equipment.localVars.forEach((localVariable) => {
                if (localVariable.variable.enableAutomaticReload) {
                    this.reloadVariableDeviceOptions(localVariable, equipment.deviceId, localVariable.status);
                }
            });
        });
    }

    extractOriginalValuesForVariablesBeforeEdition() {
        return new Promise((resolve) => {
            if (this.isEdit) {
                this.templateInstance.equipmentAssociations.forEach((equipmentAssociation: EquipmentAssociation) => {
                    var originalVariablesValuesForDevice: any = {
                        equipmentIdentifier: equipmentAssociation.equipmentIdentifier,
                        localVars: angular.copy(equipmentAssociation.localVars)
                    };
                    this.originalValuesForVariablesBeforeEdition.push(originalVariablesValuesForDevice);
                });
            }

            resolve(this.originalValuesForVariablesBeforeEdition);
        });
    }

    private hasTemplateApplicationsWithStatusFailOrNotRequested(): boolean {
        const statusesToBeReloaded = [this.applicationStatus.FAIL, this.applicationStatus.NOT_REQUESTED];
        return this.templateInstance.equipmentAssociations.some((association) =>
            association.templateAssociations
                .some(templateAssociation => statusesToBeReloaded.includes(templateAssociation.status))
        );
    }

    private prepareLocalVariablesThatNeedReload(varRestrictions) {
        const excludesStatus = [ this.applicationStatus.SUCCESS, this.applicationStatus.NOT_ORDERED,
            this.applicationStatus.NOT_REQUESTED
        ];

        const equipmentAssociationsWithLocalVars = _.filter(this.templateInstance.equipmentAssociations, (association) => {
                return !_.isEmpty(association.localVars);
            }
        );

        equipmentAssociationsWithLocalVars.forEach((association) => {
            const templatesWithFailStatus = _.filter(association.templateAssociations, (templateAssociation) => {
                return !_.includes(excludesStatus, templateAssociation.status);
            });

            templatesWithFailStatus.forEach((template) => {
                varRestrictions.forEach((varRestriction) => {
                    if (!varRestriction.var.globalScope) {
                        const equipmentRestrictions = _.filter(varRestriction.deviceAssociations, (deviceAssociation) =>
                                deviceAssociation.deviceId === this.templateInstanceIdentifier.resolveIdentifier(
                                    association.equipmentIdentifier)
                        );

                        if (this.hasTemplatesWithRestrictions(equipmentRestrictions, template.templateId)) {
                            this.addEquipmentRestriction(this.templateInstanceIdentifier.resolveIdentifier(
                                association.equipmentIdentifier), { variable: varRestriction.var });
                        }
                    }
                });
            });
        });
    }

    private hasTemplatesWithRestrictions(equipmentRestrictions, templateId) {
        return _.some(equipmentRestrictions, (restriction) => {
            return _.includes(restriction.templatesWithRestrictions, templateId);
        });
    }

    private addEquipmentRestriction(equipmentId, variable) {
        var equipment = _.find(this.variablesThatNeedToBeReloaded, (variable) => variable.equipmentId == equipmentId);

        if (!equipment) {
            equipment = { equipmentId: equipmentId, globalVars: [], localVars: [] };
            this.variablesThatNeedToBeReloaded.push(equipment);
        }

        if (variable.variable.globalScope) {
            equipment.globalVars.push(angular.copy(variable));
        } else {
            equipment.localVars.push(angular.copy(variable));
        }
    }

    private prepareGlobalVariablesThatNeedReload(varRestrictions) {
        const globalVariableRestrictions = _.filter(varRestrictions, (variableRestriction) => {
            return variableRestriction.var.globalScope && this.variableHasRestrictionsOptions(variableRestriction.var);
        });

        globalVariableRestrictions.forEach((globalVariableRestriction) => {
            globalVariableRestriction.deviceAssociations.forEach((deviceAssociation) => {
                this.addEquipmentRestriction(deviceAssociation.deviceId, { variable: globalVariableRestriction.var });
            })
        });
    }

    private processSimpleVarInsideCommand(deviceId, extractionCommand, varDetail, variables) {
        var regex = new RegExp(/{(.*?)}/g);
        var foundVariable;
        while ((foundVariable = regex.exec(extractionCommand))) {
            if (_.includes(varDetail.devicesList, deviceId)) {
                var simpleVarName = foundVariable[1].replace(/\[\d\]/g, "");
                this.includeVariableInsideCommands(variables, simpleVarName, varDetail);
            }
        }
    }

    // TODO[US-7190] - Foi alterada a regex pois havia alguns casos em que não estava encontrando a variável
    // e outros casos em que estava encontrando a variável onde não era para encontrar.
    // Exemplos: 1 - Em casos como este: VAR_INDEX ou VAR_LIST ou VAR_ + 'alguma coisa' não deverá ocorrer match na regex,
    // 2 - Em casos como este VAR? ou ${VAR} ou outros casos em que apareçam somente o nome da variável não acrescida
    // de 'undescore' como no caso acima, deverá ocorrer match.
    // Essa validação deverá ser realizada no próprio compilador do Freemarker no backend.
    // Foi mapeada a US acima para realizar essa modificação.
    private processFreemarkerCommandVariable(deviceId, extractionCommand, varDetail, variablesNames, variables) {
        var regexToFreemarkerCommand = new RegExp(/<(.*?)>/g);
        var freemarkerCommand = extractionCommand.match(regexToFreemarkerCommand);
        if (freemarkerCommand) {
            variablesNames.forEach((varName) => {
                const regexByVar = new RegExp(`\\\${${varName}(\\\[.*\\\])?}|${varName}\\\?|\\\ ${varName}\\\ |${varName}>`, "g");
                freemarkerCommand.forEach((command) => {
                    var hasVarInsideCommand = regexByVar.test(command);
                    if (hasVarInsideCommand && _.includes(varDetail.devicesList, deviceId)) {
                        this.includeVariableInsideCommands(variables, varName, varDetail);
                    }
                });
            });
        }
    }
}
