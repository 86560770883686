/**
 * Controller para modal de leaflist.
 *
 * @author joao.otavio
 */
var app = angular.module("nms.dynamicDevice");

app.controller("LeafListModalController", ["$rootScope", "$scope", "leafListTemplate", "config", "labelModal", "parentPath",
    "shouldWatchChanges", "pathKeys", "isTransient",
    function($rootScope, $scope, leafListTemplate, config, labelModal, parentPath, shouldWatchChanges, pathKeys, isTransient) {
        $scope.leaflist = Object.assign({}, leafListTemplate);
        $scope.parentPath = parentPath;
        $scope.config = config;
        $scope.labelModal = labelModal;
        $scope.hasErrorInForm = false;
        $scope.shouldWatchChanges = shouldWatchChanges;
        $scope.pathKeys = pathKeys;
        $scope.isTransient = isTransient;

        $scope.confirmChangeValues = function() {
            if ($scope.hasErrorInForm) {
                $scope.showToastError();
                return false;
            }

            $scope.confirm($scope.leaflist.values);
        };

        $scope.$watch("form.$invalid", function(invalid) {
            if(_.isBoolean(invalid) && invalid) {
                $scope.hasErrorInForm = true;
            } else {
                $scope.hasErrorInForm = false;
            }
        });

        $scope.showToastError = function() {
            $rootScope.toastError("nes.services.config.invalidValues");
        };
    }
]);
