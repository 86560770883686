import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

/**
 * Serviço responsável por retornar funcionalidades utilizadas no freemarker.
 */
@Injectable({
    providedIn: "root"
})
export class FreemarkerExpressionService {

    private readonly formatCommandFreemarker: any = {
        start: "${",
        end: "}"
    };

    private LINE_BREAK: string = "\n";

    constructor(@Inject(ANGULARJS_TRANSLATE) private translate: any) {}

    public createExpressions() {
        const expressions = [
            {name: "If", fn: this.getIfSample},
            {name: "If/else", fn: this.getIfElseSample},
            {name: "Switch", fn: this.getSwitchSample},
            {name: "List (loop)", fn: this.getLoopSample},
            {name: "Assign", fn: this.getAssignSample},
            {name: this.translate.instant("templateform.buttons.insertComment"), fn: this.getCommentSample}
        ]
        return expressions;
    }

    public getFormatCommandFreemarker() {
        return this.formatCommandFreemarker;
    }

    public getVariableTooltip() {
        return this.translate.instant("templateform.variables.tooltips.freemarker");
    }

    /**
    * Returns an example of using variables with freemarker
    * @method getVariablesSample
    * @param {Object|Array} variables - list where the first variable will generate de example
    * @return {String} generated example with given variable
    */
     getVariablesSample = (variables) => {
        return variables
            .map(variable => this.getVariableSample(variable))
            .join(" ");
    }

    /**
    * Returns an example of an IF statement in freemarker
    * @method getIfSample
    * @param {Object|Array} variables - list where the first variable will be included in the example
    * @return {String} generated IF example with given variable (when provided)
    */
    getIfSample = (variables?)  => {
        const firstVarName = this.getFirstVariableName(variables);
        const extraVarsLabel = this.getExtraVariablesLabel(variables);

        const sample =
            "<#if " + firstVarName + " == \"some_value\">" + this.LINE_BREAK +
            "  some_command " + extraVarsLabel + this.LINE_BREAK +
            "</#if>";

        return sample;
    }

    /**
    * Returns an example of an IF/ELSE statement in freemarker
    * @method getIfElseSample
    * @param {Object|Array} variables - list where the first variable will be included in the example
    * @return {String} generated IF/ELSE example with given variable (when provided)
    */
    getIfElseSample = (variables?) => {
        const firstVarName = this.getFirstVariableName(variables);
        const extraVarsLabel = this.getExtraVariablesLabel(variables);

        const sample =
            "<#if " + firstVarName + " == \"some_value\">" + this.LINE_BREAK +
            "  some_command " + extraVarsLabel + this.LINE_BREAK +
            "<#else>" + this.LINE_BREAK +
            "  some_other_command " + extraVarsLabel + this.LINE_BREAK +
            "</#if>";

        return sample;
    }

    /**
    * Returns an example of a SWITCH statement in freemarker
    * @method getSwitchSample
    * @param {Object|Array} variables - list where the first variable will be included in the example
    * @return {String} generated SWITCH example with given variable (when provided)
    */
    getSwitchSample = (variables?) => {
        const firstVarName = this.getFirstVariableName(variables);
        const extraVarsLabel = this.getExtraVariablesLabel(variables);

        const sample =
            "<#switch " + firstVarName + ">" + this.LINE_BREAK +
            "  <#case 1>" + this.LINE_BREAK +
            "    some_command " + extraVarsLabel + this.LINE_BREAK +
            "    <#break>" + this.LINE_BREAK +
            "  <#case 2>" + this.LINE_BREAK +
            "    some_other_command " + extraVarsLabel + this.LINE_BREAK +
            "    <#break>" + this.LINE_BREAK +
            "  <#case 3>" + this.LINE_BREAK +
            "    some_another_command " + extraVarsLabel + this.LINE_BREAK +
            "    <#break>" + this.LINE_BREAK +
            "  <#default>" + this.LINE_BREAK +
            "    some_default_command " + extraVarsLabel + this.LINE_BREAK +
            "</#switch>";

        return sample;
    }

    /**
    * Returns an example of a LOOP in freemarker
    * @method getLoopSample
    * @param {Object|Array} variables - list where the first variable will be included in the example
    * @return {String} generated LOOP example with given variable (when provided)
    */
    getLoopSample = (variables?) => {
        let sample;

        if (!this.isNullOrEmpty(variables)) {
            const firstVarName = variables[0].name;
            const itemName = firstVarName + "_item";
            let extraVarsLabel = "";
            const extraVars = angular.copy(variables);
            extraVars.shift();
            extraVars.forEach((v) => {
                extraVarsLabel += " ${" + v.name + "[" + itemName + "?index]}";
            });

            sample = "<#list " + firstVarName + " as " + itemName + ">" + this.LINE_BREAK +
                     "  some_command ${" + itemName + "}" + extraVarsLabel + this.LINE_BREAK +
                     "</#list>";
        } else {
            sample = "<#list 1..12 as list_item>" + this.LINE_BREAK +
                     "  some_command ${list_item}" + this.LINE_BREAK +
                     "</#list>";
        }

        return sample;
    }

    /**
    * Returns an example of an ASSIGN statement in freemarker
    * @method getAssignSample
    * @param {Object|Array} variables - list where the first variable will be included in the example
    * @return {String} generated ASSIGN example with given variable (when provided)
    */
    getAssignSample = (variables?) => {
        let extraVarsLabel = "";
        let endText = "";
        if (variables && variables.length > 0) {
            endText = "_some more text";
            variables.forEach((v) => {
                extraVarsLabel += "_${" + v.name + "}";
            });
        }

        return `<#assign some_variable = "some text${extraVarsLabel}${endText}">${this.LINE_BREAK}some_command \${some_variable}`;
    }

    /**
    * Returns an example of a COMMENT in freemarker
    * @method getCommendSample
    * @return {String} generated COMMENT example according to browser language
    */
    getCommentSample = () => {
        return "<#-- " + this.translate.instant("templateform.variables.messages.commentSample") + " -->";
    };

    isNullOrEmpty = (variables) => {
        return !variables || variables.length === 0;
    }

    getVariableSample = (variable) => {
        return "${" + variable.name + "}";
    }

    getFirstVariableName = (variables) => {
        return this.isNullOrEmpty(variables) ? "some_variable" : variables[0].name;
    }

    getExtraVariablesLabel = (variables) => {
        if (variables) {
            const extraVars = angular.copy(variables);
            extraVars.shift();

            return this.getVariablesSample(extraVars);
        }

        return "";
    };
}