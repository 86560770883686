import { Inject, Injectable } from "@angular/core";
import * as Handlebars from "handlebars/dist/handlebars";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Notification } from "@nms-ng2/app/shared/services/nms-notification/nms-notification.service";

/**
 * Responsável por configurar o Handlebars template language.
 * O Handlebars utiliza-se de um template + objeto de dados para criar uma mensagem formatada.
 * @link https://handlebarsjs.com/guide/
 */
@Injectable({
  providedIn: "root",
})
export class HandlebarsService {
  private handlebars: any;
  private partials = [
    { name: "templateTable", fileName: "template_application_table" },
  ];
  constructor(@Inject(ANGULARJS_TRANSLATE) translate: any) {
    this.handlebars = Handlebars;
    this.addHelpers(translate);
    this.registerPartial();
  }

  public getTemplateEngine() {
    return this.handlebars;
  }

  /**
   * Adiciona os helpers a serem utilizados nos templates.
   * Helpers:
   * - i18n: Responsável por traduzir mensagens
   *      params:
   *          - translationKey: chave de tradução
   *          - Notification: dados enviados via websocket da notificação. Este valor, ser[a interpolado com a mensagem.
   *                          Para utilizar esses dados a chave de tradução deve seguir o padrão "translation.key": "Hello {{property}}"
   *                          e o template deverá passar o parâmetro . que indica os dados enviados ao compilador do handlebars {{ i18n "translation.key" . }}.
   * - concat: Responsável por concatenar duas strings.
   *    params:
   *          - prefix: primeira string
   *          - sufix: segunda string
   *          - separator: tipo de separador a ser usado na concatenação (default: " ")
   */
  private addHelpers(translate: any) {
    this.handlebars.registerHelper(
      "i18n",
      (translationKey: string, notification: Notification) =>
        translate.instant(translationKey, notification)
    );
    this.handlebars.registerHelper("concat", this.concat.bind(this));
  }

  private concat(prefix, suffix, separator = " ") {
    return new this.handlebars.SafeString(
      `${this.handlebars.escapeExpression(prefix)}${separator}${this.handlebars.escapeExpression(suffix)}`);
  }

  private registerPartial() {
    this.partials.forEach(({ name, fileName }) => {
      fetch(`templates/nms-notification/partials/${fileName}.hbs`)
        .then((response) => response.text())
        .then((template) => this.handlebars.registerPartial(name, template));
    });
  }
}
