import { AuditSettings } from "./audit-retention-modal.component";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Inject, Injectable } from "@angular/core";
import {
  ANGULARJS_TRANSLATE,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Observable } from "rxjs/internal/Observable";

/**
 * Serviço responsável por realizar as requisições referentes as configurações da retenção de auditoria.
 */
@Injectable({
  providedIn: "root"
})
export class AuditRetentionService {

    private readonly CLOSE: boolean = true;
    auditRetentionUrl: string = "/audit/settings/";
    closeModalEmmiter = new EventEmitter<boolean>();

    constructor(private http: HttpClient, private toastr: NmsToastrService, @Inject(ANGULARJS_TRANSLATE) private translate) {}

    public getAuditRetentionSettings(): Observable<AuditSettings> {
        return this.http.get<AuditSettings>(this.auditRetentionUrl);
    }

    public saveAuditRetentionSettings(auditSettings: AuditSettings): void {
        this.http.put(this.auditRetentionUrl, auditSettings).subscribe(() => {
            this.toastr.success(this.translate.instant("audit.retention.saved"));
            this.closeModalEmmiter.emit(this.CLOSE);
        }, (responseError) => {
            console.error(`Could not save audit settings retention ${responseError}`);
            this.toastr.error(this.translate.instant("audit.retention.saved.error"));
        });
    }
}


