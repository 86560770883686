"use strict"

/**
 * Yang Nodes (Statements) Filters.
 *
 * @author patrick.bard
 */
var app = angular.module("nms.dynamicDevice");

app.filter("hasConfigurableChildren", ["NodeInfoCache",
    function(nodeCache) {
        return function(nodePath) {
            if (nodePath) {
                return hasChildren(nodeCache.getNode(nodePath), "configurableChildren");
            }
            return false;
        };
    }
]);

app.filter("hasNonConfigurableChildren", ["NodeInfoCache",
    function(nodeCache) {
        return function(nodePath) {
            if (nodePath) {
                return hasChildren(nodeCache.getNode(nodePath), "nonConfigurableChildren");
            }

            return false;
        };
    }
]);

app.filter("hasConfigurableChildrenToShow", ["NodeInfoCache", "YangStatements", "SessionPreferencesService",
    function(nodeCache, YangStatements, SessionPreferencesService) {
        return function(nodePath) {
            var filters = SessionPreferencesService.getFilters();

            if (nodePath && filters.showConfig) {
                return hasAnyElement(nodeCache.getNode(nodePath), "configurableChildren", _.values(YangStatements));
            }
            return false;
        };
    }
]);

app.filter("hasNonConfigurableChildrenToShow", ["NodeInfoCache", "YangStatements", "SessionPreferencesService",
    function(nodeCache, YangStatements, SessionPreferencesService) {
        return function(nodePath) {
            var filters = SessionPreferencesService.getFilters();

            if (nodePath && filters.showStatus) {
                if (!filters.showConfig) {
                    return hasAnyElement(nodeCache.getNode(nodePath), "nonConfigurableChildren", _.values(YangStatements));
                }

                var cachedNode = nodeCache.getNode(nodePath);
                var nodeTypesToShow = _.without(_.values(YangStatements), YangStatements.LISTS, YangStatements.CONTAINERS);
                if (hasAnyElement(cachedNode, "nonConfigurableChildren", nodeTypesToShow)) {
                    return true;
                }

                var configurableNodes = flattenValues(_.pick(cachedNode.configurableChildren,
                    [YangStatements.LISTS, YangStatements.CONTAINERS]));
                var nonConfigurableNodes = flattenValues(_.pick(cachedNode.nonConfigurableChildren,
                    [YangStatements.LISTS, YangStatements.CONTAINERS]));
                var exclusiveNonConfigurableNodes = _.difference(nonConfigurableNodes, configurableNodes);
                return exclusiveNonConfigurableNodes.length > 0;
            }
            return false;
        };
    }
]);

app.filter("isEmptyValue",
    function() {
        return function(value) {
            if (_.isNull(value) || angular.isUndefined(value) || _.isEmpty(value)) {
                return true;
            }

            return false;
        };
    }
);

app.filter("hasChildrenToShowInTree", ["NodeInfoCache", "YangStatements", "DomainHandlerService",
    function(nodeCache, YangStatements, DomainHandlerService) {
        return function(nodeElement, filters) {
            if (nodeElement) {
                var nodePath = DomainHandlerService.getPath(nodeElement);

                if (!filters || (filters.showStatus && filters.showConfig)) {
                    return hasNonLeafChildren(nodePath, "configurableChildren", nodeCache, YangStatements)
                            || hasNonLeafChildren(nodePath, "nonConfigurableChildren", nodeCache, YangStatements);
                } else if (filters && filters.showStatus) {
                    return hasNonLeafChildren(nodePath, "nonConfigurableChildren", nodeCache, YangStatements);
                } else if (filters && filters.showConfig) {
                    return hasNonLeafChildren(nodePath, "configurableChildren", nodeCache, YangStatements);
                }
            }

            return false;
        };
    }
]);

app.filter("hasNonContainerChildrenToShow", ["NodeInfoCache", "DomainHandlerService",
    function(nodeCache, DomainHandlerService) {
        return function(nodeElement) {
            if (nodeElement) {
                var nodePath = DomainHandlerService.getPath(nodeElement);

                if (nodePath) {
                    var cachedNode = nodeCache.getNode(nodePath);
                    return cachedNode.hasNonContainerChildren;
                }
            }

            return false;
        };
    }
]);

app.filter("showNodeBasedOnFilters", ["NodeInfoCache", "DomainHandlerService", "SessionPreferencesService", "TreeFilterService",
    "$filter",
    function(NodeInfoCache, DomainHandlerService, SessionPreferencesService, TreeFilterService, $filter) {
        return function(node, useChildren, filtered, filterDownwards) {
            var filters = SessionPreferencesService.getFilters();
            var cachedNode = NodeInfoCache.getNode(DomainHandlerService.getPath(node));

            var showNode = false;

            if (useChildren) {
                if (filters.showStatus) {
                    showNode = hasChildren(cachedNode, "nonConfigurableChildren");
                }
                if (!showNode && filters.showConfig) {
                    showNode = hasChildren(cachedNode, "configurableChildren");
                }
            } else {
                showNode = matchFilter(cachedNode, filters);
            }

            if (TreeFilterService.getCurrentSearchTerm()) {
                showNode = showNode && (filtered || filterDownwards);
            }

            return !$filter("isHidden")(node) && showNode;
        };
    }
]);

/**
 * Filtro responsável por exibir ou não um nó na info/config.
 *
 * nodeElement: Nó atual a ser testado pelo filtro
 * filterOnlyConfig: Flag que indica se o filtro por deve ser feito apenas para nós de configuração
 * useChildren: Flag para verificar se o nó atual possui filhos
 */
app.filter("showNode", ["NodeInfoCache", "DomainHandlerService", "SessionPreferencesService", "$filter",
    function(NodeInfoCache, DomainHandlerService, SessionPreferencesService, $filter) {
        return function(schemaNode, filterOnlyConfig, useChildren) {
            var filters = SessionPreferencesService.getFilters();
            var cachedNode = NodeInfoCache.getNode(DomainHandlerService.getPath(schemaNode));
            var showNode = false;

            if (useChildren) {
                var configurableChildren = flattenValues(cachedNode.configurableChildren);
                var nonConfigurableChildren = flattenValues(cachedNode.nonConfigurableChildren);

                /**
                 * Depois da funcionalidade que permite exibir containers como itens de lista
                 * um novo caso apareceu, onde uma lista de configuração possui um container de status.
                 * Para esse caso o filterOnlyConfig está sendo enviado como null para indicar que a validação
                 * é independente da localização da lista e leva em consideração apenas a existência
                 * ou não de filhos sendo estes de status ou de configuração.
                 */
                if (filterOnlyConfig === null) {
                    showNode = nonConfigurableChildren.length > 0 || configurableChildren.length > 0;
                } else if (filterOnlyConfig === true && filters.showConfig) {
                    showNode = configurableChildren.length > 0;
                } else if (filterOnlyConfig === false) {
                    if (!filters.showConfig && filters.showStatus) {
                        showNode = nonConfigurableChildren.length > 0;
                    }

                    if (filters.showStatus && filters.showConfig) {
                        showNode = nonConfigurableChildren.length > 0 && configurableChildren.length === 0;
                    }
                }
            } else {
                showNode = cachedNode.isConfiguration === filterOnlyConfig && matchFilter(cachedNode, filters);
            }

            return !$filter("isHidden")(schemaNode) && showNode;
        };
    }
]);

/**
* Retorna o estado de visibilidade atual do nodo, de acordo com as validações de WHEN/DISPLAY_WHEN processadas em
* node-status-service.js.
*
* O resultado deste filtro só é confiável, se o nodo já estiver registrado em node-status-service#register, pois ele não faz o
* registro e verificação das cláusulas, apenas retorna o valor atual já processado e armazenado em memória.
*
* Quando este filtro é utilizado para controlar as diretivas de listagem de nodos dentro da configurable-content (leaves,
* containers, leaflists e choices), deve ser utilizada a diretiva 'ng-show' ou 'ng-hide' do Angular, para assegurar que as
* diretivas internas individuais (leave, container, leaflist e choice) sejam sempre criadas (porém invisíveis) e possam registrar
* seus nodos no node-status-service. Caso contrário, se o controle de visibilidade for feito através de um 'ng-if', as diretivas
* internas não serão criadas (conseguentemente os nodos não serão registrados no serviço de verificação do WHEN), e os estados de
* visibilidade dos elementos nunca poderá ser alterado.
*/
app.filter("isVisibleByWhen", ["NodeStatusService",
    function(NodeStatusService) {
        return function(node, pathKeys) {
            return NodeStatusService.getCurrentVisibility(node, pathKeys);
        };
    }
]);

/**
 * Returns the flattened values of an object
 *
 * @param {object} object with properties and values
 * @return {boolean} flattened array with all values
 */
function flattenValues(object) {
    return _.flatten(_.values(object));
}

/**
 * Verify if a node has non leaf children on a given property
 *
 * @param {String} nodePath path of the node
 * @param {String} property Property used on cache (i.e. configurable, nonConfigurable)
 * @param {object} nodeInfoCache cache service to get node information
 * @param {object} YangStatements to get leaves and leaf-list property names
 * @return {boolean}
 */
function hasNonLeafChildren(nodePath, property, nodeInfoCache, YangStatements) {
    if (nodePath) {
        var cachedNode = nodeInfoCache.getNode(nodePath);
        var node = Object.assign({}, cachedNode[property]);
        node[YangStatements.LEAVES] = [];
        node[YangStatements.LEAFLISTS] = [];
        return flattenValues(node).length > 0;
    }
    return false;
}

/**
 * Verify if a node has children on a given property
 *
 * @param {object} node
 * @param {String} property Property used on cache (i.e. configurable, nonConfigurable)
 * @return {boolean}
 */
function hasChildren(node, property) {
    if (node) {
        return flattenValues(node[property]).length > 0;
    }

    return false;
}

/**
 * Verify if a node has any element on the given property and fields.
 *
 * @param {object} node
 * @param {String} property Property used on cache (i.e. configurable, nonConfigurable)
 * @param {object} fields fields to look for elements
 * @return {boolean}
 */
function hasAnyElement(node, property, fields) {
    if (node && node[property]) {
        var nodeProperty = node[property];
        var any = _.some(fields, function(field) {
            return nodeProperty[field] && nodeProperty[field].length > 0;
        });
        return any;
    }

    return false;
}

/**
 * Verify if a node configuration matches with filter.
 *
 * @param {object} cachedNode opened node
 * @param {object} filters page filter value
 * @return {boolean}
 */
function matchFilter(cachedNode, filters) {
    if (cachedNode.isConfiguration) {
        return filters.showConfig;
    } else {
        return filters.showStatus;
    }
}
