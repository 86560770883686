import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

/**
 * Encapsula o componente de dropdown de variáveis criado em AngularJS fazendo o upgrade da diretiva.
 */
@Directive({
    selector: "template-commands-step-wrapper"
})
export class TemplateCommandsStepWrapper extends UpgradeComponent {

    @Input() templateController?: any;
    @Input() stepController?: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        super("templateCommandsStepWrapper", elementRef, injector);
    }
}