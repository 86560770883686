import { Translatable, TranslationEnum, TranslationKey, TranslationMap } from "@nms-ng2/app/shared/models/translation.models";

/**
 * Representa o critério de como as regras serão processadas, podendo assumir os valores:
 * ALL: as regras serão processadas com o operador "AND";
 * ONE_OF_THE: as regras serão processadas com o operados "OR".
 */
export enum RuleCriteria {
    ALL = "ALL",
    ONE_OF_THE = "ONE_OF_THE"
}

/**
 * Representa o tipo das regras, definindo o tipo de filtro que será realizado.
 * Por exemplo, uma regra com o tipo "EQUALS" verificará se o valor procurado é exatamente igual ao valor assumido pelo campo.
 */
export enum RuleType {
    CONTAINS = "CONTAINS",
    DOES_NOT_CONTAIN = "DOES_NOT_CONTAIN",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH",
    EQUALS = "EQUALS",
    NOT_EQUALS = "NOT_EQUALS",
    GREATER_THAN = "GREATER_THAN",
    LESS_THAN = "LESS_THAN",
    GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
    LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
    BETWEEN = "BETWEEN",
    NOT_BETWEEN = "NOT_BETWEEN",
    IS_DMOS = "IS_DMOS",
    NOT_DMOS = "NOT_DMOS",
    AFTER = "AFTER",
    BEFORE = "BEFORE"
}

/**
 * Representa o tipo do campo das regras.
 * Exemplo: Regras do tipo IS_DMOS ou NOT_DMOS, são booleanas, mas as demais são strings.
 */
export enum RuleFieldType {
    STRING = "STRING",
    BOOLEAN = "BOOLEAN"
}

/**
 * Representa uma regra, contendo os seguintes campos:
 * ruleType: tipo que definirá como a regra será processada.
 * values: tupla de valores que deverão ser procurados na regra em questão.
 *         valor[0] é utilizado em todos os tipos de regras
 *         valor[1] é utilizado apenas quando a regra é BETWEEN
 * field (Opcional): campo que o qual deve estar vinculado a regra.
 * id: identificador único da regra. Este campo é incluído automaticamente pelo componente @see MatchingRulesComponent.
 *     O objetivo é linkar uma regra com o seu Differ específico em @see MatchingRulesDifferService.
 */
export interface Rule {
    ruleType: RuleType;
    values: [string, string?];
    field?: string;
    id?: string;
}

/**
 * Representa o objeto referente a um tipo de regra
 */
export interface RuleTypeObject extends Translatable {
    type: RuleFieldType
}

/**
 * Representa os tipos de regras que estarão disponíveis para seleção, onde:
 * key: @see RuleType
 * value: @see RuleTypeObject or @see RuleTypeObject (extends @see Translatable)
 *
 * Aceita os dois valores para manter a compatibilidade com o modelo antigo
 * ao mesmo tempo que possibilita uma versão simplificada
 */
export type RuleTypesSet = {
    [key in RuleType]?: RuleTypeObject
};

/**
 * Representa um campo vinculado à uma regra, onde:
 *     initial: indica se é o campo inicialmente selecionado.
 */
export interface RuleField extends Translatable {
    initial?: boolean;
}

/**
 * Representa os campos vinculados a regras, onde:
 * key: nome do campo vinculado a regra, por exemplo NAME, KEYWORD, DESCRIPTION...
 * value: @see RuleField (extends @see Translatable)
 */
export interface RuleFieldsSet {
    [key: string]: RuleField
}

/**
 * Representa o conjunto de critérios de regras que estarão disponíveis para seleção
 * e suas chaves de tradução. @see TranslationEnum<T> onde T é @See RuleCriteria
 */
 export type RuleCriteriasSet = TranslationEnum<RuleCriteria>

/**
 * Representa o modelo de regras completo, contendo o modo, regras e se elas devem ser utilizadas.
 * É a entrada e a saída do componente @see MatchingRulesComponent, onde:
 * criteria: Critério de regras selecionado pelo usuário (opções: @see RuleMode )
 * rules: Regras definidas pelo usuário.
 * useRules: indica se as regras definidas serão de fato utilizadas. É opcional pois o componente pode ser
 * inicializado com o input showUseMatchingRulesCheck como false, fazendo com que as regras sempre sejam usadas.
 */
export interface MatchingRules {
    criteria: RuleCriteria;
    rules: Rule[];
    useRules?: boolean;
}

/**
 * Representa as chaves de tradução.
 * isBlocked: chave de tradução para quando o componente estiver desabilitado.
 * matchingRules: chave de tradução para label inicial do componente.
 * criteriaBelow: chave de tradução para label inicial do componente.
 * atLeastOneRule: chave de tradução para mensagem de warning que avisa que pelo menos uma regra deve existir se o componente
 *                 estiver habilitado.
 * criteriaBelowTip: chave de tradução para explicação sobre uso de campos em regras.
 */
export interface MatchingRulesTranslationKeys extends TranslationMap {
    isBlocked: TranslationKey;
    matchingRules: TranslationKey;
    criteriaBelow: TranslationKey;
    atLeastOneRule: TranslationKey;
    criteriaBelowTip?: TranslationKey;
}
