"use strict";

/**
 * @ngdoc service
 * @name nms.service:DevicesTableService
 * @description Serviço para centralizar ações da tabela de devices (directive#devicesTable).
 */
var app = angular.module("nms");

app.service("DevicesTableService", [
    "LocationService",
    "DeviceAugmentService",
    "DropdownDeviceService",
    "DevicesActionsService",
    "LOCATION",
    "$translate",
    "LicenseService",
    "UserPreferencesService",
    "AuthenticationService",
    "$q",
    "$rootScope",
    "DEVICE_LIST",
    "$log",
    "NMS_FEATURES",
    "ManagementDeviceRestService",
    "TemplateActionsService",
    "PermissionsActionsService",
    "EquipmentsModalsActionsService",
    function (
        LocationService,
        DeviceAugmentService,
        DropdownDeviceService,
        DevicesActionsService,
        LOCATION,
        $translate,
        LicenseService,
        UserPreferencesService,
        AuthenticationService,
        $q,
        $rootScope,
        DEVICE_LIST,
        $log,
        NMS_FEATURES,
        ManagementDeviceRestService,
        TemplateActionsService,
        PermissionsActionsService,
        EquipmentsModalsActionsService
    ) {
        const service: any = {};

        var installedProduct;
        var installedProductIsDatacom;

        const globalConfig = EquipmentsModalsActionsService.getDefaultGlobalConfig();
        var defaultLocation = DEVICE_LIST.DEFAULT_LOCATION;
        var showDetailedErrorIfUserNotAllowed = true;
        var preferencesKeys = DEVICE_LIST.PREFERENCE_KEYS;
        const devicesSuccessRemovedKey = "toastr.devicesSuccessFullyRemoved";
        const deviceSuccessRemovedKey = "toastr.deviceSuccessFullyRemoved";

        var deviceType: any = {
            DMOS: DEVICE_LIST.DEVICE_DMOS,
            NETCONF: DEVICE_LIST.DEVICE_NETCONF
        };

        function setLicenseInfo() {
            installedProduct = LicenseService.loadInstalledProduct();
            installedProductIsDatacom = installedProduct.productName === "DmView";
        }

        function includeShortcuts(devices) {
            $log.log("Received all devices at", new Date());
            _.each(devices, function (device) {
                device.shortcuts = DropdownDeviceService.getShortcutToDeviceManager(globalConfig, device);
            });
            return devices;
        }

        service.loadStoredOptions = function (windowIdentifier) {
            var options = UserPreferencesService.loadPreferences({}, windowIdentifier, preferencesKeys);

            return options;
        };

        service.fetchDevices = function (devicesProvider) {
            setLicenseInfo();
            // TODO: Avaliar adicionar a location como String na collection device.inventory para não precisar disso
            return LocationService.getAllLocations({ hideLoadingPanel: true }).then(function (locations) {
                var getDevicesPromisse;
                var locationsById = _.indexBy(locations, "locationId");

                if (_.isFunction(devicesProvider)) {
                    getDevicesPromisse = devicesProvider();
                } else {
                    getDevicesPromisse = ManagementDeviceRestService.getAllDevices();
                }

                return getDevicesPromisse.then(includeShortcuts).then(function (devices) {
                    return successGetDevices(devices, locationsById);
                });
            });
        };

        function buildDeviceOnusLabel(device) {
            return device.totalOnus === -1 ? "" : "(" + device.totalOnus + " ONUs)";
        }

        function buildDeviceRemotesLabel(device) {
            return device.totalRemotes === 0 ? "" : getTotalRemotesDescription(device);
        }

        function getTotalRemotesDescription(device) {
            return device.totalRemotes === 1
                ? `(${device.totalRemotes} ${$translate.instant("devicelist.tablecolumn.remote")})`
                : `(${device.totalRemotes} ${$translate.instant("devicelist.tablecolumn.remotes")})`;
        }

        /**
         * Retorna um array de 'path' de locations, ordenado de acordo com a hierarquia.
         * Em casos onde não for encontrado location pelo id dado (por motivo de inconsistência da base), será adicionado 'null' na
         * posição correspondente do array para representar a sua ausência.
         */
        function getLocationHierarchyPaths(locationId, allLocations, processedLocations?) {
            var locationPath = null;
            processedLocations = processedLocations ? processedLocations : [];

            var location = allLocations[locationId];
            if (location) {
                locationPath = location.locationPath;

                if (location.locationParentId !== LOCATION.DEFAULT_LOCATION_ID) {
                    getLocationHierarchyPaths(location.locationParentId, allLocations, processedLocations);
                }
            }
            processedLocations.push(locationPath);

            return processedLocations;
        }

        /**
         * Retorna o label para respresentação da location, incluindo toda a hierarquia dividida por '/'.
         * Caso exista alguma location inválida em qualquer nível da hierarquia será retornado um label vazio.
         *
         * Oportunidade de melhoria de desempenho aqui: Criar um tipo de "cache" para armazenar os labels já processados de
         * locations e evitar processamento recursivo repetitivo que resulte no mesmo label para múltiplos equipamentos.
         */
        function getLocationLabel(locationId, locationsById) {
            var locationHierarchy = getLocationHierarchyPaths(locationId, locationsById);

            if (_.any(locationHierarchy, _.isNull)) {
                return "";
            } else {
                return "/" + locationHierarchy.join("/");
            }
        }

        function successGetDevices(devices, locationsById) {
            var allDevices = [];

            _.forEach(devices, function (device) {
                var newDevice = service.resolveDeviceInfo(device);

                // As propriedades path e isPollingRunning não devem ser atualizadas na função 'resolveDeviceInfo', pois não são
                // alteradas na finalização de um polling.
                // - A propriedade path tem o seu processamento realizado no frontend. Até que essa lógica seja movida para o
                // backend (existe uma anotação na linha 52 deste service para avaliar esse refactor), não será atualizada
                // automaticamente após o polling.
                // - A propriedade isPollingRunning é atualizada explicitamente no início e fim de polling, sendo desacoplada da
                // atualização das informações do device. Assim, no ponto abaixo, apenas é inicializada com false.
                newDevice.path = device.locationId === 0 ? defaultLocation : getLocationLabel(device.locationId, locationsById);
                newDevice.isPollingRunning = false;

                allDevices.push(newDevice);
            });

            return allDevices;
        }

        service.resolveDeviceInfo = function (device) {
            var newDevice = DeviceAugmentService.resolveDevice(device);
            newDevice.totalOnusLabel = buildDeviceOnusLabel(device);
            newDevice.totalRemotesLabel = buildDeviceRemotesLabel(device);
            newDevice.exhibitionName = (newDevice.totalOnusLabel || newDevice.totalRemotesLabel)
                ? `${device.name} ${newDevice.totalOnusLabel}${newDevice.totalRemotesLabel}`
                : device.name;

            return newDevice;
        };

        service.getDeviceType = function () {
            if (installedProductIsDatacom) {
                return deviceType.DMOS;
            } else {
                return deviceType.NETCONF;
            }
        };

        service.tryGoToDevice = function (device) {
            DevicesActionsService.tryToOpenDeviceSummary(device, globalConfig);
        };

        service.tryApplyTemplate = function (device, templateType, selection) {
            if (!device && selection.checked) {
                TemplateActionsService.tryApplyTemplate(selection.checked, templateType, showDetailedErrorIfUserNotAllowed);
            } else if (device) {
                TemplateActionsService.tryApplyTemplate([device], templateType, showDetailedErrorIfUserNotAllowed);
            }
        };

        service.viewTemplateApplication = function (device, selection, templateType) {
            if (!device && selection.checked) {
                var devicesNames = _.map(selection.checked, "name");
                TemplateActionsService.viewTemplateApplication(selection.checked, devicesNames, templateType);
            } else if (device) {
                TemplateActionsService.viewTemplateApplication([device], [device.name], templateType);
            }
        };

        service.tryOpenEditCredentials = function (device, selection) {
            if (verifyMgmtProtocolConfigUserPermission()) {
                if (device) {
                    EquipmentsModalsActionsService.tryOpenDeviceEditCredentials(device, null, showDetailedErrorIfUserNotAllowed);
                } else if (DevicesActionsService.areThereSelectedDevices(selection.checked)) {
                    EquipmentsModalsActionsService.tryOpenDevicesEditCredentials(
                        selection.checked,
                        null,
                        showDetailedErrorIfUserNotAllowed
                    );
                }
            }
        };

        service.getRemoveDevicesMessage = function (devices) {
            if (devices.length > 1) {
                return $translate.instant("devicelist.tableActions.removeDevices");
            } else {
                return $translate.instant("devicelist.tableActions.removeDevice");
            }
        };

        service.removeDevices = function (selectedDevices, allDevices, successCallback) {
            if (PermissionsActionsService.verifyUserPermission("addRemoveDevices")) {
                var deviceToRemove = _.map(selectedDevices, deviceToDeviceToRemoveConverter);

                ManagementDeviceRestService.verifyDevicesToRemove(deviceToRemove).then(function (devicesResponse) {
                    showDevicesValidateRemovalResults(devicesResponse, allDevices, selectedDevices, successCallback);
                });
            }
        };

        function startRemoveDevicesFromTable(devicesToRemove, allDevices, selectedDevices?) {
            var idsToRemove = _.map(devicesToRemove, "resourceId");

            removeDevicesFromList(allDevices, idsToRemove);
            removeDevicesFromList(selectedDevices, idsToRemove);
        }

        function removeDevicesFromList(devices, idsToRemove) {
            _.remove(devices, function (device) {
                return _.includes(idsToRemove, device.id);
            });
        }

        function showDevicesValidateRemovalResults(results, allDevices, selectedDevices, successCallback) {
            const keyViolation = $translate.instant("devicelist.tableActions.cannotRemoveDevice.belongs.templateApplication");
            const removed = results.filter((device) => device.isAbleToRemove && device.violationMessage === "" );
            const notRemoved = results.filter((device) => !device.isAbleToRemove);
            const devicesWithTemplateInstances = results.filter((device)=> {
                return device.isAbleToRemove && device.violationMessage.includes(keyViolation);
             });
            if (devicesWithTemplateInstances.length > 0) {
                showRemoveTemplateInstanceConfirmationMessage(devicesWithTemplateInstances, allDevices,
                    selectedDevices, successCallback);
            }

            if (removed.length === results.length) {
                processToRemoveEquipments(removed, allDevices, selectedDevices, successCallback);
            } else {
                if (notRemoved.length === results.length) {
                    showDeviceCannotBeRemovedError(notRemoved);
                } else {
                    showDevicesRemovedResultsMessage(notRemoved, removed, allDevices, selectedDevices, successCallback);
                }
            }
        }

        /**
         * Exibe somente o dialog com as violations ocorridas nos equipamentos que não
         * puderam ser removidos.
         */
        function showDeviceCannotBeRemovedError(notRemoved) {
            const violations = _.map(notRemoved, "violationMessage");
            const translateObjs = [
                        {
                            pluralKey: "findAddDevice.error.devicesCanNotBeRemoved",
                            singularKey: "findAddDevice.error.deviceCanNotBeRemoved",
                            isPlural: notRemoved.length > 1,
                            insideMsgParams: [notRemoved.length]
                        }
                    ];
            $rootScope.showDialogSingularOrPlural({
                translateObjs: translateObjs,
                listParams: violations,
                maxChars: 128
            });
        }

        /**
         * Exibe dialog dos equipamentos que foram removidos com sucesso mais as mensagens com as violations
         * ocorridas nos equipamentos que não puderam ser removidos.
         */
        function showDevicesRemovedResultsMessage(notRemoved, removed, allDevices, selectedDevices, successCallback) {
            let translateKey;
            let violations = [];
            let translateObjs = [];
            if (notRemoved.length > 0) {
                translateKey = notRemoved.length === 1 ? "findAddDevice.error.oneDeviceCouldNotBeRemoved" :
                    "findAddDevice.error.someDevicesCouldNotBeRemoved";
                violations.push($translate.instant(translateKey).replace("{0}", notRemoved.length));
                notRemoved.forEach((result) => violations.push(result.violationMessage));
            }
            if (removed.length > 0) {
                translateObjs = [
                    {
                        pluralKey: devicesSuccessRemovedKey,
                        singularKey: deviceSuccessRemovedKey,
                        isPlural: removed.length > 1,
                        insideMsgParams: [removed.length]
                    }
                ];
                processToRemoveEquipments(removed, allDevices, selectedDevices, successCallback);
            }
            if (notRemoved.length > 0 || removed.length > 0) {
                $rootScope.showDialogSingularOrPlural({
                    translateObjs: translateObjs,
                    listParams: violations,
                    maxChars: 128
                });
            }
        }

        function removeEquipments(devicesResp, allDevices, selectedDevices, successCallback) {
            let promises = [];
            devicesResp.forEach(function (device) {
                const deferred = $q.defer();
                var deviceToRemove = device.deviceToRemove;
                if (device.deviceAlreadyRemoved) {
                    startRemoveDevicesFromTable([deviceToRemove], allDevices, selectedDevices);
                }
                if (device.isAbleToRemove) {
                    startRemoveDevicesFromTable([deviceToRemove], allDevices, selectedDevices);
                    ManagementDeviceRestService.removeDevice(deviceToRemove).then(function (data) {
                        deferred.resolve(data);
                    });
                    promises.push(deferred.promise);
                }
            });

            $q.all(promises).then(successCallback);
        }

        function showRemoveTemplateInstanceConfirmationMessage(devicesWithTemplateInstances, allDevices,
            selectedDevices,successCallback) {
            const hostnames = devicesWithTemplateInstances.map(item => item.deviceToRemove.hostname);
            $rootScope.showDialogSingularOrPlural({
                translateObjs: [
                    {
                        pluralKey: "devicelist.tableActions.removeDevices.templateAssociationMessage",
                        singularKey: "devicelist.tableActions.removeDevice.templateAssociationMessage",
                        isPlural: hostnames.length > 1,
                        insideMsgParams: [hostnames.length]
                    }
                ],
                listParams: hostnames,
                isConfirm: true
            }).then(function () {
                processToRemoveEquipments(devicesWithTemplateInstances, allDevices, selectedDevices, successCallback);
            });
            return;
        }

        function processToRemoveEquipments(removed, allDevices, selectedDevices, successCallback) {
            const translateKey = removed.length === 1 ? deviceSuccessRemovedKey : devicesSuccessRemovedKey;
            $rootScope.toastInfo(translateKey, [removed.length]);
            removeEquipments(removed, allDevices, selectedDevices, successCallback);
        }

        function deviceToDeviceToRemoveConverter(device) {
            var deviceToRemove: any = {
                modelCode: device.modelCode,
                modelName: device.model,
                resourceId: device.id,
                hostname: device.hostname,
                vendor: device.vendorCode,
                deviceName: device.name,
                serial: device.serialNumber,
                devNo: device.devNo,
                devLocalId: device.devLocalId,
                proxyCode: device.proxyCode
            };

            return deviceToRemove;
        }

        service.tryOpenPropertiesConfig = function (device, selection) {
            if (device) {
                EquipmentsModalsActionsService.tryOpenDevicePropertiesConfig(device, showDetailedErrorIfUserNotAllowed);
            } else if (DevicesActionsService.areThereSelectedDevices(selection.checked)) {
                EquipmentsModalsActionsService.tryOpenMultipleDevicesPropertiesConfig(
                    selection.checked,
                    showDetailedErrorIfUserNotAllowed
                );
            }
        };

        /**
         * Abre a modal responsável pelo teste de conectividade dos equipamentos.
         * Parâmetros:
         * device        -   Utilizado pelo menu de dropdown de um equipamento único. No caso da existência de ambos os parâmetros,
         *                   o parâmetro device é prioritário.
         * selection     -   Utilizado na seleção de equipamentos de uma tabela. Quando o teste de conectividade é disparado a
         *                   partir de uma ação das tabelas, ou seja, para múltiplos equipamentos, e não existe nenhum equipamento
         *                   selecionado, uma modal com mensagem de erro é apresentada.
         *
         */
        service.tryOpenConnectivityTest = function (device, selection) {
            if (device) {
                EquipmentsModalsActionsService.openConnectivityTest([device]);
            } else if (DevicesActionsService.areThereSelectedDevices(selection.checked)) {
                EquipmentsModalsActionsService.openConnectivityTest(selection.checked);
            }
        };

        function verifyMgmtProtocolConfigUserPermission() {
            if (!AuthenticationService.hasPermission("managementProtocolConfiguration")) {
                $rootScope.showDialog({
                    translateKey: "findAddDevice.error.noUserPermission"
                });
                return false;
            }

            return true;
        }

        return service;
    }
]);
