"use strict";

import {
    RuleType,
    RuleCriteria,
    RuleFieldsSet,
    Rule
} from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { TranslationMap } from "@nms-ng2/app/shared/models/translation.models";

/**
 * @ngdoc Controller
 * @name nms.management.ManagePermissionsTemplatesTabController
 * @description Realiza gerência das permissões de templates.
 */
var app = angular.module("nms.management");

app.controller("ManagePermissionsTemplatesController", [
    "$scope",
    "TEMPLATE_PERMISSION_FIELDS",
    function ($scope, TEMPLATE_PERMISSION_FIELDS) {
        $scope.rulesMatchingModes = $scope.owner.rulesMatchingModes;
        $scope.ruleRestrictionTypes = $scope.owner.ruleRestrictionTypes;

        $scope.tooltipKeys = {
            matchingRules: "manage.permissions.template.restrict.application",
            criteriaBelow: "manage.permissions.template.restrict.application.below",
            atLeastOneRule: "popups.alert.atLeastOneRestriction"
        } as TranslationMap;

        $scope.availableFields = {
            NAME: {
                translationKey: TEMPLATE_PERMISSION_FIELDS.NAME.translationKey,
                initial: true
            },
            DESCRIPTION: {
                translationKey: TEMPLATE_PERMISSION_FIELDS.DESCRIPTION.translationKey
            },
            KEYWORD: {
                translationKey: TEMPLATE_PERMISSION_FIELDS.KEYWORD.translationKey
            }
        } as RuleFieldsSet;

        function init() {
            let useRules = false;
            let criteria = RuleCriteria.ALL;
            let rules: Rule[] = [{ ruleType: RuleType.CONTAINS, values: [""], field: "NAME" }];

            if ($scope.owner.model.editData) {
                useRules = !!$scope.owner.model.editData.criteria;
                criteria = $scope.owner.model.editData.criteria || RuleCriteria.ALL;

                if ($scope.owner.model.editData.rules) {
                    rules = _.map($scope.owner.model.editData.rules, function (rule) {
                        return {
                            ruleType: rule.type,
                            values: [rule.value],
                            field: rule.field
                        };
                    });
                }

                delete $scope.owner.model.editData;
            }

            $scope.owner.model.restrictions = { criteria, rules, useRules };
        }

        $scope.filterRuleTypes = function (field) {
            if (field === "KEYWORD") {
                return _.omit($scope.ruleRestrictionTypes, [RuleType.DOES_NOT_CONTAIN, RuleType.NOT_EQUALS]);
            }

            return $scope.ruleRestrictionTypes;
        };

        init();
    }
]);
