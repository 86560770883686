import { Component, Inject } from "@angular/core";

import { NotificationPreferences } from "./notification-preferences/notification-preferences.component";
import {
  NotificationPreferencesService, NotificationPreferencesByGroup, NotificationGroup
} from "@nms-ng2/app/shared/services/notification-preferences/notification-preferences.service";
import {
  ANGULARJS_ROOTSCOPE,
  ANGULARJS_TRANSLATE,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { NmsToastrService } from "@nms-ng2/app/shared/components/elements/nms-toastr/nms-toastr.service";
import { NotificationType } from '@nms-ng2/app/shared/services/nms-notification/nms-notification.service';

export type NotificationsByType = {
    [property in NotificationType]: NotificationPreferences;
};

type NotificationPreferencesGroupTitle = {
    [property in NotificationGroup]: string;
};

/**
 * Classe responsável por exibir as preferências de usuário.
 */
@Component({
    selector: "user-preferences",
    templateUrl: "./user-preferences.component.html",
    styleUrls: ["./user-preferences.component.scss"]
})
export class UserPreferencesComponent {
    currentNotifications: NotificationsByType;
    notificationsByGroup: NotificationPreferencesByGroup;
    notificationGroupsTitles: NotificationPreferencesGroupTitle;

    constructor(
        private readonly notificationPreferencesService: NotificationPreferencesService,
        private readonly toastr: NmsToastrService,
        @Inject(ANGULARJS_ROOTSCOPE) private readonly rootScope: any,
        @Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
        private readonly window: Window
    ) {
        this.notificationPreferencesService.getPreferences().subscribe((notificationsByGroup: NotificationPreferencesByGroup) => {
            this.currentNotifications = this.buildCurrentNotificationsMap(notificationsByGroup);
            this.notificationsByGroup = notificationsByGroup;
            this.notificationGroupsTitles = this.getGroupTitles(this.notificationsByGroup);
        });
    }

    saveUserPreferences(): void {
        if (!this.hasConfigChanges()) {
            this.rootScope.showDialog({
                type: "alert",
                translateKey: "cwmp.parameters.apply.noConfigurationChanged"
            });
        } else {
            this.notificationPreferencesService
                .setPreferences(this.getAllNotifications(this.notificationsByGroup))
                .subscribe(() => {
                    this.toastr.success(this.translate.instant("manage.user.notification.preferences.saved.successfully"));
                    this.currentNotifications = this.buildCurrentNotificationsMap(this.notificationsByGroup);
                });
        }
    }

    confirmAndCancel(): void {
        this.showEditionDiscardConfirmationMessage(() => {
            this.window.history.back();
        });
    }

    private buildCurrentNotificationsMap(notificationsByGroup: NotificationPreferencesByGroup): NotificationsByType {
        const notificationsPreference = this.getAllNotifications(notificationsByGroup);

        return notificationsPreference.reduce((result, currentNotification) => {
            result[currentNotification.notificationType] = angular.copy(currentNotification);
            return result;
        }, {}) as NotificationsByType;
    }

    private getAllNotifications(notificationsByGroup: NotificationPreferencesByGroup): NotificationPreferences[] {
        return Object.values(notificationsByGroup).flat();
    }

    private getGroupTitles(notificationsByGroup: NotificationPreferencesByGroup): NotificationPreferencesGroupTitle {
        const notificationGroupTitles: NotificationPreferencesGroupTitle = {} as NotificationPreferencesGroupTitle;
        Object.keys(notificationsByGroup)
            .forEach((key) => (notificationGroupTitles[key] = `manage.user.notification.preferences.group.title.${key}`));

        return notificationGroupTitles;
    }

    private showEditionDiscardConfirmationMessage(callback: Function) {
        this.rootScope
            .showDialog({
                message: this.translate.instant("manage.user.notification.preferences.edition.discard.confirmation.message"),
                isConfirm: true
            })
            .then(callback);
    }

    private hasConfigChanges(): boolean {
        const notificationsPreference = this.getAllNotifications(this.notificationsByGroup);

        return notificationsPreference.some((notification) => {
            const { notificationType, enabled, autoClose, delayBeforeCloseInSeconds } =
                this.currentNotifications[notification.notificationType];

            return notification.notificationType === notificationType &&
                (notification.enabled !== enabled ||
                    notification.autoClose !== autoClose ||
                    notification.delayBeforeCloseInSeconds !== delayBeforeCloseInSeconds);
        });
    }
}
