"use strict";

/**
* @ngdoc controller
* @name nms.templates.components.variable:blockValidation
* @description
* # blockValidation
* Diretiva para validação de valores preenchidos de acordo com valores de bloqueio retornados na execução de comandos.
*/
var app = angular.module("nms.templates");

app.directive("blockValidation", ["$timeout", "VariablesService", function($timeout, VariablesService) {
    return {
        require: "ngModel",
        scope: {
            ngModel: "="
        },
        link: function(scope, element, attrs, ngModelCtrl) {
            var getSelectedOption = function() {
                var selectedOption = _.find(element[0].options, function(option) {
                    return option.selected === true;
                });

                return selectedOption || element[0].options[0];
            };

            var getFirstValidOption = function() {
                return _.find(element[0].options, function(option) {
                    var str = option.text;
                    return VariablesService.isOptionAvailable(str);
                });
            };

            var getSelectedOptionValue = function() {
                if (element[0].value === "") {
                    var optionSelected = getFirstValidOption();
                } else {
                    var optionSelected = getSelectedOption();
                }

                if (optionSelected) {
                    optionSelected.selected = true;
                }

                return optionSelected;
            };

            var getValueByFieldType = function() {
                var value;
                if (element[0].type === "number" || _.includes(element[0].classList, "stepper")) {
                    value = parseInt(element[0].value);
                } else if (element[0].type === "select-one") {
                    var option = getSelectedOptionValue();
                    value = option ? option.text : "";
                } else if (element[0].type === "text") {
                    value = element[0].value;
                } else {
                    value = attrs.valuesToInclude;
                }

                return value;
            };

            var validate = function() {
                var value,
                    valid = true;

                if (element[0].dataset.commandtype === "BLOCK") {
                    var acceptedValues = attrs.acceptedValues ? angular.fromJson(attrs.acceptedValues) : [];

                    var options = !_.isEmpty(attrs.blockValidation) ? angular.fromJson(attrs.blockValidation) : acceptedValues;
                    var blockedOption;

                    if (element[0].type === "select-one") {
                        var optionSelected = getSelectedOptionValue();

                        if (angular.isDefined(optionSelected)) {
                            var str = optionSelected.text;

                            if (!VariablesService.isOptionAvailable(str)) {
                                var valueWithoutSuffix = VariablesService.removeOptionSuffix(str);

                                if (!_.includes(acceptedValues, valueWithoutSuffix)) {
                                    blockedOption = valueWithoutSuffix;
                                }
                            }
                        }
                    } else {
                        blockedOption = _.find(options, function(option) {
                            return option == element[0].value && !_.includes(acceptedValues, option);
                        });
                    }

                    value = getValueByFieldType();

                    if (blockedOption) {
                        valid = false;
                    }
                } else {
                    if (element[0].type === "number" || _.includes(element[0].classList, "stepper")) {
                        value = parseInt(element[0].value);
                    } else if (element[0].type === "select-one") {
                        var selectedOption = getSelectedOption();
                        value = (selectedOption) ? selectedOption.text : "";
                        valid = VariablesService.isOptionAvailable(value);
                    } else if (element[0].type === "text") {
                        value = element[0].value;
                    } else {
                        var preVal = attrs.valuesToInclude !== "" ? angular.fromJson(attrs.valuesToInclude) : "";
                        value = preVal.join("\n");
                    }
                }

                ngModelCtrl.$setValidity("blockValidation", valid);
                scope.ngModel = value;

                return ngModelCtrl.$viewValue;
            };

            var lastValidateTimeoutId = null;

            ngModelCtrl.$parsers.unshift(validate);
            scope.$on("validate", function(event) {
                event.preventDefault();
                $timeout.cancel(lastValidateTimeoutId);
                lastValidateTimeoutId = $timeout(validate, 20);
            });
            scope.$watch(attrs.blockValidation, validate);
        }
    };
}]);
