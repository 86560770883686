import { Injectable, Inject } from "@angular/core";
import {
    MANAGEMENT_DEVICE_REST_SERVICE,
    CREDENTIALS_SERVICE,
    CONNECTIVITY_TEST_SERVICE,
    LOCATION,
    PROPERTIES_SERVICE
} from "../upgraded-provider/upgraded-providers";
import { PermissionsActionsService } from "./permissions-actions.service";

/**
 * Serviço que engloba ações relacionadas a propriedades, credenciais e testes de conectividade, em atalhos de dropdown.
 */
@Injectable({
    providedIn: "root"
})
export class EquipmentsModalsActionsService {
    constructor(
        @Inject(LOCATION) private readonly location: any,
        private readonly permissionsActionsService: PermissionsActionsService,
        @Inject(MANAGEMENT_DEVICE_REST_SERVICE) private readonly managementDeviceRestService: any,
        @Inject(CREDENTIALS_SERVICE) private readonly credentialsService: any,
        @Inject(CONNECTIVITY_TEST_SERVICE) private readonly connectivityTestService: any,
        @Inject(PROPERTIES_SERVICE) private readonly propertiesService: any
    ) {}

    tryOpenCredentialsByDeviceId = (deviceId) => {
        this.managementDeviceRestService.findDeviceById(deviceId).then((device) => {
            this.tryOpenDeviceEditCredentials(device);
        });
    };

    tryOpenDeviceEditCredentials(device, showDetailedMessage?) {
        const params = [device];

        if (this.permissionsActionsService.verifyUserPermission("managementProtocolConfiguration")) {
            this.permissionsActionsService.verifyDevicesPermission(
                [device],
                showDetailedMessage,
                this.credentialsService.openDeviceCredentials,
                params
            );
        }
    }

    tryOpenDevicesEditCredentials(devices, showDetailedMessage) {
        const params = devices.length > 1 ? [] : [devices[0]];
        const callback =
            devices.length > 1 ? this.credentialsService.openDevicesCredentials : this.credentialsService.openDeviceCredentials;

        if (this.permissionsActionsService.verifyUserPermission("managementProtocolConfiguration")) {
            this.permissionsActionsService.verifyDevicesPermission(devices, showDetailedMessage, callback, params);
        }
    }

    tryOpenPropertiesConfigByDeviceId = (deviceId) => {
        this.tryOpenDevicesPropertiesConfigVerifyingPermission([deviceId], false);
    };

    tryOpenDevicePropertiesConfig(device, showDetailedMessage) {
        this.tryOpenDevicesPropertiesConfigVerifyingPermission([device.id], showDetailedMessage);
    }

    tryOpenMultipleDevicesPropertiesConfig(devices, showDetailedMessage) {
        const deviceIds = devices.map((device) => {
            return device.id;
        });
        this.tryOpenDevicesPropertiesConfigVerifyingPermission(deviceIds, showDetailedMessage);
    }

    getDefaultGlobalConfig() {
        return {
            globalCredentials: {},
            specificCredentials: {},
            globalConfig: true,
            location: {
                path: this.location.DEFAULT_PATH,
                locationId: this.location.DEFAULT_LOCATION_ID
            },
            properties: {
                name: "",
                notes: "",
                project: "",
                externalLocation: {
                    address: "",
                    city: "",
                    state: "",
                    country: ""
                },
                internalLocation: {
                    station: "",
                    room: "",
                    shelf: ""
                }
            }
        };
    }

    openConnectivityTestByDeviceId = (deviceId) => {
        this.managementDeviceRestService.findDeviceById(deviceId).then((device) => {
            this.openConnectivityTest([device]);
        });
    };

    openConnectivityTest(availableHosts) {
        this.connectivityTestService.openConnectivityTestModal(availableHosts);
    }

    /**
     * Função que abre a modal de edição de propriedades de equipamentos, verificando se o usuário tem permissão.
     * Se for passado mais de um equipamento, a modal será aberto no modo de edição múltipla.
     * @param {array} deviceIds: lista de device resource id dos equipamentos que se deseja editar propriedade.
     * @param {boolean} showDetailedMessage: flag que indica se a mensagem deve ser detalhada ou não
     */
    private tryOpenDevicesPropertiesConfigVerifyingPermission(deviceIds, showDetailedMessage) {
        const openDevicesProperties = (devices) => {
            if (_.size(devices) === 1) {
                this.propertiesService.openDevicePropertiesModal(devices[0]);
            } else {
                this.propertiesService.openMultipleDevicePropertiesModal(devices);
            }
        };
        this.managementDeviceRestService.getDevicesByIds(deviceIds).then((loadedDevices) => {
            this.permissionsActionsService.verifyDevicesPermission(loadedDevices, showDetailedMessage, openDevicesProperties, []);
        });
    }
}
