import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { AutoUpdaterDataResult } from "@nms-ng2/app/shared/components/elements/nms-auto-updater/nms-auto-updater.component";
import { ANGULARJS_ROOTSCOPE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { SchedulerJobDetails } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { SchedulerTableComponent } from "@nms-ng2/app/modules/scheduler/scheduler-list/scheduler-table/scheduler-table.component";
import { RefreshIntervalService } from "@nms-ng2/app/shared/services/refresh-interval/refresh-interval.service";
import { SchedulerListService } from "@nms-ng2/app/modules/scheduler/scheduler-list/scheduler-list.service";

/**
 * Componente responsável pela renderização da lista de Agendamentos.
 */
@Component({
    selector: "scheduler-list",
    templateUrl: "./scheduler-list.component.html"
})
export class SchedulerListComponent implements OnInit {
    autoUpdateInterval: number;
    schedulerProvider;

    @ViewChild(SchedulerTableComponent)
    schedulerTable: SchedulerTableComponent;

    constructor(private readonly refreshIntervalService: RefreshIntervalService,
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope: any,
        private readonly schedulerListService: SchedulerListService) {
        this.refreshIntervalService.getRefreshInterval((interval) => {
            this.autoUpdateInterval = interval;
        });
        this.schedulerProvider = this.retrieveSchedulerJobs.bind(this);
    }

    ngOnInit() {
        this.retrieveSchedulerJobs =  this.retrieveSchedulerJobs.bind(this);
    }

    /**
     * Busca os dados de Agendamento.
     */
    retrieveSchedulerJobs(): Observable<Array<SchedulerJobDetails>> {
        return this.schedulerListService.getAll();
    }

    /**
     * Atualiza os dados da tabela pelo nms-auto-updater.
     * @param dataResult - Resultado da atualização
     */
    refresh(dataResult: AutoUpdaterDataResult): void {
        if (dataResult.error) {
            this.showServerErrorMessage();
        } else {
            this.schedulerTable.update(dataResult.data);
        }
        this.schedulerTable.tableBodyMessage = null;
    }

    private showServerErrorMessage() {
        this.$rootScope.showDialog({ translateKey: "http.error.serverError" });
    }
}
