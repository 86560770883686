import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_TRANSLATE, FILTER } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { TemplateApplicationStatus } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { TemplateApplicationParams } from "../nms-notification.service";

export type TemplateApplicationResult = {
    templateName: string;
    templateApplicationStatus: TemplateApplicationStatus;
    applicationResult: string;
};

interface Details {
    templates: string;
    results: string;
}

/**
 * Responsável por formatar detalhes dos resultados das notificações dos templates referentes aos equipamentos
 * que foram removidos das associações. As notificações serão exibidas na seguinte ordem:
 * 1 - Templates com comandos de remoção executados com erro
 * 2 - Templates com comandos de remoção não executados
 * 3 - Templates com comandos de execução executados com sucesso.
 * Em seguida será exibido os detalhes dos comandos executados com sucesso e os detalhes dos comandos
 * executados com erro.
 */
@Injectable({
    providedIn: "root"
})
export class TemplateApplicationResultsFormatterService {
    constructor(@Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
        @Inject(FILTER) public readonly filter) {}

    public format(parameters: TemplateApplicationParams): string {
        const { equipmentName, templateApplicationResults: equipmentResults } = parameters;

        let title = "",
            successNames = "",
            failNames = "",
            notExecutedNames = "",
            successResults = "",
            failResults = "";

        const key = parameters.isDevice ? "device" : "cpe";
        title = this.translate.instant(`nms-notification.templateApplication.details.removed.${key}.title`, {
            equipmentName
        });

        const equipmentResultsByStatus = _.groupBy(equipmentResults, "templateApplicationStatus");

        if (equipmentResultsByStatus.SUCCESS) {
            const { templates, results } = this.getTemplateApplicationDetails(
                equipmentResultsByStatus.SUCCESS,
                "nms-notification.templateApplication.details.equipment.templates.success",
                "nms-notification.templateApplication.details.equipment.templates.success.result.title",
                "nms-notification.templateApplication.details.equipment.template.result"
            );
            successNames = `<br>${templates}`;
            successResults = `<br>${results}`;
        }

        if (equipmentResultsByStatus.FAIL) {
            const { templates, results } = this.getTemplateApplicationDetails(
                equipmentResultsByStatus.FAIL,
                "nms-notification.templateApplication.details.equipment.templates.fail",
                "nms-notification.templateApplication.details.equipment.templates.fail.result.title",
                "nms-notification.templateApplication.details.equipment.template.result"
            );
            failNames = `<br>${templates}`;
            failResults = `<br>${results}`;
        }

        if (equipmentResultsByStatus.NOT_EXECUTED) {
            const { templates } = this.getTemplateApplicationDetails(
                equipmentResultsByStatus.NOT_EXECUTED,
                "nms-notification.templateApplication.details.equipment.templates.not.executed",
                "nms-notification.templateApplication.details.equipment.templates.not.executed.result.title",
                "nms-notification.templateApplication.details.equipment.template.result"
            );
            notExecutedNames = `<br>${templates}`;
        }

        const details = `${title}${successNames}${failNames}${notExecutedNames}${successResults}${failResults}`;
        return this.filter("replaceLineBreak")(details);
    }

    private getTemplateApplicationDetails(
        equipmentResults: TemplateApplicationResult[],
        templateNamesLabelKey: string,
        resultTitleKey?: string,
        resultLabelKey?: string
    ): Details {
        const templateNamesLabel = this.translate.instant(templateNamesLabelKey);
        const resultTitle = this.translate.instant(resultTitleKey);
        const resultLabel = this.translate.instant(resultLabelKey);
        const detailsLabel = this.translate.instant(
            "nms-notification.templateApplication.details.equipment.result.details.title"
        );

        let templatesNames = "";
        let results = "";
        equipmentResults.forEach((result) => {
            templatesNames += `<br>${result.templateName}`;
            results += `<br><b>${detailsLabel}</b><br>${resultLabel} ${result.templateName}<br>${result.applicationResult}`;
        });

        return {
            templates: `<br>${templateNamesLabel}${templatesNames}`,
            results: `<br>${resultTitle}${results}`
        };
    }
}
