var app = angular.module("nms.templates");

/**
* @ngdoc directive
* @name nms.templates.directive:VariableInclusionOptions
* @description Diretiva para comandos de visualização.
*/
app.directive("variableVisualization", [
    function() {
        return {
            restrict: "E",
            scope: {
                variablesAlreadyAdded: "=",
                hasCommands: "=",
                deviceVariables: "=",
                variable: "=",
                language: "=",
                expressionsProvider: "&",
                formatCommand: "&",
                tooltipVariable: "&"
            },
            templateUrl: "templates/features/template/template/modals/variable/variable-visualization-panel.html"
        };
    }
]);
