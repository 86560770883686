import { Injectable } from "@angular/core";

import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";
import {
    AbstractErrorResponseHandlerService
} from "@nms-ng2/app/core/services/error-response-handler/abstract-error-response-handler";
import {
    DcbError,
    ErrorDescriptionDetails,
    ErrorResponse,
} from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";
import { DCB_ERROR_NAME } from "@nms-ng2/app/core/services/error-response-handler/error-names.constant";

/**
 * Serviço para lidar com mensagens de erros de DCB recebidas através de um @see ErrorResponse.
 */
@Injectable({
    providedIn: "root"
})
export class DeviceBackupErrorResponseHandlerService extends AbstractErrorResponseHandlerService {

    private readonly LOCALE_PREFIX: string = "backup.devices.response.error.";

    constructor(private readonly translationHelper: TranslationHelperService) {
        super(DcbError);
    }

    public buildErrorDescriptionDetails(errorResponse: ErrorResponse,
                                        detailsSeparator: string = "<br>",
                                        status?: number): ErrorDescriptionDetails {
        let { code, details } = errorResponse;
        let erroName = DCB_ERROR_NAME[code];
        let description = "";

        if (erroName) {
            description = this.translationHelper.translateByPrefix(this.LOCALE_PREFIX, erroName);
        }
        return this.handleErrorDetails(description, details);
    }

    public buildErrorDetails(code: number, details: Array<string>): string {
        let detailsMessage = "";

        if (details && details.length > 0) {
            detailsMessage = details.join(this.getDefaultSeparator())
        }

        return detailsMessage;
    }

    public interpolateErrorDetails(message: string, details: string[]): string {
        details.forEach((detail: string, index: number) => {
            message = message.replace(`{${index}}`, detail);
        });

        return message;
    }

    private handleErrorDetails(description: string, details: string[]): ErrorDescriptionDetails {
        if (description.match(new RegExp(/\{\d\}/))) {
            const errorMessage = this.interpolateErrorDetails(description, details);
            return {
                description: errorMessage,
                details: ""
            }
        }

        const detailsJoined = this.buildErrorDetails(null, details);
        return {
            description,
            details: detailsJoined
        }
    }
}
