"use strict";

/**
 * Filter to format bytes or packets in k, M, G, T, or P.
 */

var app = angular.module("nms");

app.filter("bytesFormatter", function() {
    return function(bytes) {
        var bpsUnits = ["bps", "kbps", "Mbps", "Gbps", "Tbps", "Pbps"];
        return format(bytes, bpsUnits);
    };
});

app.filter("packetsFormatter", function() {
    return function(packets) {
        var ppsUnits = ["pps", "kpps", "Mpps", "Gpps", "Tpps", "Ppps"];
        return format(packets, ppsUnits);
    };
});

function format(value, units) {
    if (isNaN(parseFloat(value)) || !isFinite(value) || _.isEqual(parseFloat(value), 0)) {
        return value;
    } else {
        var precision = value < 1000 ? 0 : 2;
        var number = Math.floor(Math.log(parseFloat(value)) / Math.log(1000));
        var converted = (parseFloat(value) / Math.pow(1000, Math.floor(number))).toFixed(precision);
        return converted + " " + units[number];
    }
}
