import { Injector } from "@angular/core";
import {
    DEVICE_SERVICE,
    DEVICE_DROPDOWN_MODEL_SERVICE,
    MONITORING_SERVICE,
    ANGULARJS_TRANSLATE,
    ANGULARJS_ROOTSCOPE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import {
    AccordionParentData,
    DeviceIdentifier,
    DeviceDetails,
    EquipmentAssociation,
    EquipmentDetails,
    TemplateIdentifier,
    EquipmentTemplateChangedStatus,
    DeviceField,
    DeviceFieldValues,
    EquipmentSelectionType,
    EquipmentPermissionResult
} from "./template-instance-models";
import { TemplateInstanceComponent } from "./template-instance.component";
import { TemplateInstanceEquipmentInterface } from "./template-instance-equipment.interface";
import { RuleType, RuleTypesSet } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { EquipmentInfo } from "../services/equipment.service";
import { Cpe } from "../../device/cpes/cpe-model";

/**
 * Classe que colabora no componente TemplateInstanceComponent contendo a implementação
 * específica de Device de acordo com a interface TemplateInstanceEquipmentInterface.
 */
export class TemplateInstanceDeviceComponent implements TemplateInstanceEquipmentInterface {
    selectionTypeTranslationKey: string;
    injector: Injector;
    templateInstanceComponent: TemplateInstanceComponent;
    deviceService: any;
    deviceDropdownModelService: any;
    monitoringService: any;
    $rootScope: any;
    translate: any;

    constructor(templateInstanceComponent: TemplateInstanceComponent, injector: Injector) {
        // TODO[US-3988] - Rever acoplamento que se fez necessário no momento
        // (Componente generico inicializa uma implementação da interface
        // TemplateInstanceEquipmentInterface e repassa uma referencia própria para a implementação)
        this.injector = injector;
        this.templateInstanceComponent = templateInstanceComponent;
        this.deviceService = this.injector.get(DEVICE_SERVICE);
        this.deviceDropdownModelService = this.injector.get(DEVICE_DROPDOWN_MODEL_SERVICE);
        this.monitoringService = this.injector.get(MONITORING_SERVICE);
        this.$rootScope = this.injector.get(ANGULARJS_ROOTSCOPE);
        this.translate = this.injector.get(ANGULARJS_TRANSLATE);
        this.selectionTypeTranslationKey = "templateinstanceform.equipmentselectiontype.selectiondevices";
        this.fillEquipment();
    }

    private getDeviceResourceId = (equipmentAssociation: EquipmentAssociation) =>
        (equipmentAssociation.equipmentIdentifier as DeviceIdentifier).resourceId;

    successAddEquipments(checkedDevices, shouldAddTemplatesToAllEquipment?): any {
        let addDevices = (template, checkedDevice, equipmentAssociation: EquipmentAssociation) => {
            let deviceLabel = this.templateInstanceComponent.createEquipmentLabel({
                name: checkedDevice.name,
                model: checkedDevice.model
            } as EquipmentDetails);

            const equipmentIdentifier = {
                resourceId: checkedDevice.id,
                type: "DeviceIdentifier"
            };

            template.children.push({
                id: equipmentIdentifier,
                label: deviceLabel
            });

            let equipmentAssociations = _.get(this.templateInstanceComponent, "templateInstance.equipmentAssociations");
            var existingDevice = equipmentAssociations.find(
                (equipmentAssociation: EquipmentAssociation) =>
                    (equipmentAssociation.equipmentIdentifier as DeviceIdentifier).resourceId === checkedDevice.id
            );
            let templateToAdd = this.templateInstanceComponent.templateSelected
                ? this.templateInstanceComponent.templateSelected
                : template;

            if (existingDevice) {
                existingDevice.templateAssociations.push(templateToAdd);
            } else if (equipmentAssociation) {
                equipmentAssociation.templateAssociations.push(templateToAdd);
            }
        };

        checkedDevices.forEach((checkedDevice) => {
            const currentEquipmentAssociation = {
                equipmentIdentifier: this.createEquipmentIdentifier(checkedDevice),
                equipmentDetails: this.createEquipmentDetails(checkedDevice),
                templateAssociations: [],
                localVars: [],
                apply: false
            };

            if (
                this.templateInstanceComponent.presentationMode.value ===
                this.templateInstanceComponent.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
            ) {
                if (shouldAddTemplatesToAllEquipment) {
                    this.templateInstanceComponent.data.forEach((currentTemplate) => {
                        let devicesIds = _.map(currentTemplate.children, "id.resourceId");

                        if (!devicesIds.includes(checkedDevice.id)) {
                            addDevices(currentTemplate, checkedDevice, currentEquipmentAssociation);
                        }
                    });
                } else {
                    let templateId = _.get(this.templateInstanceComponent, "templateSelected.templateId");
                    let currentTemplate = _.find(this.templateInstanceComponent.data, (data: AccordionParentData) => {
                        const currentTemplateId = this.templateInstanceComponent.templateInstanceUtils.resolveAccordionIdentifier(
                            data.id
                        );
                        return currentTemplateId === templateId;
                    });

                    addDevices(currentTemplate, checkedDevice, currentEquipmentAssociation);
                }
            } else {
                this.templateInstanceComponent.data.push({
                    id: currentEquipmentAssociation.equipmentIdentifier,
                    label: this.templateInstanceComponent.createEquipmentLabel(currentEquipmentAssociation.equipmentDetails),
                    children: [],
                    expand: false
                });

                this.templateInstanceComponent.templateInstance.equipmentAssociations.push(currentEquipmentAssociation);
            }

            this.templateInstanceComponent.equipments[checkedDevice.id] = angular.copy(currentEquipmentAssociation);
        });
        this.templateInstanceComponent.variablesModel.equipmentTemplateChangedStatus =
            EquipmentTemplateChangedStatus.NEEDS_CUSTOM_UPDATE;
    }

    addNewEquipment(callbackFunction?, shouldAddEquipmentsToAllTemplates?): any {
        if (this.templateInstanceComponent.canUpdate()) {
            const callback = callbackFunction || this.successAddEquipments.bind(this);

            this.templateInstanceComponent.ngDialog
                .openConfirm({
                    template: "templates/features/template/template-instance/modals/add-device-modal.html",
                    controller: "AddDeviceModalCtrl",
                    className: "extra-large-modal",
                    closeByNavigation: true,
                    resolve: {
                        devicesAlreadyAdded: () => {
                            const equipmentAssociations: EquipmentAssociation[] = new Array<EquipmentAssociation>();

                            if (
                                this.templateInstanceComponent.presentationMode.value ===
                                this.templateInstanceComponent.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
                            ) {
                                this.templateInstanceComponent.data.forEach((template) => {
                                    template.children.forEach((device) => {
                                        const deviceId = (device.id as DeviceIdentifier).resourceId;
                                        const templateId = (template.id as TemplateIdentifier).id;
                                        let selectedDevice: EquipmentAssociation = _.find(
                                            equipmentAssociations,
                                            (equipmentAssociation: EquipmentAssociation) => {
                                                return (
                                                    (equipmentAssociation.equipmentIdentifier as DeviceIdentifier).resourceId ===
                                                    (device.id as DeviceIdentifier).resourceId
                                                );
                                            }
                                        );

                                        if (!selectedDevice) {
                                            selectedDevice = angular.copy(this.templateInstanceComponent.equipments[deviceId]);
                                            selectedDevice.templateAssociations = [];
                                            equipmentAssociations.push(selectedDevice);
                                        }
                                        selectedDevice.templateAssociations.push(
                                            this.templateInstanceComponent.templates[templateId]
                                        );
                                    });
                                });
                            } else {
                                this.templateInstanceComponent.data.forEach((device) => {
                                    const deviceId = (device.id as DeviceIdentifier).resourceId;
                                    equipmentAssociations.push(angular.copy(this.templateInstanceComponent.equipments[deviceId]));
                                });
                            }

                            return equipmentAssociations;
                        },
                        templateInstanceResolvedInformation: () => {
                            let templateAlreadyAdded = null;

                            if (shouldAddEquipmentsToAllTemplates) {
                                const templates = [];

                                this.templateInstanceComponent.data.forEach((template) => {
                                    const templateId = (template.id as TemplateIdentifier).id;
                                    templates.push(angular.copy(this.templateInstanceComponent.templates[templateId]));
                                });

                                templateAlreadyAdded = templates;
                            } else if (
                                this.templateInstanceComponent.presentationMode.value ===
                                this.templateInstanceComponent.PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
                            ) {
                                templateAlreadyAdded = [angular.copy(this.templateInstanceComponent.templateSelected)];
                            }

                            return {
                                templateType: this.templateInstanceComponent.templateInstance.type,
                                templateAlreadyAdded,
                                presentationMode: this.templateInstanceComponent.presentationMode,
                                shouldAddEquipmentsToAllTemplates
                            };
                        },
                        deviceModelRestrictionTypes: () =>
                            this.templateInstanceComponent.ConfigRESTService.deviceModelRestrictionTypes(),
                        monitoringSettings: () => this.monitoringService.getMonitoringSettings()
                    }
                })
                .then(callback);
        }
    }

    createDeviceDropdownModel(deviceIdentifier: DeviceIdentifier): any {
        const deviceId = deviceIdentifier.resourceId;
        const device = this.templateInstanceComponent.equipments[deviceId];
        return this.deviceDropdownModelService.createModelFromTemplateInstanceDevice(device);
    }

    getAvailableFeatures(equipmentIdentifier: DeviceIdentifier): any {
        const deviceId = equipmentIdentifier.resourceId;
        return this.deviceDropdownModelService.getAvailableFeatures(deviceId);
    }

    private getDevicesById(ids: string) {
        this.deviceService.getDevicesByIds(ids).then(this.successAddEquipments.bind(this));
    }

    async fillEquipment() {
        await this.templateInstanceComponent.fillEquipment("id", this.getDevicesById, this.getDeviceResourceId);
    }

    applyEquipmentIfNecessary(): any {
        if (this.templateInstanceComponent.$state.params.equipment) {
            let deviceSelected = [];

            this.templateInstanceComponent.$state.params.equipment.forEach((device) => {
                deviceSelected.push({
                    id: device.id,
                    name: device.name,
                    model: device.model,
                    fwVersion: device.fwVersion,
                    hostname: device.hostname,
                    dmOs: device.dmOs,
                    locationId: device.locationId,
                    modelCode: device.modelCode,
                    vendorCode: device.vendorCode
                });
            });
            this.templateInstanceComponent.presentationMode.value =
                this.templateInstanceComponent.PRESENTATION_MODE.TEMPLATES_BY_EQUIPMENT;
            this.successAddEquipments(deviceSelected);
        }
    }

    getSpecificTranslationKeys() {
        return this.templateInstanceComponent.equipmentService.getSpecificTranslationKeys(
            this.templateInstanceComponent.TEMPLATE_TYPE.CLI.name
        );
    }

    createRuleTypesFilter(ruleTypes: RuleTypesSet) {

        const mapOmitField = {
            FIRMWARE: [RuleType.AFTER, RuleType.BEFORE, RuleType.IS_DMOS, RuleType.NOT_DMOS],
            IP_HOSTNAME: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN,
                RuleType.GREATER_THAN, RuleType.GREATER_THAN_OR_EQUAL, RuleType.NOT_BETWEEN,
                RuleType.LESS_THAN, RuleType.LESS_THAN_OR_EQUAL
            ],
            MODEL: [
                RuleType.AFTER, RuleType.BEFORE, RuleType.BEFORE,
                RuleType.BETWEEN, RuleType.NOT_BETWEEN, RuleType.GREATER_THAN_OR_EQUAL,
                RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN
            ],
            NAME: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN,
                RuleType.GREATER_THAN_OR_EQUAL, RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN
            ],
            SERIAL_NUMBER: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE, RuleType.BETWEEN, RuleType.NOT_BETWEEN,
                RuleType.GREATER_THAN_OR_EQUAL, RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN
            ],
            STATUS: [
                RuleType.IS_DMOS, RuleType.NOT_DMOS, RuleType.AFTER, RuleType.BEFORE,
                RuleType.BETWEEN, RuleType.NOT_BETWEEN, RuleType.GREATER_THAN_OR_EQUAL,
                RuleType.GREATER_THAN, RuleType.LESS_THAN_OR_EQUAL, RuleType.LESS_THAN
            ]
        };

        return function(field) {
            if (Object.keys(mapOmitField).includes(field)) {
                return _.omit(ruleTypes, mapOmitField[field]);
            }

            return ruleTypes;
        }
    }

    createEquipmentField(value: string): DeviceField {
        return { value: DeviceFieldValues[value], type: "device" };
    }

    createEquipmentIdentifier(equipment: any): DeviceIdentifier {
        return {
            resourceId: equipment.id,
            type: "DeviceIdentifier"
        } as DeviceIdentifier;
    }

    createEquipmentDetails(equipment: any): DeviceDetails {
        return {
            name: equipment.name,
            model: equipment.model,
            firmwareVersion: equipment.fwVersion,
            hostname: equipment.hostname,
            modelCode: equipment.modelCode,
            vendorCode: equipment.vendorCode,
            locationId: equipment.locationId,
            type: "DeviceDetails"
        };
    }

    getEquipmentSelectionTypeTranslationKey(equipmentSelectionType: EquipmentSelectionType): string {
        return `device.template.application.viewApply.selection.type.${equipmentSelectionType}`;
    }

    validateEquipmentsForFilters(checkedEquipments: Array<Cpe & EquipmentInfo>): Promise<EquipmentPermissionResult> {
        return this.deviceService.getDevicesWithoutManagerPermission(checkedEquipments).then(devices => {
            const hasDevicesWithoutPermission = !this.deviceService.validateDevicesWithoutPermission(checkedEquipments, devices);
            const hasCustomWithoutLicense = !this.deviceService.validateDevicesCustom(checkedEquipments);
            const messageDetails: Array<string> = [];

            if (hasDevicesWithoutPermission) {
                messageDetails.push(
                    this.translate.instant("templateinstanceform.templatedevices.filter.devices.removed.permissions"));
            }

            if (hasCustomWithoutLicense) {
                messageDetails.push(
                    this.translate.instant("templateinstanceform.templatedevices.filter.devices.removed.custom"));
            }

            if (_.isEmpty(messageDetails)) {
                return Promise.resolve({
                    hasEquipmentWithoutPermission: hasDevicesWithoutPermission,
                    equipments: _.indexBy(checkedEquipments, "id")
                } as EquipmentPermissionResult);
            }

            const confirmMessage = this.translate.instant("templateinstanceform.templatedevices.filter.devices.removed.confirm");
            const message = `${confirmMessage}<br><br>${messageDetails.join("<br>")}`;

            return this.$rootScope.showDialog({
                message,
                params: messageDetails,
                paramsInsideMessage: true,
                isConfirm: true
            }).then(() => {
                return {
                    hasEquipmentWithoutPermission: hasDevicesWithoutPermission,
                    equipments: _.indexBy(checkedEquipments, "id")
                } as EquipmentPermissionResult
            });
        });
    }

    buildMatchingEquipmentsFilterUrl() {
        let httpParams = this.templateInstanceComponent.buildUrlParametersFromMatchingRules();

        return `#/device/manage?${httpParams.toString()}`;
    }
}
