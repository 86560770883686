/**
 * Restrict value length.
 *
 * @author icaro.damiani
 */
var app = angular.module("nms.dynamicDevice");

/**
 * Verifies if a leaf value has a valid length
 */
app.directive("lengthRestriction", ["LengthRestrictionService", "RestrictionService",
    function(LengthRestrictionService, RestrictionService) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                var validatorName = RestrictionService.getLeafTypeValidatorName(scope.leaf.type,
                    LengthRestrictionService.RESTRICTION_NAME);
                ngModel.$validators[validatorName] = function(modelValue, viewValue) {
                    return LengthRestrictionService.validate(modelValue, viewValue, scope.leaf.type);
                };
            }
        };
    }
]);
