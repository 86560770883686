var app = angular.module("nms.templates");

/**
* @ngdoc directive
* @name nms.templates.directive:templateTest
* @description Diretiva para testar templates.
*/
app.directive("templateTest", [
    function() {
        return {
            scope: {
                commands: "=",
                variable: "=",
                variablesAlreadyAdded: "=",
                hasExtractionParams: "=",
                language: "="
            },
            restrict: "E",
            templateUrl: "templates/features/template/template/modals/variable/template-test.html",
            controller: "TemplateTestController"
        };
    }
]);
