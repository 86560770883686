var app = angular.module("nms.gponOnus", ["timer", "nms.templates"]);

app.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("features/gpon-onus");
}]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);
