"use strict";

/**
* @ngdoc directive
* @name nms.directive:devicesPermissions
* @description Diretiva para criar as de permissões de devices.
*
* Atributos:
* # id      - Obrigatório      - Identificador. Utilizado para a criação de ids no html de forma a evitar duplicidade
*                                quando diretiva é usada mais de uma vez na página.
*
* # owner   - Obrigatório      - Objeto utilizado para comunicação entre a diretiva e seu pai, contendo o modelo de dados.
*           - Estrutura        - {model: {}}
*
*/
var app = angular.module("nms");

app.directive("devicesPermissions", function() {
    return {
        restrict: "E",
        templateUrl: "templates/features/management/permissions/devices.html",
        controller: "ManagePermissionsDevicesController",
        scope: {
            id: "@",
            owner: "="
        }
    };
});
