"use strict";

/**
* @ngdoc service
* @name nms.window
* @description
* # window
* Service in the nms.
*/
var app = angular.module("nms");

app.factory("WindowService", ["$window",
    function($window) {
        const service: any = {};

        service.getBrandingIcon = function() {
        var brandingIcon: any = {};
            brandingIcon.url = localStorage.getItem("brandingIconUrl");
            brandingIcon.alt = localStorage.getItem("brandingIconAlt");

            return brandingIcon;
        };

        service.setBrandingIcon = function(brandingIcon) {
            localStorage.setItem("brandingIconUrl", brandingIcon.url);
            localStorage.setItem("brandingIconAlt", brandingIcon.alt);
        };

        service.getInstalledProduct = function() {
            return localStorage.getItem("installedProduct");
        };

        service.setInstalledProduct = function(installedProduct) {
            localStorage.setItem("installedProduct", installedProduct);
        };

        service.setFeaturesLicense = function(license) {
            localStorage.setItem("license", license);
        };

        service.getFeaturesLicense = function() {
            return localStorage.getItem("license");
        };

        return service;
    }
]);
