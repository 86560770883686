"use strict";

/**
* @ngdoc directive
* @name nms.directive:heightCalculator
* @description Diretiva para recalcular a altura de um elemento baseado na altura do pai e irmãos.
*/
var app = angular.module("nms");
app.directive("heightCalculator", ["$timeout", "$window",
    function($timeout, $window) {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                var getSiblingsHeightSum = function() {
                    var siblings = attrs.elementSibling.split(",");
                    var siblingsHeightSum = _.sum(siblings, function(sibling) {
                        return angular.element(sibling)[0].clientHeight;
                    });

                    return siblingsHeightSum;
                };

                var updateHeight = function(siblingsHeight?) {
                    var parentHeight = angular.element(attrs.elementParent)[0].clientHeight;
                    var siblingsHeightSum = siblingsHeight;
                    var element = elem[0];

                    if (!siblingsHeightSum) {
                        siblingsHeightSum = getSiblingsHeightSum();
                    }

                    var newHeight = parentHeight - siblingsHeightSum;
                    angular.element(element).css("height", newHeight + "px");
                };

                var triggerDelayedUpdate = function() {
                    $timeout(function() {
                        updateHeight();
                    }, 200);
                };

                scope.$watch(
                    function() {
                        return getSiblingsHeightSum();
                    },
                    function(siblingsHeight) {
                        updateHeight(siblingsHeight);
                    }
                );

                var unregisterRecalculateOnResize = angular.element($window).on("resize", triggerDelayedUpdate);
                scope.$on("$destroy", unregisterRecalculateOnResize);
            }
        };
    }
]);
