import { Inject, Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ANGULARJS_ROOTSCOPE, NMS_ERRORS } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { HttpStatusCode } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";

/**
 * Responsável por interceptar erros em chamadas http do módulo ng2.
 */
const NOT_AUTHORIZED = 401;
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(@Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
        @Inject(NMS_ERRORS) private nmsErrors: any) {}

    intercept = (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> =>
        next.handle(request)
            .pipe(
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    let { error, status } = httpErrorResponse;

                    if (status && status === HttpStatusCode.GatewayTimeout) {
                        this.showErrorDialog("http.error.serverError");
                    } else if (error && error.code) {
                        switch(error.code) {
                            case this.nmsErrors.ACS_COULD_NOT_ACCESS_SERVER_ERROR:
                                this.showErrorDialog("unavailable.acs.server.message");
                                break;
                            default:
                                console.error(`Unknown NMS error code ${httpErrorResponse.error.code}`);
                        }
                    } else if (status && status === NOT_AUTHORIZED) {
                        this.$rootScope.prepareToNewLogin(true);
                    }
                    return throwError(httpErrorResponse);
                })
        );

    /** Mostra a modal de erro com a mensagem traduzida. */
    private showErrorDialog = (translateKey: string): void => this.$rootScope.showDialog({translateKey});
}