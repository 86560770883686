"use strict";

/**
* @ngdoc service
* @name nms.audit.AuditRESTService
* @description
* # AuditRESTService
* Service in the nms.templates.
*/
angular.module("nms.audit")
.factory("AuditRESTService", ["Restangular", "$translate", function(Restangular, $translate) {
    var basePath = "/audit";

    return {
        getPaginated: function(filters) {
            var URI = ["pageNumber=" + filters.pageNumber,
                "pageSize=" + filters.pageSize,
                "column=" + filters.column,
                "direction=" + filters.direction];

            if (filters.searchTerm) {
                URI.push("searchTerm=" + filters.searchTerm);
            }

            if (filters.searchTermFinal) {
                URI.push("searchTermFinal=" + filters.searchTermFinal);
            }

            if (filters.searchColumn) {
                URI.push("searchColumn=" + filters.searchColumn);
            }

            if (filters.currentType) {
                URI.push("searchFieldType=" + filters.currentType);
            }

            if (filters.type) {
                URI.push("type=" + filters.type);
            }

            return Restangular.all(basePath + "?" + URI.join("&"))
                .withHttpConfig({notFoundBehaviorMessage:$translate.instant("audit.pageNotFound.behavior.message")})
                .customGET();
        },

        getAuditDetails: function(auditId) {
            return Restangular.one(basePath + "/details/" + auditId).get();
        },

        auditExport: function(filters) {
            var URI = [],
                urlExport = basePath + "/export";

            if (filters.searchTerm) {
                URI.push("searchTerm=" + filters.searchTerm);
            }

            if (filters.searchTermFinal) {
                URI.push("searchTermFinal=" + filters.searchTermFinal);
            }

            if (filters.searchColumn) {
                URI.push("searchColumn=" + filters.searchColumn);
            }

            if (URI.length > 0) {
                urlExport += "?";
                // searchFieldType must not be alone in the query params
                if (filters.currentType) {
                    URI.push("searchFieldType=" + filters.currentType);
                }
            }

            return Restangular.all(urlExport + URI.join("&")).customGET();
        },

        getTotalTypes: function() {
            return Restangular.all(basePath + "/totalTypes").customGET();
        }
    };
}]);
