"use strict";

/**
* @ngdoc service
* @name nms.PropertiesRestService
* @description
* # PropertiesRestService - Serviço REST para propriedades
* Factory in the nms.
*/
var app = angular.module("nms");

app.factory("PropertiesRestService", ["Restangular",
    function(Restangular) {
        var basePath = "/device-management-web";
        const service: any = {};

        service.updateProperties = function(propertiesUpdateInstance) {
            return Restangular.one(basePath + "/device/properties?username=" + localStorage.getItem("username"))
                              .customPUT(propertiesUpdateInstance);
        };

        service.getProperties = function(resourceId) {
            return Restangular.all(basePath + "/device/properties?resourceId=" + resourceId).customGET();
        };

        return service;
    }
]);
