import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  ANGULARJS_ROOTSCOPE,
  ANGULARJS_TRANSLATE,
  SNMP_DATA,
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { ManagementProtocolModel }
    from "@nms-ng2/app/shared/components/elements/management-protocols/management-protocols-model";
import { ManagementProtocolNotifier }
    from "@nms-ng2/app/shared/components/elements/management-protocols/management-protocols-notifier.model";
import { Subject } from "rxjs";
import { GlobalManagementProtocolsService } from "./global-management-protocol.service";

declare let _: any;

/**
 * Componente responsável por alterar as configurações globais dos equipamentos.
 */
@Component({
  selector: "global-management-protocols",
  templateUrl: "./global-management-protocols.component.html",
  styleUrls: ["./global-management-protocols.component.scss"],
})
export class GlobalManagementProtocolsComponent implements OnInit, OnDestroy {

  readonly GLOBAL: string = "global";
  formData: any;
  snmpVersion: any;
  snmpV3PassLength: any;
  authType: any;
  cypher: any;
  oldCredentials: any;
  globalCredentials: any;
  credentialsSaveNotifier: Subject<ManagementProtocolNotifier>;
  credentialsCloseNotifier: Subject<void>;
  credentials: ManagementProtocolModel;

  constructor(@Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
      @Inject(ANGULARJS_TRANSLATE) private translate: any,
      @Inject(SNMP_DATA) private snmpData,
      private globalManagementProtocolService: GlobalManagementProtocolsService,
      private window: Window) {
      this.oldCredentials = {};
      this.formData = { global: {} };
      this.credentials = {
          globalCredentials: {},
          specificCredentials: {},
          oldCredentials: {}
      };
      this.credentialsSaveNotifier = new Subject();
      this.credentialsCloseNotifier = new Subject();
  }

  ngOnInit() {
      this.initManagementProtocols();
      this.credentialsSaveNotifier.subscribe((credential: ManagementProtocolNotifier) => {
          this.oldCredentials.credentials = _.cloneDeep(this.formData[this.GLOBAL]);
          this.globalManagementProtocolService.saveGlobalCredentials(credential.globalCredentials);
      });

      this.credentialsCloseNotifier.subscribe(() => {
          this.$rootScope.showDialog({
              message: this.translate.instant("global.managementProtocols.save.cancel"),
              isConfirm: true
          }).then(() => {
              this.window.history.back();
          });
      });
  }

  initManagementProtocols() {
      this.globalManagementProtocolService.getManagementProtocols()
          .subscribe((data: any) => {
              this.globalCredentials = data;
              this.buildCredentials();
              this.oldCredentials.userPassConfig = this.GLOBAL;
              this.oldCredentials.credentials = _.cloneDeep(this.formData[this.GLOBAL]);
              this.credentials = {
                  globalCredentials: this.globalCredentials,
                  specificCredentials: {},
                  oldCredentials: this.oldCredentials
              }
          });
  }

  buildCredentials() {
      this.fillSnmpCredentials(this.globalCredentials.snmpCredential);
      this.fillTelnetCredentials(this.globalCredentials.telnetCredential);
      this.fillNetconfCredentials(this.globalCredentials.netconfCredential);
      this.fillSftpCredentials(this.globalCredentials.sftpCredential);
      this.fillHttpCredentials(this.globalCredentials.httpCredential);
      this.fillPcgaCredentials(this.globalCredentials.pcgaCredential);
      this.fillSshCredentials(this.globalCredentials.sshCredential);
  };

  private fillSnmpCredentials(credential: any) {
      this.formData.global.readCommunitySnmpV1 = credential.readCommunity;
      this.formData.global.writeCommunitySnmpV1 = credential.writeCommunity;
      this.formData.global.readCommunitySnmpV2C = credential.readCommunity;
      this.formData.global.writeCommunitySnmpV2C = credential.writeCommunity;
      this.formData.global.userSNMPv3 = credential.username;
      this.formData.global.passSNMPv3 = credential.userPassword;
      this.formData.global.privacyPass = credential.passPhrase;
      this.formData.global.version = credential.version;
      this.formData.global.authType = credential.authType;
      this.formData.global.timeoutSnmp = credential.timeout;
      this.formData.global.retriesSnmp = credential.retries;
      this.formData.global.privacy = !_.isEqual(credential.cypher, this.snmpData.CYPHER.NONE);
      this.formData.global.cypher = _.isEqual(credential.cypher, this.snmpData.CYPHER.NONE)
          ? this.snmpData.CYPHER.DES
          : credential.cypher;
  };

  private fillTelnetCredentials(credential) {
      this.formData.global.userCliOverTelnet = credential.username;
      this.formData.global.passCliOverTelnet = credential.password;
      this.formData.global.timeoutCliOverTelnet = credential.timeout;
      this.formData.global.retriesCliOverTelnet = credential.retries;
  };

  private fillNetconfCredentials(credential) {
      this.formData.global.userNetConfOverSsh = credential.username;
      this.formData.global.passNetConfOverSsh = credential.password;
      this.formData.global.timeoutNetConfOverSsh = credential.timeout;
  };

  private fillSftpCredentials(credential) {
      this.formData.global.passSFTP = credential.password;
      this.formData.global.timeoutSFTP = credential.timeout;
  };

  private fillHttpCredentials(credential) {
      this.formData.global.userHTTP = credential.username;
      this.formData.global.passHTTP = credential.password;
      this.formData.global.timeoutHTTP = credential.timeout;
      this.formData.global.retriesHTTP = credential.retries;
  };

  private fillPcgaCredentials(credential) {
      this.formData.global.userNMS = credential.pcgaUser;
      this.formData.global.passNMS = credential.pcgaPassword;
      this.formData.global.timeoutNMS = credential.timeout;
      this.formData.global.retriesNMS = credential.retries;
  };

  private fillSshCredentials(credential) {
      this.formData.global.userCliOverSsh = credential.username;
      this.formData.global.passCliOverSsh = credential.password;
      this.formData.global.portCliOverSsh = credential.port;
      this.formData.global.timeoutCliOverSsh = credential.timeout;
      this.formData.global.retriesCliOverSsh = credential.retries;
  };

  ngOnDestroy() {
      this.credentialsSaveNotifier.unsubscribe();
      this.credentialsCloseNotifier.unsubscribe();
  }
}