import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

/**
 * Service responsável pela recuperação do tempo de intervalo de atualizações.
 */
@Injectable({
    providedIn: "root"
})
export class RefreshIntervalService {

    private baseUrl: string;
    private intervalInSeconds;

    constructor(private http: HttpClient) {
        this.baseUrl = "/device-management-web";
    }

    public getRefreshInterval(callback: Function): void {
        if (this.intervalInSeconds) {
            callback(this.intervalInSeconds);
        } else {
            this.requestRefreshInterval().subscribe(interval => {
                this.intervalInSeconds = interval * 60;
                callback(this.intervalInSeconds);
            });
        }
    }

    private requestRefreshInterval(): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/refreshInterval`);
    }
}
