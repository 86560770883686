"use strict";

interface GponOnuUiConfiguration {
    ui: "nms.gponOnus" | "nms.newGponOnus";
    dialogWasShown: boolean;
}

var app = angular.module("nms.gponOnus");

app.service("GponOnusService", ["GponOnusRestService", "GponOnusResolver", "NMS_STATES", "UserPreferencesService",
    function(GponOnusRestService, GponOnusResolver, NMS_STATES, UserPreferencesService) {
        const SESSION_STORAGE_PREFIX = "gpon-onus";
        const SESSION_STORAGE_PROPERTY = "config";
        const service: any = {};

        service.findOnusByResourceId = function(resourceId) {
            return GponOnusRestService.findOnusByResourceId(resourceId).then(GponOnusResolver.resolve);
        };

        service.getAllOnus = function() {
            return GponOnusRestService.getAll().then(GponOnusResolver.resolve);
        };

        service.getGponOnuUISref = function() {
            return getSavedConfigurationOnStorageOrDefault().ui;
        };

        service.getGponOnuUIPath = function() {
            return service.getGponOnuUISref().replace("nms.", "");
        };

        /**
         * Busca no LocalStorage se a dialog informativa sobre performance em tabelas
         * com um número elevado de itens já foi apresentado. Se sim, não será mostrado
         * para o usuário novamente, se não a dialog será apresentada.
         */
        service.wasntPerformanceDialogWarningShown = function() {
            return !getSavedConfigurationOnStorageOrDefault().dialogWasShown;
        };

        service.setGponOnuUiPreference = function(newConfiguration: Partial<GponOnuUiConfiguration>) {
            const uiConfiguration = { config: { ...getSavedConfigurationOnStorageOrDefault(), ...newConfiguration } };

            UserPreferencesService.savePreferences(uiConfiguration, SESSION_STORAGE_PREFIX, [SESSION_STORAGE_PROPERTY]);
        };

        function getSavedConfigurationOnStorageOrDefault(): GponOnuUiConfiguration {
            const savedConfiguration = UserPreferencesService.getValue(SESSION_STORAGE_PREFIX, SESSION_STORAGE_PROPERTY);
            return savedConfiguration ? JSON.parse(savedConfiguration) : { ui: NMS_STATES.gponOnus, dialogWasShown: false };
        };

        return service;
    }
]);
