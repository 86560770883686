/**
 * Directive responsável por inserir o componente de atualização automática.
 *
 * Permite que outros itens sejam colocados dentro dela, via transclude
 * #model - Objeto para interface entre o controller principal e o controller da diretiva.
 *
 */
var app = angular.module("nms");

app.directive("autoUpdater",
    function() {
        return {
            templateUrl: "templates/components/ui/auto-updater/auto-updater.html",
            controller: "AutoUpdaterController",
            scope: {},
            transclude: true
        };
    }
);
