"use strict";

/**
* @ngdoc directive
* @name nms.directive:devicesTable
* @description Diretiva para criar tabela de visualização e edição de devices.
*/
var app = angular.module("nms");

app.directive("devicesTable", function() {
    return {
        restrict: "E",
        templateUrl: "templates/components/ui/device/table/devices-table.html",
        controller: "DevicesTableController",
        scope: {
            listOwner: "=listOwner",
            windowIdentifier: "=",
            tableIdentifier: "=",
            enableSummaryAccess: "=",
            openLinkNewTab: "=",
            insideModal: "=",
            monitoringSettings: "=",
            queryParameters: "="
        },
        link: function(scope, element, attrs) {
            scope.showApplyTemplate = attrs.showApplyTemplate === "true";
            scope.showViewTemplateApplication = attrs.showViewTemplateApplication === "true";
            scope.showRemoveDevices = attrs.showRemoveDevices === "true";
        }
    };
});
