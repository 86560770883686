"use strict";

import { AuditRetentionModalComponent } from "@nms-ng2/app/shared/components/modals/audit-retention/audit-retention-modal.component";

/**
 * @ngdoc function
 * @name nms.audit.controller:AuditCtrl
 * @description
 * # AuditCtrl
 * Controller of the nms.templates
 */
angular.module("nms.audit").controller("AuditListCtrl", [
    "$rootScope",
    "$scope",
    "AuditService",
    "$translate",
    "$timeout",
    "ngDialog",
    "types",
    "operation",
    "result",
    "names",
    "searchCriteria",
    "ServerPaginationService",
    "UserPreferencesService",
    "ModalFactory",
    "TABLE_PROPERTIES",
    "BROADCAST_EVENTS",
    function (
        $rootScope,
        $scope,
        AuditService,
        $translate,
        $timeout,
        ngDialog,
        types,
        operation,
        result,
        names,
        searchCriteria,
        ServerPaginationService,
        UserPreferencesService,
        ModalFactory,
        TABLE_PROPERTIES,
        BROADCAST_EVENTS
    ) {
        $scope.auditItens = [];
        $scope.typeTotals = { total: "0" };
        $scope.filters = ServerPaginationService.getFilters({
            origin: "audit",
            column: "time",
            currentType: "TEXT",
            searchTermFinal: "",
            searchTerm: "",
            searchColumn: ""
        });
        $scope.pageDetails = ServerPaginationService.getPageDetails();
        $scope.types = types;
        $scope.operation = operation;
        $scope.result = result;
        $scope.names = names;

        $scope.searchCriteria = searchCriteria;

        $scope.search = {};
        $scope.search.field = {};
        $scope.previousSearch = {};
        $scope.dateFinalPreviousSearch = {};
        $scope.datePicker = {};
        $scope.datePicker.date = { startDate: null, endDate: null };

        this.loadTootipTexts = function () {
            $scope.viewDetails = $translate.instant("audit.viewDetails");
            $scope.mainTip = $translate.instant("audit.mainTip");
        };

        $scope.setSearchType = function () {
            $scope.filters.currentType = AuditService.getSearchType($scope.searchCriteria[$scope.search.field]);
            if ($scope.filters.currentType !== "DATE_RANGE" && $scope.filters.currentType !== "OPTION") {
                delete $scope.filters.searchTermFinal;
                $scope.search.value = "";
            }
        };

        $scope.getAuditName = function (auditName: string) {
            return names[auditName] || auditName;
        };

        $scope.setHasSearchValue = function (value) {
            $timeout(function () {
                $scope.hasSearch = value;
            }, 1);
        };

        $scope.loadPage = function () {
            $scope.setHasSearchValue(AuditService.hasSearchCriteria($scope.filters));

            AuditService.getPaginated($scope.filters).then($scope.successGetPaginated);
        };

        $scope.readPageFiltersFromSessionStorage = function () {
            $scope.filters = UserPreferencesService.loadPageFilterValues($scope.filters, $scope.filters.origin);
            $scope.search.value = $scope.filters.searchTerm ? decodeURIComponent($scope.filters.searchTerm) : undefined;
            $scope.search.field = $scope.filters.searchColumn;
            $scope.hasSearch = AuditService.hasSearchCriteria($scope.filters);
            if ($scope.filters.currentType === "OPTION") {
                $scope.setSearchType();
            } else if ($scope.filters.currentType === "DATE_RANGE") {
                $scope.setSearchType();
                $scope.datePicker.date.startDate = +$scope.filters.searchTerm;
                $scope.datePicker.date.endDate = +$scope.filters.searchTermFinal;
            }
        };

        $scope.setPageFiltersSession = function () {
            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
        };

        $scope.orderByTable = function (column) {
            if ($scope.filters.column !== column) {
                $scope.filters.column = column;
                $scope.filters.direction = "ASC";
            } else {
                $scope.filters.direction = $scope.filters.direction === "ASC" ? "DESC" : "ASC";
            }
            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
            $scope.loadPage();
        };

        $scope.openDetails = function (auditOperation) {
            $scope.auditId = auditOperation.id;
            // Create modal to show audit operation details
            ngDialog.open({
                template: "templates/features/audit/audit-details-modal.html",
                controller: "AuditModalCtrl",
                className: "big-modal without-footer",
                closeByNavigation: true,
                resolve: {
                    auditOperation: $scope.getAuditDetails
                }
            });
        };

        $scope.getAuditDetails = function () {
            return AuditService.getAuditDetails($scope.auditId);
        };

        $scope.auditExport = function () {
            var params = [];
            params.push($scope.pageDetails.totalElements + $translate.instant("audit.export.amount"));
            $rootScope
                .showDialog({
                    translateKey: "audit.export.confirmation",
                    params: params,
                    maxChars: 128,
                    isConfirm: true
                })
                .then(function () {
                    AuditService.auditExport($scope.filters).then($scope.downloadFile);
                });
        };

        /** ** CALL BACKS *****/
        $scope.successGetPaginated = function (response) {
            $scope.auditItens = response.pageElements;
            $scope.filters = ServerPaginationService.updateFilters($scope.filters, response.pageSize, response.pageNumber);
            $scope.pageDetails = ServerPaginationService.updatePageDetails(
                $scope.pageDetails,
                response.totalElements,
                response.pageNumber,
                response.pageSize
            );
            /* Set previous search criteria */
            $scope.previousSearch.value = $scope.search.value;
            $scope.previousSearch.field = $scope.search.field;
            $scope.dateFinalPreviousSearch = $scope.filters.searchTermFinal;
        };

        $scope.resetPagination = function () {
            $rootScope.$on(BROADCAST_EVENTS.HTTP_ERROR_MODAL_CLOSED, (broadcastEvent, response) => {
                if (response.status === 404) {
                    $scope.filters.pageNumber = TABLE_PROPERTIES.DEFAULT_START_PAGE;
                    $scope.setPageFiltersSession();
                    $scope.loadPage();
                }
            });
        };

        $scope.downloadFile = function (response) {
            var file = new Blob([response], { type: "text/csv" });
            saveAs(file, "AuditExport.csv");

            $rootScope.toastInfo("audit.export.success");
        };

        $scope.getTotalTypes = function () {
            AuditService.getTotalTypes().then(function (response) {
                $scope.typeTotals = response;
            });
        };

        /** Abre a modal de retenção de auditoria caso o usuário seja administrador e mostra uma mensagem de erro caso não seja*/
        $scope.openRetentionModal = function () {
            if ($rootScope.isAdmin) {
                ModalFactory.openAsyncModal(AuditRetentionModalComponent, {});
            } else {
                $rootScope.showDialog({
                    translateKey: "audit.retention.access.denied"
                });
            }
        };

        $scope.readPageFiltersFromSessionStorage();
        $scope.loadPage();
        $scope.getTotalTypes();
        this.loadTootipTexts();
        $scope.resetPagination();
    }
]);
