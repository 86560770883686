"use strict";

var app = angular.module("LicenseModule");

app.factory("LicenseService", ["$rootScope", "$window", "WindowService", "BRAND_LOGOS", "LicenseRESTService", "NMS_LICENSES",
    "BRAND_ICONS", "APPLICATION_BRAND_ICONS",
    function($rootScope, $window, WindowService, BRAND_LOGOS, licenseRESTService, NMS_LICENSES, BRAND_ICONS,
        APPLICATION_BRAND_ICONS) {
        const service: any = {};

        service.requestLicense = function() {
            licenseRESTService.requestLicense().then(function(data) {
                service.setLicense(data.plain());
                $rootScope.$broadcast("LICENSE_RECEIVED");
            });
        };

        service.getLicense = function() {
            return service.license;
        };

        service.setLicense = function(license) {
            service.license = license;
        };

        service.hasLicense = function(licenseName) {
            return _.contains(service.getFeaturesLicense(), licenseName) || licenseName === NMS_LICENSES.notRequired.name;
        };

        service.loadBrandingApplicationIcon = function() {
            var brandingCode = $rootScope.installedProduct.brandCode;
            $rootScope.applicationBrandIcon = APPLICATION_BRAND_ICONS[brandingCode];
        };

        service.loadBrandingIcon = function() {
            $rootScope.brandingIcon = WindowService.getBrandingIcon();

            if (!$rootScope.brandingIcon || !($rootScope.brandingIcon.url && $rootScope.brandingIcon.alt)) {
                if ($rootScope.installedProduct) {
                    var brandingCode = $rootScope.installedProduct.brandCode;
                    if (BRAND_LOGOS[brandingCode]) {
                        $rootScope.brandingIcon = BRAND_LOGOS[brandingCode];
                        WindowService.setBrandingIcon($rootScope.brandingIcon);
                    }
                }
            }
        };

        service.loadInstalledProduct = function() {
            var product = WindowService.getInstalledProduct();

            if (product) {
                $rootScope.installedProduct = JSON.parse($window.atob(product));
            } else {
                $rootScope.installedProduct = service.getErrorInstalledProduct();

                product = JSON.stringify($rootScope.installedProduct);
                WindowService.setInstalledProduct($window.btoa(product));

                console.warn("Could not get installed product.");
            }

            return $rootScope.installedProduct;
        };

        service.refreshFeaturesLicense = function() {
            var license = service.getLicense();

            if (license) {
                var licenseJsonString = JSON.stringify(license.features);

                WindowService.setFeaturesLicense($window.btoa(licenseJsonString));
            } else {
                console.warn("Could not get license for features.");
            }
        };

        service.refreshInstalledProduct = function() {
            var license = service.getLicense();

            if (license) {
                $rootScope.installedProduct = license.product;
            } else {
                $rootScope.installedProduct = service.getErrorInstalledProduct();

                console.warn("Could not get license for installed product.");
            }

            var installedProductJsonString = JSON.stringify(license.product);
            WindowService.setInstalledProduct($window.btoa(installedProductJsonString));
        };

        service.getFeaturesLicense = function() {
            var featuresLicense = WindowService.getFeaturesLicense();

            if (featuresLicense) {
                var licenseJsonString = $window.atob(featuresLicense);

                return JSON.parse(licenseJsonString);
            } else {
                // FIXME Aqui no tratamento deste else, realizar a chamada para service.requestLicense()
                // de forma a tentar requisitar a licença novamente. Ao fazer isso, cuidar para não realizar múltiplas requisições.
                console.warn("License for features not set.");

                return [];
            }
        };

        service.getErrorInstalledProduct = function() {
            return {brandCode: 0, productName: "NMS", swVersion: "version not found"};
        };

        service.setFaviconByBranding = function() {
            let icon = BRAND_ICONS["defaultBrand"];
            if ($rootScope.installedProduct) {
                const brandingCode = $rootScope.installedProduct.brandCode;

                if (BRAND_ICONS[brandingCode]) {
                    icon = BRAND_ICONS[brandingCode];
                }
            }

            let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
            favIcon.href = icon;

        };

        service.isMultivendorAllowed = function() {
            return service.hasFeatureLicense(service.getFeaturesLicense(), NMS_LICENSES.deviceCustom.name);
        };

        service.hasFeatureLicense = function(featuresLicense, featureLicense) {
            return _.includes(featuresLicense, featureLicense);
        };

        var unregisterLicenseWatcher = $rootScope.$watch(
            function() {
                return WindowService.getFeaturesLicense();
            }, function(newValue, oldValue) {
            if (!newValue && oldValue) {
                service.requestLicense();
            }
        });

        $rootScope.$on("$destroy", function() {
            unregisterLicenseWatcher();
        });

        return service;
    }
]);
