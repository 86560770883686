"use strict";

/**
 * @ngdoc controller
 * @name nms.components.autoUpdater:AutoUpdaterCounterController
 * @description
 *
 * # AutoUpdaterCounterController
 *
 * Responsável por controlar a contagem crescente do tempo levado para a atualização.
 *
 * OBS: É usado $scope.$broadcast() porque se trata de repassar a informação para o timer, no escopo filho.
 *      Não confundir com os eventos em AutoUpdaterCountdownController.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.controller("AutoUpdaterCounterController", ["$rootScope", "$scope", "AutoUpdaterService",
    function($rootScope, $scope, AutoUpdaterService) {
        var unregisterStopCounter = $scope.$on("stopCounter", function(event, data) {
            $scope.$broadcast("timer-stop");
        });

        var unregisterStartCounter = $scope.$on("startCounter", function(event, data) {
            $scope.$broadcast("timer-start");
        });

        $scope.$on("$destroy", function() {
            unregisterStartCounter();
            unregisterStopCounter();
        });
    }
]);
