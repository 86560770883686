"use strict";

/**
* @ngdoc service
* @name nms.deviceSummary.DeviceStatusRESTService
* @description
* # DeviceStatusRESTService - Serviço REST para status de equipamento.
* Factory in the nms.deviceSummary.
*/
var app = angular.module("nms.deviceSummary");

app.factory("DeviceStatusRESTService", ["Restangular", function(Restangular) {
    var basePath = "/service/device";

    return {
        getStatus: function(resourceId) {
            return Restangular.one(basePath + "/" + resourceId)
                .withHttpConfig({bypassInterceptor: true, hideLoadingPanel: true})
                .customGET();
        }
    };
}]);
