"use strict";

/**
* @ngdoc directive
* @name nms.directive:inputFormatter
* @description Diretiva para adicionar máscaras específicas em inputs.
*/
angular.module("nms")
.directive("inputFormatter", function($timeout) {
    return {
        restrict: "A",
        require: "ngModel",
        link: function(scope, elem, attrs, ngModel) {
            var WAIT_FOR_DEVICE = 50;
            var pattern = new RegExp(attrs.inputFormatter);

            var options: any = {
                onChange: function(value, event, currentField, options) {
                    scope.$apply(function() {
                        validate(value);
                    });
                }
            };

            if (attrs.inputMask) {
                $(elem).mask(attrs.inputMask, options);
            } else {
                ngModel.$parsers.unshift(function(value) {
                    validate(value);
                    return value;
                });
            }

            function validate(value) {
                var isValid = pattern.test(value);
                ngModel.$setValidity("inputFormatter", isValid);
            }

            function init() {
                $(elem).keyup(function() {
                    ngModel.$setViewValue($(elem).val());
                });

                $timeout(function() {
                    var value = $(elem).val();

                    if (value) {
                        validate(value);
                    }
                }, WAIT_FOR_DEVICE);
            }

            init();
        }
    };
});
