"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.ManageUsersModelConverter
* @description - Conversor do modelo de dados da modal de criação de usuários.
*/
var app = angular.module("nms.management");

app.service("ManageUsersModelConverter", ["ManagePermissionsModelConverter",
    function(ManagePermissionsModelConverter) {
        const service: any = {};

        var convertUser = function(userFormAccount) {
            if (userFormAccount.changePassword === false) {
                userFormAccount.password = "";
            }

            if (userFormAccount.permission === "individual") {
                userFormAccount.group = null;
            }

            var user: any = {
                username: userFormAccount.user,
                password: userFormAccount.password,
                administrator: userFormAccount.administrator,
                email: userFormAccount.email,
                groupName: userFormAccount.group,
                name: userFormAccount.name,
                phone: userFormAccount.phone
            };

            return user;
        };

        /**
         *  Converte o modelo de dados da tela para o modelo esperado pelo serviço de criação de usuários, que é o seguinte:
         *
         *  {
         *    "user": {
         *      "administrator": true,
         *      "email": "string",
         *      "groupName": "string",
         *      "name": "string",
         *      "password": "string",
         *      "phone": "string",
         *      "username": "string"
         *    },
         *    "permissionModel": {
         *        "devicePermissionModels": [
         *          {
         *            "modelCode": 0,
         *            "permission": 0,
         *            "vendorCode": 0
         *          }
         *        ],
         *        "locationPermissionModels": [
         *          {
         *            "graphicId": 0,
         *            "permission": 0
         *          }
         *        ],
         *        "personalPermissionModels": [
         *          {
         *            "instance": "string",
         *            "permission": true
         *          }
         *        ]
                },
         *      "userSecuritySettings": {
         *        "loginAccessInitialTime": "string",
         *        "loginAccessFinalTime": "string",
         *        "userTimeoutDelay": 0
         *      }
         *    }
         *  }
         */
        service.convert = function(userForm) {
            var permissions = ManagePermissionsModelConverter.convertPermissions(userForm);

            return {
                user: convertUser(userForm.account.data),
                permissionsModel: permissions.permissionsModel,
                userSecuritySettings: permissions.userSecuritySettings
            };
        };

        return service;
    }
]);
