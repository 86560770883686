"use strict";

/**
* @ngdoc service
* @name nms.service.StepperService
* @description - Service do componente de stepper.
*/
var app = angular.module("nms");

app.factory("StepperService", ["$parse",
    function($parse) {
        const service: any = {};

        var removeBracesIfNeeded = function(value) {
            return value ? value.replace(/[{}]/g, "") : null;
        };

        var processPower = function(operation, element) {
            var inputValue = element.val();
            var newValue;

            var value = Number(inputValue);
            var logResult;

            if (operation.type === "UP") {
                value = value < 1 ? 1 : value;
                logResult = Math.log(value) / Math.log(operation.power);
                while (logResult !== Math.floor(logResult)) {
                    value++;
                    logResult = Math.log(value)/ Math.log(operation.power);
                }
            } else {
                logResult = Math.log(value)/ Math.log(operation.power);
                while (value >= 1 && logResult !== Math.floor(logResult)) {
                    value--;
                    logResult = Math.log(value)/ Math.log(operation.power);
                }
            }

            newValue = value;

            if (operation.validate(newValue)) {
                element.val(newValue);
            }
        };

        var isEmptyValueFn = function(value) {
            return _.isNull(value) || _.isUndefined(value);
        };

        service.process = function(operation, element) {
            processStep(operation, element);

            if (operation.power) {
                processPower(operation, element);
            }
        };

        var processStep = function(operation, element) {
            var inputValue = element.val();
            var newValue;
            if (!_.isEmpty(inputValue) && !isNaN(inputValue)) {
                var value = Number(inputValue);
                if (value % operation.step === 0) {
                    newValue = value + operation.step;
                } else {
                    var division = value / operation.step;
                    newValue = Math.ceil(division) * operation.step;
                }
            } else {
                newValue = operation.step;
            }

            if (operation.validate(newValue)) {
                var validValue = operation.getValidValue(newValue);
                element.val(validValue);
            }
        };

        service.parse = function(value) {
            return $parse(removeBracesIfNeeded(value));
        };

        service.parseValue = function(value) {
            if (_.isEmpty(value)) {
                return null;
            } else if (isNaN(value)) {
                return undefined;
            } else {
                return Number(value);
            }
        };

        service.getValidateNumberFn = function() {
            return function(modelValue) {
                var value = service.parseValue(modelValue);
                return isEmptyValueFn(modelValue) || !isNaN(value);
            };
        };

        service.getValidateMinFn = function(min) {
            return function(modelValue) {
                var value = service.parseValue(modelValue);
                return isEmptyValueFn(value) || _.isNull(min) || value >= min;
            };
        };

        service.getValidateMaxFn = function(max) {
            return function(modelValue) {
                var value = service.parseValue(modelValue);
                return isEmptyValueFn(value) || _.isNull(max) || value <= max;
            };
        };

        service.getMutationObserver = function(element) {
            return new MutationObserver(function(mutations) {
                mutations.forEach(function(mutation) {
                    if (mutation.attributeName === "class") {
                        if (_.includes(element[0].className, "ng-invalid")) {
                            element.parent().addClass("ng-invalid");
                        } else {
                            element.parent().removeClass("ng-invalid");
                        }
                    }
                });
            });
        };

        return service;
    }
]);
