import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

/**
 * Upgrade da diretiva page-title desenvolvida em AngularJS.
 */
@Directive({
    selector: "nms-old-page-title",
})
export class NmsOldPageTitle extends UpgradeComponent {
    @Input() public subtitle?: string;
    @Input() public device?: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super("pageTitleWrapper", elementRef, injector);
    }
}