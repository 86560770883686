import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { webSocket } from "rxjs/webSocket";
import { Subject } from "rxjs";
import { Notification, NmsNotificationService } from "./nms-notification.service";

/**
 * Service que se conecta a um Websocket para recebimento de notificações do usuário.
 */
@Injectable({
    providedIn: "root"
})
export class NmsNotificationWebSocketService {
    private webSocketUrl: string;
    private connectedWebSocket: Subject<Notification>;

    constructor(@Inject(DOCUMENT) private document: Document,
        private nmsNotificationService: NmsNotificationService) { }

    public connect = (username: string): void => {
        const { location: { host } } = this.document;
        this.webSocketUrl = `wss://${host}${username}`;
        this.connectedWebSocket = webSocket(this.webSocketUrl);
        this.connectedWebSocket.subscribe((notification: Notification) => this.onMessage(notification), this.onError, this.onClose);
    }

    public disconnect = (): void => {
        this.connectedWebSocket.unsubscribe();
    }

    private onMessage(notification: Notification): void {
        this.nmsNotificationService.showNotification(notification);
    }

    private onError(err: any): void {
        console.error(err);
    }

    private onClose(): void {
        console.log(`WebSocket ${this.webSocketUrl} was closed.`);
    }
}