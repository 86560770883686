"use strict";

/**
 * @ngdoc controller
 * @name nms.templates.controller:VariableModalController
 * @description Controller for Variable Modal
 */
var app = angular.module("nms.templates");

app.controller("VariableModalController", ["$scope", "$rootScope", "$translate", "ngDialog", "VariableService", "TemplateService",
    "VariableValidatorService", "isTR069Template", "ValidatorService", "DeviceOptionsExtractionService", "otherVariablesNames",
    "variableTypesConfig", "variableToEdit", "VARIABLES", "deviceVariables", "cliCommandOptionExtractionFilterTypes",
    "rulesMatchingModes", "templateUsed", "isNmsTemplate", "$filter", "hasTemplateConfigPermission", "variablesAlreadyAdded",
    "expressionsProvider", "formatCommand", "tooltipVariable", "language",
    function($scope, $rootScope, $translate, ngDialog, VariableService, TemplateService, VariableValidatorService, isTR069Template,
        ValidatorService, DeviceOptionsExtractionService, otherVariablesNames, variableTypesConfig, variableToEdit, VARIABLES,
        deviceVariables, cliCommandOptionExtractionFilterTypes, rulesMatchingModes, templateUsed, isNmsTemplate, $filter,
        hasTemplateConfigPermission, variablesAlreadyAdded, expressionsProvider, formatCommand, tooltipVariable, language) {
        var controller = this;
        $scope.variableExtractionOptions = {
            rulesMatchingModes: rulesMatchingModes,
            hasBlockingParams: null,
            hasInclusionParams: null,
            hasCommands: null,
            deviceVariables: deviceVariables,
            cliCommandOptionExtractionFilterTypes: cliCommandOptionExtractionFilterTypes
        };
        $scope.variablesAlreadyAdded = variablesAlreadyAdded;
        $scope.variableFormData = {};
        $scope.includeAndBlockOptionsModes = VARIABLES.includeAndBlockOptionsModes;
        $scope.variable = {};
        $scope.variableTypes = variableTypesConfig;
        $scope.variable.selectionOptions = [];
        $scope.variable.actionOptions = [];
        $scope.typeVariable = "";
        /** Contém os nomes de todas as outras variáveis do template para validação. */
        $scope.otherVariablesNames = angular.copy(otherVariablesNames);
        $scope.variable.includeAndBlockOptionMode = $scope.includeAndBlockOptionsModes.DO_NOT_GET;
        $scope.templateUsed = templateUsed;
        $scope.isNmsTemplate = isNmsTemplate;
        $scope.isTR069Template = isTR069Template;
        $scope.expressionsProvider = expressionsProvider;
        $scope.formatCommand = formatCommand;
        $scope.tooltipVariable = tooltipVariable;
        $scope.language = language;

        var defaults: any = {
            commands: "",
            extractOptionsFilterResults: false,
            rulesMatchingMode: "ALL",
            extractResultsFromCliResults: false,
            regexToExtractOptions: "",
            useSpecificOccurrences: false,
            specificOccurrences: "",
            ruleOptions: [{ ruleType: "CONTAINS", values: [""] }],
            cliCommandOptionExtractionFilterType: "CONTAINS",
            hasTransformationLogic: false,
            transformationLogicCommands: ""
        };

        $scope.prepareToSave = function () {
            var variableToAdd: any = {};

            variableToAdd.name = $scope.variable.name;
            variableToAdd.globalScope = $scope.variable.globalScope;
            variableToAdd.editable = $scope.variable.editable;
            variableToAdd.mandatory = $scope.variable.mandatory;
            variableToAdd.description = $scope.variable.description;
            variableToAdd.type = $scope.variable.type;
            variableToAdd.enableAutomaticReload =
                $scope.variable.enableAutomaticReload || VARIABLES.automaticReload.DEFAULT_STATE;
            variableToAdd.automaticReloadDelay = $scope.variable.automaticReloadDelay || null;
            variableToAdd.allowAutoSelection = $scope.variable.allowAutoSelection;
            variableToAdd.commands = $scope.variableExtractionOptions.hasCommands ? $scope.variable.commands : "";

            if ($scope.variableExtractionOptions.hasInclusionParams && $scope.variableExtractionOptions.hasBlockingParams) {
                variableToAdd.includeAndBlockOptionMode = $scope.includeAndBlockOptionsModes.INCLUDE_AND_BLOCK;
            } else if ($scope.variableExtractionOptions.hasInclusionParams) {
                variableToAdd.includeAndBlockOptionMode = $scope.includeAndBlockOptionsModes.INCLUDE;
            } else if ($scope.variableExtractionOptions.hasBlockingParams) {
                variableToAdd.includeAndBlockOptionMode = $scope.includeAndBlockOptionsModes.BLOCK;
            } else {
                variableToAdd.includeAndBlockOptionMode = $scope.includeAndBlockOptionsModes.DO_NOT_GET;
            }

            variableToAdd.inclusionParams = $scope.variableExtractionOptions.hasInclusionParams
                ? $scope.variable.inclusionParams
                : null;
            variableToAdd.blockingParams = $scope.variableExtractionOptions.hasBlockingParams
                ? $scope.variable.blockingParams
                : null;

            switch ($scope.variable.type) {
                case "TEXT":
                case "TEXT_LIST":
                    variableToAdd.rangeMin = $scope.variable.txtRangeMin;
                    variableToAdd.rangeMax = $scope.variable.txtRangeMax;
                    variableToAdd.regex = $scope.variable.txtRegex;
                    variableToAdd.defaultValue = $scope.variable.txtDefaultValue;
                    break;
                case "INTEGER":
                case "INTEGER_LIST":
                    variableToAdd.minValue = $scope.variable.intMinValue;
                    variableToAdd.maxValue = $scope.variable.intMaxValue;
                    variableToAdd.step = $scope.variable.intStep;
                    variableToAdd.defaultValue = $scope.variable.intDefaultValue;
                    variableToAdd.baseExponent = $scope.variable.intBaseExponent;
                    break;
                case "SELECTION":
                case "SELECTION_LIST":
                    variableToAdd.options = $scope.variable.selectionOptions;
                    variableToAdd.defaultValue = $scope.variable.selectionDefaultValue;
                    break;
                case "READ_ONLY":
                    variableToAdd.defaultView = $scope.variable.defaultView;
                    variableToAdd.mandatory = false;
                    variableToAdd.editable = false;
                    break;
                case "ACTION":
                    variableToAdd.mandatory = false;
                    variableToAdd.editable = false;
                    variableToAdd.actions = convertActionsToMap($scope.variable.actionOptions);
                    break;
            }

            return variableToAdd;
        };

        $scope.validateVariable = function () {
            var variableToAdd = $scope.prepareToSave();
            if (areVariableSimpleFieldsValid(variableToAdd)) {
                validateVariableComplexFields(variableToAdd);
            }
        };

        const areVariableSimpleFieldsValid = function (variableToAdd) {
            return VariableValidatorService.validateVariable(
                $scope.otherVariablesNames,
                variableToAdd,
                $scope.variableExtractionOptions.hasCommands,
                $scope.variableFormData.hasInterval,
                $scope.variableFormData.hasStep,
                $scope.variableFormData.hasBaseExponent
            );
        };

        const validateVariableComplexFields = function (variableToAdd) {
            VariableValidatorService.validateVariableName(variableToAdd.name).then(function () {
                validateVariableCommands(variableToAdd);
            });
        };

        const validateVariableCommands = function (variableToAdd) {
            var commandValidationRequest = {
                command: variableToAdd.commands,
                templateLanguage: $scope.language
            };
            var syntaxMessageError = "addVariableModal.error.cliCommandsSyntaxError";
            var tabTitle = "addVariableModal.tab.title.commandsVisualization";
            TemplateService.validateCommands(commandValidationRequest, syntaxMessageError, tabTitle)
                .then(function () {
                    var command = variableToAdd.inclusionParams ? variableToAdd.inclusionParams.commands : "";
                    var commandValidationRequest = {
                        command: command,
                        templateLanguage: $scope.language
                    };
                    var syntaxMessageError = "addVariableModal.error.cliCommandsSyntaxError";
                    var tabTitle = "addVariableModal.tab.title.inclusionOptions";
                    return TemplateService.validateCommands(commandValidationRequest, syntaxMessageError, tabTitle);
                })
                .then(function () {
                    var command = variableToAdd.inclusionParams ? variableToAdd.inclusionParams.transformationLogicCommands : "";
                    var commandValidationRequest = {
                        command: command,
                        templateLanguage: $scope.language
                    };
                    var syntaxMessageError = "addVariableModal.error.transformationLogicSyntaxError";
                    var tabTitle = "addVariableModal.tab.title.inclusionOptions";
                    return TemplateService.validateCommands(commandValidationRequest, syntaxMessageError, tabTitle);
                })
                .then(function () {
                    var command = variableToAdd.blockingParams ? variableToAdd.blockingParams.commands : "";
                    var commandValidationRequest = {
                        command: command,
                        templateLanguage: $scope.language
                    };
                    var syntaxMessageError = "addVariableModal.error.cliCommandsSyntaxError";
                    var tabTitle = "addVariableModal.tab.title.blockingOptions";
                    return TemplateService.validateCommands(commandValidationRequest, syntaxMessageError, tabTitle);
                })
                .then(function () {
                    var command = variableToAdd.blockingParams ? variableToAdd.blockingParams.transformationLogicCommands : "";
                    var commandValidationRequest = {
                        command: command,
                        templateLanguage: $scope.language
                    };
                    var syntaxMessageError = "addVariableModal.error.transformationLogicSyntaxError";
                    var tabTitle = "addVariableModal.tab.title.blockingOptions";
                    return TemplateService.validateCommands(commandValidationRequest, syntaxMessageError, tabTitle);
                })
                .then(function () {
                    $scope.confirm(variableToAdd);
                })
                .catch(showFreemarkerSyntaxErrorMessage);
        };

        $scope.clearInterval = function () {
            if ($scope.variableFormData.hasInterval === false) {
                $scope.variable.intMinValue = null;
                $scope.variable.intMaxValue = null;
            }
        };

        $scope.clearStep = function () {
            if ($scope.variableFormData.hasStep !== false) {
                $scope.variableFormData.hasBaseExponent = false;
            }
            $scope.variable.intStep = null;
        };

        $scope.clearBaseExponent = function () {
            if ($scope.variableFormData.hasBaseExponent !== false) {
                $scope.variableFormData.hasStep = false;
            }
            $scope.variable.intBaseExponent = null;
        };

        $scope.cleanInclusionCommandsByTypeChange = function () {
            if (
                $scope.variableExtractionOptions.hasInclusionParams === false &&
                $scope.variable.type !== VARIABLES.ACTION &&
                $scope.variable.oldType === VARIABLES.ACTION
            ) {
                $scope.variable.inclusionParams.commands = "";
            }

            $scope.variable.oldType = $scope.variable.type;
        };

        const copyOptionExtractionParams = function (from) {
            return angular.copy(from) || angular.copy(defaults);
        };

        const resolveCommands = function (params) {
            return _.isEmpty(params.commands) ? getActionInclusionCommandsExample() : params.commands;
        };

        const getActionInclusionCommandsExample = function () {
            const numberActionButtons = 3;
            let commands = $translate.instant("action.command.logic.example.comment") + `\n<#switch SELECTED_ACTION>`;
            for (let i = 1; i <= numberActionButtons; i++) {
                commands +=
                    `\n  <#case "action${i}">` +
                    `\n    ${$translate.instant("action.command.logic.example.comment.button").replace("{0}", i)}` +
                    `\n    some_command` +
                    `\n    <#break>`;
            }

            return `${commands}\n</#switch>`;
        };

        const getTransformationLogicCommand = function (params) {
            return _.isEmpty(params.transformationLogicCommands)
                ? getDefaultTransformationLogicCommand()
                : params.transformationLogicCommands;
        };

        const getDefaultTransformationLogicCommand = function () {
            const transformationLogicComment = $translate.instant("transformation.logic.example.comment");
            return `${transformationLogicComment}\n<#list RESULTS as RESULT>\n\${RESULT}\n</#list>`;
        };

        $scope.init = function () {
            $scope.variable.name = variableToEdit.name ? variableToEdit.name : "";
            $scope.variable.globalScope = _.get(variableToEdit, "globalScope", true);
            $scope.variable.defaultView = _.get(variableToEdit, "defaultView", true);
            $scope.variable.mandatory = _.get(variableToEdit, "mandatory", true);
            $scope.variable.enableAutomaticReload = _.get(variableToEdit,"enableAutomaticReload",
            VARIABLES.automaticReload.DEFAULT_STATE);
            $scope.variable.automaticReloadDelay = _.get(variableToEdit, "automaticReloadDelay", null);
            $scope.variable.editable = _.get(variableToEdit, "editable", true);
            $scope.variable.type = _.get(variableToEdit, "type", "TEXT");
            $scope.variable.description = _.get(variableToEdit, "description", "");
            $scope.variable.commands = _.get(variableToEdit, "commands", "");
            $scope.variable.txtRangeMin = _.get(variableToEdit, "rangeMin", 0);
            $scope.variable.txtRangeMax = _.get(variableToEdit, "rangeMax", null);
            $scope.variable.txtRegex = _.get(variableToEdit, "regex", "");
            $scope.variable.allowAutoSelection = _.get(variableToEdit, "allowAutoSelection", false);
            $scope.variable.intMinValue = $scope.isDefined(variableToEdit.minValue) ? variableToEdit.minValue : null;
            $scope.variable.intMaxValue = $scope.isDefined(variableToEdit.maxValue) ? variableToEdit.maxValue : null;
            $scope.variable.intStep = $scope.isDefined(variableToEdit.step) ? variableToEdit.step : null;
            $scope.variable.intBaseExponent = $scope.isDefined(variableToEdit.baseExponent) ? variableToEdit.baseExponent : null;
            $scope.variable.includeAndBlockOptionMode = _.get(
                variableToEdit,
                "includeAndBlockOptionMode",
                $scope.includeAndBlockOptionsModes.DO_NOT_GET
            );

            var inclusionParamsCopy = copyOptionExtractionParams(variableToEdit.inclusionParams);
            var blockingParamsCopy = copyOptionExtractionParams(variableToEdit.blockingParams);
            inclusionParamsCopy.transformationLogicCommands = getTransformationLogicCommand(inclusionParamsCopy);
            blockingParamsCopy.transformationLogicCommands = getTransformationLogicCommand(blockingParamsCopy);

            $scope.variable.inclusionParams = inclusionParamsCopy;
            $scope.variable.blockingParams = blockingParamsCopy;

            $scope.variableExtractionOptions.hasCommands = !!$scope.variable.commands;
            $scope.variableFormData.hasInterval =
                $scope.isDefined(variableToEdit.minValue) || $scope.isDefined(variableToEdit.maxValue);
            $scope.variableFormData.hasStep = $scope.isDefined(variableToEdit.step) ? true : false;
            $scope.variableFormData.hasBaseExponent = $scope.isDefined(variableToEdit.baseExponent) ? true : false;
            $scope.variable.selectionOptions = variableToEdit.options
                ? angular.copy(variableToEdit.options)
                : $scope.variable.selectionOptions;
            $scope.variableFormData.selectionOption = "";
            $scope.variableFormData.actionOption = { name: "", alert: false };
            $scope.variableFormData.selectionRangeMax = null;
            $scope.variableFormData.selectionRangeMin = null;
            $scope.variableExtractionOptions.hasInclusionParams = $scope.variable.inclusionParams.commands ? true : false;
            $scope.variableExtractionOptions.hasBlockingParams = $scope.variable.blockingParams.commands ? true : false;

            switch ($scope.variable.type) {
                case "TEXT":
                case "TEXT_LIST":
                    $scope.variable.txtDefaultValue = _.get(variableToEdit, "defaultValue", "");
                    $scope.variable.intDefaultValue = null;
                    $scope.variable.selectionDefaultValue = "";
                    break;
                case "INTEGER":
                case "INTEGER_LIST":
                    $scope.variable.intDefaultValue = $scope.isDefined(variableToEdit.defaultValue)
                        ? variableToEdit.defaultValue
                        : null;
                    $scope.variable.txtDefaultValue = "";
                    $scope.variable.selectionDefaultValue = "";
                    break;
                case "SELECTION":
                case "SELECTION_LIST":
                    $scope.variable.selectionDefaultValue = _.get(variableToEdit, "defaultValue", "");
                    $scope.variable.intDefaultValue = null;
                    $scope.variable.txtDefaultValue = "";
                    break;
                case "ACTION":
                    $scope.variable.actionOptions = convertActionsToList(variableToEdit.actions);
                    break;
            }
        };

        $scope.definedVariableType = function () {
            switch ($scope.variable.type) {
                case "TEXT":
                case "TEXT_LIST":
                    return ($scope.typeVariable = "TEXT");
                case "INTEGER":
                case "INTEGER_LIST":
                    return ($scope.typeVariable = "INTEGER");
                case "SELECTION":
                case "SELECTION_LIST":
                    return ($scope.typeVariable = "SELECTION");
                case "READ_ONLY":
                    return ($scope.typeVariable = "READ_ONLY");
                case "ACTION":
                    $scope.variable.inclusionParams.commands = resolveCommands($scope.variable.inclusionParams);
                    $scope.setDefaultAutomaticReloadDelay();
                    return ($scope.typeVariable = "ACTION");
            }
        };

        $scope.isDefined = function(value) {
            return angular.isDefined(value) && value !== null;
        };

        $scope.addOption = function() {
            if (VariableValidatorService.isValidOptionsToAdd($scope.variableFormData.selectionOption, $scope.variable)) {
                var selectionOptionsCopy = angular.copy($scope.variable.selectionOptions);
                if (!isNaN($scope.variableFormData.selectionOption)) {
                    $scope.variableFormData.selectionOption = parseInt($scope.variableFormData.selectionOption);
                }
                selectionOptionsCopy.push($scope.variableFormData.selectionOption);
                $scope.variable.selectionOptions = sortVariablesOptions(selectionOptionsCopy);

                var optionToScroll = $scope.variable.selectionOptions.indexOf($scope.variableFormData.selectionOption) + 1;

                delete $scope.variableFormData.selectionOption;
                reloadScroll(optionToScroll);
            }
        };

        $scope.addAction = function() {
            if (VariableValidatorService.isValidActionsToAdd($scope.variableFormData.actionOption, $scope.variable)) {
                $scope.variable.actionOptions.push($scope.variableFormData.actionOption);
                $scope.variableFormData.actionOption = { name: "", alert: false };

                var optionToScroll = $scope.variable.actionOptions.length - 1;

                reloadScroll(optionToScroll);
            }
        };

        $scope.addRange = function() {
            var optionToScroll;
            if (VariableValidatorService
                .isValidRangeToAdd($scope.variableFormData.selectionRangeMin, $scope.variableFormData.selectionRangeMax)) {
                var toAdd = [];
                var selectionOptionsCopy = angular.copy($scope.variable.selectionOptions);

                if (isValidIntervalSize($scope.variableFormData.selectionRangeMin, $scope.variableFormData.selectionRangeMax)) {
                    for (var i = $scope.variableFormData.selectionRangeMin; i <= $scope.variableFormData.selectionRangeMax; i++) {
                        if (selectionOptionsCopy.indexOf(i.toString()) === -1) {
                            toAdd.push(i.toString());
                        }
                    }

                    if (toAdd.length === 0) {
                        $rootScope.showDialog({
                            translateKey: "addVariableModal.error.noNewValueToAdd"
                        });
                        return;
                    } else if (selectionOptionsCopy.length + toAdd.length > VARIABLES.maxOptionsList) {
                        var params = [];
                        params.push(selectionOptionsCopy.length + toAdd.length);
                        params.push(VARIABLES.maxOptionsList);
                        $rootScope.showDialog({
                            translateKey: "addVariableModal.error.listOptionsIsFull",
                            params: params,
                            paramsInsideMessage: true
                        });
                        return;
                    } else {
                        selectionOptionsCopy = selectionOptionsCopy.concat(toAdd);
                        $scope.variable.selectionOptions = sortVariablesOptions(selectionOptionsCopy);
                        optionToScroll = $scope.variable.selectionOptions.indexOf(toAdd[0]) + 1;
                    }

                    delete $scope.variableFormData.selectionRangeMin;
                    delete $scope.variableFormData.selectionRangeMax;

                    reloadScroll(optionToScroll);
                }
            }
        };

        $scope.removeOption = function (index) {
            $scope.removeVariableFromList(index, $scope.variable.selectionOptions);
        };

        $scope.removeAction = function (index) {
            $scope.removeVariableFromList(index, $scope.variable.actionOptions);
        };

        $scope.removeVariableFromList = function (index, variable) {
            var optionToScroll = variable.indexOf(controller.scrollAdapter.topVisible) + 1;
            variable.splice(index - 1, 1);
            reloadScroll(optionToScroll);
        };

        $scope.hasIncludeBlockCommands = function () {
            return $scope.variable.includeAndBlockOptionMode !== $scope.includeAndBlockOptionsModes.DO_NOT_GET;
        };

        $scope.defineVariableType = function (variable) {
            return VariableService.defineVariableType(variable.type);
        };

        /**
         * Adiciona mensagem ao tooltip para campos bloqueados caso o usuário não tenha
         * a permissao templateConfig ou o seja um template Padrão do NMS.
         * @return {string} mensagem a ser mostrada no tooltip caso o usuário não tenha
         * permissão ou o template seja Padrão do NMS, vazia caso contrário.
         */
        $scope.getTooltipForNmsTemplates = function () {
            if (!hasTemplateConfigPermission) {
                return $translate.instant("templatelisting.userHasNoPermission");
            } else if ($scope.isNmsTemplate) {
                return $translate.instant("templateform.notEditableFieldForNmsTemplate");
            }

            return "";
        };

        $scope.isNmsOrUsedTemplate = function () {
            return !hasTemplateConfigPermission || $scope.isNmsTemplate || templateUsed;
        };

        $scope.optionsDataSource = {
            get (index, count, success) {
                getItemsDataSource(index, count, $scope.variable.selectionOptions, success);
            }
        };

        $scope.actionsDataSource = {
            get (index, count, success) {
                getItemsDataSource(index, count, $scope.variable.actionOptions, success);
            }
        };

        $scope.hideFieldsMandatoryAndEditable = function () {
            return $scope.typeVariable !== VARIABLES.READ_ONLY && $scope.typeVariable !== VARIABLES.ACTION;
        };

        $scope.showAutomaticReload = function () {
            return $scope.typeVariable !== VARIABLES.ACTION && !$scope.isTR069Template;
        };

        $scope.setDefaultAutomaticReloadDelay = function () {
            $scope.variable.automaticReloadDelay = $scope.variable.automaticReloadDelay
                ? $scope.variable.automaticReloadDelay
                : VARIABLES.automaticReload.DEFAULT_DELAY;
        };

        let getItemsDataSource = function(index, count, variable, success) {
            var arrayIndex = index - 1;
            var items = variable.slice(arrayIndex < 0 ? 0 : arrayIndex, arrayIndex + count);
            success(items);
        };

        /**
         * Mostra a modal de alerta de erro de sintaxe freemarker.
         * É necessário somar mais um na linha e coluna, pois os dados que vem são usados nas estruturas
         * internas do ace e nele a primeira linha e coluna são a zero.
         */
        function showFreemarkerSyntaxErrorMessage(messageData) {
            var message = $translate.instant(messageData.syntaxErrorMsgKey)
                .replace("{0}", messageData.row + 1)
                .replace("{1}", messageData.column + 1)
                .concat(" " + messageData.html);
            ValidatorService.showAlertOnTab(messageData.tabTitleKey, message);
        }

        function reloadScroll(indexToScroll) {
            if (angular.isFunction(_.get(controller, "scrollAdapter.reload"))) {
                controller.scrollAdapter.reload(indexToScroll);
            }
        }

        function sortVariablesOptions(options) {
            return $filter("orderByNumbersAndLetters")(options, true);
        }

        function isValidIntervalSize(selectionRangeMin, selectionRangeMax) {
            var rangeIntervalSize = selectionRangeMax - selectionRangeMin + 1;

            if (rangeIntervalSize > VARIABLES.maxOptionsList) {
                var params = [rangeIntervalSize, VARIABLES.maxOptionsList];
                $rootScope.showDialog({
                    translateKey: "addVariableModal.error.rangeIntervalInvalid",
                    params: params,
                    paramsInsideMessage: true
                });
                return false;
            }

            return true;
        }

        function convertActionsToMap(actions) {
            return actions.reduce((actionObject, {name, alert}) => {
                    actionObject[name] = alert;
                    return actionObject;
                }, {});
        }

        function convertActionsToList(actions) {
            return Object.entries(actions).map(([name, alert]) => ({name, alert}));
        }

        $scope.init();
        $scope.definedVariableType();
    }
]);
