"use strict";

/**
 * @name Device
 * App module
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.constant("MODULE", {
    template: "template",
    templateInstance: "templateinstance",
    audit: "audit"
});

app.constant("NMS_STATES", {
    root: "nms",
    login: "nms.login",
    forbidden: "nms.forbidden",
    namCredentials: "nms.namCredentials",
    audit: {
        list: "nms.auditList"
    },
    template: {
        list: "nms.template",
        add: "nms.template.add",
        clone: "nms.template.clone",
        edit: "nms.template.edit"
    },
    templateInstance: {
        list: "nms.templateInstance",
        add: "nms.templateInstance.add",
        clone: "nms.templateInstance.clone",
        edit: "nms.templateInstance.edit"
    },
    scheduler: {
        list: "nms.scheduler"
    },
    manageDevices: "nms.manageDevices",
    deviceDiscover: "nms.deviceDiscover",
    deviceSummary: "nms.deviceSummary",
    dynamicDevice: "nms.dynamicDevice",
    gponOnus: "nms.gponOnus",
    newGponOnus: "nms.newGponOnus",
    management: {
        monitoring: "nms.monitoring",
        users: "nms.users",
        groups: "nms.groups",
        acsCredentials: "nms.acsCredentials",
        managementProtocols: "nms.managementProtocols"
    },
    userManagement: {
        preferences: "nms.preferences"
    },
    cwmpParameters: "nms.cwmpParameters",
    unavailableAcsServer: "nms.unavailableAcsServer",
    cpes: "nms.cpes",
    deviceConfigurationBackup: "nms.deviceConfigurationBackup"
});

app.constant("APPLICATION_BRAND_ICONS", {
    1: "images/logo-dmview-white.png"
});

app.constant("BRAND_LOGOS", {
    1: {url: "images/logo-datacom-white.png", alt: "DATACOM"}
});

app.constant("BRAND_ICONS", {
    1: "images/icons/datacom/favicon.ico",
    defaultBrand: "images/icons/default/favicon.ico"
});

app.constant("POLLING_STATES", {
    STARTED: 10,
    FINISHED: 100,
    ERROR: -1
});

app.constant("DEVICE_STATUS", {
    FINDING: "FINDING",
    UPDATING: "UPDATING",
    REACHABLE: "REACHABLE",
    UNREACHABLE: "UNREACHABLE",
    UNAUTHENTICATED: "UNAUTHENTICATED",
    ADDED: "ADDED",
    NOT_ADDED: "NOT_ADDED",
    UNSUPPORTED: "UNSUPPORTED"
});

app.constant("LOCATION", {
    DEFAULT_PATH: "/",
    DEFAULT_LOCATION_ID: 0
});

app.constant("CUSTOM_DEVICE", {
    devNo: "1",
    devLocalId: "1",
    devId: "",
    hostname: "",
    hostnameToInsert: "",
    vendor: 101,
    modelCode: 50300,
    modelName: "Custom",
    locationId: 0,
    path: "/",
    proxyModelCode: 301,
    proxyVendor: 101,
    firmwareVersion: "0",
    hardwareVersion: "0",
    agentFirmwareVersion: "0",
    xPosition: 0,
    yPosition: 0,
    externalLocation: {},
    internalLocation: {},
    isManageable: false,
    useDefaultCredentials: true
});

app.constant("STATUS_KEYS", {
    TEST: "status.test",
    NORMAL: "status.normal",
    UNKNOWN: "status.unknown",
    MAJOR_FAULT: "status.majorFault",
    MINOR_FAULT: "status.minorFault",
    HW_FAIL: "status.hardwareFail",
    CRITICAL_FAULT: "status.criticalFault",
    DISABLED: "status.disabled",
    DISCOVERED: "status.discovered"
});

app.constant("GPON_ONUS_STATUS_CONFIG", {
    TEST: {
        translationKey: "status.test",
        bgColor: "#ffc800"
    },
    NORMAL: {
        translationKey: "status.normal",
        bgColor: "#1d9d23"
    },
    UNKNOWN: {
        translationKey: "status.unknown",
        bgColor: "#347ac1"
    },
    MAJOR_FAULT: {
        translationKey: "status.majorFault",
        bgColor: "#fa5904"
    },
    MINOR_FAULT: {
        translationKey:  "status.minorFault",
        bgColor: "#dbd20d"
    },
    HW_FAIL: {
        translationKey: "status.hardwareFail",
        bgColor: "#b12525"
    },
    CRITICAL_FAULT: {
        translationKey: "status.criticalFault",
        bgColor: "#b12525"
    },
    DISABLED: {
        translationKey: "status.disabled",
        bgColor: "#650000"
    },
    DISCOVERED: {
        translationKey: "status.discovered",
        bgColor: "#00ffff"
    }
});

app.constant("APPLICATION_STATUS", {
    SUCCESS: "SUCCESS",
    FAIL: "FAIL",
    NOT_ORDERED: "NOT_ORDERED",
    APPLYING: "APPLYING",
    PARTIALLY_APPLIED: "PARTIALLY_APPLIED",
    NOT_APPLIED: "NOT_APPLIED",
    FULLY_APPLIED: "FULLY_APPLIED",
    NOT_STARTED: "NOT_STARTED",
    NOT_EXECUTED: "NOT_EXECUTED",
    NOT_REQUESTED: "NOT_REQUESTED",
    APPLYING_BY_ANOTHER_USER: "APPLYING_BY_ANOTHER_USER"
});

app.constant("POLLING_STATUS", {
    SUCCESS: "SUCCESS",
    FAIL: "FAIL",
    RUNNING: "RUNNING"
});

app.constant("CONNECTIVITY_TEST_STATUS", {
    RUNNING: "RUNNING",
    NOT_AVAILABLE: "NOT_AVAILABLE",
    OK: "OK",
    AUTH_ERROR: "AUTH_ERROR",
    TIMEOUT_ERROR: "TIMEOUT_ERROR",
    DESTINATION_HOST_UNREACHABLE: "DESTINATION_HOST_UNREACHABLE",
    CONNECTION_REFUSED: "CONNECTION_REFUSED",
    DEFAULT_ERROR: "DEFAULT_ERROR",
    CONNECTION_REQUEST_RESPONSE_ERROR: "CONNECTION_REQUEST_RESPONSE_ERROR",
    UNKNOWN_CONNECTION_REQUEST_ERROR: "UNKNOWN_CONNECTION_REQUEST_ERROR",
    INVALID_CONNECTION_REQUEST_URL: "INVALID_CONNECTION_REQUEST_URL",
    IMPOSSIBLE_CONNECTION_REQUEST_TO_EXECUTE_HTTP_GET: "IMPOSSIBLE_CONNECTION_REQUEST_TO_EXECUTE_HTTP_GET",
    IMPOSSIBLE_EXECUTE_CONNECTION_REQUEST: "IMPOSSIBLE_EXECUTE_CONNECTION_REQUEST",
    COULD_NOT_RUN_PROTOCOL_ERROR: "COULD_NOT_RUN_PROTOCOL_ERROR",
    ACS_UNREACHABLE: "ACS_UNREACHABLE",
    CONNECTION_REQUEST_AUTHENTICATION_ERROR: "CONNECTION_REQUEST_AUTHENTICATION_ERROR",
    CPE_MESSAGE_TIMEOUT_ERROR: "CPE_MESSAGE_TIMEOUT_ERROR"
});

app.constant("OPTIONS_EXTRACTION_STATUS", {
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    PARSE_COMMAND_ERROR: "PARSE_COMMAND_ERROR",
    SERVICE_TIMEOUT_ERROR: "SERVICE_TIMEOUT_ERROR",
    DEVICE_APPLICATION_ERROR: "DEVICE_APPLICATION_ERROR",
    UNKNOWN_ERROR: "UNKNOWN_ERROR",
    TRANSFORMATION_LOGIC_ERROR: "TRANSFORMATION_LOGIC_ERROR"
});

app.constant("CPU_USAGE", {
    LOW: 60,
    HIGH: 80
});

app.constant("MEMORY_USAGE", {
    LOW: 70,
    HIGH: 90
});

app.constant("DEVICE_PROPERTIES", {
    DEVICE_CPU_USAGE: "nms-status-api-domain-property-device-MonitoringDeviceValueProperty-CPU_USAGE",
    DEVICE_MEMORY_USAGE: "nms-status-api-domain-property-device-MonitoringDeviceValueProperty-MEM_USAGE",
    DEVICE_CPU_LINK: "nms-status-api-domain-property-device-MonitoringDeviceValueProperty-CPU_USAGE_GRAPHIC_LINK",
    DEVICE_MEMORY_LINK: "nms-status-api-domain-property-device-MonitoringDeviceValueProperty-MEM_USAGE_GRAPHIC_LINK"
});

app.constant("TABLE_PROPERTIES", {
    DEFAULT_START_PAGE: 0,
    DEFAULT_PAGE_SIZE: 20,
    MIN_ROWS_FOR_PAGINATION: 5,
    MAX_ROWS_FOR_PAGINATION: 100
});

// TODO - Mapear outros eventos de broadcast do sistema para centralizar nesta constante.
app.constant("BROADCAST_EVENTS", {
    CHANGE_PAGE_TO: "changePageTo",
    DEVICE_NAME_CHANGED: "deviceNameChanged",
    TABLE_PAGINATION: { CHANGE_PAGE_TO: "changePageTo", CHANGE_PAGE_TO_ROW_POSITION: "changePageToRowPosition" },
    UPDATE_TEMPLATE_APPLICATION_STATUS: "updateTemplateApplicationStatus",
    HTTP_ERROR_MODAL_CLOSED: "httpErrorModalClosed"
});

app.constant("TABLE_TEMPLATE_URL", {
    INFO_CONFIG: {
        SELECTION_CHECKBOX_LIST_HEADER: "templates/components/ui/selection-checkbox/selection-checkbox-list-header.html",
        TABLE_HEADER: "/templates/features/dynamic-device/domain/yang/list/list-table-header-template.html"
    }
});

app.constant("AUTHENTICATION_STATUS", {
    NON_AUTHENTICATED: "NON_AUTHENTICATED",
    AUTHENTICATED: "AUTHENTICATED",
    NON_SECURE_REQUEST: "NON_SECURE_REQUEST",
    INVALID_CREDENTIALS: "INVALID_CREDENTIALS",
    OMITTED_CREDENTIALS: "OMITTED_CREDENTIALS"
});

app.constant("DEVICE_LIST", {
    PREFERENCE_KEYS: ["showOnlyDmOS", "globalFilter"],
    DEVICE_DMOS: "DmOS",
    DEVICE_NETCONF: "NETCONF",
    DEFAULT_LOCATION: "/ (root)"
});

app.constant("WEBSOCKET_STATES", {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
});

app.constant("SNMP_DATA", {
    V3_PASS_LENGTH: {
        MIN: 8,
        MAX: 64
    },
    SNMP_VERSION: {
        V1: "V1",
        V2C: "V2C",
        V3: "V3"
    },
    AUTH_TYPE: {
        MD5: "MD5",
        SHA: "SHA"
    },
    CYPHER: {
        AES: "AES",
        DES: "DES",
        NONE: "NONE"
    }
});

app.constant("DEVICE_PERMISSION", {
    MANAGE: {
        databaseValue: 2,
        order: 3,
        key: "manage"
    },
    TEST: {
        databaseValue: 3,
        order: 2,
        key: "test"
    },
    VIEW: {
        databaseValue: 1,
        order: 1,
        key: "view"
    },
    NOTHING: {
        databaseValue: 0,
        order: 0,
        key: "nothing"
    }
});

app.constant("SYSTEM_USER", {
    DATACOM: "DATACOM",
    ADMINISTRATOR: "ADMINISTRATOR"
});

app.constant("LDAP_MODE", {
    AUTHENTICATION: "authentication",
    AUTHORIZATION: "authorization"
});

app.constant("RESTRICTION_RULE_MATCHING_TYPES", {
    CONTAINS: {type: "STRING", translationKey: "restrictions.rule.type.CONTAINS"},
    DOES_NOT_CONTAIN: {type: "STRING", translationKey: "restrictions.rule.type.DOES_NOT_CONTAIN"},
    STARTS_WITH: {type: "STRING", translationKey: "restrictions.rule.type.STARTS_WITH"},
    ENDS_WITH: {type: "STRING", translationKey: "restrictions.rule.type.ENDS_WITH"},
    EQUALS: {type: "STRING", translationKey: "restrictions.rule.type.EQUALS"},
    NOT_EQUALS: {type: "STRING", translationKey: "restrictions.rule.type.NOT_EQUALS"}
});

app.constant("RESTRICTION_RULE_MATCHING_MODES", {
    ALL: {translationKey: "restrictions.rule.matching.mode.field.ALL"},
    ONE_OF_THE: {translationKey: "restrictions.rule.matching.mode.field.ONE_OF_THE"}
});

app.constant("TEMPLATE_PERMISSION_FIELDS", {
    NAME: {translationKey: "template.field.name"},
    DESCRIPTION: {translationKey: "template.field.description"},
    KEYWORD: {translationKey: "template.field.keyword"}
});

/**
 * Cadastro de todos os códigos de erro dos serviços do nms-services.
 * Os códigos devem ser únicos, e devem ser agrupados por serviço ou lib.
 *
 * Deve se manter alinhada com a classe br.ind.datacom.nms.services.standalone.infra.ErrorCodes
 */
// TODO[TK-48987]: Substituir os usos pelas constantes definidas em error-response-handler.model.ts
app.constant("NMS_ERRORS", {
    /* Device Access Errors */
    GENERIC_DEVICE_ERROR: 1,
    UNREACHABLE_DEVICE: 2,
    /* Database Errors */
    DATABASE_ERROR: 101,
    /* Data collector exceptions  */
    TOO_MANY_RESULTS_FOUND: 201,
    /* ACS Server */
    ACS_CONNECTION_REQUEST_ERROR: 300,
    ACS_BAD_CONDITIONAL_COMMAND_ERROR: 301,
    ACS_COULD_NOT_ACCESS_SERVER_ERROR: 302,
    ACS_CONNECTION_REQUEST_AUTHENTICATION_ERROR: 303,
    CPE_MESSAGE_TIMEOUT_ERROR: 304,
    ACS_UNEXPECTED_ERROR: 305,
    UNEXPECTED_CPE_MESSAGE: 306,
    ACS_DUPLICATED_USER: 307,

    /* NMS-Security */
    INVALID_CAPTCHA: 350
});

/* Cadastro das opções para o download disponíveis */
app.constant("CWMP_DOWNLOAD_FILE_TYPE", {
    FIRMWARE_UPGRADE_IMAGE: {type: "FIRMWARE_UPGRADE_IMAGE_TYPE", description: "1 Firmware Upgrade Image"},
    WEB_CONTENT: {type: "WEB_CONTENT_TYPE", description: "2 Web Content"},
    VENDOR_CONFIGURATION_FILE: {type: "VENDOR_CONFIGURATION_FILE_TYPE", description: "3 Vendor Configuration File"},
    TONE_FILE: {type: "TONE_FILE_TYPE", description: "4 Tone File"},
    RINGER_FILE: {type: "RINGER_FILE_TYPE", description: "5 Ringer File"}
});

/** Cadastro de configurações disponíveis para aplicação de comandos */
app.constant("APPLICATION_COMMANDS", {
    MAX_EQUIPMENTS_TO_SHOW_RESULT_DETAILS: 49
});

/** Equipamentos que não suportam SSH nem Telnet */
app.constant("DEVICES_MODELS_WITHOUT_CLI", {
    DM936: "DM936 EDFA"
});