"use strict";

/**
 * Identifier generator filters.
 *
 * @author andre.pires
 */

var app = angular.module("nms");

app.filter("nodeIdentifier", ["DataPathService",
    function(DataPathService) {
        return function(node, data) {
            var elementName = data.elementName;
            var pathKeys = data.pathKeys;
            var isTransient = data.isTransient;
            var path = node.paths.dataJsonPath;

            if (!isTransient && !_.isEmpty(pathKeys)) {
                path = DataPathService.getNodePath(pathKeys, node);
            }

            return path + "_" + elementName;
        };
    }]
);
