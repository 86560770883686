/**
* @ngdoc directive
* @name nms.templates.directive:preventscroll
* @description Diretiva para manter a posição do scroll enquanto a aplicação do templates/equipamento é feita.
*/
"use strict";

app.directive("preventscroll", ["$timeout", function($timeout) {
    return {
        link: function(scope, element, attrs) {
            var newElement;
            var newElementOffset;
            var elementScrollTop;
            var elementToScroll;
            var toScroll;
            var eventType;
            var needScroll = false;

            var scrollFromTop = function() {
                var el = angular.element(element[0]);
                if ($("#"+newElement)[0]) {
                    newElementOffset = el.find("#"+newElement)[0].offsetTop - 30;
                }

                el.triggerHandler("scroll");
                element.bind("scroll mousewheel", function(e) {
                    elementScrollTop = e.currentTarget.scrollTop;
                    eventType = e.type;

                    var activeArea = el[0].scrollHeight - el[0].offsetHeight;
                    if (activeArea - elementScrollTop < el[0].offsetHeight && newElementOffset > elementScrollTop && elementScrollTop > 10) {
                        needScroll = true;
                    }

                    if (newElementOffset < elementScrollTop || needScroll && eventType !== "mousewheel") {
                        if ($("#"+newElement)[0]) {
                            var elementAdded = el.find("#"+newElement);
                            toScroll = elementAdded[0].offsetHeight + 20;
                        }
                        elementToScroll = parseInt(toScroll) + parseInt(elementScrollTop);
                    }
                });

                if (eventType !== "mousewheel") {
                    if (newElementOffset < elementScrollTop || needScroll) {
                        $(element[0]).scrollTop(elementToScroll);
                    } else if (elementScrollTop <= 0 && newElementOffset <= 10 && newElement) {
                        $(element[0]).scrollTop(toScroll);
                    }
                    toScroll = 0;
                }
            };

            var scrollToTop = function() {
                newElementOffset = 0;
                elementScrollTop = 0;
                elementToScroll = 0;
                toScroll = 0;
                needScroll = false;
                newElement = "";
                $(element[0]).scrollTop(10);
            };

            scope.$on("scrollFromTop", function(event, args) {
                event.preventDefault();
                newElement = args.params;
                $timeout(scrollFromTop, 10);
            });

            scope.$on("scrollToTop", function(event) {
                event.preventDefault();
                $timeout(scrollToTop, 10);
            });
        }
    };
}]);
