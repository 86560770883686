/**
 * @name Device
 * App module
 *
 * @author icaro.damiani, patrick.bard
 */
"use strict";

var app = angular.module("nms.dynamicDevice", [
    "angularUtils.directives.dirPagination",
    "ngTable",
    "resizer",
    "ngWebSocket",
    "darthwade.dwLoading",
    "ngJSONPath",
    "timer"
]);

app.constant("YangStatements", {
    CONTAINERS: "containers",
    LEAVES: "leaves",
    LEAFLISTS: "leaf-lists",
    LISTS: "lists",
    CHOICES: "choices"
});

app.constant("YangConventions",
    (function() {
        var yangStatements = angular.injector(["ng", "nms.dynamicDevice"]).get("YangStatements");
        return {
            DATA_NODES: [yangStatements.CONTAINERS, yangStatements.LISTS, yangStatements.LEAVES, yangStatements.LEAFLISTS],
            INTERIOR_NODES: [yangStatements.CONTAINERS, yangStatements.LISTS, yangStatements.CHOICES],
            LEAF_NODES: [yangStatements.LEAVES, yangStatements.LEAFLISTS]
        };
    })()
);

app.constant("NodeValues", {
    FIELD_WITHOUT_VALUE: "Field without value",
    PRESENT_FIELD: "present",
    NOT_PRESENT_FIELD: "not present"
});

app.config(["$rootScopeProvider", "$translatePartialLoaderProvider",
    function($rootScopeProvider, $translatePartialLoader) {
        // TODO: Tamanho máximo de $digest cycles aumentado para evitar logs de erro
        //       Encontrar uma maneira melhor de lidar com os $digest cycles
        //       É um bug conhecido do angular, visto num caso muito semelhante ao nosso:
        //       Uma árvore de templates recursivos (https://github.com/angular/angular.js/issues/6440)
        $rootScopeProvider.digestTtl(200);

        $translatePartialLoader.addPart("features/dynamic-device");
    }
]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);

app.run(["$rootScope", "$window", "NesDataCacheService", "$state", "NMS_STATES", "TreeSelectionManager",
    function($rootScope, $window, nesDataCacheService, $state, NMS_STATES, TreeSelectionManager) {
        TreeSelectionManager.clear();
        var unregisterStateChanged = $rootScope.$on("$stateChangeSuccess", function(event, to) {
            if (to.name === NMS_STATES.login || to.name === NMS_STATES.manageDevices) {
                nesDataCacheService.clear();
            }
        });

        $rootScope.$on("$destroy", function() {
            unregisterStateChanged();
        });

        $window.onbeforeunload = function() {
            if ($state.current.name === NMS_STATES.dynamicDevice && !$state.current.ignoreConfirmation) {
                return "By leaving this page, not applied configurations will be lost.";
            }
        };

        $window.onfocus = function() {
            $rootScope.$digest();
        };
    }
]);


