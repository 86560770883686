"use strict";

/**
* @ngdoc controller
* @name nms.controller:PollingController
* @description Controller para componente de polling (directive#polling).
*/
var app = angular.module("nms");

app.controller("PollingController", ["$scope", "PollingService", "$interval", "$translate",
    function($scope, PollingService, $interval, $translate) {
        var durationInSecondsMask = $translate.instant("duration.in.seconds");
        var lastPollingTimeMessageMask = $translate.instant("device.polling.status.lastPollingTime");
        var loadingDataFromDeviceMessage = $translate.instant("device.polling.status.loadingDataFromDevice");
        var pollingStart;

        var setPollingTime = function() {
            var diff = (new Date()).valueOf() - pollingStart;
            $scope.device.pollingTime = Math.floor(diff/1000);
        };

        var startPollingCounter = function() {
            pollingStart = (new Date()).valueOf();
            $scope.pollingTimer = $interval(setPollingTime, 100);
        };

        var stopPollingCounter = function() {
            if ($scope.pollingTimer) {
                $interval.cancel($scope.pollingTimer);
            }
        };

        $scope.getPollingProgressMessage = function() {
            var message = loadingDataFromDeviceMessage + " " + durationInSecondsMask.replace("{0}", $scope.device.pollingTime);
            var lastSuccessfulPollingDuration = $scope.device.lastSuccessfulPollingDuration;
            if (lastSuccessfulPollingDuration) {
                var lastPollingTimeMessage = lastPollingTimeMessageMask.replace("{0}", lastSuccessfulPollingDuration);
                message += " " + lastPollingTimeMessage;
            }

            return message;
        };

        $scope.requestPolling = function(device) {
            PollingService.requestPollings([device]);
        };

        $scope.$watch("device.isPollingRunning", function(newIsPollingRunning) {
            if (newIsPollingRunning) {
                startPollingCounter();
            } else {
                stopPollingCounter();
            }
        });

        $scope.$on("$destroy", function() {
            stopPollingCounter();
        });
    }
]);
