"use strict";

/**
 * @ngdoc controller
 * @name nms.components.autoUpdater:AutoUpdaterController
 * @description
 *
 * # AutoUpdaterController
 *
 * Responsável por controlar o componente de atualização automática.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.controller("AutoUpdaterController", ["$scope", "AutoUpdaterService", "$translate",
    function($scope, AutoUpdaterService, $translate) {
        $scope.model = AutoUpdaterService.getAutoUpdaterModel();

        $scope.getUpdateInterval = function() {
            return AutoUpdaterService.getUpdateInterval();
        };

        $scope.getIsUpdating = function() {
            return AutoUpdaterService.getIsUpdating();
        };

        $scope.getLastSuccessfulPollingDuration = function() {
            return AutoUpdaterService.getLastSuccessfulPollingDuration();
        };

        $scope.setLastSuccessfulPollingDuration = function(time) {
            AutoUpdaterService.setLastSuccessfulPollingDuration(time)
        };

        $scope.hasLastSuccessfulPollingDuration = function() {
            var lastSuccessfulPollingDuration = AutoUpdaterService.getLastSuccessfulPollingDuration();
            return !(_.isNull(lastSuccessfulPollingDuration) || _.isUndefined(lastSuccessfulPollingDuration));
        };

        $scope.getLastUpdateString = function() {
            var translation = $translate.instant($scope.model.lastUpdateTextTranslateKey);

            return  translation.replace("{0}", AutoUpdaterService.getLastSuccessfulPollingDuration());
        };

        $scope.shouldShowCountdown = function() {
            return !$scope.model.message && !AutoUpdaterService.getIsUpdating();
        };

        $scope.shouldShowBlockMessage = function() {
            return !!$scope.model.message && !AutoUpdaterService.getIsUpdating();
        };

        $scope.isUpdateRequestBlocked = function() {
            return !$scope.model.updateButtonEnabled || AutoUpdaterService.getIsUpdating();
        };

        $scope.requestUpdate = function() {
            // TODO: Transformar numa executeUpdateFunction em uma promise para que seja possível
            // chamar aqui tanto o AutoUpdaterService.startUpdating(), quanto o AutoUpdaterService.finishedUpdating()
            AutoUpdaterService.executeUpdateFunction();
        };

        $scope.$on("$destroy", function() {
            AutoUpdaterService.reset();
        });
    }
]);
