"use strict";

var app = angular.module("nms.dynamicDevice");

app.service("NodeStatusService", ["WhenVerifierService", "$rootScope", "DomainHandlerService", "NodeInfoCache", "$timeout",
    "DataPathService",
    function(WhenVerifierService, $rootScope, DomainHandlerService, NodeInfoCache, $timeout,
        DataPathService) {
        /* global buckets */
        /* eslint-disable new-cap */
        var nodeStatus = buckets.Dictionary(angular.toJson);
        var listeners = buckets.MultiDictionary(angular.toJson, _.isEqual);
        /* eslint-enable new-cap */

        var createKey = function(schemaPath, dataPath, pathKeys) {
            return {
                schemaPath: schemaPath,
                dataPath: dataPath,
                pathKeys: pathKeys
            };
        };

        var unregister = function(key, listener) {
            listeners.remove(key, listener);
            if (_.isEmpty(listeners.get(key))) {
                nodeStatus.remove(key);
            }
        };

        var register = function(schemaPath, dataPath, pathKeys, listener) {
            var key = createKey(schemaPath, dataPath, pathKeys);
            listeners.set(key, listener);

            var currentStatus = nodeStatus.get(key);
            if (currentStatus) {
                notifyListener(key, listener, currentStatus, false);
            } else {
                processStatus(key);
            }

            return function() {
                unregister(key, listener);
            };
        };

        var notifyListener = function(key, listener, newStatus, refreshData) {
            $timeout(function() {
                if (refreshData) {
                    var pathWithKeys = DataPathService.getPathWithKeys(key.dataPath, key.pathKeys);
                    var dataNode = DomainHandlerService.getDataNode(pathWithKeys);
                    listener(newStatus, refreshData, dataNode);
                } else {
                    listener(newStatus, refreshData);
                }
            });
        };

        var notify = function(key, oldStatus, newStatus, refreshData) {
            _.forEach(listeners.get(key), function(listener) {
                notifyListener(key, listener, newStatus, refreshData);
            });
        };

        var processStatus = function(key) {
            var currentStatus = nodeStatus.get(key);

            var schemaNode = DomainHandlerService.getSchemaNode(key.schemaPath);
            var cachedNode = NodeInfoCache.getNode(DomainHandlerService.getPath(schemaNode));

            var whenStatus = WhenVerifierService.resolve(schemaNode, key.pathKeys, cachedNode);
            var newStatus: any = {
                isVisible: whenStatus.visible
            };
            var refreshData = whenStatus.dataChanged;

            if (!_.isEqual(currentStatus, newStatus)) {
                nodeStatus.set(key, newStatus);
                notify(key, currentStatus, newStatus, refreshData);
            }
        };

        var getCurrentVisibility = function(node, pathKeys) {
            if (WhenVerifierService.hasWhenCondition(node)) {
                var key = createKey(node.paths.schemaJsonPath, node.paths.dataJsonPath, pathKeys);
                var currentStatus = nodeStatus.get(key);

                return currentStatus ? currentStatus.isVisible : null;
            }

            return true;
        };

        var unregisterWhenConditionsListener = $rootScope.$on("when-condition-data-updated", function() {
            _.forEach(listeners.keys(), function(key) {
                processStatus(key);
            });
        });

        $rootScope.$on("$destroy", function() {
            unregisterWhenConditionsListener();
        });

        return {
            register: register,
            getCurrentVisibility: getCurrentVisibility
        };
    }
]);
