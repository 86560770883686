"use strict"

import { LicenseModalComponent } from "./../ng2/app/modules/license/license-modal.component";

/**
 * @name Device
 * App module
 *
 * @author patrick.bard
 */

var app = angular.module("nms", [
    "angularUtils.directives.dirPagination",
    "angularXRegExp",
    "checklist-model",
    "darthwade.dwLoading",
    "daterangepicker",
    "ng-sortable",
    "ngAnimate",
    "ngAria",
    "ngCookies",
    "ngDialog",
    "ngJSONPath",
    "ngMessages",
    "ngResource",
    "ngRoute",
    "ngSanitize",
    "ngTable",
    "ngTagsInput",
    "ngTouch",
    "ngWebSocket",
    "pascalprecht.translate", // angular-translate
    "pasvaz.bindonce",
    "RecursionHelper",
    "resizer",
    "restangular",
    "tmh.dynamicLocale", // angular-dynamic-locale
    "toastr",
    "ui.ace",
    "ui.bootstrap",
    "ui.indeterminate",
    "ui.router",
    "ui.scroll",
    "ui.scroll.grid",
    "ui.toggle",
    "uiRouterStyles",
    "ngStomp",
    "cb.x2js",
    "selectize",
    "cfp.hotkeys",
    "treeGrid",
    // NMS Modules
    // Common Modules
    "AuthenticationModule",
    "LicenseModule",
    // Features
    "nms.audit",
    "nms.templates",
    "nms.deviceSummary",
    "nms.deviceDiscover",
    "nms.dynamicDevice",
    "nms.manageDevices",
    "nms.management",
    "nms.gponOnus",
    "nms.cwmpParameters",
    "nms.downgraded",
    "nms.cpes",
    "nms.scheduler",
    "nms.deviceConfigurationBackup"
]);

app.config([
    "$translateProvider",
    "$windowProvider",
    function ($translateProvider, $windowProvider) {
        const $window = $windowProvider.$get();
        let languageUnderline;
        let language;

        if ($window.navigator.languages) {
            language = $window.navigator.languages[0];
        } else {
            language = $window.navigator.language;
        }

        if (!language) {
            language = "en-US";
        }

        languageUnderline = language.replace("-", "_");

        $translateProvider.useMissingTranslationHandlerLog();
        $translateProvider.useLoader("$translatePartialLoader", {
            urlTemplate: "locale/{part}/locale-{lang}.json"
        });

        var languagesAllow = ["en_US", "pt_BR"];
        if (languagesAllow.indexOf(languageUnderline) >= 0) {
            $translateProvider.preferredLanguage(languageUnderline);
        } else {
            $translateProvider.preferredLanguage(languagesAllow[0]);
        }

        $translateProvider.useSanitizeValueStrategy(null);
    }
]);

app.config([
    "$translatePartialLoaderProvider",
    function ($translatePartialLoaderProvider) {
        $translatePartialLoaderProvider.addPart("shared");
        $translatePartialLoaderProvider.addPart("components/ui/auto-updater");
        $translatePartialLoaderProvider.addPart("components/ui/polling");
        $translatePartialLoaderProvider.addPart("components/ui/connectivity-test");
        $translatePartialLoaderProvider.addPart("components/ui/utils");
        $translatePartialLoaderProvider.addPart("components/ui/nms-auto-updater");
        $translatePartialLoaderProvider.addPart("components/ui/nms-counter");
        $translatePartialLoaderProvider.addPart("components/ui/nms-info-config-filter");
        $translatePartialLoaderProvider.addPart("components/ui/nms-notification");
    }
]);

app.config([
    "$provide",
    function ($provide) {
        $provide.decorator("uibTypeaheadMatchDirective", function ($delegate) {
            var directive = $delegate[0];

            directive.templateUrl = "templates/components/ui/custom-directives/typeahead-match.html";

            return $delegate;
        });

        $provide.decorator("uibTypeaheadPopupDirective", function ($delegate) {
            var directive = $delegate[0];

            directive.templateUrl = "templates/components/ui/custom-directives/typeahead-popup.html";

            return $delegate;
        });
    }
]);

app.config([
    "$urlRouterProvider",
    "$httpProvider",
    "toastrConfig",
    "$logProvider",
    "ngDialogProvider",
    function ($urlRouterProvider, $httpProvider, toastrConfig, $logProvider, ngDialogProvider) {
        $httpProvider.defaults.useXDomain = true;
        $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

        angular.extend(toastrConfig, {
            allowHtml: true,
            positionClass: "toast-bottom-right",
            messageClass: "toast-message1",
            tapToDismiss: true,
            timeOut: 5000,
            templates: {
                toast: "templates/components/ui/popups/toastr-template.html",
                progressbar: "directives/progressbar/progressbar.html"
            }
        });

        ngDialogProvider.setForceBodyReload(true);
        ngDialogProvider.setOpenOnePerName(true);
        ngDialogProvider.setDefaults({
            appendClassName: "fixed"
        });

        $logProvider.debugEnabled(true);
    }
]);

app.run([
    "$rootScope",
    "$filter",
    "$window",
    "$state",
    "$translate",
    "ngDialog",
    "toastr",
    "WindowService",
    "NMS_STATES",
    "NMS_FEATURES",
    function ($rootScope, $filter, $window, $state, $translate, ngDialog, toastr, WindowService, NMS_STATES, NMS_FEATURES) {
        $rootScope.NMS_STATES = NMS_STATES;
        $rootScope.NMS_FEATURES = NMS_FEATURES;

        const getDialogTitle = function (options) {

            if (options.isConfirm) {
                return $translate.instant("popups.confirm.confirm");
            }

            return getAlertTitlekey(options);
        };

        const getAlertTitlekey = function(options) {
            return options && options.title || getAlertTitle(options);
        }

        const getAlertTitle = function(options) {
            let typeName;
            switch (options.type) {
                case "error":
                    typeName = $translate.instant("popups.alert.error");
                    break;
                case "alert":
                    typeName = $translate.instant("popups.alert.alert");
                    break;
                case "information":
                    typeName = $translate.instant("popups.alert.information");
                    break;
                default:
                    typeName = $translate.instant("popups.alert.error");
                    break;
            }
            return typeName;
        }

        var openDialog = function (options) {
            var scope = $rootScope.$new();
            _.assign(scope, options);

            var dialogConfiguration: any = {
                scope: scope
            };

            _.assign(dialogConfiguration, options.ngDialogOptions);

            if (options.isConfirm) {
                dialogConfiguration.template = "templates/components/ui/popups/confirm.html";
                return ngDialog.openConfirm(dialogConfiguration);
            } else {
                dialogConfiguration.template = "templates/components/ui/popups/alert.html";
                return ngDialog.open(dialogConfiguration);
            }
        };

        /**
         * Função reponsável por exibir popups no sistema.
         *
         * Chamada única que recebe um objeto com todas configurações necessárias para poder renderizar corretamente a popup.
         *
         * Opções possíveis
         *      type:                Tipo do popup, que seŕa traduzido e apresentado no título.
         *                           Possíveis valores, "alert" e "error".
         *                           Caso nenhum valor for passado, assume o valor "error"
         *      message:             Mensagem pura, que não passará pro i18n (Tem maior prioridade quando translateKey
         *                           também for passado)
         *      translateKey:        String que correnponde a uma chave a ser traduzida
         *      params:              Parâmetros para serem exibidos na popup
         *      paramsInsideMessage: Quando TRUE, utiliza outra forma de apresentar os parametros passados em "params".
         *                           Default é FALSE
         *      maxChars:            Quantidade máxima de caractéres, utilizada apenas quando "params" for passado
         *      isConfirm:           Quando TRUE, gera uma popup de confirmação. Default é FALSE, gerando uma popup de informação
         *      ngDialogOptions:     Objeto que pode conter as propriedade disponibilizadas pelo próprio ngDialog
         *
         * @param {object} options opções da dialog
         *
         * @return {object} objeto que identifica e permite fechar a dialog criada
         */
        $rootScope.showDialog = function (options) {
            var msg = angular.isString(options.message) ? options.message : $translate.instant(options.translateKey);

            if (options.params) {
                if (options.paramsInsideMessage) {
                    for (var i = 0; i < options.params.length; i++) {
                        msg = msg.replace("{" + i + "}", options.params[i]);
                    }
                } else {
                    for (var j = 0; j < options.params.length; j++) {
                        msg += "<br>" + $filter("cutString")(options.params[j], options.maxChars);
                    }
                }
            }

            options.message = msg;
            options.title = getDialogTitle(options);

            return openDialog(options);
        };

        /**
         * Função reponsável por exibir popups no sistema quando possuem mensagem para singular e plural, podendo possuir
         * parâmetros dentro do head da mensagem e lista de parâmetros.
         *
         * Chamada única que recebe um objeto com todas configurações necessárias para poder renderizar corretamente a popup.
         *
         * Opções possíveis
         *      type:                           Tipo do popup, que seŕa traduzido e apresentado no título.
         *                                      Possíveis valores, "alert" e "error".
         *                                      Caso nenhum valor for passado, assume o valor "error"
         *      translateObjs:                  Objeto que recebe as keys da mensagem (singular e plural),
         *                                      boolean que define se é plural ou não e parametros para serem substituídos
         *      translateObjs.pluralKey:        Key da mensagem para plural
         *      translateObjs.singularKey:      Key da mensagem para singular
         *      translateObjs.isPlural:         Boolean que define se a mensagem deve ser singular ou plural
         *      translateObjs.insideMsgParams:  Paramêtros para serem utilizados na construção da mensagem
         *      listParams:                     Parâmetros para serem exibidos na popup
         *      maxChars:                       Quantidade máxima de caractéres, utilizada apenas quando "params" for passado
         *      isConfirm:                      Quando TRUE, gera uma popup de confirmação.
         *                                      Default é FALSE, gerando uma popup de informação
         *
         * @param {object} options opções da dialog
         *
         * @return {object} objeto que identifica e permite fechar a dialog criada
         */
        $rootScope.showDialogSingularOrPlural = function (options) {
            var msg = "";
            var msgArray = [];

            options.translateObjs.forEach(function (object) {
                var text;
                if (object.isPlural) {
                    text = $translate.instant(object.pluralKey);
                } else {
                    text = $translate.instant(object.singularKey);
                }

                if (object.insideMsgParams) {
                    for (var i = 0; i < object.insideMsgParams.length; i++) {
                        text = text.replace("{" + i + "}", object.insideMsgParams[i]);
                    }
                }

                msgArray.push(text);
            });

            msg = msgArray.join("<br>");

            if (options.listParams) {
                for (var j = 0; j < options.listParams.length; j++) {
                    msg += "<br>" + $filter("cutString")(options.listParams[j], options.maxChars);
                }
            }

            options.message = msg;
            options.title = getDialogTitle(options);

            return openDialog(options);
        };

        $rootScope.toastInfo = function (translateKey, params) {
            var message = $translate.instant(translateKey);
            if (params) {
                for (var i = 0; i < params.length; i++) {
                    message = message.replace("{" + i + "}", params[i]);
                }
            }
            toastr.info(message);
        };

        $rootScope.toastError = function (translateKey, parameters) {
            parameters = parameters || {};
            var message = $translate.instant(translateKey);
            var toastParameters: any = {
                timeOut: 0,
                extendedTimeOut: 0
            };

            _.merge(toastParameters, parameters);

            toastr.error(message, toastParameters);
        };

        var unregisterStateChanged = $rootScope.$on("$stateChangeSuccess", function () {
            var updateTitle = function () {
                var productJsonString = $window.atob(WindowService.getInstalledProduct());
                var productName = angular.fromJson(productJsonString).productName;

                if ($state.current.data.pageTitle) {
                    $rootScope.header = $translate.instant($state.current.data.pageTitle) + " - " + productName;
                } else {
                    $rootScope.header = productName;
                }
            };

            if (WindowService.getInstalledProduct()) {
                updateTitle();
            } else {
                var unregisterInstalledProductWatcher = $rootScope.$watch(
                    function () {
                        return WindowService.getInstalledProduct();
                    },
                    function (newProduct, oldProduct) {
                        if (newProduct === oldProduct || newProduct == null) {
                            return;
                        }
                        updateTitle();
                        unregisterInstalledProductWatcher();
                    }
                );
            }
        });

        var unregisterAppendTitle = $rootScope.$on("title:append", function (event, subtitle) {
            if (subtitle) {
                $rootScope.header = $translate.instant(subtitle) + " - " + $rootScope.header;
            }
        });

        $rootScope.$on("$destroy", function () {
            unregisterStateChanged();
            unregisterAppendTitle();
        });

        $window.onbeforeunload = function () {
            if ($state.current.name === "device" && !$state.current.ignoreConfirmation) {
                return "By leaving this page, not applied configurations will be lost.";
            }
        };

        $window.onfocus = function () {
            $rootScope.$digest();
        };
    }
]);

app.run([
    "$rootScope",
    "$injector",
    "LicenseService",
    function ($rootScope, $injector, LicenseService) {
        const unregisterLicenseReceived = $rootScope.$on("LICENSE_RECEIVED", function () {
            LicenseService.refreshFeaturesLicense();
            LicenseService.refreshInstalledProduct();
            LicenseService.loadBrandingIcon();
            LicenseService.loadBrandingApplicationIcon();
        });

        if ($rootScope.isAuthenticated()) {
            LicenseService.loadInstalledProduct();
            LicenseService.loadBrandingIcon();
        }

        $rootScope.showLicenseModal = function () {
            const modalFactory = $injector.get("ModalFactory");
            modalFactory.openAsyncModal(LicenseModalComponent, {});
        };

        $rootScope.$on("$destroy", function () {
            unregisterLicenseReceived();
        });

        $rootScope.setFavicon = function () {
            LicenseService.setFaviconByBranding();
        };
    }
]);

app.run([
    "$translate",
    function ($translate) {
        $translate.refresh();
    }
]);
