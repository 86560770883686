"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.EditUserRequestConverter
* @description - Conversor do modelo de dados da modal de edição de dados de usuário logado.
*/
var app = angular.module("nms.management");

app.service("EditUserRequestConverter", [
    function() {
        const service: any = {};

        service.convert = function(userAccountForm) {
            return {
                currentPassword: userAccountForm.currentPassword,
                user: {
                    username: userAccountForm.user,
                    password: userAccountForm.password,
                    name: userAccountForm.name,
                    phone: userAccountForm.phone,
                    email: userAccountForm.email
                }
            };
        };

        return service;
    }
]);
