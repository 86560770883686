"use strict";

/**
* @ngdoc service
* @name nms.templates.components.accordion:TemplateInstanceAccordionDeviceChangesService
* @description Gerenciador de alterações relacionadas a equipamentos utilizados no accordion de Aplicação de Template.
*/
var app = angular.module("nms.templates");

app.factory("TemplateInstanceAccordionDeviceChangesService", ["$translate", "PRESENTATION_MODE",
    function($translate, PRESENTATION_MODE) {
        const service: any = {};

        function createDeviceLabel(device) {
            return $translate.instant("templateinstanceform.devices") + ": " + device.name + ", "
                + $translate.instant("templateinstanceform.model") + ": " + device.modelName;
        }

        function getDeviceObjectsInAccordionData(deviceId, model, presentationMode) {
            var devices = [];
            if (presentationMode === PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE) {
                model.forEach(function(templateModel) {
                    var deviceModel = _.find(templateModel.children, {id: deviceId});
                    if (deviceModel) {
                        devices.push(deviceModel);
                    }
                });
            } else {
                var deviceModel = _.find(model, {id: deviceId});
                if (deviceModel) {
                    devices.push(deviceModel);
                }
            }

            return devices;
        }

        function updateAccordionModelDeviceNames(deviceId, deviceLabel, model, presentationMode) {
            var deviceModels = getDeviceObjectsInAccordionData(deviceId, model, presentationMode);
            deviceModels.forEach(function(deviceModel) {
                deviceModel.label = deviceLabel;
            });
        }

        function updateDeviceVariable(variables, updatedDevice) {
            variables.forEach(function(variable) {
                var device = _.find(variable.devices, {deviceId: updatedDevice.id});
                if (device) {
                    device.deviceName = updatedDevice.name;
                }
            });
        }

        service.updateAccordionDeviceModels = function(device, accordionModel, presentationMode) {
            if (accordionModel) {
                var deviceLabel = createDeviceLabel(device);
                updateAccordionModelDeviceNames(device.id, deviceLabel, accordionModel, presentationMode);
            }
        };

        service.updateTemplateInstanceDeviceModels = function(device, templateInstanceModel) {
            if (templateInstanceModel) {
                _.filter(templateInstanceModel.equipmentAssociations, {equipmentId: device.id})
                    .forEach(function(deviceAssociation) {
                        deviceAssociation.equipmentName = device.name;
                    });
            }
        };

        service.updateVariableModel = function(updatedDevice, variableModel) {
            if (variableModel.equipmentLocalVarsDetails) {
                variableModel.equipmentLocalVarsDetails.equipmentLocalVars.forEach(function(equipment) {
                    if (equipment.equipmentId === updatedDevice.id) {
                        equipment.equipmentName = updatedDevice.name;
                    }
                    updateDeviceVariable(equipment.localVars, updatedDevice);
                });
            }

            if (variableModel.globalVarsDetails) {
                updateDeviceVariable(variableModel.globalVarsDetails.vars, updatedDevice);
            }
        };

        return service;
    }
]);
