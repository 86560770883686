"use strict";

import {
    DeletionStatus,
    EquipmentAssociationResultRequest,
    EquipmentFilterResponse,
    EquipmentSearchFilter,
    ExecutionOrigin,
    ScheduledTemplateApplication,
    TemplateInfo,
    TemplateInstance,
    TemplateInstanceIdentifier,
    TemplateInstanceResult,
    TemplateRestrictionFilter,
    TotalKeywords
} from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { buildPaginatedURI, PaginationFilter, PaginationResponse } from "app/shared/utils/rest-pagination/rest-pagination";
export interface TemplateInstanceRESTService {
    getPaginated(filters: PaginationFilter): Promise<PaginationResponse<TemplateInstance>>;
    get(templateInstanceId: string): Promise<ScheduledTemplateApplication>;
    verifyRemoveCommands(templateInstanceId: string): Promise<TemplateInfo>;
    removeTemplateInstance(templateInstanceId: string): Promise<void>;
    removeFromNMS(templateInstanceIds: string[]): Promise<Array<DeletionStatus>>;
    executeTemplateInstances(templateInstanceIds: string[]): Promise<void>;
    apply(scheduledTemplateApplication: ScheduledTemplateApplication): Promise<TemplateInstanceIdentifier>;
    validateVariables(templateInstance: TemplateInstance): Promise<void>;
    update(scheduledTemplateApplication: ScheduledTemplateApplication): Promise<void>;
    getResultForTemplateInstance(templateInstanceId: string, executionOrigin: ExecutionOrigin,
        applicationType: string): Promise<TemplateInstanceResult>;
    getRemoveResultForTemplateInstance(templateInstanceId: string, applicationType?: string): Promise<TemplateInstanceResult>;
    getApplicationResultDetails(templateInstanceId: string, executionOrigin: ExecutionOrigin);
    getTotalKeywords(): Promise<TotalKeywords>;
    getDeviceTemplatesAssociationsByVariable(deviceTemplateAssociations: any);
    validateDeviceForTemplate(deviceModelTemplateAssociation: any);
    getDevicesByRestrictions(restrictionRules: EquipmentSearchFilter): Promise<EquipmentFilterResponse>;
    getCpesByRestrictions(restrictionRules: EquipmentSearchFilter): Promise<EquipmentFilterResponse>;
    validateTemplatesPermissionByTemplates(templateIds: Array<string>): Promise<boolean>;
    validateTemplatesPermissionByTemplateInstances(templateInstanceIds: Array<string>): Promise<boolean>;
    validateTemplateRestriction(templateRestrictionFilter: TemplateRestrictionFilter): Promise<Array<string>>;
    isTemplateInstanceApplying(templateInstanceId: string): Promise<boolean>;
}

/**
 * @ngdoc service
 * @name nms.templates.templateInstanceRestService
 * @description
 * # templateInstanceRestService
 * Service in the nms.templates.
 */
angular.module("nms.templates").factory("TemplateInstanceRESTService", [
    "Restangular",
    function (Restangular): TemplateInstanceRESTService {
        var basePath = "/templateInstance";

        return {
            get(templateInstanceId: string): Promise<ScheduledTemplateApplication> {
                return Restangular.one(`${basePath}/${templateInstanceId}`).get();
            },
            getPaginated(filters: PaginationFilter): Promise<PaginationResponse<TemplateInstance>> {
                const uri = buildPaginatedURI(basePath, filters);
                return Restangular.all(uri).customGET();
            },
            apply(scheduledTemplateApplication: ScheduledTemplateApplication): Promise<TemplateInstanceIdentifier> {
                return Restangular.one(basePath).customPOST(scheduledTemplateApplication);
            },
            executeTemplateInstances(templateInstanceIds: string[]): Promise<void> {
                return Restangular.one(`${basePath}/applier/execute`).customPOST(templateInstanceIds);
            },
            validateVariables(templateInstance: TemplateInstance): Promise<void> {
                return Restangular.one(basePath + "/validateVariables").customPOST(templateInstance);
            },
            update(scheduledTemplateApplication: ScheduledTemplateApplication): Promise<void> {
                return Restangular.one(basePath).customPUT(scheduledTemplateApplication);
            },
            getResultForTemplateInstance(templateInstanceId: string, executionOrigin: ExecutionOrigin,
                    applicationType: string): Promise<TemplateInstanceResult> {
                return Restangular.one(`${basePath}/result/${templateInstanceId}/${applicationType}/${executionOrigin}`).get();
            },
            getRemoveResultForTemplateInstance(
                templateInstanceId: string,
                applicationType?: string
            ): Promise<TemplateInstanceResult> {
                return Restangular
                .all(`${basePath}/result/${templateInstanceId}/${applicationType}/last-execution`)
                .customGET();
            },
            getApplicationResultDetails(templateInstanceId, executionOrigin) {
                return Restangular
                    .one(`${basePath}/equipmentAssociationResults/${templateInstanceId}/${executionOrigin}`)
                    .customGET();
            },
            removeTemplateInstance(templateInstanceId: string): Promise<void> {
                return Restangular.all(`${basePath}/${templateInstanceId}`).customOperation(
                    "remove",
                    "",
                    {},
                    {
                        "Content-Type": "application/json; charset=UTF-8"
                    }
                );
            },
            verifyRemoveCommands(templateInstanceId: string): Promise<TemplateInfo> {
                return Restangular.one(basePath + "/templatesWithoutRemovalCommands/" + templateInstanceId).get();
            },
            removeFromNMS(templateInstanceIds: string[]): Promise<Array<DeletionStatus>> {
                return Restangular.all(basePath).customOperation(
                    "remove",
                    "",
                    {},
                    {
                        "Content-Type": "application/json; charset=UTF-8"
                    },
                    templateInstanceIds
                );
            },
            getTotalKeywords(): Promise<TotalKeywords> {
                return Restangular.all(basePath + "/totalKeywords").customGET();
            },
            getDeviceTemplatesAssociationsByVariable(deviceTemplateAssociations) {
                return Restangular.one(basePath + "/getDeviceTemplatesAssociationsByVariable").customPOST(
                    deviceTemplateAssociations
                );
            },
            validateDeviceForTemplate(deviceModelTemplateAssociation) {
                return Restangular.one(basePath + "/validateDeviceForTemplate").customPOST(deviceModelTemplateAssociation);
            },
            validateTemplateRestriction(templateRestrictionFilter: TemplateRestrictionFilter): Promise<Array<string>> {
                return Restangular.one(basePath + "/validateTemplateRestriction").customPOST(templateRestrictionFilter);
            },
            getDevicesByRestrictions(restrictionRules: EquipmentSearchFilter): Promise<EquipmentFilterResponse> {
                return Restangular.all(basePath + "/device/searchByRestrictions").customPOST(restrictionRules);
            },
            getCpesByRestrictions(restrictionRules: EquipmentSearchFilter): Promise<EquipmentFilterResponse> {
                return Restangular.all(basePath + "/cpe/searchByRestrictions").customPOST(restrictionRules);
            },
            validateTemplatesPermissionByTemplates(templateIds: Array<string>): Promise<boolean> {
                return Restangular.one(basePath + "/validateTemplatePermissionByTemplates").customPOST(templateIds);
            },
            isTemplateInstanceApplying(templateInstanceId: string): Promise<boolean> {
                return Restangular.one(`${basePath}/applier/${templateInstanceId}/applying`).customGET();
            },
            validateTemplatesPermissionByTemplateInstances(templateInstanceIds: Array<string>): Promise<boolean> {
                return Restangular.one(basePath + "/validateTemplatePermissionByTemplateInstances").customPOST(
                    templateInstanceIds
                );
            }
        };
    }
]);
