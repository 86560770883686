import { Injectable } from "@angular/core";

import { TranslationHelperService } from "@nms-ng2/app/shared/services/util/translation-helper.service";
import { AbstractErrorResponseHandlerService } from "../../core/services/error-response-handler/abstract-error-response-handler";
import {
    ErrorDescriptionDetails,
    ErrorResponse,
    HttpStatusCode,
    NmsError
} from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";

/**
 * Serviço para lidar com mensagens de erros de CPE recebidas através de um @see ErrorResponse.
 *
 * Verifica se ocorreu alguma falha no CPE
 *
 * A mensagem será exibida no formato abaixo:
 * 1o nível (tipo da falha): Resposta inesperada recebida do CPE.
 * 2o nível (detalhes da falha):
 *       Formato: Resposta esperada: <RESPOSTA_ESPERADA>. Resposta recebida: <RESPOSTA_RECEBIDA>.
 *       Exemplo: Resposta esperada: GetParameterNamesResponse. Resposta recebida: DownloadResponse.
 */
@Injectable({
    providedIn: "root"
})
export class CpeErrorResponseHandlerService extends AbstractErrorResponseHandlerService {

    constructor(private readonly translationHelper: TranslationHelperService) {
        super();
    }

    public buildErrorDescriptionDetails(errorResponse: ErrorResponse,
                                        detailsSeparator: string = "<br>",
                                        status?: number): ErrorDescriptionDetails {
        let erroDescriptionDetails: ErrorDescriptionDetails;
        let { code } = errorResponse;

        erroDescriptionDetails = (code && code == NmsError.UNEXPECTED_CPE_MESSAGE)
                               ? this.getErrorDetailsFromUnexpectedCpeMessage(errorResponse, detailsSeparator)
                               : this.formatErrorDescriptionDetails(errorResponse, status);

        return erroDescriptionDetails;
    }

    /**
     * Retorna a descrição e os detalhes das mensagens referentes aos erros ocorridos
     * @param errorResponse
     */
    private formatErrorDescriptionDetails(errorResponse: ErrorResponse, status?: number): ErrorDescriptionDetails {
        let description, errorDetails;
        const {code, details} = errorResponse;

        if (status === HttpStatusCode.Unauthorized) {
            description = this.translationHelper.translate(`cwmp.parameters.error.ACS_SESSION_EXPIRED`);
            errorDetails = "";
        } else {
            let errorKey = this.getErrorKeyFromEnum(code);
            description = this.translationHelper.translate(`cwmp.parameters.error.${errorKey}`);
            errorDetails = this.buildErrorDetails(code, details);
        }

        return {
            description: description,
            details: errorDetails
        };
    }

    /**
     * Verifica se o erro em questão, baseado nos detalhes passados, deveria ser considerado um erro genérico.
     */
    private isGenericError(details: Array<string>) {
        return details.length == 2
            && details[0].includes("expected type")
            && details[1].includes("received type");
    }

    /**
     * TODO: [US93924] O código abaixo está adaptado devido a estrutura das mensagens de erro do CPE a serem apresentadas
     * no entanto ja possui uma US para estimativa e refatoração do código
     * Se for erro do tipo : UNEXPECTED_CPE_MESSAGE - terá um tratamento diferente
     * Se for um BUG do CPE a mensagem recebida possuirá um determinado formato descrito logo mais abaixo
     * Caso contrário se for um erro de path inválido e nos detalhes de errorResponse vier um array com tamanho = 1
     * A descrição da mensagem de erro será => o primeiro elemento do array de detalhes
     * Caso seja um erro de setParameters e os detalhes do erro possuir um array com tamanho > 1
     * A descrição da mensagem de erro será => o primeiro elemento do array de detalhes
     * Os detalhes do erro serão os outros valores do array de details.
     *
     * @param description
     * @param errorDetails
     */
    private getErrorDetailsFromUnexpectedCpeMessage(errorResponse: ErrorResponse, separator: string): ErrorDescriptionDetails {
        let {code, details } = errorResponse;
        let isGenericError = this.isGenericError(details);
        const errorKey = this.getErrorKeyFromEnum(code);

        let description = (isGenericError)
                        ? this.translationHelper.translate(`cwmp.parameters.error.${errorKey}`)
                        : errorResponse.details.shift();

        return {
            description: description,
            details: this.handleUnexpectedCpeMessageErrorDetails(details, separator, isGenericError)
        };
    }

    /**
     * Gera os detalhes de erro de acordo com o tipo de erro recebido.
     *
     * @param errorCode código do erro
     * @param details Array com detalhes de erro.
     *
     * @returns Mensagem de erro traduzida.
     */
    public buildErrorDetails(errorCode: NmsError, details: Array<string>): string {
        let message = "";

        switch(errorCode) {
            case NmsError.ACS_CPE_MESSAGE_TIMEOUT:
                message = this.buildTimeoutErrorMessage(details);
                break;
            case NmsError.ACS_CONNECTION_REQUEST_ERROR:
                let key = this.getErrorKeyFromDetails(details);
                message = this.translationHelper.translate(`cwmp.parameters.error.connection.request.${key}`);
                break;
            case NmsError.ACS_UNEXPECTED_ERROR:
                message = this.translationHelper.translate("cwmp.parameters.error.acs.unexpected.problem");
                break;
        }

        return message;
    }

    /**
     * Gera uma mensagem de detalhes de erro para erros de timeout.
     *
     * @param errorDetails Array com detalhes de erro.
     * @returns mensagem com detalhes de erro de timeout.
     */
    private buildTimeoutErrorMessage(errorDetails: Array<string>): string {
        let translatedMessage = "";

        if (errorDetails && errorDetails.length > 0) {
            let errorMessage: string = errorDetails[0].toUpperCase();
            let timeout: string = errorDetails[1].match(/\d+/g).map(Number)[0].toString();

            translatedMessage = (errorMessage.includes("INFORM"))
                ? this.translationHelper.translateWithReplacement("cwmp.parametes.error.inform.timeout", timeout)
                : this.translationHelper.translateWithReplacement("cwmp.parametes.error.cpe.response.timeout", timeout);
        }

        return translatedMessage;
    }

    private handleUnexpectedCpeMessageErrorDetails(details: Array<string>, separator: string, isGenericError: boolean): string {
        return (isGenericError)
               ? this.getDetailsFromUnexpectedCpeMessageError(details, separator)
               : details.join(separator);
    }

    /**
     * Tratamento de erro ocorrido quando receber alguma mensagem inesperada.
     *
     * A mensagem será exibida no formato abaixo:
     * 1o nível (tipo da falha): Resposta inesperada recebida do CPE.
     * 2o nível (detalhes da falha):
     *       Formato: Resposta esperada: <RESPOSTA_ESPERADA>. Resposta recebida: <RESPOSTA_RECEBIDA>.
     *       Exemplo: Resposta esperada: GetParameterNamesResponse. Resposta recebida: DownloadResponse.
     */
    private getDetailsFromUnexpectedCpeMessageError(details: Array<string>, separator: string): any {
        let expectedResponse = details[0].split(":")[1];
        let receivedResponse = details[1].split(":")[1];

        let failTypeMessage = this.translationHelper
            .translateWithReplacement("cwmp.parameters.request.expectedResponse", expectedResponse);
        let receivedTypeMessage = this.translationHelper
            .translateWithReplacement("cwmp.parameters.request.receivedResponse", receivedResponse);

        let message = failTypeMessage + separator + receivedTypeMessage;

        return message;
    }
}
