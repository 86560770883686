/**
 * Directive responsável por customizar as colunas em tabelas do tipo ng-table
 *
 * Este componente tem como dependência a utilização do NgTableDynamic para a construção da tabela.
 * Esta dependência está ligada a funcionalidade de reordenação de colunas que só é suportada neste modo.
 *
 *  "Out of the box, only ngTableDynamic natively supports column reordering"
 *
 *  Ver: http://ng-table.com/#/columns/demo-reordering
 *
 * #id - Obrigatório - identificador usado para referenciar as customizações na storage.
 * #tableModel - Obrigatório - Objeto para interface entre o controller principal e o controller da diretiva.
 *
 *     Possui a seguinte estrutura:
 *
 *     tableModel: {
 *         options: object{          // Object - Opcional - Indica as opções iniciais do componente
 *
 *              show:                true,       // Boolean - Opcional - Default: true
 *                                               // Indica as opções default para exibição de todas as colunas na tabela.
 *                                               // Quando true indica que todas as colunas estarão visíveis na tabela.
 *
 *              editable:            true,       // Boolean - Opcional - Default: true
 *                                               // Indica as opções default para customização de todas as colunas.
 *                                               // Quando true indica que todas as colunas serão customizáveis.
 *
 *              hidden:            false,        // Boolean - Opcional - Default: false
 *                                               // Indica as opções default para exibição de todas as colunas no dropdown.
 *                                               // Quando false indica que todas as colunas serão visíveis
 *                                               // no dropdown do componente.
 *
 *              selection:           true,       // Boolean - Opcional - Default: true
 *                                               // Indica se a tabela deve conter uma coluna para seleção;
 *                                               // Quando true adiciona na primeira posição da tabela a coluna de seleção.
 *
 *              sortable:            true,       // Boolean - Opcional - Default: true
 *                                               // Indica se as colunas devem ou não possuir a funcionalidade de ordenação.
 *                                               // Quando true indica que todas as colunas terão a ordenação ativa e
 *                                               // utilizando como configuração o nome da coluna.
 *
 *              filter:              true        // Boolean - Opcional - Default: true
 *                                               // Indica se as colunas devem ou não possuir a funcionalidade de filtro.
 *                                               // Quando true indica que todas as colunas terão o filtro ativo.
 *                                               // Todos os filtros são criados com o tipo 'text'.
 *
 *              translatePrefix:     ""          // String - Opcional
 *                                               // Indica se os títulos da coluna serão criados dinamicamente.
 *                                               // Quando definido é utilizado como prefixo para criar o título da coluna.
 *                                               // O campo field do objeto Column é utilizado como sufixo.
 *                                               // Ex: translatePrefix = "teste.prefixo"
 *                                               //     column.field = "coluna"
 *                                               //
 *                                               //     Resultado = "teste.prefixo.coluna"
 *                                               // Se não for definido o campo field da coluna será utilizado como título.
 *
 *               actions             false       // Boolean - Opcional - Default: false
 *                                               // Indica se haverá ou não uma coluna com ações para o elemento.
 *                                               // Quando utilizado, será adicionada uma coluna sem nome e sem filtro de pesquisa
 *                                               // deixando apenas os elementos visíveis.
 *         },
 *
 *         columns: list[object{     // Lista - Obrigatório - Contém as colunas a serem gerenciadas pela componente.
 *
 *              field:               "",         // String -  Obrigatório
 *                                               // Nome do atributo a ser lido no dataset para preencher a coluna.
 *
 *              title:               "",         // String -  Opcional - Default: options.translatePrefix
 *                                               // Quando definido é utilizado como título da coluna.
 *
 *              show:                true,       // Boolean - Opcional - Default: options.show
 *                                               // Quando definido é utilizado para exibir/esconder a coluna na tabela.
 *
 *              editable:            true,       // Boolean - Opcional - Default: options.editable
 *                                               // Quando definido é utilizado para habilitar/desabilitar a
 *                                               // customização da coluna.
 *
 *              hidden:              false,      // Boolean - Opcional - Default: options.hidden
 *                                               // Quando definido é utilizado para esconder ou não uma coluna
 *                                               // no dropdown do componente.
 *
 *              sorting:             ""/object,  // String ou Object -  Opcional - Default: options.sorting
 *                                               // Quando definido é utilizado para configurar o sorting na coluna.
 *                                               // Pode receber o nome do campo que deve ser sorteado, ou um objeto
 *                                               // no formato { nomeDoCampo : direção } Ex: { nome : "asc" }
 *
 *              filter:              object,     // Object -  Opcional - Default: options.filter
 *                                               // Quando definido é utilizado para configurar o filtro na coluna.
 *                                               // Pode receber um objeto no formato { nomeDoCampo: tipoDoCampo }
 *                                               // Ex: { nome : "text" }
 *
 *              tipiconTranslateKey: "",         // String - Opcional
 *                                               // Chave de tradução para valor de tipicon em coluna.
 *                                               // Quando definido é utilizado para criar um cabeçalho customizado para a coluna
 *                                               // com tipicon com valor definido pela chave de tradução indicada no atributo.
 *
 *              {any}:               object      // Object -  Opcional
 *                                               // É possivel adicionar outras propriedades a lista de colunas que
 *                                               // posteriormente podem ser utilizados para visualização no html.
 *                                               // Neste caso todos os objetos que não são suportados de forma padrão
 *                                               // devem ser tratados de forma especifica na tabela.
 *
 *           }],
 *           hiddenIcon:            false       // Boolean - Opcional - Default: false
 *                                              // Não apresenta o ícone de mostrar/esconder colunas e ordenação.
 *         ...
 *     }
 */

var app = angular.module("nms");

app.directive("customizeTableColumns", [function() {
    return {
        templateUrl: "templates/components/ui/table/customize-table-columns.html",
        controller: "CustomizeTableColumnsController",
        scope: {
            id: "@",
            tableModel: "=",
            hiddenIcon: "=?"
        }
    };
}]);
