"use strict";

import { TemplateInstanceService } from "../template-instance-service";
import { TemplateInstanceOperation } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";

var app = angular.module("nms.templates");

app.controller("TemplateApplicationOneStepController", [
    "$rootScope",
    "$scope",
    "$timeout",
    "ngDialog",
    "TemplateInstanceService",
    "resolvedInformation",
    "variableTypes",
    "TemplateInstanceValidatorService",
    function (
        $rootScope,
        $scope,
        $timeout,
        ngDialog,
        TemplateInstanceService: TemplateInstanceService,
        resolvedInformation,
        variableTypes,
        TemplateInstanceValidatorService
    ) {
        $scope.templateInstance = resolvedInformation.templateInstance;
        $scope.templates = resolvedInformation.templates;
        $scope.variableTypes = variableTypes;
        $scope.deviceSelected = { equipmentId: resolvedInformation.device.id };
        $scope.variables = resolvedInformation.variables;

        var init = function () {
            var equipmentAssociations = $scope.templateInstance.equipmentAssociations[0];
            $scope.device = {
                name: equipmentAssociations.equipmentDetails.name,
                model: equipmentAssociations.equipmentDetails.model
            };

            var templateAssociation = equipmentAssociations.templateAssociations[0];
            $scope.template = {
                name: templateAssociation.templateName,
                description: templateAssociation.templateDescription
            };
        };

        $scope.$on("variableValueHasChange", function () {
            $rootScope.hideLoadingPanel = true;
            $scope.$broadcast("convertTemplateInstanceVariables");
            $scope.$broadcast("getCommands");
            // Verifica se o digest está em processamento antes de chamar novamente,
            // para evitar o erro de '$digest already in progress'.
            if (!$scope.$$phase) {
                $scope.$digest();
            }
            $rootScope.hideLoadingPanel = false;
        });

        $scope.buildTemplateInstanceCommandRequest = () => {
            const equipmentTemplateIdentifiers = _.map($scope.templateInstance.equipmentAssociations, (equipment) => {
                return {
                    equipmentIdentifier: equipment.equipmentIdentifier,
                    templateId: equipment.templateAssociations[0].templateId
                };
            });

            return {
                templateInstance: $scope.templateInstance,
                equipmentTemplateIdentifiers,
                templateInstanceOperation: TemplateInstanceOperation.CREATE
            };
        };

        $scope.apply = function () {
            var data: any = {
                form: $scope.templateInstanceForm,
                success: function () {
                    TemplateInstanceValidatorService.validateVariables($scope.templateInstance, function () {
                        const scheduledTemplateApplication = {templateInstance: $scope.templateInstance};
                        TemplateInstanceService.applyAndOpenResultModal(scheduledTemplateApplication, $scope.closeThisDialog);
                    });
                }
            };

            $rootScope.$broadcast("validateVariables", data);
        };

        init();

        $scope.$on("$destroy", function () {
            $scope.$broadcast("unsubscribeVariablesWebsocket");
        });
    }
]);
