"use strict";

/**
* @ngdoc directive
* @name nms.directive:passwordField
* @description Diretiva para criar um campo de senha com ícone de exibir e ocultar, e quando no modo oculto
* exibir o caracter digitado por um breve período de tempo.
* @param {id}                   - Mandatório    -   Identificador do input.
* @param {textBind}             - Mandatório    -   Modelo que armazenará o valor digitaco no input.
* @param {label}                - Mandatório    -   Chave de tradução para o texto a ser apresentado antes do input.
* @param {placeholder}          - Mandatório    -   Chave de tradução para mensagem a ser apresentada no input.
* @param {disabledField}        - Opcional      -   Define se o atributo é desabilitado ou não.
* @param {requiredField}        - Opcional      -   Define se o atributo é requerido ou não. Default: true
* @param {removeLabelPadding}   - Opcional      -   Define se o label e os inputs do componente password-field deverão ter o
*                                               -   atributo padding ou não. Default: false
* @param {name}                 - Opcional      -   Adiciona o atributo name aos inputs da diretiva de password. O input do valor
                                                -   encriptado sera <name>-encryptedPassword.
* @param {confirmModel}         - Opcional      -   Modelo a ser usado na verificação de confirmação de senha.
* @param {minLength}            - Opcional      -   Define o tamanho mínimo requerido. Default: 0
* @param {maxLength}            - Opcional      -   Define o tamanho máximo requerido. Default: 100
*/
var app = angular.module("nms");
app.directive("passwordField", ["$rootScope", function($rootScope) {
    return {
        restrict: "E",
        templateUrl: "/templates/components/ui/input/password-field.html",
        scope: {
            id: "@",
            textBind: "=",
            label: "=",
            placeholder: "=",
            disabledField: "=?",
            requiredField: "=?",
            removeLabelPadding: "@?",
            name: "@?",
            confirmModel: "=?",
            minLength: "=?",
            maxLength: "=?"
        },
        link: function(scope, elem, attrs) {
            if (_.isUndefined(scope.requiredField)) {
                scope.requiredField = true;
            }

            if (_.isUndefined(scope.removeLabelPadding)) {
                scope.removeLabelPadding = true;
            }

            if (_.isUndefined(scope.name)) {
                scope.name = "passwordInput";
            }

            scope.isValid = true;
            scope.useConfirmation = attrs.confirmModel ? true : false;
            scope.minLength = scope.minLength || 0;
            scope.maxLength = scope.maxLength || 100;

            scope.passwordConfig = {
                encryptedPassword: scope.textBind,
                showPassword: false
            };

            scope.updateMaskedFields = function() {
                $rootScope.$broadcast("maskPassword");
            };

            /**
            * Watch para limpar os dados presentes em passwordConfig.encryptedPassword.
            * Necessário para o caso onde os dados de textBind são limpos programaticamente.
            */
            var deregisterTextBindWatch = scope.$watch("textBind", function(newValue, oldValue) {
                if (newValue !== oldValue && newValue === "") {
                    scope.passwordConfig.encryptedPassword = "";
                }
            });

            scope.$on("$destroy", function() {
                deregisterTextBindWatch();
            });
        }
    };
}]);
