var app = angular.module("nms.manageDevices");

/**
 * @ngdoc function.
 * @name nms.manageDevice.controller:PathTreeController.
 * @description Controller of the nms.manageDevice.
 * Responsável pela lógica de localidade, montar estrutura, abrir e fechar as pastas.
 */
app.controller("PathTreeController", ["$rootScope", "$scope", "LocationService", "LOCATION",
    function($rootScope, $scope, LocationService, LOCATION) {
        var item;
        var pathsById;

        $scope.setSelected = function(container) {
            $scope.selected = true;
            $rootScope.selectedNode = $scope
                .setItemToArraySelectedNodes($rootScope.selectedNode, item, pathsById, container, $scope.selected);
            $scope.buildLocationByParentId(container);
        };

        $scope.isSelected = function(container) {
            return $rootScope.initialLocationId === container.id;
        };

        $scope.isRootSelected = function() {
            return _.isEmpty($rootScope.selectedNode);
        };

        $scope.setItemToArraySelectedNodes = function(selectedNode, item, pathsById, container, selected) {
            if (selected) {
                selectedNode = {path: item, id: container.id, pathId: pathsById};
                $rootScope.initialLocationId = container.id;
            } else {
                selectedNode = _.without(selectedNode, {path: item, id: container.id, pathId: pathsById});
                $rootScope.initialLocationId = LOCATION.DEFAULT_LOCATION_ID;
            }
            return selectedNode;
        };

        $scope.openFolder = function(container) {
            $scope.selected = !$scope.selected;
            $scope.buildLocationByParentId(container);
        };

        $scope.selectRootLocation = function() {
            $rootScope.selectedNode = _.without($rootScope.selectedNode, $rootScope.selectedNode);
            $rootScope.initialLocationId = LOCATION.DEFAULT_LOCATION_ID;
        };

        $scope.buildLocationByParentId = function(container) {
            $scope.allLocations.forEach(function(location) {
                if (_.isEqual(location.locationParentId, container.id)) {
                    var children = $scope.getNonKeyChildren($scope.allLocations, container.id);

                    children.forEach(function(child) {
                        $scope.setNewChild(container, child);
                    });
                }
            });
        };

        $scope.buildRootLocation = function(allLocations, parentId) {
            var structure: any = [];
            allLocations.forEach(function(location) {
                if (_.isEqual(location.locationId, parentId)) {
                    var children = $scope.getNonKeyChildren(allLocations, location.locationId);

                    structure = {
                        locationId: location.locationId,
                        name: location.locationPath,
                        containers: {}
                    };

                    children.forEach(function(child) {
                        if (!_.isEqual(child.locationPath, "root")) {
                            $scope.setNewChild(structure, child);
                        }
                    });
                }
            });

            return structure;
        };

        $scope.setNewChild = function(parent, child) {
            parent.containers[child.locationPath] = {
                name: child.locationPath,
                id: child.locationId,
                containers: {}
            };
        };

        $scope.getNonKeyChildren = function(allLocations, currentLocation) {
            return _.filter(allLocations, function(location) {
                return location.locationParentId === currentLocation;
            });
        };

        $scope.hasChildren = function(container) {
            return _.some($scope.allLocations, function(location) {
                return location.locationParentId === container.id;
            });
        };

        $scope.init = function() {
            LocationService.getAllLocations().then(function(response) {
                $scope.allLocations = response;
                $scope.template = $scope.buildRootLocation($scope.allLocations, 0);
                $scope.rootChildren = _.keys($scope.template.containers);

                $scope.openFolderIfNeeded();
            });
        };

        $scope.openFolderIfNeeded = function() {
            item = ($scope.container) ? $scope.parentPath + "/" + $scope.container.name : $scope.parentPath;
            pathsById = ($scope.container) ? $scope.parentId + "/" + $scope.container.id : $scope.parentId;
            if ($scope.container) {
                var arrayPaths = $rootScope.initialLocationId === LOCATION.DEFAULT_LOCATION_ID
                    ? [LOCATION.DEFAULT_LOCATION_ID]
                    : $scope.getPathsById($rootScope.initialLocationId).split("/");
                $scope.selected = _.contains(arrayPaths, $scope.container.id.toString());
            }

            if ($scope.selected && $scope.template) {
                if ($scope.container.id === $rootScope.initialLocationId) {
                    $scope.setSelected($scope.container);
                } else {
                    $scope.buildLocationByParentId($scope.container);
                }
            }
        };

        $scope.getPathsById = function(locationId) {
            var location = _.find($scope.allLocations, {locationId: locationId});
            if (location.locationId !== LOCATION.DEFAULT_LOCATION_ID) {
                return $scope.getPathsById(location.locationParentId) + "/" + location.locationId;
            } else {
                return location.locationId;
            }
        };

        $scope.init();
    }
]);
