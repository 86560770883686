/**
 * @ngdoc directive
 * @name nms.components.autoUpdater:devicePollingStatus
 * @description
 *
 * Diretiva responsável por gerenciar o status do polling.
 *
 * resourceId:       id do device que terá o polling gerenciado.
 * startController:  função responsável por inicializar o controller
 */
var app = angular.module("nms");

app.directive("devicePollingStatus",
    function() {
        return {
            restrict: "E",
            controller: "DevicePollingStatusController",
            scope: {
                deviceId: "=",
                startController: "="
            },
            transclude: true
        };
    }
);
