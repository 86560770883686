/**
* @ngdoc directive
* @name nms.templates.directive:variableStep
* @description Diretiva para validar os valores de step em inputs.
*/
app.directive("variableStep", function() {
    return {
        require: "ngModel",
        priority: 100,
        link: function(scope, elem, attr, ngModel) {
            var step = attr.variableStep;

            if (!_.isEmpty(step)) {
                ngModel.$validators.variableStep = function(value) {
                    return _.isEmpty(value) || value % step == 0;
                };
            }
        }
    };
});
