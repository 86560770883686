"use strict";

/**
 * Filter that replaces a line break character on a given input with an HTML <br> tag.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.filter("replaceLineBreak",
    function() {
        return function(input) {
            return input.replace(/\n|\\n/g, "<br>");
        };
    }
);
