"use strict";

var app = angular.module("nms");

/**
 * @ngdoc directive
 * @name nms.templates.directive:verticalScrollableOuterTable.
 * @description Diretiva que habilita scroll vertical em tabelas.
 *
 * Limitações: Como temos casos de uso apenas com o auto-updater e o client-pagination
 * a diretiva está levando em consideração apenas esses dois componentes.
 */
app.directive("verticalScrollableOuterTable",
    ["$rootScope", "$timeout", "$window", "$location","$anchorScroll",
         function($rootScope, $timeout, $window, $location, $anchorScroll) {
        return {
            restrict: "C",
            link: function(scope, element, attrs) {
                var marginTop = 10;
                var getElementHeight = function(identifier) {
                    var el = angular.element(identifier)[0];
                    return el ? el.offsetHeight : 0;
                };

                var execute = function() {
                    var windowHeight = $window.innerHeight;
                    var tableTopPosition = element[0].getBoundingClientRect().top + marginTop;
                    var fixedFooterHeight = getElementHeight(".last-sibling-scrollable-table") + marginTop;
                    var paginationHeight = getElementHeight(".client-pagination");
                    var bottomPadding = 20;
                    var tableHeight = windowHeight - tableTopPosition - fixedFooterHeight - paginationHeight - bottomPadding;

                    angular.element(element).height(tableHeight);
                };

                /**
                 * Evento para atualizar a altura da tabela.
                 * Utilizado na tela de gpon onus, como a lista de templates é obtida de forma assíncrona é preciso recalcular
                 * a altura da tabela.
                 */
                var unregister = $rootScope.$on("updateTableHeight", function() {
                    execute();
                });

                angular.element($window).bind("resize", function() {
                    execute();
                    scope.$digest();
                });

                scope.$on("updateLocationScroll", function(event, scrollerTo) {
                    if ($location.hash() !== scrollerTo) {
                        $location.hash(scrollerTo);
                    } else {
                        $anchorScroll();
                    }
                });

                scope.$on("$destroy", function() {
                    unregister();
                });

                $timeout(execute);
            }
        };
    }]
);
