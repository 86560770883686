var app = angular.module("nms.dynamicDevice");

app.directive("leaflists",
    function() {
        return {
            restrict: "E",
            scope: true,
            controller: "LeafListsController",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaflist/leaflists.html",
            link: function(scope, element) {
                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);

app.directive("leaflistBody",
    function() {
        return {
            restrict: "E",
            controller: "LeafListController",
            controllerAs: "LeafListController",
            scope: {
                leaflist: "=",
                form: "=",
                config: "=",
                showModalAction: "=",
                insideModal: "=",
                customTooltipText: "=",
                watchChanges: "=",
                pathKeys: "=?",
                getPathKeys: "&",
                isTransient: "=",
                isSingleEditing: "="
            },
            templateUrl: "templates/features/dynamic-device/domain/yang/leaflist/leaflist-body.html",
            link: function(scope, element) {
                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);

app.directive("leaflistTableCell",
    function() {
        return {
            restrict: "E",
            controller: "LeaflistTableCellController",
            scope: {
                leaflist: "=",
                config: "=",
                listId: "=",
                keys: "=",
                entry: "=",
                pathKeys: "=?",
                getPathKeys: "&",
                isTransient: "=",
                watchChanges: "=",
                rowModel: "=",
                fieldSearchStatement: "="
            },
            templateUrl: "templates/features/dynamic-device/domain/yang/leaflist/leaflist-table-cell.html",
            link: function(scope, element) {
                element.on("$destroy", function() {
                    scope.$destroy();
                });
            }
        };
    }
);
