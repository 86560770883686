"use strict";

/**
 * @ngdoc function
 * @name nms.deviceSummary.controller:DeviceSummaryController
 * @description
 * # DeviceSummaryController
 * Controller of the nms.deviceSummary
 */

var app = angular.module("nms.deviceSummary");

app.controller("DeviceSummaryController", [
    "$rootScope",
    "$scope",
    "$stateParams",
    "$timeout",
    "NgTableParams",
    "$filter",
    "DeviceStatusService",
    "$translate",
    "$state",
    "STATUS_KEYS",
    "DevicesActionsService",
    "DropdownDeviceService",
    "DevicePermissionService",
    "NMS_FEATURES",
    "TableFilterService",
    "UserPreferencesService",
    "monitoringSettings",
    "CPU_USAGE",
    "MEMORY_USAGE",
    "ZabbixMonitoringService",
    "UnitConverterService",
    "AutoUpdaterService",
    "DeviceSummaryResolver",
    "TEMPLATE_TYPE",
    "TemplateActionsService",
    "PermissionsActionsService",
    "EquipmentsModalsActionsService",
    function (
        $rootScope,
        $scope,
        $stateParams,
        $timeout,
        NgTableParams,
        $filter,
        DeviceStatusService,
        $translate,
        $state,
        STATUS_KEYS,
        DevicesActionsService,
        DropdownDeviceService,
        DevicePermissionService,
        NMS_FEATURES,
        TableFilterService,
        UserPreferencesService,
        monitoringSettings,
        CPU_USAGE,
        MEMORY_USAGE,
        ZabbixMonitoringService,
        UnitConverterService,
        AutoUpdaterService,
        DeviceSummaryResolver,
        TEMPLATE_TYPE,
        TemplateActionsService,
        PermissionsActionsService,
        EquipmentsModalsActionsService
    ) {
        const DMOS_DEVICE_MODEL_CODE = 51000;
        const DM936_MODEL_CODE = 52207;
        var LINK_AGGREGATION_IDENTIFIER = "Link Aggregation";

        var initialTableData;
        var slotShortcuts = [];
        var portShortcuts = [];
        var lagSlotShortcuts = [];
        var lagPortShortcuts = [];
        const defaultGlobalConfig = EquipmentsModalsActionsService.getDefaultGlobalConfig();
        $scope.dropdownTitle = $translate.instant($state.current.data.pageTitle);
        $scope.options = {};
        $scope.device = $stateParams.device;
        $scope.openShortcuts = false;
        var globalConfig = $stateParams.globalConfig ? $stateParams.globalConfig : defaultGlobalConfig;
        $scope.showDeviceAccessDenied = false;
        $scope.shortcuts = DropdownDeviceService.getShortcutToDeviceSummary(globalConfig);
        var availablePreferencesKeys = ["data", "globalFilter", "tableParamsFilter"];
        $scope.CPU_USAGE = CPU_USAGE;
        $scope.MEMORY_USAGE = MEMORY_USAGE;

        $scope.isAdministrator = $rootScope.isAdmin;
        $scope.zabbixUrl = monitoringSettings.url;

        $scope.columns = [
            { field: "name", filter: { fullNameLabel: "text" }, editable: false, width: 240 },
            { field: "model", width: 240 },
            { field: "description" },
            { field: "serialNumber", filter: { serial: "text" }, hidden: true },
            { field: "status", filter: { status: "text" } },
            { field: "faultReason", filter: { faultReason: "text" }, hidden: true },
            { field: "portInRateL1", filter: { "[portInRateL1, absolutePortInRateL1]": "text" }, show: false },
            { field: "portOutRateL1", filter: { "[portOutRateL1, absolutePortOutRateL1]": "text" }, show: false },
            { field: "portInRateL2", filter: { "[portInRateL2, absolutePortInRateL2]": "text" }, link: "linkInRateL2" },
            { field: "portOutRateL2", filter: { "[portOutRateL2, absolutePortOutRateL2]": "text" }, link: "linkOutRateL2" },
            { field: "portInUCast", link: "linkInUCast", show: false },
            { field: "portOutUCast", link: "linkOutUCast", show: false },
            { field: "portInBCast", link: "linkInBCast", show: false },
            { field: "portOutBCast", link: "linkOutBCast", show: false },
            { field: "portInMCast", link: "linkInMCast", show: false },
            { field: "portOutMCast", link: "linkOutMCast", show: false },
            { field: "portInDiscard", link: "linkInDiscard" },
            { field: "portOutDiscard", link: "linkOutDiscard" },
            { field: "portInErrors", link: "linkInErrors" },
            { field: "portOutErrors", link: "linkOutErrors" },
            { field: "portUnknownProtos", link: "linkUnknownProtos", show: false }
        ];

        $scope.tableModel = {
            options: {
                translatePrefix: "summary.tableColumn",
                selection: false,
                sortable: false
            },
            columns: $scope.columns
        };

        $scope.init = function () {
            getManagedDeviceByCurrentDevice($scope.device);

            $scope.options = $scope.loadSummaryOptions($scope.options);
            $scope.shortcuts = DropdownDeviceService.getShortcutToDeviceSummary(globalConfig, $scope.device);
            $scope.searchTooltip = TableFilterService.tooltipText;

            slotShortcuts = DropdownDeviceService.getShortcutToSlot($scope.device.name);
            portShortcuts = DropdownDeviceService.getShortcutToPort(globalConfig, $scope.device);
            lagSlotShortcuts = DropdownDeviceService.getShortcutToLagSlot($scope.device.name);
            lagPortShortcuts = DropdownDeviceService.getShortcutToLagPort($scope.device.name);
        };

        $scope.hasDynamicDevice = function () {
            return PermissionsActionsService.hasFeature($scope.device, NMS_FEATURES.dynamicDeviceConfig.feature);
        };

        var createWindowIdentifier = function (deviceId) {
            return "DeviceSummaryOptionsForId=" + deviceId;
        };

        var permissionAction = function () {
            $scope.hasPermission = true;
            $scope.options = $scope.loadSummaryOptions($scope.options);
            $rootScope.$broadcast("renderScrollableTable");
        };

        $scope.loadSummaryOptions = function (options) {
            return UserPreferencesService.loadPreferences(
                options,
                createWindowIdentifier($scope.device.id),
                availablePreferencesKeys
            );
        };

        var noPermissionAction = function () {
            $scope.hasPermission = false;
            $scope.showDeviceAccessDenied = true;
        };

        var getManagedDevice = function (resourceId) {
            DeviceStatusService.getStatus(resourceId).then(successGetManagedDevice);
        };

        var getManagedDeviceByCurrentDevice = function (device) {
            globalConfig.properties.name = device.name;
            getManagedDevice(device.id);
            $rootScope.$emit("title:append", device.name);
        };

        var isDmosDevice = function (deviceModelCode) {
            return deviceModelCode === DMOS_DEVICE_MODEL_CODE;
        };

        const isDm936 = function (deviceModelCode) {
            return deviceModelCode === DM936_MODEL_CODE;
        };

        // FIXME O frontend não deve ter acoplamento com modelos de equipamentos.
        // Após a refatoração do ManagedDevices, não devem ser utilizados mais fluxos de decisões por modelos
        // (função isDmosDevice e isSnmpDevice) para realizar o refresh de colunas específicas na Summary.
        var refreshSpecificDeviceColumns = function (initialTableData, deviceModelCode) {
            var specificColumns = [
                {
                    column: "serialNumber",
                    hasSupport: !isSnmpDevice(initialTableData) || isDm936(deviceModelCode)
                },
                {
                    column: "faultReason",
                    hasSupport: isDmosDevice(deviceModelCode) || isDm936(deviceModelCode)
                }
            ];

            specificColumns.forEach(function (specificColumn) {
                var hasSupport = specificColumn.hasSupport;
                var column = _.find($scope.columns, { field: specificColumn.column });

                column.hidden = !hasSupport;
                column.show = hasSupport;
            });

            $rootScope.$broadcast("tableColumnsHasChange", false);
        };

        var successGetManagedDevice = function (response) {
            $rootScope.$broadcast("updateDeviceHeaderByManagedDevice", response);

            initialTableData = buildDeviceSummaryInfo(response);
            refreshSpecificDeviceColumns(initialTableData, response.identifiedDevice.deviceCode.modelCode);

            $scope.tableSettings = {
                dataset: initialTableData
            };

            if ($scope.tableParams) {
                $scope.tableParams = new NgTableParams({ filter: $scope.tableParams.filter() }, $scope.tableSettings);
            } else if (!_.isEmpty($scope.options)) {
                $scope.tableParams = new NgTableParams({ filter: $scope.options.tableParamsFilter }, $scope.tableSettings);
            } else {
                $scope.tableParams = new NgTableParams({}, $scope.tableSettings);
            }

            $scope.tableModel.tableParams = $scope.tableParams;

            if (initialTableData) {
                verifyDeviceAccordionOnStorage(initialTableData);
            }

            $scope.enableCustomFilter();
            setGlobalSearchFromSessionStorage($scope.options);
        };

        var unregisterUpdateDeviceHeader = $rootScope.$on("updateDeviceHeaderFinished", function () {
            $scope.warningSettings = ZabbixMonitoringService.getSettings($scope.zabbixUrl, $scope.device.monitoringStatus);
        });

        var isSnmpDevice = function (slots) {
            return slots.length === 1 && slots[0].name === "Device";
        };

        /**
         * Popula as informações de equipamentos (deviceSummaryInfo) com os dados de link aggregations.
         * As link aggregations são representadas como portas na tela de summary, de modo que é necessário a criação de um slot
         * fake para encapsulá-las.
         *
         * @param {Object} response Objeto que representa os dados de insumo (Managed Device).
         * @param {Array} slots Array que mantem os dados da tabela Summary.
         */
        var enrichLinkAggregations = function (response, slots) {
            var linkAggregations = _.get(response, "linkAggregations", []);

            if (!_.isEmpty(linkAggregations)) {
                var ports = [];
                linkAggregations.forEach(function (linkAggregation) {
                    var portResolverModel: any = {
                        identifier: linkAggregation.linkAggregationIdentifier,
                        properties: linkAggregation.properties,
                        transceiverProperties: [],
                        states: response.status.linkAggregationStates,
                        model: LINK_AGGREGATION_IDENTIFIER,
                        identifierPredicate: { linkAggregationIdentifier: linkAggregation.linkAggregationIdentifier },
                        shortcuts: lagPortShortcuts,
                        shortcutAction: function () {
                            DropdownDeviceService.openDeviceOnLagPort($scope.device.name, this);
                        }
                    };

                    ports.push(DeviceSummaryResolver.resolvePort(portResolverModel));
                });

                slots.push({
                    name: LINK_AGGREGATION_IDENTIFIER,
                    slotStatus: "",
                    fullNameLabel: LINK_AGGREGATION_IDENTIFIER,
                    slotIdentifierName: "",
                    chassisIdentifierName: "",
                    model: LINK_AGGREGATION_IDENTIFIER,
                    serial: "",
                    status: "",
                    faultReason: "",
                    statusKey: "",
                    ports: ports,
                    expand: !_.isEmpty(ports),
                    shortcuts: lagSlotShortcuts,
                    shortcutAction: function () {
                        DropdownDeviceService.openDeviceOnLagSlot($scope.device.name, this);
                    }
                });
            }
        };

        /**
         * Popula as informações de equipamentos (deviceSummaryInfo) com os dados de slots e portas.
         *
         * @param {Object} response Objeto que representa os dados de insumo (Managed Device).
         * @param {Array} slots Array que mantem os dados da tabela Summary.
         */
        var enrichSlots = function (response, slots) {
            var chassis = response.inventory.chassis;

            chassis.forEach(function (chassi) {
                chassi.slots.forEach(function (slot) {
                    var ports = [];
                    var slotIdentifier = slot.identifier;

                    if (slot.provisionedCard) {
                        var portProperties = _.filter(response.portProperties, function (portProperty) {
                            return _.isEqual(portProperty.portIdentifier.slotIdentifier, slotIdentifier);
                        });

                        portProperties.forEach(function (portProperty) {
                            var portResolverModel: any = {
                                identifier: portProperty.portIdentifier,
                                properties: portProperty.properties,
                                transceiverProperties: response.transceiverProperties,
                                states: response.status.portStates,
                                identifierPredicate: { portIdentifier: portProperty.portIdentifier },
                                shortcuts: portShortcuts,
                                shortcutAction: function () {
                                    DropdownDeviceService.openDeviceOnPortConfig($scope.device.name, this);
                                }
                            };

                            ports.push(DeviceSummaryResolver.resolvePort(portResolverModel));
                        });
                    }

                    var slotResolverModel: any = {
                        chassisIdentifier: chassi.identifier,
                        slotIdentifier: slot.identifier,
                        slotProperties: response.slotProperties,
                        cardProperties: response.cardProperties,
                        cardStates: response.status.cardStates,
                        ports: ports,
                        identifierPredicate: { slotIdentifier: slotIdentifier },
                        shortcuts: slotShortcuts,
                        shortcutAction: function () {
                            DropdownDeviceService.openDeviceOnSlotInventory($scope.device.name, this);
                        }
                    };

                    slots.push(DeviceSummaryResolver.resolveSlot(slotResolverModel));
                });
            });
        };

        var buildDeviceSummaryInfo = function (response) {
            var deviceSummaryInfo = [];
            enrichLinkAggregations(response, deviceSummaryInfo);
            enrichSlots(response, deviceSummaryInfo);

            return deviceSummaryInfo;
        };

        var verifyDeviceAccordionOnStorage = function (dataTable) {
            if (isThereDeviceAlreadyOpened($scope.loadSummaryOptions($scope.options), $scope.device.id)) {
                _.forEach(dataTable, function (data) {
                    _.forEach($scope.options.data, function (dataInOptions) {
                        if (_.isEqual(data.name, dataInOptions.name)) {
                            data.expand = dataInOptions.expand;
                            return;
                        }
                    });
                });
            }
            $scope.tableParams.reload();
        };

        var pollingStatusModel: any = {
            nextReloadTextTranslateKey: "device.polling.status.timeRemaining",
            loadingTextTranslateKey: "device.polling.status.loadingDataFromDevice",
            lastUpdateTextTranslateKey: "device.polling.status.lastPollingTime",
            updateButtonTranslateKey: "device.polling.status.loadDataFromDevice",
            updateFinishedCallback: getManagedDevice
        };

        AutoUpdaterService.setAutoUpdaterModel(pollingStatusModel);

        $scope.expandAll = function () {
            _.forEach($scope.tableParams.data, function (data) {
                data.expand = true;
            });
        };

        $scope.collapseAll = function () {
            _.forEach($scope.tableParams.data, function (data) {
                data.expand = false;
            });
        };

        $scope.hasProperties = function (info) {
            return !_.isEmpty(info.ports);
        };

        var isThereDeviceAlreadyOpened = function (devicesOpened, currentDevice) {
            return !_.isEmpty(devicesOpened);
        };

        var setGlobalSearchFromSessionStorage = function (dataInStorage) {
            if (!_.isEmpty(dataInStorage)) {
                $scope.globalFilter = dataInStorage.globalFilter;
                if (!_.isEmpty($scope.globalFilter)) {
                    $scope.globalSearch();
                }
            }
        };

        $scope.enableCustomFilter = function () {
            var timer;
            $scope.tableSettings = {
                getData: function (params) {
                    $timeout.cancel(timer);
                    timer = $timeout(function () {
                        var filters = $scope.tableParams.filter();
                        filters["$"] = $scope.globalFilter;
                        var processedFilters = TableFilterService.getProcessedFilters(filters);
                        var globalFilter = processedFilters["$"];
                        var filteredInfo = $scope.customTableFilter(initialTableData, processedFilters);

                        if (!_.isEmpty(globalFilter)) {
                            filteredInfo = $scope.customGlobalFilter(filteredInfo, globalFilter);
                        }

                        return filteredInfo;
                    }, 200);
                    return timer;
                }
            };

            $scope.tableParams.settings($scope.tableSettings);
            $scope.reloadTable($scope.tableSettings);
        };

        $scope.globalSearch = function () {
            $scope.reloadTable($scope.tableSettings);
        };

        $scope.customGlobalFilter = function (slotsToSearch, globalFilter) {
            if (!_.isEmpty(globalFilter)) {
                var filteredInfo = [];

                _.forEach(slotsToSearch, function (slot) {
                    slot.ports = $filter("filter")(slot.ports, globalFilter, TableFilterService.comparator);
                });
                filteredInfo = $filter("filter")(slotsToSearch, globalFilter, TableFilterService.comparator);

                return filteredInfo;
            } else {
                return slotsToSearch;
            }
        };

        $scope.customTableFilter = function (slotsToSearch, filters) {
            if ($scope.tableParams.hasFilter()) {
                var filteredInfo = angular.copy($filter("filter")(slotsToSearch, filters, TableFilterService.comparator));

                _.forEach(slotsToSearch, function (slot) {
                    if (!_.isEmpty(slot.ports)) {
                        var nestedFilter = $filter("filter")(slot.ports, filters, TableFilterService.comparator);
                        var slotAlreadyInFilteredData = _.find(filteredInfo, { name: slot.name });

                        if (!slotAlreadyInFilteredData && !_.isEmpty(nestedFilter)) {
                            var selectedSlot = _.defaults(_.omit(slot, "ports"), { ports: nestedFilter });
                            filteredInfo.push(selectedSlot);
                        } else if (slotAlreadyInFilteredData) {
                            slotAlreadyInFilteredData.ports = nestedFilter;
                        }
                    }
                });

                return $filter("orderBy")(filteredInfo, "name");
            } else {
                return slotsToSearch;
            }
        };

        $scope.reloadTable = function (tableSettings) {
            $scope.tableParams.settings(tableSettings);
            $scope.tableParams.reload();
            $rootScope.$broadcast("deviceStatus");
        };

        $scope.toggleDropdown = function () {
            $scope.openShortcuts = !$scope.openShortcuts;
        };

        $scope.goToDeviceConfig = function () {
            DevicesActionsService.goToDeviceConfig($scope.device);
        };

        $scope.tryApplyTemplate = function () {
            TemplateActionsService.tryApplyTemplate([$scope.device], TEMPLATE_TYPE.CLI);
        };

        $scope.viewTemplateApplication = function () {
            TemplateActionsService.viewTemplateApplication([$scope.device], [$scope.device.name], TEMPLATE_TYPE.CLI);
        };

        $scope.tryOpenEditCredentials = function () {
            EquipmentsModalsActionsService.tryOpenEditCredentials([$scope.device]);
        };

        $scope.tryOpenPropertiesConfig = function () {
            EquipmentsModalsActionsService.tryOpenPropertiesConfig([$scope.device], globalConfig);
        };

        $scope.tryToOpenDeviceSummary = function () {
            DevicePermissionService.checkPermissions($scope.device, permissionAction, permissionAction, noPermissionAction);
        };

        var extractObject = function (tableParams) {
            var extracted = [];

            _.forEach(tableParams.data, function (element) {
                extracted.push({
                    name: element.name,
                    expand: element.expand
                });
            });
            return extracted;
        };

        $scope.saveDeviceSummaryOptions = function () {
            var extracted = extractObject($scope.tableParams);
            if (_.isEmpty($scope.options)) {
                var parameter: any = {
                    data: extracted,
                    tableParamsFilter: $scope.tableParams.filter(),
                    globalFilter: $scope.globalFilter ? $scope.globalFilter : ""
                };

                UserPreferencesService.savePreferences(
                    parameter,
                    createWindowIdentifier($scope.device.id),
                    availablePreferencesKeys
                );
            } else {
                $scope.options.data = extracted;
                $scope.options.globalFilter = $scope.globalFilter ? $scope.globalFilter : "";

                UserPreferencesService.savePreferences(
                    $scope.options,
                    createWindowIdentifier($scope.device.id),
                    availablePreferencesKeys
                );
            }
        };

        $scope.$watch(
            "tableParams.data",
            function () {
                if ($scope.tableParams) {
                    $scope.saveDeviceSummaryOptions();
                }
            },
            true
        );

        $scope.$on("$destroy", function () {
            $scope.saveDeviceSummaryOptions();
            unregisterUpdateDeviceHeader();
        });

        $scope.$watch("device", function () {
            $scope.tryToOpenDeviceSummary();
        });
    }
]);
