"use strict";

import {
    AccordionParentData,
    TemplateInstance,
    EquipmentSelectionType
} from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { RuleFieldType,
    RuleTypesSet,
    RuleCriteria } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { TemplateInstanceRESTService } from "./template-instance-rest-service";

/* global Promise */

/**
 * @ngdoc service
 * @name nms.templates.TemplateInstanceValidator
 * @description
 * # TemplateInstanceValidator
 * Factory in the nms.templates.
 */
var app = angular.module("nms.templates");
app.factory("TemplateInstanceValidatorService", [
    "$rootScope",
    "TemplateInstanceRESTService",
    "ConverterService",
    "DeviceDiscoverService",
    "AuthenticationService",
    "VARIABLES",
    "TemplateInstanceUtils",
    "DEVICES_MODELS_WITHOUT_CLI",
    function (
        $rootScope,
        TemplateInstanceRESTService: TemplateInstanceRESTService,
        ConverterService,
        DeviceDiscoverService,
        AuthenticationService,
        VARIABLES,
        TemplateInstanceUtils,
        DEVICES_MODELS_WITHOUT_CLI
    ) {
        var TEMPLATE_INSTANCE_REMOVE_PERMISSION = "templateInstanceRemove";
        var TEMPLATE_INSTANCE_REMOVE_NMS_PERMISSION = "templateInstanceRemoveNms";
        var TEMPLATE_INSTANCE = "templateInstance";

        const service: any = {};

        interface VariableValidationResponse {
            result: boolean;
            messageKey: string;
            messageParams: string;
        }

        const verifyPersonalPermission = function (permissionToVerify) {
            return new Promise(function (resolve, reject) {
                AuthenticationService.getCredentials().then(function (credentials) {
                    const hasPermission = _.find(credentials.personalPermissions, function (personalPermission) {
                        return personalPermission.name === permissionToVerify && personalPermission.enabled;
                    });

                    hasPermission ? resolve(true) : reject(false);
                });
            });
        };

        service.validateGeneralForm = function (form, templateInstance) {
            if (form.name.$error.required) {
                $rootScope.showDialog({
                    translateKey: "form.error.requiredFieldName"
                });
                return false;
            }

            if (form.name.$error.pattern) {
                $rootScope.showDialog({
                    translateKey: "form.error.invalidFieldName"
                });
                return false;
            }

            if (templateInstance.keywords && templateInstance.keywords.length > 20) {
                $rootScope.showDialog({
                    translateKey: "templateInstanceform.error.invalidFieldKeywords"
                });
                return false;
            }

            return true;
        };

        /**
         * Valida os equipamentos selecionados no templateInstance. Se o tipo de seleção for
         * via filtros, chama o método validateFiltersForm, se for specific chama o validateEquipmentsForm.
         * Se não for nenhum desses casos, não há validação a ser feita então retorna true.
         */
        service.validateEquipmentsSelection = function (templateinstance: TemplateInstance, ruleTypes: RuleTypesSet): boolean {
            if (templateinstance.equipmentSelectionType === EquipmentSelectionType.SPECIFIC) {
                return validateEquipmentsForm(templateinstance);
            } else if (templateinstance.equipmentSelectionType === EquipmentSelectionType.FILTER) {
                return validateFiltersForm(templateinstance, ruleTypes);
            }
            return true;
        };

        /**
         * Valida os templates selecionados.
         * Se o tipo de seleção de equipamentos for specific, chama o método validateTemplatesInEquipmentAssociations para validar
         * se todos os equipamentos selecionados possuem templates selecionados.
         * Caso contrário, chama o método validateEmptyTemplateSelection para verificar se algum template foi selecionado.
         *
         * Esse método depende que a validação de equipamentos já tenha sido realizada.
         */
        service.validateTemplatesSelection = function (
            accordionData: Array<AccordionParentData>, templateInstance: TemplateInstance): boolean {

            if (templateInstance.equipmentSelectionType === EquipmentSelectionType.SPECIFIC) {
                return validateTemplatesInEquipmentAssociations(templateInstance);
            }
            return validateEmptyTemplateSelection(accordionData);
        };

        /**
         * Valida se pelo menos 1 template foi selecionado no accordion.
         * Nesse contexto, esse método só deve ser usado para o modo de visualização "Equipamentos por Template" pois
         * não verifica os filhos do accordion.
         */
        function validateEmptyTemplateSelection(accordionData: Array<AccordionParentData>): boolean {
            if (_.isEmpty(accordionData)) {
                $rootScope.showDialog({
                    translateKey: "templateinstanceform.error.noTemplateAdded"
                });
                return false;
            }
            return true;
        }

        /**
         * Valida se todos os equipamentos do TemplateInstance possuem templates associados.
         */
        function validateTemplatesInEquipmentAssociations(templateInstance: TemplateInstance) {
            const equipmentsWithoutTemplate = [];

            for (let i = 0; i < templateInstance.equipmentAssociations.length; i++) {
                if (templateInstance.equipmentAssociations[i].templateAssociations.length === 0) {
                    equipmentsWithoutTemplate.push(templateInstance.equipmentAssociations[i].equipmentDetails.name);
                }
            }

            if (equipmentsWithoutTemplate.length > 0) {
                $rootScope.showDialog({
                    translateKey: "templateinstanceform.error.noTemplateSelectedForEquipments",
                    params: equipmentsWithoutTemplate,
                    maxChars: 128
                });
                return false;
            }

            return true;
        }

        /**
         * Valida os filtros do templateInstance. É necessário que todos os filtros estejam com valores
         * preenchidos para passar nessa validação.
         */
        function validateFiltersForm(templateInstance: TemplateInstance, ruleTypes: RuleTypesSet): boolean {
            const ruleOptions = templateInstance.equipmentSelectionFilter.equipmentRuleOption.filter(ruleOption => {
                return ruleTypes[ruleOption.ruleType].type !== RuleFieldType.BOOLEAN;
            });
            const allRulesValues = _.flatten(_.pluck(ruleOptions, 'values'));

            if (_.some(allRulesValues, _.isEmpty)) {
                $rootScope.showDialog({
                    translateKey: "templateinstanceform.error.emptyFilterValue"
                });
                return false;
            }

            return true;
        }

        function validateEquipmentsForm(templateInstance: TemplateInstance): boolean {
            if (templateInstance.equipmentAssociations.length === 0) {
                $rootScope.showDialog({
                    translateKey: "templateinstanceform.error.noEquipmentAdded"
                });
                return false;
            }

            return true;
        }

        service.validateFilledVariables = function (vars) {
            var emptyVars = [];

            vars.forEach(function (variable) {
                if (!variable.value) {
                    emptyVars.push(variable.key);
                }
            });

            return emptyVars;
        };

        service.validateTemplateInstancesChecked = function (templateInstancesChecked) {
            if (templateInstancesChecked.length === 0) {
                $rootScope.showDialog({
                    translateKey: "popups.alert.noTemplateInstanceSelected"
                });
                return false;
            }

            return true;
        };

        const hasTemplate = (templateIds, selectedTemplate) => {
            return _.some(templateIds, (template) => template === selectedTemplate.id);
        }

        /**
         * Caso os templates selecionados em 'checkedTemplates' não existam em uma das associações de
         * template existentes, o sistema irá adicioná-lo no array canAddTemplates.
         * Com isso retornará verdadeiro, caso contrário retornará falso
         * Isso significa que mesmo em uma edição, o template poderá ser adicionado.
         */
        const canAddSelectedTemplatesInAllAssociations = function(equipments, checkedTemplates): boolean {
            const canAddTemplates = [];
            checkedTemplates.forEach(function(selectedTemplate) {
                equipments.forEach(function (equipment) {
                    const templateIds = _.map(equipment.templateAssociations, "templateId");
                    if (!hasTemplate(templateIds, selectedTemplate)) {
                        canAddTemplates.push(selectedTemplate);
                    }
                });
            })

            return canAddTemplates.length > 0;
        }

        /**
         * Verifica se existem templates iguais através da propriedade 'templateIds',
         * (na qual contém todos os id dos templates inseridos nas associações),
         * com os templates selecionados na modal.
         * Esse método retornará um array contendo os templates iguais.
         */
        const getEqualTemplates = function(templateIds, checkedTemplates): any {
            const fails = [];
            const allTemplates = Array.from(new Set(templateIds));
            checkedTemplates.forEach(function (template) {
                allTemplates.forEach(function (oldTemplate) {
                    if (template.id === oldTemplate) {
                        fails.push(template);
                    }
                });
            });
            return fails;
        }

        /**
         * Para validar se os templates selecionados na modal são iguais aos templates já existentes
         * nas associações, primeiramente ele verifica se:
         * Dentre os templates selecionados, existe algum deles que ainda pode ser adicionado em uma das
         * associações. Caso exista, o método 'canAddSelectedTemplatesInAllAssociations' irá adicioná-lo no
         * array, sendo assim não ocorrerá falha para templates iguais, e a variável fails retornará vazia.
         * Caso não seja mais possível adicionar os templates nas associações, o método
         * 'canAddSelectedTemplatesInAllAssociations' retornará false e o sistema verificará através
         * do método getEqualTemplates quais templates são iguais aos templates que foram selecionados.
         */
        service.validateEqualTemplates = function (templateIds, checkedTemplates, allEquipments): any {
            let fails = [];
            if (!canAddSelectedTemplatesInAllAssociations(allEquipments, checkedTemplates)) {
                fails = getEqualTemplates(templateIds, checkedTemplates);
            }

            return fails;
        };

        /**
         * Valida se é possível adicionar os templates na associação, templates novos não podem ser adicionados.
         * Mas se o template ja existir na associação, ele poderá ser adicionado.
         * @param checkedTemplates templates selecionados que podem ser adicionados
         * @param oldTemplates templates antigos que já existiam no template instance
         * @returns retorna os templates que falharam na validação ou vazio caso todos sejam válidos
         */
        service.validateAddTemplatesInAssociations = function (checkedTemplates, oldTemplates): any {
            const fails = [];
            checkedTemplates.forEach((selectedTemplate) => {
                const containsCheckedTemplate = (templateAssociations) =>
                    templateAssociations.templates.includes(selectedTemplate.id);

                if (!oldTemplates.some(containsCheckedTemplate)) {
                    fails.push(selectedTemplate);
                }
            });

            return fails;
        };

        service.areThereEquipmentToApply = function (equipmentAssociations) {
            var result = false;

            equipmentAssociations.forEach(function (equipment) {
                if (equipment.apply) {
                    result = true;
                    return;
                }
            });

            return result;
        };

        service.areThereTemplatesToApply = function (equipmentAssociations) {
            let result = false;

            equipmentAssociations.forEach(function (equipmentAssociation) {
                const hasTemplatesToApply = _.some(equipmentAssociation.templateAssociations, { apply: true });

                if (hasTemplatesToApply) {
                    result = true;
                }
            });

            return result;
        };

        service.validateVariables = function (templateInstance: TemplateInstance, successCallback: () => void) {
            // FIXME - Conversão devido a issue: https://github.com/mbenford/ngTagsInput/issues/678
            if (templateInstance.keywords) {
                templateInstance.keywords = ConverterService.convertArrayObjectToStringArray(templateInstance.keywords);
            }

            TemplateInstanceRESTService.validateVariables(templateInstance).then(successCallback);
        };

        service.validateDeviceManagerPermissions = function (templateInstances, successCallback) {
            var devicesForPermissionsCheck = [];

            templateInstances.forEach(templateInstance => {
                const devices = templateInstance.equipmentAssociations.map(equipmentAssociation => {
                    return {
                        devModelCode: equipmentAssociation.equipmentDetails.modelCode,
                        vendorCode: equipmentAssociation.equipmentDetails.vendorCode,
                        locationId: equipmentAssociation.equipmentDetails.locationId,
                        name: equipmentAssociation.equipmentDetails.name
                    }
                });
                devicesForPermissionsCheck.push(...devices);
            });

            DeviceDiscoverService.getDevicesWithoutManagerPermission(devicesForPermissionsCheck).then(function (response) {
                var devicesWithoutPermissions = response.plain();

                if (_.isEmpty(devicesWithoutPermissions)) {
                    successCallback();
                } else {
                    const devicesWithoutPermissionsNames = _.map(devicesWithoutPermissions, "name");

                    const templateInstancesWithoutPermissionsForAllDevices = _.filter(
                        templateInstances,
                        function (templateInstance) {
                            const devicesName = templateInstance.equipmentAssociations
                                .map(({ equipmentDetails }) => equipmentDetails.name);

                            return devicesName.some(deviceName => devicesWithoutPermissionsNames.includes(deviceName));
                        }
                    );

                    const templateInstancesName = templateInstancesWithoutPermissionsForAllDevices
                        .map(templateInstance =>  templateInstance.name);

                    $rootScope.showDialogSingularOrPlural({
                        translateObjs: [
                            {
                                pluralKey: "addDeviceModal.error.userWithoutPermissionOnSomeDevices",
                                isPlural: true
                            }
                        ],
                        listParams: templateInstancesName
                    });
                }
            });
        };

        service.validateReadOnlyVariables = function (variablesAssociations, templates): VariableValidationResponse {
            const response: VariableValidationResponse = { result: true, messageKey: "", messageParams: "" };

            const globalReadOnlyMultiDeviceVariables = filterGlobalVariablesMultiDevices(
                variablesAssociations,
                VARIABLES.READ_ONLY
            );

            if (globalReadOnlyMultiDeviceVariables.length > 0) {
                response.result = false;
                response.messageKey = "templateinstanceform.error.multiDeviceReadOnlyGlobalVariable";
                response.messageParams = _.map(globalReadOnlyMultiDeviceVariables, function (variablesAssociation) {
                    const templateIds = _.uniq(_.flatten(_.map(variablesAssociation.deviceAssociations, "templateIds")));
                    const templateNames = _.map(templateIds, function (templateId) {
                        return templates[templateId].templateName;
                    });
                    return variablesAssociation.var.name + ": " + templateNames.join(", ");
                });
            }

            return response;
        };

        service.validateActionVariables = function (variablesAssociations, templates): VariableValidationResponse {
            const response: VariableValidationResponse = { result: true, messageKey: "", messageParams: "" };

            const globalActionMultiDeviceVariables = filterGlobalVariablesMultiDevices(variablesAssociations, VARIABLES.ACTION);

            if (globalActionMultiDeviceVariables.length > 0) {
                response.result = false;
                response.messageKey = "templateinstanceform.alert.multiDeviceActionGlobalVariable";
                response.messageParams = _.map(globalActionMultiDeviceVariables, function (variablesAssociation) {
                    const templateIds = _.uniq(_.flatten(_.map(variablesAssociation.deviceAssociations, "templateIds")));
                    const templateNames = _.map(templateIds, (templateId) => templates[templateId].templateName);
                    return `${variablesAssociation.var.name}: ${templateNames.join(", ")}`;
                }).join("<br>");
            }

            return response;
        };

        function filterGlobalVariablesMultiDevices(variablesAssociations, variableType) {
            return _.filter(variablesAssociations, function (variablesAssociation) {
                return (
                    variablesAssociation.var.type === variableType &&
                    variablesAssociation.var.globalScope === true &&
                    variablesAssociation.deviceAssociations.length > 1
                );
            });
        }

        service.validateTemplatesPermissionByTemplates = function (templateIds: Array<string>) {
            return new Promise(function (resolve, reject) {
                TemplateInstanceRESTService.validateTemplatesPermissionByTemplates(templateIds).then(function (hasPermission) {
                    hasPermission ? resolve(true) : reject(false);
                });
            });
        };

        service.validateTemplatesPermissionByTemplateInstances = function (templateInstanceIds: Array<string>) {
            return new Promise(function (resolve, reject) {
                TemplateInstanceRESTService.validateTemplatesPermissionByTemplateInstances(templateInstanceIds).then(function (
                    hasPermission
                ) {
                    hasPermission ? resolve(true) : reject(false);
                });
            });
        };

        /**
         * Verifica se existem templates com variáveis locais, caso afirmativo retorna os templates encontrados.
         */
        service.getTemplatesWithLocalVars = function (templates: any): any {
            return _.filter(templates, function (template) {
                return _.some(template.variables, "globalScope", false);
            });
        }

        service.validateTemplatesWithLocalVars = function(templates: any, templateType: string, nameKey: string): boolean {
            const templatesWithLocalVars = this.getTemplatesWithLocalVars(templates);
            if (templatesWithLocalVars.length > 0) {
                service.showDialogWhenInvalidTemplates(templatesWithLocalVars, nameKey, templateType,
                    "modals.error.template.local.variables");

                return false;
            }

            return true;
        }

        service.showDialogWhenInvalidTemplates = function(invalidTemplates: any, field: string,
            templateType: string, translateKey: string) {
            const templateNames = _.pluck(invalidTemplates, field);
            const key = TemplateInstanceUtils.getKeyByTemplateType(templateType);
            $rootScope.showDialog({
                translateKey: `${translateKey}.${key}.invalidTemplates`,
                params: templateNames,
                maxChars: 37
            });
        }

        /**
         * Valida os equipamentos definidos no filtro de parâmetros ou todos os equipamentos da base,
         * caso seja vazio informa a mensagem de acordo com o filtro que estava selecionado.
         */
        service.validateEquipmentAssociationsForFilters = function(equipments: any, isParameterFilter: boolean): boolean {
            if (equipments.length === 0) {
                const translatedKey = isParameterFilter
                    ? "templateinstanceform.templatedevices.filter.parameters.devices.empty"
                    : "templateinstanceform.templatedevices.filter.all.device.empty";
                $rootScope.showDialog({ translateKey: translatedKey });
                return false;
            }

            return true;
        }

        service.validateTemplateInstanceRemovePermission = function () {
            return verifyPersonalPermission(TEMPLATE_INSTANCE_REMOVE_PERMISSION);
        };

        service.validateTemplateInstanceRemoveNMSPermission = function () {
            return verifyPersonalPermission(TEMPLATE_INSTANCE_REMOVE_NMS_PERMISSION);
        };

        service.validateTemplateInstancePermission = function () {
            return verifyPersonalPermission(TEMPLATE_INSTANCE);
        };

        service.supportsCLITemplateApplication = function(productModel) {
             // FIXME[US-15988] Por enquanto estamos inserindo em uma constante 'DEVICES_MODELS_WITHOUT_CLI' os equipamentos
             // que não suportam aplicações de template, porém será mapeado para retornarmos essas informações do backend.
            if (_.contains(Object.values(DEVICES_MODELS_WITHOUT_CLI), productModel)) {
                return false;
            }
            return true;
        }

        service.getNotSupportedDevicesCLITemplateApplications = function(devices) {
            return devices.filter(device => !service.supportsCLITemplateApplication(device.model));
        }

        return service;
    }
]);
