"use strict";

var app = angular.module("nms.management");
app.service("ManagePermissionsDevicesRestService", ["Restangular",
    function(Restangular) {
        var baseURL = "/inventory/device/models";
        const service: any = {};

        /**
         * Requisita modelos de equipamentos estáticos vendorizados.
         *
         * @returns {Promise} Promise para retorno dos modelos.
         */
        service.getAllStatic = function() {
            return Restangular.all(baseURL + "/static").customGET();
        };

        return service;
    }
]);
