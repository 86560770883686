import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

/**
 * Upgrade da diretiva ng-include-wrapper desenvolvida em AngularJS.
 * Esta diretive foi feita para possibilitar o uso do ng-include do AngularJS
 * no contexto do Angular 8. O conteúdo a ser incluído estará no contexto do AngularJS.
 *
 * Devido a sua finalidade, seu uso deve ser feito apenas em casos específicos onde se prova necessário,
 * o mais comum sendo a migração de um componente AngularJS que usa ng-include
 */
@Directive({
    selector: "ng-include-wrapper",
})
export class NgIncludeWrapper extends UpgradeComponent {
    @Input() templateUrl?: string;
    @Input() controller?: any;
    @Input() formName?: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super("ngIncludeWrapper", elementRef, injector);
    }
}