import { Injectable } from "@angular/core";
import { BackupJobData, DevicesFilter, JobExecutionStatus, SchedulerJob } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { BackupDevicesFilter } from "./create-backup-modal/tabs/backup-devices-tab/backup-devices-tab.model";
import { MatchingRules, Rule } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { EquipmentSelectionType } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { MatchingRulesUtilsService } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules-utils.service";
import { BackupDevicesScheduler } from "./create-backup-modal/tabs/backup-scheduler-tab/backup-scheduler-tab.model";
import { BackupDevicesGeneral } from "./create-backup-modal/tabs/backup-general-tab/backup-general-tab.model";

/**
 * Utilitário para tratar dados da tarefa de backup de equipamentos.
 */
@Injectable({
    providedIn: "root"
})
export class DeviceBackupUtilsService {

    constructor(private matchingRulesUtils: MatchingRulesUtilsService) { }

    buildDevicesFilter(schedulerJob: SchedulerJob): BackupDevicesFilter {
        const backupJobData: BackupJobData = (schedulerJob.jobData as BackupJobData);
        const model: BackupDevicesFilter = new BackupDevicesFilter();
        model.devicesFilter = backupJobData.devicesFilter;

        model.matchingRules = model.devicesFilter.equipmentSelectionType === EquipmentSelectionType.FILTER
            ? this.buildRulesFromJob(model.devicesFilter)
            : this.matchingRulesUtils.initMatchingRules();

        return model;
    }

    buildDeviceBackupTriggers(schedulerJob: SchedulerJob): BackupDevicesScheduler {
        return {
            jobType: schedulerJob.type,
            triggers: schedulerJob.triggers,
            id: schedulerJob.id
        }
    }

    buildDeviceBackupGeneralInfo(schedulerJob: SchedulerJob): BackupDevicesGeneral {
        const backupJobData = schedulerJob.jobData as BackupJobData;
        return {
            description: backupJobData.description,
            name: schedulerJob.name,
            enabled: schedulerJob.enabled,
            exportBackups: backupJobData.exportBackups,
            status: schedulerJob.status || JobExecutionStatus.NOT_REQUESTED
        }
    }

    private buildRulesFromJob(devicesFilter: DevicesFilter): MatchingRules {
        const rules: Rule[] = devicesFilter.deviceFilterRules.map<Rule>((rule) =>
        ({
            ruleType: rule.ruleType,
            values: rule.values,
            field: rule.deviceField
        } as Rule)
        );

        return {
            criteria: devicesFilter.rulesCriteria,
            rules: rules,
            useRules: true
        };
    }
}
