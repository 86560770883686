"use strict";

var app = angular.module("nms");

/**
* Serviço responsável por realizar a conversão de unidades de medidas.
*/
app.service("UnitConverterService", [
    function() {
        const service: any = {};

        var BASE = 1000;
        var acceptedDataUnits = [
            {identifier: "k", exponent: 1},
            {identifier: "m", exponent: 2},
            {identifier: "g", exponent: 3},
            {identifier: "t", exponent: 4},
            {identifier: "p", exponent: 5}
        ];

        /**
         * Retorna o valor absoluto com precisão de 2 casas.
         * Isso é necessário devido ao requisito de que o valor exibido deve possuir precisão de 2 casas decimais,
         * garantindo assim que não haja inconsistência de valores.
         */
        service.getFixedAbsoluteValue = function(absoluteValue) {
            if (!isNaN(absoluteValue)) {
                var value: number = Number(absoluteValue);
                if (value >= 1000) {
                    var exponent: number = Math.floor(Math.log(value) / Math.log(BASE));
                    var multiplier: any = Math.pow(BASE, exponent);
                    var fixedValue: any = (value / multiplier).toFixed(2);

                    value = fixedValue * multiplier;
                }

                return value;
            }

            return null;
        };

        service.getAbsoluteValueByDataUnit = function(data) {
            if (_.isString(data)) {
                var parts = _.without(data.split(/^(?:\s*)(\d*(?:\.\d+)?)(?:\s*)([a-zA-Z]*)$/g), "");
                var value = parts[0];
                var unit = parts[1];

                if (!isNaN(value) && unit) {
                    var resultedUnit = _.find(acceptedDataUnits, function(dataUnit) {
                        var regex = new RegExp("^(" + dataUnit.identifier + ")", "gi");
                        return unit ? regex.test(unit) : false;
                    });

                    var multiplier = resultedUnit ? Math.pow(BASE, resultedUnit.exponent) : 1;

                    return Number(value) * multiplier;
                }
            }

            return data;
        };

        return service;
    }
]);
