"use strict";

/**
* @ngdoc factory
* @name AuthenticationModule.SsoService
* @description - Responsável por verificar se o NAM está habilitado ou não.
*/
var app = angular.module("AuthenticationModule");

app.factory("SsoService", ["$rootScope", "AuthenticationService", "$q",
    function($rootScope, authenticationService, $q) {
        const service: any = {};

        service.isNamEnabled = function() {
            if (_.isUndefined($rootScope.isNamEnabled)) {
                return authenticationService.namServiceEnable().then(function(namEnabled) {
                    $rootScope.isNamEnabled = !!namEnabled;
                    return $rootScope.isNamEnabled;
                });
            } else {
                return $q.when($rootScope.isNamEnabled);
            }
        };

        return service;
    }
]);
