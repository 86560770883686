import { Inject, Injectable } from "@angular/core";
import { SchedulerJob, TemplateApplicationJobData } from "./scheduler.models";
import { ExecutionOrigin, TemplateInstanceResult, TemplateInstanceTabs } from "../template/template-instance/template-instance-models";
import {
    ANGULARJS_ROOTSCOPE, APPLICATION_COMMANDS, NG_DIALOG, NMS_STATES, STATE
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { SchedulerInterface } from "./scheduler.interface";
import { SchedulerListService } from "./scheduler-list/scheduler-list.service";
import { TemplateType } from "../template/template/template.interface";

interface TemplateInstanceEditRouterParameters {
    templateInstance: any;
    activeTab: TemplateInstanceTabs;
    disableNavigation: boolean;
    onFinishOrCancelRedirectRoute: string;
}

/**
 * Implementação de SchedulerInterface para agendamentos do tipo aplicação de template.
 */
@Injectable({
    providedIn: "root"
})
export class SchedulerTemplateApplicationService implements SchedulerInterface {

    constructor(@Inject(STATE) private readonly $state: any,
        @Inject(NMS_STATES) private readonly nmsStates: any,
        @Inject(APPLICATION_COMMANDS) private readonly APPLICATION_COMMANDS: any,
        @Inject(NG_DIALOG) private readonly ngDialog: any,
        @Inject(ANGULARJS_ROOTSCOPE) private readonly $rootScope,
        private readonly schedulerListService: SchedulerListService,) { }

    editScheduler(schedulerJob: SchedulerJob) {
        const { jobData } = schedulerJob;
        const { templateApplicationId: id, templateType } = jobData as TemplateApplicationJobData;
        const templateInstance = { id, type: templateType };

        const data: TemplateInstanceEditRouterParameters = {
            templateInstance,
            activeTab: "scheduler",
            disableNavigation: true,
            onFinishOrCancelRedirectRoute: this.nmsStates.scheduler.list
        };

        this.$state.go(this.nmsStates.templateInstance.edit, data, { inherit: false });
    }

    viewResults(schedulerJob: SchedulerJob) {
        const { jobData } = schedulerJob;
        const { templateApplicationId, templateType } = jobData as TemplateApplicationJobData;
        this.getResultsFromExecution(templateApplicationId, templateType);
    }

    private getResultsFromExecution(templateApplicationId: string, templateType: TemplateType) {
        this.schedulerListService.getExecutionResultsForApply(templateApplicationId)
            .subscribe((templateInstanceResult: TemplateInstanceResult) =>
                this.openResultsModal(templateInstanceResult, templateApplicationId, templateType));
    }

    private openResultsModal(templateInstanceResult: TemplateInstanceResult, templateApplicationId: string,
        templateType: TemplateType) {
        const resultsSize = templateInstanceResult.equipmentAssociationResults.length;
        const hasTemplateApplying = templateInstanceResult.equipmentAssociationResults.some(equipmentAssociationResult =>
            equipmentAssociationResult.templateResults.some(templateResult => templateResult.status === "APPLYING")
        )
        const hideResultDetails =
            hasTemplateApplying && resultsSize > this.APPLICATION_COMMANDS.MAX_EQUIPMENTS_TO_SHOW_RESULT_DETAILS;

        if (resultsSize > 0) {
            this.ngDialog.open({
                template: "templates/features/template/template-instance/modals/results-modal.html",
                controller: "ResultsModalCtrl",
                className: hideResultDetails ? "medium-modal hide-details" : "big-modal",
                resolve: {
                    results() {
                        return templateInstanceResult;
                    },
                    templateType() {
                        return templateType;
                    },
                    hideResultDetails() {
                        return hideResultDetails;
                    },
                    executionOrigin() {
                        return ExecutionOrigin.SCHEDULER;
                    },
                    templateInstanceId() {
                        return templateApplicationId;
                    }
                }
            });
        } else {
            this.$rootScope.showDialog({
                translateKey: "templateinstancelisting.results.notApplied"
            });
        }
    }
}
