import { Injectable } from "@angular/core";
import { TimeTriggerData, TriggerType } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";

/**
 * Classe responsável por realizar a validação do agendamento por data e hora
 */
@Injectable({
    providedIn: "root"
})
export class TimeTriggerValidator {
    constructor() { }

    validate(triggerType: TriggerType, triggerData: TimeTriggerData): void {
        if (triggerType === TriggerType.ON_DEMAND) {
            return;
        }

        if (!triggerData.initialDate) {
            throw new Error("scheduler.validation.error.initialDateEmpty");
        }

        if (triggerData.finalDateEnabled && !triggerData.finalDate) {
            throw new Error("scheduler.validation.error.finalDateEmpty");
        }

        if (triggerType === TriggerType.ONCE && triggerData.finalDateEnabled) {
            throw new Error("scheduler.validation.error.finalDateEnabledWhenTriggerTypeIsOnce");
        }

        if (triggerData.initialDate.getTime() < new Date().setSeconds(0)) {
            throw new Error("scheduler.validation.error.initialDateLessThanCurrentDate");
        }

        if (triggerData.finalDateEnabled && triggerData.initialDate.getTime() > triggerData.finalDate.getTime()) {
            throw new Error("scheduler.validation.error.initialDateBiggerThanFinalDate");
        }
    }
}
