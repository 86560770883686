"use strict";

/**
* @ngdoc Controller
* @name nms.management.ManagePermissionsLocationsController
* @description Realiza gerência das permissões de locations.
*/
var app = angular.module("nms.management");

app.controller("ManagePermissionsLocationsController", ["$scope", "DEVICE_PERMISSION", "$translate",
    "ManagePermissionsLocationsService",
    function($scope, DEVICE_PERMISSION, $translate, ManagePermissionsLocationsService) {
        $scope.permissionOptions = [DEVICE_PERMISSION.MANAGE, DEVICE_PERMISSION.VIEW, DEVICE_PERMISSION.NOTHING];
        $scope.propagatePermission = true;
        $scope.owner.model.dataTree = [];

        /**
        *   Seta a permissão recursivamente para os filhos.
        */
        var setLocationPermissionRecursively = function(locations, permission) {
            _.forEach(locations, function(location) {
                if (location.children && location.children.length > 0) {
                    setLocationPermissionRecursively(location.children, permission);
                }

                location.permission.value = permission;
            });
        };

        $scope.columnsOptions = [
            {
                field: "permission",
                displayName: $translate.instant("manage.permissions.permission"),
                cellTemplate: "<segmented-control prefix-id='{{row.branch.uid}}' options='cellTemplateScope.permissionOptions' "
                    + "model='row.branch.permission' translate-key-prefix='manage.permissions.devices.permission.level' "
                    + "ng-click='cellTemplateScope.verifyAndPropagatePermission(row.branch)' value='databaseValue'/>",
                cellTemplateScope: {
                    permissionOptions: $scope.permissionOptions,
                    verifyAndPropagatePermission: function(data) {
                        if ($scope.propagatePermission) {
                            setLocationPermissionRecursively(data.children, data.permission.value);
                        }
                    }
                }
            }
        ];

        $scope.expandingProperty = {
            field: "locationPath",
            displayName: $translate.instant("manage.permissions.location")
        };

        ManagePermissionsLocationsService.getLocationPermissions($scope.owner.model.editData).then(function(locations) {
            $scope.owner.model.dataTree = locations;
        });
    }
]);
