"use strict";

var app = angular.module("nms");

app.directive("goToPageClick", [function() {
    return function(scope, element, attrs) {
        element.bind("click", function() {
            var page = parseInt(attrs.goToPageClick);

            if ((page + 1) > 0 && page < scope.pageDetails.totalPages
                && scope.pageDetails.currentPage && scope.filters.pageSize
                && scope.pageDetails.currentPage > 0 && scope.filters.pageSize > 0) {
                scope.pageDetails.currentPage = page + 1;
                scope.filters.pageNumber = page;

                scope.setPageFiltersSession();
                scope.loadPage();
            }
        });
    };
}]);
