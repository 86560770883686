"use strict";

/**
 * @ngdoc controller
 * @name nms.components.autoUpdater:AutoUpdaterModel
 * @description
 *
 * # AutoUpdaterModel
 *
 * Modelo de dados utilizados pelo componente de atualização automática
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.service("AutoUpdaterModel", ["$log",
    function($log) {
        var defaultUpdateFunction = function() {
            $log.warn("No implementation found, please provide a custom update function");
        }

        function AutoUpdaterModel(data) {
            this.updateFunction = _.get(data, "updateFunction", defaultUpdateFunction);
            this.updateFinishedCallback = _.get(data, "updateFinishedCallback");
            this.updateButtonEnabled = _.get(data, "updateButtonEnabled", true);
            this.message = _.get(data, "message", null);
            this.updateButtonTranslateKey = _.get(data, "updateButtonTranslateKey", "autoUpdater.refresh")
            this.loadingTextTranslateKey = _.get(data, "loadingTextTranslateKey", "autoUpdater.loading");
            this.lastUpdateTextTranslateKey = _.get(data, "lastUpdateTextTranslateKey");
            this.nextReloadTextTranslateKey = _.get(data, "nextReloadTextTranslateKey", "autoUpdater.nextRefresh")
        }

        AutoUpdaterModel.prototype = {
            setUpdateFunction: function(updateFunction) {
                this.updateFunction = updateFunction;
            },
            setUpdateFinishedCallback: function(updateFinishedCallback) {
                this.updateFinishedCallback = updateFinishedCallback;
            },
            setUpdateButtonEnabled: function(updateButtonEnabled) {
                this.updateButtonEnabled = updateButtonEnabled;
            },
            setMessage: function(message) {
                this.message = message;
            },
            setUpdateButtonTranslateKey: function(updateButtonTranslateKey) {
                this.updateButtonTranslateKey = updateButtonTranslateKey;
            },
            setLoadingTextTranslateKey: function(loadingTextTranslateKey) {
                this.loadingTextTranslateKey = loadingTextTranslateKey;
            },
            setLastUpdateTextTranslateKey: function(lastUpdateTextTranslateKey) {
                this.lastUpdateTextTranslateKey = lastUpdateTextTranslateKey;
            },
            setNextReloadTextTranslateKey: function(nextReloadTextTranslateKey) {
                this.nextReloadTextTranslateKey = nextReloadTextTranslateKey;
            }
        }

        return AutoUpdaterModel;
    }
]);
