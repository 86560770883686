import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { SimpleModalComponent } from "ngx-simple-modal";
import { NmsToastrService } from "../../elements/nms-toastr/nms-toastr.service";
import { AuditRetentionService } from "./audit-retention.service";

export interface AuditRetentionModel {}

/* Modelo a ser utilizado na modal de retenção de auditoria */
export class AuditSettings {
    /* Período de retenção de registros.*/
    maxAgeRetentionInDays: number;
    /* Habilita/desabilita retenção de auditoria */
    exportOldRecords: boolean;
}

/**
 * Classe responsável por exibir a modal de retenção de auditoria.
 */
@Component({
    selector: "audit-retention-modal",
    templateUrl: "./audit-retention-modal.component.html",
    styleUrls: ["./audit-retention-modal.component.css"]
})
export class AuditRetentionModalComponent extends SimpleModalComponent<AuditRetentionModel, any> implements OnInit {
    oldAuditRetention: AuditSettings;
    currentAuditRetention: AuditSettings;
    auditRetentionForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
        @Inject(ANGULARJS_TRANSLATE) private translate: any,
        private toastr: NmsToastrService,
        private auditRetentionService: AuditRetentionService) {
        super();
        this.createAuditRetentionForm();
        this.auditRetentionService.closeModalEmmiter.subscribe(() => this.close());
    }

    ngOnInit(): void {
        this.getAuditSettingsRetention();
    }

    /**
     * Cria um form com os dados e os validadores da modal de retenção.
     * maxAgeRetentionInDays: Insere o validator com o intervalo permitido 30-2000
     * exportOldRecords: Caso não tenha nenhum valor pré-configurado no banco, vem habilitado como default.
     */
    createAuditRetentionForm(): void {
        this.auditRetentionForm = this.formBuilder.group({
            maxAgeRetentionInDays: [365, [Validators.min(30), Validators.max(2000), Validators.required]],
            exportOldRecords: [true, null]
        });
      }

    /**
     * Salva os dados inseridos na modal caso tenha sido alterado as configurações.
     * Exibe um toaster de erro caso as alterações não tenham sido alteradas.
     */
    save() {
        this.currentAuditRetention = {
            maxAgeRetentionInDays: this.auditRetentionForm.value.maxAgeRetentionInDays,
            exportOldRecords: this.auditRetentionForm.value.exportOldRecords
        };

        if (this.isAuditRetentionEquals()) {
            return this.toastr.error(this.translate.instant("audit.retention.noChangesConfig"));
        }
        this.auditRetentionService.saveAuditRetentionSettings(this.currentAuditRetention);
    }

    /**
     * Busca os dados do banco de dados e chama o método para atualizar o form.
     */
    getAuditSettingsRetention(): void {
        this.auditRetentionService.getAuditRetentionSettings().subscribe((auditSettingsRetention: AuditSettings) => {
            this.oldAuditRetention = auditSettingsRetention;
            this.updateAuditSettingsRetention();
        });
    }

    /**
     * Atualiza o form com os dados da configuração retornados do banco de dados.
     */
    private updateAuditSettingsRetention(): void {
        this.auditRetentionForm.patchValue(this.oldAuditRetention);
    }

    /**
     * Verifica se houve alteração nas credenciais de auditoria
     * oldAuditRetention: dados antes da atualização das configurações da auditoria
     * currentAuditRetention: dados atualizados das configurações da auditoria
     * @returns boolean
     */
    private isAuditRetentionEquals(): boolean {
        return _.isEqual(this.oldAuditRetention, this.currentAuditRetention);
    }

}
