"use strict";

/**
* @ngdoc directive
* @name nms.templates.directive:scrollOption
* @description Diretiva para fazer scroll até a opção adicionada na lista de opções de uma variável de seleção
*/
angular.module("nms.templates")
.directive("scrollOption", function() {
    return function(scope, elem, attributes, value) {
        setTimeout(function() {
            if (scope.$last === true) {
                scope.$emit(attributes.scrollOption);
                $(".scroll-options-list li").each(function() {
                    if ($.trim($(this).text()) == scope.optionToScroll) {
                        elem = $(this);
                    }
                });
                $(".scroll-options-list").scrollTop(elem[0].offsetTop - 155);
            }
        }, 100);
    };
});
