"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.LocationRestService
* @description
* # LocationRestService - Serviço REST para busca das locations
* Factory in the nms.manageDevice.
*/
var app = angular.module("nms.manageDevices");
app.factory("LocationRestService", ["Restangular", function(Restangular) {
    var basePath = "/inventory";

    return {
        getAllLocations: function(httpConfig) {
            return Restangular.all(basePath + "/location/").withHttpConfig(httpConfig).customGET();
        }
    };
}]);
