import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { NmsWizardStep, NmsWizardStepComponent, NmsWizardStepValidationResponse } from "@nms-angular-toolkit/nms-wizard";
import { SchedulerListService } from "@nms-ng2/app/modules/scheduler/scheduler-list/scheduler-list.service";
import { JobType } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { TriggersValidatorService } from "@nms-ng2/app/modules/scheduler/triggers/triggers-validator.service";
import { AUTHENTICATION_SERVICE, NMS_FEATURES } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { BackupDevicesScheduler } from "./backup-scheduler-tab.model";
import { SchedulerService } from "@nms-ng2/app/modules/scheduler/scheduler.service";
import { CreateBackupService } from "../../create-backup.service";

/**
 * Aba de configuração de agendamento do backup.
 */
@Component({
    selector: "backup-scheduler-tab",
    templateUrl: "./backup-scheduler-tab.component.html",
    styleUrls: ["./backup-scheduler-tab.component.scss"]
})
export class BackupSchedulerTabComponent extends NmsWizardStepComponent implements OnInit {
    model: BackupDevicesScheduler = new BackupDevicesScheduler();
    hasSchedulerPermission: boolean;

    constructor(@Inject(AUTHENTICATION_SERVICE) private authenticationService: any,
                @Inject(NMS_FEATURES) private nmsFeatures: any,
                private triggersValidatorService: TriggersValidatorService,
        private schedulerListService: SchedulerListService,
        private schedulerService: SchedulerService,
        private createBackupService: CreateBackupService,
        private ref: ChangeDetectorRef) {
        super();
        this.hasSchedulerPermission = this.authenticationService.hasPermission(this.nmsFeatures.scheduler.feature);
    }

    ngOnInit(): void {
        this.handleLoadContent();
    }

    setContext(context: BackupDevicesScheduler): void {
        this.model.triggers = undefined
        this.ref.detectChanges();
        this.model = Object.assign(this.model, context);
    }

    async loadContent(): Promise<void> {
        return await new Promise(() => this.fillInitialData());
    }

    allowPrevious(): NmsWizardStepValidationResponse {
        return this.validate();
    }

    allowFinish(): NmsWizardStepValidationResponse {
        return this.validate();
    }

    allowNext(): NmsWizardStepValidationResponse {
        return this.validate();
    }

    finishAction(tabs: Array<NmsWizardStep>): void {
        const schedulerJob = this.createBackupService.buildDeviceBackupScheduler(tabs);
        this.schedulerListService.save(schedulerJob);
    }

    comingFromPreviousStep() {
        if (!this.model.triggers) {
            this.handleLoadContent();
        }
    }

    private fillInitialData() {
        const schedulerJob = this.schedulerService.initSchedulerJob(null, JobType.BACKUP);
        this.model.triggers = schedulerJob.triggers;
        this.model.jobType = schedulerJob.type;
    }

    private validate(): NmsWizardStepValidationResponse {
        if (this.triggersValidatorService.validate(this.model.triggers)) {
            return new NmsWizardStepValidationResponse(true);
        }
    }
}
