import { Injectable, Inject } from "@angular/core";
import { TimeTriggerData, Trigger, TriggerType } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { TriggersService } from "@nms-ng2/app/modules/scheduler/triggers/triggers.service";

/**
 * Classe utilitária para funções relacionadas ao disparador de Data/Hora.
 **/
@Injectable({
    providedIn: "root"
})
export class TimeTriggerService {

    constructor(
        @Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
        private readonly triggersService: TriggersService
    ) {}

    getFrequencyTriggerTypeLabel(type: TriggerType, initialDate: Date): string {
        const frequency: string = this.translate.instant(`scheduler.time.trigger.${type}`);
        switch (type) {
            case TriggerType.WEEKLY:
                const dayFromWeek = this.getPluralDayOfWeek(initialDate);
                return frequency.replace("{1}", dayFromWeek).replace("{0}", this.getDayGender(initialDate));
            case TriggerType.MONTHLY:
                const dayFromMonth = initialDate.getDate().toString();
                return frequency.replace("{0}", dayFromMonth);
            case TriggerType.YEARLY:
                const dayAndMonth = initialDate.toLocaleString(this.triggersService.getLocale(),
                    { day: "numeric", month: "numeric" });
                return frequency.replace("{0}", dayAndMonth);
            default:
                return frequency;
        }
    }

    /**
     * Atualiza a data final de um trigger de data.
     * @param eventDate: Data inicial do evento
     * @param daysToAdd: Quantidades de dias que serão somados a partir da data inicial. (Opcional. default (0))
     **/
    setFinalDate(trigger: Trigger, eventDate: Date, daysToAdd = 0) {
        const timeTriggerData = trigger.triggerData as TimeTriggerData;

        if (!timeTriggerData.finalDateEnabled) {
            const auxDate: number = eventDate.getTime();
            const setFinalDate: Date = new Date(auxDate);
            setFinalDate.setDate(setFinalDate.getDate() + daysToAdd);
            timeTriggerData.finalDate = setFinalDate;
        }
    }

    private getDayGender(date: Date): string{
        if (this.triggersService.isPtBr(this.translate.use())) {
            const dayOfTheWeek = date.getDay();
            const isWeekendDay = dayOfTheWeek === this.triggersService.SATURDAY || dayOfTheWeek === this.triggersService.SUNDAY;
            return isWeekendDay ? "aos" : "às";
        } else {
            return "";
        }
    }

    private getPluralDayOfWeek(date: Date) {
        if (this.triggersService.isPtBr(this.translate.use())) {
            const weekDayLongName = date.toLocaleString(this.triggersService.LOCALE_PT_BR_I18N, { weekday: "long" });
            if (weekDayLongName.includes("-")) {
                const [firstPart, secondPart] = weekDayLongName.split("-");
                return `${firstPart}s-${secondPart}s`;
            }
            return `${weekDayLongName}s`;
        }

        return date.toLocaleString(this.triggersService.LOCALE_EN_I18N, { weekday: "long" });
    }
}
