import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ManagementProtocolModel } from "@nms-ng2/app/shared/components/elements/management-protocols/management-protocols-model";
import {
  ManagementProtocolNotifier
} from "@nms-ng2/app/shared/components/elements/management-protocols/management-protocols-notifier.model";
import { SimpleModalComponent } from "ngx-simple-modal";
import { Subject } from "rxjs";

declare let _: any;

export interface DeviceManagementProtocolsModalModel {
  title: string;
  guiSettings: any;
  formData: any;
  globalCredentials: any;
  specificCredentials: any;
  isMultipleSelection: any;
  userPassConfig: any;
  oldCredentials: any;
  passwordsVisibility: any;
  snmpVersion: any;
  snmpV3PassLength: any;
  authType: any;
  cypher: any;
}

/**
 * Modal responsável por configurar as credenciais de um ou múltiplos equipamentos.
 */
@Component({
  selector: "device-management-protocols-modal",
  templateUrl: "./device-management-protocols-modal.component.html",
  styleUrls: ["./device-management-protocols-modal.component.css"],
})
export class DeviceManagementProtocolsModalComponent
  extends SimpleModalComponent<DeviceManagementProtocolsModalModel, any>
  implements DeviceManagementProtocolsModalModel, OnInit, OnDestroy
{
  readonly GLOBAL: string = "global";
  readonly SPECIFIC: string = "specific";

  title: string;
  guiSettings: any;
  formData: any;
  globalCredentials: any;
  specificCredentials: any;
  isMultipleSelection: any;
  userPassConfig: any;
  oldCredentials: any;
  passwordsVisibility: any;
  snmpVersion: any;
  snmpV3PassLength: any;
  authType: any;
  cypher: any;
  credentialsSaveNotifier: Subject<ManagementProtocolNotifier>;
  credentialsCloseNotifier: Subject<void>;
  credentials: ManagementProtocolModel;

  constructor() {
    super();
    this.formData = { global: {}, specific: {} };
    this.guiSettings = {};
    this.oldCredentials = {};
    this.passwordsVisibility = {};
    this.credentialsSaveNotifier = new Subject();
    this.credentialsCloseNotifier = new Subject();
  }

  ngOnInit() {
    if (_.isEmpty(this.specificCredentials)) {
      this.userPassConfig = this.GLOBAL;
      this.specificCredentials = _.cloneDeep(this.globalCredentials);
      this.formData.specific = _.cloneDeep(this.formData.global);
    } else {
      this.userPassConfig = this.SPECIFIC;
    }

    this.oldCredentials.userPassConfig = _.cloneDeep(this.userPassConfig);
    this.oldCredentials.credentials = _.cloneDeep(
      this.formData[this.userPassConfig]
    );
    this.credentials = {
      globalCredentials: this.globalCredentials,
      specificCredentials: this.specificCredentials,
      oldCredentials: this.oldCredentials,
    };

    this.credentialsSaveNotifier.subscribe(
      (credentials: ManagementProtocolNotifier) => {
        this.saveCredentials(credentials);
      }
    );

    this.credentialsCloseNotifier.subscribe(() => {
      this.close();
    });
  }

  saveCredentials(currentCredentials: ManagementProtocolNotifier) {
    let isGlobalConfig = this.userPassConfig === this.GLOBAL;

    this.result = {
      data: isGlobalConfig
        ? currentCredentials.globalCredentials
        : currentCredentials.specificCredentials,
      globalConfig: isGlobalConfig,
    };

    this.close();
  }

  ngOnDestroy() {
    this.credentialsSaveNotifier.unsubscribe();
    this.credentialsCloseNotifier.unsubscribe();
  }
}
