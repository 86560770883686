"use strict";

/**
* @ngdoc Service
* @name nms.management.ManagePermissionsLocationsRestService
* @description Realiza chamadas REST ao serviço de gerência de permissões de localidades.
*/
var app = angular.module("nms.management");

app.service("ManagePermissionsLocationsRestService", ["Restangular",
    function(Restangular) {
        const service: any = {};

        service.getAll = function() {
            return Restangular.all("/inventory/location/hierarchical").customGET();
        };

        return service;
    }
]);
