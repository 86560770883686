
import { Component, OnInit, Input, Inject, ViewChild } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Subject } from "rxjs";
import { NgSelectComponent } from "@ng-select/ng-select";

/* Interface utilizada para enviar a notificação com o valor selecionado no
/* componente de combo-box juntamente com o índice na qual foi inserido ou alterado */
export interface NmsSelectValue {
    index: number,
    value: string;
}

/* Interface utilizada para definir o modelo a ser utilizado nos itens do combo-box*/
export interface NmsSelectModel {
    value: string,
    isRemovable: boolean;
}
/**
 * Criação de um componente do tipo combo-box na qual permite adicionar ou remover valores
 * dinamicamente.
 * Para isso é necessário receber por parâmetros um array com a seguinte estrutura:
 * items = [ { value: value , isRemovable: false }]
 * no objeto value => teremos o valor a ser renderizado no combo-box
 * no objeto isRemovable => flag de identificação para verificar se o elemento poderá ser removido.
 * Ao final da listagem dos itens tem-se a opção para restaurar as opções de fábrica, ou seja
 * após inserção ou remoção, tem-se a opção de restaurar os valores iniciais do array.
 *
 */
@Component({
    selector: "nms-select",
    templateUrl: "./nms-select.component.html",
    styleUrls: ["./nms-select.component.scss"]
  })
  export class NmsSelectComponent {
      @ViewChild(NgSelectComponent, { static: true}) ngSelectComponent: NgSelectComponent;
      /* Itens do array na qual serão renderizados no combo-box */
      @Input() items: Array<NmsSelectModel> = [];
      /* Texto a ser inserido como placeholder no componente*/
      @Input() placeHolderText: string;
      /* Notifica o componente passando o valor selecionado juntamente com o index, caso haja mais
      /* de um componente renderizado na tela */
      @Input() selectedItemNotifier: Subject<NmsSelectValue>;
      /* Notifica o componente quando um valor é adicionado no combobox para que todos os outros comboboxs
      /* existentes possuam aquele valor adicionado também. */
      @Input() itemNotifier: Subject<Array<NmsSelectModel>>;
      /* Item selecionado no componente*/
      @Input() selected: string;
      /* Índex utilizado, caso haja mais de um componente renderizado na tela */
      @Input() index: number;
      /* Variável utilizada para armazenar os valores originais com os itens default, para posteriormente restaurar
      /* as configurações iniciais.*/
      @Input() itemsDefault: Array<NmsSelectModel> = [];
      /**Texto a ser exibido ao adicionar um novo item ao combo-box */
      @Input() tagText: string;
      /** Texto a ser inserido no botão localizado no final do componente de combo-box */
      @Input() buttonText: string;


      constructor(@Inject(ANGULARJS_TRANSLATE) private translate) {
          this.selectedItemNotifier = new Subject();
          this.itemNotifier = new Subject();
      }

      /**
       * Adiciona um elemento dinamicamente no combo-box juntamente com a opção de removê-lo por padrão.
       */
      addValue = (add) => {
          this.items = [...this.items, { value: add, isRemovable: true }];
          this.itemNotifier.next(this.items);
          this.selectedItemNotifier.next({ value: add, index: this.index });
          this.ngSelectComponent.close();
      }

      /**
       * Remove o elemento do combo-box
       */
      removeValue(item): void {
          this.ngSelectComponent.handleClearClick();
          this.items = this.items.filter(( element ) => element.value !== item.value);
          this.itemNotifier.next(this.items);
      }

      /**
       * Restaura as configurações iniciais
       */
      restoreValues(): void {
          this.ngSelectComponent.handleClearClick();
          this.items = this.itemsDefault;
          this.itemNotifier.next(this.items);
      }

      /**
       * Evento ocorrido ao alterar o valor do componente
       * Será enviado uma notificação contendo o valor selecionado e o index do componente
       * na qual foi feita a alteração.
       */
      changeValue(selected) {
          let value = selected ? selected.value : null;
          this.selectedItemNotifier.next({ value: value, index: this.index });
      }
  }