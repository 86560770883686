/**
 * Controller para diretiva listTableCell.
 */
var app = angular.module("nms.dynamicDevice");

app.controller("ListTableCellController", ["$scope", "NodeStatusService", "WhenVerifierService", "TableCellService",
    function($scope, NodeStatusService, WhenVerifierService, TableCellService) {
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.listSchemaNode);
        $scope.isNodeVisible = !hasWhenCondition;
        $scope.itemsLabel = "";
        if (!$scope.pathKeys && $scope.getPathKeys) {
            $scope.pathKeys = $scope.getPathKeys();
        }

        var unregisterNodeStatusListener;
        var tableCellValuesTranslationKeys: any = {
            useEmptyLabel: false,
            multiElements: "yang.list.listBody.itemsLabel",
            singleElement: "yang.list.listBody.itemLabel"
        };

        var loadData = function(dataNode) {
            var entriesByLeafkeys = TableCellService.getEntriesByLeafKeys($scope.listSchemaNode, dataNode);
            $scope.itemsLabel = TableCellService.getCellValuesStr(entriesByLeafkeys, tableCellValuesTranslationKeys);
            if ($scope.rowModel) {
                $scope.rowModel[$scope.fieldSearchStatement] = entriesByLeafkeys || [];
            }
        };

        if (hasWhenCondition) {
            unregisterNodeStatusListener = NodeStatusService.register(
                $scope.listSchemaNode.paths.schemaJsonPath,
                $scope.listSchemaNode.paths.dataJsonPath,
                $scope.pathKeys,
                function(nodeStatus, refreshData, dataNode) {
                    $scope.isNodeVisible = nodeStatus.isVisible;
                    if (refreshData) {
                        loadData(dataNode);
                    }
                });
        }

        $scope.$on("$destroy", function() {
            if (_.isFunction(unregisterNodeStatusListener)) {
                unregisterNodeStatusListener();
            }
        });

        loadData($scope.listDataNode);
    }
]);
