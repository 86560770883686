"use strict";

/*
* @ngDoc          Service
* @name           nms.management.FormValidatorService
* @description    Validador de formulários.
* @requires       [$rootScope, $translate, FieldsValidatorService]
*
* Este validador gera mensagens de erro do formato <title>: <message>.
*/
var app = angular.module("nms");
app.service("FormValidatorService", ["$rootScope", "$translate", "FieldsValidatorService",
    function($rootScope, $translate, FieldsValidatorService) {
        const service: any = {};

        /*
        * @ngDoc  method
        * @name   nms.management.FormValidatorService#validate
        * @param  {form}  Formulário pai.
        * @param  {data}  Objeto com os valores dos atributos de cada formulário.
        *                 Estrutura: {
        *                                <formAttributesName>: {
        *                                    idPreffix: <prefixo dos ids do formulário>,
        *                                    title: <título que aparecerá na mensagem de erro>,
        *                                    fieldsTranslatePreffix: <prefixo das traduções de campos do formulário>
        *                                }
        *                            }
        * @param  {String[]}  Array com campos que devem ser ignorados na validação.
        */
        service.validate = function(form, data, ignoreFields) {
            var errors = FieldsValidatorService.extractFormErrors(form);
            var consideredErrors = _.filter(errors, function(error) {
                return !_.includes(ignoreFields, error.field);
            });

            if (!_.isEmpty(consideredErrors)) {
                var dataForms = _.flatten(_.values(data));
                var errorShown = consideredErrors[0];

                var formData = _.find(dataForms, function(tab) {
                    return _.contains(errorShown.formName, tab.idPreffix);
                });

                var translatedField = $translate.instant(formData.fieldsTranslatePreffix + errorShown.field);
                // FIXME - As chaves de tradução dos campos de formulário possuem o caracter ":". Removê-los implica em refatorar
                // o componente "password-field", utilizado na modal de credenciais. Avaliar remover os ":" dos arquivos de
                // tradução para evitar a remoção deste caracter em translatedField.
                var message = $translate.instant("input.validator.error." + errorShown.type)
                    .replace("{0}", translatedField.replace(":", "").toLowerCase());
                var params = [formData.title, message];

                $rootScope.showDialog({
                    translateKey: "form.validator.error.titlePattern",
                    params: params,
                    paramsInsideMessage: true
                });

                return false;
            }

            return true;
        };

        return service;
    }
]);
