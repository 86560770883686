import { RuleCriteria, RuleType } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { SchedulerJob } from "../../scheduler/scheduler.models";

export interface EquipmentDetails {
    hostname: string;
    model: string;
    name: string;
    type: string;
}

export class DeviceDetails implements EquipmentDetails {
    name: string;
    hostname: string;
    firmwareVersion: string;
    model: string;
    modelCode: number;
    vendorCode: number;
    locationId: number;
    readonly type: string = "DeviceDetails";
}

export class CpeDetails implements EquipmentDetails {
    serialNumber?: string;
    name: string;
    hostname: string;
    model: string;
    softwareVersion: string;
    connectionRequestUrl: string;
    readonly type: string = "CpeDetails";
}

export interface EquipmentIdentifier {
    type: string;
}

export interface DeviceModelTemplateAssociation {
    deviceIdentifier: DeviceIdentifier;
    templateId: string;
}

export enum IdentifierType {
    TEMPLATE_IDENTIFIER = "TemplateIdentifier",
    CPE_IDENTIFIER = "CpeIdentifier",
    DEVICE_IDENTIFIER = "DeviceIdentifier"
}

export class DeviceIdentifier implements EquipmentIdentifier {
    resourceId?: number;
    readonly type: string = IdentifierType.DEVICE_IDENTIFIER;
}

export class CpeIdentifier implements EquipmentIdentifier {
    serialNumber?: string;
    readonly type: string = IdentifierType.CPE_IDENTIFIER;
}

export class TemplateIdentifier {
    id: string;
    readonly type: string = IdentifierType.TEMPLATE_IDENTIFIER;
}

type EquipmentApplicationStatus = "NOT_ORDERED" | "NOT_STARTED" | "APPLYING" | "DONE" | "ERROR";

export interface EquipmentAssociation {
    apply: boolean;
    equipmentDetails: DeviceDetails | CpeDetails;
    equipmentIdentifier: DeviceIdentifier | CpeIdentifier;
    localVars: any;
    templateAssociations: any[];
    status?: EquipmentApplicationStatus;
}

export type Keyword = string | { text: string };

/**
 * Representa os campos que permitem a criação de regras para a seleção de CPEs do tipo "Filtro de parâmetros".
 */
export enum CpeFieldValues {
    SERIAL_NUMBER = "SERIAL_NUMBER",
    MANUFACTURER = "MANUFACTURER",
    PRODUCT_CLASS = "PRODUCT_CLASS",
    MANAGEMENT_IP = "MANAGEMENT_IP",
    LAST_INFORM = "LAST_INFORM",
    HW_VERSION = "HW_VERSION",
    SW_VERSION = "SW_VERSION",
    PROVISIONING_CODE = "PROVISIONING_CODE"
}

/**
 * Representa um campo para criação de regras de CPEs.
 */
export interface CpeField {
    type: "cpe";
    value: CpeFieldValues;
}

/**
 * Representa os campos que permitem a criação de regras para a seleção de Devices do tipo "Filtro de parâmetros".
 */
export enum DeviceFieldValues {
    NAME = "NAME",
    IP_HOSTNAME = "IP_HOSTNAME",
    MODEL = "MODEL",
    SERIAL_NUMBER = "SERIAL_NUMBER",
    FIRMWARE = "FIRMWARE",
    LOCATION = "LOCATION",
    STATUS = "STATUS",
    LAST_UPDATE = "LAST_UPDATE"
}

/**
 * Representa um campo para criação de regras de Devices.
 */
export interface DeviceField {
    type: "device";
    value: DeviceFieldValues;
}

/**
 * Representa as regras de filtragem de equipamentos para a seleção de equipamentos do tipo "Filtro de parâmetros".
 */
export interface EquipmentRuleOption {
    equipmentField?: CpeField | DeviceField;
    ruleType: RuleType;
    values: string[];
}

/**
 * Representa o filtro de equipamentos para a seleção de equipamentos do tipo "Filtro de parâmetros",
 * encapsulando as regras definidas e o critério de filtragem.
 */
export interface EquipmentSelectionFilter {
    rulesMatchingMode: RuleCriteria;
    equipmentRuleOption: EquipmentRuleOption[];
}

/**
 * Resposta da resposta da busca de equipamentos utilizando filtros
 */
export interface EquipmentFilterResponse {
    templatesByEquipments: { [equipmentId: string]: string[] };
    equipmentDetails: { [equipmentId: string]: any };
}

/**
 * Filtros que serão utilizados na busca de equipamentos.
 * Utilizado tanto na seleção de equipamentos por filtros quanto para listar
 * os equipamentos na modal de seleção de equipamentos.
 */
export interface EquipmentSearchFilter {
    globalRules: EquipmentSelectionFilter[],
    rulesByTemplate: { [templateId: string]: EquipmentSelectionFilter }
}

export interface ScheduledTemplateApplication {
    templateInstance: TemplateInstance;
    schedulerJob?: SchedulerJob;
}

/**
 * Modelo utilizado para validar se os templates selecionados podem ser aplicados
 * nos equipamentos inseridos na aplicação, baseado nas regras de modelo e firmware.
 */
export interface TemplateRestrictionFilter {
    equipmentIdentifiers: EquipmentIdentifier[];
    templatesRules: Map<string, EquipmentSelectionFilter>;
}

export interface TemplateInstance {
    id: string;
    name: string;
    description?: string;
    equipmentAssociations: EquipmentAssociation[];
    applyCommandsOnRemovedEquipments: boolean;
    globalVars: any;
    dirty: boolean;
    status: any;
    presentationMode: any;
    type: string;
    modifiedBy?: string;
    keywords?: Array<Keyword>;
    equipmentSelectionType: EquipmentSelectionType;
    equipmentSelectionFilter?: EquipmentSelectionFilter;
}

/**
 * Tipos de seleção de equipamentos.
 */
export enum EquipmentSelectionType {
    SPECIFIC = "SPECIFIC",
    FILTER = "FILTER",
    ALL = "ALL"
}

export enum ExecutionOrigin {
    TEMPLATE_APPLICATION = "TEMPLATE_APPLICATION",
    SCHEDULER = "SCHEDULER"
}

export interface AccordionParentData {
    id: DeviceIdentifier | CpeIdentifier | TemplateIdentifier;
    label: string;
    description?: string;
    expand: boolean;
    status?: any;
    children: AccordionChildrenData[];
}

export interface AccordionChildrenData {
    id: DeviceIdentifier | CpeIdentifier | TemplateIdentifier;
    label: string;
    description: string;
}

export class EquipmentAssociationResultRequest {
    equipmentIdentifier: any;
    templateId: string;
}

export enum EquipmentTemplateChangedStatus {
    /**
     * Apenas devices removidos, não precisa recarregar as variáveis do equipamento.
     */
    ONLY_DEVICES_REMOVED = "onlyDevicesRemoved",
    /**
     * Acredito que esse caso não seja mais utilizado.
     * Favor atualizar essa documentação caso tenha mais detalhes.
     */
    CREATING = "creating",
    /**
     * Houve mudança de equipamento e template. Nesse caso é necessário recarregar as variáveis do equipamento.
     * Documentar aqui outros usos desse item.
     */
    NEEDS_CUSTOM_UPDATE = "needsCustomUpdate",
    /**
     * Esse é o valor inicial, indicando que nenhuma mudança foi feita nos equipamentos e templates.
     */
    NO_CHANGE = "noChange"
}

export interface TemplateInstanceIdentifier {
    templateInstanceId: string;
}

export interface DeletionStatus {
    id: string;
    success: boolean;
}

export interface TemplateInfo {
    totalTemplates: number;
    totalTemplatesWithoutRemoveCommands: number;
    templateWithoutRemoveCommandsNames: Array<string>;
}

export type TemplateApplicationStatus = "FAIL" | "SUCCESS" | "NOT_STARTED" | "APPLYING" | "NOT_REQUESTED" | "NOT_EXECUTED"
    | "APPLYING_BY_ANOTHER_USER";

interface TemplateResult {
    templateId: string;
    templateName: string;
    templateDescription: string;
    status: TemplateApplicationStatus;
    statusInfo: string;
}

interface EquipmentAssociationResult {
    apply: boolean;
    equipmentDetails: EquipmentDetails;
    equipmentIdentifier: EquipmentIdentifier;
    templateResults: Array<TemplateResult>;
}

export interface TemplateInstanceResult {
    templateInstanceId: string;
    presentationMode: string;
    equipmentAssociationResults: Array<EquipmentAssociationResult>;
}

interface KeywordOccurrences {
    keyword: string;
    occurrences: number;
}

export interface TotalKeywords {
    total: number;
    keywordOccurrences: Array<KeywordOccurrences>;
}

export interface SimpleEquipmentAssociations {
    id: string | number;
    templates: string[];
}

/**
 * Representa as operações de uma aplicação de templates.
 */
export enum TemplateInstanceOperation {
    CREATE = "CREATE",
    EDIT = "EDIT",
    REMOVE = "REMOVE"
}

/**
 * Representa a associação de um equipamento e um template.
 */
export interface EquipmentTemplateIdentifier {
    equipmentIdentifier: EquipmentIdentifier;
    templateId: string;
}

/**
 * Representa a requisição para compilar os comandos de equipamentos com os dados fornecidos.
 */
export interface TemplateInstanceEquipmentsCommandsRequest {
    templateInstance: TemplateInstance;
    templateInstanceOperation: TemplateInstanceOperation;
    equipmentTemplateIdentifiers: EquipmentTemplateIdentifier[];
}

/**
 * Representa a requisição para compilar os comandos sem a necessidade de equipamento.
 * Utilizado nos modos de seleção de equipamento diferente de específico.
 */
export interface TemplateInstanceCommandsRequest {
    templateId: string;
    previouslyApplied: boolean;
    newGlobalVars: {[key: string]: object};
    oldGlobalVars: {[key: string]: object};
    templateInstanceOperation: TemplateInstanceOperation;
}

/**
 * Tipos de requisição de comandos de aplicação.
 */
export type CommandsRequest = TemplateInstanceEquipmentsCommandsRequest | TemplateInstanceCommandsRequest;

/**
 * Representa o resultado de um comando compilado:
 * commands: Valor retornado da compilação.
 * hasError: indica se houve algum erro durante a compilação.
 */
export interface CommandResponse {
    commands: string;
    hasError: boolean;
}

/**
 * Representa a resposta dos comandos compilados para apresentação.
 */
export interface TemplateCommandResponse {
    templateName: string;
    equipmentDetails: EquipmentDetails;
    applicationCommands: CommandResponse;
    removalCommands: CommandResponse;
}

export type TemplateInstanceTabs = "equipments" | "variables" | "scheduler" | "viewApply" | "general";

/**
 * Representa a resposta da validação de permissão em equipamentos.
 */
export interface EquipmentPermissionResult {
    equipments: [];
    hasEquipmentWithoutPermission: boolean;
}