"use strict";

var app = angular.module("nms");

/**
 * @ngdoc directive
 * @name nms.templates.directive:updateColumnsWidth.
 * @description Diretiva que simula um resize na janela permitindo que o evento para recalcular a largura das colunas da
 * tabela seja chamado automaticamente, se ajustando novamente a dimensão da janela.
 * Caso a diretiva seja utilizada dentro de um ng-repeat o evento de 'resize' só será disparado na última iteração.
 */
app.directive("updateColumnsWidth",
    ["$timeout", "$rootScope", function($timeout, $rootScope) {
        return {
            restrict: "A",
            link: function(scope) {
                if (_.isUndefined(scope.$last) || scope.$last === true) {
                    $timeout(function() {
                        angular.element(window).trigger("resize");
                    });
                }

                var unregister = $rootScope.$on("updateTableWidth", function() {
                    angular.element(window).trigger("resize");
                });

                scope.$on("$destroy", function() {
                    unregister();
                });
            }
        };
    }]
);
