var app = angular.module("nms.dynamicDevice");

app.service("LeafService", ["$filter", "DomainHandlerService", "LeafType",
    function($filter, DomainHandlerService, LeafType) {
        var self = this;

        this.setPersistentLeafValue = function(leaf, pathWithKeys, newValue) {
            var defaultValue = self.getDefaultValue(leaf);
            if (self.getBaseType(leaf.type).name === "empty") {
                if (newValue === null || angular.isUndefined(newValue)) {
                    DomainHandlerService.deleteDataNodeByJsonPath(pathWithKeys);
                } else {
                    DomainHandlerService.addOrSetDataNode(pathWithKeys + ".value", newValue);
                }
            } else if (!_.isString(defaultValue)
                && (newValue === null || _.isUndefined(newValue) || newValue === "")) {
                DomainHandlerService.deleteDataNodeByJsonPath(pathWithKeys);
            } else {
                var value = newValue || defaultValue;
                DomainHandlerService.addOrSetDataNode(pathWithKeys + ".value", value);
            }
        };

        this.getBaseType = function(leafType) {
            return DomainHandlerService.getBaseType(leafType);
        };

        var getTypeSubStatementProperty = function(type, property, returnAsArray?) {
            if (type && type["sub-statements"]) {
                let subStatements = type["sub-statements"];

                if (type["base-type"]) {
                    var typeCopy = Object.assign({}, type);
                    var baseTypeCopy = Object.assign({}, type["base-type"]);

                    Object.assign(baseTypeCopy["sub-statements"], typeCopy["sub-statements"]);
                    subStatements = baseTypeCopy["sub-statements"];
                }

                if (subStatements[property]) {
                    return (returnAsArray) ? subStatements[property] : subStatements[property][0];
                }
            }
            return null;
        };

        this.getMin = function(type) {
            return getTypeSubStatementProperty(type, "min");
        };

        this.getMax = function(type) {
            return getTypeSubStatementProperty(type, "max");
        };

        /**
         * Returns the fraction-digits of a given type.
         *
         * @param {object} type A YANG node.
         * @return {number} Maximum number of fraction digits.
         */
        this.getMaxFractionDigits = function(type) {
            var fractionDigits = getTypeSubStatementProperty(type, "fraction-digits");

            return fractionDigits || 0;
        };


        this.getMaxIntegerDigits = function(type) {
            var fraction = self.getMaxFractionDigits(type);

            var max = 0;
            if (fraction > 0) {
                max = LeafType.DECIMAL.maxDigits - parseInt(fraction, 10);
            } else {
                max = LeafType.DECIMAL.maxDigits;
            }
            return max;
        };

        /**
         * Returns all patterns of a given type.
         *
         * @param {object} type A YANG type.
         * @return {Array} A set of all patterns a given type must match.
         */
        this.getPatterns = function(type) {
            return getTypeSubStatementProperty(type, "pattern", true);
        };

        /**
         * Returns all acceptable lengths of a given type.
         *
         * @param {object} type A YANG type.
         * @return {Array} A set of all acceptable lengths of given type.
         */
        this.getLengths = function(type) {
            return getTypeSubStatementProperty(type, "length", true);
        };

        /**
         * Returns all acceptable ranges of a given type.
         *
         * @param {object} type A YANG type.
         * @return {Array} A set of all acceptable ranges of given type.
         */
        this.getRanges = function(type) {
            var defaultRange = self.getMin(type) + ".." + self.getMax(type);
            var range = getTypeSubStatementProperty(type, "range", true);

            return range || [defaultRange];
        };

        this.getDefaultValue = function(leaf) {
            return _.get(leaf, "sub-statements.default");
        };

        this.isDiscreteType = function(type) {
            return self.getBaseType(type).name === "enumeration"
                || self.getBaseType(type).name === "bits"
                || self.getBaseType(type).name === "boolean";
        };

        this.getUnionPossibleTypes = function(unionType) {
            var possibleTypes = [];
            var baseUnionType = self.getBaseType(unionType);

            _.each(baseUnionType["sub-types"], function(subType) {
                var baseTypeOfSubType = self.getBaseType(subType);
                if (baseTypeOfSubType.name === "union") {
                    possibleTypes = possibleTypes.concat(self.getUnionPossibleTypes(baseTypeOfSubType));
                } else {
                    possibleTypes.push(subType);
                }
            });

            return possibleTypes;
        };

        /**
         * Retorna todos os tipos possíveis de uma union que não tenham valores discretos: string, int, uint.
         *
         * São tipos com valores discretos, os quais NÃO são retornados: enumeration, bits e boolean.
         *
         * @param {Object} unionType tipo YANG de uma leaf ou leaflist. Deve ser ou derivar de um tipo union.
         * @returns {Array} lista de todos os tipos não-discretos da union.
         */
        this.getUnionNonDiscretePossibleTypes = function(unionType) {
            var unionPossibleTypes = self.getUnionPossibleTypes(unionType);

            return _.filter(unionPossibleTypes, function(type) {
                return !self.isDiscreteType(type);
            });
        };

        var getUnionDiscreteTypesValues = function(unionType) {
            var possibleTypes = self.getUnionPossibleTypes(unionType);

            return _.chain(possibleTypes)
                .map(function(type) {
                    return self.getBaseType(type);
                }).map(function(type) {
                    if ((type.name === "enumeration" || type.name === "bits") && type["sub-statements"]) {
                        return self.getEnumerationValues(type);
                    } else if (type.name === "boolean") {
                        return ["true", "false"];
                    } else {
                        return [];
                    }
                })
                .flatten()
                .uniq()
                .value();
        };

        var getUnionPossibleValues = function(leaf, selectedValue, isRequired) {
            var defaultValue = self.getDefaultValue(leaf);
            var unionValues = getUnionDiscreteTypesValues(leaf);
            if (angular.isDefined(selectedValue) && selectedValue !== null) {
                unionValues.push(selectedValue);
            }
            var orderedValues = $filter("orderByNumbersAndLetters")(unionValues);

            if (!isRequired) {
                if (defaultValue) {
                    orderedValues.unshift(defaultValue);
                } else {
                    orderedValues.unshift("");
                }
            }

            return orderedValues;
        };

        this.getEnumerationValues = function(type) {
            return Object.keys(type["sub-statements"]);
        };

        /**
         * Returns a set of possible values if a given leaf has any.
         *
         * @param {object} leaf A leaf node.
         * @return {Array} all possible values for a leaf.
         */
        this.getPossibleValues = function(leaf, selectedValue, isRequired) {
            if (!leaf) {
                return null;
            }
            var baseType = self.getBaseType(leaf.type);
            if (baseType && baseType.name === "union") {
                return getUnionPossibleValues(leaf.type, selectedValue, isRequired);
            }
            if (baseType && baseType["sub-statements"]) {
                return self.getEnumerationValues(baseType);
            }

            return null;
        };
    }
]);
