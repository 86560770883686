"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.GponOnusResolver
* @description
* # GponOnusResolver - Resolver para as informações de ONU.
*/
var app = angular.module("nms.gponOnus");

app.service("GponOnusResolver", ["UnitConverterService", "STATUS_KEYS", "$translate", "$filter", "ONU_CONSTANTS", "$log",
    function(UnitConverterService, STATUS_KEYS, $translate, $filter, ONU_CONSTANTS, $log) {
        this.resolve = function(devicesOnus) {
            $log.log("Started resolve device onus", new Date());
            var allOnus = [];

            devicesOnus.forEach(function(deviceOnus) {
                _.forEach(deviceOnus.onus, function(onu) {
                    var deviceOnusConverted = angular.copy(onu);
                    var defaultOnuData: any = {
                        deviceName: "",
                        portViewName: "",
                        onuId: "",
                        status: "",
                        alarms: [],
                        alarmsTimes: "",
                        mostRecentAlarmsTime: "",
                        serialNumber: "",
                        password: "",
                        uptime: "",
                        lastSeenOnline: "",
                        vendorId: "",
                        equipmentId: "",
                        onuName: "",
                        operationalState: "",
                        primaryStatus: "",
                        distance: "",
                        ipv4Vlan: "",
                        autoProvisioned: "",
                        lineProfile: "",
                        serviceProfile: "",
                        serviceVlans: "",
                        fixedBandwidth: "",
                        assuredFixedBandwidth: "",
                        antiRogueOnuIsolate: "",
                        version: "",
                        softwareDownloadState: "",
                        rxPower: "",
                        txPower: "",
                        rxPowerLink: "",
                        txPowerLink: "",
                        activeFwFormatted: "",
                        standbyFwFormatted: "",
                        rgProfile: "",
                        snmpMode: "",
                        snmpProfileValue: "",
                        upstreamFec: "",
                        ipv4Mode: "",
                        ipv4Address: "",
                        cwmpCpeIp: "",
                        cwmpCpeLastInform: "",
                        tr069acsProfile: "",
                        ipv4DefaultGateway: "",
                        lastUpdated: "",
                        eth1InRate: "",
                        absoluteEth1InRate: "",
                        getEth1InRateLink: "",
                        eth2InRate: "",
                        absoluteEth2InRate: "",
                        getEth2InRateLink: "",
                        eth3InRate: "",
                        absoluteEth3InRate: "",
                        getEth3InRateLink: "",
                        eth4InRate: "",
                        absoluteEth4InRate: "",
                        getEth4InRateLink: "",
                        eth1OutRate: "",
                        absoluteEth1OutRate: "",
                        getEth1OutRateLink: "",
                        eth2OutRate: "",
                        absoluteEth2OutRate: "",
                        getEth2OutRateLink: "",
                        eth3OutRate: "",
                        absoluteEth3OutRate: "",
                        getEth3OutRateLink: "",
                        eth4OutRate: "",
                        absoluteEth4OutRate: "",
                        getEth4OutRateLink: "",
                        eth1Link: "",
                        eth2Link: "",
                        eth3Link: "",
                        eth4Link: ""
                    };

                    deviceOnusConverted.statusKey = onu.discovered ? "DISCOVERED" : onu.status;
                    deviceOnusConverted.status = getOnuStatus(deviceOnusConverted.statusKey);

                    deviceOnusConverted.onuId = getInteger(onu.onuId);
                    deviceOnusConverted.ipv4Vlan = getInteger(onu.ipv4Vlan);
                    deviceOnusConverted.fixedBandwidth = getInteger(onu.fixedBandwidth);
                    deviceOnusConverted.assuredFixedBandwidth = getInteger(onu.assuredFixedBandwidth);

                    deviceOnusConverted.rxPower = getFloat(onu.rxPower);
                    deviceOnusConverted.txPower = getFloat(onu.txPower);

                    deviceOnusConverted.snmpMode = resolveSnmp(onu);
                    deviceOnusConverted.ipv4Mode = resolveIpv4Mode(onu);

                    deviceOnusConverted.cwmpCpeIp = resolveIp(onu);
                    deviceOnusConverted.cwmpCpeLastInformToSort = resolveLastInformToSort(onu);
                    deviceOnusConverted.cwmpCpeLastInform = resolveLastInform(onu);
                    deviceOnusConverted.isCpeRegistered = deviceOnusConverted.cwmpCpeLastInform != '';

                    deviceOnusConverted.alarms = onu.alarms ? onu.alarms.map(alarm => alarm.information) : [];
                    deviceOnusConverted.mostRecentAlarmsTime = resolveMostRecentAlarmsTimes(onu.alarms);
                    deviceOnusConverted.alarmsTimes = resolveAlarmsTimes(onu.alarms);

                    _.forEach(onu.onuEthernetUnis, function(uni) {
                        var uniId = uni.uniId;
                        var link = uni.link;
                        var uniInBw = uni.inRate;
                        var uniInBwLink = uni.inRateLink;
                        var uniOutBw = uni.outRate;
                        var uniOutBwLink = uni.outRateLink;

                        deviceOnusConverted["eth" + uniId + "Link"] = link;

                        // IN
                        deviceOnusConverted["eth" + uniId + "InRate"] = $filter("bytesFormatter")(uniInBw);
                        deviceOnusConverted["absoluteEth" + uniId + "InRate"]
                                = UnitConverterService.getFixedAbsoluteValue(uniInBw);
                        deviceOnusConverted["getEth" + uniId + "InRateLink"] = uniInBwLink;

                        // OUT
                        deviceOnusConverted["eth" + uniId + "OutRate"] = $filter("bytesFormatter")(uniOutBw);
                        deviceOnusConverted["absoluteEth" + uniId + "OutRate"]
                                = UnitConverterService.getFixedAbsoluteValue(uniOutBw);
                        deviceOnusConverted["getEth" + uniId + "OutRateLink"] = uniOutBwLink;
                    });

                    deviceOnusConverted.deviceName = deviceOnus.deviceIdentifierOnu.deviceName.name;
                    deviceOnusConverted.deviceId = deviceOnus.deviceIdentifierOnu.deviceId.resourceId;
                    deviceOnusConverted.deviceHostname = deviceOnus.deviceIdentifierOnu.deviceHostname.hostname;
                    deviceOnusConverted.deviceModelName = deviceOnus.deviceIdentifierOnu.deviceCode.modelName;
                    deviceOnusConverted.deviceFwVersion = deviceOnus.deviceIdentifierOnu.fwVersion;
                    deviceOnusConverted.deviceLocationId = deviceOnus.deviceIdentifierOnu.locationId;
                    deviceOnusConverted.deviceModelCode = deviceOnus.deviceIdentifierOnu.deviceCode.modelCode;
                    deviceOnusConverted.deviceVendorCode = deviceOnus.deviceIdentifierOnu.deviceCode.vendorCode;
                    deviceOnusConverted.portId = onu.chassisId + "/" + onu.slotId + "/" + onu.portId;

                    deleteUnusedArrays(deviceOnusConverted);

                    var defaults = _.partialRight(_.assign, function(value, other) {
                        return _.isUndefined(value) || _.isNull(value) ? other : value;
                    });

                    defaults(deviceOnusConverted, defaultOnuData);

                    allOnus.push(deviceOnusConverted);
                });
            });

            return allOnus;
        };

        var getInteger = function(property) {
            var result = parseInt(property);
            return _.isNaN(result) ? property : result;
        };

        var getFloat = function(property) {
            var result = parseFloat(property);
            return _.isNaN(result) ? property : result;
        };

        var getOnuStatus = function(statusKey) {
            return $translate.instant(STATUS_KEYS[statusKey]);
        };

        var resolveSnmp = function(onu) {
            if (onu.snmpMode === ONU_CONSTANTS.SNMP.TYPE.ALL) {
                return $translate.instant(ONU_CONSTANTS.SNMP.VALUE.ALL);
            }

            return onu.snmpMode === ONU_CONSTANTS.SNMP.TYPE.PROFILE
                                    ? onu.snmpProfileValue
                                    : $translate.instant(ONU_CONSTANTS.SNMP.VALUE.DISABLED);
        };

        var resolveIpv4Mode = function(onu) {
            return ONU_CONSTANTS.IPV4_MODE[onu.ipv4Mode] ? $translate.instant(ONU_CONSTANTS.IPV4_MODE[onu.ipv4Mode]) : "";
        };

        var resolveIp = function(onu) {
            return onu.cwmpCpeIp ? onu.cwmpCpeIp : "";
        };

        var resolveLastInform = function(onu) {
            return onu.cwmpCpeLastInform ? $filter("dateFormat")(onu.cwmpCpeLastInform) : "";
        };

        /* O campo de last inform, quando for vazio deve ser considerado mais velho que o campo que tiver valor */
        var resolveLastInformToSort = function(onu) {
            return onu.cwmpCpeLastInform ? onu.cwmpCpeLastInform : "";
        };

        function hasAlarmsTime(alarms) {
            return alarms && alarms.length > 0 && !_.isEmpty(alarms[0].time);
        }

        function resolveAlarmsTimes(alarms) {
            if (hasAlarmsTime(alarms)) {
                let formattedAlarmsTime = alarms.map(alarm => $filter("dateFormat")(alarm.time));
                return $filter("arrayToString")(formattedAlarmsTime);
            }

            return "";
        }

        /**
         * Ordena de acordo com a data e hora do alarme ocorrido.
         * Caso haja mais de um alarme na ONU, todos serão exibidos
         * e ordenados do mais recente para o menos recente.
         * @param alarms: Data e hora com a ocorrência do alarme.
         * @returns: o alarme mais recente.
         */
        function resolveMostRecentAlarmsTimes(alarms) {
            if (hasAlarmsTime(alarms)) {
                let alarmsCopy = [...alarms];
                alarmsCopy.sort((alarm1, alarm2) => _.isEmpty(alarm1.time) || _.isEmpty(alarm2.time)
                    ? 0
                    : alarm2.time.localeCompare(alarm1.time));
                return alarmsCopy[0].time || "";
            }
            return "";
        }

        /*
        * Remove arrays após serem utilizados para criação dos dados das colunas.
        * Esses arrays causam lentidão no filtro, também por isso foi importante a remoção, trazendo melhoria na performance.
        */
        function deleteUnusedArrays(deviceOnusConverted) {
            delete deviceOnusConverted.onuEthernetUnis;
        }
    }
]);


