/**
 * Service to manipulate the loading pane.
 *
 * @author bruno.vollino
 */
var app = angular.module("nms");

app.service("LoadingPaneService", ["$rootScope", "$loading", "$translate", function($rootScope, $loading, $translate) {
    $rootScope.loadingPaneName = "loadingPane";
    $rootScope.loadingPaneOptions = {text: ""};

    this.showLoadingPane = function(translateKey) {
        var text = $translate.instant(translateKey);

        $rootScope.loadingPaneOptions.text = text;
        $loading.start($rootScope.loadingPaneName);
    };

    this.updateLoadingPane = function(translateKey) {
        var text = $translate.instant(translateKey);

        $rootScope.loadingPaneOptions.active = true;
        $rootScope.loadingPaneOptions.text = text;
    };

    this.closeLoadingPane = function() {
        $loading.finish($rootScope.loadingPaneName);
    };
}]);
