"use strict";

/**
 * Filter to format url replacing a placeholder.
 */

var app = angular.module("nms");

app.filter("zabbixUrlFormatter", function() {
    return function(link, itemId) {
        var zabbixPlaceholder = "${zabbixUrl}";
        if (link) {
            return link.replace(zabbixPlaceholder, itemId);
        }
    };
});
