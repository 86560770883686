var app = angular.module("nms.dynamicDevice");

/**
 * Diretiva que exibe todas as mensagens de erro referentes a restrições em leaf e leaf-lists.
 */
app.directive("restriction", ["RestrictionService", "LeafService", "$filter",
    function(RestrictionService, LeafService, $filter) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/restrictions/restriction.html",
            link: function(scope, elem, attrs) {
                scope.isEmpty = function(id) {
                    return RestrictionService.isEmpty(id, scope.form);
                };

                scope.hasTypeErrorsOnField = function(id) {
                    scope.hasErrors = RestrictionService.hasErrorsOnField(id, scope.form, "required");
                    return scope.hasErrors;
                };

                scope.isUnionWithDiscreteTypes = function(leaf) {
                    if (scope.getBaseType(leaf.type).name === "union") {
                        var possibleTypes = LeafService.getUnionPossibleTypes(leaf.type);
                        return _.some(possibleTypes, LeafService.isDiscreteType);
                    }

                    return false;
                };

                scope.getMessageForMultipleRestrictions = function(node) {
                    var message = _.template(" (${info}):")({info: _.unescape($filter("getInformation")(node, ". "))});
                    return _.template("Must fulfill one of these requirements${message}")({message: message});
                };

                var getTypesForRestriction = function() {
                    if (scope.getBaseType(scope.leaf.type).name === "union") {
                        return LeafService.getUnionNonDiscretePossibleTypes(scope.leaf.type);
                    } else {
                        return [scope.leaf.type];
                    }
                };

                scope.typesForRestriction = getTypesForRestriction();
            }
        };
    }
]);

/**
 * Diretiva que exibe somente mensagens de erro de restrições definidas nos types de leafs e leaf-lists.
 */
app.directive("typeRestriction", [
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/restrictions/type-restriction.html",
            controller: "TypeRestrictionController",
            link: function(scope, elem, attrs) {
                scope.type = scope.$eval(attrs.type);
                scope.hasMultipleRestrictions = attrs.hasMultipleRestrictions === "true";
            }
        };
    }
]);

/**
 * Restrict the maximum number of fraction digits accepted.
 */
app.directive("decimalFraction",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/restrictions/decimal.html"
        };
    }
);

/**
 * Restrict the maximum length of characters accepted.
 */
app.directive("length",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/restrictions/length.html"
        };
    }
);

/**
 * Restrict the value format (yang pattern).
 */
app.directive("stringPattern",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/restrictions/pattern.html"
        };
    }
);

/**
 * Restrict values with a yang range.
 */
app.directive("yangRange",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/restrictions/yang-range.html"
        };
    }
);

/**
 * Restrict value or range according to field restrictions.
 */
app.directive("userRange",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/restrictions/user-range.html"
        };
    }
);

/**
 * Restrict the required leaves.
 */
app.directive("required",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/restrictions/required.html"
        };
    }
);

/**
* Restrict numeric field with valid value
*/
app.directive("invalidNumber",
    function() {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/restrictions/invalid-number.html"
        };
    }
);
