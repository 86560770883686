/**
 * Diretiva que define a largura dos elementos de acordo com a largura do pai dividindo em 4, 3 ou 2 colunas.
 * @param {obj} $rootScope escopo da aplicação
 * @param {obj} $document página carregada na tela
 * @param {obj} $timeout versão do Angular para window.setTimeout
 * @param {obj} $window refere-se ao objeto window do Javascript
 * @return {void} altera a largura dos elementos
 */
var app = angular.module("nms.dynamicDevice");

app.directive("widthCalculator", ["$rootScope", "$document", "$timeout", "$window",
    function($rootScope, $document, $timeout, $window) {
        return {
            restrict: "A",
            link: function(scope, element, attrs) {
                var recalculateWidth = function() {
                    var target = angular.element(attrs.widthCalculatorTarget)[0];
                    if (target) {
                        var siblingWidth = target.clientWidth;
                        var fluidWidthElement = angular.element(".fluid-width");

                        if (siblingWidth >= 1540) {
                            fluidWidthElement.css("width", "25%");
                        } else if (siblingWidth >= 1185 && siblingWidth < 1540) {
                            fluidWidthElement.css("width", "33%");
                        } else if (siblingWidth < 1185) {
                            fluidWidthElement.css("width", "50%");
                        }
                    }
                };

                var triggerRecalculateWidth = function() {
                    $timeout(function() {
                        recalculateWidth();
                    }, 10);
                };

                angular.element(element[0]).on("mousedown", function() {
                    $document.on("mousemove", function() {
                        recalculateWidth();
                    });

                    $document.on("mouseup", function() {
                        $document.unbind("mousemove");
                    });
                });

                angular.element($window).on("resize", recalculateWidth);

                var unregisterReloadContent = scope.$on("reloadContent", triggerRecalculateWidth);

                triggerRecalculateWidth();

                scope.$on("$destroy", function() {
                    unregisterReloadContent();
                });
            }
        };
    }
]);
