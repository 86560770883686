"use strict";

/**
* @ngdoc directive
* @description
* # serverPagination
* Diretiva usada em tabelas que tem seus dados paginados pelo servidor.
*/
var app = angular.module("nms");

app.directive("serverPagination", function() {
    return {
        templateUrl: "templates/components/ui/pagination/server/server-pagination.html",
        link: function(scope, element, attrs) {
            scope.showNumberOfSelected = !_.isUndefined(attrs.$attr.totalChecked);

            if (scope.showNumberOfSelected) {
                attrs.$observe("totalChecked", function(newVal) {
                    scope.numberOfChecked = newVal;
                });
            }
        }
    };
});
