"use strict";

var app = angular.module("nms.gponOnus");

app.service("GponOnusDeviceInformationResolver", ["$rootScope",
    function($rootScope) {
        var resolver: any = {};

        var extractDeviceInformation = function(device) {
            return {
                id: device.id,
                name: device.name,
                hostname: device.hostname,
                model: device.model,
                fwVersion: device.fwVersion,
                locationId: device.locationId,
                modelCode: device.modelCode,
                vendorCode: device.vendorCode
            };
        };

        var getDeviceFromContext = function(params) {
            if (params.isOneDeviceContext) {
                return [extractDeviceInformation(params.deviceInContext)];
            }

            return [];
        };

        var getDeviceFromSelectedOnus = function(params) {
            var deviceInformationArray = [];

            if (!_.isEmpty(params.selectedValues)) {
                var differentDeviceOnus = _.uniq(params.selectedValues, "deviceName");

                if (!_.isEmpty(differentDeviceOnus)) {
                    deviceInformationArray = _.map(differentDeviceOnus, function(deviceOnu) {
                        var deviceInformation: any = {
                            id: deviceOnu.deviceId,
                            name: deviceOnu.deviceName,
                            hostname: deviceOnu.deviceHostname,
                            model: deviceOnu.deviceModelName,
                            fwVersion: deviceOnu.deviceFwVersion,
                            locationId: deviceOnu.deviceLocationId,
                            modelCode: deviceOnu.deviceModelCode,
                            vendorCode: deviceOnu.deviceVendorCode
                        };

                        return deviceInformation;
                    });
                }
            }

            return deviceInformationArray;
        };

        var getDeviceFromFilter = function(params) {
            var deviceNameFilterValue = params.tableFilters["deviceName"];

            if (!_.isEmpty(deviceNameFilterValue)) {
                var isSpecificSearch = (_.startsWith(deviceNameFilterValue, "\"") && _.endsWith(deviceNameFilterValue, "\""))
                    || _.startsWith(deviceNameFilterValue, "\=");

                if (isSpecificSearch && !_.isEmpty(params.tableData)) {
                    var deviceInformation = [{
                        id: params.tableData[0].deviceId,
                        name: params.tableData[0].deviceName,
                        hostname: params.tableData[0].deviceHostname,
                        model: params.tableData[0].deviceModelName,
                        fwVersion: params.tableData[0].deviceFwVersion,
                        locationId: params.tableData[0].deviceLocationId,
                        modelCode: params.tableData[0].deviceModelCode,
                        vendorCode: params.tableData[0].deviceVendorCode
                    }];

                    return deviceInformation;
                }
            }

            return [];
        };

        var getDevices = function(extractorFunctions, params) {
            var devices = [];

            _.forEach(extractorFunctions, function(extractorFunction) {
                devices = extractorFunction(params);

                if (!_.isEmpty(devices)) {
                    return false;
                }
            });

            return devices;
        };

        resolver.tryGetDevices = function(isOneDeviceContext, deviceInContext, selectedValues, tableFilters, tableData) {
            var params: any = {
                isOneDeviceContext: isOneDeviceContext,
                deviceInContext: deviceInContext,
                selectedValues: selectedValues,
                tableFilters: tableFilters,
                tableData: tableData
            };
            return getDevices([getDeviceFromContext, getDeviceFromSelectedOnus, getDeviceFromFilter], params);
        };

        return resolver;
    }
]);
