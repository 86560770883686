/**
 * Validation Service.
 *
 * @author icaro.damiani, patrick.bard
 */
var app = angular.module("nms.dynamicDevice");

app.service("RestrictionService", ["$filter",
    function($filter) {
        /**
        * Finds all errors recursively through the form and their parent form.
        *
        * @param {object} rootForm Base form where the fields are.
        * @return {object} All errors found.
        */
        var findAllFormErrors = function(rootForm) {
            var form = rootForm;
            var errors = [];
            while (form && form.$error) {
                if (!_.isEmpty(form.$error)) {
                    errors.push(form.$error);
                }
                form = form.$$parentForm;
            }

            return errors;
        };

        /**
         * Verifies if the field is invalid considering a specific validator.
         *
         * @param {object} rootForm Base form where field is.
         * @param {string} validatorName The name of the target validator.
         * @param {string} fieldId The id of the field.
         * @return {boolean} Returns TRUE if the field has error if form with the given validator.
         */
        var isInvalidFieldValue = function(rootForm, validatorName, fieldId) {
            var allErrors = findAllFormErrors(rootForm);
            var filteredErrors = _.filter(allErrors, function(error) {
                return error.hasOwnProperty(validatorName);
            });
            var validatorErrors = filteredErrors.map(function(validator) {
                return validator[validatorName];
            });
            var hasErrorInField = _.some(validatorErrors, function(controllers) {
                var hasId = _.some(controllers, function(controller) {
                    return controller.$name === fieldId;
                });
                return hasId;
            });
            return hasErrorInField;
        };

        this.getLeafTypeValidatorName = function(leafType, restriction) {
            return leafType.name + ">" + restriction;
        };

        /**
         * Verifies if a field has any error except of the given exclude validators.
         *
         * @param {string} id Name of a field.
         * @param {object} form Current form being edited.
         * @param {object} excludedValidators Validator that will be ignored.
         * @return {boolean} Returns TRUE if a field has an error.
         */
        this.hasErrorsOnField = function(id, form, excludedValidators) {
            var allErrors = findAllFormErrors(form);
            var hasError = _.some(allErrors, function(error) {
                var filteredError = _.omit(error, excludedValidators);
                var modelControllers = _.flatten(_.values(filteredError));
                var isFieldError = _.some(modelControllers, function(modelController) {
                    return modelController && modelController.$name === id;
                });
                return isFieldError;
            });

            return hasError;
        };

        /**
         * Verifies if the form (and their parents) have validation error for the given field and validator names.
         *
         * @param {string} fieldId Name of the field.
         * @param {object} form Current form to check validation.
         * @param {object} validatorNames Array of validation names to check.
         * @return {boolean} Returns TRUE if the field has error of one of the given validators.
         */
        this.isInvalidForValidators = function(fieldId, form, validatorNames) {
            return _.some(validatorNames, function(validatorName) {
                return isInvalidFieldValue(form, validatorName, fieldId);
            });
        };

        this.hasErrorOnField = function(fieldId, validatorName, form) {
            return isInvalidFieldValue(form, validatorName, fieldId);
        };

        /**
         * Verifies if value has a invalid decimal format.
         *
         * @param {string} id Name of a field.
         * @param {object} form Current form being edited.
         * @return {boolean} Returns TRUE if decimal value has a invalid format.
         */
        this.isInvalidDecimalFormat = function(id, form) {
            return isInvalidFieldValue(form, "decimalFractionValidator", id)
                || isInvalidFieldValue(form, "decimalIntegerValidator", id);
        };

        /**
         * Verifies if list has more elements than its maximum limit.
         *
         * @param {string} id Name of a field.
         * @param {object} form Current form being edited.
         * @return {boolean} Returns TRUE if list has more elements than its maximum limit.
         */
        this.hasMoreElementsThanMax = function(id, form) {
            return isInvalidFieldValue(form, "maxElementsValidator", id);
        };

        /**
         * Verifies if list has fewer elements than its minimum limit.
         *
         * @param {string} id Name of a field.
         * @param {object} form Current form being edited.
         * @return {boolean} Returns TRUE if list has fewer elements than its minimum limit.
         */
        this.hasFewerElementsThanMin = function(id, form) {
            return isInvalidFieldValue(form, "minElementsValidator", id);
        };

        /**
         * Verifies if leaf-list has a duplicated element.
         *
         * @param {string} id Name of a field.
         * @param {object} form Current form being edited.
         * @return {boolean} Returns TRUE if leaf-list has a duplicated element.
         */
        this.hasDuplicatedElement = function(id, form) {
            return isInvalidFieldValue(form, "duplicatedElementValidator", id);
        };

        /**
        * Verifies if a number type field has invalid value.
        *
        * @param {string} id Name of a field.
        * @param {object} form Current form being edited.
        * @return {boolean} Returns TRUE if the field has invalid number value.
        */
        this.isInvalidNumber = function(id, form) {
            return isInvalidFieldValue(form, "number", id);
        };

        /**
        * Verifies if a field is empty.
        *
        * @param {string} id Name of a field.
        * @param {object} form Current form being edited.
        * @return {boolean} Returns TRUE if the field is empty.
        */
        this.isEmpty = function(id, form) {
            return isInvalidFieldValue(form, "required", id);
        };

        /**
        * Build an array of values based on a range.
        *
        *   This function generates a list of all possible values
        * based on a given input.
        *
        *   The input is given as a string, with ranges separated
        * by comma. It can have any number of specific values or ranges
        * in any combination of them. Example:
        *
        *     "1"             - One specific value
        *     "1, 2, 10"      - Multiple specific values
        *     "1-5"           - One range
        *     "1-3, 7, 8-10"  - Combination of both
        *
        *   Then it process the string and returns an array
        * with all possible values for that input. Example:
        *
        *     "1"             - Returns ["1"]
        *     "1, 2, 10"      - Returns ["1","2","10"]
        *     "1-5"           - Returns ["1","2","3","4","5"]
        *     "1-3, 7, 8-10"  - Returns ["1","2","3","7","8","9","10"]
        *
        *   In case the input is a number or an array of numbers,
        * an array with those values are returned. Example:
        *
        *     1               - Returns ["1"]
        *     [1]             - Returns ["1"]
        *     [1, 2, 10]      - Returns ["1","2","10"]
        *
        *   In case of any given range is invalid, an empty array is returned.
        * A range is considered invalid when the lower bound is greater (or equal)
        * than the upper bound. Example:
        *
        *     "1-A"             - Returns []
        *     "5-3"             - Returns []
        *     "1-10, 15-12"     - Returns []
        *
        * @param {string} ranges Input with ranges separated by comma.
        * @return {array} Returns an array with all possible values for the given ranges.
        */
        this.buildArrayOfValues = function(ranges) {
            var allValuesAreSpecificNumbers = _.every(ranges, _.isNumber);

            if (allValuesAreSpecificNumbers) {
                return _.map(_.flatten([ranges]), function(range) {
                    return range.toString();
                });
            }

            var arrayOfRanges = _.isArray(ranges) ? ranges : ranges.split(",");
            var listOfValues = [];

            for (var i = 0; i < arrayOfRanges.length; i++) {
                var detachedRange = arrayOfRanges[i].split("-");
                var start = parseFloat(detachedRange[0]);
                var end = parseFloat(detachedRange[1]);

                if (start >= end) {
                    return [];
                }

                if (end) {
                    var value = start;
                    do {
                        listOfValues.push((value++).toString());
                    } while (value <= end);
                } else {
                    listOfValues.push(start.toString());
                }
            }

            return _.uniq(listOfValues);
        };

        this.getRestrictionNodeInformation = function(node, type, hasMultipleRestrictions) {
            var information = hasMultipleRestrictions
                                ? _.unescape($filter("fixInfoValue")(_.get(type, "information")))
                                : _.unescape($filter("getInformation")(node, ". "));
            var info = _.isEmpty(information) ? "" : _.template(" (${value})")({value: information});

            return _.template("Must match the field pattern${info}.")({info: info});
        };
    }
]);
