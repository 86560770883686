import { SchedulerJob } from "../../scheduler/scheduler.models";

/**
 * Modelo que representa um backup de equipamento
 */
export class DeviceBackupConfiguration {
    cfgId: string;
    jobName: string;
    lastBackupDate: Date;
    deviceName: string;
    deviceHostname: string;
    deviceModel: number;
    deviceModelName: string;
    deviceFirmware: string;
}

/**
 * Modelo que representa os dados das modais de tarefa de backup
 */
export interface BackupJobModal {
    schedulerJob: SchedulerJob;
}

/**
 * Modelo contendo os dados a serem apresentados na modal de resultado de backup de equipamento.
 */
export interface DcbBackupResultModal {
    dcbBackupResult: DcbBackupResult;
}

/**
 * Modelo que representa os dados do resultado do backup de equipamento.
 */
export interface DcbBackupResult {
    deviceName: string;
    deviceModel: string;
    status: DcbResultStatus;
}

/**
 * Lista possíveis status de resultado de backup de equipamento.
 */
export type DcbResultStatus =
    "AWAITING_JOB_EXECUTION" |
    "SUCCESS" |
    "NOT_NECESSARY" |
    "SQL_ERROR" |
    "DAO_ERROR" |
    "UNKNOWN_ERROR" |
    "BLOCK_POLLING_REQUEST_ERROR" |
    "DEVICE_ACCESS_ERROR" |
    "SERVER_ACCESS_ERROR" |
    "DEVICE_LOCK_ERROR";

/**
 * Modelo que representa um backup de equipamento para visualização na tabela
 */
export interface DeviceBackupConfigurationView extends DeviceBackupConfiguration {
    lastBackupDateView: string;
    lastBackupDateTimestamp: number;
}

/**
 * Classe utilizada no filtro de busca global
 */
export class DevicesBackupListOptions {
    public static readonly FILTER_KEY: string = "list-devices-backup-options";
    public static readonly PROPERTIES_KEYS: Array<string> = ["globalFilter"];

    public globalFilter: string;

    constructor(globalFilter: string) {
        this.globalFilter = globalFilter;
    }
}

/**
 * Interface utilizada na resposta de remoção dos backups
 */
export interface RemoveBackupStatus {
    backupId: number;
    wasRemoved: boolean;
}

/*
* Interface utilizada no envio da requisição de restauração de um backup.
*/
export interface DcbRestoreRequest {
   backupId: number;
   resourceId: number;
   restoreOperationType: RestoreOperationType
   ignoreFirmwareVersion: boolean;
}

/** Tipos de operação de restauração */
export enum RestoreOperationType {
    REPLACE = "REPLACE",
    MERGE = "MERGE"
}

/**
 * Representação dos dados dos equipamentos CLI.
 */
export interface Device {
    id: number;
    dmOs: boolean;
    globalConfig: boolean;
    name: string;
    availableFeatures: any[];
    proxyCode: number;
    devNo: number;
    devLocalId: number;
    hostname: string;
    formattedHostname: string;
    status: string;
    serialNumber: string;
    model: string;
    modelCode: number;
    locationId: number;
    vendorCode: number;
    fwVersion: string;
    cpuUsage: string;
    cpuUsageAbsolute: string;
    memoryUsage: string;
    memoryUsageAbsolute: string;
    cpuLink: string;
    memoryLink: string;
    translatedStatus: string;
    lastSuccessfulPollingFinish: Date;
    lastSuccessfulPollingDuration: number;
    lastUpdate: string;
    shortcuts: any[];
    accessData: any;
    properties: {
        notes: string;
        project: string;
        internalLocation: {
            room: string;
            shelf: string;
            station: string;
        },
        externalLocation: {
            country: string;
            state: string;
            city: string;
            address: string;
        }
    },
    totalOnusLabel: string;
    path: string;
    totalRemotesLabel: string;
    exhibitionName: string;
    isPollingRunning: boolean;
}

/**
 * Modelo de equipamentos compatíveis para restauração de backup.
 */
export interface DcbCompatibleDevices {
    resourceId: number;
    name: string;
    hostname: string;
    modelName: string;
    serialNumber: string;
    fwVersion: string;
}

/**
 * Modelo que representa os dados de backup de configuração de equipamento.
 */
export interface Dcb {
    id : number;
    resourceId : number;
    modelCode : number;
    firmwareVersion : string;
    creationDate : string,
    configurationHash : string;
    config: string;
    configText: string;
}