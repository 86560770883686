"use strict";

var app = angular.module("nms.dynamicDevice");

app.directive("configurableContent", ["$rootScope", "ContentManager", "SessionPreferencesService", "$filter",
    function($rootScope, contentManager, sessionPreferencesService, $filter) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/configurable-content.html",
            link: function(scope) {
                scope.node = contentManager.getNode();
                scope.parentPath = contentManager.getContentPath();
                scope.keys = contentManager.getCurrentKeys();
                scope.pathKeys = contentManager.getPathKeys();
                scope.accordionSettings = sessionPreferencesService.getAccordionSettings(scope.parentPath);
                scope.accordionSettings.showConfig = $filter("hasConfigurableChildrenToShow")(contentManager.getCanonicalPath());
                scope.accordionSettings.showStatus
                    = $filter("hasNonConfigurableChildrenToShow")(contentManager.getCanonicalPath());
                scope.hasVisibleContent = contentManager.hasVisibleContent();

                $rootScope.currentFormIsInvalid = false;

                scope.$watch("form.$invalid", function(newValue) {
                    $rootScope.currentFormIsInvalid = newValue;
                });

                scope.$watch("form.$dirty", function(newValue) {
                    $rootScope.$broadcast("CONTENT_MODIFIED", newValue);
                });
            }
        };
    }
]);
