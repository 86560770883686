import { Injectable } from "@angular/core";
import { SchedulerDeviceBackupService } from "./scheduler-device-backup.service";
import { SchedulerTemplateApplicationService } from "./scheduler-template-application.service";
import { SchedulerInterface } from "./scheduler.interface";
import { JobType, ServiceByJobTypeMap } from "./scheduler.models";

/**
 * Classe responsável por selecionar a implementação correta do serviço de agendamento
 * a partir do tipo do agendamento contido em @see JobType.
 */
@Injectable({
    providedIn: "root"
})
export class SchedulerResolverService {

    private serviceByJobTypeMap: ServiceByJobTypeMap = {
        [JobType.BACKUP]: this.schedulerDeviceBackupService,
        [JobType.TEMPLATE_APPLICATION]: this.schedulerTemplateApplicationService
    };

    constructor(private readonly schedulerTemplateApplicationService: SchedulerTemplateApplicationService,
                private readonly schedulerDeviceBackupService: SchedulerDeviceBackupService) {
    }

    /**
     * Escolhe a implementação correta de SchedulerInterface a partir do tipo do scheduler.
     * @param jobType tipo do schedulerJob a ser utilizado.
     * @returns Implementação escolhida a partir do type.
     */
    resolve(jobType: JobType): SchedulerInterface {

        const service = this.serviceByJobTypeMap[jobType];

        if (!service) {
            throw new Error(`Service was not found for jobType: ${jobType}`);
        }

        return service
    }
}
