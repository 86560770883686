import { Injectable } from "@angular/core";
import { Device } from "@nms-ng2/app/modules/device/device-backup/device-backup.models";
import {
    EquipmentSelectionType
} from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { MatchingRules, RuleType } from "../../../../../../shared/components/elements/matching-rules/matching-rules.models";
import { DeviceFilterRule } from "@nms-ng2/app/modules/scheduler/scheduler.models";

/**
 * Serviço utilizado na validação dos equipamentos selecionados na criação de backups.
 */
@Injectable({
    providedIn: "root"
})
export class DevicesSelectionValidatorService {

    readonly RESOURCE_ID: string = "RESOURCE_ID";

    constructor() {}

    /**
     * Valida os equipamentos selecionados no filtro da criação de backups.
     * Caso o filtro seja 'Específico': Verifica se há algum equipamento selecionado.
     * Caso o filtro seja 'Por filtro de parâmetros': Verifica se há valores configurados nos filtros.
     */
    validateDevicesSelection(equipmentSelectionType: string, devices: Device[], matchingRules: MatchingRules): boolean {
        if (equipmentSelectionType == EquipmentSelectionType.SPECIFIC) {
            return this.validateDevicesSelected(devices);
        } else if (equipmentSelectionType === EquipmentSelectionType.FILTER) {
            return this.validateMatchingRules(matchingRules);
        }
        return true;
    }

    /**
     * Retorna deviceFilterRules preenchido de acordo com o filtro selecionado.
     */
    getDevicesFilterRules(equipmentSelectionType: string, devices: Device[],
        matchingRules: MatchingRules): DeviceFilterRule[] {
        let deviceFilterRules: DeviceFilterRule[] = [];
        if (equipmentSelectionType === EquipmentSelectionType.SPECIFIC) {
            deviceFilterRules =  devices.map(device => {
                return  {
                    ruleType: RuleType.EQUALS,
                    values: [device.id.toString()],
                    deviceField: this.RESOURCE_ID
                }
            });
        } else if (equipmentSelectionType === EquipmentSelectionType.FILTER) {
            deviceFilterRules = matchingRules.rules.map(rule => {
                return {
                    ruleType: rule.ruleType,
                    values: rule.values,
                    deviceField: rule.field
                }
            });
        }

        return deviceFilterRules;
    }

    private validateDevicesSelected(devices: Device[]): boolean {
        return devices.length > 0;
    }
    private validateMatchingRules(matchingRules: MatchingRules): boolean {
        const ruleOptions = matchingRules.rules
            .filter(rule => (rule.ruleType !== RuleType.IS_DMOS) && (rule.ruleType !== RuleType.NOT_DMOS));
        const allRulesValues = _.flatten(_.map(ruleOptions, "values"));

        if (_.some(allRulesValues, _.isEmpty)) {
            return false;
        }

        return true;
    }
}