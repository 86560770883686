var app = angular.module("nms.dynamicDevice");

app.directive("leafref", ["$rootScope",
    function($rootScope) {
        return {
            restrict: "E",
            templateUrl: "templates/features/dynamic-device/domain/yang/leaf/types/leafref.html",
            link: {
                pre: function preLink(scope, element, attrs, controller) {
                    scope.leafrefSelectionConfig = {
                        valueField: "value",
                        labelField: "value",
                        searchField: "value",
                        maxItems: 1,
                        onInitialize: function(selectize) {
                            if (scope.dataNode.value === null && scope.defaultValue && selectize.options) {
                                var firstOption = selectize.options[_.findKey(selectize.options)];
                                scope.dataNode = {
                                    value: firstOption ? firstOption.value : null
                                };
                            }
                        }
                    };

                    if (scope.isInsideListBody) {
                        scope.leafrefSelectionConfig.dropdownParent = "body";
                    }
                },
                post: function postLink(scope, element, attrs) {
                    var updateLeafrefValues = function() {
                        scope.options = scope.getLeafrefPossibleValues(scope.leaf);
                    };

                    var unregisterLeaflistDataNodeChanged = $rootScope.$on("leaflistDataNodeChanged", function() {
                        updateLeafrefValues();
                    });

                    var unregisterListDataNodeChanged = $rootScope.$on("listDataNodeChanged", function() {
                        updateLeafrefValues();
                    });

                    var unregisterNodeRestored = $rootScope.$on("nodeRestored", function() {
                        updateLeafrefValues();
                    });

                    scope.$on("$destroy", function() {
                        unregisterLeaflistDataNodeChanged();
                        unregisterListDataNodeChanged();
                        unregisterNodeRestored();
                    });
                }
            }
        };
    }
]);
