"use strict";

/*
*   @ngdoc controller
*   @name nms.management:RemoveGroupModalController
*   @description
*   Controller para a janela de seleção de um novo grupo, quando o grupo a ser excluído contiver usuários.
*/
var app = angular.module("nms.management");
app.controller("RemoveGroupModalController", ["$scope", "groups", "groupsForRemove", "$translate",
    function($scope, groups, groupsForRemove, $translate) {
        $scope.groups = _.filter(groups, function(group) {
            return !_.contains(groupsForRemove, group);
        });
        $scope.groupsForRemove = groupsForRemove;
        $scope.permissionType = "individual";

        if (!_.isEmpty($scope.groups)) {
            $scope.groupToMove = $scope.groups[0];
        }

        $scope.getConfirmationMessage = function() {
            var users = _.sum($scope.groupsForRemove, "users.length");

            if (users > 1) {
                var message = $translate.instant("management.group.remove.confirmation.plural.message");
                return message.replace("{0}", users);
            }

            return $translate.instant("management.group.remove.confirmation.singular.message");
        };

        $scope.getOptionsMessage = function() {
            var translateKey = _.sum($scope.groupsForRemove, "users.length") > 1
                ? "management.group.remove.confirmation.options.plural.message"
                : "management.group.remove.confirmation.options.singular.message";

            return $translate.instant(translateKey);
        };

        $scope.confirmRemoval = function() {
            var newGroupName = $scope.permissionType === "individual" ? null : $scope.groupToMove.name;
            $scope.confirm(newGroupName);
        };
    }
]);
