import { Injectable } from "@angular/core";
import { BackupJobData,
    JobExecutionStatus,
    JobType,
    SchedulerJob,
    TemplateApplicationJobData,
    DevicesFilter,
    BackupJobResult
 } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { TriggersService } from "@nms-ng2/app/modules/scheduler/triggers/triggers.service";
import {
    TriggerType,
    Trigger,
    TimeTriggerData,
    InformTriggerData
} from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";

/**
 * Classe utilitária para funções relacionadas ao Scheduler.
 **/
@Injectable({
    providedIn: "root"
})
export class SchedulerService {
    private readonly FIFTEEN_MINUTES: number = 15;
    private readonly FIVE_MINUTES: number = 5;
    private readonly SEVEN_DAYS = 7;

    constructor(private readonly triggersService: TriggersService) {}

    initSchedulerJob(schedulerJob: SchedulerJob, jobType: JobType): SchedulerJob {
        if (!schedulerJob) {
            return this.getDefaultSchedulerJob(jobType);
        } else {
            return this.prepareSchedulerJob(schedulerJob, jobType);
        }
    }

    prepareToSave(schedulerJob: SchedulerJob): SchedulerJob {
        if (this.hasSchedulerJob(schedulerJob)) {
            const enabledTriggers = schedulerJob.triggers.filter((trigger: Trigger) => trigger.triggerEnabled);
            schedulerJob.triggers = enabledTriggers;

            return schedulerJob;
        }

        return null;
    }

    hasSchedulerJob(schedulerJob: SchedulerJob): boolean {
        return schedulerJob.triggers && schedulerJob.triggers.some((trigger) => trigger.triggerEnabled);
    }

    private getDefaultSchedulerJob(jobType: JobType): SchedulerJob {
        return {
            name: "",
            type: jobType,
            jobData: this.getJobData(jobType),
            triggers: this.getTriggers(jobType),
            enabled: true,
            status: JobExecutionStatus.NOT_REQUESTED,
            jobResult: this.getJobResult(jobType)
        };
    }

    private getTriggers(jobType: JobType): Trigger[] {
        if (jobType === JobType.BACKUP) {
            return [this.getDefaultTimeTrigger(jobType)];
        }

        return [this.getDefaultTimeTrigger(jobType), this.getDefaultInformTrigger()];
    }

    private getJobData(jobType: JobType): TemplateApplicationJobData | BackupJobData {
        if (jobType === JobType.TEMPLATE_APPLICATION) {
            return this.getDefaultTemplateApplicationJobData();
        }
        return this.getDefaultBackupJobData();
    }

    private getJobResult(jobType: JobType): BackupJobResult | null {
        if (jobType === JobType.TEMPLATE_APPLICATION) {
            return null;
        }
        return { backupResultIds: [] } as BackupJobResult;
    }

    private getDefaultTemplateApplicationJobData(): TemplateApplicationJobData {
        return {
            templateApplicationId: "",
            templateApplicationName: "",
            templateType: "CLI",
        }
    }

    private getDefaultBackupJobData(): BackupJobData {
        return {
            description: "",
            exportBackups: false,
            devicesFilter: {} as DevicesFilter
        }
    }

    private getDefaultTimeTrigger(jobType: JobType): Trigger {
        return {
            triggerType: jobType === JobType.TEMPLATE_APPLICATION ? TriggerType.ONCE : TriggerType.ON_DEMAND,
            triggerEnabled: jobType === JobType.BACKUP ? true : false,
            triggerData: {
                initialDate: this.getInitialDate(),
                finalDate: this.getFinalDate(),
                finalDateEnabled: false
            } as TimeTriggerData
        };
    }

    private getDefaultInformTrigger(): Trigger {
        return {
            triggerEnabled: false,
            triggerType: TriggerType.TR069_EVENT,
            triggerData: {
                eventCodes: []
            } as InformTriggerData
        };
    }

    private getInitialDate(): Date {
        const date = new Date();
        date.setSeconds(0);
        date.setMinutes(this.roundMinutesUp(date) + this.FIFTEEN_MINUTES);
        return date;
    }

    private getFinalDate(): Date {
        const finalDate = this.getInitialDate();
        finalDate.setDate(finalDate.getDate() + this.SEVEN_DAYS);
        return finalDate;
    }

    private prepareSchedulerJob(schedulerJob: SchedulerJob, jobType: JobType) {
        schedulerJob.triggers.forEach(trigger => {
            trigger.triggerEnabled = true;
            if (this.triggersService.isTimeTrigger(trigger.triggerType)) {
                this.convertDates(trigger.triggerData as TimeTriggerData);
            }
        });

        if ((!this.hasInformTrigger(schedulerJob.triggers)) && jobType !== JobType.BACKUP) {
            schedulerJob.triggers.push(this.getDefaultInformTrigger());
        }

        if (!this.hasTimeTrigger(schedulerJob.triggers)) {
            schedulerJob.triggers.unshift(this.getDefaultTimeTrigger(jobType));
        }

        return schedulerJob;
    }

    private convertDates(triggerData: TimeTriggerData) {
        triggerData.initialDate = triggerData.initialDate ? new Date(triggerData.initialDate) : this.getInitialDate();
        triggerData.finalDate = triggerData.finalDate ? new Date(triggerData.finalDate) : this.getFinalDate();
    }

    /**
     *
     * Retorna os minutos da hora informada
     * arredondados para cima em múltiplos de 5
     *
     * @param date Uma data base para se obter os minutos
     * @return Um inteiro contendo os minutos arredondados
     */
    private roundMinutesUp(date: Date): number {
        return Math.ceil(date.getMinutes() / this.FIVE_MINUTES) * this.FIVE_MINUTES;
    }

    private hasInformTrigger(triggers: Trigger[]): boolean {
        return triggers.some(trigger => this.triggersService.isInformTrigger(trigger.triggerType))
    }

    private hasTimeTrigger(triggers: Trigger[]): boolean {
        return triggers.some(trigger => this.triggersService.isTimeTrigger(trigger.triggerType))
    }
}
