import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  NotificationPreferences
} from "@nms-ng2/app/modules/user/user-preferences/notification-preferences/notification-preferences.component";

export enum NotificationGroup {
    CPE_ONU = "CPE_ONU",
    TEMPLATE_APPLICATION = "TEMPLATE_APPLICATION",
    SCHEDULER = "SCHEDULER"
}

export type NotificationPreferencesByGroup = {
    [property in NotificationGroup]: NotificationPreferences[];
};

/**
 * Classe responsável por realizar as requisições referentes aos CPEs
 */
@Injectable({
  providedIn: "root",
})
export class NotificationPreferencesService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = "/notification/preferences";
  }

  public getPreferences(): Observable<NotificationPreferencesByGroup> {
    return this.http.get<NotificationPreferencesByGroup>(this.baseUrl);
  }

  public setPreferences(
    notificationsPreferences: NotificationPreferences[]
  ): Observable<void> {
    return this.http.post<void>(this.baseUrl, notificationsPreferences);
  }

  public disableNotificationOfType(notificationType: any): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/disable/${notificationType}`,
      {}
    );
  }
}
