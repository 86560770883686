import { NmsWizardStep } from "@nms-angular-toolkit/nms-wizard";
import { DevicesFilter } from "@nms-ng2/app/modules/scheduler/scheduler.models";
import { MatchingRules } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";

/**
 * Representa os dados de filtros por equipamento
 * para Agendamento de Backup de equipamentos.
 */
export class BackupDevicesFilter extends NmsWizardStep {
    devicesFilter: DevicesFilter;
    matchingRules: MatchingRules;
}