import {
    ANGULARJS_ROOTSCOPE,
    ANGULARJS_TRANSLATE,
    CONNECTIVITY_TEST_STATUS,
    NMS_STATES,
    STATE,
    TEMPLATE_TYPE,
    AUTHENTICATION_SERVICE,
    NMS_FEATURES
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { Inject, Injectable } from "@angular/core";
import { Cpe } from "./cpe-model";
import { ModalFactory } from "@nms-ng2/app/shared/services/modal/modal.factory";
import {
    CwmpConnectivityTestModalComponent,
    CwmpConnectivityTestModel
} from "@nms-ng2/app/shared/components/modals/cwmp-connectivity-test/cwmp-connectivity-test-modal.component";
import {
    AcsCredentialsModalComponent
} from "@nms-ng2/app/shared/components/modals/acs-credentials-modal/acs-credentials-modal.component";
import { PermissionsActionsService } from "@nms-ng2/app/shared/services/actions/permissions-actions.service";

/**
 * Utilitário para atalhos de CPE.
 */
@Injectable({
    providedIn: "root"
})
export class CpeShortcutsActionService {
    constructor(
        @Inject(AUTHENTICATION_SERVICE) private authenticationService: any,
        @Inject(NMS_FEATURES) private nmsFeatures: any,
        @Inject(ANGULARJS_ROOTSCOPE) private $rootScope: any,
        @Inject(STATE) private $state: any,
        @Inject(NMS_STATES) private nmsStates: any,
        @Inject(TEMPLATE_TYPE) private templateType: any,
        @Inject(ModalFactory) private modalFactory: ModalFactory,
        @Inject(ANGULARJS_TRANSLATE) private translate: any,
        @Inject(CONNECTIVITY_TEST_STATUS) private readonly connectivityStatus: any,
        private readonly permissionsActionsService: PermissionsActionsService
    ) {}

    checkValidateParametersLink = (selectedCpes: Array<Cpe>, openLinksInNewTab?: boolean) => {
        if (this.validadeSelectedCpeToParametersLink(selectedCpes)) {
            let { serialNumber } = selectedCpes[0];
            let openInNewTab = !angular.isUndefined(openLinksInNewTab) ? openLinksInNewTab : true;

            if (openInNewTab) {
                window.open(`#/parameters/${serialNumber}`, "_blank");
            } else {
                this.$state.go(this.nmsStates.cwmpParameters, { serialNumber });
            }
        }
    };

    openCredentialsModalCpes = (cpesSelected: Array<Cpe>) => {
        if (this.permissionsActionsService.isUserAdministrator()) {
            let cpes = cpesSelected.map((cpe) => ({
                serialNumber: cpe.serialNumber
            }));
            this.modalFactory.openAsyncModal(AcsCredentialsModalComponent, { cpesSelected: cpes });
        }
    };

    openConnectivityTestModalForCpes = async (cpesSelected) => {
        const translateStatus = this.translate.instant("connectivityTest.protocolstatus.RUNNING");
        const cwmps = cpesSelected.map((cpe) => ({
            serialNumber: cpe.serialNumber,
            equipmentId: cpe.productModel,
            cwmpCpeIp: cpe.hostname,
            percentage: 0,
            icmpTranslated: translateStatus,
            tr069Translated: translateStatus,
            icmp: {
                status: this.connectivityStatus.RUNNING
            },
            tr069: {
                status: this.connectivityStatus.RUNNING
            }
        }));
        this.modalFactory.openAsyncModal<CwmpConnectivityTestModel, null>(CwmpConnectivityTestModalComponent, { cwmps });
    };

    goToTemplateApplication = (cpesSelected: any) => {
        if (this.userHasPermission()) {
            const tr069Type = this.templateType.TR_069.name;
            const serialNumber = cpesSelected[0].serialNumber;
            window.open(`#/templateinstance/add/${tr069Type}?serialNumber=${serialNumber}`, "_blank");
        }
    };

    viewTemplateApplication = (selectedCpe: any) => {
        if (this.userHasPermission()) {
            window.open(`#/templateinstance?cpesSerialNumbers=${selectedCpe[0].serialNumber}`, "_blank");
        }
    };

    private userHasPermission(): boolean {
        var hasPermissionToEdit = this.authenticationService.hasPermission(this.nmsFeatures.templateInstance.feature);

        if (!hasPermissionToEdit) {
            this.noPermissionError();
            return false;
        }

        return true;
    }

    private noPermissionError(): void {
        this.$rootScope.showDialog({ translateKey: "cpe.tableAction.noUserPermission" });
    }

    private validadeSelectedCpeToParametersLink = (cpesSelected: Array<Cpe>) => {
        if (cpesSelected.length > 1) {
            this.$rootScope.showDialog({ translateKey: "cpe.tableAction.multiCpesSelectedError" });
            return false;
        }
        return true;
    };
}
