"use strict";

var contextPath;

var app = angular.module("nms.templates", [
    "ui.router",
    "ngRoute",
    "ngAria",
    "ngTouch",
    "ngAnimate",
    "ngCookies",
    "ngCookies",
    "ngMessages",
    "toastr",
    "ngTagsInput",
    "ui.bootstrap",
    "restangular",
    "darthwade.dwLoading",
    "ngDialog",
    "checklist-model",
    "tmh.dynamicLocale", // angular-dynamic-locale
    "pascalprecht.translate", // angular-translate
    "AuthenticationModule",
    "LicenseModule",
    "daterangepicker",
    "ui.indeterminate",
    "ui.ace"
]);

app.constant("BRAND_LOGOS", {
    1: {url: "images/logo-datacom-white.png", alt: "DATACOM"}
});

app.constant("BRAND_ICONS", {
    1: "images/icons/datacom/favicon.ico",
    defaultBrand: "images/icons/default/favicon.ico"
});

app.constant("ACTIONS_LIST", {
    reload: "loadPage"
});

app.constant("VARIABLES", {
    maxOptionsList: 5000,
    maxActionsList: 10,
    includeAndBlockOptionsModes: {
        DO_NOT_GET: "DO_NOT_GET",
        BLOCK: "BLOCK",
        INCLUDE: "INCLUDE",
        INCLUDE_AND_BLOCK: "INCLUDE_AND_BLOCK"
    },
    automaticReload: {
        DEFAULT_DELAY: 60,
        DEFAULT_STATE: false,
        MIN_DELAY: 2,
        MAX_DELAY: 900
    },
    TEXT: "TEXT",
    TEXT_LIST: "TEXT",
    INTEGER: "INTEGER",
    INTEGER_LIST: "INTEGER",
    SELECTION: "SELECTION",
    SELECTION_LIST: "SELECTION",
    READ_ONLY: "READ_ONLY",
    ACTION: "ACTION"
});

app.constant("PRESENTATION_MODE", {
    EQUIPMENTS_BY_TEMPLATE: "equipmentsByTemplate",
    TEMPLATES_BY_EQUIPMENT: "templatesByEquipment"
});

app.constant("EXECUTION_ORIGIN", {
    TEMPLATE_APPLICATION: "TEMPLATE_APPLICATION",
    SCHEDULER: "SCHEDULER"
});

app.constant("TEMPLATE_TYPE", {
    CLI: {name: "CLI", translationKey: "template.field.type.CLI"},
    TR_069: {name: "TR_069", translationKey: "template.field.type.TR_069"}
});

app.constant("TEMPLATE_LANGUAGE", {
    FREEMARKER: "FREEMARKER",
    PYTHON: "PYTHON"
});

/*
* Keyword reservada para templates do sistema.
*/
app.constant("SYSTEM_KEYWORD", "padrao");

app.config(["RestangularProvider", "toastrConfig", "tagsInputConfigProvider", "$uibTooltipProvider",
    function(RestangularProvider, toastrConfig, tagsInputConfigProvider, $uibTooltipProvider) {
        angular.extend(toastrConfig, {
            allowHtml: true,
            positionClass: "toast-bottom-right",
            messageClass: "toast-message1",
            tapToDismiss: true
        });

        tagsInputConfigProvider.setTextAutosizeThreshold(30);

        $uibTooltipProvider.options({
            placement: "bottom",
            appendToBody: true
        });
    }
]);

app.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("features/template");
}]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);

app.run(["$location", "$rootScope", "LoadingPaneService", "Restangular",
    function($location, $rootScope, loadingPaneService, Restangular) {
        contextPath = $location.host() + ":" + $location.port() + "/";
        /**
         * A variável $rootScope.hideLoadingPanel foi mantida para garantir compatibilidade
         * com o código legado que ainda a utiliza e seu uso deve ser evitado. O ideal para novas
         * implementações é utilizar o httpConfig.hideLoadingPanel.
         */
        Restangular.addFullRequestInterceptor(function(element, operation, what, url, headers, params, httpConfig) {
            if (!url.includes("login") && !$rootScope.hideLoadingPanel
                && (!httpConfig && !httpConfig.hideLoadingPanel)) {
                loadingPaneService.showLoadingPane();
            }
            return element;
        });

        Restangular.addResponseInterceptor(function(data) {
            loadingPaneService.closeLoadingPane();
            return data;
        });

        Restangular.setErrorInterceptor(function() {
            loadingPaneService.closeLoadingPane();
            return true; // error not handled
        });
    }]);

app.run(["$rootScope", "ConfigRESTService", "AUTH_EVENTS", function($rootScope, ConfigRESTService, AUTH_EVENTS) {
    $rootScope.$on(AUTH_EVENTS.loginSuccess, function() {
        if (!$rootScope.templateInstanceStatus) {
            ConfigRESTService.templateInstanceStatus().then(function(templateInstanceStatus) {
                $rootScope.templateInstanceStatus = templateInstanceStatus;
            });
        }
        if (!$rootScope.status) {
            ConfigRESTService.equipmentStatus().then(function(equipmentStatus) {
                $rootScope.status = equipmentStatus;
            });
        }
    });
}]);

app.run(["$rootScope", "WebSocketManagerService", "AUTH_EVENTS", "$injector",
    function($rootScope, WebSocketManagerService, AUTH_EVENTS, $injector) {
        $rootScope.$on(AUTH_EVENTS.startWebSocket, function() {
            const username: string = localStorage.getItem("username");
            WebSocketManagerService.connect(contextPath, username);

            // NMS Notification
            const nmsNotificationWebSocketService = $injector.get("NmsNotificationWebSocketService");
            nmsNotificationWebSocketService.connect(`/nms-notification/${username}`);
        });

        $rootScope.$on(AUTH_EVENTS.logoutSuccess, function() {
            WebSocketManagerService.disconnect();

            // NMS Notification
            const nmsNotificationWebSocketService = $injector.get("NmsNotificationWebSocketService");
            nmsNotificationWebSocketService.disconnect();
        });
    }
]);
