"use strict";

/**
* @ngdoc directive
* @name nms.templates.directive:SearchDirective
* @description
* # SearchDirective
*/
var app = angular.module("nms.templates");

app.directive("keyword", ["$timeout",
    function($timeout) {
        return {
            restrict: "E",
            templateUrl: "templates/features/template/components/ui/search/keyword.html",
            controller: "SearchKeywordController",
            link: {
                post: function(scope, elem, attr) {
                    var scrollSelector = "#" + scope.sidebarId + " .scroll";
                    var targetSelector = scrollSelector + " #" + scope.filters.keyword;

                    var setFocus = function() {
                        var scrollElement = angular.element(scrollSelector);
                        var targetElement = angular.element(targetSelector);

                        if (scrollElement.length > 0 && targetElement.length > 0) {
                            var middleUpperBound = (scrollElement.height()/2 - targetElement.outerHeight()/2);
                            scrollElement.scrollTop(targetElement.offset().top - scrollElement.offset().top - middleUpperBound);
                        } else {
                            $timeout(setFocus, 100);
                        }
                    };

                    if (scope.filters.keyword) {
                        $timeout(setFocus);
                    }
                }
            }
        };
    }
]);
