"use strict";

var app = angular.module("nms.gponOnus");

app.factory("GponOnusRestService", ["Restangular",
    function(Restangular) {
        const service: any = {};

        service.getAll = function() {
            return Restangular.all("/onus/all").withHttpConfig({hideLoadingPanel: true}).customGET();
        };

        service.findOnusByResourceId = function(resourceId) {
            return Restangular.one("/onus/allByDevice").withHttpConfig({hideLoadingPanel: true}).customGET(resourceId);
        };

        return service;
    }
]);
