var app = angular.module("nms.deviceConfigurationBackup", []);

app.config(["$translatePartialLoaderProvider", function ($translatePartialLoader) {
    $translatePartialLoader.addPart("features/device-configuration-backup");
}]);

app.run(["$translate", function ($translate) {
    $translate.refresh();
}
]);
