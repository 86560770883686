"use strict";

/**
 * Filter that replaces a TAB character on a given input with an HTML entity tab.
 *
 * @author patrick.bard
 */

var app = angular.module("nms");

app.filter("replaceTab",
    function() {
        return function(input) {
            return input.replace(/\t/g, "&emsp;");
        };
    }
);
