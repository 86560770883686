"use strict";

/**
 * @ngdoc service
 * @name nms.templates.SelectionVariableService
 * @description
 * # SelectionVariableService
 * Factory in the nms.templates.
 */
angular.module("nms.templates")
    .factory("SelectionVariableValidatorService", ["$rootScope", "VARIABLES", "ValidatorService",
        function($rootScope, VARIABLES, ValidatorService) {
            const service: any = {};

            service.validateOption = function(option, variable) {
                if (!option) {
                    $rootScope.showDialog({
                        translateKey: "addVariableModal.error.fieldOptionIsEmpty"
                    });
                    return false;
                } else {
                    if (variable.selectionOptions.length >= VARIABLES.maxOptionsList) {
                        var params = [];
                        params.push(VARIABLES.maxOptionsList);
                        $rootScope.showDialog({
                            translateKey: "addVariableModal.error.optionsListIsFull",
                            params: params,
                            paramsInsideMessage: true
                        });
                        return false;
                    } else {
                        for (var i = 0; i < variable.selectionOptions.length; i++) {
                            if (variable.selectionOptions[i] == option) {
                                $rootScope.showDialog({
                                    translateKey: "addVariableModal.error.repeatedOption"
                                });
                                return false;
                            }
                        }
                    }
                }
                return true;
            };

            service.validateRange = function(selectionRangeMin, selectionRangeMax) {
                if (angular.isUndefined(selectionRangeMin) || angular.isUndefined(selectionRangeMax)
                    || selectionRangeMin === null || selectionRangeMax === null) {
                    $rootScope.showDialog({
                        translateKey: "addVariableModal.error.rangeIsEmpty"
                    });
                    return false;
                } else if (selectionRangeMin > selectionRangeMax) {
                    $rootScope.showDialog({
                        translateKey: "addVariableModal.error.rangeIsInvalid"
                    });
                    return false;
                }
                return true;
            };

            service.validateSelectionVariable = function(variable) {
                if (variable.type === "SELECTION") {
                    let isEmpty = (value) =>  _.isNull(value) || _.isUndefined(value) || /^$/.test(value);
                    if (variable.allowAutoSelection === true
                        && (!variable.mandatory || !isEmpty(variable.defaultValue) || variable.blockingParams === null || variable.editable)) {
                        ValidatorService.showAlertBasicTab("addVariableModal.error.allowAutoSelection");
                        return false;
                    }
                }

                if (variable.options.length >= VARIABLES.maxOptionsList) {
                    var params = [];
                    params.push(VARIABLES.maxOptionsList);
                    $rootScope.showDialog({
                        translateKey: "addVariableModal.error.optionsListIsFull",
                        params: params,
                        paramsInsideMessage: true
                    });
                    return false;
                } else if (variable.options.length === 0 && (variable.includeAndBlockOptionMode === "BLOCK"
                    || variable.includeAndBlockOptionMode === "DO_NOT_GET")) {
                    ValidatorService.showAlertBasicTab("addVariableModal.error.optionListEmpty");
                    return false;
                } else if (angular.isDefined(variable.defaultValue) && variable.defaultValue !== "" && variable.defaultValue !== null) {
                    if (variable.options.indexOf(variable.defaultValue) === -1) {
                        ValidatorService.showAlertBasicTab("addVariableModal.error.defaultValueIsNotInList");
                        return false;
                    }
                }

                return true;
            };

            return service;
        }]);
