"use strict";

/**
* @ngdoc directive
* @name nms.directive:commandsViewer
* @restrict E
* @scope
* @param {String} label Texto que será mostrado acima do componente.
* @param {Function} buildTemplateInstanceCommandRequest Função que cria o modelo de requisição dos comandos.
* @param {Function=} getCommandTitle Responsável por formatar o título do template (Opcional).
* @param {Function} errorRetrieveData Função de callback para casos de erro.
* @param {boolean} showErrorOnConsole Booleano que define o valor do httpConfig bypassInterceptor.
                                      O valor default é false.
* @param {boolean} showCommandTypeTitle Booleano que define se os títulos dos tipos de comandos devem ser mostrados.
*                                       O valor default é true.
*
* @description: Diretiva para criar um componente de visualização de comandos a serem aplicados/removidos no equipamento,
* levando em consideração as associações de equipamentos e templates.
*
* @example:
* Estrutura do objeto TemplateInstanceCommandRequest e modo de criação:
* {
*   templateInstance: TemplateInstance que está sendo criado/editado,
*   templateInstanceOperation: Tipo da operação realizada, podendo ser CREATE/EDIT,
*   equipmentTemplateIdentifiers: Lista de associações equipamentos/templates
* }
*
* Para chamar a atualização dos dados na diretiva, a controller deve chamar o getCommands usando o comando
* $scope.$broadcast("getCommands").
*/
var app = angular.module("nms.templates");
app.directive("commandsViewer", function() {
    return {
        restrict: "E",
        templateUrl: "templates/features/template/components/ui/commands-viewer/commands-viewer.html",
        controller: "CommandsViewerController",
        scope: {
            label: "@",
            buildTemplateInstanceCommandRequest: "=",
            getCommandTitle: "=?",
            errorRetrieveData: "&?",
            showErrorOnConsole: "=?",
            showCommandTypeTitle: "=?",
            parentController: "="
        },
        link: function (scope) {
            if (scope.parentController) {
                scope.getCommandsTitle = scope.getCommandTitle.bind(scope.parentController) || scope.getCommandTitleDefault;
                scope.errorRetrieveData = scope.errorRetrieveData.bind(scope.parentController) || scope.errorRetrieveDataDefault;
            } else {
                scope.getCommandsTitle = scope.getCommandTitle || scope.getCommandTitleDefault;
                scope.errorRetrieveData = scope.errorRetrieveData || scope.errorRetrieveDataDefault;
            }
        }
    };
});
