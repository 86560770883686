import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import {
    NmsTableConfig,
    NmsTableColumn,
    AsyncUpdater,
    DropdownMenu,
    NmsTableRow
} from "@nms-ng1/components/ui/nms-table-old/nms-table-models";

/**
 * Encapsula o componente de tabela criado em AngularJS fazendo o upgrade da diretiva.
 *
 * A declaração dos objetos utilizados na criação de tabelas são encontradas em "nms-table-models".
 */
@Directive({
    selector: "nms-table-old"
})
export class NmsTable extends UpgradeComponent {
    /** Define as colunas da tabela. */
    @Input() columns: Array<NmsTableColumn>;
    /**
     * Define os elementos que serão apresentados em cada linha da tabela.
     * Os dados a serem utilizados como entrada para linhas da tabela, devem
     * extender a interface NmsTableRow.
     */
    @Input() rows: Array<NmsTableRow>;
    /** Define as ações globais da tabela. */
    @Input() tableActions: Array<Object>;
    /** Define as configurações da tabela. */
    @Input() config: NmsTableConfig;
    /** Define a configuração quando houver atualização de dados via websocket. */
    @Input() asyncUpdater?: AsyncUpdater;
    /** Define quais as colunas que deverão rendenrizar o componente de dropdown. */
    @Input() dropdownMenus?: Array<DropdownMenu>;
    /** Define a mensagem de corpo da tabela.*/
    @Input() bodyMessage?: string
    /** Ação de clique único realizada na tabela */
    @Input() singleClick?: Function
    /** Ação de clique duplo realizada na tabela */
    @Input() doubleClick?: Function
    /** Responsável por conter a função que retorna a lista de itens selecionados */
    @Input() selectedItems?: Object
    /** Responsável por atualizar os itens selecionados quando houver atualização de dados */
    @Input() selectionUpdater?: Object
    /** Responsável por exibir/esconder o ícone de loading */
    @Input() showLoading?: boolean

    constructor(elementRef: ElementRef, injector: Injector) {
        super("nmsTableOld", elementRef, injector);
    }
}