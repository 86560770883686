"use strict";

angular.module("nms.templates")
.factory("ActivationService", ["ActivationServiceREST", function(ActivationServiceREST) {
    const service: any = {};

    service.applyVariableCommands = function(commandTemplate) {
        return ActivationServiceREST.applyVariableCommands(commandTemplate);
    };

    service.validateVariableCommands = function(commandTemplate) {
        return ActivationServiceREST.validateVariableCommands(commandTemplate);
    };

    return service;
}]);
