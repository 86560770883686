"use strict";

/**
* @ngdoc function
* @name nms.templates.controller:ApplicationStatusCtrl
* @description
* # ApplicationStatusCtrl
* Controller of the nms.templates
*/
var app = angular.module("nms.templates");

/**
 * Controller usada na modal de visualização de resultados de comandos de inclusão e bloqueio em variáveis
 */
app.controller("ApplicationStatusCtrl", ["$scope",
    "$translate",
    "modalData",
    "devices",
    "APPLICATION_STATUS",
    "DeviceDropdownModelService",
    "BROADCAST_EVENTS",
    "VARIABLES",
    function(
        $scope,
        $translate,
        modalData,
        devices,
        APPLICATION_STATUS,
        DeviceDropdownModelService,
        BROADCAST_EVENTS, VARIABLES
    ) {
        var addedDevices = devices;
        $scope.modalData = modalData;
        $scope.devices = [];
        $scope.resultCommands = [];
        $scope.stacked = [{value: 0, type: "success"}, {value: 0, type: "warning"}, {value: 0, type: "danger"}];

        var progressBarValues: any = {danger: 0, warning: 0, success: 0};
        var length = 0;

        $scope.getTitle = function() {
            return $translate.instant("templateinstancelisting.results") + " - " +
                    $translate.instant("modals.results.variable") + $scope.modalData.currentApplication.name;
        };

        $scope.isApplyingCommand = function() {
            return _.some($scope.modalData.currentApplication.application.devices, {status: APPLICATION_STATUS.APPLYING});
        };

        $scope.isVariableAction = function () {
            const variableType = modalData.currentApplication.application.variable.type;
            return variableType === VARIABLES.ACTION;
        };

        $scope.refresh = function(application) {
            $scope.clearProgressBarData();
            $scope.resultCommands = [];
            $scope.devices.forEach(function(device) {
                device.status = APPLICATION_STATUS.APPLYING;
            });

            modalData.refresh(application);
        };

        $scope.shouldShowResult = function(extractionOptions) {
            return extractionOptions
                && (extractionOptions.result === "DEVICE_APPLICATION_ERROR" || extractionOptions.result === "SUCCESS");
        };

        modalData.updateApplicationStatus = function(deviceId, applicationName, extractedOptions) {
            if ($scope.modalData.currentApplication.name === applicationName) {
                var device = _.find($scope.devices, {deviceId: deviceId});

                if (device) {
                    device.status = extractedOptions.result;
                    device.extractedOptions = {
                        inclusionOptions: extractedOptions.inclusionOptions,
                        blockingOptions: extractedOptions.blockingOptions
                    };

                    $scope.calculateProgressBar(device);

                    var currentSelected = _.find($scope.devices, {selected: true});
                    if (device === currentSelected) {
                        $scope.showDetails(currentSelected);
                    }
                }
            }
        };

        $scope.clearProgressBarData = function() {
            progressBarValues.success = 0;
            progressBarValues.warning = 0;
            progressBarValues.danger = 0;

            $scope.stacked.forEach(function(item) {
                item.value = 0;
            });
        };

        $scope.getProgressBarTitle = function() {
            var count = 0;
            var progressBarStatusOrder = ["success", "warning", "danger"];
            var details = "";

            progressBarStatusOrder.forEach(function(status) {
                var occurrences = progressBarValues[status];
                count += occurrences;

                if (occurrences > 0) {
                    if (details !== "") {
                        details += ", ";
                    }

                    details += $scope.getLabelForStatus(status, occurrences);
                }
            });

            var sizeFrom = $translate.instant("modals.results.sizeFrom");
            var devicesCompleted = $translate.instant("modals.results.devicescompleted");
            var title = [count, sizeFrom, length, devicesCompleted, details].join(" ");

            return title;
        };

        $scope.getLabelForStatus = function(status, occurrences) {
            var label = "";
            if (status == "success") {
                label = $translate.instant("templateinstanceform.viewapply.templateStatus.success");
            } else if (status == "warning") {
                label = $translate.instant("templateinstanceform.viewapply.templateStatus.partial");
            } else {
                label = $translate.instant("templateinstanceform.viewapply.templateStatus.fail");
            }

            return label + " (" + occurrences + ")";
        };

        $scope.calculateProgressBar = function(device) {
            var elements = device.status;

            if (!_.includes(elements, APPLICATION_STATUS.APPLYING)) {
                var type = $scope.getProgressBarType(elements);
                var option = _.find($scope.stacked, {type: type});
                progressBarValues[type]++;
                option.value = ((progressBarValues[type] / length) * 100).toFixed(2);
            }
        };

        $scope.getProgressBarType = function(elements) {
            return _.includes(elements, APPLICATION_STATUS.SUCCESS) ? "success" : "danger";
        };

        $scope.getTemplateStatusLabel = function(status) {
            if (status == APPLICATION_STATUS.APPLYING) {
                return $translate.instant("templateinstanceform.viewapply.templateStatus.applying");
            }
        };

        $scope.getResultIcon = function(status) {
            if (status !== APPLICATION_STATUS.APPLYING) {
                return status === APPLICATION_STATUS.SUCCESS ? "glyphicon glyphicon-ok" : "glyphicon glyphicon-remove";
            }

            return "";
        };

        $scope.getDeviceLabel = function(device) {
            return $translate.instant("templateinstanceform.device") + ": " + device.deviceName + ", "
                        + $translate.instant("templateinstanceform.model") + ": " + device.deviceModel;
        };

        $scope.showDetails = function(device) {
            var currentSelected = _.find($scope.devices, {selected: true});
            if (currentSelected) {
                currentSelected.selected = false;
            }

            device.selected = true;

            if (device.status !== APPLICATION_STATUS.APPLYING) {
                var templatesWithRestrictions = _.find($scope.modalData.currentApplication.templatesWithRestrictionsByDevice, {deviceId: device.deviceId});

                $scope.resultCommands = [{
                    label: $translate.instant("templateinstanceform.viewapply.templates") + ": " + templatesWithRestrictions.templates.join(", "),
                    device: device
                }];
            }
        };

        $scope.createDeviceDropdownModel = function(deviceId) {
            var device = _.find(addedDevices, (device) => device.equipmentIdentifier.resourceId === deviceId);
            return DeviceDropdownModelService.createModelFromVariableExtractionResult(device);
        };

        $scope.getAvailableFeatures = function(deviceId) {
            return DeviceDropdownModelService.getAvailableFeatures(deviceId);
        };

        var unregisterDevicePropertiesChangeListener = $scope.$on(BROADCAST_EVENTS.DEVICE_NAME_CHANGED, function(event, payload) {
            var updatedDevice = payload.device;
            var device = _.find($scope.devices, {deviceId: updatedDevice.id});
            device.deviceName = updatedDevice.name;
        });

        $scope.init = function() {
            $scope.clearProgressBarData();
            $scope.devices = modalData.currentApplication.application.devices;
            length = $scope.modalData.currentApplication.application.devices.length;

            $scope.devices.forEach(function(device) {
                device.selected = false;
                $scope.calculateProgressBar(device);
            });

            var firstDevice = _.first($scope.devices);
            $scope.showDetails(firstDevice);
        };

        $scope.init();
        $scope.$on("$destroy", unregisterDevicePropertiesChangeListener);
    }
]);
