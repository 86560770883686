/**
* @ngDoc service
* @name jsonPathService
* @description Wrapper para o objeto global JSONPath
*/
var app = angular.module("nms");

app.service("jsonPathService", [function() {
    return JSONPath;
}]);
