import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { SimpleModalComponent } from "ngx-simple-modal";
import { AcsCredentialsComponent} from "@nms-ng2/app/modules/settings/acs-credentials/acs-credentials.component";
import { AcsCredentialsService } from "@nms-ng2/app/modules/settings/acs-credentials/acs-credentials.service";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

export interface AcsCredentialsModalModel {
    cpesSelected: any;
}

@Component({
  selector: "app-acs-credentials-modal",
  templateUrl: "./acs-credentials-modal.component.html",
  styleUrls: ["./acs-credentials-modal.component.css"]
})
export class AcsCredentialsModalComponent extends SimpleModalComponent<AcsCredentialsModalModel, any>
        implements AcsCredentialsModalModel, OnInit {

    @ViewChild(AcsCredentialsComponent, {static: false})
    acsCredentialsComponent: AcsCredentialsComponent;
    cpesSelected: any[];
    title: string;
    isMultipleSelection: boolean;
    isSpecificalCredentials: boolean;

    constructor(private acsCredentialsService: AcsCredentialsService,
                @Inject(ANGULARJS_TRANSLATE) private translate: any) {
        super();
        this.isMultipleSelection = false;
        this.isSpecificalCredentials = true;
        this.acsCredentialsService.cancelModalEmmiter.subscribe( data => {
            if (data) {
                this.close();
            }
        });
    }

    ngOnInit() {
        this.isMultipleSelection = this.cpesSelected.length > 1;
        const title = this.translate.instant("credentials.modal.title");
        const multipleCPE = this.translate.instant("credentials.modal.multipleCPE");
        const serialNumberLabel = this.translate.instant("credentials.modal.serialNumber");
        const serialNumber = this.cpesSelected[0].serialNumber;

        this.title = this.isMultipleSelection
            ? `${title} - ${multipleCPE}`
            : `${title} - ${serialNumberLabel}: ${serialNumber}`;
    }
}
