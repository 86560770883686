"use strict";

/**
 * @ngdoc service
 * @name nms.components.ui.device:DeviceDropdownShortcutsService
 * @description - Service para criação dos shortcuts utilizados na diretiva device-dropdown.
 * # DeviceDropdownShortcutsService
 * Factory in the nms.
 */
var app = angular.module("nms");

app.factory("DeviceDropdownShortcutsService", [
    "$window",
    "$translate",
    "NMS_FEATURES",
    "CpeShortcutsActionService",
    "TemplateInstanceIdentifierService",
    "CpesActionsService",
    "TemplateActionsService",
    "EquipmentsModalsActionsService",
    "TemplateInstanceValidatorService",
    "GponOnusService",
    function (
        $window,
        $translate,
        NMS_FEATURES,
        CpeShortcutsActionService,
        TemplateInstanceIdentifierService,
        CpesActionsService,
        TemplateActionsService,
        EquipmentsModalsActionsService,
        TemplateInstanceValidatorService,
        GponOnusService
    ) {
        const service: any = {};

        function addFeatureShortcut(shortcuts, labelKey, url, openInNewTab, deviceAvailableFeatures?, requiredFeature?) {
            if (!requiredFeature || _.includes(deviceAvailableFeatures, requiredFeature)) {
                var shortcut: any = {
                    label: $translate.instant(labelKey),
                    isBold: true,
                    isVisible: function () {
                        return true;
                    },
                    action: function () {
                        $window.open(url, openInNewTab ? "_blank" : "_self");
                    }
                };

                shortcuts.push(shortcut);
            }
        }

        function createActionShortcut(labelKey, action, parameter) {
            return {
                label: $translate.instant(labelKey),
                isBold: true,
                isVisible: function () {
                    return true;
                },
                action: function () {
                    action(parameter);
                }
            };
        }

        service.getFeaturesShortcuts = function (deviceAvailableFeatures, deviceName, openInNewTab) {
            var shortcuts = [];
            addFeatureShortcut(
                shortcuts,
                "summary.shortcut.summary",
                "#/device/summary/" + deviceName,
                openInNewTab,
                deviceAvailableFeatures,
                NMS_FEATURES.summary.feature
            );
            addFeatureShortcut(
                shortcuts,
                "summary.shortcut.infoConfig",
                "#/device/" + deviceName,
                openInNewTab,
                deviceAvailableFeatures,
                NMS_FEATURES.dynamicDeviceConfig.feature
            );
            addFeatureShortcut(
                shortcuts,
                "summary.shortcut.alarms",
                "#/device/" + deviceName + "?path=$.containers.alarm",
                openInNewTab,
                deviceAvailableFeatures,
                NMS_FEATURES.dynamicDeviceConfig.feature
            );
            addFeatureShortcut(
                shortcuts,
                "summary.shortcut.gponOnus",
                `#/device/${GponOnusService.getGponOnuUIPath()}/${deviceName}`,
                openInNewTab,
                deviceAvailableFeatures,
                NMS_FEATURES.gponOnus.feature
            );

            return shortcuts;
        };

        service.getShortcutsByEquipmentType = function (device, openInNewTab) {
            if (device.type && !TemplateInstanceIdentifierService.isDeviceIdentifier(device.type)) {
                return getTemplateApplicationTR069Shortcuts(device);
            }
            if (!TemplateInstanceValidatorService.supportsCLITemplateApplication(device.productModel)) {
                return getDevicePropertiesShortcuts(device.id)
            }

            return [].concat(
                getTemplatesShortcuts(device.id, device.name, openInNewTab),
                getDevicePropertiesShortcuts(device.id)
            );
        };

        function getTemplatesShortcuts(deviceId, deviceName, openInNewTab) {
            var shortcuts = [];
            shortcuts.push(
                createActionShortcut(
                    "device.dropdown.shortcut.applyTemplate",
                    TemplateActionsService.tryApplyTemplateByDeviceId,
                    deviceId
                )
            );
            addFeatureShortcut(
                shortcuts,
                "device.dropdown.shortcut.viewTemplateApplications",
                "#/templateinstance?devicehostnames=" + deviceName,
                openInNewTab
            );

            return shortcuts;
        }

        function getDevicePropertiesShortcuts(deviceId) {
            var shortcuts = [];
            shortcuts.push(
                createActionShortcut(
                    "device.dropdown.shortcut.managementProtocols",
                    EquipmentsModalsActionsService.tryOpenCredentialsByDeviceId,
                    deviceId
                )
            );
            shortcuts.push(
                createActionShortcut(
                    "device.dropdown.shortcut.properties",
                    EquipmentsModalsActionsService.tryOpenPropertiesConfigByDeviceId,
                    deviceId
                )
            );
            shortcuts.push(
                createActionShortcut(
                    "device.dropdown.shortcut.connectivityTest",
                    EquipmentsModalsActionsService.openConnectivityTestByDeviceId,
                    deviceId
                )
            );

            return shortcuts;
        }

        function getTemplateApplicationTR069Shortcuts(equipment) {
            var shortcuts = [];
            shortcuts.push(
                createActionShortcut(
                    "gpon.cpes.tableActions.tr069.parameters",
                    CpeShortcutsActionService.checkValidateParametersLink,
                    new Array(equipment)
                )
            );
            shortcuts.push(
                createActionShortcut(
                    "gpon.cpes.tableActions.tr069.applyTemplate",
                    CpeShortcutsActionService.goToTemplateApplication,
                    new Array(equipment)
                )
            );
            shortcuts.push(
                createActionShortcut(
                    "gpon.cpes.tableActions.tr069.viewTemplateApplication",
                    CpeShortcutsActionService.viewTemplateApplication,
                    new Array(equipment)
                )
            );
            shortcuts.push(
                createActionShortcut(
                    "gpon.cpes.tableActions.tr069.testConnectivity",
                    CpeShortcutsActionService.openConnectivityTestModalForCpes,
                    new Array(equipment)
                )
            );
            shortcuts.push(
                createActionShortcut(
                    "gpon.cpes.tableActions.tr069.credentials",
                    CpeShortcutsActionService.openCredentialsModalCpes,
                    new Array(equipment)
                )
            );
            shortcuts.push(
                createActionShortcut(
                    "gpon.cpes.tableActions.tr069.download",
                    CpesActionsService.openDownloadRequestModal,
                    new Array(equipment)
                )
            );
            shortcuts.push(
                createActionShortcut("gpon.cpes.tableActions.tr069.reboot", CpesActionsService.rebootCpes, new Array(equipment))
            );

            return shortcuts;
        }

        return service;
    }
]);
