"use strict";

import { RuleType } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";

/* global Promise */

/**
 * @ngdoc service
 * @name nms.templates.TemplateService
 * @description
 * # TemplateService
 * Allow access to TemplateRESTService to other classes.
 * @return {Object} TemplateService object.
 */
angular.module("nms.templates")
    .factory("TemplateService", ["TemplateRESTService", "$q", "$rootScope", "ConverterService", "SYSTEM_KEYWORD",
        function(TemplateRESTService, $q, $rootScope, ConverterService, SYSTEM_KEYWORD) {
            const service: any = {};

            service.save = function(template) {
                // FIXME - Conversão devido a issue: https://github.com/mbenford/ngTagsInput/issues/678
                if (template.keywords) {
                    template.keywords = ConverterService.convertArrayObjectToStringArray(template.keywords);
                }
                template.modifiedBy = localStorage.getItem("username");

                if (template.id) {
                    if (template.used) {
                        return TemplateRESTService.updateBasic(template);
                    } else {
                        return TemplateRESTService.updateFull(template);
                    }
                } else {
                    return TemplateRESTService.save(template);
                }
            };

            service.delete = function(templates) {
                var deferred = $q.defer();

                if (templates.length <= 0) {
                    deferred.reject("No selected template");
                } else {
                    TemplateRESTService.delete(templates)
                        .then(function success(response) {
                            deferred.resolve(response);
                        }, function error(response) {
                            deferred.reject(response);
                        });
                }

                return deferred.promise;
            };

            service.clone = function(templateId) {
                return TemplateRESTService.get(templateId).then(function success(template) {
                    var templateToClone = angular.copy(template.plain());
                    delete templateToClone.id;
                    templateToClone.name = templateToClone.name.concat("_clone");
                    templateToClone.keywords = _.remove(templateToClone.keywords, function(keyword) {
                        return keyword.toLowerCase() !== SYSTEM_KEYWORD;
                    });
                    delete templateToClone.used;
                    delete templateToClone.templateInstances;
                    if (templateToClone.variables) {
                        templateToClone.variables.forEach(function(variable) {
                            variable.favorite = false;
                        });
                    }
                    return templateToClone;
                });
            };

            service.checkUncheckAll = function(selected, templates) {
                if (selected) {
                    return templates.slice();
                } else {
                    return [];
                }
            };

            service.getTemplateNameFromId = function(templateIds, templates) {
                var templatesNames = "<br>";
                templateIds.forEach(function(id) {
                    templates.forEach(function(template) {
                        if (id === template.id) {
                            templatesNames += template.name + "<br>";
                        }
                    });
                });
                return templatesNames;
            };

            service.getTemplatesId = function(templateArray) {
                var templatesId = [];
                if (Array.isArray(templateArray)) {
                    templateArray.forEach(function(element) {
                        templatesId.push(element.id);
                    });
                    return templatesId;
                }
                return null;
            };

            service.getPaginated = function(filters) {
                if (!filters.searchName) {
                    filters.searchName = "";// Remove template name for search if there is no template name filled
                }
                return TemplateRESTService.getPaginated(filters);
            };

            /* Check if there is search criteria defined by user */
            service.hasSearchCriteria = function(filters) {
                if (!filters.searchTerm) {
                    return false;
                }
                return true;
            };

            service.selectAndUnselectTemplate = function(template, checkedTemplates) {
                var index = checkedTemplates.indexOf(template);
                if (index < 0) {
                    checkedTemplates.push(template);
                } else {
                    checkedTemplates.splice(index, 1);
                }
            };

            service.removeTemplateByName = function(templateName, checkedTemplates) {
                const index = checkedTemplates.findIndex(checkedTemplate => {
                    return templateName === checkedTemplate.name;
                });
                if (index !== -1) {
                    checkedTemplates.splice(index, 1);
                }
            };

            service.removeInvalidTemplatesSelected = function(templatesNames, checkedTemplates) {
                templatesNames.forEach(function(templateName) {
                    service.removeTemplateByName(templateName, checkedTemplates);
                });
            };

            service.getTemplatesName = function(templates) {
                var templatesName = [];
                templates.forEach(function(template) {
                    templatesName.push(template.name);
                });
                return templatesName;
            };

            service.extractUsedTemplates = function(templates) {
                var usedTemplates = [];
                templates.forEach(function(template) {
                    if (template.used) {
                        usedTemplates.push(template.name);
                    }
                });
                return usedTemplates;
            };

            service.extractTemplatesWithSystemKeyword = function(templates) {
                var templatesWithSystemKeyword = [];
                templates.forEach(function(template) {
                    var keywords = _.map(template.keywords, function(keyword) {
                        return keyword.toLowerCase();
                    });

                    if (_.includes(keywords, SYSTEM_KEYWORD)) {
                        templatesWithSystemKeyword.push(template.name);
                    }
                });

                return templatesWithSystemKeyword;
            };

            service.getTemplateNamesFromResults = function(templateResults) {
                var templateNames = [];

                templateResults.forEach(function(templateResult) {
                    templateNames.push(templateResult.templateName);
                });

                return templateNames;
            };

            /* Allow service method to be called from HTML without code repetition */
            $rootScope.getTemplateNamesFromResults = function(templateResults) {
                return service.getTemplateNamesFromResults(templateResults).join(", ");
            };

            service.getTotalKeywords = function(countOnlyAllowedTemplates) {
                return TemplateRESTService.getTotalKeywords(countOnlyAllowedTemplates);
            };

            service.exportSelectedTemplates = function(templateIds) {
                return TemplateRESTService.exportSelectedTemplates(templateIds);
            };

            service.exportAllTemplates = function() {
                return TemplateRESTService.exportAllTemplates();
            };

            service.testTemplateCommand = function(name, template, variables) {
                var validationModel: any = {};

                validationModel.name = name;
                validationModel.template = template;
                validationModel.variables = variables;

                return TemplateRESTService.validateTemplate(validationModel);
            };

            service.validateTemplateCommands = function(template) {
                // FIXME - Conversão devido a issue: https://github.com/mbenford/ngTagsInput/issues/678
                if (template.keywords) {
                    template.keywords = ConverterService.convertArrayObjectToStringArray(template.keywords);
                }

                return TemplateRESTService.validateTemplateCommands(template);
            };

            service.validateCommands = function(commands, syntaxErrorMsgKey, tabTitleKey) {
                return TemplateRESTService.validateCommands(commands).then(function(response) {
                    return new Promise(function(resolve, reject) {
                        if (response.errorMessage) {
                            var matches = response.errorMessage.match(/line (\d+), column (\d+):?\n(.*)/);
                            reject({
                                row: matches[1] - 1,
                                column: matches[2] - 1,
                                html: response.errorMessage.replace(/\\n/g, ""),
                                type: "error",
                                syntaxErrorMsgKey: syntaxErrorMsgKey,
                                tabTitleKey: tabTitleKey
                            });
                        } else {
                            resolve();
                        }
                    });
                });
            };

            service.import = function(file) {
                return TemplateRESTService.import(file);
            };

            service.getTemplatesByKeywords = function(word) {
                return TemplateRESTService.getTemplatesByKeywords(word);
            };

            service.getSearchType = function(searchField) {
                return searchField ? searchField.fieldType : "TEXT";
            };

            service.validateName = function(templateId, templateName) {
                var templateIdName: any = {
                    templateId: templateId,
                    templateName: templateName
                };

                return TemplateRESTService.validateName(templateIdName);
            };

            /**
             * Verifica se a regra tem valores inválidos:
             * - SE for diferente de IS_DMOS, NOT_DMOS, BETWEEN e NOT_BETWEEN valida se existe um valor e se é igual a "".
             * - SE for igual a BETWEEN ou NOT_BETWEEN verifica se algum dos dois valores é igual a "".
             */
            const isRuleValuesInvalid = (rule) => {
                const rulesWithoutValues = [RuleType.IS_DMOS, RuleType.NOT_DMOS];
                const rulesWithMultipleValues = [RuleType.BETWEEN, RuleType.NOT_BETWEEN];

                if (rulesWithMultipleValues.includes(rule.ruleType)) {
                    return rule.values.length !== 2 || rule.values[0].trim() === "" || rule.values[1].trim() === "";
                }

                return !rulesWithoutValues.includes(rule.ruleType) && (rule.values.length !== 1 || rule.values[0].trim() === "");
            };

            service.validateModelRestrictionRules = function(useMatchingRules, matchingRules) {
                if (useMatchingRules) {
                    return !_.some(matchingRules, isRuleValuesInvalid);
                }

                return true;
            };

            service.isAllowedTemplateConfig = function(hasTemplateConfigPermission) {
                if (!hasTemplateConfigPermission) {
                    $rootScope.showDialog({
                        translateKey: "templatelisting.userHasNoPermission"
                    });

                    return false;
                }

                return true;
            };

            /**
             * Devido a um bug do restangular (https://github.com/mgonto/restangular/issues/1107) não é possível
             * retornar o campo options na raiz do JSON pois ele é sobrescrito pela função
             * options do HTTP. Para "contornar" esse comportamento é retornada uma lista do backend com um item apenas.
             */
            service.getVariable = function(variableTemplateNames) {
                return TemplateRESTService.getVariable(variableTemplateNames).then(function(response) {
                    return _.first(response);
                });
            };

            service.filterRestrictedTemplates = function(templateIds) {
                return TemplateRESTService.filterRestrictedTemplates(templateIds).then((response) => response.plain());
            };

            return service;
        }]);
