"use strict";

/**
* @ngdoc constants
* @name ONUs Constants
* @description
* # Constants used in GPON ONUs model.
*/

var app = angular.module("nms");

app.constant("ONU_CONSTANTS", {
    IPV4_MODE: {
        DHCP: "gpon.onus.ipv4.mode.dhcp",
        STATIC: "gpon.onus.ipv4.mode.static",
        NOT_CONFIGURED: "gpon.onus.ipv4.mode.notConfigured"
    },
    SNMP: {
        TYPE: {
            ALL: "ALL",
            PROFILE: "PROFILE",
            DISABLED: "DISABLED"
        },
        VALUE: {
            ALL: "gpon.onus.snmp.all",
            DISABLED: "gpon.onus.snmp.disabled"
        }
    },
    PORT_COLUMN_KEY: "portViewName"
});
