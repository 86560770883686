"use strict";

var app = angular.module("nms");

/**
 * Diretiva para o passo de Comandos da criação/edição de Template.
 */
app.directive("templateCommandsStep", [
    function() {
        return {
            restrict: "E",
            scope: {
                templateController: "=",
                stepController: "="
            },
            templateUrl: "templates/components/ui/template-commands-step.html"
        };
    }
]);