"use strict";

import { DeviceField, EquipmentRuleOption } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { TemplateInstanceRESTService } from "../template-instance-rest-service";
import { TemplateInstanceService } from "../template-instance-service";

/**
 * @ngdoc function
 * @name nms.templates.controller:AddDeviceModalCtrl
 * @description
 * Controller of the nms.templates
 */
var app = angular.module("nms.templates");

app.controller("AddDeviceModalCtrl", [
    "$scope",
    "$rootScope",
    "DeviceService",
    "devicesAlreadyAdded",
    "templateInstanceResolvedInformation",
    "PRESENTATION_MODE",
    "$translate",
    "TemplateInstanceService",
    "DeviceDiscoverService",
    "deviceModelRestrictionTypes",
    "DeviceDropdownModelService",
    "TemplateInstanceRESTService",
    "monitoringSettings",
    "EquipmentService",
    "TEMPLATE_TYPE",
    function (
        $scope,
        $rootScope,
        DeviceService,
        devicesAlreadyAdded,
        templateInstanceResolvedInformation,
        PRESENTATION_MODE,
        $translate,
        TemplateInstanceService: TemplateInstanceService,
        DeviceDiscoverService,
        deviceModelRestrictionTypes,
        DeviceDropdownModelService,
        TemplateInstanceRESTService: TemplateInstanceRESTService,
        monitoringSettings,
        EquipmentService,
        TEMPLATE_TYPE
    ) {
        $scope.monitoringSettings = monitoringSettings;
        $scope.devices = [];
        $scope.templateType = templateInstanceResolvedInformation.templateType;
        $scope.templates = _.get(templateInstanceResolvedInformation, "templateAlreadyAdded", null);
        $scope.disableRefreshButton = false;

        var restrictionRules = _.compact(
            _.map($scope.templates, function (template) {
                if (template.restrictTemplate) {
                    return template.equipmentRestrictionFilter;
                }
            })
        );

        $scope.devicesListOwner = {
            addDeviceDbClickFn: function () {
                $scope.addDeviceDbClick();
            },
            selectAndUnselectDeviceFn: function (event, device, index, selection) {
                $scope.selectAndUnselectDevice(event, device, index, selection);
            },
            devicesProvider: () => {
                const equipmentsFilters = {
                    globalRules: restrictionRules,
                    rulesByTemplate: {}
                };

                return TemplateInstanceRESTService.getDevicesByRestrictions(equipmentsFilters).then(
                    function (response) {
                        return _.values(response.equipmentDetails);
                    }
                );
            }
        };

        $scope.retrieveDevices = function () {
            $scope.disableRefreshButton = true;
            $scope.updateDevices().then(() => ($scope.disableRefreshButton = false));
        };

        $scope.updateDevices = function () {
            return $scope.devicesListOwner.updateModel.updateFunction().toPromise();
        };

        $scope.presentationMode = templateInstanceResolvedInformation.presentationMode;
        $scope.presentationModeDefault = PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;
        $scope.deviceModelRestrictionTypes = deviceModelRestrictionTypes;

        this.loadTootipTexts = function () {
            $scope.theSearchUsesOnlyTheSelectedField = $translate.instant(
                "tooltips.addEquipmentmodal.theSearchUsesOnlyTheSelectedField"
            );
        };
        this.loadTootipTexts();

        $scope.selectAndUnselectDevice = function (event, device, index, selection) {
            $scope.firstSelectedIndex = angular.copy($scope.currentIndex);
            $scope.currentIndex = index;

            if (event.shiftKey && !_.isUndefined($scope.firstSelectedIndex)) {
                var index = $scope.currentIndex < $scope.firstSelectedIndex ? $scope.currentIndex : $scope.firstSelectedIndex;
                var finalIndex =
                    $scope.currentIndex > $scope.firstSelectedIndex ? $scope.currentIndex : $scope.firstSelectedIndex;
                TemplateInstanceService.selectMultiple(index, finalIndex, selection.checked, $scope.devices);
            } else if (!event.shiftKey) {
                EquipmentService.selectAndUnselectEquipment(device, selection.checked);
            }
        };

        $scope.addDeviceDbClick = function () {
            $scope.validateAddDevices();
        };

        $scope.validateAddDevices = function () {
            var selection = $scope.devicesListOwner.getCheckedDevices();

            DeviceService.validateAddDevices(selection, devicesAlreadyAdded, $scope.presentationMode.value,
                templateInstanceResolvedInformation.shouldAddDevicesToAllTemplates, $scope.templates, TEMPLATE_TYPE.CLI.name)
            .then(selection => {
                $scope.confirm(selection);
            });
        };

        $scope.getTemplatesNames = function () {
            return TemplateInstanceService.getJoinedProperties($scope.templates, "templateName");
        };

        $scope.getJoinRangeRules = function () {
            return TemplateInstanceService.getJoinedProperties($scope.templates, "joinRangeRules");
        };

        $scope.getAllTemplatesRules = function () {
            return _.map($scope.templates, function (template) {
                return {
                    hasRuleOptions: template.restrictTemplate,
                    rulesMatchingMode: template.equipmentRestrictionFilter.rulesMatchingMode,
                    ruleOptions: template.equipmentRestrictionFilter.equipmentRuleOption
                };
            });
        };

        $scope.createDeviceDropdownModel = function (device) {
            return DeviceDropdownModelService.createModelFromDeviceSearchModal(device);
        };

        $scope.getAvailableFeatures = function (deviceId) {
            return DeviceDropdownModelService.getAvailableFeatures(deviceId);
        };
    }
]);
