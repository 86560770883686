"use strict";

var app = angular.module("LicenseModule", [
    "ngRoute",
    "ui.router"
]);

app.constant("NMS_LICENSES", {
    deviceCustom: {
        name: "NMS_CLI_TEMPLATE_FOR_CUSTOM",
        userFriendlyName: "CLI Template Multivendor"
    },
    highAvailability: {
        name: "HIGH_AVAILABILITY",
        userFriendlyName: "High Availability"
    },
    notRequired: {
        name: "NONE"
    }
});

app.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("components/license");
}]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);
