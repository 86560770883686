import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

/**
 * Modelo que define como um atalho deverá ser cadastrado.
 * Encapsula a estrutura necessária utilizada no componente dropdown-directive.js
 * do AngularJS.
 * */
 export interface NmsDropdownShortcut {
    /** O label que será exibido para o atalho. */
    label: string,
    /** Define se o atalho possui linha separadora. Default: false */
    hasDivider?: boolean,
    /** define se o atalho deve ser exibido em negrito. Default: false  */
    isBold?: boolean,
    /** Define se o atalho deve ser exibido. */
    isVisible?(): boolean,
    /** Ação a ser realizada ao clicar no atalho. */
    action(item: Object): void
}

/**
 * @deprecated - Utilizar o novo componente nms-dropdown do nms-angular-toolkit.
 *
 * Upgrade da diretiva nms-dropdown-old desenvolvida em AngularJS.
 */
@Directive({
    selector: "nms-dropdown-old"
})
export class NmsDropdownOld extends UpgradeComponent {
    /** Define os shortcuts que estarão presentes no dropdown. */
    @Input() shortcuts: Array<NmsDropdownShortcut>;
    /** Parâmetros a serem utilizados nos eventos de click do atalho. */
    @Input() parameters: Object;
    /**
     * Define um título a ser apresentado na lista de atalhos.
     * Por padrão o título é apresentado dentro de um elemento <h3>.
    */
    @Input() dropdownTitle?: string;
    /** Apresenta o título do dropdown dentro de um elemento <span>. Default: false */
    @Input() useTitleAsPlainText?: boolean;
    /** Apresenta um tooltip explicando o motivo do atalho estar desabilitado. */
    @Input() disabledShortcutTooltip?: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super("nmsDropdownOld", elementRef, injector);
    }
}