export interface PaginationFilter {
    pageNumber: number;
    pageSize: number;
    column: string;
    direction: string;
    searchTerm?: string;
    searchColumn?: string;
    currentType?: string;
    keyword?: string;
}

export interface PaginationResponse<T> {
    pageElements: Array<T>;
    pageSize: number;
    pageNumber: number;
    totalElements: number;
    numberOfElements: number;
}

/**
* Cria URI para busca paginada de elementos.
*/
export function buildPaginatedURI(baseUrl: string, filters: PaginationFilter): string {
    const URI = [];

    const pageNumberParam = `pageNumber=${filters.pageNumber}`;
    const pageSizeParam = `pageSize=${filters.pageSize}`;
    const columnParam = `column=${filters.column}`;
    const directionParam = `direction=${filters.direction}`;

    URI.push(`${baseUrl}?${pageNumberParam}&${pageSizeParam}&${columnParam}&${directionParam}`);

    if (filters.searchTerm) {
        URI.push("searchTerm=" + filters.searchTerm);
    }

    if (filters.searchColumn) {
        URI.push("searchColumn=" + filters.searchColumn);
    }

    if (filters.currentType) {
        URI.push("searchFieldType=" + filters.currentType);
    }

    if (filters.keyword) {
        URI.push("keyword=" + filters.keyword);
    }

    return URI.join("&");
}