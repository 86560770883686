"use strict";

/**
* @ngdoc directive
* @name nms.templates.directive:searchComplexDirective
* @description
* # searchComplexDirective
*/
angular.module("nms.templates")
.directive("searchComplexDirective", function() {
    return {
        restrict: "E",
        templateUrl: "templates/features/template/components/ui/search/search-complex.html",
        controller: "ComplexSearchController"
    };
});
