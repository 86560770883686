"use strict";

/**
* @ngdoc service
* @name nms.components.autoUpdater:DevicePollingRESTService
* @description
* # DevicePollingRESTService - Serviço REST para serviços de polling.
* Factory in the nms.
*/
var app = angular.module("nms");

app.factory("DevicePollingRESTService", ["Restangular",
    function(Restangular) {
        var basePath = "/device-management-web";

        return {
            verifyPollingIsRunning: function(resourceId) {
                return Restangular.one(basePath + "/pollingUnderway/" + resourceId).customGET();
            },
            requestPolling: function(resourceId) {
                return Restangular.one(basePath + "/requestPolling?resourceId=" + resourceId).customPOST();
            },
            requestPollings: function(pollingRequests) {
                return Restangular.one(basePath + "/requestPollings").customPOST(pollingRequests);
            },
            getFirstPollingStatus: function(resourceId) {
                return Restangular.one(basePath + "/firstPollingStatus/" + resourceId).customGET();
            },
            getDevicesPollingUnderway: function() {
                return Restangular.one(basePath + "/devicesPollingUnderway").customGET();
            },
            getPollingTimeoutInMinutes: function() {
                return Restangular.one(basePath + "/pollingResponseTimeout").customGET();
            }
        };
    }
]);
