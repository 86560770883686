"use strict";

var app = angular.module("nms.dynamicDevice");

app.service("WhenVerifierService", ["DomainHandlerService", "NesDataCacheService", "x2js", "XPathResolverService",
    "YangStatements", "$rootScope", "DataPathService",
    function(DomainHandlerService, NesDataCacheService, x2js, XPathResolverService, YangStatements, $rootScope, DataPathService) {
        var xmlRootDocument = null;

        var _getXmlRootDocument = function() {
            return xmlRootDocument;
        };

        /**
         * The when Statement
         *
         * The "when" statement makes its parent data definition statement conditional.  The node defined by the parent data
         * definition statement is only valid when the condition specified by the "when" statement is satisfied.  The statement's
         * argument is an XPath expression (see Section 6.4), which is used to formally specify this condition.  If the XPath
         * expression conceptually evaluates to "true" for a particular instance, then the node defined by the parent data
         * definition statement is valid; otherwise, it is not.
         *
         * The XPath expression is conceptually evaluated in the following context, in addition to the definition in
         * Section 6.4.1:
         *
         * - If the "when" statement is a child of an "augment" statement, then the context node is the augment's target node in
         * the data tree, if the target node is a data node.  Otherwise, the context node is the closest ancestor node to the
         * target node that is also a data node.
         *
         * - If the "when" statement is a child of a "uses", "choice", or "case" statement, then the context node is the closest
         * ancestor node to the "uses", "choice", or "case" node that is also a data node.
         *
         * - If the "when" statement is a child of any other data definition statement, the context node is the data definition's
         * node in the data tree.
         *
         * ---------------------------------------------
         * The "augment-when" refers to a when statement that was added to the node because it was added by an augment
         * which had a when statement.
         *
         * Simplified Example:
         *
         *    This structure...
         *
         *         container A {...}
         *             container B {...}
         *
         *         augment /A/B
         *             when "X"
         *             container C {...}
         *
         *     is analogous to this:
         *
         *         container A {...}
         *             container B {...}
         *                 container C {
         *                     when "X"
         *                     ...
         *                 }
         */
        var resolveWhen = function(schemaNode, pathKeys, nodeInfo) {
            var whenExpression = schemaNode["sub-statements"]["when"];
            var augmentWhenExpression = schemaNode["sub-statements"]["augment-when"];
            var isValid = true;

            if (whenExpression || augmentWhenExpression) {
                var currentNodeXmlPath = _.get(schemaNode, "paths.yangXMLPath");
                const currentNodeParentXmlPath = _.get(schemaNode, "parentPaths.yangXMLPath");
                var document = getXmlRootDocumentWithCurrentNode(currentNodeXmlPath, pathKeys);

                var evaluatedWhenExpression = (whenExpression)
                    ? XPathResolverService.evaluateXPathToBoolean(document, whenExpression, currentNodeXmlPath, pathKeys)
                    : true;
                var evaluatedAugmentWhenExpression = (augmentWhenExpression)
                    ? XPathResolverService.evaluateXPathToBoolean(document, augmentWhenExpression,
                        currentNodeParentXmlPath, pathKeys)
                    : true;

                isValid = evaluatedWhenExpression && evaluatedAugmentWhenExpression;
            }

            return isValid;
        };

        /**
         * tailf:display-when condition
         *
         * The argument contains an XPath expression which specifies when the node should be displayed in the CLI and WebUI.
         * For example, when the CLI performs completion, and one of the candidates is a node with a 'display-when' expression,
         * the expression is evaluated by the CLI. If the XPath expression evaluates to true, the node is shown as a possible
         * completion candidate, otherwise not.
         *
         * For a list, the display-when expression is evaluated once for the entire list. In this case, the XPath context node
         * is the list's parent node.
         *
         * The display-when statement can be used in: leaf, leaf-list, list, container, action, and refine.
         *
         * The following substatements can be used:
         * tailf:xpath-root
         */
        var resolveDisplayWhen = function(schemaNode, pathKeys, nodeInfo) {
            var displayWhenExpression = schemaNode["sub-statements"]["display-when"];
            var isValid = true;

            if (displayWhenExpression) {
                var currentNodeXmlPath = _.get(schemaNode, "paths.yangXMLPath");

                if (nodeInfo.yangType === YangStatements.LISTS) {
                    currentNodeXmlPath = _.get(schemaNode, "parentPaths.yangXMLPath");
                }

                var document = getXmlRootDocumentWithCurrentNode(currentNodeXmlPath, pathKeys);
                isValid = XPathResolverService
                    .evaluateXPathToBoolean(document, displayWhenExpression, currentNodeXmlPath, pathKeys);
            }

            return isValid;
        };

        var createXmlRootDocument = function() {
            var currentData = NesDataCacheService.getCurrentData();

            var documentAsJson: any = {
                root: DomainHandlerService.containerForXpath(currentData.value)
            };

            var xmlStr = x2js.json2xml_str(documentAsJson);
            xmlRootDocument = new DOMParser().parseFromString(xmlStr, "text/xml");
        };

        var unregister = $rootScope.$on("current-nes-data-changed", function() {
            createXmlRootDocument();
            $rootScope.$broadcast("when-condition-data-updated");
        });

        var getXmlRootDocumentWithCurrentNode = function(currentNodeXmlPath, pathKeys) {
            if (xmlRootDocument === null) {
                createXmlRootDocument();
            }
            var xmlRootDocumentWithCurrentNode = angular.copy(xmlRootDocument);
            XPathResolverService.createMissingElementsFromXPath(xmlRootDocumentWithCurrentNode, currentNodeXmlPath, pathKeys);

            return xmlRootDocumentWithCurrentNode;
        };

        var hasWhenCondition = function(node) {
            return _.get(node, "sub-statements.when")
                || _.get(node, "sub-statements.display-when")
                || _.get(node, "sub-statements.augment-when");
        };

        var resolve = function(schemaNode, pathKeys, nodeInfo) {
            var isWhenValid = true;
            var isDisplayWhenValid = true;
            var dataChanged = false;

            if (hasWhenCondition(schemaNode)) {
                isWhenValid = resolveWhen(schemaNode, pathKeys, nodeInfo);
                isDisplayWhenValid = resolveDisplayWhen(schemaNode, pathKeys, nodeInfo);
            }
            if (!isWhenValid) {
                var jsonPath = DataPathService.getPathWithKeys(schemaNode.paths.dataJsonPath, pathKeys);
                dataChanged = DomainHandlerService.deleteDataNodeByJsonPath(jsonPath);
            }

            return {
                visible: isWhenValid && isDisplayWhenValid,
                dataChanged: dataChanged
            };
        };

        $rootScope.$on("$destroy", function() {
            unregister();
        });

        return {
            resolve: resolve,
            hasWhenCondition: hasWhenCondition,
            _getXmlRootDocument: _getXmlRootDocument
        };
    }
]);
