import { Inject, Injectable } from "@angular/core";
import { ANGULARJS_TRANSLATE, VARIABLES } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { map } from 'rxjs/operators';

type Meta = "variable" | "expression";

type Completer = {
    name: string,
    value: string,
    meta: Meta,
    tooltip?: string
}

/**
 * Serviço responsável por retornar funcionalidades utilizadas no freemarker.
 */
@Injectable({
    providedIn: "root"
})
export class CodeBlockService {

    private startCommand: string;
    private endCommand: string;

    constructor(@Inject(ANGULARJS_TRANSLATE) private readonly translate: any,
        @Inject(VARIABLES) private readonly variables) {
        this.startCommand = "";
        this.endCommand = "";
    }

    setStartCommand(startCommand): void {
        this.startCommand = startCommand
    }

    setEndCommand(endCommand): void {
        this.endCommand = endCommand
    }

    getUserVariablesCompleter(userVariables, variables) {
        return {
            getCompletions: (editor, session, pos, prefix, callback) => {
                const userVariablesCompleters: Completer[] = this.mapUserVariables(userVariables);
                this.addIncludeSelectVariablesCompleter(userVariablesCompleters, variables);

                callback(null, this.mapCallbackResponse(userVariablesCompleters, 1000));
            }
        };
    };

    getEquipmentVariablesCompleter(deviceVariables) {
        return {
            getCompletions: (editor, session, pos, prefix, callback) => {
                const completers: Completer[] = this.mapVariables(deviceVariables);

                callback(null, this.mapCallbackResponse(completers, 900));
            }
        };
    };

    getExpressionsCompleter(variables, expressionsCompleter, lang) {
        return {
            getCompletions: (editor, session, pos, prefix, callback) => {
                const completers: Completer[] = this.mapExpressions(expressionsCompleter, variables);

                callback(null, this.mapCallbackResponse(completers, 0));
            },
            getDocTooltip: (item) => {
                if (item.tooltip) {
                    return [ lang.escapeHTML(item.tooltip) ].join("");
                }
            }
        };
    };

    getVariablesExpression(variables): string {
        const selectedVariables = this.filterActionVars(variables);

        return selectedVariables
            .map(variable => this.getValueVariableFormat(variable))
            .join(" ");
    }

    getExpressionValue(expression, variables) {
        const selectedVariables = this.filterActionVars(variables);

        return expression.fn(selectedVariables);
    }

    private getValueVariableFormat(variable): string {
        if (this.startCommand != "" || this.endCommand != "") {
            return this.startCommand + variable.name + this.endCommand;
        }

        return variable.name;
    }

    /**
     * Mapeia as variáveis de usuário para uso nas sugestões via ctrl + espaço para o usuário.
     * Variáveis do tipo AÇÃO não devem ser mostradas pois não estão disponíveis para uso em comandos.
     */
    private mapUserVariables(variables): Completer[] {
        const selectedVariables = variables.filter((variable) => variable.type !== this.variables.ACTION)

        return this.mapVariables(selectedVariables);
    }

    /**
     * Mapeia as variáveis para uso nas sugestões via ctrl + espaço para o usuário.
     */
    private mapVariables(variables): Completer[] {
        return _.map(variables, (variable) => {
            return this.buildCompleter(
                variable.name,
                this.getValueVariableFormat(variable),
                "variable"
            );
        });
    }

    /**
     * Mapeia as expressões para uso nas sugestões via ctrl + espaço para o usuário.
     */
    private mapExpressions(expressionsCompleter, variables): Completer [] {
        const selectedVariables = this.filterActionVars(variables);

        return _.map(expressionsCompleter, (expression) => {
            return this.buildCompleter(
                expression.name,
                expression.fn(selectedVariables),
                "expression",
                this.buildTooltip(variables, expression.tooltip)
            );
        });
    }

    private mapCallbackResponse(completers: Completer[], score) {
        return completers.map((completer) => {
            return {
                caption: completer.name,
                value: completer.value,
                score: score,
                meta: completer.meta,
                tooltip: completer.tooltip
            };
        })
    }

    private addIncludeSelectVariablesCompleter(completers: Completer[], variables) {
        const selectedVars = variables.filter(variable => variable.type !== this.variables.ACTION);

        if (selectedVars.length > 0) {
            const includeAllValue = _.transform(selectedVars, (result, value, key) => {
                result[key] = this.getValueVariableFormat(value);
                return result;
            }).join(" ");

            const includeAllChecked = this.buildCompleter(
                this.translate.instant("templateform.commands.aceEditor.includeCheckedVariables"),
                includeAllValue,
                "variable",
                this.buildTooltip(variables)
            );

            completers.push(includeAllChecked);
        }
    }

    private buildCompleter(name: string, value: string, meta: Meta, tooltip?: string): Completer {
        return { name, value, meta, tooltip };
    }

    private buildTooltip(variables, tooltip = ""): string {
        if (variables.some(variable => variable.type === this.variables.ACTION)) {
            const includeCheckedVarsTooltip = this.translate.instant("templateform.commands.tooltip.includeCheckedVariables");

            return tooltip === ""
                ? includeCheckedVarsTooltip
                : `${tooltip} ${includeCheckedVarsTooltip}`;
        }

        return tooltip;
    }

    private filterActionVars(variables) {
        return variables.filter(variable => variable.type !== this.variables.ACTION);
    }
}