import { NmsTableRow } from "@nms-ng1/components/ui/nms-table-old/nms-table-models";
import { Trigger } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";
import { RuleCriteria, RuleType } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";
import { EquipmentSelectionType } from "../template/template-instance/template-instance-models";
import { SchedulerDeviceBackupService } from "./scheduler-device-backup.service";
import { SchedulerTemplateApplicationService } from "./scheduler-template-application.service";

/**
 * Tipo de job a ser aplicado.
 */
export enum JobType {
    TEMPLATE_APPLICATION = "TEMPLATE_APPLICATION",
    BACKUP = "DEVICE_BACKUP_CONFIGURATION"
}

/**
 * Estado de execução de um Job.
 */
export enum JobExecutionStatus {
    EXECUTING = "EXECUTING",
    REQUESTED = "REQUESTED",
    NOT_REQUESTED = "NOT_REQUESTED",
    SUCCESS = "SUCCESS",
    PARTIAL = "PARTIAL",
    FAIL = "FAIL"
}

/**
 * Define o serviço que deve ser utilizado de acordo com o tipo do agendamento.
 */
export interface ServiceByJobTypeMap {
    [JobType.BACKUP]: SchedulerDeviceBackupService,
    [JobType.TEMPLATE_APPLICATION]: SchedulerTemplateApplicationService
}

/**
 * Representação do Agendamento.
 */
export interface SchedulerJob {
    id?: string;
    name: string;
    type: JobType;
    jobData: TemplateApplicationJobData | BackupJobData;
    triggers: Array<Trigger>;
    enabled: boolean;
    status: JobExecutionStatus;
    jobResult?: BackupJobResult;
}

/**
 * JobData utilizado em aplicações de template.
 */
export interface TemplateApplicationJobData {
    templateApplicationId: string;
    templateApplicationName: string;
    templateType: "CLI" | "TR_069";
}

/**
 * JobData utilizado em backups.
 */
export interface BackupJobData {
    description: string;
    exportBackups: boolean;
    devicesFilter: DevicesFilter;
}

/**
 * BackupJobResult utilizado em backups.
 */
export interface BackupJobResult {
    backupResultIds: number[];
}

/**
 * Interface utilizada na seleção de equipamentos por filtro de parâmetros.
 */
export class DevicesFilter {
    rulesCriteria: RuleCriteria;
    equipmentSelectionType: EquipmentSelectionType;
    deviceFilterRules: DeviceFilterRule[];
}

/**
 * Interface utilizada ao definir as regras utilizadas na seleção de equipamentos
 * por filtro de parâmetros.
 */
export interface DeviceFilterRule {
    deviceField: string;
    ruleType: RuleType;
    values: string[];
}

/**
 * Habilita check de agendamento e check da data final.
 */
export interface EnableSchedulerJob {
    enableSchedulerJob: boolean;
}

/**
 * Representação dos detalhes do Agendamento
 * recebidos pela API.
 */
export interface SchedulerJobDetails {
    schedulerJob: SchedulerJob;
    lastExecution: Date;
    nextExecution: Date;
    author: string;
}

/**
* Representação dos detalhes do Agendamento
* exibidos na tabela.
*/
export interface SchedulerJobDetailsView extends NmsTableRow {
    jobId: string;
    jobName: string;
    jobType: string;
    triggers: string;
    modifiedBy: string;
    lastExecution: string;
    lastExecutionTimeStamp: number;
    nextExecution: string;
    nextExecutionTimeStamp: number;
    enabled: boolean;
    status: JobExecutionStatus;
    translatedStatus: string;
}
