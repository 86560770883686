"use strict";

/**
 * Filter that wraps a given input inside an Array.
 *
 * @author patrick.bard
 */

// TODO: Verify if still necessary

var app = angular.module("nms");

app.filter("wrapInArray", ["$filter",
    function($filter) {
        return function(input) {
            var array = [];

            if (input === null || angular.isUndefined(input)) {
                return array;
            }

            if ($filter("isArray")(input)) {
                array = input;
            } else {
                array.push(input);
            }

            return array;
        };
    }
]);
