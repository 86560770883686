"use strict";

var app = angular.module("nms");

/**
 * Diretiva responsável por encapsular a diretiva tagsInput (biblioteca ng-tags-input)
 *
 * Necessária porque não é possível carregar arquivos de template
 * dinamicamente (e.g templateUrl) em um componente que é feito upgrade.
 */
app.directive("tagsInputWrapper", [
    function() {
        return {
            restrict: "E",
            scope: {
                // Propriedades originais da biblioteca
                model:                   "=",
                template:                "=",
                templateScope:           "=",
                displayProperty:         "=",
                keyProperty:             "=",
                type:                    "=",
                text:                    "=",
                tabindex:                "=",
                placeholder:             "=",
                minLength:               "=",
                maxLength:               "=",
                minTags:                 "=",
                maxTags:                 "=",
                allowLeftoverText:       "=",
                removeTagSymbol:         "=",
                addOnEnter:              "=",
                addOnSpace:              "=",
                addOnComma:              "=",
                addOnBlur:               "=",
                addOnPaste:              "=",
                pasteSplitPattern:       "=",
                replaceSpacesWithDashes: "=",
                allowedTagsPattern:      "=",
                enableEditingLastTag:    "=",
                addFromAutocompleteOnly: "=",
                spellcheck:              "=",
                tagClass:                "=",
                onTagAdding:             "=",
                onTagAdded:              "=",
                onInvalidTag:            "=",
                onTagRemoving:           "=",
                onTagRemoved:            "=",
                onTagClicked:            "=",

                // Propriedades extras adicionadas
                id:                      "=",
                isDisabled:              "=",
                specialCharactersMask:   "=",
                modelObject:             "=",
                modelProperty:           "=",
            },
            template: `
                <div ng-if="model">
                    <tags-input id="id"
                                data-ng-model="model"
                                min-length="{{minLength}}" max-length="{{maxLength}}" max-tags="{{maxTags}}"
                                placeholder="{{placeholder}}"
                                tag-class="tagClass($tag)"
                                on-tag-adding="onTagAdding($tag)" on-tag-removing="onTagRemoving($tag)"

                                special-characters-mask=""
                                ng-disabled="isDisabled">
                    </tags-input>
                </div>

                <div ng-if="modelObject && modelProperty">
                    <tags-input id="id"
                                data-ng-model="modelObject[modelProperty]"
                                min-length="{{minLength}}" max-length="{{maxLength}}" max-tags="{{maxTags}}"
                                placeholder="{{placeholder}}"
                                tag-class="tagClass($tag)"
                                on-tag-adding="onTagAdding($tag)" on-tag-removing="onTagRemoving($tag)"

                                special-characters-mask=""
                                ng-disabled="isDisabled">
                    </tags-input>
                </div>
            `,
            link: function(scope, element, attributes) {
                scope.getData = function() {
                    if (scope.model) {
                        return scope.model;
                    } else if (scope.modelObject && scope.modelProperty) {
                        return _.get(scope.modelObject, scope.modelProperty)
                    }

                    console.warn("Unable to load tags!")
                    return null;
                }
            }
        };
    }
]);