"use strict";

import { CpeFieldValues, DeviceFieldValues } from "@nms-ng2/app/modules/template/template-instance/template-instance-models";
import { RuleType } from "@nms-ng2/app/shared/components/elements/matching-rules/matching-rules.models";

/**
 * Filter firmware range rule.
 *
 * @author patrick.bard
 */

// TODO: Verify if it needs to be a filter.

var app = angular.module("nms");

app.filter("firmwareRangeRule", ["$translate",
    function ($translate) {
        return function (equipmentRuleOptions = []) {

            let firmwareRangeRule = "";
            const firmareRuleOption = equipmentRuleOptions.find(equipmentRuleOption => {
                const hasFirmware =
                    equipmentRuleOption.equipmentField.value === DeviceFieldValues.FIRMWARE ||
                    equipmentRuleOption.equipmentField.value === CpeFieldValues.SW_VERSION;

                return hasFirmware;
            });

            if (firmareRuleOption) {
                const ruleType = RuleType[firmareRuleOption.ruleType];
                const [firmwareA, firmwareB] = firmareRuleOption.values;

                if (ruleType) {
                    firmwareRangeRule = `${$translate.instant(`restrictions.rule.type.${ruleType}`)} ${firmwareA}`;

                    if (firmwareB) {
                        firmwareRangeRule += " - " + firmwareB;
                    }
                }
            }

            return firmwareRangeRule;
        };
    }]
);
