/**
 * Sub-statements extraction filters.
 *
 * @author icaro.damiani
 */
// TODO: [US54236][SETH] Verificar se algum desses filtros ficaria mais coerente no arquivo node-filters
// TODO:                 Verificar a implementação de demais filtros que retornam boolean ao invés do elemento
// TODO:                 Verificar o provável uso incoerente de filtros como "GET"
var app = angular.module("nms.dynamicDevice");

app.filter("isMandatory",
    function() {
        return function(node) {
            if (node) {
                var subStatements = node["sub-statements"];
                return subStatements.mandatory === "true";
            }
            return false;
        };
    }
);

app.filter("fixInfoValue", [
    function() {
        return function(info) {
            if (!_.isEmpty(info)) {
                return _.escape(info.replace(new RegExp(";;", "g"), " "));
            }

            return info;
        };
    }
]);

app.filter("getInformation", ["$filter",
    function($filter) {
        return function(node, joinBy) {
            var join = joinBy || "\n";
            var nodeInformation = $filter("fixInfoValue")(node["information"]);
            var nodeInfo = $filter("fixInfoValue")(_.get(node, "sub-statements.info"));
            var typeInformation = $filter("fixInfoValue")(_.get(node, "type.information"));

            var information = nodeInformation || nodeInfo;

            return _.compact([information, typeInformation]).join(join);
        };
    }
]);

app.filter("getSchemaNode", ["DomainHandlerService",
    function(DomainHandlerService) {
        return function(node) {
            if (node) {
                if (_.has(node, "sub-statements")) {
                    return node;
                }

                return DomainHandlerService.getSchemaNode(node.parentPaths.schemaJsonPath);
            }

            return;
        };
    }
]);

app.filter("hasInformation", [
    function() {
        return function(node) {
            if (node) {
                var nodeInformation = _.get(node, "information");
                var nodeInfo = _.get(node, "sub-statements.info");
                var typeInformation = _.get(node, "type.information");

                if (nodeInformation || nodeInfo || typeInformation) {
                    return true;
                }
            }
            return false;
        };
    }
]);

app.filter("getMinElements",
    function() {
        return function(node) {
            if (node) {
                var subStatements = node["sub-statements"];
                return subStatements["min-elements"];
            }
            return undefined;
        };
    }
);

app.filter("getMaxElements",
    function() {
        return function(node) {
            if (node) {
                var subStatements = node["sub-statements"];
                return subStatements["max-elements"];
            }
            return undefined;
        };
    }
);

app.filter("isConfiguration", function() {
    return function(node) {
        if (node && node["sub-statements"] && node["sub-statements"].configuration === "true") {
            return node;
        }
        return false;
    };
});

app.filter("isInformation", function() {
    return function(node) {
        if (node && node["sub-statements"] && node["sub-statements"].configuration === "false") {
            return node;
        }
        return false;
    };
});

app.filter("isHidden", ["$filter", "UserPreferencesService",
    function($filter, UserPreferencesService) {
        return function(node) {
            var hidden = _.get(node, "sub-statements.hidden");
            var forceShowHidden = UserPreferencesService.getValue("info/config", "showHidden");

            if (hidden && forceShowHidden !== "true") {
                var isConfiguration = $filter("isConfiguration")(node);

                if (isConfiguration) {
                    return node;
                } else if (hidden !== "dmview") {
                    return node;
                }
            }

            return false;
        };
    }
]);

app.filter("hasConfiguration", function() {
    return function(node) {
        if (node) {
            var subStatements = node["sub-statements"];

            if (subStatements) {
                if (subStatements.configuration) {
                    return true;
                }
            }
        }
        return false;
    };
});

app.filter("getPath", ["DomainHandlerService",
    function(DomainHandlerService) {
        return function(node) {
            return DomainHandlerService.getPath(node);
        };
    }
]);

app.filter("configurationFilter",
    function() {
        return function(node, isConfiguration) {
            return node && node["sub-statements"] && node["sub-statements"].configuration === String(isConfiguration);
        };
    }
);
