"use strict";

var app = angular.module("nms");
app.service("SystemConfigurationRestService", ["Restangular",
    function(Restangular) {
        var baseURL = "/system-configuration";
        const service: any = {};

        service.getSecuritySystemConfig = function() {
            return Restangular.one(baseURL + "/security").customGET();
        };

        service.getLdapMode = function() {
            return Restangular.one(baseURL + "/ldapMode").customGET();
        };

        return service;
    }
]);
