var app = angular.module("nms.cwmpParameters", []);

app.config(["$translatePartialLoaderProvider", function($translatePartialLoader) {
    $translatePartialLoader.addPart("features/cwmp-parameters");
}]);

app.run(["$translate",
    function($translate) {
        $translate.refresh();
    }
]);
