import { DeviceIdentifier, EquipmentIdentifier, CpeIdentifier, DeviceDetails, CpeDetails, EquipmentDetails } from './template-instance-models';
import { Injectable } from '@angular/core';

/**
 * Serviço responsável por identificar os detalhes das interfaces
 * referentes à Equipamento baseado no tipo.
 */
@Injectable({
    providedIn: "root"
})
export class TemplateInstanceIdentifierService {

    resolveIdentifier(identifier: EquipmentIdentifier): any {
        switch (identifier.type) {
            case "DeviceIdentifier":
                return (identifier as DeviceIdentifier).resourceId;
            case "CpeIdentifier":
                return (identifier as CpeIdentifier).serialNumber;
        }
    }

    resolveName(equipmentDetails: EquipmentDetails) {
        switch (equipmentDetails.type) {
            case "DeviceDetails":
                return (equipmentDetails as DeviceDetails).name;
            case "CpeDetails":
                return (equipmentDetails as CpeDetails).serialNumber;
        }
    }

    isDeviceIdentifier(equipmentIdentifierType: string): boolean {
        return equipmentIdentifierType === "DeviceIdentifier";
    }
}