var app = angular.module("nms.dynamicDevice");

/**
 * Verifica se um valor (incluindo números negativos) está contido dentro do intervalo definido pelo yang.
 * Utilizada em campos do tipo number, ou seja, onde o usuário só pode definir um único número para a configuração.
 */
app.service("YangRangeRestrictionService", ["LeafService",
    function(LeafService) {
        this.RESTRICTION_NAME = "yang-range";

        this.validate = function(modelValue, viewValue, type) {
            var value = modelValue || viewValue;

            if (!value || value === "") {
                return true;
            }

            var ranges = LeafService.getRanges(type);

            if (!ranges) {
                return true;
            }

            var isBetweenRange = function(range) {
                var start = parseFloat(range.split("..")[0]);
                var end = parseFloat(range.split("..")[1]);

                return (value >= start && value <= end);
            };

            return _.some(ranges, isBetweenRange);
        };
    }
]);
