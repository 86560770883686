"use strict";

/**
 * @ngdoc controller
 * @name nms.manageDevices.controller:PropertiesEditModalController
 * @description
 * Controller para a modal de propriedades 'properties-edit-modal.html'
 *
 * @property {object} model Objeto com as propriedades tratadas pela modal. Ex.:
 *      {
 *          name: "some name",
 *          notes: "some notes",
 *          address: "some address",
 *          city: "some city",
 *          state: "some state",
 *          country: "some coutry",
 *          station: "some station",
 *          room: "some room",
 *          shelf: "some shelf",
 *          project: "some project"
 *      }
 *
 * @property {object} guiSettings Objeto com as parametrizações visuais da modal. Ex.:
 *      {
 *          showNameField: false,       //Indica se o campo 'name' será exibido ou não.
 *          isChangeRequired: true      //Indica se será obrigatório alguma alteração no formulário no botão de confirmação.
 *          tipMessage: "some tip",     //Dica que será apresentada no formulário. Caso indefinida, nada é apresentado.
 *          subtitle: "some subtitle"   //Subtítulo, que será concatenado no cabeçalho da modal.
 *      }
 *
 * @property {function} validatorFunction função para validação dos dados da modal. Se omitida, a modal não valida a confirmação
 * do formulário, caso contrário a modal só é fechada se os dados informados passarem na validação, através de um retorno positivo
 * desta função.
 */
var app = angular.module("nms");

app.controller("PropertiesEditModalController", ["$scope", "model", "guiSettings", "validatorFunction",
    function($scope, model, guiSettings, validatorFunction) {
        $scope.guiSettings = guiSettings;
        $scope.model = angular.copy(model);

        $scope.saveProperties = function() {
            if (!validatorFunction || validatorFunction($scope.model, model)) {
                $scope.confirm($scope.model);
            }
        };
    }
]);
