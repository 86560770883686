"use strict";

/**
* @ngdoc directive
* @name nms.directive:delayMask
* @description Diretiva de delay ao aplicar mask nos caracteres do input.
*/
angular.module("nms")
.directive("delayMask", ["$timeout", function($timeout) {
    return {
        restrict: "A",
        require: "ngModel",
        link: function(scope, element, attrs, ngModel) {
            var plainTextPasswordHiddenElement = element.next();
            var text;
            var enterKeyCode = 13;
            var backspaceKeyCode = 8;
            var deleteKeyCode = 46;
            var maskTimer;
            var cursor;
            var keysToExclude = [
                "Enter",
                "Tab",
                "Del",
                "ArrowUp",
                "Up",
                "ArrowDown",
                "Down",
                "ArrowLeft",
                "Left",
                "ArrowRight",
                "Right",
                "Home",
                "End",
                "Insert",
                "PageUp",
                "PageDown",
                "Control",
                "Alt",
                "AltGraph",
                "CapsLock",
                "Shift",
                "NumLock",
                "Backspace",
                "Delete"
            ];

            var transformLetters = function(txt) {
                var stars = "";
                for (var i = 0; i < txt.length; i++) {
                    stars += "*";
                }
                return stars;
            };

            var updateMaskedFieldInfo = function(e, text, cursor) {
                e.currentTarget.value = transformLetters(text);
                e.currentTarget.selectionStart = cursor;
                e.currentTarget.selectionEnd = cursor;
            };

            var putValueAtInputScope = function(txt) {
                if (!_.isEmpty(element.val()) || txt) {
                    plainTextPasswordHiddenElement.val(txt);
                } else {
                    plainTextPasswordHiddenElement.val("");
                }

                plainTextPasswordHiddenElement.trigger("change");
            };

            var getSelection = function(eventWrapper) {
                var selection: any = {};

                if (eventWrapper.paste) {
                    selection = eventWrapper.selection;
                } else {
                    selection.start = _.get(eventWrapper, "event.currentTarget.selectionStart");
                    selection.end = _.get(eventWrapper, "event.currentTarget.selectionEnd");
                }

                return selection;
            };

            var getReceivedText = function(eventWrapper) {
                var receivedText;

                if (eventWrapper.paste) {
                    receivedText = _.get(eventWrapper, "event.currentTarget.value").replace(/\*/g, "");
                } else {
                    receivedText = _.get(eventWrapper, "event.key");
                }

                return receivedText;
            };

            var copyPasswordToScope = function(eventWrapper) {
                var selection = getSelection(eventWrapper);
                var receivedText = getReceivedText(eventWrapper);
                var currentText = plainTextPasswordHiddenElement.val().trim();
                var text = currentText.substr(0, selection.start) + receivedText + currentText.substr(selection.end);

                putValueAtInputScope(text);
            };

            var setFieldValidator = function() {
                ngModel.$validators.required(function(modelValue, viewValue) {
                    return !_.isEmpty(modelValue);
                });
                ngModel.$commitViewValue();
            };

            var maskWholePassword = function() {
                var value = plainTextPasswordHiddenElement.val();
                var maskedValue = value.replace(/./g, "*");

                element.val(maskedValue);
                ngModel.$viewValue = maskedValue;

                setFieldValidator();

                return value;
            };

            element.bind("paste", function(e) {
                var selectionStart = e.currentTarget.selectionStart;
                var selectionEnd = e.currentTarget.selectionEnd;
                $timeout(function() {
                    var eventWrapper: any = {
                        event: e,
                        selection: {
                            start: selectionStart,
                            end: selectionEnd
                        },
                        paste: true
                    };
                    copyPasswordToScope(eventWrapper);
                    updateMaskedFieldInfo(e, plainTextPasswordHiddenElement.val(), e.currentTarget.selectionEnd);
                });
            });

            // Copy and paste operations are not allowed in password fields.
            element.bind("copy cut", function(e) {
                e.preventDefault();
            });

            plainTextPasswordHiddenElement.bind("copy cut", function(e) {
                e.preventDefault();
            });

            element.on("keydown", function(e) {
                var keyCode = e.keyCode ? e.keyCode : e.charCode;
                var keyName = e.key;

                cursor = e.currentTarget.selectionStart;

                if (keyCode !== 0 && keyCode !== enterKeyCode
                        && !e.ctrlKey && !e.metaKey && !e.altKey && !_.contains(keysToExclude, keyName)) {
                    copyPasswordToScope({event: e});
                    text = plainTextPasswordHiddenElement.val();
                    if (maskTimer) {
                        clearTimeout(maskTimer);
                        updateMaskedFieldInfo(e, text.substr(0, text.length-1), cursor);
                    }
                    maskTimer = $timeout(function() {
                        updateMaskedFieldInfo(e, text, cursor + 1);
                    }, 500);
                } else if (keyCode === backspaceKeyCode || keyCode === deleteKeyCode) {
                    var selectionStart = e.currentTarget.selectionStart;
                    var selectionEnd = e.currentTarget.selectionEnd;
                    text = plainTextPasswordHiddenElement.val();
                    if (selectionStart === selectionEnd) {
                        if (keyCode === deleteKeyCode) {
                            text = text.substr(0, selectionStart) + text.substr(selectionEnd + 1);
                        } else {
                            text = text.substr(0, selectionStart - 1) + text.substr(selectionEnd);
                        }
                    } else {
                        text = text.substr(0, selectionStart) + text.substr(selectionEnd);
                    }
                    setFieldValidator();
                    putValueAtInputScope(text);

                    return text;
                }
            });

            scope.$watch(attrs.delayMask, $timeout(maskWholePassword, 10));
            scope.$on("maskPassword", function() {
                $timeout(maskWholePassword, 10);
            });

            ngModel.$formatters.push(setFieldValidator);
        }
    };
}]);
