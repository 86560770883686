"use strict";

/**
* @ngdoc directive
* @name nms.directive:userAccount
* @description Diretiva para criar a tab de conta de usuário.
*
* Atributos:
* # id                    - Obrigatório      - Identificador da tab. Utilizado para a criação de ids no html de forma a evitar
*                                            duplicidade quando diretiva é usada mais de uma vez na página.
*
* # usernamesInUse        - Obrigatório      - Array de nomes de usuários já cadastrados.
*
* # securitySystemConfig  - Optional         - Objeto com definições de segurança do sistema utilizado em validações de
*                                            formulário
* # ldapMode              - Obrigatório      - String com o modo do LDAP configurado no sistema. Usado em coerências de tela.
*
* # owner                 - Obrigatório      - Objeto utilizado para comunicação entre a diretiva e seu pai, contendo o modelo
*                                            de dados.
*                         - Estrutura        - {model: {}}
*
*/
var app = angular.module("nms");

app.directive("userAccount", function() {
    return {
        restrict: "E",
        templateUrl: "templates/features/management/users/account/account.html",
        controller: "ManageUserAccountController",
        scope: {
            id: "@",
            usernamesInUse: "=",
            securitySystemConfig: "=",
            ldapMode: "=",
            owner: "=",
            isSelfEditing: "="
        }
    };
});
