"use strict";

/**
* @ngdoc service
* @name nms.CredentialsRestService
* @description
* # CredentialsRestService - Serviço REST para credenciais
* Factory in the nms.
*/
var app = angular.module("nms");

app.factory("CredentialsRestService", ["Restangular",
    function(Restangular) {
        var basePath = "/device-management-web";
        const service: any = {};

        service.updateCredentials = function(credentials, isGlobal) {
            return Restangular.all(basePath + "/devices").customPOST(credentials, "credentials", {isGlobal: isGlobal});
        };

        service.getSpecificCredentials = function(hostname) {
            return Restangular.one(basePath + "/devices/credentials?hostname=" + hostname).customGET();
        };

        service.getGlobalCredentials = function() {
            return Restangular.one(basePath + "/global-credentials").customGET();
        };

        service.getDiscoveryGlobalCredentials = function(callbackOnResponse) {
            return Restangular.one(basePath + "/discovery-global-credentials").customGET();
        };

        return service;
    }
]);
