"use strict";

/**
* @ngdoc service
* @name nms.OnlyNumbersService
* @description
* # OnlyNumbersService - Serviço para permitir apenas números em inputs.
*/
var app = angular.module("nms");

app.factory("OnlyNumbersService", [
    function() {
        const service: any = {};
        var exclude = /Backspace|Enter|Tab|Delete|Del|ArrowUp|Up|ArrowDown|Down|ArrowLeft|Left|ArrowRight|Right/;

        var getPattern = function(customPattern) {
            return RegExp(customPattern || /[0-9]+$/g, "g");
        };

        service.validate = function(elem, event, attrs) {
            var key = event.key;

            if (attrs["type"] !== "number" && key === "-") {
                if (_.isEmpty(elem.val())) {
                    return true;
                } else if (event.target.selectionStart === 0 && elem.val()[0] !== "-") {
                    return true;
                } else if (event.target.selectionStart === 0 && event.target.selectionEnd === elem.val().length) {
                    return true;
                }
            }

            if (!(key.match(exclude) || key.match(getPattern(attrs.customPattern)))) {
                return false;
            }

            return true;
        };

        return service;
    }
]);
