var app = angular.module("nms.dynamicDevice");

/**
 * Verifies if a value matches all patterns of a given type.
 */
app.service("PatternRestrictionService", ["LeafService",
    function(LeafService) {
        this.RESTRICTION_NAME = "pattern";

        this.validate = function(modelValue, viewValue, type) {
            var value = modelValue || viewValue;

            if (value == null || value === "") {
                return true;
            }

            var patterns = LeafService.getPatterns(type);

            if (!patterns) {
                return true;
            }

            var isValid = function(pattern) {
                /* global XRegExp */
                return new XRegExp(pattern).test(value);
            };

            return _.every(patterns, isValid);
        };
    }
]);
