"use strict";

/**
* @ngdoc service
* @name nms.components.autoUpdater:DevicePollingService
* @description
* # DevicePollingService
*
* Serviço que realizada a chamada REST para o serviços de polling e controla o
* websocket responsável por receber os status de polling.
*/
var app = angular.module("nms");

app.factory("DevicePollingService", ["$rootScope", "DevicePollingRESTService", "NMS_STATES", "POLLING_STATUS", "$window",
    "$websocket", "$state", "AutoUpdaterService",
    function($rootScope, DevicePollingRESTService, NMS_STATES, POLLING_STATUS, $window, $websocket, $state, AutoUpdaterService) {
        var service: any = {
            websocket: null
        };

        /* O parâmetro actions é um mapa, onde a chave é o status do polling esperado
         * e o objeto é a função que deverá ser executado quando o status for recebido.
         * Os status que não estiverem presente no actions serão ignorados.
         */
        service.connectAndRequestPolling = function(actions, resourceId) {
            service.websocket = $websocket("wss://" + $window.location.host + "/service/device/notification/" + resourceId);
            service.messagesReceivedByWebsocket = 0;

            service.websocket.onMessage(function(event) {
                service.messagesReceivedByWebsocket++;

                var feedback = angular.fromJson(event.data);

                if (feedback.percentage in actions) {
                    actions[feedback.percentage](feedback);
                }
            });

            service.websocket.onOpen(function() {
                service.requestPolling(resourceId);
            });
        };

        service.disconnect = function() {
            if (service.websocket != null) {
                service.websocket.close();
            }
        };

        service.checkPollingStatus = function(resourceId) {
            DevicePollingRESTService.verifyPollingIsRunning(resourceId).then(function(isPollingRunning) {
                if (!isPollingRunning) {
                    AutoUpdaterService.finishedUpdating();
                }
            });
        };

        service.getPollingTimeoutInMinutes = function() {
            return DevicePollingRESTService.getPollingTimeoutInMinutes();
        };

        service.requestPolling = function(resourceId) {
            DevicePollingRESTService.verifyPollingIsRunning(resourceId).then(function(isPollingRunning) {
                if (isPollingRunning) {
                    AutoUpdaterService.startUpdating();
                } else {
                    // TODO: Abstrair lógica de start/finishedUpdating para que fique totalmente contida no serviço de
                    // auto-updater
                    $rootScope.hideLoadingPanel = true;
                    DevicePollingRESTService.requestPolling(resourceId);
                    $rootScope.$broadcast("pollingRequested");
                    $rootScope.hideLoadingPanel = false;
                }
            });
        };

        service.checkFirstPollingStatus = function(resourceId, successCallback) {
            DevicePollingRESTService.getFirstPollingStatus(resourceId)
                .then(function(status) {
                    switch (status) {
                        case POLLING_STATUS.SUCCESS:
                            successCallback();
                            break;
                        case POLLING_STATUS.FAIL:
                            $rootScope.showDialog({
                                translateKey: "device.error.firstPollingFailed"
                            });
                            $state.go(NMS_STATES.manageDevices);
                            break;
                        case POLLING_STATUS.RUNNING:
                            $rootScope.showDialog({
                                translateKey: "device.error.firstPollingInProgress"
                            });
                            $state.go(NMS_STATES.manageDevices);
                            break;
                    }
                });
        };

        return service;
    }
]);
