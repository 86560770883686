/**
* @ngdoc directive
* @name nms.templates.directive:variablesForm
* @description Diretiva para criação do componente formulário de variáveis.
*/
"use strict";

angular.module("nms.templates")
.directive("variablesForm", [function() {
    return {
        templateUrl: "templates/features/template/components/ui/accordion/variables-form.html",
        controller: "VariablesFormDirectiveCtrl",
        scope: {
            formOwner: "=formOwner",
            isTestContext: "=isTestContext"
        },
        link: function (scope, element, attrs) {
            scope.showCliColumn = attrs.showCliColumn === "true";
            scope.showMandatoryIcon = attrs.showMandatoryIcon === "true";
            scope.validateMandatory = attrs.validateMandatory === "true";
        }
    };
}]);
