"use strict";

var app = angular.module("nms.deviceDiscover");

app.factory("DeviceDiscoverRESTService", ["Restangular", function(Restangular) {
    var basePath = "/device-management-web";

    return {
        find: function(hostsToFind, clientId) {
            return Restangular.one(basePath + "/find/" + clientId).customPOST(hostsToFind);
        },
        refresh: function(devices, clientId) {
            return Restangular.one(basePath + "/refresh/" + clientId).customPOST(devices);
        },
        addDevices: function(deviceInsertionInstances) {
            return Restangular.one(basePath + "/devices?username=" + localStorage.getItem("username")).customPOST(deviceInsertionInstances);
        },
        findAndAddDevices: function(hosts, clientId) {
            return Restangular.one(basePath + "/find-and-add/" + clientId + "?username=" + localStorage.getItem("username")).customPOST(hosts);
        },
        getAvailableNetworkElements: function() {
            return Restangular.one(basePath + "/available-network-elements").customGET();
        },
        getGlobalCredentials: function() {
            return Restangular.one(basePath + "/global-credentials").customGET();
        },
        getDiscoveryGlobalCredentials: function() {
            return Restangular.one(basePath + "/discovery-global-credentials").customGET();
        },
        verifyLocationManagerPermission: function(locationId) {
            var params = "?username=" + window.localStorage.getItem("username") + "&locationId=" + locationId;
            return Restangular.one(basePath + "/permission/location" + params).customGET();
        },
        getDevicesWithoutManagerPermission: function(devices) {
            var params = "?username=" + window.localStorage.getItem("username");
            return Restangular.one(basePath + "/devices-without-permissions" + params).customPOST(devices);
        },
        testHostsConnectivity: function(clientId, hosts) {
            return Restangular.all(basePath + "/test-connectivity/test/" + clientId).customPOST(hosts);
        },
        getDevicesSupportedProtocols: function(models) {
            return Restangular.one(basePath + "/test-connectivity/protocols").customPOST(models);
        }
    };
}]);
