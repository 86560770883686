"use strict";

/**
* @ngdoc directive
* @name nms.directive:slideToggle
* @description Diretiva para criar animação de slide.
*/
var app = angular.module("nms");
app.directive("slideToggle", function() {
    return {
        restrict: "A",
        scope: {
            slideToggle: "="
        },
        link: function(scope, elem, attrs) {
            elem.on("click", function() {
                if (scope.slideToggle) {
                    angular.element(".toggle-effect-" + attrs.toToggle).slideDown(500);
                    angular.element(".toggle-effect .slide-effect-" + attrs.toToggle).slideDown(200);
                } else {
                    angular.element(".toggle-effect-" + attrs.toToggle).slideUp(500);
                    angular.element(".toggle-effect .slide-effect-" + attrs.toToggle).slideUp(200);
                }
            });
        }
    };
});
