"use strict";

/**
* @ngdoc service
* @name nms.deviceDiscover.NetworkService
* @description
* # Serviço para extração de hosts válidos usados na busca de devices.
* Factory in the nms.deviceDiscover.
*/
var app = angular.module("nms.deviceDiscover");

app.factory("NetworkService", [function() {
    var MAX_MASK_BITS_SIZE = 32;
    var IPv4_BYTES_COUNT = 4;
    var BITS_IN_BYTE = 8;

    const service: any = {};

    service.getAvailableSubsequentHosts = function(ipAddress, lastHost) {
        var finalIp = ipAddress.replace(/(\d*)$/, lastHost);
        var host = toLong(ipAddress);
        var lastIp = toLong(finalIp);
        var hosts = [];

        while (host <= lastIp) {
            hosts.push(toString(host++));
        }

        return hosts;
    };

    service.getAvailableHostsByNetwork = function(ipAddressAndMask) {
        var networkData = ipAddressAndMask.split("/");
        var baseIp = toLong(networkData[0]);
        var mask = getMask(networkData[1]);
        var networkAddress = getNetworkAddress(baseIp, mask);
        var broadcastAddress = getBroadcastAddress(baseIp, mask);

        var hosts = [];
        var nextAvailableHost = bitwiseAdd(networkAddress);

        while (nextAvailableHost !== broadcastAddress) {
            var host = toString(nextAvailableHost++);
            hosts.push(host);
        }

        return hosts;
    };

    function toString(ipAddress) {
        var address = "";

        for (var index = 3; index >= 0; index--) {
            var partial = (ipAddress >> (index * 8)) & 0xFF;
            address += index === 0 ? partial : partial + ".";
        }

        return address;
    }

    function toLong(ipAddress) {
        var address = ipAddress.split(".");
        var ip = 0;

        for (var bits = IPv4_BYTES_COUNT - 1; bits >= 0; bits--) {
            ip = ip | (address[IPv4_BYTES_COUNT - 1 - bits] << (bits * BITS_IN_BYTE));
        }

        return ip;
    }

    function getBroadcastAddress(ip, mask) {
        return ip | ~mask;
    }

    function getNetworkAddress(ip, mask) {
        return ip & mask;
    }

    function getMask(maskBits) {
        var numberOfLowOrderBitsToBeInZero = (MAX_MASK_BITS_SIZE - maskBits);
        var shiftNumberOneTotalOfLowOrderBitsToTheLeft = (1 << numberOfLowOrderBitsToBeInZero);
        var transformLowOrderBitsToOneInsteadOfZero = shiftNumberOneTotalOfLowOrderBitsToTheLeft - 1;

        return ~transformLowOrderBitsToOneInsteadOfZero;
    }

    function bitwiseAdd(ip) {
        var and = ip & 1;
        var xor = ip ^ 1;

        if (and !== 0) {
            and <<= 1;
            var temp = xor ^ and;
            and &= xor;
            xor = temp;
        }

        return xor;
    }

    return service;
}]);
