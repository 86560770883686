import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class CwmpConnectivityTestModalService {
    constructor(private http: HttpClient) {
    }

    performTests(websocketClientId: string, serialNumbers: Array<string>) {
        let url = `/device-management-web/cwmp/test-connectivity/${websocketClientId}`;
        this.http.post(url, serialNumbers).subscribe(() => {
            console.log(`Processing connectivity tests for serial numbers: ${serialNumbers}`)
        });
    }
}