import { Injectable } from "@angular/core";
import { interval, Observable, Subscription } from "rxjs";
import { SecurityService } from "./security.service";

/**
 * Serviço para a funcionalidade de keep alive.
 * Esse serviço vai enviar de tempos em tempos uma requisição de keep alive para o serviço
 * para que a sessão do usuário não seja invalidada por inatividade.
 */
@Injectable({
    providedIn: "root"
})
export class KeepAliveService {

    static readonly INTERVAL_FIVE_MINUTES = 300000;

    private intervalTimer: Observable<number>;
    private intervalSubscription: Subscription;

    constructor(private securityService: SecurityService) {
        this.intervalTimer = interval(KeepAliveService.INTERVAL_FIVE_MINUTES);
    }

    private keepAliveSubscribe(): Subscription {
        return this.securityService.postKeepAlive().subscribe();
    }

    /**
     * Inicia as requisições periódicas
     */
    public start() {
        this.keepAliveSubscribe();
        this.intervalSubscription = this.intervalTimer.subscribe(() => {
            this.keepAliveSubscribe();
        });
    }

    /**
     * Parar as requisições periódicas
     */
    public stop() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

}
