"use strict";

var app = angular.module("nms");

/**
 * Diretiva responsável por encapsular a diretiva templateCommandsStep
 *
 * Necessária porque não é possível carregar arquivos de template
 * dinamicamente (e.g templateUrl) em um componente que é feito upgrade.
 */
app.directive("templateCommandsStepWrapper", [
    function() {
        return {
            restrict: "E",
            scope: {
                templateController: "=",
                stepController: "="
            },
            template: "<template-commands-step template-controller='templateController' step-controller='stepController'></template-commands-step>"
        };
    }
]);