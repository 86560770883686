/**
 * Controller para diretiva leaflistTableCell.
 *
 * @author joao.otavio
 */
var app = angular.module("nms.dynamicDevice");

app.controller("LeaflistTableCellController", ["$scope", "ngDialog", "$translate", "DomainHandlerService", "DataPathService",
    "TableCellService", "NodeStatusService", "WhenVerifierService",
    function($scope, ngDialog, $translate, DomainHandlerService, DataPathService, TableCellService, NodeStatusService,
        WhenVerifierService) {
        $scope.leaflistLabel = "";
        var hasWhenCondition = WhenVerifierService.hasWhenCondition($scope.leaflist);
        $scope.isNodeVisible = !hasWhenCondition;

        var tableCellValuesTranslationKeys: any = {
            multiElements: "yang.leaf.leaflist.values",
            singleElement: "yang.leaf.leaflist.value"
        };

        if (!$scope.pathKeys && $scope.getPathKeys) {
            $scope.pathKeys = $scope.getPathKeys();
        }
        var pathWithKeys = DataPathService.getNodePath($scope.pathKeys, $scope.leaflist);

        var reloadDataNode = function() {
            var dataValues = _.get(DomainHandlerService.getDataNode(pathWithKeys), "values", []);
            $scope.leaflist.values = dataValues.slice();
        };

        if (!$scope.isTransient) {
            reloadDataNode();
        }

        $scope.updateLeaflist = function() {
            $scope.leaflistValuesNumber = _.get($scope.leaflist.values, "length", 0);
            $scope.leaflistLabel = TableCellService.getCellValuesStr($scope.leaflist.values, tableCellValuesTranslationKeys);

            if ($scope.rowModel) {
                $scope.rowModel[$scope.fieldSearchStatement] = $scope.leaflist.values;
            }
        };

        $scope.openModalLeaflist = function() {
            ngDialog.openConfirm({
                template: "/templates/features/dynamic-device/domain/yang/leaflist/leaflist-modal.html",
                controller: "LeafListModalController",
                className: "small-modal",
                closeByDocument: false,
                closeByNavigation: false,

                resolve: {
                    pathKeys: function() {
                        return $scope.pathKeys;
                    },
                    isTransient: function() {
                        return true;
                    },
                    leafListTemplate: function() {
                        return $scope.leaflist;
                    },
                    config: function() {
                        return $scope.config;
                    },
                    labelModal: function() {
                        return $scope.createAddLeaflistLabelModal();
                    },
                    parentPath: function() {
                        return $scope.parentPath;
                    },
                    shouldWatchChanges: function() {
                        return $scope.watchChanges;
                    }
                }
            }).then($scope.updateLeaflistValues);
        };

        var setDataNodeValues = function(values) {
            if (!$scope.isTransient) {
                DomainHandlerService.addOrSetDataNode(pathWithKeys + ".values", values);
            }
        };

        $scope.updateLeaflistValues = function(values) {
            $scope.leaflist.values = values;
            $scope.updateLeaflist();
            setDataNodeValues(values);
        };

        $scope.createAddLeaflistLabelModal = function() {
            var values = [];

            _.forEach($scope.keys, function(key) {
                var value = $scope.entry.leaves[key].value;
                values.push([key, value].join(" "));
            });

            var modalLabel = $scope.config
                ? $translate.instant("yang.leaf.leaflist.modal.title.configuration")
                : $translate.instant("yang.leaf.leaflist.modal.title.information");
            modalLabel = modalLabel.replace("{0}", $scope.listId);

            return modalLabel.replace("{1}", values.join(", "));
        };

        if ($scope.watchChanges !== false) {
            $scope.$watch("leaflist.values", function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    setDataNodeValues(newValue);
                    $scope.updateLeaflist();
                }
            });
        }

        if (hasWhenCondition && !$scope.isTransient) {
            var unregisterNodeStatusListener = NodeStatusService.register(
                $scope.leaflist.paths.schemaJsonPath,
                $scope.leaflist.paths.dataJsonPath,
                $scope.pathKeys,
                function(nodeStatus, refreshData) {
                    $scope.isNodeVisible = nodeStatus.isVisible;
                    if (refreshData) {
                        reloadDataNode();
                    }
                });

            $scope.$on("$destroy", unregisterNodeStatusListener);
        }

        $scope.updateLeaflist();
    }
]);
