import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from "@angular/core";
import { Subject, Observable } from "rxjs";

import { NmsCounterModel } from "@nms-ng2/app/shared/components/elements/nms-counter/nms-counter.component";

export interface AutoUpdaterDataResult {
    data?: any;
    error?: any;
}

/** Componente para realizar auto atualização. */
@Component({
  selector: "nms-auto-updater",
  templateUrl: "./nms-auto-updater.component.html",
  styleUrls: ["./nms-auto-updater.component.scss"]
})
export class NmsAutoUpdaterComponent implements OnInit, OnDestroy {

    /** Função que trará dados para atualização. Ela deve retornar um @see Observable. */
    @Input()
    dataRetrieveProvider: () => Observable<any>;

    /** Intervalo de tempo entre as atualizações em segundos. Default: 300 segundos (5 min) */
    @Input()
    intervalTime?: number = 300;

    /** Evento para enviar o resultado dos dados recuperados para o componente pai. */
    @Output()
    onDataReceived = new EventEmitter<AutoUpdaterDataResult>();

    /** Notifier para início de atualização */
    startNotifier: Subject<NmsCounterModel>;
    /** Notifier para fim de atualização */
    stopNotifier: Subject<NmsCounterModel>;
    /** Notifier para pausa de atualização */
    pauseNotifier: Subject<boolean>;
    /** Notifier para início de tempo ocioso */
    idleStartNotifier: Subject<NmsCounterModel>;
    /** Notifier para fim de tempo ocioso */
    idleStopNotifier: Subject<NmsCounterModel>;
    /** Indica se a atualização está em progresso */
    isUpdating: boolean;
    /** Indica se o contader está pausado */
    isPaused: boolean;
    /** Indica se o processo de auto atualização foi iniciado pelo componente pai. */
    isInitialized: boolean;

    constructor() {
        this.isUpdating = false;
        this.startNotifier = new Subject();
        this.stopNotifier = new Subject();
        this.pauseNotifier = new Subject();
        this.idleStartNotifier = new Subject();
        this.idleStopNotifier = new Subject();
    }

    ngOnInit(): void {
        this.isInitialized = true;
        this.start();
    }

    /**
     * Inicia o processo de atualização, notificando o contador de atualização e realizando a requisição dos dados.
     */
    start = (): void => {
        this.isUpdating = true;
        this.startNotifier.next({countStart: 0});
        this.continue();
        this.dataRetrieveProvider().subscribe(
            (data) => this.notifyDataReceived({data: data}),
            (error) => this.notifyDataReceived({error: error})
        );
    }

    /**
     * Pausa o processo de cronometragem.
     */
    pause = (): void => {
        this.isPaused = true;
        this.pauseNotifier.next(true);
    }

    /**
     * Continua com o processo de cronometragem.
     */
    continue = (): void => {
        this.isPaused = false;
        this.pauseNotifier.next(false);
    }

    /**
     * Finaliza a atualização, notificando fim para o contador de atualização e o iníco para contador de tempo ocioso.
     */
    stop = (): void => {
        this.isUpdating = false;
        this.stopNotifier.next();
        this.idleStartNotifier.next({countStart: this.intervalTime});
    }

    /**
     * Finaliza a contagem de tempo ocioso, notificando seu contador e iniciando uma nova atualização.
     */
    stopIdleAndRestart = (): void => {
        this.idleStopNotifier.next();
        this.start();
    }

    /**
     * Notifica o recebimento dos dados e finaliza a atualização.
     */
    private notifyDataReceived = (response: AutoUpdaterDataResult): void => {
        this.onDataReceived.emit(response)
        this.stop();
    }

    ngOnDestroy(): void {
        this.startNotifier.unsubscribe();
        this.stopNotifier.unsubscribe();
        this.idleStartNotifier.unsubscribe();
        this.idleStopNotifier.unsubscribe();
    }
}
