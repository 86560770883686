"use strict";

/**
* @ngdoc function
* @name nms.templates.controller:TemplatelistingCtrl
* @description
* # TemplatelistingCtrl
* Controller of the nms.templates
*/
var app = angular.module("nms.templates");

app.controller("TemplateListCtrl", ["$rootScope", "$scope", "$translate", "$timeout", "$filter", "$state",
    "TemplateService", "status", "searchCriteria", "NMS_STATES", "ServerPaginationService",
    "UserPreferencesService", "hasTemplateConfigPermission", "AuthenticationService",
    "TEMPLATE_PERMISSION_FIELDS", "RESTRICTION_RULE_MATCHING_TYPES", "TemplateInstanceValidatorService",
    "TEMPLATE_TYPE", "TemplateValidatorTypeService", "hasTemplateInstancePermission",
    function($rootScope, $scope, $translate, $timeout, $filter, $state, TemplateService,
            status, searchCriteria, NMS_STATES, ServerPaginationService, UserPreferencesService, hasTemplateConfigPermission,
            AuthenticationService, TEMPLATE_PERMISSION_FIELDS, RESTRICTION_RULE_MATCHING_TYPES,
            TemplateInstanceValidatorService, TEMPLATE_TYPE, TemplateValidatorTypeService, hasTemplateInstancePermission) {
        var maxTemplatesToFilterInstance = 50;

        $scope.error = false;
        $scope.checked = {templates: [], selectAllCheckbox: false};
        $scope.keywords = {total: "0"};
        $scope.templates = [];
        $scope.filters = ServerPaginationService.getFilters({
            origin: "template",
            column: "lastModified",
            currentType: "TEXT",
            searchValue: "",
            searchColumn: "",
            showOnlyAllowedTemplates: false
        });
        $scope.pageDetails = ServerPaginationService.getPageDetails({numberOfElements: 0});
        $scope.status = status;
        $scope.searchCriteria = searchCriteria;
        $scope.search = {};
        $scope.previousSearch = {};
        $scope.datePicker = {
            data: {startDate: null, endDate: null}
        };
        $scope.showTemplatesRestrictionsCheckbox = false;
        $scope.templatesRestrictionsTooltipText = "";
        $scope.templateType = null;
        $scope.hasTemplateInstancePermission = hasTemplateInstancePermission;

        $scope.setSearchType = function() {
            $scope.filters.currentType = TemplateService.getSearchType($scope.searchCriteria[$scope.search.field]);
            if ($scope.filters.currentType !== "OPTION") {
                delete $scope.filters.searchTermFinal;
                $scope.search.value = "";
            }
        };

        $scope.edit = function(template) {
            $state.go(NMS_STATES.template.edit, {template: template});
        };

        $scope.loadPage = function() {
            $scope.getTotalKeywords();
            setupTemplatesRestrictions();
            TemplateService.getPaginated($scope.filters).then($scope.successGetPaginated);
        };

        $scope.readPageFiltersFromSessionStorage = function() {
            $scope.filters = UserPreferencesService.loadPageFilterValues($scope.filters, $scope.filters.origin);

            $scope.pageDetails.currentPage = $scope.filters.pageNumber + 1;
            $scope.search.value = $scope.filters.searchTerm ? decodeURIComponent($scope.filters.searchTerm) : undefined;
            $scope.search.field = $scope.filters.searchColumn;
            $scope.hasSearch = TemplateService.hasSearchCriteria($scope.filters);
            if ($scope.filters.currentType === "OPTION") {
                $scope.setSearchType();
            }
        };

        $scope.setHasSearchValue = function(value) {
            $timeout(function() {
                $scope.hasSearch = value;
            }, 1);
        };

        $scope.setPageFiltersSession = function() {
            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
        };

        $scope.orderByTable = function(column) {
            if ($scope.filters.column !== column) {
                $scope.filters.column = column;
                $scope.filters.direction = "ASC";
            } else {
                $scope.filters.direction = $scope.filters.direction==="ASC" ? "DESC" : "ASC";
            }

            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
            $scope.loadPage();
        };

        $scope.checkUncheckAll = function() {
            $scope.checked.templates = TemplateService.checkUncheckAll($scope.checked.selectAllCheckbox, $scope.templates);
        };

        $scope.deleteTemplates = function() {
            if (TemplateService.isAllowedTemplateConfig(hasTemplateConfigPermission)) {
                if ($scope.checked.templates.length === 0) {
                    $rootScope.showDialog({
                        translateKey: "popups.alert.needAtLeastOneTemplateSelected"
                    });
                } else {
                    var message = $translate.instant("popups.confirm.deleteManyMessage")
                                + $scope.checked.templates.length + " " + $translate.instant("template.templates") + "?";

                    $rootScope.showDialog({
                        message: message,
                        isConfirm: true
                    }).then($scope.verifyRulesAndDeleteTemplates);
                }
            }
        };

        $scope.verifyRulesAndDeleteTemplates = function() {
            var templatesWithSystemKeyword = TemplateService.extractTemplatesWithSystemKeyword($scope.checked.templates);
            var usedTemplates = TemplateService.extractUsedTemplates($scope.checked.templates);

            if (templatesWithSystemKeyword.length > 0) {
                $rootScope.showDialog({
                    translateKey: "popups.error.remove.removeDefaultNmsTemplateIsNotAllowed"
                });
            } else if (usedTemplates.length > 0) {
                $rootScope.showDialog({
                    translateKey: "popups.alert.templatesAreUsed",
                    params: usedTemplates,
                    maxChars: 37
                });
            } else {
                var templatesId = TemplateService.getTemplatesId($scope.checked.templates);
                TemplateService.delete(templatesId).then($scope.successDeleteManyTemplate);
            }
        };

        $scope.deleteTemplate = function(template) {
            if (TemplateService.isAllowedTemplateConfig(hasTemplateConfigPermission)
                && template && template.id && template.name) {
                // this template will be used later in verifyRulesAndDeleteTemplate.
                $scope.templateToDelete = template;
                var message = $translate.instant("popups.confirm.deleteOneTemplateMessage") + $filter("cutString")(template.name, 37) + "?";

                $rootScope.showDialog({
                    message: message,
                    isConfirm: true
                }).then($scope.verifyRulesAndDeleteTemplate);
            }
        };

        $scope.verifyRulesAndDeleteTemplate = function() {
            var templatesWithSystemKeyword = TemplateService.extractTemplatesWithSystemKeyword([$scope.templateToDelete]);
            if (templatesWithSystemKeyword.length > 0) {
                $rootScope.showDialog({
                    translateKey: "popups.error.remove.removeDefaultNmsTemplateIsNotAllowed"
                });
            } else if ($scope.templateToDelete.used) {
                $rootScope.showDialog({
                    translateKey: "popups.alert.templateIsUsed"
                });
            } else {
                TemplateService.delete(new Object([$scope.templateToDelete.id])).then($scope.successDeleteOneTemplate);
            }
        };

        $scope.viewTemplateInstances = function() {
            if ($scope.checked.templates.length === 0) {
                $rootScope.showDialog({
                    translateKey: "popups.alert.needAtLeastOneTemplateSelected"
                });

                return;
            } else if ($scope.checked.templates.length > maxTemplatesToFilterInstance) {
                var details = $translate.instant("popups.alert.maximumTemplateAmountToFilter") + maxTemplatesToFilterInstance
                        + ". "
                        + $translate.instant("popups.alert.selectedTemplateAmountToFilter") + $scope.checked.templates.length;

                $rootScope.showDialog({
                    translateKey: "popups.alert.maximumTemplateAmountExceededToFilter",
                    params: [details]
                });

                return;
            }

            var templateNames = TemplateService.getTemplatesName($scope.checked.templates);

            $state.go(NMS_STATES.templateInstance.list, {templateNames: templateNames});
        };

        $scope.applyTemplates = async function() {
            if ($scope.checked.templates.length === 0) {
                $rootScope.showDialog({ translateKey: "popups.alert.needAtLeastOneTemplateSelected" });
                return;
            }

            const restrictedTemplateids = await TemplateService.filterRestrictedTemplates(_.map($scope.checked.templates, "id"));

            if (restrictedTemplateids.length === $scope.checked.templates.length) {
                $rootScope.showDialog({ translateKey: "template.application.alert.all.templates.restricted" });
                return;
            }

            const templatesToApply = $scope.checked.templates.filter(({ id }) => !_.includes(restrictedTemplateids, id));

            if (!TemplateValidatorTypeService.areTemplatesTheSameType(templatesToApply)) {
                $rootScope.showDialog({ translateKey: "popups.alert.templateTypeEqual" });
                return;
            }

            $scope.templateType = templatesToApply[0].type;
            const templateIds = _.map(templatesToApply, ({ id }) => id);

            TemplateInstanceValidatorService.validateTemplatesPermissionByTemplates(templateIds)
                .then(function() {
                    if (TemplateService.isAllowedTemplateConfig(hasTemplateInstancePermission)) {
                        if (templatesToApply.length !== $scope.checked.templates.length) {
                            $rootScope.showDialog({ translateKey: "template.application.alert.some.templates.restricted" });
                        }

                        $state.go(NMS_STATES.templateInstance.add, {
                            templates: templatesToApply,
                            templateType: $scope.templateType
                        });
                    }
                })
                .catch(function () {
                    $rootScope.showDialog({ translateKey: "templatelisting.userHasNoPermission" });
                });
        };

        $scope.filterByAllowedTemplates = function() {
            UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
            $scope.loadPage();
        };

    /** ** CALL BACKS *****/
        $scope.successGetPaginated = function(response) {
            $scope.templates = response.pageElements;
            $scope.filters = ServerPaginationService.updateFilters($scope.filters, response.pageSize, response.pageNumber);
            $scope.pageDetails = ServerPaginationService.updatePageDetails($scope.pageDetails, response.totalElements,
            response.pageNumber, response.pageSize);
            $scope.pageDetails.numberOfElements = response.numberOfElements;
            $scope.checked = {templates: [], selectAllCheckbox: false};
        /* Set previous search criteria */
            $scope.previousSearch.value = $scope.search.value;
            $scope.previousSearch.field = $scope.search.field;
        };

        $scope.successDeleteOneTemplate = function(response) {
            if (!response[0].success) {
                $scope.openPartialDeleteDialog(new Array(response[0].id), $scope.templates);
            } else {
                if ($scope.pageDetails.numberOfElements === 1 && $scope.filters.pageNumber > 0) {
                    $scope.filters.pageNumber--;
                    $scope.pageDetails.currentPage = $scope.filters.pageNumber + 1;

                    UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
                }
                $scope.loadPage();
                $rootScope.toastInfo("popups.confirm.templateDeleted");
            }
        };

        $scope.successDeleteManyTemplate = function(response) {
            var fails = $scope.getFailureDeletions(response);

            if (fails.length > 0) {
                $scope.openPartialDeleteDialog(fails, $scope.checked.templates);
            } else {
                if ($scope.pageDetails.numberOfElements === $scope.checked.templates.length && $scope.filters.pageNumber > 0) {
                    $scope.filters.pageNumber--;
                    $scope.pageDetails.currentPage = $scope.filters.pageNumber + 1;

                    UserPreferencesService.savePageFilterValues($scope.filters, $scope.filters.origin);
                }
                $scope.loadPage();
                $rootScope.toastInfo("popups.confirm.templatesDeleted");
            }
        };

        $scope.openPartialDeleteDialog = function(templateIds, templates) {
            var names = TemplateService.getTemplateNameFromId(templateIds, templates);
            // Break names in order to cut each one of them
            names = names.split("<br>");
            // return only the valid contents
            var params = names.filter( function(e) {
                return e;
            });
            $rootScope.showDialog({
                translateKey: "popups.confirm.deleteTemplatePartial",
                params: params,
                maxChars: 40
            });
            $scope.loadPage();
        };

        $scope.getFailureDeletions = function(response) {
            var fails = [];
            response.forEach(function(responseItem) {
                if (!responseItem.success) {
                    fails.push(responseItem.id);
                }
            });

            return fails;
        };

        $scope.getTotalKeywords = function() {
            TemplateService.getTotalKeywords($scope.filters.showOnlyAllowedTemplates).then(function(response) {
                $scope.keywords = response;
            });
        };

        $scope.isAllStillSelected = function() {
            $timeout(function() {
                $scope.isAllStillSelectedCallback();
            }, 10);
        };

        $scope.isAllStillSelectedCallback = function() {
            $scope.checked.selectAllCheckbox = $scope.checked.templates.length === $scope.templates.length;
        };

        $scope.exportSelectedTemplates = function() {
            if ($scope.checked.templates.length === 0) {
                $rootScope.showDialog({
                    translateKey: "popups.alert.needAtLeastOneTemplateSelected"
                });
                return;
            }

            TemplateService.exportSelectedTemplates(TemplateService.getTemplatesId($scope.checked.templates))
            .then($scope.downloadFile);
        };

        $scope.exportAllTemplates = function() {
            TemplateService.exportAllTemplates().then($scope.downloadFile);
        };

        $scope.downloadFile = function(response) {
            var json = JSON.stringify(response, null, "\t");
            var file = new Blob([json], {type: "text/json"});
            saveAs(file, $filter("fileNameGenerator")("templates", ".json"));
        };

        $scope.uploadFile = function() {
            $timeout(function() {
                $scope.importTemplates();
            }, 5);
        };

        $scope.importTemplates = function() {
            var file = $scope.json;
            TemplateService.import(file).then($scope.showImportMessage);
        };

        $scope.showImportMessage = function(response) {
            var message = $filter("replaceLineBreak")(response.data);
            $rootScope.toastInfo(message);
            $("#uploadFile").val(""); // FIXME: NÃO USAR JQUERY AQUI
            $scope.loadPage();
        };

        $scope.addTemplate = function() {
            if (TemplateService.isAllowedTemplateConfig(hasTemplateConfigPermission)) {
                $state.go(NMS_STATES.template.add);
            }
        };

        $scope.cloneTemplate = function(template) {
            if (TemplateService.isAllowedTemplateConfig(hasTemplateConfigPermission)) {
                $state.go(NMS_STATES.template.clone, {templateId: template.id});
            }
        };

        $scope.importTemplatesFiles = function($event) {
            if (TemplateService.isAllowedTemplateConfig(hasTemplateConfigPermission)) {
                inputSelectedFile($event);
            }
        };

        var inputSelectedFile= function($event) {
            var elem = $event.currentTarget.nextElementSibling;
            elem.click();
        };

        var setupTemplatesRestrictions = function() {
            AuthenticationService.getTemplateRestrictions().then(function(templatePermissionModel) {
                if (!_.isUndefined(templatePermissionModel)) {
                    $scope.showTemplatesRestrictionsCheckbox = true;
                    var criteria = templatePermissionModel.criteria;
                    var rules = templatePermissionModel.rules;
                    buildRestrictionsTooltipText(criteria, rules);
                }
            });
        };

        var buildRestrictionsTooltipText = function(criteria, rules) {
            var restrictionsType = $translate.instant(criteria === "ALL" ? "general.and" : "general.or");

            $scope.templatesRestrictionsTooltipText =_.chain(rules)
                .map(function(rule) {
                    var field = $translate.instant(TEMPLATE_PERMISSION_FIELDS[rule.field].translationKey);
                    var type = $translate.instant(RESTRICTION_RULE_MATCHING_TYPES[rule.type].translationKey);
                    var value = rule.value;
                    return field + " " + type + " " + value;
                })
                .join(" " + restrictionsType + "<br>")
                .value();
        };

        $scope.getTemplateTypeLabel = function(type) {
            switch (type) {
                case TEMPLATE_TYPE.CLI.name:
                    return $translate.instant(TEMPLATE_TYPE.CLI.translationKey);
                case TEMPLATE_TYPE.TR_069.name:
                    return $translate.instant(TEMPLATE_TYPE.TR_069.translationKey);
            }
        };

        $scope.readPageFiltersFromSessionStorage();
        $scope.loadPage();
    }]);
