"use strict";
import { TemplateInstanceService } from "../template-instance-service";

/**
 * @ngdoc function
 * @name nms.templates.controller:ResultsModalCtrl
 * @description
 * Controller of the nms.templates
 */
var app = angular.module("nms.templates");

app.controller("ResultsModalCtrl", [
    "$scope",
    "$rootScope",
    "$translate",
    "$filter",
    "results",
    "templateType",
    "TemplateInstanceService",
    "DeviceService",
    "TemplateService",
    "ProgressBarService",
    "TemplateInstanceAccordionDeviceChangesService",
    "APPLICATION_STATUS",
    "BROADCAST_EVENTS",
    "TemplateInstanceIdentifierService",
    "TemplateInstanceUtils",
    "PRESENTATION_MODE",
    "EquipmentService",
    "hideResultDetails",
    "executionOrigin",
    "EXECUTION_ORIGIN",
    "templateInstanceId",
    function (
        $scope,
        $rootScope,
        $translate,
        $filter,
        results,
        templateType,
        TemplateInstanceService: TemplateInstanceService,
        DeviceService,
        TemplateService,
        ProgressBarService,
        TemplateInstanceAccordionDeviceChangesService,
        APPLICATION_STATUS,
        BROADCAST_EVENTS,
        TemplateInstanceIdentifierService,
        TemplateInstanceUtils,
        PRESENTATION_MODE,
        EquipmentService,
        hideResultDetails: boolean,
        executionOrigin,
        EXECUTION_ORIGIN,
        templateInstanceId
    ) {
        $rootScope.results = results;
        $scope.executionOriginOptions = Object.keys(EXECUTION_ORIGIN).map(origin => {
            return {
                from: origin,
                label: $translate.instant("modal.view.results.originExecution." + origin.toLowerCase())
            }
        });
        $scope.executionOrigin = $scope.executionOriginOptions.find(origin => origin.from === executionOrigin);
        $scope.showLoading = false;
        $scope.showExecutionColumnsAtAccordion = true;

        $scope.setup = function() {
            $rootScope.hideLoadingPanel = true;
            $scope.hideResultDetails = hideResultDetails || false;
            $scope.selectedData = { parentId: null, childId: null };
            $scope.accordionData = [];
            $scope.filters = {
                orderByDevice: { column: "equipmentName", direction: false }
            };
            $rootScope.equipmentResults = null;
            $rootScope.templateResultsChanged = null;
            $rootScope.resultsChanged = null;
            $scope.oldEquipmentResults = null;
            $scope.templateInstanceId = templateInstanceId;
            $scope.getSpecificTranslationKeys = function () {
                return EquipmentService.getSpecificTranslationKeys(templateType);
            };
            $scope.viewTypeResults = $translate.instant("templateinstanceform.viewapply.viewresults");
            $scope.applyCommandsResult = $translate.instant("templateinstanceform.viewapply.applycommandsresult");
            $scope.removalCommandsResult = $translate.instant("templateinstanceform.viewapply.removalcommandsresult");
            $scope.presentationMode = {
                value: $rootScope.results.presentationMode
                    ? $rootScope.results.presentationMode
                    : PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE
            };
        }

        $scope.onChangeExecutionOrigin = function(executionOrigin) {
            $scope.showLoading = true;
            $scope.executionOrigin = executionOrigin;
            $scope.setup();
            TemplateInstanceService.getResultForTemplateInstance($scope.templateInstanceId, $scope.executionOrigin.from, "APPLY")
                .then(function (response) {
                    $scope.results = response;
                    $rootScope.results = response;
                    $scope.getResultForEquipment();
                    $scope.stacked = ProgressBarService.getProgressBarDataSource($scope.results.equipmentAssociationResults);
                    $scope.updateProgressBar();
                    $scope.showLoading = false;
                });
        }

        $scope.hasTemplateApplying = function() {
            const finishedStatuses = [
                APPLICATION_STATUS.SUCCESS,
                APPLICATION_STATUS.FAIL,
                APPLICATION_STATUS.NOT_REQUESTED,
                APPLICATION_STATUS.NOT_EXECUTED
            ];
            const templatesFinished = (equipment) =>
                equipment.templateResults.every(result => finishedStatuses.includes(result.status));
            const applicationFinished =
                $scope.results.equipmentAssociationResults.every(equipment => templatesFinished(equipment));
            return !applicationFinished;
        }

        $scope.getTooltipForApplying = function() {
            if ($scope.hasTemplateApplying()) {
                return  $translate.instant("modal.view.results.applying.tooltip");
            }

            return "";
        }

        $scope.getResultForEquipment = function (templateInstanceIdentifier) {
            const templateInstanceId = templateInstanceIdentifier ? templateInstanceIdentifier : $scope.templateInstanceId;
            TemplateInstanceService.getResultForEquipment(templateInstanceId, $scope.executionOrigin.from).then(
                $scope.successGetResultForEquipment
            );
        };

        $scope.successGetResultForEquipment = function (response) {
            $scope.oldEquipmentResults = angular.copy($rootScope.equipmentResults);
            $rootScope.equipmentResults = response;

            var equipmentResults = _.map($rootScope.equipmentResults, "equipmentDetails");
            var oldEquipmentResults = _.map($scope.oldEquipmentResults, "equipmentDetails");
            var newEquipmentByResult = _.map(equipmentResults, "model");
            var oldEquipmentByResult = _.map(oldEquipmentResults, "model");

            var diff = _.difference(newEquipmentByResult, oldEquipmentByResult).toString();
            diff = diff.split(" ").join("-");

            $rootScope.$broadcast("scrollFromTop", { params: diff });
        };

        $scope.createCommandResultId = function (command) {
            return command.equipmentDetails.model.split(" ").join("-");
        };

        $scope.stacked = ProgressBarService.getProgressBarDataSource($scope.results.equipmentAssociationResults);

        this.updateResults = function (resultsChanged) {
            if (!resultsChanged) {
                return;
            }
            var JSONobject = JSON.parse(resultsChanged);

            if ($rootScope.results.templateInstanceId === JSONobject.templateInstanceId) {
                $scope.results.equipmentAssociationResults.forEach(function (equipment) {
                    var equipmentName = TemplateInstanceIdentifierService.resolveName(equipment.equipmentDetails);
                    var jsonEquipName = TemplateInstanceIdentifierService.resolveName(JSONobject.equipmentDetails);
                    if (equipmentName === jsonEquipName) {
                        equipment.status = JSONobject.equipmentApplicationStatus;
                        return;
                    }
                });
            }
        };

        $scope.orderByDeviceTable = function (column) {
            if ($scope.filters.orderByDevice.column !== column) {
                $scope.filters.orderByDevice.column = column;
                $scope.filters.orderByDevice.direction = false;
            } else {
                $scope.filters.orderByDevice.direction = !$scope.filters.orderByDevice.direction;
            }

            $scope.results.equipmentAssociationResults = $filter("orderBy")(
                $scope.results.equipmentAssociationResults,
                $scope.filters.orderByDevice.column,
                $scope.filters.orderByDevice.direction
            );
        };

        $scope.setStatus = function (association) {
            return association.status === "DONE" || association.status === "ERROR" ? $scope.status.DONE : $scope.status.APPLYING;
        };

        $scope.updateProgressBar = function() {
            ProgressBarService.updateProgressBar($scope.results.equipmentAssociationResults);
        }

        this.updateResultDetails = function (templateResultsChanged) {
            if (!templateResultsChanged) {
                return;
            }

            var JSONobject = JSON.parse(templateResultsChanged);

            if (JSONobject.templateApplicationStatus != APPLICATION_STATUS.APPLYING) {
                $scope.setTemplateStatus(JSONobject);
            }

            $scope.refreshColumnHeaderInformation(JSONobject);

            if (!$scope.hideResultDetails) {
                updateAccordionStatus(JSONobject);
                $rootScope.$broadcast("showDetailsFromApplication");
            }

        };

        $scope.setTemplateStatus = function (JSONobject) {
            var selectedEquipmentId = $scope.isDefaultVisualizationMode()
                ? $scope.selectedData.childId
                : $scope.selectedData.parentId;
            var selectedTemplateId = $scope.isDefaultVisualizationMode()
                ? $scope.selectedData.parentId
                : $scope.selectedData.childId;
            var equipmentId = TemplateInstanceIdentifierService.resolveIdentifier(JSONobject.equipmentIdentifier);

            if (
                $rootScope.results.templateInstanceId === JSONobject.templateInstanceId &&
                (selectedEquipmentId === equipmentId || selectedTemplateId == JSONobject.templateId)
            ) {
                var equipmentAssociationRequests = [];
                if ($scope.isDefaultVisualizationMode()) {
                    if (
                        selectedTemplateId == JSONobject.templateId &&
                        selectedEquipmentId &&
                        selectedEquipmentId == equipmentId
                    ) {
                        equipmentAssociationRequests.push({
                            equipmentIdentifier: JSONobject.equipmentIdentifier,
                            templateId: selectedTemplateId
                        });
                    } else if (selectedTemplateId == JSONobject.templateId && !selectedEquipmentId) {
                        $rootScope.results.equipmentAssociationResults.forEach(function (equipmentAssociation) {
                            var hasTemplate = _.some(equipmentAssociation.templateResults, function (templateResult) {
                                return templateResult.templateId == JSONobject.templateId;
                            });

                            if (hasTemplate) {
                                equipmentAssociationRequests.push({
                                    equipmentIdentifier: equipmentAssociation.equipmentIdentifier,
                                    templateId: JSONobject.templateId
                                });
                            }
                        });
                    }
                } else {
                    if (selectedEquipmentId == equipmentId && selectedTemplateId && selectedTemplateId == JSONobject.templateId) {
                        equipmentAssociationRequests.push({
                            equipmentIdentifier: JSONobject.equipmentIdentifier,
                            templateId: selectedTemplateId
                        });
                    } else if (selectedEquipmentId == equipmentId && !selectedTemplateId) {
                        var currentEquipmentAssociations = _.filter(
                            $rootScope.results.equipmentAssociationResults,
                            function (equipmentAssociation) {
                                return _.isEqual(equipmentAssociation.equipmentIdentifier, JSONobject.equipmentIdentifier);
                            }
                        );

                        currentEquipmentAssociations[0].templateResults.forEach(function (templateResult) {
                            equipmentAssociationRequests.push({
                                equipmentIdentifier: JSONobject.equipmentIdentifier,
                                templateId: templateResult.templateId
                            });
                        });
                    }
                }

                if (equipmentAssociationRequests.length > 0) {
                    $scope.getResultForEquipment(JSONobject.templateInstanceId);
                }
            }
        };

        $scope.refreshColumnHeaderInformation = function (JSONobject) {
            var equipmentId = JSONobject.equipmentIdentifier;
            var selectedEquipmentAssociation = _.find(
                $scope.results.equipmentAssociationResults,
                function (equipmentAssociation) {
                    return _.isEqual(equipmentAssociation.equipmentIdentifier, equipmentId);
                }
            );
            var selectedTemplateAssociation = _.find(
                selectedEquipmentAssociation.templateResults,
                function (templateAssociation) {
                    return templateAssociation.templateId == JSONobject.templateId;
                }
            );

            selectedTemplateAssociation.status = JSONobject.templateApplicationStatus;

            $scope.updateProgressBar();
        };

        function updateAccordionStatus(JSONobject) {
            const equipmentId = JSONobject.equipmentIdentifier;

            var parent = _.find($scope.accordionData, function (data) {
                return TemplateInstanceUtils.resolveAccordionIdentifier(data.id) == JSONobject.templateId;
            });
            var child = parent
                ? _.find(parent.children, function (data) {
                      return _.isEqual(data.id, equipmentId);
                  })
                : undefined;

            if (!$scope.isDefaultVisualizationMode()) {
                parent = _.find($scope.accordionData, function (data) {
                    return _.isEqual(data.id, equipmentId);
                });
                child = _.find(parent.children, function (data) {
                    return TemplateInstanceUtils.resolveAccordionIdentifier(data.id) == JSONobject.templateId;
                });
            }

            parent.status[child.status] = parent.status[child.status] ? parent.status[child.status] - 1 : 0;
            parent.status[JSONobject.templateApplicationStatus] = parent.status[JSONobject.templateApplicationStatus]
                ? parent.status[JSONobject.templateApplicationStatus] + 1
                : 1;

            if (JSONobject.templateApplicationStatus !== APPLICATION_STATUS.APPLYING
                && JSONobject.templateApplicationStatus !== APPLICATION_STATUS.APPLYING_BY_ANOTHER_USER) {
                child.executedAt = JSONobject.executedAt;
                child.executedBy = JSONobject.executedBy;
            }

            child.status = JSONobject.templateApplicationStatus;
        }

        $scope.createCommandResultLabel = function (command) {
            if ($scope.isDefaultVisualizationMode()) {
                var equipmentLabelKey = TemplateInstanceUtils.getEquipmentLabelKey(templateType);
                return (
                    $translate.instant(equipmentLabelKey) +
                    ": " +
                    command.equipmentDetails.name +
                    ", " +
                    $translate.instant("templateinstanceform.model") +
                    ": " +
                    command.equipmentDetails.model
                );
            } else {
                return $translate.instant("templateinstanceform.template") + ": " + command.templateName;
            }
        };

        $scope.isDefaultVisualizationMode = function () {
            return !$scope.presentationMode || $scope.presentationMode.value === PRESENTATION_MODE.EQUIPMENTS_BY_TEMPLATE;
        };

        $scope.getTitle = function () {
            return $translate.instant("templateinstancelisting.results");
        };

        $scope.getProgressBarTitle = function () {
            return ProgressBarService.getProgressBarTitle(templateType);
        };

        $scope.getTemplatesList = function() {
            return Array.from(
                new Set($scope.results.equipmentAssociationResults
                    .flatMap((a) => a.templateResults.map((e) => e.templateName))
                )
            );
        };

        $scope.init = function () {
            $scope.updateProgressBar();
        };

        $scope.accordion = {
            getAccordionData: function () {
                return $scope.accordionData;
            },
            getSelectedData: function () {
                return $scope.selectedData;
            },
            getPresentationMode: function () {
                return $scope.presentationMode;
            },
            getResults: function () {
                return $scope.results;
            },
            getResultForEquipment: $scope.getResultForEquipment
        };

        var unregisterEquipmentPropertiesChangeListener = $scope.$on(
            BROADCAST_EVENTS.DEVICE_NAME_CHANGED,
            function (event, payload) {
                var updatedEquipment = payload.device;
                var equipmentResult = _.find($scope.results.equipmentAssociationResults, { equipmentId: updatedEquipment.id });
                if (equipmentResult) {
                    equipmentResult.equipmentName = updatedEquipment.name;
                }

                TemplateInstanceAccordionDeviceChangesService.updateAccordionDeviceModels(
                    updatedEquipment,
                    $scope.accordionData,
                    $scope.presentationMode.value
                );
            }
        );

        $scope.setup();
        $scope.init();
        $scope.$watch("resultsChanged", this.updateResults);
        $scope.$watch("templateResultsChanged", this.updateResultDetails);
        $scope.$on("$destroy", unregisterEquipmentPropertiesChangeListener);
    }
]);
