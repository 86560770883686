var app = angular.module("nms.dynamicDevice");

/**
 * Restringe um valor, um intervalo ou mais de um intervalo digitado pelo usuário.
 * Utilizada em campos do tipo text que permitem a definição de intervalos para a configuração.
 *
 * @author icaro.damiani, patrick.bard
 */
app.directive("userRangeRestriction", ["RestrictionService", "UserRangeRestrictionService",
    function(RestrictionService, UserRangeRestrictionService) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                var validatorName = RestrictionService.getLeafTypeValidatorName(scope.leaf.type,
                    UserRangeRestrictionService.RESTRICTION_NAME);
                ngModel.$validators[validatorName] = function(modelValue, viewValue) {
                    return UserRangeRestrictionService.validate(modelValue, viewValue, scope.leaf.type);
                };
            }
        };
    }
]);
