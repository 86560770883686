import { Component, Inject, Input, ViewChild, OnInit } from "@angular/core";
import { AbstractControl, NgModel, ValidatorFn } from "@angular/forms";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { CronTriggerData, Trigger } from "@nms-ng2/app/modules/scheduler/triggers/triggers.models";
import { CronTriggerValidator } from "@nms-ng2/app/modules/scheduler/triggers/time-trigger/cron-trigger/cron-trigger-validator";
import { CronTriggerService } from "@nms-ng2/app/modules/scheduler/triggers/time-trigger/cron-trigger/cron-trigger.service";

/**
 * Componente responsável por exibir o formulário do agendamento com expressão cron.
 */
@Component({
    selector: "cron-trigger",
    templateUrl: "./cron-trigger.component.html",
    styleUrls: ["./cron-trigger.component.scss"],
})
export class CronTriggerComponent implements OnInit {

    @Input("cronTrigger")
    cronTrigger: Trigger;
    @ViewChild("cronInput")
    cronInput: NgModel;

    constructor(
        @Inject(ANGULARJS_TRANSLATE) private readonly $translate: any,
        private readonly cronTriggerValidator: CronTriggerValidator,
        private readonly cronTriggerService: CronTriggerService
    ) {}

    ngOnInit(): void {
        this.setCronExpression();
    }

    setValidator() {
        this.cronInput.control.setValidators(this.cronExpressionValidator());
    }

    cronExpressionValidator(): ValidatorFn {
        return (control: AbstractControl): Map<string, boolean> | null => {
            return control.value
                ? this.cronTriggerValidator.validateInputCronExpression(control.value)
                : new Map().set("cronError", true);
        };
    }

    private setCronExpression() {
        const cronTriggerData = this.cronTrigger.triggerData as CronTriggerData;
        cronTriggerData.cronExpression = cronTriggerData.cronExpression || "* * * * *";
    }

    public getDateTimeDescription(dateTime: Date): string {
        return this.cronTriggerService.getDateTimeDescription(dateTime);
    }

    public getCronExpressionDescription(): string {
        const cronTriggerData = this.cronTrigger.triggerData as CronTriggerData;
        if (this.cronTriggerValidator.validateCronExpression(cronTriggerData.cronExpression)) {
            return this.cronTriggerService.translateCronExpression(cronTriggerData);
        } else {
            return this.$translate.instant("scheduler.validation.error.invalidCronExpression");
        }
    }

}
