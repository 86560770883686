"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.MonitoringService
* @description - Service para persistencia e leitura de informações de monitoramento.
* # MonitoringService
* Factory in the nms.management.
*/
var app = angular.module("nms.management");

app.factory("MonitoringService", ["$rootScope", "MonitoringRestService",
    function($rootScope, MonitoringRestService) {
        const service: any = {};

        service.getMonitoringSettings = function() {
            return MonitoringRestService.getMonitoringSettings();
        };

        service.updateMonitoringSettings = function(monitoringConnectionSettings) {
            return MonitoringRestService.updateMonitoringSettings(monitoringConnectionSettings);
        };

        service.testConnection = function(monitoringConnectionSettings) {
            return MonitoringRestService.testConnection(monitoringConnectionSettings);
        };

        return service;
    }
]);
