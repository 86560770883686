"use strict";

/**
* @ngdoc service
* @name nms.templates.converter
* @description
* # converter
* Service in the nms.templates.
*/
angular.module("nms.templates")
.factory("ConverterService", function() {
    const service: any = {};

    service.convertMapToArray = function(map) {
        var array = [];
        for (var key in map) {
            array.push({key: key, value: map[key]});
        }

        return array;
    };

    service.convertArrayToMap = function(array) {
        var map: any = {};

        array.forEach(function(variable) {
            map[variable.key] = variable.value;
        });

        return map;
    };

    service.convertPrimitiveArrayToMapWithEmptyValue = function(array) {
        var map: any = {};

        array.forEach(function(variable) {
            map[variable] = "";
        });

        return map;
    };

    service.convertStringArrayToIntegerArray = function(firmware) {
        for (var i = 0; i < firmware.length; i++) {
            if (!isNaN(firmware[i])) {
                firmware[i] = parseInt(firmware[i]);
            }
        }
        return firmware;
    };

    service.convertArrayObjectToStringArray = function(objectList) {
        var arrayList = [];
        if (objectList) {
            objectList.forEach(function(item) {
                if (item.text) {
                    arrayList.push(item.text);
                } else {
                    arrayList.push(item);
                }
            });
        }

        return arrayList;
    };

    service.convertArrayToString = function (items: []): string[] {
        return _.map(items, (item) => item ? item.toString() : "");
    };

    return service;
});
