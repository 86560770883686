"use strict";

/**
 * Filter responsible to format a given string overriding the pattern [number} to related parameter.
 *
 */

var app = angular.module("nms");

app.filter("formatString",
    function() {
        return function(message, parameters) {
            for (var index = 0; index < parameters.length; index++) {
                message = message.replace("{" + index + "}", parameters[index]);
            }

            return message;
        };
    }
);
