"use strict";

/**
* @ngdoc Controller
* @name nms.management.ManagePermissionsFeaturesTabController
* @description Realiza gerência das permissões de features.
*/
var app = angular.module("nms.management");

app.controller("ManagePermissionsFeaturesController", ["$scope", "NgTableParams", "TABLE_PROPERTIES", "TableFilterService",
    "ManagePermissionsFeaturesService",
    function($scope, NgTableParams, TABLE_PROPERTIES, TableFilterService, ManagePermissionsFeaturesService) {
        var defaultFeaturesProperties: any = {
            selection: {
                id: $scope.id,
                checked: []
            },
            available: {
                data: []
            }
        };
        _.defaults($scope.owner.model, defaultFeaturesProperties);

        $scope.selection = $scope.owner.model.selection;
        $scope.available = $scope.owner.model.available;
        $scope.columns = [
            {field: "nameLabel"},
            {field: "modulesLabel", tipiconTranslateKey: "manage.permissions.features.modules.column.tipicon"}
        ];

        $scope.tableParams = new NgTableParams(
            {
                sorting: {nameLabel: "asc"},
                filter: {modulesLabel: "Web"}
            },
            {
                counts: [],
                dataset: [],
                filterOptions: {filterFn: TableFilterService.getFilter($scope.columns)}
            }
        );

        $scope.selection.table = $scope.tableParams;
        $scope.selection.ignoreHiddenElements = false;

        $scope.tableModel = {
            options: {
                translatePrefix: "manage.permissions.features.tableColumn"
            },
            columns: $scope.columns,
            selection: $scope.selection,
            tableParams: $scope.tableParams
        };

        var init = function() {
            ManagePermissionsFeaturesService.getFeaturePermissions($scope.owner.model.editData).then(function(features) {
                $scope.available.data = features.data;
                $scope.tableParams.settings({dataset: features.data});
                $scope.tableParams.count(features.data.length);
                $scope.selection.checked = features.selected;
                delete $scope.owner.model.editData;
            });
        };

        init();
    }
]);
