var app = angular.module("nms.dynamicDevice");

/**
 * Verifica as restrições de todos os tipos de uma union.
 */
app.directive("unionRestriction", ["UnionRestrictionService", "LeafService", "RestrictionService",
    function(UnionRestrictionService, LeafService, RestrictionService) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                var possibleTypes = LeafService.getUnionPossibleTypes(scope.leaf.type);
                var validators = [];

                var setValidatorsState = function(validatorsToSet, state) {
                    _.each(validatorsToSet, function(validatorName) {
                        ngModel.$setValidity(validatorName, state);
                    });
                };

                var getValidatorsInError = function(modelValue, viewValue) {
                    setValidatorsState(validators, null);

                    var validatorsInError = UnionRestrictionService.getErrors(
                        modelValue, viewValue, possibleTypes, scope.unionPossibleValues);
                    setValidatorsState(validatorsInError, false);
                    validators = _.union(validators, validatorsInError);

                    return validatorsInError;
                };

                ngModel.$validators.unionValidator = function(modelValue, viewValue) {
                    var validatorsInError = getValidatorsInError(modelValue, viewValue);

                    return _.isEmpty(validatorsInError);
                };

                if (scope.unionSelectionConfig && scope.unionSelectionConfig.create !== false) {
                    scope.unionSelectionConfig.create = function(value) {
                        var validatorsInError = getValidatorsInError(value, value);
                        ngModel.$setViewValue(value);

                        return _.isEmpty(validatorsInError) ? {value: value} : false;
                    };
                }
            }
        };
    }
]);
