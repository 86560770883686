"use strict";

/**
 * @ngdoc service
 * @name nms.DropdownDeviceService
 * @description
 * # DropdownDeviceService - Serviço de criação de atalhos para devices.
 * @param {object} $translate - chama função para tradução de keys
 * @param {object} DevicesActionsService - serviço que possui a implementação dos atalhos
 * @param {object} NMS_FEATURES - constante que possui os identificadores das features do sistema
 * @return {object} A lista de atalhos.
 */
angular.module("nms").factory("DropdownDeviceService", [
    "$translate",
    "DevicesActionsService",
    "NMS_FEATURES",
    "$filter",
    "TemplateApplicationOneStepService",
    "ONU_CONSTANTS",
    "ConnectivityTestService",
    "TEMPLATE_TYPE",
    "TemplateActionsService",
    "PermissionsActionsService",
    "EquipmentsModalsActionsService",
    function (
        $translate,
        DevicesActionsService,
        NMS_FEATURES,
        $filter,
        TemplateApplicationOneStepService,
        ONU_CONSTANTS,
        ConnectivityTestService,
        TEMPLATE_TYPE,
        TemplateActionsService,
        PermissionsActionsService,
        EquipmentsModalsActionsService
    ) {
        const service: any = {};

        var hasFeature = function (device, feature) {
            return Boolean(device && PermissionsActionsService.hasFeature(device, feature));
        };

        var createPortPathKeys = function (port) {
            return {
                "chassis-id": _.get(port, "portIdentifier.slotIdentifier.chassisIdentifier.name"),
                "slot-id": _.get(port, "portIdentifier.slotIdentifier.name"),
                "port-id": _.get(port, "portIdentifier.name")
            };
        };

        var openDeviceOnGponPortConfig = function (name, port) {
            var linkLabel = $translate.instant("summary.shortcut.config");
            var gponPathKey: any = { id: port.id };
            var portPathKey = createPortPathKeys(port);
            var pathKeys = [gponPathKey, portPathKey];

            openDeviceOnPortView(name, port, "config", linkLabel, pathKeys);
        };

        var openDeviceOnSlotPort = function (name, port, container, linkLabel) {
            var pathKeys = [createPortPathKeys(port)];
            openDeviceOnPortView(name, port, container, linkLabel, pathKeys);
        };

        var openDeviceOnPortView = function (name, port, container, linkLabel, pathKeys) {
            var portIdentifier = port.portIdentifier;

            var displayName = createFullPathDisplayName(linkLabel, port.name);
            var schemaJsonPath = "$.containers." + container + ">interface.lists." + portIdentifier.productModel + ".template";

            var params: any = { displayName: displayName, path: schemaJsonPath, pathKeys: pathKeys };
            DevicesActionsService.goToDeviceConfigWithParams(name, params);
        };

        var createFullPathDisplayName = function (linkName, elementName) {
            var displayNameKey = $translate.instant("summary.shortcut.infoConfig.fullPath.displayName");

            return displayNameKey.replace("{0}", linkName).replace("{1}", elementName);
        };

        service.getShortcutToDeviceSummary = function (globalConfig, dropdownDevice) {
            var hasGponOnusFeature = hasFeature(dropdownDevice, NMS_FEATURES.gponOnus.feature);

            /**
             * O objeto shortcuts pode conter as seguintes propriedades:
             * @param {string} label - label que será exibida
             * @param {function} action - ação a ser realizada ao clicar no atalho
             * @param {boolean} hasDivider - define se o atalho possui linha separadora
             * @param {boolean} isBold - define se o atalho deve ser exibido em negrito
             * @param {boolean} isVisible - define se o atalho deve ser exibido, este atributo somente é mandatório quando é
             * preciso verificar por permissões específicas, caso contrário, ele pode ser omitido.
             */
            var shortcuts = [
                {
                    label: $translate.instant("summary.shortcut.infoConfig"),
                    action: function (device) {
                        DevicesActionsService.goToDeviceConfig(device);
                    },
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.dynamicDeviceConfig.feature);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.alarms"),
                    action: function (device) {
                        DevicesActionsService.goToDeviceAlarms(device, this.label);
                    },
                    hasDivider: !hasGponOnusFeature,
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.dynamicDeviceConfig.feature);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.gponOnus"),
                    action: function (device) {
                        DevicesActionsService.tryToOpenDeviceGponOnus(globalConfig, device);
                    },
                    hasDivider: hasGponOnusFeature,
                    isVisible: function () {
                        return hasGponOnusFeature;
                    }
                },
                {
                    label: $translate.instant("devicelist.tableActions.applyTemplate"),
                    action: function (device) {
                        TemplateActionsService.tryApplyTemplate([device], TEMPLATE_TYPE.CLI);
                    }
                },
                {
                    label: $translate.instant("devicelist.tableActions.viewTemplateApplication"),
                    action: function (device) {
                        TemplateActionsService.viewTemplateApplication([device], [device.name], TEMPLATE_TYPE.CLI);
                    }
                },
                {
                    label: $translate.instant("findAddDevice.managementProtocolsOption"),
                    action: function (device) {
                        EquipmentsModalsActionsService.tryOpenDeviceEditCredentials(device, null);
                    }
                },
                {
                    label: $translate.instant("findAddDevice.propertiesOption"),
                    action: function (device) {
                        EquipmentsModalsActionsService.tryOpenDevicePropertiesConfig(device);
                    }
                },
                {
                    label: $translate.instant("findAddDevice.connectivityTest"),
                    action: function (device) {
                        ConnectivityTestService.openConnectivityTestModal([device]);
                    }
                }
            ];
            return shortcuts;
        };

        service.getShortcutToGponOnus = function (globalConfig, dropdownDevice) {
            var shortcuts = [
                {
                    label: $translate.instant("summary.shortcut.summary"),
                    action: function (device) {
                        DevicesActionsService.tryToOpenDeviceSummary(device, globalConfig);
                    },
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.summary.feature);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.infoConfig"),
                    action: function (device) {
                        DevicesActionsService.tryToOpenDeviceConfig(device);
                    },
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.dynamicDeviceConfig.feature);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.alarms"),
                    action: function (device) {
                        DevicesActionsService.tryToOpenDeviceAlarms(device, this.label);
                    },
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.dynamicDeviceConfig.feature);
                    },
                    hasDivider: true
                },
                {
                    label: $translate.instant("devicelist.tableActions.applyTemplate"),
                    action: function (device) {
                        TemplateActionsService.tryApplyTemplate([device], TEMPLATE_TYPE.CLI);
                    }
                },
                {
                    label: $translate.instant("devicelist.tableActions.viewTemplateApplication"),
                    action: function (device) {
                        TemplateActionsService.viewTemplateApplication([device], [device.name], TEMPLATE_TYPE.CLI);
                    }
                },
                {
                    label: $translate.instant("findAddDevice.managementProtocolsOption"),
                    action: function (device) {
                        EquipmentsModalsActionsService.tryOpenDeviceEditCredentials(device, null);
                    }
                },
                {
                    label: $translate.instant("findAddDevice.propertiesOption"),
                    action: function (device) {
                        EquipmentsModalsActionsService.tryOpenDevicePropertiesConfig(device);
                    }
                },
                {
                    label: $translate.instant("findAddDevice.connectivityTest"),
                    action: function (device) {
                        ConnectivityTestService.openConnectivityTestModal([device]);
                    }
                }
            ];
            return shortcuts;
        };

        service.getShortcutToDeviceManager = function (globalConfig, dropdownDevice) {
            var shortcuts = [
                {
                    label: $translate.instant("summary.shortcut.summary"),
                    action: function (device) {
                        DevicesActionsService.tryToOpenDeviceSummary(device, globalConfig);
                    },
                    isBold: true,
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.summary.feature);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.infoConfig"),
                    action: function (device) {
                        DevicesActionsService.tryToOpenDeviceConfig(device);
                    },
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.dynamicDeviceConfig.feature);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.alarms"),
                    action: function (device) {
                        DevicesActionsService.tryToOpenDeviceAlarms(device, this.label);
                    },
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.dynamicDeviceConfig.feature);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.gponOnus"),
                    action: function (device) {
                        DevicesActionsService.tryToOpenDeviceGponOnus(globalConfig, device);
                    },
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.gponOnus.feature);
                    }
                }
            ];
            return shortcuts;
        };

        service.getShortcutToConfig = function (globalConfig, dropdownDevice, openDeviceOnPathCallback) {
            var hasGponOnusFeature = hasFeature(dropdownDevice, NMS_FEATURES.gponOnus.feature);

            var shortcuts = [
                {
                    label: $translate.instant("summary.shortcut.summary"),
                    action: function (device) {
                        DevicesActionsService.goToDeviceSummary(device, globalConfig);
                    },
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.summary.feature);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.alarms"),
                    action: function (device) {
                        openDeviceOnPathCallback("$.containers.alarm");
                    },
                    hasDivider: !hasGponOnusFeature,
                    isVisible: function () {
                        return hasFeature(dropdownDevice, NMS_FEATURES.dynamicDeviceConfig.feature);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.gponOnus"),
                    action: function (device) {
                        DevicesActionsService.tryToOpenDeviceGponOnus(globalConfig, device);
                    },
                    hasDivider: hasGponOnusFeature,
                    isVisible: function () {
                        return hasGponOnusFeature;
                    }
                },
                {
                    label: $translate.instant("devicelist.tableActions.applyTemplate"),
                    action: function (device) {
                        TemplateActionsService.tryApplyTemplate([device], TEMPLATE_TYPE.CLI);
                    }
                },
                {
                    label: $translate.instant("devicelist.tableActions.viewTemplateApplication"),
                    action: function (device) {
                        TemplateActionsService.viewTemplateApplication([device], [device.name], TEMPLATE_TYPE.CLI);
                    }
                },
                {
                    label: $translate.instant("findAddDevice.managementProtocolsOption"),
                    action: function (device) {
                        EquipmentsModalsActionsService.tryOpenDeviceEditCredentials(device, null);
                    }
                },
                {
                    label: $translate.instant("findAddDevice.propertiesOption"),
                    action: function (device) {
                        EquipmentsModalsActionsService.tryOpenDevicePropertiesConfig(device);
                    }
                },
                {
                    label: $translate.instant("findAddDevice.connectivityTest"),
                    action: function (device) {
                        ConnectivityTestService.openConnectivityTestModal([device]);
                    }
                }
            ];
            return shortcuts;
        };

        service.getShortcutToSlot = function (name) {
            var shortcuts = [
                {
                    label: $translate.instant("summary.table.inventory"),
                    action: function (slot) {
                        service.openDeviceOnSlotInventory(name, slot, this.label);
                    },
                    isBold: true
                },
                {
                    label: $translate.instant("summary.table.platform"),
                    action: function (slot) {
                        var schemaJsonPath = "$.containers.status>platform.lists.chassis.template.lists.slot.template";
                        var pathKeys = [{ "chassis-id": slot.chassisIdentifierName }, { "slot-id": slot.slotIdentifierName }];
                        var displayName = createFullPathDisplayName(this.label, slot.name);

                        var params: any = { displayName: displayName, path: schemaJsonPath, pathKeys: pathKeys };
                        DevicesActionsService.goToDeviceConfigWithParams(name, params);
                    }
                }
            ];
            return shortcuts;
        };

        service.getShortcutToLagSlot = function (name) {
            var shortcuts = [
                {
                    label: $translate.instant("summary.shortcut.config"),
                    action: function (slot) {
                        service.openDeviceOnLagSlot(name, slot);
                    },
                    isBold: true
                }
            ];
            return shortcuts;
        };

        service.getShortcutToPort = function (globalConfig, device) {
            var name = device.name;
            var shortcuts = [
                {
                    label: $translate.instant("summary.shortcut.config"),
                    action: function (port) {
                        service.openDeviceOnPortConfig(name, port);
                    },
                    isBold: true
                },
                {
                    label: $translate.instant("summary.table.inventory"),
                    action: function (port) {
                        var portIdentifier = port.portIdentifier;
                        var slotIdentifier = portIdentifier.slotIdentifier;
                        var chassisIdentifier = slotIdentifier.chassisIdentifier;
                        var displayName = createFullPathDisplayName(this.label, port.name);

                        var schemaJsonPath =
                            "$.containers.inventory.lists.chassis.template.lists.slot" +
                            ".template.lists.card_provisioned>port.template";
                        var pathKeys = [
                            { "chassis-id": chassisIdentifier.name },
                            { "slot-id": slotIdentifier.name },
                            { "if-index": portIdentifier.name, "if-type": portIdentifier.productModel }
                        ];

                        var params: any = { displayName: displayName, path: schemaJsonPath, pathKeys: pathKeys };
                        DevicesActionsService.goToDeviceConfigWithParams(name, params);
                    }
                },
                {
                    label: $translate.instant("summary.shortcut.status"),
                    action: function (port) {
                        // TODO - Remover lógica quando houver lista de status gpon (na versão mínima de DmOS suportada)
                        if (_.includes(port.name, "gpon")) {
                            openDeviceOnGponPortConfig(name, port);
                        } else {
                            openDeviceOnSlotPort(name, port, "status", this.label);
                        }
                    }
                },
                {
                    label: $translate.instant("summary.table.gponOnus"),
                    action: function (port) {
                        var filters: any = {};
                        filters[ONU_CONSTANTS.PORT_COLUMN_KEY] = $filter("formatString")('"{0}"', [port.name]);
                        DevicesActionsService.tryToOpenDeviceGponOnus(globalConfig, device, filters);
                    },
                    isVisible: function (port) {
                        return isGponPort(port);
                    }
                },
                {
                    label: $translate.instant("summary.table.pppoeIntermediateAgentSessions"),
                    action: function (port) {
                        var containers = ["pppoe", "intermediate-agent", "sessions", "interface"];

                        var schemaJsonPath = "$.containers.status>" + containers.join(".containers.") + ".lists.gpon.template";
                        var pathKeys = [{ interface: port.id }];
                        var displayName = createFullPathDisplayName(this.label, port.name);

                        var params: any = { displayName: displayName, path: schemaJsonPath, pathKeys: pathKeys };
                        DevicesActionsService.goToDeviceConfigWithParams(name, params);
                    },
                    isVisible: function (port) {
                        return isGponPort(port);
                    }
                }
            ];

            return shortcuts;
        };

        service.getShortcutToLagPort = function (name) {
            var shortcuts = [
                {
                    label: $translate.instant("summary.shortcut.config"),
                    action: function (port) {
                        service.openDeviceOnLagPort(name, port);
                    },
                    isBold: true
                }
            ];
            return shortcuts;
        };

        /**
         * Retorna os atalhos referentes a listagem de templates por keywords.
         *
         * @param {keywordTemplates} keyword e seus respectivos templates.
         * @param {Function} getParamsToApply Responsável por retornar os parâmetros  para a aplicação do template.
         *
         * @example
         * A estrutura do parâmetro keywordsTemplates deve ser:
         *       keywordsTemplates = {
         *           keyword: "keywordName",
         *           templatesIdentifiers: [{
         *                   templateId: "templateID",
         *                   templateName: "templateName",
         *                   templateDescription: "templateDescription"
         *           }]
         *       }
         */
        service.getShortcutToKeywordsTemplates = function (keywordTemplates, getParamsToApply) {
            var shortcuts = [];

            keywordTemplates.templatesIdentifiers.forEach(function (templateIdentifier) {
                shortcuts.push({
                    label: templateIdentifier.templateName,
                    action: function () {
                        getParamsToApply(function (device, variables, templateType) {
                            TemplateApplicationOneStepService.tryToOpenTemplateApplicationModal(
                                device,
                                variables,
                                templateIdentifier,
                                templateType
                            );
                        });
                    },
                    description: templateIdentifier.templateDescription
                });
            });

            return shortcuts;
        };

        service.openDeviceOnPortConfig = function (name, port) {
            var linkLabel = $translate.instant("summary.shortcut.config");

            if (_.includes(port.name, "gpon")) {
                openDeviceOnGponPortConfig(name, port);
            } else {
                openDeviceOnSlotPort(name, port, "config", linkLabel);
            }
        };

        service.openDeviceOnSlotInventory = function (name, slot, linkLabel) {
            var schemaJsonPath = "$.containers.inventory.lists.chassis.template.lists.slot.template";
            var pathKeys = [{ "chassis-id": slot.chassisIdentifierName }, { "slot-id": slot.slotIdentifierName }];
            var displayName = createFullPathDisplayName(linkLabel, slot.name);

            var params: any = { displayName: displayName, path: schemaJsonPath, pathKeys: pathKeys };
            DevicesActionsService.goToDeviceConfigWithParams(name, params);
        };

        service.openDeviceOnLagSlot = function (name, slot) {
            var linkLabel = $translate.instant("summary.shortcut.config");
            var schemaJsonPath = "$.containers.link-aggregation.containers.interface";
            var pathKeys = [];
            var displayName = createFullPathDisplayName(linkLabel, slot.name);

            var params: any = { displayName: displayName, path: schemaJsonPath, pathKeys: pathKeys };
            DevicesActionsService.goToDeviceConfigWithParams(name, params);
        };

        service.openDeviceOnLagPort = function (name, port) {
            var linkLabel = $translate.instant("summary.shortcut.config");
            var schemaJsonPath = "$.containers.link-aggregation.containers.interface.lists.lag.template";
            var pathKeys = [{ "lag-id": port.portIdentifier.name }];
            var displayName = createFullPathDisplayName(linkLabel, port.name);

            var params: any = { displayName: displayName, path: schemaJsonPath, pathKeys: pathKeys };
            DevicesActionsService.goToDeviceConfigWithParams(name, params);
        };

        var isGponPort = function (port) {
            return _.includes(port.name, "gpon");
        };

        return service;
    }
]);
