/**
 * Diretivas comuns a múltiplos componentes.
 *
 * @authors icaro.damiani, patrick.bard
 */
var app = angular.module("nms.dynamicDevice");

app.directive("restoreButton", ["DomainHandlerService", "$timeout", "$rootScope", "$translate", "NesDataCacheService",
    "DataPathService",
    function(DomainHandlerService, $timeout, $rootScope, $translate, NesDataCacheService, DataPathService) {
        return {
            restrict: "E",
            replace: true,
            scope: {
                paths: "=",
                pathKeys: "="
            },
            template: "<button class='restore-button' data-placement='bottom' ng-disabled='isReadOnly()' "
                + "ng-click='restoreNode()'><span class='glyphicon glyphicon-share-alt icon-flipped line-tooltip'></span>"
                + "</button>",
            link: function(scope, element, attributes) {
                $timeout(function() {
                    element.attr("title", $translate.instant("directives.restoreLastPolling"));
                    element.tooltip();
                });

                scope.isReadOnly = function() {
                    return $rootScope.readOnly;
                };

                scope.restoreNode = function() {
                    $rootScope.showDialog({
                        translateKey: "directives.lastPollingValue",
                        paramsInsideMessage: true,
                        params: [attributes["nodeName"]],
                        isConfirm: true
                    }).then(function() {
                        DomainHandlerService.restoreDataNode(scope.paths, scope.pathKeys);
                        var node = DomainHandlerService.getSchemaNode(scope.paths.schemaJsonPath);
                        var pathWithKeys = DataPathService.getNodePath(scope.pathKeys, {paths: scope.paths});
                        $rootScope.$broadcast("reloadTreeNodeOf=" + pathWithKeys);
                        $rootScope.$broadcast("nodeRestored", {pathKeys: scope.pathKeys, node: node});
                        $rootScope.toastInfo("directives.valuesRestores");
                    });
                };
            }
        };
    }
]);

app.directive("informationTooltip", ["$filter", "$timeout",
    function($filter, $timeout) {
        return {
            restrict: "E",
            replace: true,
            scope: {
                node: "=?",
                information: "=?",
                insideModal: "=?"
            },
            template: "<span class='glyphicon glyphicon-info-sign text-primary line-tooltip node-information-tooltip'"
                + " data-html='true' data-placement='bottom'></span>",
            link: function(scope, element, attr) {
                var information = scope.node ? $filter("getInformation")(scope.node) : scope.information || "";
                var htmlInformation = $filter("replaceLineBreak")(information);
                var wrappedInformation = "<p class='node-information-tooltip'>" + htmlInformation + "</p>";

                if (attr.type === "root") {
                    wrappedInformation = "<p><span class='glyphicon glyphicon-folder-close line-tooltip'></span>"
                              + " represents a set of configurations</p>"
                              + "<p><span class='glyphicon glyphicon-th-list line-tooltip'></span>"
                              + " represents a list of entries</p>"
                              + "<p><span class='glyphicon glyphicon-minus line-tooltip'></span>"
                              + " represents a list entry</p>"
                              + "<p><span class='glyphicon glyphicon-question-sign line-tooltip'></span>"
                              + " represents a set of alternatives</p>"
                              + "<p><span class='glyphicon glyphicon-ok-sign line-tooltip'></span>"
                              + " represents a chosen alternative</p>";
                    element.addClass("root-tooltip");
                    element.attr("data-placement", "bottom");
                }

                if (scope.insideModal) {
                    element.attr("data-container", ".ngdialog-content");
                } else {
                    element.attr("data-container", "body");
                }

                element.attr("title", wrappedInformation);

                $timeout(function() {
                    element.tooltip();
                });

                scope.$on("$destroy", function() {
                    element.tooltip("hide");
                });
            }
        };
    }
]);
