"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.LocationService
* @description
* # LocationService - Serviço que chama o REST para busca de locations
* Factory in the nms.manageDevice.
*/
var app = angular.module("nms.manageDevices");
app.factory("LocationService", ["$rootScope", "LocationRestService", "ngDialog",
    function($rootScope, LocationRestService, ngDialog) {
        const service: any = {};

        service.openLocationModal = function(globalConfig, devices, resolveWhenConfirmed, resolveWhenCanceled) {
            ngDialog.openConfirm({
                template: "templates/features/manage-devices/modals/location-modal.html",
                controller: "DeviceLocationController",
                className: "small-modal",
                resolve: {
                    globalLocation: function() {
                        return globalConfig.location;
                    },
                    selectedDevices: function() {
                        return devices;
                    }
                }
            }).then(resolveWhenConfirmed).catch(resolveWhenCanceled);
        };

        service.getAllLocations = function(httpConfig) {
            return LocationRestService.getAllLocations(httpConfig);
        };

        return service;
    }
]);
