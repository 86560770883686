"use strict";

/**
* @ngdoc directive
* @name nms.directive:dropdown
* @description Diretiva para criar um dropdown a partir de um array de itens contendo uma action que deve ser chamada no click.
* Parâmetros:
* {shortcuts}           - Mandatório    -   Lista de atalhos.
* {parameters}          - Mandatório    -   Parâmetros a serem utilizados nos eventos de click do atalho.
* {dropdownTitle}       - Opcional      -   Define um título a ser apresentado na lista de atalhos. Por padrão o título é
*                                           apresentado dentro de um elemento <h3>.
* {useTitleAsPlainText} - Opcional      -   Apresenta o título do dropdown dentro de um elemento <span>.
* {disabledShortcutTooltip} Opcional     -   Apresenta um tooltip quando o atalho estiver desabilitado.
*/
angular.module("nms")
.directive("dropdown", ["$window",
    function($window) {
        return {
            restrict: "E",
            templateUrl: "templates/components/ui/dropdown/dropdown.html",
            scope: {
                shortcuts: "=",
                parameters: "=",
                dropdownTitle: "=",
                useTitleAsPlainText: "=?",
                disabledShortcutTooltip: "@?"
            },
            /*
            * Utilizado para passagem de título clicável seguindo o comportamento dos demais
            * elementos de dropbox ocorrido no clique no ícone "triangle-bottom".
            */
            transclude: true,
            link: function(scope, element, attrs) {
                /**
                * Verifica se a largura do dropdown ultrapassa o limite da tela.
                * Caso isso aconteça, a lista de dropdown aparece a direita do elemento.
                *
                * @param {Element} dropdown Elemento de dropdown.
                * @param {int} windowWidth Largura da tela.
                */
                var changeDropdownSide = function(dropdown, windowWidth) {
                    var dropdownOffsetLeft = element.offset().left;
                    var dropdownWidth = dropdown.width();

                    if (dropdownOffsetLeft + dropdownWidth > windowWidth) {
                        dropdown.addClass("dropdown-menu-right");
                    } else {
                        dropdown.removeClass("dropdown-menu-right");
                    }
                };

                /**
                * Realiza o cálculo para definir qual é altura máxima da lista de dropdown
                * baseada na altura da tela.
                *
                * @param {Element} dropdown Elemento de dropdown.
                * @param {int} windowHeight Altura da tela.
                */
                var setScrollOnDropdown = function(dropdown, windowHeight) {
                    var paddingBottom = 5;
                    var dropdownOffsetTop = element.offset().top;
                    var dropdownSymbolHeight = element.height();
                    var maxHeight = windowHeight - dropdownOffsetTop - dropdownSymbolHeight - paddingBottom;

                    dropdown.css("max-height", maxHeight + "px");
                };

                var setScrollAndSideOnDropdown = function() {
                    var windowWidth = $window.innerWidth;
                    var windowHeight = $window.innerHeight;
                    var dropdown = element.find(".dropdown-menu");

                    changeDropdownSide(dropdown, windowWidth);
                    setScrollOnDropdown(dropdown, windowHeight);
                };

                element.on("click", function(event) {
                    setScrollAndSideOnDropdown();
                    event.stopPropagation();
                });

                scope.closeDropdown = function(event) {
                    scope.isOpenDropdown = false;
                    event.stopPropagation();
                };

                /**
                * Verificar se o atalho é visível ou não. O valor default é true, caso não exista uma função de verificação de
                * visibilidade.
                */
                scope.isShortcutVisible = function(shortcut) {
                    return shortcut.hasOwnProperty("isVisible") ? shortcut.isVisible(scope.parameters) : true;
                };

                /**
                * Verifica se o atalho é desabilitado ou não. Neste caso será representado o elemento em ghost não sendo possível
                * realizar o click no atalho. O valor default é false.
                */
                scope.isShortcutDisabled = (shortcut) => {
                    return shortcut.hasOwnProperty("isShortcutDisabled") ? shortcut.isShortcutDisabled(scope.parameters) : false;
                }

                scope.isShortcutsDisabled = function() {
                    return !scope.shortcuts;
                };

            }
        };
    }
]);
