import { AcsCredentialsModalComponent } from "./../acs-credentials-modal/acs-credentials-modal.component";
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from "rxjs/webSocket";
import { SimpleModalComponent } from "ngx-simple-modal";
import { ModalFactory } from "@nms-ng2/app/shared/services/modal/modal.factory";
import {
    ANGULARJS_ROOTSCOPE,
    ANGULARJS_TRANSLATE,
    CONNECTIVITY_TEST_STATUS
} from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";
import { prettifyJSONString } from "app/shared/utils/json-prettify/json-prettify";
import {
    NmsTableConfig,
    NmsTableColumn,
    NmsTableColumnType,
    NmsTableActions,
    Protocol,
    ConnectivityTestResponse,
    AsyncUpdater,
    DropdownMenu,
    NmsTableRow
} from "@nms-ng1/components/ui/nms-table-old/nms-table-models";
import { CwmpConnectivityTestModalService } from "./cwmp-connectivity-test-modal.service";
import {
    CpeErrorResponseHandlerService,
} from "@nms-ng2/app/modules/device/cpe-error-response-handler.service";
import { PermissionsActionsService } from "@nms-ng2/app/shared/services/actions/permissions-actions.service";
import { ErrorDescriptionDetails } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";

/** Modelo utilizado na tabela. */
interface Cwmp extends NmsTableRow {
    serialNumber: string;
    equipmentId: string;
    cwmpCpeIp: string;
    percentage: Number;
    icmp: Protocol;
    tr069: Protocol;
}

export interface CwmpConnectivityTestModel {
    cwmps: Array<Cwmp>;
}

const IDENTIFIER = "serialNumber";
const PROTOCOLS = ["icmp", "tr069"];
const CONNECTIVIY_SUCCESS_KEY = "modals.cwmp.connectivityTest.information.status.details.ok";
const WEB_SOCKET_TIMEOUT_CODE = 1001;
const WEB_SOCKET_AUTHENTICATION_FAILED_CODE = 1006;

/**
 * Componente responsável pela modal de teste de conectividade de CWMPs.
 */
@Component({
    selector: "cwmp-connectivity-test",
    templateUrl: "./cwmp-connectivity-test-modal.component.html",
    styleUrls: ["./cwmp-connectivity-test-modal.component.scss"]
})
export class CwmpConnectivityTestModalComponent
    extends SimpleModalComponent<CwmpConnectivityTestModel, null>
    implements CwmpConnectivityTestModel, OnInit, OnDestroy
{
    private websocketClientId: string;
    private statusLabel: any;
    cwmps: Array<Cwmp>;
    testConnectivityWebSocket: WebSocketSubject<ConnectivityTestResponse>;
    columns: Array<NmsTableColumn>;
    tableActions: Array<NmsTableActions>;
    config: NmsTableConfig;
    webSocketUri: string;
    asyncUpdater: AsyncUpdater;
    progressBarPercentage: BehaviorSubject<number>;
    dropdownMenus: Array<DropdownMenu>;
    webSocketFactory: any;

    constructor(
        private window: Window,
        private service: CwmpConnectivityTestModalService,
        @Inject(ANGULARJS_TRANSLATE) private translate: any,
        @Inject(CONNECTIVITY_TEST_STATUS) private connectivityTestStatus: any,
        private modalFactory: ModalFactory,
        private errorResponseHandlerService: CpeErrorResponseHandlerService,
        @Inject(ANGULARJS_ROOTSCOPE) private readonly rootScope: any,
        private readonly permissionsActionsService: PermissionsActionsService
    ) {
        super();
        this.progressBarPercentage = new BehaviorSubject(0);
        this.webSocketFactory = webSocket;

        this.asyncUpdater = {
            singleRow: {
                responseObservable: new Subject(),
                cleanPreviousValues: (rows: Array<Cwmp>) => {
                    rows.forEach((row) => {
                        let cwmp = this.cwmps.find((cwmp) => cwmp.serialNumber === row.serialNumber);
                        cwmp.percentage = 0;

                        PROTOCOLS.forEach((protocol) => {
                            cwmp[protocol].status = this.connectivityTestStatus.RUNNING;
                            cwmp[protocol].details = "";
                            cwmp[protocol + "Translated"] = this.statusLabel[row[protocol].status];
                        });
                    });
                },
                runAsyncUpdater: (rows: Array<Cwmp>) => {
                    this.performTests(rows);
                }
            }
        };

        this.columns = [
            {
                field: "serialNumber",
                width: "125",
                type: NmsTableColumnType.DROPDOWN_MENU
            },
            { title: "TR-069 Mgmt IP", field: "cwmpCpeIp", width: "145" },
            { field: "equipmentId", width: "139" },
            {
                field: "concludedProtocols",
                type: NmsTableColumnType.PROGRESS,
                width: "175"
            },
            {
                title: "Ping",
                field: "icmp",
                type: NmsTableColumnType.CONNECTIVITY_TEST,
                width: "90",
                filter: { icmpTranslated: "text" }
            },
            {
                title: "TR-069 Connection Request",
                field: "tr069",
                type: NmsTableColumnType.CONNECTIVITY_TEST,
                width: "209",
                filter: { tr069Translated: "text" }
            }
        ];

        this.tableActions = [
            {
                id: "testConnectivityUpdateLink",
                translateKey: "connectivityTest.actions.update",
                action: (selectedCwmps: Array<Cwmp>) => this.performTests(selectedCwmps)
            },
            {
                id: "testConnectivityUpdateAllLink",
                translateKey: "connectivityTest.actions.updateAll",
                performActionWithAllData: true,
                action: (selectedCwmps: Array<Cwmp>) => this.performTests(selectedCwmps)
            },
            {
                id: "testConnectivityRemoveLink",
                translateKey: "connectivityTest.actions.remove",
                action: (selectedCwmps: Array<Cwmp>) => {
                    selectedCwmps.forEach((cwmp) => {
                        let index = this.cwmps.indexOf(cwmp);
                        this.cwmps.splice(index, 1);
                    });
                }
            }
        ];

        this.config = {
            hideCustomizedTableComponents: true,
            tableId: "cwmp-connectivity-test-table",
            initialSort: { serialNumber: "asc" },
            tableTranslatePrefix: "modals.cwmp.connectivityTest.tablecolumn",
            selectionId: "cwmp-connectivity-test-selector",
            progressbarInformationTranslateKey: "modals.cwmp.connectivityTest.information.progressbar",
            rowId: "serialNumber"
        };

        this.dropdownMenus = [
            {
                field: "serialNumber",
                shortcuts: [
                    {
                        label: translate.instant("gpon.cpes.tableActions.tr069.credentials"),
                        action: (cwmp: Cwmp) => {
                            if (this.permissionsActionsService.isUserAdministrator()) {
                                this.modalFactory.openAsyncModal(AcsCredentialsModalComponent, {
                                    cpesSelected: [{ serialNumber: cwmp.serialNumber }]
                                });
                            }
                        }
                    }
                ]
            }
        ];

        this.websocketClientId = new Date().getTime().toString();
        this.webSocketUri = `wss://${this.window.location.host}/test-connectivity/${this.websocketClientId}`;
    }

    private performTests = (cwmps) => {
        let serialNumbers: Array<string> = cwmps.map((cwmp) => cwmp.serialNumber);
        this.service.performTests(this.websocketClientId, serialNumbers);
    };

    /**
     * Processa a mensagem recebida via websocket e atualiza os status de processamento.
     */
    private buildCwmpToUpdate = (websocketResponse: ConnectivityTestResponse) => {
        let { hostname, details, status, errorResponse } = websocketResponse;
        let protocol = websocketResponse.protocol.toLowerCase();
        let cwmp = this.cwmps.find((row) => row.serialNumber == hostname);

        cwmp[protocol] = { status, details };
        cwmp[protocol + "Translated"] = this.statusLabel[cwmp[protocol].status];

        if (errorResponse) {
            let errorDescriptionDetails: ErrorDescriptionDetails =
                this.errorResponseHandlerService.buildErrorDescriptionDetails(errorResponse);
            cwmp[protocol].details = errorDescriptionDetails.details;
            cwmp[protocol + "Translated"] = errorDescriptionDetails.description;
        }

        if (status == this.connectivityTestStatus.OK) {
            cwmp[protocol].detailsLabelKey = CONNECTIVIY_SUCCESS_KEY;
            cwmp[protocol].details = prettifyJSONString(details, true);
        }

        let completedProtocols = _.sum(PROTOCOLS, (p: number) => cwmp[p].status !== this.connectivityTestStatus.RUNNING);
        cwmp.percentage = (completedProtocols / PROTOCOLS.length) * 100;

        return cwmp;
    };

    private buildConnectivityStatusLabelTranslate = () => {
        return Object.keys(this.connectivityTestStatus).reduce((statusLabel, status) => {
            let translated = this.translate.instant("connectivityTest.protocolstatus." + status);
            return Object.assign(statusLabel, { [status]: translated });
        }, {});
    };

    /**
     * Se inscreve no websocket de teste de conectividade a fim de receber as mensagens
     * vindas do servidor.
     */
    private websocketSubscriber = async () => {
        this.testConnectivityWebSocket.subscribe((response) => {
            let cwmp = this.buildCwmpToUpdate(response);
            this.asyncUpdater.singleRow.responseObservable.next({
                identifier: IDENTIFIER,
                data: cwmp
            });
        });
    };

    private initializeWebsocket = () => {
        var shouldRequestTests = true;
        let openFunction = (openEvent: Event) => {
            if (shouldRequestTests) {
                this.performTests(this.cwmps);
                shouldRequestTests = false;
            }
        };

        let closeFunction = (closeEvent: CloseEvent) => {
            /*
             * Verifica se o motivo do encerramento do websocket se refere a timeout,
             * neste caso, se registra novamente para seguir ouvindo as mensagens.
             */
            if (closeEvent.code === WEB_SOCKET_TIMEOUT_CODE) {
                this.websocketSubscriber();
            }
            /**
             * Quando ocorre algum erro "anormal" na conexão do webscoket, o erro com o código 1006
             * é retornado. Esse erro indica que houve problemas tais como autenticação, autorização
             * incorreta ou uso de protocolo incorreto
             */
            if (closeEvent.code === WEB_SOCKET_AUTHENTICATION_FAILED_CODE) {
                this.close();
                this.rootScope.prepareToNewLogin(true);
            }
        };

        let config: WebSocketSubjectConfig<ConnectivityTestResponse> = {
            url: this.webSocketUri,
            closeObserver: {
                next(closeEvent: CloseEvent) {
                    closeFunction(closeEvent);
                }
            },
            openObserver: {
                next(openEvent: Event) {
                    openFunction(openEvent);
                }
            }
        };

        this.testConnectivityWebSocket = this.webSocketFactory(config);
        this.websocketSubscriber();
    };

    ngOnInit() {
        this.statusLabel = this.buildConnectivityStatusLabelTranslate();
        this.initializeWebsocket();
    }

    ngOnDestroy() {
        /**
         * Encerra o websocket ao finalizar a modal.
         * Testa forma o código de encerramento será diferente do código atrelado
         * a timeout, fazendo com que o novo subscribe não seja realizado.
         */
        this.testConnectivityWebSocket.unsubscribe();
    }
}
