"use strict";

var app = angular.module("nms.management");
app.service("ManagePermissionsDevicesService", ["ManagePermissionsDevicesRestService", "DEVICE_PERMISSION", "$translate",
    function(ManagePermissionsDevicesRestService, DEVICE_PERMISSION, $translate) {
        const service: any = {};

        var deviceTranslatePreffix = "manage.permissions.devices.permission.level.";

        service.translatePermission = function(permissionValue) {
            var permission = _.find(DEVICE_PERMISSION, {databaseValue: parseInt(permissionValue)});

            return $translate.instant(deviceTranslatePreffix + permission.key);
        };

        /**
         * Processa a resposta da requisição de modelos de devices vendorizados para adequação ao modelo esperado pelo
         * @see nms.management.ManagePermissionsDevicesController, incluindo a opção default de permissão para todos os devices
         * que não tiverem permissões pré definidas.
         *
         * @param {object[]} permissões de devices previamente definidas.
         * @returns {Object[]} permissões de device processadas.
         */
        service.getDevicePermissions = function(devicePermissions) {
            return ManagePermissionsDevicesRestService.getAllStatic().then(function(response) {
                return _.map(response.plain(), function(device) {
                    var foundPermission = _.find(devicePermissions, function(devicePermission) {
                        return device.vendorCode === devicePermission.vendorCode
                            && device.modelCode === devicePermission.modelCode;
                    });
                    var permissionValue = foundPermission ? foundPermission.permission : DEVICE_PERMISSION.MANAGE.databaseValue;

                    device.permission = {
                        value: permissionValue
                    };

                    device.translatedPermission = service.translatePermission(device.permission.value);

                    return device;
                });
            });
        };

        return service;
    }
]);
