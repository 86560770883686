"use strict";

var app = angular.module("nms");

app.factory("DeviceAugmentService", ["STATUS_KEYS", "$translate", "$filter", "DeviceCommonService",
    function(STATUS_KEYS, $translate, $filter, DeviceCommonService) {
        const service: any = {};

        /**
        * Propriedades default do device compartilhadas pelas diversas páginas onde pode ser utilizado.
        * Serve como modelo (definido de maneira flat pois proporciona melhor perfomance da tela manage-devices)
        * e utilizado para setar o valor default das propriedades evitando que sejam null ou undefined, pois
        * geram resultados indevidos nas buscas da tabela.
        */
        var deviceDefaults: any = {
            id: "",
            dmOs: "",
            totalOnusLabel: "",
            path: "",
            globalConfig: true,
            name: "",
            availableFeatures: "",
            devNo: "",
            devLocalId: "",
            locationId: "",
            hostname: "",
            status: "",
            serialNumber: "",
            model: "",
            modelCode: "",
            vendorCode: "",
            fwVersion: "",
            cpuUsage: "",
            cpuUsageAbsolute: "",
            memoryUsage: "",
            memoryUsageAbsolute: "",
            cpuLink: "",
            memoryLink: "",
            translatedStatus: "",
            lastSuccessfulPollingFinish: "",
            lastSuccessfulPollingDuration: null,
            lastUpdate: "",
            shortcuts: "",
            accessData: null,
            properties: null
        };

        service.resolveDevice = function(device) {
            var newDevice: any = {};
            newDevice.id = device.id;
            newDevice.dmOs = device.isDmOs;
            newDevice.globalConfig = _.get(device, "globalConfig", true);
            newDevice.name = device.name;
            newDevice.availableFeatures = device.availableFeatures;
            newDevice.proxyCode = device.proxyCode;
            newDevice.devNo = device.devNo;
            newDevice.devLocalId = device.devLocalId;
            newDevice.hostname = device.hostname;
            newDevice.formattedHostname = getDeviceName(device);
            newDevice.name = device.name;
            newDevice.status = device.status;
            newDevice.serialNumber = device.serialNumber;
            newDevice.model = device.model;
            newDevice.modelCode = device.modelCode;
            newDevice.locationId = device.locationId;
            newDevice.vendorCode = device.vendorCode;
            newDevice.fwVersion = device.fwVersion === "0" ? "" : device.fwVersion;
            newDevice.cpuUsage = device.equipmentResources.cpuUsage;
            newDevice.cpuUsageAbsolute = getValueAsIntegerOrEmpty(device.equipmentResources.cpuUsage);
            newDevice.memoryUsage = device.equipmentResources.memoryUsage;
            newDevice.memoryUsageAbsolute = getValueAsIntegerOrEmpty(device.equipmentResources.memoryUsage);
            newDevice.cpuLink = _.get(device, "equipmentResources.cpuLink", "");
            newDevice.memoryLink = _.get(device, "equipmentResources.memoryLink", "");
            newDevice.translatedStatus = $translate.instant(STATUS_KEYS[device.status] || device.status);
            newDevice.lastSuccessfulPollingFinish = device.lastSuccessfulPollingFinish;
            newDevice.lastSuccessfulPollingDuration = device.lastSuccessfulPollingDuration;
            newDevice.lastUpdate = device.lastSuccessfulPollingFinish
                ? $filter("dateFormat")(device.lastSuccessfulPollingFinish) : "";
            newDevice.shortcuts = device.shortcuts;
            newDevice.accessData = device.accessData;
            newDevice.properties = {
                notes: device.notes,
                project: device.project,
                internalLocation: device.internalLocation,
                externalLocation: device.externalLocation
            };

            var defaults = _.partialRight(_.assign, function(value, other) {
                return _.isUndefined(value) || _.isNull(value) ? other : value;
            });

            defaults(newDevice, deviceDefaults);

            return newDevice;
        };

        function getValueAsIntegerOrEmpty(value) {
            if (value) {
                return parseInt(value);
            }

            return "";
        }

        function getDeviceName(device) {
            return DeviceCommonService.getDeviceIpHostnameLabel(device);
        }

        return service;
    }
]);
