import { Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DownloadNotificationService } from "./download-notification.service";
import { TransferCompleteWebsocketService } from "./transfer-complete-websocket.service";
import { ErrorResponse } from "@nms-ng2/app/core/services/error-response-handler/error-response-handler.model";

export interface DownloadOperationResponse {
    downloadStatus: string;
    commandKey: string;
    serialNumber: string;
    errorResponse: ErrorResponse;
}

export interface DownloadRequestParameters {
    serialNumber: string;
    fileType: number;
    fileUrl: string;
}

/**
 * Classe responsável por controlar as requisições de download.
 */
@Injectable({
    providedIn: "root"
})
export class DownloadService {

    constructor(private http: HttpClient,
        private downloadNotificationService: DownloadNotificationService,
        private transferCompleteWebsocketService: TransferCompleteWebsocketService) {}

    public sendDownloads(fileUrl: string, fileType: number, serialNumbers: string[]): void {
        this.sendDownloadRequest(fileUrl, fileType, serialNumbers);
        this.downloadNotificationService.showDownloadRequestNotification(serialNumbers.length);
    }

    private sendDownloadRequest(fileUrl: string, fileType: number, serialNumbers: string[]): void {
        let downloadRequests: DownloadRequestParameters[] = serialNumbers.map((serialNumber) => {
            return {serialNumber, fileType, fileUrl};
        });
        this.transferCompleteWebsocketService.initWebsocket(downloadRequests);

        this.http.post<DownloadOperationResponse[]>("/acs-client/api/download", downloadRequests).subscribe(
            (downloadOperationResponses) => {
                this.downloadNotificationService.showDownloadResponsesNotification(downloadOperationResponses);
                this.transferCompleteWebsocketService.setDownloadOperationResponse(downloadOperationResponses);
            }
        );
    }

}
