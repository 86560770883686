var app = angular.module("nms");

app.service("DevicePermissionService", ["$rootScope", "AuthenticationService", "DEVICE_PERMISSION",
    function($rootScope, authenticationService, DEVICE_PERMISSION) {
        /**
         * A partir de uma lista de permissões, retorna aquela que for aceita pelo predicado passado.
         * O valor retornado é convertido para o formato do objeto "DEVICE_PERMISSION".
         *
         * Quando não há nenhuma permissão para o para o device, ou location, a permissão MANAGE é retornada
         * (Esse é o valor default usado pelo DmView, as permissões MANAGE não vão para o banco).
         */
        var getPermission = function(permissions, predicate) {
            var permission = _.find(permissions, predicate);

            return permission ? convertPermission(permission) : DEVICE_PERMISSION.MANAGE;
        };

        var convertPermission = function(servicePermission) {
            return _.find(DEVICE_PERMISSION, function(permission) {
                return permission.databaseValue === servicePermission.level;
            });
        };

        var getModelCodePredicate = function(device) {
            return function(devicePermission) {
                return (device.vendorCode === devicePermission.vendorCode) && (device.modelCode === devicePermission.modelCode);
            };
        };

        var getLocationPredicate = function(device) {
            return function(locationPermission) {
                return (device.locationId === locationPermission.locationId);
            };
        };

        var verifyPermission = function(device, hasPermissionAction, readOnlyAction, noPermissionAction) {
            return function(response) {
                var modelCodePermission = getPermission(response.devicePermissions, getModelCodePredicate(device));
                var locationPermission = getPermission(response.locationPermissions, getLocationPredicate(device));
                var permission = _.min([modelCodePermission, locationPermission], function(permission) {
                    return permission.order;
                });

                if (permission === DEVICE_PERMISSION.MANAGE) {
                    hasPermissionAction();
                } else if (permission === DEVICE_PERMISSION.NOTHING) {
                    noPermissionAction();
                } else {
                    readOnlyAction();
                }
            };
        };

        this.checkPermissions = function(device, hasPermissionAction, readOnlyAction, noPermissionAction) {
            authenticationService.getCredentials().then(
                    verifyPermission(device, hasPermissionAction, readOnlyAction, noPermissionAction),
                    noPermissionAction);
        };
    }
]);
