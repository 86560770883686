"use strict";

var app = angular.module("nms");

app.directive("pageTitle", ["$state", "$translate",
    function($state, $translate) {
        return {
            restrict: "E",
            replace: true,
            scope: {
                subtitle: "=",
                device: "="
            },
            template: function(scope, elem) {
                var templateHtml = "<h3>" + $translate.instant($state.current.data.pageTitle);

                if (elem.subtitle) {
                    templateHtml += " - " + $translate.instant(elem.subtitle);
                }
                if (elem.device) {
                    templateHtml += " - {{ device }}";
                }
                templateHtml += "</h3>";

                return templateHtml;
            }
        };
    }
]);