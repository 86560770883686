import { Injectable, Inject } from "@angular/core";
import { ANGULARJS_ROOTSCOPE, DEVICE_PERMISSION_SERVICE, STATE, NMS_STATES, TEMPLATE_INSTANCE_VALIDATOR_SERVICE, GPON_ONU_SERVICE } from "../upgraded-provider/upgraded-providers";
import { PermissionsActionsService } from "./permissions-actions.service";

/**
 * Serviço que engloba ações relacionadas a Devices, relacionadas a atalhos de dropdown.
 * Exemplos: abertura da página de Summary, Info/Config e GPON/ONUs.
 */
@Injectable({
    providedIn: "root"
})
export class DevicesActionsService {
    constructor(
        @Inject(ANGULARJS_ROOTSCOPE) private readonly rootScope: any,
        @Inject(DEVICE_PERMISSION_SERVICE) private readonly devicePermissionService: any,
        @Inject(STATE) private readonly state: any,
        @Inject(NMS_STATES) private readonly nmsStates: any,
        @Inject(TEMPLATE_INSTANCE_VALIDATOR_SERVICE) private readonly templateInstanceValidatorService,
        @Inject(GPON_ONU_SERVICE) private readonly gponOnuService: any,
        private readonly permissionsActionsService: PermissionsActionsService
    ) {}

    areThereSelectedDevices(checked, translateKey?) {
        let haveSelectedDevices = true;
        if (_.isEmpty(checked)) {
            this.rootScope.showDialog({
                translateKey: translateKey || "findAddDevice.error.noSelectedDevices"
            });
            haveSelectedDevices = false;
        }
        return haveSelectedDevices;
    }

    /**
     * Verifica se há suporte para equipamentos que permitam aplicações de template, caso negativo exibirá uma mensagem
     * informando ao usuário que este modelo não é suportado.
     * @param devices
     * @returns true ou false
     */
    hasSupportForTemplateApplication(devices): boolean {
        const notSupportedDevices = this.templateInstanceValidatorService.getNotSupportedDevicesCLITemplateApplications(devices);
        if (notSupportedDevices.length > 0) {
            const hasNoSupportedDevicesSelected = notSupportedDevices.length !== devices.length;
            const params = notSupportedDevices.map(device => device.hostname).join(", ");
            this.removeNoSupportedDevices(devices, notSupportedDevices);
            this.rootScope.showDialog({
                type: "warning",
                translateKey: this.getAlertMessage(hasNoSupportedDevicesSelected),
                params: [params],
                paramsInsideMessage: true
            });
            return false;
        }

        return true;
    }

    tryToOpenDeviceSummary(device, globalConfig) {
        const permissionActionCallback = () => {
            this.state.go(this.nmsStates.deviceSummary, { device, name: device.name, globalConfig });
        };

        this.devicePermissionService.checkPermissions(
            device,
            permissionActionCallback,
            permissionActionCallback,
            this.permissionsActionsService.noUserPermission
        );
    }

    tryToOpenDeviceConfig(device) {
        const permissionAction = () => {
            this.state.go(this.nmsStates.dynamicDevice, { name: device.name });
        };

        this.devicePermissionService.checkPermissions(
            device,
            permissionAction,
            permissionAction,
            this.permissionsActionsService.noUserPermission
        );
    }

    tryToOpenDeviceAlarms(device, linkLabel) {
        const permissionAction = () => {
            this.goToDeviceAlarms(device, linkLabel);
        };

        this.devicePermissionService.checkPermissions(
            device,
            permissionAction,
            permissionAction,
            this.permissionsActionsService.noUserPermission
        );
    }

    tryToOpenDeviceGponOnus(globalConfig, device, filters) {
        const permissionAction = () => {
            this.goToDeviceGponOnus(globalConfig, device, filters);
        };

        this.devicePermissionService.checkPermissions(
            device,
            permissionAction,
            permissionAction,
            this.permissionsActionsService.noUserPermission
        );
    }

    goToDeviceConfig(device) {
        this.state.go(this.nmsStates.dynamicDevice, {
            device,
            name: device.name
        });
    }

    goToDeviceSummary(device, globalConfig) {
        this.state.go(this.nmsStates.deviceSummary, {
            device,
            name: device.name,
            globalConfig
        });
    }

    goToDeviceAlarms(device, displayName) {
        const params: any = {
            displayName,
            path: "$.containers.alarm"
        };

        this.goToDeviceConfigWithParams(device.name, params);
    }

    goToDeviceConfigWithParams(name, params) {
        this.state.go(this.nmsStates.dynamicDevice, {
            name,
            fullPath: params.path,
            pathKeys: params.pathKeys,
            displayName: params.displayName
        });
    }

    private goToDeviceGponOnus(globalConfig, device, filters) {
        this.state.go(this.gponOnuService.getGponOnuUISref(), {
            device,
            name: device.name,
            globalConfig,
            filters
        });
    }

    /**
     * Desmarca os equipamentos que não suportam aplicações de template da listagem
     * de equipamentos selecionados.
     * @param devices
     * @param devicesNotSupported
     */

    private removeNoSupportedDevices(devices, devicesNotSupported) {
        devicesNotSupported.forEach(device => {
            const index = devices.indexOf(device);
            if (index !== -1) {
                devices.splice(index, 1);
            }
        });
    }

    private getAlertMessage(showDevicesNotSupported): string {
        return showDevicesNotSupported ?
            "devicelist.tableActions.applyTemplateOrViewTemplateApplication.unsupportedModel.names" :
            "devicelist.tableActions.applyTemplateOrViewTemplateApplication.unsupportedModel";
    }
}
