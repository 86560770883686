/**
 * Modelo que representa o caminho dos parâmetros.
 */
export class CwmpParameterPathModel {
    path: string;
    includeNextLevel: boolean;

    constructor(path: string, includeNextLevel: boolean) {
        /** Caminho a ser recuperado */
        this.path = path;
        /** Indica se será recuperado apenas o próximo nível */
        this.includeNextLevel = includeNextLevel;
    }
}
