"use strict";

/**
 * Filtro para exibir um array de números agrupados em ranges.
 *
 * Exemplo: [1,2,3,5,6,7,10] -> "1-3, 5-7, 10".
 *
 * @author Rafael Senna
 */
var app = angular.module("nms");

app.filter("numericRangeFormatter",
    function() {
        var groupByRange = function(numbersArray) {
            numbersArray = numbersArray.map(Number);
            numbersArray = _.uniq(_.sortBy(numbersArray, function(a, b) {
                return a - b;
            }), true);

            var ranges = [];
            var currentRange: any = {};

            _.forEach(numbersArray, function(number, index) {
                if (_.isEmpty(currentRange)) {
                    currentRange.first = number;
                }

                if (number + 1 !== numbersArray[index + 1]) {
                    currentRange.last = number;
                    ranges.push(currentRange);

                    currentRange = {};
                }
            });

            return ranges;
        };

        var formatRanges = function(ranges) {
            var formattedRanges = [];

            _.forEach(ranges, function(range) {
                if (range.first !== range.last) {
                    formattedRanges.push(range.first + "-" + range.last);
                } else {
                    formattedRanges.push(range.first);
                }
            });

            return formattedRanges;
        };

        return function(numbersArray) {
            if (_.isEmpty(numbersArray)) {
                return "";
            }

            var ranges = groupByRange(numbersArray);
            var rangesStrings = formatRanges(ranges);

            return rangesStrings.join(", ");
        };
    }
);
