import { PagingFilterType } from "@nms-angular-toolkit/nms-table";
import { FieldMapping, FilterTypeMapping, GponOnusField } from "./gpon-onus.models";

/**
 * Mapa entre as strings usadas para comparação e as funções respectivas.
 */
export const FILTER_TYPE_BY_SYMBOL: FilterTypeMapping = {
    "=":  (value) => PagingFilterType.EQUALS,
    "!":  (value) => PagingFilterType.NOT_EQUALS,
    ">":  (value) => PagingFilterType.GREATER_THAN,
    "<":  (value) => PagingFilterType.LESS_THAN,
    ">=": (value) => PagingFilterType.GREATER_OR_EQUALS,
    "<=": (value) => PagingFilterType.LESS_OR_EQUALS,
    "!!": (value) => PagingFilterType.NOT_CONTAINS,
    "%":  (value) => {
        if (value.startsWith("%")) return PagingFilterType.ENDS_WITH;
        if (value.endsWith("%")) return PagingFilterType.STARTS_WITH;
    },
    "''": (value) => PagingFilterType.CONTAINS
}

/**
 * Mapa entre @see GponOnusField e os respectivos campos paralelos ocultos que contém a URL do Zabbix.
 */
export const FIELD_URL_MAP: FieldMapping<string> = {
    [GponOnusField.RxPower]:     "rxPowerUrl",
    [GponOnusField.TxPower]:     "txPowerUrl",
    [GponOnusField.Eth1InRate]:  "eth1InRateUrl",
    [GponOnusField.Eth1OutRate]: "eth1OutRateUrl",
    [GponOnusField.Eth2InRate]:  "eth2InRateUrl",
    [GponOnusField.Eth2OutRate]: "eth2OutRateUrl",
    [GponOnusField.Eth3InRate]:  "eth3InRateUrl",
    [GponOnusField.Eth3OutRate]: "eth3OutRateUrl",
    [GponOnusField.Eth4InRate]:  "eth4InRateUrl",
    [GponOnusField.Eth4OutRate]: "eth4OutRateUrl"
}
