/**
 * Restrict value length.
 *
 * @author icaro.damiani
 */
var app = angular.module("nms.dynamicDevice");

/**
 * Verifies if a leaf value has a valid length
 */
app.service("LengthRestrictionService", ["LeafService",
    function(LeafService) {
        this.RESTRICTION_NAME = "length";

        this.validate = function(modelValue, viewValue, type) {
            var value = modelValue || viewValue;

            if (!value || value === "") {
                return true;
            }

            var valueLength = value.length;
            var lengths = LeafService.getLengths(type);

            if (!lengths) {
                return true;
            }

            var isAcceptableLength = function(length) {
                var start = parseFloat(length.split("..")[0]);
                var end = parseFloat(length.split("..")[1]);

                return (valueLength >= start && valueLength <= end);
            };

            return _.some(lengths, isAcceptableLength);
        };
    }
]);
