"use strict";

/**
* Service para criação das configurações necessárias para apresentação de warnings do Zabbix.
*
* TODO - A fim de evoluir o componente de warning, avaliar se a implementação contida em
* https://medium.com/opinionated-angularjs/angular-model-objects-with-javascript-classes-2e6a067c73bc traria benefícios
* significativos. Análise/implementação não feita devido a tempo excedido para a tarefa.
*/
var app = angular.module("nms");

app.service("ZabbixMonitoringService", [
    function() {
        const service: any = {};

        var zabbixWarningMessages: any = {
            NOT_MONITORED_DUE_MONITORING_SERVER_CONNECTION_ERROR:
                "zabbix.monitoring.warning.NOT_MONITORED_DUE_MONITORING_SERVER_CONNECTION_ERROR",
            NOT_MONITORED_DUE_HOST_ABSENCE_IN_MONITORING_SERVER:
                "zabbix.monitoring.warning.NOT_MONITORED_DUE_HOST_ABSENCE_IN_MONITORING_SERVER",
            NOT_MONITORED_DUE_MISCONFIGURATION:
                "zabbix.monitoring.warning.NOT_MONITORED_DUE_MISCONFIGURATION",
            NOT_MONITORED_DUE_UNKNOWN_ERROR: "zabbix.monitoring.warning.NOT_MONITORED_DUE_UNKNOWN_ERROR",
            PARTIALLY_MONITORED: "zabbix.monitoring.warning.PARTIALLY_MONITORED",
            NOT_MONITORED_DUE_MONITORING_SERVER_INTERNAL_ERROR:
                "zabbix.monitoring.warning.NOT_MONITORED_DUE_MONITORING_SERVER_INTERNAL_ERROR",
            NOT_MONITORED_DUE_DEVICE_SNMP_CONNECTION_ERROR:
                "zabbix.monitoring.warning.NOT_MONITORED_DUE_DEVICE_SNMP_CONNECTION_ERROR"
        };

        service.getSettings = function(zabbixUrl, monitoringStatus) {
            return {
                show: monitoringStatus && monitoringStatus !== "MONITORED"
                        && monitoringStatus !== "NOT_MONITORED",
                header: zabbixWarningMessages[monitoringStatus],
                warnings: [
                    {
                        show: monitoringStatus === "NOT_MONITORED_DUE_MISCONFIGURATION",
                        internalLink: {
                            url: "nms.monitoring",
                            translateKey: "zabbix.monitoring.tipToFix.configureZabbix"
                        }
                    },
                    {
                        show: monitoringStatus === "NOT_MONITORED_DUE_MONITORING_SERVER_CONNECTION_ERROR",
                        internalLink: {
                            url: "nms.monitoring",
                            translateKey: "zabbix.monitoring.tipToFix.testZabbixConnection"
                        },
                        externalLink: {
                            url: zabbixUrl,
                            translateKey: "zabbix.monitoring.tipToFix.tryAccessZabbix"
                        }
                    },
                    {
                        show: monitoringStatus === "NOT_MONITORED_DUE_MONITORING_SERVER_INTERNAL_ERROR"
                            || monitoringStatus === "NOT_MONITORED_DUE_HOST_ABSENCE_IN_MONITORING_SERVER",
                        internalLink: {
                            url: "nms.monitoring",
                            translateKey: "zabbix.monitoring.tipToFix.verifyZabbixOndmview"
                        },
                        externalLink: {
                            url: zabbixUrl,
                            translateKey: "zabbix.monitoring.tipToFix.verifyZabbixConfiguration"
                        }
                    },
                    {
                        show: monitoringStatus === "NOT_MONITORED_DUE_UNKNOWN_ERROR",
                        generalMessage: {
                            location: "top",
                            translateKey: "zabbix.monitoring.tipToFix.contactSupport"
                        }
                    },
                    {
                        show: monitoringStatus === "PARTIALLY_MONITORED",
                        generalMessage: {
                            location: "bottom",
                            translateKey: "zabbix.monitoring.tipToFix.verifyDeviceConfig"
                        },
                        internalLink: {
                            url: "nms.monitoring",
                            translateKey: "zabbix.monitoring.tipToFix.verifyZabbixOndmview"
                        },
                        externalLink: {
                            url: zabbixUrl,
                            translateKey: "zabbix.monitoring.tipToFix.verifyZabbixConfiguration"
                        }
                    },
                    {
                        show: monitoringStatus === "NOT_MONITORED_DUE_DEVICE_SNMP_CONNECTION_ERROR",
                        internalLink: {
                            url: "nms.monitoring",
                            translateKey: "zabbix.monitoring.tipToFix.verifyZabbixOndmview"
                        },
                        externalLink: {
                            url: zabbixUrl,
                            translateKey: "zabbix.monitoring.tipToFix.verifyZabbixConfiguration"
                        },
                        generalMessage: {
                            location: "bottom",
                            translateKey: "zabbix.monitoring.tipToFix.verifyDeviceConfig"
                        }
                    },
                    {
                        show: true,
                        needsAdministratorPermission: false,
                        generalMessage: {
                            location: "top",
                            translateKey: "zabbix.monitoring.tipToFix.contactNmsAdministrator"
                        }
                    }
                ]
            };
        };

        return service;
    }
]);
