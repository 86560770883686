"use strict";

/**
 * Filter which recursively clears empty properties inside a given JSON object.
 *
 * @author icaro.damiani
 */

var app = angular.module("nms");

app.filter("clearEmptyProperties", ["$filter",
    function($filter) {
        return function(json) {
            _.forIn(json, function(value, key) {
                if (_.isEmpty(value)) {
                    angular.isArray(json) ? json.splice(key, 1) : delete json[key];
                } else if (angular.isObject(value)) {
                    $filter("clearEmptyProperties")(value);
                }
            });
        };
    }
]);
