"use strict";

/**
* @ngdoc service
* @name nms.audit.AuditService
* @description
* # AuditService
* Service in the nms.templates.
*/
angular.module("nms.audit")
.factory("AuditService", ["AuditRESTService",
    function(AuditRESTService) {
        const service: any = {};

        service.getPaginated = function(filters) {
            return AuditRESTService.getPaginated(filters);
        };

        service.hasSearchCriteria = function(filters) {
            return !!filters.searchTerm;
        };

        service.getSearchType = function(searchField) {
            if (searchField) {
                return searchField.fieldType;
            } else {
                return "TEXT";
            }
        };

        service.getAuditDetails = function(auditId) {
            return AuditRESTService.getAuditDetails(auditId);
        };

        service.auditExport = function(filters) {
            return AuditRESTService.auditExport(filters);
        };

        service.getTotalTypes = function() {
            return AuditRESTService.getTotalTypes();
        };

        return service;
    }]);
