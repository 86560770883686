import { Component, OnInit, Input, Inject, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { ANGULARJS_TRANSLATE } from "@nms-ng2/app/shared/services/upgraded-provider/upgraded-providers";

export interface SelectionOption {
    /* Identificador único para referenciar o id do checkbox */
    id: string;
    /* Valor do item que será visualizado no checkbox */
    value: string;
    /* Propriedade utilizada para verificar se o item do checkbox está marcado ou desmarcado*/
    isSelected: boolean;
}

export interface SelectionDropdownOptions {
    /* Representa o array com as opções a serem exibidas na listagem dos checkboxes */
    options: SelectionOption[];
    /* Propriedade na qual verifica se todos os itens do checkbox estão selecionados */
    selectedAll: boolean;
    /* Título exibido no dropdow do componente na qual representará:
     * - All - Todos os itens selecionados
     * - None - Nenhum item selecionado
     * - Nome dos itens selecionados
     */
    title?: string;
}

export interface SelectionDropdownOptionsTitle {
    /* Título do dropdown quando não houver elementos no array a ser listado */
    emptyItems?: string;
    /** Título dropdown quando nenhum elemento estiver selecionado */
    checkedNone: string;
    /** Título do dropdown quando todos os elementos estiverem selecionados */
    checkedAll: string;
    /* Título do dropdown quando os elementos forem selecionados */
    selectedItems?: string;
    /** Título do dropdown quando mais de um elemento for selecionado e não couber no botão */
    manyItemsSelected?: string;
}

/**
 * Componente responsável por exibir um dropdown contendo uma lista de items
 * com seus respectivos checkbox a serem marcados/desmarcados, podendo
 * selecionar apenas um ou todos os items.
 */
@Component({
    selector: "selection-dropdown",
    templateUrl: "./selection-dropdown.component.html",
    styleUrls: ["./selection-dropdown.component.scss"]
})
export class SelectionDropdownComponent implements OnInit, OnChanges {
    /* Propriedade na qual receberá o array com os itens do checkbox */
    @Input() items: string[];
    /* Propriedade na qual receberá o array com os itens selecionados */
    @Input() itemsCheckeds?: string[];
    /** Título a ser exibido no componente */
    @Input() titles: SelectionDropdownOptionsTitle;
    /**
     * Define o tamanho máximo do titulo do dropdown.
     * Dependendo da página a ser renderizada, o tamanho do componente poderá ser maior ou menor
     * permitindo ser impresso uma quantidade maior de itens, caso a opção showItemValueSelected seja
     * igual a true, caso não seja informado, seu tamanho estará definido com 36 caracteres.
     */
    @Input() maxSizeSelectedItens? = 36;
    /**
     * Propriedade na qual exibirá os valores selecionados no checkbox caso esteja setada
     * com o valor 'true'.
     * Ex: Ao selecionar 2 itens no checkbox(VAR1, VAR2) o dropdown estará com o valores
     * VAR1 e VAR2.
     * Caso a propriedade esteja com o valor false, o dropdown exibirá '2 itens selecionados',
     * de acordo com o exemplo acima.
     */
    @Input() showItemValueSelected? = false;
    /** Desabilita o componente */
    @Input() disabled?: boolean;
    /*Propriedade utilizada para traduzir os valores a serem listado no checkbox*/
    @Input() useTranslateKeyValue?: boolean;
    /*Propriedade utilizada para definir se o checkbox deve apresentar o estado indeterminado*/
    @Input() showIndeterminatedState?: boolean;
    /**
     * Envia o evento ao componente na qual foi chamado para enviar a resposta(selectionOptions).
     */
    @Output() onCheckboxTrigger = new EventEmitter<string[]>();
    selectionOptions: SelectionDropdownOptions;
    isIndeterminated: boolean;

    private translateAll: string;
    private translateNone: string;

    constructor(@Inject(ANGULARJS_TRANSLATE) private readonly translate: any) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items && !changes.items.firstChange) {
            this.buildItemsToSelectionOptions();
            this.changeOption();
        }
    }

    ngOnInit() {
        this.loadConfigs();
        this.loadTitles();
        this.buildItemsToSelectionOptions();
        this.changeOption();
    }

    buildItemsToSelectionOptions() {
        const selectionOptionsArray = this.items.map((item) => {
            return {
                id: `id-${item}`,
                value: item,
                isSelected: this.itemsCheckeds && this.itemsCheckeds.includes(item)
            };
        });
        const selectedOptions: SelectionDropdownOptions = {
            selectedAll: false,
            options: selectionOptionsArray
        };
        this.selectionOptions = selectedOptions;
    }

    checkUncheckAll() {
        for (const option of this.selectionOptions.options) {
            option.isSelected = this.selectionOptions.selectedAll;
        }
        this.selectionOptions.title = this.buildDropDownTitle();
        this.onCheckboxTrigger.emit(this.buildItensToEmit());
    }

    changeOption() {
        this.selectionOptions.selectedAll =
            this.selectionOptions.options.length > 0 && _.every(this.selectionOptions.options, "isSelected", true);
        this.selectionOptions.title = this.buildDropDownTitle();
        this.onCheckboxTrigger.emit(this.buildItensToEmit());
    }

    isIndeterminate() {
        return this.showIndeterminatedState
            ? this.itemsCheckeds.length > 0 && this.items.length !== this.itemsCheckeds.length : false;
    }

    private loadConfigs() {
        this.useTranslateKeyValue = this.useTranslateKeyValue || false;
        this.showItemValueSelected = this.showItemValueSelected || false;
    }

    private loadTitles() {
        this.translateAll = this.titles
            ? this.translate.instant(this.titles.checkedAll)
            : this.translate.instant("selection.dropdown.title.all");
        this.translateNone = this.titles
            ? this.translate.instant(this.titles.checkedNone)
            : this.translate.instant("selection.dropdown.title.none");
    }

    private buildDropDownTitle(): string {
        return this.selectionOptions.selectedAll ? this.translateAll : this.getDropDownTitle();
    }

    private getDropDownTitle(): string {
        if (this.titles && this.titles.emptyItems && this.selectionOptions.options.length === 0) {
            return this.translate.instant(this.titles.emptyItems);
        }

        const itensSelecteds: string[] = this.getItensSelected();

        return itensSelecteds.length === 0 ? this.translateNone : this.buildSelectedItens(itensSelecteds);
    }

    private getItensSelected() {
        return _.filter(this.selectionOptions.options, (item: SelectionOption) => {
            return item.isSelected;
        });
    }

    private buildItensToEmit(): string[] {
        return _.flatten(_.map(this.getItensSelected(), "value"));
    }

    private buildSelectedItens(itensSelecteds: string[]): string {
        const selectedItensToParse = _.map(itensSelecteds, (item: SelectionOption) => {
            return item.value;
        });
        if (this.showItemValueSelected) {
            const translateKeySelecetedItems = this.titles && this.titles.selectedItems
                ? this.translate.instant(this.titles.selectedItems)
                : this.translate.instant("selection.dropdown.title.selectedItems");
            return `${selectedItensToParse.length} ${translateKeySelecetedItems}`;
        }
        return this.buildItensToShow(selectedItensToParse);
    }

    private buildItensToShow(selectedItensToParse: string[]): string {
        const selectedItensToString = selectedItensToParse.join(", ");
        const parseManyItems = this.titles.manyItemsSelected
            ? `${selectedItensToParse.length} ${this.translate.instant(this.titles.manyItemsSelected)}`
            : selectedItensToString.substring(0, this.maxSizeSelectedItens) + "...";

        return selectedItensToString.length >= this.maxSizeSelectedItens ? parseManyItems : selectedItensToString;
    }
}
