"use strict";

/**
* @ngdoc service
* @name nms.manageDevice.ManageGroupsModelConverter
* @description - Conversor do modelo de dados da modal de grupos.
*/
var app = angular.module("nms.management");

app.service("ManageGroupsModelConverter", ["ManagePermissionsModelConverter",
    function(ManagePermissionsModelConverter) {
        const service: any = {};

        /**
         * Converte o modelo de dados da tela para o modelo esperado pelo serviço de grupos.
         *  "group": {
         *      "name": "string",
         *      "description": "string",
         *      "permissionsModel": {
         *          "devicePermissionModels": [
         *              {
         *                  "modelCode": 0,
         *                  "permission": 0,
         *                  "vendorCode": 0
         *              }
         *          ],
         *          "locationPermissionModels": [
         *              {
         *                  "graphicId": 0,
         *                  "permission": 0
         *              }
         *          ],
         *          "personalPermissionModels": [
         *              {
         *                  "instance": "string",
         *                  "permission": true
         *              }
         *          ],
         *      },
         *      "userSecuritySettings": {
         *        "loginAccessInitialTime": "string",
         *        "loginAccessFinalTime": "string",
         *        "userTimeoutDelay": 0
         *      }
         *  }
         */
        service.convert = function(groupForm) {
            var permissions = ManagePermissionsModelConverter.convertPermissions(groupForm);

            return {
                name: groupForm.group.data.name,
                description: groupForm.group.data.description,
                permissionsModel: permissions.permissionsModel,
                userSecuritySettings: permissions.userSecuritySettings
            };
        };

        return service;
    }
]);
