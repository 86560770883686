"use strict";

/**
* @ngdoc directive
* @name nms.directive:templateInstanceAccordion
* @description Directive to create accordion of templates and equipaments
*
*   @param {boolean} showApplyTable Whether or not to show apply table
*   @param {boolean} showHeader Whether or not to show header
*   @param {object}  accordionOwner Owner of the accordion. Should provide some basic functions
*   @param {object}  parentController In case this directive is being managed by a parent controller
*   @param {object}  getSpecificTranslationKeys Object mapping translation keys
*   @param {object}  loadTooltipCheckApply translation key tooltip view apply now
*   @param {boolean} showVisualizationMode Whether or not to show visualization mode
*   @param {boolean} showAccordionChildren Whether or not to show accordion children
*   @param {string} executionOrigin Execution origin from application (Ex.: Template Application, Scheduler)
*/
angular.module("nms.templates")
.directive("templateInstanceAccordion", function() {
    return {
        templateUrl: "templates/features/template/components/ui/accordion/accordion.html",
        controller: "TemplateInstanceAccordionDirectiveCtrl",
        scope: {
            showApplyTable: "=",
            showExecutionColumns: "=",
            showHeader: "=",
            accordionOwner: "=",
            parentController: "=",
            getSpecificTranslationKeys: "=",
            loadTooltipCheckApply: "=",
            showVisualizationMode: "=",
            showAccordionChildren: "=",
            executionOrigin: "="
        },
        link(scope, element, attrs) {
            if (attrs.showheader) {
                scope.showheader = attrs.showheader;
            }

            const unregisterShowDetailsFromApplicationWatcher = scope.$on("showDetailsFromApplication", function () {
                scope.showDetailsTrigger();
            });

            scope.$on("$destroy", unregisterShowDetailsFromApplicationWatcher);
        }
    };
});
