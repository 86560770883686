var app = angular.module("nms.dynamicDevice");

/*
 * Diretiva para garantir que o input number receba um valor numérico, isso é
 * necessário por que a diretiva input[number] exige que o modelo seja numérico.
 * Obs: Não altera o tipo do campo no modelo, apenas no input.
 *
 * https://docs.angularjs.org/error/ngModel/numfmt
 */
app.directive("numericBinding",
    function() {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {
                // View to model
                ngModel.$parsers.push(function(value) {
                    return _.isUndefined(value) || _.isNull(value) ? null : "" + value;
                });

                // Model to view
                ngModel.$formatters.push(function(value) {
                    let isDecimal = new RegExp(/^-?\d+\.\d+$/);
                    return isDecimal.test(value) ? parseFloat(value) : parseInt(value, 10);
                });
            }
        };
    }
);
