/**
 * Node types Service.
 *
 * @author guilherme.otta
 */
var app = angular.module("nms.dynamicDevice");

app.service("NodeInfoCacheManager", ["$filter", "DomainHandlerService", "NodeInfoCache", "YangStatements",
    function($filter, DomainHandlerService, nodeInfoCache, YangStatements) {
        var emptyNodeInfo: any = {
            configurableChildren: {
                containers: [],
                lists: [],
                choices: [],
                "leaf-lists": [],
                leaves: []
            },
            nonConfigurableChildren: {
                containers: [],
                lists: [],
                choices: [],
                "leaf-lists": [],
                leaves: []
            }
        };

        this.createNodeInfoCache = function(node) {
            if (node) {
                if (node.template) {
                    node = node.template;
                }
                if (node["cases-template"]) {
                    node = node["cases-template"];
                }

                return DomainHandlerService.traverseNode(node, createNodeInfo);
            }
        };

        this.clear = function() {
            nodeInfoCache.clear();
        };

        /**
         * Creates an object with relevant node information and insert it on Node Info Cache.
         *
         * @param {object} childNode Current node being processed
         * @param {String} yangType Name of the current node set type (i.e. yang statement)
         */
        function createNodeInfo(childNode, yangType) {
            var isConfiguration;
            var path = DomainHandlerService.getPath(childNode);
            var nodeInfo = getNodeInfo(path);
            var parentSchemaPath = DomainHandlerService.getParentSchemaPath(childNode);
            var parentSchemaNode = DomainHandlerService.getSchemaNode(parentSchemaPath);
            var parentPath = DomainHandlerService.getPath(parentSchemaNode) || "/";
            var parentNodeInfo = getNodeInfo(parentPath);

            if (parentNodeInfo.yangType === YangStatements.CHOICES) {
                isConfiguration = parentNodeInfo.isConfiguration;
            } else {
                isConfiguration = !!$filter("isConfiguration")(childNode);
            }

            nodeInfo["sub-statements"] = childNode["sub-statements"];
            nodeInfo.identifier = childNode.id;
            nodeInfo.parentPath = parentPath;
            nodeInfo.yangType = yangType;
            nodeInfo.isConfiguration = isConfiguration;
            nodeInfo.hasNonContainerChildren = false;
            nodeInfo.dataJsonPath = childNode.paths.dataJsonPath;
            nodeInfo.schemaJsonPath = childNode.paths.schemaJsonPath;
            nodeInfo.name = childNode.name;

            if (childNode.template) {
                nodeInfo.listEntryPath = childNode.template.paths.dataJsonPath;
            }

            if (isConfiguration) {
                insertNodeId(parentNodeInfo, _.keys(emptyNodeInfo)[0], childNode, yangType);
            } else {
                changeParentsInfoUntilRoot(path, childNode, parentPath, parentSchemaNode, yangType, insertStatusNodeId);
            }

            if (yangType !== YangStatements.CONTAINERS) {
                changeParentsInfoUntilRoot(path, childNode, parentPath, parentSchemaNode, yangType,
                    updateHasNonContainerChildren);
            }

            if (yangType === YangStatements.LEAVES || yangType === YangStatements.LEAFLISTS) {
                nodeInfo.leafType = childNode.type;
            }

            nodeInfoCache.put(path, nodeInfo);
            nodeInfoCache.put(parentPath, parentNodeInfo);
        }

        /**
         * Change the whole hierarchy on cache from a starting node until root.
         *
         * @param {String} path Current path
         * @param {String} parentPath Path of the parent node
         * @param {String} yangType Name of the current node set type (i.e. yang statement)
         * @param {function} updateInfo function responsible to update specific information of the nodes
         */
        function changeParentsInfoUntilRoot(path, childNode, parentPath?, parentSchemaNode?, yangType?, updateInfo?) {
            var parentNodeInfo = getNodeInfo(parentPath);

            updateInfo(parentNodeInfo, childNode, path, parentPath, yangType);

            if (parentPath !== "/") {
                var grampaSchemaPath = DomainHandlerService.getParentSchemaPath(parentSchemaNode);
                var grampaSchemaNode = DomainHandlerService.getSchemaNode(grampaSchemaPath);
                var grampaPath = DomainHandlerService.getPath(grampaSchemaNode) || "/";

                changeParentsInfoUntilRoot(parentPath, parentSchemaNode, grampaPath, grampaSchemaNode,
                    parentNodeInfo.yangType, updateInfo);
            }
        }

        /**
         * Update the attribute hasNonContainerChildren of a node.
         *
         * @param {object} nodeInfo Object which will be updated
         */
        function updateHasNonContainerChildren(nodeInfo) {
            nodeInfo.hasNonContainerChildren = true;
        }

        /**
         * Insert nodeId at nonConfigurable property of a node.
         *
         * @param {object} nodeInfo Object which will be updated
         * @param {String} childPath child node's path
         * @param {String} path Path of the node
         * @param {String} childYangType Name of the child node type (i.e. yang statement)
         */
        function insertStatusNodeId(nodeInfo, childNode, childPath?, path?, childYangType?) {
            insertNodeId(nodeInfo, _.keys(emptyNodeInfo)[1], childNode, childYangType);
            nodeInfoCache.put(path, nodeInfo);
        }

        /**
         * Insert node id inside a parent node info object.
         *
         * @param {object} parentNodeInfo Object with the node info of a parent node
         * @param {String} childrenType Property that will be used on cache (i.e. configurable, nonConfigurable)
         * @param {String} nodeId Node name
         * @param {String} yangType Name of the current node set type (i.e. yang statement)
         * @return {boolean}
         */
        function insertNodeId(parentNodeInfo, childrenType, childNode?, yangType?) {
            var nodeId = childNode.id;
            var currentChildren = _.flatten(_.values(parentNodeInfo[childrenType]));

            var isHidden = $filter("isHidden")(childNode);

            if (!_.contains(currentChildren, nodeId) && !isHidden) {
                if (yangType === YangStatements.CONTAINERS) {
                    var hasChildrenElements = _.some(_.values(YangStatements), function(statement) {
                        return !_.isEmpty(childNode[statement]);
                    });

                    if (hasChildrenElements) {
                        parentNodeInfo[childrenType][YangStatements.CONTAINERS].push(nodeId);
                    }
                } else {
                    parentNodeInfo[childrenType][yangType].push(nodeId);
                }

                return true;
            }

            return false;
        }

        /**
         * Get node info inside cache or create the structure if node was not found.
         *
         * @param {String} path Path of the requested node
         * @return {*}
         */
        function getNodeInfo(path) {
            var node = nodeInfoCache.getNode(path);

            return (node) ? node : angular.copy(emptyNodeInfo);
        }
    }
]);
